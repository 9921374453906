import React from "react";
import styled from "styled-components";
import { device } from "../../constants/css";
import AppAlert from "../AppAlert";
import TrialEndedModal from "../TrialEndedModal";

const StyledBackground = styled.div`
  background: #e4e2e2;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  max-height: 100vh;
  padding: 0.5rem;
  @media ${device.tablet} {
    padding: 1.5rem;
  }
`;

const Card = styled.div`
  background: #ffffff;
  border-radius: 15px;
  height: 100%;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 10px 8px #cbccc9;
  -moz-box-shadow: 0px 0px 10px 8px #cbccc9;
  box-shadow: 0px 0px 10px 8px #cbccc9;
  position: relative;
`;

const Background = ({ children }) => {
  return (
    <StyledBackground>
      <TrialEndedModal />
      <Card>
        <AppAlert>{children}</AppAlert>
      </Card>
    </StyledBackground>
  );
};

export default Background;
