import React, { useState } from "react";
import ShareTemplate from "./ShareTemplate/ShareTemplate";
import { TeamName } from "./Styles";
import { Row } from "antd";
import TemplateListItem from "./TemplateListItem";
import { NewTemplateCard } from "./TemplateCard";
import { ListManager } from "react-beautiful-dnd-grid";

interface TemplateType {
  Name: string;
  CanShare: boolean;
  IdFolder: number | null;
  IdProcessType: number | null;
  IdProcessTemplate: number | null;
  CanStartProcesses: boolean;
  TeamName?: string;
  IdTeam: number;
}

interface TeamListItemProps {
  Team: {
    IdTeam: number;
    Name: string;
    CanConfigureProcesses: boolean;
    ProcessTemplates: TemplateType[];
  };
  handleOnStart: () => void;
  handleOnEdit: () => void;
  handleOnShare: () => void;
  handleOnMoveToFolder: () => void;
  selectedFolder: TemplateType;
  handleSelectFolder: (
    Template: TemplateType,
    Name: string,
    IdTeam: number
  ) => void;
  handleDeleteFolder: () => void;
  Template: TemplateType;
  nestedFolders: TemplateType[];
  maxItems: number;
  index: number;
  templatesToShare: number[];
  setTemplatesToShare: (templates: number[]) => void;
  getMaxItems: () => number;
  onSelectDuplicate: (Template: TemplateType, IdTeam: number) => void;
  onDragEnd: (from: number, to: number, IdTeam: number) => void;
  setIdTeamToCreate: (idTeam: number) => void;
  setModal: (modal: string) => void;
  handleEditFolder: (Folder: TemplateType, IdTeam: number) => void;
}

const TeamListItem: React.FC<TeamListItemProps> = ({
  Team,
  index,
  selectedFolder,
  getMaxItems,
  handleOnStart,
  handleOnEdit,
  handleDeleteFolder,
  handleOnMoveToFolder,
  handleSelectFolder,
  nestedFolders,
  onSelectDuplicate,
  onDragEnd,
  setIdTeamToCreate,
  setModal,
  handleEditFolder,
}) => {
  const { IdTeam, Name, ProcessTemplates, CanConfigureProcesses } = Team;
  const [templatesToShare, setTemplatesToShare] = useState<number[]>([]);
  const [sharing, setSharing] = useState<boolean>(false);

  return (
    <div
      key={index}
      onClick={() => {
        if (!sharing) {
          setTemplatesToShare([]);
        }
      }}
    >
      {!selectedFolder && <TeamName>{Name}</TeamName>}
      {sharing && (
        <ShareTemplate
          templatesToShare={templatesToShare}
          closeModal={() => setSharing(false)}
        />
      )}
      {(ProcessTemplates.length > 0 ||
        ((!selectedFolder || IdTeam === selectedFolder?.IdTeam) &&
          CanConfigureProcesses)) && (
        <Row
          gutter={[16, 16]}
          style={{
            marginTop: "0.5rem",
            marginBottom: "1rem",
            marginLeft: 0,
            marginRight: 0,
            maxWidth: 305 * (getMaxItems() - 1) + 282,
          }}
        >
          {CanConfigureProcesses ? (
            <ListManager
              items={ProcessTemplates}
              direction="horizontal"
              maxItems={getMaxItems()}
              render={(Template) => (
                <TemplateListItem
                  Team={Team}
                  Template={Template}
                  handleOnStart={handleOnStart}
                  handleOnEdit={handleOnEdit}
                  handleOnShare={() => setSharing(true)}
                  handleOnMoveToFolder={handleOnMoveToFolder}
                  selectedFolder={selectedFolder}
                  handleSelectFolder={handleSelectFolder}
                  handleDeleteFolder={handleDeleteFolder}
                  handleOnDuplicate={() => onSelectDuplicate(Template, IdTeam)}
                  nestedFolders={nestedFolders}
                  maxItems={getMaxItems()}
                  index={ProcessTemplates.findIndex((t) =>
                    t.IdProcessTemplate
                      ? t.IdProcessTemplate === Template.IdProcessTemplate
                      : t.IdFolder === Template.IdFolder
                  )}
                  templatesToShare={templatesToShare}
                  setTemplatesToShare={setTemplatesToShare}
                  handleEditFolder={handleEditFolder}
                />
              )}
              onDragEnd={(from, to) => onDragEnd(from, to, IdTeam)}
            />
          ) : (
            ProcessTemplates.map((Template, i) => (
              <TemplateListItem
                Team={Team}
                Template={Template}
                handleOnStart={handleOnStart}
                handleOnEdit={handleOnEdit}
                handleOnShare={() => setSharing(true)}
                handleOnMoveToFolder={handleOnMoveToFolder}
                selectedFolder={selectedFolder}
                handleSelectFolder={handleSelectFolder}
                handleDeleteFolder={handleDeleteFolder}
                handleOnDuplicate={() => onSelectDuplicate(Template, IdTeam)}
                nestedFolders={nestedFolders}
                maxItems={getMaxItems()}
                index={i}
                key={Template.IdProcessTemplate}
                templatesToShare={templatesToShare}
                setTemplatesToShare={setTemplatesToShare}
                handleEditFolder={handleEditFolder}
              />
            ))
          )}
          {(!selectedFolder || IdTeam === selectedFolder?.IdTeam) &&
            CanConfigureProcesses && (
              <NewTemplateCard
                onCreateTemplate={() => {
                  setIdTeamToCreate(IdTeam);
                  setModal("create");
                }}
                onCreateFolder={() => {
                  setIdTeamToCreate(IdTeam);
                  setModal("createFolder");
                }}
                Label="Crear"
              />
            )}
        </Row>
      )}
    </div>
  );
};

export default TeamListItem;
