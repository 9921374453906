import { Input, Row, Upload } from "antd";
import styled from "styled-components";
import Logo from "../../../../resources/img/SETTINGS_LOGO.svg";

export const InvisibleInputForEmail = styled.input`
  position: fixed;
  // width: 1px;
  // height: 1px;
  border: none;
  :focus-visible {
    outline: none;
  }
`;

export const LogoContainer = styled(Row)`
  background-image: ${({ $picture }) =>
    `url(${$picture ? `"${$picture}"` : Logo})`};
  background-repeat: no-repeat;
  color: white;
  background-size: 5rem;
  margin-bottom: 0.5rem;
  height: 5rem;
  width: 5rem;
  font-size: 2.2rem;
  text-align: center;
  display: block;
  background-position: center;
  padding-top: 0.7rem;
  border-radius: 50%;
`;

export const InfoContainer = styled.div`
  font-family: "Gotham-Book";
  color: #7c7c7c;
  font-size: medium;
  text-align: center;
  margin-bottom: 1rem;
`;

export const ActionsContainer = styled(Row)`
  font-family: "Gotham-Book";
  color: var(--medium-gray);
  font-size: medium;
  padding: 0.5rem 0rem 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: #f3f0f0;
  }
`;

// Change Password
export const ChangePasswordTitle = styled(Row)`
  font-family: "Gotham-Book";
  color: #7c7c7c;
  font-size: x-large;
  display: block;
  text-align: center;
  margin-bottom: 1rem;
`;

export const ChangePasswordInput = styled(Input.Password)`
  margin-bottom: 0.5rem;
  padding: 0.3rem;
  font-family: "Gotham-Book";
  color: #7c7c7c;
  font-size: medium;
  border-radius: 0.4rem;
`;

export const ChangePasswordButton = styled.button`
  color: ${({ color }) => color};
  font-family: "Gotham-Book";
  font-size: Large;
  width: calc(50% - 0.5rem);
  padding: 0.5rem 0rem;
  cursor: pointer;
  background: white;
  border: solid 1px;
  border-color: ${({ colorbor }) => colorbor};
  border-radius: 0.4rem;
  text-align: center;
  margin-top: 0.5rem;
  &:hover {
    background: #f3f0f0;
  }
`;

//Feedback

export const FeedbackInput = styled(Input)`
  margin-bottom: 0.6rem;
  font-family: "Gotham-Light";
  color: var(--medium-gray);
  font-size: 16px;
  border-radius: 0.4rem;
`;

export const FeedbackTextAreaInput = styled(Input.TextArea)`
  margin-bottom: 0.6rem;
  font-family: "Gotham-Light";
  color: var(--medium-gray);
  font-size: 16px;
  border-radius: 0.4rem;
`;

export const FeedbackUploadFile = styled(Upload)`
  margin-bottom: 0.6rem;
  cursor: pointer;
`;

export const FeedbackUploadText = styled.a`
  text-decoration: underline;
  :hover {
    text-decoration: underline;
  }
`;

export const WhatsAppContact = styled(Row)`
  background-color: #25d366;
  width: 14rem;
  height: 3rem;
  border-radius: 2rem;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Gotham-Book";
  font-size: 16px;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0px 0px 10px grey;
`;
