import React, { useRef } from "react";
import { Languages } from "./Dictionary";
import { Button } from "../../../../GeestUI";
import GeestSelect from "../../../../GeestUI/GeestSelect";
import { ColumnsSelectProps } from "./ImportDBModal.d";
import {
  ButtonsRow,
  ColumnsSelectWrapper,
  DBColumnField,
  DataTypeWrapper,
  ExcelColumnField,
  FieldLabelContainer,
  FieldRow,
  FieldTitleWrapper,
  FieldsTitles,
  FieldsWrapper,
  FileNameContainer,
  FirstRowCheckWrapper,
  StyledCheckbox,
  SubTitle,
} from "./Styles";
import DataTypeIcons from "../../../Reports/SharedComponents/DataTypeIcons";

const ColumnsSelect: React.FC<ColumnsSelectProps> = ({
  fileName,
  selectedColumns,
  setSelectedColumns,
  varDBColumns,
  importFirstRow,
  setImportFirstRow,
  importing,
  goBack,
  handleOnImport,
}) => {
  const {
    SelectFieldsLabel,
    ColumnsToImport,
    PutOnDBColumn,
    FirstRowLabel,
    ReturnLabel,
    ImportLabel,
    DontImportThisField,
  } = Languages["ESP"];

  const checkboxRef = useRef<HTMLInputElement>(null);

  const removeFocus = () => {
    checkboxRef?.current?.blur();
  };

  const getOptions = (excelId: string, columnType: string) => {
    const selected = selectedColumns
      .filter(
        ({ Id, IdColumnSelected }) => Id !== excelId && IdColumnSelected !== 0
      )
      .map(({ IdColumnSelected }) => IdColumnSelected);

    const filteredColumns = varDBColumns.filter(
      ({ IdColumn, DataType }) =>
        !selected.includes(IdColumn) && DataType === columnType
    );

    let options = filteredColumns.map(({ IdColumn, Title, DataType }) => {
      return {
        value: IdColumn,
        label: (
          <FieldLabelContainer>
            <DataTypeWrapper>
              <img
                style={{
                  ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
                }}
                alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
                src={DataTypeIcons[DataType?.toLowerCase()].icon}
              />
            </DataTypeWrapper>
            <FieldTitleWrapper>{Title}</FieldTitleWrapper>
          </FieldLabelContainer>
        ),
      };
    });

    return [
      {
        value: 0,
        label: DontImportThisField,
      },
      ...options,
    ];
  };

  const onChangeSelect = (value: number, idx: number) => {
    if (selectedColumns.length > 0) {
      let selectedColumnsAux = [...selectedColumns];
      selectedColumnsAux[idx].IdColumnSelected = value;
      setSelectedColumns(selectedColumnsAux);
    }
  };

  return (
    <ColumnsSelectWrapper>
      <FileNameContainer>
        <p>{fileName}</p>
      </FileNameContainer>

      <SubTitle>
        <p>{SelectFieldsLabel}</p>
      </SubTitle>

      <FieldsTitles>
        <p>{ColumnsToImport}</p>
        <p>{PutOnDBColumn}</p>
      </FieldsTitles>

      <FieldsWrapper>
        {selectedColumns.map((column, idx) => {
          return (
            <FieldRow key={idx}>
              <ExcelColumnField>
                <p>{column.Label}</p>
              </ExcelColumnField>

              <DBColumnField>
                <GeestSelect
                  value={column.IdColumnSelected}
                  options={getOptions(column.Id, column.DataType)}
                  onChange={(value) => onChangeSelect(value, idx)}
                  disableSearch
                  valueNecesary
                />
              </DBColumnField>
            </FieldRow>
          );
        })}
      </FieldsWrapper>

      <FirstRowCheckWrapper>
        <StyledCheckbox
          checked={importFirstRow}
          onChange={(e) => {
            setImportFirstRow(e.target.checked);
            removeFocus();
          }}
          ref={checkboxRef}
        />
        <p
          onClick={() => {
            setImportFirstRow(!importFirstRow);
            removeFocus();
          }}
        >
          {FirstRowLabel}
        </p>
      </FirstRowCheckWrapper>

      <ButtonsRow>
        <Button
          type="secondary"
          onClick={goBack}
          disabled={importing}
          loading={importing}
        >
          {ReturnLabel}
        </Button>

        <Button
          type="primary"
          onClick={handleOnImport}
          disabled={importing}
          loading={importing}
        >
          {ImportLabel}
        </Button>
      </ButtonsRow>
    </ColumnsSelectWrapper>
  );
};

export default ColumnsSelect;
