import React from "react";
import Modal from "../../../../components/Modal";
import { Typography, Button } from "../../../../GeestUI";
import { DynamicColumn } from "../../Pendings.d";
import { Row } from "antd";
import { useMutation } from "../../../../hooks";

const { P } = Typography;

interface ModalProps {
  visible: boolean;
  setVisible: () => void;
  Column: DynamicColumn | null;
  reload: () => void;
}

const HideColumnModal: React.FC<ModalProps> = ({
  visible,
  setVisible,
  Column,
  reload,
}) => {
  const [HideImportedColumn] = useMutation<[]>({
    func: "Ver2-MyPendings-hic",
    onSuccess: () => {
      reload();
      setVisible();
    },
  });

  const handleHideColumn = () => {
    HideImportedColumn({
      args: {
        IdDynamicColumn: Column?.Id,
      },
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={setVisible}
      width="18rem"
      title="Ocultar columna"
    >
      <P>{`¿Deseas ocultar la columna: "${Column ? Column.Label : ""}"`}</P>
      <Row justify="space-between">
        <Button type="default" onClick={setVisible}>
          Cancelar
        </Button>
        <Button type="primary" onClick={handleHideColumn}>
          Ocultar
        </Button>
      </Row>
    </Modal>
  );
};

export default HideColumnModal;
