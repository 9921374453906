import React from "react";
import { Card, Space } from "antd";
import { Button, Typography } from "../../GeestUI";

const { H1, B } = Typography;

const ButtonsV2: React.FC = () => {
  return (
    <>
      <H1>
        <B>Buttons V2</B>
      </H1>
      <Card size="small" title="Types">
        <Space wrap size="middle">
          <Button type="default">default</Button>
          <Button type="primary">primary</Button>
          <Button type="secondary">secondary</Button>
          <Button type="danger">danger</Button>
        </Space>
      </Card>
      <Card size="small" title="Props">
        <Space size="middle" direction="vertical">
          <Space wrap size="middle">
            <Button disabled type="default">
              default disabled
            </Button>
            <Button disabled type="primary">
              primary disabled
            </Button>
            <Button disabled type="secondary">
              secondary disabled
            </Button>
            <Button disabled type="danger">
              danger disabled
            </Button>
          </Space>
          <Space wrap size="middle">
            <Button loading type="default">
              default loading
            </Button>
            <Button loading type="primary">
              primary loading
            </Button>
            <Button loading type="secondary">
              secondary loading
            </Button>
            <Button loading type="danger">
              danger loading
            </Button>
          </Space>
        </Space>
      </Card>
    </>
  );
};
export default ButtonsV2;
