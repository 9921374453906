import React, { useEffect, useRef } from "react";
import { Input } from "./styles";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import { Row } from "antd";
import { transformedOptions } from "../../DynamicInput/Components/helpers/CurrencyHelpers";

interface CurrencyFilterProps {
  value: {
    Format: string;
    Value: string;
  };
  onChangeFilter: (filter: any) => void;
  onSubmit: () => void;
  autoFocus?: boolean;
  onPressEnter?: () => void;
}

const CurrencyFilter: React.FC<CurrencyFilterProps> = ({
  value,
  onChangeFilter,
  onSubmit,
  onPressEnter,
  autoFocus,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!value.Format) {
      onChangeFormat("MXN");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autoFocus) {
      ref?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  const onChangeFormat = (Format: string) => {
    onChangeFilter({ ...value, Format });
  };
  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let { value: newValue } = e.target;
    const regex = /^-?[0-9]*(\.[0-9]*)?$/;
    newValue = newValue.replace(/,/g, "");
    if (Number(newValue) > 1000000000000000000) return;
    const isValidNumber = !isNaN(Number(newValue)) && regex.test(newValue);
    if (isValidNumber || newValue === "" || newValue === "-") {
      onChangeFilter({ ...value, Value: newValue });
    }
  };

  const getFormatedNumber = (val: string) =>
    val ? val.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";

  return (
    <Row style={{ width: "285px" }} wrap={false}>
      <GeestSelect
        value={value.Format}
        options={transformedOptions}
        onChange={onChangeFormat}
        $width="80px"
        $listWidth="130px"
        valueNecesary
        style={{ marginRight: "5px" }}
      />
      <Input
        value={getFormatedNumber(value.Value)}
        onChange={handleOnChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (onPressEnter) {
              onPressEnter();
            } else {
              e.currentTarget.blur();
            }
          }
        }}
        onBlur={onSubmit}
        ref={ref}
      />
    </Row>
  );
};

export default CurrencyFilter;
