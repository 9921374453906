import React, { useContext, useState, useRef, useEffect } from "react";
import { useMutation } from "../../../hooks";
import { SesionContext } from "../../../config/State";
import moment from "moment";
import { FaReply } from "react-icons/fa";
import { Typography, Button } from "../../../GeestUI";
import {
  Container,
  ChatContainer,
  MessagesContainer,
  MessageBox,
  ReplyIcon,
  ProfileImg,
  UserRound,
  MessageFromSystem,
  MessageFromOthers,
  MessageFromUser,
  NewMessageContainer,
  ModalHeaderContent,
  ChannelsContainer,
  ChannelBox,
  ChannelListButton,
  StyledTextArea,
  MessageToReplyContainer,
} from "./Styles";
import _, { noop as NOOP } from "lodash";
import { ChatsData } from "../Pendings.d";
import { ChatMessage, ChatChannel } from "../Pendings.d";
import { GoTriangleDown } from "react-icons/go";
import { Popover } from "@material-ui/core";
import ChatListPopover from "./ChatListPopover";
import { CloseIcon } from "../../../components/hoverIcons";
import { ReactMarkdownStyled } from "./Styles";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
//@ts-ignore
import emoji from "emoji-dictionary";
import { CustomLink, CustomList } from "../../../components/MarkdownHelpers";
const { P, B } = Typography;

const getParsedActivityDate = (date: string) => {
  const isToday =
    moment(date).format("DD/MMM/YY") === moment().format("DD/MMM/YY");
  const isYeserday =
    moment(date).format("MMM/YY") === moment().format("MMM/YY") &&
    Number(moment(date).format("DD")) === Number(moment().format("DD")) - 1;

  if (isToday) {
    return `${moment(date).format("hh:mm A")}`;
  }
  if (isYeserday) {
    return `Ayer ${moment(date).format("hh:mm A")}`;
  }
  return moment(date).format("DD/MMM/YY hh:mm A");
};

const ChatsHeader: React.FC<{
  title: string;
  onClose?: () => void;
}> = ({ title, onClose = NOOP }) => (
  <ModalHeaderContent>
    <p>{title}</p>
    <CloseIcon onClick={onClose} size={20} />
  </ModalHeaderContent>
);

const MessageToReply: React.FC<{
  chatMessages: ChatMessage[];
  idMessage: number;
  fromUser?: boolean;
}> = ({ chatMessages, idMessage, fromUser = false }) => {
  const message = _.find(chatMessages, { IdMessage: idMessage }) as ChatMessage;
  return (
    <MessageToReplyContainer $fromUser={fromUser}>
      <B>{`${message.User?.FirstName} ${message.User?.LastName}`}</B>

      <div className="message-content">
        <ReactMarkdownStyled
          children={message.Message.replace(/\n/gi, "&nbsp; \n").replace(
            /:\w+:/gi,
            (name) => emoji.getUnicode(name) ?? name
          )}
          remarkPlugins={[remarkGfm, remarkBreaks]}
          $color="#727e8b"
          components={{
            a: ({ children, href }) => {
              return (
                <CustomLink href={href} setClickingLink={() => {}}>
                  {children}
                </CustomLink>
              );
            },
            ul: ({ children }) => {
              return <CustomList>{children}</CustomList>;
            },
          }}
        />
      </div>
    </MessageToReplyContainer>
  );
};

const Message: React.FC<{
  idUser: number;
  chatMessages: ChatMessage[];
  chatMessage: ChatMessage;
  setMessageToReplyId: (id: number | null) => void;
  chatInputRef: React.RefObject<HTMLElement>;
}> = ({
  idUser,
  chatMessages,
  chatMessage,
  setMessageToReplyId,
  chatInputRef,
}) => {
  const [hovering, setHovering] = useState<boolean>(false);

  if (chatMessage.Type === "Auto") {
    return (
      <MessageFromSystem>
        <P
          style={{
            margin: 0,
            padding: "0.5rem 1rem",
            backgroundColor: "#edecec",
            borderRadius: "20px",
            color: "#48505e",
            fontFamily: "Gotham-Bold",
          }}
        >
          {chatMessage.Message}
        </P>
      </MessageFromSystem>
    );
  } else if (chatMessage.User?.IdUser === idUser) {
    return (
      <MessageFromUser
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <div style={{ padding: "0 0.5rem" }}>
          {hovering && (
            <ReplyIcon
              onClick={() => {
                setMessageToReplyId(chatMessage.IdMessage);
                chatInputRef.current?.focus();
              }}
            >
              <FaReply size={15} />
            </ReplyIcon>
          )}
        </div>
        <MessageBox
          style={{
            backgroundColor: "#edecec",
            borderRadius: "6px 0 6px 6px",
          }}
        >
          {chatMessage.IdMessageReply && (
            <MessageToReply
              chatMessages={chatMessages}
              idMessage={chatMessage.IdMessageReply}
              fromUser
            />
          )}

          <ReactMarkdownStyled
            children={chatMessage.Message.replace(/\n/gi, "&nbsp; \n").replace(
              /:\w+:/gi,
              (name) => emoji.getUnicode(name) ?? name
            )}
            remarkPlugins={[remarkGfm, remarkBreaks]}
            components={{
              a: ({ children, href }) => {
                return (
                  <CustomLink href={href} setClickingLink={() => {}}>
                    {children}
                  </CustomLink>
                );
              },
              ul: ({ children }) => {
                return <CustomList>{children}</CustomList>;
              },
            }}
          />

          <div style={{ display: "flex", justifyContent: "end" }}>
            <P style={{ margin: 0, color: "#828d9e" }}>
              {getParsedActivityDate(chatMessage.SentAt)}
            </P>
          </div>
        </MessageBox>

        {chatMessage.User?.ProfilePicture ? (
          <ProfileImg
            style={{
              backgroundImage: `url("${chatMessage.User?.ProfilePicture}")`,
            }}
          />
        ) : (
          <UserRound>{chatMessage?.User?.Initials}</UserRound>
        )}
      </MessageFromUser>
    );
  }
  return (
    <MessageFromOthers
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {chatMessage.User?.ProfilePicture ? (
        <ProfileImg
          style={{
            backgroundImage: `url("${chatMessage.User?.ProfilePicture}")`,
          }}
        />
      ) : (
        <UserRound>{chatMessage.User?.Initials}</UserRound>
      )}

      <MessageBox
        style={{
          backgroundColor: "#edecec",
          borderRadius: "0 6px 6px 6px",
        }}
      >
        {chatMessage.IdMessageReply && (
          <MessageToReply
            chatMessages={chatMessages}
            idMessage={chatMessage.IdMessageReply}
          />
        )}
        <B style={{ color: "#48505e", fontFamily: "Gotham-Bold" }}>
          {`${chatMessage.User?.FirstName} ${chatMessage.User?.LastName}`}
        </B>

        <ReactMarkdownStyled
          children={chatMessage.Message.replace(/\n/gi, "&nbsp; \n").replace(
            /:\w+:/gi,
            (name) => emoji.getUnicode(name) ?? name
          )}
          remarkPlugins={[remarkGfm, remarkBreaks]}
          components={{
            a: ({ children, href }) => {
              return (
                <CustomLink href={href} setClickingLink={() => {}}>
                  {children}
                </CustomLink>
              );
            },
            ul: ({ children }) => {
              return <CustomList>{children}</CustomList>;
            },
          }}
        />

        <div style={{ display: "flex" }}>
          <P style={{ margin: 0, color: "#828d9e" }}>
            {getParsedActivityDate(chatMessage.SentAt)}
          </P>
        </div>
      </MessageBox>

      <div style={{ padding: "0 0.5rem" }}>
        {hovering && (
          <ReplyIcon
            onClick={() => {
              setMessageToReplyId(chatMessage.IdMessage);
              chatInputRef.current?.focus();
            }}
          >
            <FaReply size={15} />
          </ReplyIcon>
        )}
      </div>
    </MessageFromOthers>
  );
};

const ChatsContent: React.FC<{
  chatsData: ChatsData | null | undefined;
  onClose?: () => void;
  showHeader?: boolean;
  containerExtraStyles?: any;
  chatExtraStyles?: any;
}> = ({
  chatsData,
  onClose,
  showHeader = false,
  containerExtraStyles = {},
  chatExtraStyles = {},
}) => {
  const [sesion] = useContext<any>(SesionContext);
  const chatInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatChannelListPopoverRef = useRef(null);

  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [chatChannels, setChatChannels] = useState<ChatChannel[]>([]);
  const [activeChannel, setActiveChatChannel] = useState<number | null>(null);
  const [messageToReplyId, setMessageToReplyId] = useState<number | null>(null);
  const [newMessage, setNewMessage] = useState<string>("");
  const [idExecutionChat, setIdExecutionChat] = useState<number | null>(null);
  const [showChatChannelList, setShowChatChannelList] =
    useState<boolean>(false);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (chatsData) {
      setNewMessage("");
      setMessageToReplyId(null);
      setChatMessages(chatsData.ChatMessages.reverse());
      setChatChannels(chatsData.ChatChannels);
      setActiveChatChannel(chatsData.ActiveChannel);
      setIdExecutionChat(
        _.get(chatsData, "ChatChannels[0].IdExecutionChat", null)
      );
    }
    chatInputRef.current?.focus();
  }, [chatsData]);

  useEffect(() => {
    setTimeout(() => scrollToBottom(), 1);
  }, [chatMessages]);

  const [getChatChannel] = useMutation<ChatsData>({
    func: "Ver2-MyPendings-gcc",
    onSuccess: (response) => {
      setChatMessages(response.ChatMessages.reverse());
      setChatChannels(response.ChatChannels);
      chatInputRef.current?.focus();
    },
  });

  const [sendChatMessage] = useMutation<[]>({
    func: "Ver2-MyPendings-scm",
    onSuccess: () => {
      getChatChannel({
        args: {
          IdExecutionChat: idExecutionChat,
        },
      });
    },
  });

  const handleChangeChannel = (IdExecutionChat: number) => {
    setNewMessage("");
    setMessageToReplyId(null);
    setIdExecutionChat(IdExecutionChat);
    setActiveChatChannel(IdExecutionChat);
    getChatChannel({
      args: {
        IdExecutionChat,
      },
    });
  };

  const handleSendMessage = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    if (!_.isEmpty(_.trim(newMessage))) {
      sendChatMessage({
        args: {
          IdExecutionChat: activeChannel,
          Message: {
            Text: _.trim(newMessage),
            IdMessageReply: messageToReplyId || "",
            File: [],
          },
        },
      });
      setNewMessage("");
      setMessageToReplyId(null);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (!e.shiftKey && e.key === "Enter") {
      if (e) e.preventDefault();
      handleSendMessage();
    }
  };

  const getChannels = () => {
    let chats: ChatChannel[] = [...chatChannels];
    [...chatChannels].forEach((chat, i) => {
      if (chat.IdExecutionChat === activeChannel) {
        if ([...chatChannels].length > 3 && i >= 3) {
          chats.splice(i, 1);
          chats.splice(2, 0, chat);
        }
      }
    });
    return chats;
  };

  return (
    <Container
      style={
        showHeader
          ? { overflowY: "auto", ...containerExtraStyles }
          : { ...containerExtraStyles }
      }
    >
      {showHeader && (
        <ChatsHeader
          title={chatsData?.ProcessExecutionTitle || ""}
          onClose={onClose}
        />
      )}

      <ChannelsContainer>
        <div className="channels">
          {getChannels()
            .splice(0, 3)
            .map((chatChannel, i) => (
              <ChannelBox
                key={`${chatChannel.IdExecutionChat}-${i}`}
                $selected={chatChannel.IdExecutionChat === activeChannel}
                onClick={() => handleChangeChannel(chatChannel.IdExecutionChat)}
              >
                <p>{chatChannel.Label}</p>
              </ChannelBox>
            ))}
        </div>
        {(chatChannels || []).length > 3 && (
          <>
            <ChannelListButton
              ref={chatChannelListPopoverRef}
              onClick={() => setShowChatChannelList(true)}
            >
              <GoTriangleDown size={10} color="white" cursor="pointer" />
            </ChannelListButton>
            <Popover
              open={showChatChannelList}
              anchorEl={chatChannelListPopoverRef.current}
              onClose={() => setShowChatChannelList(false)}
              PaperProps={{
                style: {
                  borderRadius: "6px",
                  width: "252px",
                  padding: "5px 0",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <ChatListPopover
                chats={getChannels().splice(3)}
                selectChat={(id: number) => handleChangeChannel(id)}
                onClose={() => setShowChatChannelList(false)}
              />
            </Popover>
          </>
        )}
      </ChannelsContainer>

      <ChatContainer
        style={
          showHeader
            ? { height: "85%", maxHeight: "85vh", ...chatExtraStyles }
            : { height: "52vh", ...chatExtraStyles }
        }
      >
        <MessagesContainer>
          {(chatMessages || []).map((chatMessage, i) => (
            <Message
              idUser={Number(sesion.Id)}
              chatMessages={chatMessages}
              chatMessage={chatMessage}
              setMessageToReplyId={setMessageToReplyId}
              chatInputRef={chatInputRef}
              key={chatMessage.Message + i + Math.random()}
            />
          ))}
          <div ref={messagesEndRef} />
        </MessagesContainer>
        <NewMessageContainer
          style={
            _.isNumber(messageToReplyId) ? { backgroundColor: "#eff1f4" } : {}
          }
        >
          {_.isNumber(messageToReplyId) && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <MessageToReply
                chatMessages={chatMessages}
                idMessage={messageToReplyId}
                fromUser
              />
              <CloseIcon
                onClick={() => {
                  setMessageToReplyId(null);
                  chatInputRef.current?.focus();
                }}
              />
            </div>
          )}
          <form
            onSubmit={handleSendMessage}
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <StyledTextArea
              autoFocus
              ref={chatInputRef}
              autoSize={{ minRows: 1, maxRows: 5 }}
              value={newMessage}
              onChange={({ target: { value } }) => setNewMessage(value)}
              placeholder="Escribe un mensaje aquí..."
              onKeyDown={handleKeyPress}
            />
            <Button
              type="primary"
              size="medium"
              disabled={_.isEmpty(_.trim(newMessage))}
              onClick={() => handleSendMessage()}
            >
              Enviar
            </Button>
          </form>
        </NewMessageContainer>
      </ChatContainer>
    </Container>
  );
};

export default ChatsContent;
