import React, { useContext } from "react";
import { Modal, Paper } from "@material-ui/core";
import styled from "styled-components";
import { Row } from "antd";
import { noop as NOOP } from "lodash";
import Flow from "../../../Pendings/DetailPending/Popups/Flow";
import { MessagesContext } from "../../../../components/AppMessages";

const StyledPaper = styled(Paper)`
  width: 80vw;
  height: 37rem;
  margin-right: 3rem;
  border-radius: 10px !important;
`;

const FluxContainer = styled.div`
  width: 100%;
  height: 36rem;
  overflow-y: hidden;
`;

interface FluxProps {
  IdProcessExecution?: number | null;
  onDetails?: boolean;
  onClose: () => void;
  onRestartFlux: () => void;
  onChangePending?: (idPending: number) => void;
  closable?: boolean;
  shouldReloadFlux?: boolean;
  setshouldReloadFlux?: (value: boolean) => void;
}
const Flux: React.FC<FluxProps> = ({
  IdProcessExecution,
  onDetails = false,
  onChangePending = NOOP,
  onClose,
  onRestartFlux,
  closable,
  shouldReloadFlux = false,
  setshouldReloadFlux = NOOP,
}) => {
  const { showMessage } = useContext(MessagesContext);

  const handleOnRestartFlux = (): void => {
    showMessage("Reinicio exitoso", "success");
    onRestartFlux();
  };

  if (onDetails) {
    return (
      <Row
        justify="center"
        align="middle"
        style={{ width: "100%", height: "100%" }}
      >
        <FluxContainer style={{ height: "100%" }}>
          {IdProcessExecution && (
            <Flow
              IdProcessExecution={IdProcessExecution}
              onClose={onClose}
              onChangePending={onChangePending}
              onRestartFlux={handleOnRestartFlux}
              closable={closable}
              shouldReload={shouldReloadFlux}
              setShouldReload={setshouldReloadFlux}
            />
          )}
        </FluxContainer>
      </Row>
    );
  }
  return (
    <Modal open onClose={onClose} style={{ opacity: "1", zIndex: 2 }}>
      <Row
        justify="center"
        align="middle"
        onClick={onClose}
        style={{ width: "100%", height: "100%" }}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <FluxContainer>
            {IdProcessExecution && (
              <Flow
                IdProcessExecution={IdProcessExecution}
                onClose={onClose}
                onChangePending={onChangePending}
                onRestartFlux={handleOnRestartFlux}
              />
            )}
          </FluxContainer>
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default Flux;
