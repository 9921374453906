import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Tooltip } from "antd";
import _ from "lodash";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { AiOutlineInfoCircle, AiOutlinePlus } from "react-icons/ai";
import { Button } from "../../../../../../../GeestUI";
import { EditableField, Field } from "../../../../../Configurator.d";
import { SearchBar } from "../../../../../../Pendings/styles";
import {
  Body,
  ColumnTitle,
  SingleColumn,
  StyledDivider,
} from "../../../../../Flow/TaskFields/styles";
import {
  DraggableField,
  FieldsColumn,
} from "../../../../TaskFields/DragAndDrop";
import FieldItem from "../../../../TaskFields/FieldItem";
import { useFetch, useMutation } from "../../../../../../../hooks";
import {
  EditTriggerFieldsViewProps,
  GetTriggerDetail,
} from "./EditTriggerFieldsView.d";
import { filterFields } from "../../../../../FlowActions/EditFields/helpers";
import {
  apiTriggerFieldsToEditableFields,
  buildTriggerFields,
  getFieldFromGlobals,
  handleDragAndDrop,
} from "../../../../TaskFields/helpers";
import EditField from "../../../../TaskFields/EditField";
import { DeleteProcessField } from "../../../../../FlowActions/EditFields/ProcessFields.d";
import { OnChangeMandatoryParams } from "../../../../TaskFields/TaskFields.d";
import { ConfiguratorContext } from "../../../../../Configurator";
import DeleteFieldModal from "../../../../TaskFields/DeleteFieldModal";

const EditTriggerFieldsView: React.FC<EditTriggerFieldsViewProps> = ({
  IdPrepublishTrigger,
}) => {
  const { IdTeam, IdProcess } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const { refreshProcessFlow } = useContext(ConfiguratorContext);
  const [filter, setFilter] = useState("");
  const [triggerFields, setTriggerFields] = useState<EditableField[]>([]);
  const [editingFieldId, setEditingFieldId] = useState<number | null>(null);
  const [fieldToDelete, setFieldToDelete] = useState<Field | null>(null);

  const { data, reload: reloadFields } = useFetch<GetTriggerDetail>({
    func: "Ver2-Configurator-gtd",
    args: {
      IdTeam,
      IdPrepublishTrigger,
      IdProcessTemplate: IdProcess,
    },
    onSuccess: ({ Fields }) => {
      setTriggerFields(
        apiTriggerFieldsToEditableFields(Fields.TriggerFields ?? [])
      );
    },
  });
  const [updateTriggerDetail] = useMutation<[]>({
    func: "Ver2-Configurator-utdi",
  });
  const [deleteProcessField] = useMutation<DeleteProcessField>({
    func: "Ver2-Configurator-dpf",
    onSuccess: () => {
      reloadFields();
      refreshProcessFlow();
      setFieldToDelete(null);
    },
  });
  const [deleteProcessVarDBGroup] = useMutation<[]>({
    func: "Ver2-Configurator-dpvg",
    onSuccess: () => {
      reloadFields();
      refreshProcessFlow();
      setFieldToDelete(null);
    },
  });
  const globalFields = data?.Fields.GlobalFields ?? [];

  const filteredGlobalFields = filterFields(globalFields, filter);

  const buildUpdateTriggersArgs = (
    globalFields: Field[],
    triggerFields: EditableField[]
  ) => {
    const args = {
      ..._.omit(data, ["FilesPath", "Fields"]),
      IdTeam,
      TriggerFields: buildTriggerFields(globalFields, triggerFields),
    };
    return args;
  };

  const handleOnDragEnd: OnDragEndResponder = (dropResult) => {
    const triggersMode = true;
    const { allFields, succeeded } = handleDragAndDrop(
      dropResult,
      {
        globalFields: filteredGlobalFields,
        consultFields: [],
        editableFields: _.cloneDeep(triggerFields),
      },
      triggersMode
    );
    setTriggerFields(allFields.editableFields);
    if (succeeded) {
      updateTriggerDetail({
        args: buildUpdateTriggersArgs(
          allFields.globalFields,
          allFields.editableFields
        ),
      });
    }
  };

  const handleOnClickAddField: React.MouseEventHandler<
    HTMLButtonElement
  > = () => setEditingFieldId(0);
  const handleOnClickField = (field: Field): void =>
    setEditingFieldId(field.IdField);
  const handleOnRegisterField = (fieldId: number): void => {
    setEditingFieldId(fieldId);
    reloadFields();
  };
  const handleOnUpdateField = (): void => {
    reloadFields();
    refreshProcessFlow();
  };
  const handleOnCloseFieldEditor = (): void => setEditingFieldId(null);

  const handleOnChangeMandatory = (result: OnChangeMandatoryParams): void => {
    const { editableField, position } = result;
    let newTriggerFields = [...triggerFields];
    newTriggerFields[position] = editableField;
    setTriggerFields(newTriggerFields);
    updateTriggerDetail({
      args: buildUpdateTriggersArgs(globalFields, newTriggerFields),
    });
  };
  const handleOnDeleteGlobalField = (field: Field): void => {
    const { IdField } = field;

    if (field.Type === "Cell") {
      deleteProcessVarDBGroup({
        args: {
          IdTeam,
          IdProcessTemplate: IdProcess,
          IdVarDBGroup: field.VardbGroupInfo.IdVarDBGroup,
        },
      });
    } else {
      deleteProcessField({
        args: {
          IdTeam,
          IdProcessTemplate: IdProcess,
          IdField,
        },
      });
    }
  };
  const handleOnDeleteProcessField = (
    { IdField }: Field,
    _IdFieldForm: number
  ): void => {
    const newTriggerFields = _.reject(triggerFields, { IdField });
    setTriggerFields(newTriggerFields);
    updateTriggerDetail({
      args: buildUpdateTriggersArgs(globalFields, newTriggerFields),
    });
  };

  return (
    <div style={{ flex: "1", display: "flex", overflow: "auto" }}>
      {editingFieldId !== null && (
        <EditField
          IdField={editingFieldId}
          onRegisterField={handleOnRegisterField}
          onUpdateField={handleOnUpdateField}
          onClose={handleOnCloseFieldEditor}
        />
      )}
      {!!fieldToDelete && (
        <DeleteFieldModal
          onDelete={() => handleOnDeleteGlobalField(fieldToDelete)}
          onCancel={() => setFieldToDelete(null)}
        />
      )}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Body style={{ padding: "20px", paddingTop: "0" }}>
          <SingleColumn>
            <Row justify="space-between" align="middle">
              <ColumnTitle>Campos del proceso</ColumnTitle>
              <Tooltip
                title="Arrastra los campos que quieras utilizar en este Formulario Publicable. En esta columna se muestran todos los campos que existen en el proceso, pero tú puedes elegir cuáles solicitar como evidencia."
                placement="bottomLeft"
              >
                <AiOutlineInfoCircle size={16} color="#48505E" />
              </Tooltip>
            </Row>
            <StyledDivider />
            <SearchBar
              type="text"
              placeholder="Buscar"
              autoComplete="off"
              style={{ width: "100%", marginBottom: "8px" }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Button
              type="secondary"
              size="fullwidth"
              Icon={AiOutlinePlus}
              style={{ marginBottom: "15px" }}
              onClick={handleOnClickAddField}
            >
              Nuevo campo
            </Button>
            <FieldsColumn droppableId="globals" droppableKey={1}>
              {filteredGlobalFields.map((field, idx) => (
                <DraggableField
                  draggableId={"G-" + field.IdField.toString()}
                  key={"G-" + field.IdField.toString()}
                  draggableIndex={idx}
                >
                  <FieldItem
                    column="global"
                    field={field}
                    onClick={handleOnClickField}
                    onRemove={() => setFieldToDelete(field)}
                  />
                </DraggableField>
              ))}
            </FieldsColumn>
          </SingleColumn>
          <SingleColumn>
            <Row justify="space-between" align="middle">
              <ColumnTitle>Campos del formulario</ColumnTitle>
            </Row>
            <StyledDivider />
            <FieldsColumn droppableId="editable" droppableKey={2}>
              {triggerFields.map((triggerField, idx) => {
                const field = getFieldFromGlobals(
                  globalFields,
                  triggerField.IdField
                );
                return (
                  <DraggableField
                    draggableId={"E-" + field.IdField.toString()}
                    key={"E-" + field.IdField.toString()}
                    draggableIndex={idx}
                  >
                    <FieldItem
                      column="editable"
                      field={field}
                      onClick={handleOnClickField}
                      isMandatory={triggerField.IsMandatory}
                      onChangeIsMandatory={(IsMandatory) =>
                        handleOnChangeMandatory({
                          editableField: { ...triggerField, IsMandatory },
                          field,
                          position: idx,
                        })
                      }
                      onRemove={(removedField) =>
                        handleOnDeleteProcessField(
                          removedField,
                          triggerField.IdFieldForm
                        )
                      }
                    />
                  </DraggableField>
                );
              })}
            </FieldsColumn>
          </SingleColumn>
        </Body>
      </DragDropContext>
    </div>
  );
};

export default EditTriggerFieldsView;
