import React, { useEffect, useRef } from "react";
import { nanoid } from "nanoid";
import styled from "styled-components";
import { Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Container = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;

const InputStyle = styled.input`
  border-radius: 6px;
  border: 2px solid #edecec;
  background-color: white;
  color: #828d9e;

  min-height: 30px;
  resize: none;
  padding: 6px 10px;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const TagStyle = styled(Tag)`
  background: #e4e4e4;
  border-style: solid;
  border: 1px grey;
  border-radius: 0.4rem;
  font-size: small;
  font-family: "Gotham-Light";
  color: grey;
  padding: 0.3rem 0.8rem;
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
`;

const NewTag = styled(Tag)`
  background: #fff;
  border-style: dashed;
  border: 2px #c7c7c7;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: smaller;
  font-family: "Gotham-Light";
  color: grey;
  padding: 0.2rem 0.8rem;
  margin-bottom: 0.4rem;
`;

const InputOpcStyle = styled.input`
  border-radius: 6px;
  border: 2px solid #edecec;
  background-color: white;
  color: #828d9e;

  width: 3rem;
  min-height: 30px;
  resize: none;
  padding: 6px 10px;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const Languages = {
  ENG: {
    AddNewOptionLabel: "Add new option",
    NoComaOnDataOrigin: "options cannot contain commas",
  },
  ESP: {
    AddNewOptionLabel: "Agregar nueva opción",
    NoComaOnDataOrigin: "las opciones no pueden contener comas",
  },
};

const shouldRenderTagOptions = (DataOrigin, ImportVarDBs) => {
  return DataOrigin.length > 0 && !ImportVarDBs;
};

const TagOptions = ({
  onDragEnd,
  DataOrigin,
  ImportVarDBs = false,
  IsOwnedByThisTask = true,
  handleOnClose,
  inputVisible,
  inputValue,
  setinputValue,
  handleInputConfirm,
  toggleInput,
  InputOpcVis,
  OpcValue,
  setOpcValue,
  toggleInputOpc,
  OpcIndex,
  setOpcIndex,
  handleInputOpcConfirm,
}) => {
  const { AddNewOptionLabel } = Languages["ESP"];
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef, inputVisible]);

  return (
    <Container>
      {IsOwnedByThisTask &&
        (inputVisible ? (
          <InputStyle
            AutoFocus
            ref={inputRef}
            value={inputValue}
            onChange={({ target: { value } }) => setinputValue(value)}
            onBlur={handleInputConfirm}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleInputConfirm();
            }}
          />
        ) : (
          <NewTag
            onClick={() => toggleInput(true)}
            style={{
              background: "#fff",
              borderStyle: "dashed",
              cursor: "pointer",
            }}
          >
            <PlusOutlined /> {AddNewOptionLabel}
          </NewTag>
        ))}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="1" direction="horizontal">
          {(provided) => (
            <TagsContainer ref={provided.innerRef} {...provided.droppableProps}>
              {shouldRenderTagOptions(DataOrigin, ImportVarDBs) &&
                DataOrigin.map((tag, index) => {
                  const key = nanoid();

                  return (
                    <Draggable key={key} draggableId={tag.label} index={index}>
                      {(provided) => (
                        <TagStyle
                          closable={IsOwnedByThisTask}
                          onClose={(e) => handleOnClose(e, index)}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {InputOpcVis && OpcIndex === index ? (
                            <InputOpcStyle
                              autoFocus
                              value={OpcValue}
                              onChange={({ target: { value } }) =>
                                setOpcValue(value)
                              }
                              onBlur={handleInputOpcConfirm}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") handleInputOpcConfirm();
                              }}
                            />
                          ) : (
                            <p
                              onClick={() => {
                                toggleInputOpc(true);
                                setOpcValue(tag.label);
                                setOpcIndex(index);
                              }}
                            >
                              {tag.label}
                            </p>
                          )}
                        </TagStyle>
                      )}
                    </Draggable>
                  );
                })}
            </TagsContainer>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

export default TagOptions;
