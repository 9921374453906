import styled from "styled-components";

export const ListContainer = styled.div`
  height: 100%;
  justify-content: center;
  display: flex;
`;

// welcome
export const WelcomeContainer = styled.div`
  height: 100%;
  display: flex;
  gap: 20px;
`;

export const WelcomeInformation = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  max-width: 50%;

  p {
    margin: 0;
    font-weight: 325;
    font-size: 14px;
    color: #828d9e;
  }
  .greetings {
    font-family: Gotham-Bold;
    color: #0273e9;
  }
  .title {
    font-family: Gotham-Bold;
    font-size: 24px;
    color: #48505e;
  }
`;

export const WelcomeImageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50%;

  img {
    width: 100%;
    height: auto;
    max-height: 80%;
  }
`;

// dashboard list
export const DasboardsContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

export const DashboardItem = styled.div`
  padding: 0 14px 28px;
`;

export const MastersClubCard = styled.div<{ width: string }>`
  position: relative;
  z-index: 2;
  background: white;
  border: 1px solid #0273e9;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 13px;

  width: ${({ width }) => width};
  height: 221px;
  padding: 20px;
  user-select: none;
  cursor: pointer;
`;

export const LogoContainer = styled.div`
  width: 38px;
  height: 38px;
  background: #0273e9;
  border-radius: 3px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 11px;
  font-family: Gotham-Medium;
`;

export const TeamInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 9px;

  p {
    margin: 0;
  }
  .title {
    font-size: 16px;
    color: #48505e;
  }
  .description {
    font-size: 12px;
    color: #828d9e;
  }
`;

export const GraphCard = styled.div<{ isDragging: boolean; width: string }>`
  position: relative;
  z-index: 2;
  background: white;
  border: 1px solid ${({ isDragging }) => (isDragging ? "#1f9334" : "#0273e9")};
  border-radius: 6px;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 13px;

  width: ${({ width }) => width};
  height: 221px;
  padding: 18px;
  user-select: none;
  cursor: pointer;

  transition: box-shadow 0.35s ease, border 0.35s ease;
  box-shadow: ${({ isDragging }) =>
    isDragging ? "4px 10px 6px 0px rgba(31, 147, 52, 0.3)" : "none"};
  :hover {
    box-shadow: 4px 10px 6px 0px rgba(2, 115, 233, 0.3);
  }
`;

export const GraphTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  p {
    margin: 0;
    font-size: 16px;
    color: #48505e;
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  jusitfy-content: center;
  border-radius: 2px;
  padding: 2px;
  background: white;
  transition: background 0.35s ease;
  :hover {
    background: #d9d9d9;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ContextMenuOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    margin: 0;
  }
`;
