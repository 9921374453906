import styled from "styled-components";
import dbGray from "../../../resources/img/Databases/icon_db_gray.svg";
import dbBlue from "../../../resources/img/Databases/icon_db_blue.svg";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const DatabaseTeamContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  padding-left: 25px;
  cursor: pointer;

  color: rgba(130, 141, 158, 1);
  font-size: 18px;
  font-family: Gotham-Bold;

  background-image: url(${dbGray});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px 20px;

  transition: background 0.35s ease, color 0.35s ease;
  :hover {
    color: rgba(1, 76, 155, 1);
    background-image: url(${dbBlue});
  }

  p {
    margin: 0;
  }
`;

export const NestedFoldersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SelectedFolderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  color: rgba(72, 80, 94, 1);
  font-size: 18px;
  font-family: Gotham-Bold;
`;

export const NestedFolder = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  color: rgba(130, 141, 158, 1);
  font-size: 18px;
  font-family: Gotham-Bold;

  transition: color 0.35s ease;
  :hover {
    color: rgba(1, 76, 155, 1);
  }
`;
