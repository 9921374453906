import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../components/Modal";
import { StyledInput } from "./styles";
import { Button } from "../../../GeestUI";
import { CreateBlankProcessProps } from "./CreateProcess.d";
import { Row } from "antd";
import { useMutation } from "../../../hooks";
import { Languages } from "../Dictionary";
import _ from "lodash";

const CreateBlankProcess: React.FC<CreateBlankProcessProps> = ({
  idTeam,
  closeModal,
  IdParentFolder,
  reload,
}) => {
  const [processName, setProcessName] = useState<string>("");
  const { CreateNewTemplateLabel, CreateLabel, ProcessNamePlaceholder } =
    Languages["ESP"];
  const [registerProcessTemplate, loading] = useMutation({
    func: "Ver2-Processes-rpt",
    onSuccess: () => {
      reload();
      closeModal();
    },
  });

  const textInput = useRef(null);

  useEffect(() => {
    if (textInput.current) {
      // @ts-ignore
      textInput.current.focus();
    }
  }, []);

  const onSubmit = () => {
    if (loading) return;
    registerProcessTemplate({
      args: {
        IdTeam: idTeam,
        IdParentFolder,
        IdProcessType: 1,
        Name: _.trim(processName),
      },
    });
  };

  return (
    <Modal
      visible
      width={"432"}
      bodyStyle={{ height: "158px" }}
      title={CreateNewTemplateLabel}
      onCancel={closeModal}
    >
      <Row justify="center" style={{ width: "100%" }}>
        <StyledInput
          value={processName}
          onChange={(e) => setProcessName(e.target.value)}
          type="text"
          onKeyDown={(e) => {
            if (e.key === "Enter" && !!processName) {
              onSubmit();
            }
          }}
          placeholder={ProcessNamePlaceholder}
          autoFocus
          ref={textInput}
        />
      </Row>
      <Row justify="center">
        <Button
          loading={loading}
          type="primary"
          onClick={onSubmit}
          disabled={!processName}
        >
          {CreateLabel}
        </Button>
      </Row>
    </Modal>
  );
};

export default CreateBlankProcess;
