export const Languages = {
  ENG: {
    ModalTitle: "Delete",
    SureLabel: "Are you sure you want to delete this graph?",
    IrreversibleLabel:
      "This action is irreversible and it will delete the graph. If you are sure you wish to continue please write the name of the graph:",
    InputPlaceholder: "Write here...",
    CancelLabel: "Cancel",
    DeleteLabel: "Delete",
  },
  ESP: {
    ModalTitle: "Eliminar",
    SureLabel: "¿Seguro que deseas eliminar esta gráfica?",
    IrreversibleLabel:
      "Esta acción es irreversible y eliminará permanentemente la gráfica. Si estás seguro que deseas continuar por favor ingrese el nombre de la gráfica:",
    InputPlaceholder: "Escribe aquí...",
    CancelLabel: "Cancelar",
    DeleteLabel: "Eliminar",
  },
};
