import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";
import "chartjs-adapter-date-fns";
import { es } from "date-fns/locale";
import {
  Chart as ChartJS,
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { GraphConfiguration, GraphRenderInfo } from "../../Dashboards.d";
import { getSymbolByCode } from "../../../../components/DynamicInput/Components/helpers/CurrencyHelpers";

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const GraphTypes = {
  MASTER: 0,
  DURATIONS: 1,
  SELLS: 2,
  KPI: 3,
  FUNNEL: 4,
};

interface StackBarProps {
  config: GraphConfiguration;
  renderInfo: GraphRenderInfo;
  selectedTemplate: number;
  onClickBar: (event: any, chartRef: any, renderInfo: GraphRenderInfo) => void;
}

const StackBar: React.FC<StackBarProps> = ({
  config,
  renderInfo,
  onClickBar,
  selectedTemplate,
}) => {
  const chartRef = useRef();

  const getOptions = () => {
    let options: any = {
      indexAxis: config?.Axis ? config.Axis ?? ("y" as const) : ("x" as const),
      plugins: {
        legend: {
          position: "bottom" as const,
          align: "start",
          labels: {
            font: {
              size: 11,
            },
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "y" as const,
          },
          zoom: {
            pinch: { enabled: true },
            wheel: { enabled: true },
            mode: "y" as const,
          },
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          suggestedMin: 50,
          suggestedMax: 100,
          grace: "5%",
        },
      },
    };

    if (selectedTemplate === GraphTypes.SELLS) {
      options.scales.x.type = "time";

      options.scales.x.time = {
        unit: config?.TimeUnit ?? "month",
        tooltipFormat: "d MMM, yyyy",
        displayFormats: {
          day: "d MMM",
        },
      };

      options.scales.x.adapters = {
        date: {
          locale: es,
        },
      };

      options.plugins.tooltip = {
        callbacks: {
          label: function (context: any) {
            const format = context.dataset.metaData[0] ?? "MXN";
            const symbol = getSymbolByCode(format);
            let label = context.dataset.label || "";

            if (label) {
              label += `: ${symbol}`;
            }
            if (context.formattedValue !== null) {
              label += context.formattedValue;
            }

            return label;
          },
        },
      };
    }
    return options;
  };

  return (
    <Bar
      ref={chartRef}
      // @ts-ignore
      options={getOptions()}
      data={renderInfo}
      onClick={(e) => {
        if (selectedTemplate === GraphTypes.DURATIONS) {
          onClickBar(e, chartRef, renderInfo);
        }
      }}
    />
  );
};

export default StackBar;
