import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  EditIcon,
  ConfirmIcon,
  DeleteIcon,
  CancelIcon,
} from "../../../components/hoverIcons";
import { useMutation } from "../../../hooks";
import DeleteReportModal from "./Popups/DeleteReportModal";

const Input = styled.input`
  background-color: #ffffff;
  width: 115px;
  height: 32px;
  padding-left: 6px;

  border-radius: 6px;
  outline: none;
  border: 2px solid rgba(237, 236, 236, 1);
  font-size: 14px;
  font-weight: 325;
  color: rgba(130, 141, 158, 1);

  ::placeholder {
    color: rgba(130, 141, 158, 1);
  }

  transition: all 0.35s ease;
  :hover {
    border-color: rgba(2, 115, 233, 1);
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const OptionContainer = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 100%;

  p {
    margin: 0;
    word-wrap: break-word;
    max-width: calc(100% - 25px);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 32px;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 2px;

  :hover {
    background-color: #d9d9d9;
  }
`;

interface ReportItemProps {
  Id: number;
  Name: string;
  reloadReports: () => void;
  setEditingReport: (value: boolean) => void;
  IdTeam: number;
}

const ReportItem: React.FC<ReportItemProps> = ({
  Id,
  Name,
  reloadReports,
  setEditingReport,
  IdTeam,
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>(Name);
  const [deleting, setDeleting] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);

  const [updatePipelineReportName] = useMutation({
    func: "Ver2-Pipeline-uprn",
    onSuccess: reloadReports,
  });

  const onConfirm = () => {
    if (name !== Name) {
      updatePipelineReportName({
        args: {
          IdReport: Id,
          IdTeam,
          Name: name,
        },
      });
    }
    setEditing(false);
  };

  useEffect(() => {
    if (editing) {
      setName(Name);
      ref?.current?.focus();
    }
  }, [editing, Name]);

  return (
    <Container>
      {deleting && (
        <div onClick={(e) => e.stopPropagation()}>
          <DeleteReportModal
            onSuccess={reloadReports}
            IdReport={Id}
            IdTeam={IdTeam}
            onCancel={() => setDeleting(false)}
          />
        </div>
      )}
      {editing ? (
        <>
          <Input
            value={name}
            onChange={(e) => {
              if (e.target.value.length >= 35) return;
              setName(e.target.value);
            }}
            ref={ref}
            onFocus={(e) => e.target.select()}
            onClick={(e) => e.stopPropagation()}
          />
          <IconsContainer>
            <IconContainer>
              <DeleteIcon
                size={20}
                filled
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleting(true);
                }}
              />
            </IconContainer>
            <IconContainer>
              <CancelIcon
                size={20}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditing(false);
                  setEditingReport(false);
                }}
              />
            </IconContainer>
            <IconContainer>
              <ConfirmIcon
                size={26}
                onClick={(e) => {
                  e.stopPropagation();
                  onConfirm();
                }}
              />
            </IconContainer>
          </IconsContainer>
        </>
      ) : (
        <OptionContainer>
          <p>{Name}</p>
          <EditIcon
            filled
            // @ts-ignore
            hoverContainerColor="d9d9d9"
            onClick={(e) => {
              e.stopPropagation();
              setEditing(true);
              setEditingReport(true);
            }}
          />
        </OptionContainer>
      )}
    </Container>
  );
};

export default ReportItem;
