import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { Row } from "antd";
import { useFetch, useMutation } from "../../../../../../../hooks";
import baseURL from "../../../../../../../api/baseURL";
import { Languages } from "../../Dictionary";
import { MessagesContext } from "../../../../../../../components/AppMessages";
import {
  AiOutlineEye,
  AiOutlineInfoCircle,
  AiOutlineUpload,
} from "react-icons/ai";
import {
  GetTriggerFormResponse,
  TabData,
  FormMetaData,
  Fields,
  ArgsForUpdateForm,
} from "../../TriggersConfig";
import {
  FormData,
  FormTriggerContent,
  FormTriggerDescription,
  FormTriggerExtraData,
  FormTriggerMetaData,
  FormTriggerTitle,
  InputStyled,
  TriggerFormTitle,
  ViewContentFormTrigger,
  TabContainer,
  TabWrapper,
  TabContent,
  TabTitle,
  UploadFaviconButton,
  CenterItem,
  TabTitleInput,
  TabTitleLabel,
  TabBottom,
  FormPanel,
  StyledTextField,
  LinkContainer,
  FormDescriptionWrapper,
  FormMetaDataWrapper,
} from "../Style";
import formTriggerIcon from "../../../../../../../resources/img/formTriggerIcon.svg";
import {
  EditIcon,
  CloseIcon,
  CopyIcon,
} from "../../../../../../../components/hoverIcons";
import { Button } from "../../../../../../../GeestUI";
import { HiMenuAlt2 } from "react-icons/hi";
import GeestTextEditor from "../../../../../../../components/GeestTextEditor";
import { editorStateRaw } from "../../../../../../../components/GeestTextEditor/Utils/Editor";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
import { uploadFileToAWS } from "../../../../../../../aws/s3Client";
import { ConfiguratorContext } from "../../../../../Configurator";
import GeestSelect from "../../../../../../../GeestUI/GeestSelect";
import { useParams } from "react-router-dom";

const {
  FormTriggerTitleLabel,
  PreviewButtonLabel,
  SuccessCopy,
  FormTitleLabel,
  EmtpyTitleError,
} = Languages["ESP"];

interface FormTriggerViewProps {
  IdTeam: string;
  IdPrepublishTrigger: string;
  setFields: (fields: Fields) => void;
  setArgs: (args: ArgsForUpdateForm) => void;
  setView: (newView: string) => void;
  filesPath: string;
  setFilesPath: (filesPath: string) => void;
  reloadHome: () => void;
  Label?: string;
}

const defaultTab: TabData = { Label: "", FavIcon: "" };
const defaultForm: FormMetaData = {
  Label: "",
  Description: "",
  Logo: "",
  Background: "",
};

const encode = (IdPrepublishTrigger: string) => {
  const encoded = btoa(JSON.stringify({ IdPrepublishTrigger }));
  return `${baseURL}#/trigger/?pb=${encoded}`;
};

const FormTriggerView: React.FC<FormTriggerViewProps> = ({
  IdTeam,
  IdPrepublishTrigger,
  setFields,
  setArgs,
  setView,
  filesPath,
  setFilesPath,
  reloadHome,
  Label,
}) => {
  const [title, setTitle] = useState<string>("");
  const [tabInfo, setTabInfo] = useState<TabData>(defaultTab);
  const [formData, setFormData] = useState<FormMetaData>(defaultForm);
  const [publishLink, setPublishLink] = useState<string>("");
  const prepublishLink = encode(IdPrepublishTrigger);
  const [editingTabTitle, setEditingTabTitle] = useState<boolean>(false);
  const [editingTitle, setEditingTitle] = useState<boolean>(false);
  const [redirectLink, setRedirectLink] = useState<string | undefined>();
  const [textEditorFocus, setTextEditorFocus] = useState<string>("");
  const [formTitleEditor, setFormTitleEditor] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [formDescriptionEditor, setFormDescriptionEditor] =
    useState<EditorState>(EditorState.createEmpty());
  const { showMessage } = useContext(MessagesContext);
  const { refreshProcessFlow } = useContext(ConfiguratorContext);
  const [logoType, setLogoType] = useState<string>("");
  const { IdProcess: IdProcessTemplate } = useParams<{ IdProcess: string }>();

  const iframeContent = `<iframe\n    title="${title}"\n    width="300"\n    height="200"\n    src="${publishLink}"\n></iframe>`;

  const { data, reload } = useFetch<GetTriggerFormResponse>({
    func: "Ver2-Configurator-gtd",
    args: {
      IdTeam,
      IdPrepublishTrigger,
      IdProcessTemplate,
    },
    onSuccess: (response) => {
      setRedirectLink(response.RedirectLink);
      setTabInfo(response.Configuration.Tab);
      setFormData(response.Configuration.Form);
      if (response.Configuration.Form.Logo === "teamLogo") {
        setLogoType("team");
      } else if (!!response.Configuration.Form.Logo) {
        setLogoType("url");
      }
      setPublishLink(response.TriggerLink);
      setFilesPath(response.FilesPath);
      setFields(response.Fields);
      if (typeof response.Configuration.Form.Label === "object") {
        setFormTitleEditor(
          EditorState.createWithContent(
            convertFromRaw(response.Configuration.Form.Label)
          )
        );
      } else {
        const blocksFromHtml = convertFromHTML(
          response.Configuration.Form.Label.replaceAll("x22", '"')
        );
        const state = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        );
        setFormTitleEditor(EditorState.createWithContent(state));
      }
      if (typeof response.Configuration.Form.Description === "object") {
        setFormDescriptionEditor(
          EditorState.createWithContent(
            convertFromRaw(response.Configuration.Form.Description)
          )
        );
      } else {
        const blocksFromHtml = convertFromHTML(
          response.Configuration.Form.Description.replaceAll("x22", '"')
        );
        const state = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        );
        setFormDescriptionEditor(EditorState.createWithContent(state));
      }
    },
  });

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IdPrepublishTrigger]);

  useEffect(() => {
    setTitle(Label || "");
  }, [Label]);

  const [updateTriggerDetailInformation] = useMutation({
    func: "Ver2-Configurator-utdi",
    onSuccess: () => {
      reloadHome();
      refreshProcessFlow();
    },
  });

  const openPrepublish = () => {
    window.open(prepublishLink, "_blank", "noreferrer");
  };

  const handleSubmit = (updating: string = "", newImage: string = "") => {
    setTextEditorFocus("");
    let argsObj = {
      IdTeam,
      IdPrepublishTrigger,
      Type: data?.Type,
      Label: title,
      TriggerLink: publishLink,
      RedirectLink: redirectLink,
      Configuration: {
        Tab: tabInfo,
        Form: formData,
      },
      TriggerFields: data?.Fields.TriggerFields || [],
    };

    switch (updating) {
      case "favicon":
        argsObj.Configuration.Tab.FavIcon = newImage;
        break;
      case "logo":
        argsObj.Configuration.Form.Logo = newImage;
        break;
      case "background":
        argsObj.Configuration.Form.Background = newImage;
        break;
    }

    updateTriggerDetailInformation({ args: argsObj });
  };

  const handleOpenEdit = () => {
    setArgs({
      IdTeam,
      IdPrepublishTrigger,
      Type: data?.Type || "",
      Label: title,
      TriggerLink: publishLink,
      Configuration: {
        Tab: tabInfo,
        Form: formData,
      },
      RedirectLink: redirectLink,
    });
    setView("editFields");
  };

  const handleOnChangeTitle = () => {
    const trimTitle = _.trim(title);
    const isTitleEmpty = _.isEmpty(trimTitle);
    const isSameTitle = _.isEqual(trimTitle, data?.Label || "");
    setEditingTitle(false);
    if (isTitleEmpty || isSameTitle) return;
    handleSubmit();
  };

  const handleOnChangeTabTitle = () => {
    setEditingTabTitle(false);
    const trimTitle = _.trim(tabInfo.Label);
    const isTitleEmpty = _.isEmpty(trimTitle);
    const isSameTitle = _.isEqual(
      trimTitle,
      data?.Configuration.Tab.Label || ""
    );
    if (isTitleEmpty || isSameTitle) return;
    handleSubmit();
  };

  const handleOnChangeFormTitle = () => {
    setTextEditorFocus("");
    let trimTitle = "";
    let isTitleEmpty = false;
    if (typeof formData.Label === "string") {
      trimTitle = _.trim(formData.Label);
      isTitleEmpty = _.isEmpty(trimTitle);
    } else {
      trimTitle = _.trim(formData.Label.blocks[0].text);
      isTitleEmpty = formData.Label.blocks.length === 1 && _.isEmpty(trimTitle);
    }

    const isSameTitle = _.isEqual(
      trimTitle,
      data?.Configuration.Form.Label || ""
    );
    if (isTitleEmpty) {
      showMessage(EmtpyTitleError, "error");
      setFormData({ ...formData, Label: data?.Configuration.Form.Label || "" });
    }

    if (isTitleEmpty || isSameTitle) return;
    handleSubmit();
  };

  const handleCopy = (element: string) => {
    navigator.clipboard.writeText(element);
    showMessage(SuccessCopy, "success");
  };

  const onUpload = (id: string) => {
    const inputElement = document.getElementById(id);
    inputElement?.click();
  };

  const handleUploadFavicon = async ({ target }: any) => {
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      const extension = _.last(file.name.split("."));
      const filePath = await uploadFileToAWS(
        file,
        filesPath,
        "favicon." + extension
      );
      if (filePath) {
        const newTabInfo: TabData = {
          Label: tabInfo.Label,
          FavIcon: filePath,
        };
        setTabInfo(newTabInfo);
        handleSubmit("favicon", filePath);
      } else console.error("Upload failed");
    }
  };

  const handleUploadLogo = async ({ target }: any) => {
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      const extension = _.last(file.name.split("."));
      const filePath = await uploadFileToAWS(
        file,
        filesPath,
        "form_logo." + extension
      );
      if (filePath) {
        let newFormData = { ...formData, Logo: filePath };
        setFormData(newFormData);
        handleSubmit("logo", filePath);
      } else console.error("Upload failed");
    }
  };

  const handleUploadBackground = async ({ target }: any) => {
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      const extension = _.last(file.name.split("."));
      const filePath = await uploadFileToAWS(
        file,
        filesPath,
        "form_background." + extension
      );
      if (filePath) {
        let newFormData = { ...formData, Background: filePath };
        setFormData(newFormData);
        handleSubmit("background", filePath);
      } else console.error("Upload failed");
    }
  };

  const onChangeFormTitle = (editorState: EditorState) => {
    setFormTitleEditor(editorState);
    const raw = editorStateRaw(editorState);
    let newFormData = { ...formData, Label: raw };
    setFormData(newFormData);
  };

  const onChangeFormDescription = (editorState: EditorState) => {
    setFormDescriptionEditor(editorState);
    const raw = editorStateRaw(editorState);
    let newFormData = { ...formData, Description: raw };
    setFormData(newFormData);
  };

  const options = [
    {
      label: "Usar logotipo de mi empresa",
      value: "team",
    },
    {
      label: "Subir logotipo",
      value: "url",
    },
  ];

  return (
    <ViewContentFormTrigger>
      <FormTriggerMetaData>
        <Row>
          <img src={formTriggerIcon} width="31px" height="31px" alt="" />

          <FormTriggerContent>
            {editingTitle ? (
              <InputStyled
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={handleOnChangeTitle}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleOnChangeTitle();
                  }
                }}
                onFocus={(e) => e.target.select()}
                autoFocus
              />
            ) : (
              <Row align="bottom">
                <FormTriggerTitle>{title}</FormTriggerTitle>
                <EditIcon
                  size={32}
                  filled
                  onClick={() => setEditingTitle(true)}
                />
              </Row>
            )}

            <FormTriggerDescription>
              {FormTriggerTitleLabel}
            </FormTriggerDescription>
          </FormTriggerContent>
        </Row>

        <FormTriggerExtraData>
          <Button size="large" Icon={AiOutlineEye} onClick={openPrepublish}>
            {PreviewButtonLabel}
          </Button>
        </FormTriggerExtraData>
      </FormTriggerMetaData>

      <FormData>
        <Row
          align="bottom"
          justify="space-between"
          style={{ width: "100%", marginBottom: "10px" }}
        >
          <TriggerFormTitle>
            Configuración de la pestaña del formulario
          </TriggerFormTitle>
          <TabContainer>
            <TabWrapper>
              <TabContent>
                <TabTitle>
                  <UploadFaviconButton
                    onClick={() => onUpload("uploadFavicon")}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      id="uploadFavicon"
                      onChange={handleUploadFavicon}
                      style={{ display: "none" }}
                    />
                    {tabInfo.FavIcon ? (
                      <img
                        src={tabInfo.FavIcon}
                        width="16px"
                        height="16px"
                        alt=""
                      />
                    ) : (
                      <AiOutlineUpload size={16} />
                    )}
                  </UploadFaviconButton>
                  {editingTabTitle ? (
                    <TabTitleInput
                      autoFocus
                      value={tabInfo.Label}
                      onChange={(e) =>
                        setTabInfo({ ...tabInfo, Label: e.target.value })
                      }
                      onBlur={handleOnChangeTabTitle}
                    />
                  ) : (
                    <TabTitleLabel>
                      {tabInfo.Label.length > 20
                        ? _.truncate(tabInfo.Label, { length: 20 })
                        : tabInfo.Label}
                    </TabTitleLabel>
                  )}
                </TabTitle>
                <CenterItem>
                  {editingTabTitle ? (
                    <CloseIcon
                      onClick={() => setEditingTabTitle(false)}
                      color="white"
                      size={25}
                    />
                  ) : (
                    <EditIcon
                      onClick={() => setEditingTabTitle(true)}
                      color="white"
                      filled
                    />
                  )}
                </CenterItem>
              </TabContent>
              <TabBottom />
            </TabWrapper>
          </TabContainer>
        </Row>
        <FormMetaDataWrapper
          editorSelected={textEditorFocus === "1"}
          justify="space-between"
        >
          <FormPanel
            style={{ width: "calc(100% - 198px)" }}
            editorSelected={textEditorFocus === "1"}
          >
            <TriggerFormTitle>{FormTitleLabel}</TriggerFormTitle>
            <GeestTextEditor
              editorState={formTitleEditor}
              onBlur={handleOnChangeFormTitle}
              onEditorStateChange={onChangeFormTitle}
              toolbarConfig={{
                list: false,
                link: false,
                textalign: false,
              }}
              editorBorder
              popUpToolbar
              onFocus={() => setTextEditorFocus("1")}
            />
          </FormPanel>
          <FormPanel style={{ width: "190px" }}>
            <TriggerFormTitle>Agrega tu fondo</TriggerFormTitle>
            <Button
              type="secondary"
              size="fullwidth"
              onClick={() => onUpload("uploadBackground")}
              Icon={formData.Background ? undefined : AiOutlineUpload}
            >
              <input
                type="file"
                accept="image/*"
                id="uploadBackground"
                onChange={handleUploadBackground}
                style={{ display: "none" }}
              />
              {formData.Background ? (
                <>
                  <img
                    width="20px"
                    height="20px"
                    alt=""
                    src={formData.Background}
                    style={{ borderRadius: "50%", marginRight: "5px" }}
                  />
                  Tu fondo
                </>
              ) : (
                "Subir fondo"
              )}
            </Button>
          </FormPanel>
        </FormMetaDataWrapper>
        <div>
          <TriggerFormTitle>Agrega logotipo</TriggerFormTitle>
          <GeestSelect
            value={logoType}
            onChange={(val) => {
              if (val === "team") {
                let newFormData = { ...formData, Logo: "teamLogo" };
                setFormData(newFormData);
                handleSubmit("logo", "teamLogo");
              } else {
                let newFormData = { ...formData, Logo: "" };
                setFormData(newFormData);
                handleSubmit("logo", "");
              }
              setLogoType(val);
            }}
            options={options}
          />
          {logoType === "url" && (
            <Button
              type="secondary"
              style={{ width: "190px", marginTop: "6px" }}
              onClick={() => onUpload("uploadLogo")}
              Icon={formData.Logo ? undefined : AiOutlineUpload}
            >
              <input
                type="file"
                accept="image/*"
                id="uploadLogo"
                onChange={handleUploadLogo}
                style={{ display: "none" }}
              />
              {formData.Logo ? (
                <>
                  <img
                    width="20px"
                    height="20px"
                    alt=""
                    src={formData.Logo}
                    style={{ borderRadius: "50%", marginRight: "5px" }}
                  />
                  Tu logo
                </>
              ) : (
                "Subir logo"
              )}
            </Button>
          )}
        </div>
        <FormDescriptionWrapper editorSelected={textEditorFocus === "2"}>
          <TriggerFormTitle>Descripción del formulario</TriggerFormTitle>
          <GeestTextEditor
            editorState={formDescriptionEditor}
            onEditorStateChange={onChangeFormDescription}
            onBlur={handleSubmit}
            toolbarConfig={{
              list: false,
              link: false,
              textalign: false,
            }}
            editorBorder
            popUpToolbar
            onFocus={() => setTextEditorFocus("2")}
          />
        </FormDescriptionWrapper>
        <Row style={{ width: "600px" }} align="middle" justify="space-between">
          <div style={{ width: "356px" }}>
            <TriggerFormTitle>
              Configura los campos a solicitar en tu formulario
            </TriggerFormTitle>
            {!publishLink && (
              <Row>
                <AiOutlineInfoCircle size={16} />
                <div
                  style={{
                    fontSize: "12px",
                    fontFamily: "Gotham-Book",
                    color: "rgba(72, 80, 94, 1)",
                    fontWeight: 325,
                    width: "337px",
                    marginLeft: "2px",
                  }}
                >
                  Si deseas generar un link para compartir tu plantilla y un
                  código embebido, primero debes publicar tu plantilla
                </div>
              </Row>
            )}
          </div>
          <Button
            Icon={HiMenuAlt2}
            onClick={() => handleOpenEdit()}
            type="secondary"
            style={{ width: "219px" }}
          >
            Campos de formulario
          </Button>
        </Row>
        <div>
          <TriggerFormTitle>
            Al enviar formulario redireccionar a
          </TriggerFormTitle>
          <StyledTextField
            placeholder="https://..."
            value={redirectLink}
            onChange={(e) => setRedirectLink(e.target.value)}
            onBlur={() => handleSubmit()}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          />
        </div>
        {!!publishLink && (
          <Row style={{ width: "100%" }} justify="space-between">
            <div style={{ width: "calc(50% - 7px)" }}>
              <TriggerFormTitle>Copia este link</TriggerFormTitle>
              <LinkContainer>
                <div style={{ width: "calc(100% - 25px)" }}>
                  <p>{publishLink}</p>
                </div>
                <CopyIcon onClick={() => handleCopy(publishLink)} />
              </LinkContainer>
            </div>
            <div style={{ width: "calc(50% - 7px)" }}>
              <TriggerFormTitle>Copia el código embebido</TriggerFormTitle>
              <LinkContainer>
                <div style={{ width: "calc(100% - 25px)" }}>
                  {iframeContent?.split("\n")?.map((line, i) => (
                    <p
                      key={i}
                      style={{
                        margin: 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "pre",
                      }}
                    >
                      {line}
                    </p>
                  ))}
                </div>
                <CopyIcon onClick={() => handleCopy(iframeContent)} />
              </LinkContainer>
            </div>
          </Row>
        )}
      </FormData>
    </ViewContentFormTrigger>
  );
};

export default FormTriggerView;
