import React from "react";
import { Row } from "antd";
import Modal from "../Modal";
import Button from "../Button";

const Languages = {
  ENG: {
    Cancel: "Cancel",
    Confirm: "Confirm",
  },
  ESP: {
    Cancel: "Cancelar",
    Confirm: "Confirmar",
  },
};

const ConfirmOverlay = ({
  closeOverlay,
  isOpen,
  text,
  cancelButtonText = "",
  confirmButtonText = "",
  width = "16rem",
}) => {
  const { Cancel, Confirm } = Languages["ESP"];

  const cancelText = cancelButtonText || Cancel;
  const confirmText = confirmButtonText || Confirm;

  return (
    <Modal
      visible={isOpen}
      onCancel={() => closeOverlay(false)}
      footer={null}
      centered
      width={width}
    >
      <div
        style={{
          fontSize: "28px",
          textAlign: "center",
          fontFamily: "Gotham-Bold",
          marginBottom: "1rem",
        }}
      >
        {text}
      </div>
      <Row type="flex" justify="space-between">
        <Button
          onClick={() => closeOverlay(false)}
          style={{ width: "calc(50% - 0.25rem)" }}
        >
          {cancelText}
        </Button>
        <Button
          onClick={() => closeOverlay(true)}
          type="primary"
          style={{ width: "calc(50% - 0.25rem)" }}
        >
          {confirmText}
        </Button>
      </Row>
    </Modal>
  );
};

export default ConfirmOverlay;
