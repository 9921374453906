import React, { useState } from "react";
import styled from "styled-components";
import { Button, Typography } from "../../../GeestUI";
import DataTypeIcons from "../../../components/DataTypeIcons";
import DynamicInput from "../../../components/DynamicInput";
import { UserType } from "../../../components/DynamicInput/DynamicInput.d";
import {
  ElementType,
  FieldType,
} from "../../../views/Templates/StartTemplate/StartTemplate.d";

import placeholderData from "./placeholderData.json";

const { H1, H3, B } = Typography;

interface VarDBDataOrigin {
  IdRow: number;
  Label: string | null;
}

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  padding: 0.5rem 0;
`;

const DynamicInputsContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const DynamicInputBox = styled.div<{ background: boolean }>`
  background: ${({ background = false }) => (background ? "salmon" : "white")};
  flex: 1;
  max-width: calc(25% - 1rem);
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 0.6px 0.6px 2.2px rgba(0, 0, 0, 0.02),
    1.3px 1.3px 5.3px rgba(0, 0, 0, 0.028),
    2.5px 2.5px 10px rgba(0, 0, 0, 0.035),
    4.5px 4.5px 17.9px rgba(0, 0, 0, 0.042),
    8.4px 8.4px 33.4px rgba(0, 0, 0, 0.05), 20px 20px 80px rgba(0, 0, 0, 0.07);
`;

const InputTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    margin: 0;
    color: #48505e;
    font-size: 14px;
  }
`;

const VerticalDivisor = styled.div`
  content: "";
  background-color: #ccc;
  width: 1px;
`;

const HorizontalDivisor = styled.div`
  content: "";
  background-color: #ccc;
  height: 1px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const cellsData = [
  {
    Configuration: null,
    DataOrigin: null,
    DataType: "string",
    FillAtStart: true,
    Format: "",
    IdField: 724,
    IdValue: null,
    IdVarDBColumn: 149,
    IsEditable: true,
    IsMandatory: false,
    Label: "db string",
    Position: 1,
    Type: "Cell",
    Value: null,
  },
];

const DynamicInputCaller: React.FC = () => {
  const [globalData, setGlobalData] = useState(placeholderData.globalData);
  const [cells, setCells] = useState(cellsData);
  const [background, setBackground] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [required, setRequired] = useState<boolean>(false);
  const [oneLineString, setonelineString] = useState<boolean>(true);
  const [showFormat, setShowFormat] = useState<boolean>(true);
  const [showNumberLabel, setShowNumberLabel] = useState<boolean>(false);
  const [canAddOptions, setCanAddOptions] = useState<boolean>(false);
  const [center, setCenter] = useState<boolean>(false);

  const [checklistElements, setChecklistElements] = useState<ElementType[]>([
    {
      Checked: true,
      Element: "_Elemento 1_ :star:",
      IdElement: "10",
      Position: 1,
    },
  ]);

  const globalColumns = [
    { title: "Global input", isDB: false },
    { title: "Global consult value", isDB: false },
  ];

  const dbColumns = [
    { title: "DB input", isDB: true },
    { title: "DB consult value", isDB: true },
  ];

  const userSelectDataOrigin: UserType[] = [
    {
      Email: "atencion@GeestTesting.app",
      FirstName: "David",
      IdUser: 2,
      Initials: "DC",
      LastName: "Castellanos",
      ProfilePicture:
        "https://geest-ver2-prod.s3-us-east-2.amazonaws.com/Users/ProfilePictures/User-2/29581bdc-fe7c-438f-8b78-5a590825f019/Profile image.png",
    },
    {
      Email: "g.r.luisdaniel@GeestTesting.app",
      FirstName: "Luis Daniel",
      IdUser: 4,
      Initials: "LG",
      LastName: "Guerra Rosales",
      ProfilePicture:
        "https://geest-ver2-prod.s3-us-east-2.amazonaws.com/Users/ProfilePictures/User-4/cdcbbe65-5967-4cee-9697-9a038717c231/Profile image.png",
    },
    {
      Email: "naitvenefy@GeestTesting.app",
      FirstName: "Kevin Enrique",
      IdUser: 3,
      Initials: "KL",
      LastName: "Loera Salazar",
      ProfilePicture:
        "https://geest-ver2-prod.s3-us-east-2.amazonaws.com/Users/ProfilePictures/User-3/ad8c6a50-1ce8-488a-a9bf-a5127caddb55/Profile image.png",
    },
    {
      Email: "chivasricky96@GeestTesting.app",
      FirstName: "Ricardo",
      IdUser: 5,
      Initials: "RG",
      LastName: "Gutiérrez Barba",
      ProfilePicture:
        "https://geest-ver2-prod.s3-us-east-2.amazonaws.com/Users/ProfilePictures/User-5/f32b2e9e-a48f-46ec-ad12-af1afa1bb078/Profile image.png",
    },
    {
      Email: "ventas1@GeestTesting.app",
      FirstName: "Ventas",
      IdUser: 11,
      Initials: "VG",
      LastName: "Geest",
      ProfilePicture:
        "https://geest-ver2-prod.s3-us-east-2.amazonaws.com/Users/ProfilePictures/User-11/c50fcc6a-e78b-429e-b799-8dc4ea55e99b/Profile image.png",
    },
    {
      Email: "email@example.com",
      FirstName: "Sin",
      IdUser: 42,
      Initials: "SF",
      LastName: "Foto",
    },
  ];

  const varDBDataOrigin = [
    { IdRow: 0, Label: "Nuevo" },
    { IdRow: 1, Label: 1 },
  ] as VarDBDataOrigin[];

  const handleOnChange = (idx: number) => {
    return (newVal: any) => {
      let aux = [...globalData];
      aux[idx].value = newVal;
      setGlobalData(aux);
    };
  };

  const handleOnChangeFormat = (idx: number) => {
    return (newFormat: any) => {
      let aux = [...globalData];
      aux[idx].format = newFormat;
      setGlobalData(aux);
    };
  };

  const handleOnChangeCells = (newCells: any) => {
    setCells(newCells);
  };

  const getConfiguration = (type: string, configuration: string | null) => {
    switch (type) {
      case "select":
        return canAddOptions ? "CanAddOptions" : "CannotAddOptions";
      case "multi_select":
        return canAddOptions ? "CanAddOptions" : "CannotAddOptions";

      default:
        return configuration ?? "";
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Title>
        <H1 mb="0">
          <B>Dynamic Input</B>
        </H1>
      </Title>
      <ButtonsContainer>
        <B style={{ color: "var(--dark-gray)" }}>Actual status:</B>
        <Button type="default" onClick={() => setBackground(!background)}>
          background
        </Button>
        <Button type="default" onClick={() => setDisabled(!disabled)}>
          {disabled ? "disabled" : "enabled"}
        </Button>
        <Button type="default" onClick={() => setRequired(!required)}>
          {required ? "required" : "not required"}
        </Button>
        <Button type="default" onClick={() => setonelineString(!oneLineString)}>
          {oneLineString ? "one line string" : "multi line string"}
        </Button>
        <Button type="default" onClick={() => setShowFormat(!showFormat)}>
          {showFormat ? "with format" : "without format"}
        </Button>
        <Button
          type="default"
          onClick={() => setShowNumberLabel(!showNumberLabel)}
        >
          {showNumberLabel ? "with num label" : "without num label"}
        </Button>
        <Button type="default" onClick={() => setCanAddOptions(!canAddOptions)}>
          {canAddOptions ? "CanAddOptions" : "CannotAddOptions"}
        </Button>
        <Button type="default" onClick={() => setCenter(!center)}>
          {center ? "justify center" : "justify start"}
        </Button>
      </ButtonsContainer>
      <DynamicInputsContainer>
        {globalColumns.map((c, i) => (
          <DynamicInputBox key={i} background={background}>
            <H3>
              <B>{c.title}</B>
            </H3>
            <Content>
              {globalData.map((d, j) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                  }}
                >
                  <InputTitleContainer>
                    <div className="icon-container">
                      {DataTypeIcons[d.type] && (
                        <img src={DataTypeIcons[d.type].icon} alt="" />
                      )}
                    </div>
                    <p>{d.type}</p>
                  </InputTitleContainer>
                  <DynamicInput
                    key={`global-${i}-${j}`}
                    value={d.value}
                    type={d.type}
                    dataOrigin={d.dataOrigin}
                    format={d.format ?? ""}
                    fieldName=""
                    configuration={
                      i !== 1 ? getConfiguration(d.type, d.configuration) : ""
                    }
                    isConsult={i === 1 ? d.isConsult : false}
                    disabled={i === 1 ? false : disabled}
                    required={i === 1 ? false : required}
                    userSelectDataOrigin={userSelectDataOrigin}
                    VarDBTitle={"db test"}
                    Cells={cells}
                    VarDBDataOrigin={varDBDataOrigin}
                    VarDBRowShowSelectInput={!!d.VarDBRowShowSelectInput}
                    KeyLabel={d.KeyLabel ?? undefined}
                    onChange={handleOnChange(j)}
                    onChangeFormat={handleOnChangeFormat(j)}
                    onChangeCells={(newCells: FieldType[]) =>
                      handleOnChangeCells(newCells)
                    }
                    checklistParams={{
                      elements: checklistElements,
                      onChangeChecklist: (args: ElementType[]) =>
                        setChecklistElements(args),
                    }}
                    extraParams={{
                      showFormat: showFormat,
                      center: center,
                      canAddNewRows: !!d.CanAddNewRows,
                      CanUseExistingRows: !!d.CanUseExistingRows,
                      oneLineString: oneLineString,
                      tooltipOffset: 20,
                      tooltipPlacement: "top",
                      showNumberLabel: showNumberLabel,
                    }}
                  />
                  <HorizontalDivisor />
                </div>
              ))}
            </Content>
          </DynamicInputBox>
        ))}

        <VerticalDivisor />

        {dbColumns.map((c, i) => (
          <DynamicInputBox key={i} background={background}>
            <H3>
              <B>{c.title}</B>
            </H3>
            <Content>
              {globalData.map((d, j) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                  }}
                >
                  <InputTitleContainer>
                    <div className="icon-container">
                      {DataTypeIcons[d.type] && (
                        <img src={DataTypeIcons[d.type].icon} alt="" />
                      )}
                    </div>
                    <p>{d.type}</p>
                  </InputTitleContainer>
                  <DynamicInput
                    key={`vardb-${i}-${j}`}
                    value={d.value}
                    type={d.type}
                    fieldName=""
                    dataOrigin={d.dataOrigin}
                    format={d.format ?? ""}
                    configuration={
                      i !== 1 ? getConfiguration(d.type, d.configuration) : ""
                    }
                    isConsult={i === 1 ? d.isConsult : false}
                    disabled={i === 1 ? false : disabled}
                    required={i === 1 ? false : required}
                    userSelectDataOrigin={userSelectDataOrigin}
                    VarDBTitle={"db test"}
                    Cells={cells}
                    VarDBDataOrigin={varDBDataOrigin}
                    VarDBRowShowSelectInput={!!d.VarDBRowShowSelectInput}
                    KeyLabel={d.KeyLabel ?? undefined}
                    onChange={handleOnChange(j)}
                    onChangeFormat={handleOnChangeFormat(j)}
                    checklistParams={{
                      elements: checklistElements,
                      onChangeChecklist: (args: ElementType[]) =>
                        setChecklistElements(args),
                    }}
                    extraParams={{
                      showFormat: showFormat,
                      center: center,
                      canAddNewRows: !!d.CanAddNewRows,
                      CanUseExistingRows: !!d.CanUseExistingRows,
                      oneLineString: oneLineString,
                      tooltipOffset: 20,
                      tooltipPlacement: "top",
                      showNumberLabel: showNumberLabel,
                    }}
                  />
                  <HorizontalDivisor />
                </div>
              ))}
            </Content>
          </DynamicInputBox>
        ))}
      </DynamicInputsContainer>
    </div>
  );
};

export default DynamicInputCaller;
