import styled from "styled-components";
import { Row } from "antd";

export const TitleStyle = styled.div`
  font-family: "Gotham-Bold";
  font-size: x-large;
  color: #535151;
`;

export const Img = styled.img`
  width: 1.2rem;
  height: 1.1rem;
  margin-right: 0.4rem;
`;

export const ItemTxt = styled.div`
  font-size: 22px;
`;

export const ItemCont = styled(Row)`
  border-bottom: ${({ $isActive }) => ($isActive ? "1px solid" : "none")};
  color: ${({ $isActive }) => ($isActive ? "#0867ad" : "#525151")};
  cursor: pointer;
  font-family: ${({ $isActive }) =>
    $isActive ? "Gotham-Book" : "Gotham-Light"};
  padding: 0rem 0.7rem;
  transition: all 0.3s ease;
`;
