import React, { useContext, useState } from "react";
import { Row } from "antd";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { AiOutlineInfoCircle, AiOutlinePlus } from "react-icons/ai";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Button } from "../../../../../../../GeestUI";
import { SearchBar } from "../../../../../../Pendings/styles";
import {
  Body,
  ColumnTitle,
  DoubleColumn,
  SingleColumn,
  StyledDivider,
  SubColumn,
  SubColumnGroup,
} from "../../../../../Flow/TaskFields/styles";
import {
  DraggableField,
  FieldsColumn,
} from "../../../../TaskFields/DragAndDrop";
import FieldItem from "../../../../TaskFields/FieldItem";
import Tooltip from "../../../../../../../components/Tooltip";
import { useFetch, useMutation } from "../../../../../../../hooks";
import {
  EditManualFieldsViewProps,
  GetManualTriggersFields,
} from "./EditManualFieldsView.d";
import {
  ConsultField,
  EditableField,
  Field,
} from "./../../../../../Configurator.d";
import { filterFields } from "../../../../../FlowActions/EditFields/helpers";
import {
  getFieldFromGlobals,
  handleDragAndDrop,
  manualFieldsBuildApiUpdatedFieldFromEditableFields,
  manualFieldsDragAndDropToApi,
} from "../../../../TaskFields/helpers";
import EditField from "../../../../TaskFields/EditField";
import { DeleteProcessField } from "../../../../../FlowActions/EditFields/ProcessFields.d";
import {
  DragAndDropAction,
  OnChangeMandatoryParams,
} from "../../../../TaskFields/TaskFields.d";
import { ConfiguratorContext } from "../../../../../Configurator";
import DeleteFieldModal from "../../../../TaskFields/DeleteFieldModal";

const EditManualFieldsView: React.FC<EditManualFieldsViewProps> = () => {
  const { IdTeam, IdProcess } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const { refreshProcessFlow } = useContext(ConfiguratorContext);
  const [filter, setFilter] = useState("");
  const [fields, setFields] = useState<{
    editableFields: EditableField[];
    consultFields: ConsultField[];
  }>({
    consultFields: [],
    editableFields: [],
  });
  const [editingFieldId, setEditingFieldId] = useState<number | null>(null);
  const [fieldToDelete, setFieldToDelete] = useState<Field | null>(null);

  const { data, reload: reloadFields } = useFetch<GetManualTriggersFields>({
    func: "Ver2-Configurator-gmtf",
    args: { IdProcessTemplate: IdProcess, IdTeam },
    onSuccess: ({ ConsultFields, EditableFields }) => {
      setFields({
        consultFields: ConsultFields ?? [],
        editableFields: EditableFields ?? [],
      });
    },
  });

  const [insertProcessFieldForm] = useMutation<[]>({
    func: "Ver2-Configurator-ipff",
    onSuccess: () => reloadFields(),
    onError: () => reloadFields(),
  });
  const [updateProcessFieldForm] = useMutation<[]>({
    func: "Ver2-Configurator-upff",
    onError: () => reloadFields(),
    onSuccess: refreshProcessFlow,
  });
  const [deleteProcessField] = useMutation<DeleteProcessField>({
    func: "Ver2-Configurator-dpf",
    onSuccess: () => {
      reloadFields();
      refreshProcessFlow();
      setFieldToDelete(null);
    },
  });
  const [deleteProcessVarDBGroup] = useMutation<[]>({
    func: "Ver2-Configurator-dpvg",
    onSuccess: () => {
      reloadFields();
      refreshProcessFlow();
      setFieldToDelete(null);
    },
  });
  const [deleteProcessFormField] = useMutation<[]>({
    func: "Ver2-Configurator-dpff",
    onError: () => reloadFields(),
    onSuccess: refreshProcessFlow,
  });

  const globalFields = data?.GlobalFields ?? [];
  const filteredGlobalFields = filterFields(globalFields, filter);

  const handleOnDragEnd: OnDragEndResponder = (dropResult) => {
    const { allFields: newFields, succeeded } = handleDragAndDrop(dropResult, {
      globalFields: filteredGlobalFields,
      consultFields: _.cloneDeep(fields.consultFields),
      editableFields: _.cloneDeep(fields.editableFields),
    });
    setFields({
      consultFields: newFields.consultFields,
      editableFields: newFields.editableFields,
    });
    if (succeeded) {
      const { action, field } = manualFieldsDragAndDropToApi(dropResult, {
        globalFields: filteredGlobalFields,
        consultFields: _.cloneDeep(fields.consultFields),
        editableFields: _.cloneDeep(fields.editableFields),
      });
      const args = {
        IdProcessTemplate: IdProcess,
        IdTeam,
        FieldForm: field,
      };
      switch (action) {
        case DragAndDropAction.INSERT:
          insertProcessFieldForm({ args });
          break;
        case DragAndDropAction.UPDATED:
          updateProcessFieldForm({ args });
          break;

        default:
          break;
      }
    }
  };

  const handleOnClickAddField: React.MouseEventHandler<
    HTMLButtonElement
  > = () => setEditingFieldId(0);
  const handleOnClickField = (field: Field): void =>
    setEditingFieldId(field.IdField);
  const handleOnRegisterField = (fieldId: number): void => {
    setEditingFieldId(fieldId);
    reloadFields();
  };
  const handleOnUpdateField = (): void => {
    reloadFields();
    refreshProcessFlow();
  };
  const handleOnCloseFieldEditor = (): void => setEditingFieldId(null);

  const handleOnChangeMandatory = (result: OnChangeMandatoryParams): void => {
    const { editableField, position } = result;
    let newEditableFields = [...fields.editableFields];
    newEditableFields[position] = editableField;
    setFields({
      ...fields,
      editableFields: newEditableFields,
    });
    updateProcessFieldForm({
      args: {
        IdProcessTemplate: IdProcess,
        IdTeam,
        FieldForm: manualFieldsBuildApiUpdatedFieldFromEditableFields(result),
      },
    });
  };
  const handleOnDeleteGlobalField = (field: Field): void => {
    const { IdField } = field;

    if (field.Type === "Cell") {
      deleteProcessVarDBGroup({
        args: {
          IdTeam,
          IdProcessTemplate: IdProcess,
          IdVarDBGroup: field.VardbGroupInfo.IdVarDBGroup,
        },
      });
    } else {
      deleteProcessField({
        args: {
          IdTeam,
          IdProcessTemplate: IdProcess,
          IdField,
        },
      });
    }
  };
  const handleOnDeleteProcessField = (
    { IdField }: Field,
    IdFieldForm: number,
    column: "consultFields" | "editableFields"
  ): void => {
    setFields({
      ...fields,
      [column]: _.reject(fields[column], { IdField }),
    });
    deleteProcessFormField({
      args: {
        IdTeam,
        IdProcessTemplate: IdProcess,
        IdField,
        IdFieldForm,
      },
    });
  };

  return (
    <div style={{ flex: "1", display: "flex", overflow: "auto" }}>
      {editingFieldId !== null && (
        <EditField
          IdField={editingFieldId}
          onRegisterField={handleOnRegisterField}
          onUpdateField={handleOnUpdateField}
          onClose={handleOnCloseFieldEditor}
        />
      )}
      {!!fieldToDelete && (
        <DeleteFieldModal
          onCancel={() => setFieldToDelete(null)}
          onDelete={() => handleOnDeleteGlobalField(fieldToDelete)}
        />
      )}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Body style={{ padding: "20px", paddingTop: "0" }}>
          <SingleColumn>
            <Row justify="space-between" align="middle">
              <ColumnTitle>Campos de formulario</ColumnTitle>
              <Tooltip
                title="Arrastra los campos que quieras utilizar en este disparador manual. En esta columna se muestran todos los campos que existen en el proceso, pero tú puedes elegir cuáles consultar o solicitar como evidencia."
                placement="bottomLeft"
              >
                <AiOutlineInfoCircle size={16} color="#48505E" />
              </Tooltip>
            </Row>
            <StyledDivider />
            <SearchBar
              type="text"
              placeholder="Buscar"
              autoComplete="off"
              style={{ width: "100%", marginBottom: "8px" }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Button
              type="secondary"
              size="fullwidth"
              Icon={AiOutlinePlus}
              style={{ marginBottom: "15px" }}
              onClick={handleOnClickAddField}
            >
              Nuevo campo
            </Button>
            <FieldsColumn droppableId="globals" droppableKey={1}>
              {filteredGlobalFields.map((field, idx) => (
                <DraggableField
                  draggableId={"G-" + field.IdField.toString()}
                  key={"G-" + field.IdField.toString()}
                  draggableIndex={idx}
                >
                  <FieldItem
                    column="global"
                    field={field}
                    onClick={handleOnClickField}
                    onRemove={() => setFieldToDelete(field)}
                  />
                </DraggableField>
              ))}
            </FieldsColumn>
          </SingleColumn>
          <DoubleColumn>
            <ColumnTitle>Campos al inicio del proceso</ColumnTitle>
            <StyledDivider />
            <SubColumnGroup>
              <SubColumn>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <ColumnTitle>Campos de consulta</ColumnTitle>
                  <Tooltip title="Campos no editables" placement="bottomLeft">
                    <AiOutlineInfoCircle size={16} color="#48505E" />
                  </Tooltip>
                </Row>
                <FieldsColumn droppableId="consult" droppableKey={2}>
                  {fields.consultFields.map(({ IdField, IdFieldForm }, idx) => {
                    const field = getFieldFromGlobals(globalFields, IdField);
                    return (
                      <DraggableField
                        draggableId={"C-" + IdField.toString()}
                        key={"C-" + IdField.toString()}
                        draggableIndex={idx}
                      >
                        <FieldItem
                          column="consult"
                          field={field}
                          onClick={handleOnClickField}
                          onRemove={(field) =>
                            handleOnDeleteProcessField(
                              field,
                              IdFieldForm,
                              "consultFields"
                            )
                          }
                        />
                      </DraggableField>
                    );
                  })}
                </FieldsColumn>
              </SubColumn>
              <SubColumn>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <ColumnTitle>Evidencias</ColumnTitle>
                  <Tooltip title="Campos editables" placement="bottomLeft">
                    <AiOutlineInfoCircle size={16} color="#48505E" />
                  </Tooltip>
                </Row>
                <FieldsColumn droppableId="editable" droppableKey={2}>
                  {fields.editableFields.map((editableField, idx) => {
                    const field = getFieldFromGlobals(
                      globalFields,
                      editableField.IdField
                    );
                    return (
                      <DraggableField
                        draggableId={"E-" + editableField.IdField.toString()}
                        key={"E-" + editableField.IdField.toString()}
                        draggableIndex={idx}
                      >
                        <FieldItem
                          column="editable"
                          field={field}
                          onClick={handleOnClickField}
                          isMandatory={editableField.IsMandatory}
                          onChangeIsMandatory={(IsMandatory) =>
                            handleOnChangeMandatory({
                              editableField: { ...editableField, IsMandatory },
                              field,
                              position: idx,
                            })
                          }
                          onRemove={(removedField) =>
                            handleOnDeleteProcessField(
                              removedField,
                              editableField.IdFieldForm,
                              "editableFields"
                            )
                          }
                        />
                      </DraggableField>
                    );
                  })}
                </FieldsColumn>
              </SubColumn>
            </SubColumnGroup>
          </DoubleColumn>
        </Body>
      </DragDropContext>
    </div>
  );
};

export default EditManualFieldsView;
