import { noop as NOOP } from "lodash";
import React, { useState, useRef } from "react";
import styled from "styled-components";
import { HiOutlinePlus } from "react-icons/hi";
import {
  AiOutlineCopy,
  AiOutlineSetting,
  AiOutlineShareAlt,
  AiOutlineFolder,
  AiOutlineRollback,
  AiOutlineInfoCircle,
  AiOutlineEdit,
} from "react-icons/ai";
import { Row, Popover } from "antd";
import { Languages } from "./Dictionary";
import { ThreePointsIcon } from "../../components/hoverIcons";
import { Button } from "../../GeestUI";
import ContextMenu from "../../components/ContextMenu";
import GeestProcess from "../../resources/img/Sidebar/GeestProcess.svg";
import { FiTrash } from "react-icons/fi";
import { IoMdArrowDropright } from "react-icons/io";
import Tooltip from "../../components/Tooltip";

const Card = styled.div`
  width: 251px;
  height: 100%;
  padding: 12px;
  background-color: transpaent;
  overflow: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
`;

const FolderCardDiv = styled.div`
  width: 251px;
  height: 96px;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 0px 6px 6px 0px;
  margin-right: 0;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px rgba(72, 80, 94, 0.3);
`;

const FolderContainer = styled(Row)`
  position: relative;
  width: 280px;
  margin-right: ${({ $lastItem }) => ($lastItem ? "0px" : "25px")};
  padding-right: 0;
`;

const ProcessBar = styled.div`
  width: 26px;
  height: 100%;
  background-color: rgba(2, 115, 233, 1);
  margin: 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
`;

const FolderBar = styled.div`
  width: 12px;
  height: 96px;
  background-color: rgba(31, 147, 52, 1);
  border-radius: 0px 0px 0px 6px;
  margin: 0;
  box-shadow: 0px 3px 0px 0px rgba(72, 80, 94, 0.3);
`;

const SmallFolerBar = styled.div`
  width: 14px;
  height: 50px;
  background-color: rgba(31, 147, 52, 1);
  border-radius: 6px 0px 0px 6px;
  box-shadow: 0px 4px 4px 0px rgba(72, 80, 94, 0.3);
`;

const CardContainer = styled(Row)`
  position: relative;
  width: 280px;
  margin-right: ${({ $lastItem }) => ($lastItem ? "0px" : "25px")};
  padding: 0;
  height: 96px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px
    ${({ $selected }) =>
      $selected ? "rgba(2, 115, 233, 0.5)" : "rgba(72, 80, 94, 0.3)"};
  border: ${({ $selected }) =>
    $selected
      ? "1px solid rgba(2, 115, 233, 1)"
      : "1px solid rgba(2, 115, 233, 0)"};
  border-radius: 6px;
  background-color: ${({ $selected }) => ($selected ? "#f2f8fe" : "#ffffff")};
  :hover {
    box-shadow: 0px 4px 4px 0px rgba(2, 115, 233, 0.5);
    border: 1px solid rgba(2, 115, 233, 1);
    // background-color: #f2f8fe;
  }
`;

const CardTitle = styled(Row)`
  font-size: 14px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  overflow: hidden;
  height: ${({ $ShowControls }) =>
    $ShowControls ? "calc(100% - 1.7rem)" : "100%"};
`;

const NewCard = styled.div`
  cursor: pointer;
  width: 277px;
  height: 96px;
  border-radius: 6px;
  color: rgba(130, 141, 158, 1);
  margin: 0;
  background-color: rgba(237, 236, 236, 1);
  border-color: rgba(237, 236, 236, 1);
  transition: background-color 0.35s ease, border-color 0.35s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: #828d9e;
    border-color: #828d9e;
    color: #ffffff;
  }
`;

const NewCardTitle = styled.div`
  text-align: center;
  font-size: 14px;
  font-family: Gotham-Bold;
`;

const ContextMenuOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    margin: 0;
  }
`;

const FolderMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  height: 32px;
  cursor: pointer;
  font-family: Gotham-Bold;
  margin: 0;
  padding-left: 5px;
  :hover {
    background-color: rgba(2, 115, 233, 1);
  }
  :active {
    background-color: #1939b7;
  }
`;

const FoldersContainer = styled.div`
  width: 282px;
  background-color: rgba(72, 80, 94, 1);
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const FolderMenu = ({ Folders, onMove, IdFolder, disabled }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Popover
      placement="rightTop"
      overlayStyle={{
        backgroundColor: "transparent",
      }}
      overlayInnerStyle={{
        backgroundColor: "transparent",
        borderColor: "transparent",
        boxShadow: "none",
        borderRadius: "6px",
        overflow: "hidden",
      }}
      open={visible}
      onOpenChange={(visible) => setVisible(visible && !disabled)}
      zIndex={1}
      align={{ offset: [-11, -5] }}
      mouseLeaveDelay={0.3}
      content={
        <FoldersContainer>
          {Folders.map(
            (folder) =>
              folder.IdFolder !== IdFolder && (
                <FolderMenuContainer
                  onClick={() => onMove(folder.IdFolder)}
                  key={folder.IdFolder}
                >
                  <AiOutlineFolder size={20} style={{ marginRight: "10px" }} />
                  {folder.Name}
                </FolderMenuContainer>
              )
          )}
        </FoldersContainer>
      }
    >
      <Row align="middle" justify="space-between" style={{ width: "100%" }}>
        <ContextMenuOptionContainer>
          <AiOutlineFolder size={20} />
          {Languages["ESP"].MoveToFolderLabel}
        </ContextMenuOptionContainer>
        <IoMdArrowDropright size={20} />
      </Row>
    </Popover>
  );
};

export const TemplateCard = ({
  Template,
  Team,
  onClick = NOOP,
  onStart = NOOP,
  onEdit = NOOP,
  onShare = NOOP,
  onDuplicate = NOOP,
  Folders,
  onMoveToFolder = NOOP,
  selectedFolder,
  nestedFolders = [],
  maxItems,
  index,
  templatesToShare,
  setTemplatesToShare,
}) => {
  const [openContext, setOpenContext] = useState(false);
  const { Name, IdProcessTemplate, CanStartProcesses, CanShare } = Template;
  const { CanConfigureProcesses, IdTeam } = Team;
  const ref = useRef();
  const {
    ConfigureLabel,
    ShareLabel,
    DuplicateLabel,
    StartLabel,
    RemoveFromFolderLabel,
    ToShareMultipleLabel,
  } = Languages["ESP"];

  const ShowEditButton = CanConfigureProcesses;

  const ShowControls = ShowEditButton || CanStartProcesses;

  if (!CanStartProcesses && !CanConfigureProcesses) {
    return null;
  }

  const onRemoveFromFolder = () => {
    let idParentFolder = null;
    if (nestedFolders.length > 1) {
      idParentFolder = nestedFolders[nestedFolders.length - 2].IdFolder;
    }
    onMoveToFolder(IdTeam, IdProcessTemplate, idParentFolder);
  };

  const getContextOptions = () => {
    let options = [];
    if (templatesToShare.includes(IdProcessTemplate)) {
      return [
        {
          label: (
            <ContextMenuOptionContainer>
              <AiOutlineShareAlt size={20} />
              {ShareLabel}
            </ContextMenuOptionContainer>
          ),
          func: () => {
            onShare();
            setOpenContext(false);
          },
        },
      ];
    }
    const folderOption = {
      label: (
        <FolderMenu
          Folders={Folders}
          onMove={(idFolder) =>
            onMoveToFolder(IdTeam, IdProcessTemplate, idFolder)
          }
          disabled={Folders.length < 1}
        />
      ),
      func: () => {},
      disabled: Folders.length < 1,
    };
    if (ShowEditButton) {
      options.push({
        label: (
          <ContextMenuOptionContainer>
            <AiOutlineSetting size={20} />
            {ConfigureLabel}
          </ContextMenuOptionContainer>
        ),
        func: () => onEdit(IdProcessTemplate, IdTeam),
      });
      options.push({
        label: (
          <ContextMenuOptionContainer>
            <AiOutlineCopy size={20} />
            {DuplicateLabel}
          </ContextMenuOptionContainer>
        ),
        func: () => {
          onDuplicate();
          setOpenContext(false);
        },
      });
      options.push(folderOption);
    }
    if (CanShare && ShowEditButton) {
      options.push({
        label: (
          <Row align="middle" justify="space-between" style={{ width: "100%" }}>
            <ContextMenuOptionContainer>
              <AiOutlineShareAlt size={20} />
              {ShareLabel}
            </ContextMenuOptionContainer>
            <Tooltip title={ToShareMultipleLabel}>
              <AiOutlineInfoCircle size={18} />
            </Tooltip>
          </Row>
        ),
        func: () => {
          let templatesToShareAux = [...templatesToShare];
          templatesToShareAux.push(IdProcessTemplate);
          setTemplatesToShare(templatesToShareAux);
          onShare();
          setOpenContext(false);
        },
      });
    }
    if (!!selectedFolder && ShowEditButton) {
      options.push({
        label: (
          <ContextMenuOptionContainer>
            <AiOutlineRollback size={20} />
            {RemoveFromFolderLabel}
          </ContextMenuOptionContainer>
        ),
        func: onRemoveFromFolder,
      });
    }
    return options;
  };

  const getPoints = () => {
    if (!ref.current) {
      return { x: 0, y: 0 };
    }
    const { x, y } = ref.current?.getBoundingClientRect();
    return { x: x + 259, y: y + 20 };
  };

  return (
    <ContextMenu
      isOpen={openContext}
      width={282}
      closeMenu={() => setOpenContext(false)}
      options={getContextOptions()}
      points={getPoints()}
      placeDown
    >
      <CardContainer
        $lastItem={(index + 1) % maxItems === 0}
        onClick={(e) => {
          if (e.shiftKey && CanShare && ShowEditButton) {
            e.stopPropagation();
            let selectedTemplatesAux = [...templatesToShare];
            const index = selectedTemplatesAux.findIndex(
              (Id) => Id === IdProcessTemplate
            );
            if (index >= 0) {
              selectedTemplatesAux.splice(index, 1);
            } else {
              selectedTemplatesAux.push(IdProcessTemplate);
            }
            setTemplatesToShare(selectedTemplatesAux);
          } else {
            setOpenContext(getContextOptions().length > 0);
          }
        }}
        onKeyDownCapture={({ key }) => {
          if (key === "Shift") {
            setOpenContext(false);
          }
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpenContext(getContextOptions().length > 0);
        }}
        $canShare={CanShare && ShowEditButton}
        $selected={templatesToShare.includes(IdProcessTemplate) || openContext}
      >
        <ProcessBar
          tabIndex={0}
          onClick={() => onClick(Template)}
          onKeyDown={({ key }) => {
            if (key === "Enter" && CanStartProcesses) {
              onStart(Template, IdTeam);
            }
          }}
        />
        <Card
          tabIndex={0}
          onClick={() => onClick(Template)}
          onKeyDown={({ key }) => {
            if (key === "Enter" && CanStartProcesses) {
              onStart(Template, IdTeam);
            }
          }}
          ref={ref}
        >
          <CardTitle justify="space-between" $ShowControls={ShowControls}>
            <div
              style={{
                maxWidth: "89%",
              }}
            >
              {Name}
            </div>
            {getContextOptions().length > 0 && (
              <ThreePointsIcon onClick={() => setOpenContext(true)} />
            )}
          </CardTitle>
          {CanStartProcesses && (
            <Row justify="end" align="bottom">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onStart(Template, IdTeam);
                }}
                onKeyDown={({ key }) => {
                  if (key === "Enter") onStart(Template, IdTeam);
                }}
                type="primary"
                size="small"
                style={{ fontSize: "14px", fontFamily: "Gotham-Bold" }}
              >
                {StartLabel}
              </Button>
            </Row>
          )}
        </Card>
      </CardContainer>
    </ContextMenu>
  );
};

export const FolderCard = ({
  Folder,
  onSelect,
  Folders,
  onMoveToFolder,
  Team,
  selectedFolder,
  onDeleteFolder,
  nestedFolders = [],
  maxItems,
  index,
  onEdit = () => {},
}) => {
  const [openContext, setOpenContext] = useState(false);
  const ref = useRef();
  const { Name, IdFolder } = Folder;
  const { IdTeam, CanConfigureProcesses } = Team;
  const {
    DeleteLabel,
    FolderContentSavedInFolder,
    FolderContentSavedInTeam,
    RemoveFromFolderLabel,
  } = Languages["ESP"];

  const getPoints = () => {
    if (!ref.current) {
      return { x: 0, y: 0 };
    }
    const { x, y } = ref.current?.getBoundingClientRect();
    return { x: x + 285, y: y + 20 };
  };

  const getDeleteTooltipLabel = () => {
    if (selectedFolder) {
      return FolderContentSavedInFolder(selectedFolder.Name);
    }
    return FolderContentSavedInTeam;
  };

  const onRemoveFromFolder = () => {
    let idParentFolder = null;
    if (nestedFolders.length > 1) {
      idParentFolder = nestedFolders[nestedFolders.length - 2].IdFolder;
    }
    onMoveToFolder(IdTeam, null, idParentFolder, IdFolder);
  };

  const getContextOptions = () => {
    let options = [];
    if (CanConfigureProcesses) {
      options.push({
        label: (
          <ContextMenuOptionContainer>
            <AiOutlineEdit size={20} />
            Editar título
          </ContextMenuOptionContainer>
        ),
        func: onEdit,
      });
      options.push({
        label: (
          <FolderMenu
            Folders={Folders}
            onMove={(idParentFolder) =>
              onMoveToFolder(IdTeam, null, idParentFolder, IdFolder)
            }
            IdFolder={IdFolder}
            disabled={Folders.length < 2}
          />
        ),
        func: () => {},
        disabled: Folders?.length === 1,
      });
      if (!!selectedFolder) {
        options.push({
          label: (
            <ContextMenuOptionContainer>
              <AiOutlineRollback size={20} />
              {RemoveFromFolderLabel}
            </ContextMenuOptionContainer>
          ),
          func: onRemoveFromFolder,
        });
      }
      options.push({
        label: (
          <Row align="middle" justify="space-between" style={{ width: "100%" }}>
            <ContextMenuOptionContainer>
              <FiTrash size={20} />
              {DeleteLabel}
            </ContextMenuOptionContainer>
            <Tooltip title={getDeleteTooltipLabel()} placement="right">
              <AiOutlineInfoCircle size={20} />
            </Tooltip>
          </Row>
        ),
        func: () => onDeleteFolder(IdTeam, IdFolder),
        hoverBackground: "red",
      });
    }
    return options;
  };

  return (
    <ContextMenu
      isOpen={openContext}
      width={282}
      closeMenu={() => setOpenContext(false)}
      options={getContextOptions()}
      points={getPoints()}
      placeDown
    >
      <FolderContainer
        onClick={onSelect}
        ref={ref}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpenContext(CanConfigureProcesses);
        }}
        $lastItem={(index + 1) % maxItems === 0}
      >
        <SmallFolerBar />
        <FolderBar />
        <FolderCardDiv>
          <CardTitle justify="space-between">
            <div
              style={{
                maxWidth: "89%",
              }}
            >
              {Name}
            </div>
            {CanConfigureProcesses && (
              <ThreePointsIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenContext(CanConfigureProcesses);
                }}
              />
            )}
          </CardTitle>
        </FolderCardDiv>
      </FolderContainer>
    </ContextMenu>
  );
};

export const NewTemplateCard = ({
  onCreateTemplate = NOOP,
  Label = "",
  onCreateFolder = NOOP,
}) => {
  const [showContext, setShowContext] = useState(false);
  const { ProcessLabel, FolderLabel } = Languages["ESP"];
  const ref = useRef();
  const getPoints = () => {
    if (!ref.current) {
      return { x: 0, y: 0 };
    }
    const { x, y } = ref.current?.getBoundingClientRect();
    return { x: x + 285, y: y + 20 };
  };
  const contextOptions = [
    {
      label: (
        <ContextMenuOptionContainer>
          <img
            src={GeestProcess}
            alt="process"
            style={{
              width: "14px",
              height: "14px",
            }}
          />
          {ProcessLabel}
        </ContextMenuOptionContainer>
      ),
      func: () => {
        onCreateTemplate();
        setShowContext(false);
      },
    },
    {
      label: (
        <ContextMenuOptionContainer>
          <AiOutlineFolder size={20} />
          {FolderLabel}
        </ContextMenuOptionContainer>
      ),
      func: () => {
        onCreateFolder();
        setShowContext(false);
      },
    },
  ];

  return (
    <ContextMenu
      isOpen={showContext}
      width={287}
      closeMenu={() => setShowContext(false)}
      points={getPoints()}
      options={contextOptions}
      placeDown
    >
      <NewCard
        tabIndex={0}
        onClick={() => setShowContext(true)}
        onKeyDown={({ key }) => {
          if (key === "Enter") setShowContext(true);
        }}
        ref={ref}
      >
        <HiOutlinePlus size={20} />
        <NewCardTitle>{Label}</NewCardTitle>
      </NewCard>
    </ContextMenu>
  );
};
