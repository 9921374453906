export const getFreeTrialColor = (status: string) => {
  switch (status) {
    case "active_free_cardless_trial":
    case "active_bonus_trial":
      return "#08979C";
    case "ended_free_cardless_trial":
      return "#CF1322";
    case "ended_bonus_trial":
    case "canceled_bonus_trial":
      return "#48505E";
    default:
      return "";
  }
};

export const getFreeTrialBackground = (status: string) => {
  switch (status) {
    case "active_free_cardless_trial":
    case "active_bonus_trial":
      return "#E6FFFB";
    case "ended_free_cardless_trial":
      return "#FFF1F0";
    case "ended_bonus_trial":
    case "canceled_bonus_trial":
      return "#F6F5F5";
    default:
      return "";
  }
};

export const getFreeTrialCardColor = (status: string) => {
  switch (status) {
    case "active_free_cardless_trial":
      return "#A1E2E4";
    case "active_bonus_trial":
      return "#08979C";
    case "ended_free_cardless_trial":
      return "#EBBFC3";
    case "ended_bonus_trial":
    case "canceled_bonus_trial":
      return "#48505E";
    default:
      return "";
  }
};

export const getStatusLabel = (status: string) => {
  switch (status) {
    case "active_free_cardless_trial":
    case "active_bonus_trial":
      return "Activa";
    case "ended_free_cardless_trial":
      return "Vencida";
    case "ended_bonus_trial":
    case "canceled_bonus_trial":
      return "Cancelada";
    default:
      return "";
  }
};
