import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Background = styled.div`
  background: #283c86;
  background: linear-gradient(to top, #6afc6d, #1d4eff);
  box-shadow: inset 0px 0px 53px 65px rgba(255, 255, 255, 1);
  height: 100%;
  width: 100%;
  display: flex;
`;

const Text = styled.h1`
  margin: auto;
  color: white;
  font-size: 36px;
  font-family: "Gotham-Bold";
`;

const Languages = {
  ENG: {
    LoadingLabel: "Loading",
  },
  ESP: {
    LoadingLabel: "Cargando",
  },
};

const LoadingState = () => {
  const [LoadingStatus, setLoadingStatus] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      if (LoadingStatus === 3) setLoadingStatus(0);
      else setLoadingStatus(LoadingStatus + 1);
    }, 200);
    return () => clearInterval(interval);
  }, [LoadingStatus]);

  const { LoadingLabel } = Languages["ESP"];

  const AnimatedPoints = (() => {
    let res = "";
    for (let n = 0; n < LoadingStatus; n++) {
      res += ".";
    }
    return res;
  })();

  return (
    <Background>
      <Text>
        {LoadingLabel}
        {AnimatedPoints}
      </Text>
    </Background>
  );
};

export default LoadingState;
