import React from "react";
import {
  PendingListProps,
  // ColumnType
} from "./PendingList.d";
import TaskGroup from "./TaskGroup";
import { noop as NOOP } from "lodash";
import { PendingListContainer } from "../styles";

const PendingList: React.FC<PendingListProps> = ({
  isLoadingTaks,
  tasks,
  dynamicColumns,
  onSelectTask = NOOP,
  reloadPendings = NOOP,
}) => {
  return (
    <PendingListContainer>
      <TaskGroup
        isLoadingTaks={isLoadingTaks}
        Tasks={tasks}
        ExtraColumns={dynamicColumns}
        onSelectTask={onSelectTask}
        reloadPendings={reloadPendings}
      />
    </PendingListContainer>
  );
};

export default PendingList;
