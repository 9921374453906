import React from "react";
import { noop as NOOP } from "lodash";

interface useCopyPasteParams {
  onCopy?: () => void;
  onPaste?: () => void;
  onNotMatching?: React.KeyboardEventHandler<HTMLDivElement>;
}

interface useCopyPasteReturn {
  handleOnKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
}

const useCopyPaste = (props?: useCopyPasteParams): useCopyPasteReturn => {
  const { onCopy = NOOP, onPaste = NOOP, onNotMatching = NOOP } = props || {};

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.metaKey || event.ctrlKey) {
      switch (event.key) {
        case "c":
          onCopy();
          break;
        case "v":
          onPaste();
          break;
        default:
          onNotMatching(event);
          break;
      }
    } else onNotMatching(event);
  };

  return { handleOnKeyDown };
};

export default useCopyPaste;
