import { Space } from "antd";
import React from "react";
import _ from "lodash";
import { ChatsPageProps, SubfluxChat } from "./SubfluxEditor.d";
import { getSelectedChatValue } from "./helpers";
import { StyledLabel, StyledTitle } from "../TaskEditor/styles";
import GeestSelect from "../../../../../GeestUI/GeestSelect";

const ChatsPage: React.FC<ChatsPageProps> = ({
  subflux,
  destinyChats,
  originChats,
  onChangeAndSave,
}) => {
  const originChatsOptions = _.map(originChats, ({ Id, Label }) => ({
    value: Id,
    label: Label,
  }));

  const handleOnChangeStartFields = (Chats: SubfluxChat[]): void =>
    onChangeAndSave({
      ...subflux,
      Chats,
    });

  const onClearStartFieldValue = (
    IdPrepublishTemplateChatDestiny: number
  ): void =>
    handleOnChangeStartFields(
      _.reject(subflux.Chats, { IdPrepublishTemplateChatDestiny })
    );

  const handleOnChangeStartField = (
    IdChatOrigin: number | "",
    IdChatDestiny: number
  ) => {
    if (IdChatOrigin === "") return onClearStartFieldValue(IdChatDestiny);

    let newChatValues: SubfluxChat[] = subflux.Chats;

    const existentValue = _.findIndex(newChatValues, {
      IdPrepublishTemplateChatDestiny: IdChatDestiny,
    });
    if (existentValue >= 0) {
      console.log(newChatValues[existentValue], IdChatOrigin);
      newChatValues[existentValue].IdPrepublishProcessTemplateChatOrigin =
        IdChatOrigin;
      console.log(newChatValues[existentValue], IdChatOrigin);
    } else {
      newChatValues.push({
        IdPrepublishProcessTemplateChatOrigin: IdChatOrigin,
        IdPrepublishTemplateChatDestiny: IdChatDestiny,
      });
    }

    handleOnChangeStartFields([...newChatValues]);
  };

  return (
    <Space direction="vertical" size={[0, 20]} style={{ width: "100%" }}>
      <StyledLabel>
        Asigna los canales que quieras pasar al siguiente flujo
      </StyledLabel>
      {destinyChats.map((destinyChat) => (
        <div key={destinyChat.Id}>
          <StyledTitle>{destinyChat.Label}</StyledTitle>
          <GeestSelect
            value={getSelectedChatValue(destinyChat.Id, subflux)}
            onChange={(idChatOrigin) =>
              handleOnChangeStartField(idChatOrigin, destinyChat.Id)
            }
            options={originChatsOptions}
            placeholderSelect="Selecciona una opción..."
          />
        </div>
      ))}
    </Space>
  );
};

export default ChatsPage;
