import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Popover, Row, Tooltip } from "antd";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { AiOutlineInfoCircle, AiOutlinePlus } from "react-icons/ai";
import { PriorityPopoverProps } from "./PriorityPopover.d";
import { Card, CardTitle } from "./styles";
import { CloseIcon } from "../../../../../../components/hoverIcons";
import { Button } from "../../../../../../GeestUI";
import PriorityItem from "./PriorityItem";
import { useMutation } from "../../../../../../hooks";
import { TaskPriority } from "../TaskEditor.d";
import EditPriority from "../../../../../Pendings/DetailPending/MicroComponents/EditPriority";
import {
  DraggableItem,
  DroppableContainer,
} from "../../../../../Pendings/DetailPending/Popups/PriorityPopover";

const PriorityPopover: React.FC<PriorityPopoverProps> = ({
  children,
  IdTaskTemplate,
  taskPriorityId,
  onChangePriority,
  open,
  setOpen,
}) => {
  const [priorities, setPriorities] = useState<TaskPriority[]>([]);
  const [editingPriorityId, setEditingPriorityId] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (open) reloadPriorities();
    else setEditingPriorityId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const reloadPriorities = (): void =>
    getPriorities({
      args: {
        IdTaskTemplate,
      },
    });

  const [getPriorities] = useMutation<TaskPriority[]>({
    func: "Ver2-Configurator-gtp",
    onSuccess: setPriorities,
  });
  const [changeTaskPriority] = useMutation<[]>({
    func: "Ver2-Configurator-ctp",
    onSuccess: (_response, newPriority: TaskPriority | []) =>
      onChangePriority(newPriority),
  });
  const [insertTaskPriority] = useMutation<[]>({
    func: "Ver2-Configurator-itp",
    onSuccess: () => {
      reloadPriorities();
      setEditingPriorityId(null);
    },
  });
  const [updateTaskPriority] = useMutation<[]>({
    func: "Ver2-Configurator-utp",
    onSuccess: (_response, newPriority: TaskPriority) => {
      if (newPriority?.IdTaskPriority === taskPriorityId)
        onChangePriority(newPriority);
      reloadPriorities();
      setEditingPriorityId(null);
    },
    onError: () => reloadPriorities(),
  });
  const [deleteTaskPriority] = useMutation<[]>({
    func: "Ver2-Configurator-dtp",
    onSuccess: () => {
      reloadPriorities();
      setEditingPriorityId(null);
    },
  });

  const handleOnChangePriority = (selectedPriority: TaskPriority) => {
    const removePriority = selectedPriority.IdTaskPriority === taskPriorityId;
    changeTaskPriority({
      args: {
        IdTaskTemplate,
        IdTaskPriority: removePriority ? "" : selectedPriority.IdTaskPriority,
      },
      shippedData: removePriority ? [] : selectedPriority,
    });
  };

  const handleOnCreatePriority = ({ Title, Color }: TaskPriority): void =>
    insertTaskPriority({
      args: { IdTaskTemplate, Title, Color },
    });
  const handleOnUpdatePriority = (priority: TaskPriority): void =>
    updateTaskPriority({
      args: { IdTaskTemplate, TaskPriority: priority },
      shippedData: priority,
    });
  const handleOnDeletePriority = ({ IdTaskPriority }: TaskPriority): void =>
    deleteTaskPriority({
      args: { IdTaskTemplate, IdTaskPriority },
    });
  const handleOnReOrder = ({ source, destination }: DropResult): void => {
    if (destination) {
      const { index: sourceIndex } = source;
      const { index: destinationIndex } = destination;
      let newPriorities = [...priorities];
      // get MovedItem using sourceIndex
      let [movedItem] = _.pullAt(newPriorities, sourceIndex);
      // insert MovedItem using destinationIndex
      newPriorities.splice(destinationIndex, 0, movedItem);
      // reset positions
      newPriorities = newPriorities.map((priority, index) => ({
        ...priority,
        Position: index + 1,
      }));
      // set positions
      setPriorities([...newPriorities]);
      handleOnUpdatePriority(newPriorities[destinationIndex]);
    }
  };

  const onClose = () => setOpen(false);

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      trigger="click"
      placement="bottomLeft"
      showArrow={false}
      overlayInnerStyle={{
        borderRadius: "6px",
      }}
      align={{ offset: [0, -10] }}
      content={
        <Card>
          <Row justify="space-between" align="middle">
            <Row align="middle">
              <CardTitle>Prioridad de la tarea</CardTitle>
              <Tooltip
                title="La prioridad que aparezca hasta arriba será considerada como la más alta y así sucesivamente"
                placement="bottomLeft"
              >
                <AiOutlineInfoCircle
                  color="#48505E"
                  size={16}
                  style={{ marginLeft: "8px" }}
                />
              </Tooltip>
            </Row>
            <CloseIcon size={20} onClick={onClose} color="#48505e" />
          </Row>
          <Row justify="center" style={{ margin: "12px 0" }}>
            {editingPriorityId === 0 ? (
              <EditPriority
                disableDelete
                onSubmit={handleOnCreatePriority}
                onCancel={() => setEditingPriorityId(null)}
              />
            ) : (
              <Button
                type="secondary"
                Icon={AiOutlinePlus}
                size="large"
                onClick={() => setEditingPriorityId(0)}
              >
                Nueva prioridad
              </Button>
            )}
          </Row>
          <DragDropContext
            onBeforeDragStart={() => setEditingPriorityId(null)}
            onDragEnd={handleOnReOrder}
          >
            <DroppableContainer>
              {priorities.map((priority, index) => (
                <DraggableItem
                  draggableId={String(priority.IdTaskPriority)}
                  index={index}
                  key={priority.IdTaskPriority}
                >
                  {editingPriorityId === priority.IdTaskPriority ? (
                    <EditPriority
                      defaultValue={priority}
                      disableDelete={priority.IdTaskPriority === taskPriorityId}
                      onSubmit={handleOnUpdatePriority}
                      onDelete={handleOnDeletePriority}
                      onCancel={() => setEditingPriorityId(null)}
                    />
                  ) : (
                    <PriorityItem
                      key={priority.IdTaskPriority}
                      isSelected={priority.IdTaskPriority === taskPriorityId}
                      priority={priority}
                      onSelect={handleOnChangePriority}
                      onEdit={({ IdTaskPriority }) =>
                        setEditingPriorityId(IdTaskPriority)
                      }
                    />
                  )}
                </DraggableItem>
              ))}
            </DroppableContainer>
          </DragDropContext>
        </Card>
      }
    >
      {children}
    </Popover>
  );
};

export default PriorityPopover;
