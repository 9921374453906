import { Select } from "antd";
import { IoClose } from "react-icons/io5";
import styled from "styled-components";
import searchIMG from "../../resources/img/SEARCH_TASKS.svg";

export const HeaderContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 13px;
  user-select: none;
`;

export const OptionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    margin: 0;
    font-size: 14px;
  }
`;

export const OptionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  img {
    width: 16px;
    height: 16px;
  }
`;

export const FiltersBox = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
    color: #828d9e;
    font-size: 14px;
  }
`;

export const FilterElement = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-sizing: border-box;
    width: 200px;
    height: 32px;
    border: 0.5px solid #c0c9d3 important;
    border-radius: 5px;
  }
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 0.5px solid #c0c9d3;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

export const SearchBarContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const CloseIcon = styled(IoClose)`
  cursor: pointer;
  color: white;
`;

export const FiltersResetButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: background-color 0.35s ease, opacity 0.35s ease;
  :hover {
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 1;
  }
`;

export const SearchBar = styled.input`
  background-image: url(${searchIMG});
  background-repeat: no-repeat;
  background-size: 14px;
  background-position-y: center;
  background-position-x: 6px;
  background-color: #ffffff;
  padding: 3px 5px;
  padding-left: 25px;
  width: 180px;
  height: 32px;

  border-radius: 6px;
  outline: none;
  border: 2px solid #edecec;
  font-size: 14px;
  color: #828d9e;

  ::placeholder {
    color: #828d9e;
  }

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const AddColumnContiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const AddColumnButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;

  background: #0273e9;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  color: white;
  height: 32px;
  cursor: pointer;

  transition: background 0.35s ease;
  :hover {
    background: #014c9b;
  }
`;

export const ViewContent = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 26px;
  overflow: auto;
`;

export const PendingListContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 5px);
  overflow: auto;
  padding-bottom: 5px;
`;
