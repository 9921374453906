import styled, { css } from "styled-components";

// <PriorityPopover />

export const Card = styled.div`
  padding: 12px 16px;
  width: 304px;
`;

export const CardTitle = styled.span`
  font-size: 16px;
  font-family: "Gotham-Bold";
`;

// <PriorityItem />

export const ItemContainer = styled.div<{ $isSelected: boolean }>`
  flex: 1;
  padding: 4px 0;
  padding-left: 10px;
  margin-right: 8px;
  border-radius: 6px;
  transition: background 0.35s ease 0s;
  :hover {
    background-color: #edecec;
  }
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: #edecec;
    `}
`;
