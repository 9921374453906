import React, { useRef, useEffect } from "react";
import { VarDBColumnList } from "./DataBases.d";
import {
  ListItem,
  ColumnItem,
  ColumnItemContainer,
  PositionContainer,
} from "./Styles";
import Tooltip from "../../components/Tooltip";
import DataTypeIcons from "../../components/DataTypeIcons";

interface VarDBColumnListItemProps {
  column: VarDBColumnList;
  filter: string;
  width: number;
}

const VarDBColumnListItem: React.FC<VarDBColumnListItemProps> = ({
  column,
  filter,
  width,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const sanitizedString = (name: string) => {
    return name
      .toLowerCase()
      .replaceAll("á", "a")
      .replaceAll("é", "e")
      .replaceAll("í", "i")
      .replaceAll("ó", "o")
      .replaceAll("í", "i");
  };

  useEffect(() => {
    if (
      !!filter &&
      sanitizedString(column.Title).includes(sanitizedString(filter))
    ) {
      ref.current?.scrollIntoView();
    }
  }, [filter, column.Title]);

  return (
    <Tooltip title={column.Title} placement="top">
      <ListItem $width={width} ref={ref}>
        <ColumnItemContainer>
          <PositionContainer>{column.Position}</PositionContainer>
          <ColumnItem
            $filtered={
              !!filter &&
              sanitizedString(column.Title).includes(sanitizedString(filter))
            }
          >
            <img
              style={{
                width: "14px",
                height: "14px",
                ...DataTypeIcons[column.DataType.toLowerCase()].extraStyles,
              }}
              alt={DataTypeIcons[column.DataType.toLowerCase()]?.label || ""}
              src={DataTypeIcons[column.DataType.toLowerCase()].icon}
            />
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {column.Title}
            </div>
          </ColumnItem>
        </ColumnItemContainer>
        <div className="bar" />
      </ListItem>
    </Tooltip>
  );
};

export default VarDBColumnListItem;
