import React, { useRef, useState } from "react";
import { BsLightningFill } from "react-icons/bs";
import { Handle, NodeProps } from "reactflow";
import styled from "styled-components";
import { Tooltip } from "antd";
import { defaultHandlers } from "./shared/shared";
import { NodeData } from "../Diagram.d";
import { border, handlerStyles, Rectangle, StyledTag } from "./shared/styles";
import { getNodeStatusBackgroundColor, getStatusTag } from "../helpers";
import { useElementOverflow } from "../../../../../../hooks";

const Top = styled.div`
  text-align: center;
  display: flex;
  overflow: hidden;
  flex: 1;
`;
const Bottom = styled.div`
  height: 35px;
  text-align: center;
  display: flex;
  overflow: hidden;
  border-top: ${border};
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
`;

const TextContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  width: 100%;
`;
const Text = styled.span`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const AutomationNode: React.FC<NodeProps<NodeData>> = ({ data, selected }) => {
  const { label, status } = data;
  const nodeBackground = getNodeStatusBackgroundColor(status);
  const statusTag = getStatusTag(status);

  const textRef = useRef<HTMLSpanElement>(null);
  const isOverflow = useElementOverflow(textRef, "width");
  const [openTooltip, setOpenTooltip] = useState(false);

  return (
    <Rectangle $isSelected={selected} $backgroundColor={nodeBackground}>
      <Top>
        <TextContainer>
          <Tooltip
            title={label}
            open={openTooltip && isOverflow}
            onOpenChange={setOpenTooltip}
            overlayInnerStyle={{
              borderRadius: "2px",
              padding: "6px 8px",
            }}
          >
            <Text ref={textRef}>{label}</Text>
          </Tooltip>
          <div>
            <StyledTag color={statusTag.color}>{statusTag.label}</StyledTag>
          </div>
        </TextContainer>
      </Top>
      <Bottom>
        <BsLightningFill color="#0273e9" size={18} style={{ margin: "auto" }} />
      </Bottom>
      {defaultHandlers.map((handler) => (
        <Handle
          style={handlerStyles}
          key={handler.id}
          {...handler}
          isConnectableStart={false}
        />
      ))}
    </Rectangle>
  );
};

export default AutomationNode;
