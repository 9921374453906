import styled from "styled-components";

export const Container = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  padding: 4rem 2rem;
  color: #535151;
  margin: 0 5%;
  overflow: auto;
  text-align: center;
  font-size: 20px;
  line-height: 1.3rem;
  z-index: 9999;
`;

export const Title = styled.b`
  color: #e10600;
`;

export const Text = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const ButtonContainer = styled.div`
  width: 20rem;
`;

export const ButtonBorder = styled.div`
  width: 100%;
  padding: 1px;
  background: ${({ loading }) =>
    loading ? "#0000001e" : "linear-gradient(to right, #84be54, #3f7db8)"};
  border-radius: 11px;
  .MuiButton-label {
    font-size: 16px;
  }
`;
