import React, { CSSProperties, useRef, useState } from "react";
import { noop as NOOP } from "lodash";
import { Popover, PopoverOrigin } from "@material-ui/core";
import { CirclePicker, ColorChangeHandler } from "react-color";
import styled from "styled-components";

export const Circle = styled.div<{
  color?: string;
  border?: string;
  size?: number;
}>`
  background: ${({ color = "white" }) => color};
  border: ${({ border = "none" }) => border};
  border-radius: 50%;
  height: ${({ size = 15 }) => `${size}px`};
  width: ${({ size = 15 }) => `${size}px`};
  min-width: ${({ size = 15 }) => `${size}px`};
`;

interface ColorPickerProps {
  color?: string;
  onChange?: (color: string) => void;
  disabled?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  popoverStyle?: CSSProperties;
  paperProps?: CSSProperties;
}

const defualtAnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const defaultTransformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};

const ColorPicker: React.FC<ColorPickerProps> = ({
  children,
  color = "#f44336",
  onChange = NOOP,
  disabled = false,
  anchorOrigin = defualtAnchorOrigin,
  transformOrigin = defaultTransformOrigin,
  popoverStyle,
  paperProps,
}) => {
  const [visible, setVisible] = useState(false);
  const contentRef = useRef(null);

  const handleOnChange: ColorChangeHandler = ({ hex }) => {
    onChange(hex);
    setVisible(false);
  };

  const handleVisibleChange = (value: boolean) => {
    if (!disabled) setVisible(value);
  };

  return (
    <>
      <Popover
        open={visible && !disabled}
        anchorEl={contentRef.current}
        onClose={() => handleVisibleChange(false)}
        style={popoverStyle}
        PaperProps={{
          style: {
            borderRadius: "6px",
            width: "194px",
            padding: "12px",
            ...paperProps,
          },
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <CirclePicker
          color={color}
          circleSize={20}
          circleSpacing={10}
          width="194px"
          onChangeComplete={handleOnChange}
        />
      </Popover>
      <div ref={contentRef} onClick={() => handleVisibleChange(true)}>
        {children}
      </div>
    </>
  );
};

export default ColorPicker;
