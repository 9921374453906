import styled from "styled-components";
import { Progress } from "antd";

export const GeneralInformationContainer = styled.div`
  height: 250px;
  width: 100%;
  max-wdith: 100%;
  overflow: hidden auto;
  padding: 0.5rem 1rem;
`;

export const CardTitle = styled.div`
  font-family: "Gotham-Bold";
  color: #727e8b;
`;

export const GeneralInfoContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 2rem);
`;

export const GeneralGraphic = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  min-width: 150px;
`;

export const GraphicContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTooltip = styled.div<{ $background?: string }>`
  .ant-tooltip-inner {
    color: white;
    font-size: 10px;
    background-color: transparent;
    width: 80px;
    border-radius: 5px;
    text-align: center;
    background-image: ${({ $background = "transparent" }) => $background};
  }
`;

export const Circle = styled.div<{
  $width: string;
  $height: string;
  $borderRadius: string;
  $padding: string;
  $boxShadow?: string;
}>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  padding: ${({ $padding }) => $padding};
  color: #727e8b;
  box-shadow: ${({ $boxShadow = "" }) => $boxShadow};
`;

export const GeneralProgressBars = styled.div`
  flex: 2;
  height: 100%;
  overflow: hidden auto;
  padding: 0.5rem;
`;

export const BarsContent = styled.div``;

export const BarContainer = styled.div<{ $isSelected: boolean }>`
  cursor: pointer;
  background-color: ${({ $isSelected }) =>
    $isSelected ? "#e8ecf1" : "transparent"};
  border-radius: 5px;
  padding: 0.2rem;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #e8ecf1;
  }
`;

export const Label = styled.span<{ $mr: string }>`
  color: #727e8b;
  margin-right: ${({ $mr = "0" }) => $mr};
`;

export const StyledProgress = styled(Progress)<{ $finish: boolean }>`
  .ant-progress-inner {
    width: 100%;
    background-color: #c0c9d3;
  }
  .ant-progress-outer {
    width: 100%;
  }
  .ant-progress-text {
    color: ${({ $finish = false }) => ($finish ? "" : "#727e8b")};
  }
`;
