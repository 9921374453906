import { create } from "zustand";
import {
  Team,
  Template,
  GeneralInformationType,
  ProcessesInformationType,
  TeamMember,
  Status,
  Filter,
} from "./Dashboard/Dashboard.d";

interface ReportState {
  statuses: Status[];
  setSatuses: (statuses: Status[]) => void;
  teams: Team[];
  setTeams: (teams: Team[]) => void;
  currentTeam: Team;
  setCurrentTeam: (team: Team) => void;
  selectedProcess: Template | null;
  setSelectedProcess: (template: Template | null) => void;
  generalInformation: GeneralInformationType;
  setGeneralInformation: (info: GeneralInformationType) => void;
  processInformation: ProcessesInformationType[];
  setProcessInformation: (info: ProcessesInformationType[]) => void;
  teamMembersInformation: TeamMember[];
  setTeamMembersInformation: (members: TeamMember[]) => void;
  filters: Filter;
  setFilters: (filter: Filter) => void;
}

const getReport = create<ReportState>((set) => ({
  statuses: [],
  setSatuses: (statuses: Status[]) => set({ statuses }),
  teams: [],
  setTeams: (teams: Team[]) => set({ teams }),
  currentTeam: {} as Team,
  setCurrentTeam: (currentTeam: Team) => set({ currentTeam }),
  selectedProcess: null,
  setSelectedProcess: (selectedProcess: Template | null) =>
    set({ selectedProcess }),
  generalInformation: {} as GeneralInformationType,
  setGeneralInformation: (generalInformation: GeneralInformationType) =>
    set({ generalInformation }),
  processInformation: [],
  setProcessInformation: (processInformation) => set({ processInformation }),
  teamMembersInformation: [],
  setTeamMembersInformation: (teamMembersInformation) =>
    set({ teamMembersInformation }),
  filters: {},
  setFilters: (filters: Filter) => set({ filters }),
}));

export default getReport;
