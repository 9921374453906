import React, { useState } from "react";

export const RedirectContext = React.createContext(null);

export const Redirect = ({ children }) => {
  const [RedirectURL, setRedirectURL] = useState(null);

  return (
    <RedirectContext.Provider value={[RedirectURL, setRedirectURL]}>
      {children}
    </RedirectContext.Provider>
  );
};
