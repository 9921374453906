import React, { useEffect } from "react";
import {
  ViewContentAction,
  ActionFormTitle,
  DateConfigContainer,
  DateConfigItem,
} from "./styles";
import sumActionIcon from "../../../../../../resources/img/sumActionIcon.svg";
import substractActionIcon from "../../../../../../resources/img/substractActionIcon.svg";
import multiplyActionIcon from "../../../../../../resources/img/multiplyActionIcon.svg";
import GeestSelect from "../../../../../../GeestUI/GeestSelect";
import { useFetch } from "../../../../../../hooks";
import {
  GetActionConfigOptionsResponse,
  ActionProps,
} from "../AutomationConfig.d";
import { useParams } from "react-router-dom";
import copyActionIcon from "../../../../../../resources/img/copyActionIcon.svg";
import ViewHeader from "./ViewHeader";
import { Row } from "antd";
import DataTypeIcons from "../../../../../../components/DataTypeIcons";
import Tooltip from "../../../../../../components/Tooltip";
import { Action } from "../EditAction.d";
import divActionIcon from "../../../../../../resources/img/divActionIcon.svg";

const FieldOperationActionView: React.FC<ActionProps> = ({
  reloadHome,
  selectedAction,
  actionOptions,
  IdNode,
  setSelectedAction,
  saveAction,
}) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const actionIcon: { [key: string]: any } = {
    copy_value: copyActionIcon,
    substract_fields: substractActionIcon,
    sum_fields: sumActionIcon,
    mult_fields: multiplyActionIcon,
    div_fields: divActionIcon,
  };

  const select1Label: { [key: string]: any } = {
    copy_value: "Selecciona campo de origen",
    substract_fields: "Selecciona el minuendo",
    sum_fields: "Selecciona el primer sumando",
    mult_fields: "Selecciona el primer factor",
    div_fields: "Selecciona el dividendo",
  };

  const select2Label: { [key: string]: any } = {
    copy_value: "Selecciona campo de destino",
    substract_fields: "Selecciona el sustraendo",
    sum_fields: "Selecciona el segundo sumando",
    mult_fields: "Selecciona el segundo factor",
    div_fields: "Selecciona el divisor",
  };

  const { data, reload } = useFetch<GetActionConfigOptionsResponse[]>({
    func: "Ver2-Configurator-gaco",
    args: {
      IdTeam,
      IdProcessTemplate,
      IdNode,
      Action: selectedAction?.Action,
    },
  });

  const getIfDateFields = (action: Action) => {
    const dataType1 = data?.find(
      ({ Value }) => Value === action["IdField1"]
    )?.DataType;
    const dataType2 = data?.find(
      ({ Value }) => Value === action["IdField2"]
    )?.DataType;
    console.log(dataType1, dataType2);
    return (
      ["date", "time"].includes(dataType1 || "") ||
      ["date", "time"].includes(dataType2 || "")
    );
  };

  const sendAction = (action: Action) => {
    if (action.Action === "copy_value") {
      if (action.SubAction === "copy_process_name") {
        if (
          (action.IdField1 === 0 && !!action.IdField2) ||
          (!!action.IdField1 && action.IdField2 === 0)
        ) {
          saveAction(action);
        }
      } else {
        if (!!action.IdField1 && !!action.IdField2) {
          saveAction(action);
        }
      }
    } else {
      if (action.Action === "substract_fields" && getIfDateFields(action)) {
        if (
          !!action.IdField1 &&
          !!action.IdField2 &&
          action.IdFieldToSave &&
          !!action.DateConfig
        ) {
          saveAction(action);
        }
      } else if (
        !!action.IdField1 &&
        !!action.IdField2 &&
        action.IdFieldToSave
      ) {
        saveAction(action);
      }
    }
  };

  useEffect(() => {
    reload();
    if (
      selectedAction?.SubAction === "copy_process_name" &&
      selectedAction?.IdAction > 0
    ) {
      if (!selectedAction?.IdField1) {
        selectedAction.IdField1 = 0;
      }
      if (!selectedAction?.IdField2) {
        selectedAction.IdField2 = 0;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction?.IdAction]);

  const getFieldOptions = (KeyToFilter: string) => {
    const dataType = data?.find(
      ({ Value }) => Value === selectedAction[KeyToFilter]
    )?.DataType;
    const fields =
      data
        ?.filter(({ Value, DataType, Type }) => {
          if (KeyToFilter === "IdField1" && Type === "Ghost") {
            return false;
          }
          if (KeyToFilter === "IdField1" && DataType === "pdf_autogenerated") {
            return false;
          }
          if (selectedAction && selectedAction[KeyToFilter]) {
            if (selectedAction?.Action === "substract_fields") {
              if (getIfDateFields(selectedAction)) {
                return (
                  ["date", "time"].includes(DataType) &&
                  Value !== selectedAction[KeyToFilter]
                );
              } else {
                return (
                  !["date", "time"].includes(DataType) &&
                  Value !== selectedAction[KeyToFilter]
                );
              }
            }
            if (selectedAction?.Action === "copy_value") {
              if (KeyToFilter === "IdField1") {
                if (dataType === "select") {
                  return (
                    ["string", "select"].includes(DataType) &&
                    Value !== selectedAction[KeyToFilter]
                  );
                }
              }
              if (KeyToFilter === "IdField2") {
                if (dataType === "string") {
                  return (
                    ["string", "select"].includes(DataType) &&
                    Value !== selectedAction[KeyToFilter]
                  );
                }
              }
              return (
                (dataType === DataType ||
                  (DataType === "file" && dataType === "pdf_autogenerated") ||
                  (DataType === "pdf_autogenerated" && dataType === "file")) &&
                Value !== selectedAction[KeyToFilter]
              );
            }
            return Value !== selectedAction[KeyToFilter];
          }
          if (!KeyToFilter && selectedAction?.Action === "substract_fields") {
            if (getIfDateFields(selectedAction)) {
              return DataType === "number";
            }
            return ["number", "currency"].includes(DataType);
          }
          if (selectedAction && selectedAction[KeyToFilter] === 0) {
            return DataType === "string";
          }
          return true;
        })
        .map(({ Label, Value, DataType }) => ({
          label: (
            <Row align="middle">
              <Row
                align="middle"
                justify="center"
                style={{
                  width: "26px",
                  height: "26px",
                  display: "flex",
                }}
                wrap={false}
              >
                <img
                  style={{
                    width: "14px",
                    height: "14px",
                    ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
                  }}
                  alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
                  src={DataTypeIcons[DataType?.toLowerCase()].icon}
                />
              </Row>
              {Label.length >= 40 ? (
                <Tooltip title={Label} placement="right">
                  <div
                    style={{
                      flex: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {Label}
                  </div>
                </Tooltip>
              ) : (
                <div
                  style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {Label}
                </div>
              )}
            </Row>
          ),
          value: Value,
          searchableValues: Label,
        })) || [];
    if (
      selectedAction?.Action === "copy_value" &&
      selectedAction[KeyToFilter] !== 0 &&
      (!dataType || dataType === "string")
    ) {
      return [
        {
          label: "Título del seguimiento",
          value: 0,
          separator: fields.length > 0,
        },
        ...fields,
      ];
    }
    return fields;
  };

  const onChangeField = (value: any, key: string) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux[key] = value;
      if (value === 0 && actionAux.Action === "copy_value") {
        actionAux.SubAction = "copy_process_name";
      }
      if (actionAux.IdField1 !== 0 && actionAux.IdField2 !== 0) {
        actionAux.SubAction = null;
      }
      if (actionAux.Action === "substract_fields") {
        if (getIfDateFields(actionAux)) {
          actionAux.SubAction = "substract_date";
          if (!actionAux.DateConfig && key !== "DateConfig") {
            actionAux.DateConfig = "minutes";
          }
        } else {
          actionAux.SubAction = null;
          delete actionAux.DateConfig;
        }
      }
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  return (
    <ViewContentAction>
      <ViewHeader
        IdNode={IdNode}
        reloadHome={reloadHome}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
        icon={actionIcon[selectedAction?.Action || ""]}
        actionOptions={actionOptions}
      />
      <div>
        <ActionFormTitle>
          {select1Label[selectedAction?.Action || ""]}
        </ActionFormTitle>
        <GeestSelect
          options={getFieldOptions("IdField2")}
          onChange={(value) => onChangeField(value, "IdField1")}
          value={selectedAction?.IdField1}
          placeholderSelect="Nombre del campo 1"
          $width="330px"
          required={selectedAction?.IdField1 !== 0}
        />
      </div>
      <div>
        <ActionFormTitle>
          {select2Label[selectedAction?.Action || ""]}
        </ActionFormTitle>
        <GeestSelect
          options={getFieldOptions("IdField1")}
          onChange={(value) => onChangeField(value, "IdField2")}
          value={selectedAction?.IdField2}
          placeholderSelect="Nombre del campo 2"
          $width="330px"
          required={selectedAction?.IdField2 !== 0}
        />
      </div>
      {selectedAction?.Action !== "copy_value" && (
        <div>
          <ActionFormTitle>Selecciona campo para guardar</ActionFormTitle>
          <GeestSelect
            options={getFieldOptions("")}
            onChange={(value) => onChangeField(value, "IdFieldToSave")}
            value={selectedAction?.IdFieldToSave}
            placeholderSelect="Nombre del campo de destino"
            $width="330px"
            required
          />
        </div>
      )}
      {selectedAction?.SubAction === "substract_date" && (
        <div>
          <ActionFormTitle>Entregar resultado en:</ActionFormTitle>
          <DateConfigContainer>
            <DateConfigItem
              $selected={selectedAction?.DateConfig === "minutes"}
              onClick={() => onChangeField("minutes", "DateConfig")}
            >
              minutos
            </DateConfigItem>
            <DateConfigItem
              $selected={selectedAction?.DateConfig === "hours"}
              onClick={() => onChangeField("hours", "DateConfig")}
            >
              horas
            </DateConfigItem>
            <DateConfigItem
              $selected={selectedAction?.DateConfig === "days"}
              onClick={() => onChangeField("days", "DateConfig")}
            >
              días
            </DateConfigItem>
            <DateConfigItem
              $selected={selectedAction?.DateConfig === "weeks"}
              onClick={() => onChangeField("weeks", "DateConfig")}
              style={{ borderRight: "0px", borderRadius: "0px 6px 6px 0px" }}
            >
              semanas
            </DateConfigItem>
          </DateConfigContainer>
        </div>
      )}
    </ViewContentAction>
  );
};

export default FieldOperationActionView;
