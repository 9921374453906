import React from "react";
import { Row } from "antd";
import * as Yup from "yup";
import Modal from "@material-ui/core/Modal";
import { IoClose } from "react-icons/io5";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import {
  EmailInput,
  Message,
  PopoverTitle,
  StyledPaper,
  StyledSubmitButton,
} from "./Style";
import { Languages } from "../Dictionary";
import { useMutation } from "../../../hooks";

const RestorePassword = () => {
  const history = useHistory();
  const {
    RestorePasswordLabel,
    EnterEmailMessage,
    EnterEmailPlaceholder,
    SendLinkLabel,
    EmailValidation,
    FieldRequired,
  } = Languages["ESP"];

  const [submit, submitting] = useMutation({
    func: "Ver2-Session-crpr",
    onSuccess: () => {
      history.push("/auth/login");
    },
  });

  const onSubmit = ({ email }) => {
    submit({
      args: { Email: email },
    });
  };

  const isValidForm = (formik) =>
    !(formik.touched.email && formik.errors.email);

  const closeModal = () => history.push("/auth/login");

  return (
    <Modal open onClose={closeModal}>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          height: "100%",
        }}
        onClick={closeModal}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={onSubmit}
            validationSchema={Yup.object({
              email: Yup.string()
                .email(EmailValidation)
                .required(FieldRequired),
            })}
          >
            {(formik) => (
              <Form>
                <PopoverTitle>
                  <p>{RestorePasswordLabel}</p>
                  <div className="icon-container" onClick={closeModal}>
                    <IoClose color="#48505e" size={20} />
                  </div>
                </PopoverTitle>
                <div style={{ padding: "0 1rem", marginBottom: "2rem" }}>
                  <Message>{EnterEmailMessage}</Message>
                  <EmailInput
                    placeholder={EnterEmailPlaceholder}
                    id="email"
                    name="email"
                    type="email"
                    disabled={submitting}
                    $error={!isValidForm(formik)}
                  />
                  <Row justify="center">
                    <StyledSubmitButton
                      disabled={!isValidForm(formik)}
                      loading={submitting}
                      type="submit"
                    >
                      {SendLinkLabel}
                    </StyledSubmitButton>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default RestorePassword;
