import React, { useContext, useState, useEffect } from "react";
import _, { noop as NOOP } from "lodash";
import { Popover, Tag } from "antd";
import { Button } from "../../../../GeestUI";
import { Languages } from "../../Dictionary";
import { TeamMember, TeamRole } from "../../Teams.d";
import {
  ActionsButton,
  ActionsContainer,
  MemberContainer,
  MembersRow,
  MembersRowCell,
  MembersRowCellFlex,
  RolesContainer,
} from "./Style";
import RolesPopover from "./RolesPopover";
import { useMutation } from "../../../../hooks";
import PermissionsPopover from "./RolesPopover/PermissionsPopover";
import ActionsPopover from "./RolesPopover/ActionsPopover";
import { SesionContext } from "../../../../config/State";
import User from "./User";
import { MessagesContext } from "../../../../components/AppMessages";
import { DeleteIcon } from "../../../../components/hoverIcons";
import { ReactComponent as ActionIcon } from "../../../../resources/img/actionsIcon.svg";
import ReplaceUserPopover from "../Modals/ReplaceUserPopover";
import ReasignTasksPopover from "../Modals/ReasignTasksPopover";
const {
  RoleSelectPlaceHolder,
  Messages: { SuccessfulUpdatedRoles },
} = Languages["ESP"];

interface MemberParams {
  idTeam: string;
  teamMember: TeamMember;
  teamMembers: TeamMember[];
  teamRoles: TeamRole[];
  teamLeaderId: number | null;
  canEdit: boolean;
  isConsultor: (userId: number) => boolean;
  openExpellUser: () => void;
  setActionsUser: (user: TeamMember) => void;
  reload: () => void;
  setNoAccountUser: (arg: string) => void;
  openRegisterNewUser: () => void;
}

const Member: React.FC<MemberParams> = ({
  idTeam,
  teamMember,
  teamRoles,
  teamLeaderId,
  canEdit,
  teamMembers,
  isConsultor,
  openExpellUser,
  setActionsUser,
  reload,
  setNoAccountUser,
  openRegisterNewUser,
}) => {
  const [sesion] = useContext<any>(SesionContext);
  const { showMessage } = useContext(MessagesContext);
  const [selectedRoles, setSelectedRoles] = useState<TeamRole[]>(
    teamMember.UserRoles || []
  );
  const [editingRoles, setEditingRoles] = useState<boolean>(false);
  const [editingPermissions, setEditingPermissions] = useState<boolean>(false);
  const [actionsVisible, setActionsVisible] = useState<boolean>(false);
  const [replacingUser, setReplacingUser] = useState<boolean>(false);
  const [reasigningUser, setReasigingUser] = useState<boolean>(false);
  const consultor = isConsultor(teamMember.IdUser);

  useEffect(() => {
    setSelectedRoles(teamMember.UserRoles);
  }, [teamMember.UserRoles]);

  const [assignTeamRole] = useMutation<[]>({
    func: "Ver2-Team-atr",
    onSuccess: () => {
      showMessage(SuccessfulUpdatedRoles, "success");
      reload();
    },
  });

  const [changeTeamLeader] = useMutation({
    func: "Ver2-Team-utl",
    onSuccess: reload,
  });

  const onChangeTeamLeader = (idUser: number) => {
    changeTeamLeader({
      args: {
        IdTeam: idTeam,
        IdUser: idUser,
      },
    });
  };

  const handleSubmit = (actualRoles: TeamRole[], newRoles: TeamRole[]) => {
    assignTeamRole({
      args: {
        IdTeam: idTeam,
        IdUser: teamMember.IdUser,
        UserRoles: newRoles,
      },
    });
  };

  const onChangeRoles = (actualRoles: TeamRole[], newRoles: TeamRole[]) => {
    setSelectedRoles(newRoles);
    if (!_.isEmpty(newRoles)) handleSubmit(actualRoles, newRoles);
  };

  const handleDeleteUser = () => {
    setActionsUser(teamMember);
    openExpellUser();
  };

  return (
    <MembersRow>
      <MembersRowCell style={{ width: "350px" }}>
        <MemberContainer>
          <User
            user={teamMember}
            size="middle"
            nameColor={consultor ? "#0867ad" : ""}
            tooltipValue={`${teamMember.FirstName} ${teamMember.LastName} (${teamMember.Email})`}
            justify="start"
          />
        </MemberContainer>
      </MembersRowCell>

      <MembersRowCellFlex>
        <Popover
          trigger="click"
          overlayStyle={{
            borderRadius: "6px",
            padding: "0",
            overflow: "hidden",
            boxShadow: "0px 0px 30px #cfcdcd",
            width: "363px",
          }}
          destroyTooltipOnHide
          open={editingRoles}
          onOpenChange={canEdit ? setEditingRoles : NOOP}
          content={() => (
            <RolesPopover
              idTeam={idTeam}
              value={selectedRoles}
              teamRoles={teamRoles}
              isLeader={teamMember.IdUser === teamLeaderId}
              onChange={onChangeRoles}
              onClose={() => setEditingRoles(false)}
              reload={reload}
            />
          )}
        >
          <RolesContainer onClick={() => setEditingRoles(canEdit)}>
            {selectedRoles.map((role, i) => (
              <Tag
                key={`${teamMember.IdUser}-${role.IdTeamRole}`}
                color={role.Color || "#c0c9d3"}
                style={{
                  borderRadius: "6px",
                  height: "30px",
                  color: "white",
                  fontFamily: "Gotham-Bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px 10px",
                }}
              >
                {role.Label}
              </Tag>
            ))}
            {_.isEmpty(selectedRoles) && RoleSelectPlaceHolder}
          </RolesContainer>
        </Popover>
      </MembersRowCellFlex>

      {canEdit && (
        <MembersRowCell style={{ width: "152px" }}>
          <Popover
            trigger="click"
            overlayStyle={{
              borderRadius: "6px",
              padding: "0",
              overflow: "hidden",
              boxShadow: "0px 0px 30px #cfcdcd",
              width: "350px",
            }}
            destroyTooltipOnHide
            open={editingPermissions}
            onOpenChange={setEditingPermissions}
            placement="leftTop"
            content={() => (
              <PermissionsPopover
                idTeam={idTeam}
                idUser={teamMember.IdUser}
                canEdit={canEdit}
                isLeader={teamMember.IdUser === teamLeaderId}
                memberName={`${teamMember.FirstName} ${teamMember.LastName}`}
                memberPermissions={teamMember.Permissions}
                onClose={() => setEditingPermissions(false)}
                reload={reload}
              />
            )}
          >
            <Button type="primary" onClick={() => setEditingPermissions(true)}>
              Permisos
            </Button>
          </Popover>
        </MembersRowCell>
      )}

      {canEdit && (
        <MembersRowCell style={{ width: "152px" }}>
          <ActionsContainer>
            <Popover
              trigger="click"
              overlayStyle={{
                borderRadius: "6px",
                padding: "0",
                overflow: "hidden",
                boxShadow: "0px 0px 30px #cfcdcd",
                width: "432px",
                height: "fit-content",
              }}
              destroyTooltipOnHide
              open={actionsVisible}
              onOpenChange={setActionsVisible}
              placement="left"
              content={() => (
                <ActionsPopover
                  isLeader={teamMember.IdUser === teamLeaderId}
                  openReplaceUser={() => {
                    setReplacingUser(true);
                    setActionsVisible(false);
                  }}
                  openReasignTasks={() => {
                    setReasigingUser(true);
                    setActionsVisible(false);
                  }}
                  setActionsUser={() => setActionsUser(teamMember)}
                  onClose={() => setActionsVisible(false)}
                  canChangeLeader={sesion.Id === teamLeaderId}
                  onChangeLeader={() => onChangeTeamLeader(teamMember.IdUser)}
                />
              )}
            >
              <Popover
                trigger="click"
                overlayStyle={{
                  borderRadius: "6px",
                  padding: "0",
                  overflow: "hidden",
                  boxShadow: "0px 0px 30px #cfcdcd",
                  width: "432px",
                  height: "fit-content",
                }}
                destroyTooltipOnHide
                open={replacingUser}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setReplacingUser(false);
                  }
                }}
                placement="left"
                content={() => (
                  <ReplaceUserPopover
                    reload={reload}
                    idTeam={idTeam}
                    teamMembers={teamMembers}
                    title={`Reemplazar a ${teamMember.FirstName} ${teamMember.LastName}`}
                    okText="Confirmar"
                    cancelText="Cancelar"
                    setNoAccountUser={setNoAccountUser}
                    openRegisterNewUser={openRegisterNewUser}
                    onClose={() => setReplacingUser(false)}
                    user={teamMember}
                  />
                )}
              >
                <Popover
                  trigger="click"
                  overlayStyle={{
                    borderRadius: "6px",
                    padding: "0",
                    overflow: "hidden",
                    boxShadow: "0px 0px 30px #cfcdcd",
                    width: "432px",
                    height: "fit-content",
                  }}
                  destroyTooltipOnHide
                  open={reasigningUser}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setReasigingUser(false);
                    }
                  }}
                  placement="left"
                  content={() => (
                    <ReasignTasksPopover
                      reload={reload}
                      idTeam={idTeam}
                      teamMembers={teamMembers}
                      title={`Reasignar tareas de ${teamMember.FirstName} ${teamMember.LastName}`}
                      okText="Confirmar"
                      cancelText="Cancelar"
                      onClose={() => setReasigingUser(false)}
                      user={teamMember}
                    />
                  )}
                >
                  <ActionsButton onClick={() => setActionsVisible(true)}>
                    <ActionIcon />
                  </ActionsButton>
                </Popover>
              </Popover>
            </Popover>

            {teamMember.IdUser !== teamLeaderId &&
            teamMember.IdUser !== +sesion.Id ? (
              <DeleteIcon
                onClick={handleDeleteUser}
                tabIndex={0}
                onKeyDown={({ key }) => {
                  if (key === "Enter") handleDeleteUser();
                }}
                filled
              />
            ) : (
              <div style={{ width: "20px", height: "20px" }} />
            )}
          </ActionsContainer>
        </MembersRowCell>
      )}
    </MembersRow>
  );
};

export default Member;
