import {
  EditorState,
  convertToRaw,
  getDefaultKeyBinding,
  RichUtils,
  DraftHandleValue,
  KeyBindingUtil,
  Modifier,
  ContentBlock,
  SelectionState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import BlockRoot from "../Components/BlockRoot";
import { SyntheticKeyboardEvent } from "react-draft-wysiwyg";
import { nanoid } from "nanoid";

const { hasCommandModifier } = KeyBindingUtil;

const COMMANDS = {
  ENTER_COMMAND: "enter_command",
  BACKSLASH_COMMAND: "backslash_command",
  CTRL_SPACE_COMMAND: "ctrl_space_command",
};

export const editorStateToHTML = (editorState: EditorState) => {
  const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  return html ?? "error";
};

export const editorStateRaw = (editorState: EditorState) => {
  const raw = convertToRaw(editorState.getCurrentContent());
  return raw ?? "error";
};

export const customBlockRenderer = (block: any) => {
  if (block.getType() === "atomic") {
    return {
      component: BlockRoot,
      editable: false,
    };
  }

  return null;
};

export const addFieldEntity = (
  editorState: EditorState,
  handleOnChange: (newEditorState: EditorState) => void
) => {
  let selection = editorState.getSelection();

  const newId = nanoid();

  const emptyField = {
    IdGeestField: newId,
    IdFieldSelected: null,
    DataType: "",
    FieldLabel: "",
  };

  const entityKey = editorState
    .getCurrentContent()
    .createEntity("FIELD", "IMMUTABLE", emptyField)
    .getLastCreatedEntityKey();

  let contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    selection,
    `[[| FIELD:${newId} |]] `,
    editorState.getCurrentInlineStyle(),
    entityKey
  );

  const newEditorState = EditorState.push(
    editorState,
    contentState,
    "insert-characters"
  );

  handleOnChange(newEditorState);
};

export const keyBindingFn = (
  e: SyntheticKeyboardEvent,
  oneBlock: boolean
): string | null => {
  if (oneBlock) {
    if (e.key === "Enter") {
      return COMMANDS.ENTER_COMMAND;
    }
  }

  if (e.key === "\\") {
    return COMMANDS.BACKSLASH_COMMAND;
  }

  if (e.key === " " /* `Space` key */ && hasCommandModifier(e)) {
    return COMMANDS.CTRL_SPACE_COMMAND;
  }

  // This wasn't the delete key, so we return Draft's default command for this key
  return getDefaultKeyBinding(e);
};

export const handleKeyCommand = (
  command: string,
  editorState: EditorState,
  handleOnChange: (newEditorState: EditorState) => void,
  oneBlock: boolean,
  canAddFields: boolean
): DraftHandleValue => {
  if (oneBlock && command === COMMANDS.ENTER_COMMAND) {
    return "handled";
  }

  if (command === COMMANDS.BACKSLASH_COMMAND) {
    return "handled";
  }

  if (canAddFields && command === COMMANDS.CTRL_SPACE_COMMAND) {
    addFieldEntity(editorState, handleOnChange);
    return "handled";
  }

  const newEditorState = RichUtils.handleKeyCommand(editorState, command);
  if (newEditorState) {
    handleOnChange(newEditorState);
    return "handled";
  }

  return "not-handled";
};

export const handleReturn = (
  event: SyntheticKeyboardEvent,
  editorState: EditorState
) => {
  if (event.shiftKey) {
    return true;
  }
  return false;
};

export const getLengthOfSelectedText = (editorState: EditorState) => {
  const currentSelection = editorState.getSelection();
  const isCollapsed = currentSelection.isCollapsed();

  let length = 0;

  if (!isCollapsed) {
    const currentContent = editorState.getCurrentContent();
    const startKey = currentSelection.getStartKey();
    const endKey = currentSelection.getEndKey();
    const startBlock = currentContent.getBlockForKey(startKey);
    const isStartAndEndBlockAreTheSame = startKey === endKey;
    const startBlockTextLength = startBlock.getLength();
    const startSelectedTextLength =
      startBlockTextLength - currentSelection.getStartOffset();
    const endSelectedTextLength = currentSelection.getEndOffset();
    const keyAfterEnd = currentContent.getKeyAfter(endKey);
    if (isStartAndEndBlockAreTheSame) {
      length +=
        currentSelection.getEndOffset() - currentSelection.getStartOffset();
    } else {
      let currentKey = startKey;

      while (currentKey && currentKey !== keyAfterEnd) {
        if (currentKey === startKey) {
          length += startSelectedTextLength + 1;
        } else if (currentKey === endKey) {
          length += endSelectedTextLength;
        } else {
          length += currentContent.getBlockForKey(currentKey).getLength() + 1;
        }

        currentKey = currentContent.getKeyAfter(currentKey);
      }
    }
  }

  return length;
};

export const getContentLength = (editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent();
  const currentContentLength = currentContent.getPlainText("").length;
  return currentContentLength;
};

export const handleBeforeInput = (
  editorState: EditorState,
  maxLength: number | null,
  limitReachedMessage: () => void
): DraftHandleValue | void => {
  if (maxLength !== null) {
    const currentContentLength = getContentLength(editorState);
    const selectedTextLength = getLengthOfSelectedText(editorState);

    if (currentContentLength - selectedTextLength > maxLength - 1) {
      limitReachedMessage();
      return "handled";
    }
  }
  return "not-handled";
};

export const handlePastedText = (
  text: string,
  html: string,
  editorState: EditorState,
  onChange: (editorState: EditorState) => void,
  maxLength: number | null,
  limitReachedMessage: () => void
): DraftHandleValue | void => {
  if (maxLength !== null) {
    const currentContentLength = getContentLength(editorState);
    const selectedTextLength = getLengthOfSelectedText(editorState);

    if (currentContentLength + text.length - selectedTextLength > maxLength) {
      limitReachedMessage();
      return "handled";
    }
  }

  return "not-handled";
};

export function findWithRegex(
  regex: RegExp,
  contentBlock: ContentBlock | undefined,
  callback: (start: number, end: number) => void
) {
  if (contentBlock) {
    const text = contentBlock.getText();
    let matchArr, start, end;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      end = start + matchArr[0].length;
      callback(start, end);
    }
  }
}

export const removeBackslash = (editorState: EditorState) => {
  const selectionsToReplace: SelectionState[] = [];
  const blockMap = editorState.getCurrentContent().getBlockMap();

  blockMap.forEach((contentBlock) =>
    findWithRegex(/\\/g, contentBlock, (start: number, end: number) => {
      if (contentBlock) {
        const blockKey = contentBlock.getKey();
        const blockSelection = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: start,
          focusOffset: end,
        });
        selectionsToReplace.push(blockSelection);
      }
    })
  );

  let contentState = editorState.getCurrentContent();

  selectionsToReplace.forEach((selectionState) => {
    contentState = Modifier.replaceText(contentState, selectionState, "");
  });

  const newEditorState = EditorState.push(
    editorState,
    contentState,
    "insert-characters"
  );

  return newEditorState;
};
