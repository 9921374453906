import React from "react";
import {
  SuccessfulContainer,
  SuccesfulTitle,
  SuccesfulSubtitle,
} from "./Style";
import successIcon from "../../resources/img/succesfullICon.svg";

const Successful: React.FC = () => (
  <SuccessfulContainer>
    <SuccesfulTitle>Se ha enviado tu información</SuccesfulTitle>
    <SuccesfulSubtitle>¡Gracias por llenar!</SuccesfulSubtitle>
    <img alt="éxito" src={successIcon} width="114px" height="114px" />
  </SuccessfulContainer>
);

export default Successful;
