import React, { useState } from "react";
import { useFetch } from "../../../../hooks";
import styled from "styled-components";
import { TeamMember } from "../../Dashboard/Dashboard.d";
import User from "../User";
import { CloseIcon } from "../../../../components/hoverIcons";
import { Row } from "antd";

const Languages = {
  ENG: { TitleLabel: "Involved" },
  ESP: { TitleLabel: "Involucrados" },
};

const { TitleLabel } = Languages["ESP"];

export const Title = styled.div`
  font-size: 16px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  margin-bottom: 1rem;
`;

interface ResponsiblesProps {
  processId: number | null;
  onClose?: () => void;
}

const Responsibles: React.FC<ResponsiblesProps> = ({ processId, onClose }) => {
  const [members, setMembers] = useState<TeamMember[]>([]);

  useFetch<TeamMember[]>({
    func: "Ver2-Reports-gpui",
    args: {
      IdProcessExecution: processId,
    },
    onSuccess: (res) => setMembers(res),
  });

  return (
    <div>
      <Row justify="space-between">
        <Title>{TitleLabel}</Title>
        <CloseIcon onClick={onClose} />
      </Row>
      {(members || []).map((member) => (
        <div style={{ marginBottom: "0.5rem" }} key={member.IdUser}>
          <User user={member} size="large" justify="start" />
        </div>
      ))}
    </div>
  );
};

export default Responsibles;
