import React from "react";
import clsx from "clsx";
import {
  ModalTabContainer,
  StepLabelValue,
  StepperConnector,
  useDotStyles,
} from "./styles";
import { Stepper, Step, StepLabel } from "@material-ui/core";

interface ModalTabProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
  steps: string[];
  linear?: boolean;
  canGoNext?: boolean;
}

interface StepDotProps {
  active: boolean;
  completed: boolean;
}

function StepDot(props: StepDotProps) {
  const { active, completed } = props;
  const classes = useDotStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    ></div>
  );
}

const ModalTab: React.FC<ModalTabProps> = ({
  activeStep,
  setActiveStep,
  steps,
  linear = false,
  canGoNext,
}) => {
  const handleOnClickStep = (idx: number) => {
    if (!linear) {
      setActiveStep(idx);
      return;
    }
    if (!canGoNext) return;
    if (idx > activeStep + 1) return;
    setActiveStep(idx);
  };

  return (
    <ModalTabContainer>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={
          <StepperConnector style={{ width: "100%", marginLeft: "-11.5px" }} />
        }
      >
        {steps.map((step, idx) => (
          <Step key={step} onClick={() => handleOnClickStep(idx)}>
            <StepLabel StepIconComponent={StepDot}>
              <StepLabelValue active={activeStep >= idx}>{step}</StepLabelValue>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </ModalTabContainer>
  );
};

export default ModalTab;
