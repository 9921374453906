export const Languages = {
  ENG: {
    SelectLabel: "Selecciona",
    LoadingLabel: "Loading",
    NoResultsLabel: "No results",
    LocPlaceholder: "Search location",
    LocationTooltipTitle: "Get current location",
    LocationTooltipClear: "Clear current location",
    CalculatingLabel: "Calculating...",
    ObtainingLabel: "Obtaining...",
    LocDefaultButtonLabel: "Get current location",
    SearchPlaceholder: "Search",
    AddOtherOption: "Write other option",
    Add: "Add",
    NewElementLabel: "New",
    NewElementButtonLabel: "New element",
    DownloadLabel: "Download",
    PrintLabel: "Print",
    VizualizeLabel: "Vizualize",
    FileNameExists: "There's already a file with this name",
    FileSizeError: (Limit) => `File size must be less than ${Limit}MB`,
    InvalidFileName: (symbol) => `Filename cannot contain (${symbol})`,
    NoMoreThan: "You can't have more than 20 files",
    UploadFileLabel: "Upload file",
    NewFolioLabel: "New",
    RequiredField: "Required field",
    CurrencyIncomInfo: "The currency type was set up from My Companies",
  },
  ESP: {
    SelectLabel: "Selecciona",
    LoadingLabel: "Cargando",
    NoResultsLabel: "Sin resultados",
    LocPlaceholder: "Buscar ubicación...",
    LocationTooltipTitle: "Recabar ubicación actual",
    LocationTooltipClear: "Limpiar ubicación actual",
    CalculatingLabel: "Calculando...",
    ObtainingLabel: "Obteniendo...",
    LocDefaultButtonLabel: "Recabar ubicación actual",
    SearchPlaceholder: "Buscar",
    AddOtherOption: "Escribe otra opción",
    Add: "Agregar",
    NewElementLabel: "Nuevo",
    NewElementButtonLabel: "Nuevo elemento",
    DownloadLabel: "Descargar",
    PrintLabel: "Imprimir",
    VizualizeLabel: "Visualizar",
    FileNameExists: "Ya existe un archivo con ese nombre",
    FileSizeError: (Limit) =>
      `El tamaño del archivo debe ser menor que ${Limit}MB`,
    InvalidFileName: (symbol) =>
      `El nombre del archivo no puede contener (${symbol})`,
    NoMoreThan: "No puedes tener más de 20 archivos",
    UploadFileLabel: "Subir archivo",
    NewFolioLabel: "Nuevo",
    RequiredField: "Campo requerido",
    CurrencyIncomInfo: "El tipo de divisa fue configurado desde Mis Empresas",
  },
};
