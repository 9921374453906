import React from "react";
import { Modal as AntdModal } from "antd";
import styled from "styled-components";
import { noop as NOOP } from "lodash";
import { CloseIcon } from "../hoverIcons";

const StyledModal = styled(AntdModal)`
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
`;

const Header = styled.div`
  font-size: 16px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

const Title = styled.div`
  margin-right: 0.5rem;
  flex: 1;
  overflow: hidden;
  font-size: 16px;
  font-family: Gotham-Bold;
`;

const Content = styled.div`
  ${({ ContentFlex }) => (ContentFlex ? `flex: 1;` : "")}
  overflow: auto;
  font-size: 14px;
`;

const Modal = ({
  children,
  title,
  titleNode = null,
  bodyStyle = {},
  ContentFlex = true,
  visible = true,
  showClose = true,
  titleColor = "#48505e",
  titleStyles = {},
  contentStyles = {},
  ...props
}) => {
  const { onCancel = NOOP } = props;
  return (
    <StyledModal
      open={visible}
      footer={null}
      closable={false}
      centered
      bodyStyle={{
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Gotham-book",
        padding: "12px",
        ...bodyStyle,
      }}
      {...props}
    >
      {!!titleNode ? (
        titleNode()
      ) : (
        <Header style={titleStyles}>
          <Title style={{ color: titleColor }}>{title}</Title>
          {showClose && (
            <CloseIcon size={20} onClick={onCancel} color="#48505e" />
          )}
        </Header>
      )}
      <Content style={contentStyles} ContentFlex={ContentFlex}>
        {children}
      </Content>
    </StyledModal>
  );
};

export default Modal;
