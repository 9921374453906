import React from "react";
import _, { noop as NOOP } from "lodash";
import { Space, Switch } from "antd";
import { useMediaQuery } from "@material-ui/core";
import Modal from "../../../../../components/Modal";
import { Header, Content, ModeLabel } from "./styles";
import { device } from "../../../../../constants/css";
import PlansTable from "../../../../../components/PlansTable";
import { getPlanColors } from "../../../helpers";

const PlansModal = ({
  visible = false,
  planSelected = {},
  plansData = {},
  planModes = {},
  closeModal = NOOP,
  onSelectPlan = NOOP,
  onChangePlanMode = NOOP,
}) => {
  const isMobile = !useMediaQuery(device.tablet);
  const { mode, plan } = planSelected;
  const { mainColor } = getPlanColors(plan?.Name || "");

  const handleOnChange = (value) => {
    const values = {
      true: planModes.annually,
      false: planModes.monthly,
    };
    onChangePlanMode(values[value]);
  };

  return (
    <Modal
      width={isMobile ? "90%" : "70%"}
      visible={visible}
      onCancel={closeModal}
    >
      <Header justify="center" $planColor={mainColor}>
        <Space align="middle">
          <ModeLabel
            active={mode === planModes.monthly}
            onClick={() => onChangePlanMode(planModes.monthly)}
          >
            Mensual
          </ModeLabel>
          <Switch
            checked={mode === planModes.annually}
            onChange={handleOnChange}
          />
          <ModeLabel
            active={mode === planModes.annually}
            onClick={() => onChangePlanMode(planModes.annually)}
          >
            Anual
          </ModeLabel>
        </Space>
      </Header>
      <Content>
        {!_.isEmpty(plansData) && (
          <PlansTable
            {...plansData}
            PriceType={mode}
            onSelectPlan={onSelectPlan}
            activePlan={plan.Name}
          />
        )}
      </Content>
    </Modal>
  );
};

export default PlansModal;
