import styled from "styled-components";
import { Paper } from "@material-ui/core";
import searchIMG from "../../../../resources/img/SEARCH_TASKS.svg";
import { Checkbox } from "antd";

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const StyledPaper = styled(Paper)`
  border-radius: 10px !important;
  height: 87vh;
  max-height: 90vh;
  width: 961px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  padding: 12px;
  padding-bottom: 0;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: calc(100% - 72px);
  max-height: 73vh;
`;

export const NavigationSection = styled.div<{ $highlight?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 4px;
  padding-bottom: 8px;

  border-bottom: 1px solid #edecec;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 4px;
    height: calc(100% - 8px);
    border-radius: 8px;
    background-color: ${({ $highlight }) =>
      $highlight ? "#0273E9" : "transparent"};
  }
`;

export const NavigationHeader = styled.div<{ $highlight?: boolean }>`
  display: flex;
  padding: 10px;
  cursor: pointer;

  p {
    color: ${({ $highlight }) =>
      $highlight ? "rgba(2, 115, 233, 1)" : "#48505e"};
    font-family: Gotham-Bold;
    font-size: 14px;
    margin: 0;
    user-select: none;
  }
`;

export const NavigationItem = styled.div<{ $highlight?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  padding: 6px 10px;

  transition: background-color 0.35s ease;
  :hover {
    background-color: #edecec;
  }

  p {
    color: ${({ $highlight }) =>
      $highlight ? "rgba(2, 115, 233, 1)" : "#48505e"};
    font-family: ${({ $highlight }) =>
      $highlight ? "Gotham-Bold" : "Gotham-Book"};
    font-size: 12px;
    margin: 0;
    user-select: none;
  }
`;

export const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 236px;
  padding-left: 24px;
  max-height: 100%;
  overflow: auto;
`;

export const FieldsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  position: sticky;
  top: 0;

  background-color: #f8f8f8;
  padding-inline: 12px;
  padding-top: 16px;
  z-index: 2;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 8px;
`;

export const SearchInput = styled.input`
  background-image: url(${searchIMG});
  background-repeat: no-repeat;
  background-size: 16px;
  background-position-y: center;
  background-position-x: 8px;
  background-color: white;

  border-radius: 6px;
  border: 2px solid #edecec;
  color: #828d9e;

  width: 100%;
  min-height: 30px;
  resize: none;
  padding: 6px 10px;
  padding-left: 30px;

  margin-bottom: 8px;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const SectionTitle = styled.h2`
  color: #48505e;
  font-family: Gotham-Bold;
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

export const ElementName = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  p {
    color: #48505e;
    font-family: Gotham-Bold;
    font-size: 12px;
    margin: 0;
    margin-top: 2px;
  }
`;

export const ElementData = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

export const ElementIconInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  margin-top: -2px;

  span {
    margin-top: 1px;
    font-size: 10px;
    color: #828d9e;
  }
`;

export const ColumnCategory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InfoIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 100px;
  color: #0273e9;
`;

export const ColumnCategoryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #edecec;
  padding: 7px 12px;
  width: 100%;

  p {
    color: #48505e;
    font-family: Gotham-Bold;
    font-size: 12px;
    margin: 0;
    margin-top: 2px;
  }
`;

export const ColumnDivider = styled.div`
  display: flex;
  align-items: center;
  height: 29px;
  padding: 6px;
  border-bottom: 1px solid #d9d9d9;
  margin-inline: 12px;

  p {
    color: #48505e;
    font-family: Gotham-Bold;
    font-size: 12px;
    margin: 0;
  }
`;

export const ColumnItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 24px;
  min-height: 35px;
  color: #48505e;
  font-family: Gotham-Bold;
  max-width: 300px;

  cursor: pointer;

  p {
    margin: 0;
    user-select: none;
    font-size: 12px;
    margin-top: 2px;

    overflow: hidden;
    textoverflow: ellipsis;
    whitespace: nowrap;
  }
`;

export const ColumnFormField = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;

  p {
    margin: 0;
    margin-left: 4px;
    font-size: 12px;

    overflow: hidden;
    textoverflow: ellipsis;
    whitespace: nowrap;
  }

  span {
    color: #828d9e;
    font-size: 10px;
    font-family: Gotham-Book;
  }
`;

export const ColumnFormType = styled.div`
  display: flex;
  align-items: center;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  max-height: 100%;
  overflow: auto;
  background-color: #f8f8f8;
  border-radius: 6px;
`;

export const ColumnItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: auto;
  justify-content: flex-start;
  padding: 0;
`;

export const StyledCheckbox = styled(Checkbox)<{
  required?: boolean;
}>`
  .ant-checkbox-inner {
    margin: 0;
    background-color: ${({ disabled, checked }) =>
      disabled ? "#e8ecf1" : checked ? "default" : ""};
    border: 1px solid
      ${({ required, checked }) =>
        required
          ? checked
            ? "#1890ff"
            : "#db232c"
          : checked
          ? "#1890ff"
          : "#edecec"};
  }
`;

export const DeleteColumnButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  padding: 3px;
  cursor: pointer;
  background-color: #f2f8fe;
  margin-left: auto;

  transition: background-color 0.35s ease;
  :hover {
    background-color: #cce6ff;
  }
`;

export const ImportedColumnElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ImportedColumnElement = styled.div<{ $mb?: string }>`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #f2f8fe;
  border-radius: 100px;
  padding: 9px 10px;
  height: 42px;
  user-select: none;
  margin-bottom: ${({ $mb }) => $mb};
`;

export const IconContainer = styled.div<{
  m: string;
  $color?: string;
  cursorGrab?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  margin: ${({ m }) => m};
  color: ${({ $color }) => ($color ? $color : "#48505e")};
  cursor: ${({ cursorGrab }) => (cursorGrab ? "grab" : "auto")};
`;

export const ImportedColumns = styled.div`
  flex: 1;
  max-height: 100%;
  overflow: auto;
  padding: 16px 12px;
  padding-bottom: 46px;
`;

export const Footer = styled.div`
  padding: 0 24px 12px 24px;
`;

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 16px 10px;
  height: 48px;
  background-color: #f2f8fe;
  border-radius: 6px;
`;

export const ConfigChecks = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ConfigCheckElement = styled.div<{ $cursor: string }>`
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    color: #48505e;
    font-size: 12px;
    font-family: Gotham-Bold;
    margin: 0;
    user-select: none;
    cursor: ${({ $cursor }) => $cursor};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
