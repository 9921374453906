import { Divider } from "antd";
import _ from "lodash";
import styled, { css } from "styled-components";

// <TaskFields />

export const Body = styled.div`
  display: flex;
  overflow: auto;
  flex: 1;
`;
export const SingleColumn = styled.div`
  flex: 1;
  margin-right: 18px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
export const DoubleColumn = styled.div`
  flex: 2;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
export const SubColumnGroup = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: row;
`;
export const SubColumn = styled.div`
  flex: 1;
  margin-right: 18px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: calc(50% - 10px);
  :last-of-type {
    margin-right: 0;
  }
`;

export const ColumnTitle = styled.span`
  color: #48505e;
  font-family: "Gotham-bold";
  font-size: 14px;
`;
export const StyledDivider = styled(Divider)`
  border-color: #0273e9;
  border-width: 2px;
  margin: 0;
  margin-bottom: 28px;
`;

// <FieldItem />

export const FieldContainer = styled.div<{
  varDBColor?: string;
  isGhostField?: boolean;
  varDB?: boolean;
}>`
  padding: 14px 12px;
  border-radius: 6px;
  border: 2px solid #edecec;
  color: #48505e;
  font-size: 14px;
  cursor: pointer;
  background-color: white;
  user-select: none;

  .actions {
    display: none;
  }

  :hover {
    background-color: #edecec;
    .actions {
      display: flex;
    }
  }

  ${({ varDBColor, varDB }) =>
    !_.isEmpty(varDBColor) &&
    css`
      border-left: 12px solid ${varDBColor};
      padding: ${varDB ? "8px" : "14px 8px"};
    `}
  ${({ isGhostField = false }) =>
    isGhostField &&
    css`
      color: white;
      background-color: #828d9e;
      border-color: #828d9e;
      :hover {
        background-color: #48505e;
        border-color: #48505e;
      }
    `}
`;

export const VarDBLabel = styled.div`
  color: #828d9e;
  font-size: 12px;
`;
