import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import { NewMessages, ChatContainer, SearchBar } from "../ChatList/styles";
import { Typography } from "../../../GeestUI";
import { Row } from "antd";
import { ChatFromUserPendings } from "../Pendings.d";

const { H4, P, B } = Typography;

export const Container = styled.div`
  background: #ffffff;
  overflow-y: auto;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Divider = styled.div`
  height: 2px;
  background: #edecec;
`;

const Chats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const getParsedActivityDate = (date: string) => {
  const isToday =
    moment(date).format("DD/MMM/YY") === moment().format("DD/MMM/YY");
  const isYeserday =
    moment(date).format("MMM/YY") === moment().format("MMM/YY") &&
    Number(moment(date).format("DD")) === Number(moment().format("DD")) - 1;

  if (isToday) {
    return `${moment(date).format("hh:mm A")}`;
  }
  if (isYeserday) {
    return `Ayer ${moment(date).format("hh:mm A")}`;
  }
  return moment(date).format("DD/MMM/YY hh:mm A");
};

const ChatsList: React.FC<{
  chats: ChatFromUserPendings[];
  handleChangeId: (id: number) => void;
  idForChatList: number;
}> = ({ chats, handleChangeId, idForChatList }) => {
  const [activeChat, setActiveChat] = useState<number | null>(idForChatList);
  const [filter, setFilter] = useState<string>("");

  const getFilteredChats = (
    chats: ChatFromUserPendings[]
  ): ChatFromUserPendings[] =>
    _.filter(chats, (chat) =>
      chat.Name.toLowerCase().includes(filter.toLowerCase())
    );

  return (
    <Container>
      <SearchBar
        type="text"
        placeholder="Buscar"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        style={{
          boxShadow: "none",
          border: "0.5px solid rgba(0, 62, 140, 0.1)",
        }}
      />
      <Divider />

      <Chats>
        {getFilteredChats(chats).map((chat, i) => (
          <ChatContainer
            key={`${chat.IdExecutionChat}-${i}`}
            style={
              chat.IdExecutionChat === activeChat
                ? { backgroundColor: "rgba(232, 236, 241, 0.5)" }
                : {}
            }
            onClick={() => {
              setActiveChat(chat.IdExecutionChat);
              handleChangeId(chat.IdExecutionChat);
            }}
          >
            <Row justify="space-between">
              <H4 style={{ color: "#727e8b" }}>{chat.Name}</H4>
              <H4 style={{ color: "#003e8c" }}>
                {getParsedActivityDate(chat.SentAt)}
              </H4>
            </Row>
            <Row align="middle" justify="space-between">
              <div style={{ flex: 1 }}>
                <P mb="0">
                  {!!chat.UserName && <B>{`${chat?.UserName}: `}</B>}
                  {chat.Message}
                </P>
              </div>
              {chat.MessageCount > 0 && (
                <NewMessages>{chat.MessageCount}</NewMessages>
              )}
            </Row>
          </ChatContainer>
        ))}
      </Chats>
    </Container>
  );
};

export default ChatsList;
