import React from "react";
import Modal from "../../../components/Modal";
import { Row } from "antd";
import { Typography, Button } from "../../../GeestUI";

const { P } = Typography;

const ConfirmModal: React.FC<{ onClose: () => void; onCancel: () => void }> = ({
  onClose,
  onCancel,
}) => {
  return (
    <Modal
      open
      visible
      centered
      closable={false}
      footer={null}
      title={"Cambios sin guardar"}
      onCancel={onCancel}
      width="300px"
    >
      <P>Tienes cambios sin guardar, ¿Deseas salir sin iniciar el proceso?</P>
      <Row align="middle" justify="space-between">
        <Button type="secondary" style={{ width: "48%" }} onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="primary" style={{ width: "48%" }} onClick={onClose}>
          Salir
        </Button>
      </Row>
    </Modal>
  );
};

export default ConfirmModal;
