import styled from "styled-components";
import { Row } from "antd";
import { UserInitials } from "../styles";

export const PriorityPopoverContainer = styled.div<{ loading: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: ${({ loading }) => (loading ? "400px" : "0")};

  z-index: 2;
`;

export const PopoverTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  .title {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
      margin: 0;
      font-family: "Gotham-Bold";
      font-size: 16px;
      color: #48505e;
    }
    .icon-box {
      margin-top: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const EditPriorityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

export const NewPriorityButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 3px 0;
  font-family: "Gotham-Bold";
  font-size: 14px;

  cursor: pointer;
  border: 2px solid #0273e9;
  border-radius: 6px;
  background: white;
  color: #0273e9;
  width: 175px;

  transition: background 0.35s ease, border-color 0.35s ease;
  :hover {
    border: 2px solid #014c9b;
    border-radius: 6px;
    background: #edecec;
  }
  :focus {
    border: 2px solid #1939b7;
    border-radius: 6px;
  }
`;

export const PrioritiesContainer = styled.div`
  padding: 0 30px;
`;

export const NewPriorityContainer = styled.div`
  width: 100%;
  padding: 0 18px;
`;

export const PriorityRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const PriorityItemContainer = styled(Row)<{ $selected: boolean }>`
  height: 30px;
  flex: 1;
  background: ${({ $selected }) =>
    $selected ? "var(--silver)" : "transparent"};
  border-radius: 6px;
  padding-left: 10px;
  cursor: pointer;
  transition: background 0.35s ease;
  :hover {
    background: var(--silver);
  }
`;

export const RemoveEditorIconContainer = styled(Row)`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #db232c;
  z-index: 1;
`;

export const MinusIcon = styled.div`
  width: 8px;
  height: 2px;
  background: #ffffff;
`;

export const UserInitialsHover = styled(UserInitials)`
  :hover {
    opacity: 0.8;
  }
`;

export const UserProfilePicHover = styled.div<{ image: string }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  background-image: ${({ image }) => `url("${image}")`};
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  :hover {
    opacity: 0.8;
  }
`;

export const TeamMemberContainer = styled(Row)`
  height: 30px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  flex-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  :hover {
    background: rgba(232, 236, 241, 0.5);
  }
  p {
    width: calc(100% - 25px - 5px);
  }
`;
