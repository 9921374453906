import React from "react";
import styled from "styled-components";
import { ReactComponent as DragDropIconSVG } from "../../resources/img/dragDropIcon.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface DragDropIconProps {
  $width?: string;
  $height?: string;
}

const DragDropIcon: React.FC<DragDropIconProps> = ({
  $width = "14px",
  $height = "14px",
}) => {
  return (
    <IconWrapper>
      <DragDropIconSVG width={$width} height={$height} />
    </IconWrapper>
  );
};

export default DragDropIcon;
