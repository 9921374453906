import React from "react";
import "./styles.css";

const Loading: React.FC<{ width?: number; height?: number }> = ({
  width = 460,
  height = 460,
}) => ( 
  <div id="loading-container">
      <svg width={width} height={height} viewBox="0 0 460 460" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="segment" style={{ '--iteration': 1 } as React.CSSProperties } fill-rule="evenodd" clip-rule="evenodd" d="M5.80287 178.131L30.1721 183.736C26.7878 198.511 25 213.895 25 229.696C25 342.915 116.782 434.696 230 434.696C343.218 434.696 435 342.915 435 229.696C435 185.037 420.719 143.713 396.476 110.042L416.777 95.4501C443.978 133.227 460 179.591 460 229.696C460 356.722 357.025 459.696 230 459.696C102.975 459.696 0 356.722 0 229.696C0 211.968 2.00581 194.708 5.80287 178.131Z" fill="#033E7B"/>
    <path className="segment" style={{ '--iteration': 2 } as React.CSSProperties } fill-rule="evenodd" clip-rule="evenodd" d="M30.1721 183.736C26.7878 198.511 25 213.895 25 229.696C25 342.915 116.782 434.696 230 434.696C343.218 434.696 435 342.915 435 229.696C435 167.952 407.703 112.584 364.519 75L348.114 93.8654C386.032 126.866 410 175.482 410 229.696C410 329.108 329.411 409.696 230 409.696C130.589 409.696 50 329.108 50 229.696C50 215.822 51.5698 202.314 54.5414 189.341L30.1721 183.736Z" fill="#1A5D6A"/>
    <path className="segment" style={{ '--iteration': 3 } as React.CSSProperties } fill-rule="evenodd" clip-rule="evenodd" d="M54.5414 189.341C51.5698 202.314 50 215.822 50 229.696C50 329.108 130.589 409.696 230 409.696C329.411 409.696 410 329.108 410 229.696C410 164.57 375.412 107.522 323.604 75.9185L310.603 97.2765C355.216 124.49 385 173.615 385 229.696C385 315.301 315.604 384.696 230 384.696C144.396 384.696 75 315.301 75 229.696C75 217.749 76.3517 206.117 78.9106 194.946L54.5414 189.341Z" fill="#317D58"/>
    <path className="segment" style={{ '--iteration': 4 } as React.CSSProperties } fill-rule="evenodd" clip-rule="evenodd" d="M78.9106 194.946C76.3517 206.117 75 217.749 75 229.696C75 315.301 144.396 384.696 230 384.696C315.604 384.696 385 315.301 385 229.696C385 183.012 364.361 141.148 331.709 112.731L315.305 131.596C342.69 155.43 360 190.542 360 229.696C360 301.493 301.797 359.696 230 359.696C158.203 359.696 100 301.493 100 229.696C100 219.676 101.134 209.92 103.28 200.551L78.9106 194.946Z" fill="#498D47"/>
    <path className="segment" style={{ '--iteration': 5 } as React.CSSProperties } fill-rule="evenodd" clip-rule="evenodd" d="M103.28 200.551C101.134 209.92 100 219.676 100 229.696C100 301.493 158.203 359.696 230 359.696C301.797 359.696 360 301.493 360 229.696C360 196.657 347.674 166.496 327.371 143.561L308.646 160.125C325.045 178.65 335 203.011 335 229.696C335 287.686 287.99 334.696 230 334.696C172.01 334.696 125 287.686 125 229.696C125 221.603 125.916 213.723 127.649 206.156L103.28 200.551Z" fill="#60AE35"/>
    <path className="segment" style={{ '--iteration': 6 } as React.CSSProperties } fill-rule="evenodd" clip-rule="evenodd" d="M127.649 206.156C125.916 213.723 125 221.603 125 229.696C125 287.686 172.01 334.696 230 334.696C287.99 334.696 335 287.686 335 229.696C335 206.822 327.686 185.656 315.268 168.41L294.966 183.002C304.427 196.142 310 212.268 310 229.696C310 273.879 274.183 309.696 230 309.696C185.817 309.696 150 273.879 150 229.696C150 223.53 150.698 217.527 152.018 211.761L127.649 206.156Z" fill="#78BF24"/>
  </svg>
  </div>
);

export default Loading;
