import React from "react";
import StackBar from "./StackBar";
import DoughnutGraph from "./DoughnutGraph";
import { GraphRenderInfo, GraphType } from "../../Dashboards.d";
//@ts-ignore
import emoji from "emoji-dictionary";
import StatsTable from "./StatsTable";
import { TableData } from "./StatsTable/StatsTable.d";

interface DynamicGraphProps {
  graph: GraphType;
  loading?: boolean;
  onClickBar: (event: any, chartRef: any, renderInfo: GraphRenderInfo) => void;
  onChangePending?: (arr?: any) => void;
  reload?: () => void;
}

const DynamicGraph: React.FC<DynamicGraphProps> = ({
  graph,
  onClickBar,
  onChangePending,
  reload,
}) => {
  if (
    graph.Configuration.Type === "StackBar" ||
    graph.Configuration.Type === "Doughnut"
  ) {
    graph = {
      ...graph,
      RenderInfo: {
        ...(graph.RenderInfo as GraphRenderInfo),
        //@ts-ignore
        datasets: (graph.RenderInfo as GraphRenderInfo).datasets.map((ds) => ({
          ...ds,
          label: ds.label.replace(
            /:\w+:/gi,
            (name: string) => emoji.getUnicode(name) ?? name
          ),
        })),
      },
    };
  }

  switch (graph.Configuration.Type) {
    case "StackBar":
      return (
        <StackBar
          config={graph.Configuration}
          renderInfo={graph.RenderInfo as GraphRenderInfo}
          selectedTemplate={graph.IdGraphTemplate}
          onClickBar={onClickBar}
        />
      );

    case "Doughnut":
      return (
        <DoughnutGraph
          config={graph.Configuration}
          renderInfo={graph.RenderInfo as GraphRenderInfo}
          selectedTemplate={graph.IdGraphTemplate}
        />
      );

    case "Table":
      return (
        <StatsTable
          data={graph.RenderInfo as TableData}
          onChangePending={onChangePending}
          reload={reload}
          execution
        />
      );

    default:
      return null;
  }
};

export default DynamicGraph;
