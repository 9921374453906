import React from "react";
import Modal from "../../../../../components/Modal";
import styled from "styled-components";
import { FiAlertCircle } from "react-icons/fi";
import { Row, Space, Col } from "antd";
import Button from "../../../../../components/Button";
import { Languages } from "../Dictionary";

const Text = styled.div`
  font-size: 15.5px;
  font-family: "Gotham-book";
  line-height: 1.2rem;
  width: 100%;
  text-align: left;
`;

const CancelSubscriptionModal = ({ date, setVisible, onConfirm }) => {
  const { AreYouSure, SubEndingAt, Cancel, Accept } = Languages["ESP"];

  return (
    <Modal visible onCancel={setVisible} width="25%">
      <Col>
        <Space>
          <FiAlertCircle style={{ height: 40, width: 40, color: "red" }} />
          <Col>
            <Text>{AreYouSure}</Text>
            <Text style={{ color: "red" }}>{SubEndingAt(date)}</Text>
          </Col>
        </Space>
        <Row justify="space-around" style={{ marginTop: "1rem" }}>
          <Button
            onClick={setVisible}
            style={{ marginRight: "0.5rem", padding: ".3rem 2.5rem" }}
          >
            {Cancel}
          </Button>
          <Button
            type="primary"
            style={{ padding: ".3rem 2.5rem" }}
            onClick={onConfirm}
          >
            {Accept}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
};

export default CancelSubscriptionModal;
