import styled from "styled-components";

export const FilterStepContainer = styled.div`
  display: flex;
  align-items: flex-start;

  height: 100%;
  max-height: 100%;

  p {
    color: #48505e;
  }
`;

export const OptionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 14px;
    height: 14px;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: #828d9e;
  }
`;

export const LeftPannel = styled.div`
  align-self: stretch;
  padding: 0;
  padding-left: 20px;
  width: 50%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MainTitle = styled.p`
  margin: 0;
  font-size: 24px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

export const FilterByWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const FilterByElement = styled.div<{ filter?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: ${({ filter = false }) =>
    filter ? "calc(100% / 3 * 2 - 5px)" : "calc(100% / 3 + 10px)"};

  p.label {
    margin: 0;
    min-height: 22px;
    height: 22px;
  }
`;

export const ApplyFilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: -11px;

  height: 32px;
  border-radius: 6px;
  border: 2px solid #0273e9;
  background: white;
  color: #0273e9;
  font-family: Gotham-Bold;
  font-size: 14px;
  cursor: pointer;

  transition: all 0.35s ease;
  :hover {
    color: #014c9b;
    border-color: #014c9b;
  }
`;

export const NoAppliedFilters = styled.div`
  overflow: auto;
  flex: 0 0 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: auto;
  }
`;

export const FiltersWrapper = styled.div`
  overflow: auto;
  flex: 0 0 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding-top: 2px;
`;

export const FilterElement = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  position: relative;
  background-color: #f6f5f5;
  border-radius: 6px;
  padding: 4px 14px;

  p.title {
    margin: 0;
    margin-top: 4px;
    padding-right: 10px;
    color: #48505e;
    border-right: 1px solid #48505e;
  }
`;

export const DeleteFilterButton = styled.div`
  position: absolute;
  top: -2px;
  right: -7px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  background-color: #0067b0;
  color: white;
  cursor: pointer;
`;

export const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PrimaryButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-text: center;

  min-width: 187px;
  border-radius: 6px;
  padding: 6px 16px;
  border: none;
  font-family: Gotham-Bold;
  background: ${({ disabled = false }) => (disabled ? "#edecec" : "#0273e9")};
  color: ${({ disabled = false }) => (disabled ? "#828d9e" : "white")};
  cursor: ${({ disabled = false }) => (disabled ? "auto" : "pointer")};

  transition: backgroud 0.35s ease;
  :hover {
    backgroud: #014c9b;
  }
`;

export const RightPannel = styled.div`
  padding 0 20px 20px;
  width: 50%;
  height: 100%;
`;

export const PrevisualizationCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  background: #f6f5f5;
  border-radius: 6px;
  height: 500px;
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(72, 80, 94, 0.3);

  .title {
    margin: 0;
    color: #48505e;
    font-size: 14px;
    font-family: Gotham-Bold;
    text-align: center;
  }
`;

export const GraphPreviewContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;
