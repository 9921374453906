import React, { useState } from "react";
import { MeasurementsProps, Stat } from "./Measurements.d";
import {
  Container,
  StyledInput,
  Title,
  Subtitle,
  ActionRow,
  Content,
  Header,
  LabelItem,
  ActionItem,
  MeasurementItem,
  EmptyContent,
  SubtitleWrapper,
} from "./styles";
import { Button } from "../../../../../../GeestUI";
import { AiOutlinePlus, AiOutlineInfoCircle } from "react-icons/ai";
import { useFetch, useMutation } from "../../../../../../hooks";
import { useParams } from "react-router-dom";
import { sanitizeSearchValues } from "../../../../../../Helpers";
import Tooltip from "../../../../../../components/Tooltip";
import emptyContentImage from "../../../../../../resources/img/emptyStats.svg";
import { DeleteIcon } from "../../../../../../components/hoverIcons";
import Loading from "../../../../../../components/Loading";
import TableIcon from "../table.svg";
import ProcessIcon from "../process.svg";
import DeleteStatModal from "./DeleteStatModal";

const Measurements: React.FC<MeasurementsProps> = ({
  IdProcessTemplate,
  TableName,
  TemplateName,
  setState,
  setSelectedStat,
  onAddStat,
  setPrevEditState,
  stats,
  setFirstTime,
  onRemoveStat,
  openBeforeCreateMeasurement,
}) => {
  const [statToDelete, setStatToDelete] = useState<number | null>(null);
  const [filter, setFilter] = useState<string>("");

  const { ids } = useParams<{ ids: string }>();
  const [idTeam, idDashboard] = ids?.split("-");

  const { data, reload, loading } = useFetch<Stat[]>({
    func: "Ver2-Stats-gts",
    args: {
      IdTeam: idTeam,
      IdDashBoard: idDashboard,
    },
  });

  const [deleteStat] = useMutation({
    func: "Ver2-Stats-ds",
    onSuccess: (res, { IdStat }) => {
      reload();
      setStatToDelete(null);
      if (stats.includes(IdStat)) {
        onRemoveStat(IdStat);
      }
    },
  });

  const sanitizedFilter = sanitizeSearchValues(filter);
  const filteredStats =
    data?.filter(
      ({ Title, ProcessTemplateName }) =>
        sanitizeSearchValues(Title).includes(sanitizedFilter) ||
        sanitizeSearchValues(ProcessTemplateName).includes(sanitizedFilter)
    ) || [];

  const onDeleteStat = (IdStat: number) => {
    deleteStat({
      args: {
        IdTeam: idTeam,
        IdDashBoard: idDashboard,
        IdStat,
      },
      shippedData: { IdStat },
    });
  };

  return (
    <Container>
      {statToDelete && (
        <DeleteStatModal
          onDeleteStat={() => onDeleteStat(statToDelete)}
          closeModal={() => setStatToDelete(null)}
        />
      )}
      <Title $fontSize="24px">
        Para agregar columna, selecciona la medición que vas a usar
      </Title>
      <SubtitleWrapper>
        <Subtitle>
          <img src={TableIcon} alt="" />
          <p>{TableName}</p>
        </Subtitle>
        <Subtitle>
          <img src={ProcessIcon} alt="" />
          <p>{TemplateName}</p>
        </Subtitle>
      </SubtitleWrapper>
      {loading ? (
        <Loading simple />
      ) : !data || data.length === 0 ? (
        <EmptyContent>
          <Subtitle>
            No tienes mediciones aún, da clic en el botón para agregar tu
            primera medición de procesos:
          </Subtitle>
          <Button
            type="primary"
            Icon={AiOutlinePlus}
            style={{ width: "183px", marginTop: "40px" }}
            onClick={() => {
              setSelectedStat(0);
              openBeforeCreateMeasurement();
            }}
          >
            Crear medición
          </Button>
          <img
            src={emptyContentImage}
            alt="empty"
            style={{
              width: "298px",
              height: "304px",
            }}
          />
        </EmptyContent>
      ) : (
        <Content>
          <ActionRow>
            <StyledInput
              placeholder="Buscar..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Button
              type="primary"
              Icon={AiOutlinePlus}
              style={{ width: "183px" }}
              onClick={() => {
                setSelectedStat(0);
                openBeforeCreateMeasurement();
              }}
            >
              Crear medición
            </Button>
          </ActionRow>
          <Header>
            <LabelItem>Medición</LabelItem>
            <LabelItem>Nombre del proceso</LabelItem>
            <ActionItem>Acción</ActionItem>
          </Header>
          {filteredStats.map(
            ({
              IdStat,
              IdProcessTemplate: templateId,
              Title,
              ProcessTemplateName,
            }) => (
              <MeasurementItem
                $disabled={templateId !== IdProcessTemplate}
                onClick={() => {
                  if (templateId === IdProcessTemplate) {
                    setSelectedStat(IdStat);
                    setState("EditMeasurementes");
                    setPrevEditState("Measurements");
                  }
                }}
              >
                <LabelItem>{Title}</LabelItem>
                <LabelItem>{ProcessTemplateName}</LabelItem>
                <ActionItem>
                  <Button
                    type="secondary"
                    disabled={
                      templateId !== IdProcessTemplate || stats.includes(IdStat)
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      onAddStat(IdStat);
                      setState("Wizard");
                      setFirstTime(false);
                    }}
                  >
                    Usar medición
                  </Button>
                  {templateId !== IdProcessTemplate ? (
                    <Tooltip title="Esta medición no pertenece al proceso que seleccionaste">
                      <AiOutlineInfoCircle size={20} color="#48505E" />
                    </Tooltip>
                  ) : (
                    <DeleteIcon
                      filled
                      onClick={(e) => {
                        e.stopPropagation();
                        setStatToDelete(IdStat);
                      }}
                    />
                  )}
                </ActionItem>
              </MeasurementItem>
            )
          )}
        </Content>
      )}
    </Container>
  );
};

export default Measurements;
