import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { Button } from "../../../GeestUI";
import PageComponent from "./PageComponent";
import { Page } from "../PDFConfig.d";
import {
  NavigationWrapper,
  NewPage,
  PageCount,
  PageInput,
  PagesWrapper,
} from "./styles";
import { nanoid } from "nanoid";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

interface NavigationProps {
  pages: Page[];
  onChangePage: (i: number) => void;
  onDeletePage: (i: number) => void;
  currentPage: number;
  newPage: () => void;
  reorderPages: (newPages: Page[], newCurrentPage: number) => void;
  pageWidth: number;
  pageHeight: number;
}

const Navigation: React.FC<NavigationProps> = ({
  pages,
  onChangePage,
  onDeletePage,
  currentPage,
  newPage,
  reorderPages,
  pageWidth,
  pageHeight,
}) => {
  const [pageInputVisible, setPageInputVisible] = useState<boolean>(false);
  const [newCurrentPage, setNewCurrentPage] = useState<number>(currentPage + 1);

  const setNewPage = () => {
    setPageInputVisible(false);

    const isInvalidNumber = isNaN(+newCurrentPage) || newCurrentPage < 1;
    const isOutOfPagesCount = +newCurrentPage - 1 >= pages.length;
    const isCurrentPage = +newCurrentPage - 1 === currentPage;

    if (isInvalidNumber || isOutOfPagesCount || isCurrentPage) {
      setNewCurrentPage(currentPage + 1);
      return;
    }
    onChangePage(+newCurrentPage - 1);
  };

  const handleReorderPages = (startIndex: number, endindex: number) => {
    const result = Array.from(pages);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endindex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const newPages = handleReorderPages(source.index, destination.index);

    let newCurrentPage = currentPage;
    if (source.index === currentPage) {
      newCurrentPage = destination.index;
    }
    if (destination.index <= currentPage && source.index > currentPage) {
      newCurrentPage = currentPage + 1;
    }
    if (destination.index >= currentPage && source.index < currentPage) {
      newCurrentPage = currentPage - 1;
    }

    reorderPages(newPages, newCurrentPage);
  };

  return (
    <NavigationWrapper>
      <PagesWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-pages">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {pages.map((page, i) => (
                  <Draggable key={page.id} draggableId={page.id} index={i}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <PageComponent
                          page={page}
                          onClick={() => onChangePage(i)}
                          onDeletePage={() => onDeletePage(i)}
                          canDeletePages={pages.length > 1}
                          isCurrent={i === currentPage}
                          key={nanoid()}
                          pageWidth={pageWidth ?? 794}
                          pageHeight={pageHeight ?? 1123}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </PagesWrapper>

      <NewPage>
        <Button
          onClick={newPage}
          Icon={AiOutlinePlus}
          type="secondary"
          borderWidth={"3px"}
        >
          Agregar
        </Button>

        <PageCount>
          {pageInputVisible ? (
            <PageInput
              value={newCurrentPage === 0 ? "" : newCurrentPage}
              onChange={({ target: { value } }): void => {
                const numericValue = +value;
                if (!isNaN(numericValue) && numericValue >= 0) {
                  setNewCurrentPage(numericValue);
                }
              }}
              length={`${newCurrentPage}`.length}
              onBlur={setNewPage}
              onKeyDown={(e) => {
                if (e.key === "Enter") setNewPage();
              }}
              onFocus={(e) => e.currentTarget.select()}
              autoFocus
            />
          ) : (
            <span onClick={() => setPageInputVisible(true)}>{`${
              currentPage + 1
            }`}</span>
          )}

          <span>{`/ ${pages.length}`}</span>
        </PageCount>
      </NewPage>
    </NavigationWrapper>
  );
};

export default Navigation;
