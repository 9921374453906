export const languages = {
  ENG: {
    FieldEntityPlaceholder: "Insert data...",
    PopoverTitleLabel: "Insert data",
    SearchPlaceholder: "Search",
    Empty: "No elements",
  },
  ESP: {
    FieldEntityPlaceholder: "Inserta el dato...",
    PopoverTitleLabel: "Insertar datos",
    SearchPlaceholder: "Buscar",
    Empty: "Sin elementos",
  },
};
