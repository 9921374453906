import React from "react";
import { Col, Row } from "antd";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import _ from "lodash";
import styled from "styled-components";
import { ITotalSales } from "../sales.d";
import { moneyFormat } from "../../../../Helpers";
import { EmptyData } from "../components";
import Img from "../../../../components/Img/Img";
import PointerIcon from "./../../../../resources/img/totalSalesPointer.png";

const graphShape = [
  { name: "Section 1", value: 10 },
  { name: "Section 2", value: 10 },
  { name: "Section 3", value: 10 },
  { name: "Section 4", value: 10 },
  { name: "Section 5", value: 10 },
  { name: "Section 6", value: 10 },
  { name: "Section 7", value: 10 },
  { name: "Section 8", value: 10 },
  { name: "Section 9", value: 10 },
  { name: "Section 10", value: 10 },
];

const Pointer = styled(Img)<{ rotation: number }>`
  width: 10rem;
  height: 10rem;
  position: absolute;
  left: calc(50% - 5rem);
  top: calc(70% - 4.5rem);
  transform: rotate(${({ rotation }) => rotation}deg);
`;

const getRotation = (percentage: number): number => {
  const rotation = (percentage * 180) / 100;
  return _.round(rotation, 1) + 90;
};

const formatNumber = (num: number): string => {
  const millions = 1000000;
  const thousands = 1000;
  if (num >= millions) {
    return `${(num / millions).toFixed(1)}M`;
  } else if (num >= thousands) {
    return `${(num / thousands).toFixed(1)}K`;
  } else {
    return num.toString();
  }
};

const TotalSales: React.FC<{ data?: ITotalSales; loading: boolean }> = ({
  data,
  loading,
}) => {
  if (loading) return <div>Cargando...</div>;
  if (!data || _.isEmpty(data.GraphData.Current) || _.isEmpty(data.Description))
    return <EmptyData />;

  const total = Number(data.Goal || data.GraphData.Current);
  const current = Number(data.GraphData.Current);
  const percentage = _.clamp((current * 100) / total, 0, 100);

  const getColor = (index: number): string => {
    if ((index + 1) * 10 <= percentage) {
      return "#0b2f83";
    }
    return "#c0c0c0";
  };

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{ marginBottom: "2rem" }}
    >
      <Col xs={24} lg={14} style={{ position: "relative" }}>
        <Pointer
          img={PointerIcon}
          cursor="default"
          rotation={getRotation(percentage)}
        />
        <ResponsiveContainer minHeight={200}>
          <PieChart>
            <Pie
              data={graphShape}
              dataKey="value"
              startAngle={180}
              endAngle={0}
              innerRadius={90}
              outerRadius={110}
              paddingAngle={5}
              cy={"75%"}
              label={({ index }) => {
                const value = (index + 1) * 10;
                if (_.inRange(value, percentage - 9, percentage + 1))
                  return formatNumber(current);
                else if (value === 100) {
                  return formatNumber(Math.max(total, current));
                }
                return null;
              }}
              labelLine={false}
            >
              {graphShape.map((_data, index) => (
                <Cell key={`cell-${index}`} fill={getColor(index)} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Col>
      <Col xs={24} lg={10}>
        <TableContainer style={{ maxHeight: "16rem" }} component={Paper}>
          <Table padding="checkbox" size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Proyecto</TableCell>
                <TableCell>Monto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.Description.map(({ IdProcess, ProcessName, Amount }) => (
                <TableRow key={IdProcess}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontSize: "13px" }}
                  >
                    {ProcessName}
                  </TableCell>
                  <TableCell style={{ fontSize: "13px" }}>
                    {moneyFormat(Number(Amount), 0)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
};

export default TotalSales;
