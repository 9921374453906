import { useEffect, useState, useContext } from "react";

import { noop as NOOP } from "lodash";
import server from "../api/server";
import { MessagesContext } from "../components/AppMessages";

interface useFetchParams<Type> {
  func: string;
  args?: { [key: string]: any };
  idUR?: number;
  options?: {
    pags?: {
      PerPage: number;
      CurrentPage: number;
      sortBy?: "asc" | "desc";
      sorted?: boolean;
    };
  };
  conf?: {
    showMessageOnFail?: boolean;
    skip?: boolean;
  };
  onSuccess?: (response: Type) => void;
}

const useFetch = <Type>({
  func,
  idUR,
  args,
  options,
  conf,
  onSuccess = NOOP,
}: useFetchParams<Type>): {
  data: Type | null;
  loading: boolean;
  error: string | null;
  reload: () => void;
} => {
  const [state, setState] = useState<{
    data: Type | null;
    loading: boolean;
    error: string | null;
  }>({
    data: null,
    loading: !!!conf?.skip,
    error: null,
  });
  const { showMessage } = useContext(MessagesContext);
  const isVer2 = func.includes("Ver2");

  const showMessageOnFail = conf?.showMessageOnFail ?? true;

  const fetchData = (isSubscribed: boolean): void => {
    server(func, idUR, args, options)
      .then((response: any) => {
        if (!isVer2) {
          if (isSubscribed) {
            if (response.Request_Error) {
              if (showMessageOnFail) {
                showMessage(response.Request_Error, "error");
              }
              setState({
                ...state,
                error: response.Request_Error,
                loading: false,
              });
            } else {
              setState({
                ...state,
                data: response,
                loading: false,
              });
              onSuccess(response);
            }
          }
        } else {
          if (
            response.Request_Error ||
            ["error", "warning"].includes(response.Type)
          ) {
            showMessage(
              response.Message || response.Request_Error,
              response.Type || "error"
            );
            setState({
              ...state,
              error: response.Message || response.Request_Error,
              loading: false,
            });
          } else {
            const { Type, Message, Data } = response;
            showMessage(Message, Type);
            setState({
              ...state,
              data: Data,
              loading: false,
            });
            onSuccess(Data);
          }
        }
      })
      .catch((err) => {
        if (isSubscribed) {
          if (showMessageOnFail) showMessage("API Error", "error");
          console.error(err);
          setState({
            ...state,
            error: "API error",
            loading: false,
          });
        }
      });
  };

  const reload = (): void => {
    setState({
      ...state,
      loading: true,
    });
    fetchData(true);
  };

  useEffect(() => {
    let isSubscribed = true;
    if (!conf?.skip) fetchData(isSubscribed);
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...state, reload };
};

export default useFetch;
