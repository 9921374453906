import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { sha256 } from "js-sha256";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import _ from "lodash";
import Img from "../../../components/Img/Img";
// Img
import icono from "../../../resources/img/ICONO_.svg";
import { Languages } from "./Dictionary";
import { Title } from "../../Login/styles";
import Input from "../../../components/Inputs/Input";
import SubmitButton from "../../Login/SubmitButton";
import { useMutation } from "../../../hooks";

const ValidateToken = () => {
  const { IdUser, token } = useParams();
  const history = useHistory();
  const {
    ReestablishLabel,
    EnterNewPasswordLabel,
    ConfirmNewPasswordLabel,
    ChangePasswordLabel,
    FieldRequired,
    PasswordsNotMacthError,
  } = Languages["ESP"];

  const [resetPassword, resetting] = useMutation({
    func: "Ver2-Session-rp",
    onSuccess: () => {
      history.push("/auth/login");
    },
  });

  const onSubmit = ({ password }) =>
    resetPassword({
      args: {
        NewPassword: sha256(password),
        Token: token,
      },
      idUR: IdUser,
    });

  const removeSpaces = (value) => _.replace(value, " ", "");

  const isValidForm = (formik) => {
    const fields = ["password", "passwordRepeat"];
    for (const field of fields) {
      if (formik.touched[field] && formik.errors[field]) return false;
    }
    return true;
  };

  return (
    <div
      style={{
        backgroundColor: "#EAEAEA",
        flex: 1,
        display: "flex",
      }}
    >
      <div
        style={{
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ height: "80px", width: "80px" }}>
            <Img img={icono} size="contain" cursor="default" />
          </div>
          <Title>{ReestablishLabel}</Title>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Formik
            initialValues={{
              password: "",
              passwordRepeat: "",
            }}
            onSubmit={onSubmit}
            validationSchema={Yup.object({
              password: Yup.string().required(FieldRequired),
              passwordRepeat: Yup.string()
                .required(FieldRequired)
                .oneOf([Yup.ref("password"), null], PasswordsNotMacthError),
            })}
          >
            {(formik) => (
              <Form
                noValidate
                style={{
                  minWidth: "400px",
                  padding: "0 2rem",
                }}
              >
                <Input
                  type="password"
                  id="password"
                  name="password"
                  controlledOnChange={removeSpaces}
                  placeholder={EnterNewPasswordLabel}
                />
                <Input
                  type="password"
                  id="passwordRepeat"
                  name="passwordRepeat"
                  controlledOnChange={removeSpaces}
                  placeholder={ConfirmNewPasswordLabel}
                />
                <SubmitButton
                  disabled={!isValidForm(formik)}
                  type="submit"
                  loading={resetting}
                  style={{ marginTop: "24px" }}
                >
                  {ChangePasswordLabel}
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ValidateToken;
