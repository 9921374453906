import React, { useState } from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CalendarToday } from "@material-ui/icons";
import MomentUtils from "@date-io/moment";
import Modal from "../../../../../components/Modal";
import { useMutation } from "../../../../../hooks";
import { Button } from "../../../../../GeestUI";
import { Row } from "antd";
import moment from "moment";

interface RescheduleProcessModalProps {
  IdProcessExecution: number | null;
  onSuccess: () => void;
  closeModal: () => void;
}

const RescheduleProcessModal: React.FC<RescheduleProcessModalProps> = ({
  IdProcessExecution,
  onSuccess,
  closeModal,
}) => {
  const [newScheduleTime, setNewScheduleTime] = useState<string | null>(null);

  const [rescheduleProcess, loading] = useMutation({
    func: "Ver2-Reports-rsp",
    onSuccess: () => {
      onSuccess();
      closeModal();
    },
  });

  const onSubmit = () => {
    rescheduleProcess({
      args: {
        IdProcessExecution,
        NewScheduleDate: newScheduleTime,
      },
    });
  };

  return (
    <Modal
      title="Reagendar"
      onCancel={closeModal}
      contentStyles={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        fontSize: "14px",
        color: "rgba(130, 141, 158, 1)",
        fontFamily: "Gotham-Book",
        fontWeight: 325,
      }}
    >
      Reagendar proceso a la fecha
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          variant="dialog"
          format="DD/MMM/YY HH:mm"
          value={newScheduleTime ? moment(newScheduleTime) : null}
          onChange={(date) =>
            setNewScheduleTime(date?.format("YYYY-MM-DD HH:mm") || "")
          }
          InputProps={{
            style: {
              color: "#828d9e",
              background: "white",
              border: "2px solid #edecec",
              borderRadius: "6px",
              paddingLeft: "5px",
              height: "32px",
              fontSize: "14px",
            },
            endAdornment: (
              <Row style={{ flexWrap: "nowrap", marginRight: "5px" }}>
                <CalendarToday
                  style={{
                    color: "#48505e",
                    width: "15px",
                    height: "15px",
                  }}
                />
              </Row>
            ),
            disableUnderline: true,
            placeholder: "Selecciona fecha...",
          }}
          style={{ width: "252px" }}
        />
      </MuiPickersUtilsProvider>
      <Button
        type="primary"
        loading={loading}
        onClick={onSubmit}
        style={{ marginTop: "14px" }}
      >
        Reagendar
      </Button>
    </Modal>
  );
};

export default RescheduleProcessModal;
