import React from "react";
import TemplateImports from "./TemplateImports";
import ConfigureVarDB from "./ConfigureVarDB";
import AdvancedSettings from "./AdvancedSettings";
import { StepContentProps } from "./InstallTemplate.d";

const StepContent: React.FC<StepContentProps> = ({
  activeStep,
  onAdvancedSettings,
  varDBs,
  setVarDBs,
  steps,
  setActiveStep,
  onInstall,
  Teams,
  IdTeam,
  processTemplates,
  IdProcessTemplate,
  teamSelected,
  setTeamSelected,
  varDBData,
  isSharing,
}) => {
  if (activeStep === 0) {
    return (
      <TemplateImports
        onAdvancedSettings={onAdvancedSettings}
        varDBs={varDBs}
        onInstall={onInstall}
        IdTeam={IdTeam}
        Teams={Teams}
        processTemplates={processTemplates}
        teamSelected={teamSelected}
        setTeamSelected={setTeamSelected}
        isSharing={isSharing}
        IdProcessTemplate={IdProcessTemplate}
      />
    );
  }

  if (activeStep === 1) {
    return (
      <AdvancedSettings
        varDBs={varDBs}
        setVarDBs={setVarDBs}
        steps={steps}
        setActiveStep={setActiveStep}
        onInstall={onInstall}
        IdProcessTemplate={IdProcessTemplate}
        teamSelected={teamSelected}
      />
    );
  }

  return (
    <ConfigureVarDB
      varDBs={varDBs}
      setVarDBs={setVarDBs}
      steps={steps}
      setActiveStep={setActiveStep}
      activeStep={activeStep}
      onInstall={onInstall}
      teamSelected={teamSelected}
      varDBData={varDBData}
    />
  );
};

export default StepContent;
