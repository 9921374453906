import React, { useState, useRef, useEffect } from "react";
import Modal from "../../../components/Modal";
import { StyledInput } from "./styles";
import { Row } from "antd";
import { Button } from "../../../GeestUI";
import { useMutation } from "../../../hooks";
import { Languages } from "../Dictionary";

interface CreateFolderProps {
  IdTeam: number;
  onClose: () => void;
  reloadTemplates: () => void;
  IdParentFolder: number | null;
}

const CreateFolder: React.FC<CreateFolderProps> = ({
  IdTeam,
  onClose,
  reloadTemplates,
  IdParentFolder,
}) => {
  const [folderName, setFolderName] = useState<string>("");
  const { CreateLabel } = Languages["ESP"];
  const ref = useRef<HTMLInputElement>(null);
  const canSubmit = folderName.trim().length > 0;

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  const [registerProcessFolder, loading] = useMutation<number>({
    func: "Ver2-Processes-rpf",
    onSuccess: () => {
      reloadTemplates();
      onClose();
    },
  });

  const onSubmit = () => {
    if (loading) return;
    registerProcessFolder({
      args: {
        IdTeam,
        Name: folderName.trim(),
        IdParentFolder,
      },
    });
  };

  return (
    <Modal
      title="Crear carpeta"
      onCancel={onClose}
      width="432px"
      bodyStyle={{ height: "158px" }}
    >
      <Row justify="center">
        <StyledInput
          type="text"
          value={folderName}
          placeholder="Escribe el nombre de la carpeta..."
          onChange={(e) => setFolderName(e.target.value)}
          ref={ref}
          onKeyDown={(e) => {
            if (e.key === "Enter" && canSubmit) {
              onSubmit();
            }
          }}
          autoFocus
        />
      </Row>
      <Row justify="center">
        <Button
          loading={loading}
          disabled={!canSubmit}
          type="primary"
          onClick={onSubmit}
        >
          {CreateLabel}
        </Button>
      </Row>
    </Modal>
  );
};

export default CreateFolder;
