import { dateFormat } from "../../../../Helpers";

export const Languages = {
  ENG: {
    ErrorProcessingLabel: "Error processing payment",
    TriedLabel: (amount, plan) =>
      `We tried to make the charge of $${amount} for your membership ${plan.Name} ${plan.BillingMode} to the selected payment method`,
    DayLabel: (date) => `on ${dateFormat(date)} and we were not successful`,
    RetryPaymentLabel: "Retry payment",
  },
  ESP: {
    ErrorProcessingLabel: "Error al procesar el pago",
    TriedLabel: (amount, plan) =>
      `Intentamos hacer el cargo de $${amount} por tu membresía ${plan.Title} ${
        plan.BillingMode === "Monthly" ? "mensual" : "anual"
      } al método de pago seleccionado`,
    DayLabel: (date) => `el día ${dateFormat(date)} y no tuvimos éxito`,
    RetryPaymentLabel: "Reintentar pago",
  },
};
