import React, { useContext } from "react";
import Modal from "../../../../components/Modal";
import { Button } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";
import { Languages } from "./Dictionary";
import { TeamMember } from "../../Teams.d";
import { MessagesContext } from "../../../../components/AppMessages";
import styled from "styled-components";

const Label = styled.div`
  font-size: 14px;
  color: #48505e;
  font-family: Gotham-Bold;
  width: 309px;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 10px;
`;

const Subtitle = styled(Label)`
  font-family: Gotham-Book;
  color: #828d9e;
`;

const { SuccessExpellingUser } = Languages["ESP"];

interface ExpellUserModalProps {
  idTeam: string;
  user: TeamMember | null;
  title: string;
  okText: string;
  cancelText: string;
  onClose: () => void;
  reload: () => void;
}

const ExpellUserModal: React.FC<ExpellUserModalProps> = ({
  idTeam,
  user,
  title,
  okText,
  cancelText,
  onClose,
  reload,
}) => {
  const { showMessage } = useContext(MessagesContext);

  const [expellUser, expelling] = useMutation<[]>({
    func: "Ver2-Team-euft",
    onSuccess: () => {
      showMessage(SuccessExpellingUser, "success");
      onClose();
      reload();
    },
  });

  const handleExpellUser = () => {
    expellUser({
      args: {
        IdTeam: idTeam,
        IdUser: user?.IdUser,
      },
    });
  };

  return (
    <Modal visible onCancel={onClose} title="Eliminar" width="432px">
      <Container>
        <Label>¿Estás seguro que deseas eliminar a este integrante?</Label>
        <Subtitle>
          Al eliminar al integrante, este se eliminará permanentemente
        </Subtitle>
        <ButtonsContainer>
          <Button onClick={onClose} loading={expelling} type="secondary">
            {cancelText}
          </Button>
          <Button
            onClick={handleExpellUser}
            loading={expelling}
            type="primary"
            danger
          >
            {okText}
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};

export default ExpellUserModal;
