import { FieldDataTypes } from "../../../Configurator.d";

export interface SelectOption {
  Label: string;
  Value: string;
}

export interface StartField {
  IdFieldOrigin: string;
  IdFieldDestiny: string;
  FieldCausesIteration?: boolean;
}

export interface SubfluxChat {
  IdPrepublishProcessTemplateChatOrigin: number;
  IdPrepublishTemplateChatDestiny: number;
}

export interface Subflux {
  NodeTitle: string;
  Preconf: string;
  RefDate: string;
  StartFields: StartField[];
  TimeToAdd: string;
  Chats: SubfluxChat[];
}

export interface FieldOrigin {
  DataType: FieldDataTypes;
  IdFieldOrigin: string;
  Label: string;
  FieldCausesIteration: boolean;
}

interface StartFieldOption {
  IdFieldDestiny: string;
  DataType: string;
  Label: string;
  PossibleOrigins: FieldOrigin[];
}

interface ChatOption {
  Id: number;
  Label: string;
}

export interface GetSubfluxDetailAndConfigParams {
  Subflux: Subflux;
  StartFieldsOptions: StartFieldOption[];
  TimesPreconf: SelectOption[];
  TimesRefDate: SelectOption[];
  DestinyChats: ChatOption[];
  OriginChats: ChatOption[];
}

export interface UpdateSubfluxDetailsResponse extends Subflux {
  IdNode: string;
}

export enum SubfluxEditorPages {
  GENERAL = "GENERAL",
  SCHEDULE = "SCHEDULE",
  CHATS = "CHATS",
}

export interface TabsHeaderProps {
  value: SubfluxEditorPages;
  onChange: (value: SubfluxEditorPages) => void;
  onClose: () => void;
}

interface SharedPageProps {
  subflux: Subflux;
  onChange: (subflux: Subflux) => void;
  onChangeAndSave: (subflux: Subflux) => void;
  saveSubflux: () => void;
}

export interface ActivePageProps extends SharedPageProps {
  activePage: SubfluxEditorPages;
  timesPreconf: SelectOption[];
  timesRefDate: SelectOption[];
  startFieldsOptions: StartFieldOption[];
  destinyChats: ChatOption[];
  originChats: ChatOption[];
}

export interface GeneralPageProps extends SharedPageProps {
  startFieldsOptions: StartFieldOption[];
}
export interface SchedulePageProps extends SharedPageProps {
  timesPreconf: SelectOption[];
  timesRefDate: SelectOption[];
}
export interface ChatsPageProps extends SharedPageProps {
  destinyChats: ChatOption[];
  originChats: ChatOption[];
}
