import React, { useEffect } from "react";
import { noop as NOOP } from "lodash";
import { useFetch } from "../../../../../hooks";
import { FlowProps, GetExecutionFluxResponse } from "./Flow.d";
import { Container, FlowContainer } from "./styles";
import { CloseIcon } from "../../../../../components/hoverIcons";
import Diagram from "./Diagram";
import { apiResponseToFlow } from "./helpers";

export const FlowContext = React.createContext<{
  IdProcessExecution: number;
  onSelectTask: (idTask: number) => void;
  onRestartFlux: () => void;
}>({
  IdProcessExecution: 0,
  onSelectTask: NOOP,
  onRestartFlux: NOOP,
});

const Flow: React.FC<FlowProps> = ({
  IdProcessExecution,
  IdTaskValue,
  onClose = NOOP,
  onChangePending = NOOP,
  onRestartFlux = NOOP,
  closable = true,
  shouldReload = false,
  setShouldReload = NOOP,
}) => {
  const {
    data: apiResponse,
    loading,
    reload,
  } = useFetch<GetExecutionFluxResponse>({
    func: "Ver2-ExecutionFlux-gef",
    args: {
      IdProcessExecution,
      IdTaskValue,
    },
  });

  useEffect(() => {
    if (shouldReload) {
      setShouldReload(false);
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReload]);

  return (
    <FlowContext.Provider
      value={{
        IdProcessExecution: apiResponse?.IdProcessExecution || 0,
        onSelectTask: onChangePending,
        onRestartFlux: () => {
          onRestartFlux();
          reload();
        },
      }}
    >
      <Container>
        {closable && (
          <CloseIcon
            onClick={onClose}
            size={20}
            style={{
              position: "absolute",
              right: "12px",
              top: "12px",
              zIndex: 1,
            }}
          />
        )}
        {!loading && apiResponse && (
          <FlowContainer>
            <Diagram {...apiResponseToFlow(apiResponse)} />
          </FlowContainer>
        )}
      </Container>
    </FlowContext.Provider>
  );
};

export default Flow;
