import styled from "styled-components";

export const StringInput = styled.input`
  border-radius: 6px;
  border: 2px solid;
  border-color: #edecec;
  background-color: white;
  color: #828d9e;

  width: 384px;
  min-height: 30px;
  resize: none;
  padding: 6px 10px;

  transition: all 0.35s ease;
  ::placeholder {
    color: #828d9e;
  }
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;
