import styled from "styled-components";

export const ImageWrapper = styled.div`
  border: 1px solid salmon;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
`;

export const DebugCounter = styled.div`
  border: 1px solid teal;
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
  }

  button {
    user-select: none;
    cursor: pointer;
  }
`;
