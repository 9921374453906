import React from "react";
import { Coords, Size } from "../../../PDFConfig.d";
import { SignAbstractionWrapper } from "./styles";
import SignaturePlaceholder from "../../../../../resources/img/signaturePlaceholder.svg";

interface SignAbstractionProps {
  componentKey: string;
  size: Size;
  position: Coords;
  thumbnailSize: Size;
}

const SignAbstraction: React.FC<SignAbstractionProps> = ({
  componentKey,
  size,
  position,
  thumbnailSize,
}) => {
  const getNormalizedPosition = (): Coords => {
    let normalized = { x: position.x, y: position.y };

    if (position.x + size.width > thumbnailSize.width - 10) {
      normalized.x = thumbnailSize.width - size.width - 10;
    }

    if (position.y + size.height > thumbnailSize.height - 10) {
      normalized.y = thumbnailSize.height - size.height - 10;
    }

    return normalized;
  };

  return (
    <SignAbstractionWrapper
      key={componentKey}
      size={size}
      position={getNormalizedPosition()}
    >
      <img src={SignaturePlaceholder} alt="" />
    </SignAbstractionWrapper>
  );
};

export default SignAbstraction;
