import stringDT from "../../resources/img/DataType_48505E/string.svg";
import numberDT from "../../resources/img/DataType_48505E/number.svg";
import phoneDT from "../../resources/img/DataType_48505E/phone.svg";
import dateDT from "../../resources/img/DataType_48505E/date.svg";
import timeDT from "../../resources/img/DataType_48505E/time.svg";
import locationDT from "../../resources/img/DataType_48505E/location.svg";
import currencyDT from "../../resources/img/DataType_48505E/currency.svg";
import selectDT from "../../resources/img/DataType_48505E/select.svg";
import multiSelectDT from "../../resources/img/DataType_48505E/multiselect.svg";
import usersSelectDT from "../../resources/img/DataType_48505E/user.svg";
import checkListDT from "../../resources/img/DataType_48505E/checklist.svg";
import fileDT from "../../resources/img/DataType_48505E/file.svg";
import varDBRowDT from "../../resources/img/DataType_48505E/vardb.svg";
import pdfDT from "../../resources/img/DataType_48505E/pdf.svg";
import signatureDT from "../../resources/img/DataType_48505E/sign.svg";
import process_triggerDT from "../../resources/img/DataType_48505E/process_trigger.svg";
import durationDT from "../../resources/img/DataType_48505E/duration.svg";
import statusDT from "../../resources/img/DataType_48505E/status.svg";
import stageDT from "../../resources/img/DataType_48505E/stage.svg";
import priorityDT from "../../resources/img/DataType_48505E/priority.svg";
import processDT from "../../resources/img/DataType_48505E/process.svg";
import current_executorsDT from "../../resources/img/DataType_48505E/current_executors.svg";
import stuck_timeDT from "../../resources/img/DataType_48505E/stuck_time.svg";
import last_interactionDT from "../../resources/img/DataType_48505E/last_interaction.svg";

const DataTypeIcons: {
  [key: string]: {
    icon: string;
    label: string;
    extraStyles: { [key: string]: any };
  };
} = {
  string: {
    icon: stringDT,
    label: "Texto",
    extraStyles: {},
  },
  number: {
    icon: numberDT,
    label: "Número",
    extraStyles: {},
  },
  phone_number: {
    icon: phoneDT,
    label: "Número telefónico",
    extraStyles: {},
  },
  date: {
    icon: dateDT,
    label: "Fecha",
    extraStyles: {},
  },
  time: {
    icon: timeDT,
    label: "Fecha y hora",
    extraStyles: {},
  },
  location: {
    icon: locationDT,
    label: "Ubicación",
    extraStyles: {},
  },
  currency: {
    icon: currencyDT,
    label: "Moneda",
    extraStyles: {},
  },
  select: {
    icon: selectDT,
    label: "Lista de opciones",
    extraStyles: {},
  },
  multi_select: {
    icon: multiSelectDT,
    label: "Selección mútliple",
    extraStyles: {},
  },
  users_select: {
    icon: usersSelectDT,
    label: "Selección de usuarios",
    extraStyles: {},
  },
  checklist: {
    icon: checkListDT,
    label: "Checklist",
    extraStyles: {},
  },
  file: {
    icon: fileDT,
    label: "Archivo",
    extraStyles: {},
  },
  varDBRow: {
    icon: varDBRowDT,
    label: "",
    extraStyles: {},
  },
  pdf_autogenerated: {
    icon: pdfDT,
    label: "PDF autogenerado",
    extraStyles: {},
  },
  signature: {
    icon: signatureDT,
    label: "Firma",
    extraStyles: {},
  },

  // Icons for pipeline configuration
  process_trigger: {
    icon: process_triggerDT,
    label: "Disparador de proceso",
    extraStyles: {},
  },
  duration: {
    icon: durationDT,
    label: "Duración",
    extraStyles: {},
  },
  status: {
    icon: statusDT,
    label: "Estatus",
    extraStyles: {
      width: "16px",
      height: "16px",
    },
  },
  stage: {
    icon: stageDT,
    label: "Etapa",
    extraStyles: {},
  },
  priority: {
    icon: priorityDT,
    label: "Prioridad",
    extraStyles: {},
  },
  process: {
    icon: processDT,
    label: "Proceso",
    extraStyles: {},
  },
  current_executors: {
    icon: current_executorsDT,
    label: "Encargados y tareas actuales",
    extraStyles: {
      width: "16px",
      height: "16px",
    },
  },
  stuck_time: {
    icon: stuck_timeDT,
    label: "Tiempo vencido en total",
    extraStyles: {
      width: "16px",
      height: "16px",
    },
  },
  last_interaction: {
    icon: last_interactionDT,
    label: "Última interacción",
    extraStyles: {
      width: "16px",
      height: "16px",
    },
  },
};

export default DataTypeIcons;
