import React, { useEffect, useState } from "react";
import {
  ViewContentAction,
  ActionFormTitle,
  StyledInput,
  DateConfigurationContainer,
  TimePickerStyled,
  IconsContainer,
  SelectLabel,
} from "./styles";
import { Row } from "antd";
import {
  GetChangeFieldValueActionConfigResponse,
  ActionProps,
} from "../AutomationConfig.d";
import { useFetch } from "../../../../../../hooks";
import { useParams } from "react-router-dom";
import changeFieldValueActionIcon from "../../../../../../resources/img/changeFieldValueActionIcon.svg";
import DataTypeIcons from "../../../../../../components/DataTypeIcons";
import GeestSelect from "../../../../../../GeestUI/GeestSelect/GeestSelect";
import ViewHeader from "./ViewHeader";
import DynamicInput from "../../../../../../components/DynamicInput";
import GeestTextEditor from "../../../../../../components/GeestTextEditor";
import {
  EditorState,
  convertFromRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { editorStateRaw } from "../../../../../../components/GeestTextEditor/Utils/Editor";
import Tooltip from "../../../../../../components/Tooltip";
import { Action } from "../EditAction.d";
import { backOptionToSelectOption } from "../../TaskEditor/SchedulePage/helpers";
import {
  encodeTimeToAdd,
  TimeConfig,
  TimeFormat,
} from "../../TaskEditor/SchedulePage/helpers";
import {
  timeToAddFormatOptions,
  timeToAddConfigOptions,
} from "../../TaskEditor/SchedulePage/constants";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment, { Moment } from "moment";
import { AiOutlineClockCircle } from "react-icons/ai";
import User from "../../../../../../components/User";

export const ChangeFieldValueActionView: React.FC<ActionProps> = ({
  reloadHome,
  selectedAction,
  actionOptions,
  IdNode,
  setSelectedAction,
  saveAction,
}) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const [title, setTitle] = useState<EditorState>(EditorState.createEmpty());
  const [timePickerOpen, setTimePickerOpen] = useState<boolean>(false);
  const [time, setTime] = useState<Moment>();

  const { data, loading } = useFetch<GetChangeFieldValueActionConfigResponse>({
    func: "Ver2-Configurator-gaco",
    args: {
      IdTeam,
      IdProcessTemplate,
      IdNode,
      Action: selectedAction?.Action,
    },
  });

  useEffect(() => {
    if (
      selectedAction?.SubAction === "set_field_value" &&
      selectedAction?.IdAction > 0
    ) {
      if (
        !selectedAction?.IdField1 ||
        String(selectedAction.IdField1) === "0"
      ) {
        selectedAction.IdField1 = "0";
        if (selectedAction?.Value) {
          setTitle(
            EditorState.createWithContent(convertFromRaw(selectedAction?.Value))
          );
        }
      }
    }
    if (selectedAction?.SubAction === "calculate_date") {
      if (selectedAction?.Time) {
        setTime(
          moment()
            .hour(selectedAction?.Time.split(":")[0])
            .minute(selectedAction?.Time.split(":")[1])
        );
      } else {
        setTime(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction?.IdAction]);

  useEffect(() => {
    if (data && selectedAction?.IdField1) {
      const dataType = data?.Fields.find(
        ({ Value }) => String(Value) === String(selectedAction?.IdField1)
      )?.DataType;
      if (dataType === "string") {
        if (selectedAction?.Value) {
          if (typeof selectedAction?.Value === "object") {
            setTitle(
              EditorState.createWithContent(
                convertFromRaw(selectedAction?.Value)
              )
            );
          } else {
            const blocksFromHtml = convertFromHTML(
              selectedAction?.Value.replaceAll("x22", '"')
            );
            const state = ContentState.createFromBlockArray(
              blocksFromHtml.contentBlocks,
              blocksFromHtml.entityMap
            );
            setTitle(EditorState.createWithContent(state));
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction?.IdAction, data]);

  const sendAction = (action: Action) => {
    if (action.SubAction === "set_field_value") {
      if (!!action.IdField1 || String(action.IdField1) === "0") {
        saveAction(action);
      }
    } else {
      if (action.SubAction === "clear_vardb_group_value" && !!action.Value) {
        saveAction(action);
      } else if (action.SubAction === "round_robin") {
        if (!!action.Users && action.Users.length > 0 && !!action.IdField1) {
          saveAction(action);
        }
      } else if (!!action.IdField1 && !!action.SubAction) {
        saveAction(action);
      }
    }
  };

  const onChangeSelect = (val: number) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux["IdField1"] = val;
      const dataType = data?.Fields.find(
        ({ Value }) => String(Value) === String(val)
      )?.DataType;
      if (dataType === "date") {
        actionAux.TimeFormat = "día";
      }
      delete actionAux.Value;
      delete actionAux.Format;
      if (String(val).includes("IdRow")) {
        actionAux["IdField1"] = "";
        actionAux.Value = String(val).split("-")[1];
      }
      if (
        ["phone_number", "currency"].includes(dataType || "") &&
        actionAux.SubAction === "set_field_value"
      ) {
        actionAux.Format = data?.DefaultFormats[dataType || ""];
      }
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const fieldOptions = (() => {
    const options =
      data?.Fields?.filter(({ DataType, Configuration, Value, Type }) => {
        if (Type === "Ghost") {
          return false;
        }
        if (String(Value).includes("Process")) {
          return false;
        }
        if (selectedAction?.SubAction === "clear_vardb_group_value") {
          return String(Value).includes("IdRow");
        }
        if (selectedAction?.SubAction === "round_robin") {
          return DataType === "users_select";
        }
        if (
          selectedAction?.SubAction === "set_current_date" ||
          selectedAction?.SubAction === "calculate_date"
        ) {
          return ["time", "date"].includes(DataType);
        }
        if (selectedAction?.SubAction === "set_field_value") {
          return (
            !["file", "location"].includes(DataType) &&
            Configuration !== "ImportedFromVardbColumn"
          );
        }
        return true;
      }).map(({ Label, Value, DataType }) => ({
        label: (
          <Row align="middle">
            <Row
              align="middle"
              justify="center"
              style={{
                width: "26px",
                height: "26px",
                display: "flex",
              }}
              wrap={false}
            >
              <img
                style={{
                  width: "14px",
                  height: "14px",
                  ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
                }}
                alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
                src={DataTypeIcons[DataType?.toLowerCase()].icon}
              />
            </Row>
            {Label.length >= 40 ? (
              <Tooltip title={Label} placement="right">
                <div
                  style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {Label}
                </div>
              </Tooltip>
            ) : (
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {Label}
              </div>
            )}
          </Row>
        ),
        value: Value,
        searchableValues: Label,
      })) || [];
    if (
      !selectedAction?.SubAction ||
      selectedAction?.SubAction === "set_field_value"
    ) {
      return [
        {
          label: "Título del seguimiento",
          value: "0",
          separator: options.length > 0,
        },
        ...options,
      ];
    }
    return options;
  })();

  const userOptions =
    (() =>
      data?.Users.map((usr) => {
        return {
          value: usr.IdUser,
          searchableValues: `${usr.FirstName},${usr.LastName},${usr.Email}`,
          label: <User user={usr} size="middle" placement="bottom" />,
        };
      }))() || [];

  const subActionOptions =
    data?.SubActions?.filter(({ Value }) => {
      if (!selectedAction.IdField1 && !!selectedAction.Value) {
        return Value === "clear_vardb_group_value";
      }
      if (String(selectedAction?.IdField1) === "0") {
        return Value === "set_field_value";
      }
      if (selectedAction?.IdField1) {
        const field = data?.Fields?.find(
          ({ Value }) => String(Value) === String(selectedAction?.IdField1)
        );
        const dataType = field?.DataType;
        const configuration = field?.Configuration;
        let dateFilter = true;
        if (!["date", "time"].includes(dataType || "")) {
          dateFilter =
            Value !== "set_current_date" && Value !== "calculate_date";
        }
        const roundRobinFilter =
          Value !== "round_robin" || dataType === "users_select";
        let setFieldFilter = true;
        if (
          ["file", "location", "pdf_autogenerated"].includes(dataType || "") ||
          configuration === "ImportedFromVardbColumn"
        ) {
          setFieldFilter = Value !== "set_field_value";
        }
        return (
          dateFilter &&
          setFieldFilter &&
          Value !== "clear_vardb_group_value" &&
          roundRobinFilter
        );
      }
      return true;
    }).map(({ Label, Value }) => ({ label: Label, value: Value })) || [];

  const fieldSelected = data?.Fields.find(
    ({ Value }) => String(Value) === String(selectedAction?.IdField1)
  );

  const editorFields = data?.Fields.filter(
    ({ DataType }) => !["file"].includes(DataType)
  ).map(({ Value, DataType, Label }) => ({
    Label,
    DataType,
    IdField: String(Value),
  }));

  const onChangeSubAction = (value: string) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.SubAction = value;
      if (value === "calculate_date") {
        if (!actionAux.TimeAmount) {
          actionAux.TimeAmount = 0;
        }
        if (!actionAux.TimeFormat) {
          actionAux.TimeFormat = "día";
        }
        if (!actionAux.TimeConfig) {
          actionAux.TimeConfig = "después";
        }
        actionAux.TimeToAdd = encodeTimeToAdd({
          timeAmount: actionAux.TimeAmount,
          timeFormat: actionAux.TimeFormat,
          timeConfig: actionAux.TimeConfig,
        });
        actionAux.Time = time?.format("HH:mm") || "";
        if (!actionAux.PreConf) {
          actionAux.PreConf = "nd";
        }
        if (!actionAux.RefDate) {
          actionAux.RefDate = "processStart";
        }
      }
      if (value === "set_field_value" && !!actionAux.IdField1) {
        const dataType = data?.Fields.find(
          ({ Value }) => String(Value) === String(actionAux.IdField1)
        )?.DataType;
        if (["currency", "phone_number"].includes(dataType || "")) {
          actionAux.Format = data?.DefaultFormats[dataType || ""];
        }
      }
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const onChangeValue = (value: any) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.Value = value;
      setSelectedAction(actionAux);
      if (
        !["currency", "phone_number", "number", "string"].includes(
          fieldSelected?.DataType || ""
        )
      ) {
        sendAction(actionAux);
      }
    }
  };

  const onChangeFormat = (val: string) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.Format = val;
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const onBlur = () => {
    if (selectedAction) {
      if (
        ["currency", "phone_number", "number", "string"].includes(
          fieldSelected?.DataType || ""
        ) ||
        String(selectedAction.IdField1) === "0"
      ) {
        sendAction(selectedAction);
      }
    }
  };

  const onChangeTitle = (val: EditorState) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.Value = editorStateRaw(val);
      setTitle(val);
      setSelectedAction(actionAux);
    }
  };

  const onChangeDateTimeToAdd = (
    {
      amount = selectedAction?.TimeAmount || 0,
      format = selectedAction?.TimeFormat || "día",
      config = selectedAction?.TimeConfig || "después",
    },
    action: Action
  ) => {
    if (selectedAction) {
      let actionAux = { ...action };
      actionAux.TimeToAdd = encodeTimeToAdd({
        timeAmount: amount,
        timeFormat: format,
        timeConfig: config,
      });
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const onChangeTimeFormat = (val: TimeFormat) => {
    if (selectedAction) {
      let actiounAux = { ...selectedAction };
      actiounAux.TimeFormat = val;
      if (val !== "día") {
        delete actiounAux.Time;
      }
      onChangeDateTimeToAdd({ format: val }, actiounAux);
    }
  };

  const onChangeDateConfigSelect = (val: string, key: string) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux[key] = val;
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const onChangeTimeConfig = (val: TimeConfig) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.TimeConfig = val;
      onChangeDateTimeToAdd({ config: val }, actionAux);
    }
  };

  const onChangeTimeAmount = (val: number) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.TimeAmount = val;
      setSelectedAction(actionAux);
    }
  };

  const fieldRefDateOptions =
    data?.Fields.filter(({ DataType }) =>
      ["date", "time"].includes(DataType)
    ).map(({ Label, Value, DataType }) => ({
      label: (
        <Row align="middle" style={{ gap: 0 }}>
          <Row
            align="middle"
            justify="center"
            style={{
              width: "26px",
              height: "26px",
              display: "flex",
            }}
            wrap={false}
          >
            <img
              style={{
                width: "14px",
                height: "14px",
                ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
              }}
              alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
              src={DataTypeIcons[DataType?.toLowerCase()].icon}
            />
          </Row>
          {Label.length >= 20 ? (
            <Tooltip title={Label} placement="right">
              <div style={{ overflow: "hidden", display: "flex", flex: 1 }}>
                <SelectLabel>{Label}</SelectLabel>
              </div>
            </Tooltip>
          ) : (
            <SelectLabel>{Label}</SelectLabel>
          )}
        </Row>
      ),
      value: Value,
      searchableValues: Label,
    })) || [];

  const refDateOptions = [
    ...backOptionToSelectOption(data?.RefDate || []),
    ...fieldRefDateOptions,
  ];

  const onChangeTime = (val: Moment | null) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.Time = val?.format("HH:mm");
      setTime(val || undefined);
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const onChangeRoundRobinUsers = (users: string) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.Users = users.split(",");
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  return (
    <ViewContentAction>
      <ViewHeader
        IdNode={IdNode}
        reloadHome={reloadHome}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
        icon={changeFieldValueActionIcon}
        actionOptions={actionOptions}
      />
      <div>
        <ActionFormTitle>Selecciona el campo a modificar</ActionFormTitle>
        <GeestSelect
          options={fieldOptions}
          onChange={onChangeSelect}
          value={
            selectedAction?.IdField1 || String(selectedAction?.IdField1) === "0"
              ? String(selectedAction?.IdField1)
              : selectedAction?.Value
              ? `IdRow-${selectedAction.Value}`
              : null
          }
          $width="330px"
          required={String(selectedAction?.IdField1) !== "0"}
        />
      </div>
      <div>
        <ActionFormTitle>Selecciona acción a realizar</ActionFormTitle>
        <GeestSelect
          options={subActionOptions}
          onChange={onChangeSubAction}
          value={selectedAction?.SubAction}
          $width="330px"
          disableSearch
          required
        />
      </div>
      {(!!selectedAction.IdField1 || String(selectedAction.IdField1) === "0") &&
        selectedAction.SubAction === "set_field_value" &&
        (!!fieldSelected || String(selectedAction.IdField1) === "0") && (
          <div>
            <ActionFormTitle>Selecciona el valor</ActionFormTitle>
            {String(selectedAction.IdField1) === "0" ||
            fieldSelected?.DataType === "string" ? (
              !loading && (
                <GeestTextEditor
                  editorState={title}
                  onEditorStateChange={onChangeTitle}
                  editorBorder
                  fieldEditorOrigin={editorFields}
                  toolbarConfig={{
                    field: true,
                    inline: false,
                    fontSize: false,
                    fontFamily: false,
                    list: false,
                    textalign: false,
                    colorPicker: false,
                    link: false,
                  }}
                  toolbarExtraParams={{ fieldOptions: { largeButton: true } }}
                  placeholder="Escribe aquí..."
                  onBlur={onBlur}
                />
              )
            ) : (
              <DynamicInput
                value={selectedAction?.Value}
                format={selectedAction?.Format}
                onChange={onChangeValue}
                onChangeFormat={onChangeFormat}
                fieldName=""
                dataOrigin={fieldSelected?.DataOrigin}
                userSelectDataOrigin={fieldSelected?.DataOrigin || []}
                type={fieldSelected?.DataType ? fieldSelected?.DataType : ""}
                configuration=""
                disabled={false}
                required={false}
                isConsult={false}
                extraParams={{
                  inputPlaceHolder: "Escribe aquí...",
                  phonePlaceholder: "",
                  currencyPlaceholder: "",
                }}
                onBlur={onBlur}
              />
            )}
          </div>
        )}
      {!!selectedAction.IdField1 &&
        selectedAction.SubAction === "round_robin" && (
          <div>
            <ActionFormTitle>
              Selecciona los usuarios que participan en la ruleta
            </ActionFormTitle>
            <GeestSelect
              options={userOptions}
              value={
                !!selectedAction.Users ? selectedAction.Users.join(",") : ""
              }
              onChange={onChangeRoundRobinUsers}
              multiselect
            />
          </div>
        )}
      {!!selectedAction.IdField1 &&
        selectedAction.SubAction === "calculate_date" && (
          <DateConfigurationContainer>
            <StyledInput
              value={selectedAction?.TimeAmount}
              onChange={(e) => onChangeTimeAmount(Number(e.target.value))}
              pattern="^[0-9]*$"
              onBlur={() => onChangeDateTimeToAdd({}, selectedAction)}
              style={{ width: String(selectedAction?.TimeAmount).length * 15 }}
            />
            <GeestSelect
              value={selectedAction?.TimeFormat}
              onChange={onChangeTimeFormat}
              options={timeToAddFormatOptions}
              valueNecesary
              $width="124px"
              disabled={fieldSelected?.DataType === "date"}
            />
            <GeestSelect
              value={selectedAction?.PreConf}
              onChange={(val) => onChangeDateConfigSelect(val, "PreConf")}
              options={backOptionToSelectOption(data?.TimesPreconf || [])}
              valueNecesary
              $width="124px"
            />
            <GeestSelect
              value={selectedAction?.TimeConfig}
              onChange={onChangeTimeConfig}
              options={timeToAddConfigOptions}
              valueNecesary
              $width="110px"
            />
            <ActionFormTitle style={{ height: "32px", alignItems: "center" }}>
              de
            </ActionFormTitle>
            <GeestSelect
              value={selectedAction?.RefDate}
              onChange={(val) => onChangeDateConfigSelect(val, "RefDate")}
              options={refDateOptions}
              valueNecesary
              $width="192px"
              style={{ height: "32px" }}
            />
            {fieldSelected?.DataType === "time" &&
              selectedAction?.TimeFormat === "día" && (
                <>
                  <ActionFormTitle
                    style={{ height: "32px", alignItems: "center" }}
                  >
                    a las
                  </ActionFormTitle>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <TimePickerStyled
                      open={timePickerOpen}
                      onOpen={() => setTimePickerOpen(true)}
                      onClose={() => setTimePickerOpen(false)}
                      value={time ? time : null}
                      variant="dialog"
                      format="HH:mm"
                      onChange={onChangeTime}
                      InputProps={{
                        disableUnderline: true,
                        placeholder: "Selecciona...",
                        style: {
                          border: "2px solid",
                          borderColor: "#edecec",
                          borderRadius: "6px",
                          color: "#828d9e",
                          background: "white",
                          height: "32px",
                          paddingLeft: "5px",
                          fontSize: "14px",
                          transition: "all 0.35s ease",
                          width: "288px",
                        },
                        endAdornment: (
                          <div
                            onClick={() => setTimePickerOpen(true)}
                            style={{
                              position: "absolute",
                              right: "3px",
                              width: "40px",
                              height: "20px",
                              bottom: "10px",
                              zIndex: 1,
                            }}
                          >
                            <IconsContainer>
                              <AiOutlineClockCircle color="#48505e" size={14} />
                            </IconsContainer>
                          </div>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </>
              )}
          </DateConfigurationContainer>
        )}
    </ViewContentAction>
  );
};

export default ChangeFieldValueActionView;
