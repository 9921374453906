import React, { useContext, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { Switch } from "antd";
import { useParams } from "react-router-dom";
import { useMutation } from "../../../hooks";
import TagOptions from "../../../components/TagOptions";
import PrimaryKeyIcon from "../../../resources/img/Databases/icon_key.svg";
import { ColumnType, TableData } from "../DataBases.d";
import { PrimaryKey } from "../Styles";
import { Paper } from "@material-ui/core";
import DataTypeIcons from "./DataTypeIcons";
import { CloseIcon } from "../../../components/hoverIcons";
import GeestSelect from "../../../GeestUI/GeestSelect";
import { Button } from "../../../GeestUI";
import { Languages } from "./Dictionary";
import { MessagesContext } from "../../../components/AppMessages";
import { DropResult } from "react-beautiful-dnd";

const StyledPaper = styled(Paper)`
  border-radius: 6px !important;
  width: 432px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 12px;

  p {
    margin: 0;
    font-family: Gotham-Bold;
    font-size: 16px;
    color: #48505e;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 60px;

  p {
    margin: 0;
    text-align: center;
    font-size: 14px;
    color: #828d9e;
  }
  .table-name {
    color: #db232c;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
`;

const Input = styled.input`
  width: 100%;
  color: rgba(130, 141, 158, 1);
  border: 2px solid rgba(237, 236, 236, 1);
  border-radius: 6px;
  padding-left: 10px;
  height: 32px;
  font-family: Gotham-Book;
  ::placeholder {
    color: #828d9e;
  }
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const ChangeDataTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;

  .cannot-change-datatype {
    display: flex;
    align-items: center;
    gap: 2px;

    p {
      margin: 0;
      color: #828d9e;
      font-size: 16px;
    }
  }
`;

const OptionLabelWrapper = styled.div`
  display: flex;
  align-items: center;

  .cannot-change-datatype {
    display: flex;
    align-items: center;
    gap: 2px;

    p {
      margin: 0;
      color: #828d9e;
      font-size: 16px;
    }
  }
`;

const DataOriginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  padding: 0 60px;

  p {
    margin: 0;
    text-align: center;
    font-size: 14px;
    color: #828d9e;
  }
`;

const CannAddOptionsWrapper = styled(SwitchContainer)``;

const SelectMoreLocations = styled(SwitchContainer)``;

const SelectPrimaryKey = styled(SwitchContainer)`
  gap: 8px;
  padding: 12px 60px;
  .key-label {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const StyledSwitch = styled(Switch)`
  background: ${({ checked }) => (checked ? "#0273E9" : "#828D9E")};
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
`;

const EditColumn: React.FC<{
  closeModal: () => void;
  selectedColumn: TableData | null;
  columnTypeArray: ColumnType[];
  reload: () => void;
}> = ({ closeModal, selectedColumn, columnTypeArray, reload }) => {
  const { IdTeam, IdVarDB } = useParams<{ IdTeam: string; IdVarDB: string }>();
  const [newTitle, setNewTitle] = useState<string>(selectedColumn?.Title || "");
  const [dataType, setDataType] = useState<string>(
    selectedColumn?.DataType || ""
  );
  const [dataOrigin, setDataOrigin] = useState<any>(
    selectedColumn?.DataOrigin ?? []
  );
  const [configuration, setConfiguration] = useState<
    | ""
    | "CanAddOptions"
    | "CannotAddOptions"
    | "SelectAndCurrent"
    | "OnlyCurrent"
  >(selectedColumn?.Configuration || "");
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputOpcVis, setInputOpcVis] = useState<boolean>(false);
  const [opcValue, setOpcValue] = useState<string>("");
  const [opcIndex, setOpcIndex] = useState<any>(null);

  const { showMessage } = useContext(MessagesContext);

  const emptyName = _.isEmpty(_.trim(newTitle));
  const emptydataType = _.isEmpty(_.trim(dataType));
  const importing = dataType === "select" || dataType === "multi_select";
  const emptyDataOrigin = importing && dataOrigin.length === 0;

  const cantSave = emptyName || emptydataType || emptyDataOrigin;

  const {
    NewOptionsLabel,
    MoreLocationsLabel,
    ActivePrimaryKey,
    DesactivatedPrimaryKey,
    DeleteColumnLabel,
    DataTypeLabel,
    SaveLabel,
    ValueAlreadyExistsLabel,
    YouNeedAtLeastOneOption,
  } = Languages["ESP"];

  const currentType = _.find(columnTypeArray, {
    Value: selectedColumn?.DataType,
  });
  const validTypes = (selectedColumn?.ChangeToDataTypes || []).map((colType) =>
    _.find(columnTypeArray, { Value: colType })
  );

  const datatypeSelectOptions = (() => {
    return [currentType, ...validTypes].map((columnType) => {
      return {
        value: columnType?.Value,
        searchableValues: `${columnType?.Value},${columnType?.Label}`,
        label: (
          <OptionLabelWrapper>
            <div className="cannot-change-datatype">
              <div style={{ height: "20px", width: "20px" }}>
                {DataTypeIcons[columnType?.Value || ""] && (
                  <img
                    src={DataTypeIcons[columnType?.Value || ""].icon}
                    alt=""
                    style={{
                      width: "50%",
                      height: "50%",
                      ...DataTypeIcons[columnType?.Value || ""]?.extraStyles,
                    }}
                  />
                )}
              </div>
              <p>{columnType?.Label}</p>
            </div>
          </OptionLabelWrapper>
        ),
      };
    });
  })();

  const renderDataOriginSelect =
    selectedColumn?.DataType === "select" ||
    selectedColumn?.DataType === "multi_select";

  const renderPrimaryKeySwitch = ["number", "string"].includes(
    selectedColumn?.DataType || ""
  );

  const handleOnDeleteOption = (e: any, index: any) => {
    e.preventDefault();
    if (dataOrigin.length === 1) {
      showMessage(YouNeedAtLeastOneOption, "error");
      return;
    }
    let data = [...dataOrigin];
    data.splice(index, 1);
    setDataOrigin(data);
  };

  const valueAlreadyExists = (value: string) => {
    const indexedDataOrigin = [...dataOrigin].reduce((acc, el) => {
      acc[el.label.toLowerCase()] = el;
      return acc;
    }, {});

    if (value.toLowerCase() in indexedDataOrigin) {
      showMessage(ValueAlreadyExistsLabel, "error");
      return true;
    }
    return false;
  };

  const handleInputConfirm = () => {
    if (inputValue === "") {
      setInputValue("");
      setInputVisible(false);
      return;
    }

    if (!valueAlreadyExists(inputValue)) {
      let newDataOrigin = [...dataOrigin];
      newDataOrigin.push({ label: inputValue.trim() });
      setDataOrigin(newDataOrigin);
    }

    setInputValue("");
  };

  const handleInputOpcConfirm = () => {
    let modifiedValue = opcValue.trim();
    if (modifiedValue === "") {
      setOpcValue("");
      setInputOpcVis(false);
      setOpcIndex(null);
      return;
    }

    if (!valueAlreadyExists(modifiedValue)) {
      let data = [...dataOrigin];
      const newOpc = { ...data[opcIndex], label: modifiedValue };
      data.splice(opcIndex, 1, newOpc);
      setDataOrigin(data);
    }

    setOpcValue("");
    setInputOpcVis(false);
    setOpcIndex(null);
  };

  const toggleInput = async (inputVisible: any) => {
    await setInputVisible(inputVisible);
  };

  const toggleInputOpc = async (inputVisible: any) => {
    await setInputOpcVis(inputVisible);
  };

  const [updateVarDBColumn, updating] = useMutation<[]>({
    func: "Ver2-Vardbs-uvc",
    onSuccess: () => {
      closeModal();
      reload();
    },
  });

  const [deleteVarDBColumn, deleting] = useMutation<[]>({
    func: "Ver2-Vardbs-dvc",
    onSuccess: (res, { IdColumn }) => {
      closeModal();
      reload();
      let filters: any[] = JSON.parse(
        localStorage.getItem(`DBFilters-${IdTeam}-${IdVarDB}`) || "[]"
      );
      filters = filters.filter(({ IdColumn: Id }) => IdColumn !== Id);
      localStorage.setItem(
        `DBFilters-${IdTeam}-${IdVarDB}`,
        JSON.stringify(filters)
      );
    },
  });

  const handleClose = () => {
    closeModal();
    setNewTitle(selectedColumn?.Title || "");
    setDataOrigin(selectedColumn?.DataOrigin ?? []);
  };

  const handleTogglePrimaryKey = () => {
    updateVarDBColumn({
      args: {
        IdTeam,
        IdVarDB,
        Column: {
          IdColumn: selectedColumn?.IdColumn,
          DataType: dataType,
          Title: newTitle,
          DataOrigin: dataOrigin,
          Configuration: configuration,
          DataOriginFromIdColumn: "",
          PrimaryKey: !Boolean(selectedColumn?.PrimaryKey),
          Position: selectedColumn?.Position,
          LastPosition: selectedColumn?.Position,
        },
      },
    });
  };

  const handleSetDataType = (dataType: string) => {
    setDataType(dataType);
    if (dataType === "select") {
      setConfiguration("CannotAddOptions");
    } else {
      setConfiguration("");
    }
  };

  const handleConfirm = (newTitle: string) => {
    const newTitleEmpty = _.isEmpty(_.trim(newTitle));
    const isSameTitle = _.trim(newTitle) === selectedColumn?.Title;
    const isSameDataOrigin = dataOrigin === selectedColumn?.DataOrigin;
    const isSameDataType = dataType === selectedColumn?.DataType;
    const isSameConfig = configuration === selectedColumn?.Configuration;

    if (newTitleEmpty) return;
    if (isSameTitle && isSameDataOrigin && isSameDataType && isSameConfig)
      return;

    updateVarDBColumn({
      args: {
        IdTeam,
        IdVarDB,
        Column: {
          IdColumn: selectedColumn?.IdColumn,
          DataType: dataType,
          Title: newTitle,
          DataOrigin: dataOrigin,
          Configuration: configuration,
          DataOriginFromIdColumn: "",
          PrimaryKey: selectedColumn?.PrimaryKey,
          Position: selectedColumn?.Position,
          LastPosition: selectedColumn?.Position,
        },
      },
    });
  };

  const handleDelete = () => {
    deleteVarDBColumn({
      args: {
        IdTeam,
        IdVarDB,
        IdColumn: selectedColumn?.IdColumn,
      },
      shippedData: {
        IdColumn: selectedColumn?.IdColumn,
      },
    });
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(dataOrigin);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const newDataOrigin = reorder(
      result.source.index,
      result.destination.index
    );

    setDataOrigin(newDataOrigin);
  };

  return (
    <StyledPaper>
      <ModalTitle>
        <p>Editar columna</p>
        <CloseIcon onClick={handleClose} />
      </ModalTitle>

      <Description>
        <p>
          Edita el nombre de tu columna y selecciona el tipo de dato que
          requieres
        </p>
      </Description>

      <InputContainer>
        <Input
          autoFocus
          disabled={updating || deleting}
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          placeholder="Nombre de la columna..."
        />
      </InputContainer>

      <ChangeDataTypeWrapper>
        {_.isEmpty(selectedColumn?.ChangeToDataTypes) ? (
          <div className="cannot-change-datatype">
            <div style={{ height: "20px", width: "20px" }}>
              {DataTypeIcons[selectedColumn?.DataType || ""] && (
                <img
                  src={DataTypeIcons[selectedColumn?.DataType || ""].icon}
                  alt=""
                  style={{
                    width: "50%",
                    height: "50%",
                    ...DataTypeIcons[selectedColumn?.DataType || ""]
                      ?.extraStyles,
                  }}
                />
              )}
            </div>
            <p>
              {DataTypeLabel}{" "}
              {_.find(columnTypeArray, {
                Value: selectedColumn?.DataType,
              })?.Label || ""}
            </p>
          </div>
        ) : (
          <GeestSelect
            value={dataType}
            options={datatypeSelectOptions}
            onChange={(newDataType: string) => handleSetDataType(newDataType)}
            placeholderSelect="Tipo de dato"
            valueNecesary
          />
        )}
      </ChangeDataTypeWrapper>

      {renderDataOriginSelect && (
        <DataOriginWrapper>
          <TagOptions
            onDragEnd={onDragEnd}
            DataOrigin={dataOrigin}
            handleOnClose={(val: any, index: number) =>
              handleOnDeleteOption(val, index)
            }
            inputVisible={inputVisible}
            inputValue={inputValue}
            setinputValue={(val: any) => setInputValue(val)}
            handleInputConfirm={handleInputConfirm}
            toggleInput={(val: boolean) => toggleInput(val)}
            InputOpcVis={inputOpcVis}
            OpcValue={opcValue}
            setOpcValue={setOpcValue}
            toggleInputOpc={toggleInputOpc}
            OpcIndex={opcIndex}
            setOpcIndex={setOpcIndex}
            handleInputOpcConfirm={handleInputOpcConfirm}
          />
        </DataOriginWrapper>
      )}

      {(selectedColumn?.DataType === "select" ||
        selectedColumn?.DataType === "multi_select") && (
        <CannAddOptionsWrapper>
          <StyledSwitch
            checked={configuration === "CanAddOptions"}
            onChange={(val) =>
              setConfiguration(val ? "CanAddOptions" : "CannotAddOptions")
            }
          />
          <p>{NewOptionsLabel}</p>
        </CannAddOptionsWrapper>
      )}

      {selectedColumn?.DataType === "location" && (
        <SelectMoreLocations>
          <StyledSwitch
            checked={configuration === "SelectAndCurrent"}
            onChange={(val) =>
              setConfiguration(val ? "SelectAndCurrent" : "OnlyCurrent")
            }
          />
          <p>{MoreLocationsLabel}</p>
        </SelectMoreLocations>
      )}

      {renderPrimaryKeySwitch && (
        <SelectPrimaryKey>
          <StyledSwitch
            checked={Boolean(selectedColumn?.PrimaryKey)}
            onChange={handleTogglePrimaryKey}
            disabled={updating || deleting}
          />

          <div className="key-label">
            <PrimaryKey src={PrimaryKeyIcon} alt="" />
            <p>
              {Boolean(selectedColumn?.PrimaryKey)
                ? ActivePrimaryKey
                : DesactivatedPrimaryKey}
            </p>
          </div>
        </SelectPrimaryKey>
      )}

      <ButtonsRow>
        <Button
          type="secondary"
          danger
          size="large"
          disabled={updating || deleting}
          loading={updating || deleting}
          onClick={handleDelete}
        >
          {DeleteColumnLabel}
        </Button>

        <Button
          type="primary"
          size="large"
          disabled={updating || deleting || cantSave}
          loading={updating || deleting}
          onClick={() => handleConfirm(newTitle)}
        >
          {SaveLabel}
        </Button>
      </ButtonsRow>
    </StyledPaper>
  );
};

export default EditColumn;
