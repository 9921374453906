import React, { useState } from "react";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { CloseIcon } from "../../../components/hoverIcons";
import { Button } from "../../../GeestUI";
import { useMutation } from "../../../hooks";
import { useParams } from "react-router-dom";
import { Switch } from "antd";
import { Languages } from "./Dictionary";
import { TableData } from "../DataBases.d";
import PrimaryKeyIcon from "../../../resources/img/Databases/icon_key.svg";
import { PrimaryKey } from "../Styles";

const StyledPaper = styled(Paper)`
  border-radius: 6px !important;
  width: 432px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px;
`;

const ModalTile = styled.div`
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
`;

const Subtitle = styled(ModalTile)`
  font-size: 14px;
`;

const Input = styled.input`
  width: 100%;
  color: rgba(130, 141, 158, 1);
  border: 2px solid rgba(237, 236, 236, 1);
  border-radius: 6px;
  padding-left: 10px;
  height: 32px;
  font-family: Gotham-Book;
  ::placeholder {
    color: #828d9e;
  }
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  padding: 0 60px;

  p {
    margin: 0;
    text-align: center;
    font-size: 14px;
    color: #828d9e;
  }
`;

const SelectPrimaryKey = styled(SwitchContainer)`
  gap: 8px;
  padding: 12px 60px;
  .key-label {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const StyledSwitch = styled(Switch)`
  background: ${({ checked }) => (checked ? "#0273E9" : "#828D9E")};
`;

interface EditIdRowColumnProps {
  closeModal: () => void;
  rowOffset?: number;
  reload: () => void;
  pkColumn?: TableData | null;
}

const EditIdRowColumn: React.FC<EditIdRowColumnProps> = ({
  closeModal,
  rowOffset,
  reload,
  pkColumn,
}) => {
  const { IdTeam, IdVarDB } = useParams<{ IdTeam: string; IdVarDB: string }>();
  const [offset, setOffset] = useState<number>(rowOffset ? rowOffset + 1 : 1);
  const [updateRowOffset, updating] = useMutation({
    func: "Ver2-Vardbs-uird",
    onSuccess: reload,
  });
  const [updateVarDBColumn, updatingPK] = useMutation<[]>({
    func: "Ver2-Vardbs-uvc",
    onSuccess: () => {
      closeModal();
      reload();
    },
  });
  const { ActivePrimaryKey, DesactivatedPrimaryKey } = Languages["ESP"];

  const onSubmit = () => {
    updateRowOffset({
      args: {
        IdTeam,
        IdVarDB,
        RowOffset: offset - 1,
      },
    });
  };

  const handleTogglePrimaryKey = () => {
    updateVarDBColumn({
      args: {
        IdTeam,
        IdVarDB,
        Column: {
          IdColumn: pkColumn?.IdColumn,
          DataType: pkColumn?.DataType,
          Title: pkColumn?.Title,
          DataOrigin: pkColumn?.DataOrigin,
          Configuration: pkColumn?.Configuration || "",
          DataOriginFromIdColumn: "",
          PrimaryKey: false,
          Position: pkColumn?.Position,
          LastPosition: pkColumn?.Position,
        },
      },
    });
  };

  return (
    <StyledPaper>
      <ModalTile>
        Editar folio
        <CloseIcon onClick={closeModal} />
      </ModalTile>
      <Content>
        <Subtitle>Iniciar folio a partir de:</Subtitle>
        <Input
          type="number"
          min={1}
          value={offset}
          onChange={(e) => setOffset(Number(e.target.value))}
        />
        <SelectPrimaryKey>
          <StyledSwitch
            checked={!Boolean(pkColumn)}
            onChange={handleTogglePrimaryKey}
            disabled={!Boolean(pkColumn) || updatingPK}
          />

          <div className="key-label">
            <PrimaryKey src={PrimaryKeyIcon} alt="" />
            <p>
              {!Boolean(pkColumn) ? ActivePrimaryKey : DesactivatedPrimaryKey}
            </p>
          </div>
        </SelectPrimaryKey>
      </Content>
      <ButtonContainer>
        <Button type="primary" loading={updating} onClick={onSubmit}>
          Guardar
        </Button>
      </ButtonContainer>
    </StyledPaper>
  );
};

export default EditIdRowColumn;
