import React from "react";
import _ from "lodash";
import { Row, Space } from "antd";
import { Typography } from "../../../../../GeestUI";
import { Languages } from "../Dictionary";
import { CloseIcon, PopoverContentWrapper, StageContainer } from "../Styles";
import { Stage } from "../../Dashboard.d";
import { Circle } from "../../../../../components/ColorPicker";

const { H3, Text } = Typography;
const { StageLabel } = Languages["ESP"];

interface StagePopoverProps {
  value: Stage[];
  stageCollection: Stage[];
  onChange: (oldStage: Stage[], newStage: Stage[]) => void;
  onClose: () => void;
}

const StagePopover: React.FC<StagePopoverProps> = ({
  value,
  stageCollection,
  onChange,
  onClose,
}) => {
  const onSelectStage = (stage: Stage): void => {
    const existentStage = !!_.find(value, { IdStage: stage.IdStage });
    if (existentStage) {
      // Remove stage
      onChange(value, _.reject(value, { IdStage: stage.IdStage }));
    } else {
      // Add stage
      onChange(value, [...value, stage]);
    }
  };

  return (
    <PopoverContentWrapper>
      <Row>
        <H3 mb="0" style={{ flex: 1 }}>
          {StageLabel}
        </H3>
        <CloseIcon onClick={onClose} />
      </Row>
      <Space direction="vertical" style={{ width: "100%" }} size={2}>
        {(stageCollection || []).map((stage) => (
          <div key={stage.IdStage}>
            <StageContainer
              justify="space-between"
              $selected={!!_.find(value, { IdStage: stage.IdStage })}
              onClick={() => onSelectStage(stage)}
              tabIndex={0}
              onKeyDown={({ key }) => {
                if (key === "Enter") onSelectStage(stage);
              }}
            >
              <Space size={4}>
                <Circle color={stage.Color} />
                <Text>{stage.Label}</Text>
              </Space>
            </StageContainer>
          </div>
        ))}
      </Space>
    </PopoverContentWrapper>
  );
};

export default StagePopover;
