import React from "react";
import { StateMachineProps } from "./Intranet.d";
import { Permissions, FreeTrials, Passwords } from "./States";

const StateMachine: React.FC<StateMachineProps> = ({ state }) => {
  switch (state) {
    case "permissions":
      return <Permissions />;
    case "freeTrials":
      return <FreeTrials />;
    case "passwords":
      return <Passwords />;
    default:
      return <></>;
  }
};

export default StateMachine;
