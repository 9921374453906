import React from "react";
import { ListItem, StyledTag } from "./styles";
import { EditIcon } from "../../../../components/hoverIcons";
import { TeamRole } from "./EditChatsDropdown.d";

interface TeamRoleItemProps {
  teamRole: TeamRole;
  showEditChatTeamRoles: boolean;
  selected: boolean;
  onEdit: (teamRole: TeamRole) => void;
  onSelect: (teamRole: TeamRole) => void;
}

const TeamRoleItem: React.FC<TeamRoleItemProps> = ({
  teamRole,
  selected,
  showEditChatTeamRoles,
  onEdit,
  onSelect,
}) => {
  const isEditable = showEditChatTeamRoles && teamRole.Label !== "Líder";

  const handleOnEdit: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    onEdit(teamRole);
  };
  const handleOnSelect: React.MouseEventHandler<HTMLDivElement> = () => {
    if (isEditable) onSelect(teamRole);
  };

  return (
    <ListItem
      $selected={selected}
      justify="space-between"
      align="middle"
      onClick={handleOnSelect}
    >
      <StyledTag color={teamRole.Color}>{teamRole.Label}</StyledTag>
      {isEditable && <EditIcon filled onClick={handleOnEdit} />}
    </ListItem>
  );
};

export default TeamRoleItem;
