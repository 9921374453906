import React from "react";
import {
  CountContainer,
  IconContainer,
  Img,
  ItemContent,
  Text,
} from "./Styles";
import Tooltip from "../../../../components/Tooltip";

const Item = ({ onClick, isOpen, route, active, count }) => {
  return (
    <Tooltip title={route?.name || ""} placement="right" hide={isOpen}>
      <ItemContent onClick={onClick} $active={active} $isOpen={isOpen}>
        <IconContainer>
          <Img src={route?.icon} />
          {false && !isOpen && count > 0 && (
            <CountContainer $fontSize={count >= 10 ? "10px" : "12px"}>
              {count}
            </CountContainer>
          )}
        </IconContainer>

        <Text $isOpen={isOpen}>
          <div>{route?.name}</div>
          <div>{count}</div>
        </Text>
      </ItemContent>
    </Tooltip>
  );
};

export default Item;
