import React, { useState } from "react";
import { Space } from "antd";
import _ from "lodash";
import { Label, StyledInput } from "./styles";
import { EditIcon } from "../../../components/hoverIcons";
import { useMutation } from "../../../hooks";
import { useParams } from "react-router-dom";

interface ProcessNameProps {
  processName: string;
  onFinishEditing: () => void;
}

const ProcessName: React.FC<ProcessNameProps> = ({
  processName,
  onFinishEditing,
}) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const [editingProcessName, setEditingProcessName] = useState<string | null>(
    null
  );

  const [updateProcessTemplate] = useMutation<[]>({
    func: "Ver2-Configurator-upt",
    onSuccess: () => {
      onFinishEditing();
      setEditingProcessName(null);
    },
    onError: () => setEditingProcessName(null),
  });

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") onSubmit();
  };
  const onEdit: React.MouseEventHandler<HTMLDivElement> = () =>
    setEditingProcessName(processName);
  const onChangeText: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setEditingProcessName(e.target.value);
  const onSubmit = () => {
    const Name = _.trim(editingProcessName ?? "");
    if (!_.isEmpty(Name) && Name !== processName)
      updateProcessTemplate({
        args: {
          IdTeam,
          IdProcessTemplate,
          Name,
        },
      });
    else setEditingProcessName(null);
  };

  if (editingProcessName !== null)
    return (
      <StyledInput
        autoFocus
        type="text"
        value={editingProcessName}
        onChange={onChangeText}
        onKeyDown={handleKeyDown}
        onBlur={onSubmit}
        onFocus={(e) => e.currentTarget.select()}
      />
    );
  return (
    <Space align="center">
      <Label>{processName}</Label>
      <EditIcon filled size={32} onClick={onEdit} />
    </Space>
  );
};

export default ProcessName;
