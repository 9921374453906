import { IRoute } from "./routes.d";
import { isProd } from "./constants/PlatformDetails";
// Icons
import GeestPendings from "./resources/img/Sidebar/GeestPendings.svg";
import GeestProcess from "./resources/img/Sidebar/GeestProcess.svg";
import GeestDB from "./resources/img/Sidebar/GeestDB.svg";
import GeestReports from "./resources/img/Sidebar/GeestReports.svg";
import GeestStatistics from "./resources/img/Sidebar/GeestStatistics.svg";
// Dev
import Playground from "./playground";
// Auth
import Login from "./views/Login/Login";
import Register from "./views/Register/Register";
import ForgotPass from "./views/ForgotPass/ForgotPass";
// Home
import Templates from "./views/Templates/Templates";
import DataBases from "./views/DataBases";
import DataBase from "./views/DataBases/DataBase";
import Teams from "./views/Teams/Teams";
import Reports from "./views/Reports";
import Dashboards from "./views/Dashboards";
import Premium from "./views/Premium";
import Settings from "./views/Settings";
import Statistics from "./views/Statistics";
import Pendings from "./views/Pendings";
import Configurator from "./views/Configurator";
import DataBaseColumns from "./views/DataBases/DataBaseColumns";
import PDFConfigurator from "./views/Configurator/PDFConfigurator";
import Notifications from "./views/Notifications/Notifications";
import Intranet from "./views/Intranet/Intranet";
const homeRoutes: IRoute[] = [
  {
    path: "/pendings",
    name: "Mis pendientes",
    component: Pendings,
    layout: "/home",
    icon: GeestPendings,
  },
  {
    path: "/templates",
    name: "Procesos",
    component: Templates,
    layout: "/home",
    icon: GeestProcess,
  },
  {
    path: "/template/folder/:IdParentFolder",
    name: "Carpeta",
    component: Templates,
    layout: "/home",
    hidden: true,
  },
  {
    path: "/configurator/:IdTeam/:IdProcess",
    name: "Configurador",
    component: Configurator,
    layout: "/home",
    hidden: true,
  },
  {
    path: "/pdf-configurator/:IdTeam/:IdProcess/:IdField",
    name: "PDF Config",
    component: PDFConfigurator,
    layout: "/home",
    hidden: true,
  },
  {
    path: "/databases",
    name: "Bases de datos",
    component: DataBases,
    layout: "/home",
    icon: GeestDB,
  },
  {
    path: "/database/folder/:IdParentFolder",
    name: "DB Carpeta",
    component: DataBases,
    layout: "/home",
    hidden: true,
  },
  {
    path: "/database/columns/:IdVarDB/:IdTeam",
    name: "Columas de base de datos",
    component: DataBaseColumns,
    layout: "/home",
    hidden: true,
  },
  {
    path: "/database/:IdVarDB/:IdTeam",
    name: "Detalle de bases de datos",
    component: DataBase,
    layout: "/home",
    hidden: true,
  },
  {
    path: "/reports",
    name: "Reportes",
    component: Reports,
    layout: "/home",
    icon: GeestStatistics,
  },
  {
    path: "/dashboards",
    name: "Dashboards",
    component: Dashboards,
    layout: "/home",
    icon: GeestReports,
  },
  {
    path: "/teams",
    name: "Mis empresas",
    component: Teams,
    layout: "/home",
    hidden: true,
  },
  {
    path: "/premium",
    name: "Mejorar mi plan",
    component: Premium,
    layout: "/home",
    hidden: true,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: "/home",
    hidden: true,
  },
  {
    path: "/statistics",
    name: "Estadísticas",
    component: Statistics,
    layout: "/home",
    icon: GeestStatistics,
    hidden: true,
  },
  {
    path: "/playground",
    name: "Playground",
    component: Playground,
    layout: "/home",
    hidden: true,
    disabled: isProd,
  },
  {
    path: "/notifications",
    name: "Notificaciones",
    component: Notifications,
    layout: "/home",
    hidden: true,
  },
  {
    path: "/intranet",
    name: "Intranet",
    component: Intranet,
    layout: "/home",
    hidden: true,
  },
];

const authRoutes: IRoute[] = [
  {
    path: "/login",
    name: "Iniciar sesión",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Registro",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/forgot/:token/:IdUser",
    name: "Olvidé mi contraseña",
    component: ForgotPass,
    layout: "/auth",
    exact: true,
  },
];

const routes = [...homeRoutes, ...authRoutes];

export default routes;
