import React from "react";
import styled from "styled-components";
import { UserPicture, UserType } from ".";
import { Tag } from "antd";
import DataTypeIcons from "../DataTypeIcons";
//@ts-ignore
import emoji from "emoji-dictionary";
import { IoEyeOutline } from "react-icons/io5";
import moment from "moment";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 -8px;

  width: 339px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
  border-bottom: 1px solid #edecec;

  p.username {
    margin: 0;
    color: #48505e;
    font-family: Gotham-Bold;
  }
`;

const TasksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TasksContent = styled.div<{ $border: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  padding: 10px 3px;
  font-family: Gotham-Book;
  font-size: 10px;
  color: #48505e;

  border-bottom: ${({ $border }) => ($border ? "1px solid #edecec" : "none")};
`;

const TaskHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 6px;
  width: 100%;

  p {
    margin: 0;
    font-size: 12px;
  }

  span {
    font-family: Gotham-Bold;
  }
`;

const TaskRevisionStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RevisionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  padding: 3px;
  cursor: pointer;

  :hover {
    background-color: #f2f8fe;
  }
`;

const TaskFooter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 6px;
  width: 100%;

  p {
    margin: 0;
  }
`;

const StyledTag = styled(Tag)`
  border-radius: 6px;
  border-width: 2px;
  font-size: 10px;
  font-family: Gotham-Bold;
  margin: 0;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 12px;

  p {
    margin-top: 2px;
  }
`;

export const IconContainer = styled.div<{
  m: string;
  $color?: string;
  cursorGrab?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  margin: ${({ m }) => m};
  color: ${({ $color }) => ($color ? $color : "#48505e")};
  cursor: ${({ cursorGrab }) => (cursorGrab ? "grab" : "auto")};
`;

export interface StatusDataOriginType {
  Label: string;
  Value: number;
}

interface Task {
  Title: string;
  IdStatus: number;
  StartedAt: string;
  Revision: boolean;
  StuckTime: string;
}

interface TaksPopoverProps {
  tasks: Task[];
  user: UserType;
  statusDataOrigin: StatusDataOriginType[];
}

const TaksPopover: React.FC<TaksPopoverProps> = ({
  tasks,
  user,
  statusDataOrigin,
}) => {
  const getStatusInfo = (status: number | undefined | null) => {
    const statusInfo = status
      ? statusDataOrigin.find((s) => s.Value === status)
      : null;
    if (!statusInfo) return { color: "default", label: status };

    switch (statusInfo.Label) {
      case "New":
        return { color: "yellow", label: "Nuevo" };
      case "OnTime":
        return { color: "cyan", label: "Iniciado" };
      case "Completed":
        return { color: "green", label: "Terminado" };
      case "Canceled":
        return { color: "default", label: "Cancelado" };
      case "WaitingToStart":
        return { color: "orange", label: "Por iniciar" };
      case "Stuck":
        return { color: "red", label: "Vencido" };
      default:
        return { color: "default", label: status };
    }
  };

  const getDate = (date: string) => {
    return moment(date).format("DD/MMM/YY HH:mm");
  };

  return (
    <Container onClick={() => console.log(tasks)}>
      <TitleWrapper>
        <UserPicture
          pic={user.ProfilePicture}
          picSize="20px"
          initials={user.Initials}
        />
        <p className="username">{`${user.FirstName} ${user.LastName}`}</p>
      </TitleWrapper>

      <TasksList>
        {tasks.map((task, idx) => (
          <TasksContent key={idx} $border={idx !== tasks.length - 1}>
            <TaskHeader>
              <p>
                <span>Tarea: </span>
                {task.Title.replace(/\n/gi, "&nbsp; \n").replace(
                  /:\w+:/gi,
                  (name) => emoji.getUnicode(name) ?? name
                )}
              </p>

              <TaskRevisionStatus>
                {task.Revision && (
                  <RevisionIconContainer>
                    <IoEyeOutline size={14} color="rgba(2, 115, 233, 1)" />
                  </RevisionIconContainer>
                )}

                <StyledTag color={getStatusInfo(task.IdStatus).color}>
                  {getStatusInfo(task.IdStatus).label}
                </StyledTag>
              </TaskRevisionStatus>
            </TaskHeader>
            <TaskFooter>
              <DateWrapper>
                <IconContainer m="0">
                  {DataTypeIcons["time"] && (
                    <img
                      src={DataTypeIcons["time"].icon}
                      alt=""
                      style={{
                        width: "10px",
                        height: "10px",
                        ...DataTypeIcons["time"].extraStyles,
                      }}
                    />
                  )}
                </IconContainer>

                <p>{getDate(task.StartedAt)}</p>
              </DateWrapper>

              {task.StuckTime && (
                <DateWrapper>
                  <IconContainer m="0">
                    {DataTypeIcons["duration"] && (
                      <img
                        src={DataTypeIcons["duration"].icon}
                        alt=""
                        style={{
                          width: "10px",
                          height: "10px",
                          ...DataTypeIcons["duration"].extraStyles,
                        }}
                      />
                    )}
                  </IconContainer>

                  <p>Vencida {task.StuckTime}</p>
                </DateWrapper>
              )}
            </TaskFooter>
          </TasksContent>
        ))}
      </TasksList>
    </Container>
  );
};

export default TaksPopover;
