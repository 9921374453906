import React, { useRef, useState, useContext, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import _ from "lodash";
import { Typography } from "../../GeestUI";
import PendingList from "./PendingsList";
import ChatList from "./ChatList";
import { useFetch, useMutation, useLocalStore } from "../../hooks";
import DetailPending from "./DetailPending";
import ChatsModal from "./ChatsModal";
import { dateHourFormat } from "../../Helpers";
import ViewTitle from "../../components/ViewTitle/ViewTitle";
import moment from "moment";
import { Popover } from "@material-ui/core";
import { AddColumnPopup } from "./PendingsList/Popups";
import GeestSelect from "../../GeestUI/GeestSelect";
import { Languages } from "./Dicionary";
import FilterStatusIndicator from "./FilterStatusIndicator";
import {
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineFlag,
  AiOutlinePlus,
} from "react-icons/ai";
import StartToday from "../../resources/img/FilterOptions/StartToday.svg";
import StuckToday from "../../resources/img/FilterOptions/StuckToday.svg";
import StartTomorrow from "../../resources/img/FilterOptions/StartTomorrow.svg";
import StuckTomorrow from "../../resources/img/FilterOptions/StuckTomorrow.svg";
import {
  GetUserPendingsResponse,
  StartedTask,
  TasksWaitingToStart,
  ChatsData,
  ChatFromUserPendings,
} from "./Pendings.d";
import {
  SearchBar,
  SearchBarContainer,
  CloseIcon,
  FiltersResetButton,
  FiltersBox,
  HeaderContent,
  AddColumnContiner,
  ViewContent,
  OptionIconContainer,
  FilterElement,
  OptionLabelContainer,
} from "./styles";
import Button from "../../GeestUI/Button";
import { getCurrencyFormatSearchableValues } from "../../components/DynamicInput/Components/helpers/CurrencyHelpers";
import { getPhoneFormatSearchableValues } from "../../components/DynamicInput/Components/helpers/PhoneNumberHelpers";
import { SocketContext } from "../../config/socket";
import taskOrderIcon from "../../resources/img/OrderOptions/task.svg";
import processOrderIcon from "../../resources/img/OrderOptions/process.svg";

const { H1 } = Typography;

const {
  ViewTitleLabel,
  OrderLabel,
  OrderOptions,
  FilterLabel,
  FilterOptions,
  SelectPlaceholder,
  SearchPlaceholder,
  AddColumnLabel,
} = Languages["ESP"];

const Pendings: React.FC = () => {
  const history = useHistory();
  const [consultTasks, setConsultTasks] = useState<
    (StartedTask | TasksWaitingToStart)[]
  >([]);
  const [orderedTasks, setOrderedTasks] = useState<
    (StartedTask | TasksWaitingToStart)[]
  >([]);
  const [tasks, setTasks] = useState<(StartedTask | TasksWaitingToStart)[]>([]);
  const [chats, setChats] = useState<ChatFromUserPendings[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [orderBy, setOrderBy] = useLocalStore("GeestOrderBy", "priority");
  const [timeFilter, setTimeFilter] = useLocalStore("GeestTimeFilter", "All");
  const [showChatsModal, setShowChatsModal] = useState<boolean>(false);
  const [chatsData, setChatsData] = useState<ChatsData>();
  const [idForChatList, setIdForChatList] = useState<number>(0);
  const [addColumnVisible, setAddColumnVisible] = useState<boolean>(false);

  const popoverRef = useRef(null);
  const searchBarRef = useRef(null);
  const [socket] = useContext<any>(SocketContext);

  const orderByOptions = [
    {
      label: (
        <OptionLabelContainer>
          <OptionIconContainer>
            <AiOutlineFlag color="#828d9e" size={16} />
          </OptionIconContainer>
          <p>{OrderOptions.Priority}</p>
        </OptionLabelContainer>
      ),
      value: "priority",
    },
    {
      label: (
        <OptionLabelContainer>
          <OptionIconContainer>
            <AiOutlineClockCircle color="#828d9e" size={16} />
          </OptionIconContainer>
          <p>{OrderOptions.Deadline}</p>
        </OptionLabelContainer>
      ),
      value: "deadline",
    },
    {
      label: (
        <OptionLabelContainer>
          <OptionIconContainer>
            <AiOutlineCalendar color="#828d9e" size={16} />
          </OptionIconContainer>
          <p>{OrderOptions.StartDate}</p>
        </OptionLabelContainer>
      ),
      value: "startDate",
    },
    {
      label: (
        <OptionLabelContainer>
          <OptionIconContainer>
            <img src={taskOrderIcon} alt="" />
          </OptionIconContainer>
          <p>{OrderOptions.Task}</p>
        </OptionLabelContainer>
      ),
      value: "task",
    },
    {
      label: (
        <OptionLabelContainer>
          <OptionIconContainer>
            <img src={processOrderIcon} alt="" />
          </OptionIconContainer>
          <p>{OrderOptions.Process}</p>
        </OptionLabelContainer>
      ),
      value: "process",
    },
  ];

  const timeFilterOptions = [
    {
      label: (
        <OptionLabelContainer>
          <FilterStatusIndicator status="All" />
          <p>{FilterOptions.All}</p>
        </OptionLabelContainer>
      ),
      value: "All",
    },
    {
      label: (
        <OptionLabelContainer>
          <FilterStatusIndicator status="Actuals" />
          <p>{FilterOptions.Actual}</p>
        </OptionLabelContainer>
      ),
      value: "Actual",
    },
    {
      label: (
        <OptionLabelContainer>
          <FilterStatusIndicator status="Started" />
          <p>{FilterOptions.Started}</p>
        </OptionLabelContainer>
      ),
      value: "Started",
    },
    {
      label: (
        <OptionLabelContainer>
          <FilterStatusIndicator status="Stuck" />
          <p>{FilterOptions.Stuck}</p>
        </OptionLabelContainer>
      ),
      value: "Stuck",
    },
    {
      label: (
        <OptionLabelContainer>
          <FilterStatusIndicator status="ToStart" />
          <p>{FilterOptions.Waiting}</p>
        </OptionLabelContainer>
      ),
      value: "Waiting",
    },
    {
      label: (
        <OptionLabelContainer>
          <OptionIconContainer>
            <img src={StartToday} alt="" />
          </OptionIconContainer>
          <p>{FilterOptions.StartToday}</p>
        </OptionLabelContainer>
      ),
      value: "StartToday",
    },
    {
      label: (
        <OptionLabelContainer>
          <OptionIconContainer>
            <img src={StuckToday} alt="" />
          </OptionIconContainer>
          <p>{FilterOptions.StuckToday}</p>
        </OptionLabelContainer>
      ),
      value: "StuckToday",
    },
    {
      label: (
        <OptionLabelContainer>
          <OptionIconContainer>
            <img src={StartTomorrow} alt="" />
          </OptionIconContainer>
          <p>{FilterOptions.StartTomorrow}</p>
        </OptionLabelContainer>
      ),
      value: "StartTomorrow",
    },
    {
      label: (
        <OptionLabelContainer>
          <OptionIconContainer>
            <img src={StuckTomorrow} alt="" />
          </OptionIconContainer>
          <p>{FilterOptions.StuckTomorrow}</p>
        </OptionLabelContainer>
      ),
      value: "StuckTomorrow",
    },
  ];

  const { data, reload, loading } = useFetch<GetUserPendingsResponse>({
    func: "Ver2-MyPendings-gup",
    args: { Filter: timeFilter ?? "All" },
    onSuccess: ({
      StartedTasks,
      TasksWaitingToStart,
      Chats,
      PriorityOrder,
    }) => {
      const tasksAux = [...StartedTasks, ...TasksWaitingToStart];
      orderTasks(orderBy, tasksAux, PriorityOrder);
      setConsultTasks(tasksAux);
      setChats(Chats);
    },
  });

  useEffect(() => {
    socket?.on("reloadTasks", () => {
      resetPendings({ args: { Filter: timeFilter } });
    });
    window.addEventListener("socketReconnect", reload);

    return () => {
      socket?.off("reloadTasks");
      window.removeEventListener("socketReconnect", reload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, timeFilter]);

  const [resetPendings] = useMutation<GetUserPendingsResponse>({
    func: "Ver2-MyPendings-gup",
    onSuccess: ({
      StartedTasks,
      TasksWaitingToStart,
      Chats,
      PriorityOrder,
    }) => {
      const tasksAux = [...StartedTasks, ...TasksWaitingToStart];
      orderTasks(orderBy, tasksAux, PriorityOrder);
      setConsultTasks(tasksAux);
      setChats(Chats);
    },
  });

  const { DynamicColumns = [], PriorityOrder = [] } = data || {};

  const filterTasks = (
    tasks: (StartedTask | TasksWaitingToStart)[],
    filterValue: string
  ) => {
    const filteredTasks = _.filter(tasks, (Task) => {
      const task = Task as StartedTask | TasksWaitingToStart;

      // getting dynamic columns keys
      const keys = task.DynamicData ? Object.keys(task.DynamicData) : [];
      const dynamic =
        keys
          // getting values with keys
          .map((key) => task.DynamicData[+key])
          // removing all undefined values from the rows without data on any dynamic column
          .filter((d) => !_.isEmpty(d))
          // get all values on those rows that has some value
          .map((d) => {
            // if is currency or phone
            if (d.Value) {
              let format = "";
              format += getCurrencyFormatSearchableValues(d.Format);
              format += getPhoneFormatSearchableValues(d.Format);
              return `${d.Value},${format}`;
            }
            // if is location
            if (_.isString(d) && d.split("|").length > 1)
              return d.split("|")[1];
            // if is user
            if (_.isArray(d) && d[0].FirstName)
              return d
                .map(
                  (u) => `${u.FirstName} ${u.LastName} ${u.Initials} ${u.Email}`
                )
                .join(", ");
            // default case
            return d;
          })
          // removing undefined values when row only have data on some of the dynamic columns
          .filter((v) => !_.isEmpty(v))
          // turning all into a string so it could be used on search
          .join(", ") || "";

      for (const property of [
        task.Priority?.Title.toLowerCase() || "",
        task.TaskTitle?.toLowerCase() || "",
        task.ProcessExecutionTitle?.toLowerCase() || "",
        task.Stage?.Label.toLowerCase() || "",
        dateHourFormat(task.StartedAt, true).toLowerCase(),
        dateHourFormat(task.Deadline, true).toLowerCase(),
        dynamic.toLowerCase(),
      ])
        if (property.includes(filterValue.toLowerCase())) return true;

      return false;
    });
    setTasks(filteredTasks);
  };

  const orderTasks = (
    order: string,
    tasksAux: (StartedTask | TasksWaitingToStart)[],
    priorityOrder: number[]
  ) => {
    let newTasks = [] as (StartedTask | TasksWaitingToStart)[];
    switch (order) {
      case "startDate":
        newTasks = [...tasksAux].sort((l, r) =>
          moment(l.StartedAt).diff(r.StartedAt)
        );
        setOrderedTasks(newTasks);
        filterTasks(newTasks, filter);
        break;

      case "deadline":
        newTasks = [...tasksAux].sort((l, r) =>
          moment(l.Deadline).diff(r.Deadline)
        );
        setOrderedTasks(newTasks);
        filterTasks(newTasks, filter);
        break;
      case "task":
        newTasks = [...tasksAux].sort((l, r) =>
          l.TaskTitle.replace(/:\w+:/gi, "")
            .trim()
            .localeCompare(r.TaskTitle.replace(/:\w+:/gi, "").trim())
        );
        console.log(newTasks);
        setOrderedTasks(newTasks);
        filterTasks(newTasks, filter);
        break;
      case "process":
        newTasks = [...tasksAux].sort((l, r) =>
          l.ProcessExecutionTitle.replace(/:\w+:/gi, "")
            .trim()
            .localeCompare(
              r.ProcessExecutionTitle.replace(/:\w+:/gi, "").trim()
            )
        );
        setOrderedTasks(newTasks);
        filterTasks(newTasks, filter);
        break;
      // case priority
      case "priority":
        const startedAux = _.cloneDeep(tasksAux).filter(
          (task) => task.TaskStatus !== "WaitingToStart"
        );
        const waitingAux = _.cloneDeep(tasksAux).filter(
          (task) => task.TaskStatus === "WaitingToStart"
        );

        // find all with priority
        // * filtering started
        priorityOrder.forEach((IdTaskPriority) => {
          let res = _.filter(
            startedAux,
            (task) => task.Priority?.IdTaskPriority === IdTaskPriority
          );
          newTasks = [...newTasks, ...res];
        });
        // append at the end those without priority
        const noPriorityStarted = _.filter(
          tasksAux,
          (task) =>
            task.TaskStatus !== "WaitingToStart" && _.isEmpty(task.Priority)
        );
        newTasks = [...newTasks, ...noPriorityStarted];

        // * filtering waiting
        priorityOrder.forEach((IdTaskPriority) => {
          let res = _.filter(
            waitingAux,
            (task) => task.Priority?.IdTaskPriority === IdTaskPriority
          );
          newTasks = [...newTasks, ...res];
        });
        // append at the end those without priority
        const noPriorityWaiting = _.filter(
          tasksAux,
          (task) =>
            task.TaskStatus === "WaitingToStart" && _.isEmpty(task.Priority)
        );
        newTasks = [...newTasks, ...noPriorityWaiting];

        setOrderedTasks(newTasks);
        filterTasks(newTasks, filter);
        break;
    }
  };

  const handleSearch = (value: string) => {
    setFilter(value);
    if (value === "") {
      setTasks(orderedTasks.length > 0 ? orderedTasks : consultTasks);
      return;
    }
    filterTasks(orderedTasks.length > 0 ? orderedTasks : consultTasks, value);
  };

  const handleOnReload = (): void => {
    reload();
  };

  const [GetChatChannel] = useMutation<ChatsData>({
    func: "Ver2-MyPendings-gcc",
    onSuccess: (response) => {
      setChatsData(response);
      setShowChatsModal(true);
    },
  });

  const setIdExecutionChat = (id: number) => {
    setIdForChatList(id);
    GetChatChannel({
      args: {
        IdExecutionChat: id,
      },
    });
  };

  const onCloseDetailPending = (needReload = false): void => {
    if (needReload) handleOnReload();
    history.push("/home/pendings");
  };

  const onSelectTask = (task: StartedTask | TasksWaitingToStart): void =>
    history.push("/home/pendings/" + task.IdTaskValue);

  const handleReorderTasks = (order: string) => {
    setOrderBy(order);
    orderTasks(order, orderedTasks, PriorityOrder);
  };

  const handleReorderTasksDates = (newTime: string) => {
    setTimeFilter(newTime);
    resetPendings({ args: { Filter: newTime } });
  };

  return (
    <>
      <Route
        path="/home/pendings/:idPending"
        render={() => (
          <DetailPending
            onClose={onCloseDetailPending}
            reloadPendings={handleOnReload}
            onChangePending={(idPending, nextTask) => {
              onCloseDetailPending();
              setTimeout(() => {
                history.push({
                  pathname: "/home/pendings/" + idPending,
                  state: nextTask,
                });
              }, 1);
            }}
            resetFilters={() => handleSearch("")}
          />
        )}
        exact
      />
      {showChatsModal && (
        <ChatsModal
          onClose={() => setShowChatsModal(false)}
          chats={chats}
          chatsDataFirst={chatsData}
          idForChatList={idForChatList}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <ViewTitle
          headerMB=""
          headerMT="0"
          dividerMB="14px"
          dividerMT="12px"
          ShowTeamIcon
        >
          <HeaderContent>
            <H1 mb="0" style={{ whiteSpace: "nowrap" }}>
              {ViewTitleLabel}
            </H1>

            <FiltersBox>
              <FilterElement>
                <p>{OrderLabel}:</p>
                <GeestSelect
                  value={orderBy}
                  onChange={handleReorderTasks}
                  options={orderByOptions}
                  $width="180px"
                  $listWidth="180px"
                  placeholderSelect={SelectPlaceholder}
                  valueNecesary
                  disableSearch
                />
              </FilterElement>

              <FilterElement>
                <p>{FilterLabel}:</p>
                <GeestSelect
                  value={timeFilter}
                  onChange={handleReorderTasksDates}
                  options={timeFilterOptions}
                  $width="175px"
                  $listWidth="175px"
                  $listMaxHeight="auto"
                  placeholderSelect={SelectPlaceholder}
                  valueNecesary
                  disableSearch
                  itemsContainerContentWidth="100%"
                />
              </FilterElement>

              <SearchBarContainer>
                <SearchBar
                  type="text"
                  placeholder={SearchPlaceholder}
                  autoComplete="off"
                  style={{ flex: 1 }}
                  ref={searchBarRef}
                  value={filter}
                  onChange={({ target: { value } }) => handleSearch(value)}
                />
                {filter.length > 0 && (
                  <FiltersResetButton onClick={() => handleSearch("")}>
                    <CloseIcon />
                  </FiltersResetButton>
                )}
              </SearchBarContainer>
            </FiltersBox>

            <AddColumnContiner ref={popoverRef}>
              <Button
                onClick={() => setAddColumnVisible(true)}
                type="primary"
                Icon={AiOutlinePlus}
                size="large"
                style={{ width: "180px", padding: "6px" }}
              >
                {AddColumnLabel}
              </Button>
              <Popover
                open={addColumnVisible}
                anchorEl={popoverRef.current}
                onClose={() => setAddColumnVisible(false)}
                PaperProps={{
                  style: {
                    borderRadius: "10px",
                    width: "433px",
                    padding: "12px",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <AddColumnPopup
                  onClose={() => setAddColumnVisible(false)}
                  reload={() => handleOnReload()}
                />
              </Popover>
            </AddColumnContiner>
          </HeaderContent>
        </ViewTitle>

        <ViewContent>
          <PendingList
            isLoadingTaks={loading}
            tasks={tasks}
            dynamicColumns={DynamicColumns}
            onSelectTask={onSelectTask}
            reloadPendings={handleOnReload}
          />

          <ChatList Chats={chats} setIdExecutionChat={setIdExecutionChat} />
        </ViewContent>
      </div>
    </>
  );
};

export default Pendings;
