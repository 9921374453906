import styled from "styled-components";
import { Switch } from "antd";

export const TextEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  background-color: #eee;
  padding: 4px 6px;
  border-radius: 6px;

  p.title {
    margin: 0;
    font-size: 20px;
    font-family: Gotham-Bold;
  }
`;

export const DisplayControl = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
  }
`;

export const StyledSwitch = styled(Switch)`
  background: ${({ checked }) => (checked ? "#0273E9" : "#828D9E")};
`;

export const EditorValueWrapper = styled.div<{ $overflow?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${({ $overflow = false }) => ($overflow ? "max-height: 400px;" : "")}
`;

export const EditorTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;

  transition: background-color 0.35s ease;
  :hover {
    background-color: #eee;
  }
`;

export const EditorValueTitle = styled.p`
  margin: 0;
  font-family: "Gotham-Bold";
  font-size: 20px;
  color: #48505e;
`;
