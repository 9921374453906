import React from "react";
import { useFetch } from "../../../../hooks";
import ChatsContent from "../../../Pendings/ChatsModal/ChatsContent";
import { ChatsData } from "./ProcessDetail.d";

interface ChatsModalProps {
  IdProcessExecution?: number | null;
  onClose: () => void;
}

const ChatsModal: React.FC<ChatsModalProps> = ({
  IdProcessExecution,
  onClose,
}) => {
  const { data: chatsData } = useFetch<ChatsData>({
    func: "Ver2-Reports-gcc",
    args: { IdProcessExecution },
  });

  return <ChatsContent chatsData={chatsData} onClose={onClose} showHeader />;
};

export default ChatsModal;
