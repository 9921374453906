import React, { useState } from "react";
import _ from "lodash";
import { Checkbox, Row, Space, Typography, Divider } from "antd";
import {
  CheckboxLabel,
  CloseIcon,
  DeleteEmailButton,
  EmailContainer,
  Form,
  NotificationUsersContainer,
  StyledInput,
  StyledSelect,
} from "./styles";
import StripeInput from "../../views/Premium/StripeInput";
import CardAddOn from "./CardAddOn";
import { noop as NOOP } from "lodash";
import { regimenOptions } from "../../views/Premium/helpers";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "@material-ui/core";

const Input = ({ name, ...props }) => (
  <StyledInput
    label={name}
    placeholder={name}
    variant="outlined"
    size="small"
    {...props}
  />
);

export const defaultValues = {
  bussines: "",
  rfc: "XAXX010101000",
  address: "",
  zip: "",
  city: "",
  country: "",
  disclosure: false,
  regimen: null,
};

const FormComponent = ({ values = defaultValues, onChange = NOOP }) => {
  const [newOption, setNewOption] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [hoveredEmail, setHoveredEmail] = useState(null);

  const validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isNewEmailEmpty = _.isEmpty(_.trim(newEmail));
  const isNewEmailValid = validateEmail.test(_.trim(newEmail));
  const isDuplicatedEmail = [
    ...(values?.NotifyToCsv || "").split(","),
  ].includes(newEmail);

  const handleOnChange = (e) => onChange({ [e.target.id]: e.target.value });
  const handleOnChangeCheckbox = (e) =>
    onChange({ [e.target.id]: e.target.checked });

  const onEnterNewOption = () => {
    onChange({ regimen: newOption });
    setNewOption("");
  };

  const addNewNotificationUser = () => {
    if (isNewEmailEmpty || !isNewEmailValid || isDuplicatedEmail) return;
    if (!!values.NotifyToCsv) {
      onChange({
        NotifyToCsv: [...values.NotifyToCsv.split(","), newEmail].join(","),
      });
    } else {
      onChange({
        NotifyToCsv: newEmail,
      });
    }
    setNewEmail("");
  };

  const removeNewNotificationUser = (i) => {
    const auxArr = [...values.NotifyToCsv.split(",")];
    _.pullAt(auxArr, i);
    onChange({
      NotifyToCsv: auxArr.join(","),
    });
  };

  return (
    <Form>
      <Row justify="space-between" align="middle">
        <StripeInput />
        <CardAddOn />
      </Row>
      <Row style={{ marginTop: "1.5rem" }} justify="space-between">
        <Input
          name="Empresa"
          id="bussines"
          value={values.bussines}
          onChange={handleOnChange}
          borderColor={values.bussines ? "#39a52f" : "#e11909"}
        />
        <Input
          name="RFC"
          id="rfc"
          value={values.rfc}
          onChange={handleOnChange}
        />
        <Input
          name="Dirección"
          id="address"
          value={values.address}
          onChange={handleOnChange}
        />
        <Input
          name="Código postal"
          id="zip"
          value={values.zip}
          onChange={handleOnChange}
        />
        <Input
          name="Ciudad"
          id="city"
          value={values.city}
          onChange={handleOnChange}
          style={{ width: "25%" }}
        />
        <Input
          name="País"
          id="country"
          value={values.country}
          onChange={handleOnChange}
          style={{ width: "25%" }}
        />
        <StyledSelect
          style={{ width: "43%", height: "2.5rem" }}
          name="Régimen"
          id="regimen"
          placeholder="Régimen fiscal"
          value={values.regimen}
          onChange={(val) => onChange({ regimen: val })}
          allowClear
          showSearch
          optionFilterProp="label"
          options={regimenOptions}
          dropdownRender={(menu) => (
            <>
              {menu}
              <div>
                <Divider style={{ margin: "8px 0" }} />
                <Row
                  align="middle"
                  style={{ padding: "0 8px 4px", width: "100%" }}
                >
                  <Input
                    placeholder="Escribe otra opción"
                    style={{ flex: 1 }}
                    value={newOption}
                    onChange={(e) => setNewOption(e.target.value)}
                  />
                  <Typography.Link
                    onClick={onEnterNewOption}
                    style={{ whiteSpace: "nowrap", marginLeft: "0.5rem" }}
                  >
                    <PlusOutlined /> Agregar
                  </Typography.Link>
                </Row>
              </div>
            </>
          )}
        />
        <Input
          name="Enviar facturas a"
          style={{ width: "77%" }}
          value={newEmail}
          type="email"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              addNewNotificationUser();
            }
          }}
          onChange={({ target: { value } }) => setNewEmail(value)}
        />
        <Button
          style={{
            width: "20%",
            height: "40px",
            borderRadius: "10px",
            color: "#525151",
            backgroundColor: "white",
          }}
          onClick={addNewNotificationUser}
        >
          <PlusOutlined /> Agregar
        </Button>
        <NotificationUsersContainer show={!!values.NotifyToCsv}>
          {!!values.NotifyToCsv &&
            values.NotifyToCsv.split(",").map((email, i) => (
              <EmailContainer
                key={email}
                onMouseEnter={() => setHoveredEmail(i)}
                onMouseLeave={() => setHoveredEmail(null)}
              >
                <p>{email}</p>
                <DeleteEmailButton
                  show={hoveredEmail === i}
                  onClick={() => removeNewNotificationUser(i)}
                >
                  <CloseIcon />
                </DeleteEmailButton>
              </EmailContainer>
            ))}
        </NotificationUsersContainer>
      </Row>
      <Space align="middle">
        <Checkbox
          id="disclosure"
          checked={values.disclosure}
          onChange={handleOnChangeCheckbox}
        />
        <CheckboxLabel>
          Mediante esta contratación doy fe y consentimiento de los{" "}
          <a
            href={`${process.env.REACT_APP_BACK}#/legal/terms`}
            target="_blank"
            rel="noreferrer"
          >
            Términos y condiciones
          </a>{" "}
          del servicio así como del{" "}
          <a
            href={`${process.env.REACT_APP_BACK}#/legal/privacy`}
            target="_blank"
            rel="noreferrer"
          >
            Aviso de privacidad
          </a>{" "}
          de Geest.
        </CheckboxLabel>
      </Space>
    </Form>
  );
};

export default FormComponent;
