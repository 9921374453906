import React from "react";
import SkeletonCell from "./SkeletonCell";
import {
  CellTypes,
  CellComponentProps,
  DynamicSkeletonCellProps,
} from "./DynamicSkeletonCell.d";
import SkeletonFromComponent from "./SkeletonFromComponent";

const CellComponents: Record<CellTypes, React.FC<CellComponentProps>> = {
  [CellTypes.DURATION]: () => <SkeletonCell />,
  [CellTypes.PROCESS_TEMPLATE_NAME]: () => <SkeletonCell />,
  [CellTypes.STUCK_TIME]: () => <SkeletonCell />,
  [CellTypes.TASK_DURATION]: () => <SkeletonCell />,
  [CellTypes.START_DATE]: () => <SkeletonCell />,
  [CellTypes.END_DATE]: () => <SkeletonCell />,
  [CellTypes.EXECUTION_ID]: () => <SkeletonCell />,
  [CellTypes.STATUS]: () => <SkeletonCell />,
  [CellTypes.PRIORITY]: () => <SkeletonCell />,

  [CellTypes.EXECUTION_NAME]: () => <SkeletonCell hasSquare isExecutionName />,
  [CellTypes.PROCESS_TRIGGER]: () => <SkeletonCell hasSquare />,

  [CellTypes.STAGE]: () => <SkeletonCell hasCircle />,
  [CellTypes.CURRENT_EXECUTORS]: () => <SkeletonCell hasCircle />,
  [CellTypes.LAST_INTERACTION]: () => <SkeletonCell hasCircle />,
  [CellTypes.STARTED_USER]: () => <SkeletonCell hasCircle />,
  [CellTypes.USERS_INVOLVED]: () => <SkeletonCell hasCircle />,

  [CellTypes.INFO_FROM_FIELD]: ({ dataType }) => (
    <SkeletonFromComponent dataType={dataType} />
  ),
  [CellTypes.INFO_FROM_COLUMN]: ({ dataType }) => (
    <SkeletonFromComponent dataType={dataType} />
  ),
};

const DynamicSkeletonCell: React.FC<DynamicSkeletonCellProps> = ({
  cellType,
  dataType,
}) => {
  const CellComponent = CellComponents[cellType];
  if (CellComponent) {
    return <CellComponent dataType={dataType} />;
  }

  return null;
};

export default DynamicSkeletonCell;
