import styled from "styled-components";
import ReloadFile from "../../../../../resources/img/RELOADFILE.svg";
import ReloadFileHover from "../../../../../resources/img/RELOADFILE_HOVER.svg";

export const FileContainer = styled.div`
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    min-height: 300px;
    max-height: 90vh;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FileWrapper = styled.div`
  padding: 30px 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

export const LoadingWrapper = styled.div`
  background-color: white;
  position: absolute;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 80%;
`;

export const PDFWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 60vh;
  max-height: 60vh;
  overflow: auto;
  background-color: #eee;
  padding: 0 20px;

  canvas {
    margin: 0 auto;
    margin-bottom: 20px;
    height: auto;
    width: 80%;
  }
`;

export const PDFPlaceholder = styled.div`
  width: 100%;
  height: 60vh;
  background-color: #eee;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 65vh;
  overflow: auto;
  display: flex;
  justify-content: center;

  img {
    display: block;
    height: 100%;
    max-height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: cover;
  }
`;

export const FrameWrapper = styled.div`
  position: relative;
  height: 65vh;
  width: 100%;
`;

export const ErrorContainer = styled.div`
  font-family: Gotham-Book;
  color: grey;
  text-align: center;
  font-size: 20px;
`;

export const ReloadFileButton = styled.div`
  height: 5rem;
  width: 5rem;
  background-image: url(${ReloadFile});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: 0.2s all;
  :hover {
    background-image: url(${ReloadFileHover});
  }
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;
