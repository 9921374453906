import React from "react";
import styled from "styled-components";
import { Divider, Empty } from "antd";
import Img from "../../../components/Img/Img";
import { noop as NOOP } from "lodash";
import { Tooltip } from "@material-ui/core";

const TitleContainer = styled.div`
  text-align: center;
  position: relative;
`;

const TitleText = styled.b`
  font-size: 18px;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 0;
  top: -0.5rem;
`;

const Icon = styled(Img)`
  height: 2rem;
  width: 2rem;
`;

const StyledDivider = styled(Divider)<{ color?: string }>`
  margin-top: 0;
  margin-bottom: 0.5rem;
  border-color: ${({ color }) => color || "rgba(0, 0, 0, 0.10)"};
`;

export const Title: React.FC<{
  color?: string;
  title: string;
  icon?: string;
  iconTooltip?: string;
  onClickIcon?: () => void;
}> = ({ title, color, icon, onClickIcon = NOOP, iconTooltip = "" }) => (
  <TitleContainer>
    <TitleText>{title}</TitleText>
    {!!icon && (
      <Tooltip
        title={<div style={{ fontSize: "14px" }}>{iconTooltip}</div>}
        arrow
      >
        <IconContainer>
          <Icon
            size="contain"
            cursor="pointer"
            img={icon}
            onClick={onClickIcon}
          />
        </IconContainer>
      </Tooltip>
    )}
    <StyledDivider color={color} />
  </TitleContainer>
);

export const EmptyData: React.FC = () => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Filtra la información para ver los datos"
    />
  );
};
