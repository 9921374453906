import { Row, Input } from "antd";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const { TextArea } = Input;

export const ModalContainer = styled(Row)`
  display: flex;
  flex: 1;
  overflow: auto;
  width: 100%;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #727e8b !important;
`;

export const ModalHeaderContent = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 12px;

  p {
    margin: 0;
    color: #48505e;
    font-family "Gotham-Bold";
    font-size: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ChatHeader = styled(Row)`
  width: 90%;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.35s ease;
  &:hover {
    background: rgba(232, 236, 241, 0.5);
  }
`;

export const ChatTitleOnModal = styled.div`
  padding-left: 8px;
  max-width: 475px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ChannelsContainer = styled.div`
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 6px;

  .channels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    flex: 1;
  }
`;

export const ChannelBox = styled.div<{ $selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 114px;
  height: 32px;
  border-radius: 6px;
  cursor: pointer;

  background: ${({ $selected = false }) => ($selected ? "#0273e9" : "white")};
  border: 2px solid
    ${({ $selected = false }) => ($selected ? "#0273e9" : "#828d9e")};
  color: ${({ $selected = false }) => ($selected ? "white" : "#828d9e")};
  transition: background 0.35s ease, color 0.35s ease, border-color 0.35s ease;
  :hover {
    background: ${({ $selected = false }) =>
      $selected ? "#0273e9" : "#edecec"};
    border: 2px solid
      ${({ $selected = false }) => ($selected ? "#0273e9" : "#edecec")};
  }

  p {
    max-width: 100%;
    margin: 0;
    padding: 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Gotham-Bold";
    font-size: 14px;
  }
`;

export const ChannelListButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0273e9;
  border-radius: 6px;
  border: none;
  outline: none;
  height: 32px;
  width: 32px;
  padding: 0;
  cursor: pointer;

  transition: background 0.35s ease;
  :hover {
    background: #014c9b;
  }
  :focus {
    background: #1939b7;
  }
`;

export const ChatChannelTitle = styled.div`
  width: 10rem;
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid #c0c9d3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 0.5rem;
  transition: background-color 0.35s ease;
  &:hover {
    background-color: #e8ecf1;
    cursor: pointer;
  }
`;

export const MessagesCount = styled.div`
  color: #ffffff;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #003e8c;
  height: 24px;
  width: 24px;
  border-radius: 100%;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: rgba(237, 236, 236, 0.2);
  padding: 8px 12px;
  margin: 0 12px;
  border-radius: 6px;
  height: 100%;
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.5rem;
  gap: 0.2rem;
  transition: width 0.35s ease;
  max-width: 70%;
`;

export const MessageToReplyContainer = styled.div<{ $fromUser: boolean }>`
  flex: 1;
  background: ${({ $fromUser }) => ($fromUser ? "#dbe3ef" : "#eff1f4")};
  padding: 8px;
  border-radius: 10px;

  .message-content {
    max-height: 100px;
    overflow-y: auto;
  }
`;

export const ReactMarkdownStyled = styled(ReactMarkdown)<{ $color?: string }>`
  max-width: 100%;
  p {
    color: ${({ $color = "#48505e" }) => $color};
  }
  * {
    overflow-wrap: break-word;
  }
`;

export const ReplyIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.35s ease;
  &:hover {
    background-color: #e8ecf1;
    cursor: pointer;
  }
`;

export const ProfileImg = styled.div`
  align-self: start;
  width: 2rem;
  height: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
`;

export const UserRound = styled.div`
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  background: #014c9b;
  color: white;
  height: 2rem;
  width: 2rem;
  font-size: 16px;
  padding: 0.5rem;
  border-radius: 100%;
`;

export const MessageFromSystem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;
export const MessageFromOthers = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;
export const MessageFromUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const NewMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 6px;
  transition: background-color 0.35s ease;
`;

export const StyledTextArea = styled(TextArea)`
  border-radius: 6px;
  border: 2px solid #edecec;
  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9 !important;
    border-right: 2px solid #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e !important;
    border-right: 2px solid #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const SendMessageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0273e9;
  color: white;
  border-radius: 6px;
  border: none;
  outline: none;
  width: 100px;
  height: 100%;
  padding: 6px 16px;
  cursor: pointer;
  font-family: "Gotham-Bold";
  font-size: 14px;

  transition: background 0.35s ease;
  :hover {
    background: #014c9b;
  }
  :focus {
    background: #1939b7;
  }
`;
