import React from "react";
import { ArticleWrapper, SectionWrapper, StyledLink } from "../Styles";

interface OverviewLinkProps {
  isActive: () => boolean;
  setSection: () => void;
}

const SectionName = "Overview";

export const OverviewLink: React.FC<OverviewLinkProps> = ({
  isActive,
  setSection,
}) => {
  return (
    <StyledLink onClick={setSection} $isActive={isActive()}>
      {SectionName}
    </StyledLink>
  );
};

interface OverviewSectionProps {}

export const OverviewSection: React.FC<OverviewSectionProps> = () => {
  return (
    <SectionWrapper>
      <h2>{SectionName}</h2>
      <ArticleWrapper>
        <p>
          Este editor fue desarrollado con el objetivo de ser reutilizable,
          altamente configurable y lo más fácil de extender posibles, además de
          cubrir las necesidades específicas que tenemos en Geest, fundamentado
          principalmente en dos librerías:{" "}
          <a
            href="https://draftjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Draft.js
          </a>{" "}
          y{" "}
          <a
            href="https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp"
            target="_blank"
            rel="noopener noreferrer"
          >
            react-draft-wysiwyg
          </a>
          .
        </p>
        <p>
          La forma más fácil de entender el funcionamiento general de este
          editor es mirandolo más como una herramienta para generar y editar
          templates de contenido.
        </p>
        <p>
          Dada la necesidad de permitir una configuración altamente flexible
          para este editor, es necesario contar con esta pequeña documentación
          donde se intentan esclarecer las posibles dudas que surgiran al
          intentar implementar este editor en nuevas secciones del sistema o al
          actualizar/extender el editor como tal.
        </p>
        <p>
          A la izquierda, tienes la navegación de esta documentación. Si no es
          suficientemente clara, por favor, pide ayuda e informa para extender
          la misma. Del mismo modo, siempre tienes a disposición el código en la
          ruta <code className="code">src/components/GeestTextEditor</code> y un
          ejemplo de implementación en{" "}
          <code className="code">src/playground/pages/TextEditor</code>.
        </p>
      </ArticleWrapper>
    </SectionWrapper>
  );
};
