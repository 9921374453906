import React from "react";
import { LeftBarProps } from "./Intranet.d";
import {
  LeftBarContainer,
  StateGroup,
  StatesContainer,
  StateTitle,
  ItemContainer,
  State,
  Divider,
} from "./styles";

const LeftBar: React.FC<LeftBarProps> = ({ state, setState, permissions }) => {
  return (
    <LeftBarContainer>
      {permissions?.Permission_Lider && (
        <>
          <StateGroup
            $selected={state === "permissions"}
            onClick={() => setState("permissions")}
          >
            <div className="bar" />
            <ItemContainer>
              <StateTitle
                style={
                  state === "permissions" ? { color: "#0273E9" } : undefined
                }
              >
                Permisos
              </StateTitle>
            </ItemContainer>
          </StateGroup>
          <Divider />
        </>
      )}
      {(permissions?.Permission_FreeTrials || permissions?.Permission_Pwd) && (
        <StateGroup
          $selected={["freeTrials", "passwords"].includes(state)}
          onClick={() => {
            if (permissions?.Permission_FreeTrials) {
              setState("freeTrials");
            } else if (permissions?.Permission_Pwd) {
              setState("passwords");
            }
          }}
        >
          <div className="bar" />
          <ItemContainer>
            <StateTitle>Soporte</StateTitle>
            <StatesContainer>
              {permissions?.Permission_FreeTrials && (
                <State
                  $selected={state === "freeTrials"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setState("freeTrials");
                  }}
                >
                  Pruebas gratis
                </State>
              )}
              {permissions?.Permission_Pwd && (
                <State
                  $selected={state === "passwords"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setState("passwords");
                  }}
                >
                  Contraseñas
                </State>
              )}
            </StatesContainer>
          </ItemContainer>
        </StateGroup>
      )}
    </LeftBarContainer>
  );
};

export default LeftBar;
