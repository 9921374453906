import React, { useRef, useState } from "react";
import {
  MoveIconContainer,
  PopoverContainer,
  PopoverContent,
  PopoverRow,
  Subtitle,
} from "./Styles";
import {
  DeleteIconContainer,
  EditIconContainer,
  IconsContainer,
} from "../Styles";
import { DeleteIcon } from "../../../../../../components/hoverIcons";
import { AiOutlineEdit } from "react-icons/ai";
import { Popover } from "@material-ui/core";
import MoveIcon from "../../../../../../components/hoverIcons/MoveIcon";
import GeestSelect from "../../../../../../GeestUI/GeestSelect";
import { Button } from "../../../../../../GeestUI";
import { Column } from "../../../../Dashboards.d";

interface EditColumnPopoverProps {
  columns: Column[];
  onRemoveStat: () => void;
  onEditStat: () => void;
  currentStat: number;
  stats: number[];
  setStats: (newStats: number[]) => void;
}

const EditColumnPopover: React.FC<EditColumnPopoverProps> = ({
  columns,
  onRemoveStat,
  onEditStat,
  currentStat,
  stats,
  setStats,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [moveTo, setMoveTo] = useState<string>("");
  const [newPosition, setNewPosition] = useState<number | null>(null);

  const handleClose = () => {
    setVisible(false);
    setMoveTo("");
    setNewPosition(null);
  };

  const popoverRef = useRef(null);

  const getMoveToOptions = () => {
    let options: any = [];

    const statIndex = stats.findIndex((idStat) => idStat === currentStat);
    if (statIndex === -1) {
      return [];
    }

    if (statIndex !== 0) {
      options.push({
        value: "left",
        label: "Izquierda",
      });
      options.push({
        value: "start",
        label: "Al inicio",
      });
    }

    if (statIndex !== stats.length - 1) {
      options.push({
        value: "right",
        label: "Derecha",
      });
      options.push({
        value: "end",
        label: "Al final",
      });
    }

    options.push({
      value: "before",
      label: "Antes de",
    });
    options.push({
      value: "after",
      label: "Después de",
    });

    return options;
  };

  const getColsOptions = () => {
    let options: any = [];
    columns.forEach((column, i) => {
      if (i === 0) {
        return;
      }
      if (column.IdStat === currentStat) {
        return;
      }
      options.push({
        value: column.IdStat,
        label: column.name,
      });
    });
    return options;
  };

  const canSave = () => {
    const isSimpleMove =
      moveTo === "start" ||
      moveTo === "end" ||
      moveTo === "left" ||
      moveTo === "right";
    const isBeforeOrAfter = moveTo === "before" || moveTo === "after";
    const hasValidNewPosition =
      String(newPosition) !== "" && newPosition !== null;

    return isSimpleMove || (isBeforeOrAfter && hasValidNewPosition);
  };

  const handleOnMove = () => {
    handleClose();

    const statIndex = stats.findIndex((idStat) => idStat === currentStat);
    if (statIndex === -1) {
      setStats(stats);
      return;
    }

    let newStats = Array.from(stats);

    if (moveTo === "left" && statIndex > 0) {
      newStats.splice(statIndex, 1);
      newStats.splice(statIndex - 1, 0, currentStat);
    }

    if (moveTo === "right" && statIndex < newStats.length - 1) {
      newStats.splice(statIndex, 1);
      newStats.splice(statIndex + 1, 0, currentStat);
    }

    if (moveTo === "start") {
      newStats.splice(statIndex, 1);
      newStats.unshift(currentStat);
    }

    if (moveTo === "end") {
      newStats.splice(statIndex, 1);
      newStats.push(currentStat);
    }

    if (
      moveTo === "start" ||
      moveTo === "end" ||
      moveTo === "left" ||
      moveTo === "right"
    ) {
      setStats(newStats);
      return;
    }

    let targetIndex = stats.findIndex((idStat) => idStat === newPosition);
    if (targetIndex === -1) {
      setStats(stats);
      return;
    }

    if (targetIndex === statIndex) {
      setStats(stats);
      return;
    }

    if (moveTo === "before") {
      if (targetIndex > statIndex) {
        targetIndex -= 1;
      }

      newStats.splice(statIndex, 1);
      newStats.splice(targetIndex, 0, currentStat);
    }

    if (moveTo === "after") {
      if (targetIndex < statIndex) {
        targetIndex += 1;
      }

      newStats.splice(statIndex, 1);
      newStats.splice(targetIndex, 0, currentStat);
    }

    setStats(newStats);
  };

  return (
    <PopoverContainer>
      <Popover
        open={visible}
        onClick={(e) => e.stopPropagation()}
        anchorEl={popoverRef.current}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "6px",
            width: "282px",
            padding: "12px 16px",
            marginTop: "1rem",
            marginLeft: "1.5rem",
            fontFamily: "Gotham-Book",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <PopoverContent>
          <PopoverRow>
            <Subtitle>
              <p>Mover columna</p>
            </Subtitle>
            <GeestSelect
              value={moveTo}
              onChange={setMoveTo}
              options={getMoveToOptions()}
            />
          </PopoverRow>

          {(moveTo === "before" || moveTo === "after") && (
            <PopoverRow>
              <Subtitle>
                <p>{moveTo === "before" ? "Antes de" : "Despues de"}</p>
              </Subtitle>
              <GeestSelect
                value={newPosition}
                onChange={setNewPosition}
                options={getColsOptions()}
              />
            </PopoverRow>
          )}

          <PopoverRow center>
            <Button type="primary" onClick={handleOnMove} disabled={!canSave()}>
              Mover
            </Button>
          </PopoverRow>
        </PopoverContent>
      </Popover>

      <IconsContainer>
        <DeleteIconContainer className="deleteIconContainer">
          <DeleteIcon filled onClick={onRemoveStat} />
        </DeleteIconContainer>

        <EditIconContainer onClick={onEditStat}>
          <AiOutlineEdit size={16} color="white" />
        </EditIconContainer>

        <MoveIconContainer ref={popoverRef} onClick={() => setVisible(true)}>
          <MoveIcon size={20} noContainer />
        </MoveIconContainer>
      </IconsContainer>
    </PopoverContainer>
  );
};

export default EditColumnPopover;
