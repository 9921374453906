import React from "react";
import { IconContainer } from "./styles";
import { HiOutlineLocationMarker } from "react-icons/hi";

const SettingsIcon: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => (
  <IconContainer size={18} {...props}>
    <HiOutlineLocationMarker color="#48505E" size={16} />
  </IconContainer>
);

export default SettingsIcon;
