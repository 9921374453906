import React from "react";

const useElementOverflow = (
  refElement: React.RefObject<HTMLElement>,
  mode: "width" | "height" = "width",
  safeZone: number = 0
): boolean => {
  if (!refElement.current) return false;

  if (mode === "height")
    return (
      refElement.current.scrollHeight >
      refElement.current.clientHeight + safeZone
    );
  if (mode === "width")
    return (
      refElement.current.scrollWidth > refElement.current.clientWidth + safeZone
    );

  return false;
};

export default useElementOverflow;
