import React from "react";
import styled from "styled-components";
import { Languages } from "./Dictionary";

const {
  TimeSelet: { minutes, hours, days, weeks, months },
} = Languages["ESP"];

const ListElement = styled.p<{ $selected: boolean }>`
  margin: 0;
  padding: 6px 10px;
  background: ${({ $selected = false }) => ($selected ? "#edecec" : "white")};
  color: #828d9e;
  cursor: pointer;
  transition: background 0.35s ease;
  :hover {
    background: #edecec;
  }
`;

interface AddTimePopoverProps {
  timeToAddSelect: string;
  onSelectTimeToAdd: (
    time: "minutes" | "hours" | "days" | "weeks" | "months"
  ) => void;
}

const AddTimePopover: React.FC<AddTimePopoverProps> = ({
  timeToAddSelect,
  onSelectTimeToAdd,
}) => {
  return (
    <div>
      <ListElement
        $selected={timeToAddSelect === "minutes"}
        onClick={() => onSelectTimeToAdd("minutes")}
      >
        {minutes}
      </ListElement>
      <ListElement
        $selected={timeToAddSelect === "hours"}
        onClick={() => onSelectTimeToAdd("hours")}
      >
        {hours}
      </ListElement>
      <ListElement
        $selected={timeToAddSelect === "days"}
        onClick={() => onSelectTimeToAdd("days")}
      >
        {days}
      </ListElement>
      <ListElement
        $selected={timeToAddSelect === "weeks"}
        onClick={() => onSelectTimeToAdd("weeks")}
      >
        {weeks}
      </ListElement>
      <ListElement
        $selected={timeToAddSelect === "months"}
        onClick={() => onSelectTimeToAdd("months")}
      >
        {months}
      </ListElement>
    </div>
  );
};

export default AddTimePopover;
