import React, { useState } from "react";
import _, { noop as NOOP } from "lodash";
import { Popover, Space } from "antd";
import { Typography, Tag } from "../../../../GeestUI";
import StagePopover from "./Popovers/StagePopover";
import { Stage } from "../Dashboard.d";
import { StyledInputBox } from "./Styles";

const { H4 } = Typography;

interface StageFilterProps {
  extended: boolean;
  disabled: boolean;
  stages: Stage[];
  stageCollection: Stage[];
  stagePlaceholder: string;
  onChangeStage: (oldStage: Stage[], newStage: Stage[]) => void;
}

const StageFilter: React.FC<StageFilterProps> = ({
  extended,
  disabled,
  stages,
  stageCollection,
  stagePlaceholder,
  onChangeStage,
}) => {
  const [stagePopoverVisible, setStagePopoverVisible] =
    useState<boolean>(false);

  return (
    <Popover
      trigger="click"
      placement="bottom"
      overlayStyle={{
        border: "solid 1px #cfcdcd",
        borderRadius: ".9rem",
        padding: "0",
        overflow: "hidden",
        boxShadow: "0px 0px 30px #cfcdcd",
        width: "350px",
      }}
      destroyTooltipOnHide
      visible={stagePopoverVisible}
      onVisibleChange={extended && !disabled ? setStagePopoverVisible : NOOP}
      content={() => (
        <StagePopover
          value={stages}
          stageCollection={stageCollection}
          onChange={onChangeStage}
          onClose={() => setStagePopoverVisible(false)}
        />
      )}
    >
      <StyledInputBox
        tabIndex={0}
        onKeyDown={({ key }) => {
          if (key === "Enter" && !disabled) setStagePopoverVisible(true);
        }}
      >
        <Space size={5} wrap>
          {stages.map((stage) => (
            <Tag key={stage.IdStage} color={stage.Color}>
              {stage.Label}
            </Tag>
          ))}
        </Space>
        {_.isEmpty(stages) && (
          <H4
            ml="5px"
            mb="0"
            color="#c0c9d3"
            onClick={
              extended && !disabled ? () => setStagePopoverVisible(true) : NOOP
            }
          >
            {stagePlaceholder}
          </H4>
        )}
      </StyledInputBox>
    </Popover>
  );
};

export default StageFilter;
