import axios from "axios";
import { loadFromLocalStorage } from "../config/Helpers";
import baseURL from "./baseURL";
import queryString from "query-string";
import { Languages } from "./Dictionary";
import { formatQuery, formatRequest } from "../Helpers";
import { VersionCode as uiVersion } from "../components/GeestVersion/GeestVersion";

let TokenExpiredEvt = document.createEvent("Event");
TokenExpiredEvt.initEvent("TokenExpired", true, true);
const { ServerErrorMessage } = Languages["ESP"];

const transformRequest = (data) => {
  let type = typeof data;
  if (type === "object") {
    let params = new FormData();
    for (let key in data) {
      if (data[key]) {
        if (["args", "pags"].includes(key)) {
          params.append(
            key,
            formatRequest(JSON.stringify(formatQuery(data[key], true)))
          );
        } else params.append(key, data[key]);
      }
    }
    return params;
  }
  return data;
};

export const GeestInstance = axios.create({
  baseURL: `${baseURL}api/index.php`,
  transformRequest,
});

const ERROR = "Error|";
const UNAUTHORIZED = "Error|Unauthorized|";
const SUBSCRIPTIONS = "Error|Subscriptions|";
const OUTDATEDVERSION = "Error|OutdatedVersion|";

const handleUnauthorized = (response) => {
  document.dispatchEvent(TokenExpiredEvt);
  return { Request_Error: response.data.slice(UNAUTHORIZED.length) };
};

const handleSubscriptions = (response) => {
  const { Message = "", RedirectUrl = "" } = JSON.parse(
    response.data.slice(SUBSCRIPTIONS.length)
  );
  const [, params] = RedirectUrl.split("?");
  const { redirect } = queryString.parse("?" + params);
  return { Request_Error: Message, Redirect: redirect };
};

const handleOutdatedVersion = () => {
  window.location.reload();
  return { Request_Error: "Outdated UI version", Type: "info" };
};

GeestInstance.interceptors.response.use(
  (response) => {
    const responseString = JSON.stringify(response);

    if (responseString.includes(ERROR)) {
      // Token
      if (responseString.includes(UNAUTHORIZED))
        return handleUnauthorized(response);
      // Subscriptions
      if (responseString.includes(SUBSCRIPTIONS))
        return handleSubscriptions(response);
      // OutdatedVersion
      if (responseString.includes(OUTDATEDVERSION))
        return handleOutdatedVersion();
      return { Request_Error: response.data.slice(ERROR.length) };
    } else {
      return formatQuery(response.data);
    }
  },
  (error) => {
    //descomentar para ver mensaje de error de back en consola
    //console.log(JSON.parse(error.request.response).Message);
    return { Request_Error: ServerErrorMessage };
  }
);

const server = (func, idUR, args = {}, options = {}) => {
  const Sesion = loadFromLocalStorage();
  if (Sesion) {
    const { Auth } = Sesion;
    GeestInstance.defaults.headers.common["Authorization"] = Auth;
  }
  return GeestInstance.post("", { func, idUR, args, uiVersion, ...options });
};

export default server;
