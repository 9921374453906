import React, { useEffect, useState } from "react";
import { DebugCounter, ImageWrapper } from "./Styles";

interface ImageBlockProps {
  src: string;
  height: string;
  width: string;
}

const ImageBlock: React.FC<ImageBlockProps> = ({ src, height, width }) => {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    return () => {
      alert("image entity deleted, so, call backend to delete image from s3");
    };
  }, []);

  return (
    <ImageWrapper>
      <img src={src} alt="" height={height} width={width} />
      <DebugCounter>
        <button onClick={() => setCount(count - 1)}>-</button>
        <p>count: {count}</p>
        <button onClick={() => setCount(count + 1)}>+</button>
      </DebugCounter>
      <small>we have a working local state on entities</small>
    </ImageWrapper>
  );
};

export default ImageBlock;
