import React from "react";
import { TemplateCard, FolderCard } from "./TemplateCard";

interface TemplateType {
  Name: string;
  CanShare: boolean;
  IdFolder: number | null;
  IdProcessType: number | null;
  IdProcessTemplate: number | null;
  CanStartProcesses: boolean;
  TeamName?: string;
  IdTeam: number;
}

interface TemplateListItemProps {
  Team: {
    IdTeam: number;
    Name: string;
    CanConfigureProcesses: boolean;
    ProcessTemplates: TemplateType[];
  };
  handleOnStart: () => void;
  handleOnEdit: () => void;
  handleOnShare: () => void;
  handleOnMoveToFolder: () => void;
  selectedFolder: TemplateType;
  handleSelectFolder: (
    Template: TemplateType,
    Name: string,
    IdTeam: number
  ) => void;
  handleDeleteFolder: () => void;
  handleOnDuplicate: () => void;
  Template: TemplateType;
  nestedFolders: TemplateType[];
  maxItems: number;
  index: number;
  templatesToShare: number[];
  setTemplatesToShare: (templates: number[]) => void;
  handleEditFolder: (Folder: TemplateType, IdTeam: number) => void;
}

const TemplateListItem: React.FC<TemplateListItemProps> = ({
  Team,
  Template,
  handleOnStart,
  handleOnEdit,
  handleOnShare,
  handleOnMoveToFolder,
  selectedFolder,
  handleSelectFolder,
  handleDeleteFolder,
  handleOnDuplicate,
  nestedFolders,
  maxItems,
  index,
  templatesToShare,
  setTemplatesToShare,
  handleEditFolder,
}) => {
  const { Name, ProcessTemplates, IdTeam } = Team;
  return !!Template.IdProcessTemplate ? (
    <TemplateCard
      Template={Template}
      Team={Team}
      onStart={handleOnStart}
      onEdit={handleOnEdit}
      onShare={handleOnShare}
      onDuplicate={handleOnDuplicate}
      Folders={ProcessTemplates.filter(({ IdFolder }) => !!IdFolder)}
      onMoveToFolder={handleOnMoveToFolder}
      selectedFolder={selectedFolder}
      nestedFolders={nestedFolders}
      maxItems={maxItems}
      index={index}
      templatesToShare={templatesToShare}
      setTemplatesToShare={setTemplatesToShare}
    />
  ) : (
    <FolderCard
      Folder={Template}
      onSelect={() => handleSelectFolder(Template, Name, IdTeam)}
      Folders={ProcessTemplates.filter(({ IdFolder }) => !!IdFolder)}
      onMoveToFolder={handleOnMoveToFolder}
      selectedFolder={selectedFolder}
      Team={Team}
      onDeleteFolder={handleDeleteFolder}
      nestedFolders={nestedFolders}
      maxItems={maxItems}
      index={index}
      onEdit={() => handleEditFolder(Template, IdTeam)}
    />
  );
};

export default TemplateListItem;
