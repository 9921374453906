import React from "react";
import OrderPopup from "./OrderPopup";
import { Radio, Row } from "antd";
import { ProcessOrderType } from "../TableDetail.d";

interface ProcessOrderProps {
  processOrder: ProcessOrderType;
  setProcessOrder: (val: ProcessOrderType) => void;
}

const ProcessOrder: React.FC<ProcessOrderProps> = ({
  processOrder,
  setProcessOrder,
}) => {
  return (
    <OrderPopup>
      <Radio.Group
        value={processOrder}
        onChange={(e) => setProcessOrder(e.target.value)}
        style={{ width: "100%" }}
      >
        <Row
          style={{
            width: "100%",
            fontSize: "14px",
            lineHeight: "2.5rem",
          }}
          justify="space-between"
          align="middle"
        >
          Folio
          <Radio value="IdProcessExecution" />
        </Row>
        <Row
          style={{
            width: "100%",
            fontSize: "14px",
            lineHeight: "2.5rem",
          }}
          justify="space-between"
          align="middle"
        >
          Título de seguimiento
          <Radio value="Name" />
        </Row>
        <Row
          style={{
            width: "100%",
            fontSize: "14px",
            lineHeight: "2.5rem",
          }}
          justify="space-between"
          align="middle"
        >
          Estatus
          <Radio value="Status" />
        </Row>
      </Radio.Group>
    </OrderPopup>
  );
};

export default ProcessOrder;
