import React, { useState } from "react";
import _ from "lodash";
import { FooterContainer } from "./Styles";
import { UploadFile } from "antd";
import { Languages } from "../../Dictionary";
import printJS from "print-js";
import { Button } from "../../../../../GeestUI";

const { DownloadLabel, VizualizeLabel, PrintLabel } = Languages["ESP"];

interface FooterProps {
  file: UploadFile<any>;
}

const Footer: React.FC<FooterProps> = ({ file }) => {
  const [downloading, setDownloading] = useState(false);
  const [printing, setPrinting] = useState(false);

  const fileExtension = _.toLower((file?.url ?? "").split(".").pop());

  const regularExtensions = ["jpeg", "jpg", "png"];
  const printableExtensions = ["jpeg", "jpg", "png", "pdf"];

  const useIframe = file?.url && !regularExtensions.includes(fileExtension);
  const printable = file?.url && printableExtensions.includes(fileExtension);

  const openImageInNewTab = async (file: UploadFile<any>) => {
    const { url = "" } = file;

    const img = new Image();
    img.src = url;

    img.onload = () => {
      let widthStyle = "100%";
      let heightStyle = "auto";

      if (img.width === img.height) {
        widthStyle = "auto";
        heightStyle = "100%";
      } else if (img.width > img.height) {
        widthStyle = "100%";
        heightStyle = "auto";
      } else {
        widthStyle = "auto";
        heightStyle = "100%";
      }

      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(
          `<div style="width:100%;height:100%;display:flex;align-items:center;justify-content:center;"><img src="${url}" style="width:${widthStyle};height:${heightStyle};" /></div>`
        );
        newWindow.document.title = "Image Preview";
        newWindow.document.close();
      } else {
        alert(
          "Los pop-up están bloqueados. Por favor, activa los pop-ups para previsualizar la imagen."
        );
      }
    };

    img.onerror = () => {
      alert("No se pudo cargar la imagen. Por favor, verifica la URL.");
    };
  };

  const handlePrint = (file: UploadFile<any>): void => {
    const { url = "" } = file;
    if (useIframe) {
      // PDF
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = window.URL.createObjectURL(new Blob([blob]));
          printJS(blobUrl);
          setPrinting(false);
        });
    } else {
      // Images
      printJS(url, "image");
      setPrinting(false);
    }
  };

  const handleDownload = (file: UploadFile<any>) => {
    const { url = "", name = "" } = file;

    const fileExtension = _.toLower(url.split(".").pop());
    const regularExtensions = ["jpeg", "jpg", "png"];
    const useIframe = url && !regularExtensions.includes(fileExtension);

    const downloadBlob = (blob: Blob, filename: string) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const aTag = document.createElement("a");
      aTag.href = blobUrl;
      aTag.setAttribute("download", filename);
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
      window.URL.revokeObjectURL(blobUrl); // Clean up the URL object
    };

    if (useIframe) {
      // PDF or other file types not in the regularExtensions list
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          downloadBlob(blob, name);
        })
        .catch((error) => {
          console.error("Error downloading the file", error);
        });
    } else {
      // Images or other regular extensions
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          downloadBlob(blob, name);
          setDownloading(false);
        })
        .catch((error) => {
          console.error("Error downloading the file", error);
          setDownloading(false);
        });
    }
  };

  return (
    <FooterContainer className="swiper-no-swiping">
      <Button
        type="secondary"
        onClick={(e) => {
          e.stopPropagation();
          setDownloading(true);
          handleDownload(file || ({} as UploadFile<any>));
        }}
        loading={downloading}
        disabled={!file?.url || downloading || printing}
      >
        {DownloadLabel}
      </Button>

      {useIframe ? (
        <Button
          type="primary"
          href={`https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(
            file?.url ?? ""
          )}`}
          //@ts-ignore
          target="_blank"
          download
          htmlType="submit"
        >
          {VizualizeLabel}
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            openImageInNewTab(file || ({} as UploadFile<any>));
          }}
          disabled={!file?.url || downloading || printing}
        >
          {VizualizeLabel}
        </Button>
      )}

      {printable && (
        <Button
          type="primary"
          onClick={() => {
            setPrinting(true);
            handlePrint(file || ({} as UploadFile<any>));
          }}
          loading={printing}
          disabled={!file?.url || downloading || printing}
        >
          {PrintLabel}
        </Button>
      )}
    </FooterContainer>
  );
};

export default Footer;
