import styled from "styled-components";

export const ProcessesInformationContainer = styled.div`
  flex: 1;
  width: 100%;
  max-wdith: 100%;
  overflow: hidden auto;
  padding: 0.5rem 1rem;
`;

export const CardTitle = styled.div`
  font-family: "Gotham-Bold";
  color: #727e8b;
`;

export const ProcessesContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  color: #727e8b;
`;

export const ProcessBox = styled.div`
  display: flex;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: white;
`;

export const ProcessLeftContent = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const ProcessName = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const NameWrapper = styled.div`
  max-width: 320px;
  display: flex;

  p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ProcessMetadata = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StatusLabel = styled.span`
  font-family: "Gotham-Bold";
  font-size: 12px;
`;

export const FolioLabel = styled.span`
  font-size: 10px;
`;

export const ProcessRightContent = styled.div`
  flex: 2;
  min-width: 150px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.2rem;
  font-size: 16px;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #dfe4e9;
  }
`;

export const GraphContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;
