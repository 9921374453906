import { FormControl, withStyles } from "@material-ui/core";
import styled from "styled-components";

export const SelectStyle = withStyles({
  root: {
    minWidth: "8rem",
    marginLeft: "0.5rem",
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiSelect-select": {
      fontSize: "22px",
      paddingBottom: "0",
      fontFamily: "Gotham-Book",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid black",
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(FormControl);

export const Content = styled.div`
  overflow: auto;
  flex: 1;
`;
