import React, { useState } from "react";
import "jspdf-autotable";
import PDFConfig from "../../../components/PDFConfig";
import { DocumentData } from "../../../components/PDFConfig/PDFConfig.d";
import { ViewContainer } from "./Styles";
import { nanoid } from "nanoid";

interface PDFEditorProps {}

const PDFEditor: React.FC<PDFEditorProps> = () => {
  const [documentData, setDocumentData] = useState<DocumentData>({
    pages: [{ id: nanoid(), elements: [] }],
    elementsOrigin: [],
    width: 794,
    height: 1123,
  });
  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <ViewContainer>
      <PDFConfig
        documentData={documentData}
        setDocumentData={setDocumentData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        fieldEditorOrigin={[]}
        fieldImageOrigin={[]}
        filesPath=""
        importingPDF={false}
        teamLogo=""
      />
    </ViewContainer>
  );
};

export default PDFEditor;
