import React from "react";
import _ from "lodash";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { EmptyData } from "../components";
import { ILostReason } from "../sales.d";

const LossReasons: React.FC<{ data?: ILostReason; loading: boolean }> = ({
  data,
  loading,
}) => {
  if (loading) return <div>Cargando...</div>;
  if (!data || _.isEmpty(data.GraphData)) return <EmptyData />;

  return (
    <ResponsiveContainer maxHeight={350}>
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data.GraphData}>
        <PolarGrid />
        <PolarAngleAxis dataKey="Label" />
        <PolarRadiusAxis angle={30} />
        <Tooltip />
        <Radar
          name="Pérdidas"
          dataKey="Value"
          stroke="#928ed5"
          fill="#928ed5"
          fillOpacity={0.3}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default LossReasons;
