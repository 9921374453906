import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import { Checkbox } from "antd";

interface ModalProps {
  $showExtra?: boolean;
  $minHeight?: string;
  $height?: string;
  $maxHeight?: string;
  $width?: string;
  $maxWidth?: string;
  $secondaryHeight?: string;
  $secondaryWidth?: string;
  $padding?: string;
}

// general

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ModalContent = styled(Paper)<ModalProps>`
  display: flex;
  flex-direction: column;
  width: ${({ $width = "560px" }) => $width};
  max-width: ${({ $maxWidth = "60%" }) => $maxWidth};
  min-height: ${({ $minHeight = "100px" }) => $minHeight};
  height: ${({ $height = "379px" }) => $height};
  max-height: ${({ $maxHeight = "95vh" }) => $maxHeight};
  border-radius: 6px !important;
  padding: ${({ $padding = "0" }) => $padding};
  animation: all 1s ease;
  font-family: Gotham-Book;
  position: relative;
`;

export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  .info-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  p {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    color: #48505e;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CloseIcon = styled(IoClose)`
  color: #525151;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  max-height: 100%;
  margin: 12px;
  overflow: auto;
`;

export const LoadingContainer = styled.div`
  background: white;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  max-height: 450px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

// drag drop file

export const FormFileUpload = styled.form`
  height: 450px;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;

  .drag-active {
    background-color: #48505eb2;
  }
`;

export const InputFileUpload = styled.input`
  display: none;
`;

export const LabelFileUpload = styled.label`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: white;
  position: relative;

  transition: background-color 0.35s ease;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  p {
    margin: 0;
    color: #828d9e;
    text-align: center;
  }
`;

export const DropItContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  p {
    margin: 0;
    margin-bottom: 120px;
    text-align: center;
    color: white;
    font-size: 24px;
    font-family: Gotham-Bold;
  }
`;

export const DragDileElement = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

// column select

export const ColumnsSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;

  p {
    margin: 0;
    color: #48505e;
    text-align: center;
    font-family: Gotham-Bold;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;

  p {
    margin: 0;
    color: #828d9e;
    text-align: center;

    span {
      font-family: Gotham-Bold;
      color: #48505e;
    }
  }
`;

export const FieldsTitles = styled.div`
  display: flex;
  padding-bottom: 12px;
  padding: 0 60px;

  p {
    width: 50%;
    margin: 0;
    color: #828d9e;
  }
`;

export const FieldsWrapper = styled.div`
  flex: 1;
  padding: 0 60px;
  max-height: 180px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FieldRow = styled.div`
  display: flex;
  gap: 14px;
`;

export const ExcelColumnField = styled.div`
  width: calc(50% - 14px);
  max-width: calc(50% - 14px);
  border-radius: 6px;
  background-color: #edecec;

  width: 100%;
  min-height: 30px;
  resize: none;
  padding: 6px 10px;

  p {
    margin: 0;
    color: #828d9e;
    user-select: none;
  }
`;

export const DBColumnField = styled.div`
  width: 50%;
  max-width: 50%;
`;

export const FieldLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DataTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;

  img {
    height: 14px;
    max-height: 14px;
    width: 14px;
    max-width: 14px;
  }
`;

export const FieldTitleWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FirstRowCheckWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 60px;

  p {
    margin: 0;
    color: #828d9e;
    text-align: center;
    cursor: pointer;
    user-select: nne;
  }
`;

export const StyledCheckbox = styled(Checkbox)``;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
`;

export const FinishStepWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;

  text-align: center;
  height: 450px;
  width: 100%;
  max-width: 100%;
`;
