import React from "react";
import styled from "styled-components";
import { Languages } from "../Dictionary";

const { RequiredField } = Languages["ESP"];

const Container = styled.div`
  p {
    margin: 0;
    font-weight: 325;
    font-size: 14px;
    color: #db232c !important;
  }
`;

interface IsRequiredProps {
  test_id?: string;
}

const IsRequired: React.FC<IsRequiredProps> = ({ test_id }) => {
  return (
    <Container data-testid={test_id}>
      <p>{RequiredField}</p>
    </Container>
  );
};

export default IsRequired;
