export interface FlowProps {
  IdProcessExecution?: number;
  IdTaskValue?: number;
  onClose?: () => void;
  onRestartFlux?: () => void;
  onChangePending?: (idPending: number) => void;
  closable?: boolean;
  shouldReload?: boolean;
  setShouldReload?: (value: boolean) => void;
}

export type IPortNodeDestiny =
  | "BidirectionalLeft"
  | "BidirectionalRight"
  | "BidirectionalTop"
  | "BidirectionalBottom"
  | "InLeft"
  | "InTop"
  | "InBottom"
  | HandlerId;

export type IPortNodeOrigin =
  | "BidirectionalLeft"
  | "BidirectionalRight"
  | "BidirectionalTop"
  | "BidirectionalBottom"
  | "port1"
  | HandlerId
  | string;

export interface ApiLink {
  IdLink: number;
  IdNodeDestiny: number;
  IdNodeOrigin: number;
  PortNodeDestiny: IPortNodeDestiny;
  PortNodeOrigin: IPortNodeOrigin;
}

export enum NodeStatus {
  NEW = "New",
  ON_TIME = "OnTime",
  COMPLETED = "Completed",
  CANCELED = "Canceled",
  WAITING_TO_START = "WaitingToStart",
  STUCK = "Stuck",
}

export type ApiNodeTypes =
  | "Task"
  | "Condition"
  | "Automation"
  | "Subflux"
  | "EndFlux";

interface ApiConditionPort {
  Port: string;
  Status: NodeStatus;
  PortLabel: string;
}

export interface ApiUser {
  Email: string;
  FirstName: string;
  IdUser: number;
  Initials: string;
  LastName: string;
  ProfilePicture: string;
}
export interface NodeInfo {
  Deadline: string | null;
  EndedAt: string | null;
  StartedAt: string | null;
  IdTaskValue: number | null;
  IsRevision: boolean | null;
  UsersRelations: ApiUser[] | null;
}

export interface ApiNode {
  ConditionPorts: ApiConditionPort[];
  CoordinatesCsv: string;
  IdNode: number;
  IdStage: number;
  IsThisNodeTaskOpeningFlux: boolean;
  NodeTitle: string;
  NodeType: ApiNodeTypes;
  Status: NodeStatus;
  NodeInfo: NodeInfo | [];
}

export interface ApiStage {
  Color: string;
  Id: number;
  IsDefaultStage: boolean;
  Label: string;
}

export interface GetExecutionFluxResponse {
  IdProcessExecution: number;
  Links: ApiLink[];
  Nodes: ApiNode[];
  Stages: ApiStage[];
}
