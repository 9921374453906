import React from "react";
import {
  Container,
  SelectRow,
  SelectTitleContainer,
  Title,
  GroupsContainer,
  ConditionalGroupContainer,
  ConditionsContainer,
  DeleteGroupIconContainer,
  SwitchContainer,
  SwitchOption,
  DynamicInputContainer,
  SelectedOptionLabelContainer,
} from "./styles";
import {
  ProcessMeasurementsProps,
  Conditions,
  Fields,
} from "./EditMeasurements.d";
import GeestSelect from "../../../../../../GeestUI/GeestSelect";
import DataTypeIcons from "../../../../../../components/DataTypeIcons";
import { Row } from "antd";
import Tooltip from "../../../../../../components/Tooltip";
import { Button } from "../../../../../../GeestUI";
import { AiOutlinePlus } from "react-icons/ai";
import {
  CancelIcon,
  DeleteIcon,
} from "../../../../../../components/hoverIcons";
import DynamicInput from "../../../../../../components/DynamicInput";
//@ts-ignore
import emoji from "emoji-dictionary";
import _ from "lodash";

const ProcessMeasurements: React.FC<ProcessMeasurementsProps> = ({
  StatsOptions,
  IdProcessTemplate,
  selectedTemplateId,
  setSelectedTemplateId,
  idSumField,
  setIdSumField,
  measurementType,
  setMeasurementType,
  conditional,
  setConditional,
  conditionalGroups,
  setConditionalGroups,
}) => {
  const processOptions = StatsOptions?.Processes?.Values.map(
    ({ Label, IdProcessTemplate }) => ({
      label: Label,
      value: IdProcessTemplate,
    })
  );
  const typeOptions = [
    {
      label: "Suma",
      value: "Sum",
    },
    {
      label: "Cuenta",
      value: "Count",
    },
  ];

  const selectedProcess = StatsOptions?.Processes?.Values.find(
    ({ IdProcessTemplate }) => IdProcessTemplate === selectedTemplateId
  );

  const sumFieldOptions =
    selectedProcess?.Fields.filter(({ DataType }) =>
      ["number", "currency"].includes(DataType)
    ).map(({ Label, IdField, DataType }) => {
      return {
        label: (
          <Row align="middle">
            <Row
              align="middle"
              justify="center"
              style={{
                width: "26px",
                height: "26px",
                display: "flex",
              }}
              wrap={false}
            >
              <img
                style={{
                  width: "14px",
                  height: "14px",
                  ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
                }}
                alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
                src={DataTypeIcons[DataType?.toLowerCase()].icon}
              />
            </Row>
            {Label.length >= 28 ? (
              <Tooltip title={Label} placement="right">
                <div
                  style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {_.truncate(Label, { length: 28 })}
                </div>
              </Tooltip>
            ) : (
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {Label}
              </div>
            )}
          </Row>
        ),
        asSelected: (
          <Row align="middle">
            <Row
              align="middle"
              justify="center"
              style={{
                width: "26px",
                height: "26px",
                display: "flex",
              }}
              wrap={false}
            >
              <img
                style={{
                  width: "14px",
                  height: "14px",
                  ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
                }}
                alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
                src={DataTypeIcons[DataType?.toLowerCase()].icon}
              />
            </Row>
            {Label.length >= 28 ? (
              <Tooltip title={Label} placement="right">
                <SelectedOptionLabelContainer>
                  {_.truncate(Label, { length: 28 })}
                </SelectedOptionLabelContainer>
              </Tooltip>
            ) : (
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {Label}
              </div>
            )}
          </Row>
        ),
        value: IdField,
        searchableValues: Label,
      };
    }) || [];

  const fieldOptions =
    selectedProcess?.Fields.map(({ Label, IdField, DataType }) => ({
      label: (
        <Row align="middle">
          <Row
            align="middle"
            justify="center"
            style={{
              width: "26px",
              height: "26px",
              display: "flex",
            }}
            wrap={false}
          >
            <img
              style={{
                width: "14px",
                height: "14px",
                ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
              }}
              alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
              src={DataTypeIcons[DataType?.toLowerCase()].icon}
            />
          </Row>
          {Label.length >= 50 ? (
            <Tooltip title={Label} placement="right">
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {Label}
              </div>
            </Tooltip>
          ) : (
            <div
              style={{
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {Label}
            </div>
          )}
        </Row>
      ),
      value: IdField,
      searchableValues: Label,
    })) || [];

  const transformConditons = (conditions: Conditions[]) =>
    conditions.map(({ Label, Value }) => ({
      label: Label,
      value: Value,
    }));

  const onAddConditionalGroup = () => {
    const defaultConditionalGroup = {
      Conditional: "And",
      Conditions: [
        {
          Type: "",
          ConditionValue: "",
        },
      ],
    };
    let auxConditionalGroups = [...conditionalGroups];
    auxConditionalGroups.push(defaultConditionalGroup);
    setConditionalGroups(auxConditionalGroups);
  };

  const onAddCondition = (index: number) => {
    const defaultCondition = {
      Type: "",
      ConditionValue: "",
    };
    let auxConditionalGroup = [...conditionalGroups];
    auxConditionalGroup[index].Conditions.push(defaultCondition);
    setConditionalGroups(auxConditionalGroup);
  };

  const onChangeConditional = () => {
    if (conditional === "And") {
      setConditional("Or");
    } else {
      setConditional("And");
    }
  };

  const onRemoveConditionalGroup = (index: number) => {
    let auxConditionalGroup = [...conditionalGroups];
    auxConditionalGroup.splice(index, 1);
    setConditionalGroups(auxConditionalGroup);
  };

  const onChangeGroupConditional = (index: number) => {
    let auxConditionalGroup = [...conditionalGroups];
    let conditional = auxConditionalGroup[index].Conditional;
    if (conditional === "And") {
      conditional = "Or";
    } else {
      conditional = "And";
    }
    auxConditionalGroup[index].Conditional = conditional;
    setConditionalGroups(auxConditionalGroup);
  };

  const conditionTypeOptions = [
    {
      label: "El seguimiento",
      value: "Process",
    },
    {
      label: "La etapa",
      value: "Stage",
    },
    {
      label: "La tarea",
      value: "Task",
    },
    {
      label: "El campo",
      value: "Field",
    },
  ];

  const onChangeConditionType = (
    type: string,
    index: number,
    condIndex: number
  ) => {
    const condition = {
      IdField: undefined,
      IdProcessTemplate: type === "Process" ? selectedTemplateId : undefined,
      IdStage: undefined,
      IdTaskTemplate: undefined,
      ConditionValue: "",
      Type: type,
    };
    let auxConditionalGroup = [...conditionalGroups];
    auxConditionalGroup[index].Conditions[condIndex] = condition;
    setConditionalGroups(auxConditionalGroup);
  };

  const taskOptions = selectedProcess?.Tasks.Values.map(
    ({ Label, IdTaskTemplate }) => ({
      label: Label.replace(/:\w+:/gi, (name) => emoji.getUnicode(name) ?? name),
      value: IdTaskTemplate,
    })
  );

  const stageOptions = selectedProcess?.Stages.Values.map(
    ({ Color, Label, IdStage }) => ({
      label: (
        <Row align="middle">
          <Row
            align="middle"
            justify="center"
            style={{
              width: "26px",
              height: "26px",
              display: "flex",
            }}
            wrap={false}
          >
            <div
              style={{
                width: "14px",
                height: "14px",
                borderRadius: "50%",
                backgroundColor: Color,
              }}
            />
          </Row>
          {Label.length >= 50 ? (
            <Tooltip
              title={Label.replace(
                /:\w+:/gi,
                (name) => emoji.getUnicode(name) ?? name
              )}
              placement="right"
            >
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {Label.replace(
                  /:\w+:/gi,
                  (name) => emoji.getUnicode(name) ?? name
                )}
              </div>
            </Tooltip>
          ) : (
            <div
              style={{
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {Label.replace(
                /:\w+:/gi,
                (name) => emoji.getUnicode(name) ?? name
              )}
            </div>
          )}
        </Row>
      ),
      value: IdStage,
      searchableValues: Label,
    })
  );

  const getSecondConditionSelectOptions = (type: string) => {
    switch (type) {
      case "Process":
        return transformConditons(StatsOptions?.Processes.Conditions || []);
      case "Task":
        return taskOptions;
      case "Field":
        return fieldOptions;
      case "Stage":
        return stageOptions;
      default:
        return [];
    }
  };

  const onChangeSecondConditonSelect = (
    value: any,
    type: string,
    index: number,
    condIndex: number
  ) => {
    let auxConditionalGroups = [...conditionalGroups];
    let condition = auxConditionalGroups[index].Conditions[condIndex];
    switch (type) {
      case "Process":
        condition.ConditionValue = value;
        break;
      case "Task":
        condition.IdTaskTemplate = value;
        break;
      case "Stage":
        condition.IdStage = value;
        break;
      case "Field":
        condition.IdField = value;
        condition.ConditionValue = "";
        condition.Value = undefined;
        break;
    }
    auxConditionalGroups[index].Conditions[condIndex] = condition;
    setConditionalGroups(auxConditionalGroups);
  };

  const getSecondConditionSelectPlaceHolder = (type: string) => {
    switch (type) {
      case "Process":
        return "Selecciona condición...";
      case "Field":
        return "Selecciona el campo...";
      case "Task":
        return "Selecciona la tarea...";
      case "Stage":
        return "Selecciona la etapa...";
    }
  };

  const onChangeCondition = (
    value: string,
    index: number,
    condIndex: number
  ) => {
    let conditionalGroupAux = [...conditionalGroups];
    conditionalGroupAux[index].Conditions[condIndex].ConditionValue = value;
    setConditionalGroups(conditionalGroupAux);
  };

  const onChangeValue = (
    value: any,
    index: number,
    condIndex: number,
    dataType: string
  ) => {
    let conditionalGroupAux = [...conditionalGroups];
    switch (dataType) {
      case "select":
        value = value.Value;
        break;
      case "multi_select":
        value = (value as unknown as any[]).map(({ Value }) => Value).join(",");
        break;
    }
    conditionalGroupAux[index].Conditions[condIndex].Value = value;
    setConditionalGroups(conditionalGroupAux);
  };

  const onDeleteCondition = (index: number, condIndex: number) => {
    let conditionalGroupAux = [...conditionalGroups];
    let conditionsAux = conditionalGroupAux[index].Conditions;
    conditionsAux.splice(condIndex, 1);
    conditionalGroupAux[index].Conditions = conditionsAux;
    setConditionalGroups(conditionalGroupAux);
  };

  const getValue = (
    index: number,
    condIndex: number,
    value: string,
    selectedField: Fields | null
  ) => {
    if (!value) return value;
    if (selectedField) {
      const { DataType, DataOrigin } = selectedField;
      switch (DataType) {
        case "select":
          if (
            !DataOrigin?.find(
              ({ value: Value }) => String(Value) === String(value)
            )
          ) {
            let auxConditionalGroup = [...conditionalGroups];
            auxConditionalGroup[index].Conditions[condIndex].Value = undefined;
            return "";
          }
          return { Type: "Key", Label: null, Value: Number(value) };
        case "multi_select":
          const valueArray = value.split(",");
          const newValueArray = valueArray.filter((val) =>
            DataOrigin.map(({ value }) => String(value)).includes(String(val))
          );
          if (newValueArray.length !== valueArray.length) {
            let auxConditionalGroup = [...conditionalGroups];
            auxConditionalGroup[index].Conditions[condIndex].Value =
              newValueArray.join(",");
          }
          return newValueArray.map((val) => ({
            Type: "Key",
            Label: null,
            Value: val,
          }));
        default:
          return value;
      }
    }
  };

  const renderAddConditionButton =
    !!measurementType &&
    (measurementType !== "Sum" || !!idSumField) &&
    conditionalGroups.length === 0;

  return (
    <Container style={{ padding: 0 }}>
      <SelectRow>
        <SelectTitleContainer>
          <Title>Nombre del proceso</Title>
          <GeestSelect
            value={selectedTemplateId}
            onChange={(val) => {
              setSelectedTemplateId(val);
              setConditionalGroups([]);
              setIdSumField(undefined);
            }}
            disabled={!!IdProcessTemplate}
            options={processOptions || []}
            valueNecesary
            $width="270px"
          />
        </SelectTitleContainer>
        <SelectTitleContainer>
          <Title>¿Qué quieres medir?</Title>
          <GeestSelect
            value={measurementType}
            onChange={(type) => {
              setMeasurementType(type);
              if (type !== "Sum") {
                setIdSumField(undefined);
              }
            }}
            options={typeOptions}
            valueNecesary
            $width="232px"
          />
        </SelectTitleContainer>
        {measurementType === "Sum" && (
          <SelectTitleContainer>
            <Title>Selecciona el campo de formulario</Title>
            <GeestSelect
              value={idSumField}
              onChange={setIdSumField}
              options={sumFieldOptions}
              valueNecesary
              $width="300px"
              style={{ maxHeight: "32px" }}
              itemsContainerContentWidth="100%"
            />
          </SelectTitleContainer>
        )}
        {renderAddConditionButton && (
          <Button
            Icon={AiOutlinePlus}
            onClick={onAddConditionalGroup}
            style={{ width: "fit-content", alignSelf: "flex-end" }}
          >
            Agregar condición
          </Button>
        )}
      </SelectRow>
      {conditionalGroups.length > 0 && (
        <>
          <Title $fontSize="18px" style={{ margin: "0.5rem 0" }}>
            Establece las condiciones
          </Title>
          <GroupsContainer>
            {conditionalGroups.map(({ Conditional, Conditions }, index) => (
              <>
                {index > 0 && (
                  <SwitchContainer onClick={onChangeConditional}>
                    <SwitchOption $side="And" $selected={conditional === "And"}>
                      y
                    </SwitchOption>
                    <SwitchOption $side="Or" $selected={conditional === "Or"}>
                      o
                    </SwitchOption>
                  </SwitchContainer>
                )}
                <ConditionalGroupContainer>
                  <DeleteGroupIconContainer>
                    <CancelIcon
                      size={26}
                      onClick={() => onRemoveConditionalGroup(index)}
                    />
                  </DeleteGroupIconContainer>
                  <ConditionsContainer>
                    {Conditions.map((condition, condIndex) => {
                      const {
                        Type,
                        IdField,
                        IdStage,
                        IdTaskTemplate,
                        ConditionValue,
                        Value,
                      } = condition;

                      const secondSelectValue = (() => {
                        switch (Type) {
                          case "Process":
                            return ConditionValue;
                          case "Field":
                            return IdField;
                          case "Task":
                            return IdTaskTemplate;
                          case "Stage":
                            return IdStage;
                        }
                      })();

                      const selectedField = selectedProcess?.Fields.find(
                        ({ IdField: Id }) => Id === IdField
                      );

                      const thirdSelectOptions = (() => {
                        switch (Type) {
                          case "Field":
                            return selectedField?.Conditions;
                          case "Task":
                            return selectedProcess?.Tasks.Conditions;
                          case "Stage":
                            return selectedProcess?.Stages.Conditions;
                          default:
                            return [];
                        }
                      })();

                      const renderDynamicInput =
                        Type === "Field" &&
                        !!selectedField &&
                        !!ConditionValue &&
                        !["Field_Have_Any_Value", "Field_Is_Empty"].includes(
                          ConditionValue
                        );

                      return (
                        <>
                          {condIndex > 0 && (
                            <SwitchContainer
                              onClick={() => onChangeGroupConditional(index)}
                              style={{ alignSelf: "flex-start" }}
                            >
                              <SwitchOption
                                $side="And"
                                $selected={Conditional === "And"}
                              >
                                y
                              </SwitchOption>
                              <SwitchOption
                                $side="Or"
                                $selected={Conditional === "Or"}
                              >
                                o
                              </SwitchOption>
                            </SwitchContainer>
                          )}
                          {condIndex === 0 && <Title>Donde</Title>}
                          <Row
                            style={{ width: "100%", gap: "10px" }}
                            align="middle"
                            justify="space-between"
                          >
                            <SelectRow>
                              <GeestSelect
                                value={Type}
                                options={conditionTypeOptions}
                                onChange={(val: any) =>
                                  onChangeConditionType(val, index, condIndex)
                                }
                                $width={"236px"}
                                valueNecesary
                                placeholderSelect="Selecciona..."
                              />
                              {!!Type && (
                                <GeestSelect
                                  value={secondSelectValue}
                                  options={
                                    getSecondConditionSelectOptions(Type) || []
                                  }
                                  onChange={(val: any) =>
                                    onChangeSecondConditonSelect(
                                      val,
                                      Type,
                                      index,
                                      condIndex
                                    )
                                  }
                                  valueNecesary
                                  $width="330px"
                                  placeholderSelect={getSecondConditionSelectPlaceHolder(
                                    Type
                                  )}
                                  style={{ maxHeight: "32px" }}
                                />
                              )}
                              {(!Type || Type !== "Process") &&
                                !!secondSelectValue && (
                                  <GeestSelect
                                    value={ConditionValue}
                                    options={transformConditons(
                                      thirdSelectOptions || []
                                    )}
                                    placeholderSelect="Selecciona condición..."
                                    onChange={(val) =>
                                      onChangeCondition(val, index, condIndex)
                                    }
                                    $width={
                                      Type === "Field" ? "200px" : "268px"
                                    }
                                    valueNecesary
                                  />
                                )}
                              {renderDynamicInput && (
                                <DynamicInputContainer
                                  $limitHeight={
                                    !["users_select"].includes(
                                      selectedField?.DataType
                                    )
                                  }
                                >
                                  <DynamicInput
                                    value={getValue(
                                      index,
                                      condIndex,
                                      Value,
                                      selectedField
                                    )}
                                    type={selectedField?.DataType}
                                    onChange={(val: any) =>
                                      onChangeValue(
                                        val,
                                        index,
                                        condIndex,
                                        selectedField?.DataType
                                      )
                                    }
                                    isConsult={false}
                                    format=""
                                    dataOrigin={selectedField?.DataOrigin}
                                    configuration=""
                                    fieldName=""
                                    disabled={false}
                                    required={false}
                                    userSelectDataOrigin={
                                      selectedField?.DataOrigin || []
                                    }
                                    extraParams={{
                                      hideCurrencySelect: true,
                                      hidePhoneFormat: true,
                                      mb: "0px",
                                    }}
                                  />
                                </DynamicInputContainer>
                              )}
                            </SelectRow>
                            <DeleteIcon
                              filled
                              style={{ justifySelf: "flex-end" }}
                              size={26}
                              onClick={() =>
                                onDeleteCondition(index, condIndex)
                              }
                            />
                          </Row>
                        </>
                      );
                    })}
                    <Button
                      Icon={AiOutlinePlus}
                      style={{ width: "fit-content" }}
                      onClick={() => onAddCondition(index)}
                    >
                      Agregar condición
                    </Button>
                  </ConditionsContainer>
                </ConditionalGroupContainer>
              </>
            ))}
          </GroupsContainer>
          <Button
            type="secondary"
            size="large"
            Icon={AiOutlinePlus}
            style={{
              width: "300px",
              alignSelf: "flex-end",
            }}
            onClick={onAddConditionalGroup}
          >
            Agregar grupo de condición
          </Button>
        </>
      )}
    </Container>
  );
};

export default ProcessMeasurements;
