import React from "react";
import Modal from "../../components/Modal";
import { Row } from "antd";
import styled from "styled-components";
import GeestLogoSVG from "../../resources/img/GeestLogo.svg";
import IntroVideo from "../../resources/img/introVideo.png";
import { Languages } from "./Dictionary";

const Title = styled(Row)`
  font-family: "Gotham-Book";
  color: #48505e;
  font-size: 24px;
  display: block;
  text-align: center;
`;

const Text = styled.div`
  font-family: "Gotham-Book";
  color: #828d9e;
  font-size: 18px;
  display: block;
  text-align: center;
  margin: 0 190px;
`;

const VideoImg = styled.img`
  width: 70%;
  margin-top: 1rem;
  margin-bottom: 4rem;
  cursor: pointer;
`;

const IntroModal = (props) => {
  const { WelcomeLabel, WatchVideoLabel } = Languages["ESP"];
  const openIntroVid = () => {
    window.open(
      "https://www.youtube.com/playlist?list=PLTwfrEBfpGHYSn7ho9xq7dvo4MGRuklD7"
    );
  };
  return (
    <Modal
      {...props}
      width="80%"
      style={{ borderRadius: "6px" }}
      bodyStyle={{ maxHeight: "100%" }}
    >
      <Title align="middle" justify="center">
        {WelcomeLabel}
        <img
          alt="logo"
          src={GeestLogoSVG}
          style={{
            height: "50px",
            width: "105px",
            marginLeft: "12px",
            marginTop: "5px",
          }}
        />
      </Title>
      <Text>{WatchVideoLabel}</Text>
      <Row justify="center">
        <VideoImg src={IntroVideo} onClick={openIntroVid} />
      </Row>
    </Modal>
  );
};

export default IntroModal;
