export const Languages = {
  ENG: {
    ChooseYourPlan: "Choose your plan",
    Monthly: "Monthly",
    Yearly: "Yearly",
  },
  ESP: {
    ChooseYourPlan: "Elige tu plan",
    Monthly: "Mensual",
    Yearly: "Anual",
  },
};
