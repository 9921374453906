import React, { useState } from "react";
import Tooltip from "../../../../../components/Tooltip";
import {
  NoDataCell,
  NoDataTable,
  StatsTableContainer,
  StyledTable,
  StyledTableHead,
  StyledTableRow,
  StyledTableHeadCell,
  TableHeaderLabel,
  TableHeaderName,
  StyledTableContainer,
  StyledTableBody,
  StyledTableBodyCell,
  StyledTableBodyCellContent,
} from "./Styles";
import TableDetail from "./Modals/TableDetail";
import { MetaData, StatsTableProps } from "./StatsTable.d";
import User, { UserType } from "../../../../../components/User";
import ProcessDetail from "../../../../Reports/SharedComponents/ProcessDetail";
import DeleteColumnModal from "./Modals/DeleteColumnModal";
import EditColumnPopover from "./EditColumnPopover";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const StatsTable: React.FC<StatsTableProps> = ({
  data,
  execution,
  stats = [],
  setStats = () => {},
  onChangePending = () => {},
  onRemoveStat = () => {},
  onEditStat = () => {},
  reload = () => {},
}) => {
  const [cellDetail, SetCellDetail] = useState<number | null>(null);
  const [metaData, setMetaData] = useState<MetaData | null>(null);
  const [detailProcessId, setDetailProceessId] = useState<number | null>(null);
  const [statToRemove, setStatToRemove] = useState<number | null>(null);
  const history = useHistory();

  const handleOnRemoveStat = (idStat: number) => {
    onRemoveStat(idStat);
    setStatToRemove(null);
  };

  const handleSetDetail = (idStat: number, metaData?: MetaData) => {
    SetCellDetail(idStat);
    setMetaData(metaData ?? null);
  };

  const handleOpenDetailProcess = (id: number | null) => {
    history.push(`/home/reports/detail/${id}`);
    SetCellDetail(null);
  };

  const isColumnPrimary = (idx: number) => {
    return !Array.isArray(data) && Boolean(data.columns[idx].primaryKey);
  };

  const getFormatedNumber = (val: number) =>
    String(val) ? String(val).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";

  const getMinMaxWidth = (isColumnPrimary: boolean) => {
    if (execution && isColumnPrimary) {
      return { minWidth: "50px", maxWidth: "230px" };
    }
    if (execution && !isColumnPrimary) {
      return { minWidth: "50px", maxWidth: "180px" };
    }
    if (!execution && isColumnPrimary) {
      return { minWidth: "50px", maxWidth: "310px" };
    }
    if (!execution && !isColumnPrimary) {
      return { minWidth: "50px", maxWidth: "260px" };
    }

    return { minWidth: "50px", maxWidth: "260px" };
  };

  return (
    <StatsTableContainer>
      {statToRemove !== null && (
        <DeleteColumnModal
          closeModal={() => setStatToRemove(null)}
          onRemoveStat={() => handleOnRemoveStat(statToRemove)}
        />
      )}
      {cellDetail !== null && metaData !== null && (
        <TableDetail
          closeModal={() => {
            SetCellDetail(null);
            reload();
          }}
          idStat={cellDetail}
          metaData={metaData}
          setDetailProceessId={handleOpenDetailProcess}
        />
      )}
      {detailProcessId && (
        <ProcessDetail
          open
          processId={detailProcessId}
          onChangePending={onChangePending}
          onClose={() => setDetailProceessId(null)}
          reload={() => {}}
          softReload={() => {}}
        />
      )}
      {Array.isArray(data) ? (
        <NoDataTable>Sin datos</NoDataTable>
      ) : (
        <StyledTableContainer>
          <StyledTable stickyHeader={false} size="small">
            <StyledTableHead>
              <StyledTableRow>
                {data.columns.map((column) => {
                  const { minWidth, maxWidth } = getMinMaxWidth(
                    Boolean(column.primaryKey)
                  );

                  const maxLength = execution ? 10 : 20;

                  return (
                    <StyledTableHeadCell
                      key={column.IdStat}
                      minWidth={minWidth}
                      maxWidth={maxWidth}
                    >
                      <TableHeaderLabel>
                        {column.name.length > maxLength ? (
                          <Tooltip title={column.name} placement="top">
                            <TableHeaderName>
                              {_.truncate(column.name, { length: maxLength })}
                            </TableHeaderName>
                          </Tooltip>
                        ) : (
                          <TableHeaderName>{column.name}</TableHeaderName>
                        )}

                        {!column.primaryKey && !execution && (
                          <EditColumnPopover
                            columns={data.columns}
                            onRemoveStat={() => setStatToRemove(column.IdStat)}
                            onEditStat={() => onEditStat(column.IdStat)}
                            currentStat={column.IdStat}
                            stats={stats}
                            setStats={setStats}
                          />
                        )}
                      </TableHeaderLabel>
                    </StyledTableHeadCell>
                  );
                })}
              </StyledTableRow>
            </StyledTableHead>

            <StyledTableBody>
              {data.rows.length === 0 && (
                <StyledTableRow>
                  <NoDataCell $execution={execution}>Sin datos</NoDataCell>
                </StyledTableRow>
              )}

              {data.rows.map((row) => {
                return (
                  <StyledTableRow>
                    {row.cells.map((cell, i) => {
                      const isPrimary = isColumnPrimary(i);
                      const { minWidth, maxWidth } = getMinMaxWidth(isPrimary);

                      return (
                        <StyledTableBodyCell
                          key={cell.IdStat}
                          bg={i % 2 === 0 ? "#fff" : "#f6f5f5"}
                          $detailed={cell.IdStat !== ""}
                          minWidth={minWidth}
                          maxWidth={maxWidth}
                          onClick={() => {
                            if (execution && cell.IdStat !== "") {
                              handleSetDetail(
                                cell.IdStat as number,
                                row.metaData
                              );
                            }
                          }}
                        >
                          <StyledTableBodyCellContent>
                            {typeof cell.Value === "object" ? (
                              <User
                                user={cell.Value as UserType}
                                size="middle"
                                tooltip
                              />
                            ) : (
                              <p>
                                {cell.Format !== "" ? cell.Format + " " : ""}
                                {getFormatedNumber(cell.Value)}
                              </p>
                            )}
                          </StyledTableBodyCellContent>
                        </StyledTableBodyCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
          </StyledTable>
        </StyledTableContainer>
      )}
    </StatsTableContainer>
  );
};

export default StatsTable;
