import { dateFormat } from "../../../../Helpers";
import moment from "moment";

export const Languages = {
  ENG: {
    Subscription: "Subscription",
    StillNotPrem: "Still not premium?",
    SubText: "Choose the best plan for you and enjoy greater benefits.",
    ButtonText: "Choose a plan",
    Payment: "Payment",
    EditBillingData: "Edit billing data",
    PaymentHistoty: "Payment history",
    CancelRenewal: "Cancel automatic renewal",
    ReactivateRenewal: "Reactivate automatic renewal",
    SocialReasonLabel: "Bussiness name",
    RfcLabel: "SSN",
    AddressLabel: "Address",
    ZipCodeLabel: "Zip Code",
    CityLabel: "City",
    CountryLabel: "Country",
    Save: "Save",
    Cancel: "Cancel",
    nextBill: (amount, date) =>
      "Your next be will be of " +
      amount +
      " and it will arrive on " +
      dateFormat(date),
    AreYouSure: "Are you sure you wish to cancel automatic renewal?",
    SubEndingAt: (date) => "Your subscription will end on " + dateFormat(date),
    Accept: "Confirm",
    Unlimited: "Unlimited",
    ActiveRenewal:
      "Are you sure you want to reactivate the automatic renewal of your plan?",
    Users: "Users",
    UsersQuantity: "Users quantity",
    InUse: "In use",
    Hired: "Hired",
    ModifyNUsers: "Modify the number of users",
    MessageAlertTxt: (date, users, price) =>
      `From ${dateFormat(
        new Date(date)
      )} you can use only ${users} users and your bill will be by $${price} USD`,
    LabelDeleteUser: "Are you sure to delete",
    UsersQ: "users?",
    LabelDelete: "Delete",
    MessageAddUserTxt: (date, users, price, BilledMonthly, addUsers) =>
      `You will be charged a difference for the new ${
        addUsers === 1 ? "user" : "users"
      } for the rest of the period and from
      ${dateFormat(new Date(date))} your bill ${
        BilledMonthly ? "monthly" : "yearly"
      } will be by $${price} USD per ${users} users`,
    UsersMessageText: (date) => `${dateFormat(new Date(date))} you will have `,
    CancelledText:
      "You can not change the amount of the users because you canceled the automatic renewal.",
  },
  ESP: {
    Subscription: "Suscripción",
    StillNotPrem: "¿Aún no eres premium?",
    SubText: "Elige el mejor plan para ti y disfruta de mayores beneficios.",
    ButtonText: "Elegir un plan",
    Payment: "Pago",
    EditBillingData: "Editar datos de facturación",
    PaymentHistoty: "Historial de pagos",
    CancelRenewal: "Cancelar renovación automática",
    ReactivateRenewal: "Reactivar renovación automática",
    SocialReasonLabel: "Razón social",
    RfcLabel: "RFC",
    AddressLabel: "Dirección",
    ZipCodeLabel: "Código Postal",
    CityLabel: "Ciudad",
    CountryLabel: "País",
    Save: "Guardar",
    Cancel: "Cancelar",
    nextBill: (amount, date) =>
      "Tu próxima factura será de $" +
      parseInt(amount, 10).toFixed(2) +
      " USD y llegará el " +
      moment(date).format("DD/MMM/YY"),
    AreYouSure: "¿Seguro que desea cancelar la renovación automática?",
    SubEndingAt: (date) =>
      "Su suscripción finalizará el " + dateFormat(new Date(date)),
    Accept: "Aceptar",
    Unlimited: "Ilimitado",
    ActiveRenewal:
      "¿Seguro que deseas volver a activar la renovación automática de tu plan?",
    Users: "Usuarios",
    UsersQuantity: "Cantidad de usuarios",
    InUse: "En uso",
    Hired: "Contratados",
    ModifyNUsers: "Modificar número de usuarios",
    MessageAlertTxt: (date, users, price) =>
      `A partir del ${dateFormat(
        new Date(date)
      )} podrás utilizar ${users} usuarios únicamente y tu factura llegará por $${price} MXN`,
    LabelDeleteUser: "¿Seguro que deseas eliminar",
    UsersQ: "usuarios?",
    LabelDelete: "Eliminar",
    MessageAddUserTxt: (date, users, price, BilledMonthly, addUsers) =>
      `Se te cobrará una diferencia por ${
        addUsers === 1 ? "el nuevo usuario" : "los nuevos usuarios"
      } el resto del periodo y a partir del ${dateFormat(
        new Date(date)
      )} tu factura ${
        BilledMonthly ? "mensual" : "anual"
      } será por $${price} MXN por ${users} usuarios`,
    UsersMessageText: (date) => `${dateFormat(new Date(date))} tendrás `,
    CancelledText:
      "No puedes modificar la cantidad de usuarios porque cancelaste la renovación automática.",
  },
};
