import { Paper } from "@material-ui/core";
import styled from "styled-components";
import { Input } from "antd";

export const StyledPaper = styled(Paper)`
  border-radius: 6px !important;
  width: 357px;
  display: flex;
  flex-direction: column;
`;

export const PopoverTitle = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 12px;

  p {
    margin: 0;
    font-family: "Gotham-Bold";
    font-size: 16px;
    color: #48505e;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const PopoverReminderLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px 24px;

  p {
    margin: 0;
    font-size: 14px;
    color: #828d9e;
  }
`;

export const ReminderInputContainer = styled.div`
  display: flex;
  align-items: center;
  jusytify-content: center;
  padding: 0 36px 12px;
`;

export const TextAreaStyled = styled(Input.TextArea)`
  border: 2px solid #edecec;
  border-radius: 6px;
  background-color: white;
  color: #828d9e;
  width: 100%;
  height: 32px;
  resize: none;
  padding: 5px;
  transition: all 0.35s ease;
  ::placeholder {
    color: #828d9e;
  }
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e !important;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const PopoverBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 0 36px 12px;
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  gap: 16px;
  color: #828d9e;

  .subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
  }

  .select-element {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const TimeToAddInput = styled.input`
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  border: 2px solid #edecec;
  border-radius: 6px;
  background-color: white;
  color: #828d9e;
  width: 40px;
  height: 32px;
  text-align: center;
  resize: none;
  padding: 5px;
  transition: all 0.35s ease;
  ::placeholder {
    color: #828d9e;
  }
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e !important;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const TimeToAddSelect = styled.div`
  border: 2px solid #edecec;
  border-radius: 6px;
  background-color: white;
  color: #828d9e;
  flex: 1;
  padding: 3px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e !important;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const WeekDaySelect = styled(TimeToAddSelect)``;

export const DropDownIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconsContainer = styled.div`
  background: white;
  position: absolute;
  top: 4px;
  right: 6px;

  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
  padding-left: 2px;
`;

export const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ disabled }) => (disabled ? "#edecec" : "#0273e9")};
  color: ${({ disabled }) => (disabled ? "#828d9e" : "white")};
  font-family: "Gotham-Bold";
  font-size: 14px;

  border-radius: 6px;
  border: none;
  outline: none;
  width: 100px;
  height: 100%;
  padding: 6px 16px;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};

  transition: all 0.35s ease;
  :hover {
    background: ${({ disabled }) => (disabled ? "#edecec" : "#014c9b")};
  }
  :focus {
    background: ${({ disabled }) => (disabled ? "#edecec" : "#1939b7")};
  }
`;
