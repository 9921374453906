export const defaultExtraParams = `export interface ToolbarExtraParams {
  inlineOptions?: inlineOptions[];
  blockTypeOptions?: blockOptions[];
  fontSizeOptions?: fontSizeOptions[];
  fontFamilyOptions?: fontFamilyOptions[];
  listOptions?: listOptions[];
  textAlignOptions?: alignOptions[];
  colorPickerOptions?: string[];
  linkOptions?: {
    defaultTarget?: "_self" | "_blank";
    options?: ("link" | "unlink")[];
  };
  fieldOptions?: {
    largeButton?: boolean;
  };
}

const defaultToolbarExtraParams: ToolbarExtraParams = {
  inlineOptions: ["bold", "italic", "underline"],
  linkOptions: { defaultTarget: "_blank", options: ["link"] },
  blockTypeOptions: ["Normal", "H1", "H2", "H3", "Blockquote", "Code"],
  fontSizeOptions: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30],
  listOptions: ["unordered", "ordered", "indent", "outdent"],
};
`;

export const inlineExtraParams = `<GeestTextEditor
  editorState={editorState}
  onEditorStateChange={setEditorState}
  toolbarExtraParams={{ inlineOptions: ["italic", "bold"] }}
  toolbarConfig={{
    fontSize: false,
    fontFamily: false,
    list: false,
    textalign: false,
    colorPicker: false,
    link: false,
  }}
  editorBorder
/>
`;

export const blockExtraParams = `<GeestTextEditor
  editorState={editorState}
  onEditorStateChange={setEditorState}
  toolbarExtraParams={{
    blockTypeOptions: ["Normal", "H1", "H2", "H3", "Code"],
  }}
  toolbarConfig={{
    blockType: true,
    inline: false,
    fontSize: false,
    fontFamily: false,
    list: false,
    textalign: false,
    colorPicker: false,
    link: false,
  }}
  editorBorder
/>
`;

export const fontSizeExtraParams = `<GeestTextEditor
  editorState={editorState}
  onEditorStateChange={setEditorState}
  toolbarExtraParams={{
    fontSizeOptions: [8, 12, 14, 16, 24],
  }}
  toolbarConfig={{
    inline: false,
    fontFamily: false,
    list: false,
    textalign: false,
    colorPicker: false,
    link: false,
  }}
  editorBorder
/>
`;

export const fontFamilyExtraParams = `<GeestTextEditor
  editorState={editorState}
  onEditorStateChange={setEditorState}
  toolbarExtraParams={{
    fontFamilyOptions: ["Gotham-Book", "Arial", "Times New Roman"],
  }}
  toolbarConfig={{
    inline: false,
    fontSize: false,
    fontFamily: true,
    list: false,
    textalign: false,
    colorPicker: false,
    link: false,
  }}
  editorBorder
/>
`;

export const listExtraParams = `<GeestTextEditor
  editorState={editorState}
  onEditorStateChange={setEditorState}
  toolbarExtraParams={{
    listOptions: ["unordered", "ordered"],
  }}
  toolbarConfig={{
    inline: false,
    fontSize: false,
    fontFamily: false,
    list: true,
    textalign: false,
    colorPicker: false,
    link: false,
  }}
  editorBorder
/>
`;

export const alignExtraParms = `<GeestTextEditor
  editorState={editorState}
  onEditorStateChange={setEditorState}
  toolbarExtraParams={{
    textAlignOptions: ["left", "center"],
  }}
  toolbarConfig={{
    inline: false,
    fontSize: false,
    fontFamily: false,
    list: false,
    textalign: true,
    colorPicker: false,
    link: false,
  }}
  editorBorder
/>
`;

export const colorExtraParms = `<GeestTextEditor
  editorState={editorState}
  onEditorStateChange={setEditorState}
  toolbarExtraParams={{
    colorPickerOptions: [
      "black",
      "white",
      "#00acc1",
      "hsla(323, 100%, 50%, 1)",
    ],
  }}
  toolbarConfig={{
    inline: false,
    fontSize: false,
    fontFamily: false,
    list: false,
    textalign: false,
    colorPicker: true,
    link: false,
  }}
  editorBorder
/>
`;

export const linkExtraParms = `<GeestTextEditor
  editorState={editorState}
  onEditorStateChange={setEditorState}
  toolbarExtraParams={{
    linkOptions: {
      defaultTarget: "_blank",
      options: ["link"],
    },
  }}
  toolbarConfig={{
    inline: false,
    fontSize: false,
    fontFamily: false,
    list: false,
    textalign: false,
    colorPicker: false,
    link: true,
  }}
  editorBorder
/>
`;

export const fieldExtraParms = `<GeestTextEditor
  editorState={editorState}
  onEditorStateChange={setEditorState}
  toolbarExtraParams={{
    fieldOptions: { largeButton: true },
  }}
  toolbarConfig={{
    inline: false,
    fontSize: false,
    fontFamily: false,
    list: false,
    textalign: false,
    colorPicker: false,
    link: false,
    field: true,
  }}
  editorBorder
/>
`;
