import styled, { css } from "styled-components";

export const ContextBackground = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

export const ContextMenuArrow = styled.div<{
  top: number;
  left: number;
  zIndex: number;
}>`
  z-index: ${({ zIndex }) => zIndex};
  position: absolute;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}

  background: #48505e;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  transform: rotate(45deg);
`;

export const ContextMenuContainer = styled.div<{
  top: number;
  left: number;
  width?: string;
  zIndex: number;
}>`
  z-index: ${({ zIndex }) => zIndex};
  position: absolute;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}

  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: hidden;

  border-radius: 6px;
  background: #48505e;
  width: ${({ width = "200px" }) => width};
  padding: 4px 0;
  cursor: auto;
`;

export const ContextualAction = styled.div<{
  hoverBackground: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 16px;
  background: #48505e;
  width: 100%;
  height: 34px;
  max-height: 34px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  user-select: none;

  * {
    margin: 0;
    color: ${({ disabled }) => (disabled ? "#828d9e" : "white")};
    font-size: 14px;
    font-family: Gotham-Bold;
  }

  transition: background 0.35s ease;
  :hover {
    background: ${({ hoverBackground = "#0273e9", disabled }) =>
      disabled ? "#48505e" : hoverBackground};
  }
  :active {
    background-color: ${({ disabled }) => (disabled ? "#48505e" : "#1939b7")};
  }
`;
