import React from "react";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import { FilterValue, Option, OriginElement } from "../DynamicFilter.d";

interface SelectFilterProps {
  DataOrigin: OriginElement[];
  onChangeFilter: (value: FilterValue[]) => void;
  value: FilterValue;
  defaultOpions?: Option[];
  placeholder: string;
}

const MultiSelectFilter: React.FC<SelectFilterProps> = ({
  DataOrigin,
  onChangeFilter,
  value,
  defaultOpions,
  placeholder,
}) => {
  const getOptions = () => {
    let options = defaultOpions ? defaultOpions : DataOrigin;

    if (options.length > 0 && options[0].hasOwnProperty("Label")) {
      options = options.map((option) => {
        return {
          // @ts-ignore
          label: option.Label,
          // @ts-ignore
          asSelected: option.asSelected ?? null,
          // @ts-ignore
          value: option.Value,
        };
      });
    }

    return options;
  };

  return (
    <GeestSelect
      value={value}
      options={getOptions()}
      onChange={onChangeFilter}
      $width="285px"
      multiselect
      showTags={typeof getOptions()[0]?.label === "string"}
      placeholderSelect={placeholder}
    />
  );
};

export default MultiSelectFilter;
