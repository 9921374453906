import React from "react";
import { IconContainer } from "./styles";
import { AiFillMinusCircle } from "react-icons/ai";

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 20 | 26;
}

const iconSizes: { [key: number]: number } = {
  20: 18,
  26: 21,
};

const RemoveIcon: React.FC<IconProps> = ({ onClick, size = 20, ...props }) => (
  <IconContainer size={size} onClick={onClick} {...props}>
    <AiFillMinusCircle color="#db232c" size={iconSizes[size]} />
  </IconContainer>
);

export default RemoveIcon;
