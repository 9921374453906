import React from "react";
import { Space } from "antd";
import { Chat } from "./EditChatsDropdown.d";
import { ItemRow, ChatLabel } from "./styles";
import { EditIcon } from "../../../../components/hoverIcons";

interface ChatItemProps {
  chat: Chat;
  onEdit: (chat: Chat) => void;
}

const ChatItem: React.FC<ChatItemProps> = ({ chat, onEdit }) => {
  const handleOnEdit = () => onEdit(chat);
  return (
    <ItemRow justify="space-between" align="middle">
      <ChatLabel>{chat.Label}</ChatLabel>
      <Space align="center" size={4}>
        <EditIcon filled onClick={handleOnEdit} />
      </Space>
    </ItemRow>
  );
};

export default ChatItem;
