import styled from "styled-components";

export const PermissionsForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow: auto;
  padding: 0 12px 12px;
`;

export const PermisssionElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  p {
    margin: 0;
    color: #828d9e;
  }
`;

export const PermissionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .user {
    width: 50%;
  }

  .select {
    width: 50%;
  }

  p {
    margin: 0;
    color: #828d9e;
  }
`;
