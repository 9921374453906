import React from "react";
import FieldBlock from "../Components/BlockRoot/Blocks/FieldBlock";

const FIELD_REGEX = /\[\[\|\s(FIELD:(null|.*?))\s\|\]\]/g;

function findWithRegex(contentBlock: any, callback: Function) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = FIELD_REGEX.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

function handleStrategy(
  contentBlock: any,
  callback: Function,
  contentState: any
) {
  findWithRegex(contentBlock, callback);
}

const getFieldDecorator = (
  fieldEditorOrigin: any[] = [],
  setFieldPopoverOpen: (open: boolean) => void,
  readOnly: boolean,
  fieldsPopoverStyles: React.CSSProperties
) => {
  const HandleField = (props: any) => {
    return (
      <FieldBlock
        {...props}
        fieldEditorOrigin={fieldEditorOrigin}
        setFieldPopoverOpen={setFieldPopoverOpen}
        readOnly={readOnly}
        fieldsPopoverStyles={fieldsPopoverStyles}
      />
    );
  };

  return {
    strategy: handleStrategy,
    component: HandleField,
  };
};

export default getFieldDecorator;
