import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import _, { noop as NOOP } from "lodash";
import { UploadFile } from "antd/es/upload/interface";
import Modal from "../../../../Modal";
import { FileContainer, FileWrapper } from "./Styles";
import SwiperCore, { Keyboard, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import PreviewComponent from "./PreviewComponent";
import Footer from "./Footer";

SwiperCore.use([Keyboard, Pagination, Navigation]);

// Definimos la interfaz para el tipo de referencia esperado por Swiper
interface SwiperRefType {
  swiper?: any; // Puedes ajustar el tipo según la documentación de Swiper
}

const SwiperWithRef = forwardRef(
  ({ children, ...rest }: any, ref: ForwardedRef<SwiperRefType>) => (
    <Swiper {...rest} ref={ref}>
      {children}
    </Swiper>
  )
);

interface FileCarouselProps {
  fileToOpen: UploadFile<any>;
  files: UploadFile<any>[];
  closeModal: (e: any) => void;
  handleEsc?: () => void;
}

const FileCarousel: React.FC<FileCarouselProps> = ({
  fileToOpen,
  files,
  closeModal,
  handleEsc = NOOP,
}) => {
  const [initialSlide, setInitialSlide] = useState<number>(-1);
  const [fileName, setFileName] = useState<string>("");
  const navigationEnabled = files.length > 1;

  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const idx = _.findIndex(files, { uid: fileToOpen.uid });
    setInitialSlide(idx);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSlide = (swiper: any) => {
    const file = files[swiper.realIndex];
    setFileName(file.name);
  };

  return (
    <Modal title={fileName} visible onCancel={closeModal} width={"90vw"}>
      <FileContainer>
        {initialSlide > -1 && (
          <SwiperWithRef
            ref={swiperRef}
            initialSlide={initialSlide}
            slidesPerView={1}
            spaceBetween={30}
            centeredSlides
            navigation={navigationEnabled}
            className="mySwiper"
            keyboard={{
              enabled: navigationEnabled,
            }}
            pagination={{
              clickable: navigationEnabled,
            }}
            onSlideChange={handleChangeSlide}
          >
            {files.map((file) => {
              return (
                <SwiperSlide key={file.uid}>
                  {({ isActive }) => (
                    <FileWrapper className="swiper-no-swiping">
                      <PreviewComponent
                        file={file}
                        PreviewURL={file?.url || ""}
                        handleEsc={handleEsc}
                        isActive={isActive}
                      />

                      <Footer file={file} />
                    </FileWrapper>
                  )}
                </SwiperSlide>
              );
            })}
          </SwiperWithRef>
        )}
      </FileContainer>
    </Modal>
  );
};

export default FileCarousel;
