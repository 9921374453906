import React from "react";
import { Card, Col, Row, Space } from "antd";
import { Typography } from "../../GeestUI";

const { H1, H2, H3, H4, P, Text, L, B } = Typography;

const typographies: { name: string; Component: React.FC; size: string }[] = [
  { name: "H1", Component: H1, size: "22px" },
  { name: "H2", Component: H2, size: "18px" },
  { name: "H3", Component: H3, size: "16px" },
  { name: "H4", Component: H4, size: "14px" },
  { name: "P", Component: P, size: "14px" },
  { name: "Text", Component: Text, size: "14px" },
  { name: "L", Component: L, size: "inherit" },
  { name: "B", Component: B, size: "inherit" },
];

const TypographyPage: React.FC = () => {
  return (
    <>
      <H1>
        <B>Typography</B>
      </H1>
      <Card size="small" title="Types">
        <Row>
          <Col xs={6}>
            <Space direction="vertical" size={0}>
              {typographies.map(({ name, Component, size }, key) => (
                <Component key={key}>
                  {name} - {size}
                </Component>
              ))}
            </Space>
          </Col>
          <Col xs={6}>
            <Space direction="vertical" size={0}>
              {typographies.map(({ name, Component }, key) => (
                // @ts-ignore
                <Component color="var(--royal-blue)" key={key}>
                  {name} - Royal Blue
                </Component>
              ))}
            </Space>
          </Col>
          <Col xs={6}>
            <Space direction="vertical" size={0}>
              {typographies.map(({ name, Component }, key) => (
                // @ts-ignore
                <Component color="var(--lemon-green)" key={key}>
                  {name} - Lemon Green
                </Component>
              ))}
            </Space>
          </Col>
          <Col xs={6}>
            <Space direction="vertical" size={0}>
              {typographies.map(({ name, Component }, key) => (
                // @ts-ignore
                <Component color="var(--red)" key={key}>
                  {name} - Red
                </Component>
              ))}
            </Space>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default TypographyPage;
