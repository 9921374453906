import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { DatePicker } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { Languages } from "./Dictionary";
import {
  FiltersContent,
  OpenFiltersButton,
  FiltersHeaders,
  FilterContainer,
  FiltersInputs,
  StyledInputBox,
  FilterContainerAlt,
  FiltersResetButton,
  FiltersCloseIcon,
} from "./Styles";
import { Proccess, TeamMember, Status, Stage, Filter } from "../Dashboard.d";
import StatusFilter from "./StatusFilter";
import InvolvedFilter from "./InvolvedFilter";
import ProcessFilter from "./ProcessFilter";
import StageFilter from "./StageFilter";
import _ from "lodash";

const { RangePicker } = DatePicker;

const {
  FiltersButton,
  StartDateLabel,
  EndDateLabel,
  StatusLabel,
  InvolvedLabel,
  ProcessLabel,
  StageLabel,
  StatusPlaceholder,
  InvolvedPlaceholder,
  ProcessPlaceholder,
  StagePlaceholder,
} = Languages["ESP"];

interface FiltersProps {
  processCollection: Proccess[];
  teamMembers: TeamMember[];
  statusCollection: Status[];
  filters: Filter;
  setFilters: (filters: Filter) => void;
  showInvolvedFilter: boolean;
  updateDashboardReport: (currentFilter: Filter) => void;
}

const Filters: React.FC<FiltersProps> = ({
  processCollection = [],
  teamMembers = [],
  statusCollection = [],
  filters,
  setFilters,
  showInvolvedFilter,
  updateDashboardReport,
}) => {
  const [extended, setExtended] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<[Moment, Moment] | null>(null);
  const [endDate, setEndDate] = useState<[Moment, Moment] | null>(null);
  const [status, setStatus] = useState<Status[]>([]);
  const [involved, setInvolved] = useState<TeamMember[]>([]);
  const [proccess, setProccess] = useState<Proccess[]>([]);
  const [stages, setStages] = useState<Stage[]>([]);

  useEffect(() => {
    if (filters.StartDate?.Start && filters.StartDate.End) {
      setStartDate([
        moment(filters.StartDate.Start, "YY-MM-DD HH:mm:ss"),
        moment(filters.StartDate.End, "YY-MM-DD HH:mm:ss"),
      ]);
    } else {
      setStartDate(null);
    }

    if (filters.EndDate?.Start && filters.EndDate.End) {
      setEndDate([
        moment(filters.EndDate.Start, "YY-MM-DD HH:mm:ss"),
        moment(filters.EndDate.End, "YY-MM-DD HH:mm:ss"),
      ]);
    } else {
      setEndDate(null);
    }
    if (filters.Statuses) {
      let initialStatuses: Status[] = [];
      filters.Statuses.split(",").forEach((value) => {
        const statusIndex = _.findIndex(statusCollection, { Value: +value });
        if (statusIndex !== -1) {
          initialStatuses.push(statusCollection[statusIndex]);
        }
      });
      setStatus(initialStatuses);
    } else {
      setStatus([]);
    }

    if (filters.TeamMembers && teamMembers) {
      setInvolved(
        teamMembers.filter(({ IdUser }) =>
          `,${filters.TeamMembers},`.includes(`,${IdUser},`)
        )
      );
    } else {
      setInvolved([]);
    }

    if (filters.Proccesses) {
      let newProcesses: Proccess[] = [];
      processCollection.forEach((process) => {
        `${filters.Proccesses}`.split(",").forEach((selectedProcessId) => {
          if (process.IdTemplateProcess === +selectedProcessId) {
            newProcesses.push(process);
          }
        });
      });
      setProccess(newProcesses);
    } else {
      setProccess([]);
      setStages([]);
    }

    if (filters.Stages && proccess.length === 1) {
      let newStages: Stage[] = [];
      proccess[0]?.Stages.forEach((stage) => {
        `${filters.Stages}`.split(",").forEach((selectedStageId) => {
          if (stage.IdStage === +selectedStageId) {
            newStages.push(stage);
          }
        });
      });
      setStages(newStages);
    } else {
      setStages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onChangeStartDate = (newDate: any) => {
    if (_.isEmpty(newDate)) {
      setStartDate(null);
      const newFilter = { ...filters };
      delete newFilter.StartDate;
      setFilters(newFilter);
      updateDashboardReport(newFilter);
      return;
    }
    const dateForFilter = {
      Start: moment(newDate[0]).format("YY-MM-DD HH:mm:ss"),
      End: moment(newDate[1]).format("YY-MM-DD HH:mm:ss"),
    };
    setStartDate(newDate);
    const newFilter = { ...filters, StartDate: dateForFilter };
    setFilters(newFilter);
    updateDashboardReport(newFilter);
    return;
  };

  const onChangeEndDate = (newDate: any) => {
    const hasFinishStatus = !!_.find(status, { Label: "Terminado", Value: 2 });
    if (_.isEmpty(newDate)) {
      setEndDate(null);
      let newFilter = { ...filters };
      delete newFilter.EndDate;

      if (hasFinishStatus) {
        const newStatus = [...status];
        _.remove(newStatus, { Label: "Terminado", Value: 2 });
        setStatus(newStatus);
        const statusForFilter = newStatus.map((s) => s.Value).join(",");
        if (_.isEmpty(statusForFilter)) {
          delete newFilter.Statuses;
        }
        newFilter = { ...newFilter, Statuses: statusForFilter };
      }

      setFilters(newFilter);
      updateDashboardReport(newFilter);
      return;
    }
    const dateForFilter = {
      Start: moment(newDate[0]).format("YY-MM-DD HH:mm:ss"),
      End: moment(newDate[1]).format("YY-MM-DD HH:mm:ss"),
    };
    setEndDate(newDate);
    let newFilter = { ...filters, EndDate: dateForFilter };

    if (!hasFinishStatus) {
      const newStatus = [...status, { Label: "Terminado", Value: 2 }];
      setStatus(newStatus);
      const statusForFilter = newStatus.map((s) => s.Value).join(",");
      newFilter = { ...newFilter, Statuses: statusForFilter };
    }

    setFilters(newFilter);
    updateDashboardReport(newFilter);
  };

  const onChangeStatus = (oldStatus: Status[], newStatus: Status[]) => {
    if (_.isEqual(oldStatus, newStatus)) return;
    setStatus(newStatus);
    const statusForFilter = newStatus.map((stat) => stat.Value).join(",");
    if (_.isEmpty(statusForFilter)) {
      const newFilter = { ...filters };
      delete newFilter.Statuses;
      setFilters(newFilter);
      updateDashboardReport(newFilter);
      return;
    }
    const newFilter = { ...filters, Statuses: statusForFilter };
    setFilters(newFilter);
    updateDashboardReport(newFilter);
  };

  const onChangeInvolved = (
    oldInvolved: TeamMember[],
    newInvolved: TeamMember[]
  ) => {
    if (_.isEqual(oldInvolved, newInvolved)) return;
    setInvolved(newInvolved);
    const involvedForFilter = newInvolved.map((u) => u.IdUser).join(",");
    if (_.isEmpty(involvedForFilter)) {
      const newFilter = { ...filters };
      delete newFilter.TeamMembers;
      setFilters(newFilter);
      updateDashboardReport(newFilter);
      return;
    }
    const newFilter = { ...filters, TeamMembers: involvedForFilter };
    setFilters(newFilter);
    updateDashboardReport(newFilter);
  };

  const onChangeProcess = (oldProcess: Proccess[], newProcess: Proccess[]) => {
    if (_.isEqual(oldProcess, newProcess)) return;
    setProccess(newProcess);
    if (newProcess.length === 0 || newProcess.length > 1) {
      setStages([]);
    }

    const processForFilter = newProcess
      .map((p) => p.IdTemplateProcess)
      .join(",");
    if (_.isEmpty(processForFilter)) {
      const newFilter = { ...filters };
      delete newFilter.Proccesses;
      if (newFilter.Stages) {
        delete newFilter.Stages;
      }
      setFilters(newFilter);
      updateDashboardReport(newFilter);
      return;
    }
    let newFilter = { ...filters, Proccesses: processForFilter };
    if (newProcess.length !== 1) {
      delete newFilter.Stages;
    }
    setFilters(newFilter);
    updateDashboardReport(newFilter);
  };

  const onChangeStage = (oldStage: Stage[], newStage: Stage[]) => {
    if (_.isEqual(oldStage, newStage)) return;
    if (proccess.length !== 1) {
      setStages([]);
      return;
    }
    setStages(newStage);
    const stageForFilter = newStage.map((s) => s.IdStage).join(",");
    if (_.isEmpty(stageForFilter)) {
      const newFilter = { ...filters };
      delete newFilter.Stages;
      setFilters(newFilter);
      updateDashboardReport(newFilter);
      return;
    }
    const newFilter = { ...filters, Stages: stageForFilter };
    setFilters(newFilter);
    updateDashboardReport(newFilter);
  };

  const onRemoveFilter = (filt: string) => {
    const newFilter = { ...filters };
    switch (filt) {
      case "Status":
        delete newFilter.Statuses;
        setStatus([]);
        break;
      case "Involved":
        delete newFilter.TeamMembers;
        setInvolved([]);
        break;
      case "Process":
        delete newFilter.Proccesses;
        delete newFilter.Stages;
        setStages([]);
        setProccess([]);
        break;
      case "Stage":
        delete newFilter.Stages;
        setStages([]);
        break;
    }
    if (_.isEqual(newFilter, filters)) return;
    setFilters(newFilter);
    updateDashboardReport(newFilter);
  };

  return (
    <FiltersContent>
      <FiltersHeaders>
        <FilterContainer $flex="1" $visible style={{ marginLeft: "0" }}>
          <OpenFiltersButton
            tabIndex={0}
            onClick={() => setExtended(!extended)}
            onKeyDown={({ key }) => {
              if (key === "Enter") setExtended(!extended);
            }}
          >
            <IoIosArrowDown
              size={15}
              color="#48505e"
              style={{
                transform: extended ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.5s ease",
              }}
            />
          </OpenFiltersButton>
          <div style={{ color: "#48505e" }}>{FiltersButton}</div>
        </FilterContainer>

        <FilterContainer $flex="2" $visible={extended}>
          <div>{StartDateLabel}</div>
        </FilterContainer>

        <FilterContainer $flex="2" $visible={extended}>
          <div>{EndDateLabel}</div>
        </FilterContainer>

        <FilterContainer $flex="4" $visible={extended}>
          <div>{StatusLabel}</div>
        </FilterContainer>

        {showInvolvedFilter && (
          <FilterContainer $flex="4" $visible={extended}>
            <div>{InvolvedLabel}</div>
          </FilterContainer>
        )}

        <FilterContainer $flex="3" $visible={extended}>
          <div>{ProcessLabel}</div>
        </FilterContainer>

        <FilterContainer $flex="3" $visible={extended}>
          <div>{StageLabel}</div>
        </FilterContainer>
      </FiltersHeaders>

      <FiltersInputs $extended={extended}>
        <FilterContainer
          $flex="1"
          $visible
          style={{ marginLeft: "0" }}
        ></FilterContainer>

        <FilterContainer $flex="2" $visible>
          <StyledInputBox>
            <RangePicker
              size="small"
              bordered={false}
              value={startDate}
              disabled={!extended}
              onChange={(newDate: any) => onChangeStartDate(newDate)}
            />
          </StyledInputBox>
        </FilterContainer>

        <FilterContainer $flex="2" $visible>
          <StyledInputBox>
            <RangePicker
              size="small"
              bordered={false}
              value={endDate}
              disabled={!extended}
              onChange={(newDate: any) => onChangeEndDate(newDate)}
            />
          </StyledInputBox>
        </FilterContainer>

        <FilterContainer $flex="4" $visible>
          <StatusFilter
            extended={extended}
            status={status}
            statusCollection={statusCollection}
            StatusPlaceholder={StatusPlaceholder}
            onChangeStatus={onChangeStatus}
          />
          {status.length > 0 && (
            <FiltersResetButton onClick={() => onRemoveFilter("Status")}>
              <FiltersCloseIcon />
            </FiltersResetButton>
          )}
        </FilterContainer>

        {showInvolvedFilter && (
          <FilterContainer $flex="4" $visible>
            <InvolvedFilter
              extended={extended}
              involved={involved}
              teamMembers={teamMembers}
              InvolvedPlaceholder={InvolvedPlaceholder}
              onChangeInvolved={onChangeInvolved}
            />
            {involved.length > 0 && (
              <FiltersResetButton onClick={() => onRemoveFilter("Involved")}>
                <FiltersCloseIcon />
              </FiltersResetButton>
            )}
          </FilterContainer>
        )}

        <FilterContainer $flex="3" $visible>
          <ProcessFilter
            extended={extended}
            process={proccess}
            processCollection={processCollection}
            ProcessPlaceholder={ProcessPlaceholder}
            onChangeProcess={onChangeProcess}
          />
          {proccess.length > 0 && (
            <FiltersResetButton onClick={() => onRemoveFilter("Process")}>
              <FiltersCloseIcon />
            </FiltersResetButton>
          )}
        </FilterContainer>

        <FilterContainerAlt
          $flex="3"
          $visible={proccess.length <= 1 && proccess.length !== 0}
        >
          <StageFilter
            extended={extended}
            disabled={proccess.length !== 1}
            stages={stages}
            stageCollection={proccess[0]?.Stages || []}
            stagePlaceholder={StagePlaceholder}
            onChangeStage={onChangeStage}
          />
          {proccess.length <= 1 && proccess.length !== 0 && (
            <FiltersResetButton onClick={() => onRemoveFilter("Stage")}>
              <FiltersCloseIcon />
            </FiltersResetButton>
          )}
        </FilterContainerAlt>
      </FiltersInputs>
    </FiltersContent>
  );
};
export default Filters;
