import React from "react";
import styled from "styled-components";

const ExecutionId = styled.div`
  font-size: 12px;
  font-family: Gotham-Book;
  color: #0273e9;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
  text-align: center;
  width: 100%;
`;

interface ExecutionIdComponentProps {
  value: number | undefined | null;
}

const ExecutionIdComponent: React.FC<ExecutionIdComponentProps> = ({
  value,
}) => {
  return <ExecutionId>{value ? value : ""}</ExecutionId>;
};

export default ExecutionIdComponent;
