import React, { useState } from "react";
import _ from "lodash";
import Modal from "../../../../../components/Modal";
import { Row, Select, Input, Divider, Typography } from "antd";
import { TextField, withStyles } from "@material-ui/core";
import Button from "../../../../../components/Button";
import { Languages } from "../Dictionary";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import { regimenOptions } from "../../../helpers";
import { Button as AddEmailButton } from "@material-ui/core";
import { IoClose } from "react-icons/io5";

const StyledTextFiel = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "grey",
    },
    "& label": {
      fontFamily: "Gotham-Book",
      fontSize: "medium",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Gotham-Book",
      borderRadius: ".4rem",
      marginBottom: "1.4rem",
      "&.Mui-focused fieldset": {
        borderColor: "grey",
      },
    },
  },
})(TextField);

const TextFieldStyled = styled(TextField)`
  &.Mui-focused fieldset {
    border-color: ${({ borderColor }) => borderColor} !important;
  }
`;

const StyledInput = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "grey",
    },
    "& label": {
      fontFamily: "Gotham-Book",
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Gotham-Book",
      borderRadius: "5px",
      "&.Mui-focused fieldset": {
        borderColor: "grey",
      },
    },
    "&.MuiFormControl-root": {
      marginBottom: "1.5rem",
    },
    "&.MuiFormControl-root:nth-child(odd)": {
      width: "calc(55% - 0.75rem)",
    },
    "&.MuiFormControl-root:nth-child(even)": {
      width: "calc(45% - 0.75rem)",
    },
  },
})(TextFieldStyled);

const InputEmail = ({ name, ...props }) => (
  <StyledInput
    label={name}
    placeholder={name}
    variant="outlined"
    size="small"
    {...props}
  />
);

const StyledButton = styled(AddEmailButton)`
  background-color: white !important;
  color: #525151 !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 10px !important;
  width: ${({ $width = "20%" }) => $width} !important;
  height: 40px !important;
  .MuiButton-label {
    display: flex !important;
    gap: 5px !important;
  }
`;

const NotificationUsersContainer = styled.div`
  border: ${({ show }) => (show ? "1px solid #aaa" : "none")};
  border-radius: 10px;
  width: 100%;
  margin-bottom: ${({ show }) => (show ? "1rem" : "0")};
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: ${({ show }) => (show ? "5px" : "0")};
  font-size: 14px;
  color: #535151;

  transition: height 0.35s ease, margin-bottom 0.35s ease;
`;

const EmailContainer = styled.div`
  border: 1px solid #aaa;
  border-radius: 10px;
  max-width: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: width 0.35s ease;

  p {
    margin: 0;
    overflow-wrap: break-word;
  }
`;

const DeleteEmailButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${({ show }) => (show ? "16px" : "0")};
  height: ${({ show }) => (show ? "16px" : "0")};
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: ${({ show }) => (show ? "1" : "0")};
  transition: background-color 0.35s ease, opacity 0.35s ease;
  :hover {
    background-color: rgba(0, 0, 0, 0.45);
  }
`;

const CloseIcon = styled(IoClose)`
  cursor: pointer;
  color: white;
`;

const StyledSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #c0c0c0;
    border-radius: 0.4rem;
    height: 2.5rem;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    :hover {
      border: 1px solid grey;
    }
  }
  &.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    font-size: medium;
    font-family: Gotham-Book;
    height: 1.1876;
    text-align-vertical: center;
    align-self: center;
    margin-top: 2px;
  }
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-size: medium;
    font-family: Gotham-Book;
    height: 1.1876;
    text-align-vertical: center;
    align-self: center;
  }
  .ant-select-selection-placeholder {
    font-size: medium;
    font-family: Gotham-Book;
    color: grey;
    height: 1.1876;
    text-align-vertical: center;
    align-self: center;
  }
`;

const TextInput = ({ name, width, ...props }) => {
  return (
    <StyledTextFiel
      label={name}
      placeholder={name}
      variant="outlined"
      size="small"
      style={{ width }}
      {...props}
    />
  );
};

const EditBillingDataModal = ({
  setVisible,
  socialReason,
  setSocialReason,
  rfc,
  setRfc,
  address,
  setAddress,
  postalCode,
  setPostalCode,
  country,
  setCountry,
  city,
  setCity,
  notifyToCsv,
  setNotifyToCsv,
  handleSubmit,
  loading,
  regimen,
  setRegimen,
}) => {
  const {
    SocialReasonLabel,
    RfcLabel,
    AddressLabel,
    ZipCodeLabel,
    CountryLabel,
    CityLabel,
    Save,
    Cancel,
  } = Languages["ESP"];
  const { EditBillingData } = Languages["ESP"];
  const [newOption, setNewOption] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [hoveredEmail, setHoveredEmail] = useState(null);

  const validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isNewEmailEmpty = _.isEmpty(_.trim(newEmail));
  const isNewEmailValid = validateEmail.test(_.trim(newEmail));
  const isDuplicatedEmail = [...(notifyToCsv || "").split(",")].includes(
    newEmail
  );

  const onEnterNewOption = () => {
    setRegimen(newOption);
    setNewOption("");
  };

  const addNewNotificationUser = () => {
    if (isNewEmailEmpty || !isNewEmailValid || isDuplicatedEmail) return;
    if (!!notifyToCsv) {
      setNotifyToCsv([...notifyToCsv.split(","), newEmail].join(","));
    } else {
      setNotifyToCsv(newEmail);
    }
    setNewEmail("");
  };

  const removeNewNotificationUser = (i) => {
    const auxArr = [...`${notifyToCsv}`.split(",")];
    _.pullAt(auxArr, i);
    setNotifyToCsv(auxArr.join(","));
  };

  return (
    <Modal visible onCancel={setVisible} title={EditBillingData} width="50%">
      <Row type="flex" justify="space-between" style={{ width: "100%" }}>
        <TextInput
          name={SocialReasonLabel}
          width="55%"
          value={socialReason}
          onChange={({ target: { value } }) => setSocialReason(value)}
        />
        <TextInput
          name={RfcLabel}
          width="42%"
          value={rfc}
          onChange={({ target: { value } }) => setRfc(value)}
        />
        <TextInput
          name={AddressLabel}
          width="55%"
          value={address}
          onChange={({ target: { value } }) => setAddress(value)}
        />
        <TextInput
          name={ZipCodeLabel}
          width="42%"
          value={postalCode}
          onChange={({ target: { value } }) => setPostalCode(value)}
        />
        <TextInput
          name={CityLabel}
          width="26%"
          value={city}
          onChange={({ target: { value } }) => setCity(value)}
        />
        <TextInput
          name={CountryLabel}
          width="26%"
          value={country}
          onChange={({ target: { value } }) => setCountry(value)}
        />
        <StyledSelect
          data-testid="selectInputTest"
          style={{ width: "42%", height: "2.5rem" }}
          placeholder="Régimen fiscal"
          value={regimen}
          onChange={setRegimen}
          allowClear
          showSearch
          optionFilterProp="label"
          options={regimenOptions}
          dropdownRender={(menu) => (
            <>
              {menu}
              <div>
                <Divider style={{ margin: "8px 0" }} />
                <Row
                  align="middle"
                  style={{ padding: "0 8px 4px", width: "100%" }}
                >
                  <Input
                    placeholder="Escribe otra opción"
                    style={{ flex: 1 }}
                    value={newOption}
                    onChange={(e) => setNewOption(e.target.value)}
                  />
                  <Typography.Link
                    onClick={onEnterNewOption}
                    style={{ whiteSpace: "nowrap", marginLeft: "0.5rem" }}
                  >
                    <PlusOutlined /> Agregar
                  </Typography.Link>
                </Row>
              </div>
            </>
          )}
        />
        <InputEmail
          name="Enviar facturas a"
          style={{ width: "75%" }}
          value={newEmail}
          type="email"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              addNewNotificationUser();
            }
          }}
          onChange={({ target: { value } }) => setNewEmail(value)}
        />
        <StyledButton $width="22%" onClick={addNewNotificationUser}>
          <PlusOutlined /> Agregar
        </StyledButton>
        <NotificationUsersContainer show={!!notifyToCsv}>
          {!!notifyToCsv &&
            notifyToCsv.split(",").map((email, i) => (
              <EmailContainer
                key={email}
                onMouseEnter={() => setHoveredEmail(i)}
                onMouseLeave={() => setHoveredEmail(null)}
              >
                <p>{email}</p>
                <DeleteEmailButton
                  show={hoveredEmail === i}
                  onClick={() => removeNewNotificationUser(i)}
                >
                  <CloseIcon />
                </DeleteEmailButton>
              </EmailContainer>
            ))}
        </NotificationUsersContainer>
      </Row>
      <Row type="flex" justify="end">
        <Button
          onClick={setVisible}
          style={{ marginRight: "0.5rem", padding: ".3rem 2.5rem" }}
          loading={loading}
        >
          {Cancel}
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          style={{ padding: ".3rem 2.5rem" }}
          loading={loading}
        >
          {Save}
        </Button>
      </Row>
    </Modal>
  );
};

export default EditBillingDataModal;
