export const Languages = {
  ENG: {
    TemplateTriggersTitle: "Disparadores de plantilla",
    TemplateTriggersInfo: "Configura quién puede disparar este proceso",
    NewTrigger: "Nuevo disparador",
    AddTrigerTab: "Agregar nuevo disparador",
    ConfigManualTriggerTab: "Configurar disparador manual",
    ConfigFormTriggerTab: "Configurar disparador de Formulario",
    ConfigFormFieldsTab: "Configurar campos de formulario",
    DefaultText1: "Agrega formas de iniciar tu proceso",
    DefaultText2: "Cada disparador hace que el proceso pueda iniciar",
    DefaultText3: "Muchas de ellas te ahorraran toneladas de tiempo",
    MoreTriggersSoonLabel: "Más disparadores próximamente",
    ConformDeleteTitle: "Eliminar disparador",
    Cancel: "Cancelar",
    Delete: "Eliminar",
    ManualTriggerTitleLabel: "Disparador Manual",
    ManualTriggerDescription1:
      "Configura los permisos para iniciar tu plantilla desde Geest.",
    ManualTriggerDescription2:
      "Configura los roles que podrán iniciar la plantilla",
    ManualTriggerRolesLabel: "Roles de empresa con permiso de iniciar",
    DeleteTriggerButtonLabel: "Eliminar",
    FormTriggerTitleLabel: "Disparador de formulario",
    FormTriggerDescription1:
      "Configura cómo se mostrará tu plantilla fuera de Geest.",
    HasChangesTooltipLabel:
      "Has click en publicar cambios para guardar el estado actual de tu plantilla",
    HasChangesLabel: "Se necesita publicar la plantilla",
    PreviewButtonLabel: "Vista previa",
    ConfigButtonLabel: "Configurar campos a solicitar",
    NoLinkDescription:
      "Si deseas generar un link para compartir tu plantilla y un código embebido, primero debes publicar tu plantilla",
    SuccessCopy: "¡Copiado con éxito!",
    CopyThisLink: "Copia este link de tu formulario",
    CopyThisEmbebed: "Copia código embebido",
    TabConfigurationTitle: "Configuración de la pestaña",
    FormTitleLabel: "Título del formulario",
    AddLogoLabel: "Agrega un logotipo",
    FilledLogo: "Tu logo",
    EmptyLogo: "Sube tu logo",
    FormDescriptionLabel: "Descripción del formulario",
    FormBackgroundLabel: "Agrega un fondo",
    FilledBackground: "Tu fondo",
    EmptyBackground: "Sube tu fondo",
    EmtpyTitleError: "El titulo de formulario no puede estar vacío.",
    SearchPlaceholder: "Buscar",
    GlobalFieldsTitle: "Campos del proceso",
    FormFieldsTitle: "Campos del formulario",
    ItemExistsMessage: "Este campo ya está importado",
    OnlyOneManual: "Sólo puede existir un disparador de manual en Geest",
  },
  ESP: {
    TemplateTriggersTitle: "Disparadores de plantilla",
    TemplateTriggersInfo: "Configura quién puede disparar este proceso",
    NewTrigger: "Nuevo disparador",
    AddTrigerTab: "Agregar nuevo disparador",
    ConfigManualTriggerTab: "Configurar disparador manual",
    ConfigFormTriggerTab: "Configurar disparador de formulario",
    ConfigFormFieldsTab: "Configurar campos de formulario",
    DefaultText1: "Agrega formas de iniciar tu proceso",
    DefaultText2: "Cada disparador hace que el proceso pueda iniciar",
    DefaultText3: "Muchas de ellas te ahorraran toneladas de tiempo",
    MoreTriggersSoonLabel: "Más disparadores próximamente",
    ConformDeleteTitle: "Eliminar disparador",
    Cancel: "Cancelar",
    Delete: "Eliminar",
    ManualTriggerTitleLabel: "Disparador Manual",
    ManualTriggerDescription1:
      "Configura los permisos para iniciar tu plantilla desde Geest.",
    ManualTriggerDescription2:
      "Configura los roles que podrán iniciar la plantilla",
    ManualTriggerRolesLabel: "Roles de empresa con permiso de iniciar",
    DeleteTriggerButtonLabel: "Eliminar",
    FormTriggerTitleLabel: "Disparador de formulario",
    FormTriggerDescription1:
      "Configura cómo se mostrará tu plantilla fuera de Geest.",
    HasChangesTooltipLabel:
      "Has click en publicar cambios para guardar el estado actual de tu plantilla",
    HasChangesLabel: "Se necesita publicar la plantilla",
    PreviewButtonLabel: "Vista previa",
    ConfigButtonLabel: "Configurar campos a solicitar",
    NoLinkDescription:
      "Si deseas generar un link para compartir tu plantilla y un código embebido, primero debes publicar tu plantilla",
    SuccessCopy: "¡Copiado con éxito!",
    CopyThisLink: "Copia este link de tu formulario",
    CopyThisEmbebed: "Copia código embebido",
    TabConfigurationTitle: "Configuración de la pestaña",
    FormTitleLabel: "Título del formulario",
    AddLogoLabel: "Agrega un logotipo",
    FilledLogo: "Tu logo",
    EmptyLogo: "Sube tu logo",
    FormDescriptionLabel: "Descripción del formulario",
    FormBackgroundLabel: "Agrega un fondo",
    FilledBackground: "Tu fondo",
    EmptyBackground: "Sube tu fondo",
    EmtpyTitleError: "El titulo de formulario no puede estar vacío.",
    SearchPlaceholder: "Buscar",
    GlobalFieldsTitle: "Campos del proceso",
    FormFieldsTitle: "Campos del formulario",
    ItemExistsMessage: "Este campo ya está importado",
    OnlyOneManual: "Sólo puede existir un disparador de manual en Geest",
  },
};
