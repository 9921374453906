import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import Bugsnag, { NotifiableError } from "@bugsnag/js";
import { RcFile } from "antd/lib/upload";
import { createUUID } from "../Helpers";

const s3Config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_AWS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_AWS_KEY,
};

const s3Client = new S3Client({
  region: s3Config.region,
  credentials: {
    accessKeyId: s3Config.accessKeyId,
    secretAccessKey: s3Config.secretAccessKey,
  },
});

const awsUrlBase: string = `https://${s3Config.bucketName}.s3.${s3Config.region}.amazonaws.com/`;

type AWSFilePath = string | null;

const getFileExtension = (fileName: string) => {
  const namesplit = fileName.split(".");
  return namesplit[namesplit.length - 1];
};

export const uploadFileToAWS = async (
  file: RcFile,
  filesPath: string,
  fileName?: string
): Promise<AWSFilePath> => {
  let key = "";
  if (fileName) {
    key = `${filesPath}${createUUID()}/${fileName}`;
  } else {
    key = `${filesPath}${createUUID()}.${getFileExtension(file.name)}`;
  }

  try {
    await s3Client.send(
      new PutObjectCommand({
        Bucket: s3Config.bucketName,
        Key: key,
        Body: file,
        ACL: "public-read",
      })
    );
  } catch (error: unknown) {
    Bugsnag.notify(error as NotifiableError, function (event) {
      event.addMetadata("Upload file error", { filePath: key });
    });
    return null;
  }
  return awsUrlBase + key;
};
