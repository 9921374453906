import React from "react";
import { Modal } from "@material-ui/core";
import { Row } from "antd";
import { Typography } from "../../GeestUI";
import { CloseIcon } from "../hoverIcons";
import {
  StyledPaper,
  ColumnsContainer,
  Column,
  ShortcutContainer,
  ShortcutName,
  KeyContainer,
  KeyIcon,
} from "./Styles";

interface ShortcutListProps {
  setShowShortcuts: () => void;
}

interface shortcutType {
  name: string;
  keyMap: string;
}

const Languages = {
  ENG: {
    Title: "Keyboard shortcuts",
    // navigation
    ShortcutHelp: "Shortcut help",
    CloseShortcutHelp: "Close shortcut help",
    OpenPendings: "Go to my pendings",
    OpenProcesses: "Go to templates",
    OpenDatabases: "Go to databases",
    OpenTracking: "Go to tracking",
    OpenPipeline: "Go to pipeline",
    OpenDashboards: "Go to dashboards",
    OpenCompanies: "Go to my companies",
  },
  ESP: {
    Title: "Atajos de teclado",
    // navigation
    ShortcutHelp: "Ayuda para atajos",
    CloseShortcutHelp: "Cerrar ayuda para atajos",
    OpenPendings: "Ir a mis pendientes",
    OpenProcesses: "Ir a procesos",
    OpenDatabases: "Ir a bases de datos",
    OpenTracking: "Ir a tacking",
    OpenPipeline: "Ir a pipeline",
    OpenDashboards: "Ir a dashboards",
    OpenCompanies: "Ir a mis empresas",
  },
};

const {
  Title,
  ShortcutHelp,
  CloseShortcutHelp,
  OpenPendings,
  OpenProcesses,
  OpenDatabases,
  OpenTracking,
  OpenPipeline,
  OpenDashboards,
  OpenCompanies,
} = Languages["ESP"];

const { H3, B, P } = Typography;

const ShortcutList: React.FC<ShortcutListProps> = ({ setShowShortcuts }) => {
  const shortcuts: shortcutType[] = [
    { name: ShortcutHelp, keyMap: "SHIFT+?" },
    { name: CloseShortcutHelp, keyMap: "ESC" },
    { name: OpenPendings, keyMap: "G+M" },
    { name: OpenProcesses, keyMap: "G+P" },
    { name: OpenDatabases, keyMap: "G+B" },
    { name: OpenTracking, keyMap: "G+T" },
    { name: OpenPipeline, keyMap: "G+F" },
    { name: OpenDashboards, keyMap: "G+D" },
    { name: OpenCompanies, keyMap: "G+E" },
  ];

  return (
    <Modal open onClose={setShowShortcuts} style={{ opacity: 1, zIndex: 20 }}>
      <Row
        justify="center"
        align="middle"
        onClick={setShowShortcuts}
        style={{ width: "100%", height: "100%" }}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <Row align="middle" justify="space-between">
            <H3 mb="0">
              <B>{Title}</B>
            </H3>
            <CloseIcon onClick={setShowShortcuts} />
          </Row>
          <ColumnsContainer>
            <Column>
              {shortcuts.map((shortcut) => (
                <ShortcutContainer key={shortcut.name}>
                  <ShortcutName>
                    <P mb="0">{shortcut.name}:</P>
                  </ShortcutName>
                  <KeyContainer>
                    {shortcut.keyMap.split("+").map((key, i) => (
                      <>
                        {i > 0 && <P mb="0">+</P>}
                        <KeyIcon>
                          <P mb="0" key={i}>
                            {key}
                          </P>
                        </KeyIcon>
                      </>
                    ))}
                  </KeyContainer>
                </ShortcutContainer>
              ))}
            </Column>
          </ColumnsContainer>
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default ShortcutList;
