import styled from "styled-components";

export const LinkStyled = styled.a`
  border-radius: 5px;
  padding: 0 5px !important;
  color: #0273e9;
  background: white;

  :hover {
    color: #014c9b;
  }

  :focus {
    border: 1px solid #2684ff;
  }
`;

export const IconContainer = styled.span<{ size: string }>`
  margin-right: 5px !important;
  img {
    width: ${({ size = "20px" }) => size};
    height: ${({ size = "20px" }) => size};
  }
`;

export const ImgContainer = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;

export const ListContainer = styled.ul`
  margin-left: 26px !important;
`;

export const OrderedListContainer = styled.ol`
  margin-left: 26px !important;
`;
