import React, { useContext, useState } from "react";
import { noop as NOOP } from "lodash";
import styled from "styled-components";
import Modal from "../../../components/Modal";
import { Languages } from "../Dictionary";
import { MessagesContext } from "../../../components/AppMessages";
import GeestSelect from "../../../GeestUI/GeestSelect";
import { Row } from "antd";
import { Button } from "../../../GeestUI";
import baseURL from "../../../api/baseURL";
import TemplateImportsList from "../InstallTemplate/TemplateImportsList";
import { useFetch } from "../../../hooks";
import { TemplateImportsContainer } from "../InstallTemplate/style";

const Text = styled.div`
  font-size: 14px;
  color: rgba(130, 141, 158, 1);
`;

const ShareTemplate = ({ templatesToShare, closeModal = NOOP }) => {
  const { showMessage } = useContext(MessagesContext);

  const { data } = useFetch({
    func: "Ver2-Processes-giw",
    args: {
      IdProcessTemplates: templatesToShare,
      Step: "General",
    },
  });

  const {
    WantToShareLabel,
    CopyLinkLabel,
    ShareThroughLinkLabel,
    WantToSharePackageLabel,
  } = Languages["ESP"];

  const [TeamReceivesCanShare, setTeamReceivesCanShare] = useState(0);

  const options = [
    {
      label: "No",
      value: 0,
    },
    {
      label: "Sí",
      value: 1,
    },
  ];

  const copyLink = () => {
    const obj = {
      IdProcessTemplate: templatesToShare,
      CanReShareTemplate: Boolean(TeamReceivesCanShare),
    };
    const value = JSON.stringify(obj);
    const encoded = btoa(value);
    const link = `${baseURL}#/redirect?redirect=templates?enc=${encoded}`;
    navigator.clipboard.writeText(link);
    showMessage("¡Link copiado con éxito!", "success");
    closeModal();
  };

  return (
    <Modal
      visible
      width={"879px"}
      onCancel={closeModal}
      bodyStyle={{ height: "663px" }}
      title={
        templatesToShare.length > 1
          ? "Compartir paquete de procesos"
          : `Compartir proceso: ${data?.ProcessTemplateName}`
      }
    >
      <Row style={{ width: "100%", marginTop: "1rem" }} justify="center">
        <TemplateImportsContainer>
          <TemplateImportsList
            varDBs={data?.ProcessTemplateVarDBs}
            IdProcessTemplate={templatesToShare}
            processTemplates={data?.ProcessTemplates}
            sharing
          />
          <Row
            align="middle"
            justify="space-between"
            style={{ margin: "10px" }}
          >
            <Text>
              {templatesToShare.length > 1
                ? WantToSharePackageLabel
                : WantToShareLabel}
            </Text>
            <GeestSelect
              value={TeamReceivesCanShare}
              options={options}
              onChange={setTeamReceivesCanShare}
              disableSearch
              $width="122px"
              $listWidth="122px"
              valueNecesary
            />
          </Row>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            <Text>{ShareThroughLinkLabel}</Text>
          </Row>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            <Button type="primary" onClick={copyLink}>
              {CopyLinkLabel}
            </Button>
          </Row>
        </TemplateImportsContainer>
      </Row>
    </Modal>
  );
};

export default ShareTemplate;
