import React from "react";
import styled from "styled-components";
import useCounter from "../../../store/useCounter";
import { Button } from "../../../GeestUI";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 20px;
  border-radius: 10px;
  background-color: #f8f9fa;

  p {
    font-size: 48px;
    font-weight: bold;
    color: #007bff;
    margin: 0;
  }
`;

interface SocketsProps {}

const Sockets: React.FC<SocketsProps> = () => {
  const { count, inc } = useCounter();
  return (
    <Container>
      <p>{count}</p>
      <Button type="primary" onClick={inc}>
        one up
      </Button>
    </Container>
  );
};

export default Sockets;
