import React, { useEffect, useState } from "react";
import _, { noop as NOOP } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useMutation } from "../../hooks";
import GeestLogo from "../../resources/img/ICONO_.svg";
import { Typography } from "../../GeestUI";
import {
  FormMetaData,
  DecodedFormInfo,
  FieldType,
  FieldVarDBRowType,
  VarDBRowType,
} from "./TriggerView.d";
import {
  Container,
  CenteredItem,
  MainContent,
  AlertContainer,
  HeaderContainer,
  Img,
  TitleText,
  DescriptionText,
  FieldsContainer,
  FieldsCard,
  FieldsWrapper,
  FieldContainer,
  Field,
  SendContainer,
  FooterContainer,
  FooterButton,
} from "./Style";
import DynamicInput from "../../components/DynamicInput";
import DataTypeIcons from "../../components/DataTypeIcons";
import Loading from "../../components/Loading";
import { Button } from "../../GeestUI";
import { EditorState, convertFromRaw } from "draft-js";
import GeestTextEditor from "../../components/GeestTextEditor";
import Successful from "./Successful";
import {
  FileField,
  SelectFileFieldModal,
  onPasteImage,
  FileType,
} from "../../components/DynamicInput/Components/helpers/FileHelpers";

const Languages = {
  ENG: {
    SendButtonLabel: "Send",
    IsPrepublishAlert:
      "This form is a preview, remember to publish your changes!",
  },
  ESP: {
    SendButtonLabel: "Enviar",
    IsPrepublishAlert:
      "Este formulario es una vista previa, ¡recuerda publicar tus cambios!",
  },
};

const { P } = Typography;
const { SendButtonLabel, IsPrepublishAlert } = Languages["ESP"];

const defaultForm = {
  Label: "",
  Description: "",
  Logo: "",
  Background: "",
};

const TriggerView: React.FC = () => {
  const location = useLocation();
  const { pb } = queryString.parse(location.search);
  const [isPrepublish, setIsPrepublish] = useState<boolean>(false);
  const [idTrigger, setIdTrigger] = useState<number | null>(null);
  const [formData, setFormData] = useState<FormMetaData>(defaultForm);
  const [copiedFile, setCopiedFile] = useState<FileType | null>(null);
  const [fileFields, setFileFields] = useState<FileField[]>([]);
  const [prevFields, setPrevField] = useState<
    (FieldType | FieldVarDBRowType)[]
  >([]);
  const [fields, setFields] = useState<(FieldType | FieldVarDBRowType)[]>([]);
  const [favicon, setFavicon] = useState<string>(
    "https://app.geest.app/ver2/icono.png"
  );
  const [filesPath, setFilesPath] = useState<string>("");
  const [redirectLink, setRedirectLink] = useState<string | undefined>();
  const [successful, setSuccesful] = useState<boolean>(false);
  const history = useHistory();

  const fullWidth = fields.length < 5;

  const [getFormInformation, gettingInfo] = useMutation<string>({
    func: "Ver2-PublicForm-gfi",
    onSuccess: (response) => {
      const decoded = atob(response);
      const {
        Configuration: { Tab, Form },
        TriggerFields,
        RedirectLink,
        FilesPath,
      }: DecodedFormInfo = JSON.parse(decoded);
      setFilesPath(FilesPath);
      setRedirectLink(RedirectLink);

      document.title = Tab.Label ?? "Geest";
      setFavicon(Tab.FavIcon ?? "https://app.geest.app/ver2/icono.png");
      setFormData(Form);

      let auxFields = _.cloneDeep(TriggerFields);
      let idx = -1;
      TriggerFields.forEach((field) => {
        idx += 1;
        if (field.DataType === "varDBRow") {
          (field as VarDBRowType).Cells.forEach((f: any) => {
            idx += 1;
            auxFields.splice(idx, 0, { ...f, IdVarDB: field.IdVarDB });
          });
        }
      });
      auxFields = auxFields.filter((f) => f.DataType !== "varDBRow");
      setFields(auxFields);
      const fileFields = auxFields
        .filter((f) => f.DataType === "file")
        .map(({ IdField, Label }) => ({
          Id: IdField || 0,
          Label: Label,
        }));
      setFileFields(fileFields);

      setPrevField(TriggerFields);
    },
  });

  const [sendForm, sendingForm] = useMutation({
    func: "Ver2-PublicForm-sf",
    onSuccess: () => {
      let auxFields = _.cloneDeep(prevFields);
      let idx = -1;
      prevFields.forEach((field) => {
        idx += 1;
        if (field.DataType === "varDBRow") {
          (field as VarDBRowType).Cells.forEach((f: any) => {
            idx += 1;
            auxFields.splice(idx, 0, f);
          });
        }
      });
      auxFields = auxFields.filter((f) => f.DataType !== "varDBRow");

      setFields(auxFields);

      setSuccesful(true);

      setTimeout(() => {
        if (redirectLink) {
          if (
            redirectLink.includes("https://") ||
            redirectLink.includes("http://")
          ) {
            window.open(redirectLink, "_self");
          } else {
            window.open(`http://${redirectLink}`, "_self");
          }
        }
        setSuccesful(false);
      }, 3000);
    },
  });

  const [addOptionToDataOriginPendings] = useMutation<{
    label: string;
    value: number;
  }>({
    func: "Ver2-MyPendings-aotdo",
    onSuccess: (newOption, { index }) => {
      let fieldsAux = [...fields];
      (fieldsAux[index] as FieldType).DataOrigin = [
        ...(fieldsAux[index] as FieldType).DataOrigin,
        newOption,
      ];
      if (fields[index].DataType === "select") {
        (fieldsAux[index] as FieldType).Value = {
          Type: "Key",
          Value: newOption.value,
          Label: newOption.label,
        };
      }
      if (fields[index].DataType === "multi_select") {
        (fieldsAux[index] as FieldType).Value = [
          ...((fieldsAux[index] as FieldType).Value as any[]),
          {
            Type: "Key",
            Value: newOption.value,
            Label: newOption.label,
          },
        ];
      }
      setFields(fieldsAux);
    },
  });

  const handleAddOption = (option: string, IdField: number, index: number) => {
    addOptionToDataOriginPendings({
      args: {
        IdField,
        NewElement: option,
      },
      shippedData: { index },
    });
  };

  const [addOptionToDataOriginVarDBs] = useMutation<{
    label: string;
    value: number;
  }>({
    func: "Ver2-Vardbs-aotdo",
    onSuccess: (newOption, { index }) => {
      let fieldsAux = [...fields];
      (fieldsAux[index] as FieldType).DataOrigin = [
        ...(fieldsAux[index] as FieldType).DataOrigin,
        newOption,
      ];
      if (fields[index].DataType === "select") {
        (fieldsAux[index] as FieldType).Value = {
          Type: "Key",
          Value: newOption.value,
          Label: newOption.label,
        };
      }
      if (fields[index].DataType === "multi_select") {
        (fieldsAux[index] as FieldType).Value = [
          ...(((fieldsAux[index] as FieldType).Value as any[]) || []),
          {
            Type: "Key",
            Value: newOption.value,
            Label: newOption.label,
          },
        ];
      }
      setFields(fieldsAux);
    },
  });

  const handleAddOptionFromCell = (
    option: string,
    field: any,
    index: number
  ) => {
    if (field.IdVarDBColumn) {
      addOptionToDataOriginVarDBs({
        args: {
          IdColumn: field.IdVarDBColumn,
          IdVarDB: field.IdVarDB,
          DataOrigin: [...field.DataOrigin, { label: option }],
        },
        shippedData: { index },
      });
    }
  };

  useEffect(() => {
    const faviconElement = document.getElementById("favicon");
    // @ts-ignore
    faviconElement.href = favicon;
  }, [favicon]);

  useEffect(() => {
    if (pb) {
      // @ts-ignore
      const decoded = atob(pb);
      const args = JSON.parse(decoded);
      if (args.IdPrepublishTrigger) {
        setIsPrepublish(true);
        getFormInformation({
          args: {
            IdPrepublishTrigger: args.IdPrepublishTrigger,
          },
        });
      } else {
        setIdTrigger(args.IdTrigger);
        getFormInformation({
          args: {
            IdTrigger: args.IdTrigger,
          },
        });
      }
    } else {
      history.push("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pb]);

  const openGeestWeb = () =>
    window.open("https://www.geest.app", "_blank", "noreferrer");

  const onChangeFields = (value: any, index: number) => {
    let fieldsAux: any = _.cloneDeep(fields);
    fieldsAux[index].Value = value;
    setFields(fieldsAux);
  };

  const onChangeFormat = (value: any, index: number) => {
    let fieldsAux: any = _.cloneDeep(fields);
    fieldsAux[index].Format = value;
    setFields(fieldsAux);
  };

  const onChangeCells = (newCells: any, index: number) => {
    let fieldsAux: any = _.cloneDeep(fields);
    fieldsAux[index].Cells = newCells;
    setFields(fieldsAux);
  };

  const isValidForm = (): boolean => {
    for (const field of fields) {
      if (field.DataType === "varDBRow") {
        // varDB cells
        const missingFields = (field as FieldVarDBRowType).Cells.filter(
          // Mandatory and empty
          (cell) => cell.IsMandatory && _.isEmpty(cell.Value)
        );
        if (!_.isEmpty(missingFields)) return false;
      } else {
        // Rest of regular fields
        const fieldType = field as FieldType;
        // Mandatory and empty
        if (fieldType.IsMandatory && !Boolean(fieldType.Value)) return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    let auxFields = _.cloneDeep(prevFields);
    fields.forEach((field: any) => {
      prevFields.forEach((f: any, i) => {
        if (f.Type === "Manual") {
          if (f.IdField === field.IdField) {
            auxFields.splice(i, 1, field);
          }
        } else {
          if (f.IdVarDBGroup === field.IdVarDBGroup) {
            (f as VarDBRowType).Cells.forEach((c, j) => {
              if (c.IdField === field.IdField) {
                (auxFields[i] as VarDBRowType).Cells.splice(j, 1, field);
              }
            });
          }
        }
      });
    });

    sendForm({
      args: {
        IdTrigger: idTrigger,
        TriggerFields: auxFields,
      },
    });
  };

  const onPasteImageToField = (Id: number, value: FileType) => {
    const index = fields.findIndex(({ IdField }) => IdField === Id);
    if (index >= 0) {
      const oldValue = (fields[index] as any).Value;
      onChangeFields(oldValue ? [...oldValue, value] : [value], index);
    }
  };

  useEffect(() => {
    if (fileFields.length > 0) {
      const onKeyDown = async (e: KeyboardEvent) => {
        if (e.key.toLowerCase() === "v" && e.ctrlKey) {
          const value = await onPasteImage(filesPath);
          if (value) {
            if (fileFields.length > 1) {
              setCopiedFile(value);
            } else {
              onPasteImageToField(fileFields[0].Id, value);
            }
          }
        }
      };
      window.addEventListener("keydown", onKeyDown);

      return () => window.removeEventListener("keydown", onKeyDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileFields, fields]);

  return (
    <Container $bgImage={formData.Background}>
      {!!copiedFile && (
        <SelectFileFieldModal
          fileFields={fileFields}
          onChangeFileField={onPasteImageToField}
          file={copiedFile}
          closeModal={() => setCopiedFile(null)}
        />
      )}
      {isPrepublish && (
        <AlertContainer>
          <P mb="0">{IsPrepublishAlert}</P>
        </AlertContainer>
      )}
      <MainContent>
        {gettingInfo || sendingForm ? (
          <Loading Label={sendingForm ? "Enviando" : undefined} />
        ) : successful ? (
          <Successful />
        ) : (
          <>
            <HeaderContainer style={isPrepublish ? { marginTop: "2rem" } : {}}>
              {formData.Logo && (
                <CenteredItem>
                  <Img
                    src={formData.Logo}
                    alt=""
                    width="100px"
                    height="100px"
                  />
                </CenteredItem>
              )}
              {typeof formData.Label === "object" ? (
                <GeestTextEditor
                  editorState={EditorState.createWithContent(
                    convertFromRaw(formData.Label)
                  )}
                  readOnly
                  onEditorStateChange={() => {}}
                />
              ) : (
                <TitleText>{formData.Label}</TitleText>
              )}
              {formData.Description &&
                (typeof formData.Description === "object" ? (
                  <GeestTextEditor
                    editorState={EditorState.createWithContent(
                      convertFromRaw(formData.Description)
                    )}
                    readOnly
                    onEditorStateChange={() => {}}
                  />
                ) : (
                  <DescriptionText>{formData.Description}</DescriptionText>
                ))}
            </HeaderContainer>

            <FieldsContainer>
              <FieldsCard $fullWidth={fullWidth}>
                <FieldsWrapper>
                  {fields.map((field: any, i) => (
                    <FieldContainer $fullWidth={fullWidth} key={i}>
                      <Field>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          {DataTypeIcons[field.DataType] && (
                            <img
                              src={DataTypeIcons[field.DataType].icon}
                              alt=""
                              style={{
                                width: "10px",
                                height: "10px",
                                ...DataTypeIcons[field.DataType]?.extraStyles,
                              }}
                            />
                          )}
                          <P mb="0" style={{ fontFamily: "Gotham-Bold" }}>
                            {field.Label}
                          </P>
                        </div>
                        <DynamicInput
                          value={field.Value ?? undefined}
                          type={field.DataType}
                          dataOrigin={field.DataOrigin ?? ""}
                          format={field.Format ?? ""}
                          configuration={field.Configuration ?? ""}
                          userSelectDataOrigin={field.DataOrigin ?? []}
                          IdVarDB={field.IdVarDB}
                          VarDBTitle={field.VarDBTitle ?? ""}
                          Cells={field.Cells || []}
                          VarDBDataOrigin={field.VarDBDataOrigin ?? []}
                          IdVarDBGroup={field.IdVarDBGroup}
                          fieldName={field.Label}
                          isConsult={false}
                          disabled={false}
                          required={field.IsMandatory}
                          onChange={(value) => onChangeFields(value, i)}
                          onChangeFormat={(value) => onChangeFormat(value, i)}
                          onChangeCells={(newCells) =>
                            onChangeCells(newCells, i)
                          }
                          extraParams={{
                            FilesPath: filesPath ?? "",
                            canAddNewRows: !!field.canAddNewRows,
                            CanUseExistingRows: !!field.CanUseExistingRows,
                            shouldValidateSize: false,
                          }}
                          onAddOption={(option) =>
                            field.Type !== "Cell"
                              ? handleAddOption(option, field.IdField, i)
                              : handleAddOptionFromCell(option, field, i)
                          }
                        />
                      </Field>
                    </FieldContainer>
                  ))}
                </FieldsWrapper>
              </FieldsCard>

              <SendContainer $fullWidth={fullWidth}>
                <Button
                  disabled={!isValidForm()}
                  onClick={isPrepublish ? NOOP : handleSubmit}
                  loading={sendingForm}
                  type="primary"
                  size="fullwidth"
                >
                  {SendButtonLabel}
                </Button>
              </SendContainer>
            </FieldsContainer>
          </>
        )}

        <FooterContainer onClick={openGeestWeb}>
          <FooterButton>
            <P mb="0">Powered by Geest</P>
            <CenteredItem>
              <img src={GeestLogo} alt="" width="32px" height="32px" />
            </CenteredItem>
          </FooterButton>
        </FooterContainer>
      </MainContent>
    </Container>
  );
};

export default TriggerView;
