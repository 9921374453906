import styled from "styled-components";
import ButtonComponent from "./../../../../../components/Button";

export const Card = styled.div`
  background: white;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  padding: 1rem 2rem;
  border: 1px solid ${({ planColor }) => planColor};
`;

export const Title = styled.div`
  font-size: 20px;
  font-family: "gotham-bold";
  border-bottom: 1.5px solid #c9c9c9;
  margin-bottom: 1rem;
  color: ${({ planColor }) => planColor};
`;

export const Bold = styled.b`
  font-family: "gotham-bold";
`;

export const H3 = styled.div`
  font-size: 18px;
  line-height: 1.4rem;
  margin-bottom: 1.25rem;
`;

export const H4 = styled.div`
  font-size: 16px;
  line-height: 1.2rem;
  margin-bottom: 1.25rem;
`;

export const H6 = styled.div`
  font-size: 12px;
`;

export const Button = styled(ButtonComponent)`
  && {
    margin-bottom: 1.25rem;
  }
  span {
    color: ${({ $planColor }) => $planColor};
    font-size: 16px;
  }
`;
