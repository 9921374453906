import React, { useState } from "react";
import { Checkbox, Row } from "antd";
import { Permissions } from "../../../Teams.d";
import { PermissionContainer } from "../Style";
import { useMutation } from "../../../../../hooks";
import { RoleTitle, PermissionItems, PermissionLabel } from "../Style";
import { CloseIcon } from "../../../../../components/hoverIcons";

interface PermissionsPopoverProps {
  idTeam: string;
  idUser: number;
  canEdit: boolean;
  isLeader: boolean;
  memberName: string;
  memberPermissions: Permissions;
  onClose: () => void;
  reload: () => void;
}

const PermissionsPopover: React.FC<PermissionsPopoverProps> = ({
  idTeam,
  idUser,
  canEdit,
  isLeader,
  memberName,
  memberPermissions,
  onClose,
  reload,
}) => {
  const [permissions, setPermissions] =
    useState<Permissions>(memberPermissions);

  const [updateUserPermissions, updating] = useMutation<[]>({
    func: "Ver2-Team-uup",
    onSuccess: () => reload(),
  });

  const handleUpdatePermissions = (permission: string, checked: boolean) => {
    let newPermission = { ...permissions };
    switch (permission) {
      case "CanExportDatabases":
        newPermission.CanExportDatabases = checked;
        setPermissions(newPermission);
        break;
      case "CanExportReportData":
        newPermission.CanExportReportData = checked;
        setPermissions(newPermission);
        break;

      case "CanEditTeamAndMembers":
        newPermission.CanEditTeamAndMembers = checked;
        setPermissions(newPermission);
        break;

      case "CanConfigureProcesses":
        newPermission.CanConfigureProcesses = checked;
        setPermissions(newPermission);
        break;

      case "CanAddDashBoards":
        newPermission.CanAddDashBoards = checked;
        setPermissions(newPermission);
        break;

      case "CanEditDatabases":
        if (checked) {
          newPermission.CanEditDatabases = checked;
          newPermission.CanViewDatabases = checked;
          setPermissions(newPermission);
          break;
        }
        newPermission.CanEditDatabases = checked;
        setPermissions(newPermission);
        break;

      case "CanViewDatabases":
        if (checked) {
          newPermission.CanViewDatabases = checked;
          setPermissions(newPermission);
          break;
        }
        newPermission.CanEditDatabases = checked;
        newPermission.CanViewDatabases = checked;
        setPermissions(newPermission);
        break;

      case "CanEditAllReports":
        if (checked) {
          newPermission.CanEditAllReports = checked;
          newPermission.CanViewAllReports = checked;
          newPermission.CanEditHisReportsInfo = checked;
          newPermission.CanViewHisReportsInfo = checked;
          newPermission.CanViewHisReportsFlux = checked;
          setPermissions(newPermission);
          break;
        }
        newPermission.CanEditAllReports = checked;
        setPermissions(newPermission);
        break;

      case "CanViewAllReports":
        if (checked) {
          newPermission.CanViewAllReports = checked;
          newPermission.CanEditHisReportsInfo = checked;
          newPermission.CanViewHisReportsInfo = checked;
          newPermission.CanViewHisReportsFlux = checked;
          setPermissions(newPermission);
          break;
        }
        newPermission.CanEditAllReports = checked;
        newPermission.CanViewAllReports = checked;
        setPermissions(newPermission);
        break;

      case "CanEditHisReportsInfo":
        if (checked) {
          newPermission.CanEditHisReportsInfo = checked;
          newPermission.CanViewHisReportsInfo = checked;
          newPermission.CanViewHisReportsFlux = checked;
          setPermissions(newPermission);
          break;
        }
        newPermission.CanEditAllReports = checked;
        newPermission.CanViewAllReports = checked;
        newPermission.CanEditHisReportsInfo = checked;
        setPermissions(newPermission);
        break;

      case "CanViewHisReportsInfo":
        if (checked) {
          newPermission.CanViewHisReportsInfo = checked;
          newPermission.CanViewHisReportsFlux = checked;
          setPermissions(newPermission);
          break;
        }
        newPermission.CanEditAllReports = checked;
        newPermission.CanViewAllReports = checked;
        newPermission.CanEditHisReportsInfo = checked;
        newPermission.CanViewHisReportsInfo = checked;
        setPermissions(newPermission);
        break;

      case "CanViewHisReportsFlux":
        if (checked) {
          newPermission.CanViewHisReportsFlux = checked;
          setPermissions(newPermission);
          break;
        }
        newPermission.CanEditAllReports = checked;
        newPermission.CanViewAllReports = checked;
        newPermission.CanEditHisReportsInfo = checked;
        newPermission.CanViewHisReportsInfo = checked;
        newPermission.CanViewHisReportsFlux = checked;
        setPermissions(newPermission);
        break;

      default:
        break;
    }
    updateUserPermissions({
      args: {
        IdTeam: idTeam,
        IdUser: idUser,
        Permissions: { ...newPermission },
      },
    });
  };

  return (
    <div style={{ margin: 0 }}>
      <Row
        align="middle"
        justify="space-between"
        wrap={false}
        style={{ marginBottom: "14px" }}
      >
        <RoleTitle>Permisos de {memberName}</RoleTitle>
        <CloseIcon onClick={onClose} />
      </Row>
      <PermissionContainer>
        <RoleTitle style={{ fontSize: "14px", marginBottom: "5px" }}>
          Generales
        </RoleTitle>
        <PermissionItems>
          <PermissionLabel>Configurar procesos</PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanConfigureProcesses}
            onChange={(e) =>
              handleUpdatePermissions("CanConfigureProcesses", e.target.checked)
            }
          />
        </PermissionItems>
        <PermissionItems>
          <PermissionLabel>
            Editar miembros e información de la empresa
          </PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanEditTeamAndMembers}
            onChange={(e) =>
              handleUpdatePermissions("CanEditTeamAndMembers", e.target.checked)
            }
          />
        </PermissionItems>
        <hr style={{ width: "100%", border: ".5px solid #edecec" }} />
        <RoleTitle style={{ fontSize: "14px", marginBottom: "5px" }}>
          Dashboards
        </RoleTitle>
        <PermissionItems>
          <PermissionLabel>Poder crear dashboards</PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanAddDashBoards}
            onChange={(e) =>
              handleUpdatePermissions("CanAddDashBoards", e.target.checked)
            }
          />
        </PermissionItems>
        <hr style={{ width: "100%", border: ".5px solid #edecec" }} />
        <RoleTitle style={{ fontSize: "14px", marginBottom: "5px" }}>
          Bases de datos
        </RoleTitle>
        <PermissionItems>
          <PermissionLabel>
            Editar información de bases de datos
          </PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanEditDatabases}
            onChange={(e) =>
              handleUpdatePermissions("CanEditDatabases", e.target.checked)
            }
          />
        </PermissionItems>
        <PermissionItems>
          <PermissionLabel>Ver información de bases de datos</PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanViewDatabases}
            onChange={(e) =>
              handleUpdatePermissions("CanViewDatabases", e.target.checked)
            }
          />
        </PermissionItems>
        <PermissionItems>
          <PermissionLabel>
            Exportar e importar información de bases de datos
          </PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanExportDatabases}
            onChange={(e) =>
              handleUpdatePermissions("CanExportDatabases", e.target.checked)
            }
          />
        </PermissionItems>
        <hr style={{ width: "100%", border: ".5px solid #edecec" }} />
        <RoleTitle style={{ fontSize: "14px", marginBottom: "5px" }}>
          Reportes
        </RoleTitle>
        <PermissionItems>
          <PermissionLabel>
            Editar la información de todos los procesos del equipo
          </PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanEditAllReports}
            onChange={(e) =>
              handleUpdatePermissions("CanEditAllReports", e.target.checked)
            }
          />
        </PermissionItems>
        <PermissionItems>
          <PermissionLabel>
            Ver la información de todos los procesos del equipo
          </PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanViewAllReports}
            onChange={(e) =>
              handleUpdatePermissions("CanViewAllReports", e.target.checked)
            }
          />
        </PermissionItems>
        <PermissionItems>
          <PermissionLabel>
            Editar toda la información de los procesos donde participa
          </PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanEditHisReportsInfo}
            onChange={(e) =>
              handleUpdatePermissions("CanEditHisReportsInfo", e.target.checked)
            }
          />
        </PermissionItems>
        <PermissionItems>
          <PermissionLabel>
            Ver toda la información de los procesos donde participa
          </PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanViewHisReportsInfo}
            onChange={(e) =>
              handleUpdatePermissions("CanViewHisReportsInfo", e.target.checked)
            }
          />
        </PermissionItems>
        <PermissionItems>
          <PermissionLabel>
            Ver el flujo de los procesos donde participa
          </PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanViewHisReportsFlux}
            onChange={(e) =>
              handleUpdatePermissions("CanViewHisReportsFlux", e.target.checked)
            }
          />
        </PermissionItems>
        <PermissionItems>
          <PermissionLabel>Exportar datos</PermissionLabel>
          <Checkbox
            style={{
              color: "#727e8b",
              margin: 0,
            }}
            disabled={!canEdit || updating || isLeader}
            checked={permissions.CanExportReportData}
            onChange={(e) =>
              handleUpdatePermissions("CanExportReportData", e.target.checked)
            }
          />
        </PermissionItems>
      </PermissionContainer>
    </div>
  );
};

export default PermissionsPopover;
