import React, { useRef } from "react";
import { Row } from "antd";
import { noop } from "lodash";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import { AiOutlineDatabase } from "react-icons/ai";
import { FieldItemProps } from "./FieldItem.d";
import { FieldContainer, VarDBLabel } from "./styles";
import {
  CopyIcon,
  DeleteIcon,
  RemoveIcon,
} from "../../../../components/hoverIcons";
import { StyledSwitch } from "../styles";
import { FieldTypes } from "../../Configurator.d";
import { useElementOverflow } from "../../../../hooks";
import { getDataTypeIcon } from "../../helpers";
import Tooltip from "../../../../components/Tooltip";

const disableDuplicate = true;

const FieldItem: React.FC<FieldItemProps> = ({
  column,
  field,
  isMandatory = false,
  onClick = noop,
  onDuplicate = noop,
  onRemove = noop,
  onChangeIsMandatory = noop,
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const isOverflow = useElementOverflow(textRef, "width");

  const showCopyAndDelete =
    column === "global" && field.Type !== FieldTypes.GHOST;
  const showRemove = column === "consult" || column === "editable";
  const showIsMandatory = column === "editable";
  const vardbGroupInfo =
    field.Type === FieldTypes.CELL ? field.VardbGroupInfo : undefined;
  const isGhostField = field.Type === FieldTypes.GHOST;

  const onClickField: React.MouseEventHandler<HTMLDivElement> = () =>
    onClick(field);
  const onClickAction = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    action: "duplicate" | "remove"
  ) => {
    e.stopPropagation();
    switch (action) {
      case "duplicate":
        onDuplicate(field);
        break;
      case "remove":
        onRemove(field);
        break;
    }
  };
  const handleOnChangeIsMandatory: SwitchChangeEventHandler = (value, e) => {
    e.stopPropagation();
    onChangeIsMandatory(value);
  };

  const DataTypeIcon = getDataTypeIcon(field.DataType);

  const color = vardbGroupInfo?.Color || field?.PdfInfo?.Color || field?.Color;

  const tooltipTitle =
    field.DataType === "checklist"
      ? `Marcar como requerido. En campos checklist 
        esta opción bloqueará la edición y eliminación
        de las listas de este campo`
      : "Marcar como requerido";

  return (
    <FieldContainer
      varDBColor={color}
      isGhostField={isGhostField}
      onClick={onClickField}
      varDB={!!vardbGroupInfo}
    >
      <Row justify="space-between" align="middle">
        <Row align="middle" style={{ flex: 1 }}>
          <DataTypeIcon size={20} style={{ marginRight: "8px" }} />
          <div
            style={{
              lineHeight: "16px",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            {isOverflow ? (
              <Tooltip title={field.Label} placement="top">
                <div
                  ref={textRef}
                  style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {field.Label}
                </div>
              </Tooltip>
            ) : (
              <div
                ref={textRef}
                style={{
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {field.Label}
              </div>
            )}
            {vardbGroupInfo && (
              <VarDBLabel>
                <AiOutlineDatabase size={8} style={{ marginRight: "3px" }} />
                {vardbGroupInfo.Label}
              </VarDBLabel>
            )}
          </div>
        </Row>
        <Row align="middle">
          <Row align="middle" className="actions">
            {showCopyAndDelete && (
              <>
                {!disableDuplicate && (
                  <CopyIcon onClick={(e) => onClickAction(e, "duplicate")} />
                )}
                {field.DataType !== "signature" && (
                  <DeleteIcon
                    filled
                    onClick={(e) => onClickAction(e, "remove")}
                  />
                )}
              </>
            )}
            {showRemove && (
              <RemoveIcon onClick={(e) => onClickAction(e, "remove")} />
            )}
          </Row>
          {showIsMandatory && (
            <Tooltip title={tooltipTitle} placement="top">
              <StyledSwitch
                checked={isMandatory}
                onChange={handleOnChangeIsMandatory}
                style={{ marginLeft: "5px" }}
              />
            </Tooltip>
          )}
        </Row>
      </Row>
    </FieldContainer>
  );
};

export default FieldItem;
