import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../../../GeestUI";
import { useMutation } from "../../../hooks";
import PDFIcon from "../../hoverIcons/PDFIcon";
import { DeleteIcon } from "../../hoverIcons";
import DownloadIcon from "../../hoverIcons/DownloadIcon";
import FileCarousel from "./helpers/FileCarousel";
import { UploadFile } from "antd";
import { noop } from "lodash";

interface PDFAutoGeneratedProps {
  value: string;
  onChange: (newValue: string) => void;
  IdProcessExecution?: number;
  IdField?: number;
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  funcBeforePdf?: (generate: () => void) => void;
  needsCatchEsc?: boolean;
  isFileOpen?: boolean;
  setIsFileOpen?: (isOpen: boolean) => void;
  extraParams?: {
    mb?: string;
  };
}

const InputContainer = styled.div<{ isConsult: boolean; mb?: string }>`
  display: flex;
  flex-direction: column;
  gap: 6px;

  ${({ isConsult, mb = "10px" }) => (isConsult ? "" : `margin-bottom: ${mb};`)}
  width: 100%;
  * {
    margin: 0;
    padding: 0;
  }
`;

const GeneratedPDF = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  background-color: #edecec;
  border-radius: 6px;
  padding: 7.5px 12px;
  color: #0273e9;
  font-family: Gotham-Book;
`;

const PDFName = styled.p`
  margin: 0;
  cursor: pointer;
  color: #0273e9 !important;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const PDFAutoGenerated: React.FC<PDFAutoGeneratedProps> = ({
  value,
  onChange,
  IdProcessExecution,
  IdField,
  isConsult,
  disabled,
  required,
  funcBeforePdf,
  needsCatchEsc,
  isFileOpen,
  setIsFileOpen = noop,
  extraParams = { mb: "10px" },
}) => {
  const [fileToOpen, setFileToOpen] = useState<string>("");

  const setOpenFile = (file: string) => {
    setFileToOpen(file);
    setIsFileOpen(Boolean(file));
  };

  const [generatePDF, generatingPDF] = useMutation<any>({
    func: "Ver2-Processes-gp",
    onSuccess: (filePath) => onChange(filePath),
  });

  const onGeneratePdf = () => {
    generatePDF({
      args: {
        IdFieldTemplate: IdField,
        IdProcessExecution,
      },
    });
  };

  const handleRemovePDF = () => {
    onChange("");
  };

  const handleDownload = () => {
    fetch(value)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(new Blob([blob]));
        const aTag = document.createElement("a");
        aTag.href = blobUrl;
        aTag.setAttribute("download", "PDF Generado.pdf");
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  const handleGeneratePDF = () => {
    if (funcBeforePdf) {
      funcBeforePdf(onGeneratePdf);
    } else {
      onGeneratePdf();
    }
  };

  return (
    <InputContainer isConsult={isConsult} mb={extraParams.mb}>
      <Button
        type="secondary"
        size="fullwidth"
        danger={required && !Boolean(value)}
        onClick={handleGeneratePDF}
        disabled={disabled || generatingPDF}
        // @ts-ignore
        Icon={PDFIcon}
      >
        <div style={{ marginLeft: "5px" }}>Generar PDF</div>
      </Button>

      {value && (
        <GeneratedPDF>
          <PDFName onClick={() => setOpenFile(value)}>PDF Generado</PDFName>
          <ButtonsWrapper>
            <DownloadIcon onClick={handleDownload} />
            {!disabled && <DeleteIcon filled onClick={handleRemovePDF} />}
          </ButtonsWrapper>
        </GeneratedPDF>
      )}

      {needsCatchEsc
        ? isFileOpen &&
          Boolean(fileToOpen) && (
            <FileCarousel
              fileToOpen={
                {
                  name: "PDF Generado.pdf",
                  uid: "0",
                  url: fileToOpen,
                } as UploadFile<any>
              }
              files={[{ name: "PDF Generado.pdf", uid: "0", url: fileToOpen }]}
              closeModal={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                setOpenFile("");
                setIsFileOpen(false);
              }}
              handleEsc={() => {
                setOpenFile("");
                setIsFileOpen(false);
              }}
            />
          )
        : Boolean(fileToOpen) && (
            <FileCarousel
              fileToOpen={
                {
                  name: "PDF Generado.pdf",
                  uid: "0",
                  url: fileToOpen,
                } as UploadFile<any>
              }
              files={[{ name: "PDF Generado.pdf", uid: "0", url: fileToOpen }]}
              closeModal={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                setOpenFile("");
                setIsFileOpen(false);
              }}
              handleEsc={() => {
                setOpenFile("");
                setIsFileOpen(false);
              }}
            />
          )}
    </InputContainer>
  );
};

export default PDFAutoGenerated;
