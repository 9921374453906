import React, { useEffect } from "react";
import { noop as NOOP } from "lodash";
import styled from "styled-components";
import DynamicInput from "../DynamicInput";
import { Typography } from "../../../GeestUI";
import { Languages } from "./Dictionary";
import { TooltipPlacement } from "antd/lib/tooltip";
import { FieldType } from "../../../views/Templates/StartTemplate/StartTemplate.d";
import DataTypeIcons from "../../DataTypeIcons";
import GeestSelect from "../../../GeestUI/GeestSelect";
import { AiOutlineDatabase } from "react-icons/ai";

const { H4, P } = Typography;

const { NewFolioLabel } = Languages["ESP"];

interface UserType {
  Email: string;
  IdUser: number;
  Initials: string;
  LastName: string;
  FirstName: string;
  ProfilePicture?: string;
}

interface VarDBDataOrigin {
  IdRow: number;
  Label: string | null;
}

interface VarDBRowInputProps {
  value: number | null;
  userSelectDataOrigin?: UserType[];
  VarDBTitle?: string;
  Cells?: FieldType[];
  VarDBDataOrigin?: VarDBDataOrigin[];
  VarDBRowShowSelectInput?: boolean;
  KeyLabel?: string | null;
  isConsult: boolean;
  disabled: boolean;
  onChangeRow?: (value: any) => void;
  onChangeCells?: (value: FieldType[]) => void;
  onAddOptionFromCell?: (
    option: string,
    id: number | null,
    DataOrigin: any,
    indexCell: number
  ) => void;
  needsCatchEsc?: boolean;
  isFileOpen?: boolean;
  RowSelectedLabel?: number | string;
  setIsFileOpen?: (isOpen: boolean) => void;
  extraParams?: {
    showFormat?: boolean;
    center?: boolean;
    FilesPath?: string;
    canAddNewRows?: boolean;
    CanUseExistingRows?: boolean;
    oneLineString?: boolean;
    tooltipOffset?: number;
    tooltipPlacement?: TooltipPlacement;
    showNumberLabel?: boolean;
    FilterDataOrigin?: number | null;
    mb?: string;
  };
}

const VarDBRowContainer = styled.div`
  border: 2px solid #cbeefd;
  border-radius: 6px;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const VarDBTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 0;
  padding: 6px 12px;
  background: #cbeefd;
  border-radius: 6px 6px 0 0;
  color: #48505e;
`;

const VarDBTitleContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px 8px 6px;
`;

const VarDBRowInput: React.FC<VarDBRowInputProps> = ({
  value = null,
  userSelectDataOrigin = [],
  VarDBTitle,
  Cells = [],
  VarDBDataOrigin = [],
  VarDBRowShowSelectInput = false,
  KeyLabel = null,
  isConsult,
  disabled,
  onChangeRow = NOOP,
  onChangeCells = NOOP,
  onAddOptionFromCell = NOOP,
  needsCatchEsc = false,
  isFileOpen = false,
  setIsFileOpen = NOOP,
  RowSelectedLabel,
  extraParams = { center: false, mb: "10px" },
}) => {
  const transformedOptions = (() => {
    let options: any[] = [];
    VarDBDataOrigin.forEach(({ IdRow, Label = "" }) => {
      if (
        IdRow === 0 &&
        !extraParams.canAddNewRows &&
        !extraParams.FilterDataOrigin
      ) {
        return;
      }
      options.push({
        value: IdRow,
        searchableValues: `${Label}`,
        label: Label,
      });
    });
    return options;
  })();

  useEffect(() => {
    if (String(value) === "") {
      if (extraParams.canAddNewRows && !disabled) {
        onChangeRow(0);
      } else {
        onChangeRow(VarDBDataOrigin[0]?.IdRow);
      }
    }
    //eslint-disable-next-line
  }, [value, VarDBDataOrigin]);

  const onChangeVarDBCell = (val: any, index: number) => {
    let CellsAux = [...Cells];
    CellsAux[index].Value = val;
    onChangeCells(CellsAux);
  };

  const isDisabled = () => {
    if (!!extraParams.FilterDataOrigin) {
      if (!extraParams.canAddNewRows) {
        return (
          ((value === null || value === 0) &&
            !VarDBDataOrigin.find(({ IdRow }) => IdRow === 0)) ||
          disabled
        );
      }
      return disabled;
    }
    if (!extraParams.canAddNewRows && extraParams.CanUseExistingRows) {
      return value === null || value === 0;
    }
    return disabled;
  };

  return (
    <VarDBRowContainer>
      <VarDBTitleContainer>
        <VarDBTitleContent>
          <AiOutlineDatabase size={14} />
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {VarDBTitle}
          </div>
        </VarDBTitleContent>
        {`Folio: ${RowSelectedLabel ? RowSelectedLabel : NewFolioLabel}`}
      </VarDBTitleContainer>
      <InputsContainer>
        {VarDBRowShowSelectInput && (
          <div style={{ marginBottom: "6px" }}>
            <H4 mb={KeyLabel ? "0.5rem" : "0"}>{KeyLabel}</H4>
            <GeestSelect
              value={value || 0}
              disabled={disabled || isConsult}
              options={transformedOptions}
              onChange={onChangeRow}
              valueNecesary
            />
          </div>
        )}
        {Cells.map((field, index) => {
          let format = (field as FieldType).Format || "";

          const onChangeFormat = (newFormat: any) => {
            format = `${newFormat}`;
            const auxCells = [...Cells];
            (auxCells[index] as FieldType).Format = format;
            onChangeCells(auxCells);
          };

          return (
            <div key={field.IdField}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {DataTypeIcons[field.DataType] && (
                  <img
                    src={DataTypeIcons[field.DataType].icon}
                    alt=""
                    style={{
                      width: "10px",
                      height: "10px",
                      ...DataTypeIcons[field.DataType]?.extraStyles,
                    }}
                  />
                )}
                <P mb="0" style={{ fontFamily: "Gotham-Bold" }}>
                  {field.Label}
                </P>
              </div>
              <DynamicInput
                value={(field as FieldType).Value || undefined}
                type={field.DataType}
                dataOrigin={field.DataOrigin}
                format={format}
                configuration={(field as FieldType).Configuration || ""}
                isConsult={isConsult}
                disabled={isDisabled()}
                required={field.IsMandatory}
                userSelectDataOrigin={userSelectDataOrigin}
                onChange={(val) => onChangeVarDBCell(val, index)}
                onChangeFormat={onChangeFormat}
                fieldName={field.Label}
                onAddOption={
                  onAddOptionFromCell
                    ? (option: string) =>
                        onAddOptionFromCell(
                          option,
                          field.IdVarDBColumn || null,
                          field.DataOrigin,
                          index
                        )
                    : NOOP
                }
                checklistParams={{
                  elements: (field as FieldType).Elements,
                  onChangeChecklist: NOOP,
                }}
                needsCatchEsc={needsCatchEsc}
                isFileOpen={isFileOpen}
                setIsFileOpen={setIsFileOpen}
                extraParams={extraParams}
              />
            </div>
          );
        })}
      </InputsContainer>
    </VarDBRowContainer>
  );
};

export default VarDBRowInput;
