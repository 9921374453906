export const Languages = {
  ENG: {
    By: "By",
    To: "To",
    ReplaceDescription: "Write the new user's email:",
    EmailLabel: "Email",
    FirstNameLabel: "Name",
    LastNameLabel: "Last name",
    PasswordLabel: "Password",
    ValidatePasswordLabel: "Confirm password",
    SuccessOnRegister: "Success on register new user",
    SuccessOnReplace: "Success on replace user",
    UserLabel: "Select user to reasign tasks",
    SelectionLabel: "Select",
    SuccessOnReasign: "Success on reasign tasks",
    ExpellUserLabel: "Removing the member will remove the member permanently",
    SuccessExpellingUser: "Successon expelling member",
    MessageLabel:
      "When replacing a user, the old user will be removed, the new user join to the team and ALL their pending tasks or process assignments will be assigned to the new one",
    CancelLabel: "Cancel",
    SaveLabel: "Save",
    ErrorMessage: "Email is empty",
    ErrorNewEmailEmpty: "Email is not valid",
    ErrorNewEmailInTeam: "A user with that email is already in the team",
  },
  ESP: {
    By: "por",
    To: "a",
    ReplaceDescription: "Ingresa el correo electrónico del nuevo usuario",
    EmailLabel: "Correo electrónico...",
    FirstNameLabel: "Nombre(s)...",
    LastNameLabel: "Apellido(s)...",
    PasswordLabel: "Contraseña...",
    ValidatePasswordLabel: "Repetir contraseña...",
    SuccessOnRegister: "Usuario registrado con éxito",
    SuccessOnReplace: "Usuario remplazado con éxito",
    UserLabel: "Seleccione usuario al que se le reasignarán las tareas",
    SelectionLabel: "Seleccionar",
    SuccessOnReasign: "Tareas reasignadas con éxito",
    ExpellUserLabel:
      "Al eliminar al integrante, este se eliminará permanentemente",
    SuccessExpellingUser: "Integrante eliminado con éxito",
    MessageLabel:
      "Al reemplazar un usuario, ese usuario anterior se eliminará, se meterá al usuario nuevo a la empresa y TODAS las tareas pendientes o asignaciones en procesos se transferirán del usuario anterior al nuevo",
    CancelLabel: "Cancelar",
    SaveLabel: "Guardar",
    ErrorMessage: "Email está vacío",
    ErrorNewEmailEmpty: "El correo no es válido",
    ErrorNewEmailInTeam: "El usuario ya es parte de la empresa",
  },
};
