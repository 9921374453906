import { Row } from "antd";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Tag } from "../../../GeestUI";

export const Container = styled.div`
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  border-radius: 6px 6px 0px 0px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  font-family: "Gotham-Bold";
  font-size: 16px;
  color: #525151;
`;

export const TaskTitle = styled.p`
  margin: 0;
  padding: 12px 12px 6px;
`;

export const CloseContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14.5px;
`;

export const CloseIcon = styled(IoClose)`
  color: #48505e;
  cursor: pointer;
`;

export const TaskMetaDataContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TaskMetaData = styled.div`
  padding: 0 12px 12px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 5px;
`;

export const TaskMetaDataRow = styled.div<{ mb?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: ${({ mb = "0px" }) => mb};
  p {
    margin: 0;
    color: #48505e;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const EditIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  :hover {
    background: #d9d9d9;
  }
`;

export const ProcessNameInput = styled.input`
  border: 1.5px solid #c0c9d3;
  border-radius: 5px;
  color: #48505e;
  width: 100%;
  min-height: 30px;
  resize: none;
  padding: 0 5px;
  transition: all 0.35s ease;
  :hover {
    border-color: #2684ff;
    box-shadow: none;
  }
  :focus {
    border-color: var(--navy-blue) !important;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const PriorityData = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NoPriorityTag = styled(Tag)`
  margin-right: 0;
  border-radius: 6px;
  border: 1px solid #828d9e;
  color: #828d9e;
`;

export const UserListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 5px;
`;

export const UsersListSelected = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
  min-height: 30px;
`;

export const EditorsPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 12px 12px;
`;

export const EditorsPopoverTitle = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 12px 0;

  p {
    margin: 0;
    font-family: "Gotham-Bold";
    font-size: 16px;
    color: #48505e;
  }
`;

export const EditorsPopoverContent = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap = "10px" }) => gap};

  p {
    margin: 0;
    font-size: 14px;
    color: #48505e;
  }
`;

export const UsersSelected = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  min-height: 25px;
`;

export const UserContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const UserImageContainer = styled.div<{ $index: number }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: ${({ $index }) => ($index > 0 ? "-1.5rem" : "")};
`;

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const UserCount = styled.div`
  height: 18px;
  width: 18px;
  margin-left: -1rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(219, 35, 44, 1);
  z-index: 99999;
  margin-top: -5px;
  font-size: 10px;
  font-family: Gotham-Bold;
`;

export const TimeRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TimeIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  p {
    margin: 0;
  }
`;

export const TaskDataContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 17px;
  flex-grow: 1;
  padding: 0px 12px 15px;
  overflow: auto;
`;

export const TaskInformation = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 5px;
  }
  :hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(130, 141, 158, 1);
    }
    ::-webkit-scrollbar-track {
      background: #edecec;
      border-radius: 20px;
    }
  }
`;

export const IncomeInformation = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  background-color: #fff5d8;
  border-radius: 6px;
  padding: 8px;

  p {
    margin: 0;
    font-size: 12px;
    font-family: "Gotham-Bold";
    color: #48505e;
  }
`;

export const TaskDescription = styled.div`
  background: #f6f5f5;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 8px;

  p {
    font-size: 14px;
    color: #48505e;
    margin: 0;
  }
  .task-title {
    font-family: "Gotham-Bold";
  }
`;

export const ReactMarkdownStyled = styled(ReactMarkdown)`
  max-width: 100%;
  * {
    overflow-wrap: break-word;
  }
`;

export const TaskFieldsContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
`;

export const TaskFieldsList = styled.div`
  background: #f6f5f5;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
  height: 100%;
  ::-webkit-scrollbar {
    width: 5px;
  }
  :hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(130, 141, 158, 1);
    }
  }

  p {
    font-size: 14px;
    color: #48505e;
    margin: 0;
  }
  .task-title {
    font-family: "Gotham-Bold";
    padding: 6px 11px;
  }
`;

export const FieldListContainer = styled.div`
  padding: 0 11px 11px;
  color: #48505e;
`;

export const TaskInfoContainer = styled.div<{ isOpen: boolean }>`
  background: #f6f5f5;
  border-radius: 6px;
  padding: ${({ isOpen }) => (isOpen ? "0 11px" : "6px 11px")};
  color: #48505e;

  p {
    font-size: 14px;
    color: #48505e;
    margin: 0;
  }
  p.info-title {
    padding: ${({ isOpen }) => (isOpen ? "6px 0" : "0")};
    cursor: pointer;
    font-family: "Gotham-Bold";
    display: flex;
    align-items: center;
    gap: 3px;
  }
`;

export const TaskInfoContent = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  padding-left: 18px;
  padding-bottom: 11px;

  .col {
    width: calc(50% - 10px);
    display: flex;
    flex-direction: column;
  }
  p {
    font-size: 14px;
    color: #48505e;
    margin: 0;
  }
  .col-title {
    font-family: "Gotham-Bold";
  }
`;

export const TaskActivityContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  padding-bottom: 11px;
`;

export const TaskActivity = styled.div<{ isReminder: boolean }>`
  background: ${({ isReminder }) => (isReminder ? "#d9d9d9" : "transparent")};
  border-radius: 6px;
  padding: 6px 11px;
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const TaskActivityData = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    color: #48505e;
    margin: 0;
  }

  .userAndTime {
    display: flex;
    align-items: center;
    gap: 5px;
    p.name {
      font-family: "Gotham-Bold";
    }
    p.time {
      font-size: 12px;
      margin-top: 2px;
    }
  }
`;

export const ReminderBox = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;

  .notification-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  p span {
    font-style: italic;
  }
`;

export const RedDot = styled.div`
  position: absolute;
  top: 2px;
  right: 1px;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #db232c;
`;

export const StageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  p {
    font-size: 14px;
    color: #48505e;
    margin: 0;
  }
`;

export const VarDBRowItem = styled.div`
  p {
    font-size: 14px;
    color: #48505e;
    margin: 0;
  }
  .title {
    font-family: "Gotham-Bold";
  }
  .varDBRow-Item {
    word-wrap: break-word;
  }
`;

export const TaskActions = styled.div`
  height: 100%;
  width: 175px;
  min-width: 175px;
  display: flex;
  flex-direction: column;

  .actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .bottom-actions {
    justify-content: end;
    flex-grow: 1;
  }
`;

export const ActionButton = styled.button<{
  $border?: boolean;
  $solid?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 3px 0;
  font-family: "Gotham-Bold";
  font-size: 14px;

  cursor: pointer;
  border-radius: 6px;
  border: 2px solid ${({ $border = false }) => ($border ? "#0273e9" : "white")};
  background: ${({ $solid = false }) => ($solid ? "#0273e9" : "white")};
  color: ${({ $solid = false }) => ($solid ? "white" : "#0273e9")};

  transition: background 0.35s ease, border-color 0.35s ease;
  :hover {
    border: 2px solid
      ${({ $border = false }) => ($border ? "#014c9b" : "white")};
    background: ${({ $solid = false }) => ($solid ? "#014c9b" : "#edecec")};
  }
  :focus {
    border: 2px solid
      ${({ $border = false }) => ($border ? "#1939b7" : "white")};
  }
`;

export const CheckmarkContainer = styled(Row)`
  position: absolute;
  top: -4px;
  right: -10px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: rgba(57, 165, 47, 0.7);

  transition: background 0.35s ease;
  :hover {
    background: rgb(57, 165, 47);
  }
`;

export const UserInitials = styled(Row)<{ $isRejected?: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: ${({ $isRejected = false }) =>
    $isRejected ? "2px solid #db232c" : "none"};
  background: #014c9b;
  font-size: 10px;
  color: #ffffff;
`;

export const UserProfilePic = styled(Row)<{
  image: string;
  $isRejected?: boolean;
}>`
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border: ${({ $isRejected = false }) =>
    $isRejected ? "2px solid #db232c" : "none"};
  border-radius: 50%;
  background-image: ${({ image }) => `url("${image}")`};
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  font-weight: 325;
  font-size: 15px;
  color: #ffffff;
`;
