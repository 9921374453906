import styled from "styled-components";
import searchIMG from "../../resources/img/SEARCH_TASKS.svg";
import { DatePicker } from "@material-ui/pickers";

export const ViewTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Gotham-Bold;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  border-bottom: 2px solid #edecec;
  font-size: 24px;
  margin-bottom: 27px;
  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  max-height: 100%;
  flex: 1;
`;

export const LeftBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 10px;
  border-right: 1px solid #edecec;
  max-height: 100%;
  overflow: auto;
  width: 222px;
`;

export const StateGroup = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 14px;
  cursor: pointer;
  .bar {
    height: 100%;
    width: 4px;
    border-radius: 2px;
    background-color: ${({ $selected }) =>
      $selected ? "#0273e9" : "transparent"};
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StateTitle = styled.div`
  display: flex;
  flex: 1;
  min-height: 40px;
  font-size: 14px;
  color: #48505e;
  font-family: Gotham-Bold;
  flex-direction: row;
  align-items: center;
`;

export const StatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const State = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 29px;
  color: ${({ $selected }) => ($selected ? "#0273e9" : "#48505e")};
  font-family: ${({ $selected }) =>
    $selected ? "Gotham-Bold" : "Gotham-Book"};
  font-size: 12px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #edecec;
`;

export const SearchBar = styled.input`
  background-image: url(${searchIMG});
  background-repeat: no-repeat;
  background-size: 14px;
  background-position-y: center;
  background-position-x: 6px;
  background-color: #ffffff;
  padding: 3px 5px;
  padding-left: 25px;
  width: 389px;
  height: 32px;
  font-family: Gotham-Book;

  border-radius: 6px;
  outline: none;
  border: 2px solid #edecec;
  font-size: 14px;
  color: #828d9e;

  ::placeholder {
    color: #828d9e;
  }

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const StyledInput = styled.input`
  border: 2px solid #edecec;
  color: #828d9e;
  border-radius: 6px;
  padding-left: 6px;
  width: 396px;
  height: 32px;
  font-size: 14px;
  font-weight: 300;
  font-family: "Gotham-Book";
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
`;

export const StateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  max-heigh: 100%;
  max-width: calc(100% - 234px);
  overflow: auto;
`;

export const StateHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0px;
  padding-bottom: 40px;
  overflow-y: hidden;
  min-width: calc(100% - 10px);
  width: fit-content;
  padding-right: 10px;
`;

export const TableHeader = styled.div`
  background-color: #f2f8fe;
  display: flex;
  flex-direction: row;
  gap: 0px;
  color: #48505e;
  font-family: Gotham-Bold;
  height: 40px;
`;

export const TableCell = styled.div<{ $width: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  width: ${({ $width }) => $width}px;
  overflow: hidden;
  text-overflow: ellipis;
  white-space: nowrap;
  min-width: ${({ $width }) => $width}px;
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  font-family: Gotham-Book;
  height: 40px;
  border-bottom: 1px solid #828e9e;
  font-size: 14px;
  color: #48505e;
`;

export const PermissionsTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: #48505e;
  font-family: Gotham-Bold;
  width: 100%;
`;

export const PermissionsConainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 12px;
  width: 100%;
`;

export const PermissionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 4px;
`;

export const PermissionGroupTitle = styled.div`
  font-size: 14px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

export const Permission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #828d9e;
  font-size: 14px;
  font-family: Gotham-Book;
  justify-content: space-between;
`;

export const PermissionsInGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const FreeTrialHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const TrialBadge = styled.div<{ $background: string; $color: string }>`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  padding: 0px 8px;
  height: 30px;
  background-color: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  font-size: 12px;
  font-family: Gotham-Bold;
  border-radius: 6px;
`;

export const TrialCount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  height: 18px;
  padding: 0px 4px;
`;

export const PasswordTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #48505e;
  font-family: Gotham-Bold;
  font-size: 16px;
`;

export const TinyEmail = styled.div`
  font-size: 10px;
  font-family: Gotham-Book;
  color: #828d9e;
`;

export const TrialStatusBadge = styled(TrialBadge)`
  height: 23px;
  font-family: Gotham-Book;
  gap: 2px;
  border: 2px solid ${({ $color }) => $color};
  width: 83px;
  padding: 0;
  display: flex;
  justify-content: center;
`;

export const EndDateBadge = styled.div<{ $color: string; $background: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  background-color: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  height: 25px;
  border-radius: 6px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px 12px;
  font-size: 14px;
  font-family: Gotham-Bold;
`;

export const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-betwen;
`;

export const ModalItem = styled.div`
  flex-direction: column;
  display: flex;
  gap: 0;
  flex: 1;
  p {
    font-family: Gotham-Book;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: no-wrap;
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  .Mui-disabled {
    cursor: auto;
  }
  .Mui-focused {
    border-color: #48505e !important;
  }
  .MuiInput-formControl {
    :hover {
      border-color: #0273e9 !important;
      box-shadow: none !important;
    }
    :focus {
      border-color: #48505e !important;
      box-shadow: none !important;
    }
    :focus-visible {
      outline: none !important;
    }
  }
`;
