import React from "react";
import styled from "styled-components";
import { TriggerValueType } from "../DynamicCell.d";
import TriggerManualIcon from "../../../../../components/hoverIcons/TriggerManualIcon";
import TriggerFormIcon from "../../../../../components/hoverIcons/TriggerFormIcon";
import TriggerWebhookIcon from "../../../../../components/hoverIcons/TriggerWebhookIcon";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: #0273e9;
  border-radius: 2px;
  color: white;
`;

const TriggerDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const TriggerName = styled.div`
  font-size: 12px;
  color: #48505e;
`;

const TriggerType = styled.div`
  font-size: 10px;
  color: #828d9e;
`;

interface TriggerComponentProps {
  value: TriggerValueType | undefined | null;
}

const TriggerComponent: React.FC<TriggerComponentProps> = ({ value }) => {
  return (
    <Container>
      {value?.Type && (
        <IconContainer>
          {value.Type === "Manual" && <TriggerManualIcon />}
          {value.Type === "Form" && <TriggerFormIcon />}
          {value.Type === "Webhook" && <TriggerWebhookIcon />}
        </IconContainer>
      )}

      <TriggerDescription>
        <TriggerName>{value?.Label ?? ""}</TriggerName>
        <TriggerType>{value?.Type ?? ""}</TriggerType>
      </TriggerDescription>
    </Container>
  );
};

export default TriggerComponent;
