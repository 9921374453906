import React, { useEffect, useState } from "react";
import DynamicInput from "../../../../../../../components/DynamicInput";
import { FieldConfigWrapper, SubTitle } from "../../Styles";

interface CurrencyConfigProps {
  value: string;
  onChange: (newValue: string) => void;
  format: string;
  setFormat: (newFormat: string) => void;
  disabled: boolean;
}

const CurrencyConfig: React.FC<CurrencyConfigProps> = ({
  value,
  onChange,
  format,
  setFormat,
  disabled,
}) => {
  const [localValue, setLocalValue] = useState<string>(value);

  useEffect(() => {
    if (format === "") setFormat("MXN");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FieldConfigWrapper>
        <SubTitle>Valor por defecto</SubTitle>
        <DynamicInput
          value={localValue ?? ""}
          type="currency"
          dataOrigin={[]}
          format={format}
          configuration=""
          fieldName=""
          isConsult={false}
          disabled={disabled}
          required={false}
          onChange={setLocalValue}
          onChangeFormat={setFormat}
          onBlur={() => onChange(localValue)}
        />
      </FieldConfigWrapper>
    </>
  );
};

export default CurrencyConfig;
