import React from "react";
import { Typography } from "../../../../GeestUI";
import { Row } from "antd";
import bellIcon from "../../../../resources/img/Ajustes_Notificaciones.png";
import { RedDot } from "../styles";

const { H2, P } = Typography;

const ReminderPopup: React.FC<{ Reminder: string }> = ({ Reminder }) => (
  <div onClick={(e) => e.stopPropagation()}>
    <H2>
      <Row justify="space-between" align="middle">
        Recordatorio
        <Row align="top">
          <img
            src={bellIcon}
            alt="bell"
            style={{ height: "15px", width: "15px" }}
          />
          <RedDot />
        </Row>
      </Row>
    </H2>
    {Reminder.split("\n").map((text, index) => (
      <P key={index} mb="0">
        {text}
      </P>
    ))}
  </div>
);

export default ReminderPopup;
