import React from "react";
import styled from "styled-components";
import { Row } from "antd";
import { useHistory } from "react-router-dom";
import GeestLogoSVG from "../../resources/img/GeestLogo.svg";

const urlGeestLogo = (IMG) => `url(${IMG})`;

const Languages = {
  ENG: {
    BackToApp: "Go to app",
  },
  ESP: {
    BackToApp: "Volver a la app",
  },
};

const GeestLogo = styled.div`
  height: 45px;
  width: 105px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${urlGeestLogo(GeestLogoSVG)};
  cursor: pointer;
  margin: auto;
  margin-right: 1rem;
`;

const Container = styled(Row)`
  padding: 20px 1.5rem;
  position: sticky;
`;

const StyledButton = styled.button`
  background-color: #0273e9;
  color: #ffffff;
  border-radius: 6px;
  font-size: 14px;
  font-family: "Gotham-bold";
  border: none;
  height: fit-content;
  margin: auto;
  padding: 6px 14px;
  cursor: pointer;
  :hover {
    background-color: #014c9b;
  }
  :focus {
    background-color: #1939b7;
  }
  :disabled {
    background-color: ${({ $loading }) => ($loading ? "#0273e9" : "#edecec")};
    cursor: not-allowed;
  }
`;

const UserHeader = ({ lightVersion = false }) => {
  const { BackToApp } = Languages["ESP"];

  const history = useHistory();

  const handleOnClickLogo = () => {
    if (!lightVersion) {
      history.push("/");
    }
  };

  return (
    <Container justify="space-between" style={{ backgroundColor: "white" }}>
      <Row>
        <GeestLogo onClick={handleOnClickLogo} />
      </Row>
      {!lightVersion && (
        <Row>
          <StyledButton
            onClick={() => {
              history.push("/");
            }}
          >
            {BackToApp}
          </StyledButton>
        </Row>
      )}
    </Container>
  );
};

export default UserHeader;
