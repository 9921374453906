import React from "react";
import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import styled from "styled-components";
import { Row } from "antd";
import { noop as NOOP } from "lodash";
import { Languages } from "../Dictionary";
import moment from "moment";
import { moneyFormat } from "../../../../../Helpers";

const TitleText = styled.div`
  font-family: "Gotham-Light";
  font-size: larger;
  text-align: center;
  margin-top: -0.8rem;
  margin-bottom: 0.6rem;
`;

const MessageText = styled.div`
  font-family: "Gotham-Book";
  color: #525151;
  font-size: larger;
  text-align: center;
  margin-bottom: 0.8rem;
`;

const ReactiveSubscriptionModal = ({
  date,
  amount,
  setVisible,
  onConfirm,
  Loading,
}) => {
  const { Cancel, Accept, ActiveRenewal } = Languages["ESP"];

  return (
    <Modal visible onCancel={Loading ? NOOP : setVisible} width={400}>
      <TitleText>{ActiveRenewal}</TitleText>
      <MessageText>
        {`Tu próxima factura llegará el ${moment(date).format(
          "DD/MMM/YY"
        )} por ${moneyFormat(amount)} USD`}
      </MessageText>
      <Row justify="space-around">
        <Button
          type="default"
          style={{ width: "9rem" }}
          onClick={setVisible}
          loading={Loading}
        >
          {Cancel}
        </Button>
        <Button
          type="primary"
          style={{ width: "9rem" }}
          onClick={onConfirm}
          loading={Loading}
        >
          {Accept}
        </Button>
      </Row>
    </Modal>
  );
};

export default ReactiveSubscriptionModal;
