export const Languages = {
  ENG: {
    Greetings: "Greentings",
    CreateADashboard: "Create a new dashboard to check it out!",
    SelectATeam: "Select a team to view its dashboards!",
    Description:
      "Monitor and visually analyze the metrics and key data to track the status of your company, department, or specific process. Select the permissions that each team member will have and choose the types of chart templates you need to build dashboards according to your requirements.",

    // context options
    ChangeName: "Change name",
    Permissions: "Permissions",
    Delete: "Delete",
  },
  ESP: {
    Greetings: "Hola",
    CreateADashboard: "¡Crea un nuevo dashboard para poder acceder a el!",
    SelectATeam:
      "¡Selecciona una empresa para que puedas visualizar sus dashboards!",
    Description:
      "Monitoriza y analiza visualmente las métricas y datos fundamentales para hacer un seguimiento del estado de tu empresa, área o proceso específico. Selecciona los permisos que tendrá cada integrante de tu empresa y elige los tipos de plantillas de gráficas que necesites para armar dashboards según tus necesidades.",

    // context options
    ChangeName: "Cambiar nombre",
    Permissions: "Permisos",
    Delete: "Eliminar",
  },
};
