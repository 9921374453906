import styled from "styled-components";

export const Container = styled.div<{ $bgImage?: string }>`
  ${({ $bgImage }) => ($bgImage ? `background-image: url("${$bgImage}");` : "")}
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  position: relative;
  overflow: auto;

  font-family: "Gotham-Book";
  font-style: normal;
  font-size: 14px;
  color: #222;

  * {
    margin: 0;
  }
`;

export const CenteredItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AlertContainer = styled(CenteredItem)`
  background: rgb(255, 215, 144);
  background: linear-gradient(
    90deg,
    rgba(255, 215, 144, 1) 0%,
    rgba(255, 185, 56, 1) 75%,
    rgba(255, 165, 0, 1) 100%
  );
  position: absolute;
  width: 100%;
  padding: 10px;
  text-align: center;
`;

export const MainContent = styled.div`
  height: 100%;
  max-height: 100%;
  width: calc(100vw / 3 * 2);

  @media only screen and (max-width: 500px) {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  padding: 20px;

  @media only screen and (max-width: 600px) {
    padding: 15px 10px 0;
  }
`;

export const Img = styled.img`
  width: 100%;
  max-width: 10vw;
  height: auto;

  @media only screen and (max-width: 600px) {
    max-width: 20vw;
  }
`;

export const TitleText = styled.h1`
  font-family: "Gotham-Bold";

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const DescriptionText = styled.p`
  font-size: 16px;
`;

export const FieldsContainer = styled.div`
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const FieldsCard = styled.div<{ $fullWidth: boolean }>`
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  padding-bottom: 10px;
  max-height: 100%;
  overflow-y: auto;
  width: ${({ $fullWidth }) => ($fullWidth ? "calc(100% / 3 * 2)" : "550px")};
  max-width: 100%;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const FieldContainer = styled.div<{
  $fullWidth: boolean;
}>`
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "50%")};

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Field = styled.div`
  padding: 10px;
`;

export const SendContainer = styled.div<{ $fullWidth: boolean }>`
  width: ${({ $fullWidth }) => ($fullWidth ? "calc(100% / 3 * 2)" : "480px")};
  max-width: 100%;
`;

export const SendButton = styled.button`
  border: none;
  border-radius: 5px;
  background-color: #2684ff;
  text-align: center;
  padding: 5px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #5da3ff;
  }

  * {
    color: white;
  }
`;

export const FooterContainer = styled.div`
  padding: 20px;
  font-family: "Gotham-Bold";
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

export const FooterButton = styled.button`
  padding: 5px 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0.7px 0.7px 2.2px rgba(0, 0, 0, 0.02),
    1.7px 1.7px 5.3px rgba(0, 0, 0, 0.028),
    3.1px 3.1px 10px rgba(0, 0, 0, 0.035),
    5.6px 5.6px 17.9px rgba(0, 0, 0, 0.042),
    10.4px 10.4px 33.4px rgba(0, 0, 0, 0.05), 25px 25px 80px rgba(0, 0, 0, 0.07);
`;

export const SuccessfulContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`;

export const SuccesfulTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
`;

export const SuccesfulSubtitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Book;
  font-weight: 325;
`;
