import React, { useState, useContext, useEffect } from "react";
import { Row, Tag } from "antd";
import styled from "styled-components";
import Button from "../../../../components/Button";
import VISA from "./../../../../resources/img/visa.svg";
import MASTER_CARD from "./../../../../resources/img/mc.svg";
import AMERICAN_EXPRESS from "./../../../../resources/img/am.svg";
import { Languages } from "./Dictionary";
import { useParams } from "react-router";
import States from "../States";
import { StateMachineContext } from "..";
import { useMutation } from "../../../../hooks";

const Container = styled(Row)`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  width: 70%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: fit-content;
  overflow: auto;
`;

const Content = styled.div`
  flex: 1;
  padding: 2rem;
  color: #535151;
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

const ErrorText = styled.div`
  font-family: "Gotham-Bold";
  color: #e10600;
  font-size: 20px;
`;

const MainText = styled.div`
  font-family: "Gotham-Bold";
  color: #696969;
  font-size: 20px;
`;

const PaymentCard = styled(Row)`
  height: fit-content;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0.3rem 0.8rem;
  row-gap: 0px;
  box-shadow: 0px 3px 4px 2px #d7d7d7;
  background-color: white;
  border-radius: 0.4rem;
`;

const PaymentNotCompleted = () => {
  const BrandLogos = {
    visa: VISA,
    mastercard: MASTER_CARD,
    amex: AMERICAN_EXPRESS,
  };
  const [Card, setCard] = useState({});
  const [Date, setDate] = useState("");
  const [Amount, setAmount] = useState("");
  const [Plan, setPlan] = useState({});
  const { ErrorProcessingLabel, TriedLabel, DayLabel, RetryPaymentLabel } =
    Languages["ESP"];
  const { IdTeam } = useParams();
  const { setCurrentState } = useContext(StateMachineContext);

  const [changeSubscriptionState] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: (response) => {
      const { State } = response;
      setCurrentState(State);
    },
  });

  const onRetry = () => {
    changeSubscriptionState({
      args: {
        IdTeam,
        NextState: States.CHECKOUT,
      },
    });
  };

  const [errorPaymentNotCompletedGetInformation] = useMutation({
    func: "Ver2-Subscription-ErrorPaymentNotCompleted-gi",
    onSuccess: (response) => {
      const { SubscriptionError } = response;
      const {
        Amount,
        ApplicationTime,
        Title,
        Name,
        BillingMode,
        ...PaymentMethod
      } = SubscriptionError;
      setAmount(Amount);
      setDate(ApplicationTime);
      setCard(PaymentMethod);
      setPlan({ Title, Name, BillingMode });
    },
  });

  useEffect(() => {
    errorPaymentNotCompletedGetInformation({
      args: {
        IdTeam,
      },
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Row justify="center">
      <Container>
        <Content>
          <ErrorText>{ErrorProcessingLabel}</ErrorText>
          <Row align="middle">
            <MainText style={{ width: "100%" }}>
              {TriedLabel(Amount, Plan)}
            </MainText>
            <PaymentCard align="middle">
              {BrandLogos[Card?.Brand] ? (
                <img
                  style={{ height: "17px" }}
                  src={BrandLogos[Card?.Brand]}
                  alt={Card?.Brand}
                />
              ) : (
                <Tag color="cyan">{Card?.Brand}</Tag>
              )}
              <div style={{ marginLeft: "0.5rem" }}>
                <div style={{ fontSize: "14px" }}>
                  <b>{`•••• •••• •••• ${Card?.Last4}`}</b>
                </div>
                <div style={{ fontSize: "10px" }}>{`Exp: ${
                  Card?.ExpirationMonth
                }
                /${Card?.ExpirationYear && Card.ExpirationYear.slice(2)}`}</div>
              </div>
            </PaymentCard>
            <MainText>{DayLabel(Date)}</MainText>
          </Row>
          <Row justify="end" align="bottom" style={{ marginTop: "2rem" }}>
            <Button
              type="shadow"
              onClick={onRetry}
              style={{ padding: "0.5rem 2.5rem", marginBottom: "0.8rem" }}
            >
              {RetryPaymentLabel}
            </Button>
          </Row>
        </Content>
      </Container>
    </Row>
  );
};

export default PaymentNotCompleted;
