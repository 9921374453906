import _ from "lodash";
import { Position } from "reactflow";
import { Handler, HandlerId } from "../CustomNodes.d";

export const defaultHandlers: Handler[] = [
  {
    id: HandlerId.IN_TOP,
    type: "target",
    position: Position.Top,
    isConnectableStart: false,
  },
  {
    id: HandlerId.IN_RIGHT,
    type: "target",
    position: Position.Right,
    isConnectableStart: false,
  },
  {
    id: HandlerId.IN_BOTTOM,
    type: "target",
    position: Position.Bottom,
    isConnectableStart: false,
  },
  {
    id: HandlerId.IN_LEFT,
    type: "target",
    position: Position.Left,
    isConnectableStart: false,
  },
  {
    id: HandlerId.OUT_TOP,
    type: "source",
    position: Position.Top,
  },
  {
    id: HandlerId.OUT_RIGHT,
    type: "source",
    position: Position.Right,
  },
  {
    id: HandlerId.OUT_BOTTOM,
    type: "source",
    position: Position.Bottom,
  },
  {
    id: HandlerId.OUT_LEFT,
    type: "source",
    position: Position.Left,
  },
];

// Remove Source and Right handlers
export const conditionNodeDefaultHandlers = _.reject(
  defaultHandlers,
  (handler) => handler.type === "source" || handler.position === Position.Right
);

export const startNodeHandlers = _.filter(defaultHandlers, {
  type: "source",
  position: Position.Right,
});

export const endNodeHandlers = _.filter(defaultHandlers, {
  type: "target",
  position: Position.Left,
});
