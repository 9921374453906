import React, { useContext, useState } from "react";
import { Container, Content } from "./styles";
import { SesionContext } from "../../config/State";
import { ViewTitle } from "./styles";
import LeftBar from "./LeftBar";
import StateMachine from "./StateMachine";
import { useFetch } from "../../hooks";
import { Permissions } from "./Intranet.d";
import { useHistory } from "react-router-dom";
import Loading from "../../components/NewLoading/NewLoading";

const Intranet: React.FC = () => {
  const [sesion] = useContext<any>(SesionContext);
  const [state, setState] = useState<string>("");
  const history = useHistory();
  const { data, error, loading } = useFetch<Permissions>({
    func: "Ver2-Intranet-giup",
    onSuccess: (res) => {
      if (res.Permission_Lider) {
        setState("permissions");
      } else if (res.Permission_FreeTrials) {
        setState("freeTrials");
      } else if (res.Permission_Pwd) {
        setState("passwords");
      }
    },
  });

  if (error) {
    history.push("/home/pendings");
  }

  if (loading) {
    return <Loading width={200} />;
  }

  return (
    <Container>
      <ViewTitle>
        {`Hola, ${sesion.FirstName}`}
        {!!sesion.ProfilePicture && (
          <img alt="perfil" src={sesion.ProfilePicture} />
        )}
      </ViewTitle>
      <Content>
        <LeftBar state={state} setState={setState} permissions={data} />
        <StateMachine state={state} />
      </Content>
    </Container>
  );
};

export default Intranet;
