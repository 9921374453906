import React from "react";
import styled from "styled-components";
import _ from "lodash";
import {
  MX,
  US,
  EU,
  GB,
  JP,
  CH,
  GT,
  CO,
  BZ,
  CL,
  AR,
  VE,
  PA,
  CR,
} from "country-flag-icons/react/3x2";

export interface CurrencyType {
  code: string;
  symbol: string;
}

export const currencies: CurrencyType[] = [
  { code: "MXN", symbol: "$" },
  { code: "USD", symbol: "$" },
  { code: "GTQ", symbol: "Q" },
  { code: "EUR", symbol: "€" },
  { code: "GBP", symbol: "£" },
  { code: "JPY", symbol: "¥" },
  { code: "FR", symbol: "₣" },
  { code: "COP", symbol: "$" },
  { code: "BZD", symbol: "$" },
  { code: "CLP", symbol: "$" },
  { code: "ARS", symbol: "$" },
  { code: "VES", symbol: "Bs" },
  { code: "PAB", symbol: "B/. PAB" },
  { code: "CRC", symbol: "₡" },
];

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: #828d9e;
  font-weight: 325;
  font-size: 12px;
  margin: 0;

  p {
    color: #828d9e;
    font-weight: 325;
    font-size: 12px;
    margin: 0;
  }
`;

const ListElement = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #828d9e;

  p {
    color: #828d9e;
    font-weight: 325;
    font-size: 12px;
    margin: 0;
  }
`;

export const getSymbolByCode = (searchCode: string) => {
  const currency = _.find(currencies, { code: searchCode });
  return currency?.symbol ? currency.symbol : "";
};

export const getCountryFlag = (code: string, width: string) => {
  switch (code) {
    case "MXN":
      return (
        <FlagContainer>
          <MX style={{ width }} />
        </FlagContainer>
      );
    case "USD":
      return (
        <FlagContainer>
          <US style={{ width }} />
        </FlagContainer>
      );
    case "GTQ":
      return (
        <FlagContainer>
          <GT style={{ width }} />
        </FlagContainer>
      );
    case "EUR":
      return (
        <FlagContainer>
          <EU style={{ width }} />
        </FlagContainer>
      );
    case "GBP":
      return (
        <FlagContainer>
          <GB style={{ width }} />
        </FlagContainer>
      );
    case "JPY":
      return (
        <FlagContainer>
          <JP style={{ width }} />
        </FlagContainer>
      );
    case "FR":
      return (
        <FlagContainer>
          <CH style={{ width }} />
        </FlagContainer>
      );
    case "COP":
      return (
        <FlagContainer>
          <CO style={{ width }} />
        </FlagContainer>
      );
    case "BZD":
      return (
        <FlagContainer>
          <BZ style={{ width }} />
        </FlagContainer>
      );
    case "CLP":
      return (
        <FlagContainer>
          <CL style={{ width }} />
        </FlagContainer>
      );
    case "ARS":
      return (
        <FlagContainer>
          <AR style={{ width }} />
        </FlagContainer>
      );
    case "VES":
      return (
        <FlagContainer>
          <VE style={{ width }} />
        </FlagContainer>
      );
    case "PAB":
      return (
        <FlagContainer>
          <PA style={{ width }} />
        </FlagContainer>
      );
    case "CRC":
      return (
        <FlagContainer>
          <CR style={{ width }} />
        </FlagContainer>
      );
  }
};

export const getCurrencyFormatSearchableValues = (code: string) => {
  switch (code) {
    case "MXN":
      return `${code},$,peso,pesos,mexico,méxico`;

    case "USD":
      return `${code},$,dolar,dolares,dólares,dólar,dollar,dollars,usa`;

    case "GTQ":
      return `${code},Q,quetzal,quetzales,guatemala`;

    case "EUR":
      return `${code},€,euro,euros,europe`;

    case "GBP":
      return `${code},£,pound,sterling,libra,libras,united,kingdom,reino,unido`;

    case "JPY":
      return `${code},¥,yen,yenes,yens,japón,japon,japan,nihon,nippon`;

    case "FR":
      return `${code},₣,franc,francs,franco,francos,francia,french,française`;
    case "COP":
      return `${code},$,peso,pesos,colombia`;
    case "BZD":
      return `${code},$,dolar,dolares,dólares,dólar,dollar,dollars,usa,belice`;
    case "CLP":
      return `${code},$,peso,pesos,chile`;
    case "ARS":
      return `${code},$,peso,pesos,argentina`;
    case "VES":
      return `${code},Bs,bolivar,bolívar,bolivar soberano,bolívar soberano,venezuela`;
    case "PAB":
      return `${code},balboa,panama,panamá`;
    case "CRC":
      return `${code},₡,colon,colón,costa rica`;

    default:
      return "";
  }
};

export const transformedOptions = (() =>
  currencies.map((c) => {
    return {
      value: c.code,
      searchableValues: getCurrencyFormatSearchableValues(c.code),
      label: (
        <ListElement>
          {getCountryFlag(c.code || "", "14px")}
          <p>{c.symbol}</p>
          <p>{c.code}</p>
        </ListElement>
      ),
      asSelected: (
        <ListElement>
          {getCountryFlag(c.code || "", "25px")}
          <p>{c.symbol}</p>
        </ListElement>
      ),
    };
  }))();
