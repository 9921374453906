import React from "react";
import { Node } from "reactflow";
import Modal from "../../../../components/Modal";
import { CreateNodeBuffer, NodeData, NodeRequest } from "../Flow.d";
import RequestNodeName from "./RequestNodeName";
import RequestSubfluxConfig from "./RequestSubfluxConfig";
import { ProcessOption } from "./RequestSubfluxConfig.d";

interface CreateNodeModalProps {
  createNodeBuffer: CreateNodeBuffer;
  cleanNodeBuffer: () => void;
  onCreateNodeWithName: (node: Node<NodeData>) => void;
  onCreateSubfluxNode: (
    node: Node<NodeData>,
    idProcessTemplateToTrigger: number
  ) => void;
}

const CreateNodeModal: React.FC<CreateNodeModalProps> = ({
  createNodeBuffer,
  cleanNodeBuffer,
  onCreateNodeWithName,
  onCreateSubfluxNode,
}) => {
  const { type, node } = createNodeBuffer;

  const onSubmitNodeName = (label: string) => {
    onCreateNodeWithName({
      ...node,
      data: { ...node.data, label },
    });
    cleanNodeBuffer();
  };

  const onSubmitSubfluxConfig = ({
    Label,
    IdProcessTemplateToTrigger,
  }: ProcessOption) => {
    onCreateSubfluxNode(
      {
        ...node,
        data: { ...node.data, label: Label },
      },
      IdProcessTemplateToTrigger
    );
    cleanNodeBuffer();
  };
  return (
    <Modal
      title="Nombre del nodo"
      centered
      width={450}
      onCancel={cleanNodeBuffer}
    >
      {type === NodeRequest.NODE_NAME && (
        <RequestNodeName
          onCancel={cleanNodeBuffer}
          onSubmit={onSubmitNodeName}
        />
      )}
      {type === NodeRequest.SUBFLUX_CONFIG && (
        <RequestSubfluxConfig
          onCancel={cleanNodeBuffer}
          onSubmit={onSubmitSubfluxConfig}
        />
      )}
    </Modal>
  );
};

export default CreateNodeModal;
