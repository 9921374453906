import { Row, Progress, Popover } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { Languages } from "./../Dictionary";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { TeamOutlined } from "@ant-design/icons";
import Checked from "../../../../../resources/img/Checked.svg";
import UsersManagementModal from "./../Modals/UsersManagementModal";
import { FrontViewStates } from "../FrontViewStates";
import { getPlanColors } from "../../../helpers";

const Container = styled(Row)`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  overflow: hidden;
`;

const Content = styled.div`
  flex: 1;
  padding: 2rem;
  color: #535151;
  display: flex;
  flex-direction: column;
  height: 27rem;
  overflow: auto;
`;

const CardTitle = styled.div`
  font-family: "Gotham-Bold";
  color: ${({ $color }) => $color};
  font-size: 20px;
`;

const CardTitleCont = styled(Row)`
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.3rem;
`;

const UsersButton = styled(Row)`
  background: ${({ $disable }) => ($disable ? "#0867ad" : "#e0e0e0")};
  color: ${({ $disable }) => ($disable ? "white" : "#afafaf")};
  font-size: 18px;
  justify-content: space-between;
  align-items: center;
  font-family: "Gotham-Book";
  padding: 0.2rem 0.7rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  :hover {
    background: ${({ $disable }) => ($disable ? "#065288" : "#e0e0e0")};
  }
`;

const UsersIcon = styled(TeamOutlined)`
  font-size: 28px;
  color: ${({ $disable }) => ($disable ? "white" : "#afafaf")};
  margin-right: 0.7rem;
`;

const TitleCont = styled.div`
  font-family: "Gotham-Book";
  font-size: large;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
`;

const TextVal = styled.div`
  font-family: "Gotham-Light";
`;

const TextCont = styled.div`
  font-family: "Gotham-Book";
  font-size: large;
  margin-bottom: 1rem;
`;

const InfoContainer = styled.div`
  flex: 1;
`;

const ProgressStyle = styled(Progress)`
  margin-bottom: 1rem;
  .ant-progress-inner {
    border-radius: 0.5rem;
    background: none;
    border: solid 1px #4488c7;
  }
  .ant-progress-bg {
    border-radius: 0.1rem;
  }
`;

const RenderInfoType = ({ item: { Type, Label, Value, PlanLimitValue } }) => {
  const { Unlimited } = Languages["ESP"];

  switch (Type) {
    case "Bar":
      return (
        <>
          <TitleCont>
            <div>{Label}</div>
            <TextVal>{`${Value}/${PlanLimitValue}`}</TextVal>
          </TitleCont>
          <ProgressStyle
            showInfo={false}
            status="active"
            strokeColor={"linear-gradient(to right, #6fb124, #033f7a)"}
            strokeWidth={45}
            percent={(Value / PlanLimitValue) * 100}
          />
        </>
      );

    case "Bool":
      return (
        <Row align="middle">
          <TextCont>{`${Label} `}</TextCont>
          {Value ? (
            <img
              src={Checked}
              style={{
                heigh: "1.5rem",
                width: "1.5rem",
                marginLeft: "0.25rem",
                marginBottom: "1rem",
              }}
              alt="Sí"
            />
          ) : (
            <AiOutlineCloseCircle
              size="1rem"
              style={{
                marginBottom: "1rem",
                marginLeft: ".25rem",
              }}
            />
          )}
        </Row>
      );

    case "Value":
      return (
        <Row align="middle">
          <TextCont>{`${Label} - ${Unlimited}`}</TextCont>
        </Row>
      );
    default:
      return null;
  }
};

const PlanCard = ({
  planName,
  limits = [],
  usersManagement,
  usersMessage,
  date,
  planStatus,
  onChangeUsers,
}) => {
  const [Modal, setModal] = useState(null);
  const [ActivePopOver, setActivePopOver] = useState(false);
  const planColor = getPlanColors(planName).mainColor;
  const { CanBeEdited, PurchasedUsers } = usersManagement;
  const { Users, UsersMessageText, CancelledText } = Languages["ESP"];

  const disablePopover =
    ![FrontViewStates.BU_CANCELED].includes(planStatus) &&
    _.isEmpty(usersMessage);

  const Modals = {
    usersManagement: (
      <UsersManagementModal
        onCancel={() => setModal(null)}
        usersManagement={usersManagement}
        date={date}
        onChangeUsers={onChangeUsers}
      />
    ),
  };

  const ContentPopOver = () => (
    <div style={{ fontFamily: "Gotham-Book", color: "#535353" }}>
      {planStatus === FrontViewStates.BU_CANCELED ? (
        <>{CancelledText}</>
      ) : (
        <>
          {`${usersMessage.Text} ${UsersMessageText(date)} `}
          <b>{`${usersMessage.Bold}`}</b>
        </>
      )}
    </div>
  );

  const handleOnClick = () => {
    if (CanBeEdited) setModal("usersManagement");
  };

  const handleOnHover = (visible) => {
    if (!CanBeEdited) {
      if (date || planStatus === FrontViewStates.BU_CANCELED) {
        setActivePopOver(visible);
      }
    }
  };

  return (
    <Container>
      {Modals[Modal]}
      <Content>
        <InfoContainer>
          <CardTitleCont>
            <CardTitle $color={planColor}>{planName}</CardTitle>
            <Popover
              visible={ActivePopOver}
              trigger="hover"
              placement="bottom"
              content={ContentPopOver}
              onVisibleChange={(vis) => handleOnHover(vis && !disablePopover)}
              overlayStyle={{
                width: "22rem",
                borderRadius: ".5rem",
                padding: "0",
                overflow: "hidden",
                boxShadow: "0px 0px 30px #cfcdcd",
              }}
            >
              <UsersButton $disable={CanBeEdited} onClick={handleOnClick}>
                <UsersIcon $disable={CanBeEdited} />
                {`${PurchasedUsers} ${Users}`}
              </UsersButton>
            </Popover>
          </CardTitleCont>
          {limits.map((item, index) => (
            <RenderInfoType item={item} key={index} />
          ))}
        </InfoContainer>
      </Content>
    </Container>
  );
};

export default PlanCard;
