import styled, { css } from "styled-components";
import { Row } from "antd";

export const iconSize = 18;
const iconContainerHeight = iconSize + 6;

export const Toolbar = styled(Row)`
  background-color: #48505e;
  padding: 6px 16px;
  border-radius: 6px;
  color: white;
`;

export const Arrow = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  transform: rotate(45deg);
  background-color: #48505e;
  top: -3px;
  right: calc(50% - 4px);
  z-index: -1;
`;

export const IconContainer = styled.div<{ active?: boolean }>`
  cursor: pointer;
  border-radius: 2px;
  height: ${iconContainerHeight}px;
  padding: 3px;
  margin-right: 8px;
  :last-of-type {
    margin-right: 0;
  }
  :hover {
    background: #0273e9;
  }
  ${({ active = false }) =>
    active &&
    css`
      background: #0273e9;
    `}
`;
export const IconContainerDanger = styled(IconContainer)`
  :hover {
    background: #db232c;
  }
`;
