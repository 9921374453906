import React from "react";
import { FilterOption, User, Option } from "../../../Dashboards.d";
import FilterSelect from "./FilterSelect";
import FilterMultiselect from "./FilterMultiselect";
import FilterUsers from "./FilterUsers";

interface DynamicFilterByProps {
  editingFilter?: boolean;
  filterData: FilterOption | null;
  onChangeFilter: (filter: FilterOption) => void;
  TeamMembers: User[];
  dateOptions: Option[];
  resetValue?: boolean;
}

const DynamicFilterBy: React.FC<DynamicFilterByProps> = ({
  editingFilter = false,
  filterData,
  onChangeFilter,
  TeamMembers,
  dateOptions,
  resetValue,
}) => {
  switch (filterData?.DynamicInput) {
    case "select":
      return (
        <FilterSelect
          editingFilter={editingFilter}
          filterData={filterData}
          onChangeFilter={onChangeFilter}
          dateOptions={dateOptions}
        />
      );

    case "multi_select":
      return (
        <FilterMultiselect
          filterData={filterData}
          onChangeFilter={onChangeFilter}
          resetValue={resetValue}
        />
      );

    case "user_multi_select":
      return (
        <FilterUsers
          filterData={filterData}
          onChangeFilter={onChangeFilter}
          teamMembers={TeamMembers}
        />
      );

    default:
      return (
        <div>
          <p>no case: {filterData?.DynamicInput}</p>
        </div>
      );
  }
};

export default DynamicFilterBy;
