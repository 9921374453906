import styled from "styled-components";

export const Title = styled.h1`
  color: #48505e;
  margin: 0;
  font-family: "Gotham-bold";
  font-size: 16px;
`;

export const StyledLabel = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 23px;
  cursor: pointer;
  color: #828d9e;
  font-family: "Gotham-book";
  text-align: center;
  font-size: 14px;
  width: fit-content;
  :hover {
    color: #48505e;
  }
`;

export const Navigator = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 14px;
  span {
    color: #828d9e;
    font-family: "Gotham-book";
    margin-right: 30px;
  }
  a {
    color: #0273e9;
    font-family: "Gotham-bold";
    text-decoration: none;
  }
`;
