import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import { StringInput } from "./style";
import { Button } from "../../../../GeestUI";
import { Row } from "antd";
import { useMutation } from "../../../../hooks";
import { Languages } from "./Dictionary";

interface DeleteDashboardModalProps {
  onClose: () => void;
  dashboardName?: string;
  idDashboard?: string;
  reloadDashboard: () => void;
  handleBack: () => void;
}

const DeleteDashboardModal: React.FC<DeleteDashboardModalProps> = ({
  onClose,
  dashboardName,
  idDashboard,
  reloadDashboard,
  handleBack,
}) => {
  const [name, setName] = useState<string>("");
  const {
    ModalTitle,
    IrreversibleLabel,
    SureLabel,
    InputPlaceholder,
    CancelLabel,
    DeleteLabel,
  } = Languages["ESP"];

  const [deleteDashboard] = useMutation({
    func: "Ver2-DashBoards-dd",
    onSuccess: () => {
      onClose();
      handleBack();
      reloadDashboard();
    },
  });

  const onDeleteDashboard = () => {
    deleteDashboard({
      args: {
        IdDashBoard: idDashboard,
      },
    });
  };

  return (
    <Modal
      title={ModalTitle}
      onCancel={onClose}
      width="432px"
      contentStyles={{ textAlign: "center" }}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ width: "309px" }}>
          <p>
            <b style={{ color: "#48505e" }}>{SureLabel}</b>
          </p>
          <div>
            <p style={{ color: "#828d9e", margin: 0 }}>{IrreversibleLabel}</p>
            <p style={{ color: "#db232c" }}>{dashboardName}</p>
          </div>
        </div>
      </div>
      <StringInput
        value={name}
        onChange={({ target: { value } }) => setName(value)}
        placeholder={InputPlaceholder}
      />
      <Row justify="center" style={{ marginTop: "1rem" }}>
        <Button
          type="secondary"
          onClick={onClose}
          size="regular"
          style={{ marginRight: "0.5rem" }}
        >
          <div style={{ fontFamily: "Gotham-Bold" }}>{CancelLabel}</div>
        </Button>
        <Button
          type="primary"
          onClick={onDeleteDashboard}
          size="regular"
          danger
          disabled={name !== dashboardName}
        >
          <div style={{ fontFamily: "Gotham-Bold" }}>{DeleteLabel}</div>
        </Button>
      </Row>
    </Modal>
  );
};

export default DeleteDashboardModal;
