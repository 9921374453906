import React from "react";
import styled from "styled-components";
import { ReactComponent as OrderDownIconSVG } from "../../resources/img/OrderDownIcon.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface OrderDownIconProps {
  width?: string;
  height?: string;
}

const OrderDownIcon: React.FC<OrderDownIconProps> = ({
  width = "14px",
  height = "14px",
}) => {
  return (
    <IconWrapper>
      <OrderDownIconSVG width={width} height={height} />
    </IconWrapper>
  );
};

export default OrderDownIcon;
