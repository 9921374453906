import styled from "styled-components";

export const GeneralStepContainer = styled.div`
  display: flex;
  align-items: flex-start;

  height: 100%;
  max-height: 100%;
`;

export const LeftPannel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 0 20px;
  width: 50%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const RightPannel = styled.div`
  width: 50%;
  height: 100%;
  padding 0 20px 20px;
`;

export const StatsTablePreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  height: 100%;
  height: calc(100% - 42px);
  overflow: auto;
  gap: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Title = styled.div<{ $fontSize?: string }>`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  color: #48505e;
  font-family: Gotham-Bold;
  font-size: ${({ $fontSize = "14px" }) => $fontSize};
`;

export const StyledInput = styled.input`
  border-radius: 6px;
  border: 2px solid;
  border-color: #edecec;
  background-color: white;
  color: #828d9e;

  width: 236px;
  height: 32px;
  resize: none;
  padding-left: 6px;

  font-size: 14px;
  font-weight: 300;
  font-family: "Gotham-Book";

  transition: all 0.35s ease;
  ::placeholder {
    color: #d8dbdf;
  }
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-contet: center;
  gap: 12px;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-contet: center;
  gap: 8px;
  color: #48505e;

  p {
    font-size: 18px;
    font-weight: 325;
    font-family: Gotham-Book;
    margin: 0;
  }
`;

export const ConfigRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;

export const SelectTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SelectRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const FirstTimeWrapper = styled.div`
  flex: 1;
  border: 2px solid #edecec;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
