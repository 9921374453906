import React, { useEffect, useRef } from "react";
import _ from "lodash";
import {
  ButtonRow,
  Content,
  Header,
  InputContainer,
  StyledInput,
  StyledModal,
  Title,
} from "./Styles";
import { CloseIcon } from "../../../../../../components/hoverIcons";
import { Button } from "../../../../../../GeestUI";

interface BeforeCreateMeasurementProps {
  closeModal: () => void;
  defaultMeasurementName: string;
  setDefaultMeasurementName: (name: string) => void;
  openMeasurementsView: () => void;
}

const BeforeCreateMeasurement: React.FC<BeforeCreateMeasurementProps> = ({
  closeModal,
  defaultMeasurementName,
  setDefaultMeasurementName,
  openMeasurementsView,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  return (
    <StyledModal
      open
      footer={null}
      closable={false}
      onCancel={closeModal}
      centered
      width="432px"
      bodyStyle={{
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Gotham-Book",
        padding: "12px",
      }}
    >
      <Header>
        <Title>Crear medición</Title>
        <CloseIcon size={20} onClick={closeModal} color="#48505e" />
      </Header>

      <Content>
        <InputContainer>
          <StyledInput
            value={defaultMeasurementName}
            onChange={(e) => setDefaultMeasurementName(e.target.value)}
            placeholder="Nombre de la medición..."
            autoFocus
            ref={inputRef}
          />
        </InputContainer>

        <ButtonRow>
          <Button
            type="primary"
            onClick={openMeasurementsView}
            disabled={_.trim(defaultMeasurementName).length === 0}
          >
            Crear
          </Button>
        </ButtonRow>
      </Content>
    </StyledModal>
  );
};

export default BeforeCreateMeasurement;
