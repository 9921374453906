import React from "react";
import { Typography, Button } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";
import Modal from "../../../../components/Modal";
import { Row } from "antd";
import { FinishModalProps, FinishResponse } from "./FinishTaskModal.d";

const { P } = Typography;

const FinishTaskModal: React.FC<FinishModalProps> = ({
  idTeam,
  idPending,
  onConfirm,
  onCancel,
  fieldsForm,
}) => {
  const [userFinishTask] = useMutation<FinishResponse>({
    func: "Ver2-MyPendings-uft",
    onSuccess: () => onConfirm(),
  });

  const onSubmit = () => {
    userFinishTask({
      args: {
        IdTeam: idTeam,
        IdTaskValue: idPending,
        FieldsForm: fieldsForm,
      },
    });
  };

  return (
    <Modal visible title="Terminar tarea" onCancel={onCancel} width="300px">
      <P>¿Has confirmado que toda la información sea correcta?</P>
      <Row align="middle" justify="space-between">
        <Button type="secondary" style={{ width: "48%" }} onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="primary" style={{ width: "48%" }} onClick={onSubmit}>
          Confirmar
        </Button>
      </Row>
    </Modal>
  );
};

export default FinishTaskModal;
