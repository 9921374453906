import React, { useState, useRef, useEffect } from "react";
//import Modal from "../../../components/Modal";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { ProcessData } from "./ProcessDetail.d";
import { useFetch, useMutation } from "../../../hooks";
import { EditIcon, CloseIcon } from "../../../components/hoverIcons";
import { getStatusInfo } from "./helpers";
import {
  StyledTag,
  StyledInput,
  TitleRow,
  Header,
  Container,
  StateContent,
  StyledPaper,
} from "./styles";
import _ from "lodash";
import LeftBar from "./LeftBar";
import StateMachine from "./StateMachine";
import { Row } from "antd";
import { Modal } from "@material-ui/core";
import UnsavedChanges from "../SharedComponents/UnsavedChanges";

const ProcessDetail: React.FC = () => {
  const { idProcess } = useParams<{ idProcess: string }>();
  const { state: LocState } = useLocation<{
    state?: string;
    shouldGoBackTo?: string;
  }>();
  const [processName, setProcessName] = useState<string>("");
  const [editingTitle, setEditingTitle] = useState<boolean>(false);
  const [state, setState] = useState<string>(
    !!LocState?.state ? LocState.state : "basicInfo"
  );
  const [nextState, setNextState] = useState<string>("");
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [openedUnsaved, setOpenUnsaved] = useState<boolean>(false);
  const [shouldOpenUnsaved, setShouldOpenUnsaved] = useState<boolean>(false);
  const { data, reload } = useFetch<ProcessData>({
    func: "Ver2-Reports-gpd",
    args: {
      IdProcessExecution: idProcess,
    },
    onSuccess: (data) => {
      setProcessName(data.Name);
    },
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef, editingTitle]);

  const [updateProcessExecutionTitle] = useMutation<[]>({
    func: "Ver2-Reports-upet",
    onSuccess: () => {
      setEditingTitle(false);
      reload();
    },
    onError: () => {
      setEditingTitle(false);
    },
  });

  const onChangeProcessName = () => {
    const isProcessEmpty = _.isEmpty(_.trim(processName));
    const isSameProcessName = _.isEqual(_.trim(processName), data?.Name);

    if (isProcessEmpty || isSameProcessName) {
      setEditingTitle(false);
      return;
    }
    updateProcessExecutionTitle({
      args: {
        IdProcessExecution: idProcess,
        Name: _.trim(processName),
      },
    });
  };

  const onCloseModal = () => {
    if (shouldOpenUnsaved) {
      setCloseModal(true);
      setOpenUnsaved(true);
    } else {
      history.goBack();
    }
  };

  const onChangeState = (newState: string) => {
    if (newState !== state) {
      if (state === "chat" && !!data?.MessageCount && data?.MessageCount > 0) {
        reload();
      }
      if (shouldOpenUnsaved) {
        setOpenUnsaved(true);
        setNextState(newState);
      } else {
        setState(newState);
      }
    }
  };

  return (
    <Modal open style={{ zIndex: 1 }} onClose={onCloseModal}>
      <Row
        justify="center"
        align="middle"
        onClick={onCloseModal}
        style={{ width: "100%", height: "100%" }}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          {openedUnsaved && (
            <UnsavedChanges
              onCancel={() => {
                setOpenUnsaved(false);
                setNextState("");
                setCloseModal(false);
              }}
              onClose={() => {
                setOpenUnsaved(false);
                setShouldOpenUnsaved(false);
                if (closeModal) {
                  onCloseModal();
                } else if (nextState) {
                  setState(nextState);
                }
              }}
            />
          )}
          <Header>
            <TitleRow>
              {!editingTitle ? (
                <>
                  {data?.Name}
                  {data?.Permissions.UserCanEditProcess && (
                    <EditIcon filled onClick={() => setEditingTitle(true)} />
                  )}
                </>
              ) : (
                <StyledInput
                  value={processName}
                  onChange={(e) => setProcessName(e.target.value)}
                  onFocus={(e) => e.target.select()}
                  onBlur={onChangeProcessName}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onChangeProcessName();
                    }
                  }}
                  ref={inputRef}
                />
              )}
              {state === "basicInfo" && (
                <StyledTag
                  color={getStatusInfo(data?.Status).color}
                  style={{
                    width: "74px",
                    justifyContent: "center",
                  }}
                >
                  {getStatusInfo(data?.Status).label}
                </StyledTag>
              )}
            </TitleRow>
            <CloseIcon
              size={20}
              onClick={onCloseModal}
              color="#48505e"
              hoverContainerColor="#d9d9d9"
            />
          </Header>
          <Container
            style={
              state === "chat"
                ? { gap: "10px", padding: "0px 0px 0px 12px" }
                : {}
            }
          >
            <LeftBar
              state={state}
              setState={onChangeState}
              processInfo={data}
            />
            <StateContent>
              <StateMachine
                state={state}
                processInfo={data}
                reload={reload}
                setShouldOpenUnsaved={setShouldOpenUnsaved}
                setState={onChangeState}
              />
            </StateContent>
          </Container>
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default ProcessDetail;
