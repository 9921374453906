import React from "react";
import styled from "styled-components";
import { Languages } from "./Dictionary";

const {
  WeekDays: { monday, tuesday, wednesday, thursday, friday, saturday, sunday },
} = Languages["ESP"];

const ListElement = styled.p<{ $selected: boolean }>`
  margin: 0;
  padding: 6px 10px;
  background: ${({ $selected = false }) => ($selected ? "#edecec" : "white")};
  color: #828d9e;
  cursor: pointer;
  transition: background 0.35s ease;
  :hover {
    background: #edecec;
  }
`;

interface WeekDayPopoverProps {
  weekDay: number;
  onSelectWeekDay: (dayToPostpone: number) => void;
}

const WeekDayPopover: React.FC<WeekDayPopoverProps> = ({
  weekDay,
  onSelectWeekDay,
}) => {
  return (
    <div>
      <ListElement $selected={weekDay === 1} onClick={() => onSelectWeekDay(1)}>
        {monday}
      </ListElement>
      <ListElement $selected={weekDay === 2} onClick={() => onSelectWeekDay(2)}>
        {tuesday}
      </ListElement>
      <ListElement $selected={weekDay === 3} onClick={() => onSelectWeekDay(3)}>
        {wednesday}
      </ListElement>
      <ListElement $selected={weekDay === 4} onClick={() => onSelectWeekDay(4)}>
        {thursday}
      </ListElement>
      <ListElement $selected={weekDay === 5} onClick={() => onSelectWeekDay(5)}>
        {friday}
      </ListElement>
      <ListElement $selected={weekDay === 6} onClick={() => onSelectWeekDay(6)}>
        {saturday}
      </ListElement>
      <ListElement $selected={weekDay === 7} onClick={() => onSelectWeekDay(7)}>
        {sunday}
      </ListElement>
    </div>
  );
};

export default WeekDayPopover;
