export const Languages = {
  ENG: {
    selectTeamPlaceholder: "Select team",
    CreateDashboardLabel: "Create dasboard",
    AddGraphLabel: "Add graphic",
    EditionMode: "Edition mode:",
    ShareDashboard: "Share dashboard",
    Permissions: "Permissions",
    Delete: "Delete",
  },
  ESP: {
    selectTeamPlaceholder: "Selecciona empresa",
    CreateDashboardLabel: "Crear dasboard",
    AddGraphLabel: "Agregar gráfica",
    EditionMode: "Modo edición:",
    ShareDashboard: "Compartir dashboard",
    Permissions: "Permisos",
    Delete: "Eliminar",
  },
};
