export enum CellTypes {
  EXECUTION_NAME = "ExecutionName",
  DURATION = "Duration",
  PROCESS_TEMPLATE_NAME = "ProcessTemplateName",
  STUCK_TIME = "StuckTime",
  TASK_DURATION = "TaskDuration",

  START_DATE = "StartDate",
  END_DATE = "EndDate",

  EXECUTION_ID = "ExecutionId",

  STARTED_USER = "StartedUser",
  USERS_INVOLVED = "UsersInvolved",

  PROCESS_TRIGGER = "ProcessTrigger",

  STATUS = "Status",
  STAGE = "Stage",
  PRIORITY = "Priority",

  CURRENT_EXECUTORS = "CurrentExecutors",

  LAST_INTERACTION = "LastInteraction",

  INFO_FROM_FIELD = "InfoFromField",
  INFO_FROM_COLUMN = "InfoFromColumn",
}

export enum DataTypes {
  STRING = "string",
  LOCATION = "location",
  SELECT = "select",
  MULTI_SELECT = "multi_select",
  DATE = "date",
  TIME = "time",
  NUMBER = "number",
  USERS_SELECT = "users_select",
  PHONE_NUMBER = "phone_number",
  CURRENCY = "currency",
  FILE = "file",
  PDF_AUTOGENERATED = "pdf_autogenerated",
}

export enum FilterTypes {
  STRING = "string",
  MULTI_SELECT = "multi_select",
  USERS_SELECT = "users_select",
  DATE_RANGE = "date_range",
  CURRENCY = "currency",
  PHONE_NUMBER = "phone_number",
  SELECT = "select",
}

interface Status {
  Label: string;
  Value: number;
}

export interface Stage {
  Color: string;
  Label: string;
  IdStage: number;
}

export interface TriggerValueType {
  Type: string;
  Label: string;
}

export interface FileValueType {
  Name: string;
  IdFile: number;
  SourceUrl: string;
}

export interface Task {
  Title: string;
  IdStatus: number;
  StartedAt: string;
  Revision: boolean;
  StuckTime: string;
}

export interface ExecutorValueType {
  IdUser: number;
  IdStatus: number;
  Tasks: Task[];
}

export interface InteractionValueType {
  Message: string;
  Date: string;
  UsersInvolved: number[];
}

export interface User {
  IdUser: number;
  Initials: string;
  FirstName: string;
  LastName: string;
  Email: string;
  ProfilePicture?: string;
}

export interface StatusDataOriginType {
  Label: string;
  Value: number;
}

export interface StageDataOriginType {
  Color: string;
  Label: string;
  IdStage: number;
  IdProcessTemplate: number;
}

export interface PriorityDataOriginType {
  IdTaskPriority: number;
  Color: string;
  Title: string;
}

export interface SelectValueType {
  String_Value: string;
  Key_Value: number[];
}

export interface FormatValueType {
  Value: string;
  Format: string;
}

export interface CellComponentProps {
  dataType?: DataTypes;
  stringValue?: string;
  dateValue?: string;
  numValue?: number | null;
  userValue?: number[] | null;
  selectValue?: SelectValueType | null;
  triggerValue?: TriggerValueType | null;
  keyValue?: number | null;
  formatValue?: FormatValueType;
  filesValue?: FileValueType[];
  executorsValue?: ExecutorValueType[];
  interactionValue?: InteractionValueType | null;

  UserSelectDataOrigin: User[];
  StatusDataOrigin: StatusDataOriginType[];
  StageDataOrigin: StageDataOriginType[];
  PriorityDataOrigin: PriorityDataOriginType[];
}

export interface DynamicCellProps {
  cellType: CellTypes;
  dataType?: DataTypes;
  stringValue?: string;
  dateValue?: string;
  numValue?: number | null;
  userValue?: number[] | null;
  selectValue?: SelectValueType | null;
  triggerValue?: TriggerValueType | null;
  keyValue?: number | null;
  formatValue?: FormatValueType;
  filesValue?: FileValueType[];
  executorsValue?: ExecutorValueType[];
  interactionValue?: InteractionValueType | null;

  UserSelectDataOrigin: User[];
  StatusDataOrigin: StatusDataOriginType[];
  StageDataOrigin: StageDataOriginType[];
  PriorityDataOrigin: PriorityDataOriginType[];
}

export interface TriggerValueType {
  Type: string;
  Label: string;
}

export interface FileValueType {
  Name: string;
  IdFile: number;
  SourceUrl: string;
}

export interface Task {
  Title: string;
  IdStatus: number;
  StartedAt: string;
  Revision: boolean;
  StuckTime: string;
}

export interface ExecutorValueType {
  IdUser: number;
  IdStatus: number;
  Tasks: Task[];
}

export interface CellValue {
  String_Value?: string;
  Date_Value?: string;
  Num_Value?: number;
  User_Value?: number[];
  Select_Value?: SelectValueType;
  Trigger_Value?: TriggerValueType | null;
  Key_Value?: number | null;
  Format_Value?: FormatValueType;
  Files_Value?: FileValueType[];
  Executors_Value?: ExecutorValueType[];
  Interaction_Value?: InteractionValueType | null;
}

export interface ReportCell {
  Key: CellTypes;
  DataType?: DataTypes;
  CellValue: CellValue;
}

export interface Header {
  Id: string;
  Key: CellTypes;
  Label: string;
  DataType: DataTypes | null;
  DataOrigin:
    | StatusDataOriginType[]
    | StageDataOriginType[]
    | PriorityDataOriginType[]
    | User[]
    | null;
  FilterType: FilterTypes;
}

export interface PermissionsType {
  UserCanCancelProcess: boolean;
  UserCanDeleteProcess: boolean;
}

export interface ReportCell {
  [key: string]: CellValue;
}

export interface RowData {
  IdProcessExecution: number;
  Permissions: PermissionsType;
  Cells: ReportCell[];
}

export interface Option {
  label: React.ReactNode | string | number | undefined | null;
  value: string | number;
  separator?: boolean;
  asSelected?: OptionLabel;
  searchableValues?: string;
}

export interface Filter {
  Value: string | null;
  IdReportColumn: string;
  FilterType: FilterTypes;
}

export interface FilterOption {
  IdReportColumn: string;
  Value?: any;
  Label?: string;
  DataOrigin:
    | string
    | UsersType[]
    | Stage[]
    | Status[]
    | PriorityDataOriginType[]
    | OriginElement[];
  defaultOptions?: Option[];
  FilterType: FilterTypes;
}

export interface Order {
  [key: string | number]: "ASC" | "DESC";
}

export interface TotalsType {
  [key: string]: string | number;
}

export interface ConfigurationType {
  FirstColumStatic: boolean;
  ShowTotal: boolean;
}

export interface GetPipelineReport {
  Headers: Header[];
  UserSelectDataOrigin: User[];
  Rows: RowData[];
  Totals: TotalsType | undefined;
  Filters: Filter[];
  Order: Order | undefined;
  Configuration: ConfigurationType;
}

export interface TableDataType {
  Headers: Header[];
  Rows: RowData[];
  Configuration: ConfigurationType;
}

export type OrderValue = "ASC" | "DESC" | "NONE" | null;

export interface OrderIconProps {
  Direction: OrderValue;
}

export interface Process {
  Stages: Stage[];
  ProcessName: string;
  IdTemplateProcess: number;
}

interface Team {
  Name: string;
  IdTeam: number;
  Proccesses: Process[] | null;
  TeamMembers: User[];
  CanExportReportData: boolean;
  CanAddDynamicColumns: boolean;
}

export interface GetTeamsAndFiltersResponse {
  Statuses: Status[];
  Teams: Team[];
}

export interface CallGetPipelineReportParams {
  IdTeam?: number;
  IdReport?: number | null;
  teamsAndFiltersInfo?: GetTeamsAndFiltersResponse | null;
  changedReport?: boolean;
}
