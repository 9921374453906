export const Languages = {
  ENG: {
    FiltersButton: "Filters",
    StartDateLabel: "Start date",
    EndDateLabel: "End date",
    StatusLabel: "Status",
    InvolvedLabel: "Involved",
    ProcessLabel: "Process",
    StageLabel: "Stage",

    StatusPlaceholder: "Select status",
    InvolvedPlaceholder: "Select involved",
    ProcessPlaceholder: "Select process",
    StagePlaceholder: "Select stage",
  },
  ESP: {
    FiltersButton: "Filtros",
    StartDateLabel: "Fecha de inicio",
    EndDateLabel: "Fecha de fin",
    StatusLabel: "Estatus",
    InvolvedLabel: "Involucrados",
    ProcessLabel: "Proceso",
    StageLabel: "Etapa",

    StatusPlaceholder: "Selecciona Status",
    InvolvedPlaceholder: "Selecciona involucrado(s)",
    ProcessPlaceholder: "Selecciona proceso(s)",
    StagePlaceholder: "Selecciona etapa(s)",
  },
};
