import { CustomNodeTypes as NodeTypes } from "./CustomNodes.d";
import StartNode from "./StartNode";
import TaskNode from "./TaskNode";
import ConditionalNode from "./ConditionalNode";
import AutomationNode from "./AutomationNode";
import SubfluxNode from "./SubfluxNode";
import EndNode from "./EndNode";

const CustomNodes = {
  [NodeTypes.START_NODE]: StartNode,
  [NodeTypes.TASK_NODE]: TaskNode,
  [NodeTypes.CONDITIONAL_NODE]: ConditionalNode,
  [NodeTypes.AUTOMATION_NODE]: AutomationNode,
  [NodeTypes.SUBFLUX_NODE]: SubfluxNode,
  [NodeTypes.END_NODE]: EndNode,
};

export default CustomNodes;
