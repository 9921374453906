export interface GetProcessTemplate {
  CanShare: boolean;
  HasOngoingExecutions: boolean;
  HasPendingPublishChanges: boolean;
  Id: number;
  IdProcessType: number;
  NGlobalFields: number;
  Name: string;
  UserCanDeleteProcessTemplate: boolean;
}

export interface IConfiguratorContext {
  refreshProcessInformation: () => void;
  refreshProcessFlow: () => void;
}

// Fields

export enum FieldTypes {
  GHOST = "Ghost",
  MANUAL = "Manual",
  CELL = "Cell",
}

export enum FieldDataTypes {
  USERS_SELECT = "users_select",
  STRING = "string",
  NUMBER = "number",
  PHONE_NUMBER = "phone_number",
  DATE = "date",
  TIME = "time",
  LOCATION = "location",
  CURRENCY = "currency",
  SELECT = "select",
  MULTI_SELECT = "multi_select",
  CHECKLIST = "checklist",
  FILE = "file",
  VARDB_ROW = "varDBRow",
  PDF_AUTOGENERATED = "pdf_autogenerated",
  SIGNATURE = "signature",
}

interface RegularField {
  IdField: number;
  Label: string;
  DataType: FieldDataTypes;
  Type: FieldTypes.MANUAL | FieldTypes.GHOST;
  Color?: string;
  PdfInfo?: {
    IdFieldParent: number;
    PdfLabel: string;
    Color: string;
  };
}

interface VarDBCellField extends RegularField {
  Type: FieldTypes.CELL;
  VardbGroupInfo: {
    Color: string;
    Label: string;
    CanAddNewRows: boolean;
    CanUseExistingRows: bnoolean;
    IdVarDB: number;
    IdVarDBGroup: number;
  };
}

export type Field = RegularField | VarDBCellField;

export interface ConsultField {
  IdField: number;
  IdFieldForm: number;
  IsMandatory: boolean;
}

export interface EditableField extends ConsultField {}
