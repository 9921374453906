import React, { useRef, useState } from "react";
import _ from "lodash";
import { EditorState } from "draft-js";
import { nanoid } from "nanoid";
import Navigation from "./Navigation";
import Controls from "./Controls";
import PageWrapper from "./PageWrapper";
import {
  PDFConfigProps,
  Element,
  NodeType,
  ElementOrigin,
  ElementsCount,
  Page,
} from "./PDFConfig.d";
import { ConfigWrapper } from "./Styles";

const initialElementsCounter: ElementsCount = {
  text: 0,
  image: 0,
  sign: 0,
};

const PDFConfig: React.FC<PDFConfigProps> = ({
  documentData,
  setDocumentData,
  currentPage,
  setCurrentPage,
  onDocumentDataChange = _.noop,
  fieldEditorOrigin,
  fieldImageOrigin,
  filesPath,
  importingPDF,
  teamLogo,
}) => {
  const [activeDrags, setActiveDrags] = useState<number>(0);
  const [resizing, setResizing] = useState<boolean>(false);
  const [movingElment, setMovingElment] = useState<string | null>(null);
  const [currentElement, setCurrentElement] = useState<string | null>(null);
  const [elementToConfig, setElementToConfig] = useState<string | null>(null);
  const [elementsCounter, setElementsCounter] = useState<ElementsCount>(
    initialElementsCounter
  );
  const [elementOnFocus, setElmentOnFocus] = useState<string>("");

  const pageRef = useRef<HTMLDivElement>();

  const zoomFactor = 0.75;

  const increaseElementsCount = (type: NodeType) => {
    let newCount = { ...elementsCounter };
    if (type === "text") {
      if (isNaN(Number(newCount.text)) || newCount.text === undefined) {
        newCount.text = 0;
      } else {
        newCount.text = ++newCount.text;
      }
    }
    if (type === "image") {
      if (isNaN(Number(newCount.image)) || newCount.image === undefined) {
        newCount.image = 0;
      } else {
        newCount.image = ++newCount.image;
      }
    }
    if (type === "sign") {
      if (isNaN(Number(newCount.sign)) || newCount.sign === undefined) {
        newCount.sign = 0;
      } else {
        newCount.sign = ++newCount.sign;
      }
    }
    return newCount;
  };

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const onDeletePage = (page: number) => {
    if (page !== 0) {
      if (page < currentPage) {
        setCurrentPage(currentPage - 1);
      }
      if (page === currentPage) {
        setCurrentPage(page - 1);
      }
    }

    let newPages = [...documentData.pages];
    newPages.splice(page, 1);

    if (currentPage >= newPages.length) {
      setCurrentPage(newPages.length - 1);
    }

    let newDocumentData = { ...documentData, pages: newPages };
    setDocumentData(newDocumentData);
    onDocumentDataChange(newDocumentData);
  };

  const newPage = () => {
    const pages = documentData.pages;

    let newPages = [...pages];
    newPages.push({ id: nanoid(), elements: [] });

    let newDocumentData = { ...documentData, pages: newPages };
    setDocumentData(newDocumentData);
    onDocumentDataChange(newDocumentData);
    setCurrentPage(newPages.length - 1);
  };

  const reorderPages = (newPages: Page[], newCurrentPage: number) => {
    let newDocumentData = { ...documentData, pages: newPages };
    setDocumentData(newDocumentData);
    onDocumentDataChange(newDocumentData);
    setCurrentPage(newCurrentPage);
  };

  // when move, resize a field, etc
  const updatePage = (newElements: Element[]) => {
    const pages = documentData.pages;

    let newPages = [...pages];
    newPages[currentPage].elements = newElements;

    let newDocumentData = { ...documentData, pages: newPages };
    setDocumentData(newDocumentData);
    onDocumentDataChange(newDocumentData);
  };

  const getCurrentPageBackground = () => {
    const page = documentData.pages[currentPage];
    return page.backgroundImage ?? "";
  };

  // when delete a field from panel, change state of field, etc
  const updateElementsOrigin = (newElementsOrigin: ElementOrigin[]) => {
    let newDocumentData = {
      ...documentData,
      elementsOrigin: newElementsOrigin,
    };
    setDocumentData(newDocumentData);
    onDocumentDataChange(newDocumentData);
  };

  const onInputStateChange = (newValue: EditorState, id: string) => {
    const elementsOrigin = [...documentData.elementsOrigin];
    const idx = _.findIndex(elementsOrigin, { id });

    let newElementsOrigin = [...elementsOrigin];
    newElementsOrigin[idx].value = newValue;
    updateElementsOrigin(newElementsOrigin);
  };

  const onImageSourceChange = (newValue: string, id: string) => {};

  const onStartMoving = (element: Element, i: number) => {
    if (resizing) return false;
    if (element.type === "text" && element.id === elementOnFocus) {
      return false;
    }
    setMovingElment(element.id);
    setActiveDrags(activeDrags + 1);
  };

  const onStopMoving = (element: Element, i: number) => {
    setMovingElment(null);
    setActiveDrags(activeDrags - 1);
  };

  const onControlledDrag = (
    e: any,
    position: any,
    element: Element,
    i: number
  ) => {
    if (resizing) return false;
    const pages = documentData.pages;
    const newElement = {
      ...element,
      position: {
        x: position.x,
        y: position.y,
      },
    };
    const elements = pages[currentPage].elements as Element[];
    let newArr = [...elements];
    newArr[i] = newElement;
    updatePage(newArr);
  };

  const onResizeStart = () => {
    setResizing(true);
  };

  const onResize = (
    e: any,
    { node, size, handle }: any,
    element: Element,
    i: number
  ) => {
    const pages = documentData.pages;

    const newSize = {
      width: size.width,
      height: size.height,
    };

    const documentWidth = documentData.width ?? 794;
    const documentHeight = documentData.height ?? 1123;

    // more x than limit
    if (newSize.width + element.position.x > documentWidth) {
      newSize.width = documentWidth - element.position.x;
    }
    // more y than limit
    if (newSize.height + element.position.y > documentHeight) {
      newSize.height = documentHeight - element.position.y;
    }

    const newElement = {
      ...element,
      boxSize: newSize,
    };
    const elements = pages[currentPage].elements as Element[];
    let newArr = [...elements];
    newArr[i] = newElement;
    updatePage(newArr);
  };

  const onResizeStop = () => {
    setResizing(false);
  };

  const unSelectElement = () => {
    setCurrentElement(null);
  };

  // it's ok to just delete on page, we don't want to search each page
  // to check if it's the last element to delete it from control
  // just delete it on current page
  const onDeleteElement = (i: number) => {
    unSelectElement();
    const pages = documentData.pages;
    const elements = pages[currentPage].elements as Element[];
    let newElements = [...elements];
    newElements.splice(i, 1);
    updatePage(newElements);
    setElementToConfig(null);
  };

  const onDeleteOriginElement = (elementId: string) => {
    if (elementToConfig === elementId) {
      setElementToConfig(null);
    }

    // delete globaly
    let newElementsOrigin = documentData.elementsOrigin.filter(
      (el) => el.id !== elementId
    );

    // delete inseance on each page
    let newPages = documentData.pages.map((page) => ({
      ...page,
      elements: page.elements.filter((el) => el.id !== elementId),
    }));

    let newDocumentData = {
      elementsOrigin: newElementsOrigin,
      pages: newPages,
      width: documentData.width ? documentData.width : 794,
      height: documentData.height ? documentData.height : 1123,
    };
    setDocumentData(newDocumentData);
    onDocumentDataChange(newDocumentData);
  };

  // when create a new element from element type list
  const dropNewNode = (e: any, nodeType: NodeType) => {
    if (pageRef.current) {
      const dropTarget = pageRef.current.getBoundingClientRect();
      const clientWidth = pageRef.current.clientWidth;
      const clientHeight = pageRef.current.clientHeight;

      let newX = (e.clientX - dropTarget.x) / zoomFactor;
      let newY = (e.clientY - dropTarget.y) / zoomFactor;

      // normalize left
      if (newX < 0) newX = 0;
      // normalize right
      if (newX + 300 > clientWidth) newX = clientWidth - 300;
      //normalize top
      if (newY < 0) newY = 0;
      // normalize bottom
      if (newY + 50 > clientHeight) newY = clientHeight - 50;

      const coords = {
        x: newX,
        y: newY,
      };

      const newElementId = nanoid();

      let newElement: Element = {
        id: newElementId,
        type: nodeType,
        position: coords,
        boxSize: { width: 300, height: 50 },
      };

      if (nodeType === "image") {
        newElement.boxSize = { width: 150, height: 150 };
      }

      const elements = documentData.pages[currentPage].elements as Element[];
      let newElements = [...elements, newElement];
      updatePage(newElements);

      const newCount = increaseElementsCount(nodeType);
      setElementsCounter(newCount);

      const elementsOrigin = documentData.elementsOrigin;
      let newElementOrigin: ElementOrigin = {
        id: newElementId,
        type: nodeType,
        name: "",
        value: "",
        imageConfig: "source",
        imageDimensions: "both",
        signColor: "#000",
        imageSourceConfig: "file",
      };

      if (nodeType === "text") {
        newElementOrigin.name = "Texto " + newCount[nodeType];
        newElementOrigin.value = EditorState.createEmpty();
      }

      if (nodeType === "image") {
        newElementOrigin.name = "Imagen " + newCount[nodeType];
        newElementOrigin.value = "";
      }

      if (nodeType === "sign") {
        newElementOrigin.name = "Firma " + newCount[nodeType];
        newElementOrigin.value = "";
      }

      let newElementsOrigin = [...elementsOrigin, newElementOrigin];
      updateElementsOrigin(newElementsOrigin);
      setCurrentElement(newElementOrigin.id);
      setElementToConfig(newElementOrigin.id);
    }
  };

  const instantiateNode = (e: any, node: ElementOrigin) => {
    if (pageRef.current) {
      const dropTarget = pageRef.current.getBoundingClientRect();
      const clientWidth = pageRef.current.clientWidth;
      const clientHeight = pageRef.current.clientHeight;

      let newX = (e.clientX - dropTarget.x) / zoomFactor;
      let newY = (e.clientY - dropTarget.y) / zoomFactor;

      // normalize left
      if (newX < 0) newX = 0;
      // normalize right
      if (newX + 300 > clientWidth) newX = clientWidth - 300;
      //normalize top
      if (newY < 0) newY = 0;
      // normalize bottom
      if (newY + 50 > clientHeight) newY = clientHeight - 50;

      const coords = {
        x: newX,
        y: newY,
      };

      let newNodeInstance: Element = {
        id: node.id,
        type: node.type,
        position: coords,
        boxSize: { width: 300, height: 50 },
      };

      if (node.type === "image") {
        newNodeInstance.boxSize = { width: 150, height: 150 };
      }

      const elements = documentData.pages[currentPage].elements as Element[];
      let newElements = [...elements, newNodeInstance];
      updatePage(newElements);
      setCurrentElement(newNodeInstance.id);
      setElementToConfig(newNodeInstance.id);
    }
  };

  const getElements = () => {
    if (currentPage >= documentData.pages.length) {
      return documentData.pages[documentData.pages.length - 1].elements;
    }
    return documentData.pages[currentPage].elements ?? [];
  };

  return (
    <ConfigWrapper onClick={unSelectElement}>
      <Navigation
        pages={documentData.pages}
        onChangePage={onChangePage}
        onDeletePage={onDeletePage}
        currentPage={currentPage}
        newPage={newPage}
        reorderPages={reorderPages}
        pageWidth={documentData.width}
        pageHeight={documentData.height}
      />

      <PageWrapper
        pageRef={pageRef}
        elementsOrigin={documentData.elementsOrigin}
        elements={getElements()}
        pageWidth={documentData.width}
        pageHeight={documentData.height}
        pageBackgroundImage={getCurrentPageBackground()}
        currentElement={currentElement}
        setCurrentElement={setCurrentElement}
        setElementToConfig={setElementToConfig}
        elementToConfig={elementToConfig}
        onInputStateChange={onInputStateChange}
        onImageSourceChange={onImageSourceChange}
        setElmentOnFocus={setElmentOnFocus}
        onStartMoving={onStartMoving}
        onStopMoving={onStopMoving}
        onControlledDrag={onControlledDrag}
        onResizeStart={onResizeStart}
        onResize={onResize}
        onResizeStop={onResizeStop}
        onDeleteElement={onDeleteElement}
        fieldEditorOrigin={fieldEditorOrigin ?? []}
        importingPDF={importingPDF}
        isMovingElements={resizing}
        movingElment={movingElment}
        teamLogo={teamLogo}
      />

      <Controls
        elementsOrigin={documentData.elementsOrigin}
        elementToConfig={elementToConfig}
        setElementToConfig={setElementToConfig}
        filesPath={filesPath}
        dropNewNode={dropNewNode}
        instantiateNode={instantiateNode}
        onDeleteOriginElement={onDeleteOriginElement}
        updateElementsOrigin={updateElementsOrigin}
        fieldImageOrigin={fieldImageOrigin ?? []}
        teamLogo={teamLogo}
      />
    </ConfigWrapper>
  );
};

export default PDFConfig;
