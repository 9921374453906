import React from "react";
import {
  TemplateImportsDescription,
  ColumnTitle,
  ImportItemsContainer,
  ImportItem,
  BlueDiv,
  ImportDetail,
  DarkBlueDiv,
} from "./style";
import { Row } from "antd";
import processBlack from "../../../resources/img/ProcessBlack.svg";
import dataBaseBlack from "../../../resources/img/DataBaseBlack.svg";
import { Languages } from "./Dictionary";
import { TemplateImportsListProps } from "./InstallTemplate.d";

const TemplateImportsList: React.FC<TemplateImportsListProps> = ({
  IdProcessTemplate,
  processTemplates,
  varDBs,
  sharing,
}) => {
  const {
    TemplatePackageLabel,
    TemplateProcessesLabel,
    ProcessesLabel,
    DataBasesLabel,
    YouCanInstallLabel,
  } = Languages["ESP"];
  return (
    <>
      <TemplateImportsDescription>
        {IdProcessTemplate && IdProcessTemplate?.length > 1
          ? TemplatePackageLabel
          : TemplateProcessesLabel}
        {!sharing && YouCanInstallLabel}
      </TemplateImportsDescription>
      <Row align="top" justify="space-between" style={{ width: "100%" }}>
        <div style={{ width: "272px" }}>
          <ColumnTitle>
            <img
              src={processBlack}
              alt="process"
              style={{
                width: "25px",
                height: "23px",
                marginRight: "5px",
              }}
            />
            {ProcessesLabel}
          </ColumnTitle>
          <ImportItemsContainer>
            {processTemplates?.map(({ Label, IdProcessTemplate }) => (
              <ImportItem key={IdProcessTemplate}>
                <BlueDiv />
                <ImportDetail>{Label}</ImportDetail>
              </ImportItem>
            ))}
          </ImportItemsContainer>
        </div>
        <div style={{ width: "272px" }}>
          <ColumnTitle>
            <img
              src={dataBaseBlack}
              alt="db"
              style={{
                width: "25px",
                height: "23px",
                marginRight: "5px",
              }}
            />
            {DataBasesLabel}
          </ColumnTitle>
          <ImportItemsContainer>
            {varDBs?.map(({ Label, ProcessTemplateIdVarDB }) => (
              <ImportItem key={ProcessTemplateIdVarDB}>
                <DarkBlueDiv />
                <ImportDetail>{Label}</ImportDetail>
              </ImportItem>
            ))}
          </ImportItemsContainer>
        </div>
      </Row>
    </>
  );
};

export default TemplateImportsList;
