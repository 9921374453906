import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { Row } from "antd";
import styled from "styled-components";
import Modal from "../../../../../components/Modal";
import { Button, Typography } from "../../../../../GeestUI";
import { useMutation } from "../../../../../hooks";

const { P } = Typography;

const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start !important;
  & .PrivateSwitchBase-root-1 {
    padding-top: 0;
  }
`;

interface DeleteProcessModalProps {
  IdProcessExecution: number | null;
  hasVarDBs: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

const DeleteProcessModal: React.FC<DeleteProcessModalProps> = ({
  IdProcessExecution,
  hasVarDBs,
  onSuccess,
  onCancel,
}) => {
  const [deleteVarDBs, setDeleteVarDBs] = useState(false);

  const [deleteProcessExecution, deleting] = useMutation<[]>({
    func: "Ver2-Reports-dpe",
    onSuccess: () => onSuccess(),
  });

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () =>
    deleteProcessExecution({
      args: {
        IdProcessExecution,
        DeleteLinkedRows: deleteVarDBs,
      },
    });

  return (
    <Modal
      visible
      title="Eliminar seguimiento"
      width="300px"
      onCancel={onCancel}
    >
      <P>
        Se eliminará todo rastro de este proceso junto con su información y ya
        no será consultable en el sistema. Esta acción no se puede revertir.
        ¿Estás seguro de eliminar?
      </P>
      {hasVarDBs && (
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={deleteVarDBs}
              onChange={(_e, value) => setDeleteVarDBs(value)}
              name="checkedB"
              color="primary"
            />
          }
          label={
            <P>
              Eliminar también la información vinculada de las bases de datos.
            </P>
          }
        />
      )}
      <Row justify="end" style={{ marginTop: "1rem" }}>
        <Button type="danger" onClick={onClick} loading={deleting}>
          Eliminar
        </Button>
      </Row>
    </Modal>
  );
};

export default DeleteProcessModal;
