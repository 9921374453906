import {
  StepConnector,
  makeStyles,
  withStyles,
  Paper,
} from "@material-ui/core";
import { Space } from "antd";
import styled from "styled-components";
import searchIMG from "../../../resources/img/SEARCH_TASKS.svg";

export const Label = styled.div`
  font-size: 18px;
`;

export const StyledSpace = styled(Space)`
  margin: 1rem 0;
`;

export const StyledPaper = styled(Paper)<{ $active: boolean }>`
  padding: 1rem;
  border-radius: 6px !important;
  width: 160px;
  text-align: center;
  background-color: ${({ $active }) =>
    $active ? "#f2f2f2" : "white"} !important;
  cursor: pointer;
  :hover {
    background-color: #f2f2f2 !important;
  }
`;

export const ModalTabContainer = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;

  .MuiStepper-root {
    padding: 0;
    flex: 1;
  }

  .MuiStepLabel-root {
    cursor: pointer;
  }
`;

export const useDotStyles = makeStyles({
  root: {
    backgroundColor: "#edecec",
    minWidth: 17,
    width: 17,
    minHeight: 17,
    height: 17,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.35s ease",
  },
  active: {
    background: "#0273e9",
  },
  completed: {
    background: "#0273e9",
  },
});

export const StepperConnector = withStyles({
  alternativeLabel: {
    top: 7,
    transition: "all 0.35s ease",
  },
  active: {
    "& $line": {
      background: "#0273e9",
    },
  },
  completed: {
    "& $line": {
      background: "#0273e9",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#edecec",
    transition: "all 0.35s ease",
  },
})(StepConnector);

export const StepLabelValue = styled.p<{ active: boolean }>`
  font-family: ${({ active = false }) => (active ? "Gotham-Bold" : "")};
  font-size: 12px;
  text-align: center;
  color: #48505e;
  margin: 0;
`;

export const ProcessTypesContainer = styled.div`
  width: 269px;
  height: 100%;
`;

export const BoldLabel = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
`;

export const TypesContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const TypeLabel = styled.div`
  max-width: 236px;
  color: rgba(130, 141, 158, 1);
  font-size 14px;
  font-weight: 325;
  font-family: Gotham-Book;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const BlueLabel = styled.div`
  margin-left: 5px;
  color: rgba(2, 115, 233, 1);
  font-size: 12px;
  font-family: "Gotham-Bold";
`;

export const TemplateBankContainer = styled.div`
  width: 493px;
  height: 100%;
`;

export const SearchBar = styled.input`
  background-image: url(${searchIMG});
  background-repeat: no-repeat;
  background-size: 14px;
  background-position-y: center;
  background-position-x: 6px;
  background-color: #ffffff;
  padding: 3px 5px;
  padding-left: 25px;
  width: 314px;
  height: 32px;
  margin-right: 2.4rem;

  border-radius: 6px;
  outline: none;
  border: 2px solid rgba(237, 236, 236, 1);
  font-size: 14px;
  font-weight: 325;
  color: rgba(130, 141, 158, 1);

  ::placeholder {
    color: rgba(130, 141, 158, 1);
  }

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const TemplateListContainer = styled.div`
  width: 100%;
  height: 450px;
  margin-top: 10px;
  overflow: auto;
  padding-right: 2rem;
`;

export const TemplateContainer = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  height: 92px;
  box-shadow: 0px 4px 4px 0px rgba(72, 80, 94, 0.3);
  cursor: pointer;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;

  .blue {
    color: rgba(2, 115, 233, 1);
    font-size: 12px;
    font-family: "Gotham-Bold";
    margin-right: 20px;
  }

  :hover {
    .blue {
      color: rgba(1, 76, 155, 1);
    }
    box-shadow: 4px 10px 6px 0px rgba(2, 115, 233, 0.3);
    border: 2px solid rgba(2, 115, 233, 1);
  }

  :active {
    .blue {
      color: rgba(25, 57, 183, 1);
    }
  }
`;

export const BlueDiv = styled.div`
  width: 30px;
  height: 100%;
  background-color: rgba(2, 115, 233, 1);
`;

export const TemplateInfoContainer = styled.div`
  width: 431px;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
`;

export const TemplateDescription = styled.div`
  color: rgba(72, 80, 94, 1);
  font-weight: 325;
  font-size: 12px;
  line-height: 16.8px;
  height: 32px;
  overflow: hidden;
`;

export const StyledInput = styled.input`
  background-color: #ffffff;
  min-width: 384px;
  height: 32px;
  padding-left: 6px;
  margin-bottom: 1.5rem;

  border-radius: 6px;
  outline: none;
  border: 2px solid rgba(237, 236, 236, 1);
  font-size: 14px;
  font-weight: 325;
  color: rgba(130, 141, 158, 1);

  ::placeholder {
    color: rgba(130, 141, 158, 1);
  }

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const TemplateTitle = styled.div`
  color: rgba(72, 80, 94, 1);
  font-size: 18px;
  font-family: Gotham-Bold;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TemplateCategory = styled.div`
  color: rgba(72, 80, 94, 1);
  font-weight: 325;
  font-size: 14px;
  margin-left: 25px;
`;

export const TemplateDetailDescription = styled.div`
  width: 100%;
  height: 270px;
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 19.6px;
  color: rgba(72, 80, 94, 1);
  overflow: auto;
`;
