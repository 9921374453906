import styled from "styled-components";
import { IoClose } from "react-icons/io5";
import { Space, Button, Input, Row } from "antd";
import searchIMG from "../../../../../../resources/img/SEARCH_TASKS.svg";

export const CenterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleBar = styled.div`
  border-radius: 0 10px 0 0;
  padding: 5px 10px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const TabsContainer = styled.div`
  min-height: 22px;
  display: flex;
  gap: 5px;
`;

export const EmptyElement = styled.div`
  height: 32px;
`;

export const TabElement = styled.div<{ $isActive: boolean }>`
  background-color: ${({ $isActive }) =>
    $isActive ? "rgba(224, 240, 255, 1)" : "rgba(242, 248, 254, 1)"};
  border-radius: 6px;
  padding: 6px;
  display: flex;
  height: 34px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  * {
    text-align: center;
  }
`;

export const TabElementTitle = styled.div`
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  font-size: 14px;
`;

export const CloseIcon = styled(IoClose)`
  cursor: pointer;
  :hover {
    color: black;
  }
`;

export const ViewContent = styled.div`
  flex: 1;
  overflow: auto;
  border-radius: 0 0 10px 0;
  padding: 20px;
  display: flex;
`;

export const ViewContentDefault = styled(ViewContent)`
  flex-direction: column;
  algin-items: center;
  text-align: center;
`;

export const ViewContentAddTrigger = styled(ViewContent)`
  flex-wrap: wrap;
  gap: 15px;
`;

export const ViewContentManualTrigger = styled(ViewContent)`
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
`;

export const ViewContentFormTrigger = styled(ViewContent)`
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
`;

export const ViewContentWebhookTrigger = styled(ViewContent)`
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
`;

export const ViewContentEditFields = styled(ViewContent)``;

export const ManualTriggerMetaData = styled.div`
  display: flex;
  align-items: top;
`;

export const FormTriggerMetaData = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const WebhookTriggerMetaData = styled(ManualTriggerMetaData)``;

export const ManualTriggerContent = styled.div`
  flex-direction: column;
  display: flex;
  margin-left: 20px;
`;
export const FormTriggerContent = styled(ManualTriggerContent)``;
export const WebhookTriggerContent = styled(ManualTriggerContent)``;

export const ManualTriggerTitle = styled.div`
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  font-size: 18px;
  margin-right: 10px;
`;

export const TriggerFormTitle = styled.div`
  color: rgba(72, 80, 94, 1);
  font-size: 14px;
  font-family: Gotham-Bold;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 10px;
`;

export const WebhookTriggerTitle = styled(ManualTriggerTitle)``;

export const FormTriggerTitle = styled(ManualTriggerTitle)``;

export const InputStyled = styled.input`
  color: rgba(72, 80, 94, 1);
  font-size: 18px;
  font-family: Gotham-Bold;
  border: 2px solid rgba(72, 80, 94, 1);
  border-radius: 6px;
  padding-left: 10px;
  width: 247px;
  height: 37px;
  :focus-visible {
    outline: none;
  }
`;

export const ManualTriggerDescription = styled.p`
  margin-top: 5px;
  color: rgba(130, 141, 158, 1);
  font-size: 14px;
  font-family: Gotham-Book;
  font-weight: 325;
`;
export const FormTriggerDescription = styled(ManualTriggerDescription)``;

export const WebhookTriggerDescription = styled(ManualTriggerDescription)``;

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #c3331e;
  p {
    margin: 0;
    font-size: 12px;
  }
`;

export const PreviewButton = styled.button`
  border: 1px solid #c0c9d3;
  color: #727e8b;
  background-color: white;
  width: 150px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.35s ease;

  :hover {
    background-color: #cbcfd4;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
`;

export const ManualTriggerExtraData = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const FormTriggerExtraData = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;
  margin-top: 10px;
`;

export const RolesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RolesBox = styled.div`
  min-height: 42px;
  width: 352px;
  border-radius: 6px;
  border: 1px solid rgba(237, 236, 236, 1);
  padding: 10px;
  transition: background-color 0.35s ease;
  gap: 8px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  flex-wrap: wrap;
  :hover {
    background-color: rgba(237, 236, 236, 1);
  }
`;

export const StyledSpace = styled(Space)`
  width: 100%;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
`;

export const FormData = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 420px;
  height: 100%;
  overflow: auto;
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 5px;
  padding-right: 2px;
`;

export const WebHookFieldsContainer = styled.div`
  width: calc(100% - 10px);
  height: 475px;
  padding: 10px;
  overflow: auto;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const HookFieldLabel = styled.div`
  font-family: Gotham-Book;
  font-size: 14px;
  color: #48505e;
  font-weigth: 325;
  margin-left: 5px;
`;

export const WebHookData = styled.div`
  width: calc(55% - 10px);
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const WebHookText = styled.div`
  widht: 380px;
  font-family: Gotham-Book;
  font-weight: 325;
  color: rgba(72, 80, 94, 1);
  font-size: 14px;
  line-height: 19.6px;
  margin-top: 5px;
`;

export const JsonData = styled.div`
  width: calc(45% - 10px);
`;

export const JsonContainer = styled.div`
  width: 100%;
  height: 355px;
  border-radius: 6px;
  color: rgba(72, 80, 94, 1);
  font-size: 14px;
  font-weight: 325;
  line-height: 19.6px;
  border: 2px solid rgba(237, 236, 236, 1);
  padding: 10px;
  overflow: hidden;
  textarea {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: transparent;
    border-color: transparent;
    :disabled {
      font: inherit !important;
    }
  }
`;

export const JsonRequestContainer = styled.div`
  height: 28px;
  display: flex;
  justify-content: center;
  width: fit-content;
  align-items: center;
  padding: 4px 8px 4px 8px;
  border-radius: 6px;
  background-color: rgba(242, 248, 254, 1);
`;

export const RequestsSelectLabel = styled.div`
  font-size: 14px;
  font-family: Gotham-Book;
  color: rgba(130, 141, 158, 1);
  font-weight: 325px;
`;

export const RequestSelectDate = styled(RequestsSelectLabel)`
  font-size: 12px;
`;

export const FormMetaDataWrapper = styled(Row)<{ editorSelected?: boolean }>`
  width: 100%;
  z-index: ${({ editorSelected = false }) => (editorSelected ? 1 : 0)};
`;

export const FormPanel = styled.div<{ editorSelected?: boolean }>`
  width: 48%;
  z-index: 0;

  .geestToolbar,
  .geestEditorPopup {
    ${({ editorSelected = false }) => (editorSelected ? "z-index: 1;" : "")}
  }
`;

export const FormDescriptionWrapper = styled.div<{ editorSelected?: boolean }>`
  z-index: 0;

  .geestToolbar {
    ${({ editorSelected = false }) => (editorSelected ? "z-index: 2;" : "")}
  }
  .geestEditorPopup * {
    ${({ editorSelected = false }) => (editorSelected ? "z-index: 2;" : "")}
  }
`;

export const PanelElement = styled.div<{ $padding?: string }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: ${({ $padding = "0" }) => $padding};
  color: #727e8b;
  font-size: 14px;
`;

export const LinkElement = styled(PanelElement)`
  padding: 0 10px;
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  border: 2px solid rgba(237, 236, 236, 1);
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
  max-height: 60px;
  overflow: auto;
  p {
    color: rgba(130, 141, 158, 1);
    font-family: Gotham-Book;
    margin: 0;
    font-size: 14px;
    font-weight: 325px;
  }
`;

export const CopyIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #eee;
  }
`;

export const PanelElementTitle = styled.p`
  margin: 0;
  margin-bottom: 5px;
  font-family: Gotham-Bold;
  font-size: 16px;
`;

export const ConfigurationCont = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  color: #727e8b;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.35s ease;

  :hover {
    background-color: #e8e8e8;
  }
`;

export const NoLinkComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #727e8b;
  height: 100%;
  gap: 10px;
  text-align: center;
  padding: 10px 20px;
  p {
    margin: 0;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  width: 290px;
  justify-content: flex-end;
`;

export const TabWrapper = styled.div`
  max-width: 100%;
`;

export const StyledTextField = styled.input`
  width: 100%;
  color: rgba(130, 141, 158, 1);
  border: 2px solid rgba(237, 236, 236, 1);
  border-radius: 6px;
  padding-left: 10px;
  height: 32px;
  font-family: Gotham-Book;
  :hover {
    border-color: #0273e9;
  }
  :focus-visible {
    border: 2px solid rgba(72, 80, 94, 1);
    outline: none;
  }
`;

export const TabContent = styled.div`
  max-width: calc(100% - 20px);
  margin: 0 10px;
  margin-bottom: -10px;
  padding: 5px;
  background-color: rgba(130, 141, 158, 1);
  min-width: 165px;
  height: 32px;
  border-radius: 2px 2px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  p {
    margin: 0;
  }
`;

export const TabTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const TabBottom = styled.div`
  background-color: rgba(130, 141, 158, 1);
  height: 10px;
  min-width: 189px;
`;

export const UploadFaviconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;

  transition: background-color 0.35s ease;
  :hover {
    background-color: #fff2;
  }
`;

export const TabTitleLabel = styled.p`
  font-weight: 700;
  font-family: Gotham-Book;
  color: white;
`;

export const TabTitleInput = styled.input`
  font-weight: 700;
  font-family: Gotham-Book;
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  text-align: start;
  heght: 24px;
  width: 156px;
  background-color: transparent;
  :focus-visible {
    outline: none;
  }
`;

export const FormElementContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const FormElementContent = styled.div<{ $width?: string }>`
  width: ${({ $width = "100%" }) => $width};
  display: flex;
  flex-direction: column;
`;

export const FormTitleInput = styled.input`
  color: #727e8b;
  border: 0.5px solid #c0c9d3;
  border-radius: 5px;
  padding: 5px 10px;
  :focus-visible {
    outline: none;
  }
`;

export const FormImageInputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 0.5px solid #c0c9d3;
  border-radius: 5px;
  padding: 5px 10px;
  padding-right: 5px;
  color: #727e8b;
  cursor: pointer;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #cbcfd4;
  }
  p {
    margin: 0;
  }
`;

export const FormDescriptionInput = styled(Input.TextArea)`
  color: #727e8b;
  border: 0.5px solid #c0c9d3;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  :hover {
    border-color: #c0c9d3;
    box-shadow: none;
  }
  :focus {
    border-color: #c0c9d3;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const StyledButton = styled(Button)<{ $padding?: string }>`
  background: #0066ff;
  padding: ${({ $padding }) => $padding && "0 0"};
`;

export const DeleteContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: end;
`;

export const DeleteButton = styled.button`
  border: 1px solid #c3331e;
  color: #c3331e;
  background-color: white;
  width: 150px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.35s ease;

  :hover {
    // background-color: #cbcfd4;
  }
`;

export const TextElement = styled.p<{ fontSize?: string }>`
  font-size: ${({ fontSize = "14px" }) => fontSize};
  color: #074775;
  font-family: Gotham-Bold;
  margin: 0;
`;

export const CardOption = styled.div<{ $canAddTrigger: boolean }>`
  border: 1px solid rgba(2, 115, 233, 1);
  border-radius: 6px;
  width: 211px;
  height: 167px;
  cursor: ${({ $canAddTrigger }) =>
    $canAddTrigger ? "pointer" : "not-allowed"};
  background-color: rgba(255, 255, 255, 1);
  transition: box-shadow 0.35s ease;
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  :hover {
    box-shadow: 4px 6px 10px 0px rgba(2, 115, 233, 0.3);
  }
`;

export const CardTitle = styled.div<{ $canAddTrigger: boolean }>`
  font-size: 14px;
  color: rgba(72, 80, 94, 1);
  font-weight: 325;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Gotham-Book;
  gap: 5px;
`;

export const CardDescription = styled.div`
  color: rgba(130, 141, 158, 1);
  text-align: center;
  font-size: 12px;
  font-family: Gotham-Book;
  width: 160px;
  font-weight: 325;
  line-height: 16.8px;
`;

export const MoreTriggersSoon = styled(CenterItem)`
  border: 3px solid #dbdee2;
  border-radius: 10px;
  width: 200px;
  height: 200px;
  background-color: #dbdee2;
  font-family: Gotham-Bold;
  font-size: 18px;
  color: #074775;
  text-align: center;
`;

export const NarrowColumn = styled.div<{ justify?: string }>`
  width: 5%;
  display: flex;
  justify-content: ${({ justify = "start" }) => justify};
`;

export const PreviewContainer = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #cbcfd4;
  }
`;

export const FieldsWrapper = styled.div`
  width: 90%;
  display: flex;
  height: 477px;
`;

export const FieldsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const FieldsColumnBox = styled.div`
  width: 50%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const FieldsColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ColumnTitle = styled.div`
  font-family: Gotham-Bold;
  color: #727e8b;
`;

export const ColumnDivider = styled.div<{ $bgColor: string }>`
  background-color: ${({ $bgColor }) => $bgColor};
  width: 100%;
  height: 3px;
  border-radius: 3px;
  margin-bottom: 10px;
`;

export const FieldsBox = styled.div`
  background-color: #fafbfd;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
`;

export const SearchBar = styled.input`
  background-image: url(${searchIMG});
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-y: center;
  background-position-x: 6px;
  background-color: #ffffff;
  padding: 2px 0px 2px 35px;
  width: 100%;
  height: 30px;
  box-shadow: 0px 0px 10px 2.5px rgba(0, 62, 140, 0.1);
  border-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
  outline: none;
  margin-bottom: 0;
`;

export const FieldsContent = styled.div`
  max-height: 100%;
  overflow: auto;
  height: 100%;
`;

export const NoHookFieldsTitle = styled.div`
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  font-size: 18px;
  line-height: 25.6px;
`;

export const NoHookFieldsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const NoHookFieldsSubtitle = styled.div`
  font-family: Gotham-Book;
  font-weight: 325;
  font-size: 14px;
  line-height: 19.6px;
  color: rgba(72, 80, 94, 1);
  margin-bottom: 5px;
  text-align: center;
`;
