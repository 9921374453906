import React from "react";
import {
  ArticleWrapper,
  SectionWrapper,
  SnippetWrapper,
  StyledLink,
} from "../Styles";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface ParamsLinkProps {
  isActive: () => boolean;
  setSection: () => void;
}

const SectionName = "Otros parametros";

export const ParamsLink: React.FC<ParamsLinkProps> = ({
  isActive,
  setSection,
}) => {
  return (
    <StyledLink onClick={setSection} $isActive={isActive()}>
      {SectionName}
    </StyledLink>
  );
};

const fieldEditorOrigin = `export interface FieldEditorOrigin {
  DataType: string;
  Label: string;
  IdField: number;
}
`;

interface ParamsSectionProps {}

export const ParamsSection: React.FC<ParamsSectionProps> = () => {
  return (
    <SectionWrapper>
      <h2>{SectionName}</h2>
      <ArticleWrapper>
        <p>
          El editor recibe una serie de parametros que permiten desde
          simplemente trabajar con el, hasta modificar algunas partes del
          aspecto, estos son: <code className="code">editorState</code>,{" "}
          <code className="code">onEditorStateChange</code>,{" "}
          <code className="code">placeholder</code>,{" "}
          <code className="code">editorBorder</code>,{" "}
          <code className="code">toolbarConfig</code>,{" "}
          <code className="code">toolbarExtraParams</code>,{" "}
          <code className="code">fieldEditorOrigin</code>,{" "}
          <code className="code">onBlur</code>,{" "}
          <code className="code">onFocus</code>,{" "}
          <code className="code">required</code>,{" "}
          <code className="code">readOnly</code>,{" "}
          <code className="code">autoFocus</code>,{" "}
          <code className="code">popUpToolbar</code>,{" "}
          <code className="code">maxLength</code> y{" "}
          <code className="code">oneBlock</code>.
        </p>

        <h3>
          editorState
          <span className="info type">EditorState</span>
          <span className="info req">required</span>
        </h3>
        <p>
          Es el estado actual del editor. A través de esta propiedad se controla
          el contenido.
        </p>

        <h3>
          onEditorStateChange
          <span className="info type">
            (newEditorState: EditorState) =&gt; void
          </span>
          <span className="info req">required</span>
        </h3>
        <p>
          Es una función que recibe el nuevo estado y ejecuta el cambio deseado.
          Puede ser directamente el setState de un hook o una función que reciba
          el estado para procesar la información antes de guardr los cambios.
        </p>

        <h3>
          placeholder
          <span className="info type">string</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Recibe un string para configurar a modo de placeholder para mostrar en
          el editor cuando se inicializa. Por defecto, este valor es un string
          vacío.
        </p>

        <h3>
          editorBorder
          <span className="info type">boolean</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad activa o desactiva el borde en el editor. Este por
          defecto tiene un valor de <span className="type">false</span>, así que
          si deseas un editor con borde, debes especificarlo agregando esta
          propiedad.
        </p>

        <h3>
          toolbarConfig
          <span className="info type">obj</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad recibe un objeto de configuración con el cual se
          modifican las secciones por defecto en el editor. Permite activar y
          desactivar secciones enteras de controladores por categoría. Puedes
          ver más a detalle sus parametros en la sección{" "}
          <span className="info">toolbar básica</span> en esta documentación.
        </p>

        <h3>
          toolbarExtraParams
          <span className="info type">obj</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad recibe un objeto de configuración con el cual se
          modifican los controladores particulares, tanto de manera individual
          cambiando sus opciones como activando y desactivando solo algunas
          partes de categorías concretas. Puedes ver más a detalle sus
          parametros en la sección{" "}
          <span className="info">toolbar avanzada</span> en esta documentación.
        </p>

        <h3>
          fieldEditorOrigin
          <span className="info type">FieldEditorOrigin[]</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad opcional, recibe un arreglo de campos con los cuales se
          genera el listado para seleccionar alguna opción al agregar campos
          dentro del contenido. Su valor por defecto es un arreglo vacío, así
          que, incluso si agregas el controlador de campos en la configuración,
          pero no agregas un <code className="code">fieldEditorOrigin</code> no
          habrá problemas de crasheos.
        </p>
        <p>
          la interfaz del <code className="code">fieldEditorOrigin</code> es la
          siguiente:
        </p>
        <SnippetWrapper>
          <SyntaxHighlighter
            children={fieldEditorOrigin}
            language="typescript"
            style={atomOneDark}
          />
        </SnippetWrapper>

        <h3>
          onBlur
          <span className="info type">() =&gt; void</span>
          <span className="info req">optional</span>
        </h3>
        <p>Esta función se ejecuta al perder el focus en el editor.</p>

        <h3>
          onFocus
          <span className="info type">() =&gt; void</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta función se ejecuta al entrar en estado de focus en el editor.
        </p>

        <h3>
          required
          <span className="info type">boolean</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad cambia el color del borde a rojo cuando la cuenta de
          caracteres total es igual a 0 y lo regresa al color normal en
          cualquier otro caso. El valor por defecto es falso.
        </p>

        <h3>
          readOnly
          <span className="info type">boolean</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad deshabilita todo control del editor. El valor por
          defecto es falso.
        </p>

        <h3>
          autoFocus
          <span className="info type">boolean</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad activa el focus en el campo automáticamente al aparecer
          en la vista por primera vez. El valor por defecto es falso.
        </p>

        <h3>
          popUpToolbar
          <span className="info type">boolean</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad cambia el comportamiento del toolbar para ser invisible
          hasta que se haga focus en el. El valor por defecto es falso.
        </p>

        <h3>
          maxLength
          <span className="info type">number | null</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad al tener un valor numérico, limita la cantidad de
          caracteres en el editor. La sumatoria toma en consideración los
          caracteres que se les hace copy paste. También al asignar una cantidad
          limite, el editor mostrará la cuenta actual de caracteres de manera
          activa en la parte inferior derecha del editor. El valor por defecto
          es null.
        </p>

        <h3>
          oneBlock
          <span className="info type">boolean</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad deshabilita la posibilidad de insertar nuevas lineas,
          manteniendo el contenido en un único bloque de estado. El valor por
          defecto es falso.
        </p>
      </ArticleWrapper>
    </SectionWrapper>
  );
};
