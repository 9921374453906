import styled from "styled-components";
import searchIMG from "../../resources/img/SEARCH_TASKS.svg";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SearchBar = styled.input`
  background-image: url(${searchIMG});
  background-repeat: no-repeat;
  background-size: 14px;
  background-position-y: center;
  background-position-x: 6px;
  background-color: #ffffff;
  padding: 3px 5px;
  padding-left: 25px;
  width: 345px;
  height: 32px;

  border-radius: 6px;
  outline: none;
  border: 2px solid #edecec;
  font-size: 14px;
  color: #828d9e;

  ::placeholder {
    color: #828d9e;
  }

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const Tab = styled.div<{ $isSelected: boolean }>`
  padding: 6px 10px;
  border-radius: 6px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: rgba(72, 80, 94, 1);
  font-size: 14px;
  font-family: Gotham-Bold;
  cursor: pointer;
  background-color: ${({ $isSelected }) =>
    $isSelected ? "rgba(204, 230, 255, 1)" : "rgba(242, 248, 254, 1)"};
  :hover {
    background-color: ${({ $isSelected }) =>
      $isSelected ? "rgba(204, 230, 255, 1)" : "rgba(224, 240, 255, 1)"};
  }
`;

export const CountBadge = styled.div`
  display: flex;
  color: white;
  font-family: Gotham-Bold;
  background-color: rgba(219, 35, 44, 1);
  font-size: 10px;
  border-radius: 100px;
  padding: 2px;
  min-width: 20px;
  justify-content: center;
`;

export const ListCotainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0px;
  padding-bottom: 10px;
`;

export const ListItem = styled.div`
  border-bottom: 1px solid rgba(130, 141, 158, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  min-height: 42px;
  align-items: center;
  :hover {
    background-color: rgba(237, 236, 236, 1);
  }
`;

export const DataItem = styled.div<{ $width?: number }>`
  width: ${({ $width }) => $width}px;
  height: 100%;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const ReadIconContainer = styled.div`
  border-radius: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: rgba(217, 217, 217, 1);
  }
`;
