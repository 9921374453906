import React from "react";
import { Button } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";
import { useHistory } from "react-router-dom";
import Modal from "../../../../components/Modal";
import styled from "styled-components";

const Label = styled.div`
  font-size: 14px;
  color: #48505e;
  font-family: Gotham-Bold;
  width: 309px;
  text-align: center;
  margin-bottom: 14px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 10px;
`;

interface LeaveModalProps {
  idTeam: string;
  title: string;
  okText: string;
  cancelText: string;
  onClose: () => void;
}

const LeaveModal: React.FC<LeaveModalProps> = ({
  idTeam,
  title,
  okText,
  cancelText,
  onClose,
}) => {
  const history = useHistory();

  const [exitTeam, leavingTeam] = useMutation<[]>({
    func: "Ver2-Team-et",
    onSuccess: () => {
      onClose();
      history.push("/home/teams");
    },
  });

  const handleExitTeam = () => {
    exitTeam({ args: { IdTeam: idTeam } });
  };

  return (
    <Modal visible onCancel={onClose} title="Abandonar empresa" width="432px">
      <Container>
        <Label>¿Estás seguro que deseas abandonar esta empresa?</Label>
        <ButtonsContainer>
          <Button onClick={onClose} loading={leavingTeam} type="secondary">
            {cancelText}
          </Button>
          <Button
            onClick={handleExitTeam}
            loading={leavingTeam}
            type="primary"
            danger
          >
            {okText}
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};

export default LeaveModal;
