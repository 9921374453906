import React, { useRef, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import styled from "styled-components";
import { Tooltip } from "antd";
import { conditionNodeDefaultHandlers } from "./shared/shared";
import { Rectangle, handlerStyles, border, StyledTag } from "./shared/styles";
import { ConditionPort, NodeData } from "../Diagram.d";
import { useElementOverflow } from "../../../../../../hooks";
import {
  getConditionStatusBackgroundColor,
  getConditionStatusTag,
  getNodeStatusBackgroundColor,
} from "../helpers";
import { NodeStatus } from "../Flow.d";

const defaultConditionPorts: ConditionPort[] = [
  { Port: "out1", PortLabel: "Si", Status: NodeStatus.WAITING_TO_START },
  { Port: "out2", PortLabel: "No", Status: NodeStatus.WAITING_TO_START },
];

const ConditionRectangle = styled(Rectangle)`
  flex-direction: row;
`;

interface ConditionSectionProps {
  $backgroundColor?: string;
}

const Left = styled.div<ConditionSectionProps>`
  flex: 1;
  text-align: center;
  display: flex;
  overflow: hidden;
  border-right: ${border};
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background-color: ${({ $backgroundColor = "white" }) => $backgroundColor};
  padding: 0 4px;
`;
const TextContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
`;
const Right = styled.div`
  width: 90px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const Option = styled.div<ConditionSectionProps>`
  border-bottom: ${border};
  text-align: center;
  display: flex;
  height: 40px;
  background-color: ${({ $backgroundColor = "white" }) => $backgroundColor};
  padding: 0 4px;
  :last-child {
    border-bottom: none;
  }
`;
const Text = styled.span`
  line-height: 110%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const OptionText = styled.span`
  margin: auto;
  line-height: 110%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const ConditionOption: React.FC<ConditionPort> = ({ PortLabel, Status }) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const isOverflow = useElementOverflow(textRef, "height", 1);
  const [openTooltip, setOpenTooltip] = useState(false);
  const optionBackground = getNodeStatusBackgroundColor(Status);

  return (
    <Option $backgroundColor={optionBackground}>
      <Tooltip
        title={PortLabel}
        open={openTooltip && isOverflow}
        onOpenChange={setOpenTooltip}
        overlayInnerStyle={{
          borderRadius: "2px",
          padding: "6px 8px",
        }}
      >
        <OptionText ref={textRef}>{PortLabel}</OptionText>
      </Tooltip>
    </Option>
  );
};

const ConditionalNode: React.FC<NodeProps<NodeData>> = ({ data, selected }) => {
  const { label, status, conditionPorts = defaultConditionPorts } = data;
  const nodeBackground = getConditionStatusBackgroundColor(data);
  const statusTag = getConditionStatusTag(data, status);

  const textRef = useRef<HTMLSpanElement>(null);
  const isOverflow = useElementOverflow(textRef, "height", 1);
  const [openTooltip, setOpenTooltip] = useState(false);
  return (
    <ConditionRectangle
      style={{ height: conditionPorts.length * 40 }}
      $isSelected={selected}
    >
      <Left $backgroundColor={nodeBackground}>
        <TextContainer>
          <Tooltip
            title={label}
            open={openTooltip && isOverflow}
            onOpenChange={setOpenTooltip}
            overlayInnerStyle={{
              borderRadius: "2px",
              padding: "6px 8px",
            }}
          >
            <Text ref={textRef}>{label}</Text>
          </Tooltip>
          <div>
            <StyledTag color={statusTag.color}>{statusTag.label}</StyledTag>
          </div>
        </TextContainer>
      </Left>
      <Right>
        {conditionPorts.map((conditionPort) => (
          <ConditionOption {...conditionPort} key={conditionPort.Port} />
        ))}
      </Right>
      {conditionNodeDefaultHandlers.map((handler) => (
        <Handle
          style={handlerStyles}
          key={handler.id}
          {...handler}
          isConnectableStart={false}
        />
      ))}
      {conditionPorts.map(({ Port }, idx) => (
        <Handle
          style={{ ...handlerStyles, top: (idx + 1) * 40 - 20 }}
          key={Port}
          id={Port}
          position={Position.Right}
          type="source"
          isConnectableStart={false}
        />
      ))}
    </ConditionRectangle>
  );
};

export default ConditionalNode;
