import React, { useState, useEffect } from "react";
import { ViewContentAction, ActionFormTitle, StyledTextField } from "./styles";
import {
  ActionProps,
  GetEmailActionConfigResponse,
} from "../AutomationConfig.d";
import emailIcon from "../../../../../../resources/img/emailActionIcon.svg";
import ViewHeader from "./ViewHeader";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../../../../hooks";
import { Row } from "antd";
import DataTypeIcons from "../../../../../../components/DataTypeIcons";
import GeestSelect from "../../../../../../GeestUI/GeestSelect";
import { Button } from "../../../../../../GeestUI";
import { AiOutlinePlus } from "react-icons/ai";
import GeestTextEditor from "../../../../../../components/GeestTextEditor";
import { EditorState, convertFromRaw } from "draft-js";
import { editorStateRaw } from "../../../../../../components/GeestTextEditor/Utils/Editor";
import { Action } from "../EditAction.d";
import { AiOutlineUpload } from "react-icons/ai";
import { uploadFileToAWS } from "../../../../../../aws/s3Client";

const SendEmailActionView: React.FC<ActionProps> = ({
  IdNode,
  selectedAction,
  setSelectedAction,
  actionOptions,
  reloadHome,
  saveAction,
}) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const [destinationEmails, setDestinationEmails] = useState<
    { label: string; value: string }[]
  >([]);
  const [extraDestinationEmail, setExtraDestinationEmail] =
    useState<string>("");
  const [ccEmails, setCcEmails] = useState<{ label: string; value: string }[]>(
    []
  );
  const [extraccEmail, setExtraCcEmail] = useState<string>("");
  const [ccoEmails, setCcoEmails] = useState<
    { label: string; value: string }[]
  >([]);
  const [extraccoEmail, setExtraCcoEmail] = useState<string>("");
  const [responseEmails, setResponseEmails] = useState<
    { label: string; value: string }[]
  >([]);
  const [extraResponseEmail, setExtraResponseEmail] = useState<string>("");
  const [message, setMessage] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [subject, setSubject] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [logoType, setLogoType] = useState<string>("");

  useEffect(() => {
    if (selectedAction?.message) {
      setMessage(
        EditorState.createWithContent(convertFromRaw(selectedAction.message))
      );
    } else {
      setMessage(EditorState.createEmpty());
    }
    if (selectedAction?.subject) {
      setSubject(
        EditorState.createWithContent(convertFromRaw(selectedAction.subject))
      );
    } else {
      setSubject(EditorState.createEmpty());
    }
    if (selectedAction?.addresses) {
      let extraAddresses = selectedAction.addresses
        .filter(({ type }: { type: string }) => type === "String")
        .map(({ value }: { value: string }) => ({ label: value, value }));
      setDestinationEmails(extraAddresses);
    } else {
      setDestinationEmails([]);
    }
    if (selectedAction?.ccAddresses) {
      let extraccAddresses = selectedAction.ccAddresses
        .filter(({ type }: { type: string }) => type === "String")
        .map(({ value }: { value: string }) => ({ label: value, value }));
      setCcEmails(extraccAddresses);
    } else {
      setCcEmails([]);
    }
    if (selectedAction?.ccoAddresses) {
      let extraccoAddresses = selectedAction.ccoAddresses
        .filter(({ type }: { type: string }) => type === "String")
        .map(({ value }: { value: string }) => ({ label: value, value }));
      setCcoEmails(extraccoAddresses);
    } else {
      setCcoEmails([]);
    }
    if (selectedAction?.replyTo) {
      if (selectedAction.replyTo.type === "String") {
        setResponseEmails([
          {
            label: selectedAction.replyTo.value,
            value: selectedAction.replyTo.value,
          },
        ]);
      }
    } else {
      setResponseEmails([]);
    }
    if (!selectedAction?.fromConfig && selectedAction.fromName) {
      setSelectedAction({
        ...selectedAction,
        fromConfig: "manual",
      });
    }
    if (!selectedAction?.fromConfig && selectedAction?.IdAction === 0) {
      setSelectedAction({
        ...selectedAction,
        fromConfig: "teamName",
      });
    }
    if (selectedAction?.Logo === "teamLogo") {
      setLogoType("team");
    } else if (!!selectedAction?.Logo) {
      setLogoType("url");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction?.IdAction]);

  const { data, loading } = useFetch<GetEmailActionConfigResponse>({
    func: "Ver2-Configurator-gaco",
    args: {
      IdTeam,
      IdProcessTemplate,
      IdNode,
      Action: selectedAction?.Action,
    },
  });

  const sendAction = (action: Action) => {
    if (
      !!action.addresses &&
      action.addresses.length > 0 &&
      !!action.subject &&
      !!action.message
    ) {
      saveAction(action);
    }
  };

  const getFieldOptions = (Type?: string) =>
    data?.Fields?.filter(({ DataType, Value }) => {
      if (Type === "Email") {
        return (
          ["string", "users_select"].includes(DataType) &&
          !String(Value).includes("Process")
        );
      }
      if (Type === "File") {
        return ["file", "pdf_autogenerated"].includes(DataType);
      }
      return !["file", "pdf_autogenerated"].includes(DataType);
    }).map(({ Label, Value, DataType }) => ({
      label: (
        <Row align="middle">
          <Row
            align="middle"
            justify="center"
            style={{
              width: "26px",
              height: "26px",
              display: "flex",
            }}
            wrap={false}
          >
            <img
              style={{
                width: "14px",
                height: "14px",
                ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
              }}
              alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
              src={DataTypeIcons[DataType?.toLowerCase()].icon}
            />
          </Row>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {Label}
          </div>
        </Row>
      ),
      value: String(Value),
      searchableValues: Label,
    })) || [];

  const onChangeEmail = (val: string, key: string) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      if (!val) {
        actionAux[key] = [];
      } else {
        const values = val.split(",");
        actionAux[key] = values.map((val) => {
          let type = "";
          let newValue: string | number = val;
          if (
            getFieldOptions("Email")
              .map(({ value }) => value)
              .find((val) => val === newValue)
          ) {
            type = "Field";
            newValue = Number(newValue);
          } else if (
            newValue.match(
              // eslint-disable-next-line no-useless-escape
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ) {
            type = "String";
          }
          return {
            value: newValue,
            type,
          };
        });
      }
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const onChangeReplyTo = (val: string) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      let newValue: string | number = val;
      let type = "";
      if (
        getFieldOptions("Email")
          .map(({ value }) => value)
          .find((val) => val === newValue)
      ) {
        type = "Field";
        newValue = Number(newValue);
      } else if (
        newValue.match(
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        type = "String";
      }
      actionAux.replyTo = {
        type,
        value: newValue,
      };
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const onChangeAttachments = (val: string) => {
    const values = val.split(",");
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.filesAttach = values.map((val) => ({
        type: "Field",
        value: Number(val),
      }));
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const onAddDestinationEmail = () => {
    if (
      destinationEmails.find(({ value }) => value === extraDestinationEmail)
    ) {
      setExtraDestinationEmail("");
      return;
    }
    let newDestinationEmails = [...destinationEmails];
    newDestinationEmails.push({
      label: extraDestinationEmail,
      value: extraDestinationEmail,
    });
    setDestinationEmails(newDestinationEmails);
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      if (actionAux.addresses) {
        let addressesAux = [...actionAux.addresses];
        addressesAux.push({
          type: "String",
          value: extraDestinationEmail,
        });
        actionAux.addresses = addressesAux;
      } else {
        actionAux.addresses = [
          {
            type: "String",
            value: extraDestinationEmail,
          },
        ];
      }
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
    setExtraDestinationEmail("");
  };

  const onAddCCEmail = () => {
    if (ccEmails.find(({ value }) => value === extraccEmail)) {
      setExtraCcEmail("");
      return;
    }
    let newccEmails = [...ccEmails];
    newccEmails.push({
      label: extraccEmail,
      value: extraccEmail,
    });
    setCcEmails(newccEmails);
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      if (actionAux.ccAddresses) {
        let addressesAux = [...actionAux.ccAddresses];
        addressesAux.push({
          type: "String",
          value: extraccEmail,
        });
        actionAux.ccAddresses = addressesAux;
      } else {
        actionAux.ccAddresses = [
          {
            type: "String",
            value: extraccEmail,
          },
        ];
      }
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
    setExtraCcEmail("");
  };

  const onAddCCoEmail = () => {
    if (ccoEmails.find(({ value }) => value === extraccoEmail)) {
      setExtraCcoEmail("");
      return;
    }
    let newccoEmails = [...ccoEmails];
    newccoEmails.push({
      label: extraccoEmail,
      value: extraccoEmail,
    });
    setCcoEmails(newccoEmails);
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      if (actionAux.ccoAddresses) {
        let addressesAux = [...actionAux.ccoAddresses];
        addressesAux.push({
          type: "String",
          value: extraccoEmail,
        });
        actionAux.ccoAddresses = addressesAux;
      } else {
        actionAux.ccoAddresses = [
          {
            type: "String",
            value: extraccoEmail,
          },
        ];
      }
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
    setExtraCcoEmail("");
  };

  const onAddResponseEmail = () => {
    if (responseEmails.find(({ value }) => value === extraResponseEmail)) {
      setExtraResponseEmail("");
      return;
    }
    let newResponseEmails = [...responseEmails];
    newResponseEmails.push({
      label: extraResponseEmail,
      value: extraResponseEmail,
    });
    setResponseEmails(newResponseEmails);
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.replyTo = {
        type: "String",
        value: extraResponseEmail,
      };
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
    setExtraResponseEmail("");
  };

  const onChangeMessage = (editorState: EditorState) => {
    if (selectedAction) {
      setMessage(editorState);
      let actionAux = { ...selectedAction };
      actionAux.message = editorStateRaw(editorState);
      setSelectedAction(actionAux);
    }
  };

  const onChangeSubject = (editorState: EditorState) => {
    if (selectedAction) {
      setSubject(editorState);
      let actionAux = { ...selectedAction };
      actionAux.subject = editorStateRaw(editorState);
      setSelectedAction(actionAux);
    }
  };

  const fieldEditorOrigin =
    data?.Fields?.filter(
      ({ DataType }) => !["file", "pdf_autogenerated"].includes(DataType)
    ).map(({ Value, Label, DataType, DataOrigin }) => ({
      IdField: Value,
      Label,
      DataType,
      DataOrigin,
    })) || [];

  const fromOptions = [
    {
      label: "Usar nombre de mi empresa",
      value: "teamName",
      asSelected: `Usar ${data?.TeamName}`,
    },
    {
      label: "Escribir el nombre manualmente",
      value: "manual",
    },
  ];

  const onChangeFromConfig = (val: string) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.fromConfig = val;
      if (val === "teamName") {
        actionAux.fromName = "";
      }
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const logoOptions = [
    {
      label: "Usar logotipo de mi empresa",
      value: "team",
    },
    {
      label: "Subir logotipo",
      value: "url",
    },
  ];

  const onChangeLogoType = (val: string) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      if (val === "team") {
        actionAux.Logo = "teamLogo";
      } else {
        actionAux.Logo = "";
      }
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
    setLogoType(val);
  };

  const onUpload = (id: string) => {
    const inputElement = document.getElementById(id);
    inputElement?.click();
  };

  const handleUploadLogo = async ({ target }: any) => {
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      const filePath = await uploadFileToAWS(
        file,
        data?.FilePath || "",
        file.name
      );
      if (filePath) {
        let actionAux = { ...selectedAction };
        actionAux.Logo = filePath;
        setSelectedAction(actionAux);
        sendAction(actionAux);
      } else console.error("Upload failed");
    }
  };

  return (
    <ViewContentAction>
      <ViewHeader
        IdNode={IdNode}
        reloadHome={reloadHome}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
        icon={emailIcon}
        actionOptions={actionOptions}
      />
      <div>
        <ActionFormTitle>Para</ActionFormTitle>
        <GeestSelect
          value={
            selectedAction?.addresses
              ?.map(({ value }: { value: string | number }) => String(value))
              ?.join(",") || ""
          }
          onChange={(val) => onChangeEmail(val, "addresses")}
          options={[...getFieldOptions("Email"), ...destinationEmails]}
          multiselect
          showTags
          required
        />
        <Row style={{ marginTop: "10px" }}>
          <StyledTextField
            placeholder="Ingresa un correo..."
            value={extraDestinationEmail}
            onChange={(e) => setExtraDestinationEmail(e.target.value)}
            style={{ width: "calc(100% - 145px)", marginRight: "10px" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (
                  extraDestinationEmail.match(
                    // eslint-disable-next-line no-useless-escape
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ) &&
                  !extraDestinationEmail.split("@")[0].match(/([à-ü]|[À-Ü])/)
                ) {
                  onAddDestinationEmail();
                }
              }
            }}
          />
          <Button
            type="secondary"
            Icon={AiOutlinePlus}
            disabled={
              !extraDestinationEmail.match(
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              ) || !!extraDestinationEmail.split("@")[0].match(/([à-ü]|[À-Ü])/)
            }
            onClick={onAddDestinationEmail}
          >
            Agregar
          </Button>
        </Row>
      </div>
      <div>
        <ActionFormTitle>CC</ActionFormTitle>
        <GeestSelect
          value={
            selectedAction?.ccAddresses
              ?.map(({ value }: { value: string | number }) => String(value))
              ?.join(",") || ""
          }
          onChange={(val) => onChangeEmail(val, "ccAddresses")}
          options={[...getFieldOptions("Email"), ...ccEmails]}
          multiselect
          showTags
        />
        <Row style={{ marginTop: "10px" }}>
          <StyledTextField
            placeholder="Ingresa un correo..."
            value={extraccEmail}
            onChange={(e) => setExtraCcEmail(e.target.value)}
            style={{ width: "calc(100% - 145px)", marginRight: "10px" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (
                  extraccEmail.match(
                    // eslint-disable-next-line no-useless-escape
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ) &&
                  !extraccEmail.split("@")[0].match(/([à-ü]|[À-Ü])/)
                ) {
                  onAddCCEmail();
                }
              }
            }}
          />
          <Button
            type="secondary"
            Icon={AiOutlinePlus}
            disabled={
              !extraccEmail.match(
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              ) || !!extraccEmail.split("@")[0].match(/([à-ü]|[À-Ü])/)
            }
            onClick={onAddCCEmail}
          >
            Agregar
          </Button>
        </Row>
      </div>
      <div>
        <ActionFormTitle>CCO</ActionFormTitle>
        <GeestSelect
          value={
            selectedAction?.ccoAddresses
              ?.map(({ value }: { value: string | number }) => String(value))
              ?.join(",") || ""
          }
          onChange={(val) => onChangeEmail(val, "ccoAddresses")}
          options={[...getFieldOptions("Email"), ...ccoEmails]}
          multiselect
          showTags
        />
        <Row style={{ marginTop: "10px" }}>
          <StyledTextField
            placeholder="Ingresa un correo..."
            value={extraccoEmail}
            onChange={(e) => setExtraCcoEmail(e.target.value)}
            style={{ width: "calc(100% - 145px)", marginRight: "10px" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (
                  extraccoEmail.match(
                    // eslint-disable-next-line no-useless-escape
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ) &&
                  !extraccoEmail.split("@")[0].match(/([à-ü]|[À-Ü])/)
                ) {
                  onAddCCoEmail();
                }
              }
            }}
          />
          <Button
            type="secondary"
            Icon={AiOutlinePlus}
            disabled={
              !extraccoEmail.match(
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              ) || !!extraccoEmail.split("@")[0].match(/([à-ü]|[À-Ü])/)
            }
            onClick={onAddCCoEmail}
          >
            Agregar
          </Button>
        </Row>
      </div>
      <div>
        <ActionFormTitle>Nombre de quien envía</ActionFormTitle>
        <GeestSelect
          value={selectedAction?.fromConfig}
          onChange={onChangeFromConfig}
          options={fromOptions}
        />
        {selectedAction?.fromConfig === "manual" && (
          <StyledTextField
            placeholder="Escribe aquí..."
            value={selectedAction?.fromName}
            style={{ marginTop: "6px" }}
            onChange={(e) => {
              if (selectedAction) {
                setSelectedAction({
                  ...selectedAction,
                  fromName: e.target.value,
                });
              }
            }}
            onBlur={() => sendAction(selectedAction)}
          />
        )}
      </div>
      <div>
        <ActionFormTitle>Email para recibir respuesta</ActionFormTitle>
        <GeestSelect
          value={
            selectedAction?.replyTo?.value
              ? String(selectedAction?.replyTo?.value)
              : ""
          }
          onChange={onChangeReplyTo}
          options={[...getFieldOptions("Email"), ...responseEmails]}
        />
        <Row style={{ marginTop: "10px" }}>
          <StyledTextField
            placeholder="Ingresa un correo..."
            value={extraResponseEmail}
            onChange={(e) => setExtraResponseEmail(e.target.value)}
            style={{ width: "calc(100% - 145px)", marginRight: "10px" }}
            onKeyDown={(e) => {
              if (
                extraResponseEmail.match(
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) &&
                e.key === "Enter"
              ) {
                onAddResponseEmail();
              }
            }}
          />
          <Button
            type="secondary"
            Icon={AiOutlinePlus}
            disabled={
              !extraResponseEmail.match(
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
            }
            onClick={onAddResponseEmail}
          >
            Agregar
          </Button>
        </Row>
      </div>
      <div>
        <ActionFormTitle>Agrega logotipo</ActionFormTitle>
        <GeestSelect
          value={logoType}
          onChange={onChangeLogoType}
          options={logoOptions}
        />
        {logoType === "url" && (
          <Button
            type="secondary"
            style={{ width: "190px", marginTop: "6px" }}
            onClick={() => onUpload("uploadLogo")}
            Icon={selectedAction.Logo ? undefined : AiOutlineUpload}
          >
            <input
              type="file"
              accept="image/*"
              id="uploadLogo"
              onChange={handleUploadLogo}
              style={{ display: "none" }}
            />
            {selectedAction?.Logo ? (
              <>
                <img
                  width="20px"
                  height="20px"
                  alt=""
                  src={selectedAction.Logo}
                  style={{ borderRadius: "50%", marginRight: "5px" }}
                />
                Tu logo
              </>
            ) : (
              "Subir logo"
            )}
          </Button>
        )}
      </div>
      <div>
        <ActionFormTitle>Asunto</ActionFormTitle>
        {!loading && (
          <GeestTextEditor
            editorState={subject}
            onEditorStateChange={onChangeSubject}
            editorBorder
            fieldEditorOrigin={fieldEditorOrigin}
            toolbarConfig={{
              field: true,
              inline: false,
              fontSize: false,
              fontFamily: false,
              list: false,
              textalign: false,
              colorPicker: false,
              link: false,
            }}
            toolbarExtraParams={{ fieldOptions: { largeButton: true } }}
            required={!selectedAction?.subject}
            onBlur={() => sendAction(selectedAction)}
          />
        )}
      </div>
      <div>
        <ActionFormTitle>Mensaje</ActionFormTitle>
        {!loading && (
          <GeestTextEditor
            editorState={message}
            onEditorStateChange={onChangeMessage}
            editorBorder
            fieldEditorOrigin={fieldEditorOrigin}
            toolbarConfig={{ field: true }}
            required={!selectedAction?.message}
            onBlur={() => sendAction(selectedAction)}
          />
        )}
      </div>
      <div>
        <ActionFormTitle>Adjuntar</ActionFormTitle>
        <GeestSelect
          value={
            selectedAction?.filesAttach
              ?.map(({ value }: { value: number }) => String(value))
              ?.join(",") || ""
          }
          onChange={onChangeAttachments}
          options={getFieldOptions("File")}
          multiselect
          showTags
        />
      </div>
    </ViewContentAction>
  );
};

export default SendEmailActionView;
