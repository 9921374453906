import React from "react";
import { getPlanColors } from "../../../../helpers";
import Limits from "../Limits";
import { Container, Content, Header, Title, Bold } from "./styles";

const TrialInfo = ({ currentPlan = {}, premium = false }) => {
  const { PlanName, Limits: planLimits = [] } = currentPlan;
  const planColor = getPlanColors(PlanName);
  return (
    <Container planColor={planColor.mainColor}>
      <Content>
        <Header justify="space-between" align="middle">
          <Title planColor={planColor.mainColor}>
            <Bold>Plan {PlanName}</Bold>
            {!premium && ": Prueba gratis"}
          </Title>
        </Header>
        <Limits limits={planLimits} planColor={planColor} />
      </Content>
    </Container>
  );
};

export default TrialInfo;
