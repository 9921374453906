import { ProcessStatus } from "./Pipeline.d";

export const getStatusInfo = (
  status: ProcessStatus
): { color: string; label: string } => {
  switch (status) {
    case "New":
      return { color: "yellow", label: "Nuevo" };
    case "OnTime":
      return { color: "cyan", label: "Iniciado" };
    case "Completed":
      return { color: "green", label: "Terminado" };
    case "Canceled":
      return { color: "default", label: "Cancelado" };
    case "WaitingToStart":
      return { color: "orange", label: "Por iniciar" };
    case "Stuck":
      return { color: "red", label: "Vencido" };
    default:
      return { color: "default", label: status };
  }
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "Nuevo":
      return "yellow";
    case "Iniciado":
      return "cyan";
    case "Terminado":
      return "green";
    case "Por iniciar":
      return "orange";
    case "Vencido":
      return "red";
    default:
      return "default";
  }
};
