import styled, { css } from "styled-components";
import { Row } from "antd";
import { Paper } from "@material-ui/core";
import SubmitButton from "../SubmitButton";
import { Field } from "formik";

export const Message = styled(Row)`
  font-family: "Gotham-Light";
  color: #828d9e;
  text-align: center;
  margin: 12px 60px;
  font-size: 14px;
`;

export const EmailInput = styled(Field)`
  font-family: "Gotham-Book";
  padding: 6px 10px;
  margin-bottom: 24px;
  color: #828d9e;
  border: 2px solid #edecec;
  border-radius: 6px;
  width: 100%;
  outline-width: 0;
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
  :disabled {
    background-color: #edecec;
    border-color: #edecec;
  }
  ${({ $error }) =>
    $error &&
    css`
      border-color: #db232c;
    `}
`;

export const StyledPaper = styled(Paper)`
  border-radius: 6px !important;
  width: 432px;
  display: flex;
  flex-direction: column;
`;

export const PopoverTitle = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 12px;
  font-family: "gotham-bold";
  p {
    margin: 0;
    font-size: 16px;
    color: #48505e;
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover {
      background-color: #d9d9d9;
      border-radius: 2px;
    }
  }
`;

export const StyledSubmitButton = styled(SubmitButton)`
  width: fit-content;
  padding: 6px 18px;
  margin: 0 auto;
`;
