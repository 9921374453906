import React from "react";
import styled from "styled-components";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const InvisibleRow = styled(TableRow)`
  height: 47px;
  visibility: hidden;
`;

interface InvisibleRowsProps {
  containerHeight: number;
  headerHeight: number;
  rowHeight: number;
  footerHeight: number;
  rowCount: number;
}

const calculateInvisibleRows = (
  containerHeight: number,
  headerHeight: number,
  rowHeight: number,
  footerHeight: number,
  rowCount: number
) => {
  const totalTableHeight =
    headerHeight + rowCount * rowHeight + (footerHeight ?? 0);
  const remainingHeight = containerHeight - totalTableHeight;
  return Math.max(0, Math.ceil(remainingHeight / rowHeight));
};

const InvisibleRows: React.FC<InvisibleRowsProps> = ({
  containerHeight,
  headerHeight,
  rowHeight,
  footerHeight,
  rowCount,
}) => {
  const invisibleRowsCount = calculateInvisibleRows(
    containerHeight,
    headerHeight,
    rowHeight,
    footerHeight,
    rowCount
  );

  return (
    <>
      {Array.from({ length: invisibleRowsCount - 1 }).map((_, i) => (
        <InvisibleRow key={`invisible-${i}`}>
          <TableCell>&nbsp;</TableCell>
        </InvisibleRow>
      ))}
    </>
  );
};

export default InvisibleRows;
