import React from "react";
import { useHistory } from "react-router-dom";
import { Languages } from "./Dictionary";
import { UserTask } from "../Dashboard.d";
import {
  TasksContainer,
  TasksHeader,
  TasksHeaderTitle,
  TasksHeaderItem,
  Tasks,
  Task,
  TaskItem,
  TaskIcons,
  TitleWrapper,
} from "./Styles";
import { ViewIcon, ViewMoreIcon } from "../../../../components/hoverIcons";
import Tooltip from "../../../../components/Tooltip";
//@ts-ignore
import emoji from "emoji-dictionary";

const {
  HeaderFollowUpLabel,
  ProcessDetailsTooltipLabel,
  ViewTaksTooltipLabel,
} = Languages["ESP"];

interface UserTasksInfoProps {
  userTasks: UserTask[];
  titleColor: string;
  titleLabel: string;
  dateLabel: string;
  setProcessToOpen: (id: number) => void;
  openMultiModal: () => void;
}

const UserTasksInfo: React.FC<UserTasksInfoProps> = ({
  userTasks,
  titleColor,
  titleLabel,
  dateLabel,
  setProcessToOpen,
  openMultiModal,
}) => {
  const history = useHistory();

  const openTask = (id: number) => history.push("/home/reports/tracking/" + id);

  const openProcessDetail = (id: number) => {
    setProcessToOpen(id);
    openMultiModal();
  };

  return (
    <TasksContainer>
      <TasksHeader>
        <TasksHeaderTitle $flex={3} $color={titleColor}>
          {titleLabel}
        </TasksHeaderTitle>
        <TasksHeaderItem $flex={3}>{HeaderFollowUpLabel}</TasksHeaderItem>
        <TasksHeaderItem $flex={2}>{dateLabel}</TasksHeaderItem>
        <TasksHeaderItem $flex={1}></TasksHeaderItem>
      </TasksHeader>
      <Tasks>
        {(userTasks || []).map((task) => (
          <Task key={task.IdTaskValue}>
            <TaskItem $flex={3} $center={false}>
              <TitleWrapper>
                {task.Title.length > 12 ? (
                  <Tooltip
                    title={task.Title.replace(
                      /:\w+:/gi,
                      (name) => emoji.getUnicode(name) ?? name
                    )}
                  >
                    <p>
                      {task.Title.replace(
                        /:\w+:/gi,
                        (name) => emoji.getUnicode(name) ?? name
                      )}
                    </p>
                  </Tooltip>
                ) : (
                  <p>
                    {task.Title.replace(
                      /:\w+:/gi,
                      (name) => emoji.getUnicode(name) ?? name
                    )}
                  </p>
                )}
              </TitleWrapper>
            </TaskItem>
            <TaskItem $flex={3} $center>
              <TitleWrapper>
                {task.ProcessExecutionTitle.length > 12 ? (
                  <Tooltip
                    title={task.ProcessExecutionTitle.replace(
                      /:\w+:/gi,
                      (name) => emoji.getUnicode(name) ?? name
                    )}
                  >
                    <p>
                      {task.ProcessExecutionTitle.replace(
                        /:\w+:/gi,
                        (name) => emoji.getUnicode(name) ?? name
                      )}
                    </p>
                  </Tooltip>
                ) : (
                  <p>
                    {task.ProcessExecutionTitle.replace(
                      /:\w+:/gi,
                      (name) => emoji.getUnicode(name) ?? name
                    )}
                  </p>
                )}
              </TitleWrapper>
            </TaskItem>
            {task.StartedAt && (
              <TaskItem $flex={2} $center>
                {task.StartedAt}
              </TaskItem>
            )}
            {task.Deadline && (
              <TaskItem $flex={2} $center>
                {task.Deadline}
              </TaskItem>
            )}
            {task.SchedStartTime && (
              <TaskItem $flex={2} $center>
                {task.SchedStartTime}
              </TaskItem>
            )}
            {task.EndedAt && (
              <TaskItem $flex={2} $center>
                {task.EndedAt}
              </TaskItem>
            )}

            <TaskIcons>
              <Tooltip title={ViewTaksTooltipLabel}>
                <ViewIcon onClick={() => openTask(task.IdTaskValue)} filled />
              </Tooltip>
              <Tooltip title={ProcessDetailsTooltipLabel}>
                <ViewMoreIcon
                  onClick={() => openProcessDetail(task.IdProcessExecution)}
                  colored
                />
              </Tooltip>
            </TaskIcons>
          </Task>
        ))}
      </Tasks>
    </TasksContainer>
  );
};

export default UserTasksInfo;
