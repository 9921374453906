import React, { useEffect, useContext } from "react";
import { RedirectContext } from "../../config/Redirect";
import { SesionContext } from "../../config/State";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import LoadingState from "../LoadingState/LoadingState";

const RedirectComp = () => {
  const [, setRedirectURL] = useContext(RedirectContext);
  const [sesion] = useContext(SesionContext);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Example:
    // http://localhost:3000/#/redirect?redirect=templates
    const { redirect } = queryString.parse(location.search);
    const redirectURL = `/home/${redirect}`;
    if (!redirect) return history.push("/");
    if (sesion) history.push(redirectURL);
    else {
      setRedirectURL(redirectURL);
      history.push("/auth");
    }
    //eslint-disable-next-line
  }, [sesion]);

  return (
    <div style={{ height: "100vh", width: "100%", padding: "0.5rem" }}>
      <LoadingState isLoading />
    </div>
  );
};

export default RedirectComp;
