import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import server from "../../../../api/server";
import { Languages } from "./Dictionary";
import { StateMachineContext } from "../index";
import { LoadingWrapperContext } from "../../LoadingWrapper";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PlanCard from "./PlanCard";
import PaymentDataCard from "./PaymentDataCard";
import ErrorModal from "./ErrorModal";
import States from "../States";
import { AlertContext } from "../../../../components/AppAlert/AppAlertContext";
import NewPlanCard from "./NewPlanCard";
import { useMutation } from "../../../../hooks";
import { MessagesContext } from "../../../../components/AppMessages";

const Container = styled(Row)`
  padding: 2rem;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

const CheckOut = () => {
  const { IdTeam } = useParams();
  const { CurrentState, setCurrentState } = useContext(StateMachineContext);
  const { showMessage } = useContext(MessagesContext);
  const { setIsActive } = useContext(AlertContext);
  const { setHardLoading } = useContext(LoadingWrapperContext);
  const stripe = useStripe();
  const elements = useElements();

  const [bussinesName, setBussinessName] = useState("");
  const [rfc, setRfc] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [regimen, setRegimen] = useState();
  const [plan, setPlan] = useState("");
  const [price, setPrice] = useState("");
  const [period, setPeriod] = useState("");
  const [notificationUsers, setNotificationUsers] = useState("");
  const [Users, setUsers] = useState("");
  const [error, setError] = useState(false);
  const [PaymentMethods, setPaymentMethods] = useState([]);
  const [SelectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [applicationTime, setApplicationTime] = useState("");
  const [amountToPay, setAmountToPay] = useState("");

  const [FrontView, setFrontView] = useState("");
  const history = useHistory();

  const getInfoFunc = {
    [States.CHECKOUT]: "Ver2-Subscription-Checkout-gi",
  };

  const submitInfoFunc = {
    [States.CHECKOUT]: "Ver2-Subscription-Checkout-hp",
  };

  const verifyPaymentFunc = {
    [States.CHECKOUT]: "Ver2-Subscription-Checkout-vp",
    [States.PAYMENT_OUTDATED]: "Ver2-Subscription-Checkout-vp",
  };

  const {
    BussinesNameLabel,
    RfcLabel,
    AddressLabel,
    ZipCodeLabel,
    CountryLabel,
    CityLabel,
    FieldsValidations,
  } = Languages["ESP"];

  useEffect(() => {
    server(getInfoFunc[CurrentState], null, { IdTeam }).then((response) => {
      if (response.Request_Error) {
        showMessage(response.Request_Error, "error");
      } else {
        const {
          Data: {
            PaymentMethods,
            FrontView,
            WaitingPlan,
            BillingInformation,
            AmountToPay,
          },
        } = response;
        if (AmountToPay) {
          setAmountToPay(AmountToPay);
        } else {
          setAmountToPay(WaitingPlan.Cost);
        }
        if (WaitingPlan.ApplicationTime) {
          setApplicationTime(WaitingPlan.ApplicationTime);
        }
        setFrontView(FrontView);

        if (BillingInformation) {
          const {
            SocialReason,
            RFC,
            Address,
            PostalCode,
            City,
            Country,
            EnterpriseRegimen,
            NotifyToCsv,
          } = BillingInformation;
          setPaymentMethods(PaymentMethods);
          setSelectedPaymentMethod(
            _.find(PaymentMethods, { IsFavorite: true })
          );
          setBussinessName(SocialReason);
          setRfc(RFC || "XAXX010101000");
          setAddress(Address);
          setZipCode(PostalCode);
          setCity(City);
          setCountry(Country);
          setRegimen(EnterpriseRegimen ? EnterpriseRegimen : null);
          setNotificationUsers(NotifyToCsv);
        }
        const { Cost, Title, BillingMode, Users } = WaitingPlan;
        setPlan(Title);
        setPrice(Cost);
        setPeriod(BillingMode);
        setUsers(Users);
      }
    });
    // eslint-disable-next-line
  }, [IdTeam]);

  const getCardInfo = async () => {
    const cardElement = elements.getElement(CardElement);

    const response = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    return response;
  };

  const getEndPoint = () => {
    switch (FrontView) {
      case "EditPlansUpdragePlan":
        return "Ver2-Subscription-Checkout-up";
      case "EditPlansDowngradePlan":
        return "Ver2-Subscription-Checkout-dp";
      case "PaymentOutdated":
        return "Ver2-Subscription-Checkout-mop";

      default:
        return submitInfoFunc[CurrentState];
    }
  };

  const [submitMutation] = useMutation({
    func: getEndPoint(),
    onSuccess: async (response) => {
      const {
        SubscriptionStateChangeTo,
        requires_action,
        Error_CardDeclined,
        Error_PaymentDeclined,
      } = response;
      if (SubscriptionStateChangeTo === "Successful") {
        showMessage(SubscriptionStateChangeTo, "success");
        if (CurrentState === States.PAYMENT_OUTDATED) setIsActive(false);
        history.go(0);
      }
      if (SubscriptionStateChangeTo === "Normal") {
        showMessage(SubscriptionStateChangeTo, "success");
        history.go(0);
      }
      if (Error_CardDeclined || Error_PaymentDeclined) {
        showMessage(Error_CardDeclined || Error_PaymentDeclined, "error");
        setError(true);
      }
      if (requires_action) {
        const { client_secret, PaymentIntentId, PaymentObject } =
          requires_action;
        setLoading(true);
        setHardLoading(true);
        const r = await stripe.confirmCardPayment(client_secret);
        if (r) {
          setLoading(false);
          setHardLoading(false);
          verifyUser(PaymentIntentId, PaymentObject);
        }
      }
      setLoading(false);
      setHardLoading(false);
    },
    onError: () => {
      setLoading(false);
      setHardLoading(false);
    },
  });

  const handleSubmit = async () => {
    if (!requirementsCompleted()) return;
    let PaymentMethodStripeId, PaymentMethodId;
    setLoading(true);
    setHardLoading(true);

    if (SelectedPaymentMethod) {
      PaymentMethodStripeId = SelectedPaymentMethod.StripeId;
      PaymentMethodId = SelectedPaymentMethod.PaymentMethodId;
    } else {
      if (FrontView !== "CheckoutNormalEditPlans-DowngradePlan") {
        const { error, paymentMethod } = await getCardInfo();
        if (error) {
          setLoading(false);
          setHardLoading(false);
          return showMessage(error.message, "error");
        }
        PaymentMethodStripeId = paymentMethod.id;
        PaymentMethodId = "0";
      }
    }
    switch (FrontView) {
      case "EditPlansDowngradePlan":
        submitMutation({
          args: {
            IdTeam,
          },
        });
        break;
      case "EditPlansUpdragePlan":
      case "PaymentOutdated":
      default:
        submitMutation({
          args: {
            IdTeam,
            PaymentMethod: {
              PaymentMethodStripeId,
              PaymentMethodId,
            },
            BillingInformation: {
              RFC: rfc,
              PostalCode: zipCode,
              Address: address,
              SocialReason: bussinesName,
              City: city,
              Country: country,
              EnterpriseRegimen: regimen,
              NotifyToCsv: notificationUsers,
            },
          },
        });
        break;
    }
  };

  const [verifyPayment] = useMutation({
    func: verifyPaymentFunc[CurrentState],
    onSuccess: (response) => {
      const { Success, Error_PaymentDeclined = "" } = response;
      if (Error_PaymentDeclined) {
        setError(true);
      } else {
        showMessage(Success, "success");
        history.go(0);
      }
      setLoading(false);
      setHardLoading(false);
    },
    onError: () => {
      setLoading(false);
      setHardLoading(false);
    },
  });

  const verifyUser = (PaymentIntentId, PaymentObject) => {
    setLoading(true);
    setHardLoading(true);
    verifyPayment({
      args: {
        IdTeam,
        PaymentIntentId,
        PaymentObject,
      },
    });
  };

  const requirementsCompleted = () => {
    let errors = [];

    if (FrontView !== "CheckoutNormalEditPlans-DowngradePlan") {
      if (!bussinesName) errors.push(FieldsValidations(BussinesNameLabel));
      if (!rfc) errors.push(FieldsValidations(RfcLabel));
      if (!address) errors.push(FieldsValidations(AddressLabel));
      if (!zipCode) errors.push(FieldsValidations(ZipCodeLabel));
      if (!city) errors.push(FieldsValidations(CityLabel));
      if (!country) errors.push(FieldsValidations(CountryLabel));
    }

    if (errors.length) {
      errors.forEach((err) => showMessage(err, "error"));
      return false;
    } else return true;
  };

  const onChooseOtherPlanOpc = () => {
    switch (CurrentState) {
      case States.CHECKOUT:
      case States.CHECKOUT_CHANGE_USERS:
      case States.PAYMENT_OUTDATED:
        onChooseOtherPlan(States.CHANGE_PLAN);
        break;
      case States.CHECKOUT_NORMAL_EDIT_PLAN:
        onChooseOtherPlan(States.CHANGE_PLAN_NORMAL_EDIT_PLAN);
        break;
      default:
        break;
    }
  };

  const [changeSubscriptionState] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: (response) => {
      const { State } = response;
      setCurrentState(State);
    },
  });

  const onChooseOtherPlan = (NextState) => {
    changeSubscriptionState({
      args: {
        IdTeam,
        NextState,
      },
    });
  };

  const onCancelOpc = () => {
    switch (CurrentState) {
      case States.CHECKOUT:
      case States.CHECKOUT_CHANGE_USERS:
      case States.CHECKOUT_NORMAL_EDIT_PLAN:
        if (FrontView === "PaymentOutdated") {
          onCancel("ErrorPaymentNotCompleted");
        } else {
          onCancel("ChangePlan");
        }
        break;
      case States.PAYMENT_OUTDATED:
        onCancel("ErrorPaymentNotCompleted");
        break;
      default:
        break;
    }
  };

  const onCancel = (NextState) => {
    changeSubscriptionState({
      args: {
        IdTeam,
        NextState,
      },
    });
  };

  return (
    <Container>
      <Col xs={24} md={6}>
        <PlanCard
          plan={plan}
          price={price}
          period={period}
          MaxUsers={""}
          MinUsers={""}
          users={Users}
          setUsers={setUsers}
          AmountPerUser={0}
          FrontView={FrontView}
          amountToPay={amountToPay}
        />
      </Col>
      <Col xs={24} md={15}>
        {(() => {
          switch (FrontView) {
            case "EditPlansDowngradePlan":
              return (
                <NewPlanCard
                  price={price}
                  date={applicationTime}
                  onCancelOpc={onCancelOpc}
                  onChooseOtherPlan={onChooseOtherPlanOpc}
                  onSubmit={handleSubmit}
                  loading={Loading}
                />
              );

            default:
              return (
                <PaymentDataCard
                  bussinesName={bussinesName}
                  setBussinessName={setBussinessName}
                  rfc={rfc}
                  setRfc={setRfc}
                  address={address}
                  setAddress={setAddress}
                  zipCode={zipCode}
                  setZipCode={setZipCode}
                  city={city}
                  setCity={setCity}
                  country={country}
                  setCountry={setCountry}
                  regimen={regimen}
                  setRegimen={setRegimen}
                  notificationUsers={notificationUsers}
                  setNotificationUsers={setNotificationUsers}
                  agreed={agreed}
                  setAgreed={setAgreed}
                  handleSubmit={handleSubmit}
                  Cards={PaymentMethods}
                  SelectedPaymentMethod={SelectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  disableSubmit={!stripe || !elements}
                  Loading={Loading}
                  onCancelOpc={onCancelOpc}
                  onChooseOtherPlan={onChooseOtherPlanOpc}
                />
              );
          }
        })()}
      </Col>
      <ErrorModal visible={error} setVisible={setError} />
    </Container>
  );
};

export default CheckOut;
