import React from "react";
import _ from "lodash";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { IClientOrigin } from "./../sales.d";
import { EmptyData } from "../components";

interface Record {
  Cerrados: number;
  Iniciados: number;
  Perdidos: number;
  Label: string;
}

const Origination: React.FC<{ data?: IClientOrigin; loading: boolean }> = ({
  data,
  loading,
}) => {
  if (loading) return <div>Cargando...</div>;
  if (
    !data ||
    (_.isEmpty(data.GraphData.Iniciados) &&
      _.isEmpty(data.GraphData.Cerrados) &&
      _.isEmpty(data.GraphData.Perdidos))
  )
    return <EmptyData />;

  const initializeRecords = (): Record[] => {
    let keys: string[] = [];
    // getKeys
    _.values(data.GraphData).forEach((category) =>
      category.forEach(({ Key }) => keys.push(Key))
    );
    // Uniq and initialize
    return _.uniq(keys).map((Label) => ({
      Label,
      Cerrados: 0,
      Iniciados: 0,
      Perdidos: 0,
    }));
  };

  const parseData = (): Record[] => {
    let records = initializeRecords();
    _.map(data.GraphData, (value, category) => {
      value.forEach(({ Key, Value }) => {
        const idx = _.findIndex(records, { Label: Key });
        _.set(records, [idx, category], Value);
      });
    });
    return records;
  };

  return (
    <ResponsiveContainer maxHeight={350}>
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={parseData()}>
        <PolarGrid />
        <PolarAngleAxis dataKey="Label" />
        <PolarRadiusAxis angle={30} />
        <Legend />
        <Tooltip />
        <Radar
          name="Iniciados"
          dataKey="Iniciados"
          stroke="#898989"
          fill="#898989"
          fillOpacity={0.3}
        />
        <Radar
          name="Perdidos"
          dataKey="Perdidos"
          stroke="#ff4045"
          fill="#ff4045"
          fillOpacity={0.3}
        />
        <Radar
          name="Cerrados"
          dataKey="Cerrados"
          stroke="#6cff89"
          fill="#6cff89"
          fillOpacity={0.3}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default Origination;
