import React from "react";
import DynamicInput from "../../../../../../../components/DynamicInput";
import {
  FieldConfigWrapper,
  StyledSwitch,
  SubTitle,
  SwitchContainer,
  SwitchItem,
} from "../../Styles";

interface LocationConfigProps {
  value: string | null;
  onChange: (newValue: string) => void;
  configuration: string;
  setConfiguration: (newConfig: string) => void;
  disabled: boolean;
}

const LocationConfig: React.FC<LocationConfigProps> = ({
  value,
  onChange,
  configuration,
  setConfiguration,
  disabled,
}) => {
  return (
    <>
      <FieldConfigWrapper>
        <SubTitle>Valor por defecto</SubTitle>
        <DynamicInput
          value={value}
          type="location"
          dataOrigin={[]}
          format=""
          fieldName=""
          configuration={configuration}
          isConsult={false}
          disabled={disabled}
          required={false}
          onChange={onChange}
        />
      </FieldConfigWrapper>
      <SwitchContainer>
        <SwitchItem>
          <StyledSwitch
            checked={configuration === "SelectAndCurrent"}
            onChange={(val) =>
              setConfiguration(val ? "SelectAndCurrent" : "OnlyCurrent")
            }
            disabled={disabled}
          />
          <SubTitle>Permitir seleccionar otras ubicaciones</SubTitle>
        </SwitchItem>
      </SwitchContainer>
    </>
  );
};

export default LocationConfig;
