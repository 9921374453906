import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import { PageView } from "../../analytics";
import ViewTitle from "../../components/ViewTitle/ViewTitle";
import StateMachine from "./StateMachine";
import { FormControl, withStyles } from "@material-ui/core";
import LoadingWrapper from "./LoadingWrapper";
import { useMutation } from "../../hooks";
import GeestSelect from "../../GeestUI/GeestSelect";
import { Row } from "antd";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Content = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  overflow: auto;
  position: relative;
`;

export const SelectStyle = withStyles({
  root: {
    width: "15rem",
    "& label.Mui-focused": {
      color: "black",
    },
    "& label": {
      fontFamily: "Gotham-Book",
      fontSize: "medium",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid black",
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(FormControl);

const Languages = {
  ENG: {
    teams: "Teams",
    title: "My subscription",
  },
  ESP: {
    teams: "Empresas",
    title: "Mi suscripción",
  },
};

const Premium = () => {
  const { path: BasePath, isExact } = useRouteMatch();
  const history = useHistory();
  const { title } = Languages["ESP"];

  const [Teams, setTeams] = useState([]);
  const [CurrentTeam, setCurrentTeam] = useState("");

  useEffect(() => {
    PageView("/premium");
  }, []);

  const [getSubscriptionListOfTeams] = useMutation({
    func: "Ver2-Subscription-gslot",
    onSuccess: (response) => {
      if (Array.isArray(response) && response[0]) {
        const { IdTeam } = response[0];
        setTeams(response);
        if (isExact) {
          setCurrentTeam(IdTeam);
          history.push(`${BasePath}/${IdTeam}`);
        }
      }
    },
  });

  useEffect(() => {
    getSubscriptionListOfTeams({ args: {} });
    // eslint-disable-next-line
  }, [isExact]);

  const handleChangeTeam = (value) => {
    setCurrentTeam(value);
    history.push(`${BasePath}/${value}`);
  };

  const teamOptions =
    Teams?.map(({ IdTeam, Name }) => ({ label: Name, value: IdTeam })) || [];

  return (
    <Container>
      <ViewTitle>
        <Row>
          {title}
          <Divider
            type="vertical"
            style={{
              height: "2rem",
              background: "#D5D5D3",
              margin: "0rem 1rem",
            }}
          />
          <GeestSelect
            options={teamOptions}
            value={Teams.length ? +CurrentTeam : ""}
            onChange={handleChangeTeam}
            placeholderSelect="Selecciona una empresa"
            $width="20rem"
            $listWidth="20rem"
            style={{
              fontSize: "14px",
              fontFamily: "Gotham-Book",
              marginLeft: "10px",
            }}
          />
        </Row>
      </ViewTitle>
      <Content>
        <LoadingWrapper>
          <Switch>
            <Route
              path={BasePath + "/:IdTeam"}
              exact
              render={() => <StateMachine setCurrentTeam={setCurrentTeam} />}
            />
          </Switch>
        </LoadingWrapper>
      </Content>
    </Container>
  );
};

export default Premium;
