import React from "react";
import { NodeEditorProps } from "./NodeEditor.d";
import { CustomNodeTypes } from "../CustomNodes";
import TaskEditor from "./TaskEditor";
import AutomationEditor from "./AutomationEditor";
import ConditionEditor from "./ConditionEditor";
import { TriggersEditor } from "./TriggersEditor";
import SubfluxEditor from "./SubfluxEditor";

const NodeEditor: React.FC<NodeEditorProps> = (props) => {
  const { node } = props;
  switch (node.type as CustomNodeTypes) {
    case CustomNodeTypes.START_NODE:
      return <TriggersEditor {...props} />;
    case CustomNodeTypes.TASK_NODE:
      return <TaskEditor {...props} />;
    case CustomNodeTypes.CONDITIONAL_NODE:
      return <ConditionEditor {...props} />;
    case CustomNodeTypes.AUTOMATION_NODE:
      return <AutomationEditor {...props} />;
    case CustomNodeTypes.SUBFLUX_NODE:
      return <SubfluxEditor {...props} />;
    default:
      return null;
  }
};

export default NodeEditor;
