import React from "react";
import { IconContainer } from "./styles";
import { FiTrash } from "react-icons/fi";
import { ReactComponent as DeleteIconFilled } from "../../resources/img/DeleteIcon.svg";

const iconSize: { [key: number]: number } = {
  20: 14,
  26: 17,
  32: 22,
};

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  $width?: string;
  $height?: string;
  size?: 20 | 26 | 32;
  filled?: boolean;
  fillColor?: string;
  containerSize?: number;
  customIconSize?: number;
  hoverContainer?: boolean;
}

const DeleteIcon: React.FC<IconProps> = ({
  $width = "14px",
  $height = "14px",
  size = 20,
  filled,
  fillColor = "#db232c",
  containerSize,
  customIconSize,
  hoverContainer,
  ...props
}) => {
  return (
    <IconContainer
      size={containerSize ? containerSize : size}
      fillColor={fillColor}
      hoverColor={fillColor}
      hoverContainerColor={hoverContainer ? "#fff1f0" : ""}
      {...props}
    >
      {filled ? (
        <DeleteIconFilled width={$width} height={$height} />
      ) : (
        <FiTrash
          color="#db232c"
          size={customIconSize ? customIconSize : iconSize[size]}
        />
      )}
    </IconContainer>
  );
};

export default DeleteIcon;
