import React, { useEffect, useRef } from "react";
import {
  Title,
  Subtitle,
  ConfigRow,
  Content,
  ButtonContainer,
  StyledInput,
  GeneralStepContainer,
  LeftPannel,
  RightPannel,
  StatsTablePreviewContainer,
} from "./styles";
import { Button } from "../../../../../../../GeestUI";
import { GeneralStepProps } from "./Steps.d";
import GeestSelect from "../../../../../../../GeestUI/GeestSelect";
import StatsTablePreview from "../../../../../../../resources/img/statsTablePreview.svg";

const GeneralStep: React.FC<GeneralStepProps> = ({
  setIdProcessTemplate,
  setTableName,
  IdProcessTemplate,
  TableName,
  IdGraph = "",
  setActiveStep,
  processOptions,
  setGroupByField,
  setUnsavedChanges,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const options = processOptions.map(({ IdProcessTemplate, Name }) => ({
    label: Name,
    value: IdProcessTemplate,
  }));

  return (
    <>
      <GeneralStepContainer>
        <LeftPannel>
          <Content>
            <Title $fontSize="24px" style={{ width: "435px" }}>
              Configura los parámetros generales de tu tabla
            </Title>
            <Subtitle style={{ fontSize: "14px" }}>
              Nombra tu nueva tabla y selecciona el proceso que quieras
              reportar.
            </Subtitle>
            <ConfigRow style={{ marginTop: "3rem" }}>
              <Title>Nombre</Title>
              <StyledInput
                value={TableName}
                onChange={(e) => {
                  setTableName(e.target.value);
                  setUnsavedChanges();
                }}
                placeholder="Nombre de tabla..."
                autoFocus
                ref={inputRef}
              />
            </ConfigRow>
            <ConfigRow>
              <Title>Proceso</Title>
              <GeestSelect
                value={IdProcessTemplate}
                onChange={(val) => {
                  setIdProcessTemplate(val);
                  setGroupByField(undefined);
                  setUnsavedChanges();
                }}
                options={options}
                $width="236px"
                valueNecesary
              />
            </ConfigRow>
          </Content>
        </LeftPannel>

        <RightPannel>
          <StatsTablePreviewContainer>
            <img src={StatsTablePreview} alt="stats table preview" />
          </StatsTablePreviewContainer>
        </RightPannel>
      </GeneralStepContainer>
      <ButtonContainer>
        <Button
          type="primary"
          disabled={!TableName || !IdProcessTemplate}
          onClick={() => setActiveStep(1)}
          size="large"
        >
          Continuar
        </Button>
      </ButtonContainer>
    </>
  );
};

export default GeneralStep;
