import React from "react";
import {
  DataTypes,
  FileValueType,
  FormatValueType,
  SelectValueType,
  User,
} from "../DynamicCell.d";
import StringComponent from "./StringComponent";
import DateComponent from "./DateComponent";
import UserSelectComponent from "./UserSelectComponent";
import NumberComponent from "./NumberComponent";
import FormatValueComponent from "./FormatValueComponent";
import FileComponent from "./FileComponent";
import SelectComponent from "./SelectComponent";
import LocationComponent from "./LocationComponent";

interface InfoFromComponentProps {
  dataType: DataTypes | undefined;
  stringValue?: string;
  SelectValue?: SelectValueType | undefined | null;
  dateValue?: string;
  numValue?: number | undefined | null;
  userValue?: number[];
  formatValue?: FormatValueType;
  filesValue?: FileValueType[];

  userSelectDataOrigin?: User[];
}

const InfoFromComponent: React.FC<InfoFromComponentProps> = ({
  dataType,
  stringValue = "",
  SelectValue,
  dateValue = "",
  numValue,
  userValue,
  formatValue,
  filesValue,

  userSelectDataOrigin = [],
}) => {
  if (dataType === DataTypes.STRING) {
    return <StringComponent value={stringValue} />;
  }

  if (dataType === DataTypes.LOCATION) {
    return <LocationComponent value={stringValue} />;
  }

  if (dataType === DataTypes.SELECT || dataType === DataTypes.MULTI_SELECT) {
    return <SelectComponent selectValue={SelectValue} />;
  }

  if (dataType === DataTypes.DATE || dataType === DataTypes.TIME) {
    return <DateComponent value={dateValue} dataType={dataType} />;
  }

  if (dataType === DataTypes.NUMBER) {
    return <NumberComponent value={numValue} />;
  }

  if (dataType === DataTypes.USERS_SELECT) {
    return (
      <UserSelectComponent
        value={userValue ?? []}
        UserSelectDataOrigin={userSelectDataOrigin}
      />
    );
  }

  if (dataType === DataTypes.PHONE_NUMBER || dataType === DataTypes.CURRENCY) {
    return (
      <FormatValueComponent
        dataType={dataType}
        formatValue={formatValue ?? { Value: "", Format: "" }}
      />
    );
  }

  if (dataType === DataTypes.FILE || dataType === DataTypes.PDF_AUTOGENERATED) {
    return <FileComponent filesValue={filesValue ?? []} />;
  }

  return <div />;
};

export default InfoFromComponent;
