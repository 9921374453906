import React from "react";
import { IconContainer } from "./styles";
import { AiFillPlayCircle } from "react-icons/ai";

const iconSize: { [key: number]: number } = {
  14: 10,
  20: 16,
};

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 14 | 20;
}

const PlayIcon: React.FC<IconProps> = ({ size = 20, ...props }) => (
  <IconContainer size={size} style={{ paddingLeft: "0.5px" }} {...props}>
    <AiFillPlayCircle color="#0273E9" size={iconSize[size]} />
  </IconContainer>
);

export default PlayIcon;
