import React from "react";
import { Typography } from "../../GeestUI";

const { Text } = Typography;

export const VersionCode: string = "2.5.9";

const GeestVersion: React.FC<{
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}> = ({ style = {}, textStyle = {} }) => (
  <div
    data-testid="geestVersion"
    style={{
      textAlign: "center",
      color: "var(--light-gray)",
      fontSize: "unset",
      ...style,
    }}
  >
    <Text
      style={textStyle}
    >{`${VersionCode} ${process.env.REACT_APP_VERSION}`}</Text>
  </div>
);

export default GeestVersion;
