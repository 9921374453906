import React from "react";
import { noop as NOOP } from "lodash";
import { SelectWrapper, StyledContentRow } from "./Styles";
import { Row } from "antd";
import Vt from "../../../../components/ViewTitle/ViewTitle";
import GeestSelect from "../../../../GeestUI/GeestSelect/GeestSelect";
import Loading from "../../../../resources/img/loading.svg";
import { ButtonSizes } from "../../../../GeestUI/Button/Button.d";

const getIconSize = (size: ButtonSizes) => {
  if (size === "small") {
    return 14;
  }
  if (size === "medium") {
    return 18;
  }
  return 20;
};

const LoadingIcon: React.FC<{ size: ButtonSizes }> = ({ size }) => (
  <img
    src={Loading}
    alt="loading"
    style={{
      width: `${getIconSize(size)}px`,
      height: `${getIconSize(size)}px`,
    }}
  />
);

interface ViewTitleProps {
  children: JSX.Element;
  idTeam?: number;
  teams?: any[];
  onChangeTeam?: (newTeamId: number) => void;
  gettingPipelineReport?: boolean;
  showBack?: boolean;
  rightElement?: JSX.Element;
}

const ViewTitle: React.FC<ViewTitleProps> = ({
  children,
  idTeam = 0,
  teams = [],
  onChangeTeam = NOOP,
  gettingPipelineReport,
  showBack = false,
  rightElement,
}) => {
  const teamOptions =
    teams?.map(({ IdTeam, Name }) => ({ label: Name, value: IdTeam })) || [];

  return (
    <Vt ShowTeamIcon ShowBack={showBack}>
      <Row align="middle" justify="space-between">
        <StyledContentRow>
          {children}
          <SelectWrapper>
            <GeestSelect
              options={teamOptions}
              value={idTeam}
              onChange={onChangeTeam}
              placeholderSelect="Selecciona una empresa"
              $width="224px"
              $listWidth="224px"
              style={{
                fontSize: "14px",
                fontFamily: "Gotham-Book",
                marginLeft: "10px",
              }}
              valueNecesary
              renderPlaceholderIfNoValueInList
            />
            {gettingPipelineReport && <LoadingIcon size="regular" />}
          </SelectWrapper>
        </StyledContentRow>
        {rightElement}
      </Row>
    </Vt>
  );
};

export default ViewTitle;
