import React, { useState } from "react";
import ViewTitle from "../../components/ViewTitle/ViewTitle";
import { useHistory } from "react-router-dom";
import {
  ColumnsViewTitleContainer,
  SearchBar,
  ColumnsListContainer,
} from "./Styles";
import { ListManager } from "react-beautiful-dnd-grid";
import { useFetch, useMutation } from "../../hooks";
import { useParams } from "react-router-dom";
import { VarDBColumnList } from "./DataBases.d";
import VarDBColumnListItem from "./VarDBColumnListItem";

const DataBaseColumns: React.FC = () => {
  const history = useHistory();
  const { IdTeam, IdVarDB } = useParams<{ IdTeam: string; IdVarDB: string }>();
  const [filter, setFilter] = useState<string>("");
  const [varDBColumns, setVarDBColumns] = useState<VarDBColumnList[]>([]);
  const { reload } = useFetch<VarDBColumnList[]>({
    func: "Ver2-Vardbs-gvc",
    args: {
      IdTeam,
      IdVarDB,
    },
    onSuccess: setVarDBColumns,
  });

  const getMaxItems = () => {
    if (varDBColumns.length < 24) {
      return 2;
    }
    if (varDBColumns.length < 34) {
      return 3;
    }
    if (varDBColumns.length < 45) {
      return 4;
    }
    return 5;
  };

  const getItemWidth = () => {
    const width = window.innerWidth - 400;
    return width / getMaxItems() - 10;
  };

  const [reorderVarDBColumn] = useMutation<[]>({
    func: "Ver2-Vardbs-rovc",
    onSuccess: reload,
    onError: reload,
  });

  const onDragEnd = (from: number, to: number) => {
    if (to === from) return;
    let varDBColumnsAux = [...varDBColumns];
    const IdVarDBColumn = varDBColumns[from].Id;
    varDBColumnsAux.splice(to, 0, varDBColumnsAux.splice(from, 1)[0]);
    setVarDBColumns(varDBColumnsAux);
    reorderVarDBColumn({
      args: {
        IdTeam,
        IdVarDB,
        LastPosition: from + 1,
        Position: to + 1,
        IdVarDBColumn,
      },
    });
  };

  return (
    <>
      <ViewTitle ShowBack ShowTeamIcon onBack={history.goBack}>
        Columnas
      </ViewTitle>
      <ColumnsViewTitleContainer>
        Organiza tus columnas arrastrándolas y soltándolas en el orden que las
        necesites
        <SearchBar
          placeholder="Buscar"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </ColumnsViewTitleContainer>
      <ColumnsListContainer>
        {varDBColumns.length > 0 && (
          <ListManager
            maxItems={getMaxItems()}
            items={varDBColumns}
            onDragEnd={onDragEnd}
            direction="horizontal"
            render={(column) => (
              <VarDBColumnListItem
                width={getItemWidth()}
                column={column}
                filter={filter}
              />
            )}
          />
        )}
      </ColumnsListContainer>
    </>
  );
};

export default DataBaseColumns;
