import React, { useState, useEffect, useContext } from "react";
import {
  FormField,
  VarDBRowField,
} from "../../../Pendings/DetailPending/DetailPending.d";
import { FieldList } from "../../../Pendings/DetailPending/MicroComponents";
import { InputStyled, Row, FieldContainer } from "../styles";
import { useFetch, useMutation } from "../../../../hooks";
import { Button } from "../../../../GeestUI";
import { FieldsProps, GetProcessFieldsResponse } from "../ProcessDetail.d";
import { useParams } from "react-router-dom";
import {
  FileField,
  FileType,
  onPasteImage,
  SelectFileFieldModal,
} from "../../../../components/DynamicInput/Components/helpers/FileHelpers";
import _ from "lodash";
import { sanitizeSearchValues } from "../../../../Helpers";
import { MessagesContext } from "../../../../components/AppMessages";

const Fields: React.FC<FieldsProps> = ({
  processInfo,
  setShouldOpenUnsaved,
  reloadProcess,
}) => {
  const { idProcess } = useParams<{ idProcess: string }>();
  const [editableFields, setEditableFields] = useState<FormField[]>([]);
  const [auxFields, setAuxFields] = useState<FormField[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [fileFields, setFileFields] = useState<FileField[]>([]);
  const [copiedFile, setCopiedFile] = useState<FileType | null>();
  const [isFileOpenDetails, setIsFileOpenDetails] = useState<boolean>(false);
  const [fieldsBeforeChangeRow, setFieldsBeforeChangeRow] = useState<
    FormField[]
  >([]);
  const { showMessage } = useContext(MessagesContext);

  const { data, loading, reload } = useFetch<GetProcessFieldsResponse>({
    func: "Ver2-Reports-gpf",
    args: {
      IdProcessExecution: idProcess,
    },
    onSuccess: ({ Fields }) => {
      setEditableFields(Fields);
      const fileFields = Fields.filter(
        (field) => field.DataType === "file"
      ).map((field) => ({
        Id: field.IdField,
        Label: field.Label,
      }));
      let fileCells: FileField[] = [];
      Fields.filter((field) => field.DataType === "varDBRow").forEach(
        (field) => {
          const fileFields = (field as unknown as VarDBRowField).Cells.filter(
            (field) => field.DataType === "file"
          ).map((cell) => ({
            Id: cell.IdField,
            Label: `${field.Label} > ${cell.Label}`,
          }));
          fileCells = [...fileCells, ...fileFields];
        }
      );
      setFileFields([...fileFields, ...fileCells]);
      setAuxFields(_.cloneDeep(Fields));
    },
  });

  const [saveProcessFields, saving] = useMutation<[]>({
    func: "Ver2-Reports-spf",
    onSuccess: () => {
      setShouldOpenUnsaved(false);
      showMessage("Actualización exitosa", "success");
      setFieldsBeforeChangeRow([]);
      reloadProcess();
    },
  });

  const handleFilter = (value: string) => {
    if (value === "" && fieldsBeforeChangeRow.length > 0) {
      setFieldsBeforeChangeRow([]);
      reload();
    } else if (value === "") {
      setEditableFields([]);
      reload();
    }
    setFilter(value);
  };

  const savePrevStateBeforeChangeRow = () => {
    let updatedFields = _.cloneDeep(editableFields);
    updatedFields.map((field) => {
      if (field.DataType === "varDBRow") {
        if (
          typeof (field as unknown as VarDBRowField).IdRowSelected !== "number"
        ) {
          (field as unknown as VarDBRowField).IdRowSelected = 0;
          return field;
        }
      }
      return field;
    });

    setFieldsBeforeChangeRow(updatedFields);
  };

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = () => {
    let updatedFields = _.cloneDeep(editableFields);
    updatedFields.map((field) => {
      if (field.DataType === "varDBRow") {
        if (
          typeof (field as unknown as VarDBRowField).IdRowSelected !== "number"
        ) {
          (field as unknown as VarDBRowField).IdRowSelected = 0;
          return field;
        }
      }
      return field;
    });

    setAuxFields(updatedFields);
    saveProcessFields({
      args: {
        IdProcessExecution: idProcess,
        Fields: updatedFields,
      },
    });
  };

  const getFilteredFieldGroup = (group: 1 | 2) => {
    let filteredFields = [...editableFields];
    let filteredCells: FormField[] = [];
    const fieldsAux = _.cloneDeep(editableFields);
    if (filter) {
      filteredFields = fieldsAux.filter(({ Label }) =>
        sanitizeSearchValues(Label).includes(sanitizeSearchValues(filter))
      );
      filteredCells = fieldsAux
        .filter(
          ({ DataType, Label }) =>
            DataType === "varDBRow" &&
            !sanitizeSearchValues(Label).includes(sanitizeSearchValues(filter))
        )
        .map((field) => {
          (field as unknown as VarDBRowField).Cells = (
            field as unknown as VarDBRowField
          ).Cells.filter(({ Label }) =>
            sanitizeSearchValues(Label).includes(sanitizeSearchValues(filter))
          );
          return field;
        })
        .filter(
          (field) => (field as unknown as VarDBRowField).Cells.length > 0
        );
      filteredFields = [...filteredFields, ...filteredCells];
    }
    if (group === 1) {
      return filteredFields.filter((f, i) => i % 2 === 0);
    } else {
      return filteredFields.filter((f, i) => i % 2 > 0);
    }
  };

  const handleOnUpdateField = (updatedField: FormField): void => {
    let newEditableFields = _.cloneDeep(editableFields);
    const fieldIndex = _.findIndex(editableFields, {
      IdField: updatedField.IdField,
    });
    if (fieldIndex >= 0) {
      newEditableFields[fieldIndex] = updatedField;
      setEditableFields([...newEditableFields]);
    } else {
      const index = editableFields.findIndex(
        (field) =>
          (field as unknown as VarDBRowField).IdVarDBGroup ===
          (updatedField as unknown as VarDBRowField).IdVarDBGroup
      );
      if (index >= 0) {
        newEditableFields[index] = updatedField;
        setEditableFields([...newEditableFields]);
      }
    }
    const hasUnsavedChanges = !_(newEditableFields)
      .differenceWith(auxFields, _.isEqual)
      .isEmpty();
    setShouldOpenUnsaved(hasUnsavedChanges);
  };

  const onPasteImageToField = (IdField: number, file: FileType) => {
    const value = file;
    const index = editableFields.findIndex(
      (field) => field.IdField === IdField
    );
    let EditableFieldsAux = [...editableFields];
    if (index >= 0) {
      const oldValue = (editableFields[index] as FormField).Value as any[];
      EditableFieldsAux[index].Value = oldValue
        ? [...oldValue, value]
        : [value];
      setEditableFields(EditableFieldsAux);
    } else {
      const index = editableFields.findIndex(
        (field) =>
          !!(field as unknown as VarDBRowField).Cells?.find(
            (cell) => cell.IdField === IdField
          )
      );
      if (index >= 0) {
        const vardbRowInput = editableFields[index];
        const cells = (vardbRowInput as unknown as VarDBRowField).Cells.map(
          (cell) => {
            if (cell.IdField === IdField) {
              return {
                ...cell,
                Value: cell.Value ? [...(cell.Value as any[]), value] : [value],
              };
            }
            return cell;
          }
        );
        let EditableFieldsAux = [...editableFields];
        (EditableFieldsAux[index] as unknown as VarDBRowField).Cells = cells;
        setEditableFields(EditableFieldsAux);
      }
    }
    const hasUnsavedChanges = !_(EditableFieldsAux)
      .differenceWith(auxFields, _.isEqual)
      .isEmpty();
    setShouldOpenUnsaved(hasUnsavedChanges);
  };

  useEffect(() => {
    if (fileFields.length > 0) {
      const onKeyDown = async (e: KeyboardEvent) => {
        if (e.key.toLowerCase() === "v" && e.ctrlKey) {
          const value = await onPasteImage(data?.FilesPath || "");
          if (value) {
            if (fileFields.length > 1) {
              setCopiedFile(value);
            } else {
              onPasteImageToField(fileFields[0].Id, value);
            }
          }
        }
      };
      window.addEventListener("keydown", onKeyDown);

      return () => window.removeEventListener("keydown", onKeyDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileFields, editableFields]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: "16px", flex: 1 }}
    >
      {!!copiedFile && (
        <SelectFileFieldModal
          file={copiedFile}
          onChangeFileField={onPasteImageToField}
          fileFields={fileFields}
          closeModal={() => setCopiedFile(null)}
        />
      )}
      <Row style={{ gap: "8px" }}>
        <InputStyled
          value={filter}
          onChange={(e) => handleFilter(e.target.value)}
          placeholder="Buscar..."
        />
        {processInfo?.Permissions.UserCanEditProcess && (
          <Button
            type="primary"
            style={{ width: "125px" }}
            onClick={onSubmit}
            loading={loading || saving}
          >
            Guardar
          </Button>
        )}
      </Row>
      <FieldContainer>
        <div style={{ width: "calc(50% - 20px)", height: "100%" }}>
          <FieldList
            fields={getFilteredFieldGroup(1)}
            filesPath={data?.FilesPath}
            editable={processInfo?.Permissions.UserCanEditProcess}
            onUpdateField={handleOnUpdateField}
            UserSelectDataOrigin={data?.UserSelectDataOrigin || []}
            VarDBDataOrigin={data?.VarDBDataOrigin || []}
            isFileOpen={isFileOpenDetails}
            setIsFileOpen={setIsFileOpenDetails}
            IdProcessExecution={Number(idProcess) || undefined}
            savePrevStateBeforeChangeRow={savePrevStateBeforeChangeRow}
          />
        </div>
        <div style={{ width: "calc(50% - 20px)", height: "100%" }}>
          <FieldList
            fields={getFilteredFieldGroup(2)}
            filesPath={data?.FilesPath}
            editable={processInfo?.Permissions.UserCanEditProcess}
            onUpdateField={handleOnUpdateField}
            UserSelectDataOrigin={data?.UserSelectDataOrigin || []}
            VarDBDataOrigin={data?.VarDBDataOrigin || []}
            isFileOpen={isFileOpenDetails}
            setIsFileOpen={setIsFileOpenDetails}
            IdProcessExecution={Number(idProcess) || undefined}
            savePrevStateBeforeChangeRow={savePrevStateBeforeChangeRow}
          />
        </div>
      </FieldContainer>
    </div>
  );
};

export default Fields;
