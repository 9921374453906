import React from "react";
import { Container, Content, CardTitle, StyledButton, MainText } from "./Style";
import GeestIcon from "../../../../resources/img/ICONO_.svg";
import { Row, Button } from "antd";
import moment from "moment";

interface CardProps {
  price: number;
  date: string;
  onCancelOpc: () => void;
  onChooseOtherPlan: () => void;
  onSubmit: () => void;
  loading: boolean;
}

const NewPlanCard: React.FC<CardProps> = ({
  price,
  date,
  onCancelOpc,
  onChooseOtherPlan,
  onSubmit,
  loading,
}) => {
  return (
    <>
      <Row justify="end">
        <StyledButton onClick={onChooseOtherPlan}>
          Elegir otro plan
        </StyledButton>
      </Row>
      <Container>
        <Content>
          <CardTitle style={{ textAlign: "center" }}>Cambio de Plan</CardTitle>
          <Row justify="center">
            <img
              src={GeestIcon}
              alt="geest"
              style={{ width: "15rem", height: "15rem" }}
            />
          </Row>
          <Row justify="center">
            <MainText
              style={{ textAlign: "center", width: "20rem", fontSize: "18px" }}
            >
              {`Tu primer factura será de $${price} y llegará el ${moment(
                date
              ).format("DD/MMM/YY")}`}
            </MainText>
          </Row>
          <Row justify="center" style={{ marginTop: "3rem" }}>
            <Row justify="space-between" style={{ width: "20rem" }}>
              <Button
                style={{
                  borderRadius: "10px",
                  color: "#525151",
                  backgroundColor: "white",
                  width: "8.5rem",
                }}
                onClick={onCancelOpc}
                loading={loading}
              >
                Cancelar
              </Button>
              <Button
                style={{
                  borderRadius: "10px",
                  color: "white",
                  backgroundColor: "green",
                  borderColor: "white",
                  marginLeft: "1rem",
                  width: "10.5rem",
                }}
                onClick={onSubmit}
                loading={loading}
              >
                Finalizar cambio
              </Button>
            </Row>
          </Row>
        </Content>
      </Container>
    </>
  );
};

export default NewPlanCard;
