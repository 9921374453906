import React, { useState } from "react";
import {
  StateContainer,
  FreeTrialHeader,
  SearchBar,
  TrialBadge,
  TrialCount,
  TableContainer,
  TableHeader,
  TableRow,
  TableCell,
  TinyEmail,
  TrialStatusBadge,
  EndDateBadge,
} from "../styles";
import { ReactComponent as CardIcon } from "../../../resources/img/freeTrialCardIcon.svg";
import { useFetch } from "../../../hooks";
import { FreeTrialResponse, FreeTrial } from "../Intranet.d";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { sanitizeSearchValues } from "../../../Helpers";
import { Button } from "../../../GeestUI";
import moment from "moment";
import Tooltip from "../../../components/Tooltip";
import {
  getStatusLabel,
  getFreeTrialBackground,
  getFreeTrialCardColor,
  getFreeTrialColor,
} from "./helper";
import { ExtendTrialModal } from "./components";
import Loading from "../../../components/NewLoading/NewLoading";

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

const FreeTrials: React.FC = () => {
  const [filter, setFilter] = useState<string>("");
  const [trialToExtend, setTrialToExtend] = useState<FreeTrial | null>(null);
  const { data, reload, loading } = useFetch<FreeTrialResponse>({
    func: "Ver2-Intranet-Free-gift",
  });

  const filteredItems =
    data?.FreeTrials.filter((trial) =>
      sanitizeSearchValues(
        `${trial.Name} ${trial.Lider.Email} ${getStatusLabel(trial.Status)} ${
          trial.Users
        }`
      ).includes(sanitizeSearchValues(filter))
    ) || [];

  const getEndDateColors = (endDate: string) => {
    const dayDiff = moment(endDate).diff(moment(), "days");
    if (dayDiff >= 4) {
      return {
        color: "#1F9334",
        background: "#E2FBD9",
      };
    }
    if (dayDiff <= 3 && dayDiff >= 1) {
      return {
        color: "#D46B08",
        background: "#FFF7E6",
      };
    }
    return {
      color: "#DB232C",
      background: "#FFF1F0",
    };
  };

  if (loading) {
    return (
      <StateContainer>
        <Loading width={200} />
      </StateContainer>
    );
  }

  const renderFreeTrial = ({ index, key, style, parent }: any) => {
    const trial = filteredItems[index];

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <TableRow style={style}>
          <TableCell style={{ justifyContent: "flex-start" }} $width={214}>
            <Tooltip title={trial.Name}>{trial.Name}</Tooltip>
          </TableCell>
          <TableCell $width={100}>{trial.IdTeam}</TableCell>
          <TableCell $width={136}>
            <Button onClick={() => setTrialToExtend(trial)}>
              Extender prueba
            </Button>
          </TableCell>
          <TableCell $width={99}>
            <TrialStatusBadge
              $color={getFreeTrialColor(trial.Status)}
              $background={getFreeTrialBackground(trial.Status)}
            >
              {getStatusLabel(trial.Status)}
              <CardIcon color={getFreeTrialCardColor(trial.Status)} />
            </TrialStatusBadge>
          </TableCell>
          <TableCell $width={250} style={{ justifyContent: "flex-start" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: 0 }}>
              {trial.Lider.Name}
              <TinyEmail>{trial.Lider.Email}</TinyEmail>
            </div>
          </TableCell>
          <TableCell $width={76}>
            {moment(trial.StartTime).format("DD/MMM")}
          </TableCell>
          <TableCell $width={93}>
            <EndDateBadge
              $color={getEndDateColors(trial.EndTime).color}
              $background={getEndDateColors(trial.EndTime).background}
            >
              {moment(trial.EndTime).format("DD/MMM")}
            </EndDateBadge>
          </TableCell>
          <TableCell $width={101}>{trial.Amount}</TableCell>
          <TableCell $width={76}>{trial.UserCount}</TableCell>
          <TableCell $width={81}>{trial.ProcessCount}</TableCell>
          <TableCell $width={101}>{trial.ExecutionCount}</TableCell>
          <TableCell $width={303} style={{ justifyContent: "flex-start" }}>
            <Tooltip title={trial.Users}>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  maxWidth: "calc(100% - 20px)",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  alignSelf: trial.UserCount > 1 ? "flex-start" : "",
                  whiteSpace: "pre-wrap",
                  lineHeight: "18px",
                }}
              >
                {trial.Users}
              </div>
            </Tooltip>
          </TableCell>
        </TableRow>
      </CellMeasurer>
    );
  };

  const rowHeight = 40;

  return (
    <StateContainer>
      {!!trialToExtend && (
        <ExtendTrialModal
          onClose={() => setTrialToExtend(null)}
          reload={reload}
          trial={trialToExtend}
        />
      )}
      <FreeTrialHeader>
        <SearchBar
          style={{ width: "337px" }}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Buscar empresa o correo..."
        />
        <TrialBadge
          $color={getFreeTrialColor("active_free_cardless_trial")}
          $background={getFreeTrialBackground("active_free_cardless_trial")}
        >
          <TrialCount>{data?.Totals.active_free_cardless_trial}</TrialCount>
          Activas
          <CardIcon
            color={getFreeTrialCardColor("active_free_cardless_trial")}
          />
        </TrialBadge>
        <TrialBadge
          $color={getFreeTrialColor("active_bonus_trial")}
          $background={getFreeTrialBackground("active_bonus_trial")}
        >
          <TrialCount>{data?.Totals.active_bonus_trial}</TrialCount>
          Activas
          <CardIcon color={getFreeTrialCardColor("active_bonus_trial")} />
        </TrialBadge>
        <TrialBadge
          $color={getFreeTrialColor("ended_free_cardless_trial")}
          $background={getFreeTrialBackground("ended_free_cardless_trial")}
        >
          <TrialCount>{data?.Totals.ended_free_cardless_trial}</TrialCount>
          Vencidas
          <CardIcon
            color={getFreeTrialCardColor("ended_free_cardless_trial")}
          />
        </TrialBadge>
        <TrialBadge
          $color={getFreeTrialColor("canceled_bonus_trial")}
          $background={getFreeTrialBackground("canceled_bonus_trial")}
        >
          <TrialCount>{data?.Totals.canceled_bonus_trial}</TrialCount>
          Canceladas
          <CardIcon color={getFreeTrialCardColor("canceled_cardless_trial")} />
        </TrialBadge>
      </FreeTrialHeader>
      <StateContainer style={{ maxWidth: "100%" }}>
        <TableContainer>
          <TableHeader>
            <TableCell style={{ justifyContent: "flex-start" }} $width={214}>
              Empresa
            </TableCell>
            <TableCell $width={100}>Id de equipo</TableCell>
            <TableCell $width={136}>Acción</TableCell>
            <TableCell $width={99}>Estatus</TableCell>
            <TableCell $width={250}>Líder</TableCell>
            <TableCell $width={76}>Inico</TableCell>
            <TableCell $width={93}>Fin</TableCell>
            <TableCell $width={101}>Cobro (USD)</TableCell>
            <TableCell $width={76}>Usuarios</TableCell>
            <TableCell $width={81}>Procesos</TableCell>
            <TableCell $width={101}>Ejecuciones</TableCell>
            <TableCell $width={303}>Miembros</TableCell>
          </TableHeader>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={rowHeight}
                rowRenderer={renderFreeTrial}
                rowCount={filteredItems.length}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        </TableContainer>
      </StateContainer>
    </StateContainer>
  );
};

export default FreeTrials;
