import React from "react";
import styled from "styled-components";
import { Button } from "../../../../../../../GeestUI";
import { useHistory, useParams } from "react-router-dom";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface PDFAutoGeneratedConfigProps {
  IdField: number | null;
  disabled: boolean;
}

const PDFAutoGeneratedConfig: React.FC<PDFAutoGeneratedConfigProps> = ({
  IdField,
  disabled,
}) => {
  const history = useHistory();

  const { IdTeam, IdProcess } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();

  const openPDFConfiguration = () => {
    history.push(
      "/home/pdf-configurator/" + IdTeam + "/" + IdProcess + "/" + IdField
    );
  };

  return (
    <ButtonWrapper>
      <Button
        type="primary"
        size="large"
        disabled={disabled || IdField === 0 || IdField === null}
        onClick={openPDFConfiguration}
      >
        Configurar PDF
      </Button>
    </ButtonWrapper>
  );
};

export default PDFAutoGeneratedConfig;
