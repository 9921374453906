import { Row, Space } from "antd";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../../GeestUI";
import { StyledInput } from "./../styles";
interface RequestNodeNameProps {
  onSubmit: (nodeName: string) => void;
  onCancel: () => void;
}

const RequestNodeName: React.FC<RequestNodeNameProps> = ({
  onSubmit,
  onCancel,
}) => {
  const [nodeName, setNodeName] = useState<string>("");
  const isValidForm = !_.isEmpty(_.trim(nodeName));

  const inputElement = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputElement.current?.focus();
  }, []);

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit(_.trim(nodeName));
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <div style={{ margin: "0px 15px" }}>
        <StyledInput
          value={nodeName}
          onChange={(e) => setNodeName(e.target.value)}
          placeholder="Escribe el nombre del nodo..."
          type="text"
          ref={inputElement}
          style={{
            marginBottom: "1.2rem",
            marginTop: "0.5rem",
          }}
        />
      </div>
      <Row justify="center">
        <Space align="center">
          <Button type="secondary" onClick={onCancel}>
            Cancelar
          </Button>
          <Button disabled={!isValidForm} type="primary" htmlType="submit">
            Guardar
          </Button>
        </Space>
      </Row>
    </form>
  );
};

export default RequestNodeName;
