import React from "react";
import styled from "styled-components";
import { ReactComponent as PDFIconSVG } from "../../resources/img/pdfIcon.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
`;

interface PDFIconProps {}

const PDFIcon: React.FC<PDFIconProps> = () => {
  return (
    <IconWrapper>
      <PDFIconSVG width="14px" height="14px" />
    </IconWrapper>
  );
};

export default PDFIcon;
