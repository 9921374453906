import { Row, Button, Select } from "antd";
import styled from "styled-components";
import { TextField, withStyles } from "@material-ui/core";
import { IoClose } from "react-icons/io5";

export const Container = styled(Row)`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  width: 100%;
  margin-top: ${({ $margin }) => ($margin ? "6.5rem" : "1.5rem")};
  overflow: hidden;
  border: ${({ $border }) => $border};
`;

export const Content = styled.div`
  flex: 1;
  padding: 2rem;
  color: #535151;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height};
`;

export const CardTitle = styled.div`
  font-family: "Gotham-Bold";
  color: ${({ $color }) => $color};
  font-size: 20px;
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
`;

export const MainText = styled.div`
  font-family: "Gotham-Bold";
  color: #696969;
  font-size: ${({ $fontSize }) => $fontSize};
`;

export const ContentText = styled.div`
  font-family: "Gotham-Book";
  color: ${({ $color }) => ($color ? "#80808073" : "grey")};
  font-size: ${({ $fontSize }) => $fontSize};
`;

export const StyledTextFiel = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "grey",
    },
    "& label": {
      fontFamily: "Gotham-Book",
      fontSize: "medium",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Gotham-Book",
      borderRadius: ".4rem",
      marginBottom: "1.4rem",
      "&.Mui-focused fieldset": {
        borderColor: "grey",
      },
    },
  },
})(TextField);

export const StyledSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #c0c0c0;
    border-radius: 0.4rem;
    height: 2.5rem;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    :hover {
      border: 1px solid grey;
    }
  }
  &.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    font-size: medium;
    font-family: Gotham-Book;
    height: 1.1876;
    text-align-vertical: center;
    align-self: center;
    margin-top: 2px;
  }
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-size: medium;
    font-family: Gotham-Book;
    height: 1.1876;
    text-align-vertical: center;
    align-self: center;
  }
  .ant-select-selection-placeholder {
    font-size: medium;
    font-family: Gotham-Book;
    color: grey;
    height: 1.1876;
    text-align-vertical: center;
    align-self: center;
  }
`;

export const StyledButton = styled(Button)`
  width: 25%;
  border-radius: 10px;
  border: 1px solid #4488c7;
`;

export const CardButton = styled(Button)`
  border-radius: 10px;
  border-color: white;
  font-family: "Gotham-Bold";
  color: #696969;
  font-size: 16px;
  background-color: ${({ $active }) => ($active ? "#e0e0e0" : "transparent")};
`;

export const NotificationUsersContainer = styled.div`
  border: ${({ show }) => (show ? "1px solid #aaa" : "none")};
  border-radius: 10px;
  width: 100%;
  margin-bottom: ${({ show }) => (show ? "1rem" : "0")};
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: ${({ show }) => (show ? "5px" : "0")};

  transition: height 0.35s ease, margin-bottom 0.35s ease;
`;

export const EmailContainer = styled.div`
  border: 1px solid #aaa;
  border-radius: 10px;
  max-width: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: width 0.35s ease;

  p {
    margin: 0;
    overflow-wrap: break-word;
  }
`;

export const DeleteEmailButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${({ show }) => (show ? "16px" : "0")};
  height: ${({ show }) => (show ? "16px" : "0")};
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: ${({ show }) => (show ? "1" : "0")};
  transition: background-color 0.35s ease, opacity 0.35s ease;
  :hover {
    background-color: rgba(0, 0, 0, 0.45);
  }
`;

export const CloseIcon = styled(IoClose)`
  cursor: pointer;
  color: white;
`;
