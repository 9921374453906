import React from "react";
import styled from "styled-components";
import { ChatChannel } from "../Pendings.d";

const Container = styled.div`
  p {
    margin: 0;
    padding: 6px 10px;
    background: white;
    color: #828d9e;
    cursor: pointer;
    transition: background 0.35s ease;
    :hover {
      background: #edecec;
    }
  }
`;

interface ChatListPopoverProps {
  chats: ChatChannel[];
  selectChat: (id: number) => void;
  onClose: () => void;
}

const ChatListPopover: React.FC<ChatListPopoverProps> = ({
  chats,
  selectChat,
  onClose,
}) => {
  const handleOnSelect = (id: number) => {
    selectChat(id);
    onClose();
  };

  return (
    <Container>
      {[...chats].map((chat, i) => (
        <p
          key={`${chat.IdExecutionChat}-${i}`}
          onClick={() => handleOnSelect(chat.IdExecutionChat)}
        >
          {chat.Label}
        </p>
      ))}
    </Container>
  );
};

export default ChatListPopover;
