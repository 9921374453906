import React from "react";
import {
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledP,
  StyledB,
  StyledLight,
  StyledText,
} from "./styles";
import {
  H1Props,
  H2Props,
  H4Props,
  ParagraphProps,
  TextProps,
  BoldProps,
  LightProps,
} from "./Typography.d";

/** Size: 22px */
export const H1: React.FC<H1Props> = ({ children, ...props }) => (
  <StyledH1 {...props}>{children}</StyledH1>
);

/** Size: 18px */
export const H2: React.FC<H2Props> = ({ children, ...props }) => (
  <StyledH2 {...props}>{children}</StyledH2>
);

/** Size: 16px */
export const H3: React.FC<H4Props> = ({ children, ...props }) => (
  <StyledH3 {...props}>{children}</StyledH3>
);

/** Size: 14px */
export const H4: React.FC<H4Props> = ({ children, ...props }) => (
  <StyledH4 {...props}>{children}</StyledH4>
);

/** Size: 14px */
export const P: React.FC<ParagraphProps> = ({ children, ...props }) => (
  <StyledP {...props}>{children}</StyledP>
);

/** Size: 14px */
export const Text: React.FC<TextProps> = ({ children, ...props }) => (
  <StyledText {...props}>{children}</StyledText>
);

export const B: React.FC<BoldProps> = ({ children, ...props }) => (
  <StyledB {...props}>{children}</StyledB>
);

export const L: React.FC<LightProps> = ({ children, ...props }) => (
  <StyledLight {...props}>{children}</StyledLight>
);
