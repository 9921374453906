export const Languages = {
  ENG: {
    SomethingWrong: "Something went wrong",
    WelcomeMessage: "Welcome",
    LoadingLabel: "Loading...",
    NotificationsLabel: "Notifications",
    MyTeamsLabel: "My teams",
    MyAccountLabel: "My account",
    UpgradePlanLabel: "Upgrade my plan",
    WelcomeLabel: "Welcome to",
    WatchVideoLabel:
      "Now you are ready to start being more productive and change the work culture of your work team",
  },
  ESP: {
    SomethingWrong: "Algo salió mal",
    WelcomeMessage: "Bienvenid@",
    LoadingLabel: "Cargando...",
    NotificationsLabel: "Notificaciones",
    MyTeamsLabel: "Mis empresas",
    MyAccountLabel: "Mi cuenta",
    UpgradePlanLabel: "Mejorar mi plan",
    WelcomeLabel: "Bienvenido a",
    WatchVideoLabel:
      "Ahora estás listo para comenzar a ser más productivo y cambiar la cultura laboral de tu empresa de trabajo",
  },
};
