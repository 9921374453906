import React from "react";
import { DynamicInputProps } from "./DynamicInput.d";
import StringInput from "./Components/StringInput";
import NumberInput from "./Components/NumberInput";
import PhoneNumberInput from "./Components/PhoneNumberInput";
import DateInput from "./Components/DateInput";
import TimeInput from "./Components/TimeInput";
import CurrentLocationInput from "./Components/CurrentLocationInput";
import CurrencyInput from "./Components/CurrencyInput";
import SelectInput from "./Components/SelectInput";
import MultiSelectInput from "./Components/MultiSelectInput";
import UserSelectInput from "./Components/UsersSelectInput";
import CheckListInput from "./Components/CheckListInput";
import FileInput from "./Components/FileInput";
import VarDBRowInput from "./Components/VarDBRowInput";
import PDFAutoGenerated from "./Components/PDFAutoGenerated";
import SignatureInput from "./Components/SignatureInput";

const DynamicInput: React.FC<DynamicInputProps> = (props) => {
  const { type } = props;
  switch (type) {
    case "string":
      return <StringInput {...props} />;
    case "number":
      return <NumberInput {...props} />;
    case "phone_number":
      return <PhoneNumberInput {...props} />;
    case "date":
      return <DateInput {...props} />;
    case "time":
      return <TimeInput {...props} />;
    case "location":
      return <CurrentLocationInput {...props} />;
    case "currency":
      return <CurrencyInput {...props} />;
    case "select":
      return <SelectInput {...props} />;
    case "multi_select":
      return <MultiSelectInput {...props} />;
    case "users_select":
      return <UserSelectInput {...props} />;
    case "checklist":
      return <CheckListInput {...props} />;
    case "file":
      return <FileInput {...props} />;
    case "varDBRow":
      return <VarDBRowInput {...props} />;
    case "pdf_autogenerated":
      return <PDFAutoGenerated {...props} />;
    case "signature":
      return <SignatureInput {...props} />;
    default:
      return (
        <div style={{ backgroundColor: "salmon" }}>
          <small style={{ margin: 0 }}>
            there's still no DynamicInput for type:{" "}
            <span style={{ fontFamily: "Gotham-Bold" }}>{props.type}</span>
          </small>
        </div>
      );
  }
};

export default DynamicInput;
