import React, { useEffect, useRef, useState, useContext } from "react";
import _ from "lodash";
import { Tag, Row } from "antd";
import { Popover } from "@material-ui/core";
import { useFetch, useMutation } from "../../../../../../../hooks";
import RolesPopover from "../../../../../../Teams/TeamDetails/MembersList/RolesPopover";
import { Languages } from "../../Dictionary";
import { getTriggerManualResponse, TeamRole } from "../../TriggersConfig.d";
import {
  ViewContentManualTrigger,
  ManualTriggerMetaData,
  ManualTriggerContent,
  RolesContainer,
  ManualTriggerTitle,
  ManualTriggerDescription,
  InputStyled,
  RolesBox,
  TriggerFormTitle,
} from "../Style";
import manualTriggerIcon from "../../../../../../../resources/img/manualTriggerIcon.svg";
import { EditIcon } from "../../../../../../../components/hoverIcons";
import { Button } from "../../../../../../../GeestUI";
import { HiMenuAlt2 } from "react-icons/hi";
import { ConfiguratorContext } from "../../../../../Configurator";
import GeestSelect from "../../../../../../../GeestUI/GeestSelect";
import GeestTextEditor from "../../../../../../../components/GeestTextEditor";
import {
  editorStateRaw,
  editorStateToHTML,
} from "../../../../../../../components/GeestTextEditor/Utils/Editor";
import { EditorState, convertFromRaw } from "draft-js";
import { useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";

const { ManualTriggerTitleLabel, ManualTriggerRolesLabel } = Languages["ESP"];

interface ManualTriggerViewProps {
  IdTeam: string;
  IdPrepublishTrigger: string;
  setView: (newView: string) => void;
  reloadHome: () => void;
  Label?: string;
}

const ManualTriggerView: React.FC<ManualTriggerViewProps> = ({
  IdTeam,
  IdPrepublishTrigger,
  setView,
  reloadHome,
  Label,
}) => {
  const [title, setTitle] = useState<string>("");
  const [teamRoles, setTeamRoles] = useState<TeamRole[]>([]);
  const [allowRoles, seetAllowRoles] = useState<TeamRole[]>([]);
  const [editingRoles, setEditingRoles] = useState<boolean>(false);
  const [editingTitle, setEditingTitle] = useState<boolean>(false);
  const [processTitleMode, setProcessTitleMode] = useState<string>("");
  const [processTitle, setProcessTitle] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const editRolesButton = useRef<HTMLDivElement>(null);
  const { refreshProcessFlow } = useContext(ConfiguratorContext);
  const { IdProcess: IdProcessTemplate } = useParams<{ IdProcess: string }>();
  const [warningEnitytFields, setWarningEntityFields] = useState<string[]>([]);

  const { data, reload } = useFetch<getTriggerManualResponse>({
    func: "Ver2-Configurator-gtd",
    args: {
      IdTeam,
      IdPrepublishTrigger,
      IdProcessTemplate,
    },
    onSuccess: (response) => {
      setTeamRoles(response.TeamRoles);
      seetAllowRoles(response.TriggerAllowRoles || []);
      setProcessTitleMode(response.TitleMode);
      if (response.ProcessTitle) {
        setProcessTitle(
          EditorState.createWithContent(convertFromRaw(response.ProcessTitle))
        );
      }
    },
  });

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IdPrepublishTrigger]);

  useEffect(() => {
    setTitle(Label || "");
  }, [Label]);

  useEffect(() => {
    const rawEditor = editorStateRaw(processTitle);
    if (rawEditor.entityMap) {
      setWarningEntityFields([]);
    }
    const validFieldIds = data?.Fields.map(({ Value }) => Value);
    const validEntityFields = (
      Object.values(rawEditor.entityMap) as unknown as any[]
    )
      .filter(({ data, type }) => {
        if (type === "FIELD") {
          return (
            !!data.IdFieldSelected &&
            validFieldIds?.includes(String(data.IdFieldSelected))
          );
        }
        return false;
      })
      .map(({ data }) => data.IdFieldSelected);
    const warningFields = data?.Fields.filter(
      ({ Value, FillAtStart }) =>
        validEntityFields.includes(Value) && !FillAtStart
    ).map(({ Label }) => Label);
    setWarningEntityFields(warningFields || []);
  }, [processTitle, data]);

  const [updateTriggerDetailInformation] = useMutation({
    func: "Ver2-Configurator-utdi",
    onSuccess: () => {
      reloadHome();
      refreshProcessFlow();
    },
  });

  const getProcessHasValue = (processTitle: EditorState) => {
    if (!processTitle?.getCurrentContent().hasText()) {
      return false;
    }
    const rawEditor = editorStateRaw(processTitle);
    const htmlEditor = editorStateToHTML(processTitle)
      .replaceAll("<p>", "")
      .replaceAll("</p>", "")
      .replaceAll(/\[\[\| FIELD:\w+ \|\]\]/g, "")
      .replaceAll("&nbsp;", "")
      .replaceAll("\n", "")
      .trim();
    if (!htmlEditor) {
      if (!rawEditor.entityMap) {
        return false;
      }
      const validFieldIds = data?.Fields.map(({ Value }) => Value);
      const validEntityFields = (
        Object.values(rawEditor.entityMap) as unknown as any[]
      ).filter(({ data, type }) => {
        if (type === "FIELD") {
          return (
            !!data.IdFieldSelected &&
            validFieldIds?.includes(String(data.IdFieldSelected))
          );
        }
        return false;
      });
      if (validEntityFields.length === 0) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = ({
    Label = title,
    roles = allowRoles,
    TitleMode = processTitleMode,
    ProcessTitle = processTitle,
  }) => {
    if (TitleMode === "field" && !getProcessHasValue(processTitle)) {
      return;
    }
    updateTriggerDetailInformation({
      args: {
        IdTeam,
        Type: data?.Type || "",
        Label,
        IdPrepublishTrigger,
        TriggerAllowRoles: roles,
        TitleMode,
        ProcessTitle: editorStateRaw(ProcessTitle),
      },
    });
  };

  const handleOnChangeTitle = () => {
    const trimTitle = _.trim(title);
    const isTitleEmpty = _.isEmpty(trimTitle);
    const isSameTitle = _.isEqual(trimTitle, data?.Label || "");
    setEditingTitle(false);
    if (isTitleEmpty || isSameTitle) return;
    handleSubmit({ Label: trimTitle });
  };

  const handleUpdateRoles = (oldRoles: TeamRole[], newRoles: TeamRole[]) => {
    seetAllowRoles(newRoles);
    if (!_.isEmpty(newRoles)) handleSubmit({ roles: newRoles });
  };

  const handleOnChangeTitleMode = (titleMode: string) => {
    setProcessTitleMode(titleMode);
    handleSubmit({ TitleMode: titleMode });
  };

  const titleModeOptions = [
    {
      label: "Se escribe al iniciar el proceso",
      value: "manual",
    },
    {
      label: "Calculado a partir de campos",
      value: "field",
    },
    {
      label: "Autogenerado por Geest",
      value: "auto",
    },
  ];

  const editorFields = data?.Fields.map(({ Value, DataType, Label }) => ({
    Label,
    DataType,
    IdField: String(Value),
  }));

  return (
    <ViewContentManualTrigger>
      <ManualTriggerMetaData>
        <img src={manualTriggerIcon} width="40px" height="40px" alt="" />

        <ManualTriggerContent>
          {editingTitle ? (
            <InputStyled
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={handleOnChangeTitle}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleOnChangeTitle();
                }
              }}
              onFocus={(e) => e.target.select()}
              autoFocus
            />
          ) : (
            <Row align="bottom">
              <ManualTriggerTitle>{title}</ManualTriggerTitle>
              <EditIcon
                size={32}
                filled
                onClick={() => setEditingTitle(true)}
              />
            </Row>
          )}
          <ManualTriggerDescription>
            {ManualTriggerTitleLabel}
          </ManualTriggerDescription>
        </ManualTriggerContent>

        {/*<ManualTriggerExtraData>
          {hasPendingChanges ? (
            <AlertContainer>
              <Tooltip title={HasChangesTooltipLabel} placement="bottomRight">
                <AiOutlineInfoCircle size={16} color="#c3331e" />
              </Tooltip>
              <p>{HasChangesLabel}</p>
            </AlertContainer>
          ) : (
            <div style={{ width: "150px" }} />
          )}
          </ManualTriggerExtraData>*/}
      </ManualTriggerMetaData>

      <RolesContainer>
        <TriggerFormTitle>{ManualTriggerRolesLabel}</TriggerFormTitle>
        <RolesBox ref={editRolesButton} onClick={() => setEditingRoles(true)}>
          {[...allowRoles].map((role, i) => (
            <Tag
              key={role.IdTeamRole}
              color={role.Color || "#c0c9d3"}
              style={{
                minWidth: "77px",
                minHeight: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontFamily: "Gotham-Bold",
                borderRadius: "6px",
                fontSize: "14px",
                margin: 0,
              }}
            >
              <div
                style={{
                  whiteSpace: "normal",
                  textAlign: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {role.IdTeamRole === 0 ? "Todos" : role.Label}
              </div>
            </Tag>
          ))}
        </RolesBox>

        <Popover
          open={editingRoles}
          anchorEl={editRolesButton.current}
          onClose={() => setEditingRoles(false)}
          PaperProps={{
            style: {
              borderRadius: "6px",
              width: "362px",
              padding: "15px",
              marginTop: "-2rem",
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <RolesPopover
            idTeam={IdTeam}
            value={allowRoles}
            teamRoles={teamRoles}
            isLeader={true}
            fromTriggers
            onChange={handleUpdateRoles}
            onClose={() => setEditingRoles(false)}
            reload={reload}
          />
        </Popover>
      </RolesContainer>

      <RolesContainer>
        <TriggerFormTitle>
          Configura los campos a solicitar al inicio de tu proceso
        </TriggerFormTitle>
        <Button
          Icon={HiMenuAlt2}
          onClick={() => setView("editManualFields")}
          type="secondary"
          style={{ width: "222px", marginTop: "10px" }}
        >
          Campos de formulario
        </Button>
      </RolesContainer>
      <RolesContainer>
        <TriggerFormTitle>
          ¿De dónde proviene el título del seguimiento?
        </TriggerFormTitle>
        <GeestSelect
          value={processTitleMode}
          onChange={handleOnChangeTitleMode}
          $width="249px"
          options={titleModeOptions}
          style={{ marginBottom: "6px" }}
          valueNecesary
        />
        {processTitleMode === "auto" && (
          <Alert
            severity="info"
            style={{
              borderRadius: "6px",
              width: "fit-content",
            }}
          >
            El título de seguimiento será generado por Geest
          </Alert>
        )}
        {processTitleMode === "field" && (
          <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <GeestTextEditor
              editorState={processTitle}
              onEditorStateChange={setProcessTitle}
              editorBorder
              fieldEditorOrigin={editorFields}
              toolbarConfig={{
                field: true,
                inline: false,
                fontSize: false,
                fontFamily: false,
                list: false,
                textalign: false,
                colorPicker: false,
                link: false,
              }}
              toolbarExtraParams={{ fieldOptions: { largeButton: true } }}
              placeholder="Escribe aquí..."
              onBlur={() => handleSubmit({ ProcessTitle: processTitle })}
            />
            {warningEnitytFields.length > 0 && (
              <Alert
                severity="warning"
                style={{
                  borderRadius: "6px",
                  width: "fit-content",
                }}
              >
                Los siguientes campos seleccionados no están marcados como
                requeridos al inicio del proceso:
                {warningEnitytFields.map((label) => (
                  <>
                    <br />
                    {label}
                  </>
                ))}
              </Alert>
            )}
          </div>
        )}
      </RolesContainer>
    </ViewContentManualTrigger>
  );
};

export default ManualTriggerView;
