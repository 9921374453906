import React, { useState } from "react";
import Modal from "../../../components/Modal";
import { useFetch, useMutation } from "../../../hooks";
import { Button } from "../../../GeestUI";
import GeestSelect from "../../../GeestUI/GeestSelect";
import styled from "styled-components";

interface LinkVarDBModalProps {
  onClose: () => void;
  reload: () => void;
  IdTeam: string;
  IdVarDB: string;
  VarDBTitle: string;
}

interface ImportableVarDB {
  IdVarDBFather: number;
  Title: string;
}

const Description = styled.div`
  text-align: center;
  font-family: Gotham-Book;
  font-size: 14px;
  font-weight: 325;
  color: rgba(130, 141, 158, 1);
  line-height: 19.6px;
  width: 310px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;

const BoldDescription = styled.div`
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-algin: center;
  flex-wrap: wrap;
  gap: 10px;
`;

const LinkVarDBModal: React.FC<LinkVarDBModalProps> = ({
  onClose,
  IdTeam,
  IdVarDB,
  VarDBTitle,
  reload,
}) => {
  const [selectedVarDB, setSelectedVarDB] = useState<number>();

  const { data } = useFetch<ImportableVarDB[]>({
    func: "Ver2-Vardbs-glv",
    args: {
      IdTeam,
      IdVarDB,
    },
  });

  const [linkVarDB, sending] = useMutation({
    func: "Ver2-Vardbs-lv",
    onSuccess: () => {
      reload();
      onClose();
    },
  });

  const onSend = () => {
    if (!selectedVarDB) return;
    linkVarDB({
      args: {
        IdVarDB,
        IdVarDBFather: selectedVarDB,
        IdTeam,
      },
    });
  };

  const options =
    data?.map(({ IdVarDBFather, Title }) => ({
      label: Title,
      value: IdVarDBFather,
    })) || [];

  return (
    <Modal
      title="Vincular a otra base de datos"
      visible
      onCancel={onClose}
      width="409px"
      contentStyles={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
        gap: "20px",
      }}
    >
      <Description>
        Selecciona la base de datos a la que quieres vincular esta base de datos
      </Description>
      <BoldDescription>
        Un registro de
        <GeestSelect
          options={options}
          value={selectedVarDB}
          onChange={setSelectedVarDB}
          $width="220px"
        />
        tiene muchos ({VarDBTitle})
      </BoldDescription>
      <ButtonContainer>
        <Button loading={sending} type="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button loading={sending} type="primary" onClick={onSend}>
          Vincular
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export default LinkVarDBModal;
