import React from "react";
import Modal from "../Modal";
import Button from "../Button";
import { Row } from "antd";
import { TextField, withStyles } from "@material-ui/core";

const InputStyle = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "black",
    },
    "& label": {
      fontFamily: "Gotham-Book",
      fontSize: "medium",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Gotham-Book",
      borderRadius: ".4rem",
      marginBottom: "1.4rem",
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

const Languages = {
  ENG: {
    title: "Edit file name",
    cancelLabel: "Cancel",
    saveLabel: "Save",
  },
  ESP: {
    title: "Editar nombre del archivo",
    cancelLabel: "Cancelar",
    saveLabel: "Guardar",
  },
};

const EditFileNameModal = ({ FileName, setFileName, onChange, onClose }) => {
  const { title, cancelLabel, saveLabel } = Languages["ESP"];

  return (
    <Modal visible onCancel={onClose} title={title}>
      <InputStyle
        id="file-name"
        value={FileName}
        onChange={({ target: { value } }) => setFileName(value)}
        variant="outlined"
        size="small"
        autoComplete="off"
        autoFocus
      />
      <Row justify="space-between">
        <Button
          type="default"
          style={{ width: "calc(50% - 0.5rem)" }}
          onClick={onClose}
        >
          {cancelLabel}
        </Button>
        <Button
          disabled={!FileName}
          type="primary"
          style={{ width: "calc(50% - 0.5rem)" }}
          onClick={onChange}
        >
          {saveLabel}
        </Button>
      </Row>
    </Modal>
  );
};

export default EditFileNameModal;
