import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Languages } from "../Dictionary";
import DefaultView from "./views/DefaultView";
import AddTriggerView from "./views/AddTriggerView";
import ManualTriggerView from "./views/ManualTriggerView";
import FormTriggerView from "./views/FormTriggerView";
import WebhookTriggerView from "./views/WebhookTriggerView";
import EditHookFieldsView from "./views/EditHookFieldsView";
import {
  TriggerOption,
  TabInfo,
  Fields,
  ArgsForUpdateForm,
  Trigger,
} from "../TriggersConfig.d";
import {
  EmptyElement,
  TabElement,
  TabElementTitle,
  TabsContainer,
  TitleBar,
} from "./Style";
import { CloseIcon } from "../../../../../../components/hoverIcons";
import EditTriggerFieldsView from "./views/EditTriggerFieldsView";
import EditManualFieldsView from "./views/EditManualFieldsView";

const {
  AddTrigerTab,
  ConfigManualTriggerTab,
  ConfigFormTriggerTab,
  ConfigFormFieldsTab,
} = Languages["ESP"];

interface TriggerInformationProps {
  IdTeam: string;
  IdProcessTemplate: string;
  IdPrepublishTrigger: string;
  triggerOptions: TriggerOption[];
  backView?: string;
  view: string;
  setView: (newView: string) => void;
  setTriggers: (triggers: Trigger[]) => void;
  onClose: () => void;
  handleSelectTrigger: (id: string, type: string) => void;
  reload: () => void;
  Label?: string;
}

const ViewContent: React.FC<TriggerInformationProps> = ({
  IdTeam,
  IdProcessTemplate,
  IdPrepublishTrigger,
  triggerOptions = [],
  backView,
  view,
  setView,
  setTriggers,
  onClose,
  handleSelectTrigger,
  reload,
  Label,
}) => {
  const [, setArgs] = useState<ArgsForUpdateForm>({} as ArgsForUpdateForm);
  const [, setFields] = useState<Fields>({} as Fields);
  const [filesPath, setFilesPath] = useState<string>("");
  const [selectedJson, setSelectedJson] = useState<string>("");
  const [selectedRequest, setSelectedRequest] = useState<number | null>();
  const [json, setJson] = useState<{ [key: string]: any }>();
  const [prevIdPrepublishTrigger, setPrevIdPrepublishTrigger] =
    useState<string>(IdPrepublishTrigger);

  switch (view) {
    case "addTrigger":
      return (
        <AddTriggerView
          IdTeam={IdTeam}
          IdProcessTemplate={IdProcessTemplate}
          triggerOptions={triggerOptions}
          setView={setView}
          setTriggers={setTriggers}
          handleSelectTrigger={handleSelectTrigger}
          reload={reload}
        />
      );

    case "manualTrigger":
      return (
        <ManualTriggerView
          IdTeam={IdTeam}
          IdPrepublishTrigger={IdPrepublishTrigger}
          setView={setView}
          reloadHome={reload}
          Label={Label}
        />
      );

    case "formTrigger":
      return (
        <FormTriggerView
          IdTeam={IdTeam}
          IdPrepublishTrigger={IdPrepublishTrigger}
          setFields={setFields}
          setArgs={setArgs}
          setView={setView}
          filesPath={filesPath}
          setFilesPath={setFilesPath}
          reloadHome={reload}
          Label={Label}
        />
      );

    case "webhookTrigger":
      return (
        <WebhookTriggerView
          IdTeam={IdTeam}
          IdPrepublishTrigger={IdPrepublishTrigger}
          setView={setView}
          reloadHome={reload}
          Label={Label}
          selectedJson={selectedJson}
          setSelectedJson={setSelectedJson}
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
          setJson={setJson}
          prevIdPrepublishTrigger={prevIdPrepublishTrigger}
          setPrevIdPrepublishTrigger={setPrevIdPrepublishTrigger}
          json={json}
        />
      );

    case "editManualFields":
      return <EditManualFieldsView />;
    case "editFields":
      return (
        <EditTriggerFieldsView IdPrepublishTrigger={IdPrepublishTrigger} />
      );
    case "editHookFields":
      return (
        <EditHookFieldsView
          IdTeam={IdTeam}
          IdPrepublishTrigger={IdPrepublishTrigger}
          setView={setView}
          Label={Label}
          selectedRequest={selectedRequest}
          json={json}
          setJson={setJson}
        />
      );

    default:
      return <DefaultView onClose={onClose} />;
  }
};

const TriggerInformation: React.FC<TriggerInformationProps> = ({
  IdTeam,
  IdProcessTemplate,
  IdPrepublishTrigger,
  triggerOptions = [],
  view,
  setView,
  setTriggers,
  onClose,
  handleSelectTrigger,
  reload,
  Label,
}) => {
  const [tabs, setTabs] = useState<TabInfo[]>([]);

  useEffect(() => {
    let newTabs: TabInfo[] = [];
    let auxTabs = [];
    switch (view) {
      case "addTrigger":
        newTabs = [
          {
            view: "addTrigger",
            title: AddTrigerTab,
            isActive: true,
            showClose: false,
          },
        ];
        break;

      case "manualTrigger":
        newTabs = [
          {
            view: "manualTrigger",
            title: ConfigManualTriggerTab,
            isActive: true,
            showClose: false,
          },
        ];
        break;

      case "formTrigger":
        newTabs = [
          {
            view: "formTrigger",
            title: ConfigFormTriggerTab,
            isActive: true,
            showClose: false,
          },
        ];
        break;
      case "webhookTrigger":
        newTabs = [
          {
            view: "webhookTrigger",
            title: "Configurar disparador de Webhook",
            isActive: true,
            showClose: false,
          },
        ];
        break;

      case "editManualFields":
        auxTabs = _.cloneDeep(tabs);
        auxTabs[0] = { ...auxTabs[0], isActive: false };
        newTabs = [
          ...auxTabs,
          {
            view: "editFields",
            title: ConfigFormFieldsTab,
            isActive: true,
            showClose: true,
          },
        ];
        break;
      case "editFields":
        auxTabs = _.cloneDeep(tabs);
        auxTabs[0] = { ...auxTabs[0], isActive: false };
        newTabs = [
          ...auxTabs,
          {
            view: "editFields",
            title: ConfigFormFieldsTab,
            isActive: true,
            showClose: true,
          },
        ];
        break;
      case "editHookFields":
        let aTabs = _.cloneDeep(tabs);
        aTabs[0] = { ...aTabs[0], isActive: false };
        newTabs = [
          ...aTabs,
          {
            view: "editHookFields",
            title: "Vincular datos",
            isActive: true,
            showClose: false,
          },
        ];
        break;
    }
    setTabs(newTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, IdPrepublishTrigger]);

  return (
    <>
      <TitleBar>
        <TabsContainer>
          {tabs.length > 0 ? (
            tabs.map((tab, i) => (
              <TabElement
                $isActive={tab.isActive}
                key={i}
                onClick={() => {
                  if (tabs.length > 0 && i === 0) {
                    setView(tabs[0].view);
                  }
                }}
              >
                <TabElementTitle>{tab.title}</TabElementTitle>
              </TabElement>
            ))
          ) : (
            <EmptyElement />
          )}
        </TabsContainer>
        <CloseIcon onClick={onClose} />
      </TitleBar>
      <div
        style={{
          marginTop: "16px",
          flex: 1,
          display: "flex",
          overflow: "auto",
        }}
      >
        <ViewContent
          IdTeam={IdTeam}
          IdProcessTemplate={IdProcessTemplate}
          IdPrepublishTrigger={IdPrepublishTrigger}
          triggerOptions={triggerOptions}
          backView={tabs[0]?.view || "default"}
          view={view}
          setView={setView}
          setTriggers={setTriggers}
          onClose={onClose}
          handleSelectTrigger={handleSelectTrigger}
          reload={reload}
          Label={Label}
        />
      </div>
    </>
  );
};

export default TriggerInformation;
