import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import States from "./States";
import LoadingState from "./LoadingState";
import Normal from "./Normal";
import ChangePlan from "./ChangePlan";
import Successful from "./Successful";
import CheckOut from "./Checkout";
import PaymentNotCompleted from "./Error";
import RequestBonusTrial from "./RequestBonusTrial";
import { useMutation } from "../../../hooks";

const MockView = () => <div>MockView</div>;

export const StateMachineContext = React.createContext(null);

const StateMachine = ({ setCurrentTeam }) => {
  const { IdTeam } = useParams();
  const [CurrentState, setCurrentState] = useState(States.LOADING);
  const history = useHistory();

  const [getSubscriptionState, settingState] = useMutation({
    func: "Ver2-Subscription-gss",
    onSuccess: (response) => {
      const { State } = response;
      setCurrentTeam(IdTeam);
      if (CurrentState === State) {
        history.go(0);
      } else {
        setCurrentState(State);
      }
    },
  });

  useEffect(() => {
    getSubscriptionState({
      args: {
        IdTeam: IdTeam,
      },
    });
    // eslint-disable-next-line
  }, [IdTeam]);

  const StateView = (() => {
    switch (CurrentState) {
      case States.LOADING:
        return <LoadingState />;
      case States.NORMAL:
        return <Normal />;
      case States.CHANGE_PLAN_OUTDATED:
      case States.CHANGE_PLAN:
      case States.CHANGE_PLAN_NORMAL_EDIT_PLAN:
        return <ChangePlan />;
      case States.CHECKOUT_OUTDATED:
      case States.CHECKOUT:
      case States.CHECKOUT_CHANGE_USERS:
      case States.CHECKOUT_NORMAL_EDIT_PLAN:
        return <CheckOut />;
      case States.SUCCESSFUL:
      case States.SUCCESSFUL_BENEFITS_FITTED:
      case States.SUCCESSFUL_CHANGE_USERS:
        return <Successful />;
      case States.SUCCESSFUL_EDIT_PLAN:
        return <Successful changedPlan />;
      case States.ERROR_PAYMENT_NOT_COMPLETED:
        return <PaymentNotCompleted />;
      case States.ERROR_ACCOUNT_FREEZED:
        return <MockView />;
      case States.SELECT_BENEFITS_OUDATED:
      case States.SELECT_BENEFITS_FREEZED:
      case States.SELECT_BENEFITS_NORMAL:
        return <MockView />;

      case States.REQUEST_BONUS_TRIAL:
        return <RequestBonusTrial />;

      default:
        return `Invalid state: ${CurrentState}`;
    }
  })();

  return (
    <StateMachineContext.Provider
      value={{
        CurrentState,
        setCurrentState,
        settingState,
      }}
    >
      {StateView}
    </StateMachineContext.Provider>
  );
};

export default StateMachine;
