import React from "react";
import { OrderIconProps } from "./PipelineTypes.d";
import OrderNoneIcon from "../../../components/hoverIcons/OrderNoneIcon";
import OrderDownIcon from "../../../components/hoverIcons/OrderDownIcon";
import OrderUpIcon from "../../../components/hoverIcons/OrderUpIcon";

export const OrderIcon: React.FC<OrderIconProps> = ({ Direction }) => {
  if (Direction === "NONE") {
    return <OrderNoneIcon width="25px" height="25px" />;
  }

  if (Direction === "ASC") {
    return <OrderUpIcon width="25px" height="25px" />;
  }

  if (Direction === "DESC") {
    return <OrderDownIcon width="25px" height="25px" />;
  }

  return <></>;
};

export default OrderIcon;
