import _ from "lodash";
import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { EmptyData } from "../components";
import { ITotalClosuresPerUser } from "../sales.d";

const barColors = ["#09276e", "#309d09", "#73b63a", "#5bc588"];

interface Record {
  Cerrados: number;
  Cotizados: number;
  Iniciados: number;
  Perdidos: number;
  Label: string;
}

const TotalClosuresPerUser: React.FC<{
  data?: ITotalClosuresPerUser;
  loading: boolean;
}> = ({ data, loading }) => {
  if (loading) return <div>Cargando...</div>;
  if (
    !data ||
    (_.isEmpty(data.GraphData.Iniciados) &&
      _.isEmpty(data.GraphData.Cotizados) &&
      _.isEmpty(data.GraphData.Cerrados) &&
      _.isEmpty(data.GraphData.Perdidos))
  )
    return <EmptyData />;

  const initializeRecords = (): Record[] => {
    let userNames: string[] = [];
    // getUsers
    _.values(data.GraphData).forEach((category) =>
      category.forEach(({ Name }) => userNames.push(Name))
    );
    // Uniq and initialize
    return _.uniq(userNames).map((Label) => ({
      Label,
      Cerrados: 0,
      Cotizados: 0,
      Iniciados: 0,
      Perdidos: 0,
    }));
  };

  const parseData = (): Record[] => {
    let records = initializeRecords();
    _.map(data.GraphData, (value, category) => {
      value.forEach(({ Name, Value }) => {
        const idx = _.findIndex(records, { Label: Name });
        _.set(records, [idx, category], Value);
      });
    });
    return records;
  };

  return (
    <ResponsiveContainer maxHeight={310}>
      <BarChart layout="horizontal" data={parseData()}>
        <XAxis axisLine={false} dataKey="Label" type="category" />
        <YAxis
          axisLine={false}
          dataKey="Iniciados"
          type="number"
          allowDecimals={false}
        />
        <Tooltip />
        <Legend
          layout="horizontal"
          verticalAlign="top"
          align="center"
          wrapperStyle={{ top: -30 }}
        />
        <Bar dataKey="Iniciados" fill={barColors[0]} />
        <Bar dataKey="Cotizados" fill={barColors[1]} />
        <Bar dataKey="Cerrados" fill={barColors[2]} />
        <Bar dataKey="Perdidos" fill={barColors[3]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TotalClosuresPerUser;
