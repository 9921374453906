import React, { useEffect, useRef } from "react";
import { Input } from "./styles";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import { Row } from "antd";
import { transformedOptions } from "../../DynamicInput/Components/helpers/PhoneNumberHelpers";

interface PhoneNumberFilterProps {
  value: {
    Format: string;
    Value: string;
  };
  onChangeFilter: (filter: any) => void;
  onSubmit: () => void;
  onPressEnter?: () => void;
  autoFocus?: boolean;
}

const PhoneNumberFilter: React.FC<PhoneNumberFilterProps> = ({
  value,
  onChangeFilter,
  onSubmit,
  onPressEnter,
  autoFocus,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!value.Format) {
      onChangeFormat("MX");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autoFocus) {
      ref?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  const onChangeFormat = (Format: string) => {
    onChangeFilter({ ...value, Format });
  };
  const isInt = (value: string) => /^[0-9]*$/.test(value);

  return (
    <Row style={{ width: "285px" }}>
      <GeestSelect
        value={value.Format}
        options={transformedOptions}
        onChange={onChangeFormat}
        $width="100px"
        $listWidth="200px"
        valueNecesary
        style={{ marginRight: "5px" }}
      />
      <Input
        $width="180px"
        value={value.Value}
        onChange={({ target: { value: val } }) => {
          const newValue = val.replace(/\s/g, "").replace(/-/g, "");
          if (isInt(newValue) && newValue.length <= 10)
            onChangeFilter({ ...value, Value: newValue });
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (onPressEnter) {
              onPressEnter();
            } else {
              e.currentTarget.blur();
            }
          }
        }}
        onBlur={onSubmit}
        ref={ref}
      />
    </Row>
  );
};

export default PhoneNumberFilter;
