import React from "react";
import { IconContainer } from "./styles";
import { AiFillEdit, AiOutlineEdit } from "react-icons/ai";

const iconSize: { [key: number]: number } = {
  20: 16,
  32: 22,
};

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  filled?: boolean;
  size?: 20 | 32;
  color?: string;
  fillColor?: string;
  containerSize?: number;
  customIconSize?: number;
}

const EditIcon: React.ForwardRefRenderFunction<HTMLDivElement, IconProps> = (
  {
    onClick,
    size = 20,
    filled,
    color = "#0273E9",
    fillColor,
    containerSize,
    customIconSize,
    ...props
  },
  ref
) => {
  const Icon = filled ? AiFillEdit : AiOutlineEdit;
  return (
    <IconContainer
      ref={ref}
      size={containerSize ? containerSize : size}
      onClick={onClick}
      fillColor={fillColor}
      {...props}
    >
      <Icon
        color={color}
        size={customIconSize ? customIconSize : iconSize[size]}
      />
    </IconContainer>
  );
};

export default React.forwardRef(EditIcon);
