import React from "react";
import ViewHeader from "./ViewHeader";
import { ViewContentAction, ActionFormTitle, StyledTextField } from "./styles";
import { ActionProps } from "../AutomationConfig.d";
import webHookTriggerIcon from "../../../../../../resources/img/webHookTriggerIcon.svg";

const WebHookActionView: React.FC<ActionProps> = ({
  IdNode,
  selectedAction,
  setSelectedAction,
  actionOptions,
  reloadHome,
  saveAction,
}) => {
  return (
    <ViewContentAction>
      <ViewHeader
        IdNode={IdNode}
        reloadHome={reloadHome}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
        icon={webHookTriggerIcon}
        actionOptions={actionOptions}
      />
      <div>
        <ActionFormTitle>URL del servicio web</ActionFormTitle>
        <StyledTextField
          placeholder="Escribe aquí..."
          style={{ width: "352px" }}
          onChange={(e) => {
            if (selectedAction) {
              setSelectedAction({ ...selectedAction, Url: e.target.value });
            }
          }}
          value={selectedAction?.Url}
          onBlur={() => {
            if (selectedAction?.Url) {
              saveAction(selectedAction);
            }
          }}
        />
      </div>
    </ViewContentAction>
  );
};

export default WebHookActionView;
