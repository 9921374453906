import React, { useEffect, useState } from "react";
import _ from "lodash";
import DynamicGraphPreview from "../../DynamicGraphPreview";
import { Languages } from "../Dictionary";
import {
  AxisConfigContainer,
  ByFieldContainer,
  FieldContainer,
  GraphPreviewContainer,
  GroupByContainer,
  IncomeConfiguration,
  InformationBadge,
  LeftPannel,
  OptionsContainer,
  PrevisualizationCard,
  ProcessContainer,
  RightPannel,
  SelectElement,
  SubmitContainer,
  TextContainer,
  VisualizationStepContainer,
  XAxisContainer,
  YAxisContainer,
} from "./VisualizationStepStyles";
import {
  DurationsGraphFormat,
  FieldForGraph,
  FieldsCollection,
  FieldsForGroup,
  FilterBackend,
  FilterOption,
  GraphType,
  GroupByInterface,
  GroupByType,
  SellsStepThreeResponse,
  XAxisValueType,
} from "../../../../Dashboards.d";
import { Button } from "../../../../../../GeestUI";
import GeestSelect from "../../../../../../GeestUI/GeestSelect/GeestSelect";
import { transformedOptions } from "../../../../../../components/DynamicInput/Components/helpers/CurrencyHelpers";
import Tooltip from "../../../../../../components/Tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";
import DataTypeIcons from "../../../../DataTypeIcons";
import { useMutation } from "../../../../../../hooks";

const {
  VisualizationLeftTitle,
  VisualizationLeftDescription,
  GroupByLabel,
  ByProcessLabel,
  ByFieldLabel,
  ByNoneLabel,

  YAxisLabel,
  YAxisInformation,
  ChangeIncomeConfig,

  XAxisLabel,
  ByYearLabel,
  ByMonthLabel,
  ByWeekLabel,
  ByDayLabel,
  RemoveSelectionLabel,

  AddGraphLabel,
  UpdateGraphLabel,
  Previsualization,
} = Languages["ESP"];

interface VisualizationStepProps {
  graphName: string;
  selectedProcesses: string;
  selectedTemplate: number;
  selectedDashboard: string | null;
  format: DurationsGraphFormat;
  graphToEdit: GraphType | null;
  filters: FilterOption[];
  groupBy: GroupByType;
  setGroupBy: (groupBy: GroupByType) => void;
  xAxisValue: XAxisValueType;
  setXAxisValue: (value: XAxisValueType) => void;
  fields: FieldsCollection[];
  setFields: (newSelection: FieldsCollection[]) => void;
  fieldsForGroup: FieldsForGroup;
  setFieldsForGroup: (fields: FieldsForGroup) => void;
  setGroupByFromExistendGraph: (group: GroupByInterface | null) => void;
  canSubmit: boolean;
  handleOnSubmit: () => void;
  setUnsavedChanges: () => void;
}

const VisualizationStep: React.FC<VisualizationStepProps> = ({
  graphName,
  selectedProcesses,
  selectedTemplate,
  selectedDashboard,
  format,
  graphToEdit,
  filters,
  groupBy,
  setGroupBy,
  xAxisValue,
  setXAxisValue,
  fields,
  setFields,
  fieldsForGroup,
  setFieldsForGroup,
  setGroupByFromExistendGraph,
  canSubmit,
  handleOnSubmit,
  setUnsavedChanges,
}) => {
  const [incomeFormat, setIncomeFormat] = useState<string>("MXN");
  const [configUrl, setConfigUrl] = useState<string>("");
  const [selectedDataType, setSelectedDataType] = useState<string>("");

  const xAxisOptions = [
    { value: "Year", label: ByYearLabel },
    { value: "Month", label: ByMonthLabel },
    { value: "Week", label: ByWeekLabel },
    { value: "Day", label: ByDayLabel },
  ];

  const [getGraphConfigurationWizard] = useMutation<SellsStepThreeResponse>({
    func: "Ver2-DashBoards-ggcw",
    onSuccess: ({ FieldsCollection, GroupBy, XAxis, YAxis }) => {
      setConfigUrl(`/ver2#/home/teams/${YAxis.IdTeam}`);
      setXAxisValue(XAxis.Frequency);
      setIncomeFormat(YAxis.Format);
      setGroupBy(GroupBy.Mode);

      let auxFields = _.cloneDeep([...FieldsCollection]);
      auxFields = auxFields.map((process) => {
        let selectedFieldsAux: number[] = [];
        process.Fields.forEach((field) => {
          if (field.Checked) {
            selectedFieldsAux.push(field.IdField);
          }
        });
        process.FieldsSelected = selectedFieldsAux.join(",");
        return process;
      });
      setFields(auxFields);
    },
  });

  const getFilters = () => {
    return _.cloneDeep(filters).map((filter) => {
      let newFilter: FilterBackend = {
        Key: filter.Key,
        Label: filter.Label,
        Value: filter.Value,
      };

      if (filter.Type !== "Static") {
        newFilter.DataType = filter.DataType;
      }

      if (filter.Start) {
        newFilter.Start = filter.Start;
      }
      if (filter.End) {
        newFilter.End = filter.End;
      }

      return newFilter;
    });
  };

  useEffect(() => {
    getGraphConfigurationWizard({
      args: {
        IdDashBoard: selectedDashboard,
        IdGraphTemplate: selectedTemplate,
        IdGraph: graphToEdit?.IdGraph ?? "",
        Step: 3,
        IdProcessTemplate: selectedProcesses,
        Filters: getFilters(),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChangeXAxis = (newValue: XAxisValueType) => {
    setXAxisValue(newValue);
    setUnsavedChanges();
  };

  const handleOnChangeGroupBy = (groupBy: GroupByType) => {
    setGroupBy(groupBy);
    setFieldsForGroup({});
    setGroupByFromExistendGraph(null);
    setUnsavedChanges();
  };

  const handleOnChangeSelectedFields = (
    processIndex: number,
    field: FieldForGraph,
    fieldIndex: number
  ) => {
    setUnsavedChanges();
    let auxProcesses = _.cloneDeep(fields);
    let auxField = auxProcesses[processIndex].Fields[fieldIndex];
    auxProcesses[processIndex].Fields[fieldIndex].Checked = !auxField.Checked;
    setFields(auxProcesses);

    const processes = (_.cloneDeep(auxProcesses) as any[]).map((process) => {
      let auxFields = [] as number[];

      process.Fields.filter((field: FieldForGraph) => field.Checked).forEach(
        (field: FieldForGraph) => {
          auxFields.push(field.IdField);
        }
      );

      process.FieldsSelected = auxFields.join(",");
      return process;
    });

    let processesForBack = {} as any;

    processes.forEach((process) => {
      let selectedFields = process.Fields.map((field: any) => {
        if (field.Checked) {
          setSelectedDataType(field.DataType);
          return field.IdField;
        }
        return null;
      })
        .filter((idField: any) => typeof idField === "number")
        .join(",");

      if (selectedFields) {
        processesForBack[process.IdProcessTemplate] = selectedFields;
      }
    });

    if (field.Checked && Object.keys(processesForBack).length === 0) {
      setSelectedDataType("");
    }

    setFieldsForGroup(processesForBack);
    setGroupByFromExistendGraph(null);
  };

  const handleRemoveSelecction = () => {
    let auxProcesses = _.cloneDeep(fields).map((process) => {
      process.Fields.map((field) => {
        field.Checked = false;
        return field;
      });
      return process;
    });
    setFields(auxProcesses);
    setSelectedDataType("");
    setFieldsForGroup({});
    setGroupByFromExistendGraph(null);
  };

  const canChangeCheck = (
    idProcessTemplate: number,
    dataType: string,
    isChecked: boolean
  ) => {
    const processIsSelected = idProcessTemplate in fieldsForGroup;
    const noSelectedDataType = selectedDataType === "";
    const currentSelectedDataType = selectedDataType === dataType;

    return (
      (!processIsSelected || isChecked) &&
      (noSelectedDataType || currentSelectedDataType)
    );
  };

  return (
    <VisualizationStepContainer>
      <LeftPannel>
        <TextContainer>
          <p className="title">{VisualizationLeftTitle}</p>
        </TextContainer>

        <OptionsContainer>
          <p className="description">{VisualizationLeftDescription}</p>
          <GroupByContainer>
            <p className="label">{GroupByLabel}:</p>

            <div className="radio-container">
              <SelectElement onClick={() => handleOnChangeGroupBy("ByProcess")}>
                <p className="option">{ByProcessLabel}</p>
                <input
                  type="radio"
                  value="ByProcess"
                  name="groupBy"
                  checked={groupBy === "ByProcess"}
                  onClick={() => handleOnChangeGroupBy("ByProcess")}
                />
              </SelectElement>

              <SelectElement onClick={() => handleOnChangeGroupBy("ByField")}>
                <p className="option">{ByFieldLabel}</p>
                <input
                  type="radio"
                  value="ByField"
                  name="groupBy"
                  checked={groupBy === "ByField"}
                  onClick={() => handleOnChangeGroupBy("ByField")}
                />
              </SelectElement>

              <SelectElement onClick={() => handleOnChangeGroupBy("None")}>
                <p className="option">{ByNoneLabel}</p>
                <input
                  type="radio"
                  value="None"
                  name="groupBy"
                  checked={groupBy === "None"}
                  onClick={() => handleOnChangeGroupBy("None")}
                />
              </SelectElement>
            </div>
          </GroupByContainer>

          <AxisConfigContainer>
            <YAxisContainer>
              <p className="label">{YAxisLabel}:</p>
              <IncomeConfiguration>
                <div className="select-wrapper">
                  <GeestSelect
                    value={incomeFormat}
                    options={transformedOptions}
                    onChange={() => {}}
                    $width="60px"
                    valueNecesary
                    disabled
                  />

                  <Tooltip title={YAxisInformation} mui>
                    <InformationBadge>
                      <AiOutlineInfoCircle color="#48505e" size={18} />
                    </InformationBadge>
                  </Tooltip>
                </div>

                <p
                  className="link"
                  onClick={() => window.open(configUrl, "_blank")}
                >
                  {ChangeIncomeConfig}
                </p>
              </IncomeConfiguration>
            </YAxisContainer>

            <XAxisContainer>
              <p className="label">{XAxisLabel}:</p>
              <GeestSelect
                value={xAxisValue}
                options={xAxisOptions}
                onChange={(value) => handleOnChangeXAxis(value)}
                $width="220px"
                $listWidth="220px"
              />
            </XAxisContainer>
          </AxisConfigContainer>

          {groupBy === "ByField" && (
            <ByFieldContainer>
              <div className="remove-selection">
                <p onClick={handleRemoveSelecction}>{RemoveSelectionLabel}</p>
              </div>

              {fields.map((process, processIndex) => {
                return (
                  <ProcessContainer>
                    <p className="name">{process.Name}</p>

                    {process.Fields.map((field, fieldIndex) => {
                      return (
                        <FieldContainer
                          $isDisabled={
                            !canChangeCheck(
                              process.IdProcessTemplate,
                              field.DataType,
                              field.Checked
                            )
                          }
                          onClick={() => {
                            if (
                              canChangeCheck(
                                process.IdProcessTemplate,
                                field.DataType,
                                field.Checked
                              )
                            ) {
                              handleOnChangeSelectedFields(
                                processIndex,
                                field,
                                fieldIndex
                              );
                            }
                          }}
                        >
                          <div className="label">
                            {DataTypeIcons[field.DataType] && (
                              <img
                                src={DataTypeIcons[field.DataType].icon}
                                alt=""
                                style={{
                                  ...DataTypeIcons[field.DataType]?.extraStyles,
                                }}
                              />
                            )}
                            <p>{field.Label}</p>
                          </div>

                          <input
                            type="checkbox"
                            value={`${process.IdProcessTemplate}-${field.IdField}`}
                            name="fieldForGraph"
                            checked={field.Checked}
                            disabled={
                              !canChangeCheck(
                                process.IdProcessTemplate,
                                field.DataType,
                                field.Checked
                              )
                            }
                          />
                        </FieldContainer>
                      );
                    })}
                  </ProcessContainer>
                );
              })}
            </ByFieldContainer>
          )}
        </OptionsContainer>

        <SubmitContainer>
          <Button
            type="primary"
            disabled={!canSubmit}
            onClick={handleOnSubmit}
            size="large"
          >
            {graphToEdit?.IdGraph ? UpdateGraphLabel : AddGraphLabel}
          </Button>
        </SubmitContainer>
      </LeftPannel>

      <RightPannel>
        <PrevisualizationCard>
          <p className="title">{graphName ? graphName : Previsualization}</p>
          <GraphPreviewContainer>
            <DynamicGraphPreview
              selectedDashboard={selectedDashboard}
              selectedTemplate={selectedTemplate}
              selectedProcesses={selectedProcesses}
              format={format}
              graphName={graphName}
              rawFilters={filters}
              groupBy={groupBy}
              xAxisValue={xAxisValue}
              fieldsForGroup={fieldsForGroup}
            />
          </GraphPreviewContainer>
        </PrevisualizationCard>
      </RightPannel>
    </VisualizationStepContainer>
  );
};

export default VisualizationStep;
