import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import {
  ModalContainer,
  ModalItem,
  ModalRow,
  TrialStatusBadge,
  DatePickerStyled,
} from "../../styles";
import { Button } from "../../../../GeestUI";
import { ExtendTrialModalProps } from "../../Intranet.d";
import {
  getStatusLabel,
  getFreeTrialBackground,
  getFreeTrialCardColor,
  getFreeTrialColor,
} from "../helper";
import { ReactComponent as CardIcon } from "../../../../resources/img/freeTrialCardIcon.svg";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useMutation } from "../../../../hooks";

const ExtendTrialModal: React.FC<ExtendTrialModalProps> = ({
  trial,
  onClose,
  reload,
}) => {
  const [endDate, setEndDate] = useState<string>("");
  const [extendTrial, loading] = useMutation({
    func: "Ver2-Intranet-Free-et",
    onSuccess: () => {
      reload();
      onClose();
    },
  });

  const onSend = () => {
    extendTrial({
      args: {
        NewEndTime: endDate,
        IdSubscription: trial.IdSubscription,
      },
    });
  };
  return (
    <Modal title="Extender prueba gratis" width={396} onCancel={onClose}>
      <ModalContainer>
        <ModalRow>
          <ModalItem
            style={{
              minWidth: "calc(100% - 90px)",
              maxWidth: "calc(100% - 90px)",
            }}
          >
            Prueba gratis
            <p>{trial.Name}</p>
          </ModalItem>
          <ModalItem>
            Estatus
            <TrialStatusBadge
              $color={getFreeTrialColor(trial.Status)}
              $background={getFreeTrialBackground(trial.Status)}
            >
              {getStatusLabel(trial.Status)}
              <CardIcon
                color={getFreeTrialCardColor(trial.Status)}
                style={{ marginBottom: "3px" }}
              />
            </TrialStatusBadge>
          </ModalItem>
        </ModalRow>
        <ModalRow>
          <ModalItem
            style={{
              minWidth: "calc(100% - 90px)",
              maxWidth: "calc(100% - 90px)",
            }}
          >
            Inicio de Prueba
            <p>{moment(trial.StartTime).format("DD/MMM")}</p>
          </ModalItem>
          <ModalItem>
            Fin previo
            <p>{moment(trial.EndTime).format("DD/MMM")}</p>
          </ModalItem>
        </ModalRow>
        <ModalItem>
          Fin de prueba
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePickerStyled
              value={endDate ? moment(endDate) : null}
              variant="dialog"
              format="DD/MMM/YY"
              onChange={(endDate) =>
                setEndDate(endDate ? endDate.format("YYYY-MM-DD") : "")
              }
              minDate={moment(trial.EndTime)}
              fullWidth
              clearable
              InputProps={{
                disableUnderline: true,
                placeholder: "Selecciona la nueva fecha de fin...",
                style: {
                  border: "2px solid",
                  borderColor: "#edecec",
                  borderRadius: "6px",
                  color: "#828d9e",
                  background: "white",
                  height: "32px",
                  paddingLeft: "5px",
                  fontSize: "14px",
                  transition: "all 0.35s ease",
                },
                endAdornment: (
                  <div
                    style={{
                      position: "absolute",
                      right: "3px",
                      width: "20px",
                      height: "20px",
                      zIndex: 1,
                    }}
                  />
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </ModalItem>
        <Button
          type="primary"
          style={{ alignSelf: "center", marginTop: "24px" }}
          disabled={!endDate}
          onClick={onSend}
          loading={loading}
        >
          Guardar
        </Button>
      </ModalContainer>
    </Modal>
  );
};

export default ExtendTrialModal;
