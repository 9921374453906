import React from "react";
import { Tag as AntdTag } from "antd";
import styled from "styled-components";
import { ITagProps } from "./Tag.d";

const StyledTag = styled(AntdTag)`
  margin-right: 0;
  border-radius: 5px;
  font-family: Gotham-Book;
`;

const Tag: React.FC<ITagProps> = ({ children, ...props }) => (
  <StyledTag {...props}>{children}</StyledTag>
);

export default Tag;
