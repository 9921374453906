import React, { useState } from "react";
import { noop as NOOP } from "lodash";
import ActivateModal from "./ActivateModal";
import { InfoText } from "./styles";
import { useParams } from "react-router-dom";
import { useMutation } from "../../../../hooks";
import { Button } from "../../../../GeestUI";
import DeactivateModal from "./DeactivateModal";

const ActiveConsulting = ({ consultorsData = [], reload = NOOP }) => {
  const { idTeam } = useParams();
  const [ShowConfirm, setShowConfirm] = useState(false);

  const consultorsNames = (consultorsData[0]?.ConsultingUsers || []).map(
    (consultor) => consultor.Name
  );

  const [disableTeamConsultors, loading] = useMutation({
    func: "Ver2-Team-dtc",
    onSuccess: () => reload(),
  });

  const disableConsulting = () => {
    disableTeamConsultors({
      args: {
        IdTeam: idTeam,
      },
    });
  };

  return (
    <div>
      <InfoText>
        <span style={{ fontFamily: "Gotham-Bold" }}>Consultores activos: </span>
        <br />
        {consultorsNames}
      </InfoText>
      <DeactivateModal
        visible={ShowConfirm}
        onConfirm={disableConsulting}
        onCancel={() => setShowConfirm(false)}
        loading={loading}
        consultorsNames={consultorsNames}
      />
      <Button
        type="secondary"
        style={{ width: "210px" }}
        onClick={() => setShowConfirm(true)}
      >
        Desactivar consultoría
      </Button>
    </div>
  );
};

const UnactiveConsulting = ({ consultorsData = [], reload = NOOP }) => {
  const [ShowModal, setShowModal] = useState(false);
  return (
    <div>
      <ActivateModal
        visible={ShowModal}
        closeModal={() => setShowModal(false)}
        consultorsData={consultorsData}
        reload={() => {
          setShowModal(false);
          reload();
        }}
      />
      <InfoText
        style={{
          color: "rgb(0, 0, 0, 0)",
          userSelect: "none",
          cursor: "default",
        }}
      >
        Placeholder
      </InfoText>
      <Button
        onClick={() => setShowModal(true)}
        type="secondary"
        style={{ width: "210px" }}
      >
        Activar consultoría
      </Button>
    </div>
  );
};

const Consulting = ({ active = false, ...rest }) => {
  const Content = active ? ActiveConsulting : UnactiveConsulting;
  return <Content {...rest} />;
};

export default Consulting;
