import React, { useEffect, useState } from "react";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { Col, Row, Space } from "antd";
import _ from "lodash";
import {
  Card,
  StyledFormControl,
  StyledRangePicker,
  StyledCol,
} from "./styles";
import { useFetch } from "../../../hooks";
import {
  Team,
  RangeDate,
  Filters,
  SelectChangeEvent,
  GraphData,
} from "./sales.d";
import { Title } from "./components";
import {
  TotalClosures,
  TotalClosuresPerUser,
  TotalSales,
  TotalSalesPerUser,
  Origination,
  LossReasons,
} from "./graphs";
import Goals from "./modals/Goals";
import goalsIcon from "./../../../resources/img/goals.png";

const Sales: React.FC = () => {
  const [showGoals, setShowGoals] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    idTeam: 0,
    idProcess: 0,
    rangeDate: null,
  });

  const { data: teams, loading } = useFetch<Team[]>({
    func: "Ver2-Statistics-gtap",
    onSuccess: (teams) => {
      const team: Team | undefined = _.nth(teams, 0);
      if (team) {
        const process = _.nth(team.Processes, 0);
        if (process) {
          setFilters({
            ...filters,
            idTeam: team.IdTeam,
            idProcess: process.IdProcessTemplate,
          });
        }
      }
    },
  });

  const {
    data: graphData,
    reload: reloadGraphData,
    loading: loadingGraphData,
  } = useFetch<GraphData>({
    func: "Ver2-Statistics-gsr",
    args: {
      ReportView: "Sales",
      IdTeam: filters.idTeam,
      IdProcessTemplates: [filters.idProcess],
      Filters: {
        InitialDate:
          _.nth(filters.rangeDate, 0)
            ?.startOf("day")
            .format("YYYY-MM-DD HH:mm:ss") || "",
        FinalDate:
          _.nth(filters.rangeDate, 1)
            ?.startOf("day")
            .format("YYYY-MM-DD HH:mm:ss") || "",
      },
    },
    conf: { skip: !filters.idTeam || !filters.idProcess },
  });

  const parsedGraphData = _.isEmpty(graphData) ? null : graphData;
  const processes = _.find(teams, { IdTeam: filters.idTeam })?.Processes || [];

  useEffect(() => {
    if (filters.idProcess) {
      reloadGraphData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.idProcess, filters.rangeDate]);

  const onChangeTeam = (e: SelectChangeEvent): void =>
    setFilters({
      ...filters,
      idTeam: e.target.value as number,
      idProcess:
        _.find(teams, { IdTeam: e.target.value as number })?.Processes[0]
          ?.IdProcessTemplate || 0,
    });

  const onChangeProcess = (e: SelectChangeEvent): void =>
    setFilters({ ...filters, idProcess: e.target.value as number });

  const onChangeDateFilter = (values: any) =>
    setFilters({ ...filters, rangeDate: values as RangeDate });

  return (
    <div>
      <Goals
        visible={showGoals}
        onClose={() => setShowGoals(false)}
        onSuccess={() => {
          setShowGoals(false);
          reloadGraphData();
        }}
        IdTeam={filters.idTeam}
        IdProcessTemplate={filters.idProcess}
      />
      <Row justify="space-between" style={{ marginBottom: "1rem" }}>
        <Space wrap style={{ marginBottom: "0.5rem" }}>
          <StyledFormControl variant="outlined" margin="dense">
            <InputLabel id="team-label">Empresa</InputLabel>
            <Select
              labelId="team-label"
              name="idTeam"
              value={filters.idTeam || ""}
              onChange={onChangeTeam}
              disabled={loading}
              label={"Empresa"}
            >
              {(teams || []).map(({ TeamName, IdTeam }) => (
                <MenuItem key={IdTeam} value={IdTeam}>
                  {TeamName}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <StyledFormControl variant="outlined" margin="dense">
            <InputLabel id="process-label">Proceso</InputLabel>
            <Select
              labelId="process-label"
              name="idProcess"
              value={filters.idProcess || ""}
              onChange={onChangeProcess}
              disabled={loading}
              label={"Proceso"}
            >
              {processes.map(({ IdProcessTemplate, Name }) => (
                <MenuItem key={IdProcessTemplate} value={IdProcessTemplate}>
                  {Name}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Space>
        <Space wrap style={{ marginRight: "0.4rem", marginLeft: "0.4rem" }}>
          <div style={{ fontSize: "16px" }}>Periodo:</div>
          <StyledRangePicker
            bordered={false}
            value={filters.rangeDate}
            onChange={onChangeDateFilter}
            disabled={loading}
            format="DD/MMM/YY"
          />
        </Space>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{ marginLeft: 0, marginRight: 0, marginBottom: "1rem" }}
      >
        <Col xs={24} lg={12}>
          <Card>
            <Title title="Ventas totales" color="#09276e" />
            <TotalSales
              loading={loadingGraphData}
              data={parsedGraphData?.TotalSales}
            />
            <Title
              title="Ventas por vendedor"
              color="#b0e7a9"
              icon={goalsIcon}
              iconTooltip="Metas"
              onClickIcon={() => setShowGoals(!loadingGraphData)}
            />
            <TotalSalesPerUser
              loading={loadingGraphData}
              data={parsedGraphData?.SalesPerUser}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card>
            <Title title="Cierres totales" color="#add382" />
            <TotalClosures
              loading={loadingGraphData}
              data={parsedGraphData?.TotalClosures}
            />
            <Title title="Cierres por vendedor" color="#caf080" />
            <div
              style={{
                width: "100%",
                height: "30px",
                backgroundColor: "transparent",
              }}
            />
            <TotalClosuresPerUser
              loading={loadingGraphData}
              data={parsedGraphData?.TotalClosuresPerUser}
            />
          </Card>
        </Col>
        <Col xs={24}>
          <Card>
            <Row
              gutter={{ xs: 0, lg: 64 }}
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              <StyledCol xs={24} lg={12}>
                <Title title="Origen" color="#c896ff" />
                <Origination
                  loading={loadingGraphData}
                  data={parsedGraphData?.ClientOrigin}
                />
              </StyledCol>
              <StyledCol xs={24} lg={12}>
                <Title title="Razones de pérdida" color="#c5e7d2" />
                <LossReasons
                  loading={loadingGraphData}
                  data={parsedGraphData?.LostReason}
                />
              </StyledCol>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Sales;
