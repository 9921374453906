import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useMutation } from "../../../../../../../hooks";
import DynamicInput from "../../../../../../../components/DynamicInput";
import DataOriginConfig from "../Components/DataOriginConfig";
import GeestSelect from "../../../../../../../GeestUI/GeestSelect";
import {
  FieldDataOrigin,
  ImportedDataOrigin,
  MultiSelectValue,
  SelectOption,
  TeamVarDB,
} from "../../EditField.d";
import {
  DataBases,
  FieldConfigWrapper,
  SelectDataBasesWrapper,
  StyledSwitch,
  SubTitle,
  SwitchItem,
} from "../../Styles";
import { MessagesContext } from "../../../../../../../components/AppMessages";

interface SelectConfigProps {
  IdField: number | null;
  value: MultiSelectValue | null;
  onChange: (newValue: MultiSelectValue | null) => void;
  fieldDataOrigin: FieldDataOrigin[] | ImportedDataOrigin | null;
  setFieldDataOrigin: (
    newDataOrigin: FieldDataOrigin[] | ImportedDataOrigin | null
  ) => void;
  configuration: string;
  setConfiguration: (newConfig: string) => void;
  disabled: boolean;
}

const SelectConfig: React.FC<SelectConfigProps> = ({
  IdField,
  value,
  onChange,
  fieldDataOrigin,
  setFieldDataOrigin,
  configuration,
  setConfiguration,
  disabled,
}) => {
  const [teamVarDBs, setTeamVarDBs] = useState<TeamVarDB[]>([]);
  const [varDBsOptions, setVarDBsOptions] = useState<SelectOption[]>([]);
  const [columnsOptions, setColumnsOptions] = useState<SelectOption[]>([]);
  const { showMessage } = useContext(MessagesContext);

  const { IdTeam } = useParams<{
    IdTeam: string;
  }>();

  const configValues = {
    ImportedFromVardbColumn: "ImportedFromVardbColumn",
    CannotAddOptions: "CannotAddOptions",
    CanAddOptions: "CanAddOptions",
  };

  const [GetTeamVarDBsImportedColumns] = useMutation<TeamVarDB[]>({
    func: "Ver2-Vardbs-gtvic",
    onSuccess: (varDBs) => {
      setTeamVarDBs(varDBs);
      let newOptions = varDBs.map((vardb) => {
        return { value: vardb.IdVardb, label: vardb.Title };
      });
      setVarDBsOptions(newOptions);
      if (!!(fieldDataOrigin as ImportedDataOrigin).IdVarDB) {
        const indexedTeamVarDBs = varDBs.reduce(
          (acc: { [id: number]: TeamVarDB }, el) => {
            acc[el.IdVardb] = el;
            return acc;
          },
          {}
        );
        const idVarDB = (fieldDataOrigin as ImportedDataOrigin).IdVarDB || 0;
        if (idVarDB in indexedTeamVarDBs) {
          const columns = indexedTeamVarDBs[idVarDB].Columns.map((col) => {
            return { value: col.IdColumn, label: col.Title };
          });
          setColumnsOptions(columns);
          return;
        }
      }
    },
  });

  useEffect(() => {
    if (configuration === configValues.ImportedFromVardbColumn) {
      GetTeamVarDBsImportedColumns({
        args: { IdTeam },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration]);

  const handleChangeConfig = (newConfig: string) => {
    setConfiguration(newConfig);
  };

  const handleOnChangeImportedVarDB = (idVarDB: number) => {
    const indexedTeamVarDBs = teamVarDBs.reduce(
      (acc: { [id: number]: TeamVarDB }, el) => {
        acc[el.IdVardb] = el;
        return acc;
      },
      {}
    );

    if (idVarDB in indexedTeamVarDBs) {
      const columns = indexedTeamVarDBs[idVarDB].Columns.map((col) => {
        return { value: col.IdColumn, label: col.Title };
      });
      setFieldDataOrigin({
        ...(fieldDataOrigin as ImportedDataOrigin),
        IdVarDB: idVarDB,
        IdVarDBColumn: columns[0].value,
      });
      setColumnsOptions(columns);
      return;
    }

    setFieldDataOrigin({
      ...(fieldDataOrigin as ImportedDataOrigin),
      IdVarDB: null,
      IdVarDBColumn: null,
    });
    setColumnsOptions([]);
  };

  const handleOnChangeColumn = (idColumn: number) => {
    if (typeof idColumn === "number") {
      setFieldDataOrigin({
        ...(fieldDataOrigin as ImportedDataOrigin),
        IdVarDBColumn: idColumn,
      });
      return;
    }
    setFieldDataOrigin({
      ...(fieldDataOrigin as ImportedDataOrigin),
      IdVarDBColumn: null,
    });
  };

  const handleOnAddOption = (option: string) => {
    const newOption = _.trim(option);
    if (newOption === "") {
      showMessage("No puede haber una opción vacía", "error");
      return;
    }
    const indexedDataOrigin = (fieldDataOrigin as FieldDataOrigin[]).reduce(
      (acc: { [label: string]: FieldDataOrigin }, el) => {
        acc[el.label.toLowerCase()] = el;
        return acc;
      },
      {}
    );
    if (newOption.toLowerCase() in indexedDataOrigin) {
      showMessage("Ya existe una opción con ese valor", "error");
      return;
    }
    let newDO = [
      ...(fieldDataOrigin as FieldDataOrigin[]),
      { label: newOption },
    ];
    setFieldDataOrigin(newDO);
  };

  const fallbackOptions = () => {
    return _.cloneDeep(fieldDataOrigin as FieldDataOrigin[]).map((opt) => {
      if (!opt.value) {
        return { ...opt, value: opt.label };
      }
      return opt;
    });
  };

  return (
    <>
      <SwitchItem>
        <StyledSwitch
          checked={configuration === configValues.ImportedFromVardbColumn}
          onChange={(flag) =>
            handleChangeConfig(
              flag
                ? configValues.ImportedFromVardbColumn
                : configValues.CannotAddOptions
            )
          }
          disabled={disabled}
        />
        <SubTitle>Importar de base de datos</SubTitle>
      </SwitchItem>

      {configuration === configValues.ImportedFromVardbColumn ? (
        <SelectDataBasesWrapper>
          <SubTitle>Base de datos</SubTitle>
          <DataBases>
            <GeestSelect
              value={(fieldDataOrigin as ImportedDataOrigin).IdVarDB}
              onChange={handleOnChangeImportedVarDB}
              options={varDBsOptions}
              placeholderSelect={"Base de datos..."}
              disabled={disabled}
              valueNecesary
            />
            <GeestSelect
              value={(fieldDataOrigin as ImportedDataOrigin).IdVarDBColumn}
              onChange={handleOnChangeColumn}
              options={columnsOptions}
              placeholderSelect={"Columna..."}
              disabled={disabled}
              valueNecesary
            />
          </DataBases>
        </SelectDataBasesWrapper>
      ) : (
        <>
          <FieldConfigWrapper>
            <SubTitle>Opciones</SubTitle>
            <DataOriginConfig
              disabled={disabled}
              fieldDataOrigin={fieldDataOrigin as FieldDataOrigin[]}
              setFieldDataOrigin={setFieldDataOrigin}
            />
          </FieldConfigWrapper>

          <FieldConfigWrapper>
            <SubTitle>Valor por defecto</SubTitle>
            <DynamicInput
              value={value ?? { Type: "", Label: null, Value: null }}
              type="select"
              dataOrigin={fallbackOptions()}
              format=""
              configuration=""
              fieldName=""
              isConsult={false}
              disabled={disabled || IdField === 0 || IdField === null}
              required={false}
              onChange={onChange}
              onAddOption={handleOnAddOption}
            />

            <SwitchItem>
              <StyledSwitch
                checked={configuration === configValues.CanAddOptions}
                onChange={(flag) =>
                  handleChangeConfig(
                    flag
                      ? configValues.CanAddOptions
                      : configValues.CannotAddOptions
                  )
                }
                disabled={disabled}
              />
              <SubTitle>
                Se pueden escribir valores distintos a las opciones
              </SubTitle>
            </SwitchItem>
          </FieldConfigWrapper>
        </>
      )}
    </>
  );
};

export default SelectConfig;
