import React, { useEffect } from "react";
import DynamicInput from "../../../../../../../components/DynamicInput";
import { FieldConfigWrapper, SubTitle } from "../../Styles";
import { onPasteImage } from "../../../../../../../components/DynamicInput/Components/helpers/FileHelpers";

interface FileType {
  Name: string;
  SourceUrl: string;
}

interface FileConfigProps {
  value: FileType[];
  onChange: (value: FileType[]) => void;
  FilesPath: string;
  disabled: boolean;
}

const FileConfig: React.FC<FileConfigProps> = ({
  value,
  onChange,
  FilesPath,
  disabled,
}) => {
  useEffect(() => {
    const onKeyDown = async (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === "v" && e.ctrlKey) {
        const newValue = await onPasteImage(FilesPath);
        if (newValue) {
          onChange(value ? [...value, newValue] : [newValue]);
        }
      }
    };
    window.addEventListener("keydown", onKeyDown);

    return () => window.removeEventListener("keydown", onKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <FieldConfigWrapper>
        <SubTitle>Valor por defecto</SubTitle>
        <DynamicInput
          value={value ?? []}
          type="file"
          dataOrigin={[]}
          format=""
          fieldName=""
          configuration=""
          isConsult={false}
          disabled={disabled}
          required={false}
          onChange={onChange}
          extraParams={{ FilesPath }}
        />
      </FieldConfigWrapper>
    </>
  );
};

export default FileConfig;
