import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Row } from "antd";
import { noop as NOOP } from "lodash";
import {
  Container,
  Header,
  Message,
  Content,
  Footer,
  ButtonBorder,
  ErrorMessage,
} from "./styles";
import Button from "../../../../../../components/Button";
import Img from "../../../../../../components/Img/Img";
import GeestIcon from "./../../../../../../resources/img/ICONO_.svg";
import States from "../../../States";
import { StateMachineContext } from "../../..";
import { getPlanColors } from "../../../../helpers";
import { moneyFormat } from "../../../../../../Helpers";
import ConfirmOverlay from "../../../../../../components/ConfirmOverlay/ConfirmOverlay";
import { IoAlertCircleOutline } from "react-icons/io5";
import moment from "moment";
import { useMutation } from "../../../../../../hooks";

const MessageSection = ({ currentPlan = {}, canceled = false }) => {
  const { SubscriptionTrialEnds, NextPayment = {} } = currentPlan;
  const planColors = getPlanColors(NextPayment.Name);
  if (canceled) {
    return (
      <ErrorMessage>
        <b>Has finalizado tu prueba gratuita.</b>
        <div style={{ marginTop: "0.2rem" }}>
          {SubscriptionTrialEnds}
          <div>
            Reactiva la contratación automática o contrata ahora para seguir
            disfrutando de los beneficios de Geest
          </div>
        </div>
      </ErrorMessage>
    );
  } else {
    return (
      <Message>
        {SubscriptionTrialEnds}
        <div>
          Tu primer cargo{" "}
          {NextPayment.BillingMode === "Monthly" ? "Mensual" : "Anual"} será de{" "}
          <b>{moneyFormat(NextPayment.Amount)} USD</b> por el{" "}
          <b style={{ color: planColors.mainColor }}>
            Plan {NextPayment.Title}
          </b>{" "}
          y llegará el{" "}
          <b>{moment(NextPayment.ApplicationTime).format("DD/MMM/YY HH:mm")}</b>
          .
        </div>
      </Message>
    );
  }
};

const ActiveBonusTrial = ({
  currentPlan = {},
  canceled = false,
  reload = NOOP,
}) => {
  const { IdTeam } = useParams();
  const { setCurrentState } = useContext(StateMachineContext);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [changeSubscriptionState] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: (response) => {
      const { State } = response;
      setCurrentState(State);
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  const changeState = (NextState = "") => {
    setLoading(true);
    changeSubscriptionState({
      args: {
        IdTeam,
        NextState,
      },
    });
  };

  const handleOnClick = () => {
    if (canceled) {
      activateTrial();
    } else {
      setShowConfirm(true);
    }
  };

  const handleOnCloseModal = (confirm) => {
    if (confirm) {
      cancelTrial();
    }
    setShowConfirm(false);
  };

  const [cancelHiringBonusTrial] = useMutation({
    func: "Ver2-Subscription-Normal-chibt",
    onSuccess: () => {
      reload();
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  const [reactivateHiringBonusTrial] = useMutation({
    func: "Ver2-Subscription-Normal-rhibt",
    onSuccess: () => {
      reload();
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  const cancelTrial = () => {
    setLoading(true);
    cancelHiringBonusTrial({
      args: {
        IdTeam,
      },
    });
  };
  const activateTrial = () => {
    setLoading(true);
    reactivateHiringBonusTrial({
      args: {
        IdTeam,
      },
    });
  };

  return (
    <Container>
      <ConfirmOverlay
        isOpen={showConfirm}
        width="20rem"
        confirmButtonText="Aceptar"
        closeOverlay={handleOnCloseModal}
        text={
          <Row
            style={{
              fontSize: "17px",
              textAlign: "left",
              lineHeight: "1.25rem",
            }}
            justify="space-between"
            align="middle"
          >
            <IoAlertCircleOutline
              size={38}
              color="#e10600"
              style={{ marginLeft: "0.5rem" }}
            />
            <div style={{ flex: 1, marginLeft: "1rem" }}>
              ¿Seguro que deseas cancelar la contratación automática?
            </div>
          </Row>
        }
      />
      <Header>Suscripción</Header>
      <MessageSection currentPlan={currentPlan} canceled={canceled} />
      <Content>
        <Img cursor="default" img={GeestIcon} size="contain" />
      </Content>
      <Footer size="middle" direction="vertical">
        <Button
          block
          type={canceled ? "secondaryOutlined" : "warning"}
          loading={loading}
          onClick={handleOnClick}
        >
          {canceled
            ? "Reactivar contratación automática"
            : "Cancelar contratación automática"}
        </Button>
        <ButtonBorder disable={loading}>
          <Button
            type="defaultActive"
            block
            style={{ borderColor: "#ffffff0f" }}
            loading={loading}
            onClick={() => changeState(States.CHANGE_PLAN)}
          >
            Contratar ahora
          </Button>
        </ButtonBorder>
      </Footer>
    </Container>
  );
};

export default ActiveBonusTrial;
