export const Languages = {
  ENG: {
    CardTitleLabel: "General information",
    progressLabel: "Follow up",
    ProgressTooltipLabel: "Progress",
  },
  ESP: {
    CardTitleLabel: "Información general",
    progressLabel: "seguimientos",
    ProgressTooltipLabel: "Progreso",
  },
};
