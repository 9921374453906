import styled from "styled-components";

export const TeamName = styled.div`
  font-family: "Gotham-Bold";
  font-size: 18px;
  color: rgba(72, 80, 94, 1);
  margin-bottom: 1rem;
`;

export const Container = styled.div`
  font-family: "Gotham-Book";
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
`;
