import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _, { noop as NOOP } from "lodash";
import Modal from "../../../../../components/Modal";
import SelectConsultors from "./SelectConsultors";
import { Description, ButtonContainer, Container } from "./styles";
import { useMutation } from "../../../../../hooks";
import { Button } from "../../../../../GeestUI";

const ActivateModal = ({
  visible = false,
  consultorsData = [],
  closeModal = NOOP,
  reload = NOOP,
}) => {
  const { idTeam } = useParams();

  const [Teams, setTeams] = useState([]);
  const [Selected, setSelected] = useState({ team: null, users: [] });

  useEffect(() => {
    const [team] = consultorsData;
    setTeams(consultorsData);
    if (team) {
      setSelected({
        team: team.Id,
        users: [team.ConsultingUsers[0].IdUser],
      });
    }
  }, [consultorsData]);

  const [activateTeamConsultors, submitting] = useMutation({
    func: "Ver2-Team-atc",
    onSuccess: () => reload(),
  });

  const onSubmit = () => {
    const selectedTeam = _.find(Teams, { Id: Selected.team });
    activateTeamConsultors({
      args: {
        IdTeam: idTeam,
        ConsultingUsers: selectedTeam.ConsultingUsers,
      },
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      title="Activar consultoría"
      width="432px"
    >
      <Container>
        <Description style={{ marginBottom: "12px" }}>
          Selecciona a la empresa y consultores de tu preferencia
        </Description>
        <SelectConsultors
          selected={Selected}
          setSelected={setSelected}
          teams={Teams}
        />
        <Description>
          Al activar la consultoría acepto compartir toda la información de mi
          empresa con los consultores
        </Description>
        <ButtonContainer>
          <Button loading={submitting} onClick={closeModal} type="secondary">
            Cancelar
          </Button>
          <Button type="primary" loading={submitting} onClick={onSubmit}>
            Activar
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default ActivateModal;
