import React from "react";
import { NodeToolbar, Position } from "reactflow";
import { Row } from "antd";
import { AiOutlineZoomIn } from "react-icons/ai";
import { BsArrowClockwise } from "react-icons/bs";
import { ToolboxActions, ToolboxProps } from "./Toolbox.d";
import { Arrow, Toolbar } from "./styles";

const Toolbox: React.FC<ToolboxProps> = ({
  nodeId,
  onAction,
  showDetail,
  showRestart,
}) => {
  return (
    <NodeToolbar nodeId={nodeId} position={Position.Right}>
      <Arrow />
      <Toolbar>
        {showDetail && (
          <Row
            align="middle"
            onClick={() => onAction(ToolboxActions.OPEN_PENDING_DETAIL)}
          >
            <AiOutlineZoomIn size={14} />
            Ver detalles
          </Row>
        )}
        {showRestart && (
          <Row
            align="middle"
            onClick={() => onAction(ToolboxActions.RESTART_PROCESS)}
          >
            <BsArrowClockwise size={14} />
            Reiniciar
          </Row>
        )}
      </Toolbar>
    </NodeToolbar>
  );
};

export default Toolbox;
