import { faker } from "@faker-js/faker";

interface metaData {
  idTaskValue: number;
  limitTime: number[];
}

interface DataSet {
  label: string;
  backgroundColor: string;
  data: any;
  metaData: metaData;
}

interface InstalationTable {
  tableName: string;
  labels: string[];
  datasets: DataSet[];
}

const colors = [
  "#D87CAC",
  "#143642",
  "#0F8B8D",
  "#EC9A29",
  "#A1E8AF",
  "#B8336A",
  "#5EFC8D",
  "#FF8811",
  "#1B998B",
  "#261132",
];

const labels = [
  "Proyecto Juanita",
  "Instalación Ana",
  "Seguimiento Pedro",
  "Instalación Luis",
];

export const options = {
  indexAxis: "y" as const,
  scales: {
    x: {
      stacked: true,
      display: false,
    },
    y: {
      stacked: true,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};

export const data: InstalationTable = {
  tableName: "Duración de instalación",
  labels: labels,
  datasets: Array.from(Array(10).keys()).map((i) => {
    return {
      label: `tarea ${i + 1}`,
      backgroundColor: colors[i],
      data: labels.map(() => faker.number.int({ min: 0, max: 10 })),
      metaData: {
        idTaskValue: 68714,
        limitTime: labels.map(() => faker.number.int({ min: 0, max: 10 })),
      },
    };
  }),
};
