import React from "react";
import styled from "styled-components";
import { Tooltip as TP } from "antd";
import { Tooltip as MTP } from "@material-ui/core";
import { TooltipPlacement } from "antd/lib/tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#48505e",
    borderRadius: "2px",
    padding: "6px 8px",
    fontSize: "12px",
  },
  customArrow: {
    color: "#48505e",
    borderRadius: "2px",
  },
}));

const CustomTooltip = ({ className, children, ...restProps }: any) => (
  <TP overlayClassName={className} {...restProps}>
    {children}
  </TP>
);

const StyledTooltip = styled(CustomTooltip)<{
  hide: boolean;
  $color?: string;
  fontColor?: string;
}>`
  .ant-tooltip-arrow-content {
    ${({ hide = false }) => (hide ? "display: none;" : "")}
    border-radius: 2px;
    background-color: ${({ $color = "#48505e" }) => $color};
    height: 8px;
    width: 8px;
    z-index: 100;

    ::before {
      display: none;
    }
  }

  .ant-tooltip-inner {
    ${({ hide = false }) => (hide ? "display: none;" : "")}
    background-color: ${({ $color = "#48505e" }) => $color};
    border-radius: 2px;
    ${({ $fontColor }) => (!!$fontColor ? `color: ${$fontColor};` : "")}
  }
`;

const StyledMuiTooltip = styled(MTP)``;

const Tooltip: React.FC<{
  title: string;
  placement?: TooltipPlacement;
  hide?: boolean;
  mui?: boolean;
  color?: string;
  fontColor?: string;
}> = ({
  children,
  title,
  placement = "bottom",
  hide,
  mui = false,
  color,
  fontColor,
}) => {
  const classes = useStyles();

  return mui ? (
    <StyledMuiTooltip
      title={title}
      placement={placement as any}
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
      arrow
      leaveDelay={0}
    >
      {children as any}
    </StyledMuiTooltip>
  ) : (
    <StyledTooltip
      title={title}
      placement={placement}
      hide={hide}
      mouseLeaveDelay={0}
      $color={color}
      $fontColor={fontColor}
    >
      {children}
    </StyledTooltip>
  );
};

export default Tooltip;
