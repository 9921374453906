import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import _ from "lodash";
import { Languages } from "./Dictionary";
import { useFetch, useMutation } from "../../../../hooks";
import {
  Dashboard,
  DashboardPermission,
  Permission,
  TeamMember,
  dashboardPermissionsData,
} from "../../Dashboards.d";
import {
  ContentWrapper,
  ModalContent,
  ModalTitle,
  ModalWrapper,
} from "../modalStyles";
import {
  PermissionContent,
  PermissionsForm,
  PermisssionElement,
} from "./style";
import User from "../../../../components/User";
import GeestSelect from "../../../../GeestUI/GeestSelect/GeestSelect";
import { CloseIcon } from "../../../../components/hoverIcons";

const { ModalTitleLabel, PermissionDescription } = Languages["ESP"];

interface EditPermissionsProps {
  dashboard: Dashboard | null;
  onClose: () => void;
}

const EditPermissions: React.FC<EditPermissionsProps> = ({
  dashboard,
  onClose,
}) => {
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);

  useFetch<dashboardPermissionsData>({
    func: "Ver2-DashBoards-gdp",
    args: {
      IdDashBoard: dashboard?.IdDashBoard,
    },
    onSuccess: ({ TeamMembers, Permissions }) => {
      setTeamMembers(TeamMembers);
      setPermissions(Permissions);
    },
  });

  const [updateDashboardPermissions] = useMutation({
    func: "Ver2-DashBoards-udp",
  });

  const onChangeUserPermissions = (
    idUser: number,
    permission: DashboardPermission
  ) => {
    let user = teamMembers[idUser];
    user.Permission = permission;
    let members = _.cloneDeep(teamMembers);
    members[idUser] = user;
    setTeamMembers(members);

    updateDashboardPermissions({
      args: {
        IdDashBoard: dashboard?.IdDashBoard,
        TeamMembers: teamMembers,
      },
    });
  };

  return (
    <Modal open onClose={onClose}>
      <ModalWrapper onClick={onClose}>
        <ModalContent
          onClick={(e) => e.stopPropagation()}
          $width="468px"
          $height="407px"
          $maxHeight="470px"
        >
          <ModalTitle>
            <p>{ModalTitleLabel}</p>
            <CloseIcon size={20} onClick={onClose} />
          </ModalTitle>

          <ContentWrapper>
            <PermissionsForm>
              {teamMembers.map((user, idx) => (
                <PermisssionElement key={user.IdUser}>
                  <PermissionContent>
                    <div className="user">
                      <User
                        user={user}
                        size="middle"
                        picSize="26px"
                        nameStyle="bold"
                        nameColor="#48505e"
                      />
                    </div>

                    <div className="select">
                      <GeestSelect
                        value={user.Permission}
                        options={permissions}
                        onChange={(permission) =>
                          onChangeUserPermissions(idx, permission)
                        }
                        valueNecesary
                      />
                    </div>
                  </PermissionContent>

                  <p>{PermissionDescription[user.Permission]}</p>
                </PermisssionElement>
              ))}
            </PermissionsForm>
          </ContentWrapper>
        </ModalContent>
      </ModalWrapper>
    </Modal>
  );
};

export default EditPermissions;
