import React, { useState } from "react";
import { Popover } from "antd";
import { FolderType } from "../DataBases.d";
import {
  ContextMenuOptionContainer,
  FolderMenuContainer,
  FolderOptionWrapper,
  FoldersContainer,
} from "./Styles";
import { AiOutlineFolder } from "react-icons/ai";
import { IoMdArrowDropright } from "react-icons/io";
import Tooltip from "../../../components/Tooltip";

const Languages = {
  ENG: {
    MoveToFolderLabel: "Move to folder",
  },
  ESP: {
    MoveToFolderLabel: "Mover a carpeta",
  },
};

interface FolderMenuProps {
  folders: FolderType[];
  onMove: (IdVardbFolder: string) => void;
  IdVardbFolder?: string;
  disabled?: boolean;
}

const FolderMenu: React.FC<FolderMenuProps> = ({
  folders,
  onMove,
  IdVardbFolder,
  disabled = false,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { MoveToFolderLabel } = Languages["ESP"];

  return (
    <Popover
      placement="rightTop"
      overlayStyle={{
        backgroundColor: "transparent",
      }}
      overlayInnerStyle={{
        backgroundColor: "transparent",
        borderColor: "transparent",
        boxShadow: "none",
        borderRadius: "6px",
        overflow: "hidden",
      }}
      open={visible}
      onOpenChange={(visible) => setVisible(visible && !disabled)}
      zIndex={1}
      align={{ offset: [-11, -5] }}
      mouseLeaveDelay={0.3}
      content={
        <FoldersContainer>
          {folders.map(
            (folder) =>
              folder.IdVardbFolder !== IdVardbFolder && (
                <FolderMenuContainer
                  key={folder.IdVardbFolder}
                  onClick={() => onMove(folder.IdVardbFolder)}
                >
                  <AiOutlineFolder size={20} />
                  {folder.Title.length > 19 ? (
                    <Tooltip title={folder.Title} placement="top">
                      <p>{folder.Title}</p>
                    </Tooltip>
                  ) : (
                    <p>{folder.Title}</p>
                  )}
                </FolderMenuContainer>
              )
          )}
        </FoldersContainer>
      }
    >
      <FolderOptionWrapper>
        <ContextMenuOptionContainer>
          <AiOutlineFolder size={20} />
          {MoveToFolderLabel}
        </ContextMenuOptionContainer>
        <IoMdArrowDropright size={20} />
      </FolderOptionWrapper>
    </Popover>
  );
};

export default FolderMenu;
