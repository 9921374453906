import _ from "lodash";
import { TimeFormatValues, TimeConfigValues } from "./constants";
import { SelectOption } from "../TaskEditor.d";

export type TimeFormat = "minuto" | "día" | "hora";
export type TimeConfig = "antes" | "después";

interface decodeTimeToAddResult {
  timeAmount: number;
  timeFormat: TimeFormat;
  timeConfig: TimeConfig;
}

export const decodeTimeToAdd = (
  timeToAdd: number = 0
): decodeTimeToAddResult => {
  let timeAmount: number = 0,
    timeFormat: TimeFormat = "minuto",
    timeConfig: TimeConfig = timeToAdd >= 0 ? "después" : "antes";

  if (Math.abs(timeToAdd) !== 0) {
    for (let key of ["día", "hora", "minuto"] as TimeFormat[]) {
      if (Math.abs(timeToAdd) % TimeFormatValues[key] === 0) {
        timeFormat = key;
        timeAmount = Math.abs(timeToAdd) / TimeFormatValues[key];
        break;
      }
    }
  }

  return {
    timeAmount,
    timeFormat,
    timeConfig,
  };
};

interface EncodeTimeToAddArgs {
  timeAmount: number;
  timeFormat: TimeFormat;
  timeConfig: TimeConfig;
}

export const encodeTimeToAdd = ({
  timeAmount,
  timeFormat,
  timeConfig,
}: EncodeTimeToAddArgs): number =>
  timeAmount * TimeFormatValues[timeFormat] * TimeConfigValues[timeConfig];

export const assertTimeToAdd = (value: string): number => {
  if (_.isEmpty(value)) return 0;
  return _.parseInt(value);
};

export const backOptionToSelectOption = (
  options: SelectOption[]
): { label: string; value: string }[] =>
  _.map(options, ({ Label, Value }) => ({
    label: Label,
    value: String(Value),
  }));
