import { Tag } from "antd";
import styled, { css } from "styled-components";

export const borderColor = "#48505E";
export const border = `2px solid ${borderColor}`;
export const handlerStyles: React.CSSProperties = {
  border: "none",
  background: borderColor,
  height: "10px",
  width: "10px",
};

const borderColorCurrentNode = "#0273e9";

interface RectangleProps {
  $isSelected: boolean;
  $currentTask?: boolean;
  $backgroundColor?: string;
}

export const Rectangle = styled.div<RectangleProps>`
  border: ${border};
  border-radius: 10px;
  height: 90px;
  width: 220px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  font-family: "Gotham-book";
  background-color: ${({ $backgroundColor = "white" }) => $backgroundColor};

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      box-shadow: 4px 10px 6px 0px #48505e4d;
    `}

  ${({ $currentTask = false, $isSelected }) =>
    $currentTask &&
    css`
      border-color: ${borderColorCurrentNode};
      ${() =>
        $isSelected &&
        css`
          box-shadow: 4px 10px 6px 0px #0273e94d;
        `}
    `}
`;

interface CircleProps {
  $backgroundColor?: string;
}

export const Circle = styled(Rectangle)<CircleProps>`
  border-radius: 50%;
  height: 90px;
  width: 90px;
  background-color: ${({ $backgroundColor = "white" }) => $backgroundColor};
`;

export const StyledTag = styled(Tag)`
  border-radius: 6px;
  border-width: 2px;
  margin: 0;
  padding: 0 5px;
  font-size: 10px;
  font-family: "Gotham-Book";
`;
