import bold from "./Images/bold.svg";
import italic from "./Images/italic.svg";
import underline from "./Images/underline.svg";
import strikethrough from "./Images/strikethrough.svg";
import monospace from "./Images/monospace.svg";
import fontSize from "./Images/font-size.svg";
import indent from "./Images/indent.svg";
import outdent from "./Images/outdent.svg";
import ordered from "./Images/list-ordered.svg";
import unordered from "./Images/list-unordered.svg";
import left from "./Images/align-left.svg";
import center from "./Images/align-center.svg";
import right from "./Images/align-right.svg";
import color from "./Images/color.svg";
import link from "./Images/link.svg";
import unlink from "./Images/unlink.svg";
import undo from "./Images/undo.svg";
import redo from "./Images/redo.svg";
import subscript from "./Images/subscript.svg";
import superscript from "./Images/superscript.svg";

import {
  ToolbarConfig,
  ToolbarControls,
  ToolbarExtraParams,
} from "./GeestTextEditor.d";

const TOOLBAR: ToolbarConfig = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "history",
    "image",
  ],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "monospace",
      "superscript",
      "subscript",
    ],
    bold: { icon: bold, className: "geestEditorButton" },
    italic: { icon: italic, className: "geestEditorButton" },
    underline: { icon: underline, className: "geestEditorButton" },
    strikethrough: { icon: strikethrough, className: "geestEditorButton" },
    monospace: { icon: monospace, className: "geestEditorButton" },
    superscript: { icon: superscript, className: "geestEditorButton" },
    subscript: { icon: subscript, className: "geestEditorButton" },
  },
  colorPicker: {
    icon: color,
    className: "geestEditorButton",
    component: undefined,
    popupClassName: "geestEditorPopup",
    colors: [
      "#000000",
      "#48505E",
      "#828D9E",
      "#FFFFFF",
      "#F44336",
      "#E91E63",
      "#2196F3",
      "#673AB7",
      "#3F51B5",
      "#2196F3",
      "#03A9F4",
      "#00BCD4",
      "#009688",
      "#4CAF50",
      "#8BC34A",
      "#CDDC39",
      "#FFEB3B",
      "#FFC107",
      "#FF9800",
      "#FF5722",
      "#795548",
      "#E299FC",
      "transparent",
    ],
  },

  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: "geestEditorPopup geestEditorLink",
    dropdownClassName: "geestEditorSelectDrowdown",
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link", "unlink"],
    link: { icon: link, className: "geestEditorButton" },
    unlink: { icon: unlink, className: "geestEditorButton" },
    linkCallback: undefined,
  },

  fontSize: {
    icon: fontSize,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: "geestEditorSelect",
    component: undefined,
    dropdownClassName: "geestEditorSelectDrowdown",
  },
  fontFamily: {
    options: [
      "Gotham-Book",
      "Arial",
      "Georgia",
      "Impact",
      "Tahoma",
      "Times New Roman",
      "Verdana",
    ],
    className: "geestEditorSelect geestEditorSelectFontFamily",
    component: undefined,
    dropdownClassName:
      "geestEditorSelectDrowdown geestEditorSelectDropwdownFontFamily",
  },
  blockType: {
    inDropdown: true,
    options: [
      "Normal",
      "H1",
      "H2",
      "H3",
      "H4",
      "H5",
      "H6",
      "Blockquote",
      "Code",
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right"],
    left: { icon: left, className: "geestEditorButton" },
    center: { icon: center, className: "geestEditorButton" },
    right: { icon: right, className: "geestEditorButton" },
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["unordered", "ordered", "outdent", "indent"],
    unordered: { icon: unordered, className: "geestEditorButton" },
    ordered: { icon: ordered, className: "geestEditorButton" },
    outdent: { icon: outdent, className: "geestEditorButton" },
    indent: { icon: indent, className: "geestEditorButton" },
  },

  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["undo", "redo"],
    undo: { icon: undo, className: undefined },
    redo: { icon: redo, className: undefined },
  },
};

const getToolbar = (
  config: ToolbarControls,
  extraParams?: ToolbarExtraParams
) => {
  let toolbar: ToolbarConfig = {
    options: [],
  };

  if (config.inline) {
    toolbar.options.push("inline");
    toolbar.inline = TOOLBAR.inline;
    if (toolbar.inline?.options && extraParams?.inlineOptions) {
      toolbar.inline.options = extraParams.inlineOptions;
    }
  }

  if (config.colorPicker) {
    toolbar.options.push("colorPicker");
    toolbar.colorPicker = TOOLBAR.colorPicker;
    if (toolbar.colorPicker?.colors && extraParams?.colorPickerOptions) {
      toolbar.colorPicker.colors = extraParams.colorPickerOptions;
    }
  }

  if (config.link) {
    toolbar.options.push("link");
    toolbar.link = TOOLBAR.link;
    if (
      toolbar.link?.defaultTargetOption &&
      extraParams?.linkOptions?.defaultTarget
    ) {
      toolbar.link.defaultTargetOption = extraParams.linkOptions.defaultTarget;
    }
    if (toolbar.link?.options && extraParams?.linkOptions?.options) {
      toolbar.link.options = extraParams.linkOptions.options;
    }
  }

  if (config.fontSize) {
    toolbar.options.push("fontSize");
    toolbar.fontSize = TOOLBAR.fontSize;
    if (toolbar.fontSize?.options && extraParams?.fontSizeOptions) {
      toolbar.fontSize.options = extraParams.fontSizeOptions;
    }
  }

  if (config.fontFamily) {
    toolbar.options.push("fontFamily");
    toolbar.fontFamily = TOOLBAR.fontFamily;
    if (toolbar.fontFamily?.options && extraParams?.fontFamilyOptions) {
      toolbar.fontFamily.options = extraParams.fontFamilyOptions;
    }
  }

  if (config.blockType) {
    toolbar.options.push("blockType");
    toolbar.blockType = TOOLBAR.blockType;
    if (toolbar.blockType?.options && extraParams?.blockTypeOptions) {
      toolbar.blockType.options = extraParams.blockTypeOptions;
    }
  }

  if (config.textalign) {
    toolbar.options.push("textAlign");
    toolbar.textAlign = TOOLBAR.textAlign;
    if (toolbar.textAlign?.options && extraParams?.textAlignOptions) {
      toolbar.textAlign.options = extraParams.textAlignOptions;
    }
  }

  if (config.list) {
    toolbar.options.push("list");
    toolbar.list = TOOLBAR.list;
    if (toolbar.list?.options && extraParams?.listOptions) {
      toolbar.list.options = extraParams.listOptions;
    }
  }

  if (config.history) {
    toolbar.options.push("history");
    toolbar.history = TOOLBAR.history;
  }

  return toolbar;
};

const toolbarUtils = {
  getToolbar,
};

export default toolbarUtils;
