import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { IoExitOutline } from "react-icons/io5";
import type { RcFile } from "antd/es/upload/interface";
import {
  Container,
  EditTeamLogo,
  LeftSidebar,
  MembersCount,
  MembersTitle,
  TeamContainer,
  TeamContent,
  TeamLogo,
  TeamLogoContainer,
  TeamLogoUploader,
  TeamDetailHeader,
  HeaderItem,
  LeftInfo,
  TeamTitle,
  TeamTitleInput,
} from "./Style";
import LeaveModal from "./Modals/LeaveModal";
import { SesionContext } from "../../../config/State";
import { useFetch, useMutation } from "../../../hooks";
import ViewTitle from "../../../components/ViewTitle/ViewTitle";
import { Languages } from "../Dictionary";
import ConsultingButton from "./Consulting";
import {
  ConsultorsData,
  GetTeamDetailResponse,
  Permissions,
  TeamMember,
  TeamRole,
} from "../Teams.d";
import { MdEdit } from "react-icons/md";
import DeleteTeamModal from "./Modals/DeleteTeamModal";
import MembersList from "./MembersList/MembersList";
import ExpellUserModal from "./Modals/ExpellUserModal";
import RegisterNewUserModal from "./Modals/RegisterNewUserModal";
import { MessagesContext } from "../../../components/AppMessages";
import { uploadFileToAWS } from "../../../aws/s3Client";
import { ReactComponent as OrgIcon } from "../../../resources/img/TEAM_ORG.svg";
import { Button } from "../../../GeestUI";
import { FiTrash } from "react-icons/fi";
import { InputContainer, AddUserInput } from "./MembersList/Style";
import { EditIcon } from "../../../components/hoverIcons";
import TeamConfiguration from "./TeamConfiguration";

const FileSizeLimit = 25;

const TeamDetails: React.FC = () => {
  const { showMessage } = useContext(MessagesContext);
  const [sesion, setSession] = useContext<any>(SesionContext);
  const { idTeam } = useParams<{ idTeam: string }>();
  const [teamLogo, setTeamLogo] = useState<string>("");
  const [teamRoles, setTeamRoles] = useState<TeamRole[]>([]);
  const [teamFilesPath, setTeamFilesPath] = useState<string>("");
  const [teamName, setTeamName] = useState<string>("");
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [editingTitle, setEditingTitle] = useState<boolean>(false);
  const [newTitle, setNewTitle] = useState<string>("");
  const [modal, setModal] = useState<string>("");
  const [userPermissions, setUserPermissions] = useState<Permissions>(
    {} as Permissions
  );
  const [teamLeaderId, setTeamLeaderId] = useState<number | null>(null);
  const [isTeamLead, setIsTeamLead] = useState<boolean>(false);
  const [consultorsData, setConsultorsData] = useState<ConsultorsData[]>();
  const [teamConsulting, setTeamConsulting] = useState(false);
  const [reportsCurrencyFormat, setReportsCurrencyFormat] =
    useState<string>("MXN");
  const [fieldsCurrencyFormat, setFieldsCurrencyFormat] =
    useState<string>("MXN");
  const [fieldsPhoneFormat, setFieldsPhoneFormat] = useState<string>("MX");
  const [newUserEmail, setNewUserEmail] = useState<string>("");
  const validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isNewUserEmailEmpty = _.isEmpty(_.trim(newUserEmail));
  const isNewUserEmailValid = validateEmail.test(_.trim(newUserEmail));
  const isNewUserOnTeam = !_.isEmpty(
    _.filter(teamMembers, { Email: newUserEmail })
  );
  const [view, setView] = useState<string>("Info");

  const [noAccountUser, setNoAccountUser] = useState<string>("");
  const [actionsUser, setActionsUser] = useState<TeamMember | null>(null);

  const titleRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const {
    ButtonsLabels,
    PopoverLabels,
    Messages,
    Add,
    AddUserLabel,
    AddUserAlert,
    AddUserDescription,
    Messages: {
      ErrorNewEmailEmpty,
      ErrorNewEmailInTeam,
      SuccessfulUserCreated,
    },
  } = Languages["ESP"];

  const { reload } = useFetch<GetTeamDetailResponse>({
    func: "Ver2-Team-gtd",
    args: { IdTeam: idTeam },
    onSuccess: ({
      Name,
      TeamLogo,
      FilesPath,
      TeamMembers,
      TeamRoles,
      CreationIdUser,
      TeamHasActiveConsultors,
      ReportsCurrencyFormat,
      FieldsCurrencyFormat,
      FieldsPhoneFormat,
    }) => {
      setTeamLogo(TeamLogo || "");
      setTeamFilesPath(FilesPath);
      setTeamName(Name);
      setNewTitle(Name);
      setTeamMembers(TeamMembers);
      setTeamConsulting(TeamHasActiveConsultors);
      setTeamRoles(TeamRoles);
      setReportsCurrencyFormat(ReportsCurrencyFormat);
      setFieldsCurrencyFormat(FieldsCurrencyFormat);
      setFieldsPhoneFormat(FieldsPhoneFormat);

      const currentUser = _.find(TeamMembers, {
        IdUser: +sesion.Id,
      });
      setUserPermissions(currentUser?.Permissions || ({} as Permissions));
      setIsTeamLead(currentUser?.IdUser === CreationIdUser);
      setTeamLeaderId(CreationIdUser);
    },
  });

  useFetch<ConsultorsData[]>({
    func: "Ver2-Team-gtc",
    onSuccess: (res) => {
      setConsultorsData(res);
    },
  });

  const [updateTeam] = useMutation<[]>({
    func: "Ver2-Team-ut",
    onSuccess: () => {
      setEditingTitle(false);
      setTeamName(newTitle);
      reload();
    },
  });

  const isConsultor = (userId: number) => {
    if (consultorsData) {
      const consultor = _.filter(consultorsData[0].ConsultingUsers, {
        IdUser: userId,
      });
      return !_.isEmpty(consultor);
    }
    return false;
  };

  useEffect(() => {
    if (editingTitle) titleRef.current?.focus();
  }, [editingTitle]);

  const editTitle = () => {
    const isNewTitleEmpty = _.isEmpty(_.trim(newTitle));
    const isSameTitle = _.trim(newTitle) === teamName;

    if (isNewTitleEmpty || isSameTitle) {
      setEditingTitle(false);
      setNewTitle(teamName);
      return;
    }
    updateTeam({
      args: {
        IdTeam: idTeam,
        Name: _.trim(newTitle),
        TeamLogo: teamLogo,
        ReportsCurrencyFormat: reportsCurrencyFormat ?? "MXN",
        FieldsCurrencyFormat: fieldsCurrencyFormat ?? "MXN",
        FieldsPhoneFormat: fieldsPhoneFormat ?? "MX",
      },
    });
  };

  const onChangeReportsFormat = (newFormat: string) => {
    setReportsCurrencyFormat(newFormat);
    updateTeam({
      args: {
        IdTeam: idTeam,
        Name: teamName,
        TeamLogo: teamLogo,
        ReportsCurrencyFormat: newFormat ?? "MXN",
        FieldsCurrencyFormat: fieldsCurrencyFormat,
        FieldsPhoneFormat: fieldsPhoneFormat,
      },
    });
  };

  const onChangeFieldsCurrenyFormat = (newFormat: string) => {
    setFieldsCurrencyFormat(newFormat);
    updateTeam({
      args: {
        IdTeam: idTeam,
        Name: teamName,
        TeamLogo: teamLogo,
        ReportsCurrencyFormat: reportsCurrencyFormat,
        FieldsCurrencyFormat: newFormat,
        FieldsPhoneFormat: fieldsPhoneFormat,
      },
    });
  };

  const onChangeFieldsPhoneFormat = (newFormat: string) => {
    setFieldsPhoneFormat(newFormat);
    updateTeam({
      args: {
        IdTeam: idTeam,
        Name: teamName,
        TeamLogo: teamLogo,
        ReportsCurrencyFormat: reportsCurrencyFormat,
        FieldsCurrencyFormat: fieldsCurrencyFormat,
        FieldsPhoneFormat: newFormat,
      },
    });
  };

  const beforeUpload = async (file: RcFile) => {
    let cleanName = file.name.replace(/[^a-zA-Z0-9.]/g, "");
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isSizeValid = file.size / 1000000 < FileSizeLimit;
    const isValidFileName = !cleanName.includes("..");
    const isNameEmpty = !cleanName.split(".")[0];
    if (isNameEmpty) {
      cleanName = `image.${cleanName.split(".")[1]}`;
    }
    if (!isJpgOrPng) {
      showMessage(Messages.OnlyImages, "error");
      return;
    }
    if (!isSizeValid) {
      showMessage(Messages.FileLimit(FileSizeLimit), "error");
      return;
    }
    if (!isValidFileName) {
      showMessage(Messages.InvalidFileName, "error");
      return;
    }

    const filePath = await uploadFileToAWS(file, teamFilesPath, cleanName);
    if (filePath) {
      updateTeam({
        args: {
          IdTeam: idTeam,
          Name: _.trim(teamName),
          TeamLogo: filePath,
          ReportsCurrencyFormat: reportsCurrencyFormat ?? "MXN",
          FieldsCurrencyFormat: fieldsCurrencyFormat ?? "MXN",
          FieldsPhoneFormat: fieldsPhoneFormat ?? "MX",
        },
      });
      if (String(sesion.FavoriteTeam.IdFavoriteTeam) === String(idTeam)) {
        setSession({
          ...sesion,
          FavoriteTeam: {
            ...sesion.FavoriteTeam,
            TeamLogoUrl: filePath,
          },
        });
      }
      setTeamLogo(filePath);
      reload();
    }
  };

  const [addNewUser, addingNewUser] = useMutation<[]>({
    func: "Ver2-Team-iutt",
    onSuccess: () => {
      showMessage(SuccessfulUserCreated, "success");
      setNewUserEmail("");
      reload();
    },
    onError: (_, message) => {
      if (message === "El usuario no tiene cuenta de Geest, Registralo") {
        setNoAccountUser(newUserEmail);
        setNewUserEmail("");
        setModal("RegisterUser");
      }
    },
  });

  const handleAddNewUser = () => {
    if (isNewUserEmailEmpty || !isNewUserEmailValid) {
      showMessage(ErrorNewEmailEmpty, "error");
    }
    if (isNewUserOnTeam) {
      showMessage(ErrorNewEmailInTeam, "error");
    }
    if (isNewUserEmailEmpty || !isNewUserEmailValid || isNewUserOnTeam) return;
    addNewUser({
      args: {
        IdTeam: idTeam,
        Email: newUserEmail,
      },
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      if (e) e.preventDefault();
      handleAddNewUser();
    }
  };

  const Modals: { [key: string]: React.ReactNode } = {
    Leave: (
      <LeaveModal
        idTeam={idTeam}
        title={PopoverLabels["LEAVETEAM"]}
        cancelText={ButtonsLabels["CANCEL"]}
        okText={ButtonsLabels["LEAVE"]}
        onClose={() => setModal("")}
      />
    ),
    Delete: (
      <DeleteTeamModal
        idTeam={idTeam}
        name={teamName}
        onClose={() => setModal("")}
      />
    ),
    ExpellUser: (
      <ExpellUserModal
        idTeam={idTeam}
        user={actionsUser}
        title={PopoverLabels["DELETEMEMBER"]}
        cancelText={ButtonsLabels["CANCEL"]}
        okText={ButtonsLabels["DELETE"]}
        onClose={() => setModal("")}
        reload={reload}
      />
    ),
    RegisterUser: (
      <RegisterNewUserModal
        idTeam={idTeam}
        noAccountUser={noAccountUser}
        title={AddUserLabel}
        alertText={AddUserAlert}
        description={AddUserDescription}
        cancelText={ButtonsLabels["CANCEL"]}
        okText={Add}
        onClose={() => setModal("")}
        reload={reload}
      />
    ),
  };

  return (
    <TeamContainer>
      {Modals[modal]}
      <ViewTitle
        ShowBack
        ShowTeamIcon
        onBack={() => history.push("/home/teams")}
      >
        <TeamDetailHeader>
          <HeaderItem
            $selected={view === "Info" && userPermissions.CanEditTeamAndMembers}
            onClick={() => setView("Info")}
          >
            Información de la empresa
          </HeaderItem>
          {userPermissions.CanEditTeamAndMembers && (
            <HeaderItem
              $selected={view === "Conf"}
              onClick={() => setView("Conf")}
            >
              Configuración
            </HeaderItem>
          )}
        </TeamDetailHeader>
      </ViewTitle>
      <Container>
        <LeftSidebar>
          <LeftInfo>
            <MembersTitle>
              <div>Integrantes</div>
              <MembersCount>
                {teamConsulting ? teamMembers?.length - 1 : teamMembers?.length}
              </MembersCount>
            </MembersTitle>

            <TeamLogoContainer>
              <TeamLogo sourceUrl={teamLogo}>
                {userPermissions.CanEditTeamAndMembers && (
                  <TeamLogoUploader
                    beforeUpload={beforeUpload}
                    disabled={!userPermissions.CanEditTeamAndMembers}
                    fileList={[]}
                    accept={"image/png, image/jpeg"}
                    showUploadList={false}
                  >
                    <EditTeamLogo>
                      <MdEdit size="50%" color="#222" />
                    </EditTeamLogo>
                  </TeamLogoUploader>
                )}
              </TeamLogo>
            </TeamLogoContainer>

            {(isTeamLead || userPermissions.CanEditTeamAndMembers) && (
              <ConsultingButton
                consultorsData={consultorsData}
                active={teamConsulting}
                reload={reload}
              />
            )}

            <Button
              onClick={() => history.push(`/home/teams/${idTeam}/org`)}
              type="secondary"
              SvgIcon={OrgIcon}
              style={{ width: "210px" }}
            >
              {ButtonsLabels["ORG"]}
            </Button>
          </LeftInfo>
          {isTeamLead ? (
            <Button
              onClick={() => setModal("Delete")}
              type="secondary"
              danger
              Icon={FiTrash}
              style={{ width: "210px" }}
            >
              {ButtonsLabels["DELETETEAM"]}
            </Button>
          ) : (
            <Button
              onClick={() => setModal("Leave")}
              type="secondary"
              danger
              Icon={IoExitOutline}
              style={{ width: "210px" }}
            >
              Abandonar empresa
            </Button>
          )}
          {/*<ReportCurrencyContainer>
            <p>
              Selecciona la divisa con la que se reportarán las ventas de la
              empresa
            </p>
            <GeestSelect
              value={reportsCurrencyFormat}
              options={transformedOptions}
              onChange={onChangeFormat}
              disabled={!userPermissions.CanEditTeamAndMembers}
              $width="80px"
              $listWidth="200px"
              valueNecesary
              placeholderSelect="Divisa..."
            />
        </ReportCurrencyContainer>*/}
        </LeftSidebar>

        <TeamContent>
          <InputContainer>
            {!editingTitle ? (
              <TeamTitle>
                {teamName}
                <EditIcon
                  size={32}
                  filled
                  onClick={() => setEditingTitle(true)}
                />
              </TeamTitle>
            ) : (
              <TeamTitleInput
                value={newTitle}
                ref={titleRef}
                onChange={(e) => setNewTitle(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    editTitle();
                  }
                }}
                onBlur={editTitle}
                onFocus={(e) => {
                  e.target.select();
                }}
              />
            )}
            {userPermissions.CanEditTeamAndMembers && view === "Info" && (
              <>
                <AddUserInput
                  value={newUserEmail}
                  placeholder={AddUserLabel}
                  onChange={({ target: { value } }) => {
                    if (value.split("")[value.length - 1] !== " ")
                      setNewUserEmail(value);
                  }}
                  onKeyDown={handleKeyPress}
                />
                <Button
                  disabled={addingNewUser || isNewUserEmailEmpty}
                  onClick={handleAddNewUser}
                  tabIndex={0}
                  onKeyDown={({ key }) => {
                    if (key === "Enter") handleAddNewUser();
                  }}
                  type="primary"
                >
                  {Add}
                </Button>
              </>
            )}
          </InputContainer>
          {view === "Info" && (
            <MembersList
              idTeam={idTeam}
              canEdit={userPermissions.CanEditTeamAndMembers}
              teamLeaderId={teamLeaderId}
              teamMembers={teamMembers}
              teamRoles={teamRoles}
              isConsultor={isConsultor}
              setNoAccountUser={setNoAccountUser}
              openRegisterNewUser={() => setModal("RegisterUser")}
              openExpellUser={() => setModal("ExpellUser")}
              setActionsUser={setActionsUser}
              reload={reload}
            />
          )}
          {view === "Conf" && (
            <TeamConfiguration
              onChangeReportsCurrencyFormat={onChangeReportsFormat}
              reportsCurrencyFormat={reportsCurrencyFormat}
              fieldsCurrencyFormat={fieldsCurrencyFormat}
              onChangeFieldsCurrencyFormat={onChangeFieldsCurrenyFormat}
              fieldsPhoneFormat={fieldsPhoneFormat}
              onChangeFieldsPhoneFormat={onChangeFieldsPhoneFormat}
            />
          )}
        </TeamContent>
      </Container>
    </TeamContainer>
  );
};

export default TeamDetails;
