import styled from "styled-components";

export const SignConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ConfigRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 34px;
`;

export const ConfigRowName = styled.p`
  margin: 0;
  color: #48505e;
  font-family: Gotham-Bold;
`;

export const InputsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 2px;

  :hover {
    background-color: #cce6ff;
  }
`;
