import React from "react";
import ViewTitle from "../../../components/ViewTitle/ViewTitle";
import { Button } from "../../../GeestUI";
import ImportIcon from "../../../components/hoverIcons/ImportIcon";
import { ButtonList, HeaderContent, ImportIconWrapper, Title } from "./styles";

interface HeaderProps {
  syncing: boolean;
  unsavedChanges: boolean;
  importInputRef: React.RefObject<HTMLInputElement>;
  handleGoBack: () => void;
  handleOnImport: (e: any) => void;
  handleOnSave: () => void;
  fieldLabel?: string;
}

const Header: React.FC<HeaderProps> = ({
  syncing,
  unsavedChanges,
  importInputRef,
  handleGoBack,
  handleOnImport,
  handleOnSave,
  fieldLabel,
}) => {
  const onUpload = () => {
    importInputRef.current?.click();
  };

  return (
    <ViewTitle ShowBack onBack={handleGoBack} ShowTeamIcon headerMT="7px">
      <HeaderContent>
        <Title>
          <p>Configurador de PDF {!!fieldLabel && ` -  ${fieldLabel}`}</p>
        </Title>

        <ButtonList>
          <Button
            onClick={onUpload}
            disabled={syncing}
            type="secondary"
            Icon={() => (
              <ImportIconWrapper>
                <ImportIcon />
              </ImportIconWrapper>
            )}
          >
            <input
              ref={importInputRef}
              type="file"
              accept="application/pdf"
              onChange={handleOnImport}
              style={{ display: "none" }}
            />
            Importar
          </Button>
          <Button
            onClick={handleOnSave}
            disabled={syncing || !unsavedChanges}
            type="primary"
          >
            Guardar
          </Button>
        </ButtonList>
      </HeaderContent>
    </ViewTitle>
  );
};

export default Header;
