import React from "react";
import FileConfig from "./FieldConfig/FileConfig";
import DateConfig from "./FieldConfig/DateConfig";
import TimeConfig from "./FieldConfig/TimeConfig";
import ChecklistConfig from "./FieldConfig/ChecklistConfig";
import VarDBRowConfig from "./FieldConfig/VarDBRowConfig";
import SelectConfig from "./FieldConfig/SelectConfig";
import CurrencyConfig from "./FieldConfig/CurrencyConfig";
import NumberConfig from "./FieldConfig/NumberConfig";
import PhoneNumber from "./FieldConfig/PhoneNumber";
import UserSelectConfig from "./FieldConfig/UserSelectConfig";
import MultiSelectConfig from "./FieldConfig/MultiSelectConfig";
import StringConfig from "./FieldConfig/StringConfig";
import LocationConfig from "./FieldConfig/LocationConfig";
import {
  DataOrigins,
  ElementType,
  FieldDataOrigin,
  ImportedDataOrigin,
  VarDBRelationship,
} from "../EditField.d";
import PDFAutoGeneratedConfig from "./FieldConfig/PDFAutoGeneratedConfig";

interface FieldConfigurationProps {
  dataType: string;
  filesPath: string;
  disabled: boolean;
  IdField: number | null;
  value: any;
  setValue: (newValue: any) => void;
  configuration: string;
  setConfiguration: (newConfig: string) => void;
  format: string;
  setFormat: (newFormat: string) => void;
  dataOrigins: DataOrigins;
  fieldDataOrigin: FieldDataOrigin[] | ImportedDataOrigin | null;
  setFieldDataOrigin: (
    newDataOrigin: FieldDataOrigin[] | ImportedDataOrigin | null
  ) => void;
  checklistElements: ElementType[];
  setChecklistElements: (newElements: ElementType[]) => void;
  idVarDB: number | null;
  setIdVarDB: (id: number | null) => void;
  IdFieldParent: number | null;
  varDBRelationship: VarDBRelationship;
  setVarDBRelationship: (newRelation: VarDBRelationship) => void;
  FieldLabel: string;
}

const FieldConfiguration: React.FC<FieldConfigurationProps> = ({
  dataType,
  filesPath,
  disabled,
  IdField,
  value,
  setValue,
  configuration,
  setConfiguration,
  format,
  setFormat,
  dataOrigins,
  fieldDataOrigin,
  setFieldDataOrigin,
  checklistElements,
  setChecklistElements,
  idVarDB,
  setIdVarDB,
  IdFieldParent,
  varDBRelationship,
  setVarDBRelationship,
  FieldLabel,
}) => {
  if (dataType === "file") {
    return (
      <FileConfig
        value={value}
        onChange={setValue}
        FilesPath={filesPath}
        disabled={disabled}
      />
    );
  }

  if (dataType === "date") {
    return <DateConfig value={value} onChange={setValue} disabled={disabled} />;
  }

  if (dataType === "time") {
    return <TimeConfig value={value} onChange={setValue} disabled={disabled} />;
  }

  if (dataType === "checklist") {
    return (
      <ChecklistConfig
        checklistElements={checklistElements}
        setChecklistElements={setChecklistElements}
        disabled={disabled}
      />
    );
  }

  if (dataType === "varDBRow") {
    return (
      <VarDBRowConfig
        IdField={IdField}
        idVarDB={idVarDB}
        setIdVarDB={setIdVarDB}
        varDBRelationship={varDBRelationship}
        setVarDBRelationship={setVarDBRelationship}
        disabled={disabled}
        FieldLabel={FieldLabel}
      />
    );
  }

  if (dataType === "select") {
    return (
      <SelectConfig
        IdField={IdField}
        value={value}
        onChange={setValue}
        fieldDataOrigin={fieldDataOrigin}
        setFieldDataOrigin={setFieldDataOrigin}
        configuration={configuration}
        setConfiguration={setConfiguration}
        disabled={disabled}
      />
    );
  }

  if (dataType === "currency") {
    return (
      <CurrencyConfig
        value={value}
        onChange={setValue}
        format={format}
        setFormat={setFormat}
        disabled={disabled}
      />
    );
  }

  if (dataType === "number") {
    return (
      <NumberConfig value={value} onChange={setValue} disabled={disabled} />
    );
  }

  if (dataType === "phone_number") {
    return (
      <PhoneNumber
        value={value}
        onChange={setValue}
        format={format}
        setFormat={setFormat}
        disabled={disabled}
      />
    );
  }

  if (dataType === "users_select") {
    return (
      <UserSelectConfig
        value={value}
        onChange={setValue}
        dataOrigins={dataOrigins}
        disabled={disabled}
      />
    );
  }

  if (dataType === "multi_select") {
    return (
      <MultiSelectConfig
        IdField={IdField}
        value={value}
        onChange={setValue}
        fieldDataOrigin={fieldDataOrigin}
        setFieldDataOrigin={setFieldDataOrigin}
        configuration={configuration}
        setConfiguration={setConfiguration}
        disabled={disabled}
      />
    );
  }

  if (dataType === "string") {
    return (
      <StringConfig value={value} onChange={setValue} disabled={disabled} />
    );
  }

  if (dataType === "location") {
    return (
      <LocationConfig
        value={value}
        onChange={setValue}
        configuration={configuration}
        setConfiguration={setConfiguration}
        disabled={disabled}
      />
    );
  }

  if (dataType === "pdf_autogenerated") {
    return <PDFAutoGeneratedConfig IdField={IdField} disabled={disabled} />;
  }

  if (dataType === "signature" && IdFieldParent) {
    return (
      <PDFAutoGeneratedConfig IdField={IdFieldParent} disabled={disabled} />
    );
  }

  return (
    <div>
      <p>fallback</p>
    </div>
  );
};

export default FieldConfiguration;
