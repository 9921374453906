import React, { useState } from "react";
import DynamicInput from "../../../../../../../components/DynamicInput";
import { FieldConfigWrapper, SubTitle } from "../../Styles";

interface PhoneNumberProps {
  value: string;
  onChange: (newValue: string) => void;
  format: string;
  setFormat: (newFormat: string) => void;
  disabled: boolean;
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({
  value,
  onChange,
  format,
  setFormat,
  disabled,
}) => {
  const [localValue, setLocalValue] = useState<string>(value);

  return (
    <>
      <FieldConfigWrapper>
        <SubTitle>Valor por defecto</SubTitle>
        <DynamicInput
          value={localValue ?? ""}
          type="phone_number"
          dataOrigin={[]}
          format={format}
          configuration=""
          fieldName=""
          isConsult={false}
          disabled={disabled}
          required={false}
          onChange={setLocalValue}
          onChangeFormat={setFormat}
          onBlur={() => onChange(localValue)}
        />
      </FieldConfigWrapper>
    </>
  );
};

export default PhoneNumber;
