import styled, { css } from "styled-components";

export const Title = styled.div<{ $fontSize?: string }>`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  color: #48505e;
  font-family: Gotham-Bold;
  font-size: ${({ $fontSize = "14px" }) => $fontSize};
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  height: 100%;
  padding: 0px 10px;
`;

export const Content = styled(Container)`
  height: calc(100% - 42px);
  overflow: auto;
  gap: 10px;
`;

export const StyledInput = styled.input`
  border: 2px solid #edecec;
  color: #828d9e;
  border-radius: 6px;
  padding-left: 6px;
  width: 330px;
  height: 32px;
  font-size: 14px;
  font-weight: 300;
  font-family: "Gotham-Book";
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-contet: center;
  gap: 12px;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-contet: center;
  gap: 8px;
  color: #48505e;

  p {
    font-size: 18px;
    font-weight: 325;
    font-family: Gotham-Book;
    margin: 0;
  }
`;

export const SelectTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const SelectRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const GroupsContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

export const ConditionalGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const DeleteGroupIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 9999;
`;

export const ConditionsContainer = styled.div`
  width: 100%;
  margin-top: -13px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #f2f8fe;
  z-index: 1;
  padding: 15px 6px;
  border-radius: 6px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  height: 24px;
  width: 51px;
  align-self: center;
  cursor: pointer;
`;

export const SwitchOption = styled.div<{ $selected: boolean; $side: string }>`
  border-radius: ${({ $side }) =>
    $side === "And" ? "6px 0px 0px 6px" : "0px 6px 6px 0px"};
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: middle;
  font-size: 14px;
  font-family: Gotham-Bold;
  justify-content: center;
  ${({ $selected }) =>
    $selected
      ? css`
          color: #ffffff;
          background-color: #0273e9;
        `
      : css`
          background-color: #edecec;
          color: #828d9e;
        `}
`;

export const DynamicInputContainer = styled.div<{ $limitHeight: boolean }>`
  display: flex;
  align-items: center;
  width: 196px;
  ${({ $limitHeight }) =>
    $limitHeight &&
    css`
      height: 32px;
    `}
`;

export const SelectedOptionLabelContainer = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-wrap: nowrap !important;
`;
