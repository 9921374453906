import React from "react";
import styled from "styled-components";
import { Space } from "antd";
import { Circle } from "../../../../../components/ColorPicker";
import { StageDataOriginType } from "../DynamicCell.d";

const StyledSpace = styled(Space)`
  p {
    margin: 0;
    font-size: 14px;
    font-family: Gotham-Book;
    color: #48505e;
  }
`;

interface StageComponentProps {
  value: number | undefined | null;
  stageDataOrigin: StageDataOriginType[];
}

const StageComponent: React.FC<StageComponentProps> = ({
  value,
  stageDataOrigin,
}) => {
  const getStageInfo = (stage: number | undefined | null) => {
    const stageInfo = stage
      ? stageDataOrigin.find((s: StageDataOriginType) => s.IdStage === stage)
      : null;
    if (!stageInfo) return { Color: "default", Label: stage, Value: stage };

    return stageInfo;
  };

  return (
    <StyledSpace size={4}>
      <Circle color={getStageInfo(value).Color} />
      <p>{getStageInfo(value).Label}</p>
    </StyledSpace>
  );
};

export default StageComponent;
