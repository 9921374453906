import React from "react";
import { Row, Tooltip } from "antd";
import { IoClose } from "react-icons/io5";
import { AiFillClockCircle, AiFillHome, AiFillMessage } from "react-icons/ai";
import { StyledTabs, Tab, CloseIconContainer } from "./../TaskEditor/styles";
import { SubfluxEditorPages, TabsHeaderProps } from "./SubfluxEditor.d";

const TabsHeader: React.FC<TabsHeaderProps> = ({
  value,
  onChange,
  onClose,
}) => {
  return (
    <StyledTabs>
      <Row style={{ flex: 1 }}>
        <Tooltip title="General" placement="bottom" mouseLeaveDelay={0}>
          <Tab
            active={value === SubfluxEditorPages.GENERAL}
            onClick={() => onChange(SubfluxEditorPages.GENERAL)}
          >
            <AiFillHome color="#FFFFFF" size={32} />
          </Tab>
        </Tooltip>
        <Tooltip title="Programar" placement="bottom" mouseLeaveDelay={0}>
          <Tab
            active={value === SubfluxEditorPages.SCHEDULE}
            onClick={() => onChange(SubfluxEditorPages.SCHEDULE)}
          >
            <AiFillClockCircle color="#FFFFFF" size={32} />
          </Tab>
        </Tooltip>
        <Tooltip title="Chats" placement="bottom" mouseLeaveDelay={0}>
          <Tab
            active={value === SubfluxEditorPages.CHATS}
            onClick={() => onChange(SubfluxEditorPages.CHATS)}
          >
            <AiFillMessage color="#FFFFFF" size={32} />
          </Tab>
        </Tooltip>
      </Row>
      <CloseIconContainer onClick={onClose}>
        <IoClose color="#FFFFFF" size={15} />
      </CloseIconContainer>
    </StyledTabs>
  );
};

export default TabsHeader;
