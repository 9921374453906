import React from "react";
//import { Person } from "@material-ui/icons";
import { NodeCont, NodeHeader, NodeBody, Text } from "./Style";

interface NodeParams {
  nodeData: {
    NodeTitle: string;
    Id: string;
  };
}

const Node: React.FC<NodeParams> = ({ nodeData: { NodeTitle, Id } }) => {
  const isTeamNode = Id === "teamNode";
  return (
    <NodeCont>
      <NodeHeader isTeamNode={isTeamNode} />
      <NodeBody>
        <Text>{NodeTitle}</Text>
      </NodeBody>
    </NodeCont>
  );
};

export default Node;
