import React, { useContext } from "react";
import { Trigger, TriggerOption } from "../../TriggersConfig";
import { Languages } from "../../Dictionary";
import GeestLogo from "../../../../../../../resources/img/ICONO_.svg";
import GeestCalendar from "../../../../../../../resources/img/GeestCalendar.svg";
import Tooltip from "../../../../../../../components/Tooltip";
import {
  ViewContentAddTrigger,
  CardOption,
  CardTitle,
  CardDescription,
} from "../Style";
import { useMutation } from "../../../../../../../hooks";
import manualTriggerIcon from "../../../../../../../resources/img/manualTriggerIcon.svg";
import formTriggerIcon from "../../../../../../../resources/img/formTriggerIcon.svg";
import webHookTriggerIcon from "../../../../../../../resources/img/webHookTriggerIcon.svg";
import { ConfiguratorContext } from "../../../../../Configurator";

const { OnlyOneManual } = Languages["ESP"];

interface AddTriggerViewProps {
  IdTeam: string;
  IdProcessTemplate: string;
  triggerOptions: TriggerOption[];
  setView: (newView: string) => void;
  setTriggers: (triggers: Trigger[]) => void;
  handleSelectTrigger: (id: string, type: string) => void;
  reload: (newId?: string | null) => void;
}

interface AddTemplateTriggersProps {
  MyTriggers: Trigger[];
}

const getTriggerIcon = (type: string) => {
  switch (type) {
    case "Manual":
      return <img src={manualTriggerIcon} width="31px" height="31px" alt="" />;
    case "Form":
      return <img src={formTriggerIcon} width="31px" height="31px" alt="" />;
    case "Webhook":
      return <img src={webHookTriggerIcon} width="31px" height="31px" alt="" />;
    case "Calendar":
      return <img src={GeestCalendar} width="30px" height="30px" alt="" />;
    default:
      return <img src={GeestLogo} width="30px" height="30px" alt="" />;
  }
};

const AddTriggerView: React.FC<AddTriggerViewProps> = ({
  IdTeam,
  IdProcessTemplate,
  triggerOptions,
  setView,
  setTriggers,
  handleSelectTrigger,
  reload,
}) => {
  const { refreshProcessFlow } = useContext(ConfiguratorContext);

  const [AddTemplateTriggers] = useMutation<AddTemplateTriggersProps>({
    func: "Ver2-Configurator-att",
    onSuccess: ({ MyTriggers }) => {
      setTriggers(MyTriggers);
      const trigger = MyTriggers[MyTriggers.length - 1];
      handleSelectTrigger(trigger.IdPrepublishTrigger, trigger.Type);
      reload();
      refreshProcessFlow();
    },
  });

  const handleAddTrigger = (option: TriggerOption) => {
    if (!option.CanAddTrigger) return;
    AddTemplateTriggers({
      args: {
        IdTeam,
        IdProcessTemplate,
        Type: option.Type,
      },
    });
  };

  return (
    <ViewContentAddTrigger>
      {triggerOptions.map((option) =>
        option.CanAddTrigger ? (
          <CardOption
            key={option.Title}
            onClick={() => handleAddTrigger(option)}
            $canAddTrigger={option.CanAddTrigger}
          >
            <CardTitle $canAddTrigger={option.CanAddTrigger}>
              {getTriggerIcon(option.Type)}
              <span>{option.Title}</span>
            </CardTitle>
            <CardDescription>{option.Description}</CardDescription>
          </CardOption>
        ) : (
          <Tooltip title={OnlyOneManual} key={option.Title}>
            <CardOption
              onClick={() => handleAddTrigger(option)}
              $canAddTrigger={option.CanAddTrigger}
            >
              <CardTitle $canAddTrigger={option.CanAddTrigger}>
                {getTriggerIcon(option.Type)}
                <span>{option.Title}</span>
              </CardTitle>
              <CardDescription>{option.Description}</CardDescription>
            </CardOption>
          </Tooltip>
        )
      )}
    </ViewContentAddTrigger>
  );
};

export default AddTriggerView;
