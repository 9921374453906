import axios from "axios";
import { loadFromLocalStorage } from "../config/Helpers";
import queryString from "query-string";
import { Languages } from "./Dictionary";
import { formatQuery } from "../Helpers";
import { VersionCode as uiVersion } from "../components/GeestVersion/GeestVersion";

let TokenExpiredEvt = document.createEvent("Event");
TokenExpiredEvt.initEvent("TokenExpired", true, true);
const { ServerErrorMessage } = Languages["ESP"];

export const ApoloInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SOCKET_URL}api/`,
});

const ERROR = "Error|";
const UNAUTHORIZED = "Error|Unauthorized|";
const SUBSCRIPTIONS = "Error|Subscriptions|";
const OUTDATEDVERSION = "Error|OutdatedVersion|";

const handleUnauthorized = (response) => {
  document.dispatchEvent(TokenExpiredEvt);
  return { Request_Error: response.data.slice(UNAUTHORIZED.length) };
};

const handleSubscriptions = (response) => {
  const { Message = "", RedirectUrl = "" } = JSON.parse(
    response.data.slice(SUBSCRIPTIONS.length)
  );
  const [, params] = RedirectUrl.split("?");
  const { redirect } = queryString.parse("?" + params);
  return { Request_Error: Message, Redirect: redirect };
};

const handleOutdatedVersion = () => {
  window.location.reload();
  return { Request_Error: "Outdated UI version", Type: "info" };
};

ApoloInstance.interceptors.response.use(
  (response) => {
    const responseString = JSON.stringify(response);

    if (responseString.includes(ERROR)) {
      // Token
      if (responseString.includes(UNAUTHORIZED))
        return handleUnauthorized(response);
      // Subscriptions
      if (responseString.includes(SUBSCRIPTIONS))
        return handleSubscriptions(response);
      // OutdatedVersion
      if (responseString.includes(OUTDATEDVERSION))
        return handleOutdatedVersion();

      return { Request_Error: response.data.slice(ERROR.length) };
    } else {
      return formatQuery(response.data);
    }
  },
  (error) => {
    //descomentar para ver mensaje de error de back en consola
    //console.log(JSON.parse(error.request.response).Message);
    return { Request_Error: ServerErrorMessage };
  }
);

const apoloServer = (route, method, params = {}, body = {}) => {
  const Sesion = loadFromLocalStorage();
  if (Sesion) {
    const { Auth } = Sesion;
    ApoloInstance.defaults.headers.common["Authorization"] = Auth;
  }
  let urlParams = [];
  Object.keys(params).forEach((param) => {
    urlParams.push([param, params[param]]);
  });
  urlParams.push(["uiVersion", uiVersion]);
  params = new URLSearchParams(urlParams);
  if (method === "get" || method === "delete") {
    return ApoloInstance[method](route, { params, method });
  } else {
    return ApoloInstance[method](route, body, { params });
  }
};

export default apoloServer;
