import React from "react";
import {
  DetailTitle,
  ActivityTabContainer,
  AcitivityInfoContainer,
  TaskCountsContainer,
  StuckTasksCount,
  StartedTaskCount,
  FinishedTaskCount,
  WaitingTaskCount,
  RevisionTaskCount,
  ActivityContainer,
  NodeContainer,
  Node,
  NodeLink,
  ActivitiesContainer,
  ActivityInfo,
  Label,
  UserContainer,
  Row,
} from "../styles";
import { useParams } from "react-router-dom";
import { ActivityProps, ProcessAcitivityInfo } from "../ProcessDetail.d";
import { useFetch } from "../../../../hooks";
import { parseEmoji } from "../../../../emoji";
import Tooltip from "../../../../components/Tooltip";
import {
  UserInitials,
  UserProfilePic,
} from "../../SharedComponents/User/Styles";
import _ from "lodash";
import moment from "moment";
import taskCountIcon from "../../../../resources/img/taskCountIcon.svg";
import nodeIcon from "../../../../resources/img/nodeIcon.svg";

const Activity: React.FC<ActivityProps> = ({ setState }) => {
  const { idProcess } = useParams<{ idProcess: string }>();

  const { data } = useFetch<ProcessAcitivityInfo>({
    func: "Ver2-Reports-gpac",
    args: { IdProcessExecution: idProcess },
  });

  const userLimit = 4;

  return (
    <ActivityTabContainer>
      <DetailTitle>¿Qué está pasando?</DetailTitle>
      <AcitivityInfoContainer>
        <ActivityContainer>
          <NodeContainer>
            {data?.Activity.map((a, index) => (
              <>
                {index > 0 && <NodeLink />}
                <Node />
              </>
            ))}
          </NodeContainer>
          <ActivitiesContainer>
            {data?.Activity.map(({ UsersInvolved, Title, Message, Date }) => (
              <ActivityInfo>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0px",
                    flex: 1,
                    maxWidth: "calc(100% - 150px)",
                    overflow: "hidden",
                  }}
                >
                  <DetailTitle
                    style={{
                      width: "calc(100% - 4px)",
                      overflow: "hidden",
                      fontSize: "14px",
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "calc(100% - 3px)",
                      }}
                    >
                      {Title.length > 70 ? (
                        <Tooltip title={parseEmoji(Title || "")}>
                          {parseEmoji(Title || "")}
                        </Tooltip>
                      ) : (
                        parseEmoji(Title || "")
                      )}
                    </div>
                  </DetailTitle>
                  <Label>{Message}</Label>
                </div>
                <UserContainer style={{ width: "78px" }}>
                  {!!UsersInvolved ? (
                    _.slice(UsersInvolved, 0, userLimit).map((user, index) => (
                      <Tooltip
                        title={
                          index === userLimit - 1
                            ? _.slice(
                                UsersInvolved,
                                index,
                                UsersInvolved.length
                              )
                                .map(
                                  (user) => `${user.FirstName} ${user.LastName}`
                                )
                                .join(", ")
                            : `${user.FirstName} ${user.LastName}`
                        }
                        placement="bottom"
                      >
                        {!user.ProfilePicture ? (
                          <UserInitials
                            align="middle"
                            justify="center"
                            picSize="30px"
                            style={{
                              opacity:
                                (UsersInvolved || []).length > userLimit &&
                                index === userLimit - 1
                                  ? 0.6
                                  : 1,
                              margin: 0,
                              marginLeft: index > 0 ? "-10px" : "0",
                            }}
                          >
                            {user.Initials}
                            <div
                              style={{
                                position: "absolute",
                                margin: "auto",
                                fontSize: "15px",
                                opacity: 1,
                              }}
                            >
                              {(UsersInvolved || []).length > userLimit &&
                                index === userLimit - 1 &&
                                `+${(UsersInvolved || []).length - userLimit}`}
                            </div>
                          </UserInitials>
                        ) : (
                          <UserProfilePic
                            image={user.ProfilePicture}
                            align="middle"
                            justify="center"
                            picSize="30px"
                            style={{
                              opacity:
                                (UsersInvolved || []).length > userLimit &&
                                index === userLimit - 1
                                  ? 0.6
                                  : 1,
                              margin: 0,
                              marginLeft: index > 0 ? "-15px" : "0",
                            }}
                          >
                            <div style={{ opacity: 1, color: "#48505e" }}>
                              {(UsersInvolved || []).length > userLimit &&
                                index === userLimit - 1 &&
                                `+${(UsersInvolved || []).length - userLimit}`}
                            </div>
                          </UserProfilePic>
                        )}
                      </Tooltip>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "15px",
                        backgroundColor: "rgba(0, 103, 176, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="node"
                        src={nodeIcon}
                        style={{
                          height: "20px",
                          width: "16px",
                        }}
                      />
                    </div>
                  )}
                </UserContainer>
                <Label style={{ width: "56px", justifyContent: "flex-end" }}>
                  {moment(Date).isValid()
                    ? moment(Date).format("DD/MMM")
                    : Date}
                </Label>
              </ActivityInfo>
            ))}
          </ActivitiesContainer>
        </ActivityContainer>
        <TaskCountsContainer>
          <StuckTasksCount onClick={() => setState("flux")}>
            <Row style={{ height: "172px", flex: 1, gap: "12px" }}>
              <div
                className="square"
                style={{
                  width: "48px",
                  height: "48px",
                  minWidth: "48px",
                  minHeight: "48px",
                }}
              >
                <img
                  alt="tasks"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                  src={taskCountIcon}
                />
              </div>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  width: "calc(100% - 100px)",
                }}
              >
                Tareas vencidas
              </div>
            </Row>
            <div
              className="square"
              style={{
                width: "78px",
                height: "73px",
              }}
            >
              {data?.StuckTasks}
            </div>
          </StuckTasksCount>
          <StartedTaskCount onClick={() => setState("flux")}>
            <Row style={{ height: "172px", flex: 1, gap: "12px" }}>
              <div
                className="square"
                style={{
                  width: "48px",
                  height: "48px",
                }}
              >
                <img
                  alt="tasks"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                  src={taskCountIcon}
                />
              </div>
              <div
                style={{
                  height: "100%",
                  flex: 1,
                  display: "flex",
                }}
              >
                Tareas iniciadas
              </div>
            </Row>
            <div
              className="square"
              style={{
                width: "78px",
                height: "73px",
              }}
            >
              {data?.StartedTasks}
            </div>
          </StartedTaskCount>
          <WaitingTaskCount onClick={() => setState("flux")}>
            <Row style={{ height: "172px", flex: 1, gap: "12px" }}>
              <div
                className="square"
                style={{
                  width: "48px",
                  height: "48px",
                }}
              >
                <img
                  alt="tasks"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                  src={taskCountIcon}
                />
              </div>
              <div
                style={{
                  height: "100%",
                  flex: 1,
                  display: "flex",
                }}
              >
                Tareas por iniciar
              </div>
            </Row>
            <div
              className="square"
              style={{
                width: "78px",
                height: "73px",
              }}
            >
              {data?.WaitingTasks}
            </div>
          </WaitingTaskCount>
          <FinishedTaskCount onClick={() => setState("flux")}>
            <Row style={{ height: "172px", flex: 1, gap: "12px" }}>
              <div
                className="square"
                style={{
                  width: "48px",
                  height: "48px",
                }}
              >
                <img
                  alt="tasks"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                  src={taskCountIcon}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                }}
              >
                Tareas terminadas
              </div>
            </Row>
            <div
              className="square"
              style={{
                width: "78px",
                height: "73px",
              }}
            >
              {data?.CompletedTasks}
            </div>
          </FinishedTaskCount>
          <RevisionTaskCount onClick={() => setState("flux")}>
            <Row style={{ height: "172px", flex: 1, gap: "12px" }}>
              <div
                className="square"
                style={{
                  width: "48px",
                  height: "48px",
                }}
              >
                <img
                  alt="tasks"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                  src={taskCountIcon}
                />
              </div>
              <div
                style={{
                  height: "100%",
                  flex: 1,
                  display: "flex",
                }}
              >
                Tareas en espera de revisión
              </div>
            </Row>
            <div
              className="square"
              style={{
                width: "78px",
                height: "73px",
              }}
            >
              {data?.PendingRevisionTasks}
            </div>
          </RevisionTaskCount>
        </TaskCountsContainer>
      </AcitivityInfoContainer>
    </ActivityTabContainer>
  );
};

export default Activity;
