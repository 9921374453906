import { create } from "zustand";
import {
  Filter,
  Order,
  User,
  StatusDataOriginType,
  StageDataOriginType,
  PriorityDataOriginType,
  Option,
  TotalsType,
} from "./PipelineTypes.d";
import { TableDataType } from "./DynamicCell/DynamicCell.d";

interface PipelineState {
  zusIdTeam: number;
  setZusIdTeam: (zusIdTeam: number) => void;
  zusFilters: Filter[];
  zusSetFilters: (filters: Filter[]) => void;
  zusOrder: Order;
  zusSetOrder: (order: Order) => void;
  zusHasMoreData: boolean;
  setZusHasMoreData: (hasMoraData: boolean) => void;
  zusTableData: TableDataType;
  setZusTableData: (zusTableData: TableDataType) => void;
  zusTotalsData: TotalsType;
  setZusTotalsData: (totalsData: TotalsType) => void;
  zusUsersSelectDataOrigin: User[];
  setZusUsersSelectDataOrigin: (usersDataOrign: User[]) => void;
  zusStatusDataOrigin: StatusDataOriginType[];
  setZusStatusDataOrigin: (statusDo: StatusDataOriginType[]) => void;
  zusStageDataOrigin: StageDataOriginType[];
  setZusStageDataOrigin: (stageDo: StageDataOriginType[]) => void;
  zusPriorityDataOrigin: PriorityDataOriginType[];
  setZusPriorityDataOrigin: (priorityDo: PriorityDataOriginType[]) => void;
  zusReportOptions: Option[];
  setZusReportOptions: (options: Option[]) => void;
  zusSelectedReport: number | null;
  setZusSelectedReport: (report: number | null) => void;
  zusCurrentPage: number | null;
  setZusCurrentPage: (page: number | null) => void;
  zusScrollTop: number;
  setZusScrollTop: (scroll: number) => void;
}

const usePipeline = create<PipelineState>((set) => ({
  zusIdTeam: 0,
  setZusIdTeam: (zusIdTeam) => set({ zusIdTeam }),
  zusFilters: [],
  zusSetFilters: (zusFilters) => set({ zusFilters }),
  zusOrder: {},
  zusSetOrder: (zusOrder) => set({ zusOrder }),
  zusHasMoreData: true,
  setZusHasMoreData: (zusHasMoreData) => set({ zusHasMoreData }),
  zusTableData: {
    isFirstColumnFixed: true,
    Headers: [],
    Rows: [],
    showTotals: false,
  },
  setZusTableData: (zusTableData) => set({ zusTableData }),
  zusTotalsData: {},
  setZusTotalsData: (zusTotalsData) => set({ zusTotalsData }),
  zusUsersSelectDataOrigin: [],
  setZusUsersSelectDataOrigin: (zusUsersSelectDataOrigin) =>
    set({ zusUsersSelectDataOrigin }),
  zusStatusDataOrigin: [],
  setZusStatusDataOrigin: (zusStatusDataOrigin) => set({ zusStatusDataOrigin }),
  zusStageDataOrigin: [],
  setZusStageDataOrigin: (zusStageDataOrigin) => set({ zusStageDataOrigin }),
  zusPriorityDataOrigin: [],
  setZusPriorityDataOrigin: (zusPriorityDataOrigin) =>
    set({ zusPriorityDataOrigin }),
  zusReportOptions: [],
  setZusReportOptions: (zusReportOptions) => set({ zusReportOptions }),
  zusSelectedReport: null,
  setZusSelectedReport: (zusSelectedReport) => set({ zusSelectedReport }),
  zusScrollTop: 0,
  setZusScrollTop: (zusScrollTop) => set({ zusScrollTop }),
  zusCurrentPage: null,
  setZusCurrentPage: (zusCurrentPage) => set({ zusCurrentPage }),
}));

export default usePipeline;
