import React from "react";
import _ from "lodash";
import { Row, Space } from "antd";
import { Typography } from "../../../../../GeestUI";
import User from "../../../SharedComponents/User";
import { Languages } from "../Dictionary";
import { CloseIcon, PopoverContentWrapper, UserContainer } from "../Styles";
import { TeamMember } from "../../Dashboard.d";

const { H3 } = Typography;
const { InvolvedLabel } = Languages["ESP"];

interface InvolvedPopoverProps {
  value: TeamMember[];
  teamMembers: TeamMember[];
  onChange: (oldInvolved: TeamMember[], newInvolved: TeamMember[]) => void;
  onClose: () => void;
}

const InvolvedPopover: React.FC<InvolvedPopoverProps> = ({
  value,
  teamMembers,
  onChange,
  onClose,
}) => {
  const onSelectMember = (member: TeamMember): void => {
    const existentMember = !!_.find(value, { IdUser: member.IdUser });
    if (existentMember) {
      // Remove member
      onChange(value, _.reject(value, { IdUser: member.IdUser }));
    } else {
      // Add member
      onChange(value, [...value, member]);
    }
  };

  return (
    <PopoverContentWrapper>
      <Row>
        <H3 mb="0" style={{ flex: 1 }}>
          {InvolvedLabel}
        </H3>
        <CloseIcon onClick={onClose} />
      </Row>
      <Space direction="vertical" style={{ width: "100%" }} size={2}>
        {(teamMembers || []).map((member) => (
          <div key={member.IdUser}>
            <UserContainer
              justify="space-between"
              $selected={!!_.find(value, { IdUser: member.IdUser })}
              onClick={() => onSelectMember(member)}
              tabIndex={0}
              onKeyDown={({ key }) => {
                if (key === "Enter") onSelectMember(member);
              }}
            >
              <User user={member} size="middle" placement="top" />
            </UserContainer>
          </div>
        ))}
      </Space>
    </PopoverContentWrapper>
  );
};

export default InvolvedPopover;
