import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useMutation } from "../../../hooks";
import { StyledInput } from "../Styles";
import { Languages } from "../Dictionary";
import Modal from "../../../components/Modal";
import { CreateNewTableProps } from "../DataBases.d";
import { MessagesContext } from "../../../components/AppMessages";
import { Button } from "../../../GeestUI";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CreateNewTable: React.FC<CreateNewTableProps> = ({
  closeModal,
  reload,
  idTeam,
  IdParentFolder,
}) => {
  const { showMessage } = useContext(MessagesContext);
  const [newTitle, setNewTitle] = useState<string>("");

  const ref = useRef<HTMLInputElement>(null);

  const {
    SuccessfulCreationLabel,
    NewTableNameLabel,
    NewTablePlaceholder,
    CreateLabel,
  } = Languages["ESP"];

  const canSubmit = newTitle.trim().length > 0;

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  const [insertVarDB, submiting] = useMutation<{ IdVarDB: number }>({
    func: "Ver2-Vardbs-iv",
    onSuccess: () => {
      showMessage(SuccessfulCreationLabel, "success");
      reload();
    },
  });

  const handleCreate = () => {
    if (!canSubmit) return;
    insertVarDB({
      args: {
        IdTeam: idTeam,
        Title: newTitle.trim(),
        IdParentFolder: IdParentFolder,
      },
    });
    closeModal();
  };

  return (
    <Modal
      title={NewTableNameLabel}
      onCancel={closeModal}
      width="432px"
      bodyStyle={{ height: "158px" }}
    >
      <Wrapper>
        <StyledInput
          autoFocus
          ref={ref}
          disabled={submiting}
          value={newTitle}
          placeholder={NewTablePlaceholder}
          onChange={({ target: { value } }) => setNewTitle(value)}
          onKeyDown={({ key }) => {
            if (key === "Enter" && canSubmit) {
              handleCreate();
            }
          }}
        />
      </Wrapper>
      <Wrapper>
        <Button
          type="primary"
          onClick={handleCreate}
          disabled={submiting || !canSubmit}
        >
          {CreateLabel}
        </Button>
      </Wrapper>
    </Modal>
  );
};

export default CreateNewTable;
