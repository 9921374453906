import React from "react";
import Modal from "../../../../components/Modal";
import { Row, Space } from "antd";
import styled from "styled-components";
import { Button } from "../../../../GeestUI";

const CenteredTitle = styled.div`
  color: #48505e;
  text-align: center;
  font-family: "Gotham-bold";
  font-size: 14px;
`;

const Paragraph = styled.p`
  color: #828d9e;
  margin: 0;
  font-size: 14px;
  text-align: center;
`;

interface DeleteFieldModalProps {
  onDelete: () => void;
  onCancel: () => void;
}

const DeleteFieldModal: React.FC<DeleteFieldModalProps> = ({
  onDelete,
  onCancel,
}) => {
  return (
    <Modal title="Eliminar campo" onCancel={onCancel}>
      <Space direction="vertical" size={12} style={{ width: "100%" }}>
        <CenteredTitle>
          ¿Estás seguro de que deseas eliminar este campo?
        </CenteredTitle>
        <Paragraph>
          Esta acción es irreversible. Sólo bórralo si estás seguro. Si se trata
          de una fila importada de base de datos, se borrarán todos los campos
          de la fila importada.
        </Paragraph>
        <Row justify="center">
          <Button
            type="secondary"
            style={{ marginRight: "8px" }}
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button type="primary" danger onClick={onDelete}>
            Eliminar
          </Button>
        </Row>
      </Space>
    </Modal>
  );
};

export default DeleteFieldModal;
