import styled from "styled-components";
import { Row } from "antd";

export const Container = styled.div`
  flex: 1;
  padding: 2rem 4rem;
  font-size: 18px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const Bold = styled.b`
  font-weight: normal;
  font-family: "gotham-bold";
`;

export const PlanLabel = styled(Bold)`
  color: ${({ planColor }) => planColor};
`;

export const Content = styled(Row)`
  flex: 1;
`;
