import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import _ from "lodash";

interface TableData {
  id: number;
  data: string[];
  color: string;
}

const TableRow: React.FC<{ row: TableData }> = memo(
  ({ row }) => {
    return (
      <tr style={{ backgroundColor: row.color }}>
        {row.data.map((cell, index) => (
          <td
            key={index}
            style={{
              width: "100px",
              border: "1px solid black",
              padding: "8px",
              textAlign: "center",
            }}
          >
            {cell}
          </td>
        ))}
      </tr>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.row.id === nextProps.row.id;
  }
);

const VirtualizedTable: React.FC = () => {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const rowsPerPage = 25;
  const totalRequiredRows = 100;
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const tableRef = useRef<HTMLDivElement>(null);
  const previousScrollPosition = useRef<number>(0);

  const fetchData = useCallback((page: number): Promise<TableData[]> => {
    return new Promise<TableData[]>((resolve) => {
      const startIndex = (page - 1) * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, totalRequiredRows);
      const newData: TableData[] = [];

      for (let i = startIndex; i < endIndex; i++) {
        const rowData: TableData = {
          id: i + 1,
          data: Array.from(
            { length: 20 },
            (_, index) => `Row ${i + 1}, Col ${index + 1}`
          ),
          color: getColor(i + 1),
        };
        newData.push(rowData);
      }

      setTimeout(() => resolve(newData), 1000); // Simulamos un retraso de 1 segundo
    });
  }, []);

  useEffect(() => {
    const loadInitialData = async () => {
      const initialData = await fetchData(1);
      setTableData(initialData);
      setTotalItems(initialData.length);
      if (initialData.length < rowsPerPage) {
        setHasMoreData(false);
      }
    };

    loadInitialData();
  }, [fetchData]);

  useEffect(() => {
    const loadData = async () => {
      const newData = await fetchData(currentPage);
      setTableData((prevData) => [...prevData, ...newData]);
      setTotalItems((prevTotal) => prevTotal + newData.length);
      if (
        newData.length < rowsPerPage ||
        totalItems + newData.length >= totalRequiredRows
      ) {
        setHasMoreData(false);
      }
    };

    if (currentPage > 1 && hasMoreData) {
      loadData();
    }
  }, [currentPage, fetchData, hasMoreData, totalItems]);

  const handleScroll = useCallback(
    _.debounce(() => {
      if (tableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
        if (scrollHeight - scrollTop === clientHeight && hasMoreData) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
        previousScrollPosition.current = scrollTop;
      }
    }, 200),
    [hasMoreData]
  );

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (tableRef.current) {
          tableRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = previousScrollPosition.current;
    }
  }, [tableData]);

  const getColor = useCallback((rowNumber: number): string => {
    if (rowNumber <= 10) {
      return "lightblue";
    } else if (rowNumber <= 20) {
      return "lightgreen";
    } else {
      return "lightcoral";
    }
  }, []);

  return (
    <div style={{ height: "400px", overflow: "scroll" }} ref={tableRef}>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            {Array.from({ length: 20 }, (_, index) => (
              <th
                key={index}
                style={{
                  width: "100px",
                  border: "1px solid black",
                  padding: "8px",
                }}
              >
                Column {index + 1}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <TableRow key={row.id} row={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VirtualizedTable;
