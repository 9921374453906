import React, { useState } from "react";
import styled, { css } from "styled-components";
import { noop as NOOP } from "lodash";
import moment from "moment";
import { Row } from "antd";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Typography } from "../../../GeestUI";
import { Languages } from "./Dictionary";
import IsRequired from "./helpers/IsRequired";
import { AiOutlineCalendar } from "react-icons/ai";

const { P } = Typography;

const { SelectLabel } = Languages["ESP"];

interface DateInputProps {
  value: string;
  onChange: (value: string | null) => void;
  onBlur?: (e: any) => void;
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  extraParams?: {
    center?: boolean;
    mb?: string;
  };
}

const InputContainer = styled.div<{ isConsult: boolean; mb?: string }>`
  ${({ isConsult, mb = "10px" }) => (isConsult ? "" : `margin-bottom: ${mb};`)}
  width: 100%;
  position: relative;
  * {
    margin: 0;
    padding: 0;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  .Mui-disabled {
    cursor: auto;
  }
  .Mui-focused {
    border-color: #48505e !important;
  }
  .MuiInput-formControl {
    ${({ disabled }) =>
      !disabled
        ? css`
            :hover {
              border-color: #0273e9 !important;
              box-shadow: none !important;
            }
          `
        : ""}
    :focus {
      border-color: #48505e !important;
      box-shadow: none !important;
    }
    :focus-visible {
      outline: none !important;
    }
  }
`;

const IconsContainer = styled.div<{ background: string }>`
  background: ${({ background = "white" }) => background};
  position: absolute;
  top: 4px;
  right: 6px;

  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
  padding-left: 2px;
  transition: background 0.35s ease;
`;

const DateInput: React.FC<DateInputProps> = ({
  value,
  onChange,
  onBlur = NOOP,
  isConsult,
  disabled,
  required,
  extraParams = { center: false, mb: "10px" },
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <InputContainer isConsult={isConsult} mb={extraParams.mb}>
      {isConsult ? (
        <Row
          justify={extraParams.center ? "center" : "start"}
          align="middle"
          style={{
            overflow: "auto",
            height: "32px",
          }}
        >
          <P style={{ color: "#48505e" }}>
            {value && moment(value).format("DD/MMM/YY")}
          </P>
        </Row>
      ) : (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePickerStyled
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={value ? moment(value) : null}
            disabled={disabled}
            variant="dialog"
            format="DD/MMM/YY"
            onChange={(value) => onChange(value && value.format("YYYY-MM-DD"))}
            fullWidth
            clearable
            InputProps={{
              disableUnderline: true,
              placeholder: SelectLabel,
              style: {
                border: "2px solid",
                borderColor: required
                  ? value
                    ? "#edecec"
                    : "#db232c"
                  : "#edecec",
                borderRadius: "6px",
                color: "#828d9e",
                background: disabled ? "#edecec" : "white",
                height: "32px",
                paddingLeft: "5px",
                fontSize: "14px",
                transition: "all 0.35s ease",
              },
              endAdornment: (
                <div
                  onClick={() => setOpen(true)}
                  style={{
                    position: "absolute",
                    right: "3px",
                    width: "20px",
                    height: "20px",
                    zIndex: 1,
                  }}
                />
              ),
            }}
          />
        </MuiPickersUtilsProvider>
      )}
      {!isConsult && (
        <IconsContainer background={disabled ? "#edecec" : "white"}>
          <AiOutlineCalendar color="#48505e" size={14} />
        </IconsContainer>
      )}

      {required && !isConsult && !Boolean(value) && <IsRequired />}
    </InputContainer>
  );
};

export default DateInput;
