import React from "react";
import { Row, Tooltip } from "antd";
import { IoClose } from "react-icons/io5";
import { HiMenuAlt2 } from "react-icons/hi";
import { AiFillClockCircle, AiFillHome } from "react-icons/ai";
import { StyledTabs, Tab, CloseIconContainer } from "./styles";
import { TaskEditorPages } from "./TaskEditor.d";

interface TabsHeaderProps {
  value: TaskEditorPages;
  onChange: (value: TaskEditorPages) => void;
  onClose: () => void;
}

const TabsHeader: React.FC<TabsHeaderProps> = ({
  value,
  onChange,
  onClose,
}) => {
  return (
    <StyledTabs>
      <Row style={{ flex: 1 }}>
        <Tooltip title="General" placement="bottom" mouseLeaveDelay={0}>
          <Tab
            active={value === TaskEditorPages.GENERAL}
            onClick={() => onChange(TaskEditorPages.GENERAL)}
          >
            <AiFillHome color="#FFFFFF" size={32} />
          </Tab>
        </Tooltip>
        <Tooltip title="Programar" placement="bottom" mouseLeaveDelay={0}>
          <Tab
            active={value === TaskEditorPages.SCHEDULE}
            onClick={() => onChange(TaskEditorPages.SCHEDULE)}
          >
            <AiFillClockCircle color="#FFFFFF" size={32} />
          </Tab>
        </Tooltip>
        <Tooltip
          title="Campos de formulario"
          placement="bottom"
          mouseLeaveDelay={0}
        >
          <Tab
            active={value === TaskEditorPages.FIELDS}
            onClick={() => onChange(TaskEditorPages.FIELDS)}
          >
            <HiMenuAlt2 color="#FFFFFF" size={32} />
          </Tab>
        </Tooltip>
      </Row>
      <CloseIconContainer onClick={onClose}>
        <IoClose color="#FFFFFF" size={15} />
      </CloseIconContainer>
    </StyledTabs>
  );
};

export default TabsHeader;
