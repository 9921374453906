import { Row } from "antd";
import styled from "styled-components";
import { StyledInput as GenericInput } from "../../Flow/styles";

// <EditStagesDropdown />

export const Card = styled.div`
  padding: 12px 16px;
  width: 304px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
`;

export const List = styled.div``;

// <StageItem />

export const ItemRow = styled(Row)`
  padding: 10px 0;
`;

export const NoStagesPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin: 0;
    font-family: Gotham-bold;
    font-family: 16px;
  }
`;

export const StageLabel = styled.span`
  color: #48505e;
  font-size: 14px;
  margin: 0 4px;
  font-family: Gotham-Book;
`;

// <StageEditor />

export const StyledInput = styled(GenericInput)`
  flex: 1;
  margin-right: 14px;
`;
