import React from "react";
import { Link } from "react-router-dom";
import { Languages } from "./Dictionary";
import { Title } from "../../Login/styles";
// Img
import Img from "../../../components/Img/Img";
import icono from "../../../resources/img/ICONO_.svg";

const InvalidToken = () => {
  const { ReestablishLabel, LinkExpiredLabel, AskNewOneLabel } =
    Languages["ESP"];
  return (
    <div
      style={{
        backgroundColor: "#EAEAEA",
        flex: 1,
        display: "flex",
      }}
    >
      <div
        style={{
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ height: "80px", width: "80px" }}>
            <Img img={icono} size="contain" cursor="default" />
          </div>
          <Title>{ReestablishLabel}</Title>
        </div>
        <div
          style={{
            color: "#828d9e",
            fontFamily: "Gotham-book",
            textAlign: "center",
          }}
        >
          {LinkExpiredLabel}
        </div>
        <div style={{ textAlign: "center" }}>
          <Link
            to="/auth/login/forgotPass"
            style={{
              color: "#0273e9",
              fontSize: "14px",
              fontFamily: "Gotham-bold",
            }}
          >
            {AskNewOneLabel}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InvalidToken;
