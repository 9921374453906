import styled from "styled-components";

export const Subtitle = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin: 0;
    color: #828d9e;
  }
`;

export const GraphTemplatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  max-height: 100%;
  overflow: auto;
`;

export const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 50%;
  padding: 0 14px 20px;
`;

export const GraphCard = styled.div`
  border: 1px solid #0273e9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 39px 20px 16px;
  border-radius: 6px;
  background: white;
  text-align: center;
  cursor: pointer;

  transition: -webkit-filter 0.35s ease;
  :hover {
    filter: drop-shadow(4px 10px 6px rgba(2, 115, 233, 0.3));
  }

  p {
    margin: 0;
  }
  .title {
    font-size: 16px;
    color: #48505e;
  }
  .description {
    font-size: 12px;
    color: #828d9e;
  }
`;

export const GraphIconContaier = styled.img`
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
  border-radius: 3px;
`;
