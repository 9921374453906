export const Languages = {
  ENG: {
    ModalTitleLabel: "Sells",
    StepGeneral: "General",
    StepFilter: "Filters",
    StepVisualization: "Visualization",
    Continue: "Continue",

    // General pannels
    GeneralLeftTitle: "Configure general parameters for your graph",
    GeneralLeftDescription:
      "First, name your new chart, select the process(es) you want to graph, and finally choose the chart format you want to visualize.",

    GeneralLeftNameLabel: "Name",
    GeneralLeftNamePlaceholder: "Durations graph...",
    GeneralLeftSellsNamePlaceholder: "Sells graph...",

    GeneralLeftProcessLabel: "Process",
    GeneralLeftProcessPlaceholder: "Select a process...",
    GeneralLeftProcessesPlaceholder: "Select...",

    GeneralLeftFormatStackbar: "All trackings",
    GeneralLeftFormatAll: "Trackings and averages",
    GeneralLeftFormatDoughnut: "Only averages",

    Previsualization: "Previsualization",

    // Filters pannels
    SelectTheFiltersYouWant: "Select the filters you want!",
    FilterByPlaceholder: "Filter by...",
    FilterLabel: "Filter",
    ApplyFilterLabel: "Apply filter",
    DatePickerPlaceholder: "Select...",

    // Visualization pannel
    VisualizationLeftTitle: "Configure your chart's visualization",
    VisualizationLeftDescription:
      "Choose how you want to group the information and set up the data for the X-axis of the chart. When grouping by a certain field, select a field type from a process (this will be applied to all the processes you chose in the filtering stage).",
    GroupByLabel: "Group",
    ByProcessLabel: "By process",
    ByFieldLabel: "By some field",
    ByNoneLabel: "None",

    YAxisLabel: "Y axis",
    YAxisInformation:
      "Previously, the currency type was selected by a user with configuration permissions in My Teams.",
    ChangeIncomeConfig: "Change income configuration",

    XAxisLabel: "X axis",
    ByYearLabel: "By year",
    ByMonthLabel: "By month",
    ByWeekLabel: "By week",
    ByDayLabel: "By day",
    RemoveSelectionLabel: "Remove selecction",

    AddGraphLabel: "Add graph",
    UpdateGraphLabel: "Update graph",
  },
  ESP: {
    ModalTitleLabel: "Ventas",
    StepGeneral: "General",
    StepFilter: "Filtros",
    StepVisualization: "Visualización",
    Continue: "Continuar",

    // General pannels
    GeneralLeftTitle: "Configura los parámetros generales de tu gráfica",
    GeneralLeftDescription:
      "Mide las ventas de tu empresa de forma general: cuánto estás vendiendo agrupado por vendedor, fecha y más",

    GeneralLeftNameLabel: "Nombre",
    GeneralLeftNamePlaceholder: "Gráfica de duraciones...",
    GeneralLeftSellsNamePlaceholder: "Gráfica de ventas...",

    GeneralLeftProcessLabel: "Proceso",
    GeneralLeftProcessPlaceholder: "Selecciona un proceso...",
    GeneralLeftProcessesPlaceholder: "Selecciona...",

    GeneralLeftFormatStackbar: "Detallado",
    GeneralLeftFormatAll: "Detallado y totales",
    GeneralLeftFormatDoughnut: "Totales",

    Previsualization: "Previsualización",

    // Filters pannels
    SelectTheFiltersYouWant: "¡Selecciona los filtros que deseas!",
    FilterByPlaceholder: "Filtrar por...",
    FilterLabel: "Filtro",
    ApplyFilterLabel: "Aplicar filtro",
    DatePickerPlaceholder: "Seleciona...",

    // Visualization pannel
    VisualizationLeftTitle: "Configura la visualización de tu gráfica",
    VisualizationLeftDescription:
      "Elige la forma en la que quieres agrupar la información y configura los datos para el eje X de la gráfica. Al agrupar por cierto campo, elige un tipo de campo de un proceso (este se seleccionará en todos los procesos que elegiste en la etapa de filtros)",
    GroupByLabel: "Agrupar",
    ByProcessLabel: "Por proceso",
    ByFieldLabel: "Por cierto campo",
    ByNoneLabel: "Ninguno",

    YAxisLabel: "Eje Y",
    YAxisInformation:
      "El tipo de divisa fue seleccionada previamente por un usuario con permiso de configuración en Mis equipos",
    ChangeIncomeConfig: "Cambiar configuración de moneda",

    XAxisLabel: "Eje X",
    ByYearLabel: "Por año",
    ByMonthLabel: "Por mes",
    ByWeekLabel: "Por semana",
    ByDayLabel: "Por día",
    RemoveSelectionLabel: "Quitar selección",

    AddGraphLabel: "Agregar gráfica",
    UpdateGraphLabel: "Actualizar gráfica",
  },
};
