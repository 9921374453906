import React from "react";
import { Space } from "antd";
import _ from "lodash";
import { GeneralPageProps, StartField } from "./SubfluxEditor.d";
import {
  getSelectedFieldValue,
  possibleOriginsToSelectOption,
} from "./helpers";
import { StyledLabel, StyledTitle } from "../TaskEditor/styles";
import GeestSelect from "../../../../../GeestUI/GeestSelect";

const GeneralPage: React.FC<GeneralPageProps> = ({
  subflux,
  startFieldsOptions,
  onChangeAndSave,
}) => {
  const handleOnChangeStartFields = (StartFields: StartField[]): void =>
    onChangeAndSave({
      ...subflux,
      StartFields,
    });

  const onClearStartFieldValue = (IdFieldDestiny: string): void =>
    handleOnChangeStartFields(
      _.reject(subflux.StartFields, { IdFieldDestiny })
    );

  const handleOnChangeStartField = (
    IdFieldOrigin: string,
    IdFieldDestiny: string
  ) => {
    if (IdFieldOrigin === "") return onClearStartFieldValue(IdFieldDestiny);

    let newStartFormValues: StartField[] = subflux.StartFields;

    const PossibleOrigins = _.filter(startFieldsOptions, {
      IdFieldDestiny,
    })[0].PossibleOrigins;
    const FieldCausesIteration = _.filter(PossibleOrigins, {
      IdFieldOrigin,
    })[0].FieldCausesIteration;

    const existentValue = _.findIndex(newStartFormValues, { IdFieldDestiny });
    if (existentValue >= 0) {
      newStartFormValues[existentValue].IdFieldOrigin = IdFieldOrigin;
      newStartFormValues[existentValue].FieldCausesIteration =
        FieldCausesIteration;
    } else {
      newStartFormValues.push({
        IdFieldDestiny,
        IdFieldOrigin,
        FieldCausesIteration,
      });
    }

    handleOnChangeStartFields([...newStartFormValues]);
  };

  return (
    <Space direction="vertical" size={[0, 20]} style={{ width: "100%" }}>
      <StyledTitle>{subflux.NodeTitle}</StyledTitle>
      <StyledLabel>
        Selecciona los campos que quieres que se conserven al disparar el
        subflujo
      </StyledLabel>
      {startFieldsOptions.map((startField) => (
        <div key={startField.IdFieldDestiny}>
          <StyledTitle>{startField.Label}</StyledTitle>
          <GeestSelect
            value={getSelectedFieldValue(startField.IdFieldDestiny, subflux)}
            onChange={(idFieldOrigin) =>
              handleOnChangeStartField(idFieldOrigin, startField.IdFieldDestiny)
            }
            options={possibleOriginsToSelectOption(startField.PossibleOrigins)}
            placeholderSelect="Selecciona una opción..."
          />
        </div>
      ))}
    </Space>
  );
};

export default GeneralPage;
