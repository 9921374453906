import React from "react";
import { IconContainer } from "./styles";
import { AiFillCopy } from "react-icons/ai";

const CopyIcon: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => {
  return (
    <IconContainer size={20} {...props}>
      <AiFillCopy size={16} color="rgba(2, 115, 233, 1)" />
    </IconContainer>
  );
};

export default CopyIcon;
