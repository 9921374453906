import React from "react";
import DynamicInput from "../../../../../../../components/DynamicInput";
import { FieldConfigWrapper, SubTitle } from "../../Styles";
import { DataOrigins } from "../../EditField.d";

interface UserSelectConfigProps {
  value: string;
  onChange: (newValue: string) => void;
  dataOrigins: DataOrigins;
  disabled: boolean;
}

const UserSelectConfig: React.FC<UserSelectConfigProps> = ({
  value,
  onChange,
  dataOrigins,
  disabled,
}) => {
  return (
    <>
      <FieldConfigWrapper>
        <SubTitle>Valor por defecto</SubTitle>
        <DynamicInput
          value={value ?? ""}
          type="users_select"
          dataOrigin={[]}
          userSelectDataOrigin={dataOrigins.users_select ?? []}
          format=""
          fieldName=""
          configuration=""
          isConsult={false}
          disabled={disabled}
          required={false}
          onChange={onChange}
        />
      </FieldConfigWrapper>
    </>
  );
};

export default UserSelectConfig;
