import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { Row } from "antd";

export const SecondaryModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 12px;
`;

export const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 0;
  max-height: 90vh;
  width: 380px;
  border-radius: 6px !important;
`;

export const ModalTitle = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 12px;
  p {
    margin: 0;
    font-family: "Gotham-Bold";
    font-size: 16px;
    color: #48505e;
  }
`;

export const FormRow = styled(Row)`
  flex: 1;
  padding: 8px 12px;
  margin: 0 12px;
  border-radius: 6px;
  overflow-y: auto;
  background: var(--super-light-gray);
`;
