import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "@material-ui/core";
import { Row } from "antd";
import { useReactFlow } from "reactflow";
import _ from "lodash";
import {
  StyledPaper,
  LeftColumn,
  LeftTitle,
  StyledInput,
  ActionsContainer,
  ActionBox,
  ActionTitle,
  RightColumn,
} from "./styles";
import { useFetch, useMutation } from "../../../../../hooks";
import {
  GetAutomationDetailsAndConfigParamsResponse,
  Automation,
  AutomationResponse,
} from "./AutomationConfig.d";
import { Action } from "./EditAction.d";
import { EditIcon, DeleteIcon } from "../../../../../components/hoverIcons";
import { Button } from "../../../../../GeestUI";
import { AiOutlinePlus } from "react-icons/ai";
import copyActionIcon from "../../../../../resources/img/copyActionIcon.svg";
import sumActionIcon from "../../../../../resources/img/sumActionIcon.svg";
import substractActionIcon from "../../../../../resources/img/substractActionIcon.svg";
import multiplyActionIcon from "../../../../../resources/img/multiplyActionIcon.svg";
import emailActionIcon from "../../../../../resources/img/emailActionIcon.svg";
import notificationActionIcon from "../../../../../resources/img/notificationActionIcon.svg";
import changeFieldValueActionIcon from "../../../../../resources/img/changeFieldValueActionIcon.svg";
import changeTasksPriorityActionIcon from "../../../../../resources/img/changeTasksPriorityActionIcon.svg";
import webHookActionIcon from "../../../../../resources/img/webHookTriggerIcon.svg";
import GeestLogo from "../../../../../resources/img/ICONO_.svg";
import ConfirmDeleteAction from "./ConfirmDeleteAction";
import ActionInformation from "./ActionInformation";
import UnsavedModal from "./UnsavedModal";
import { NodeEditorProps } from "../NodeEditor.d";
import { updateNodeFromAutomation } from "../../helpers";
import { ListManager } from "react-beautiful-dnd-grid";
import divActionIcon from "../../../../../resources/img/divActionIcon.svg";

const AutomationModal: React.FC<NodeEditorProps> = ({ node, onClose }) => {
  const { getNodes, setNodes } = useReactFlow();
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const { id: IdNode } = node;

  const [automation, setAutomation] = useState<Automation | null>(null);
  const [scheduleStart, setScheduleStart] = useState(false);
  const [actions, setActions] = useState<Action[]>([]);
  const [selectedAction, setSelectedAction] = useState<Action | null>(null);
  const [editingTitle, setEditingTitle] = useState<boolean>(false);
  const [deletingAction, setDeletingAction] = useState<number | null>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [closingModal, setClosingModal] = useState<boolean>(false);
  const [nextSelectedAction, setNextSelectedAction] = useState<Action | null>(
    null
  );

  const { data, reload } =
    useFetch<GetAutomationDetailsAndConfigParamsResponse>({
      func: "Ver2-Configurator-gadcp",
      args: {
        IdTeam,
        IdProcessTemplate,
        IdNode,
      },
      onSuccess: ({ Automation }) => {
        setScheduleStart(
          !_.isEmpty(
            Automation.RefDate || Automation.Preconf || Automation.TimeToAdd
          )
        );
        setActions(Automation.Actions);
        setAutomation(Automation);
      },
    });

  const [updateAutomationDetails] = useMutation<AutomationResponse>({
    func: "Ver2-Configurator-uad",
    onSuccess: ({ NodeTitle }) =>
      setNodes(updateNodeFromAutomation(getNodes(), node.id, NodeTitle)),
  });

  const [registerAutomationAction, savingAction] = useMutation<number>({
    func: "Ver2-Configurator-raa",
    onSuccess: (IdAction, { action }) => {
      if (action) {
        setSelectedAction({
          ...action,
          IdAction,
        });
      }
      reload();
    },
    onError: ({ action }) => setSelectedAction(action),
  });

  const saveAction = (action: Action) => {
    if (savingAction) return;
    registerAutomationAction({
      args: {
        IdTeam,
        IdProcessTemplate,
        IdNode,
        ...action,
      },
      shippedData: { action },
      errorArgs: { action },
    });
  };

  const [deleteAutomationAction] = useMutation<Action[]>({
    func: "Ver2-Configurator-daa",
    onSuccess: setActions,
  });

  const [reorderAutomationActions] = useMutation({
    func: "Ver2-Configurator-roaa",
    onError: reload,
  });

  const handleOnDeleteAction = (): void => {
    if (selectedAction?.IdAction === deletingAction) {
      setSelectedAction(null);
    }
    if (deletingAction === 0) {
      let actionsAux = [...actions];
      actionsAux.splice(actions.length - 1);
      setActions(actionsAux);
    } else {
      deleteAutomationAction({
        args: {
          IdTeam,
          IdProcessTemplate,
          IdNode,
          IdAction: deletingAction,
        },
      });
    }
    setDeletingAction(null);
  };

  const saveAutomation = (automation: Automation) => {
    const isTimeToAddCompleted =
      scheduleStart &&
      !_.isEmpty(automation.TimeToAdd) &&
      !_.isEmpty(automation.Preconf) &&
      !_.isEmpty(automation.RefDate);
    const isTimeToAddEmpty = _.isEmpty(
      automation.TimeToAdd || automation.Preconf || automation.RefDate
    );
    if (!isTimeToAddCompleted && !isTimeToAddEmpty) return;
    updateAutomationDetails({
      args: {
        IdTeam,
        IdProcessTemplate,
        IdNode,
        NodeTitle: automation.NodeTitle,
        Automation: {
          ...automation,
          TimeToAdd: Number(automation.TimeToAdd),
        },
      },
    });
    setEditingTitle(false);
  };

  const setNodeTitle = (NodeTitle: string): void =>
    setAutomation({
      ...(automation as Automation),
      NodeTitle,
    });

  const actionIcons: { [key: string]: any } = {
    copy_value: copyActionIcon,
    substract_fields: substractActionIcon,
    sum_fields: sumActionIcon,
    mult_fields: multiplyActionIcon,
    send_email: emailActionIcon,
    change_field_value: changeFieldValueActionIcon,
    change_tasks_priority: changeTasksPriorityActionIcon,
    send_notification: notificationActionIcon,
    webhook: webHookActionIcon,
    div_fields: divActionIcon,
  };

  const validateAutomationToExit = (
    nextSelectedAction: Action | null,
    closingModal?: boolean
  ) => {
    switch (selectedAction?.Action) {
      case "copy_value":
        if (
          (selectedAction.IdField1 ||
            (selectedAction.IdField1 === 0 && selectedAction.IdField2 !== 0)) &&
          (selectedAction.IdField2 ||
            (selectedAction.IdField2 === 0 && selectedAction.IdField1 !== 0))
        ) {
          setSelectedAction(nextSelectedAction);
          if (closingModal) {
            onClose();
          }
        } else {
          setModalVisible(true);
        }
        break;
      case "substract_fields":
      case "sum_fields":
      case "mult_fields":
        if (
          selectedAction.IdField1 &&
          selectedAction.IdField2 &&
          selectedAction.IdFieldToSave
        ) {
          setSelectedAction(nextSelectedAction);
          if (closingModal) {
            onClose();
          }
        } else {
          setModalVisible(true);
        }
        break;
      case "send_email":
        if (
          selectedAction.message &&
          selectedAction.addresses &&
          selectedAction.addresses.length > 0 &&
          selectedAction.subject
        ) {
          setSelectedAction(nextSelectedAction);
          if (closingModal) {
            onClose();
          }
        } else {
          setModalVisible(true);
        }
        break;
      case "change_field_value":
        if (
          (selectedAction.IdField1 || selectedAction.IdField1 === 0) &&
          selectedAction.SubAction
        ) {
          setSelectedAction(nextSelectedAction);
          if (closingModal) {
            onClose();
          }
        } else {
          if (
            selectedAction.SubAction === "clear_vardb_group_value" &&
            !!selectedAction.Value
          ) {
            setSelectedAction(nextSelectedAction);
            if (closingModal) {
              onClose();
            }
          } else {
            setModalVisible(true);
          }
        }
        break;
      case "change_tasks_priority":
        setSelectedAction(nextSelectedAction);
        if (closingModal) {
          onClose();
        }
        break;
      case "send_notification":
        if (
          selectedAction.NotificationTitle &&
          selectedAction.Message &&
          selectedAction.Destination
        ) {
          setSelectedAction(nextSelectedAction);
          if (closingModal) {
            onClose();
          }
        } else {
          setModalVisible(true);
        }
        break;
      case "webhook":
        if (selectedAction.Url) {
          setSelectedAction(nextSelectedAction);
          if (closingModal) {
            onClose();
          }
        } else {
          setModalVisible(true);
        }
        break;
      default:
        setSelectedAction(nextSelectedAction);
        if (closingModal) {
          onClose();
        }
    }
  };

  const handleOnCloseModal = () => {
    onClose();
  };

  const onCloseModal = () => {
    setClosingModal(true);
    validateAutomationToExit(selectedAction, true);
  };

  const onDragEnd = (from: number, to: number) => {
    let actionsAux = [...actions];
    if (actionsAux[from].IdAction === 0) return;
    if (actionsAux[to].IdAction === 0) {
      to = to - 1;
    }
    actionsAux.splice(to, 0, actionsAux.splice(from, 1)[0]);
    setActions(actionsAux);
    reorderAutomationActions({
      args: {
        Actions: actionsAux.filter(({ IdAction }) => IdAction !== 0),
        IdTeam,
        IdProcessTemplate,
      },
    });
  };

  return (
    <Modal open style={{ opacity: 1, zIndex: 2 }}>
      <Row
        justify="center"
        align="middle"
        style={{ width: "100%", height: "100%" }}
        onClick={onCloseModal}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <UnsavedModal
            onCancel={() => {
              setModalVisible(false);
              setClosingModal(false);
            }}
            onConfirm={() => {
              setModalVisible(false);
              if (closingModal) {
                handleOnCloseModal();
              } else {
                setSelectedAction(nextSelectedAction);
              }
            }}
            visible={modalVisible}
          />
          <ConfirmDeleteAction
            open={!!deletingAction || deletingAction === 0}
            onClose={() => setDeletingAction(null)}
            onConfirm={handleOnDeleteAction}
          />
          <LeftColumn>
            <Row
              align="middle"
              style={{
                marginLeft: "10px",
                marginBottom: "2rem",
                marginTop: "7px",
              }}
            >
              {editingTitle ? (
                <StyledInput
                  value={automation?.NodeTitle}
                  onChange={(e) => setNodeTitle(e.target.value)}
                  onBlur={() => saveAutomation(automation as Automation)}
                  onFocus={(e) => e.currentTarget.select()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      saveAutomation(automation as Automation);
                    }
                  }}
                  autoFocus
                />
              ) : (
                <>
                  <LeftTitle>{automation?.NodeTitle}</LeftTitle>
                  <EditIcon filled onClick={() => setEditingTitle(true)} />
                </>
              )}
            </Row>
            <ActionsContainer>
              <Button
                type="primary"
                size="fullwidth"
                Icon={AiOutlinePlus}
                style={{ marginBottom: "1rem" }}
                onClick={() => {
                  validateAutomationToExit(null);
                  setNextSelectedAction(null);
                }}
                disabled={
                  !selectedAction ||
                  !!actions?.find(({ IdAction }) => IdAction === 0)
                }
              >
                Agregar nueva acción
              </Button>
              <ListManager
                items={actions}
                maxItems={2 ^ 30}
                direction="vertical"
                onDragEnd={onDragEnd}
                render={(action) => (
                  <ActionBox
                    $isSelected={action.IdAction === selectedAction?.IdAction}
                    onClick={() => {
                      if (selectedAction?.IdAction !== action.IdAction) {
                        validateAutomationToExit(action);
                        setNextSelectedAction(action);
                      }
                    }}
                  >
                    <Row
                      align="middle"
                      justify="space-between"
                      style={{ width: "100%" }}
                    >
                      <Row align="middle">
                        <img
                          src={actionIcons[action?.Action] || GeestLogo}
                          width="20px"
                          height="20px"
                          alt=""
                        />
                        <ActionTitle>{action?.Title}</ActionTitle>
                      </Row>
                      <DeleteIcon
                        filled
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeletingAction(action.IdAction);
                        }}
                      />
                    </Row>
                  </ActionBox>
                )}
              />
            </ActionsContainer>
          </LeftColumn>
          <RightColumn>
            <ActionInformation
              selectedAction={selectedAction}
              reloadHome={reload}
              setSelectedAction={setSelectedAction}
              closeModal={onCloseModal}
              actionOptions={data?.ActionsOptions}
              actions={actions}
              setActions={setActions}
              IdNode={_.toNumber(IdNode)}
              saveAction={saveAction}
            />
          </RightColumn>
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default AutomationModal;
