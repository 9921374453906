import React from "react";
import styled from "styled-components";
import { ReactComponent as MoveIconFilled } from "../../resources/img/moveIconFilled.svg";

const IconWrapper = styled.div<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  transition: background 0.35s ease;
  :hover {
    background: #d9d9d9;
  }
`;

interface MoveIconProps {
  size?: 20 | 26;
  $width?: string;
  $height?: string;
  noContainer?: boolean;
  onClick?: () => void;
}

const MoveIcon: React.FC<MoveIconProps> = ({
  size = 20,
  $width = "14px",
  $height = "14px",
  noContainer = false,
  onClick,
}) => {
  if (noContainer) {
    return <MoveIconFilled onClick={onClick} width={$width} height={$height} />;
  }

  return (
    <IconWrapper size={size} onClick={onClick}>
      <MoveIconFilled width={$width} height={$height} />
    </IconWrapper>
  );
};

export default MoveIcon;
