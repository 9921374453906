import React, { useEffect, useState } from "react";
import { Route, useHistory, useParams } from "react-router-dom";
import _ from "lodash";
// chart
import { getDatasetAtEvent, getElementAtEvent } from "react-chartjs-2";
// grid
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
// styles and data
import { useFetch, useMutation } from "../../../hooks";
import DetailPending from "../../Pendings/DetailPending/DetailPending";
import DynamicGraph from "./DynamicGraph";
import {
  DashboardDetailInformation,
  DashboardPermissions,
  GraphRenderInfo,
  GraphType,
  ModalsType,
} from "../Dashboards.d";
import {
  Container,
  ContextMenuOptionContainer,
  GridItemWrapper,
  TableContainer,
  GraphTitle,
} from "./style";
import ContextMenu from "../../../components/ContextMenu/ContextMenu";
import { AiOutlineEdit } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { ThreePointsIcon } from "../../../components/hoverIcons";
import GraphTemplates from "../Modals/GraphTemplates";
import DeleteGraphModal from "../Modals/DeleteGraphModal";

const ResponsiveGridLayout = WidthProvider(Responsive);

const Languages = {
  ENG: {
    Edit: "Edit",
    Delete: "Delete",
  },
  ESP: {
    Edit: "Editar",
    Delete: "Eliminar",
  },
};

const { Edit, Delete } = Languages["ESP"];

interface Points {
  x: number;
  y: number;
}

interface ContextOption {
  label: React.ReactNode | string | number;
  func: Function;
  hoverBackground?: string;
}

interface DashboardGraphProps {
  selectedTeam: number | null;
  setSelectedTeam: (id: number) => void;
  setSelectedDashboard: (id: string) => void;
  setSelectedDashboardName: (name: string) => void;
  editionMode: boolean;
  setEditionMode: (enable: boolean) => void;
  setSelectedTemplate: (templateId: number) => void;
  backHome: () => void;
  modal: ModalsType;
  setModal: (modal: ModalsType) => void;
  selectedTemplate: number;
  setDashboardsModal: (modal: ModalsType) => void;
  permissions: DashboardPermissions;
  setPermissions: (val: DashboardPermissions) => void;
}

const DashboardGraph: React.FC<DashboardGraphProps> = ({
  selectedTeam,
  setSelectedTeam,
  setSelectedDashboard,
  setSelectedDashboardName,
  editionMode,
  setEditionMode,
  setSelectedTemplate,
  backHome,
  modal,
  setModal,
  setDashboardsModal,
  selectedTemplate,
  permissions,
  setPermissions,
}) => {
  const { ids } = useParams<{ ids: string }>();
  const [idTeam, idDashboard] = ids.split("-");
  const history = useHistory();
  const [layout, setLayout] = useState<any>({ lg: [] });
  const [graphs, setGraphs] = useState<GraphType[]>([]);
  const [graphForContext, setGraphForContext] = useState<GraphType | null>(
    null
  );
  const [graphToEdit, setGraphToEdit] = useState<GraphType | null>(null);
  const [points, setPoints] = useState<Points>({
    x: 0,
    y: 0,
  });
  const [contextOptions, setContextOptions] = useState<ContextOption[]>([]);

  if (!idTeam || !idDashboard) {
    backHome();
  } else {
    if ((!selectedTeam || selectedTeam !== +idTeam) && idTeam) {
      setSelectedTeam(+idTeam);
    }
    setSelectedDashboard(idDashboard);
  }

  useEffect(() => {
    const newLayout = {
      lg: layout.lg.map((item: any) => {
        return { ...item, isDraggable: editionMode, static: !editionMode };
      }),
    };

    if (!_.isEqual(layout, newLayout)) {
      setLayout(newLayout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editionMode]);

  const getPositionsForNewLayout = (idx: number) => {
    const positionX = (idx % 4) * 3;
    const positionY = Math.floor(idx / 4) * 3;
    return {
      x: positionX,
      y: positionY,
    };
  };

  const getMinSize = (type: string) => {
    switch (type) {
      case "StackBar":
        return {
          minW: 3,
          minH: 2,
        };
      case "Doughnut":
        return {
          minW: 3,
          minH: 3,
        };
      default:
        return {
          minW: 3,
          minH: 2,
        };
    }
  };

  const { reload } = useFetch<DashboardDetailInformation>({
    func: "Ver2-DashBoards-gdi",
    args: {
      IdDashBoard: idDashboard,
    },
    onSuccess: (dashboardInformation) => {
      setPermissions(dashboardInformation.Permissions);
      setGraphs([...dashboardInformation?.Graphs]);
      setSelectedDashboardName(
        dashboardInformation.DashBoardName ?? "Dasboard"
      );
      setSelectedDashboard(String(dashboardInformation.IdDashBoard));

      // has a layout
      if (dashboardInformation.Layout !== "EmptyLayout") {
        let layoutAux = JSON.parse(dashboardInformation.Layout);
        let newLayout = {
          lg: layoutAux.lg.map((item: any) => {
            const graph = _.find(dashboardInformation.Graphs, { Key: item.i });
            const { minW, minH } = getMinSize(graph?.Configuration.Type ?? "");
            return { ...item, isDraggable: false, static: true, minW, minH };
          }),
        };

        // needs to add graph;
        if (dashboardInformation.Graphs.length > newLayout.lg.length) {
          const missingGraphs = dashboardInformation.Graphs.filter(
            ({ Key }) =>
              !newLayout.lg.map(({ i }: { i: string }) => i).includes(Key)
          );
          addGraphToLayout(missingGraphs);
          return;
        }

        // needs to remove graph
        if (dashboardInformation.Graphs.length < newLayout.lg.length) {
          const extraGraphIndexes = newLayout.lg
            .filter((item: any) => {
              const existenGraph = _.find(dashboardInformation.Graphs, {
                Key: item.i,
              });
              return !existenGraph;
            })
            .map((item: any) => {
              const index = _.findIndex(layout.lg, {
                i: item.i,
              });
              return index;
            });
          // sort from last to first, so it can delete in the right order
          extraGraphIndexes.sort((a: number, b: number) => b - a);

          removeGraphsFromLayout(extraGraphIndexes);
          return;
        }

        //set edition mode
        newLayout["lg"] = newLayout["lg"].map((item: any) => {
          return { ...item, isDraggable: editionMode, static: !editionMode };
        });

        setLayout(newLayout);
        return;
      }

      // new layout
      let newLayout: any = { lg: [] };
      dashboardInformation.Graphs.forEach((graph, idx) => {
        const { x, y } = getPositionsForNewLayout(idx);
        const { minW, minH } = getMinSize(graph.Configuration.Type);
        const newElement = {
          i: graph.Key,
          moved: false,
          isDraggable: false,
          static: true,
          h: minH,
          w: minW,
          x: x,
          y: y,
          minW,
          minH,
        };
        newLayout.lg.push(newElement);
      });

      setLayout(newLayout);
    },
  });

  const [updateDashboardLayout] = useMutation({
    func: "Ver2-DashBoards-udl",
  });

  const getLayouts = () => {
    return layout;
  };

  const handleLayoutChange = (ly: any, layouts: any) => {
    setLayout(layouts);

    // no need to update if only turns of edition
    if (!editionMode) return;

    // creating new layout without edition options for backend
    let newLayout = _.cloneDeep(layouts);
    Object.keys(newLayout).forEach((key) => {
      newLayout[key] = newLayout[key].map((item: any) => {
        return { ...item, isDraggable: false, static: true };
      });
    });

    // if no change, no update
    if (_.isEqual(layouts, layout)) return;

    const layoutStringified = JSON.stringify(newLayout);
    updateDashboardLayout({
      args: {
        IdDashBoard: idDashboard,
        Layout: layoutStringified,
      },
    });
  };

  const onResize = (
    layout: any,
    oldLayoutItem: any,
    layoutItem: any,
    placeholder: any
  ) => {
    const graph = _.find([...graphs], { Key: layoutItem.i });
    const type = graph?.Configuration.Type ?? "";

    switch (type) {
      case "StackBar":
        if (layoutItem.h < 3 && layoutItem.w > 3) {
          layoutItem.h = 3;
          placeholder.h = 3;
        }
        if (layoutItem.h >= 3 && layoutItem.w < 4) {
          layoutItem.w = 4;
          placeholder.w = 4;
        }
        return;

      case "Doughnut":
        return;

      default:
        return;
    }
  };

  const removeGraphsFromLayout = (indexes: number[]) => {
    let newLayout = {
      lg: _.cloneDeep(layout.lg),
    };
    indexes.forEach((index) => {
      if (newLayout.lg) newLayout.lg.splice(index, 1);
    });
    setLayout(newLayout);
    const layoutStringified = JSON.stringify(newLayout);
    updateDashboardLayout({
      args: {
        IdDashBoard: idDashboard,
        Layout: layoutStringified,
      },
    });
  };

  const addGraphToLayout = (graphs: GraphType[]) => {
    const newLayout = _.cloneDeep(layout);

    // Calculate the maximum row number
    let maxRow = 0;
    newLayout?.lg?.forEach(({ y, h }: { y: number; h: number }) => {
      maxRow = Math.max(maxRow, y + h);
    });

    const gridMatrix = Array.from(Array(maxRow + 4), () =>
      Array.from(Array(12), () => false)
    );

    newLayout?.lg?.forEach(
      ({ x, y, h, w }: { x: number; y: number; h: number; w: number }) => {
        for (let i = y; i < y + h; i++) {
          for (let j = x; j < x + w; j++) {
            gridMatrix[i][j] = true;
          }
        }
      }
    );

    graphs.forEach((graph) => {
      const { minW, minH } = getMinSize(graph.Configuration.Type);
      let newX = 0;
      let newY = 0;

      let emptyCells = 0;
      let cellToSearchFrom = 0;

      for (let y = 0; y < gridMatrix.length; y++) {
        let needsToSearchCells = true;

        if (newY === 0) {
          for (let x = 0; x < gridMatrix[y].length; x++) {
            if (x + minW > 12) {
              // If there's not enough horizontal space, skip to the next row
              emptyCells = 0;
              cellToSearchFrom = 0;
              newX = 0;
              break;
            }

            if (x >= cellToSearchFrom && !gridMatrix[y][x]) {
              cellToSearchFrom = x;
              emptyCells++;

              if (emptyCells === minW) {
                needsToSearchCells = false;
                newY = y;
                newX = x - emptyCells + 1;
                break;
              }
            }
          }
        }

        if (!needsToSearchCells) {
          break;
        }
      }

      const newGraph = {
        i: graph.Key,
        x: newX,
        y: newY,
        w: minW,
        h: minH,
        moved: false,
        isDraggable: false,
        static: true,
      };

      newLayout.lg.push(newGraph);
    });

    setLayout(newLayout);
    const layoutStringified = JSON.stringify(newLayout);
    updateDashboardLayout({
      args: {
        IdDashBoard: idDashboard,
        Layout: layoutStringified,
      },
    });
  };

  const openTask = (id: number) => {
    history.push(`/home/dashboards/${idTeam}-${idDashboard}/${id}`);
  };

  const onCloseDetailPending = () => {
    history.push(`/home/dashboards/${idTeam}-${idDashboard}`);
  };

  const contextMenuHasOptions = () => {
    return permissions?.CanEditGraphs || permissions?.CanDeleteDashboard;
  };

  const Modal: { [key: string]: React.ReactNode } = {
    GraphTemplates: (
      <GraphTemplates
        selectedTemplate={selectedTemplate}
        selectedDashboard={idDashboard}
        graphToEdit={graphToEdit}
        onClose={() => {
          setModal("");
          setDashboardsModal("");
          setGraphToEdit(null);
          setEditionMode(false);
          reload();
        }}
      />
    ),
    EditGraph: (
      <GraphTemplates
        selectedTemplate={selectedTemplate}
        selectedDashboard={idDashboard}
        graphToEdit={graphToEdit}
        onClose={() => {
          setModal("");
          setGraphToEdit(null);
          setEditionMode(false);
          reload();
        }}
      />
    ),
    DeleteGraph: (
      <DeleteGraphModal
        graph={graphToEdit}
        idDashboard={idDashboard}
        onClose={() => {
          setModal("");
          setGraphToEdit(null);
          setEditionMode(false);
          reload();
        }}
      />
    ),
  };

  const handleContextGraph = (graph: GraphType) => {
    let newOptions: ContextOption[] = [];

    if (permissions?.CanEditGraphs) {
      newOptions = [
        ...newOptions,
        {
          label: (
            <ContextMenuOptionContainer>
              <AiOutlineEdit />
              <p>{Edit}</p>
            </ContextMenuOptionContainer>
          ),
          func: () => {
            setGraphToEdit(graph);
            setSelectedTemplate(graph.IdGraphTemplate);
            setModal("EditGraph");
            setGraphForContext(null);
          },
        },
      ];
    }

    if (permissions?.CanDeleteDashboard) {
      newOptions = [
        ...newOptions,
        {
          label: (
            <ContextMenuOptionContainer>
              <FiTrash />
              <p>{Delete}</p>
            </ContextMenuOptionContainer>
          ),
          hoverBackground: "#db232c",
          func: () => {
            setGraphToEdit(graph);
            setModal("DeleteGraph");
            setGraphForContext(null);
          },
        },
      ];
    }

    setContextOptions(newOptions);
    setGraphForContext(graph);
  };

  const onClickBar = (
    event: any,
    chartRef: any,
    renderInfo: GraphRenderInfo
  ) => {
    const { current: chart } = chartRef;
    if (editionMode) return;
    if (!chart) return;
    const dataset = getDatasetAtEvent(chart, event);
    if (!dataset.length) return;
    const datasetIndex = dataset[0].datasetIndex;
    const element = getElementAtEvent(chart, event);
    if (!element.length) return;
    const idPending =
      renderInfo.datasets[datasetIndex].metaData[element[0].index];
    if (idPending) openTask(idPending);
  };

  const onChangePending = (idPending: number) => {
    if (idPending) openTask(idPending);
  };

  const getGraphTitle = (graph: GraphType) => {
    switch (graph.Configuration.Type) {
      case "StackBar":
        return `${graph.Configuration.Title} (Detallado)`;
      case "Doughnut":
        return `${graph.Configuration.Title} (Totales)`;
      case "Table":
        return `${graph.Configuration.Title}`;
    }
  };

  return (
    <Container editionMode={editionMode}>
      <Route
        path={`/home/dashboards/${idTeam}-${idDashboard}/:idPending`}
        render={() => {
          return (
            <DetailPending
              onClose={onCloseDetailPending}
              onChangePending={(idPending) => {
                onCloseDetailPending();
                setTimeout(() => {
                  history.push(
                    `/home/dashboards/${idTeam}-${idDashboard}/${idPending}`
                  );
                }, 1);
              }}
            />
          );
        }}
      />
      {Modal[modal]}
      <ContextMenu
        isOpen={
          Boolean(graphForContext?.IdGraph) &&
          Boolean(graphs.find((g) => g.IdGraph === graphForContext?.IdGraph))
        }
        points={points}
        width={200}
        options={contextOptions}
        closeMenu={() => setGraphForContext(null)}
      >
        <ResponsiveGridLayout
          layouts={getLayouts()}
          breakpoints={{ lg: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          rowHeight={80}
          width={1000}
          onLayoutChange={handleLayoutChange}
          onResize={onResize}
        >
          {[...graphs].map((graph) => {
            return (
              <GridItemWrapper key={graph.Key}>
                <TableContainer
                  className="table-container"
                  editionMode={editionMode}
                  onContextMenu={(e) => {
                    if (contextMenuHasOptions()) {
                      e.preventDefault();
                      setPoints({ x: e.pageX, y: e.pageY });
                      handleContextGraph(graph);
                    }
                  }}
                >
                  <GraphTitle>
                    <p className="title">{getGraphTitle(graph)}</p>
                    {permissions?.CanEditGraphs && (
                      <ThreePointsIcon
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setPoints({ x: e.pageX, y: e.pageY });
                          handleContextGraph(graph);
                        }}
                      />
                    )}
                  </GraphTitle>
                  <div className="wrapper">
                    <DynamicGraph
                      graph={graph}
                      onClickBar={onClickBar}
                      onChangePending={onChangePending}
                      reload={reload}
                    />
                  </div>
                </TableContainer>
              </GridItemWrapper>
            );
          })}
        </ResponsiveGridLayout>
      </ContextMenu>
    </Container>
  );
};

export default DashboardGraph;
