import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { AiOutlinePlus } from "react-icons/ai";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import {
  Chat,
  EditChatsDropdownProps,
  GetProcessTemplateChats,
  InsertProcessTemplateChats,
} from "./EditChatsDropdown.d";
import { Card, Header, List } from "./../EditStages/styles";
import { useMutation } from "../../../../hooks";
import { Button } from "../../../../GeestUI";
import ChatItem from "./ChatItem";
import EditChatModal from "./EditChatModal";
import {
  DraggableItem,
  DroppableContainer,
} from "../../../Pendings/DetailPending/Popups/PriorityPopover";

const defaultChat: Chat = {
  IdPrepublishChat: 0,
  Label: "",
  TeamRoles: [],
};

const EditChatsDropdown: React.FC<EditChatsDropdownProps> = ({
  children,
  open,
  setOpen,
}) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const [chats, setChats] = useState<Chat[]>([]);
  const [editingChatId, setEditingChatId] = useState<number | null>(null);

  // Edition/Creation is active when `editingChatId` is any number
  const editingChat = _.isNumber(editingChatId)
    ? // if chat is not found then it is Creation mode.
      _.find(chats, { IdPrepublishChat: editingChatId }) || defaultChat
    : null;

  useEffect(() => {
    if (open) {
      getProcessTemplateChats({
        args: {
          IdProcessTemplate,
          IdTeam,
        },
      });
    } else setEditingChatId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [getProcessTemplateChats] = useMutation<GetProcessTemplateChats>({
    func: "Ver2-Configurator-gptc",
    onSuccess: setChats,
  });
  const [insertProcessChat] = useMutation<InsertProcessTemplateChats>({
    func: "Ver2-Configurator-ipc",
    onSuccess: setChats,
  });
  const [updateProcessChat] = useMutation<[]>({
    func: "Ver2-Configurator-upc",
    onSuccess: (_response, updatedChat: Chat) =>
      setChats(
        _.map(chats, (chat) =>
          chat.IdPrepublishChat === updatedChat.IdPrepublishChat
            ? updatedChat
            : chat
        )
      ),
  });
  const [deleteProcessChat] = useMutation<[]>({
    func: "Ver2-Configurator-dpc",
    onSuccess: (_response, chat: Chat) =>
      setChats(_.reject(chats, { IdPrepublishChat: chat.IdPrepublishChat })),
  });
  const [reOrderProcessChats] = useMutation<InsertProcessTemplateChats>({
    func: "Ver2-Configurator-rpc",
    onError: () =>
      getProcessTemplateChats({
        args: {
          IdProcessTemplate,
          IdTeam,
        },
      }),
  });

  const handleOnEditChat = (chat: Chat) =>
    setEditingChatId(chat.IdPrepublishChat);
  const handleOnDeleteChat = (chat: Chat) =>
    deleteProcessChat({
      args: {
        IdTeam,
        IdProcessTemplate,
        IdPrepublishChat: chat.IdPrepublishChat,
      },
      shippedData: chat,
    });
  const handleOnSubmitEdition = (chat: Chat) => {
    if (chat.IdPrepublishChat === 0) {
      // Create chat
      insertProcessChat({
        args: {
          IdTeam,
          IdProcessTemplate,
          ..._.pick(chat, ["Label", "TeamRoles"]),
        },
      });
    } else {
      // Update chat
      updateProcessChat({
        args: {
          IdTeam,
          IdProcessTemplate,
          ...chat,
        },
        shippedData: chat,
      });
    }
    setEditingChatId(null);
  };

  const handleOnReOrder = ({ source, destination }: DropResult): void => {
    if (destination) {
      const { index: sourceIndex } = source;
      const { index: destinationIndex } = destination;
      let newChats: Chat[] = [...chats];
      // get MovedItem using sourceIndex
      let [movedItem] = _.pullAt(newChats, sourceIndex);
      // insert MovedItem using destinationIndex
      newChats.splice(destinationIndex, 0, movedItem);
      // set positions
      setChats([...newChats]);
      reOrderProcessChats({
        args: {
          IdTeam,
          Chats: newChats,
          IdProcessTemplate,
        },
      });
    }
  };

  const isDefaultChat = (editingChat: Chat) => {
    if (editingChat.TeamRoles.length > 1) return false;
    if (editingChat.TeamRoles.length === 0) return false;

    return (
      editingChat.TeamRoles[0].IdTeamRole === 0 &&
      editingChat.TeamRoles[0].Label === "ALL"
    );
  };

  const canDeleteChat = (editingChat: Chat) => {
    if (editingChat.TeamRoles.length > 1) return true;
    if (editingChat.TeamRoles.length === 0) return false;

    return (
      editingChat.TeamRoles[0].IdTeamRole !== 0 &&
      editingChat.TeamRoles[0].Label !== "ALL"
    );
  };

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      trigger="click"
      placement="bottom"
      showArrow={false}
      overlayInnerStyle={{
        borderRadius: "6px",
      }}
      zIndex={0}
      align={{ offset: [0, -10] }}
      content={
        <Card>
          <Header>
            <Button
              type="secondary"
              Icon={AiOutlinePlus}
              size="large"
              onClick={() => handleOnEditChat(defaultChat)}
            >
              Nuevo canal
            </Button>
          </Header>
          <DragDropContext
            onBeforeDragStart={() => setEditingChatId(null)}
            onDragEnd={handleOnReOrder}
          >
            <List>
              <DroppableContainer>
                {chats.map((chat, idx) => (
                  <DraggableItem
                    draggableId={String(chat.IdPrepublishChat)}
                    index={idx}
                    isDragDisabled={false}
                    key={chat.IdPrepublishChat}
                  >
                    <ChatItem
                      key={chat.IdPrepublishChat}
                      chat={chat}
                      onEdit={handleOnEditChat}
                    />
                  </DraggableItem>
                ))}
              </DroppableContainer>
            </List>
          </DragDropContext>
          {editingChat && (
            <EditChatModal
              chat={editingChat}
              isDefaultChat={isDefaultChat(editingChat)}
              onSubmit={handleOnSubmitEdition}
              canDelete={canDeleteChat(editingChat)}
              onDelete={handleOnDeleteChat}
              onClose={() => setEditingChatId(null)}
            />
          )}
        </Card>
      }
    >
      {children}
    </Popover>
  );
};

export default EditChatsDropdown;
