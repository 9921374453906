import React from "react";
import {
  Android,
  IOS,
  StoresCommingFooter,
  Container,
  GeestLogoWhite,
  StyledLink,
  Icons,
  AppStore,
  GooglePlay,
  AppGallery,
} from "./styles";
import { Row, Col } from "antd";
import {
  InstagramOutlined,
  FacebookOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import { Languages } from "./Dictionary";

export const StoresComming = () => {
  return (
    <StoresCommingFooter
      data-testid="storesComming"
      type="flex"
      justify="center"
    >
      <Row justify="center" align="middle" style={{ width: "1400px" }}>
        <Col type="flex" justify="center">
          <Row type="flex" justify="center" align="bottom">
            <Android />
            <IOS />
          </Row>
        </Col>
        <Col style={{ textAlign: "center" }}>
          <div>
            <p
              style={{
                fontSize: "2rem",
                fontFamily: "Gotham-Bold",
                color: "#2344E8",
                margin: "0",
              }}
            >
              Ya en tiendas móviles.
            </p>
            <p
              style={{
                fontSize: "1rem",
                fontFamily: "Gotham-Bold",
                margin: "0",
              }}
            >
              Incrementa tu rentabilidad dando seguimiento al alcance de tu
              mano.
            </p>
          </div>
        </Col>
      </Row>
    </StoresCommingFooter>
  );
};

const Footer = ({ disabled = false }) => {
  const { PrivacyNotice, TermsAndCond, RightsReserved } = Languages["ESP"];

  const openAppGallery = () => {
    window.open("https://appgallery.huawei.com/#/app/C108609879");
  };

  const openAppStore = () => {
    window.open("https://apps.apple.com/mx/app/geest/id1672124842");
  };

  const openPlayStore = () => {
    window.open("https://play.google.com/store/apps/details?id=v2.geest.app");
  };

  return (
    <>
      <Container justify="center" data-testid="footer">
        <Row justify="space-between" style={{ width: "1400px" }}>
          <Col>
            <GeestLogoWhite />
            <Row hidden={disabled}>
              <StyledLink to="/legal/privacy">{PrivacyNotice}</StyledLink>
              <StyledLink to="/legal/terms">{TermsAndCond}</StyledLink>
            </Row>
            <div>&copy; {RightsReserved}</div>
            <Row>
              <Icons
                href="https://www.instagram.com/geestbpm/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramOutlined />
              </Icons>
              <Icons
                href="https://www.facebook.com/GeestAppBPM"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookOutlined />
              </Icons>
              <Icons
                href="https://www.youtube.com/channel/UC-ro2XFSc5gGRs_mJ-JdO_w"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeOutlined />
              </Icons>
              <Icons
                href="https://www.linkedin.com/company/geestapp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinOutlined />
              </Icons>
            </Row>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <GooglePlay
              data-testid="footer--googlePlay"
              onClick={openPlayStore}
            />
            <AppStore data-testid="footer--appStore" onClick={openAppStore} />
            <AppGallery
              data-testid="footer--appGallery"
              onClick={openAppGallery}
            />
            <div style={{ textAlign: "left" }}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:support@geest.app"
              >
                support@geest.app
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
