import { Row, Tag } from "antd";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "../../../../../GeestUI";
import { useMutation } from "../../../../../hooks";
import { TeamRole } from "../../../Teams.d";
import { RoleContainer, RoleTitle, RolesSpaceContainer } from "../Style";
import EditRole from "./EditRole";
import { MessagesContext } from "../../../../../components/AppMessages";
import { CloseIcon, EditIcon } from "../../../../../components/hoverIcons";

const { Text } = Typography;

interface RolesPopoverProps {
  idTeam: string;
  value: TeamRole[];
  teamRoles: TeamRole[];
  isLeader: boolean;
  fromTriggers?: boolean;
  onChange: (oldRoles: TeamRole[], newRoles: TeamRole[]) => void;
  onClose: () => void;
  reload: () => void;
}

const RolesPopover: React.FC<RolesPopoverProps> = ({
  idTeam,
  value,
  teamRoles,
  isLeader,
  fromTriggers = false,
  onChange,
  onClose,
  reload,
}) => {
  const history = useHistory();
  const { showMessage } = useContext(MessagesContext);
  const [editingRoleId, setEditingRoleId] = useState<number | null>(null);

  const [insertTeamRole] = useMutation<TeamRole & { IdTeam: number }>({
    func: "Ver2-Team-itr",
    onSuccess: () => {
      setEditingRoleId(null);
      reload();
    },
  });
  const [updateTeamRole] = useMutation<[]>({
    func: "Ver2-Team-utr",
    onSuccess: (_response, { updatedRole }) => {
      const role = updatedRole as TeamRole;
      const existingIndex = _.findIndex(value, { IdTeamRole: role.IdTeamRole });
      if (existingIndex >= 0) {
        let newValue = [...value];
        newValue[existingIndex] = role;
        onChange(teamRoles, newValue);
      }
      setEditingRoleId(null);
      if (!fromTriggers) {
        history.push(`/home/teams/`);
        history.push(`/home/teams/${idTeam}`);
      } else {
        reload();
      }
    },
  });
  const [deleteTeamRole] = useMutation<[]>({
    func: "Ver2-Team-dtr",
    onSuccess: (_response, { deletedRole }) => {
      setEditingRoleId(null);
      onSelectRole(deletedRole as TeamRole, true);
      if (!fromTriggers) {
        history.push(`/home/teams/`);
        history.push(`/home/teams/${idTeam}`);
      } else {
        reload();
      }
    },
  });

  const onCreateRole = (newRole: TeamRole) =>
    insertTeamRole({ args: { ...newRole, IdTeam: idTeam } });

  const onUpdateRole = (updatedRole: TeamRole): void => {
    if (updatedRole.Label === "Líder") {
      showMessage("No puede haber dos roles de lider", "error");
      return;
    }
    updateTeamRole({ args: updatedRole, shippedData: { updatedRole } });
  };

  const onDeleteRole = (deletedRole: TeamRole): void => {
    deleteTeamRole({
      args: { IdTeamRole: deletedRole.IdTeamRole },
      shippedData: { deletedRole },
    });
  };

  const onSelectRole = (role: TeamRole, isDeleting: boolean = false): void => {
    if (role.Label === "Líder" && !fromTriggers) return;

    // if choosing default role on triggers is default
    if (role.IdTeamRole === 0 && role.Label === "ALL") {
      // you can't uncheck ALL role
      if (value[0]?.IdTeamRole === 0 && value[0]?.Label === "ALL") return;
      // if you have any other roles out there, you can set ALL role removing everyother role.
      onChange(teamRoles, [role]);
    }
    // if choosing any other role besides ALL, check if all role is selected (only one possible)
    // unset it and set the new one
    else if (value[0]?.IdTeamRole === 0 && value[0]?.Label === "ALL") {
      onChange(teamRoles, [role]);
    }
    // any other case possible is just adding or removing normal roles.
    else {
      const isCurrentRoleSelected = !!_.find(value, {
        IdTeamRole: role.IdTeamRole,
      });

      // is the role isn't selected and comes with isDeleting
      // that means that, this role has been actually deleted and
      // it will reload from the deleteRole function itself.
      // so we don't need to call onChange and do another submit
      // with the new roles.
      if (!isCurrentRoleSelected && isDeleting) return;
      if (isCurrentRoleSelected) {
        // Remove role
        if (fromTriggers && value.length === 1) return;
        onChange(teamRoles, _.reject(value, { IdTeamRole: role.IdTeamRole }));
      } else {
        // Add role
        onChange(teamRoles, [...value, role]);
      }
    }
  };

  return (
    <div style={{ width: "330px" }}>
      <Row align="middle" justify="space-between">
        <RoleTitle>Roles</RoleTitle>
        <CloseIcon onClick={onClose} />
      </Row>
      {editingRoleId === -1 ? (
        <div
          style={{
            padding: "0px 10px 0px 10px",
            marginTop: "1rem",
            marginBottom: "12px",
          }}
        >
          <EditRole
            onSubmit={onCreateRole}
            onCancel={() => setEditingRoleId(null)}
            disableDelete
          />
        </div>
      ) : (
        <Row
          style={{
            width: "100%",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          justify="center"
        >
          <Button
            type="secondary"
            onClick={() => setEditingRoleId(-1)}
            Icon={AiOutlinePlus}
          >
            Nuevo rol
          </Button>
        </Row>
      )}
      <RolesSpaceContainer>
        {(teamRoles || []).map((role) => (
          <div key={role.IdTeamRole}>
            {role.IdTeamRole === editingRoleId ? (
              <EditRole
                defaultValue={role}
                onSubmit={onUpdateRole}
                onDelete={onDeleteRole}
                onCancel={() => setEditingRoleId(null)}
                teamRoles={teamRoles}
              />
            ) : (
              (isLeader || role.Label !== "Líder") && (
                <RoleContainer
                  justify="space-between"
                  $selected={!!_.find(value, { IdTeamRole: role.IdTeamRole })}
                  onClick={() => onSelectRole(role)}
                  align="middle"
                >
                  <Tag
                    color={role.Color || "#c0c9d3"}
                    style={{
                      borderRadius: "6px",
                      minWidth: "61px",
                      maxWidth: "calc(100% - 0.5rem - 30px)",
                      textAlign: "center",
                      minHeight: "20px",
                      display: "flex",
                      color: "white",
                      fontSize: "10px",
                      fontFamily: "Gotham-Book",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        whiteSpace: "normal",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {role.IdTeamRole === 0 ? "Todos" : role.Label}
                    </Text>
                  </Tag>
                  {role.Label !== "Líder" && role.Label !== "ALL" && (
                    <EditIcon
                      style={{ marginLeft: "0.5rem" }}
                      filled
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingRoleId(role.IdTeamRole);
                      }}
                    />
                  )}
                </RoleContainer>
              )
            )}
          </div>
        ))}
      </RolesSpaceContainer>
    </div>
  );
};

export default RolesPopover;
