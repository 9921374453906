import styled from "styled-components";
import { keyframes } from "styled-components";

export const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 200px;
  height: auto;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Textcontainer = styled.div`
  display: flex;
  align-items: baseline;
  width: 120px;
`;

export const RingsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const rotateRight = keyframes`
 0% {
  transform: rotate(0deg);
 }
 100% {
  transform: rotate(360deg);
 }
`;
const rotateLeft = keyframes`
 0% {
  transform: rotate(360deg);
 }
 100% {
  transform: rotate(0deg);
 }
`;

export const Ring = styled.div<{
  $rotate: string;
  $color: string;
  $delay?: string;
  $position?: string;
  $top?: string;
  $dotPlacement?: string;
}>`
  position: ${({ $position = "relative" }) => $position};
  top: ${({ $top = "" }) => $top};
  border: 1px solid transparent;
  border-top: 1px solid ${({ $color }) => $color};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: -10px;
  animation: ${({ $rotate }) =>
      $rotate === "right" ? rotateRight : rotateLeft}
    4s linear infinite;
  animation-delay: ${({ $delay = "0s" }) => $delay};

  &:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: ${({ $color }) => $color};
    top: 4px;
    ${({ $dotPlacement = "right: 4px" }) => $dotPlacement};
    border-radius: 50%;
    box-shadow: 0 0 0 2px ${({ $color }) => $color}33,
      0 0 0 4px ${({ $color }) => $color}22,
      0 0 0 8px ${({ $color }) => $color}11, 0 0 8px ${({ $color }) => $color},
      0 0 16px ${({ $color }) => $color};
  }
`;

export const ProgressBarContainer = styled.div`
  width: 257px;
  height: 33px;
  padding: 1px;
  border: 2px solid rgba(31, 147, 52, 1);
  border-radius: 6px;
`;

export const ProgressBar: any = styled.div<{ progress: number | undefined }>`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  border-radius: ${({ progress }) =>
    progress === 100 ? "4px" : "4px 0px 0px 4px"};
  background-color: rgba(2, 115, 233, 1);
`;

export const ProgressLabel = styled.div`
  font-size: 14px;
  font-family: "Gotham-Bold";
  line-height: 20px;
  text-align: center;
  color: rgba(2, 115, 233, 1);
  margin-top: -1.5rem;
`;
