import React, { useEffect } from "react";
import { noop as NOOP } from "lodash";
import styled, { css } from "styled-components";
import { Row, Input } from "antd";
import { PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons";
import {
  getPhoneByCountry,
  getCountryFlag,
  transformedOptions,
  countries,
} from "./helpers/PhoneNumberHelpers";
import { AiOutlinePhone, AiOutlineWhatsApp } from "react-icons/ai";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import IsRequired from "./helpers/IsRequired";

interface PhoneNumberInputProps {
  value: string;
  format: string;
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  onChange: (value: string) => void;
  onBlur?: (e: any) => void;
  onPressEnter?: (e: any) => void;
  onChangeFormat?: (value: string) => void;
  extraParams?: {
    showFormat?: boolean;
    formatedValueColor?: string;
    formatedValueSize?: string;
    center?: boolean;
    phonePlaceholder?: string;
    hidePhoneFormat?: boolean;
    mb?: string;
  };
}

const InputContainer = styled.div<{ isConsult: boolean; mb?: string }>`
  ${({ isConsult, mb = "10px" }) => (isConsult ? "" : `margin-bottom: ${mb};`)}
  width: 100%;
`;

const PhoneIconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const PhoneInputsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledInput = styled(Input)<{
  $borderColor: string;
  $background: string;
}>`
  border-radius: 6px;
  border: 2px solid;
  border-color: ${({ $borderColor }) => $borderColor};
  background: ${({ $background }) => $background};
  color: #828d9e;
  font-size: 12px;

  display: flex;
  flex: 1;
  min-height: 32px;
  resize: none;
  padding: 0 10px;

  box-shadow: none !important;
  transition: all 0.35s ease;
  .ant-input {
    color: #828d9e;
    background-color: transparent;
  }
  ${({ disabled, $borderColor }) =>
    !disabled
      ? css`
          :hover {
            border-color: #0273e9 !important;
            box-shadow: none !important;
          }
        `
      : `
          :hover {
            border-color: ${$borderColor} !important;
            box-shadow: none !important;
          }
      `}
  &.ant-input-affix-wrapper-focused {
    border-color: #48505e !important;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e !important;
    box-shadow: none;
  }
  :focus-visible {
    outline: none !important;
  }
`;

const Text = styled.p`
  color: #828d9e;
  font-weight: 325;
  font-size: 12px;
  margin-bottom: 0;
`;

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value = "",
  format = "",
  isConsult,
  disabled,
  required,
  onChange,
  onChangeFormat = NOOP,
  onBlur = NOOP,
  onPressEnter = NOOP,
  extraParams = {
    showFormat: false,
    center: false,
    phonePlaceholder: "",
    hidePhoneFormat: false,
    mb: "10px",
  },
}) => {
  const fullNumber = value.length >= 7 && value.length <= 10;
  const isInt = (value: string) => /^[0-9]*$/.test(value);

  useEffect(() => {
    if (!format) {
      onChangeFormat("MX");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format]);

  return (
    <InputContainer isConsult={isConsult} mb={extraParams.mb}>
      {isConsult ? (
        <Row
          justify={extraParams.center ? "center" : "start"}
          align="middle"
          style={{
            overflow: "auto",
            height: "32px",
            flexWrap: "nowrap",
          }}
        >
          {extraParams.showFormat &&
            getCountryFlag(
              format,
              extraParams.formatedValueColor ?? "#828d9e",
              extraParams.formatedValueSize ?? "14px"
            )}
          <Text style={{ color: extraParams.formatedValueColor ?? "#828d9e" }}>
            {value}
          </Text>
          {value && (
            <PhoneIconsContainer style={{ marginLeft: "0.5rem" }}>
              <a
                href={
                  fullNumber
                    ? `https://wa.me/${getPhoneByCountry(format)}${value}`
                    : undefined
                }
                target="_blank"
                rel="noreferrer"
                style={{
                  cursor: Boolean(fullNumber) ? "pointer" : "not-allowed",
                }}
              >
                <AiOutlineWhatsApp size={14} color="#48505e" />
              </a>
              <a
                href={
                  fullNumber
                    ? `tel:${getPhoneByCountry(format)}${value}`
                    : undefined
                }
                style={{
                  cursor: Boolean(fullNumber) ? "pointer" : "not-allowed",
                }}
              >
                <AiOutlinePhone color="#48505e" />
              </a>
            </PhoneIconsContainer>
          )}
        </Row>
      ) : (
        <PhoneInputsContainer>
          {!extraParams.hidePhoneFormat && (
            <GeestSelect
              value={format}
              options={transformedOptions}
              onChange={onChangeFormat}
              required={required}
              disabled={disabled}
              $width="fit-content"
              style={{
                minWidth: "100px",
              }}
              $listWidth="200px"
              arrowSize={8}
              valueNecesary
              placeholderSelect={extraParams.phonePlaceholder}
            />
          )}
          <StyledInput
            placeholder="Número..."
            value={value}
            onChange={({ target: { value } }) => {
              let newValue = value.replace(/[^\d]+/g, "");
              if (isInt(newValue)) {
                if (newValue.length > 10) {
                  const countriesRegEx = new RegExp(
                    `^((${countries
                      .map((c) => c.phone.replace("-", ""))
                      .join(")|(")}))`
                  );
                  newValue = newValue.replace(countriesRegEx, (matched) => {
                    const code = countries.find(
                      (c) => c.phone.replace("-", "") === matched
                    )?.code;
                    if (code) {
                      onChangeFormat(code);
                    }
                    return "";
                  });
                  if (newValue.length <= 10) {
                    onChange(newValue);
                  }
                } else {
                  onChange(newValue);
                }
              }
            }}
            onBlur={onBlur}
            onPressEnter={onPressEnter}
            disabled={disabled}
            $background={disabled ? "#edecec" : "white"}
            $borderColor={
              required
                ? fullNumber && Boolean(value)
                  ? "#edecec"
                  : "#db232c"
                : "#edecec"
            }
            suffix={
              <PhoneIconsContainer>
                <a
                  href={
                    fullNumber
                      ? `https://wa.me/${getPhoneByCountry(format)}${value}`
                      : undefined
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    cursor: !!fullNumber ? "pointer" : "not-allowed",
                    color: "inherit",
                  }}
                >
                  <WhatsAppOutlined style={{ color: "#25D366" }} />
                </a>
                <a
                  href={
                    fullNumber
                      ? `tel:${getPhoneByCountry(format)}${value}`
                      : undefined
                  }
                  style={{ cursor: !!fullNumber ? "pointer" : "not-allowed" }}
                >
                  <PhoneOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </a>
              </PhoneIconsContainer>
            }
          />
        </PhoneInputsContainer>
      )}
      {required && !isConsult && value.length < 7 && <IsRequired />}
    </InputContainer>
  );
};

export default PhoneNumberInput;
