import React, { useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import { Editor, SyntheticKeyboardEvent } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import toolbarUtils from "./toolbar";
import {
  GeestTextEditorProps,
  ToolbarControls,
  ToolbarExtraParams,
} from "./GeestTextEditor.d";
import "./styles.css";
import Spacer from "./Components/Spacer";
import EnglishLabels from "./EditorLabels/englishLabels";
import SpanishLabels from "./EditorLabels/spanishLabels";
import Image from "./Components/Controllers/Image";
import {
  customBlockRenderer,
  getContentLength,
  handleBeforeInput,
  handleKeyCommand,
  handlePastedText,
  handleReturn,
  keyBindingFn,
  removeBackslash,
} from "./Utils/Editor";
import Field from "./Components/Controllers/Field";
import getFieldDecorator from "./Decorators/FieldDecorator";
import { EditorState, SelectionState } from "draft-js";
import { MessagesContext } from "../AppMessages";
import { languages } from "./Dictionary";

const defaultToolbarConfig: ToolbarControls = {
  inline: true,
  fontSize: true,
  fontFamily: true,
  list: true,
  textalign: true,
  colorPicker: true,
  link: true,
};

const defaultToolbarExtraParams: ToolbarExtraParams = {
  inlineOptions: ["bold", "italic", "underline"],
  blockTypeOptions: ["Normal", "H1", "H2", "H3", "Blockquote", "Code"],
  fontSizeOptions: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30],
  listOptions: ["unordered", "ordered", "indent", "outdent"],
  linkOptions: { defaultTarget: "_blank", options: ["link"] },
};

const GeestTextEditor: React.FC<GeestTextEditorProps> = ({
  editorState,
  onEditorStateChange,
  placeholder = "",
  editorBorder = false,
  smallInnerPadding = false,
  transparentBorder = false,
  PermanentFocusBorder = false,
  toolbarConfig,
  toolbarExtraParams,
  fieldEditorOrigin = [],
  fieldsPopoverStyles = {},
  onBlur,
  onFocus,
  required = false,
  readOnly = false,
  autoFocus = false,
  popUpToolbar = false,
  popupPosition = "top",
  dynamicHeight = false,
  maxLength = null,
  oneBlock = false,
  userSelectOnlyOnFocus = false,
}) => {
  const { CharacterLimitReached } = languages["ESP"];
  const { showMessage } = useContext(MessagesContext);
  const [hasOpenedField, setHasOpenedField] = useState<boolean>(false);
  const [editorFocus, setEditorFocus] = useState<boolean>(false);
  const [fieldPopoverOpen, setFieldPopoverOpen] = useState<boolean>(false);
  const [charCount, setCharCount] = useState<number>(0);

  // reset fields that now didn't exists on field origin
  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    contentState.getBlockMap().forEach((block: any) => {
      block.findEntityRanges((character: any) => {
        const entityKey = character.getEntity();
        if (!entityKey) return;
        // could be `null`
        const contentEntity = contentState.getEntity(entityKey);
        if (contentEntity === null) return;

        const entity = contentEntity.getData();
        // if an entity field has a selected value
        if (
          contentEntity.getType() === "FIELD" &&
          entity.IdFieldSelected !== null
        ) {
          // check if it still exists in fields origin
          const id = entity.IdFieldSelected;
          const fieldOriginIndex = _.findIndex(fieldEditorOrigin, {
            IdField: id,
          });

          // if no, just reset it
          // don't delete it, it still existing, just needs a current value
          if (fieldOriginIndex === -1) {
            contentState.mergeEntityData(entityKey, {
              IdFieldSelected: null,
              DataType: "",
              FieldLabel: "",
            });
          }
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cleanNewEditorState = removeBackslash(editorState);
    const currentContentLength = getContentLength(cleanNewEditorState);
    setCharCount(currentContentLength);
  }, [editorState]);

  const getCustomDecorators = () => {
    let decorators = [];
    decorators.push(
      getFieldDecorator(
        fieldEditorOrigin,
        setFieldPopoverOpen,
        readOnly,
        fieldsPopoverStyles
      )
    );
    return decorators;
  };

  const editorReference = useRef<Editor | null>(null);
  useEffect(() => {
    if (editorReference?.current?.focusEditor && autoFocus) {
      editorReference.current.focusEditor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorReference]);

  const Language = "ESP";
  const getLocalization = () => {
    switch (Language) {
      // @ts-ignore
      case "ENG":
        return { locale: "en", translations: EnglishLabels };
      case "ESP":
        return { locale: "es", translations: SpanishLabels };
      default:
        return { locale: "es", translations: SpanishLabels };
    }
  };

  const customToolbar = () => {
    return toolbarUtils.getToolbar(
      {
        ...defaultToolbarConfig,
        ...toolbarConfig,
      },
      { ...defaultToolbarExtraParams, ...toolbarExtraParams }
    );
  };

  const customToolbarExtraButtons = () => {
    const config = {
      ...defaultToolbarConfig,
      ...toolbarConfig,
    };

    const customButtons: any[] = [];

    if (config.spacer) {
      customButtons.push(<Spacer />);
    }
    if (config.image) {
      // @ts-ignore
      customButtons.push(<Image />);
    }

    // TODO: Sign
    // TODO: PDF

    if (config.field) {
      customButtons.push(
        // @ts-ignore
        <Field largeButton language={Language} />
      );
    }

    return customButtons;
  };

  const handleOnChange = (newEditorState: EditorState) => {
    const cleanNewEditorState = removeBackslash(newEditorState);
    onEditorStateChange(cleanNewEditorState);
  };

  useEffect(() => {
    setHasOpenedField(true);
    if (!fieldPopoverOpen && hasOpenedField) {
      const content = editorState.getCurrentContent();
      const blockMap = content.getBlockMap();

      const key = blockMap.last().getKey();
      const length = blockMap.last().getLength();

      const selection = new SelectionState({
        anchorKey: key,
        anchorOffset: length,
        focusKey: key,
        focusOffset: length,
      });
      const newEditorState = EditorState.forceSelection(editorState, selection);

      onEditorStateChange(newEditorState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldPopoverOpen]);

  const hanleOnBlur = () => {
    if (readOnly || fieldPopoverOpen) {
      setEditorFocus(false);
      return;
    }
    setEditorFocus(false);
    if (onBlur && !fieldPopoverOpen) onBlur();
  };

  const handleOnFocus = () => {
    if (readOnly || fieldPopoverOpen) {
      setEditorFocus(false);
      return;
    }
    setEditorFocus(true);
    if (onFocus) onFocus();
  };

  const limitReachedMessage = () => {
    showMessage(CharacterLimitReached, "info");
  };

  return (
    <div className={classNames("geestEditorWrapper", { dynamicHeight })}>
      <Editor
        editorState={editorState}
        wrapperClassName={classNames(
          "geestWrapper",
          { dynamicHeight },
          { userSelectDisabled: userSelectOnlyOnFocus && !editorFocus }
        )}
        toolbarClassName={classNames("geestToolbar", {
          popUpToolbar: popUpToolbar && (editorFocus || fieldPopoverOpen),
          popUpToolbarTop:
            popUpToolbar &&
            (editorFocus || fieldPopoverOpen) &&
            popupPosition === "top",
          popUpToolbarBottom:
            popUpToolbar &&
            (editorFocus || fieldPopoverOpen) &&
            popupPosition === "bottom",
        })}
        editorClassName={classNames(
          "geestEditor",
          { editorBorder },
          { smallInnerPadding },
          { transparentBorder },
          { requiredError: editorBorder && required && charCount === 0 },
          { canHover: editorBorder && !readOnly },
          { editorFocus: (editorFocus || fieldPopoverOpen) && !readOnly },
          {
            PermanentFocusBorder:
              PermanentFocusBorder &&
              !((editorFocus || fieldPopoverOpen) && !readOnly),
          }
        )}
        ref={editorReference}
        onEditorStateChange={handleOnChange}
        placeholder={placeholder}
        toolbar={customToolbar()}
        toolbarCustomButtons={customToolbarExtraButtons()}
        localization={getLocalization()}
        onBlur={hanleOnBlur}
        onFocus={handleOnFocus}
        readOnly={readOnly || fieldPopoverOpen}
        toolbarHidden={
          readOnly || (popUpToolbar && !editorFocus && !fieldPopoverOpen)
        }
        customBlockRenderFunc={customBlockRenderer}
        customDecorators={getCustomDecorators()}
        // @ts-ignore
        handleBeforeInput={() =>
          handleBeforeInput(editorState, maxLength, limitReachedMessage)
        }
        // @ts-ignore
        handlePastedText={(
          text: string,
          html: string,
          editorState: EditorState,
          onChange: (editorState: EditorState) => void
        ) =>
          handlePastedText(
            text,
            html,
            editorState,
            onChange,
            maxLength,
            limitReachedMessage
          )
        }
        handleReturn={handleReturn}
        keyBindingFn={(e: SyntheticKeyboardEvent) => keyBindingFn(e, oneBlock)}
        handleKeyCommand={(command: string) =>
          handleKeyCommand(
            command,
            editorState,
            handleOnChange,
            oneBlock,
            toolbarConfig?.field ?? false
          )
        }
      />

      {maxLength !== null && (
        <div className="countWrapper">{charCount + "/" + maxLength}</div>
      )}
    </div>
  );
};

export default GeestTextEditor;
