import React, { useState } from "react";
import _ from "lodash";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Tag } from "../../../../GeestUI";
import { AiOutlineInfoCircle, AiOutlinePlus } from "react-icons/ai";
import {
  EditPriorityContainer,
  NewPriorityContainer,
  PopoverTitle,
  PrioritiesContainer,
  PriorityItemContainer,
  PriorityPopoverContainer,
  PriorityRow,
} from "./styles";
import { useFetch, useMutation } from "../../../../hooks";
import { TaskPriority } from "../DetailPending.d";
import { Priority } from "./PriorityPopover.d";
import EditPriority from "../MicroComponents/EditPriority";
import Tooltip from "../../../../components/Tooltip";
import Button from "../../../../GeestUI/Button";
import { EditIcon, CloseIcon } from "../../../../components/hoverIcons";

export const DroppableContainer: React.FC = ({ children }) => (
  <Droppable droppableId="1">
    {(provided) => (
      <div ref={provided.innerRef} {...provided.droppableProps}>
        {children}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

export const DraggableItem: React.FC<{
  draggableId: string;
  index: number;
  isDragDisabled?: boolean;
}> = ({ children, draggableId, index, isDragDisabled }) => (
  <Draggable
    draggableId={draggableId}
    isDragDisabled={isDragDisabled}
    index={index}
  >
    {(provided) => (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        {children}
      </div>
    )}
  </Draggable>
);

const PriorityPopover: React.FC<{
  setVisible: (arg: boolean) => void;
  taskPriority?: TaskPriority;
  onChange: (priority: TaskPriority) => void;
  idPending: string;
  idTeam: number;
}> = ({ setVisible, taskPriority, onChange, idPending, idTeam }) => {
  const [priorities, setPriorities] = useState<Priority[]>([]);
  const [editingPriorityId, setEditingPriorityId] = useState<number | null>(
    null
  );

  const { reload: reloadPriorities } = useFetch<Priority[]>({
    func: "Ver2-MyPendings-gtp",
    args: {
      IdTaskValue: idPending,
    },
    onSuccess: setPriorities,
  });

  const [changeTaskPriority] = useMutation<[]>({
    func: "Ver2-MyPendings-ctp",
    onSuccess: (_response, { newPriority }) => {
      onChange(newPriority);
    },
  });
  const [insertTaskPriority] = useMutation<[]>({
    func: "Ver2-MyPendings-itp",
    onSuccess: () => {
      reloadPriorities();
      setEditingPriorityId(null);
    },
  });
  const [updateTaskPriority] = useMutation<[]>({
    func: "Ver2-MyPendings-utp",
    onSuccess: (_response, { newPriority }) => {
      if (newPriority?.IdTaskPriority === taskPriority?.IdTaskPriority) {
        onChange(newPriority);
      }
      reloadPriorities();
      setEditingPriorityId(null);
    },
    onError: () => reloadPriorities(),
  });
  const [deleteTaskPriority] = useMutation<[]>({
    func: "Ver2-MyPendings-dtp",
    onSuccess: () => {
      reloadPriorities();
      setEditingPriorityId(null);
    },
  });

  const handleOnSelectPriority = (newPriority: Priority): void => {
    const removePriority =
      newPriority.IdTaskPriority === taskPriority?.IdTaskPriority;
    changeTaskPriority({
      args: {
        IdTaskValue: idPending,
        IdTaskPriority: removePriority ? "" : newPriority.IdTaskPriority,
      },
      shippedData: { newPriority: removePriority ? undefined : newPriority },
    });
  };

  const handleOnCreatePriority = ({ Title, Color }: Priority): void =>
    insertTaskPriority({
      args: { IdTeam: idTeam, Title, Color },
    });
  const handleOnUpdatePriority = (TaskPriority: Priority): void =>
    updateTaskPriority({
      args: { IdTeam: idTeam, TaskPriority },
      shippedData: { newPriority: TaskPriority },
    });
  const handleOnDeletePriority = ({ IdTaskPriority }: Priority): void =>
    deleteTaskPriority({ args: { IdTeam: idTeam, IdTaskPriority } });
  const handleOnReOrder = ({ source, destination }: DropResult): void => {
    if (destination) {
      const { index: sourceIndex } = source;
      const { index: destinationIndex } = destination;
      let newPriorities = [...priorities];
      // get MovedItem using sourceIndex
      let [movedItem] = _.pullAt(newPriorities, sourceIndex);
      // insert MovedItem using destinationIndex
      newPriorities.splice(destinationIndex, 0, movedItem);
      // reset positions
      newPriorities = newPriorities.map((priority, index) => ({
        ...priority,
        Position: index + 1,
      }));
      // set positions
      setPriorities([...newPriorities]);
      handleOnUpdatePriority(newPriorities[destinationIndex]);
    }
  };

  return (
    <PriorityPopoverContainer
      loading={priorities.length === 0}
      onClick={(e) => e.stopPropagation()}
    >
      <PopoverTitle>
        <div className="title">
          <p>Prioridad de la tarea</p>
          <Tooltip
            title="La prioridad que aprezca hasta arriba será considerada
como la más alta y así sucesivamente"
            mui
          >
            <div className="icon-box">
              <AiOutlineInfoCircle color="#48505e" size={15} />
            </div>
          </Tooltip>
        </div>
        <CloseIcon size={20} onClick={() => setVisible(false)} />
      </PopoverTitle>

      <EditPriorityContainer>
        {editingPriorityId === 0 ? (
          <NewPriorityContainer>
            <EditPriority
              disableDelete
              onSubmit={handleOnCreatePriority}
              onCancel={() => setEditingPriorityId(null)}
            />
          </NewPriorityContainer>
        ) : (
          <Button
            type="secondary"
            size="large"
            onClick={() => setEditingPriorityId(0)}
            Icon={AiOutlinePlus}
          >
            NuevaPrioridad
          </Button>
        )}
      </EditPriorityContainer>

      <PrioritiesContainer>
        <DragDropContext
          onBeforeDragStart={() => setEditingPriorityId(null)}
          onDragEnd={handleOnReOrder}
        >
          <DroppableContainer>
            {priorities.map((priority, index) => (
              <DraggableItem
                draggableId={String(priority.IdTaskPriority)}
                index={index}
                key={priority.IdTaskPriority}
              >
                {editingPriorityId === priority.IdTaskPriority ? (
                  <PriorityRow>
                    <EditPriority
                      defaultValue={priority}
                      disableDelete={
                        priority.IdTaskPriority === taskPriority?.IdTaskPriority
                      }
                      onSubmit={handleOnUpdatePriority}
                      onDelete={handleOnDeletePriority}
                      onCancel={() => setEditingPriorityId(null)}
                    />
                  </PriorityRow>
                ) : (
                  <PriorityRow>
                    <PriorityItemContainer
                      align="middle"
                      $selected={
                        priority.IdTaskPriority === taskPriority?.IdTaskPriority
                      }
                      onClick={() => handleOnSelectPriority(priority)}
                    >
                      <Tag color={priority.Color}>{priority.Title}</Tag>
                    </PriorityItemContainer>

                    <EditIcon
                      onClick={() =>
                        setEditingPriorityId(priority.IdTaskPriority)
                      }
                      filled
                    />
                  </PriorityRow>
                )}
              </DraggableItem>
            ))}
          </DroppableContainer>
        </DragDropContext>
      </PrioritiesContainer>
    </PriorityPopoverContainer>
  );
};

export default PriorityPopover;
