import React from "react";
import Modal from "../../../../components/Modal";
import styled from "styled-components";
import { Button } from "../../../../GeestUI";

const SubtitleContainer = styled.div`
  padding: 12px 12px 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    font-size: 14px;
    color: #828d9e;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const Languages = {
  ENG: {
    Subtitle: "Would you like to exit the task without saving the changes?",
    SaveLabel: "Save",
    ExitLabel: "Close",
  },
  ESP: {
    Subtitle: "¿Deseas salir de la tarea sin haber guardado los cambios?",
    SaveLabel: "Guardar",
    ExitLabel: "Salir",
  },
};

const { Subtitle, SaveLabel, ExitLabel } = Languages["ESP"];

interface UnsavedModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  onSave: () => void;
}

const UnsavedModal: React.FC<UnsavedModalProps> = ({
  onCancel,
  onConfirm,
  onSave,
}) => {
  return (
    <Modal
      visible
      title="Cambios sin guardar"
      onCancel={onCancel}
      width="357px"
    >
      <SubtitleContainer>
        <p>{Subtitle}</p>
      </SubtitleContainer>

      <ButtonsContainer
        onKeyDown={({ key }) => {
          if (key === "Enter") {
            console.log("Enter pressed");
          }
        }}
      >
        <Button onClick={onConfirm} type="secondary" size="regular">
          {ExitLabel}
        </Button>
        <Button onClick={onSave} type="primary" size="regular">
          {SaveLabel}
        </Button>
      </ButtonsContainer>
    </Modal>
  );
};

export default UnsavedModal;
