import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
// Tools
import ScrollToTop from "./components/ScrollToTop";
// Permissions
import VisitorRoute from "./permissions/VisitorRoute";
// Components
import RedirectComp from "./components/Redirect/RedirectComp";
// Layouts
import AuthLayout from "./layouts/AuthLayout";
import ProtectedRoute from "./permissions/ProtectedRoute";
import HomeLayout from "./layouts/HomeLayout/HomeLayout";
import ErrorLayout from "./layouts/ErrorLayout/ErrorLayout";
import LegalLayout from "./layouts/LegalLayout";
import AppAlertContext from "./components/AppAlert/AppAlertContext";
import AppMessagesContext from "./components/AppMessages/AppMessagesContext";
import LimitContext from "./components/SubscriptionLimit/SubscriptionLimitContext";
import TriggerView from "./layouts/TriggerView";

const App = () => {
  useEffect(() => {
    ReactGA.initialize("UA-203530604-1");
  }, []);

  return (
    <>
      <ScrollToTop />
      <AppMessagesContext>
        <LimitContext>
          <Switch>
            <ProtectedRoute
              path="/home"
              component={() => (
                <AppAlertContext>
                  <HomeLayout />
                </AppAlertContext>
              )}
            />
            <VisitorRoute path="/auth" component={AuthLayout} />
            <Route path="/legal" component={LegalLayout} />
            <Route path="/redirect" component={RedirectComp} />
            <Route path="/trigger" component={TriggerView} />
            <Route path="/error" component={ErrorLayout} />

            <Redirect from="/" to="/auth" />
          </Switch>
        </LimitContext>
      </AppMessagesContext>
    </>
  );
};

export default App;
