import React, { useState } from "react";
import { useFetch } from "../../../../hooks";
import styled from "styled-components";
import { TeamMember } from "../../Dashboard/Dashboard.d";
import User from "../User";
import { Row } from "antd";
import { CloseIcon } from "../../../../components/hoverIcons";

const Languages = {
  ENG: {
    TitleLabel: "Alerts",
    StartedLabel: "Started",
    StuckLabel: "Stuck",
    WaitingLabel: "Waiting",
    CompletedLabel: "Completed",
  },
  ESP: {
    TitleLabel: "Alertas",
    StartedLabel: "Iniciada",
    StuckLabel: "Vencida",
    WaitingLabel: "Por iniciar",
    CompletedLabel: "Terminada",
  },
};

const { TitleLabel, StartedLabel, StuckLabel, WaitingLabel, CompletedLabel } =
  Languages["ESP"];

export const Title = styled.div`
  font-size: 16px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  margin-bottom: 1rem;
`;

export const AlertTitle = styled(Title)`
  font-weight: 325;
  font-size: 14px;
  color: rgba(130, 141, 158, 1);
  font-family: Gotham-Book;
`;

export const StatusCont = styled.div`
  margin-left: 10px;
`;

export const Status = styled.div<{ $color: string; $bg: string }>`
  border-radius: 5px;
  min-width: 70px;
  padding: 0 0.2rem;
  text-align: center;
  font-size: 12px;
  color: ${({ $color }) => $color};
  background-color: ${({ $bg }) => $bg};
  border: 1px solid ${({ $color }) => $color};
`;

interface ResponsiblesProps {
  alertsId?: number | null;
  onClose: () => void;
}

interface Alert {
  Title: string;
  Status: string;
  UsersInvolve: TeamMember[];
}

const Alerts: React.FC<ResponsiblesProps> = ({ alertsId, onClose }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  useFetch<Alert[]>({
    func: "Ver2-Reports-gpa",
    args: {
      IdProcessExecution: alertsId,
    },
    onSuccess: (res) => setAlerts(res),
  });

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Started":
        return "#08979c";
      case "Stuck":
        return "#e11909";
      case "Waiting":
        return "#d46b08";
      case "Completed":
        return "#389e0d";
      default:
        return "#727e8b";
    }
  };

  const getStatusBackground = (status: string) => {
    switch (status) {
      case "Started":
        return "#e6fffb";
      case "Stuck":
        return "#f2d4d1";
      case "Waiting":
        return "#fff7e6";
      case "Completed":
        return "#f6ffed";
      default:
        return "#eff1f4";
    }
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case "Started":
        return StartedLabel;
      case "Stuck":
        return StuckLabel;
      case "Waiting":
        return WaitingLabel;
      case "Completed":
        return CompletedLabel;
      default:
        return "";
    }
  };

  return (
    <div>
      <Row justify="space-between">
        <Title>{TitleLabel}</Title>
        <CloseIcon onClick={onClose} />
      </Row>
      {(alerts || []).map((alert, idx) => (
        <div key={idx}>
          <Row>
            <AlertTitle>{alert.Title}</AlertTitle>
            <StatusCont>
              <Status
                $color={getStatusColor(alert.Status)}
                $bg={getStatusBackground(alert.Status)}
              >
                {getStatusLabel(alert.Status)}
              </Status>
            </StatusCont>
          </Row>
          {(alert.UsersInvolve || []).map((member) => (
            <User
              user={member}
              size="large"
              justify="start"
              key={member.IdUser}
            />
          ))}
          {alerts.length > 1 && idx !== alerts.length - 1 && (
            <hr style={{ border: "1px solid #c0c9d3" }} />
          )}
        </div>
      ))}
    </div>
  );
};

export default Alerts;
