import React, { useState, useContext, useEffect } from "react";
import { Link, Route, useHistory, useLocation } from "react-router-dom";
import { sha256 } from "js-sha256";
import { Formik, Form } from "formik";
import _ from "lodash";
import * as Yup from "yup";
import moment from "moment-timezone";
// API
import Bowser from "bowser";
import { useMutation } from "../../hooks/";
// Styles
import { Title, StyledLabel, Navigator } from "./styles";
// Components
import Input from "../../components/Inputs/Input";
import SubmitButton from "./SubmitButton";
// Img
import icono from "./../../resources/img/ICONO_.svg";
import userIMG from "./../../resources/img/USER_.svg";
import passIMG from "./../../resources/img/PASS_.svg";
import Img from "../../components/Img/Img";
// Context
import { SesionContext } from "./../../config/State";
import { SignUpContext } from "../../config/SignUpState";
// Constants
import { SessionType } from "../../constants/PlatformDetails";
import GeestVersion from "../../components/GeestVersion/GeestVersion";
import RestorePassword from "./RestorePassword/RestorePassword";
import { Languages } from "./Dictionary";

const Login = () => {
  const history = useHistory();
  const { state: locationState } = useLocation();
  const [, setSesion] = useContext(SesionContext);
  const { setIdPlan, setMode } = useContext(SignUpContext);
  const {
    LoginLabel,
    UserPlaceholder,
    PasswordPlaceholder,
    ForgotPasswordLabel,
    NoAccountLabel,
    RegisterLabel,
    FieldRequired,
    EmailValidation,
  } = Languages["ESP"];

  const [Ip, setIp] = useState("");
  const {
    parsedResult: { browser },
  } = Bowser.getParser(window.navigator.userAgent);

  useEffect(() => {
    fetch("https://api.ipify.org?format=jsonp?callback=?'", {
      method: "GET",
      headers: {},
    })
      .then((res) => {
        return res.text();
      })
      .then((ip) => {
        setIp(ip);
      });
  }, []);

  const [submitLogin, submitting] = useMutation({
    func: "Ver2-Session-l",
    onSuccess: (response) => {
      const {
        User,
        Token: { TokenType, AccessToken },
      } = response;
      setSesion({
        ...User,
        FavoriteTeam: { IdFavoriteTeam: "", TeamLogoUrl: "" },
        Auth: `${TokenType} ${AccessToken}`,
      });
    },
  });

  const handleOnSubmit = ({ email, password }) => {
    submitLogin({
      args: {
        IdClient: process.env.REACT_APP_CLIENT_ID,
        ClientSecret: process.env.REACT_APP_CLIENT_SECRET,
        Email: email,
        Password: sha256(password),
        SessionType,
        TimeZone: moment.tz.guess(),
        DeviceInfo: {
          Model: "",
          Ip,
          Browser: browser.name,
          ApiVersion: browser.version,
          LanguageCode: navigator.language,
          Country: "",
          PushNotificationsToken: "",
        },
      },
    });
  };

  const isValidForm = (formik) =>
    !(
      (formik.touched.email && formik.errors.email) ||
      (formik.touched.password && formik.errors.password)
    );

  const onChangeEmail = (value) => _.replace(value, " ", "");

  return (
    <div style={{ flex: 1, display: "flex", backgroundColor: "#EAEAEA" }}>
      <Route
        path="/auth/login/forgotPass"
        exact
        render={(props) => <RestorePassword {...props} />}
      />
      <div
        style={{
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ height: "80px", width: "80px" }}>
            <Img img={icono} size="contain" cursor="default" />
          </div>
          <Title>{LoginLabel}</Title>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Formik
            initialValues={{
              email: locationState?.email || "",
              password: "",
            }}
            onSubmit={handleOnSubmit}
            validationSchema={Yup.object({
              email: Yup.string()
                .email(EmailValidation)
                .required(FieldRequired),
              password: Yup.string().required(FieldRequired),
            })}
          >
            {(formik) => (
              <Form noValidate style={{ minWidth: "400px", padding: "0 2rem" }}>
                <Input
                  image={userIMG}
                  id="email"
                  name="email"
                  placeholder={UserPlaceholder}
                  controlledOnChange={onChangeEmail}
                />
                <Input
                  image={passIMG}
                  type="password"
                  id="password"
                  name="password"
                  placeholder={PasswordPlaceholder}
                />
                <StyledLabel
                  onClick={() => history.push("/auth/login/forgotPass")}
                >
                  {ForgotPasswordLabel}
                </StyledLabel>
                <SubmitButton
                  disabled={!isValidForm(formik)}
                  type="submit"
                  loading={submitting}
                >
                  Siguiente
                </SubmitButton>
              </Form>
            )}
          </Formik>
          <Navigator>
            <span>{NoAccountLabel}</span>
            <Link
              to="/auth/register"
              onClick={() => {
                setIdPlan("");
                setMode("");
              }}
            >
              {RegisterLabel}
            </Link>
          </Navigator>
          <GeestVersion textStyle={{ fontSize: "12px", color: "#828D9E" }} />
        </div>
      </div>
    </div>
  );
};

export default Login;
