import styled, { css } from "styled-components";

export const Container = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
  font-family: Gotham-Book;
`;

export const SelectInputBox = styled.div<{
  $borderColor: string;
  $background: string;
  $cursor: string;
  $disabled: boolean;
  $height: string;
  $maxHeight: string;
}>`
  border-radius: 6px;
  border: 2px solid;
  border-color: ${({ $borderColor }) => $borderColor};
  background-color: ${({ $background }) => $background};
  color: #828d9e;
  flex: 1;
  padding: 3px 10px;
  cursor: ${({ $cursor }) => $cursor};

  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 32px;
  ${({ $height }) => $height && `height: ${$height};`}
  ${({ $maxHeight }) => $maxHeight && `max-height: ${$maxHeight};`}
  resize: none;
  span {
    font-size: 14px;
    font-family: "Gotham-Book";
  }

  transition: all 0.35s ease;
  ${({ $disabled }) =>
    !$disabled
      ? css`
          :hover {
            border-color: #0273e9 !important;
            box-shadow: none !important;
          }
          :focus {
            border-color: #48505e !important;
            box-shadow: none;
          }
        `
      : ""}
  :focus-visible {
    outline: none;
  }
`;

export const DropDownIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemsContainer = styled.div<{
  itemsContainerContentWidth: string;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  max-width: calc(100% - 12px);

  * {
    text-wrap: balance !important;
    overflow: hidden;
    width: ${({ itemsContainerContentWidth = "auto" }) =>
      itemsContainerContentWidth};
    max-width: 100%;
    user-select: none;
    display: flex;
    gap: 8px;
  }
`;

export const Tag = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 6px;
  gap: 6px;
  background: ${({ disabled = false }) => (disabled ? "#d7d5d5" : "#edecec")};
  color: #48505e;
  border-radius: 6px;
  font-family: Gotham-Book;
`;

export const TagLabel = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;

  p {
    margin: 0;
    max-width: 100%;
    text-wrap: balance !important;
    overflow: hidden;
  }
`;

export const DeleteTagButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  min-width: 16px;
  width: 16px;
  min-height: 16px;
  height: 16px;
  max-width: 16px;
  max-height: 16px;

  transition: background 0.35s ease;
  :hover {
    background: #ccc;
  }
`;

export const ListContainer = styled.div<{ $listMaxHeight: string }>`
  max-height: ${({ $listMaxHeight }) => $listMaxHeight};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: Gotham-Book;
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
  }

  max-width: 100%;

  p {
    margin: 0;
    max-width: 100%;
    text-wrap: balance !important;
  }
`;

export const FilterContainer = styled.div`
  border: 2px solid #edecec;
  border-radius: 6px;
  padding-left: 10px;
  margin: 0 6px;
  color: #828d9e;
  cursor: pointer;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FilterInput = styled.input`
  width: 100%;
  border: none;
  padding: 0;
  :focus {
    border: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const Divider = styled.div`
  border: 1px solid #edecec;
  margin: 6px;
`;

export const OptionItem = styled.div<{
  canHoverOption: boolean;
  $padding: string;
  $selected: boolean;
  $focused: boolean;
  $separator?: boolean;
}>`
  padding: ${({ $padding }) => $padding};
  border-bottom: ${({ $separator = false }) =>
    $separator ? "1px solid #828d9e" : "none"};
  background: ${({
    $selected = false,
    $focused = false,
    canHoverOption = true,
  }) =>
    $focused
      ? "#edecec"
      : $selected
      ? canHoverOption
        ? "#f2f8fe"
        : "white"
      : "white"};
  color: #828d9e;
  cursor: pointer;
  user-select: none;
  transition: background 0.35s ease;
  :hover {
    background: ${({ canHoverOption }) =>
      canHoverOption ? "#edecec" : "transparent"};
  }
  * {
    user-select: none;
  }
`;

export const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #48505e;
  padding: 10px 0;
  font-family: Gotham-Book;
`;
