import { IGeestDestinations } from "./PlatformDetails.d";

export const SessionType: string = "Web";
export const isProd: boolean = process.env.REACT_APP_VERSION === "gp";

export const KeyMaps: string = "AIzaSyBnpxqDog8RyX0BeK2mgfWOVx8uH605ISg";
export const KeyStripe: string = process.env.REACT_APP_STRIPE_KEY;

export const GeestPhoneNumber: string = "+523781860033";
export const reCAPTCHA_key: string = "6LchuLUZAAAAAI1U7Zr70s0GkGvoQQp4VkI5Rs6o";

export const FileSizeLimit = 100; // MB.
export const maxFilesList = 20;

export const GeestDestinations: IGeestDestinations = {
  App: "Geest.app",
  LandingPage: "GeestLandingPage",
  Solar: "SolarGeestLangingPage",
};
