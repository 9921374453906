import React, { useRef } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { GraphConfiguration, GraphRenderInfo } from "../Dashboards.d";
import { getSymbolByCode } from "../../../components/DynamicInput/Components/helpers/CurrencyHelpers";
import { isArray } from "lodash";

ChartJS.register(ArcElement, Tooltip, Legend);

const GraphTypes = {
  MASTER: 0,
  DURATIONS: 1,
  SELLS: 2,
  KPI: 3,
  FUNNEL: 4,
};

interface DoughnutGraphProps {
  config: GraphConfiguration;
  renderInfo: GraphRenderInfo;
  selectedTemplate: number;
}

const DoughnutGraph: React.FC<DoughnutGraphProps> = ({
  config,
  renderInfo,
  selectedTemplate,
}) => {
  const chartRef = useRef();

  const getOptions = () => {
    let options: any = {
      plugins: {
        legend: {
          position: "right" as const,
        },
        responsive: true,
      },
    };

    if (selectedTemplate === GraphTypes.DURATIONS) {
      options.plugins.tooltip = {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed !== null) {
              label += context.parsed;
            }
            return label;
          },
        },
      };
    }

    if (selectedTemplate === GraphTypes.SELLS) {
      options.plugins.tooltip = {
        callbacks: {
          title: function (context: any) {
            let title = "";
            if (isArray(context) && context[0]) {
              title = context[0].label;
            }
            return title;
          },
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (context.dataset?.metaData) {
              const format = context.dataset.metaData[0] ?? "MXN";
              const symbol = getSymbolByCode(format);
              if (label) {
                label += `: ${symbol}`;
              }
              if (context.formattedValue !== null) {
                label += context.formattedValue;
              }
            }
            return label;
          },
        },
      };
    }

    return options;
  };

  return <Doughnut ref={chartRef} options={getOptions()} data={renderInfo} />;
};

export default DoughnutGraph;
