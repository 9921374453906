import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  background-color: #f6f5f5;
  overflow: auto;
  padding: 6px;
`;

export const LoadingContainer = styled.div<{ targetWidth: number }>`
  margin: 0 auto;
  display: flex;
  algin-items: center;
  justify-content: center;
  width: ${({ targetWidth }) => targetWidth}px;
  max-width: 1200px;
  height: 100%;
  background-color: white;
`;

export const Page = styled.div<{
  contentWidth: number;
  contentHeight: number;
  targetWidth: number;
  scale: number;
  backgroundImage: string;
}>`
  min-width: ${({ contentWidth }) => contentWidth}px;
  width: ${({ contentWidth }) => contentWidth}px;
  min-height: ${({ contentHeight }) => contentHeight}px;
  height: ${({ contentHeight }) => contentHeight}px;

  margin: 0 auto;
  position: relative;

  background-image: url(${({ backgroundImage = "" }) => backgroundImage});
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
