import React, { useState, useEffect } from "react";
import { Alert } from "@material-ui/lab";

export type MessageType = "error" | "warning" | "info" | "success";

export const MessagesContext = React.createContext<{
  showMessage: (message: string, type: MessageType) => void;
}>({ showMessage: (message, type) => {} });

const AppMessagesContext: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [CurrrentType, setCurrentType] = useState<MessageType | null>();
  const [Message, setMessage] = useState<string>();
  const [time, setTime] = useState<number>(3000);

  const showMessage = (
    message: string,
    type: MessageType | "hide" = "error"
  ) => {
    if (type !== "hide" && message) {
      setCurrentType(type);
      setMessage(message);
      if (message.length * 65 < 2000) {
        setTime(2000);
      } else {
        setTime(message.length * 65);
      }
    }
  };
  useEffect(() => {
    if (CurrrentType) {
      setTimeout(() => {
        setCurrentType(null);
        setMessage("");
      }, time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CurrrentType]);
  return (
    <MessagesContext.Provider value={{ showMessage }}>
      {CurrrentType && (
        <Alert
          severity={CurrrentType}
          style={{
            zIndex: 9999,
            position: "absolute",
            top: 30,
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            width: "fit-content",
          }}
        >
          {Message}
        </Alert>
      )}
      {children}
    </MessagesContext.Provider>
  );
};

export default AppMessagesContext;
