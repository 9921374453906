import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Row } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { EditChatModalProps } from "./EditChatModal.d";
import Modal from "../../../../components/Modal";
import { Label, List } from "./styles";
import { Chat, TeamRole } from "./EditChatsDropdown.d";
import { StyledInput } from "../../Flow/styles";
import { Button } from "../../../../GeestUI";
import { useFetch, useMutation } from "../../../../hooks";
import TeamRoleItem from "./TeamRoleItem";
import TeamRoleEditor from "./TeamRoleEditor";

const defaultTeamRole: TeamRole = {
  IdTeamRole: 0,
  Color: "#F44336",
  Label: "",
};

const EditChatModal: React.FC<EditChatModalProps> = ({
  chat: selectedChat,
  isDefaultChat,
  onSubmit,
  canDelete,
  onDelete,
  onClose,
}) => {
  const { IdTeam } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();

  const [chat, setChat] = useState<Chat>(selectedChat);
  const [teamRoles, setTeamRoles] = useState<TeamRole[]>([]);
  const [editingTeamRoleId, setEditingTeamRoleId] = useState<number | null>(
    null
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const isNewChat = chat.IdPrepublishChat === 0;
  const showEditChatTeamRoles = !isDefaultChat;
  const isValidForm = !_.isEmpty(_.trim(chat.Label));
  const modalTitle = isNewChat
    ? "Agregar nuevo canal de chat"
    : "Editar canal de chat";

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  useFetch<TeamRole[]>({
    func: "Ver2-Configurator-gtr",
    args: {
      IdTeam,
    },
    onSuccess: (roles) => {
      setTeamRoles(roles);
      if (isNewChat) handleOnSelectRole(roles[0]);
    },
  });
  const [insertTeamRole] = useMutation<TeamRole>({
    func: "Ver2-Configurator-itr",
    onSuccess: (teamRole) => {
      setTeamRoles([...teamRoles, teamRole]);
      setEditingTeamRoleId(null);
      handleOnSelectRole(teamRole);
    },
  });
  const [updateTeamRole] = useMutation<[]>({
    func: "Ver2-Configurator-utr",
    onSuccess: (_response, { updatedTeamRole, SelectRole }) => {
      setTeamRoles(
        _.map(teamRoles, (teamRole) =>
          teamRole.IdTeamRole === updatedTeamRole.IdTeamRole
            ? updatedTeamRole
            : teamRole
        )
      );
      setEditingTeamRoleId(null);
      if (SelectRole) handleOnSelectRole(updatedTeamRole);
    },
  });
  const [deleteTeamRole] = useMutation<[]>({
    func: "Ver2-Configurator-dtr",
    onSuccess: (_response, deletedTeamRole: TeamRole) => {
      setTeamRoles(
        _.reject(teamRoles, { IdTeamRole: deletedTeamRole.IdTeamRole })
      );
      // If selected then remove it
      if (_.find(chat.TeamRoles, { IdTeamRole: deletedTeamRole.IdTeamRole }))
        handleOnSelectRole(deletedTeamRole);
      setEditingTeamRoleId(null);
    },
  });

  const handleOnEditTeamRole = (teamRole: TeamRole) =>
    setEditingTeamRoleId(teamRole.IdTeamRole);
  const handleOnCancel = () => setEditingTeamRoleId(null);
  const handleOnDeleteTeamRole = (teamRole: TeamRole) =>
    deleteTeamRole({
      args: { IdTeamRole: teamRole.IdTeamRole },
      shippedData: teamRole,
    });
  const handleOnSubmitTeamRoleEdition = (teamRole: TeamRole) => {
    if (teamRole.IdTeamRole === 0)
      insertTeamRole({ args: { ...teamRole, IdTeam } });
    else
      updateTeamRole({
        args: teamRole,
        shippedData: { updatedTeamRole: teamRole, SelectRole: true },
      });
  };

  const handleOnChangeLabel: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setChat({ ...chat, Label: e.target.value });
  const handleOnSelectRole = (teamRole: TeamRole) => {
    // Remove if exists
    if (_.find(chat.TeamRoles, { IdTeamRole: teamRole.IdTeamRole }))
      setChat({
        ...chat,
        TeamRoles: _.reject(chat.TeamRoles, {
          IdTeamRole: teamRole.IdTeamRole,
        }),
      });
    // Add selected role
    else setChat({ ...chat, TeamRoles: [...chat.TeamRoles, teamRole] });
  };
  const handleOnSubmit: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (isValidForm) onSubmit({ ...chat, Label: _.trim(chat.Label) });
  };

  return (
    <Modal title={modalTitle} centered width={432} onCancel={onClose}>
      <div>
        <div style={{ margin: "0px 15px" }}>
          <StyledInput
            placeholder="Nombre del canal..."
            value={chat.Label}
            onChange={handleOnChangeLabel}
            ref={inputRef}
          />
        </div>
        <Label>Roles del equipo que pueden acceder a este canal</Label>
        {showEditChatTeamRoles && (
          <Row justify="center" style={{ margin: "0 40px" }}>
            {editingTeamRoleId === 0 ? (
              <TeamRoleEditor
                defaultValue={defaultTeamRole}
                onSubmit={handleOnSubmitTeamRoleEdition}
                onDelete={handleOnDeleteTeamRole}
                onCancel={handleOnCancel}
              />
            ) : (
              <Button
                type="secondary"
                Icon={AiOutlinePlus}
                onClick={() => setEditingTeamRoleId(0)}
              >
                Nuevo rol
              </Button>
            )}
          </Row>
        )}
        <List>
          {isDefaultChat ? (
            <TeamRoleItem
              teamRole={{ Label: "Todos", IdTeamRole: 0 }}
              selected
              showEditChatTeamRoles={false}
              onEdit={_.noop}
              onSelect={_.noop}
            />
          ) : (
            teamRoles.map((teamRole) =>
              teamRole.IdTeamRole === editingTeamRoleId ? (
                <TeamRoleEditor
                  key={teamRole.IdTeamRole}
                  defaultValue={teamRole}
                  onSubmit={handleOnSubmitTeamRoleEdition}
                  onDelete={handleOnDeleteTeamRole}
                  onCancel={handleOnCancel}
                />
              ) : (
                <TeamRoleItem
                  key={teamRole.IdTeamRole}
                  teamRole={teamRole}
                  selected={
                    !!_.find(chat.TeamRoles, {
                      IdTeamRole: teamRole.IdTeamRole,
                    })
                  }
                  showEditChatTeamRoles={showEditChatTeamRoles}
                  onEdit={handleOnEditTeamRole}
                  onSelect={handleOnSelectRole}
                />
              )
            )
          )}
        </List>
        <Row justify="center">
          {isNewChat || !canDelete ? (
            <Button
              type="secondary"
              style={{ marginRight: "8px" }}
              onClick={onClose}
            >
              Cancelar
            </Button>
          ) : (
            <Button
              type="danger"
              style={{ marginRight: "8px" }}
              onClick={() => {
                onDelete(chat);
                onClose();
              }}
            >
              Eliminar chat
            </Button>
          )}
          <Button
            type="primary"
            disabled={!isValidForm}
            onClick={handleOnSubmit}
          >
            Guardar
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

export default EditChatModal;
