import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { PageView } from "../../analytics";
import MyTeams from "./MyTeams/MyTeams";
import TeamDetails from "./TeamDetails/TeamDetails";
import OrganizationChart from "./OrganizationChart";
import { TeamsContainer } from "./Style";

const Teams: React.FC = () => {
  useEffect(() => {
    PageView("/teams");
  }, []);

  return (
    <TeamsContainer>
      <Switch>
        <Route path="/home/teams" exact render={() => <MyTeams />} />
        <Route
          path="/home/teams/:idTeam"
          exact
          render={() => <TeamDetails />}
        />
        <Route
          path="/home/teams/:idTeam/org"
          exact
          render={() => <OrganizationChart />}
        />
      </Switch>
    </TeamsContainer>
  );
};

export default Teams;
