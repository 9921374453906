import styled from "styled-components";

export const MoveIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: #eee;
  cursor: pointer;
  height: 20px;
  width: 20px;
  transition: background-color 0.35s ease;

  :hover {
    background-color: #d9d9d9;
  }
`;

export const PopoverContainer = styled.div``;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PopoverRow = styled.div<{ center?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: ${({ center }) => (center ? "center" : "flex-start")};
`;

export const Subtitle = styled.div`
  p {
    margin: 0;
    font-family: Gotham-Bold;
    color: #48505e;
    user-select: none;
  }
`;
