import React from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import ViewTitle from "../../components/ViewTitle/ViewTitle";
import Profile from "./Profile";
import Notifications from "./Notifications";
import Devices from "./Devices";
import Configuration from "./Configuration";

import ProfileImg from "../../resources/img/Ajustes_Perfil.png";
import ProfileImgActive from "../../resources/img/Ajustes_Perfil_Azul.png";
import NotificationsImg from "../../resources/img/Ajustes_Notificaciones.png";
import NotificationsImgActive from "../../resources/img/Ajustes_Notificaciones_Azul.png";
//import DevicesImg from "../../resources/img/Ajustes_Dispositivo.png";
import { Languages } from "./Dictionary";
import { AiOutlineControl } from "react-icons/ai";

import { Row } from "antd";
import { TitleStyle, Img, ItemTxt, ItemCont } from "./Style";

const Settings = () => {
  const { SettingsLabel, ProfileLabel, NotificationsLabel /*DevicesLabel*/ } =
    Languages["ESP"];

  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <>
      <ViewTitle ShowTeamIcon>
        <TitleStyle>{SettingsLabel}</TitleStyle>
      </ViewTitle>
      <Row justify="center">
        <Row justify="center" style={{ width: "46%" }}>
          <ItemCont
            align="middle"
            onClick={() => history.push("/home/settings/profile")}
            $isActive={pathname.includes("profile")}
          >
            <Img
              src={pathname.includes("profile") ? ProfileImgActive : ProfileImg}
              alt="Profile"
            />
            <ItemTxt>{ProfileLabel}</ItemTxt>
          </ItemCont>
          <ItemCont
            align="middle"
            onClick={() => history.push("/home/settings/notifications")}
            $isActive={pathname.includes("notifications")}
          >
            <Img
              src={
                pathname.includes("notifications")
                  ? NotificationsImgActive
                  : NotificationsImg
              }
              alt="Notification"
            />
            <ItemTxt>{NotificationsLabel}</ItemTxt>
          </ItemCont>
          <ItemCont
            align="middle"
            onClick={() => history.push("/home/settings/configuration")}
            $isActive={pathname.includes("configuration")}
          >
            <AiOutlineControl size={20} />
            <ItemTxt>Configuración</ItemTxt>
          </ItemCont>
          {/*<ItemCont
            align="middle"
            //onClick={() => history.push("/home/settings/devices")}
            $isActive={pathname.includes("devices")}
          >
            <Img src={DevicesImg} alt="Devices" />
            <ItemTxt>{DevicesLabel}</ItemTxt>
          </ItemCont>*/}
        </Row>
      </Row>
      <div style={{ width: "100%" }}>
        <Switch>
          <Route
            path="/home/settings/profile"
            exact
            render={() => <Profile />}
          />
          <Route
            path="/home/settings/notifications"
            exact
            render={() => <Notifications />}
          />
          <Route
            path="/home/settings/devices"
            exact
            render={() => <Devices />}
          />
          <Route
            path="/home/settings/configuration"
            exact
            render={() => <Configuration />}
          />
        </Switch>
      </div>
    </>
  );
};

export default Settings;
