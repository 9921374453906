import styled from "styled-components";
import TableContainer from "@material-ui/core/TableContainer";
import { Row } from "antd";
import { Tag } from "../../../GeestUI";
import { TableCell } from "@material-ui/core";

export const PipelineContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  *::-webkit-scrollbar-thumb:horizontal {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  *::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
`;

export const LoadingContainer = styled.div`
  z-index: 4;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
`;

export const NoDataContainer = styled.div`
  z-index: 5;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
`;

export const Controls = styled(Row)`
  /* Left some space for the AddButton and his margin */
  width: calc(100% - 62px);
  margin-bottom: 25px;
`;

export const Content = styled.div<{ $openFilters: boolean; $height: number }>`
  flex: 1;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  .scroll {
    ::-webkit-scrollbar-thumb:horizontal {
      background-color: rgba(0, 0, 0, 0.5);
    }
    max-height: ${({ $openFilters, $height }) =>
      $openFilters ? `${$height - 40}px` : `${$height}px`};
  }
`;

export const TopButtonContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const AddButton = styled.div`
  cursor: pointer;
  border-radius: 5px;
  border: 1px dashed var(--light-gray);
  color: var(--light-gray);
  width: 40px;
  margin-left: 22px;
  display: flex;
  svg {
    margin: auto;
  }
  :hover {
    border: 1px dashed var(--medium-gray);
    color: var(--medium-gray);
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  overflow: auto;
  position: relative;
`;

export const StyledTableContainer = styled(TableContainer)`
  & table {
    overflow: auto;
  }
  & .MuiTableCell-root {
    white-space: nowrap;
    border-right: 3px solid white;
    max-width: 300px;
  }
  & .MuiTableCell-head {
    background-color: rgba(242, 248, 254, 1);
    color: rgba(72, 80, 94, 1);
    font-family: Gotham-Bold;
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 40px;
    border-right: 3px solid white;
  }
  & .MuiTableCell-body {
    color: var(--medium-gray);
    border-bottom: transparent;
  }
  * {
    user-select: none !important;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #dfe4e9;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(130, 141, 158, 1);
  border: 1px solid rgba(130, 141, 158, 1);
`;

export const FilterContainer = styled(Row)`
  min-width: 390px;
  border-radius: 6px;
  min-height: 40px;
  background-color: rgba(246, 245, 245, 1);
  padding: 6px;
  margin-top: 5px;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 80%;
  background-color: rgba(72, 80, 94, 1);
  border: 1px solid rgba(72, 80, 94, 1);
  margin-left: 1.5rem;
  margin-right: 10px;
`;

export const ArrowContainer = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background-color: rgba(130, 141, 158, 1);
  margin-right: 10px;

  padding-bottom: ${({ open }) => (open ? "2px" : "0px")};
`;

export const FiltersConteiner = styled(Row)<{ $filtersVisible: boolean }>`
  gap: 10px;
  margin-bottom: 19px;
  max-height: ${({ $filtersVisible }) => ($filtersVisible ? "80vh" : "0px")};
  transition: max-height 0.5s ease;
  overflow: hidden;
`;

export const FiltersSelectContainer = styled(Row)<{ $filtersVisible: boolean }>`
  max-height: ${({ $filtersVisible }) => ($filtersVisible ? "30vh" : "0px")};
  transition: max-height 0.3s ease;
  overflow: hidden;
`;

export const FilterLabel = styled.p`
  margin: 0;
  font-family: Gotham-Bold;
  color: #48505e;
`;

export const FilterCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 33px;
  width: auto;
  background-color: rgba(242, 248, 254, 1);
  border-radius: 6px;
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  font-size: 12px;
  cursor: pointer;
  padding: 7.5px 8px 7.5px 9px;
`;

export const FilterIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1px;
`;

export const ProcessTitle = styled.div`
  font-size: 14px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
  max-width: 190px;
`;

export const StyledTag = styled(Tag)`
  border-radius: 6px;
  border-width: 2px;
  font-size: 10px;
  font-family: Gotham-Bold;
`;

export const StyledCell = styled(TableCell)<{ isEven?: boolean }>`
  background-color: ${({ isEven }) =>
    isEven ? "rgba(246, 245, 245, 1)" : "transparent"};
`;

export const CustomReportTitleContainer = styled.div`
  font-size: 14px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
