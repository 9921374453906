import React, { useState } from "react";
import Modal from "../../../components/Modal";
import ModalTab from "../CreateProcess/ModalTab";
import {
  InstallTemplateProps,
  GetInstallWizardResponse,
  GetVarDBStepResponse,
  VarDBType,
} from "./InstallTemplate.d";
import StepContent from "./StepContent";
import Loading from "../../../components/Loading";
import { Languages } from "./Dictionary";
import { useFetch, useMutation } from "../../../hooks";

const InstallTemplate: React.FC<InstallTemplateProps> = ({
  closeModal,
  IdTeam,
  Teams,
  reshareTemplateData,
  IdParentFolder,
  isSharing,
  reload,
}) => {
  const {
    DescriptionLabel,
    AdvancedSettingsLabel,
    DBSettingsLabel,
    LinkingDBLabel,
    LinkingColumnsLabel,
    ProcessingInfoLabel,
    CreatingProcessLabel,
    ProcessCreatedLabel,
    InstallProcessTitle,
  } = Languages["ESP"];
  const [activeStep, setActiveStep] = useState<number>(0);
  const [varDBs, setVarDBs] = useState<VarDBType[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [teamSelected, setTeamSelected] = useState<number | null>(IdTeam);
  const [varDBData, setVarDBData] = useState<GetVarDBStepResponse>();
  const [installing, setInstalling] = useState<boolean>(false);

  const { data } = useFetch<GetInstallWizardResponse>({
    func: "Ver2-Processes-giw",
    args: {
      IdProcessTemplates: reshareTemplateData?.IdProcessTemplate,
      Step: "General",
    },
    onSuccess: (data) => {
      setVarDBs(
        data.ProcessTemplateVarDBs.map((varDB) => ({
          ...varDB,
          TeamDestinyIdVarDB: 0,
        }))
      );
    },
  });

  const [getVarDBStep] = useMutation<GetVarDBStepResponse>({
    func: "Ver2-Processes-giw",
    onSuccess: (data, shipped) => {
      setVarDBData(data);
      setActiveStep(shipped.step);
      if (!shipped.selectedVarDB.ImportingColumns) {
        let varDBsAux = [...varDBs];
        let selectedVarDB = shipped.selectedVarDB;
        const varDBIndex = varDBsAux.findIndex(
          ({ ProcessTemplateIdVarDB }) =>
            selectedVarDB.ProcessTemplateIdVarDB === ProcessTemplateIdVarDB
        );
        selectedVarDB.ImportingColumns = {};
        data.ProcessTemplateVarDBColumns.forEach(({ IdColumn }) => {
          selectedVarDB.ImportingColumns[IdColumn] = null;
        });
        varDBsAux[varDBIndex] = selectedVarDB;
        setVarDBs(varDBsAux);
      }
    },
  });

  const [installProcessTemplate, loading] = useMutation({
    func: "Ver2-Processes-cpt",
    onSuccess: () => {
      if (progress !== 100) {
        setProgress(100);
        setTimeout(() => {
          closeModal();
        }, 500);
      } else {
        closeModal();
      }
      reload();
    },
    onError: () => setInstalling(false),
  });

  const getSteps = () => {
    const defaultSteps = [DescriptionLabel, AdvancedSettingsLabel];
    const varDBSteps = varDBs
      .filter(({ TeamDestinyIdVarDB }) => TeamDestinyIdVarDB !== 0)
      .map(({ Label }) => DBSettingsLabel(Label));
    return [...defaultSteps, ...varDBSteps];
  };

  const onChangeStep = (step: number) => {
    if (step >= 2) {
      const selectedVarDB = varDBs?.filter(
        ({ TeamDestinyIdVarDB }) => TeamDestinyIdVarDB !== 0
      )[step - 2];
      getVarDBStep({
        args: {
          Step: "ImportVarDB",
          IdProcessTemplates: reshareTemplateData?.IdProcessTemplate,
          IdTeamDestiny: teamSelected,
          TeamDestinyIdVarDB: selectedVarDB?.TeamDestinyIdVarDB,
          ProcessTemplateIdVarDB: selectedVarDB?.ProcessTemplateIdVarDB,
        },
        shippedData: { step, selectedVarDB },
      });
    } else {
      setActiveStep(step);
    }
  };

  const onInstall = () => {
    setInstalling(true);
    installProcessTemplate({
      args: {
        IdTeamDestiny: teamSelected,
        IdProcessTemplates: reshareTemplateData?.IdProcessTemplate,
        CanReShareTemplate: reshareTemplateData?.CanReShareTemplate,
        IdParentFolder,
        VardbToCopy: varDBs,
      },
    });
    const firstTime = Math.random() * 1000;
    const secondTime = Math.random() * 1000 + firstTime;
    const thirdTime = Math.random() * 1000 + secondTime;
    const fourthTime = Math.random() * 1000 + thirdTime;
    const fifthTime = Math.random() * 1000 + fourthTime;
    setTimeout(() => {
      if (loading) {
        setProgress(20);
      }
    }, firstTime);
    setTimeout(() => {
      if (loading) {
        setProgress(40);
      }
    }, secondTime);
    setTimeout(() => {
      if (loading) {
        setProgress(60);
      }
    }, thirdTime);
    setTimeout(() => {
      if (loading) {
        setProgress(80);
      }
    }, fourthTime);
    setTimeout(() => {
      if (loading) {
        setProgress(100);
      }
    }, fifthTime);
  };
  const progressLabels = {
    20: LinkingDBLabel,
    40: LinkingColumnsLabel,
    60: ProcessingInfoLabel,
    80: CreatingProcessLabel,
    100: ProcessCreatedLabel,
  };

  return (
    <Modal
      visible
      width={installing ? "703px" : "879px"}
      onCancel={closeModal}
      bodyStyle={{ height: installing ? "572px" : "663px" }}
      title={
        installing
          ? ""
          : reshareTemplateData?.IdProcessTemplate &&
            reshareTemplateData?.IdProcessTemplate?.length > 1
          ? "Instalar paquete de procesos"
          : InstallProcessTitle(data?.ProcessTemplateName)
      }
    >
      {!installing ? (
        <>
          {activeStep !== 0 && (
            <div style={{ marginTop: "1rem" }}>
              <ModalTab
                steps={getSteps()}
                activeStep={activeStep}
                setActiveStep={onChangeStep}
              />
            </div>
          )}
          <StepContent
            activeStep={activeStep}
            varDBs={varDBs}
            steps={getSteps()}
            setActiveStep={onChangeStep}
            setVarDBs={setVarDBs}
            onAdvancedSettings={() => onChangeStep(1)}
            onInstall={onInstall}
            IdTeam={IdTeam}
            Teams={Teams}
            processTemplates={data?.ProcessTemplates}
            IdProcessTemplate={reshareTemplateData?.IdProcessTemplate}
            teamSelected={teamSelected}
            setTeamSelected={setTeamSelected}
            varDBData={varDBData}
            isSharing={isSharing}
          />
        </>
      ) : (
        <Loading
          progressBar
          progress={progress}
          progressLabels={progressLabels}
        />
      )}
    </Modal>
  );
};

export default InstallTemplate;
