import React, { useState } from "react";
import _, { noop as NOOP } from "lodash";
import { Popover, Tag } from "antd";
import { Typography } from "../../../../GeestUI";
import ProcessPopover from "./Popovers/ProcessPopover";
import { Proccess } from "../Dashboard.d";
import { StyledInputBox } from "./Styles";

const { H4 } = Typography;

interface ProcessFilterProps {
  extended: boolean;
  process: Proccess[];
  processCollection: Proccess[];
  ProcessPlaceholder: string;
  onChangeProcess: (oldProcess: Proccess[], newProcess: Proccess[]) => void;
}

const ProcessFilter: React.FC<ProcessFilterProps> = ({
  extended,
  process,
  processCollection,
  ProcessPlaceholder,
  onChangeProcess,
}) => {
  const [processPopoverVisible, setProcessPopoverVisible] =
    useState<boolean>(false);

  return (
    <Popover
      trigger="click"
      placement="bottom"
      overlayStyle={{
        border: "solid 1px #cfcdcd",
        borderRadius: ".9rem",
        padding: "0",
        overflow: "hidden",
        boxShadow: "0px 0px 30px #cfcdcd",
        width: "350px",
      }}
      destroyTooltipOnHide
      open={processPopoverVisible}
      onOpenChange={extended ? setProcessPopoverVisible : NOOP}
      content={() => (
        <ProcessPopover
          value={process}
          proccessCollection={processCollection}
          onChange={onChangeProcess}
          onClose={() => setProcessPopoverVisible(false)}
        />
      )}
    >
      <StyledInputBox
        tabIndex={0}
        onKeyDown={({ key }) => {
          if (key === "Enter") setProcessPopoverVisible(true);
        }}
      >
        {process.map((proc, idx) => (
          <Tag
            key={idx}
            color="#0066af"
            style={{
              borderRadius: "5px",
              marginRight:
                process.length === 1 || idx === process.length - 1 ? 0 : "5px",
              marginBottom: process.length <= 2 ? 0 : "5px",
            }}
          >
            {proc.ProcessName}
          </Tag>
        ))}
        {_.isEmpty(process) && (
          <H4
            ml="5px"
            mb="0"
            color="#c0c9d3"
            onClick={extended ? () => setProcessPopoverVisible(true) : NOOP}
          >
            {ProcessPlaceholder}
          </H4>
        )}
      </StyledInputBox>
    </Popover>
  );
};

export default ProcessFilter;
