import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const StyledPaper = styled(Paper)`
  border-radius: 6px !important;
  max-height: 90vh;
  width: 397px;
  min-height: 332px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 24px;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #48505e;
  font-size: 16px;
  font-family: Gotham-Bold;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 12px;
  align-items: center;
`;

export const Description = styled.div`
  text-align: center;
  width: 264px;
  font-size: 14px;
  color: #828d9e;
  font-family: Gotham-Book;
`;

export const LimitContainer = styled.div<{ $backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  padding: 20px 18px;
  height: 110px;
  width: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 6px;
  gap: 12px;
`;

export const LimitTitle = styled.div<{ $fontColor: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: ${({ $fontColor }) => $fontColor};
  font-family: Gotham-Bold;
  font-size: 14px;
`;

export const LimitBadge = styled.div<{ $badgeColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  color: #ffffff;
  border-radius: 4px;
  background-color: ${({ $badgeColor }) => $badgeColor};
`;

export const LimitDescription = styled.div<{ $fontColor: string }>`
  color: ${({ $fontColor }) => $fontColor};
  font-size: 12px;
  font-family: Gotham-Book;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
