import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useMutation } from "../../../hooks";
import Modal from "../../../components/Modal";
import { StyledInput } from "../Styles";
import { Button } from "../../../GeestUI";
import { MessagesContext } from "../../../components/AppMessages";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Languages = {
  ENG: {
    ModalTitle: "Create folder",
    InputPlaceholder: "Folder name...",
    CreateLabel: "Create",
    SuccessfulCreationLabel: "Successful creation",
  },
  ESP: {
    ModalTitle: "Crear carpeta",
    InputPlaceholder: "Escribe el nombre de la carpeta...",
    CreateLabel: "Crear",
    SuccessfulCreationLabel: "Creación exitosa",
  },
};

interface CreateFolderProps {
  IdTeam: number | null;
  IdParentFolder: string | null;
  closeModal: () => void;
  reload: () => void;
}

const CreateFolder: React.FC<CreateFolderProps> = ({
  IdTeam,
  IdParentFolder,
  closeModal,
  reload,
}) => {
  const { showMessage } = useContext(MessagesContext);
  const [folderName, setFolderName] = useState<string>("");
  const ref = useRef<HTMLInputElement>(null);

  const { ModalTitle, InputPlaceholder, CreateLabel, SuccessfulCreationLabel } =
    Languages["ESP"];

  const canSubmit = folderName.trim().length > 0;

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  const [registerFolder, submiting] = useMutation<number>({
    func: "Ver2-Vardbs-rvf",
    onSuccess: () => {
      showMessage(SuccessfulCreationLabel, "success");
      reload();
    },
  });

  const onSubmit = () => {
    if (!canSubmit) return;
    registerFolder({
      args: {
        IdTeam,
        Name: folderName.trim(),
        IdParentFolder,
      },
    });
    closeModal();
  };

  return (
    <Modal
      title={ModalTitle}
      onCancel={closeModal}
      width="432px"
      bodyStyle={{ height: "158px" }}
    >
      <Wrapper>
        <StyledInput
          ref={ref}
          autoFocus
          type="text"
          disabled={submiting}
          placeholder={InputPlaceholder}
          value={folderName}
          onChange={({ target: { value } }) => setFolderName(value)}
          onKeyDown={({ key }) => {
            if (key === "Enter" && canSubmit) {
              onSubmit();
            }
          }}
        />
      </Wrapper>

      <Wrapper>
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={submiting || !canSubmit}
        >
          {CreateLabel}
        </Button>
      </Wrapper>
    </Modal>
  );
};

export default CreateFolder;
