import React from "react";
import Modal from "../../../../../components/Modal";
import styled from "styled-components";
import { Button } from "../../../../../GeestUI";

const SubtitleContainer = styled.div`
  padding: 12px 12px 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    font-size: 14px;
    color: #828d9e;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const Languages = {
  ENG: {
    Subtitle: "Would you like to exit the action without saving the changes?",
    ConfirmLabel: "Confirm",
    CancelLabel: "Cancel",
  },
  ESP: {
    Subtitle: "¿Deseas salir de la acción sin haber guardado los cambios?",
    ConfirmLabel: "Confirmar",
    CancelLabel: "Cancelar",
  },
};

const { Subtitle, ConfirmLabel, CancelLabel } = Languages["ESP"];

interface UnsavedModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  visible: boolean;
}

const UnsavedModal: React.FC<UnsavedModalProps> = ({
  onCancel,
  onConfirm,
  visible,
}) => {
  return (
    <Modal
      visible={visible}
      title="Cambios sin guardar"
      onCancel={onCancel}
      width="357px"
    >
      <SubtitleContainer>
        <p>{Subtitle}</p>
      </SubtitleContainer>

      <ButtonsContainer>
        <Button onClick={onCancel} type="secondary" size="regular">
          {CancelLabel}
        </Button>
        <Button onClick={onConfirm} type="primary" size="regular">
          {ConfirmLabel}
        </Button>
      </ButtonsContainer>
    </Modal>
  );
};

export default UnsavedModal;
