export enum ToolboxActions {
  RESTART_PROCESS = "restartProcess",
  OPEN_PENDING_DETAIL = "openPendingDetail",
}

export interface ToolboxProps {
  nodeId: string;
  onAction: (action: ToolboxActions) => void;
  showDetail: boolean;
  showRestart: boolean;
}
