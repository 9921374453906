import React, { useState } from "react";
import Modal from "../../../Modal";
import DataTypeIcons from "../../../DataTypeIcons";
import GeestSelect from "../../../../GeestUI/GeestSelect";
import { Button } from "../../../../GeestUI";
import styled from "styled-components";
import { Row } from "antd";
import Tooltip from "../../../Tooltip";
import { uploadFileToAWS } from "../../../../aws/s3Client";
import { RcFile } from "antd/lib/upload";
import fileIcon from "../../../../resources/img/DataType_828D9E/file.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 0px 14px;
`;

const Text = styled.div`
  font-family: Gotham-Book;
  text-align: center;
  color: #828d9e;
  width: 343px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export interface FileField {
  Id: number;
  Label: string;
}

export interface FileType {
  Name: string;
  SourceUrl: string;
}

interface SelectFileFieldModalProps {
  fileFields: FileField[];
  onChangeFileField: (Id: number, file: FileType) => void;
  closeModal: () => void;
  file: FileType;
}

export const SelectFileFieldModal: React.FC<SelectFileFieldModalProps> = ({
  fileFields,
  onChangeFileField,
  closeModal,
  file,
}) => {
  const [selectedField, setSelectedField] = useState<number>();
  const options =
    fileFields.map(({ Label, Id }) => ({
      label: (
        <Row align="middle">
          <Row
            align="middle"
            justify="start"
            style={{
              width: "26px",
              height: "26px",
              display: "flex",
              marginRight: -4,
            }}
            wrap={false}
          >
            <img
              style={{
                width: "14px",
                height: "14px",
                ...DataTypeIcons["file"].extraStyles,
              }}
              alt={DataTypeIcons["file"]?.label || ""}
              src={fileIcon}
            />
          </Row>
          {Label.length >= 40 ? (
            <Tooltip title={Label} placement="right">
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {Label}
              </div>
            </Tooltip>
          ) : (
            <div
              style={{
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {Label}
            </div>
          )}
        </Row>
      ),
      value: Id,
      searchableValues: Label,
    })) || [];

  const onSubmit = () => {
    if (selectedField) {
      onChangeFileField(selectedField, file);
      closeModal();
    }
  };

  return (
    <Modal
      width="437px"
      title="Pegar archivo de portapapeles"
      onCancel={closeModal}
    >
      <Container>
        <Text>Elige el campo donde quieres pegar este archivo</Text>
        <GeestSelect
          value={selectedField}
          onChange={setSelectedField}
          valueNecesary
          options={options}
        />
        <ButtonContainer>
          <Button type="secondary" onClick={closeModal}>
            Cancelar
          </Button>
          <Button type="primary" onClick={onSubmit} disabled={!selectedField}>
            Subir
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export const onPasteImage = async (FilesPath: string) => {
  try {
    const clipBoardItems = await navigator.clipboard.read();
    const type = clipBoardItems[0].types;
    if (!type.find((val) => val.includes("image"))) {
      return;
    }
    const blobOutput = await clipBoardItems[0].getType("image/png");
    if (blobOutput) {
      const file = new File([blobOutput], "pastedimg");
      const fileName = `Imagen_copiada.png`;
      const sourceUrl = (await uploadFileToAWS(
        file as RcFile,
        FilesPath,
        fileName
      )) as unknown as string;
      const value = {
        Name: fileName,
        SourceUrl: sourceUrl,
      };
      return value;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};
