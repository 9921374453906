import React from "react";
import { RowWrapper } from "../Styles";

interface CellProps {
  cellKey: string;
  maxWidth: string;
  handleOnClick: (e: any) => void;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
  children: React.ReactNode;
  onContextMenu: (e: any) => void;
  isEven: boolean;
  isDragging: boolean;
}

const Cell: React.FC<CellProps> = ({
  cellKey,
  maxWidth,
  handleOnClick,
  handleMouseEnter,
  handleMouseLeave,
  children,
  onContextMenu,
  isEven,
  isDragging,
}) => {
  return (
    <RowWrapper
      key={cellKey}
      $maxWidth={maxWidth}
      onClick={handleOnClick}
      onContextMenu={onContextMenu}
      isEven={isEven}
      isDragging={isDragging}
    >
      {children}
    </RowWrapper>
  );
};

export default React.memo(Cell);
