import React from "react";
import GeestSelect from "../../../../../../GeestUI/GeestSelect/GeestSelect";
import Stackbar from "../../../../../../resources/img/Dashboards/stackbar_axis_x.svg";
import Donut from "../../../../../../resources/img/Dashboards/donut.svg";
import { Button } from "../../../../../../GeestUI";
import DynamicGraphPreview from "../../DynamicGraphPreview";
import {
  GeneralStepContainer,
  ContinueButtonRow,
  FormatContainer,
  FormatForm,
  GraphFormatAbstraction,
  GraphPreviewContainer,
  InputElement,
  InputsContainer,
  LeftPannel,
  PrevisualizationCard,
  RightPannel,
  StringInput,
  TextContainer,
} from "./GeneralStepStyles";
import { Languages } from "../Dictionary";
import {
  DurationsGraphFormat,
  FilterOption,
  GroupByInterface,
  GroupByType,
  XAxisValueType,
} from "../../../../Dashboards.d";

const {
  Continue,
  GeneralLeftTitle,
  GeneralLeftDescription,
  GeneralLeftNameLabel,
  GeneralLeftSellsNamePlaceholder,
  GeneralLeftProcessLabel,
  GeneralLeftProcessesPlaceholder,
  GeneralLeftFormatStackbar,
  GeneralLeftFormatAll,
  GeneralLeftFormatDoughnut,
  Previsualization,
} = Languages["ESP"];

interface Option {
  value: string | number;
  label: string;
}

interface GeneralStepProps {
  graphName: string;
  setGraphName: (name: string) => void;
  selectedProcesses: string;
  setSelectedProcesses: (selectedProcesses: string) => void;
  selectedTemplate: number;
  selectedDashboard: string | null;
  format: DurationsGraphFormat;
  setFormat: (format: DurationsGraphFormat) => void;
  options: Option[];
  filters: FilterOption[];
  groupBy: GroupByType;
  xAxisValue: XAxisValueType;
  groupByFromExistendGraph: GroupByInterface | null;
  cleanOnChangeProcesses: () => void;
  canGoNext: boolean;
  goNextStep: () => void;
  setUnsavedChanges: () => void;
}

const GeneralStep: React.FC<GeneralStepProps> = ({
  graphName,
  setGraphName,
  selectedProcesses,
  setSelectedProcesses,
  selectedTemplate,
  selectedDashboard,
  format,
  setFormat,
  options,
  filters,
  groupBy,
  xAxisValue,
  groupByFromExistendGraph,
  cleanOnChangeProcesses,
  canGoNext,
  goNextStep,
  setUnsavedChanges,
}) => {
  const transformedOptions = options.map((option) => {
    return {
      label: option.label,
      value: `${option.value}`,
    };
  });

  const handleChangeFormat = (format: DurationsGraphFormat) => {
    setFormat(format);
    setUnsavedChanges();
  };

  const handleContinue = () => {
    if (canGoNext) goNextStep();
  };

  return (
    <GeneralStepContainer>
      <LeftPannel>
        <TextContainer>
          <p className="title">{GeneralLeftTitle}</p>
          <p className="description">{GeneralLeftDescription}</p>
        </TextContainer>

        <InputsContainer>
          <InputElement>
            <p className="name">{GeneralLeftNameLabel}</p>
            <div className="input">
              <StringInput
                value={graphName}
                onChange={({ target: { value } }) => {
                  setGraphName(value);
                  setUnsavedChanges();
                }}
                placeholder={GeneralLeftSellsNamePlaceholder}
                autoFocus
              />
            </div>
          </InputElement>

          <InputElement>
            <p className="name">{GeneralLeftProcessLabel}</p>
            <div className="input">
              <GeestSelect
                value={selectedProcesses}
                options={transformedOptions}
                onChange={(options: string) => {
                  setSelectedProcesses(options);
                  cleanOnChangeProcesses();
                  setUnsavedChanges();
                }}
                placeholderSelect={GeneralLeftProcessesPlaceholder}
                multiselect
                showTags
              />
            </div>
          </InputElement>
        </InputsContainer>

        <FormatForm>
          <FormatContainer onClick={() => handleChangeFormat("StackBar")}>
            <GraphFormatAbstraction selected={format === "StackBar"}>
              <img src={Stackbar} alt="" />
            </GraphFormatAbstraction>

            <input
              tabIndex={0}
              type="radio"
              value="StackBar"
              name="stackbarFormat"
              checked={format === "StackBar"}
              style={{ cursor: "pointer" }}
            />
            <p>{GeneralLeftFormatStackbar}</p>
          </FormatContainer>

          <FormatContainer onClick={() => handleChangeFormat("All")}>
            <GraphFormatAbstraction selected={format === "All"}>
              <img src={Stackbar} alt="" className="stack" />
              <img src={Donut} alt="" className="donut" />
            </GraphFormatAbstraction>

            <input
              tabIndex={0}
              type="radio"
              value="All"
              name="allFormat"
              checked={format === "All"}
              style={{ cursor: "pointer" }}
            />
            <p>{GeneralLeftFormatAll}</p>
          </FormatContainer>

          <FormatContainer onClick={() => handleChangeFormat("Doughnut")}>
            <GraphFormatAbstraction selected={format === "Doughnut"}>
              <img src={Donut} alt="" />
            </GraphFormatAbstraction>

            <input
              tabIndex={0}
              type="radio"
              value="Doughnut"
              name="doughnutFormat"
              checked={format === "Doughnut"}
              style={{ cursor: "pointer" }}
            />
            <p>{GeneralLeftFormatDoughnut}</p>
          </FormatContainer>
        </FormatForm>

        <ContinueButtonRow>
          <Button
            type="primary"
            size="large"
            onClick={handleContinue}
            disabled={!canGoNext}
          >
            {Continue}
          </Button>
        </ContinueButtonRow>
      </LeftPannel>

      <RightPannel>
        <PrevisualizationCard>
          <p className="title">{graphName ? graphName : Previsualization}</p>
          <GraphPreviewContainer>
            <DynamicGraphPreview
              selectedDashboard={selectedDashboard}
              selectedTemplate={selectedTemplate}
              selectedProcesses={selectedProcesses}
              format={format}
              graphName={graphName}
              rawFilters={filters}
              groupBy={groupBy}
              xAxisValue={xAxisValue}
              groupByFromExistendGraph={groupByFromExistendGraph}
            />
          </GraphPreviewContainer>
        </PrevisualizationCard>
      </RightPannel>
    </GeneralStepContainer>
  );
};

export default GeneralStep;
