import React, { useRef, useState } from "react";
import _, { noop as NOOP } from "lodash";
import { Popover } from "@material-ui/core";
import { FaCheck } from "react-icons/fa";
import { AssignedUser, User } from "../DetailPending.d";
import { EditorsPopover } from "../Popups";
import {
  CheckmarkContainer,
  TaskMetaDataRow,
  UserContainer,
  UserInitials,
  UserProfilePic,
  UsersListSelected,
} from "../styles";
import Tooltip from "../../../../components/Tooltip";
import { EditIcon } from "../../../../components/hoverIcons";

const userLimit = 3;

interface UserListProps {
  users?: AssignedUser[];
  onChange?: (users: AssignedUser[]) => void;
  onFinishEdit?: () => void;
  teamMembers?: User[];
  type: "executors" | "revisers";
  userCanReassign: boolean;
  resetFilters?: () => void;
  isInRevision?: boolean;
}

const UserList: React.FC<UserListProps> = ({
  users = [],
  teamMembers = [],
  type,
  onChange = NOOP,
  onFinishEdit = NOOP,
  userCanReassign = false,
  resetFilters = NOOP,
  isInRevision = false,
}) => {
  const [editUsers, setEditUsers] = useState(false);

  const editUsersButton = useRef(null);

  const RowTitle = {
    executors: "Encargados",
    revisers: "Revisores",
  };
  const tooltipEdit = {
    executors: "Editar encargados",
    revisers: "Editar revisores",
  };
  const tooltipTexts = {
    executors: "Tarea terminada por este usuario",
    revisers: "Tarea revisada por este usuario",
  };

  return (
    <>
      <TaskMetaDataRow>
        <p>{RowTitle[type]}</p>

        {userCanReassign && (
          <>
            <Popover
              open={editUsers}
              anchorEl={editUsersButton.current}
              onClose={() => {
                setEditUsers(false);
                onFinishEdit();
              }}
              PaperProps={{
                style: {
                  borderRadius: "6px",
                  width: "300px",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <EditorsPopover
                setVisible={setEditUsers}
                type={type}
                users={users}
                teamMembers={teamMembers}
                onChange={onChange}
                userCanReassign={userCanReassign}
                resetFilters={resetFilters}
                onClose={onFinishEdit}
                isInRevision={isInRevision}
              />
            </Popover>
            <Tooltip title={tooltipEdit[type]}>
              <EditIcon
                ref={editUsersButton}
                onClick={() => setEditUsers(true)}
                filled
              />
            </Tooltip>
          </>
        )}
      </TaskMetaDataRow>

      <UsersListSelected>
        {_.slice(users, 0, userLimit).map((user, index) => {
          return (
            <UserContainer key={user.IdUser}>
              <Tooltip
                title={`${user.FirstName} ${user.LastName} (${user.Email})${
                  user.UserTaskStatus === "Completed" && type === "revisers"
                    ? `\n${tooltipTexts[type]}`
                    : ""
                }`}
                placement="bottom"
              >
                {!user.ProfilePicture ? (
                  <UserInitials
                    align="middle"
                    justify="center"
                    $isRejected={
                      user.UserTaskStatus === "Completed" && type === "revisers"
                    }
                    style={{
                      opacity:
                        users.length > userLimit && index === userLimit - 1
                          ? 0.6
                          : 1,
                    }}
                  >
                    {user.Initials}
                    <div
                      style={{
                        position: "absolute",
                        margin: "auto",
                        fontSize: "15px",
                        opacity: 1,
                      }}
                    >
                      {users.length > userLimit &&
                        index === userLimit - 1 &&
                        `+${users.length - userLimit}`}
                    </div>
                  </UserInitials>
                ) : (
                  <UserProfilePic
                    image={user.ProfilePicture}
                    align="middle"
                    justify="center"
                    $isRejected={
                      user.UserTaskStatus === "Completed" && type === "revisers"
                    }
                    style={{
                      opacity:
                        users.length > userLimit && index === userLimit - 1
                          ? 0.6
                          : 1,
                    }}
                  >
                    <div style={{ opacity: 1, color: "#48505e" }}>
                      {users.length > userLimit &&
                        index === userLimit - 1 &&
                        `+${users.length - userLimit}`}
                    </div>
                  </UserProfilePic>
                )}
              </Tooltip>
              {user.UserTaskStatus === "Completed" && type === "executors" && (
                <Tooltip placement="bottom" title={tooltipTexts[type]}>
                  <CheckmarkContainer align="middle" justify="center">
                    <FaCheck size={8} color="#ffffff" />
                  </CheckmarkContainer>
                </Tooltip>
              )}
            </UserContainer>
          );
        })}
      </UsersListSelected>
    </>
  );
};

export default UserList;
