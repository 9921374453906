import React from "react";
import { Languages } from "./Dictionary";
import WelcomeImage from "../../../resources/img/Dashboards/welcome.svg";
import {
  WelcomeContainer,
  WelcomeImageContainer,
  WelcomeInformation,
} from "./style";

const { Greetings, CreateADashboard, SelectATeam, Description } =
  Languages["ESP"];

const Welcome: React.FC<{ selectedTeam: boolean }> = ({ selectedTeam }) => {
  return (
    <WelcomeContainer>
      <WelcomeInformation>
        <p className="greetings">{Greetings.toUpperCase()}</p>
        <p className="title">{selectedTeam ? CreateADashboard : SelectATeam}</p>
        <p>{Description}</p>
      </WelcomeInformation>

      <WelcomeImageContainer>
        <img src={WelcomeImage} alt="" />
      </WelcomeImageContainer>
    </WelcomeContainer>
  );
};

export default Welcome;
