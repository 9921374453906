import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Row } from "antd";
import { useMediaQuery } from "@material-ui/core";
import { device } from "../../constants/css";
import { SignUpContext } from "../../config/SignUpState";
import { useLocation, Link } from "react-router-dom";
import GeestLogoSVG from "../../resources/img/GeestLogo.svg";
import Menu from "../../resources/img/Landing_Móvil_Geest_menú.png";
import Button from "../Button";
import { GeestDestinations } from "../../constants/PlatformDetails";

const urlGeestLogo = (IMG) => `url(${IMG})`;

const Languages = {
  ENG: {
    LoginLabel: "Login",
    SignIn: "Sign in",
  },
  ESP: {
    LoginLabel: "Iniciar sesión",
    SignIn: "Regístrate",
  },
};

const GeestLogo = styled.div`
  height: 45px;
  width: 105px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${urlGeestLogo(GeestLogoSVG)};
  cursor: pointer;
`;

const Container = styled(Row)`
  padding: 20px 1.5rem;
  position: sticky;
`;

const StyledLink = styled(Link)`
  margin: ${({ $menu }) => ($menu ? "0 0 0.5rem 0" : "auto 1.5rem auto 0")};
  color: #0273e9;
  font-size: 14px;
  font-family: "Gotham-bold";
  cursor: pointer;
  :hover {
    color: #014c9b;
  }
`;

const IconMenu = styled.img`
  width: 2rem;
  cursor: pointer;
  background: white;
  :hover {
    background: whitesmoke;
  }
`;

const MenuCont = styled(Row)`
  flex-direction: column;
  width: 100%;
  align-items: center;
  transition: all 0.3s ease;
`;

const BlueButton = styled.button`
  background-color: #0273e9;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  padding: 6px 30px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Gotham-bold";
  :hover {
    background-color: #014c9b;
  }
  :focus {
    background-color: #1939b7;
  }
`;

const LoginHeader = () => {
  const { LoginLabel, SignIn } = Languages["ESP"];
  const { tablet } = device;

  const { pathname } = useLocation();
  const { setIdPlan, setMode } = useContext(SignUpContext);
  const [ShowMenu, setShowMenu] = useState(false);

  const isDesktop = useMediaQuery(tablet);
  const isLandingPage = pathname.includes("/landing-page");
  const registerQueryParams = isLandingPage
    ? `?UserCameFrom=${GeestDestinations.LandingPage}`
    : "";

  useEffect(() => {
    if (isDesktop) setShowMenu(false);
  }, [isDesktop]);

  return (
    <Container justify="center" style={{ backgroundColor: "white" }}>
      <Row justify="space-between" style={{ width: "1400px" }}>
        <Row>
          <a
            href="https://www.geest.app/"
            style={{ marginBottom: "-10px", marginRight: "30px" }}
          >
            <GeestLogo />
          </a>
          {isDesktop && (
            <StyledLink
              to=""
              onClick={() => {
                setShowMenu(false);
                window.open("https://www.geest.app/", "_self");
              }}
              $menu={ShowMenu && !isDesktop}
            >
              Inicio
            </StyledLink>
          )}
        </Row>
        {isDesktop ? (
          <Row>
            <StyledLink to="/auth/login">{LoginLabel}</StyledLink>
            <Link
              to={"/auth/register" + registerQueryParams}
              style={{ margin: "auto 0" }}
            >
              <BlueButton
                onClick={() => {
                  setIdPlan("");
                  setMode("");
                }}
              >
                {SignIn}
              </BlueButton>
            </Link>
          </Row>
        ) : (
          <Row align="middle">
            <IconMenu
              src={Menu}
              alt="Menu"
              onClick={() => setShowMenu(!ShowMenu)}
            />
          </Row>
        )}
        {ShowMenu && (
          <MenuCont>
            <StyledLink
              onClick={() => setShowMenu(false)}
              style={{ marginTop: "0.5rem" }}
              $menu={ShowMenu && !isDesktop}
              to="/auth/login"
            >
              {LoginLabel}
            </StyledLink>
            <Link to={"/auth/register" + registerQueryParams}>
              <Button
                style={{
                  padding: "0.5rem 3rem",
                  fontSize: "20px",
                  fontFamily: "Gotham-Bold",
                }}
                type="geestV1"
                onClick={() => {
                  setIdPlan("");
                  setMode("");
                  setShowMenu(false);
                }}
              >
                {SignIn}
              </Button>
            </Link>
          </MenuCont>
        )}
      </Row>
    </Container>
  );
};

export default LoginHeader;
