import styled from "styled-components";
import { Collapse } from "antd";

export const Panel = styled(Collapse.Panel)`
  & .ant-collapse-content-box {
    padding: 0;
  }
  & .ant-table-row > .ant-table-cell {
    padding: 0 0.5rem !important;
  }
`;
