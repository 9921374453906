import React from "react";
import { StateMachineProps } from "./ProcessDetail.d";
import { BasicInfo, Chat, Fields, Durations, Activity, Flux } from "./States";

const StateMachine: React.FC<StateMachineProps> = ({
  state,
  processInfo,
  reload,
  setShouldOpenUnsaved,
  setState,
}) => {
  switch (state) {
    case "basicInfo":
      return <BasicInfo processInfo={processInfo} reload={reload} />;
    case "flux":
      return <Flux reload={reload} />;
    case "chat":
      return <Chat />;
    case "fields":
      return (
        <Fields
          processInfo={processInfo}
          setShouldOpenUnsaved={setShouldOpenUnsaved}
          reloadProcess={reload}
        />
      );
    case "durations":
      return <Durations reloadProcess={reload} />;
    case "activity":
      return <Activity setState={setState} />;
    default:
      return <></>;
  }
};

export default StateMachine;
