import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../GeestUI";
import SignatureIcon from "../../hoverIcons/SignatureIcon";
import ReactSignatureCanvas from "react-signature-canvas";
import SignGenerator from "../../SignGenerator";
import { DeleteIcon, EditIcon, ViewIcon } from "../../hoverIcons";
import { MessagesContext } from "../../AppMessages";

interface SignatureInputProps {
  value: string;
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  fieldName: string;
  onChange: (newSign: string) => void;
  extraParams?: {
    mb?: string;
  };
}

const InputContainer = styled.div<{ isConsult: boolean; mb?: string }>`
  ${({ isConsult, mb = "10px" }) => (isConsult ? "" : `margin-bottom: ${mb};`)}
  width: 100%;
  * {
    margin: 0;
    padding: 0;
  }
`;

const CollectedSignature = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  background-color: #edecec;
  border-radius: 6px;
  padding: 7.5px 12px;
  font-family: Gotham-Book;

  p {
    margin: 0;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const SignatureInput: React.FC<SignatureInputProps> = ({
  value,
  isConsult,
  disabled,
  required,
  fieldName,
  onChange,
  extraParams = { mb: "10px" },
}) => {
  const [openSignatureModal, setOpenSignatureModal] = useState<boolean>(false);
  const { showMessage } = useContext(MessagesContext);

  const handleOnSave = async (
    canvasRef: React.RefObject<ReactSignatureCanvas>
  ) => {
    const svgData = canvasRef.current?.toDataURL("image/svg+xml");
    onChange(svgData ?? "");
    setOpenSignatureModal(false);

    const dataLength = svgData?.length ?? 0;
    const percent = (dataLength * 100) / 65000;

    if (percent > 100) {
      showMessage("Su firma excede el límite de tinta", "warning");
    }
  };

  const handleOnClear = (canvasRef: React.RefObject<ReactSignatureCanvas>) => {
    canvasRef.current?.clear();
  };

  const handleOnCancel = () => {
    setOpenSignatureModal(false);
  };

  const handleRemoveSignature = () => {
    onChange("");
  };

  return (
    <InputContainer isConsult={isConsult} mb={extraParams.mb}>
      {openSignatureModal && (
        <SignGenerator
          signatureData={value}
          fieldName={fieldName}
          handleOnSave={handleOnSave}
          handleOnClear={handleOnClear}
          handleOnCancel={handleOnCancel}
          disabled={disabled}
        />
      )}
      {Boolean(value) ? (
        <CollectedSignature>
          <p>Firma recabada</p>
          <ButtonsWrapper>
            {disabled ? (
              <ViewIcon filled onClick={() => setOpenSignatureModal(true)} />
            ) : (
              <EditIcon filled onClick={() => setOpenSignatureModal(true)} />
            )}
            {!disabled && <DeleteIcon filled onClick={handleRemoveSignature} />}
          </ButtonsWrapper>
        </CollectedSignature>
      ) : (
        <Button
          type="secondary"
          size="fullwidth"
          danger={required && !Boolean(value)}
          disabled={disabled}
          onClick={() => setOpenSignatureModal(true)}
          // @ts-ignore
          Icon={SignatureIcon}
        >
          Firmar
        </Button>
      )}
    </InputContainer>
  );
};

export default SignatureInput;
