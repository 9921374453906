import React, { useState } from "react";
import { useFetch, useMutation } from "../../../hooks";
import {
  SearchBar,
  StyledInput,
  StateHeader,
  HeaderRow,
  StateContainer,
  TableContainer,
  TableHeader,
  TableCell,
  TableRow,
} from "../styles";
import { Button } from "../../../GeestUI";
import User from "../../../components/User";
import { DeleteIcon } from "../../../components/hoverIcons";
import { Users } from "../Intranet.d";
import { sanitizeSearchValues } from "../../../Helpers";
import { Popover } from "antd";
import { PermissionsPopover } from "./components";
import Loading from "../../../components/NewLoading/NewLoading";

const Permissions: React.FC = () => {
  const [filter, setFilter] = useState<string>("");
  const [emailToAdd, setEmailToAdd] = useState<string>("");
  const { data, reload, loading } = useFetch<Users[]>({
    func: "Ver2-Intranet-Perms-gip",
  });
  const [idUserToEdit, setIdUserToEdit] = useState<number | null>(null);

  const [insertUser] = useMutation({
    func: "Ver2-Intranet-Perms-iu",
    onSuccess: () => {
      reload();
      setEmailToAdd("");
    },
  });

  const [deleteUser] = useMutation({
    func: "Ver2-Intranet-Perms-du",
    onSuccess: reload,
  });

  const submitInsertUser = () => {
    insertUser({
      args: {
        Email: emailToAdd,
      },
    });
  };

  const onDeleteUser = (IdUser: number) => {
    deleteUser({
      args: {
        IdUser,
      },
    });
  };

  const filteredUsers =
    data?.filter((user) =>
      sanitizeSearchValues(
        `${user.FirstName} ${user.LastName} ${user.Email}`
      ).includes(sanitizeSearchValues(filter))
    ) || [];

  if (loading) {
    return (
      <StateContainer>
        <Loading width={200} />
      </StateContainer>
    );
  }

  return (
    <StateContainer>
      <StateHeader>
        <SearchBar
          placeholder="Buscar integrante..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <HeaderRow>
          <StyledInput
            value={emailToAdd}
            onChange={(e) => setEmailToAdd(e.target.value)}
            placeholder="Agregar correo electrónico del nuevo integrante..."
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submitInsertUser();
              }
            }}
          />
          <Button
            type="primary"
            disabled={!emailToAdd}
            onClick={submitInsertUser}
          >
            Agregar
          </Button>
        </HeaderRow>
      </StateHeader>
      <StateContainer style={{ maxWidth: "100%" }}>
        <TableContainer>
          <TableHeader>
            <TableCell $width={358} style={{ justifyContent: "flex-start" }}>
              Integrante
            </TableCell>
            <TableCell $width={368} style={{ justifyContent: "flex-start" }}>
              Correo
            </TableCell>
            <TableCell $width={155}>Permisos</TableCell>
            <TableCell $width={87}>Acciones</TableCell>
          </TableHeader>
          {filteredUsers.map((user) => (
            <TableRow style={{ height: "52px" }} key={user.IdUser}>
              <TableCell $width={358} style={{ justifyContent: "flex-start" }}>
                <User user={user} size="middle" nameStyle="bold" />
              </TableCell>
              <TableCell $width={368} style={{ justifyContent: "flex-start" }}>
                {user.Email}
              </TableCell>
              <TableCell $width={155}>
                <Popover
                  trigger="click"
                  overlayStyle={{
                    borderRadius: "6px",
                    padding: "0",
                    overflow: "hidden",
                    boxShadow: "0px 0px 30px #cfcdcd",
                    width: "385px",
                  }}
                  destroyTooltipOnHide
                  open={idUserToEdit === user.IdUser}
                  onOpenChange={(open) =>
                    open ? setIdUserToEdit(user.IdUser) : setIdUserToEdit(null)
                  }
                  placement="leftTop"
                  content={() => (
                    <PermissionsPopover
                      idUser={user.IdUser}
                      permissions={user.Permissions}
                      firstName={user.FirstName}
                      onClose={() => setIdUserToEdit(null)}
                      reload={reload}
                    />
                  )}
                >
                  <Button type="secondary">Permisos</Button>
                </Popover>
              </TableCell>
              {!user.Permissions.Permission_Lider && (
                <TableCell $width={87}>
                  <DeleteIcon
                    filled
                    hoverContainer
                    onClick={() => onDeleteUser(user.IdUser)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableContainer>
      </StateContainer>
    </StateContainer>
  );
};

export default Permissions;
