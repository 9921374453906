import React, { useState } from "react";
import { SubscriptionLimit } from "./SubscriptionLimit.d";
import SubscriptionLimitModal from "./SubscriptionLimitModal";

export const SubcriptionLimitContext = React.createContext<{
  setSubscriptionLimit: (limit: SubscriptionLimit | null) => void;
}>({
  setSubscriptionLimit: () => {},
});

const LimitContext: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [subscriptionLimit, setSubscriptionLimit] =
    useState<SubscriptionLimit | null>(null);

  return (
    <SubcriptionLimitContext.Provider value={{ setSubscriptionLimit }}>
      {subscriptionLimit && (
        <SubscriptionLimitModal
          SubscriptionLimit={subscriptionLimit}
          onClose={() => setSubscriptionLimit(null)}
        />
      )}
      {children}
    </SubcriptionLimitContext.Provider>
  );
};

export default LimitContext;
