import moment from "moment";

export const Languages = {
  ENG: {
    PrivacyNotice: "Privacy notice",
    TermsAndCond: "Terms and condition",
    RightsReserved: `${moment().format(
      "YYYY"
    )} GC Productividad S.C. All rights reserved`,
  },
  ESP: {
    PrivacyNotice: "Aviso de privacidad",
    TermsAndCond: "Términos y condiciones",
    RightsReserved: `${moment().format(
      "YYYY"
    )} GC Productividad S.C. Todos los derechos reservados`,
  },
};
