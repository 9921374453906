import React from "react";
import { Row } from "antd";
import { TemplateDetailProps } from "./CreateProcess.d";
import {
  TemplateTitle,
  TemplateCategory,
  TemplateDetailDescription,
  BoldLabel,
} from "./styles";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { Button } from "../../../GeestUI";
import videoPlaceholder from "../../../resources/img/Video_Placeholder.svg";
import { Languages } from "../Dictionary";
import processFridayIcon from "../../../resources/img/ViernesDeProcesosIcon.svg";

const TemplateDetail: React.FC<TemplateDetailProps> = ({
  selectedTemplate,
  setActiveStep,
  selectedCategory,
  onInstallTemplate,
}) => {
  const { InstalTemplateLabel } = Languages["ESP"];
  return (
    <Row
      style={{ width: "100%", height: "500px" }}
      align="middle"
      justify="center"
    >
      <Row
        style={{ width: "717px", height: "319px" }}
        align="middle"
        justify="space-between"
      >
        <div style={{ width: "364px", height: "258px" }}>
          <TemplateTitle>
            <IoIosArrowBack
              size={25}
              onClick={() => setActiveStep(0)}
              style={{ cursor: "pointer" }}
            />
            {selectedTemplate?.Name}
          </TemplateTitle>
          <TemplateCategory>{selectedCategory}</TemplateCategory>
          <TemplateDetailDescription>
            {selectedTemplate?.Description}
          </TemplateDetailDescription>
          <Button
            type="primary"
            size="large"
            Icon={AiOutlinePlus}
            style={{ width: "218px" }}
            onClick={() => onInstallTemplate(selectedTemplate)}
          >
            {InstalTemplateLabel}
          </Button>
        </div>
        <div>
          {selectedTemplate?.Video ? (
            <iframe
              style={{
                width: "319px",
                height: "200px",
              }}
              src={selectedTemplate?.Video}
              title="video"
              allowFullScreen
              allow="autoplay"
            />
          ) : (
            <img
              src={videoPlaceholder}
              alt="video"
              style={{
                width: "319px",
                height: "240px",
              }}
            />
          )}
          <Row style={{ width: "319px", marginTop: "1rem" }} justify="center">
            <Row
              style={{ width: "225px", cursor: "pointer" }}
              justify="space-between"
              align="middle"
              onClick={() =>
                window.open("https://viernesdeprocesos.geest.app/episodes")
              }
            >
              <BoldLabel>Ver más casos en</BoldLabel>
              <img
                src={processFridayIcon}
                width="100px"
                height="32px"
                alt="Viernes de procesos"
              />
            </Row>
          </Row>
        </div>
      </Row>
    </Row>
  );
};

export default TemplateDetail;
