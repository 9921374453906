import textIcon from "../../resources/img/DataType_48505E/string.svg";
import imageIcon from "../../resources/img/DataType_48505E/image.svg";
import signIcon from "../../resources/img/DataType_48505E/sign.svg";

const TypeIcons: {
  [key: string]: {
    icon: string;
    extraStyles: { [key: string]: any };
  };
} = {
  text: {
    icon: textIcon,
    extraStyles: {
      width: "20px",
    },
  },
  image: {
    icon: imageIcon,
    extraStyles: {},
  },
  sign: {
    icon: signIcon,
    extraStyles: {},
  },
};

export default TypeIcons;
