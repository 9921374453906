import React from "react";
import { Container } from "./styles";
import AddNode from "./AddNode";
import EditStages from "./EditStages";
import EditChats from "./EditChats";
import EditFields from "./EditFields";

interface FlowActionsProps {
  openStages: boolean;
  setOpenStages: React.Dispatch<React.SetStateAction<boolean>>;
  openChats: boolean;
  setOpenChats: React.Dispatch<React.SetStateAction<boolean>>;
}

const FlowActions: React.FC<FlowActionsProps> = ({
  openStages,
  openChats,
  setOpenChats,
  setOpenStages,
}) => {
  return (
    <Container size={[12, 0]}>
      <AddNode />
      <EditStages open={openStages} setOpen={setOpenStages} />
      <EditChats open={openChats} setOpen={setOpenChats} />
      <EditFields />
    </Container>
  );
};

export default FlowActions;
