import React from "react";
import { Row } from "antd";
import { TaskPriority } from "../TaskEditor.d";
import { StyledTag } from "../styles";
import { EditIcon } from "../../../../../../components/hoverIcons";
import { ItemContainer } from "./styles";

interface PriorityItemProps {
  priority: TaskPriority;
  isSelected: boolean;
  onEdit: (priority: TaskPriority) => void;
  onSelect: (priority: TaskPriority) => void;
}

const PriorityItem: React.FC<PriorityItemProps> = ({
  priority,
  isSelected,
  onEdit,
  onSelect,
}) => {
  const handleOnEdit: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    onEdit(priority);
  };
  const handleOnClick: React.MouseEventHandler<HTMLDivElement> = (e) =>
    onSelect(priority);
  return (
    <Row align="middle" onClick={handleOnClick}>
      <ItemContainer $isSelected={isSelected}>
        <StyledTag color={priority.Color}>{priority.Title}</StyledTag>
      </ItemContainer>
      <EditIcon filled onClick={handleOnEdit} />
    </Row>
  );
};

export default PriorityItem;
