import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { HashRouter as Router } from "react-router-dom";

import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import "./index.css";
import "antd/dist/antd.min.css";
import "react-day-picker/lib/style.css";
import "leaflet/dist/leaflet.css";
import "reactflow/dist/style.css";

import { State } from "./config/State";
import { Redirect } from "./config/Redirect";
import { KeyStripe } from "./constants/PlatformDetails";
import { SignUpState } from "./config/SignUpState";

import App from "./App";
import ErrorBoundary from "./hoc/ErrorBoundary";
import { VersionCode } from "./components/GeestVersion/GeestVersion";

const stripePromise = loadStripe(KeyStripe);

const THEME = createTheme({
  typography: {
    fontFamily: `"Gotham-book", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
});

Bugsnag.start({
  apiKey: "ed31ffa7c1adca45938b6905af58be98",
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
  enabledReleaseStages: ["production", "staging"],
  appVersion: VersionCode,
});

ReactDOM.render(
  <Redirect>
    <State>
      <Elements stripe={stripePromise}>
        <MuiThemeProvider theme={THEME}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router>
              <ErrorBoundary>
                <SignUpState>
                  <App />
                </SignUpState>
              </ErrorBoundary>
            </Router>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Elements>
    </State>
  </Redirect>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
