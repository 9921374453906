import React, { useState } from "react";
import { Checkbox } from "antd";
import styled from "styled-components";
import { DeleteProcessModalProps } from "./DeleteProcessModal.d";
import Modal from "../../../../components/Modal";
import { Button } from "../../../../GeestUI";
import { useMutation, useFetch } from "../../../../hooks";

const Title = styled.div`
  width: 352px;
  font-size: 14px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  text-align: center;
`;

const Content = styled.div`
  width: 394px;
  font-size: 14px;
  color: rgba(130, 141, 158, 1);
  font-weight: 325;
  font-family: Gotham-Book;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FormControl = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  width: 30px;
`;

const StyledCheckbox = styled(Checkbox)`
  font-family: Gotham-Book;

  .ant-checkbox {
    max-width: 100%;
    width: 16px;

    .ant-checkbox-inner {
      max-width: 100% !important;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      max-width: 100% !important;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0273e9;
  }
`;

const CheckboxLabel = styled.p`
  margin: 0;
  font-size: 12px;
  color: #828d9e;
`;

const ButtonConteiner = styled.div`
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: center;
  display: flex;
`;

const DeleteProcessModal: React.FC<DeleteProcessModalProps> = ({
  selectedProcesses,
  onSuccess,
  onCancel,
  ProcessTitle,
  processes = [],
}) => {
  const [deleteVarDBs, setDeleteVarDBs] = useState(false);

  const [deleteProcessExecution, deleting] = useMutation<[]>({
    func: "Ver2-Reports-dpe",
    onSuccess: () => onSuccess(),
  });

  const { data } = useFetch<{ hasVardbRowLinked: boolean }>({
    func: "Ver2-Reports-phvrl",
    args: { IdProcessExecution: selectedProcesses },
  });

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () =>
    deleteProcessExecution({
      args: {
        IdProcessExecution: selectedProcesses,
        DeleteLinkedRows: deleteVarDBs,
      },
    });

  const getProcessTitle = (selectedProcesses: number[]) => {
    if (ProcessTitle) return ProcessTitle;

    let title = "";

    if (selectedProcesses.length === 1) {
      const process = processes.find(
        (row) => row.IdProcessExecution === selectedProcesses[0]
      );
      if (process) {
        Object.keys(process.Cells).forEach((key: string) => {
          if (key.includes("ExecutionName")) {
            // @ts-ignore
            const cell = process.Cells[key];
            title = cell.CellData.String_Value;
          }
        });
      }
    }
    return title;
  };

  return (
    <Modal
      visible
      title={`Eliminar${
        selectedProcesses.length > 1 ? ` ${selectedProcesses.length}` : ""
      } seguimiento${
        selectedProcesses.length > 1
          ? "s"
          : ` ${getProcessTitle(selectedProcesses)}`
      } `}
      width="442px"
      onCancel={onCancel}
      bodyStyle={{
        borderRadius: "6px",
      }}
      contentStyles={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
      }}
    >
      <Title>¿Seguro que deseas eliminar este seguimiento?</Title>

      <Content>
        Se eliminará todo rastro de este proceso junto con su información y ya
        no será consultable en el sistema. Esta acción no se puede revertir
      </Content>

      {data?.hasVardbRowLinked && (
        <FormControl
          onClick={(e) => {
            e.stopPropagation();
            setDeleteVarDBs(!deleteVarDBs);
          }}
        >
          <StyledCheckboxContainer>
            <StyledCheckbox
              checked={deleteVarDBs}
              onClick={(e) => {
                e.stopPropagation();
                setDeleteVarDBs(!deleteVarDBs);
              }}
            />
          </StyledCheckboxContainer>

          <CheckboxLabel>
            Eliminar también la info vinculada de las bases de datos
          </CheckboxLabel>
        </FormControl>
      )}

      <ButtonConteiner>
        <Button type="secondary" onClick={onCancel} loading={deleting}>
          Cancelar
        </Button>
        <Button type="primary" danger onClick={onClick} loading={deleting}>
          Eliminar
        </Button>
      </ButtonConteiner>
    </Modal>
  );
};

export default DeleteProcessModal;
