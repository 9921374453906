export const Languages = {
  ENG: {
    PasswordNotMatchError: "The new password doesn't match",
    EmptyFieldsError: "There are empty fields",
    PasswordChangedMessage: "Password changed successfully",
    CurrentPasswordLabel: "Current password",
    NewPasswordLabel: "New password",
    RepeatPasswordLabel: "Repeat password",
    CancelLabel: "Cancel",
    SaveLabel: "Save",
    ChangePasswordLabel: "Change password",
    OnlyImages: "You can only upload JPG/PNG files",
    FileLimit: (Limit) => `Image must be less than ${Limit}MB`,
    InvalidFileName: "File name is not valid",
    NeedHelpLabel: "Need help?",
    TopicLabel: "Topic",
    HowHelpLabel: "What can we help you with?",
    AddFileLabel: "Add file...",
    SendLabel: "Send",
    MySubscriptionLabel: "My subscription",
    HelpLabel: "Help",
    LogoutLabel: "Logout",
    SubjectError: 'Missing fill the field "Subject"',
    DescError: 'Missing fill the field "Description"',
    SendWhats: "Send WhatsApp",
    TermsAndCondsLabel: "Terms and conditions",
    PrivacyNoticeLabel: "Privacy notice",
    Settings: "Settings",
  },
  ESP: {
    PasswordNotMatchError: "La nueva contraseña no coincide",
    EmptyFieldsError: "Hay campos vacíos",
    PasswordChangedMessage: "Contraseña cambiada correctamente",
    CurrentPasswordLabel: "Contraseña actual",
    NewPasswordLabel: "Nueva contraseña",
    RepeatPasswordLabel: "Repetir contraseña",
    CancelLabel: "Cancelar",
    SaveLabel: "Guardar",
    ChangePasswordLabel: "Cambiar contraseña",
    OnlyImages: "Sólo puedes subir archivos JPG/PNG",
    FileLimit: (Limit) => `La imagen debe ser menor a ${Limit}MB`,
    InvalidFileName: "El nombre del archivo no es válido",
    NeedHelpLabel: "¿Necesitas ayuda?",
    TopicLabel: "Asunto",
    HowHelpLabel: "¿En qué podemos ayudarte?",
    AddFileLabel: "Adjuntar un Archivo...",
    SendLabel: "Enviar",
    MySubscriptionLabel: "Mi suscripción",
    HelpLabel: "Ayuda",
    LogoutLabel: "Cerrar sesión",
    SubjectError: 'Falta llenar el campo "Asunto"',
    DescError: 'Falta llenar el campo "Descripción"',
    SendWhats: "Enviar WhatsApp",
    TermsAndCondsLabel: "Términos y condiciones",
    PrivacyNoticeLabel: "Aviso de privacidad",
    Settings: "Ajustes",
  },
};
