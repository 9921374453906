export const Languages = {
  ENG: {
    ModalTitleLabel: "Permissions",
    PermissionDescription: {
      No_Access: "Will not be able to view the dashboard",
      Just_View: "Will be able to see dashboard detail",
      Edit_Graphs: "Can configure the graphs within a dashboard",
      Configure_Dashboard:
        "You will be able to add, move and delete graphs. Share, delete and modify dashboard permissions",
    },
  },
  ESP: {
    ModalTitleLabel: "Permisos",
    PermissionDescription: {
      No_Access: "No podrá visualizar el dashboard",
      Just_View: "Podrá ver detalle de dashboard",
      Edit_Graphs: "Podrá configurar las gráficas dentro de un dashboard",
      Configure_Dashboard:
        "Podrá agregar, mover y elminar gráficas. Compartir, elimminar y modificar los permisos del dashboard",
    },
  },
};
