import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import { DatePicker } from "@material-ui/pickers";
import { CalendarToday } from "@material-ui/icons";
import { Languages } from "../Durations/Dictionary";
import GeestSelect from "../../../../../GeestUI/GeestSelect/GeestSelect";
import { FilterOption, Option } from "../../../Dashboards.d";

const InputWrapper = styled.div<{ flexDirection: string }>`
  display: flex;
  gap: 8px;
  flex-direction: ${({ flexDirection = "row" }) => flexDirection};
`;

const DatesWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const DatePickerStyled = styled(DatePicker)`
  .Mui-disabled {
    cursor: auto;
  }
  .Mui-focused {
    border-color: #48505e !important;
  }
  .MuiInput-formControl {
    :hover {
      border-color: #0273e9 !important;
      box-shadow: none !important;
    }
    :focus {
      border-color: #48505e !important;
      box-shadow: none !important;
    }
    :focus-visible {
      outline: none !important;
    }
  }
`;

interface FilterSelectProps {
  editingFilter: boolean;
  filterData: FilterOption | null;
  onChangeFilter: (filter: FilterOption) => void;
  dateOptions: Option[];
}

const FilterSelect: React.FC<FilterSelectProps> = ({
  editingFilter,
  filterData,
  onChangeFilter,
  dateOptions,
}) => {
  const { DatePickerPlaceholder, SelectPlaceholder } = Languages["ESP"];
  const [selectValue, setSelectValue] = useState<string>(
    filterData?.Value ?? ""
  );
  const [startDate, setStartDate] = useState<string | null>(
    filterData?.Start ? filterData?.Start : null
  );
  const [endDate, setEndDate] = useState<string | null>(
    filterData?.End ? filterData?.End : null
  );

  useEffect(() => {
    if (filterData?.Value === undefined) {
      setSelectValue("");
    }
  }, [filterData]);

  const getOptions = (): Option[] => {
    if (filterData?.DataOrigin === "DateOptions") {
      return dateOptions;
    }
    return [];
  };

  const renderDateRangePicker =
    selectValue === "Personalized" && filterData?.DataOrigin === "DateOptions";

  const handleOnChangeSelect = (option: string) => {
    setSelectValue(option);

    let newFilter: FilterOption = {
      ...(filterData as FilterOption),
      Value: option,
    };

    if (filterData?.DataOrigin === "DateOptions" && option === "Personalized") {
      const tempYesterdayDate = moment().subtract(1, "days");
      const tempTodayDate = moment();

      newFilter = {
        ...newFilter,
        Start: moment(tempYesterdayDate).format("YYYY-MM-DD"),
        End: moment(tempTodayDate).format("YYYY-MM-DD"),
      };
      setStartDate(moment(tempYesterdayDate).format("YYYY-MM-DD"));
      setEndDate(moment(tempTodayDate).format("YYYY-MM-DD"));
    } else {
      delete newFilter.Start;
      delete newFilter.End;
      setStartDate(null);
      setEndDate(null);
    }

    onChangeFilter(newFilter);
  };

  const onChangeStartDate = (newDate: any) => {
    let newFilter: FilterOption = {
      ...(filterData as FilterOption),
    };

    if (_.isEmpty(newDate)) {
      setStartDate(null);
      delete newFilter.Start;
      onChangeFilter(newFilter);
      return;
    }

    setStartDate(newDate.format("YYYY-MM-DD"));
    newFilter = {
      ...newFilter,
      Start: newDate.format("YYYY-MM-DD"),
    };

    if (endDate && moment(newDate).diff(endDate) > 0) {
      const newEndDate = moment(newDate).add(1, "days");
      setEndDate(newEndDate.format("YYYY-MM-DD"));
      newFilter = {
        ...newFilter,
        End: newEndDate.format("YYYY-MM-DD"),
      };
    }

    onChangeFilter(newFilter);
  };

  const onChangeEndDate = (newDate: any) => {
    let newFilter: FilterOption = {
      ...(filterData as FilterOption),
    };

    if (_.isEmpty(newDate)) {
      setEndDate(null);
      delete newFilter.End;
      onChangeFilter(newFilter);
      return;
    }

    setEndDate(newDate.format("YYYY-MM-DD"));
    newFilter = {
      ...newFilter,
      End: newDate.format("YYYY-MM-DD"),
    };

    onChangeFilter(newFilter);
  };

  return (
    <InputWrapper
      flexDirection={editingFilter && renderDateRangePicker ? "column" : "row"}
    >
      <GeestSelect
        value={selectValue}
        options={getOptions()}
        onChange={(option) => handleOnChangeSelect(option)}
        $width="188px"
        $listWidth="188px"
        placeholderSelect={SelectPlaceholder}
        valueNecesary
      />
      {editingFilter && renderDateRangePicker && (
        <DatesWrapper>
          <DatePickerStyled
            value={startDate ? moment(startDate) : null}
            variant="dialog"
            format="DD/MMM/YY"
            onChange={(newDate: any) => onChangeStartDate(newDate)}
            fullWidth
            InputProps={{
              disableUnderline: true,
              placeholder: DatePickerPlaceholder,
              endAdornment: (
                <CalendarToday
                  style={{
                    color: "#48505e",
                    width: "15px",
                    height: "15px",
                    marginRight: "5px",
                  }}
                />
              ),
              style: {
                width: "150px",
                border: "2px solid",
                borderColor: "#edecec",
                borderRadius: "6px",
                color: "#828d9e",
                background: "white",
                height: "32px",
                paddingLeft: "5px",
                fontSize: "14px",
                transition: "all 0.35s ease",
              },
            }}
          />

          <DatePickerStyled
            value={endDate ? moment(endDate) : null}
            minDate={startDate ? moment(startDate) : null}
            variant="dialog"
            format="DD/MMM/YY"
            onChange={(newDate: any) => onChangeEndDate(newDate)}
            fullWidth
            InputProps={{
              disableUnderline: true,
              placeholder: DatePickerPlaceholder,
              endAdornment: (
                <CalendarToday
                  style={{
                    color: "#48505e",
                    width: "15px",
                    height: "15px",
                    marginRight: "5px",
                  }}
                />
              ),
              style: {
                width: "150px",
                border: "2px solid",
                borderColor: "#edecec",
                borderRadius: "6px",
                color: "#828d9e",
                background: "white",
                height: "32px",
                paddingLeft: "5px",
                fontSize: "14px",
                transition: "all 0.35s ease",
              },
            }}
          />
        </DatesWrapper>
      )}
    </InputWrapper>
  );
};

export default FilterSelect;
