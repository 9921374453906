import React, { useRef, useState } from "react";
import { BsLightningFill } from "react-icons/bs";
import { Handle, NodeProps } from "reactflow";
import styled from "styled-components";
import { Tooltip } from "antd";
import {
  defaultHandlers,
  filterTargetHandlers,
  useIsConnecting,
} from "./shared/shared";
import { border, handlerStyles, Rectangle } from "./shared/styles";
import { NodeData } from "../Flow.d";
import Toolbox from "./Toolbox/Toolbox";
import { ToolboxActions } from "./Toolbox/Toolbox.d";
import ConfirmDeletion from "./shared/ConfirmDeletion";
import { useElementOverflow } from "../../../../hooks";

const Top = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  overflow: hidden;
  padding: 0 12px;
`;
const Bottom = styled(Top)`
  border-top: ${border};
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
`;
const Text = styled.span`
  margin: auto;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const AutomationNode: React.FC<NodeProps<NodeData>> = ({
  data,
  id,
  selected,
}) => {
  const isConnecting = useIsConnecting();
  const { label, deleting, onAction, onDeleteAttempt, onAbortDelete } = data;
  const showConfirmDeletion = deleting && selected;

  const textRef = useRef<HTMLSpanElement>(null);
  const isOverflow = useElementOverflow(textRef, "width");
  const [openTooltip, setOpenTooltip] = useState(false);

  return (
    <ConfirmDeletion
      visible={showConfirmDeletion}
      onConfirm={() => onAction(ToolboxActions.Delete, id)}
      onCancel={onAbortDelete}
      toolboxOffset
    >
      <Rectangle $isSelected={selected}>
        <Top>
          <Tooltip
            title={label}
            open={openTooltip && isOverflow}
            onOpenChange={setOpenTooltip}
            overlayInnerStyle={{
              borderRadius: "2px",
              padding: "6px 8px",
            }}
          >
            <Text ref={textRef}>{label}</Text>
          </Tooltip>
        </Top>
        <Bottom>
          <BsLightningFill
            color="#0273e9"
            size={18}
            style={{ margin: "auto" }}
          />
        </Bottom>
        {filterTargetHandlers(defaultHandlers, isConnecting).map((handler) => (
          <Handle style={handlerStyles} key={handler.id} {...handler} />
        ))}
        <Toolbox
          nodeId={id}
          onAction={onAction}
          onDeleteAttempt={onDeleteAttempt}
        />
      </Rectangle>
    </ConfirmDeletion>
  );
};

export default AutomationNode;
