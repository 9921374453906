import React, { useContext, useState, useRef, useEffect } from "react";
import _ from "lodash";
import { nanoid } from "nanoid";
import { AiOutlinePlus } from "react-icons/ai";
import { FieldDataOrigin } from "../../../EditField.d";
import { MessagesContext } from "../../../../../../../../components/AppMessages";
import {
  DataOriginContainer,
  DataOriginOptionsContainer,
  NewOptionContainer,
  StringInput,
} from "./Styles";
import TagOption from "./TagOption";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { Button } from "../../../../../../../../GeestUI";

interface DataOriginConfigProps {
  fieldDataOrigin: FieldDataOrigin[];
  setFieldDataOrigin: (newDataOrigin: FieldDataOrigin[]) => void;
  disabled: boolean;
}

const DataOriginConfig: React.FC<DataOriginConfigProps> = ({
  fieldDataOrigin,
  setFieldDataOrigin,
  disabled,
}) => {
  const [newOptionLabel, setNewOptionLabel] = useState<string>("");
  const [editingOption, setEditingOption] = useState<number | null>(null);
  const { showMessage } = useContext(MessagesContext);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!disabled) inputRef?.current?.focus();
  }, [disabled]);

  const handleAddOption = () => {
    const newOption = _.trim(newOptionLabel);
    if (newOption === "") {
      showMessage("No puede haber una opción vacía", "warning");
      setNewOptionLabel("");
      return;
    }
    const indexedDataOrigin = fieldDataOrigin.reduce(
      (acc: { [label: string]: FieldDataOrigin }, el) => {
        acc[el.label.toLowerCase()] = el;
        return acc;
      },
      {}
    );
    if (newOption.toLowerCase() in indexedDataOrigin) {
      showMessage("Ya existe una opción con ese valor", "warning");
      setNewOptionLabel("");
      return;
    }
    let newDO = [...fieldDataOrigin, { label: newOption }];
    setFieldDataOrigin(newDO);
    setNewOptionLabel("");
    inputRef?.current?.focus();
  };

  const handleDeleteOption = (optionIdx: number) => {
    let newDataOrigin = _.cloneDeep(fieldDataOrigin);
    newDataOrigin.splice(optionIdx, 1);
    setFieldDataOrigin(newDataOrigin);
  };

  const handleUpdateOption = (newLabel: string) => {
    if (editingOption === null) return;
    const newOptionLabel = _.trim(newLabel);
    if (newOptionLabel === "") {
      showMessage("No puede haber una opción vacía", "warning");
      setNewOptionLabel("");
      return;
    }
    if (newOptionLabel === fieldDataOrigin[editingOption].label) return;
    const indexedDataOrigin = fieldDataOrigin.reduce(
      (acc: { [label: string]: FieldDataOrigin }, el) => {
        acc[el.label.toLowerCase()] = el;
        return acc;
      },
      {}
    );
    if (newOptionLabel.toLowerCase() in indexedDataOrigin) {
      showMessage("Ya existe una opción con ese valor", "warning");
      setEditingOption(null);
      return;
    }
    let newDataOrigin = _.cloneDeep(fieldDataOrigin);
    newDataOrigin[editingOption] = {
      ...newDataOrigin[editingOption],
      label: newOptionLabel,
    };
    setFieldDataOrigin(newDataOrigin);
    setEditingOption(null);
  };

  const reorder = (startIndex: number, endIndex: number): FieldDataOrigin[] => {
    const result = Array.from(fieldDataOrigin);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const newDataOrigin = reorder(
      result.source.index,
      result.destination.index
    );
    setFieldDataOrigin(newDataOrigin);
  };

  return (
    <DataOriginContainer>
      <NewOptionContainer>
        <StringInput
          disabled={disabled}
          value={newOptionLabel}
          onChange={({ target: { value } }) => setNewOptionLabel(value)}
          onKeyDown={({ key }) => {
            if (key === "Enter") handleAddOption();
          }}
          ref={inputRef}
          placeholder="Escribe una opción..."
        />
        <Button
          onClick={handleAddOption}
          disabled={disabled}
          Icon={AiOutlinePlus}
        >
          Agregar
        </Button>
      </NewOptionContainer>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="1" direction="horizontal">
          {(provided) => (
            <DataOriginOptionsContainer
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fieldDataOrigin.map((dataOrigin, index) => (
                <TagOption
                  disabled={disabled}
                  key={nanoid()}
                  draggableId={dataOrigin.label}
                  index={index}
                  label={dataOrigin.label}
                  editingOption={index === editingOption}
                  unsetEditingOption={() => setEditingOption(null)}
                  setEditingOption={() => setEditingOption(index)}
                  onUpdateOption={(newLabel) => handleUpdateOption(newLabel)}
                  onClose={() => handleDeleteOption(index)}
                />
              ))}
              {provided.placeholder}
            </DataOriginOptionsContainer>
          )}
        </Droppable>
      </DragDropContext>
    </DataOriginContainer>
  );
};

export default DataOriginConfig;
