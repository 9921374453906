import { Drawer } from "antd";
import styled from "styled-components";

// <SubfluxEditor />

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    background: #f2f8fe;
    padding: 0;
  }
`;

export const StyledBody = styled.div`
  padding: 24px;
`;
