import { Space, Row } from "antd";
import styled, { css } from "styled-components";

export const Container = styled(Space)`
  margin: 12px;
`;

export const DropdownButton = styled(Row)<{ open: boolean }>`
  border-radius: 6px;
  border: 2px solid #edecec;
  padding: 5px 8px;
  cursor: pointer;
  :hover {
    border-color: #0273e9;
    ${({ open }) =>
      open &&
      css`
        border-color: #48505e;
      `}
  }
  ${({ open }) =>
    open &&
    css`
      border-color: #48505e;
    `}
`;

export const DropdownButtonLabel = styled.span`
  font-family: "Gotham-bold";
  font-size: 14px;
  margin-right: 8px;
`;
