import React, { useCallback, useEffect } from "react";
import { ImageWrapper } from "./Styles";

interface PreviewImgProps {
  PreviewURL: string;
  handleEsc: () => void;
}

const PreviewImg: React.FC<PreviewImgProps> = ({ PreviewURL, handleEsc }) => {
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      handleEsc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <ImageWrapper className="swiper-no-swiping">
      <img src={PreviewURL} alt="Preview" />
    </ImageWrapper>
  );
};

export default PreviewImg;
