import React from "react";
import styled from "styled-components";

const StyledImg = styled.div`
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${({ $size }) => $size};
  background-image: ${({ $img }) => $img};
  cursor: ${({ $cursor }) => $cursor};
`;

const Img = ({
  img,
  size = "cover",
  cursor = "pointer",
  onClick = () => {},
  ...props
}) => {
  return (
    <StyledImg
      onClick={onClick}
      $size={size}
      $cursor={cursor}
      $img={`url(${img})`}
      {...props}
    />
  );
};

export default Img;
