import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { languages } from "../Controls/dicitonary";
import { CloseIcon } from "../../hoverIcons";
import { Button } from "../../../GeestUI";

const StyledModal = styled(Modal)`
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

const Title = styled.div`
  flex: 1;
  overflow: hidden;
  font-size: 16px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  font-size: 14px;
`;

const Paragraph = styled.p`
  margin: 0;
  text-align: center;
  color: #828d9e;
  padding: 0 18px;
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

interface UnsavedChangesProps {
  discardChanges: () => void;
  saveChanges: () => void;
  closeModal: () => void;
}

const UnsavedChanges: React.FC<UnsavedChangesProps> = ({
  discardChanges,
  saveChanges,
  closeModal,
}) => {
  const {
    UnsavedChangesTitle,
    UnsavedChangesDescription,
    DiscardChangesLabel,
    SaveChangesLabel,
  } = languages["ESP"];

  return (
    <StyledModal
      open
      footer={null}
      closable={false}
      centered
      width="385px"
      onCancel={closeModal}
      bodyStyle={{
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Gotham-Book",
        padding: "12px",
      }}
    >
      <Header>
        <Title>{UnsavedChangesTitle}</Title>
        <CloseIcon size={20} onClick={closeModal} color="#48505e" />
      </Header>
      <Content>
        <Paragraph>{UnsavedChangesDescription}</Paragraph>
        <ButtonsRow>
          <Button type="secondary" onClick={discardChanges}>
            {DiscardChangesLabel}
          </Button>
          <Button type="primary" onClick={saveChanges}>
            {SaveChangesLabel}
          </Button>
        </ButtonsRow>
      </Content>
    </StyledModal>
  );
};

export default UnsavedChanges;
