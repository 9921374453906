import React from "react";
import styled from "styled-components";
import { ReactComponent as ImportIconOutlined } from "../../resources/img/importIconOutline.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ImportIconProps {
  $width?: string;
  $height?: string;
}

const ImportIcon: React.FC<ImportIconProps> = ({
  $width = "14px",
  $height = "14px",
}) => {
  return (
    <IconWrapper>
      <ImportIconOutlined width={$width} height={$height} />
    </IconWrapper>
  );
};

export default ImportIcon;
