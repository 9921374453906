import React, { useContext, useState } from "react";
import { Row } from "antd";
import { Button } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";
import { Languages } from "./Dictionary";
import { TeamMember } from "../../Teams.d";
import { MessagesContext } from "../../../../components/AppMessages";
import styled from "styled-components";
import { RoleTitle } from "../MembersList/Style";
import { CloseIcon } from "../../../../components/hoverIcons";
import GeestSelect from "../../../../GeestUI/GeestSelect";
import UsersSelectDT from "../../../../resources/img/userIcon.svg";
import User from "../../../../components/User";

const Text = styled.div`
  text-align: center;
  font-size: 14px;
  font-family: Gotham-Book;
  width: 309px;
  color: #828d9e;
`;

const Container = styled.div`
  display: flex;
  wdith: 400px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 0px 10px;
  margin-top: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 14px;
`;
const { UserLabel, SuccessOnReasign } = Languages["ESP"];

interface ReasignTasksPopoverProps {
  idTeam: string;
  user: TeamMember | null;
  teamMembers: TeamMember[];
  title: string;
  okText: string;
  cancelText: string;
  onClose: () => void;
  reload: () => void;
}

const ReasignTasksPopover: React.FC<ReasignTasksPopoverProps> = ({
  idTeam,
  user,
  teamMembers,
  title,
  okText,
  cancelText,
  onClose,
  reload,
}) => {
  const [userIdToReasign, setUserIdToReasign] = useState<number | null>(null);
  const { showMessage } = useContext(MessagesContext);

  const [reasignUserInTeam, reasignin] = useMutation<[]>({
    func: "Ver2-Team-rauit",
    onSuccess: () => {
      showMessage(SuccessOnReasign, "success");
      onClose();
      reload();
    },
  });

  const handleReasignTasks = () => {
    if (!userIdToReasign || userIdToReasign === user?.IdUser) return;
    reasignUserInTeam({
      args: {
        IdTeam: idTeam,
        IdOldUser: user?.IdUser,
        IdNewUser: userIdToReasign,
      },
    });
  };

  const options = (teamMembers || []).map((member) => ({
    label: member.IdUser !== user?.IdUser && (
      <User user={member} size="middle" />
    ),
    value: member.IdUser,
    searchableValues: `${member.FirstName},${member.LastName},${member.Email}`,
  }));

  return (
    <div style={{ width: "400px" }}>
      <Row justify="space-between" align="middle">
        <RoleTitle
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "calc(100% - 30px)",
          }}
        >
          {title}
        </RoleTitle>
        <CloseIcon onClick={onClose} />
      </Row>
      <Container>
        <Text>{UserLabel}</Text>
        <GeestSelect
          placeholderSelect={
            <Row justify="start" align="middle">
              <img
                style={{
                  width: "14px",
                  height: "14px",
                }}
                src={UsersSelectDT}
                alt="user"
              />
              <Text style={{ textAlign: "left", marginLeft: "10px" }}>
                Selección de usuarios
              </Text>
            </Row>
          }
          value={userIdToReasign}
          onChange={setUserIdToReasign}
          options={options}
        />
        <ButtonContainer>
          <Button onClick={onClose} loading={reasignin} type="secondary">
            {cancelText}
          </Button>
          <Button
            onClick={handleReasignTasks}
            loading={reasignin}
            disabled={!userIdToReasign || userIdToReasign === user?.IdUser}
            type="primary"
          >
            {okText}
          </Button>
        </ButtonContainer>
      </Container>
    </div>
  );
};

export default ReasignTasksPopover;
