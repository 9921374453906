import styled from "styled-components";
import { Row } from "antd";

export const Container = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px #00000066;
  overflow: hidden;
  border: 1px solid;
  border-color: ${({ planColor }) => planColor};
`;

export const Content = styled.div`
  flex: 1;
  padding: 2rem;
  color: #535151;
  display: flex;
  flex-direction: column;
  height: 27rem;
  overflow: auto;
`;

export const Header = styled(Row)`
  border-bottom: 1.5px solid #c9c9c9;
  font-size: 22px;
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  color: ${({ planColor }) => planColor};
`;

export const Bold = styled.b`
  font-family: "gotham-bold";
`;
