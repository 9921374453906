import React, { useEffect, useState } from "react";
import { useMutation } from "../../../../hooks";
import { Languages } from "./Dictionary";
import { GraphTemplate } from "../../Dashboards.d";
import { ContentWrapper } from "../modalStyles";
import {
  GraphCard,
  GraphContainer,
  GraphIconContaier,
  GraphTemplatesContainer,
  Subtitle,
} from "./style";
import Modal from "../../../../components/Modal";

const { AddYourGraph, AddGraphSubtitle } = Languages["ESP"];

interface AddGraphModalProps {
  onClose: () => void;
  selectedDashboard: string | null;
  selectedTemplate: number | null;
  setSelectedTemplate: (templateId: number) => void;
  openGraphTemplateModal: () => void;
}

const AddGraphModal: React.FC<AddGraphModalProps> = ({
  onClose,
  selectedDashboard,
  selectedTemplate,
  setSelectedTemplate,
  openGraphTemplateModal,
}) => {
  const [graphTemplates, setGraphTemplates] = useState<GraphTemplate[]>([]);

  const [getGraphTemplates] = useMutation<GraphTemplate[]>({
    func: "Ver2-DashBoards-ggt",
    onSuccess: (data) => setGraphTemplates(data),
  });

  useEffect(() => {
    if (typeof selectedDashboard === "string" && !!selectedDashboard) {
      getGraphTemplates({
        args: {
          IdDashBoard: selectedDashboard,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboard]);

  const handleSelectGraph = (templateId: number) => {
    setSelectedTemplate(templateId);
    openGraphTemplateModal();
  };

  return (
    <Modal
      title={AddYourGraph}
      width="700px"
      bodyStyle={{
        height: "auto",
        maxHeight: "570px",
      }}
      onCancel={onClose}
    >
      <Subtitle>
        <p>{AddGraphSubtitle}</p>
      </Subtitle>

      <ContentWrapper>
        <GraphTemplatesContainer>
          {graphTemplates.map((graph) => (
            <GraphContainer key={graph.IdGraphTemplate}>
              <GraphCard
                onClick={() => handleSelectGraph(graph.IdGraphTemplate)}
              >
                <GraphIconContaier src={graph.Image} alt={graph.Title} />
                <p className="title">{graph.Title}</p>
                {graph.Description.split("\n").map((paragraph, idx) => (
                  <p
                    key={`${graph.Title}-${graph.IdGraphTemplate}-${idx}`}
                    className="description"
                  >
                    {paragraph}
                  </p>
                ))}
              </GraphCard>
            </GraphContainer>
          ))}
        </GraphTemplatesContainer>
      </ContentWrapper>
    </Modal>
  );
};

export default AddGraphModal;
