import styled from "styled-components";
import searchIMG from "../../../resources/img/SEARCH_TASKS.svg";
import InfiniteScroll from "react-infinite-scroller";

export const Container = styled.div`
  background: #ffffff;
  box-shadow: inset 4px 2px 2px #edecec;
  border-radius: 6px;
  padding: 16px 14px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;

  // 100% / 12 to get a column, * 3 and + 20 margin each side of card + 26 of gap
  width: calc((100% / 12 * 3) + 66px);
  max-height: calc(100% - 10px);
`;

export const TitleBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  p {
    user-select: none;
    margin: 0;
    color: #828d9e;
    font-size: 14px;
  }
  .mainTitle {
    display: flex;
    gap: 8px;
    padding: 10px;
  }
`;

export const MessageNumberBox = styled.div`
  position: absolute;
  top: -5px;
  right: -8px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;

  color: #ffffff;
  font-family: Gotham-Book;
  font-style: normal;
  font-weight: 350;
  font-size: 8px;
  line-height: 10px;

  background: #ff4d4f;
`;

export const SearchBar = styled.input`
  background-image: url(${searchIMG});
  background-repeat: no-repeat;
  filter: grayscale(100%);
  background-size: 20px;
  background-position-y: center;
  background-position-x: 6px;

  background-color: #ffffff;
  border: 2px solid #edecec;
  box-shadow: 2px 4px 4px #edecec;
  border-radius: 6px;
  padding: 6px 10px 6px 35px;
  outline: none;
`;

export const Division = styled.div`
  border: 1px solid #edecec;
`;

export const InfiniteScrollStyled = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ChatContainer = styled.div`
  background: #ffffff;
  border: 1px solid #edecec;
  padding: 10px 13px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 14px;

  transition: background-color 0.35s ease;
  &:hover {
    background: rgba(232, 236, 241, 0.5);
  }
`;

export const ChatTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  p {
    margin: 0;
    font-size: 14px;
  }
  .chat-name {
    color: #828d9e;
  }
  .chat-time {
    color: #014c9b;
  }
`;

export const ChatMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
    flex: 1;
    font-size: 14px;
    color: #48505e;
  }
`;

export const NewMessages = styled.div`
  color: #ffffff;
  font-family: Gotham-Book;
  font-style: normal;
  font-weight: 350;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  background: #ff4d4f;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  justify-content: center;
  margin-left: 0.5rem;
`;

export const ContainerCompact = styled.div`
  position: absolute;
  right: 0;
  padding: 10px 10px 6px;
  display: flex;
  align-items: center;
  gap: 8px;

  background: #ffffff;
  box-shadow: inset 4px 2px 2px #edecec;
  border-radius: 6px;
  cursor: pointer;
`;
