import styled from "styled-components";
import { Modal } from "antd";
import TableContainer from "@material-ui/core/TableContainer";
import searchIMG from "../../../../../../../resources/img/SEARCH_TASKS.svg";
import { TableCell } from "@material-ui/core";
import { Tag } from "../../../../../../../GeestUI";

export const StyledModal = styled(Modal)`
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  flex: 1;
  overflow: hidden;
  font-size: 16px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SearchInput = styled.input`
  background-image: url(${searchIMG});
  background-repeat: no-repeat;
  background-size: 16px;
  background-position-y: center;
  background-position-x: 8px;
  background-color: white;

  border-radius: 6px;
  border: 2px solid #edecec;
  color: #828d9e;

  width: 100%;
  min-height: 30px;
  resize: none;
  padding: 6px 10px;
  padding-left: 30px;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const TableWrapper = styled.div`
  flex: 1;
  overflow: auto scroll;
  max-height: 100%;
  max-height: 80vh;
  padding-bottom: 10px;
`;

export const StyledTableContainer = styled(TableContainer)`
  & table {
    overflow: auto;
  }
  & .MuiTableCell-root {
    white-space: nowrap;
    border-right: 3px solid white;
    max-width: 300px;
  }
  & .MuiTableCell-head {
    background-color: rgba(242, 248, 254, 1);
    color: rgba(72, 80, 94, 1);
    font-family: Gotham-Bold;
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 40px;
    border-right: 3px solid white;
  }
  & .MuiTableCell-body {
    color: var(--medium-gray);
    border-bottom: transparent;
  }
  & .MuiTableCell-sizeSmall {
    padding: 7px 12px;
  }
  * {
    user-select: none !important;
  }
`;

export const StyledCell = styled(TableCell)<{ isEven?: boolean }>`
  padding: 10px 8px;
  background-color: ${({ isEven }) =>
    isEven ? "rgba(246, 245, 245, 1)" : "transparent"};

  p {
    margin: 0;
  }
`;

export const FormatCellValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

export const MeasurramentTitleCell = styled(TableCell)`
  border: 2px solid red;
`;

export const ProcessTitle = styled.div`
  font-size: 14px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
  max-width: 190px;
`;

export const StyledTag = styled(Tag)`
  border-radius: 6px;
  border-width: 2px;
  font-size: 10px;
  font-family: Gotham-Bold;
`;
