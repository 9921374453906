export const popupToolbarExample = `import React from "react";
const MyComponent: React.FC<MyComponentProps> = () => {
  return (
    <GeestTextEditor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      popUpToolbar
      editorBorder
    />
  );
};

export default MyComponent;
`;

export const defaultToolbarConfig = `export interface ToolbarControls {
  inline?: boolean;
  blockType?: boolean;
  fontSize?: boolean;
  fontFamily?: boolean;
  list?: boolean;
  textalign?: boolean;
  colorPicker?: boolean;
  link?: boolean;
  history?: boolean;
  spacer?: boolean;
  field?: boolean;
  image?: boolean;
}

const defaultToolbarConfig: ToolbarControls = {
  inline: true,
  fontSize: true,
  fontFamily: true,
  list: true,
  textalign: true,
  colorPicker: true,
  link: true,
};
`;

export const toolbarConfigExample = `import React from "react";
const MyComponent: React.FC<MyComponentProps> = () => {
  return (
    <GeestTextEditor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      toolbarConfig={{
        fontSize: false,
        fontFamily: false,
        list: false,
        colorPicker: false,
        link: false,
        spacer: true,
        field: true,
      }}
      editorBorder
    />
  );
};

export default MyComponent;
`;

export const toolbarConfigNoLink = `import React from "react";
const MyComponent: React.FC<MyComponentProps> = () => {
  return (
    <GeestTextEditor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      toolbarConfig={{ link: false }}
      editorBorder
    />
  );
};

export default MyComponent;
`;
