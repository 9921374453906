import React, { useEffect, useRef, useState } from "react";
import { Row, Space } from "antd";
import { Popover } from "@material-ui/core";
import { NodeCard } from "./styles";
import { CustomNodeTypes } from "../../Flow/CustomNodes";
import NodeIcons from "./NodeIcons";

interface AddNodeDropdownProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface Node {
  label: string;
  icon: JSX.Element;
  type: CustomNodeTypes;
}

const nodeList: Node[] = [
  { label: "Tarea", icon: NodeIcons.TaskIcon, type: CustomNodeTypes.TASK_NODE },
  {
    label: "Condición",
    icon: NodeIcons.ConditionIcon,
    type: CustomNodeTypes.CONDITIONAL_NODE,
  },
  {
    label: "Automatización",
    icon: NodeIcons.AutomationIcon,
    type: CustomNodeTypes.AUTOMATION_NODE,
  },
  {
    label: "Subflujo",
    icon: NodeIcons.SubfluxIcon,
    type: CustomNodeTypes.SUBFLUX_NODE,
  },
  {
    label: "Fin del proceso",
    icon: NodeIcons.EndIcon,
    type: CustomNodeTypes.END_NODE,
  },
];

const AddNodeDropdown: React.FC<AddNodeDropdownProps> = ({
  children,
  open,
  setOpen,
}) => {
  const [draggingNode, setDraggingNode] = useState<CustomNodeTypes | null>(
    null
  );
  const ButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) setDraggingNode(null);
  }, [open]);

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: CustomNodeTypes
  ): void => {
    setDraggingNode(nodeType);
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
    setOpen(false);
  };

  return (
    <>
      <div ref={ButtonRef} onClick={() => setOpen(!open)}>
        {children}
      </div>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ButtonRef.current}
        transitionDuration={7}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            borderRadius: "6px",
            backgroundColor: "#F2F8FE",
            padding: "10px",
          },
        }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {nodeList.map(({ label, type, icon }) => (
            <NodeCard
              key={type}
              draggable
              onDragStart={(event) => onDragStart(event, type)}
              onDragEnd={() => setDraggingNode(null)}
              dragging={draggingNode === type}
            >
              <Row
                justify="center"
                style={{ marginTop: "15px", marginBottom: "5px" }}
              >
                {icon}
              </Row>
              <p>{label}</p>
            </NodeCard>
          ))}
        </Space>
      </Popover>
    </>
  );
};

export default AddNodeDropdown;
