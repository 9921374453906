import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { Modal, Paper } from "@material-ui/core";
import { Form, Row } from "antd";
import { useMutation } from "../../../hooks";
import { useParams } from "react-router-dom";
import { AddEditButtonsRow, FormRow } from "../Styles";
import { Button, Typography } from "../../../GeestUI";
import { TableData, VarDBRow } from "../DataBases.d";
import DynamicInput from "../../../components/DynamicInput";
import ConfirmModal from "./ConfirmModal";
import { CloseIcon } from "../../../components/hoverIcons";
import DataTypeIcons from "../../../components/DataTypeIcons";
import { Languages } from "./Dictionary";
import { DefaultFormats } from "../DataBases.d";
import {
  SelectFileFieldModal,
  onPasteImage,
  FileType,
} from "../../../components/DynamicInput/Components/helpers/FileHelpers";

const { H3, H4, B } = Typography;

const StyledPaper = styled(Paper)`
  border-radius: 10px !important;
  max-height: 90vh;
  width: 350px;
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
  padding: 1rem;
`;

interface AddEditRowPopupProps {
  onClose: () => void;
  columns: TableData[];
  editingRow: VarDBRow | null;
  unsetEditingRow: () => void;
  isEditing: boolean;
  filesPath: string;
  reload: () => void;
  teamCurrency: string;
  defaultFormats: DefaultFormats;
  canEdit: boolean;
}

const AddEditRowPopup: React.FC<AddEditRowPopupProps> = ({
  onClose,
  columns,
  editingRow,
  unsetEditingRow,
  isEditing,
  filesPath,
  reload,
  teamCurrency,
  defaultFormats,
  canEdit,
}) => {
  const { IdTeam, IdVarDB } = useParams<{ IdTeam: string; IdVarDB: string }>();
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [newRow, setNewRow] = useState<{ [key: string]: any }>(
    isEditing ? undoFormat() : generateDefaultRow()
  );
  const [copiedFile, setCopiedFile] = useState<FileType | null>(null);

  const { NewRegisterLabel, DeleteLabel, SaveLabel } = Languages["ESP"];

  const existLocation = !!_.find(columns, { DataType: "location" });

  function generateDefaultRow() {
    let newObject: any = {};
    const currencyColumns = _.filter(columns, { DataType: "currency" }).map(
      (col) => `${col.IdColumn}`
    );
    for (const id of currencyColumns) {
      newObject[id] = { Value: "", Format: defaultFormats.currency };
    }

    const phoneColumns = _.filter(columns, { DataType: "phone_number" }).map(
      (col) => `${col.IdColumn}`
    );
    for (const id of phoneColumns) {
      newObject[id] = { Value: "", Format: defaultFormats.phone_number };
    }

    return newObject;
  }

  function undoFormat() {
    if (editingRow) {
      let newObject = { ...editingRow };

      const currencyColumns = _.filter(columns, { DataType: "currency" }).map(
        (col) => `${col.IdColumn}`
      );
      for (const [key, value] of Object.entries(newObject)) {
        if (currencyColumns.includes(key)) {
          let currencyFormated;
          if (value?.Format) {
            currencyFormated = {
              Value: value?.Value || "",
              Format: value?.Format || "",
            };
          } else {
            currencyFormated = {
              Value: value?.Value || "",
              Format: "MXN",
            };
          }

          if (value) {
            newObject = { ...newObject, [key]: currencyFormated };
          } else {
            delete newObject[key];
            newObject = { ...newObject };
          }
        }
      }

      const phoneColumns = _.filter(columns, { DataType: "phone_number" }).map(
        (col) => `${col.IdColumn}`
      );
      for (const [key, value] of Object.entries(newObject)) {
        if (phoneColumns.includes(key)) {
          let phoneFormated;
          if (value?.Format) {
            phoneFormated = {
              Value: value?.Value || "",
              Format: value?.Format || "",
            };
          }

          if (value) {
            newObject = { ...newObject, [key]: phoneFormated };
          } else {
            delete newObject[key];
            newObject = { ...newObject };
          }
        }
      }

      const userColumns = _.filter(columns, { DataType: "users_select" }).map(
        (col) => `${col.IdColumn}`
      );
      for (const [key, value] of Object.entries(newObject)) {
        if (userColumns.includes(key)) {
          let usersFiltered;
          if (value) {
            usersFiltered = value.map((user: any) => user.IdUser).join(",");
          }

          if (usersFiltered) {
            newObject = { ...newObject, [key]: usersFiltered };
          } else {
            delete newObject[key];
            newObject = { ...newObject };
          }
        }
      }
      return newObject;
    }
    return {};
  }

  const formatNewRow = () => {
    let newObject = { ...newRow };

    const currencyColumns = _.filter(columns, { DataType: "currency" }).map(
      (col) => `${col.IdColumn}`
    );
    for (const [key, value] of Object.entries(newObject)) {
      if (currencyColumns.includes(key)) {
        const filtered = {
          Value: value?.Value || "",
          Format: value?.Format || "",
        };

        newObject = { ...newObject, [key]: filtered };
      }
    }

    columns.map(({ IdColumn }) => {
      if (!newObject[IdColumn] && IdColumn !== 0) {
        newObject[IdColumn] = "";
      }
      return IdColumn;
    });

    return newObject;
  };

  const [insertVarDBRow, insertingVarDBRow] = useMutation<[]>({
    func: "Ver2-Vardbs-ivr",
    onSuccess: () => {
      handleOnClose();
      reload();
    },
  });

  const [updateVarDBRow, updatingVarDBRow] = useMutation<[]>({
    func: "Ver2-Vardbs-uvr",
    onSuccess: () => {
      handleOnClose();
      reload();
    },
  });

  const [deleteVarDBRow, deletingVarDBRow] = useMutation<[]>({
    func: "Ver2-Vardbs-dvr",
    onSuccess: () => {
      handleOnClose();
      reload();
    },
  });

  // TODO:
  // set new option on select/multiselect after this
  // probably we'll need to ship some data
  const [addOptionToDataOrigin] = useMutation<[]>({
    func: "Ver2-Vardbs-aotdo",
    onSuccess: () => reload(),
  });

  const handleAddRow = () => {
    const newFormatedRow = formatNewRow();
    insertVarDBRow({
      args: {
        IdTeam,
        IdVarDB,
        Row: { ...newFormatedRow },
      },
    });
  };

  const handleEditRow = () => {
    const newFormatedRow = formatNewRow();
    updateVarDBRow({
      args: {
        IdTeam,
        IdVarDB,
        Row: { ...newFormatedRow },
      },
    });
  };

  const handleDeleteRow = () => {
    deleteVarDBRow({
      args: {
        IdTeam,
        IdVarDB,
        IdRow: editingRow?.IdRow,
      },
    });
  };

  const getFormatField = (type: string, row: any, IdColumn: number) => {
    const field = row[IdColumn];

    if (field?.Format) return field?.Format;

    let auxRow = _.cloneDeep(row);
    switch (type) {
      case "currency":
        if (_.isEmpty(auxRow[IdColumn])) {
          let auxValue = "";
          const currentRow = _.filter(columns, { IdColumn })[0];
          if (currentRow) {
            auxValue = currentRow.data[0].Value ?? "";
          }
          auxRow[IdColumn] = { Value: auxValue, Format: teamCurrency };
        } else if (_.isString(auxRow[IdColumn])) {
          let auxValue = auxRow[IdColumn] || "";
          auxRow[IdColumn] = {
            Value: auxValue,
            Format: teamCurrency,
          };
        }
        setNewRow(auxRow);
        return teamCurrency;

      case "phone_number":
        if (_.isEmpty(auxRow[IdColumn])) {
          let auxValue = "";
          const currentRow = _.filter(columns, { IdColumn })[0];
          if (currentRow) {
            auxValue = currentRow.data[0].Value ?? "";
          }
          auxRow[IdColumn] = { Value: auxValue, Format: "MX" };
        } else if (_.isString(auxRow[IdColumn])) {
          let auxValue = auxRow[IdColumn] || "";
          auxRow[IdColumn] = {
            Value: auxValue,
            Format: "MX",
          };
        }
        setNewRow(auxRow);
        return "MX";

      default:
        return "";
    }
  };

  function handleAddOption(newOption: string, column: number, DataOrigin: any) {
    addOptionToDataOrigin({
      args: {
        IdVarDB,
        IdColumn: column,
        DataOrigin: [...DataOrigin, { label: newOption }],
      },
    });
  }

  const handleOnClose = () => {
    onClose();
    unsetEditingRow();
  };

  const beforeClose = () => {
    if (isEdited) {
      setOpenConfirmModal(true);
      return;
    }
    handleOnClose();
  };

  const getDynamicInputDefaultValue = (
    datatype: string,
    newRow: { [key: string]: any },
    idColumn: number
  ) => {
    if (datatype === "currency" || datatype === "phone_number") {
      // console.log("newRow");
      // console.log(newRow);
      // console.log("idColumn");
      // console.log(idColumn);
      return newRow[idColumn]?.Value || "";
    }

    if (datatype === "select") {
      return newRow[idColumn] || {};
    }

    if (datatype === "multi_select" || datatype === "user_select") {
      return newRow[idColumn] || [];
    }

    return newRow[idColumn] || "";
  };

  const fileFields = columns
    .filter(({ DataType }) => DataType === "file")
    .map(({ IdColumn, Title }) => ({
      Id: IdColumn,
      Label: Title,
    }));

  const onPasteImageToField = (Id: number, value: FileType) => {
    const oldValue = newRow[Id];
    setNewRow({ ...newRow, [Id]: oldValue ? [...oldValue, value] : [value] });
  };

  useEffect(() => {
    if (fileFields.length > 0) {
      const onKeyDown = async (e: KeyboardEvent) => {
        if (e.key.toLowerCase() === "v" && e.ctrlKey) {
          const value = await onPasteImage(filesPath);
          if (value) {
            if (fileFields.length > 1) {
              setCopiedFile(value);
            } else {
              onPasteImageToField(fileFields[0].Id, value);
            }
          }
        }
      };
      window.addEventListener("keydown", onKeyDown);

      return () => window.removeEventListener("keydown", onKeyDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileFields]);

  return (
    <Modal open onClose={beforeClose} style={{ opacity: "1", zIndex: 1 }}>
      <Row
        onClick={(e) => {
          e.stopPropagation();
          beforeClose();
        }}
        justify="center"
        align="middle"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {!!copiedFile && (
          <SelectFileFieldModal
            file={copiedFile}
            onChangeFileField={onPasteImageToField}
            fileFields={fileFields}
            closeModal={() => setCopiedFile(null)}
          />
        )}
        {openConfirmModal && (
          <ConfirmModal
            openModal={openConfirmModal}
            onClose={() => {
              setIsEdited(false);
              setOpenConfirmModal(false);
              onClose();
              unsetEditingRow();
            }}
            onCancel={() => {
              setOpenConfirmModal(false);
            }}
          />
        )}
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          {!!copiedFile && (
            <SelectFileFieldModal
              file={copiedFile}
              onChangeFileField={onPasteImageToField}
              fileFields={fileFields}
              closeModal={() => setCopiedFile(null)}
            />
          )}
          <Row align="middle" justify="space-between">
            <H3 mb="0">
              <B>
                {isEditing ? `Folio: ${editingRow?.IdRow}` : NewRegisterLabel}
              </B>
            </H3>
            <CloseIcon color="#525151" onClick={handleOnClose} size={25} />
          </Row>

          <FormRow
            style={{
              padding: "0.5rem",
              paddingBottom: existLocation ? "7rem" : "",
            }}
          >
            <Form layout="vertical" style={{ width: "100%" }}>
              {columns.map(
                (
                  {
                    Title,
                    IdColumn,
                    DataType,
                    DataOrigin,
                    Configuration,
                    Type,
                  },
                  i
                ) => {
                  return (
                    DataType !== "Folio" &&
                    Type !== "Imported" && (
                      <Form.Item
                        label={
                          <H4
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              marginBottom: 0,
                            }}
                          >
                            {DataType !== "varDBRow" && (
                              <div
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {DataTypeIcons[DataType] && (
                                  <img
                                    src={DataTypeIcons[DataType].icon}
                                    alt=""
                                    style={{
                                      width: "14px",
                                      height: "14px",
                                      maxWidth: "14px",
                                      maxHeight: "14px",
                                      ...DataTypeIcons[DataType]?.extraStyles,
                                    }}
                                  />
                                )}
                              </div>
                            )}
                            <p
                              style={{
                                margin: 0,
                                color: "#48505E",
                              }}
                            >
                              {Title}
                            </p>
                          </H4>
                        }
                        colon={false}
                        style={{ marginBottom: "0.5rem" }}
                        key={IdColumn}
                      >
                        <DynamicInput
                          value={getDynamicInputDefaultValue(
                            DataType,
                            newRow,
                            IdColumn
                          )}
                          type={DataType}
                          dataOrigin={DataOrigin || []}
                          format={getFormatField(DataType, newRow, IdColumn)}
                          configuration={Configuration}
                          userSelectDataOrigin={
                            DataType === "users_select" ? DataOrigin : []
                          }
                          IdVarDB={Number(IdVarDB) || 0}
                          isConsult={false}
                          disabled={!canEdit}
                          required={false}
                          fieldName=""
                          onChange={(value: any) => {
                            setIsEdited(true);
                            if (
                              DataType === "currency" ||
                              DataType === "phone_number"
                            ) {
                              let auxRow = _.cloneDeep(newRow);
                              auxRow[IdColumn].Value = value;
                              setNewRow(auxRow);
                              return;
                            }
                            setNewRow({ ...newRow, [IdColumn]: value });
                          }}
                          onChangeFormat={(format: string) => {
                            setIsEdited(true);
                            if (
                              DataType === "currency" ||
                              DataType === "phone_number"
                            ) {
                              let auxRow = _.cloneDeep(newRow);
                              if (_.isEmpty(auxRow[IdColumn])) {
                                auxRow[IdColumn] = {
                                  Value: "",
                                  Format:
                                    DataType === "currency" ? "MXN" : "MX",
                                };
                              } else if (_.isString(auxRow[IdColumn])) {
                                let auxValue = auxRow[IdColumn] || "";
                                auxRow[IdColumn] = {
                                  Value: auxValue,
                                  Format:
                                    DataType === "currency" ? "MXN" : "MX",
                                };
                              }
                              auxRow[IdColumn].Format = format;
                              setNewRow(auxRow);
                            }
                          }}
                          onAddOption={(option: string) => {
                            setIsEdited(true);
                            handleAddOption(option, IdColumn, DataOrigin);
                          }}
                          extraParams={{
                            FilesPath: filesPath,
                            showFormat: true,
                          }}
                        />
                      </Form.Item>
                    )
                  );
                }
              )}
            </Form>
          </FormRow>
          {canEdit && (
            <AddEditButtonsRow isEditing={isEditing}>
              {isEditing && (
                <Button
                  type="secondary"
                  danger
                  size="large"
                  onClick={handleDeleteRow}
                  disabled={
                    insertingVarDBRow || updatingVarDBRow || deletingVarDBRow
                  }
                  loading={
                    insertingVarDBRow || updatingVarDBRow || deletingVarDBRow
                  }
                >
                  {DeleteLabel}
                </Button>
              )}

              <Button
                type="primary"
                size="large"
                onClick={() => (isEditing ? handleEditRow() : handleAddRow())}
                disabled={
                  insertingVarDBRow || updatingVarDBRow || deletingVarDBRow
                }
                loading={
                  insertingVarDBRow || updatingVarDBRow || deletingVarDBRow
                }
              >
                {SaveLabel}
              </Button>
            </AddEditButtonsRow>
          )}
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default AddEditRowPopup;
