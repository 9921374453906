import React, { useContext, useState } from "react";
import _ from "lodash";
import {
  Row,
  Button,
  Tag,
  Space,
  Checkbox,
  Input,
  Typography,
  Divider,
} from "antd";
import VISA from "./../../../../resources/img/visa.svg";
import MASTER_CARD from "./../../../../resources/img/mc.svg";
import AMERICAN_EXPRESS from "./../../../../resources/img/am.svg";
import { StateMachineContext } from "../index";
import { FiLock } from "react-icons/fi";
import { Languages } from "./Dictionary";
import {
  Container,
  Content,
  CardTitle,
  StyledTextFiel,
  StyledButton,
  MainText,
  ContentText,
  CardButton,
  StyledSelect,
  NotificationUsersContainer,
  EmailContainer,
  CloseIcon,
  DeleteEmailButton,
} from "./Style";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import StripeInput from "../../StripeInput";
import States from "../States";
import { PlusOutlined } from "@ant-design/icons";
import { regimenOptions } from "../../helpers";

const TextInput = ({ name, width, ...props }) => {
  return (
    <StyledTextFiel
      label={name}
      placeholder={name}
      variant="outlined"
      size="small"
      style={{ width }}
      {...props}
    />
  );
};

const PaymentMetod = ({
  Cards = [],
  SelectedPaymentMethod,
  setSelectedPaymentMethod,
}) => {
  const BrandLogos = {
    visa: VISA,
    mastercard: MASTER_CARD,
    amex: AMERICAN_EXPRESS,
  };
  const { ThisCard, StopUsingCard } = Languages["ESP"];

  const SelectedCardId = SelectedPaymentMethod?.PaymentMethodId ?? null;

  const handleOnSelectCard = (card) => {
    if (SelectedCardId === card.PaymentMethodId) {
      setSelectedPaymentMethod(null);
    } else setSelectedPaymentMethod(card);
  };

  return (
    <Space
      direction="vertical"
      size="small"
      style={{ height: "18%", overflow: "auto" }}
    >
      {Cards.map((Card, key) => (
        <Row justify="space-between" key={key}>
          <Row align="middle" style={{ height: "15px" }}>
            {BrandLogos[Card.Brand] ? (
              <img
                style={{ height: "17px" }}
                src={BrandLogos[Card.Brand]}
                alt={Card.Brand}
              />
            ) : (
              <Tag color="cyan">{Card.Brand}</Tag>
            )}
            <div style={{ marginLeft: "0.5rem" }}>
              <div style={{ fontSize: "14px" }}>
                <b>{`•••• •••• •••• ${Card.Last4}`}</b>
              </div>
              <div style={{ fontSize: "10px" }}>{Card.Exp}</div>
            </div>
          </Row>
          <CardButton
            onClick={() => handleOnSelectCard(Card)}
            $active={SelectedCardId === Card.PaymentMethodId}
          >
            {SelectedCardId === Card.PaymentMethodId ? StopUsingCard : ThisCard}
          </CardButton>
        </Row>
      ))}
    </Space>
  );
};

const PaymentDataCard = ({
  bussinesName,
  setBussinessName,
  rfc,
  setRfc,
  address,
  setAddress,
  zipCode,
  setZipCode,
  city,
  setCity,
  country,
  setCountry,
  regimen,
  setRegimen,
  notificationUsers,
  setNotificationUsers,
  agreed,
  setAgreed,
  handleSubmit,
  Cards,
  SelectedPaymentMethod,
  setSelectedPaymentMethod,
  disableSubmit,
  Loading = false,
  onCancelOpc,
  onChooseOtherPlan,
}) => {
  const [newOption, setNewOption] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [hoveredEmail, setHoveredEmail] = useState(null);

  const validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isNewEmailEmpty = _.isEmpty(_.trim(newEmail));
  const isNewEmailValid = validateEmail.test(_.trim(newEmail));
  const isDuplicatedEmail = [...(notificationUsers || "").split(",")].includes(
    newEmail
  );

  const { CurrentState } = useContext(StateMachineContext);
  const paymentOutdated =
    CurrentState === States.CHECKOUT_SINGLE_PAYMENT_OUTDATED;
  const paymentAddUser = CurrentState === States.CHECKOUT_CHANGE_USERS;
  const Normalpayment =
    CurrentState === States.CHECKOUT_NORMAL ||
    CurrentState === States.CHECKOUT_NORMAL_EDIT_PLAN;

  const {
    BussinesNameLabel,
    RfcLabel,
    AddressLabel,
    ZipCodeLabel,
    CountryLabel,
    CityLabel,
    SendBillsToLabel,
    OtherPlanButton,
    Agreement1,
    Agreement2,
    Agreement3,
    TermsAndCondsLabel,
    PrivacyNoticeLabel,
    Finish,
    Data,
    Cancel,
  } = Languages["ESP"];

  const onEnterNewOption = () => {
    setRegimen(newOption);
    setNewOption("");
  };

  const addNewNotificationUser = () => {
    if (isNewEmailEmpty || !isNewEmailValid || isDuplicatedEmail) return;
    if (!!notificationUsers) {
      setNotificationUsers(
        [...notificationUsers.split(","), newEmail].join(",")
      );
    } else {
      setNotificationUsers(newEmail);
    }
    setNewEmail("");
  };

  const removeNewNotificationUser = (i) => {
    const auxArr = [...notificationUsers.split(",")];
    _.pullAt(auxArr, i);
    setNotificationUsers(auxArr.join(","));
  };

  const getContainerHeight = () => {
    let height = 36.5;
    if (!!notificationUsers) {
      height += 4;
    }
    if (!!SelectedPaymentMethod) {
      height -= 2;
    }
    return `${height}rem`;
  };

  return (
    <>
      {Normalpayment && (
        <Row justify="end">
          <StyledButton onClick={onChooseOtherPlan}>
            {OtherPlanButton}
          </StyledButton>
        </Row>
      )}
      <Container $margin={paymentOutdated || paymentAddUser}>
        <Content height={getContainerHeight()}>
          <CardTitle>{Data}</CardTitle>
          <PaymentMetod
            Cards={Cards}
            SelectedPaymentMethod={SelectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
          />
          <Row
            justify="space-between"
            align="middle"
            style={{ display: "flex", marginTop: "0.5rem" }}
            hidden={!!SelectedPaymentMethod}
          >
            <StripeInput />
            <Space size="middle" style={{ marginLeft: "1rem" }}>
              <Row align="middle">
                <img src={VISA} alt="visa" style={{ height: "17px" }} />
                <img
                  src={MASTER_CARD}
                  alt="masterCard"
                  style={{ marginLeft: "0.5rem", height: "17px" }}
                />
                <img
                  src={AMERICAN_EXPRESS}
                  alt="americanExpress"
                  style={{ marginLeft: "0.5rem", height: "17px" }}
                />
              </Row>
              <FiLock size={22} />
            </Space>
          </Row>
          <Row
            type="flex"
            justify="space-between"
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <TextInput
              name={BussinesNameLabel}
              width="55%"
              value={bussinesName}
              onChange={({ target: { value } }) => setBussinessName(value)}
            />
            <TextInput
              name={RfcLabel}
              width="42%"
              value={rfc}
              onChange={({ target: { value } }) => setRfc(value)}
            />
            <TextInput
              name={AddressLabel}
              width="55%"
              value={address}
              onChange={({ target: { value } }) => setAddress(value)}
            />
            <TextInput
              name={ZipCodeLabel}
              width="42%"
              value={zipCode}
              onChange={({ target: { value } }) => setZipCode(value)}
            />
            <TextInput
              name={CityLabel}
              width="26%"
              value={city}
              onChange={({ target: { value } }) => setCity(value)}
            />
            <TextInput
              name={CountryLabel}
              width="26%"
              value={country}
              onChange={({ target: { value } }) => setCountry(value)}
            />
            <StyledSelect
              data-testid="selectInputTest"
              style={{ width: "42%", height: "2.5rem" }}
              placeholder="Régimen fiscal"
              value={regimen}
              onChange={setRegimen}
              allowClear
              showSearch
              optionFilterProp="label"
              options={regimenOptions}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <div>
                    <Divider style={{ margin: "8px 0" }} />
                    <Row
                      align="middle"
                      style={{ padding: "0 8px 4px", width: "100%" }}
                    >
                      <Input
                        placeholder="Escribe otra opción"
                        style={{ flex: 1 }}
                        value={newOption}
                        onChange={(e) => setNewOption(e.target.value)}
                      />
                      <Typography.Link
                        onClick={onEnterNewOption}
                        style={{ whiteSpace: "nowrap", marginLeft: "0.5rem" }}
                      >
                        <PlusOutlined /> Agregar
                      </Typography.Link>
                    </Row>
                  </div>
                </>
              )}
            />
            <TextInput
              name={SendBillsToLabel}
              width="77%"
              value={newEmail}
              type="email"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  addNewNotificationUser();
                }
              }}
              onChange={({ target: { value } }) => setNewEmail(value)}
            />
            <Button
              disabled={Loading}
              style={{
                width: "20%",
                height: "40px",
                borderRadius: "10px",
                color: "#525151",
                backgroundColor: "white",
              }}
              onClick={addNewNotificationUser}
            >
              <PlusOutlined /> Agregar
            </Button>
            <NotificationUsersContainer show={!!notificationUsers}>
              {!!notificationUsers &&
                notificationUsers.split(",").map((email, i) => (
                  <EmailContainer
                    key={email}
                    onMouseEnter={() => setHoveredEmail(i)}
                    onMouseLeave={() => setHoveredEmail(null)}
                  >
                    <p>{email}</p>
                    <DeleteEmailButton
                      show={hoveredEmail === i}
                      onClick={() => removeNewNotificationUser(i)}
                    >
                      <CloseIcon />
                    </DeleteEmailButton>
                  </EmailContainer>
                ))}
            </NotificationUsersContainer>
          </Row>
          <Space>
            <Checkbox
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
              style={{ marginRight: "0.5rem" }}
            />
            <Row>
              <MainText $fontSize="11px">{Agreement1}</MainText>
              <a
                href={`${process.env.REACT_APP_BACK}#/legal/terms`}
                target="_blank"
                rel="noreferrer"
                style={{
                  fontSize: "11px",
                  paddingLeft: "3px",
                  paddingRight: "3px",
                }}
              >
                {TermsAndCondsLabel}
              </a>
              <MainText $fontSize="11px">{Agreement2}</MainText>
              <a
                href={`${process.env.REACT_APP_BACK}#/legal/privacy`}
                target="_blank"
                rel="noreferrer"
                style={{
                  fontSize: "11px",
                  paddingLeft: "3px",
                  paddingRight: "3px",
                }}
              >
                {PrivacyNoticeLabel}
              </a>
              <MainText $fontSize="11px">{Agreement3}</MainText>
            </Row>
          </Space>
          <Row
            align="center"
            justify="space-between"
            style={{
              width: "92%",
              position: "absolute",
              bottom: "1rem",
            }}
          >
            <Button
              disabled={Loading}
              style={{
                width: "25%",
                borderRadius: "10px",
                color: "#525151",
                backgroundColor: "white",
              }}
              onClick={onCancelOpc}
            >
              {Cancel}
            </Button>
            <div>
              <Space>
                <IoShieldCheckmarkOutline color="green" size={20} />
                <ContentText $fontSize="16px">Pago seguro</ContentText>
              </Space>
              <Button
                loading={Loading}
                disabled={disableSubmit || !agreed}
                style={{
                  borderRadius: "10px",
                  color: "white",
                  backgroundColor: "green",
                  borderColor: "white",
                  marginLeft: "1rem",
                }}
                onClick={handleSubmit}
              >
                {Finish}
              </Button>
            </div>
          </Row>
        </Content>
      </Container>
    </>
  );
};

export default PaymentDataCard;
