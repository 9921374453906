import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ListManager } from "react-beautiful-dnd-grid";
import { useHistory } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { AiOutlineEdit, AiOutlineLock } from "react-icons/ai";
import ContextMenu from "../../../components/ContextMenu/";
import { Languages } from "./Dictionary";
import { Dashboard, ModalsType } from "../Dashboards.d";
import {
  DasboardsContainer,
  DashboardItem,
  MastersClubCard,
  LogoContainer,
  TeamInformation,
  GraphCard,
  GraphTitle,
  ImageContainer,
  ContextMenuOptionContainer,
} from "./style";
import { useMutation } from "../../../hooks";
import { ThreePointsIcon } from "../../../components/hoverIcons";

const { ChangeName, Permissions, Delete } = Languages["ESP"];

interface Points {
  x: number;
  y: number;
}

interface ContextOption {
  label: React.ReactNode | string | number;
  func: Function;
  hoverBackground?: string;
}

interface ListProps {
  idTeam: number | null;
  dashboards: Dashboard[];
  setDashboards: (newOrder: Dashboard[]) => void;
  setSelectedDashboard: (dashboard: string | null) => void;
  setDashboardToEdit: (dashboard: Dashboard | null) => void;
  openPermissionsModal: () => void;
  setModal: (modal: ModalsType) => void;
  reload: () => void;
}

const List: React.FC<ListProps> = ({
  idTeam,
  dashboards = [],
  setDashboards,
  setSelectedDashboard,
  setDashboardToEdit,
  openPermissionsModal,
  setModal,
  reload,
}) => {
  const history = useHistory();
  const [, setCardWidth] = useState<string>("200px");
  const [dashboardForContext, setDashboardForContext] =
    useState<Dashboard | null>(null);
  const [points, setPoints] = useState<Points>({
    x: 0,
    y: 0,
  });
  const [contextOptions, setContextOptions] = useState<ContextOption[]>([]);
  const [draggingDashboard, setDraggingDashboard] = useState<string>("");

  const [updatePositions] = useMutation({
    func: "Ver2-DashBoards-utdp",
    onSuccess: reload,
    onError: (dashboards) => setDashboards(dashboards),
  });

  useEffect(() => {
    setSelectedDashboard(null);
    const width = window.innerWidth;
    if (width <= 900) setCardWidth("200px");
    else if (width <= 1090) setCardWidth("250px");
    else if (width <= 1250) setCardWidth("270px");
    else if (width <= 1366) setCardWidth("340px");
    else setCardWidth("350px");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMaxItems = () => {
    const width = window.innerWidth;
    if (width <= 1450) return 2;
    if (width <= 2000) return 3;
    return 4;
  };

  const handleOpenDashboard = (idDashboard: string) => {
    history.push(`/home/dashboards/${idTeam}-${idDashboard}`);
  };

  /*const filteredDashboards = (): Dashboard[] => {
    return dashboards.filter(
      (dashboard) => dashboard.Permissions.CanViewDashBoard
    );
  };*/

  const handleReorder = (sourceIndex: number, destinationIndex: number) => {
    setDraggingDashboard("");
    if (destinationIndex === sourceIndex) {
      return;
    }
    const list = _.cloneDeep(dashboards);
    list.splice(destinationIndex, 0, list.splice(sourceIndex, 1)[0]);
    setDashboards(list);
    updatePositions({
      args: {
        IdTeam: idTeam,
        Dashboards: list,
      },
      errorArgs: dashboards,
    });
  };

  const contextMenuHasOptions = (dashboard: Dashboard) => {
    const {
      Permissions: { CanDeleteDashboard, CanEditPermissions },
    } = dashboard;

    return CanDeleteDashboard || CanEditPermissions;
  };

  const handleContextDashboard = (dashboard: Dashboard) => {
    let newOptions: ContextOption[] = [];

    const {
      Permissions: { CanDeleteDashboard, CanEditPermissions },
    } = dashboard;

    // permission to change name
    if (CanDeleteDashboard) {
      newOptions = [
        ...newOptions,
        {
          label: (
            <ContextMenuOptionContainer>
              <AiOutlineEdit />
              <p>{ChangeName}</p>
            </ContextMenuOptionContainer>
          ),
          func: () => {
            setModal("ChangeName");
            setDashboardToEdit(dashboard);
          },
        },
      ];
    }

    if (CanEditPermissions) {
      newOptions = [
        ...newOptions,
        {
          label: (
            <ContextMenuOptionContainer>
              <AiOutlineLock />
              <p>{Permissions}</p>
            </ContextMenuOptionContainer>
          ),
          func: () => {
            setDashboardToEdit(dashboard);
            openPermissionsModal();
            setDashboardForContext(null);
          },
        },
      ];
    }

    if (CanDeleteDashboard) {
      newOptions = [
        ...newOptions,
        {
          label: (
            <ContextMenuOptionContainer>
              <FiTrash />
              <p>{Delete}</p>
            </ContextMenuOptionContainer>
          ),
          func: () => {
            setModal("DeleteDashboard");
            setDashboardToEdit(dashboard);
            setDashboardForContext(null);
          },
          hoverBackground: "#db232c",
        },
      ];
    }

    setContextOptions(newOptions);
    setDashboardForContext(dashboard);
  };

  return (
    <DasboardsContainer>
      <ContextMenu
        isOpen={
          Boolean(dashboardForContext?.IdDashBoard) &&
          Boolean(
            dashboards.find(
              (d) => d.IdDashBoard === dashboardForContext?.IdDashBoard
            )
          )
        }
        points={points}
        width={280}
        options={contextOptions}
        closeMenu={() => {
          setDashboardForContext(null);
          setDraggingDashboard("");
        }}
      >
        <ListManager
          items={dashboards}
          direction="horizontal"
          maxItems={getMaxItems()}
          onDragEnd={handleReorder}
          render={(dashboard: Dashboard) =>
            dashboard.Permissions.CanViewDashBoard ? (
              dashboard.Type === "master" ? (
                <DashboardItem>
                  <MastersClubCard
                    key={`${dashboard.IdDashBoard}`}
                    width={"286px"}
                    onClick={() => handleOpenDashboard(dashboard.IdDashBoard)}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      if (contextMenuHasOptions(dashboard)) {
                        setPoints({ x: e.pageX, y: e.pageY });
                        handleContextDashboard(dashboard);
                      }
                    }}
                  >
                    <LogoContainer>LOGO</LogoContainer>
                    <TeamInformation>
                      <p className="title">{dashboard.DashBoardName}</p>
                      <p className="description">{dashboard.Description}</p>
                    </TeamInformation>
                  </MastersClubCard>
                </DashboardItem>
              ) : (
                <DashboardItem
                  onMouseDown={() => {
                    setDraggingDashboard(dashboard.IdDashBoard);
                  }}
                >
                  <GraphCard
                    key={`${dashboard.IdDashBoard}`}
                    width={"286px"}
                    isDragging={dashboard.IdDashBoard === draggingDashboard}
                    onClick={() => handleOpenDashboard(dashboard.IdDashBoard)}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      if (contextMenuHasOptions(dashboard)) {
                        setPoints({ x: e.pageX, y: e.pageY });
                        handleContextDashboard(dashboard);
                      }
                    }}
                  >
                    <GraphTitle>
                      <p>{dashboard.DashBoardName}</p>
                      {contextMenuHasOptions(dashboard) && (
                        <ThreePointsIcon
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setPoints({ x: e.pageX + 35, y: e.pageY });
                            handleContextDashboard(dashboard);
                          }}
                        />
                      )}
                    </GraphTitle>

                    <ImageContainer>
                      <img src={dashboard.Image} alt="" />
                    </ImageContainer>
                  </GraphCard>
                </DashboardItem>
              )
            ) : (
              <></>
            )
          }
        />
      </ContextMenu>
    </DasboardsContainer>
  );
};

export default List;
