import { Row } from "antd";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { StateMachineContext } from "../../..";
import Button from "../../../../../../components/Button";
import { useMutation } from "../../../../../../hooks";
import States from "../../../States";
import {
  Container,
  Title,
  Text,
  ButtonContainer,
  ButtonBorder,
} from "./styles";

const BonusTrialEnded = () => {
  const { setCurrentState } = useContext(StateMachineContext);
  const { IdTeam } = useParams();
  const [loading, setLoading] = useState(false);

  const [changeSubscriptionState] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: (response) => {
      const { State } = response;
      setCurrentState(State);
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  const changeState = () => {
    setLoading(true);
    changeSubscriptionState({
      args: {
        IdTeam,
        NextState: States.CHANGE_PLAN,
      },
    });
  };

  return (
    <Container>
      <Title>Has finalizado tu prueba gratuita</Title>
      <Text>
        Contrata ahora para seguir disfrutando de los beneficios de Geest.
      </Text>
      <Row justify="center">
        <ButtonContainer>
          <ButtonBorder disable={loading}>
            <Button
              type="defaultActive"
              block
              style={{ borderColor: "#ffffff0f" }}
              loading={loading}
              onClick={changeState}
            >
              Contratar ahora
            </Button>
          </ButtonBorder>
        </ButtonContainer>
      </Row>
    </Container>
  );
};

export default BonusTrialEnded;
