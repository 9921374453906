import React from "react";
import { IconContainer } from "./styles";
import Node from "../../resources/img/Node.svg";
import { AiOutlineNodeIndex } from "react-icons/ai";

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  filled?: boolean;
}

const NodeIcon: React.FC<IconProps> = ({ onClick, filled, ...props }) => (
  <IconContainer size={20} onClick={onClick} {...props}>
    {filled ? (
      <img
        src={Node}
        style={{
          width: "12px",
          height: "14px",
        }}
        alt="node"
      />
    ) : (
      <AiOutlineNodeIndex color="#48505E" size={18} />
    )}
  </IconContainer>
);

export default NodeIcon;
