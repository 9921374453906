export const Languages = {
  ENG: {
    TabTitles: {
      Tracking: "Tracking",
      Pipeline: "Pipeline",
      Progress: "Progress",
      Productivity: "Productivity",
      Statistics: "Statistics",
    },
    Buttons: {
      ProcessDetailsTooltipLabel: "Tracking details",
      ResponsiblesTooltipLabel: " Tracking responsibles",
      AlertsTooltipLabel: "Tracking alerts",
      FlowTooltipLabel: "Tracking flux",
      FormFieldsTooltipLabel: "Tracking fields",
      CancelActionLabel: "Cancel tracking",
      DeleteActionLabel: "Delete tracking",
    },
    Messages: {
      UpdateForm: "Successful update",
    },
  },
  ESP: {
    TabTitles: {
      Tracking: "Tracking",
      Pipeline: "Pipeline",
      Progress: "Progreso",
      Productivity: "Productividad",
      Statistics: "Estadísticas",
    },
    Buttons: {
      ProcessDetailsTooltipLabel: "Ver detalle de seguimiento",
      ResponsiblesTooltipLabel: " Ver responsables de seguimiento",
      AlertsTooltipLabel: "Ver alertas de seguimiento",
      FlowTooltipLabel: "Ver flujo de seguimiento",
      FormFieldsTooltipLabel: "Ver campos del formulario",
      CancelActionLabel: "Detener seguimiento",
      DeleteActionLabel: "Eliminar seguimiento",
    },
    Messages: {
      UpdateForm: "Actualización exitosa",
    },
  },
};
