import React, { useState, useEffect, useContext } from "react";
import ViewTitle from "../../components/ViewTitle/ViewTitle";
import {
  Container,
  SearchBar,
  Header,
  ContentHeader,
  CountBadge,
  TabsContainer,
  Tab,
  ListCotainer,
  ListItem,
  DataItem,
  ReadIconContainer,
} from "./styles";
import { sanitizeSearchValues } from "../../Helpers";
import { Notification, GetNotificationsResponse } from "./Notifications.d";
import { useApoloMutation, useMutation } from "../../hooks";
import { Button } from "../../GeestUI";
import moment from "moment";
import User from "../../components/User";
import geestLogo from "../../resources/img/icono.png";
import { AiOutlineClockCircle } from "react-icons/ai";
import Tooltip from "../../components/Tooltip";
import openedNotIcon from "../../resources/img/openedNotIcon.svg";
import unopenedNotIcon from "../../resources/img/unopenedNotIcon.svg";
import { parseEmoji } from "../../emoji";
import taskStartedIcon from "../../resources/img/notifications/taskStarted.svg";
import taskExpiredIcon from "../../resources/img/notifications/taskExpired.svg";
import assignedExecutorIcon from "../../resources/img/notifications/assignedExecutor.svg";
import assignedReviserIcon from "../../resources/img/notifications/assignedReviser.svg";
import approvedTaskIcon from "../../resources/img/notifications/approvedTask.svg";
import rejectedTaskIcon from "../../resources/img/notifications/rejectedTask.svg";
import { SocketContext } from "../../config/socket";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import DetailPending from "../Pendings/DetailPending";
import { useHistory } from "react-router-dom";

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

const Notifications: React.FC = () => {
  const [filter, setFilter] = useState<string>("");
  const [list, setList] = useState<string>("Unread");
  const [notificationList, setNotificationList] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [socket] = useContext<any>(SocketContext);
  const [selectedPending, setSelectedPending] = useState<string>("");
  const history = useHistory();

  const [getUserNotifications] = useApoloMutation<GetNotificationsResponse>({
    route: "notifications",
    method: "get",
    onSuccess: ({ Notifications: nots, UnreadCount }) => {
      setNotificationList(nots);
      setUnreadCount(UnreadCount);
    },
  });

  const reload = () => {
    getUserNotifications({
      params: {
        OnlyUnread: list === "Unread" ? 1 : 0,
      },
    });
  };

  const [markNotificationsAsRead] = useApoloMutation({
    route: "notifications/markAsRead",
    method: "put",
  });

  const [changeNotificationReadStatus] = useApoloMutation({
    route: "notifications/changeRead",
    method: "put",
  });

  const [changeSubsctriptionPlan] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: (res, { IdTeam }) => {
      history.push(`/home/premium/${IdTeam}`);
    },
  });

  const onChangeNotificationStatus = (
    IdNotification: number,
    IsRead: boolean
  ) => {
    changeNotificationReadStatus({
      params: { IdNotification },
      body: { IsRead },
    });
  };

  useEffect(() => {
    getUserNotifications({
      params: {
        OnlyUnread: 1,
      },
    });
    window.addEventListener("socketReconnect", reload);
    return () => window.removeEventListener("socketReconnect", reload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket?.on("reloadNotifications", reload);

    return () => socket?.off("reloadNotifications");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, list]);

  const onChangeList = (list: string) => {
    setList(list);
    getUserNotifications({
      params: {
        OnlyUnread: list === "Unread" ? 1 : 0,
      },
    });
  };

  const filteredItems = notificationList.filter(
    ({ Title, Description }) =>
      !filter ||
      sanitizeSearchValues(Title).includes(sanitizeSearchValues(filter)) ||
      sanitizeSearchValues(Description).includes(sanitizeSearchValues(filter))
  );

  const onClickNotification = (Type: string, ActionId: string) => {
    switch (Type) {
      case "taskRedirect":
        setSelectedPending(ActionId);
        break;
      case "premimumChangePlanRedirect":
        changeSubsctriptionPlan({
          args: {
            IdTeam: ActionId,
            NextState: "ChangePlan",
          },
          shippedData: {
            IdTeam: ActionId,
          },
        });
        break;
    }
  };

  const notIcons: { [key: number]: string } = {
    1: taskStartedIcon,
    2: taskExpiredIcon,
    3: assignedExecutorIcon,
    4: assignedReviserIcon,
    5: approvedTaskIcon,
    6: rejectedTaskIcon,
    7: assignedReviserIcon,
    8: geestLogo,
  };

  const renderNotification = ({ index, key, style, parent }: any) => {
    const notification = filteredItems[index];

    const {
      Title,
      Description,
      UserCreator,
      Date,
      IsRead,
      Id,
      ActionId,
      MessageCode,
      Type,
    } = notification;

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <ListItem
          onClick={() => onClickNotification(Type, ActionId)}
          style={style}
        >
          <DataItem
            $width={228}
            style={{
              color: "rgba(72, 80, 94, 1)",
              fontSize: "14px",
              fontFamily: IsRead ? "Gotham-Book" : "Gotham-Bold",
            }}
          >
            <img
              alt="icon"
              src={notIcons[MessageCode] || ""}
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "12px",
              }}
            />
            {Title}
          </DataItem>
          <DataItem
            $width={300}
            style={{
              color: "rgba(130, 141, 158, 1)",
              fontSize: "14px",
              fontFamily: IsRead ? "Gotham-Book" : "Gotham-Bold",
            }}
          >
            <div style={{ display: "list-item" }}>
              {!!UserCreator ? (
                <div style={{ maxWidth: "280px", overflow: "hidden" }}>
                  {!!UserCreator.ProfilePicture ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ color: "transparent", width: 0 }}>.</div>
                      <User
                        size="middle"
                        user={UserCreator}
                        nameColor="rgba(130, 141, 158, 1)"
                        nameStyle={!IsRead ? "bold" : ""}
                        picSize="25px"
                      />
                    </div>
                  ) : (
                    <User
                      size="middle"
                      user={UserCreator}
                      nameColor="rgba(130, 141, 158, 1)"
                      nameStyle={!IsRead ? "bold" : ""}
                      picSize="25px"
                    />
                  )}
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: "transparent", width: 0 }}>.</div>
                    <img
                      alt="Geest"
                      src={geestLogo}
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "12.5px",
                        marginLeft: -10,
                      }}
                    />
                    <div>Geest</div>
                  </div>
                </div>
              )}
            </div>
          </DataItem>
          <DataItem
            style={{
              flex: 1,
              overflow: "hidden",
              maxWidth: "calc(100% - 635px)",
              fontSize: "14px",
              fontFamily: "Gotham-Book",
              color: "rgba(130, 141, 158, 1)",
            }}
          >
            <Tooltip title={parseEmoji(Description)}>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "calc(100% - 3px)",
                }}
              >
                {parseEmoji(Description)}
              </div>
            </Tooltip>
          </DataItem>
          <DataItem
            style={{
              fontSize: "12px",
              fontFamily: "Gotham-Book",
              color: "rgba(130, 141, 158, 1)",
              justifyContent: "flex-end",
            }}
            $width={117}
          >
            <AiOutlineClockCircle size={14} />
            <div style={{ paddingTop: "2px" }}>
              {moment(Date).isValid() ? moment(Date).format("DD/MMM") : Date}
            </div>
            <Tooltip
              title={IsRead ? "Marcar como no leída" : "Marcar como leída"}
              placement="bottomLeft"
            >
              <ReadIconContainer
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeNotificationStatus(Id, !IsRead);
                }}
              >
                <img
                  alt="mail"
                  src={IsRead ? openedNotIcon : unopenedNotIcon}
                />
              </ReadIconContainer>
            </Tooltip>
          </DataItem>
        </ListItem>
      </CellMeasurer>
    );
  };

  const rowHeight = 42;

  return (
    <Container>
      <ViewTitle ShowTeamIcon>
        <Header>
          Notificaciones
          <SearchBar
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Buscar..."
          />
        </Header>
      </ViewTitle>
      {!!selectedPending && (
        <DetailPending
          idPending={selectedPending}
          onClose={() => setSelectedPending("")}
          onChangePending={(id) => setSelectedPending(String(id))}
        />
      )}
      <ContentHeader>
        <TabsContainer>
          <Tab
            $isSelected={list === "Unread"}
            onClick={() => onChangeList("Unread")}
          >
            No leídas
            {!!unreadCount && <CountBadge>{unreadCount}</CountBadge>}
          </Tab>
          <Tab $isSelected={list === "All"} onClick={() => onChangeList("All")}>
            Todas
          </Tab>
        </TabsContainer>
        <Button
          style={{ width: "fit-content" }}
          onClick={() =>
            markNotificationsAsRead({ body: { markAsUnread: list === "All" } })
          }
          disabled={!!!unreadCount && list !== "All"}
        >
          Marcar todo como {list === "All" && "no "}leído
        </Button>
      </ContentHeader>
      {filteredItems.length > 0 && (
        <ListCotainer>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={rowHeight}
                rowRenderer={renderNotification}
                rowCount={filteredItems.length}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        </ListCotainer>
      )}
    </Container>
  );
};

export default Notifications;
