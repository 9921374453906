import React, { useState } from "react";
import { StringInput, TagContainer } from "./Styles";
import { CloseIcon } from "../../../../../../../Pendings/styles";
import { Draggable } from "react-beautiful-dnd";

interface TagOptionProps {
  draggableId: string;
  index: number;
  label: string;
  editingOption: boolean;
  unsetEditingOption: () => void;
  setEditingOption: () => void;
  onUpdateOption: (newLabel: string) => void;
  onClose: () => void;
  disabled: boolean;
}

const TagOption: React.FC<TagOptionProps> = ({
  draggableId,
  index,
  label,
  editingOption,
  unsetEditingOption,
  setEditingOption,
  onUpdateOption,
  onClose,
  disabled,
}) => {
  const [newLabel, setNewLabel] = useState<string>(label);
  const [valueChanged, setValueChanged] = useState<boolean>(false);

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <TagContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {editingOption ? (
            <StringInput
              disabled={disabled}
              autoFocus
              value={newLabel}
              onChange={({ target: { value } }) => {
                setNewLabel(value);
                setValueChanged(true);
              }}
              onKeyDown={({ key }) => {
                if (key === "Enter") {
                  if (valueChanged) {
                    onUpdateOption(newLabel);
                  } else {
                    unsetEditingOption();
                  }
                }
                setValueChanged(false);
              }}
              onBlur={() => {
                if (valueChanged) {
                  onUpdateOption(newLabel);
                } else {
                  unsetEditingOption();
                }
                setValueChanged(false);
              }}
            />
          ) : (
            <div onClick={setEditingOption}>
              <p>{label}</p>
            </div>
          )}
          <CloseIcon size={15} color="#48505e" onClick={onClose} />
        </TagContainer>
      )}
    </Draggable>
  );
};

export default TagOption;
