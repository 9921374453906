import React, { useContext, useRef, useState } from "react";
import _ from "lodash";
import { Handle, NodeProps } from "reactflow";
import styled from "styled-components";
import { AiOutlineClockCircle, AiOutlineEye } from "react-icons/ai";
import { Row, Tooltip } from "antd";
import { defaultHandlers } from "./../shared/shared";
import { handlerStyles, Rectangle, StyledTag } from "./../shared/styles";
import { NodeData } from "../../Diagram.d";
import { getNodeStatusBackgroundColor, getStatusTag } from "../../helpers";
import { dateHourFormat } from "../../../../../../../Helpers";
import { Circle } from "../../../../../../../components/ColorPicker";
import { useElementOverflow, useMutation } from "../../../../../../../hooks";
import Toolbox from "./../Toolbox";
import { ToolboxActions } from "./../Toolbox/Toolbox.d";
import { FlowContext } from "../../Flow";
import UserList from "./UserList";
import ConfirmRestartModal from "../../ConfirmRestartModal";

const TaskRectangle = styled(Rectangle)`
  height: calc(90px + 24px); // Base 90 + Padding 24
`;
const Content = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  overflow: hidden;
  padding: 12px;
  position: relative;
`;
const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;
const Right = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Text = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const TaskLabel = styled.div`
  font-family: "Gotham-bold";
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const DatesContainer = styled.div`
  div > span {
    width: 85px;
    text-align: end;
  }
`;

const TaskNode: React.FC<NodeProps<NodeData>> = ({ id, data, selected }) => {
  const { label, status, currentTask, taskInfo } = data;
  const {
    stage,
    isRevision,
    idTaskValue,
    startedAt,
    endedAt,
    deadline,
    users,
  } = taskInfo!;
  const nodeBackground = getNodeStatusBackgroundColor(status);
  const statusTag = getStatusTag(status);

  // TaskLabel overflow
  const taskLabelRef = useRef<HTMLDivElement>(null);
  const isTaskLabelOverflow = useElementOverflow(taskLabelRef, "width");
  const [openTaskLabelTooltip, setOpenTaskLabelTooltip] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);

  // StageLabel overflow
  const stageLabelRef = useRef<HTMLSpanElement>(null);
  const isStageLabelOverflow = useElementOverflow(stageLabelRef, "width");
  const [openStageLabelTooltip, setOpenStageLabelTooltip] = useState(false);

  const { IdProcessExecution, onSelectTask, onRestartFlux } =
    useContext(FlowContext);

  const [restartExecutionFluxInNode, restarting] = useMutation<[]>({
    func: "Ver2-ExecutionFlux-refin",
    onSuccess: () => onRestartFlux(),
  });

  const showToolbox = !restarting && !(currentTask && !!startedAt && !endedAt);
  const showRestart = !(!!startedAt && !endedAt);

  const handleToolboxAction = (action: ToolboxActions): void => {
    if (!restarting) {
      switch (action) {
        case ToolboxActions.OPEN_PENDING_DETAIL:
          onSelectTask(idTaskValue);
          break;
        case ToolboxActions.RESTART_PROCESS:
          setConfirmVisible(true);
          break;
      }
    }
  };

  return (
    <TaskRectangle
      $isSelected={selected}
      $backgroundColor={nodeBackground}
      $currentTask={currentTask}
    >
      {confirmVisible && (
        <ConfirmRestartModal
          onConfirm={() => {
            restartExecutionFluxInNode({
              args: {
                IdNode: id,
                IdProcessExecution,
              },
            });
            setConfirmVisible(false);
          }}
          closeModal={() => setConfirmVisible(false)}
        />
      )}
      <Content>
        {isRevision && (
          <Tooltip
            title="Pendiente de revisión"
            placement="bottom"
            overlayInnerStyle={{
              borderRadius: "2px",
              padding: "6px 8px",
            }}
          >
            <AiOutlineEye
              style={{ position: "absolute", top: 12, left: 12 }}
              color="#0273e9"
              size={14}
            />
          </Tooltip>
        )}
        <Left>
          <UserList users={users} />
          <div>
            {stage && (
              <Row
                justify="center"
                align="middle"
                style={{ marginBottom: "6px" }}
                wrap={false}
              >
                <Circle
                  size={16}
                  style={{ marginRight: "5px" }}
                  color={stage.color}
                />
                <Tooltip
                  title={stage.label}
                  open={openStageLabelTooltip && isStageLabelOverflow}
                  onOpenChange={setOpenStageLabelTooltip}
                  overlayInnerStyle={{
                    borderRadius: "2px",
                    padding: "6px 8px",
                  }}
                >
                  <Text ref={stageLabelRef}>{stage.label}</Text>
                </Tooltip>
              </Row>
            )}
            <div>
              <StyledTag color={statusTag.color}>{statusTag.label}</StyledTag>
            </div>
          </div>
        </Left>
        <Right>
          <div>
            <Tooltip
              title={label}
              open={openTaskLabelTooltip && isTaskLabelOverflow}
              onOpenChange={setOpenTaskLabelTooltip}
              overlayInnerStyle={{
                borderRadius: "2px",
                padding: "6px 8px",
              }}
            >
              <TaskLabel ref={taskLabelRef}>{label}</TaskLabel>
            </Tooltip>
          </div>
          <DatesContainer>
            {!_.isEmpty(startedAt) && (
              <Row align="middle" justify="end">
                <AiOutlineClockCircle
                  style={{ marginBottom: "2px" }}
                  color="#1f9334"
                  size={13}
                />
                <span>{dateHourFormat(startedAt, true)}</span>
              </Row>
            )}
            {!_.isEmpty(deadline) && (
              <Row align="middle" justify="end">
                <AiOutlineClockCircle
                  style={{ marginBottom: "2px" }}
                  color="#db232c"
                  size={13}
                />
                <span>{dateHourFormat(deadline, true)}</span>
              </Row>
            )}
            {!_.isEmpty(endedAt) && (
              <Row align="middle" justify="end">
                <AiOutlineClockCircle
                  style={{ marginBottom: "2px" }}
                  color="#48505e"
                  size={13}
                />
                <span>{dateHourFormat(endedAt, true)}</span>
              </Row>
            )}
          </DatesContainer>
        </Right>
      </Content>
      {defaultHandlers.map((handler) => (
        <Handle
          style={handlerStyles}
          key={handler.id}
          {...handler}
          isConnectableStart={false}
        />
      ))}
      {showToolbox && (
        <Toolbox
          nodeId={id}
          onAction={handleToolboxAction}
          showDetail={!currentTask}
          showRestart={showRestart}
        />
      )}
    </TaskRectangle>
  );
};

export default TaskNode;
