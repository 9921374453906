import React, { useState } from "react";
import {
  ActionMetaData,
  InputStyled,
  ActionContent,
  ActionTitle,
  ActionDescription,
} from "./styles";
import { Row } from "antd";
import { EditIcon } from "../../../../../../components/hoverIcons";
import { Action } from "../EditAction.d";
import { ActionOption } from "../AutomationConfig.d";
import { useMutation } from "../../../../../../hooks";
import { useParams } from "react-router-dom";

interface ViewHeaderProps {
  selectedAction: Action | null;
  setSelectedAction: (action: Action) => void;
  icon: string;
  actionOptions?: ActionOption[];
  reloadHome: () => void;
  IdNode: number;
}

const ViewHeader: React.FC<ViewHeaderProps> = ({
  selectedAction,
  setSelectedAction,
  icon,
  actionOptions,
  reloadHome,
  IdNode,
}) => {
  const [editingTitle, setEditingTitle] = useState<boolean>(false);
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();

  const [updateAutomationActionTitle] = useMutation({
    func: "Ver2-Configurator-uaat",
    onSuccess: reloadHome,
  });

  const handleOnChangeTitle = () => {
    if (selectedAction?.IdAction !== 0) {
      updateAutomationActionTitle({
        args: {
          IdNode,
          IdProcessTemplate,
          IdTeam,
          Title: selectedAction?.Title,
          IdAction: selectedAction?.IdAction,
        },
      });
    }
    setEditingTitle(false);
  };

  return (
    <ActionMetaData>
      <img src={icon} width="40px" height="40px" alt="" />

      <ActionContent>
        {editingTitle ? (
          <InputStyled
            value={selectedAction?.Title}
            onChange={(e) => {
              if (selectedAction) {
                let actionAux = { ...selectedAction };
                actionAux.Title = e.target.value;
                setSelectedAction(actionAux);
              }
            }}
            onBlur={handleOnChangeTitle}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleOnChangeTitle();
              }
            }}
            onFocus={(e) => e.target.select()}
            autoFocus
          />
        ) : (
          <Row align="bottom">
            <ActionTitle>{selectedAction?.Title}</ActionTitle>
            <EditIcon size={32} filled onClick={() => setEditingTitle(true)} />
          </Row>
        )}
        <ActionDescription>
          {
            actionOptions?.find(
              ({ Action }) => Action === selectedAction?.Action
            )?.Label
          }
        </ActionDescription>
      </ActionContent>
    </ActionMetaData>
  );
};

export default ViewHeader;
