import { useHistory } from "react-router-dom";
import _, { noop as NOOP } from "lodash";

interface keymapShortcutValues {
  OPEN_HELP?: any;
  CLOSE_HELP?: any;
  REDIRECT_PENDINGS?: any;
  REDIRECT_PROCESSES?: any;
  REDIRECT_DATABASES?: any;
  REDIRECT_TRACKING?: any;
  REDIRECT_PIPELINE?: any;
  REDIRECT_DASHBOARDS?: any;
  REDIRECT_TEAMS?: any;
}

const useShortcuts = (
  shortcuts: string = "ALL",
  openModal: (open: boolean) => void = NOOP
) => {
  const history = useHistory();

  const keyMap: keymapShortcutValues = {
    OPEN_HELP: "shift+?",
    CLOSE_HELP: "esc",
    REDIRECT_PENDINGS: "g+m",
    REDIRECT_PROCESSES: "g+p",
    REDIRECT_DATABASES: "g+b",
    REDIRECT_TRACKING: "g+t",
    REDIRECT_PIPELINE: "g+f",
    REDIRECT_DASHBOARDS: "g+d",
    REDIRECT_TEAMS: "g+e",
  };

  const handlers: keymapShortcutValues = {
    OPEN_HELP: () => openModal(true),
    CLOSE_HELP: () => openModal(false),
    REDIRECT_PENDINGS: (event: any) => {
      history.push("/home/pendings");
    },
    REDIRECT_PROCESSES: (event: any) => {
      history.push("/home/templates");
    },
    REDIRECT_DATABASES: (event: any) => {
      history.push("/home/databases");
    },
    REDIRECT_TRACKING: (event: any) => {
      history.push("/home/reports/tracking");
    },
    REDIRECT_PIPELINE: (event: any) => {
      history.push("/home/reports/pipeline");
    },
    REDIRECT_DASHBOARDS: (event: any) => {
      history.push("/home/dashboards");
    },
    REDIRECT_TEAMS: (event: any) => {
      history.push("/home/teams");
    },
  };

  let returnKeyMap: keymapShortcutValues = { OPEN_HELP: keyMap.OPEN_HELP };
  let returnHandlers: keymapShortcutValues = { OPEN_HELP: handlers.OPEN_HELP };

  if (shortcuts.split(",").includes("ALL")) {
    returnKeyMap = _.cloneDeep(keyMap);
    returnHandlers = _.cloneDeep(handlers);
    return [returnKeyMap, returnHandlers];
  }

  shortcuts.split(",").forEach((shortcut) => {
    switch (shortcut.toUpperCase()) {
      case "REDIRECT_PENDINGS":
        returnKeyMap = {
          ...returnKeyMap,
          REDIRECT_PENDINGS: keyMap.REDIRECT_PENDINGS,
        };
        returnHandlers = {
          ...returnHandlers,
          REDIRECT_PENDINGS: handlers.REDIRECT_PENDINGS,
        };
        break;
      case "REDIRECT_PROCESSES":
        returnKeyMap = {
          ...returnKeyMap,
          REDIRECT_PROCESSES: keyMap.REDIRECT_PROCESSES,
        };
        returnHandlers = {
          ...returnHandlers,
          REDIRECT_PROCESSES: handlers.REDIRECT_PROCESSES,
        };
        break;
      case "REDIRECT_DATABASES":
        returnKeyMap = {
          ...returnKeyMap,
          REDIRECT_DATABASES: keyMap.REDIRECT_DATABASES,
        };
        returnHandlers = {
          ...returnHandlers,
          REDIRECT_DATABASES: handlers.REDIRECT_DATABASES,
        };
        break;
      case "REDIRECT_TRACKING":
        returnKeyMap = {
          ...returnKeyMap,
          REDIRECT_TRACKING: keyMap.REDIRECT_TRACKING,
        };
        returnHandlers = {
          ...returnHandlers,
          REDIRECT_TRACKING: handlers.REDIRECT_TRACKING,
        };
        break;
      case "REDIRECT_PIPELINE":
        returnKeyMap = {
          ...returnKeyMap,
          REDIRECT_PIPELINE: keyMap.REDIRECT_PIPELINE,
        };
        returnHandlers = {
          ...returnHandlers,
          REDIRECT_PIPELINE: handlers.REDIRECT_PIPELINE,
        };
        break;
      case "REDIRECT_DASHBOARDS":
        returnKeyMap = {
          ...returnKeyMap,
          REDIRECT_DASHBOARDS: keyMap.REDIRECT_DASHBOARDS,
        };
        returnHandlers = {
          ...returnHandlers,
          REDIRECT_DASHBOARDS: handlers.REDIRECT_DASHBOARDS,
        };
        break;
      case "REDIRECT_TEAMS":
        returnKeyMap = {
          ...returnKeyMap,
          REDIRECT_TEAMS: keyMap.REDIRECT_TEAMS,
        };
        returnHandlers = {
          ...returnHandlers,
          REDIRECT_TEAMS: handlers.REDIRECT_TEAMS,
        };
        break;
    }
  });

  return [returnKeyMap, returnHandlers];
};

export default useShortcuts;
