import React from "react";
import { ViewMoreIconImg } from "./styles";

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  colored?: boolean;
}

const ViewMoreIcon: React.FC<IconProps> = ({ colored, ...props }) => (
  <ViewMoreIconImg size={20} colored {...props} />
);

export default ViewMoreIcon;
