import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .sigCanvas {
    border-radius: 6px;
    border: 2px solid #edecec;
    position: relative;
  }
`;

export const PlaceholderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 42px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  p {
    margin: 0;
    text-align: center;
    color: #828d9e;
    font-family: Gotham-Book;
  }
`;

export const CanvasWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PrevSignatureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }
`;

export const ClearButton = styled.div<{ isEdited: boolean }>`
  opacity: ${({ isEdited = false }) => (isEdited ? 1 : 0)};
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  margin: 0 auto;
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
