import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import { Languages } from "./Dictionary";
import styled from "styled-components";
import { useMutation } from "../../hooks";
import { Switch } from "antd";
import { Button } from "../../GeestUI";
import { Alert } from "@material-ui/lab";

const Description = styled.div`
  color: #828d9e;
  font-size: 14px;
  text-align: center;
  width: 309px;
`;

const ChecklistsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  margin-top: 12px;
`;

const ChecklistElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  label {
    color: #828d9e;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 14px;
`;

interface DuplicateModalProps {
  IdProcessTemplate: string;
  IdTeam: string;
  open: boolean;
  onDuplicate: (importedVardb: any[], varDBsInTemplate: VarDB[]) => void;
  onCancel: () => void;
  TemplateName: string;
}

interface VarDB {
  IdVardb?: number;
  Title: string;
  Checked?: boolean;
  ProcessTemplateIdVarDB?: number;
  TeamDestinyIdVarDB?: number;
}

const DuplicateModal: React.FC<DuplicateModalProps> = ({
  IdProcessTemplate,
  IdTeam,
  open,
  onDuplicate,
  onCancel,
  TemplateName,
}) => {
  const [varDBsInTemplate, setVarDBsInTemplate] = useState<VarDB[]>([]);
  const [vardbsToCopy, setVardbsToCopy] = useState<any[]>([]);
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const {
    DuplicateModalTitle,
    DuplicateModalDescription,
    DuplicateModalDescriptionVardbs,
    ConfirmLabel,
  } = Languages["ESP"];

  const [getVarDBsInTemplate] = useMutation<VarDB[]>({
    func: "Ver2-Processes-gvitfc",
    onSuccess: (res) => {
      setVarDBsInTemplate(res);
    },
  });

  useEffect(() => {
    if (open) {
      getVarDBsInTemplate({
        args: {
          IdTeam: IdTeam,
          IdTeamDestiny: IdTeam,
          IdProcessTemplate: IdProcessTemplate,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOnCheck = (idx: number, checked: boolean) => {
    let checks = { ...varDBsInTemplate };
    let toCopy = [] as any[];
    let copyVarDBsInTemplate = [...varDBsInTemplate];

    checks = copyVarDBsInTemplate.map((vdb, i) => ({
      ...vdb,
      Checked: i === idx ? checked : vdb.Checked,
    }));

    toCopy = checks.map((c) => {
      return {
        ProcessTemplateIdVarDB: c.ProcessTemplateIdVarDB,
        TeamDestinyIdVarDB: c.Checked ? 0 : c.TeamDestinyIdVarDB,
      };
    });

    setVarDBsInTemplate(checks);
    setVardbsToCopy(toCopy);
  };

  const handleDuplicate = () => {
    onDuplicate(vardbsToCopy, varDBsInTemplate);
    onCancel();
    setVarDBsInTemplate([]);
    setVardbsToCopy([]);
  };

  const hasChecked = !!varDBsInTemplate.find(({ Checked }) => Checked);
  return (
    <Modal
      visible={open}
      title={confirmed ? "Duplicar bases de datos" : DuplicateModalTitle}
      width="357px"
      onCancel={onCancel}
    >
      <Container>
        <Description>
          {varDBsInTemplate.length > 0 && confirmed
            ? DuplicateModalDescriptionVardbs
            : DuplicateModalDescription(TemplateName)}
        </Description>

        {varDBsInTemplate.length > 0 && confirmed && (
          <ChecklistsContainer>
            {varDBsInTemplate.map(({ IdVardb, Title }, idx) => {
              return (
                <ChecklistElement key={IdVardb}>
                  <label htmlFor={`check-${idx}`} style={{ flex: 1 }}>
                    {Title}
                  </label>
                  <Switch
                    checked={varDBsInTemplate[idx].Checked}
                    onChange={(checked) => {
                      handleOnCheck(idx, checked);
                    }}
                    style={{
                      backgroundColor: varDBsInTemplate[idx].Checked
                        ? "#0273E9"
                        : "#828D9E",
                      height: "22px",
                      fontSize: "12px",
                      fontFamily: "Gotham-Book",
                      minWidth: "113px",
                    }}
                    checkedChildren="Se duplicará"
                    unCheckedChildren="No se duplicará"
                  />
                </ChecklistElement>
              );
            })}
          </ChecklistsContainer>
        )}
        {confirmed && hasChecked && (
          <Alert severity="warning" style={{ marginTop: "24px" }}>
            Cuidado. Se duplicarán las bases de datos seleccionadas. Eso
            generará nuevas bases de datos, solo procede si estás seguro
          </Alert>
        )}
        <ButtonBox>
          {confirmed && (
            <Button type="secondary" onClick={onCancel}>
              Cancelar
            </Button>
          )}
          <Button
            type="primary"
            onClick={
              varDBsInTemplate.length > 0 && !confirmed
                ? () => setConfirmed(true)
                : handleDuplicate
            }
          >
            {ConfirmLabel}
          </Button>
        </ButtonBox>
      </Container>
    </Modal>
  );
};

export default DuplicateModal;
