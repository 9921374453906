import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/LoginFooter/Footer";

// Routes
import routes from "./../routes";

const Routes = routes.map((r, key) => {
  if (r.layout === "/auth") {
    return (
      <Route
        path={r.layout + r.path}
        component={r.component}
        key={key}
        exact={r.exact}
      />
    );
  } else {
    return null;
  }
});

const AuthLayout = () => {
  return (
    <div
      style={{
        backgroundColor: "#EAEAEA",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Switch>
        {Routes}
        <Redirect from="/auth" to="/auth/login" />
      </Switch>
      <div style={{ minHeight: "25%" }}>
        <Footer />
      </div>
    </div>
  );
};

export default AuthLayout;
