import React from "react";
import { Progress, Row, Tooltip } from "antd";
import { Languages } from "./Dictionary";
import { StyledTooltip, Circle } from "./Styles";

const { progressLabel, ProgressTooltipLabel } = Languages["ESP"];

interface DashboardGraphicProps {
  data: { Count: number; ProgressPercentage: number };
  width: number;
  redStatusPercent?: number;
  yellowStatusPercent?: number;
  greenStatusPercent?: number;
}

const DashboardGraphic: React.FC<DashboardGraphicProps> = ({
  data = { Count: 0, ProgressPercentage: 0 },
  width,
  redStatusPercent = 25,
  yellowStatusPercent = 50,
  greenStatusPercent = 100,
}) => {
  const progress = data.ProgressPercentage < 0 ? 0 : data.ProgressPercentage;

  const getTooltipColor = () => {
    let firstColor;
    let lastColor;
    if (progress <= redStatusPercent) {
      firstColor = "rgba(194,50,40,255)";
      lastColor = "rgba(234,174,60,255)";
    } else if (progress <= yellowStatusPercent) {
      firstColor = "rgba(224,233,71,255)";
      lastColor = "rgba(177,229,73,255)";
    } else if (progress <= greenStatusPercent) {
      firstColor = "rgba(67,162,67,255)";
      lastColor = "rgba(50,149,65,255)";
    }
    return `linear-gradient(to right, ${firstColor},${lastColor})`;
  };

  const getCircleColor = () => {
    let firstColor;
    let lastColor;
    if (progress <= redStatusPercent) {
      firstColor = "rgba(194,50,40,255)";
      lastColor = "rgba(234,174,60,255)";
    } else if (progress <= yellowStatusPercent) {
      firstColor = "rgba(224,233,71,255)";
      lastColor = "rgba(177,229,73,255)";
    } else if (progress <= greenStatusPercent) {
      firstColor = "rgba(67,162,67,255)";
      lastColor = "rgba(50,149,65,255)";
    } else {
      firstColor = "rgba(67,162,67,255)";
      lastColor = "rgba(50,149,65,255)";
    }
    return {
      "0%": firstColor,
      "100%": lastColor,
    };
  };

  const getTruncatedNumberOfProcesses = () => {
    if (data.Count >= 1000) {
      return (data.Count / 1000).toFixed(1).toString() + "k";
    }
    return data.Count.toString();
  };

  return (
    <StyledTooltip $background={getTooltipColor()}>
      <Tooltip
        title={`${ProgressTooltipLabel} ${progress}%`}
        getPopupContainer={(triggerNode) => triggerNode}
        placement="right"
      >
        <Progress
          percent={progress}
          type="circle"
          width={width}
          strokeColor={getCircleColor()}
          strokeLinecap="butt"
          format={() => (
            <Row justify="center" align="middle">
              <Circle
                $width={`${width * (13 / 15)}px`}
                $height={`${width * (13 / 15)}px`}
                $borderRadius={`${width * (13 / 30)}px`}
                $padding={`${width * (8 / 50)}px`}
                $boxShadow={`0px 0px ${width * (1 / 12)}px ${
                  width * (1 / 100)
                }px rgba(114, 126, 139, 0.25)`}
              >
                <div>
                  <span
                    style={{
                      fontSize: `${
                        width *
                        (1 /
                          (4 +
                            (getTruncatedNumberOfProcesses().length - 2) * 0.5))
                      }px`,
                    }}
                  >
                    {data.Count}
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: `${width * (1 / 13)}px` }}>
                    {progressLabel}
                  </span>
                </div>
              </Circle>
            </Row>
          )}
        />
      </Tooltip>
    </StyledTooltip>
  );
};

export default DashboardGraphic;
