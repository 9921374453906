import React, { useState, useRef, useEffect } from "react";
import { Row, Space } from "antd";
import Modal from "../../../components/Modal";
import { Button } from "../../../GeestUI";
import { StyledInput } from "../Flow/styles";
import { CenteredTitle, Paragraph, ProcessNameLabel } from "./styles";

interface DeleteProcessModalProps {
  processName: string;
  deleting: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteProcessModal: React.FC<DeleteProcessModalProps> = ({
  processName,
  deleting,
  onClose,
  onDelete,
}) => {
  const [confirmProcessName, setConfirmProcessName] = useState("");
  const isValidForm = processName === confirmProcessName;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const handleOnDeleteProcess: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    if (isValidForm) onDelete();
  };

  return (
    <Modal title="Eliminar proceso" onCancel={onClose}>
      <Space direction="vertical" size={12} style={{ width: "100%" }}>
        <CenteredTitle>¿Seguro que deseas eliminar este proceso?</CenteredTitle>
        <div style={{ margin: "0px 15px" }}>
          <Paragraph>
            Esta acción es irreversible y eliminará permanentemente todos los
            datos almacenados. Si estás seguro que deseas continuar, por favor
            ingresa el nombre de la base de datos:
          </Paragraph>
          <Row justify="center">
            <ProcessNameLabel>{processName}</ProcessNameLabel>
          </Row>
        </div>
        <div style={{ margin: "0px 15px" }}>
          <StyledInput
            placeholder="Escribe aquí..."
            disabled={deleting}
            value={confirmProcessName}
            onChange={(e) => setConfirmProcessName(e.target.value)}
            ref={inputRef}
          />
        </div>
        <Row justify="center">
          <Button
            loading={deleting}
            type="secondary"
            style={{ marginRight: "8px" }}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            loading={deleting}
            type="primary"
            danger
            disabled={!isValidForm}
            onClick={handleOnDeleteProcess}
          >
            Eliminar
          </Button>
        </Row>
      </Space>
    </Modal>
  );
};

export default DeleteProcessModal;
