import React from "react";
import { Space } from "antd";
import { PriorityDataOriginType } from "../DynamicCell.d";
import { Tag } from "../../../../../GeestUI";
import styled from "styled-components";

const Container = styled.div<{ $justify: "start" | "center" }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ $justify }) => $justify};
`;

const StyledTag = styled(Tag)<{ isSelected?: boolean }>`
  margin-right: 0;

  width: ${({ isSelected }) => (isSelected ? "120px" : "auto")};
`;

const TagContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface PriorityComponentProps {
  value: number | undefined | null;
  priorityDataOrigin: PriorityDataOriginType[];
  justify: "start" | "center";
  isSelected?: boolean;
}

const PriorityComponent: React.FC<PriorityComponentProps> = ({
  value,
  priorityDataOrigin,
  justify,
  isSelected = false,
}) => {
  const getPriorityInfo = (priority: number | undefined | null) => {
    const priorityInfo = priority
      ? priorityDataOrigin.find((p) => p.IdTaskPriority === priority)
      : null;
    if (!priorityInfo)
      return { Color: "default", Title: priority, Value: priority };
    return priorityInfo;
  };

  return (
    <Container $justify={justify}>
      <Space wrap={false}>
        {value && (
          <StyledTag
            color={getPriorityInfo(value).Color}
            isSelected={isSelected}
          >
            <TagContent>
              <span>{getPriorityInfo(value).Title}</span>
            </TagContent>
          </StyledTag>
        )}
      </Space>
    </Container>
  );
};

export default PriorityComponent;
