import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { IoIosArrowBack } from "react-icons/io";
import Measurements from "./Measurements";
import EditMeasurements from "./EditMeasurements";
import StatsConfigWizard from "./StatsConfigWizard";
import { useMutation } from "../../../../../hooks";
import {
  Step1Response,
  Process,
  StatsModalProps,
  DateOptionValue,
} from "./StatsModal.d";
import { useParams } from "react-router-dom";
import Modal from "../../../../../components/Modal";
import { MessagesContext } from "../../../../../components/AppMessages";
import BeforeCreateMeasurement from "./Modals/BeforeCreateMeasurement";
import UnsavedChangesModal from "../UnsavedChangesModal";

const Title = styled.div<{ $fontSize?: string }>`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  color: #48505e;
  font-family: Gotham-Bold;
  font-size: ${({ $fontSize = "14px" }) => $fontSize};
`;

const StatsModal: React.FC<StatsModalProps> = ({
  onClose,
  selectedTemplate,
  graphToEdit,
}) => {
  const [state, setState] = useState<string>("Wizard");
  const [selectedStat, setSelectedStat] = useState<number>(0);
  const [idProcessTemplate, setIdProcessTemplate] = useState<number>();
  const [tableName, setTableName] = useState<string>("");
  const [wizardStep, setWizardStep] = useState<number>(0);
  const [processOptions, setProcesssOptions] = useState<Process[]>([]);
  const [groupByField, setGroupByField] = useState<number | undefined>();
  const [dateOption, setDateOption] = useState<DateOptionValue | undefined>();
  const [stats, setStats] = useState<number[]>([]);
  const [prevEditState, setPrevEditState] = useState<string>("Measurements");
  const [idGraph, setIdGraph] = useState<string>(
    graphToEdit?.IdGraph ? `${graphToEdit?.IdGraph}` : ""
  );
  const [openBeforeCreateMeasurement, setOpenBeforeCreateMeasurement] =
    useState<boolean>(false);
  const [defaultMeasurementName, setDefaultMeasurementName] =
    useState<string>("");
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] =
    useState<boolean>(false);
  const { showMessage } = useContext(MessagesContext);

  const { ids } = useParams<{ ids: string }>();
  const [, idDashboard] = ids?.split("-");

  const [getConfigurationWizard] = useMutation<Step1Response>({
    func: "Ver2-DashBoards-ggcw",
    onSuccess: ({ IdProcessTemplate, GraphName, Processes, Stats }) => {
      setIdProcessTemplate(IdProcessTemplate);
      setTableName(GraphName);
      setProcesssOptions(Processes);
      setStats(Stats ?? []);
      setFirstTime(firstTime && Stats === undefined);
    },
  });

  useEffect(() => {
    getConfigurationWizard({
      args: {
        IdDashBoard: idDashboard,
        IdGraph: idGraph,
        Step: 1,
        IdGraphTemplate: selectedTemplate,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStats([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProcessTemplate]);

  const templateName =
    processOptions.find(
      ({ IdProcessTemplate }) => IdProcessTemplate === idProcessTemplate
    )?.Name || "";

  const onAddStat = (IdStat: number) => {
    setUnsavedChanges(true);
    if (!stats.includes(IdStat)) {
      let statsAux = [...stats];
      statsAux.push(IdStat);
      setStats(statsAux);
      showMessage("Medición agregada", "success");
    }
  };

  const onRemoveStat = (IdStat: number) => {
    setUnsavedChanges(true);
    const statIndex = stats.findIndex((idStat) => idStat === IdStat);
    let statsAux = [...stats];
    statsAux.splice(statIndex, 1);
    setStats(statsAux);
  };

  const onEditStat = (IdStat: number) => {
    setUnsavedChanges(true);
    setSelectedStat(IdStat);
    setState("EditMeasurementes");
    setPrevEditState("Wizard");
  };

  const handleOnClose = (submit = false) => {
    if (unsavedChanges && !submit) {
      setOpenUnsavedChangesModal(true);
    } else {
      onClose();
    }
  };

  const States: {
    [key: string]: {
      Title: React.ReactNode;
      Content: React.ReactNode;
    };
  } = {
    Measurements: {
      Title: (
        <Title $fontSize="16px">
          <IoIosArrowBack
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() => setState("Wizard")}
          />
          Agregar columna
        </Title>
      ),
      Content: (
        <Measurements
          setState={setState}
          setSelectedStat={setSelectedStat}
          IdProcessTemplate={idProcessTemplate}
          TableName={tableName}
          TemplateName={templateName}
          onAddStat={onAddStat}
          setPrevEditState={setPrevEditState}
          stats={stats}
          setFirstTime={setFirstTime}
          onRemoveStat={onRemoveStat}
          openBeforeCreateMeasurement={() =>
            setOpenBeforeCreateMeasurement(true)
          }
        />
      ),
    },
    EditMeasurementes: {
      Title: (
        <Title $fontSize="16px">
          <IoIosArrowBack
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setState(prevEditState);
              setDefaultMeasurementName("");
            }}
          />
          {`${selectedStat === 0 ? "Crear" : "Editar"} medición`}
        </Title>
      ),
      Content: (
        <EditMeasurements
          IdStat={selectedStat}
          IdProcessTemplate={idProcessTemplate}
          TableName={tableName}
          setState={setState}
          onAddStat={onAddStat}
          prevEditState={prevEditState}
          defaultMeasurementName={defaultMeasurementName}
          setDefaultMeasurementName={setDefaultMeasurementName}
          setFirstTime={setFirstTime}
        />
      ),
    },
    Wizard: {
      Title: (
        <Title $fontSize="16px">
          {wizardStep > 0 && (
            <IoIosArrowBack
              size={20}
              onClick={() => setWizardStep(wizardStep - 1)}
              style={{ cursor: "pointer" }}
            />
          )}
          Tabla de estadísticas
        </Title>
      ),
      Content: (
        <StatsConfigWizard
          IdProcessTemplate={idProcessTemplate}
          TableName={tableName}
          IdGraph={idGraph}
          setIdGraph={setIdGraph}
          TemplateName={templateName}
          setIdProcessTemplate={setIdProcessTemplate}
          setTableName={setTableName}
          activeStep={wizardStep}
          setActiveStep={setWizardStep}
          processOptions={processOptions}
          setState={setState}
          groupByField={groupByField}
          setGroupByField={setGroupByField}
          dateOption={dateOption}
          setDateOption={setDateOption}
          IdGraphTemplate={selectedTemplate}
          stats={stats}
          setStats={setStats}
          firstTime={firstTime}
          setFirstTime={setFirstTime}
          onRemoveStat={onRemoveStat}
          onEditStat={onEditStat}
          onClose={handleOnClose}
          setUnsavedChanges={() => setUnsavedChanges(true)}
        />
      ),
    },
  };

  return (
    <>
      {openUnsavedChangesModal && (
        <UnsavedChangesModal
          onCancel={() => setOpenUnsavedChangesModal(false)}
          onConfirm={onClose}
        />
      )}
      <Modal
        title={States[state]?.Title}
        onCancel={() => handleOnClose()}
        width="1113px"
        bodyStyle={{
          height: "659px",
        }}
      >
        {openBeforeCreateMeasurement && (
          <BeforeCreateMeasurement
            closeModal={() => setOpenBeforeCreateMeasurement(false)}
            defaultMeasurementName={defaultMeasurementName}
            setDefaultMeasurementName={setDefaultMeasurementName}
            openMeasurementsView={() => {
              setOpenBeforeCreateMeasurement(false);
              setState("EditMeasurementes");
              setUnsavedChanges(true);
            }}
          />
        )}
        {States[state]?.Content}
      </Modal>
    </>
  );
};

export default StatsModal;
