import React, { useContext } from "react";
import { SesionContext } from "../../config/State";
import LoginHeader from "./LoginHeader";
import UserHeader from "./UserHeader";

const Header = () => {
  const [session] = useContext(SesionContext);
  const HeaderComponent = !!session ? UserHeader : LoginHeader;

  return <HeaderComponent />;
};

export default Header;
