import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";
import { noop } from "lodash";

const StyledButton = styled.button<{ $loading: boolean }>`
  background-color: #0273e9;
  color: #ffffff;
  border-radius: 6px;
  font-size: 14px;
  font-family: "Gotham-bold";
  border: none;
  width: 100%;
  padding: 6px 0;
  cursor: pointer;
  margin-bottom: 14px;
  :hover {
    background-color: #014c9b;
  }
  :focus {
    background-color: #1939b7;
  }
  :disabled {
    background-color: ${({ $loading }) => ($loading ? "#0273e9" : "#828d9e")};
    cursor: not-allowed;
  }
`;

interface ISubmitButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const SubmitButton = ({
  loading = false,
  children,
  ...buttonProps
}: ISubmitButton) => {
  return (
    <StyledButton
      {...buttonProps}
      disabled={buttonProps.disabled || loading}
      $loading={loading}
      onClick={loading ? noop : buttonProps.onClick}
    >
      {loading ? <LoadingOutlined /> : children}
    </StyledButton>
  );
};

export default SubmitButton;
