import { Tooltip } from "antd";
import React from "react";

interface ActionTooltipProps {
  title: string;
}

const ActionTooltip: React.FC<ActionTooltipProps> = ({ children, title }) => {
  return (
    <Tooltip
      title={title}
      overlayInnerStyle={{
        borderRadius: "2px",
        padding: "6px 8px",
      }}
    >
      {children}
    </Tooltip>
  );
};

export default ActionTooltip;
