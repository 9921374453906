import React from "react";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import { UsersType } from "../DynamicFilter.d";
import User from "../../User";

interface UserSelectFilterProps {
  DataOrigin: UsersType[];
  onChangeFilter: (value: (string | number | undefined | null)[]) => void;
  value: string | number | undefined | null;
  placeholder: string;
}

const UsersSelectFilter: React.FC<UserSelectFilterProps> = ({
  DataOrigin,
  onChangeFilter,
  value,
  placeholder,
}) => {
  const transformedOptions = (() =>
    DataOrigin.map((usr) => {
      return {
        value: `${usr.IdUser}`,
        searchableValues: `${usr.FirstName},${usr.LastName},${usr.Email}`,
        label: <User user={usr} size="middle" placement="bottom" />,
      };
    }))();
  return (
    <GeestSelect
      options={transformedOptions}
      onChange={onChangeFilter}
      multiselect
      value={value}
      $width="285px"
      placeholderSelect={placeholder}
    />
  );
};

export default UsersSelectFilter;
