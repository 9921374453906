import React, { useContext, useEffect, useState } from "react";
import { NodeToolbar, Position } from "reactflow";
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useParams } from "react-router-dom";
import {
  GetProcessStages,
  Stage,
  ToolboxActions,
  ToolboxProps,
} from "./Toolbox.d";
import { HiMenuAlt2 } from "react-icons/hi";
import {
  iconSize,
  Arrow,
  Toolbar,
  IconContainer,
  IconContainerDanger,
} from "./styles";
import StageSelector from "./StageSelector";
import { useNode, useTooltipActions } from "../shared/shared";
import { CustomNodeTypes } from "../../CustomNodes";
import { useMutation } from "../../../../../hooks";
import { ConfiguratorContext } from "../../../Configurator";
import ActionTooltip from "./ActionTooltip";

const Toolbox: React.FC<ToolboxProps> = ({
  nodeId,
  onAction,
  onDeleteAttempt,
}) => {
  const { refreshProcessInformation } = useContext(ConfiguratorContext);
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const {
    type,
    selected,
    data: { stageColor, stageId, onChangeNodeStage },
  } = useNode(nodeId);
  const { showEdit, showStageSelector, showFields, showDuplicate, showDelete } =
    useTooltipActions(type as CustomNodeTypes);
  const [stages, setStages] = useState<Stage[]>([]);

  useEffect(() => {
    if (selected && showStageSelector)
      getStages({
        args: {
          IdProcessTemplate,
          IdTeam,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const [getStages] = useMutation<GetProcessStages>({
    func: "Ver2-Configurator-gps",
    onSuccess: setStages,
  });
  const [linkTaskToProcessStage] = useMutation<[]>({
    func: "Ver2-Configurator-lttps",
    onSuccess: refreshProcessInformation,
    onError: () =>
      onChangeNodeStage(nodeId, {
        stageColor,
        stageId,
      }),
  });

  const handleOnChangeStage = (stage: Stage) => {
    onChangeNodeStage(nodeId, {
      stageColor: stage.Color,
      stageId: stage.Id,
    });
    linkTaskToProcessStage({
      args: {
        IdProcessTemplate,
        IdTeam,
        IdNode: nodeId,
        IdStage: stage.Id,
      },
    });
  };

  return (
    <NodeToolbar nodeId={nodeId} position={Position.Bottom}>
      <Arrow />
      <Toolbar>
        {showEdit && (
          <ActionTooltip title="Editar">
            <IconContainer
              onClick={() => onAction(ToolboxActions.Edit, nodeId)}
            >
              <AiOutlineEdit size={iconSize} />
            </IconContainer>
          </ActionTooltip>
        )}
        {showStageSelector && (
          <StageSelector
            activeStageId={stageId}
            activeStageColor={stageColor}
            stages={stages}
            onChangeStage={handleOnChangeStage}
          />
        )}
        {showFields && (
          <ActionTooltip title="Campos">
            <IconContainer
              onClick={() => onAction(ToolboxActions.Fields, nodeId)}
            >
              <HiMenuAlt2 size={iconSize} />
            </IconContainer>
          </ActionTooltip>
        )}
        {showDuplicate && (
          <ActionTooltip title="Duplicar">
            <IconContainer
              onClick={() => onAction(ToolboxActions.Duplicate, nodeId)}
            >
              <AiOutlineCopy size={iconSize} />
            </IconContainer>
          </ActionTooltip>
        )}
        {showDelete && (
          <ActionTooltip title="Eliminar">
            <IconContainerDanger onClick={onDeleteAttempt}>
              <AiOutlineDelete size={iconSize} />
            </IconContainerDanger>
          </ActionTooltip>
        )}
      </Toolbar>
    </NodeToolbar>
  );
};

export default Toolbox;
