import React, { useState } from "react";
import { HiMenuAlt2 } from "react-icons/hi";
import { Button } from "../../../../GeestUI";
import ProcessFields from "./ProcessFields";

const EditFields: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <>
      <Button
        type="secondary"
        Icon={HiMenuAlt2}
        onClick={() => setOpenModal(true)}
        style={{ height: "36px", width: "220px" }}
      >
        Campos de formulario
      </Button>
      {openModal && <ProcessFields onClose={() => setOpenModal(false)} />}
    </>
  );
};

export default EditFields;
