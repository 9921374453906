import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import { Row } from "antd";

interface SharedProps {
  $showExtra?: boolean;
  $height?: string;
  $maxHeight?: string;
  $width?: string;
  $secondaryHeight?: string;
  $secondaryWidth?: string;
  $padding?: string;
}

export const StyledRow = styled(Row)<{ $mt?: string }>`
  height: 100%;
  width: 100%;
  margin-top: ${({ $mt = "0" }) => $mt};
`;

export const MainModal = styled(Paper)<SharedProps>`
  width: ${({ $width = "786px" }) => $width};
  max-width: 60%;
  height: ${({ $height = "700px" }) => $height};
  max-height: ${({ $maxHeight = "95vh" }) => $maxHeight};
  border-radius: 6px !important;
  padding: ${({ $padding = "0" }) => $padding};
  animation: all 1s ease;
`;

export const SecondaryModal = styled(MainModal)<SharedProps>`
  height: ${({ $secondaryHeight = "700px" }) => $secondaryHeight};
  max-height: ${({ $maxHeight = "95vh" }) => $maxHeight};
  width: ${({ $secondaryWidth = "449px" }) => $secondaryWidth};
  max-width: 40%;
  margin-left: 8px;
`;
