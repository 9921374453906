import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Background = styled.div`
  background: #283c86;
  background: -webkit-linear-gradient(to top, #6afc6d, #1d4eff);
  background: linear-gradient(to top, #6afc6d, #1d4eff);
  -webkit-box-shadow: inset 0px 0px 53px 65px rgba(255, 255, 255, 1);
  -moz-box-shadow: inset 0px 0px 53px 65px rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 53px 65px rgba(255, 255, 255, 1);
  height: calc(100% - 1rem);
  width: calc(100% - 1rem);
  display: flex;
  position: absolute;
`;

const Text = styled.h1`
  margin: auto;
  color: white;
  font-size: 36px;
  font-family: "Gotham-Bold";
`;

const Languages = {
  ENG: {
    LoadingLabel: "Loading",
  },
  ESP: {
    LoadingLabel: "Cargando",
  },
};

const LoadingState = ({ isLoading = false }) => {
  const [LoadingStatus, setLoadingStatus] = useState(3);
  const { LoadingLabel } = Languages["ESP"];

  useEffect(() => {
    const interval = setInterval(() => {
      if (LoadingStatus === 3) setLoadingStatus(0);
      else setLoadingStatus(LoadingStatus + 1);
    }, 200);
    return () => clearInterval(interval);
  }, [LoadingStatus]);

  const getPoints = () => {
    let res = "";
    for (let n = 0; n < LoadingStatus; n++) {
      res += ".";
    }
    return res;
  };

  if (!isLoading) return null;

  return (
    <Background>
      <Text>
        {LoadingLabel}
        {getPoints()}
      </Text>
    </Background>
  );
};

export default LoadingState;
