import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";
import _ from "lodash";
import { NodeEditorProps } from "../NodeEditor.d";
import {
  GetTaskDetailAndConfigParams,
  ActivePageProps,
  Task,
  TaskEditorPages,
} from "./TaskEditor.d";
import { StyledBody, StyledDrawer } from "./styles";
import { useFetch, useMutation } from "../../../../../hooks";
import TabsHeader from "./TabsHeader";
import GeneralPage from "./GeneralPage";
import SchedulePage from "./SchedulePage/SchedulePage";
import { updateNodeFromTask } from "../../helpers";

const ActivePage: React.FC<ActivePageProps> = ({
  activePage,
  userList,
  reportIncomeFields,
  taskReportingIncome,
  setTaskReportingIncome,
  timesPreconf,
  timesRefDateForStart,
  timesRefDateForDeadline,
  ...props
}) => {
  switch (activePage) {
    case TaskEditorPages.GENERAL:
      return (
        <GeneralPage
          {...props}
          userList={userList}
          reportIncomeFields={reportIncomeFields}
          taskReportingIncome={taskReportingIncome}
          setTaskReportingIncome={setTaskReportingIncome}
        />
      );
    case TaskEditorPages.SCHEDULE:
      return (
        <SchedulePage
          {...props}
          timesPreconf={timesPreconf}
          timesRefDateForStart={timesRefDateForStart}
          timesRefDateForDeadline={timesRefDateForDeadline}
        />
      );
    case TaskEditorPages.FIELDS:
      return <div>Fields</div>;
  }
};

const TaskEditor: React.FC<NodeEditorProps> = ({
  node,
  onClose,
  openTaskFields,
}) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const { getNodes, setNodes } = useReactFlow();
  const [page, setPage] = useState<TaskEditorPages>(TaskEditorPages.GENERAL);
  const [task, setTask] = useState<Task | null>(null);
  const [taskReportingIncome, setTaskReportingIncome] = useState<number | null>(
    null
  );

  const { data, reload } = useFetch<GetTaskDetailAndConfigParams>({
    func: "Ver2-Configurator-gtdcp",
    args: { IdTeam, IdProcessTemplate, IdNode: node.id },
    onSuccess: ({ Task, TaskReportingIncome }) => {
      setTask(Task);
      setTaskReportingIncome(TaskReportingIncome);
    },
  });

  const [updateTaskDetails] = useMutation<Task>({
    func: "Ver2-Configurator-utd",
    onSuccess: (response) => {
      setNodes(updateNodeFromTask(getNodes(), node.id, response));
    },
    onError: reload,
  });

  // Must be used for onBlur inputs
  const handleOnSave = (updatedTask: Task = task as Task) =>
    updateTaskDetails({
      args: {
        IdTeam,
        IdProcessTemplate,
        IdNode: node.id,
        NodeTitle: _.trim(updatedTask.Title),
        Task: {
          ...updatedTask,
          UsersRevisers: (updatedTask.UsersRevisers || []).map(Number),
          DeadlineSchedTime_TimeToAdd: Number(
            updatedTask.DeadlineSchedTime_TimeToAdd
          ),
          StartSchedTime_TimeToAdd: Number(
            updatedTask.StartSchedTime_TimeToAdd || "0"
          ),
        },
      },
    });
  // Must be used for text/number inputs where change should be saved until onBlur.
  const handleOnChange = setTask;
  // Must be used on selects/switches when value changes.
  const handleOnChangeAndSave = (updatedTask: Task) => {
    setTask(updatedTask);
    handleOnSave(updatedTask);
  };

  const handleOnChangePage = (newPage: TaskEditorPages) => {
    switch (newPage) {
      case TaskEditorPages.GENERAL:
      case TaskEditorPages.SCHEDULE:
        setPage(newPage);
        break;
      case TaskEditorPages.FIELDS:
        openTaskFields(node);
        break;
    }
  };

  if (!task) return null;

  return (
    <StyledDrawer open closable={false} onClose={onClose}>
      <TabsHeader
        value={page}
        onChange={handleOnChangePage}
        onClose={onClose}
      />
      <StyledBody>
        <ActivePage
          activePage={page}
          task={task}
          userList={data?.UserFields ?? []}
          reportIncomeFields={data?.ReportIncomeFields ?? []}
          taskReportingIncome={taskReportingIncome}
          setTaskReportingIncome={setTaskReportingIncome}
          timesPreconf={data?.TimesPreconf ?? []}
          timesRefDateForStart={data?.TimesRefDateForStart ?? []}
          timesRefDateForDeadline={data?.TimesRefDateForDeadline ?? []}
          onChange={handleOnChange}
          onChangeAndSave={handleOnChangeAndSave}
          saveTask={() => handleOnSave()}
        />
      </StyledBody>
    </StyledDrawer>
  );
};

export default TaskEditor;
