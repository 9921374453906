export const timeToAddFormatOptions = [
  { value: "minuto", label: "minutos" },
  { value: "hora", label: "horas" },
  { value: "día", label: "días" },
];

export const timeToAddConfigOptions = [
  { value: "antes", label: "antes" },
  { value: "después", label: "después" },
];

export const TimeFormatValues = {
  día: 1440,
  hora: 60,
  minuto: 1,
};

export const TimeConfigValues = {
  antes: -1,
  después: 1,
};
