import React from "react";
import styled from "styled-components";
import { ReactComponent as ProcessIconSVG } from "../../resources/img/processIconVariable.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ProcessIconProps {
  $width?: string;
  $height?: string;
}

const ProcessIcon: React.FC<ProcessIconProps> = ({
  $width = "14px",
  $height = "14px",
}) => {
  return (
    <IconWrapper>
      <ProcessIconSVG width={$width} height={$height} />
    </IconWrapper>
  );
};

export default ProcessIcon;
