import React from "react";
import { Modal, Paper } from "@material-ui/core";
import styled from "styled-components";
import { Button } from "../../../../GeestUI";
import { CloseIcon } from "../../../../components/hoverIcons";

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const StyledPaper = styled(Paper)`
  border-radius: 10px !important;
  height: 166px;
  max-height: 90vh;
  width: 385px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  padding: 12px;
  padding-bottom: 0;
`;

const Title = styled.h2`
  font-size: 16px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 45px;
  text-align: center;
  margin-bottom: 12px;

  p {
    margin: 0;
    font-size: 14px;
    color: #828d9e;
    font-family: Gotham-Book;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

interface UnsavedChangesModalProps {
  onCancel: () => void;
  onExit: () => void;
}

const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
  onCancel,
  onExit,
}) => {
  return (
    <Modal open onClose={onCancel} style={{ opacity: 1, zIndex: 20 }}>
      <ModalContent onClick={onCancel}>
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <Header>
            <Title>Cambios sin guardar</Title>
            <CloseIcon
              size={20}
              onClick={onCancel}
              hoverContainerColor="#d9d9d9"
            />
          </Header>

          <ContentWrapper>
            <p>
              ¿Deseas salir de la configuración sin haber guardado los cambios?
            </p>
          </ContentWrapper>

          <ButtonsRow>
            <Button type="secondary" onClick={onCancel}>
              Cancelar
            </Button>
            <Button type="primary" onClick={onExit}>
              Salir
            </Button>
          </ButtonsRow>
        </StyledPaper>
      </ModalContent>
    </Modal>
  );
};

export default UnsavedChangesModal;
