import React from "react";
import { ArticleWrapper, SectionWrapper, StyledLink } from "../Styles";

interface ExtendLinkProps {
  isActive: () => boolean;
  setSection: () => void;
}

const SectionName = "Extender";

export const ExtendLink: React.FC<ExtendLinkProps> = ({
  isActive,
  setSection,
}) => {
  return (
    <StyledLink onClick={setSection} $isActive={isActive()}>
      {SectionName}
    </StyledLink>
  );
};

interface ExtendSectionProps {}

export const ExtendSection: React.FC<ExtendSectionProps> = () => {
  return (
    <SectionWrapper>
      <h2>{SectionName}</h2>
      <ArticleWrapper>
        <p>
          Extender el editor puede ser complicado, así que empezaré dando un
          ejemplo superficial de cómo es que funciona el controlador para
          agregar campos (ignorando su lógica interna), para luego explicar las
          funciones de utilería que serán útiles para diversos propósitos.
        </p>

        <h3>Creando el controlador para agrar campos</h3>
        <p>
          Si quisieramos crear el botón para agregar campos, primero habría que
          extender la interfaz del editor mismo (
          <code className="code type">GeestTextEditorProps</code>), agregando un
          parametro para activar y desactivar tanto en la toolbarConfig como en
          la toolbarExtraParams en caso de requerir alguna configuración extra.
        </p>
        <p>
          Lo siguiente sería ir al archivo{" "}
          <code className="code">
            src/components/GeestTextEditor/GeestTextEditor.tsx
          </code>{" "}
          y en la función{" "}
          <code className="code">customToolbarExtraButtons</code> hacer un push
          al arreglo <code className="code">customButtons</code> con el
          componente para el controlador que será parte de la toolbar.
        </p>
        <p>
          La función customToolbarExtraButtons es pasada directamente al Editor,
          el cual recorre ese arreglo y renderiza los componentes que pasemos,
          y, además de los argumentos que nosotros agreguemos, el mismo siempre
          pasará algunos argumentos, como{" "}
          <code className="code">editorState</code> y{" "}
          <code className="code">onChange</code>.
        </p>
        <p>
          En este caso, yo cree un botón que al hacer click, ejecuta una función
          llamada <code className="code">addFieldEntity</code> la cual viene
          directamente desde la utilería.
        </p>
        <p>
          Esta función recibe el estado, un callback para gestionar el onChange,
          y un par de propiedades para gestionar cuantos campos se han creado en
          el estado del editor y así asignarles un id que es en principio,
          único.
        </p>
        <p className="note">
          Puedes ver los detalles de cómo agrega este campo en el estado viendo
          la función en la ruta{" "}
          <code className="code">
            src/compoents/GeestTextEditor/Utils/Editor.ts
          </code>
        </p>
        <p>
          El siguiente paso es crear un decorador personalizado, ya que el campo
          es un elemento en linea. Si fuese un bloque insertado como por
          ejemplo, una imagen, entonces habría que crear una función
          <code className="code">customBlockRenderer</code>.
        </p>
        <p>
          Nuestra función <code className="code">getCustomDecorators</code> que
          se encuentra dentro de la raiz del editor, genera un arreglo de
          decoradores, al cual se le puede hacer push de manera condicional y
          luego retornarlo, aunque lo recomendable sería agregar todos los
          decoradores desde el inicio. En este caso, yo hago push al resultado
          de ejecutar una función llamada{" "}
          <code className="code">getFieldDecorator</code> que se encuentra en la
          ruta{" "}
          <code className="code">
            src/components/GeestTextEditor/Decorators/FeildDecorator.tsx
          </code>{" "}
          la cual retorna un objeto con dos propiedades,{" "}
          <code className="code">strategy</code> la cual es una función la cual
          ejecuta otra para hacer match del patrón del decorador utilizando un
          regex, y una segunda propiedad llamada{" "}
          <code className="code">component</code> la cual es una función con un
          componente que será renderizado al hacer match con la propiedad
          anterior.
        </p>
        <p>
          Como puede notarse, crear nuevos componentes puede ser confuso, pero
          espero que esta pequeña guía y los ejemplos en el propio proyecto
          sirvan para facilitar el proceso. En cualquier caso, siempre puedes
          consultar con algún compañero que ya haya trabajado a fondo con el
          editor.
        </p>
        <p>
          Ahora veamos alguanas de las funciones que tenemos disponibles en la
          utilería para extender el editor.
        </p>

        <h3>
          customBlockRenderer
          <span className="info">
            (block: <span className="type">any</span>) =&gt;{" "}
            <span className="type">void</span>
          </span>
        </h3>
        <p>
          Lo importante aquí no es la función como tal, ya que esta solo sirve
          para gestionar las raices de los bloques, lo importante es el
          componente que se encuentra dentro del objeto que retorna.
        </p>
        <p>
          Casi siempre que trabajes creando componentes de bloque, terminarás
          utilizando el tipo <code className="code">"atomic"</code>, el cual
          carga el componente <code className="code">BlockRoot</code>. En este
          componente ubicado en la ruta{" "}
          <code className="code">
            src/components/GeestTextEditor/Components/BlockRoot/index.tsx
          </code>{" "}
          podrás ver que, de manera condicional, retorna un bloque diferente en
          relación a una propiedad llamada type, la cual es parte de la entity
          como tal. Puedes ver un ejemplo al revisar cómo se agrega el tipo{" "}
          <code className="code">"IMAGE"</code> además de otra información de
          metadata en el controlador de imagenes en la ruta{" "}
          <code className="code">
            src/components/GeestTextEditor/components/Controllers/Image/index.tsx
          </code>
        </p>

        <h3>
          getLengthOfSelectedText
          <span className="info">
            (editorState <span className="type">EditorState</span>) =&gt;{" "}
            <span className="type">number</span>
          </span>
        </h3>
        <p>
          Esta función tiene una utilidad principalmente interna pero podría ser
          de utilidad. Recibe un estado, de este extrae la selección que hay en
          el mismo, el contenido, la key del bloque seleccionado y la key donde
          termina la selección. Con estas, busca el bloque entero dentro del
          contenido actual del estado. Además, guarda el largo del bloque de
          inicio y el largo del bloque de fin y la key después del final.
        </p>
        <p>
          Luego verifica si el bloque de inicio es el mismo del bloque de fin en
          la selección, de ser así, simplemente realiza un offset de la
          selección, en caso contrario, recorre los bloques utilizando las keys
          para ir haciendo una sumatoria de los lengths de los bloques
          involucrados en dicha selección.
        </p>

        <h3>
          getContentLength
          <span className="info">
            (editorState <span className="type">EditorState</span>) =&gt;{" "}
            <span className="type">number</span>
          </span>
        </h3>
        <p>
          Esta función retorna la cuenta total de caracteres dentro de un editor
          state, sacando el contenido actual del mismo para luego convertirlo en
          texto plano y así obtener una cuenta del largo de ese string.
        </p>
        <p className="note">
          Es importante resaltar que al ser una cuenta con texto plano, no toma
          en consideración, por ejemplo, el largo real de un label en un campo
          de tipo field, ya que en realidad este componente es solo un decorador
          para un texto que sirve como template. Para saber más sobre el
          elemento de campo puedes consultar en la sección{" "}
          <code className="code">Extender</code>.
        </p>

        <h3>
          findWithRegex
          <span className="info">
            (regex: <span className="type">RegExp</span>, contentBlock:{" "}
            <span className="type">ContentBlock | undefined</span>, callback:{" "}
            <span className="type">
              (start: number, end: number) =&gt; void
            </span>
            ) =&gt; <span className="type">void</span>
          </span>
        </h3>
        <p>
          Esta función recibe un regex, un bloque de contenido y una función
          callback.
        </p>
        <p>
          Primero, si existe un bloque de contenido, extrae el texto del mismo y
          a buscar coincidencias con el regex, finalmente, cada que encuentra
          una de estas, ejecuta la función de callback.
        </p>
      </ArticleWrapper>
    </SectionWrapper>
  );
};
