import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Row, Popover } from "antd";

import { SesionContext } from "../../../config/State";
import { emailValidation, fieldValidation } from "./formik-conf";
import FormikInput from "./FormikInput";
import EditProfileImg from "../../../resources/img/Ajustar_Editar.png";
import CropImage from "./CropImage";
import PreviewFile from "../../../components/DynamicInput/Components/helpers/PreviewFile";
import { Languages } from "../Dictionary";
import { Button, Typography } from "../../../GeestUI";
import {
  CardCont,
  LogoContainer,
  EditImgCont,
  ProfileImgCont,
  InitialsCont,
  ActionsItems,
  ActionsItemsCont,
} from "./Style";
import { useMutation } from "../../../hooks";
import { MessagesContext } from "../../../components/AppMessages";

const { H1 } = Typography;

const overlayStyle = {
  width: "13rem",
  borderRadius: ".9rem",
  padding: "0",
  overflow: "hidden",
  boxShadow: "0px 0px 30px #cfcdcd",
};

const Profile = () => {
  const { showMessage } = useContext(MessagesContext);
  const [Sesion, setSesion] = useContext(SesionContext);
  const {
    FirstName: firstName,
    LastName: lastName,
    Id,
    ProfilePicture: profilePicture,
  } = Sesion;

  const [IconOpacity, setIconOpacity] = useState(false);
  const [Email, setEmail] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [ProfilePicture, setProfilePicture] = useState(profilePicture);

  const [ProfileMenu, setProfileMenu] = useState(false);
  const [InfoLoaded, setInfoLoaded] = useState(false);
  const [Modal, setModal] = useState(null);

  const [UpImg, setUpImg] = useState("");

  const {
    ProfileLabel,
    FirstNameLabel,
    LastNameLabel,
    EmailLabel,
    SaveLabel,
    UploadImage,
    DeleteImage,
  } = Languages["ESP"];

  const [getUserInformation] = useMutation({
    func: "Ver2-Settings-gui",
    onSuccess: (response) => {
      const { Email, FirstName, LastName, ProfilePicture } = response;
      setEmail(Email);
      setFirstName(FirstName);
      setLastName(LastName);
      setProfilePicture(ProfilePicture);
      setInfoLoaded(true);
    },
  });

  const [updateUserInformation] = useMutation({
    func: "Ver2-Settings-uui",
    onSuccess: (res, shippedData) => {
      onCloseModal();
      setSesion({
        ...Sesion,
        Email,
        FirstName,
        LastName,
        ProfilePicture: shippedData.newPic,
      });
      setProfilePicture(shippedData.newPic);
      if (shippedData.formik) {
        shippedData.formik?.setSubmitting(false);
      }
      showMessage("Actualización exitosa", "success");
      getUserInformation({ args: { IdUR: Id } });
    },
    onError: (errorArgs) => {
      if (errorArgs.formik) {
        errorArgs.formik.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    setInfoLoaded(false);
    getUserInformation({ args: { IdUR: Id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = (userData, formik) => {
    const { profilePicture, deletePicture } = userData;
    updateUserInformation({
      args: {
        Email: _.trim(userData.email),
        FirstName: _.trim(userData.firstName),
        LastName: _.trim(userData.lastName),
        ProfilePicture: deletePicture
          ? ""
          : profilePicture?.SourceUrl || ProfilePicture || "",
      },
      shippedData: {
        formik: formik,
        newPic: profilePicture?.SourceUrl || ProfilePicture || "",
      },
      errorArgs: { formik: formik },
    });
  };

  const onUpload = () => {
    const inputElement = document.getElementById("Upload");
    inputElement.click();
  };

  const onSelectFile = ({ target }) => {
    if (target.files && target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(target.files[0]);
      reader.addEventListener("load", () => setUpImg(reader.result));
      setProfileMenu(false);
      setModal("cropImage");
    }
  };

  const onCloseModal = () => {
    setModal(null);
    const inputElement = document.getElementById("Upload");
    if (inputElement) inputElement.value = null;
  };

  const onDeleteImg = () => {
    setProfilePicture("");
    handleOnSubmit({
      email: Email,
      firstName: FirstName,
      lastName: LastName,
      profilePicture: "",
      deletePicture: true,
    });
  };

  const onChangeProfilePicture = (profilePicture) => {
    setProfilePicture(profilePicture);
    handleOnSubmit({
      email: Email,
      firstName: FirstName,
      lastName: LastName,
      profilePicture,
    });
  };

  const Modals = {
    cropImage: (
      <CropImage
        onClose={onCloseModal}
        UpImg={UpImg}
        setProfilePicture={onChangeProfilePicture}
        UserId={Id}
      />
    ),
    previewImage: (
      <PreviewFile
        PreviewURL={ProfilePicture}
        closeModal={() => setModal(null)}
      />
    ),
  };

  const ActionsPopover = () => (
    <>
      <ActionsItemsCont onClick={onUpload} align="middle">
        <input
          type="file"
          accept="image/*"
          id="Upload"
          onChange={onSelectFile}
          style={{ display: "none" }}
        />
        <ActionsItems>{UploadImage}</ActionsItems>
      </ActionsItemsCont>
      {ProfilePicture && (
        <ActionsItemsCont onClick={onDeleteImg} align="middle">
          <ActionsItems $alert>{DeleteImage}</ActionsItems>
        </ActionsItemsCont>
      )}
    </>
  );

  return (
    <Row justify="center">
      {Modals[Modal]}
      <CardCont>
        <H1 mb="0" mt="1rem" style={{ textAlign: "center" }}>
          {ProfileLabel}
        </H1>
        <Row justify="center" align="middle">
          <ProfileImgCont
            onMouseEnter={() => setIconOpacity(true)}
            onMouseLeave={() => setIconOpacity(false)}
            onClick={() => setProfileMenu((val) => !val)}
          >
            {IconOpacity && (
              <EditImgCont src={EditProfileImg} alt="EditProfileImg" />
            )}
            <LogoContainer $imgProfile={ProfilePicture} $opacity={IconOpacity}>
              <Popover
                trigger="click"
                visible={ProfileMenu}
                placement="bottomLeft"
                content={ActionsPopover}
                overlayStyle={overlayStyle}
                onVisibleChange={setProfileMenu}
              >
                {!ProfilePicture && (
                  <InitialsCont justify="center" align="middle">
                    {`${firstName[0]}${lastName[0]}`}
                  </InitialsCont>
                )}
              </Popover>
            </LogoContainer>
          </ProfileImgCont>
        </Row>
        {InfoLoaded && (
          <Formik
            initialValues={{
              firstName: FirstName,
              lastName: LastName,
              email: Email,
            }}
            onSubmit={handleOnSubmit}
            validationSchema={Yup.object({
              firstName: fieldValidation,
              lastName: fieldValidation,
              email: emailValidation,
            })}
          >
            {(formik) => (
              <Form style={{ marginTop: "0.5rem" }} noValidate>
                <Row justify="center">
                  <FormikInput
                    label={FirstNameLabel}
                    variant="outlined"
                    size="small"
                    id="firstName"
                    name="firstName"
                    fullWidth
                  />
                  <FormikInput
                    label={LastNameLabel}
                    variant="outlined"
                    size="small"
                    id="lastName"
                    name="lastName"
                    fullWidth
                  />
                  <FormikInput
                    label={EmailLabel}
                    variant="outlined"
                    size="small"
                    id="email"
                    name="email"
                    fullWidth
                  />
                </Row>
                <Row justify="center">
                  <Button
                    disabled={!(formik.isValid || formik.dirty)}
                    loading={formik.isSubmitting}
                    type="primary"
                    style={{ width: "28%", margin: "2rem 0rem" }}
                    htmlType="submit"
                  >
                    {SaveLabel}
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </CardCont>
    </Row>
  );
};

export default Profile;
