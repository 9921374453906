import React, { useState } from "react";
import {
  StateContainer,
  SearchBar,
  PasswordTitle,
  TableHeader,
  TableCell,
  TableContainer,
  TableRow,
} from "../styles";
import { useFetch } from "../../../hooks";
import { PasswordUser } from "../Intranet.d";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { sanitizeSearchValues } from "../../../Helpers";
import { Button } from "../../../GeestUI";
import { RestorePasswordModal } from "./components";
import Loading from "../../../components/NewLoading/NewLoading";

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

const Passwords: React.FC = () => {
  const [filter, setFilter] = useState<string>("");
  const [userToReset, setUserToReset] = useState<PasswordUser | null>(null);
  const { data, loading } = useFetch<PasswordUser[]>({
    func: "Ver2-Intranet-Pwd-gip",
  });

  const rowHeight = 40;

  const filteredItems =
    data?.filter((user) =>
      sanitizeSearchValues(`${user.Name} ${user.Email}`).includes(
        sanitizeSearchValues(filter)
      )
    ) || [];

  if (loading) {
    return (
      <StateContainer>
        <Loading width={200} />
      </StateContainer>
    );
  }

  const renderUser = ({ index, key, style, parent }: any) => {
    const user = filteredItems[index];

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <TableRow style={style}>
          <TableCell $width={269} style={{ justifyContent: "flex-start" }}>
            {user.Name}
          </TableCell>
          <TableCell $width={275} style={{ justifyContent: "flex-start" }}>
            {user.TeamName}
          </TableCell>
          <TableCell $width={286} style={{ justifyContent: "flex-start" }}>
            {user.Email}
          </TableCell>
          <TableCell $width={136}>
            <Button onClick={() => setUserToReset(user)}>Restablecer</Button>
          </TableCell>
        </TableRow>
      </CellMeasurer>
    );
  };

  return (
    <StateContainer>
      {!!userToReset && (
        <RestorePasswordModal
          user={userToReset}
          onClose={() => setUserToReset(null)}
        />
      )}
      <PasswordTitle>
        Restablecer contraseña de Usuarios
        <SearchBar
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Buscar usuario o correo electrónico..."
        />
      </PasswordTitle>
      <StateContainer style={{ maxWidth: "100%" }}>
        <TableContainer>
          <TableHeader>
            <TableCell $width={269} style={{ justifyContent: "flex-start" }}>
              Usuario
            </TableCell>
            <TableCell $width={275} style={{ justifyContent: "flex-start" }}>
              Empresa
            </TableCell>
            <TableCell $width={286} style={{ justifyContent: "flex-start" }}>
              Correo
            </TableCell>
            <TableCell $width={136}>Acción</TableCell>
          </TableHeader>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={rowHeight}
                rowRenderer={renderUser}
                rowCount={filteredItems.length}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        </TableContainer>
      </StateContainer>
    </StateContainer>
  );
};

export default Passwords;
