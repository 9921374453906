import React, { useState } from "react";
import _ from "lodash";
import { Popover } from "@material-ui/core";
import DataTypeIcons from "../../../../DataTypeIcons";
import { languages } from "./dictionary";
import {
  EmptyList,
  FieldElement,
  FieldList,
  PopoverContent,
  PopoverTitle,
  SearchWrapper,
  StyledInput,
} from "./Styles";
import { FieldEditorOrigin } from "../../../../GeestTextEditor.d";
import { AiOutlineSearch } from "react-icons/ai";
import { BiSad } from "react-icons/bi";

interface SelectFieldPopoverProps {
  anchorEl: HTMLButtonElement | null;
  fieldEditorOrigin: FieldEditorOrigin[];
  selectedIdField: number | null | string;
  fieldsPopoverStyles: React.CSSProperties;
  onSelectNewIdField: (selectedField: number | null | string) => void;
  onClose: () => void;
}

const SelectFieldPopover: React.FC<SelectFieldPopoverProps> = ({
  anchorEl,
  fieldEditorOrigin,
  selectedIdField,
  fieldsPopoverStyles,
  onSelectNewIdField,
  onClose,
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "field-popover" : undefined;
  const { PopoverTitleLabel, SearchPlaceholder, Empty } = languages["ESP"];

  const [search, setSearch] = useState<string>("");

  const handleOnClose = () => {
    setSearch("");
    onClose();
  };

  const removeAccentMarks = (str: string) => {
    const accentMap: { [key: string]: string } = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      ü: "u",
    };

    return str.toLowerCase().replace(/[áéíóúü]/g, (m) => accentMap[m] || m);
  };

  const filteredFields = () => {
    const filtered = _.filter(fieldEditorOrigin, (field) => {
      if (removeAccentMarks(field.Label).includes(removeAccentMarks(search)))
        return true;
      return false;
    });

    return filtered;
  };

  const handleSelectField = (idField: number | string) => {
    if (idField === selectedIdField) {
      onSelectNewIdField(null);
    } else {
      onSelectNewIdField(idField);
    }
    handleOnClose();
  };

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      const firstField = filteredFields()[0];
      if (firstField) {
        handleSelectField(firstField.IdField);
      } else {
        handleOnClose();
      }
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleOnClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      style={fieldsPopoverStyles}
      PaperProps={{
        style: {
          borderRadius: "6px",
          marginTop: "4px",
          minWidth: "200px",
          maxWidth: "400px",
        },
      }}
    >
      <PopoverContent>
        <PopoverTitle>
          <p className="field-title">{PopoverTitleLabel}</p>
        </PopoverTitle>

        <SearchWrapper>
          <AiOutlineSearch size={25} color="#48505e" />
          <StyledInput
            autoFocus
            type="text"
            value={search}
            placeholder={SearchPlaceholder}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={handleOnKeyDown}
          />
        </SearchWrapper>

        {filteredFields().length > 0 ? (
          <FieldList>
            {filteredFields().map((field) => {
              return (
                <FieldElement
                  key={field.IdField}
                  $selected={field.IdField === selectedIdField}
                  onClick={() => handleSelectField(field.IdField)}
                >
                  {DataTypeIcons[field.DataType] && (
                    <img
                      src={DataTypeIcons[field.DataType].icon}
                      alt=""
                      style={{
                        ...DataTypeIcons[field.DataType]?.extraStyles,
                      }}
                    />
                  )}
                  <p className="label">{field.Label}</p>
                </FieldElement>
              );
            })}
          </FieldList>
        ) : (
          <EmptyList>
            <BiSad color="#48505e" size={28} />
            {Empty}
          </EmptyList>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default SelectFieldPopover;
