import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import { Languages } from "./Dictionary";
import ModalTab from "../../components/ModalTab/ModalTab";
import StepContent from "./StepContent";
import {
  DurationsGraphFormat,
  DuratonsStepOneResponse,
  GraphType,
} from "../../../Dashboards.d";
import {
  CloseIcon,
  ContentWrapper,
  ModalContent,
  ModalTitle,
  ModalWrapper,
} from "../../modalStyles";
import { useMutation } from "../../../../../hooks";
import UnsavedChangesModal from "../UnsavedChangesModal";

const { ModalTitleLabel, StepGeneral, StepFilter } = Languages["ESP"];

interface Option {
  value: string | number;
  label: string;
}

interface DurationsProps {
  onClose: () => void;
  selectedTemplate: number;
  selectedDashboard: string | null;
  graphToEdit: GraphType | null;
}

const Durations: React.FC<DurationsProps> = ({
  onClose,
  selectedTemplate,
  selectedDashboard,
  graphToEdit,
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [graphName, setGraphName] = useState<string>("");
  const [selectedProcess, setSelectedProcess] = useState<number | null>(null);
  const [format, setFormat] = useState<DurationsGraphFormat>("");
  const [processOptions, setProcesssOptions] = useState<Option[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] =
    useState<boolean>(false);

  const steps = [StepGeneral, StepFilter];

  const [getGraphConfigurationWizard] = useMutation<DuratonsStepOneResponse>({
    func: "Ver2-DashBoards-ggcw",
    onSuccess: ({ Processes, GraphMode, GraphName, IdProcessTemplate }) => {
      const newOptions = Processes.map((p) => {
        return {
          value: p.IdProcessTemplate,
          label: p.Name,
        };
      });
      setProcesssOptions(newOptions);
      setFormat(GraphMode || "StackBar");
      setGraphName(GraphName);
      setSelectedProcess(IdProcessTemplate);
    },
  });

  useEffect(() => {
    if (processOptions.length === 0) {
      getGraphConfigurationWizard({
        args: {
          IdDashBoard: selectedDashboard,
          IdGraphTemplate: selectedTemplate,
          IdGraph: graphToEdit?.IdGraph ?? "",
          Step: 1,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateNextStep = () => {
    switch (activeStep) {
      case 0:
        const graphNameValid = Boolean(graphName);
        const selectedProcessValid =
          Boolean(selectedProcess) && selectedProcess !== 0;
        return graphNameValid && selectedProcessValid;

      default:
        return true;
    }
  };

  const handleOnClose = (submit = false) => {
    if (unsavedChanges && !submit) {
      setOpenUnsavedChangesModal(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      {openUnsavedChangesModal && (
        <UnsavedChangesModal
          onCancel={() => setOpenUnsavedChangesModal(false)}
          onConfirm={onClose}
        />
      )}
      <Modal open onClose={() => handleOnClose()}>
        <ModalWrapper onClick={() => handleOnClose()}>
          <ModalContent
            onClick={(e) => e.stopPropagation()}
            $width="1113px"
            $maxWidth="90vw"
            $height="700px"
            $maxHeight="700px"
          >
            <ModalTitle>
              <p>{ModalTitleLabel}</p>
              <CloseIcon size={20} onClick={() => handleOnClose()} />
            </ModalTitle>
            <ModalTab
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={steps}
              linear
              canGoNext={validateNextStep()}
            />

            <ContentWrapper>
              <StepContent
                step={activeStep}
                graphName={graphName}
                setGraphName={setGraphName}
                selectedProcess={selectedProcess}
                setSelectedProcess={setSelectedProcess}
                format={format}
                setFormat={setFormat}
                processOptions={processOptions}
                selectedTemplate={selectedTemplate}
                selectedDashboard={selectedDashboard}
                graphToEdit={graphToEdit}
                canGoNext={validateNextStep()}
                goNextStep={() => setActiveStep(activeStep + 1)}
                onClose={handleOnClose}
                setUnsavedChanges={() => setUnsavedChanges(true)}
              />
            </ContentWrapper>
          </ModalContent>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default Durations;
