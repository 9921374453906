const SpanishLabels = {
  // Generic
  "generic.add": "Agregar",
  "generic.cancel": "Cancelar",

  // BlockType
  "components.controls.blocktype.h1": "Titulo 1",
  "components.controls.blocktype.h2": "Titulo 2",
  "components.controls.blocktype.h3": "Titulo 3",
  "components.controls.blocktype.h4": "Titulo 4",
  "components.controls.blocktype.h5": "Titulo 5",
  "components.controls.blocktype.h6": "Titulo 6",
  "components.controls.blocktype.blockquote": "Cita",
  "components.controls.blocktype.code": "Código",
  "components.controls.blocktype.blocktype": "Tipo de bloque",
  "components.controls.blocktype.normal": "Normal",

  // Color Picker
  "components.controls.colorpicker.colorpicker": "Color",
  "components.controls.colorpicker.text": "Texto",
  "components.controls.colorpicker.background": "Fondo",

  // Embedded
  "components.controls.embedded.embedded": "Embebido",
  "components.controls.embedded.embeddedlink": "Link embebido",
  "components.controls.embedded.enterlink": "Ingresar link",

  // Emoji
  "components.controls.emoji.emoji": "Emoji",

  // FontFamily
  "components.controls.fontfamily.fontfamily": "Fuente",

  // FontSize
  "components.controls.fontsize.fontsize": "Tamaño",

  // History
  "components.controls.history.history": "Historial",
  "components.controls.history.undo": "Deshacer",
  "components.controls.history.redo": "Rehacer",

  // Image
  "components.controls.image.image": "Imagen",
  "components.controls.image.fileUpload": "Subir archivo",
  "components.controls.image.byURL": "URL",
  "components.controls.image.dropFileText":
    "Suelta el archivo o da click en subir",

  // Inline
  "components.controls.inline.bold": "Negrita",
  "components.controls.inline.italic": "Cursiva",
  "components.controls.inline.underline": "Subrayado",
  "components.controls.inline.strikethrough": "Tachado",
  "components.controls.inline.monospace": "Monospace",
  "components.controls.inline.superscript": "Superscript",
  "components.controls.inline.subscript": "Subscript",

  // Link
  "components.controls.link.linkTitle": "Titulo del link",
  "components.controls.link.linkTarget": "URL del link",
  "components.controls.link.linkTargetOption":
    "Abrir link en una nueva ventana",
  "components.controls.link.link": "Link",
  "components.controls.link.unlink": "Unlink",

  // List
  "components.controls.list.list": "Lista",
  "components.controls.list.unordered": "Lista con viñetas",
  "components.controls.list.ordered": "Lista numerada",
  "components.controls.list.indent": "Alinear listado a la derecha",
  "components.controls.list.outdent": "Alinear listado a la izquierda",

  // Remove
  "components.controls.remove.remove": "Eliminar",

  // TextAlign
  "components.controls.textalign.textalign": "Alineación del texto",
  "components.controls.textalign.left": "Alinear a la izquierda",
  "components.controls.textalign.center": "Centrar",
  "components.controls.textalign.right": "Alinear a la derecha",
  "components.controls.textalign.justify": "Justificado",
};

export default SpanishLabels;
