import React, { useState } from "react";
import _ from "lodash";
import { Row, Space } from "antd";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import { AiOutlineClockCircle } from "react-icons/ai";
import { SchedulePageProps } from "../TaskEditor.d";
import { StyledDivider, StyledTitle } from "../styles";
import { StyledInput, StyledSwitch } from "../../../styles";
import GeestSelect from "../../../../../../GeestUI/GeestSelect";
import { timeToAddFormatOptions, timeToAddConfigOptions } from "./constants";
import {
  TimeConfig,
  TimeFormat,
  assertTimeToAdd,
  backOptionToSelectOption,
  decodeTimeToAdd,
  encodeTimeToAdd,
} from "./helpers";

const SchedulePage: React.FC<SchedulePageProps> = ({
  task,
  timesPreconf,
  timesRefDateForStart,
  timesRefDateForDeadline,
  onChange,
  onChangeAndSave,
  saveTask,
}) => {
  const [scheduleStart, setScheduleStart] = useState(
    !_.isEmpty(
      task.StartSchedTime_Preconf ||
        task.StartSchedTime_RefDate ||
        task.StartSchedTime_TimeToAdd
    )
  );

  const {
    timeAmount: startTimeAmount,
    timeFormat: startTimeFormat,
    timeConfig: startTimeConfig,
  } = decodeTimeToAdd(task.StartSchedTime_TimeToAdd);
  const {
    timeAmount: deadlineTimeAmount,
    timeFormat: deadlineTimeFormat,
    timeConfig: deadlineTimeConfig,
  } = decodeTimeToAdd(task.DeadlineSchedTime_TimeToAdd);
  const preconfOptions = backOptionToSelectOption(timesPreconf);
  const startRefDateOptions = backOptionToSelectOption(timesRefDateForStart);
  const deadlineRefDteOptions = backOptionToSelectOption(
    timesRefDateForDeadline
  );

  const handleSetScheduleStart: SwitchChangeEventHandler = (value) => {
    if (!value) {
      onChangeAndSave({
        ...task,
        StartSchedTime_RefDate: "",
        StartSchedTime_TimeToAdd: 0,
        StartSchedTime_Preconf: "",
      });
    } else {
      onChange({
        ...task,
        StartSchedTime_Preconf: "nd",
      });
    }
    setScheduleStart(value);
  };

  const onChangeStartTimeAmount: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) =>
    onChange({
      ...task,
      StartSchedTime_TimeToAdd: encodeTimeToAdd({
        timeAmount: assertTimeToAdd(e.target.value),
        timeFormat: startTimeFormat,
        timeConfig: startTimeConfig,
      }),
    });
  const onChangeStartTimeFormat = (timeFormat: TimeFormat) =>
    onChangeAndSave({
      ...task,
      StartSchedTime_TimeToAdd: encodeTimeToAdd({
        timeAmount: startTimeAmount,
        timeFormat,
        timeConfig: startTimeConfig,
      }),
    });
  const onChangeStartTimeConfig = (timeConfig: TimeConfig) => {
    onChangeAndSave({
      ...task,
      StartSchedTime_TimeToAdd: encodeTimeToAdd({
        timeAmount: startTimeAmount,
        timeFormat: startTimeFormat,
        timeConfig,
      }),
    });
  };
  const onChangeStartPreconf = (StartSchedTime_Preconf: string) =>
    onChangeAndSave({
      ...task,
      StartSchedTime_Preconf,
    });
  const onChangeStartRefDate = (StartSchedTime_RefDate: string) =>
    onChangeAndSave({
      ...task,
      StartSchedTime_RefDate,
    });
  const onChangeDeadlineTimeAmount: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) =>
    onChange({
      ...task,
      DeadlineSchedTime_TimeToAdd: encodeTimeToAdd({
        timeAmount: assertTimeToAdd(e.target.value),
        timeFormat: deadlineTimeFormat,
        timeConfig: deadlineTimeConfig,
      }),
    });
  const onChangeDeadlineTimeFormat = (timeFormat: TimeFormat) =>
    onChangeAndSave({
      ...task,
      DeadlineSchedTime_TimeToAdd: encodeTimeToAdd({
        timeAmount: deadlineTimeAmount,
        timeFormat,
        timeConfig: deadlineTimeConfig,
      }),
    });
  const onChangeDeadlineTimeConfig = (timeConfig: TimeConfig) => {
    onChangeAndSave({
      ...task,
      DeadlineSchedTime_TimeToAdd: encodeTimeToAdd({
        timeAmount: deadlineTimeAmount,
        timeFormat: deadlineTimeFormat,
        timeConfig,
      }),
    });
  };
  const onChangeDeadlinePreconf = (DeadlineSchedTime_Preconf: string) =>
    onChangeAndSave({
      ...task,
      DeadlineSchedTime_Preconf,
    });
  const onChangeDeadlineRefDate = (DeadlineSchedTime_RefDate: string) =>
    onChangeAndSave({
      ...task,
      DeadlineSchedTime_RefDate,
    });
  const onChangePostpone: SwitchChangeEventHandler = (Postpone) =>
    onChangeAndSave({
      ...task,
      Postpone,
    });

  return (
    <div>
      <Space align="center" style={{ marginBottom: "13px" }}>
        <StyledTitle>Programar inicio</StyledTitle>
        <StyledSwitch
          checked={scheduleStart}
          onChange={handleSetScheduleStart}
        />
      </Space>
      {scheduleStart && (
        <Space direction="vertical">
          <Row align="middle">
            <AiOutlineClockCircle
              color="#1F9334"
              size={16}
              style={{ marginRight: "5px" }}
            />
            <StyledTitle>Inicio</StyledTitle>
          </Row>
          <Space align="center">
            <StyledTitle>en</StyledTitle>
            <StyledInput
              value={startTimeAmount}
              onChange={onChangeStartTimeAmount}
              pattern="^[0-9]*$"
              onBlur={saveTask}
            />
            <GeestSelect
              value={startTimeFormat}
              onChange={onChangeStartTimeFormat}
              options={timeToAddFormatOptions}
              placeholderSelect=""
              $width="124px"
              valueNecesary
            />
            <GeestSelect
              value={task.StartSchedTime_Preconf}
              onChange={onChangeStartPreconf}
              options={preconfOptions}
              placeholderSelect=""
              $width="124px"
              valueNecesary
            />
          </Space>
          <Space align="center">
            <GeestSelect
              value={startTimeConfig}
              onChange={onChangeStartTimeConfig}
              options={timeToAddConfigOptions}
              placeholderSelect=""
              $width="110px"
              valueNecesary
            />
            <StyledTitle>de</StyledTitle>
            <GeestSelect
              value={task.StartSchedTime_RefDate}
              onChange={onChangeStartRefDate}
              options={startRefDateOptions}
              placeholderSelect=""
              $width="192px"
              valueNecesary
            />
          </Space>
        </Space>
      )}
      <StyledDivider />
      <Space direction="vertical" style={{ marginBottom: "14px" }}>
        <Row align="middle">
          <AiOutlineClockCircle
            color="#DB232C"
            size={16}
            style={{ marginRight: "5px" }}
          />
          <StyledTitle>Vencimiento</StyledTitle>
        </Row>
        <Space align="center">
          <StyledTitle>en</StyledTitle>
          <StyledInput
            value={deadlineTimeAmount}
            onChange={onChangeDeadlineTimeAmount}
            pattern="^[0-9]*$"
            onBlur={saveTask}
          />
          <GeestSelect
            value={deadlineTimeFormat}
            onChange={onChangeDeadlineTimeFormat}
            options={timeToAddFormatOptions}
            placeholderSelect=""
            $width="124px"
            valueNecesary
          />
          <GeestSelect
            value={task.DeadlineSchedTime_Preconf}
            onChange={onChangeDeadlinePreconf}
            options={preconfOptions}
            placeholderSelect=""
            $width="124px"
            valueNecesary
          />
        </Space>
        <Space align="center">
          <GeestSelect
            value={deadlineTimeConfig}
            onChange={onChangeDeadlineTimeConfig}
            options={timeToAddConfigOptions}
            placeholderSelect=""
            $width="110px"
            valueNecesary
          />
          <StyledTitle>de</StyledTitle>
          <GeestSelect
            value={task.DeadlineSchedTime_RefDate}
            onChange={onChangeDeadlineRefDate}
            options={deadlineRefDteOptions}
            placeholderSelect=""
            $width="192px"
            valueNecesary
          />
        </Space>
      </Space>
      <StyledDivider />
      <Space align="center">
        <StyledTitle>La tarea se puede posponer</StyledTitle>
        <StyledSwitch checked={task.Postpone} onChange={onChangePostpone} />
      </Space>
    </div>
  );
};

export default SchedulePage;
