import React from "react";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { IoInfinite } from "react-icons/io5";

export const CenteredText = ({ children }) => (
  <div
    style={{
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {children}
  </div>
);

const BoolType = ({ limitation, plan }) => {
  const Indicator = limitation[plan] ? BiCheckCircle : BiXCircle;
  const color = limitation[plan] ? "inherit" : "var(--transparent)";
  return <Indicator size={26} color={color} />;
};

const StorageType = ({ limitation, plan }) => {
  const color = limitation[plan] ? "inherit" : "var(--transparent)";

  return (
    <div>
      {limitation[plan] === -1 ? (
        <IoInfinite size={26} color={color} />
      ) : (
        limitation[plan]
      )}
    </div>
  );
};

const Limitation = (props) => {
  const { limitation } = props;
  switch (limitation.Type) {
    case "Bool":
      return (
        <CenteredText>
          <BoolType {...props} />
        </CenteredText>
      );
    case "Number":
    case "Storage":
      return (
        <CenteredText>
          <StorageType {...props} />
        </CenteredText>
      );

    default:
      return limitation.Type;
  }
};

export default Limitation;
