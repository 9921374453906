import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useMutation } from "../../../hooks";
import States from "../../../views/Premium/StateMachine/States";

const PaymentText = styled.span`
  font-family: "Gotham-Light";
  text-decoration: underline;
  :hover {
    color: darkcyan;
  }
`;

const Languages = {
  ENG: {
    FirstPartMessage: "Your payment could not be processed.",
    SecondPartMessage: "Do it",
    FinalPartMessage: (date) =>
      `before ${date} to prevent your account from being frozen.`,
  },
  ESP: {
    FirstPartMessage: "No se pudo procesar tu pago.",
    SecondPartMessage: "Hazlo",
    FinalPartMessage: (date) =>
      `antes del ${date} para evitar que tu cuenta se congele.`,
  },
};

const WarningContent = ({ AlertInfo }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { FirstPartMessage, SecondPartMessage, FinalPartMessage } =
    Languages["ESP"];

  const [changeSubscriptionState] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: () => {
      if (pathname === `/home/premium/${AlertInfo.IdTeam}`) {
        window.location.reload();
      }
      history.push(`/home/premium/${AlertInfo.IdTeam}`);
    },
    onError: () => {
      if (pathname === `/home/premium/${AlertInfo.IdTeam}`) {
        window.location.reload();
      }
      history.push(`/home/premium/${AlertInfo.IdTeam}`);
    },
  });

  const onRetryPayment = (e) => {
    e.stopPropagation();
    changeSubscriptionState({
      args: {
        IdTeam: AlertInfo.IdTeam,
        NextState: States.CHECKOUT_SINGLE_PAYMENT_OUTDATED,
      },
    });
  };

  return (
    <div
      style={{ fontFamily: "Gotham-Medium" }}
      onClick={() => history.push(`/home/premium/${AlertInfo.IdTeam}`)}
    >
      {FirstPartMessage}{" "}
      <PaymentText onClick={onRetryPayment}>{SecondPartMessage}</PaymentText>{" "}
      {FinalPartMessage(AlertInfo.Date)}
    </div>
  );
};

export default WarningContent;
