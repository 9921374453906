import styled from "styled-components";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";

export const DraggableItem = styled(Draggable)``;

export const StyledResizable = styled(Resizable)<{ selected: boolean }>`
  .react-resizable-handle {
    background: none;

    &::after {
      content: "";
      position: absolute;
      right: 3px;
      bottom: 3px;
      width: 5px;
      height: 5px;

      border-right: 2px solid
        ${({ selected = false }) =>
          selected ? "rgba(0, 0, 0, 0.4)" : "transparent"};
      border-bottom: 2px solid
        ${({ selected = false }) =>
          selected ? "rgba(0, 0, 0, 0.4)" : "transparent"};
    }
  }

  .react-resizable-handle-se {
    cursor: ${({ selected = false }) => (selected ? "re-size" : "auto")};
  }
`;

export const ResizableContent = styled.div<{
  width: number;
  height: number;
  selected: boolean;
}>`
  position: absolute;
  padding: 6px 8px;
  padding-bottom: 6px;
  border: 1px dashed;
  border-radius: 6px;

  width: ${({ width = 300 }) => width}px;
  height: ${({ height = 50 }) => height}px;
  border-color: ${({ selected = false }) =>
    selected ? "black" : "transparent"};

  z-index: ${({ selected = false }) => (selected ? "100" : "1")};
  .geestToolbar {
    ${({ selected = false }) => (selected ? "z-index: 110" : "")};
  }
`;

export const DeleteIconContainer = styled.div`
  position: absolute;
  top: -9px;
  right: -9px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  background-color: #0067b0;
  color: white;
  border-radius: 50%;
  cursor: pointer;

  transition: background-color 0.35s ease;
  :hover {
    background-color: #014c9b;
  }
`;
