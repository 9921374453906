import styled from "styled-components";
import { StyledInput as SharedInput } from "../Flow/styles";
import { Paper } from "@material-ui/core";

// <ProcessName />

export const Label = styled.span`
  color: #48505e;
  font-size: 24px;
`;

export const StyledInput = styled(SharedInput)`
  max-width: 300px;
  font-size: 18px;
`;

// <ProcessActions />

export const StyledPaper = styled(Paper)`
  border-radius: 6px !important;
  display: flex;
  flex-direction: column;
`;

export const ShareProcessItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(130, 141, 158, 1);
  font-size: 14px;
  font-family: Gotham-Book;
  width: 252px;
  font-weight: 325;
  height: 32px;
  padding: 6px 10px 6px 10px;
  cursor: pointer;
  :hover {
    background-color: rgba(237, 236, 236, 1);
  }
  :active {
    background-color: rgba(242, 248, 254, 1);
  }
`;

export const DeleteProcessItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: rgba(219, 35, 44, 1);
  font-size: 14px;
  font-family: Gotham-Book;
  width: 252px;
  font-weight: 325;
  height: 32px;
  padding: 6px 10px 6px 10px;
  cursor: pointer;
  :hover {
    background-color: rgba(237, 236, 236, 1);
  }
  :active {
    background-color: rgba(242, 248, 254, 1);
  }
`;

// <DeleteProcessModal />

export const CenteredTitle = styled.div`
  color: #48505e;
  text-align: center;
  font-family: "Gotham-bold";
  font-size: 14px;
`;

export const Paragraph = styled.p`
  color: #828d9e;
  margin: 0;
  font-size: 14px;
  text-align: center;
`;

export const ProcessNameLabel = styled.span`
  color: #db232c;
  font-size: 14px;
`;
