import Bugsnag from "@bugsnag/js";
import { Component } from "react";
import { withRouter } from "react-router";

const errorLogger = console.error;
class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return {};
  }

  componentDidCatch(error, errorInfo) {
    Bugsnag.notify(error);
    errorLogger({
      errorInfo,
      errorMessage: error.toString(),
      path: this.props.location.pathname,
    });
    this.props.history.push("/error");
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
