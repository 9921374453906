import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row } from "antd";

import GeestLogoSVG from "../../resources/img/GeestLogo.svg";
import GeestLogoWhiteSVG from "../../resources/img/GeestLogoWhite.svg";
import AppStoreLogo from "../../resources/img/AppStore.svg";
import GooglePlayLogo from "../../resources/img/GooglePlay.png";
import AppGalleryLogo from "../../resources/img/AppGallery.png";
import IOSLogo from "../../resources/img/IOS.png";
import AndroidLogo from "../../resources/img/Android.png";

export const GeestLogo = styled.div`
  height: 4.1rem;
  width: 11rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${`url(${GeestLogoSVG})`};
  cursor: pointer;
  margin-bottom: 1rem;
`;

export const GeestLogoWhite = styled(GeestLogo)`
  height: 52px;
  width: 117px;
  margin-bottom: 0;
  cursor: default;
  background-image: ${`url(${GeestLogoWhiteSVG})`};
`;

export const Container = styled(Row)`
  background: #0067b0;
  color: white;
  font-family: "Gotham-book";
  font-size: 12px;
  padding: 15px 1.5rem;
  position: sticky;
`;

export const StyledLink = styled(Link)`
  color: white;
  text-decoration: underline white;
  margin-right: 10px;
  :last-of-type {
    margin-right: 0;
  }
  :hover {
    color: white;
    text-decoration: underline white;
  }
`;

export const ImageBase = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0.5rem;
  cursor: pointer;
`;

export const AppStore = styled(ImageBase)`
  height: 38px;
  width: 116px;
  cursor: pointer;
  margin-bottom: 8px;
  margin-left: auto;
  background-image: ${`url(${AppStoreLogo})`};
`;

export const GooglePlay = styled(ImageBase)`
  height: 34px;
  width: 117px;
  margin-bottom: 8px;
  margin-left: auto;
  cursor: pointer;
  background-image: ${`url(${GooglePlayLogo})`};
`;

export const AppGallery = styled(ImageBase)`
  height: 34px;
  width: 117px;
  margin-bottom: 8px;
  margin-left: auto;
  cursor: pointer;
  background-image: ${`url(${AppGalleryLogo})`};
`;

export const Icons = styled.a`
  font-size: 17px;
  color: white;
  margin-right: 5px;
  transition: all 0.2s;
  :hover {
    color: grey;
  }
`;

export const StoresCommingFooter = styled(Row)`
  background: #dbdbdb;
  text-align: center;
  color: black;
  font-family: "Gotham-light";
  font-size: 16px;
  padding: 1.5rem 1.5rem;
  position: sticky;
  align-items: center;
`;

export const IOS = styled(ImageBase)`
  height: 7.18rem;
  width: 5.9rem;
  margin-top: 0;
  margin-bottom: 1vw;
  margin-left: 2rem;
  margin-right: 3rem;
  cursor: default;
  background-image: ${`url(${IOSLogo})`};
`;

export const Android = styled(ImageBase)`
  height: 7.5rem;
  width: 6rem;
  margin-bottom: 0;
  margin-top: 2vw;
  cursor: default;
  background-image: ${`url(${AndroidLogo})`};
`;
