import React from "react";
import styled from "styled-components";
import DynamicInput from "../../../../../components/DynamicInput";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Gotham-Bold;
  color: #0067b0;
  width: 100%;
`;

interface LocationComponentProps {
  value: string;
}

const LocationComponent: React.FC<LocationComponentProps> = ({ value }) => {
  return (
    <Container>
      <DynamicInput
        value={value}
        type="location"
        dataOrigin=""
        format=""
        configuration=""
        fieldName=""
        isConsult={true}
        disabled={true}
        required={false}
        onChange={() => null}
        extraParams={{
          locationColor: "#48505e",
          locatonFontSize: "12px",
        }}
      />
    </Container>
  );
};

export default LocationComponent;
