import React from "react";
import { Space } from "antd";
import { useHistory, useLocation } from "react-router-dom";

import Button from "../../Button";
import States from "../../../views/Premium/StateMachine/States";
import { useMutation } from "../../../hooks";

const TrialContent = ({ AlertInfo }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  // Show Extend button for first Trial
  const showExtendTrial = [
    "active_free_cardless_trial",
    "ended_free_cardless_trial",
  ].includes(AlertInfo.Value);
  const spaceSize = showExtendTrial ? "middle" : "large";

  const [changeSubscriptionState, loading] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: () => {
      if (pathname === `/home/premium/${AlertInfo.IdTeam}`) {
        history.go(0);
      } else {
        history.push(`/home/premium/${AlertInfo.IdTeam}`);
      }
    },
    onError: () => {
      if (pathname === `/home/premium/${AlertInfo.IdTeam}`) {
        history.go(0);
      } else {
        history.push(`/home/premium/${AlertInfo.IdTeam}`);
      }
    },
  });

  const onClick = (NextState = "") => {
    changeSubscriptionState({
      args: {
        IdTeam: AlertInfo.IdTeam,
        NextState: NextState,
      },
    });
  };

  return (
    <Space size={spaceSize}>
      {AlertInfo.Message}
      {showExtendTrial && (
        <Button
          onClick={() => onClick(States.REQUEST_BONUS_TRIAL)}
          loading={loading}
          style={{ border: "none" }}
        >
          {AlertInfo.TrialBonusDaysMessage}
        </Button>
      )}
      {AlertInfo.IsTeamOwner && (
        <Button
          onClick={() =>
            onClick(
              AlertInfo.Value === "immediate_payment_required"
                ? States.CHECKOUT
                : States.CHANGE_PLAN
            )
          }
          loading={loading}
          style={{ border: "none" }}
          type="defaultActive"
        >
          {AlertInfo.Value === "immediate_payment_required"
            ? "Realizar pago"
            : "Contratar ahora"}
        </Button>
      )}
    </Space>
  );
};

export default TrialContent;
