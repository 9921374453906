import React, { useContext, useState } from "react";
import { sha256 } from "js-sha256";
import _ from "lodash";
import { Button } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";
import { Languages } from "./Dictionary";
import { RegisterForm } from "../Style";
import { MessagesContext } from "../../../../components/AppMessages";
import { InputStyled } from "../Style";
import Modal from "../../../../components/Modal";
import styled from "styled-components";

const Text = styled.div`
  text-align: center;
  font-size: 14px;
  font-family: Gotham-Book;
  color: #828d9e;
`;

const {
  EmailLabel,
  FirstNameLabel,
  LastNameLabel,
  PasswordLabel,
  ValidatePasswordLabel,
  SuccessOnRegister,
} = Languages["ESP"];

interface RegisterNewUserModalProps {
  idTeam: string;
  noAccountUser: string;
  title: string;
  alertText: string;
  description: string;
  okText: string;
  cancelText: string;
  onClose: () => void;
  reload: () => void;
}

const RegisterNewUserModal: React.FC<RegisterNewUserModalProps> = ({
  idTeam,
  noAccountUser,
  title,
  alertText,
  description,
  okText,
  cancelText,
  onClose,
  reload,
}) => {
  const [email, setEmail] = useState<string>(noAccountUser);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [validatePassword, setValidatePassword] = useState<string>("");
  const { showMessage } = useContext(MessagesContext);

  const validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const isEmailValid = validateEmail.test(_.trim(email));
  const isFirstNameEmpty = _.isEmpty(_.trim(firstName));
  const isLastNameEmpty = _.isEmpty(_.trim(lastName));
  const isPasswordValid = !_.isEmpty(_.trim(password));
  const isValidatePasswordValid = _.isEqual(validatePassword, password);

  const cantSubmit =
    !isEmailValid ||
    isFirstNameEmpty ||
    isLastNameEmpty ||
    !isPasswordValid ||
    !isValidatePasswordValid;

  const [registerNewUser, registering] = useMutation<[]>({
    func: "Ver2-Team-rnuftw",
    onSuccess: () => {
      showMessage(SuccessOnRegister, "success");
      onClose();
      reload();
    },
  });

  const handleAddNewUser = () => {
    if (cantSubmit) return;

    registerNewUser({
      args: {
        IdTeam: idTeam,
        Email: email,
        Password: sha256(password),
        FirstName: firstName,
        LastName: lastName,
      },
    });
  };

  return (
    <Modal
      visible
      onCancel={onClose}
      title="Agregar nuevo integrante"
      width="432px"
    >
      <Text style={{ color: "#db232c" }}>{alertText}</Text>

      <RegisterForm>
        <Text>{description}</Text>

        <InputStyled
          placeholder={FirstNameLabel}
          value={firstName}
          onChange={({ target: { value } }) => {
            setFirstName(value);
          }}
          style={{ width: "100%" }}
        />

        <InputStyled
          style={{ width: "100%" }}
          placeholder={LastNameLabel}
          value={lastName}
          onChange={({ target: { value } }) => {
            setLastName(value);
          }}
        />

        <InputStyled
          style={{ width: "100%" }}
          placeholder={EmailLabel}
          value={email}
          onChange={({ target: { value } }) => {
            if (value.split("")[value.length - 1] !== " ")
              setEmail(_.trim(value));
          }}
        />

        <InputStyled
          placeholder={PasswordLabel}
          style={{ width: "100%" }}
          value={password}
          type="password"
          autoComplete="current-password"
          onChange={({ target: { value } }) => {
            setPassword(value);
          }}
        />

        <InputStyled
          placeholder={ValidatePasswordLabel}
          style={{ width: "100%" }}
          value={validatePassword}
          type="password"
          autoComplete="current-password"
          onChange={({ target: { value } }) => {
            setValidatePassword(value);
          }}
        />
      </RegisterForm>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <Button onClick={onClose} loading={registering} type="secondary">
          {cancelText}
        </Button>
        <Button
          onClick={handleAddNewUser}
          disabled={cantSubmit}
          loading={registering}
          type="primary"
        >
          {okText}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterNewUserModal;
