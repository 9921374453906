import React from "react";
import styled from "styled-components";
import { ReactComponent as VarDBIconSVG } from "../../resources/img/varDBIconVariable.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface VarDBIconProps {
  $width?: string;
  $height?: string;
}

const VarDBIcon: React.FC<VarDBIconProps> = ({
  $width = "14px",
  $height = "14px",
}) => {
  return (
    <IconWrapper>
      <VarDBIconSVG width={$width} height={$height} />
    </IconWrapper>
  );
};

export default VarDBIcon;
