import React from "react";
import styled from "styled-components";
import Modal from "../Modal";
import Button from "../Button";
import { noop as NOOP } from "lodash";
import { Row } from "antd";
import { Languages } from "./Dictionary";

const Title = styled.div`
  font-size: medium;
  font-family: "Gotham-Medium";
  color: #5f5f5f;
  text-align: center;
  padding: 0rem 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: -0.8rem;
`;

const Subtitle = styled.div`
  font-size: medium;
  font-family: "Gotham-Book";
  color: #5f5f5f;
  text-align: center;
  padding: ${({ $padding }) => ($padding ? "0rem 1.5rem" : "0rem 0.5rem")};
  margin-bottom: 1rem;
`;

const ButtonBorder = styled.div`
  background: linear-gradient(to right, #84be54, #3f7db8);
  border-radius: 10px;
  padding: 1px;
  display: flex;
  align-items: center;
  width: calc(50% - 0.5rem);
`;

const ExcelExportAlert = ({
  onCancel = NOOP,
  onAccept = NOOP,
  isOwner = false,
}) => {
  const { titleLabel, memberLabel, leaderLabel, cancelLabel, hireLabel } =
    Languages["ESP"];

  const isOwnerTxt = isOwner ? leaderLabel : memberLabel;

  return (
    <Modal width={400} visible onCancel={onCancel}>
      <Title>{titleLabel}</Title>
      <Subtitle $padding={isOwner}>{isOwnerTxt}</Subtitle>
      <Row justify={isOwner ? "space-between" : "center"}>
        <Button
          type="default"
          style={{ width: "calc(50% - 0.5rem)" }}
          onClick={onCancel}
        >
          {cancelLabel}
        </Button>
        {isOwner && (
          <ButtonBorder>
            <Button
              type="default"
              style={{ width: "100%", borderRadius: "9px", border: "none" }}
              onClick={onAccept}
            >
              {hireLabel}
            </Button>
          </ButtonBorder>
        )}
      </Row>
    </Modal>
  );
};

export default ExcelExportAlert;
