import React from "react";
import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Row } from "antd";

interface ModalProps {
  setVisible: () => void;
  onConfirm: () => void;
  loading: boolean;
}

const ConfirmDeleteCardModal: React.FC<ModalProps> = ({
  setVisible,
  onConfirm,
  loading,
}) => {
  return (
    <Modal
      title="Eliminar método de pago"
      visible
      onCancel={setVisible}
      titleColor="red"
      width="23%"
    >
      <div>¿Deseas eliminar este método de pago?</div>
      <Row justify="space-between" style={{ marginTop: "1rem" }}>
        <Button
          type="default"
          onClick={setVisible}
          style={{ width: "48%" }}
          loading={loading}
        >
          Cancelar
        </Button>
        <Button
          type="warning"
          style={{ width: "48%" }}
          onClick={onConfirm}
          loading={loading}
        >
          Eliminar
        </Button>
      </Row>
    </Modal>
  );
};

export default ConfirmDeleteCardModal;
