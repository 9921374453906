import React from "react";
import { EditorState } from "draft-js";
import FieldIcon from "../../../Images/field.svg";
import { addFieldEntity } from "../../../Utils/Editor";
import classNames from "classnames";

const Languages = {
  ENG: {
    LabelTitle: "Add field",
  },
  ESP: {
    LabelTitle: "Insertar campo",
  },
};

interface FieldProps {
  generatedFields: number;
  setGeneratedFields: (newCount: number) => void;
  onChange: (newState: EditorState) => void;
  editorState: EditorState;
  largeButton: boolean;
  language: "ESP" | "ENG";
}

const Field: React.FC<FieldProps> = ({
  onChange,
  editorState,
  largeButton,
  language,
}) => {
  const { LabelTitle } = Languages[language];

  const handleAddField = () => {
    addFieldEntity(editorState, onChange);
  };

  return largeButton ? (
    <div
      onClick={handleAddField}
      className="geestEditorCustomWrapper"
      title={LabelTitle}
    >
      <div className={classNames("geestEditorCustomOption", { largeButton })}>
        <img src={FieldIcon} alt="" />
        <p>{LabelTitle}</p>
      </div>
    </div>
  ) : (
    <div
      onClick={handleAddField}
      className="geestEditorCustomWrapper"
      title={LabelTitle}
    >
      <div className="geestEditorCustomOption">
        <img src={FieldIcon} alt="" />
      </div>
    </div>
  );
};

export default Field;
