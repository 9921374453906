import React, { useContext, useEffect, useState } from "react";
import _, { noop as NOOP } from "lodash";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useFetch, useMutation } from "../../../hooks";
import ViewTitle from "../SharedComponents/ViewTitle";
import { Languages } from "../Dictionary";
import { StyledTab, ReportsHeader } from "../Styles";
import {
  GetTeamAndFiltersResponse,
  Status,
  Team,
  Filter,
  GetDashboardReportResponse,
  GeneralInformationType,
  ProcessesInformationType,
  TeamMember,
  Template,
  UserTask,
} from "./Dashboard.d";
import {
  DashboardCard,
  DashboardContent,
  DasboardLeft,
  DasboardRight,
} from "./Styles";
import Filters from "./Filters";
import GeneralInformation from "./GeneralInformation";
import ProcessesInformation from "./ProcessesInformation";
import TeamMembersInformation from "./TeamMembersInformation";
import DetailPending from "../../Pendings/DetailPending";
import ProcessDetail from "../SharedComponents/ProcessDetail";
import Flux from "../SharedComponents/Flux";
import Fields from "../SharedComponents/Fields";
import UnsavedChanges from "../SharedComponents/UnsavedChanges";
import { MessagesContext } from "../../../components/AppMessages";
import useReport from "../ReportsContext";

const Dashboard: React.FC = () => {
  const {
    statuses: zusStatuses,
    teams: zusTeams,
    currentTeam: zusCurrentTeam,
    selectedProcess: zusSelectedProcess,
    generalInformation: zusGeneralInformation,
    processInformation,
    setSelectedProcess: setSelectedProcessZus,
    setCurrentTeam: setCurrentTeamZus,
    setGeneralInformation: setGeneralInformationZus,
    setProcessInformation: setProcessesInformationZus,
    setSatuses: setSatusesZus,
    setTeamMembersInformation: setTeamMembersInformationZus,
    setTeams: setTeamsZus,
    filters: filterZus,
    setFilters: setFilterrZus,
  } = useReport();
  const { TabTitles, Messages } = Languages["ESP"];
  const { pathname } = useLocation();
  const [statuses, setStatuses] = useState<Status[]>(zusStatuses);
  const [teams, setTeams] = useState<Team[]>(zusTeams);
  const [currentTeam, setCurrentTeam] = useState<Team>(zusCurrentTeam);
  const [idTeam, setIdTeam] = useState<number>(zusCurrentTeam.IdTeam || 0);
  const [filters, setFilters] = useState<Filter>(filterZus);
  const [selectedProcess, setSelectedProcess] = useState<Template | null>(
    zusSelectedProcess
  );
  const [canEditProcess, setCanEditProcess] = useState<boolean>(false);
  const [processToOpen] = useState<number | null>(null);
  const [modal, setModal] = useState<"" | "Flux" | "Fields">("");
  const [openUnsaved, setOpenUnsaved] = useState<boolean>(false);
  const [multiModalVisible, setMultiModalVisible] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(!zusCurrentTeam.IdTeam);

  const [generalInformation, setGeneralInformation] =
    useState<GeneralInformationType>(zusGeneralInformation);
  const [processesInformation, setProcessesInformation] =
    useState<ProcessesInformationType[]>(processInformation);
  const [teamMembersInformation, setTeamMembersInformation] = useState<
    TeamMember[]
  >([]);
  const [userTasksStatus, setUserTasksStatus] = useState<{
    IdUser: number;
    Status: number;
  } | null>(null);
  const [userTasks, setUserTasks] = useState<UserTask[]>([]);
  const history = useHistory();

  const { showMessage } = useContext(MessagesContext);

  const Routes = [
    {
      title: TabTitles.Tracking,
      path: "tracking",
    },
    {
      title: TabTitles.Pipeline,
      path: "pipeline",
    },
  ];

  const { reload: ReloadData, loading } = useFetch<GetTeamAndFiltersResponse>({
    func: "Ver2-Tracking-gtaf",
    onSuccess: (res) => {
      setStatuses(res.Statuses);
      if (firstLoad) {
        setTeams(res.Teams);
        handleChangeTeam(_.get(res, "Teams[0].IdTeam", 0), res.Teams);
      } else if (selectedProcess) {
        getProcessInfo(selectedProcess);
      }
    },
  });

  const [setUpDashboardReport, settingUp] =
    useMutation<GetDashboardReportResponse>({
      func: "Ver2-Tracking-gtr",
      onSuccess: (
        { GeneralInformation, ProcessesInformation, TeamMembersInformation },
        shippedData = { updateTeam: true, updatedFilter: false }
      ) => {
        setGeneralInformation(GeneralInformation);
        if (
          !selectedProcess ||
          !GeneralInformation.Templates.find(
            (t) => t.IdProcessTemplate === selectedProcess?.IdProcessTemplate
          ) ||
          shippedData.updatedFilter
        ) {
          setProcessesInformation(ProcessesInformation);
          setSelectedProcess(GeneralInformation.Templates[0]);
        }
        if (shippedData.updateTeam) {
          setTeamMembersInformation(TeamMembersInformation);
        }
      },
    });

  const [getProcessExecutions, gettingExecutions] = useMutation<
    ProcessesInformationType[]
  >({
    func: "Ver2-Tracking-gpe",
    onSuccess: (res) => setProcessesInformation(res),
  });

  const [getUserTasks, gettingUserTasks] = useMutation<UserTask[]>({
    func: "Ver2-Tracking-gut",
    onSuccess: (res) => setUserTasks(res),
  });

  useEffect(() => {
    setSelectedProcessZus(null);
    setTeamMembersInformationZus([]);
    setTeamsZus([]);
    setProcessesInformationZus([]);
    setGeneralInformationZus({} as GeneralInformationType);
    setCurrentTeamZus({} as Team);
    setSatusesZus([]);
    setFilterrZus({});
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let filtersToCall = _.cloneDeep(filters);
    const filtersOnLocalStore = localStorage.getItem(
      `${currentTeam.IdTeam}-TrackingFilters`
    );
    let auxFilters: Filter = {};
    if (filtersOnLocalStore) {
      auxFilters = JSON.parse(filtersOnLocalStore);
    } else {
      auxFilters = JSON.parse('{ "Statuses": "1,4,5" }');
      localStorage.setItem(
        `${currentTeam.IdTeam}-TrackingFilters`,
        JSON.stringify(auxFilters)
      );
    }

    let newFilters: Filter = {};
    Object.keys(auxFilters).forEach((Key) => {
      switch (Key) {
        case "StartDate":
        case "EndDate":
          newFilters[Key] = auxFilters[Key];
          break;
        case "Statuses":
          newFilters[Key] = auxFilters[Key];
          break;
        case "TeamMembers":
          let val = auxFilters[Key];
          let newValArray: string[] = [];
          val?.split(",").forEach((idUser: string) => {
            if (
              currentTeam?.TeamMembers?.map(({ IdUser }) => IdUser)?.includes(
                Number(idUser)
              )
            ) {
              newValArray.push(idUser);
            }
          });
          const newVal = newValArray.join(",");
          if (newVal) {
            newFilters[Key] = newVal;
          }
          break;
        case "Proccesses":
          let processVal = auxFilters[Key];
          let newProcessValArray: string[] = [];
          processVal?.split(",").forEach((idTemplate: string) => {
            if (
              currentTeam?.Proccesses?.map(
                ({ IdTemplateProcess }) => IdTemplateProcess
              )?.includes(Number(idTemplate))
            ) {
              newProcessValArray.push(idTemplate);
            }
          });
          const newProcessVal = newProcessValArray.join(",");
          if (newProcessVal) {
            newFilters[Key] = newProcessVal;
          }
          break;
      }
    });

    filtersToCall = newFilters;
    setFilters(filtersToCall);
    if (!_.isEqual(auxFilters, newFilters)) {
      localStorage.setItem(
        `${currentTeam.IdTeam}-TrackingFilters`,
        JSON.stringify(newFilters)
      );
    }

    if (
      currentTeam.IdTeam &&
      !settingUp &&
      !gettingExecutions &&
      !gettingUserTasks
    ) {
      if (firstLoad) {
        setFirstLoad(false);
      }

      setUpDashboardReport({
        args: {
          IdTeam: currentTeam.IdTeam,
          Filters: filtersToCall,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeam]);

  const softReload = () => {
    setIdTeam(currentTeam.IdTeam);
    setUpDashboardReport({
      args: {
        IdTeam: currentTeam.IdTeam,
        Filters: filters,
      },
      shippedData: {
        updateTeam: false,
      },
    });
  };

  const updateDashboardReport = (
    currentFilter: Filter,
    filterChanged?: boolean
  ) => {
    if (!settingUp && !gettingExecutions && !gettingUserTasks) {
      setUpDashboardReport({
        args: {
          IdTeam: currentTeam.IdTeam,
          Filters: currentFilter,
        },
        shippedData: {
          updatedFilter: filterChanged,
          updateTeam: true,
        },
      });
    }
  };

  const handleChangeTeam = (
    newTeamId: number,
    currentTeams: Team[] = teams
  ) => {
    setIdTeam(newTeamId);
    const team = _.find(currentTeams, { IdTeam: newTeamId }) || ({} as Team);
    setCurrentTeam(team);
    if (firstLoad) setFirstLoad(false);

    const auxFilters = JSON.parse(
      localStorage.getItem(`${newTeamId}-TrackingFilters`) ??
        '{ "Statuses": "4,5" }'
    );

    setFilters(auxFilters);
  };

  const getProcessInfo = (template: Template) => {
    setSelectedProcess(template);
    getProcessExecutions({
      args: {
        IdTeam: currentTeam.IdTeam,
        Filters: filters,
        IdProcessTemplate: template.IdProcessTemplate,
      },
    });
  };

  const handleGetUserTasks = (idUser: number, status: number) => {
    setUserTasksStatus({ IdUser: idUser, Status: status });
    getUserTasks({
      args: {
        IdTeam: currentTeam.IdTeam,
        Filters: filters,
        IdUser: idUser,
        Status: status,
      },
    });
  };

  const onCloseDetailPending = (needReload = false): void => {
    if (needReload) NOOP();
    history.push("/home/reports/tracking");
  };

  const onChangeFilters = (filter: Filter) => {
    setFilters(filter);
    localStorage.setItem(`${idTeam}-TrackingFilters`, JSON.stringify(filter));
  };

  const Modals: { [key: string]: React.ReactNode } = {
    Flux: (
      <Flux
        IdProcessExecution={processToOpen}
        onClose={() => setModal("")}
        onChangePending={(idPending) => {
          setModal("");
          setTimeout(() => {
            history.push("/home/reports/tracking/" + idPending);
          }, 1);
        }}
        onRestartFlux={() => {
          ReloadData();
          if (userTasksStatus) {
            getUserTasks({
              args: {
                IdTeam: currentTeam.IdTeam,
                Filters: filters,
                ...userTasksStatus,
              },
            });
          }
        }}
      />
    ),
    Fields: (
      <Fields
        IdProcessExecution={processToOpen}
        canEdit={canEditProcess}
        openUnsaved={() => setOpenUnsaved(true)}
        onSuccess={() => {
          showMessage(Messages.UpdateForm, "success");
        }}
        onCancel={() => setModal("")}
      />
    ),
  };

  return (
    <>
      <Route
        path={"/home/reports/tracking/:idPending"}
        render={() => (
          <DetailPending
            onClose={onCloseDetailPending}
            onChangePending={(idPending) => {
              onCloseDetailPending();
              setTimeout(() => {
                history.push("/home/reports/tracking/" + idPending);
              }, 1);
            }}
            reloadReport={() => {
              updateDashboardReport(filters);
              if (userTasksStatus) {
                getUserTasks({
                  args: {
                    IdTeam: currentTeam.IdTeam,
                    Filters: filters,
                    ...userTasksStatus,
                  },
                });
              }
            }}
          />
        )}
      />
      <ProcessDetail
        open={multiModalVisible}
        processId={processToOpen}
        onClose={() => setMultiModalVisible(false)}
        onChangePending={(idPending) => {
          setMultiModalVisible(false);
          setTimeout(() => {
            history.push("/home/reports/tracking/" + idPending);
          }, 1);
        }}
        reload={ReloadData}
        softReload={softReload}
      />
      {Modals[modal]}
      {openUnsaved && (
        <UnsavedChanges
          onCancel={() => setOpenUnsaved(false)}
          onClose={() => {
            setOpenUnsaved(false);
            setModal("");
          }}
        />
      )}
      <DashboardCard>
        <ViewTitle
          teams={teams}
          idTeam={idTeam || currentTeam.IdTeam}
          onChangeTeam={handleChangeTeam}
        >
          <ReportsHeader>
            {Routes.map(({ title, path }, idx) => (
              <StyledTab
                to={`/home/reports/${loading ? "tracking" : path}`}
                $active={pathname.includes(path)}
                key={idx}
              >
                {title}
              </StyledTab>
            ))}
          </ReportsHeader>
        </ViewTitle>

        {currentTeam && (
          <Filters
            processCollection={currentTeam.Proccesses}
            teamMembers={currentTeam.TeamMembers}
            statusCollection={statuses}
            filters={filters}
            setFilters={onChangeFilters}
            showInvolvedFilter={currentTeam.CanViewAllReports}
            updateDashboardReport={(filters) =>
              updateDashboardReport(filters, true)
            }
          />
        )}

        <DashboardContent>
          <DasboardLeft>
            <GeneralInformation
              generalInfo={generalInformation}
              getProcessInfo={getProcessInfo}
              selectedIndex={generalInformation.Templates?.findIndex(
                (t) =>
                  t.IdProcessTemplate === selectedProcess?.IdProcessTemplate
              )}
            />
            <ProcessesInformation
              processesInfo={processesInformation}
              selectedTemplate={selectedProcess}
              openFlux={() => setModal("Flux")}
              openFields={() => setModal("Fields")}
              setProcessToOpen={(id, state) => {
                setSelectedProcessZus(selectedProcess);
                setCurrentTeamZus(currentTeam);
                setGeneralInformationZus(generalInformation);
                setProcessesInformationZus(processesInformation);
                setSatusesZus(statuses);
                setTeamMembersInformationZus(teamMembersInformation);
                setTeamsZus(teams);
                setFilterrZus(filters);
                history.push({
                  pathname: `/home/reports/detail/${id}`,
                  state: {
                    state,
                  },
                });
              }}
              setCanEditProcess={setCanEditProcess}
              openMultiModal={() => setMultiModalVisible(true)}
              reload={ReloadData}
            />
          </DasboardLeft>

          <DasboardRight>
            <TeamMembersInformation
              members={teamMembersInformation}
              handleGetUserTasks={handleGetUserTasks}
              userTasks={userTasks}
              setProcessToOpen={(id) => {
                history.push(`/home/reports/detail/${id}`);
              }}
              openMultiModal={() => setMultiModalVisible(true)}
              setUserTasksStatus={setUserTasksStatus}
            />
          </DasboardRight>
        </DashboardContent>
      </DashboardCard>
    </>
  );
};

export default Dashboard;
