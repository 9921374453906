import styled from "styled-components";

export const DocumentationContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex: 1;
  gap: 10px;
  overflow: auto;
`;

export const SidePanel = styled.nav`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 4px 6px;

  background: #48505e;
  border-radius: 6px;
`;

export const StyledLink = styled.a<{ $isActive: boolean }>`
  padding: 4px 6px;
  border-radius: 6px;
  cursor: pointer;
  color: ${({ $isActive = false }) => ($isActive ? "white" : "#eee")};
  background: ${({ $isActive = false }) => ($isActive ? "#1890ff" : "#48505e")};
  transition: all 0.35s ease;
  :hover {
    color: white;
    background: #1890ff;
  }
`;

export const BodyContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 4px 6px;
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 1000px;

  h2,
  h3 {
    color: #48505e;
    font-size: 24px;
    font-family: Gotham-Bold;
    margin: 0;
    margin-bottom: 4px;
  }

  h3 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    font-size: 18px;
    margin-top: 10px;
  }

  span.info {
    background: #eee;
    border-radius: 6px;
    padding: 2px 4px;
    font-size: 12px;
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
      monospace;
  }
  span.type {
    color: #1890ff;
  }
`;

export const ArticleWrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #48505e;

  a {
    color: #1890ff;
  }

  hr {
    width: 100%;
  }

  code.code {
    background-color: #eee;
    border-radius: 6px;
    padding: 2px 4px;
  }
  code.type {
    color: #1890ff;
  }

  p {
    margin: 0;
  }
  p.note {
    border: 1px solid #48505e;
    border-left: 6px solid #1890ff;
    padding: 4px;
    padding-left: 10px;
    border-radius: 4px;
    font-style: italic;
    color: #777;
    font-size: 12px;
    margin: 4px 0;
  }
`;

export const SnippetWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ababab;

  transition: color 0.35s ease;
  :hover {
    color: white;
  }
`;

export const EditorWrapper = styled.div`
  background-color: #eee;
  padding: 10px;
  border-radius: 4px;
`;
