const data = {
  columns: [
    {
      name: "Usuario",
      primaryKey: true,
    },
    {
      name: "Nombre de medición completa 1",
      primaryKey: false,
      IdStat: 12,
    },
    {
      name: "Nombre de medición completa 2",
      primaryKey: false,
      IdStat: 3,
    },
    {
      name: "Nombre de medición completa 3",
      primaryKey: false,
      IdStat: 312,
    },
  ],
  rows: [
    {
      cells: [
        {
          Value: "Carlos",
          Format: "MULTI_COIN", // Si un campo de moneda tiene varias currencies el format
          IdStat: "",
        },
        {
          Value: "100",
          Format: "",
          IdStat: 12,
        },
        {
          Value: "456",
          Format: "",
          IdStat: 3,
        },
        {
          Value: "456",
          Format: "",
          IdStat: 312,
        },
      ],
      metadata: {
        Filters: [
          {
            Type: "Field",
            IdfieldTemplate: 123,
            Value: 1,
          },
        ],
      },
    },
    {
      cells: [
        {
          Value: "Alma",
          Format: "MULTI_COIN", // Si un campo de moneda tiene varias currencies el format
          IdStat: "",
        },
        {
          Value: "100",
          Format: "",
          IdStat: 12,
        },
        {
          Value: "456",
          Format: "",
          IdStat: 3,
        },
        {
          Value: "456",
          Format: "",
          IdStat: 312,
        },
      ],
      metadata: {
        Filters: [
          {
            Type: "Field",
            IdfieldTemplate: 123,
            Value: 1,
          },
        ],
      },
    },
    {
      cells: [
        {
          Value: "Total",
          Format: "MULTI_COIN", // Si un campo de moneda tiene varias currencies el format
          IdStat: "",
        },
        {
          Value: "100",
          Format: "",
          IdStat: 12,
        },
        {
          Value: "456",
          Format: "",
          IdStat: 3,
        },
        {
          Value: "456",
          Format: "",
          IdStat: 312,
        },
      ],
      metadata: null,
    },
  ],
};

export default data;
