export const Languages = {
  ENG: {
    TeamMembersTitle: "",
  },
  ESP: {
    TeamMembersTitle: "Información por integrante",
    HeaderTasksLabel: "Tareas",
    HeaderStartedLabel: "Iniciadas",
    HeaderStuckLabel: "Vencidas",
    HeaderWaitingLabel: "Por iniciar",
    HeaderFinishedLabel: "Terminadas",
    HeaderFollowUpLabel: "Seguimiento",
    StartedDateLabel: "Inicio",
    StuckDateLabel: "Deadline",
    WaitingDateLabel: "Inicio",
    FinishedDateLabel: "Fin",
    ProcessDetailsTooltipLabel: "Ver detalle de seguimiento",
    ViewTaksTooltipLabel: "Ver tarea",
  },
};
