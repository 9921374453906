import React from "react";
import styled from "styled-components";
import StackBar from "./StackBar";
import DoughnutGraph from "./DoughnutGraph";
import { GraphRenderInfo, GraphType } from "../Dashboards.d";
//@ts-ignore
import emoji from "emoji-dictionary";

const Container = styled.div<{ justify: string }>`
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ justify = "center" }) => justify};
  gap: 20px;

  padding: 0 20px;
`;

const StackBarContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const DoughnutContainer = styled.div`
  canvas {
    max-height: 200px;
  }
`;

interface DynamicGraphProps {
  data: GraphType[];
  loading?: boolean;
  selectedTemplate: number;
}

const DynamicGraph: React.FC<DynamicGraphProps> = ({
  data,
  loading,
  selectedTemplate,
}) => {
  return (
    // <Container justify={[...data].length > 1 ? "center" : "flex-start"}>
    <Container justify={"center"}>
      {data.map((graph) => {
        graph = {
          ...graph,
          RenderInfo: {
            ...graph.RenderInfo,
            //@ts-ignore
            datasets: graph.RenderInfo.datasets.map((ds) => ({
              ...ds,
              label: ds.label.replace(
                /:\w+:/gi,
                (name: string) => emoji.getUnicode(name) ?? name
              ),
            })),
          },
        };
        switch (graph.Configuration.Type) {
          case "StackBar":
            return (
              <StackBarContainer>
                <StackBar
                  config={graph.Configuration}
                  renderInfo={graph.RenderInfo as GraphRenderInfo}
                  showLegend={data.length === 1}
                  selectedTemplate={selectedTemplate}
                />
              </StackBarContainer>
            );

          case "Doughnut":
            return (
              <DoughnutContainer>
                <DoughnutGraph
                  config={graph.Configuration}
                  renderInfo={graph.RenderInfo as GraphRenderInfo}
                  selectedTemplate={selectedTemplate}
                />
              </DoughnutContainer>
            );

          default:
            return null;
        }
      })}
    </Container>
  );
};

export default DynamicGraph;
