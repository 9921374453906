import React from "react";
import { Row } from "antd";
import { ConfigureVarDBProps } from "./InstallTemplate.d";
import {
  TemplateImportsDescription,
  ColumnTitle,
  ImportedVarDBItem,
  VarDBColumnsContainer,
} from "./style";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import { Button } from "../../../GeestUI";
import { Languages } from "./Dictionary";

const ConfigureVarDB: React.FC<ConfigureVarDBProps> = ({
  activeStep,
  varDBs,
  setActiveStep,
  steps,
  onInstall,
  varDBData,
  setVarDBs,
}) => {
  const {
    CreateNewColumnLabel,
    SelectColumnsLabel,
    ExistingColumnLabel,
    PlaceInColumnLabel,
    NextLabel,
    InstallLabel,
  } = Languages["ESP"];
  const selectedVarDB = varDBs?.filter(
    ({ TeamDestinyIdVarDB }) => TeamDestinyIdVarDB !== 0
  )[activeStep - 2];

  const getOptions = (DataType: string, IdColumn: number) => {
    let filteredVarDBColumns = varDBData?.TeamDestinyVarDBColumns.filter(
      ({ DataType: dt }) => dt === DataType
    );

    if (selectedVarDB?.ImportingColumns) {
      let selectedColumns = Object.keys(selectedVarDB?.ImportingColumns).map(
        (IdC) =>
          selectedVarDB.ImportingColumns &&
          Number(IdC) !== IdColumn &&
          selectedVarDB.ImportingColumns[Number(IdC)]
      );

      filteredVarDBColumns = filteredVarDBColumns?.filter(
        ({ IdColumn }) => !selectedColumns.includes(IdColumn)
      );
    }

    let options =
      filteredVarDBColumns?.map(({ Title, IdColumn }) => {
        let idDestiny = 0;

        varDBData?.ProcessTemplateVarDBColumns.forEach((column) => {
          if (column.IdDestinyColumn === IdColumn) {
            idDestiny = column.IdColumn;
          }
        });

        return {
          label: Title,
          value: IdColumn,
          idDestiny: idDestiny,
        };
      }) || [];

    options = [
      {
        label: CreateNewColumnLabel,
        value: 0,
        idDestiny: 0,
      },
      ...options,
    ];
    return options;
  };

  const getValue = (IdColumn: number, options: any[]) => {
    if (
      selectedVarDB?.ImportingColumns &&
      selectedVarDB.ImportingColumns[IdColumn] === null
    ) {
      let newValue = 0;
      options.forEach((option) => {
        if (option.idDestiny === IdColumn) {
          newValue = option.value;
        }
      });

      onChangeSelect(newValue, IdColumn);

      return newValue;
    }

    return selectedVarDB?.ImportingColumns
      ? selectedVarDB.ImportingColumns[IdColumn]
      : 0;
  };

  const onChangeSelect = (value: number, IdColumn: number) => {
    let varDBsAux = [...varDBs];
    let varDBAux = selectedVarDB;
    const varDBIndex = varDBsAux.findIndex(
      ({ ProcessTemplateIdVarDB }) =>
        ProcessTemplateIdVarDB === selectedVarDB?.ProcessTemplateIdVarDB
    );
    if (varDBAux) {
      if (!varDBAux?.ImportingColumns) {
        varDBAux.ImportingColumns = {};
      }
      varDBAux.ImportingColumns[IdColumn] = value;
      varDBsAux[varDBIndex] = varDBAux;
      setVarDBs(varDBsAux);
    }
  };
  return (
    <Row style={{ width: "100%", marginTop: "2rem" }} justify="center">
      <div style={{ width: "548px" }}>
        <TemplateImportsDescription>
          {SelectColumnsLabel}
        </TemplateImportsDescription>
        <Row justify="space-between" style={{ width: "100%" }}>
          <div style={{ width: "236px" }}>
            <ColumnTitle>{`Base de datos ${varDBData?.ProcessTemplateVarDBTitle}`}</ColumnTitle>
            <TemplateImportsDescription style={{ marginBottom: "1.5rem" }}>
              {ExistingColumnLabel}
            </TemplateImportsDescription>
          </div>
          <div style={{ width: "236px" }}>
            <ColumnTitle>{`Base de datos ${varDBData?.TeamDestinyVarDBTitle}`}</ColumnTitle>
            <TemplateImportsDescription style={{ marginBottom: "1.5rem" }}>
              {PlaceInColumnLabel}
            </TemplateImportsDescription>
          </div>
        </Row>
        <VarDBColumnsContainer>
          {varDBData?.ProcessTemplateVarDBColumns.map(
            ({ Title, IdColumn, DataType }) => (
              <Row
                justify="space-between"
                style={{ width: "100%" }}
                key={IdColumn}
              >
                <ImportedVarDBItem>{Title}</ImportedVarDBItem>
                <div style={{ width: "236px" }}>
                  <GeestSelect
                    value={getValue(IdColumn, getOptions(DataType, IdColumn))}
                    options={getOptions(DataType, IdColumn)}
                    onChange={(value) => onChangeSelect(value, IdColumn)}
                    disableSearch
                    valueNecesary
                  />
                </div>
              </Row>
            )
          )}
        </VarDBColumnsContainer>
        <Row justify="center" style={{ width: "100%", marginTop: "1rem" }}>
          {steps.length === activeStep + 1 ? (
            <Button type="primary" onClick={onInstall}>
              {InstallLabel}
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => setActiveStep(activeStep + 1)}
            >
              {NextLabel}
            </Button>
          )}
        </Row>
      </div>
    </Row>
  );
};

export default ConfigureVarDB;
