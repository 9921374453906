import React, { useRef, useState, useEffect } from "react";
import { ViewContentAction, ActionFormTitle, NoPriorityTag } from "./styles";
import ViewHeader from "./ViewHeader";
import changeTasksPriorityActionIcon from "../../../../../../resources/img/changeTasksPriorityActionIcon.svg";
import {
  Priority,
  GetChangeTaskPriorityConfigResponse,
  ActionProps,
} from "../AutomationConfig.d";
import { useFetch } from "../../../../../../hooks";
import { useParams } from "react-router-dom";
import GeestSelect from "../../../../../../GeestUI/GeestSelect";
import { Tag } from "../../../../../../GeestUI";
import { EditIcon } from "../../../../../../components/hoverIcons";
import { Row } from "antd";
import { Popover } from "@material-ui/core";
import PriorityPopover from "./PriorityPopover";
//@ts-ignore
import emoji from "emoji-dictionary";
import { Action } from "../EditAction.d";

const ChangeTasksPriorityActionView: React.FC<ActionProps> = ({
  reloadHome,
  selectedAction,
  setSelectedAction,
  IdNode,
  actionOptions,
  saveAction,
}) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const editPriorityButton = useRef(null);
  const [priorityPopoverVisible, setPriorityPopoverVisible] =
    useState<boolean>(false);

  const { data } = useFetch<GetChangeTaskPriorityConfigResponse[]>({
    func: "Ver2-Configurator-gaco",
    args: {
      IdTeam,
      IdProcessTemplate,
      IdNode,
      Action: selectedAction?.Action,
    },
  });

  useEffect(() => {
    if (!selectedAction.Tasks) {
      let actionAux = { ...selectedAction };
      actionAux.Tasks = "0";
      setSelectedAction(actionAux);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction]);

  const taskOptions =
    data?.map(({ Id, Title }) => ({
      label: Title.replace(/:\w+:/gi, (name) => emoji.getUnicode(name) ?? name),
      value: String(Id),
    })) || [];

  const options = [{ label: "Todas", value: "0" }, ...taskOptions];

  const sendAction = (action: Action) => {
    if (!!action.Tasks) {
      saveAction(action);
    }
  };

  const onChangeTaskOptions = (val: string) => {
    if (selectedAction) {
      let value = val;
      if (!value) {
        if (selectedAction?.Tasks === "0") {
          return;
        } else {
          value = "0";
        }
      }
      if (value.startsWith("0,")) {
        value = value.replace("0,", "");
      }
      if (value.includes(",0")) {
        value = "0";
      }
      let selectedActionAux = { ...selectedAction };
      selectedActionAux.Tasks = value;
      setSelectedAction(selectedActionAux);
      sendAction(selectedActionAux);
    }
  };

  const onChangePriority = (val: Priority | null) => {
    if (selectedAction) {
      let selectedActionAux = { ...selectedAction };
      selectedActionAux.Priority = val;
      setSelectedAction(selectedActionAux);
      sendAction(selectedActionAux);
    }
  };

  return (
    <ViewContentAction>
      <ViewHeader
        reloadHome={reloadHome}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
        IdNode={IdNode}
        icon={changeTasksPriorityActionIcon}
        actionOptions={actionOptions}
      />
      <div>
        <ActionFormTitle>
          Selecciona la(s) tarea(s) a la(s) que quieras cambiarle(s) la
          prioridad
        </ActionFormTitle>
        <GeestSelect
          options={options}
          onChange={onChangeTaskOptions}
          value={selectedAction?.Tasks || ""}
          multiselect
          showTags
          $width="80%"
        />
      </div>
      {!!data?.length && (
        <Row
          style={{ width: "188px", fontFamily: "Gotham-Book" }}
          justify="space-between"
        >
          <ActionFormTitle>Prioridad</ActionFormTitle>
          {!!selectedAction?.Priority ? (
            <Tag color={selectedAction?.Priority?.Color || "#757575"}>
              {selectedAction?.Priority?.Title}
            </Tag>
          ) : (
            <NoPriorityTag>Sin prioridad</NoPriorityTag>
          )}
          <EditIcon
            filled
            ref={editPriorityButton}
            onClick={() => setPriorityPopoverVisible(true)}
          />
          <Popover
            open={priorityPopoverVisible}
            anchorEl={editPriorityButton.current}
            onClose={() => setPriorityPopoverVisible(false)}
            PaperProps={{
              style: {
                borderRadius: "10px",
                width: "362px",
                padding: "12px 16px",
                height: "366px",
              },
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <PriorityPopover
              setVisible={setPriorityPopoverVisible}
              taskPriority={selectedAction?.Priority}
              onChange={onChangePriority}
              idTaskTemplate={data ? data[0].Id : null}
            />
          </Popover>
        </Row>
      )}
    </ViewContentAction>
  );
};

export default ChangeTasksPriorityActionView;
