import React from "react";
import { StringInput } from "../../styles";
import {
  ColorPickerContainer,
  ConfigRow,
  ConfigRowName,
  InputsRow,
  SignConfigContainer,
} from "./styles";
import ColorPicker, { Circle } from "../../../../ColorPicker";

interface SignConfigProps {
  nodeName: string;
  setNodeName: (newName: string) => void;
  signColor: string;
  setSignColor: (newColor: string) => void;
}

const SignConfig: React.FC<SignConfigProps> = ({
  nodeName,
  setNodeName,
  signColor,
  setSignColor,
}) => {
  const handleOnChangeColor = (newColor: string) => {
    setSignColor(newColor !== signColor ? newColor : "#000");
  };

  return (
    <SignConfigContainer>
      <ConfigRow>
        <ConfigRowName>Nombre del elemento</ConfigRowName>
        <InputsRow>
          <StringInput
            value={nodeName}
            onChange={({ target: { value } }) => setNodeName(value)}
          />
          <ColorPickerContainer>
            <ColorPicker
              color={signColor}
              onChange={handleOnChangeColor}
              popoverStyle={{
                zoom: "75%",
                marginLeft: "20%",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Circle
                color={signColor}
                size={18}
                style={{ cursor: "pointer" }}
              />
            </ColorPicker>
          </ColorPickerContainer>
        </InputsRow>
      </ConfigRow>
    </SignConfigContainer>
  );
};

export default SignConfig;
