import styled from "styled-components";

export const TeamMembersInformationContainer = styled.div`
  padding: 0.5rem 1rem;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  color: #727e8b;
`;

export const CardTitle = styled.div`
  font-family: "Gotham-Bold";
  margin-bottom: 0.5rem;
`;

export const HeadersContainer = styled.div`
  display: flex;
`;

export const HeaderItem = styled.div<{ $flex?: number; $center?: boolean }>`
  flex: ${({ $flex = 1 }) => $flex};
  text-align: ${({ $center = false }) => ($center ? "center" : "")};
`;

export const TeamMembersBox = styled.div`
  flex: 1;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const TeamMemberContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 0.5rem;
`;

export const TeamMemberData = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const UserContainer = styled.div`
  flex: 2;
`;

export const MemberDataButton = styled.div`
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #727e8b;
  padding: 0.2rem 0;
  background-color: #eff1f4;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #c0c9d3;
  }
`;

export const StartedButton = styled(MemberDataButton)<{ $selected: boolean }>`
  color: #08979c;
  background-color: ${({ $selected = false }) =>
    $selected ? "#a4fff0" : "#e6fffb"};
  :hover {
    background-color: #c6fff6;
  }
`;

export const StuckButton = styled(MemberDataButton)<{ $selected: boolean }>`
  color: #e11909;
  background-color: ${({ $selected = false }) =>
    $selected ? "#f1a69f" : "#f2d4d1"};
  :hover {
    background-color: #f2beb9;
  }
`;

export const WaitingButton = styled(MemberDataButton)<{ $selected: boolean }>`
  color: #d46b08;
  background-color: ${({ $selected = false }) =>
    $selected ? "#ffe2a5" : "#fff7e6"};
  :hover {
    background-color: #fff0d0;
  }
`;

export const CompletedButton = styled(MemberDataButton)<{ $selected: boolean }>`
  color: #389e0d;
  background-color: ${({ $selected = false }) =>
    $selected ? "#d1ffa4" : "#f6ffed"};
  :hover {
    background-color: #ebffd7;
  }
`;

export const TasksInfoContainer = styled.div<{ $selected: boolean }>`
  max-height: 250px;
  transition: height 0.35s ease, opacity 0.35s ease, margin-top 0.35s ease;
  height: ${({ $selected = false }) => ($selected ? "" : 0)};
  opacity: ${({ $selected = false }) => ($selected ? 1 : 0)};
  margin-top: ${({ $selected = false }) => ($selected ? "0.5rem" : 0)};
`;

export const TasksContainer = styled.div`
  background-color: #fafbfd;
  max-height: calc(250px - 1rem);
  height: 100%;
  overflow: auto;
  padding: 0.5rem;
`;

export const TasksHeader = styled.div`
  margin-bottom: 0.2rem;
  display: flex;
  gap: 0.2rem;
`;

export const TasksHeaderTitle = styled.div<{ $flex: number; $color: string }>`
  flex: ${({ $flex }) => $flex};
  color: ${({ $color }) => $color};
  font-family: "Gotham-Bold";
`;
export const TasksHeaderItem = styled.div<{ $flex: number }>`
  flex: ${({ $flex }) => $flex};
  text-align: center;
`;

export const Tasks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const Task = styled.div`
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  padding: 0.2rem;
`;

export const TaskItem = styled.div<{ $flex: number; $center: boolean }>`
  flex: ${({ $flex }) => $flex};
  text-align: ${({ $center }) => ($center ? "center" : "")};
  max-width: calc(${({ $flex }) => ($flex * 100) / 9}%);
`;

export const TitleWrapper = styled.div`
  max-width: 100%;
  display: flex;

  p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const TaskIcons = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #dfe4e9;
  }
`;
