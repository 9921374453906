export const languages = {
  ENG: {
    SearchLabel: "Search",

    ElementTypes: "Element types",
    ElementTypesInfo:
      "Drag the buttons and drop them onto the sheet according to your needs",
    TextElementName: "Text editor",
    ImageElementName: "Image",
    SignElementName: "Sign",

    CreatedElements: "Created elements",
    CreatedElementsInfo:
      "Here you can view the list of elements you have created. To create one, simply drag and drop it onto the sheet and configure as needed",
    NoCreatedElements: "No created elements",
    noSearchResults: "No results found",

    ConfigureElement: "Configure element",
    TextEditorLabel: "Text editor",
    ImageEditorLabel: "Image",
    SignEditorLabel: "Sign",
    DeleteElement: "Delete element",
    Accept: "Accept",

    UnsavedChangesTitle: "Unsaved changes",
    UnsavedChangesDescription:
      "There are unsaved changes on the configuration for this element",
    DiscardChangesLabel: "Discard",
    SaveChangesLabel: "Save",

    DeleteElementTitle: "Delete element",
    DeleteElementDescription:
      "Are you sure do you want to delete this element?",
    CancelLabel: "Cancel",
    DeleteLabel: "Delete",

    ImportPDFTitle: "Are you sure you want to import a new file?",
    ImportPDFDescription:
      "This will cause the current configuration to be deleted. Proceed only if you are sure.",

    ChangesApplied: "Changes applied",
  },
  ESP: {
    SearchLabel: "Buscar",

    ElementTypes: "Tipos de elementos",
    ElementTypesInfo:
      "Arrastra los botones y suéltalos en la hoja según tus necesidades",
    TextElementName: "Editor de textos",
    ImageElementName: "Imagen",
    SignElementName: "Firma",

    CreatedElements: "Elementos creados",
    CreatedElementsInfo:
      "Aquí podrás visualizar la lista de elementos que hayas creado. Para crear uno, solo arrastra y suéltalo en la hoja y configura",
    NoCreatedElements: "Sin elementos creados",
    noSearchResults: "Sin elementos encontrados",

    ConfigureElement: "Configurar elemento",
    TextEditorLabel: "Editor de textos",
    ImageEditorLabel: "Imagen",
    SignEditorLabel: "Firma",
    DeleteElement: "Eliminar elemento",
    Accept: "Aceptar",

    UnsavedChangesTitle: "Cambios sin guardar",
    UnsavedChangesDescription:
      "Hay cambios sin guardar en la configuración de este elemento. ¿Qué acción deseas realizar?",
    DiscardChangesLabel: "Descartar",
    SaveChangesLabel: "Guardar",

    DeleteElementTitle: "Eliminar elemento",
    DeleteElementDescription: "¿Seguro que deseas eliminar este elemento?",
    CancelLabel: "Cancelar",
    DeleteLabel: "Eliminar",

    ImportPDFTitle: "Importar",
    ImportPDFDescription:
      "¿Seguro que deseas importar un nuevo archivo? Esto hará que la configuración actual se elimine. Procede solo si estás seguro",

    ChangesApplied: "Cambios guardados",
  },
};
