import React from "react";
import { Row } from "antd";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
// Components
import Header from "../components/Header";
import Footer from "../components/LoginFooter/Footer";
// Views
import Privacy from "../views/LandingPage/Privacy";
import Terms from "../views/LandingPage/Terms";

const routes = [
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    layout: "/legal",
    hidden: true,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
    layout: "/legal",
    hidden: true,
  },
];

const Routes = routes.map((r, key) => (
  <Route
    path={r.layout + r.path}
    component={r.component}
    key={key}
    exact={r.exact}
  />
));

const LegalLayout = () => {
  const { path } = useRouteMatch();

  return (
    <div style={{ backgroundColor: "#EAEAEA", minHeight: "100%" }}>
      <div style={{ minHeight: "20%" }}>
        <Header />
      </div>
      <Row justify="center">
        <Row style={{ width: "1200px" }}>
          <Switch>
            {Routes}
            <Redirect from={path} to={`${path}/terms`} />
          </Switch>
        </Row>
      </Row>
      <div style={{ minHeight: "25%" }}>
        <Footer />
      </div>
    </div>
  );
};

export default LegalLayout;
