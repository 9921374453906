import React from "react";
import styled from "styled-components";
import { ReactComponent as DownloadIconFilled } from "../../resources/img/downloadIconFilled.svg";

const IconWrapper = styled.div<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  :hover {
    background: #d9d9d9;
  }
`;

interface DownloadIconProps {
  size?: 20 | 26;
  $width?: string;
  $height?: string;
  onClick?: () => void;
}

const DownloadIcon: React.FC<DownloadIconProps> = ({
  size = 20,
  $width = "14px",
  $height = "14px",
  onClick,
}) => {
  return (
    <IconWrapper size={size} onClick={onClick}>
      <DownloadIconFilled width={$width} height={$height} />
    </IconWrapper>
  );
};

export default DownloadIcon;
