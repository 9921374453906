import styled from "styled-components";

export const NodeBase = styled.div<{ dragged?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  backgorund-color: white;
  border-radius: 6px;
  border: 2px solid #edecec;
  padding: 10px 12px;
  color: #48505e;
  cursor: grab;

  transition: background-color 0.35s ease;
  :hover {
    background-color: #edecec;
  }

  p {
    margin: 0;
  }
`;

export const StringInput = styled.input`
  background-color: white;
  border-radius: 6px;
  border: 2px solid #edecec;
  color: #828d9e;

  width: 100%;
  min-height: 32px;
  height: 34px;
  resize: none;
  padding: 3px 10px;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;
