import React, { useState } from "react";
import { ErrorMessage, useField } from "formik";
import styled from "styled-components";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const ErrorMessageStyle = styled(ErrorMessage)`
  color: #db232c;
  text-align: left;
  font-family: "Gotham-book";
  font-size: 14px;
`;

const StyledInput = styled.input`
  background-color: white;
  border-radius: 6px;
  width: 100%;
  padding: 4px 8px;
  color: #828d9e;
  font-family: "Gotham-book";
  font-size: 14px;
  outline-width: 0;
  border: 2px solid ${({ $error }) => ($error ? "#db232c" : "#EDECEC")};
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
  :disabled {
    background-color: #edecec;
    border-color: #edecec;
  }

  /* Change the white to any color */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const IconHover = styled.div`
  border-radius: 2px;
  position: absolute;
  padding: 0 3.5px;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  height: 20px;
  width: 20px;
  :hover {
    background-color: #d9d9d9;
  }
`;

const Input = ({
  image,
  font,
  pad,
  controlledOnChange = (value) => value,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  const isPassword = props.type === "password";
  const Icon = showPassword ? AiOutlineEyeInvisible : AiOutlineEye;

  const getInputType = () => {
    if (isPassword) {
      if (showPassword) return "text";
      else return "password";
    }
    return props.type;
  };

  const handleOnChange = (e) =>
    field.onChange(props.name)(controlledOnChange(e.target.value));

  return (
    <div style={{ marginBottom: "8px" }}>
      <div style={{ position: "relative" }}>
        <StyledInput
          autoComplete="off"
          required
          {...field}
          onChange={handleOnChange}
          {...props}
          type={getInputType()}
          $error={meta.touched && meta.error}
        />
        {isPassword && (
          <IconHover onClick={() => setShowPassword(!showPassword)}>
            <Icon
              color="#48505E"
              style={{
                userSelect: "none",
              }}
              size={14}
            />
          </IconHover>
        )}
      </div>
      <ErrorMessageStyle name={props.name} component="div" />
    </div>
  );
};

export default Input;
