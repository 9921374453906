import React, { useState } from "react";
import { noop as NOOP } from "lodash";
import { useMutation } from "../../../hooks";
import { ChatsData } from "../Pendings.d";
import { ChatsModalProps } from "./ChatsModal.d";
import Modal from "../../../components/Modal";
import ChatsList from "./ChatsList";
import ChatsContent from "./ChatsContent";
import { ModalContainer, ChatHeader, ChatTitleOnModal } from "./Styles";
import { Row, Badge } from "antd";
import _ from "lodash";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { ViewMoreIcon } from "../../../components/hoverIcons";
import { useHistory } from "react-router-dom";
import Tooltip from "../../../components/Tooltip";

const ChatsModal: React.FC<ChatsModalProps> = ({
  chats,
  chatsDataFirst,
  idForChatList,
  onClose = NOOP,
}) => {
  const [chatsData, setChatsData] = useState<ChatsData | undefined>(
    chatsDataFirst
  );
  const history = useHistory();
  const [modalTitle, setModalTitle] = useState<string>(
    chatsDataFirst?.ProcessExecutionTitle || ""
  );

  const totalChatMessages = _.sum(chats.map((chat) => chat.MessageCount)) || 0;

  const [getChatChannel] = useMutation<ChatsData>({
    func: "Ver2-MyPendings-gcc",
    onSuccess: (response) => {
      setChatsData(response);
      setModalTitle(response.ProcessExecutionTitle);
    },
  });

  const handleChangeId = (id: number) => {
    getChatChannel({
      args: {
        IdExecutionChat: id,
      },
    });
  };

  return (
    <Modal
      visible
      onCancel={onClose}
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            width: "100%",
          }}
        >
          <ChatHeader
            align="middle"
            justify="space-between"
            style={{ color: "#48505e", fontSize: 16, width: "290px" }}
          >
            <Row align="middle">
              <p style={{ margin: 0, fontFamily: "Gotham-Bold" }}>Chats</p>
            </Row>
            <Badge count={totalChatMessages} offset={[0, 6]} size="small">
              <IoChatbubbleEllipsesOutline color="#48505e" size={20} />
            </Badge>
          </ChatHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <ChatTitleOnModal>{modalTitle}</ChatTitleOnModal>
            {chatsData?.CanViewProcessDetail && (
              <Tooltip title="Ver detalle de seguimiento">
                <ViewMoreIcon
                  colored
                  onClick={() =>
                    history.push({
                      pathname: `/home/reports/detail/${Number(
                        chatsData?.IdProcessExecution
                      )}`,
                    })
                  }
                />
              </Tooltip>
            )}
          </div>
        </div>
      }
      centered
      width="788px"
      bodyStyle={{
        display: "flex",
      }}
    >
      <ModalContainer>
        <div
          style={{
            width: "264px",
            maxHeight: "100%",
          }}
        >
          <ChatsList
            chats={chats}
            handleChangeId={handleChangeId}
            idForChatList={idForChatList}
          />
        </div>
        <div
          style={{
            width: "500px",
            height: "50%",
            overflowY: "auto",
          }}
        >
          <ChatsContent chatsData={chatsData} />
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default ChatsModal;
