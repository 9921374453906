import React from "react";
import { Row } from "antd";
import { Typography } from "../../../../GeestUI";
import { UserContainer, UserInitials, UserProfilePic } from "./Styles";
import Tooltip from "../../../../components/Tooltip";

const { P } = Typography;

interface UserType {
  IdUser: number;
  Email: string;
  Initials: string;
  FirstName: string;
  LastName: string;
  ProfilePicture?: string;
}

interface UserProps {
  user: UserType;
  size: string;
  picSize?: string;
  justify?:
    | "end"
    | "start"
    | "center"
    | "space-around"
    | "space-between"
    | undefined;
  placement?:
    | "topLeft"
    | "top"
    | "topRight"
    | "leftTop"
    | "left"
    | "leftBottom"
    | "rightTop"
    | "right"
    | "rightBottom"
    | "bottomLeft"
    | "bottom"
    | "bottomRight";
}

const User: React.FC<UserProps> = ({
  user,
  size,
  picSize = "35px",
  justify = "end",
  placement = "bottom",
}) => {
  if (user) {
    switch (size) {
      case "small":
        return (
          <UserContainer justify={justify}>
            <Tooltip
              title={`${user.FirstName} ${user.LastName} (${user.Email})`}
              placement={placement}
            >
              {!user.ProfilePicture ? (
                <UserInitials align="middle" justify="center" picSize={picSize}>
                  {user.Initials}
                </UserInitials>
              ) : (
                <UserProfilePic
                  image={user.ProfilePicture}
                  align="middle"
                  justify="center"
                  picSize={picSize}
                />
              )}
            </Tooltip>
          </UserContainer>
        );
      case "middle":
        return (
          <UserContainer justify={justify}>
            <Tooltip title={user.Email} placement={placement}>
              <Row justify={justify} align="middle">
                {!user.ProfilePicture ? (
                  <UserInitials
                    align="middle"
                    justify="center"
                    picSize={picSize}
                  >
                    {user.Initials}
                  </UserInitials>
                ) : (
                  <UserProfilePic
                    image={user.ProfilePicture}
                    align="middle"
                    justify="center"
                    picSize={picSize}
                  />
                )}
                <P
                  mb="0"
                  style={{ color: "#727e8b" }}
                >{`${user.FirstName} ${user.LastName}`}</P>
              </Row>
            </Tooltip>
          </UserContainer>
        );
      case "large":
      default:
        return (
          <UserContainer justify={justify} align="middle">
            {!user.ProfilePicture ? (
              <UserInitials align="middle" justify="center" picSize={picSize}>
                {user.Initials}
              </UserInitials>
            ) : (
              <UserProfilePic
                image={user.ProfilePicture}
                align="middle"
                justify="center"
                picSize={picSize}
              />
            )}
            <div>
              <P
                mb="0"
                style={{ color: "#727e8b" }}
              >{`${user.FirstName} ${user.LastName}`}</P>
              <P mb="0" style={{ color: "#727e8b", fontSize: "12px" }}>
                {user.Email}
              </P>
            </div>
          </UserContainer>
        );
    }
  }
  return <div />;
};

export default User;
