export const Languages = {
  ENG: {
    DescriptionLabel: "Description",
    AdvancedSettingsLabel: "Advanced settings",
    DBSettingsLabel: (Name) => `Settings DB ${Name}`,
    LinkingDBLabel: "Linking DB",
    LinkingColumnsLabel: "Linking columns",
    ProcessingInfoLabel: "Processing information",
    CreatingProcessLabel: "Creating process",
    ProcessCreatedLabel: "Process created",
    InstallProcessTitle: (Name) => `Install process: ${Name}`,
    BusinessToInstallLabel: "Business where the process will be installed",
    TemplateProcessesLabel:
      "This template has the following processes and data bases.\n",
    TemplatePackageLabel:
      "This template package has the following processes and data bases.\n",
    YouCanInstallLabel:
      "You can install it with everything it contains or you can select advanced settings",
    ProcessesLabel: "Processes",
    DataBasesLabel: "Data bases",
    InstallLabel: "Install",
    AdvancedSettingsDescriptionLabel:
      "This template has existing data bases you can reuse. Select for each of them if you want to create a new data base o replace it with one you already have",
    NextLabel: "Next",
    CreateNewDBLabel: "Create new data base",
    SelectColumnsLabel:
      "Select which column you want to link the existing columns with, or you can create it as a new column",
    ExistingColumnLabel: "Existing columns",
    PlaceInColumnLabel: "Place in column:",
    CreateNewColumnLabel: "Create new column",
    BusinessToInstallPackageLabel:
      "Business where the processes will be installed",
  },
  ESP: {
    DescriptionLabel: "Descripción",
    AdvancedSettingsLabel: "Configuración avanzada",
    DBSettingsLabel: (Name) => `Configuración BD ${Name}`,
    LinkingDBLabel: "Vinculando BD",
    LinkingColumnsLabel: "Vinculando columnas",
    ProcessingInfoLabel: "Procesando información",
    CreatingProcessLabel: "Creando proceso",
    ProcessCreatedLabel: "Proceso creado",
    InstallProcessTitle: (Name) => `Instalar proceso: ${Name}`,
    BusinessToInstallLabel: "Empresa donde se instalará el proceso",
    BusinessToInstallPackageLabel: "Empresa donde se instalarán los procesos",
    TemplateProcessesLabel:
      "Esta plantilla está conformada por los siguientes procesos y bases de datos.\n",
    TemplatePackageLabel:
      "Este paquete está conformado por los siguientes procesos y bases de datos.\n",
    YouCanInstallLabel:
      "Puedes instalarla con todo lo que contiene o puedes seleccionar la configuración avanzada",
    ProcessesLabel: "Procesos",
    DataBasesLabel: "Bases de datos",
    InstallLabel: "Instalar",
    ReuseDBLabel: "Reutilizar bases de datos",
    AdvancedSettingsDescriptionLabel:
      "Esta plantilla tiene bases de datos existentes que puedes reutilizar. Selecciona en cada una de ellas si deseas crear una nueva base de datos o reemplazarla por una que ya tengas",
    NextLabel: "Siguiente",
    CreateNewDBLabel: "Crear nueva base de datos",
    SelectColumnsLabel:
      "Selecciona a qué columna de tu base de datos quieres vincular las columnas existentes o bien, puedes crearla como nueva columna",
    ExistingColumnLabel: "Columnas existentes",
    PlaceInColumnLabel: "Colocar en la columna:",
    CreateNewColumnLabel: "Crear nueva columna",
  },
};
