import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { Popover } from "@material-ui/core";
import { StarOutlined, StarTwoTone } from "@ant-design/icons";
import { useFetch, useMutation } from "../../../hooks";
import ViewTitle from "../../../components/ViewTitle/ViewTitle";
import { Languages } from "../Dictionary";
import { Team, GetTeamsResponse, UpdateFavoriteResponse } from "../Teams.d";
import {
  AddTeamPopoverContent,
  AddTeamTitle,
  Container,
  Content,
  StarContainer,
  StyledInput,
  Table,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableRowItem,
} from "./Style";
import { SesionContext } from "../../../config/State";
import { MessagesContext } from "../../../components/AppMessages";
import { Button } from "../../../GeestUI";
import { AiOutlinePlus } from "react-icons/ai";
import { CloseIcon } from "../../../components/hoverIcons";
import { getUserFormats } from "../../../Helpers";

interface NewTeamModalProps {
  PopoverLabels: any;
  newTeamName: string;
  setNewTeamName: (newName: string) => void;
  searchForInputRef: boolean;
  newTeamModalVisible: boolean;
  setNewTeamModalVisible: (visible: boolean) => void;
  ButtonsLabels: any;
  isNewTeamNameEmpty: boolean;
  registeringTeam: boolean;
  handleCreateTeam: () => void;
}

const NewTeamModal: React.FC<NewTeamModalProps> = ({
  PopoverLabels,
  newTeamName,
  setNewTeamName,
  searchForInputRef,
  newTeamModalVisible,
  setNewTeamModalVisible,
  ButtonsLabels,
  isNewTeamNameEmpty,
  registeringTeam,
  handleCreateTeam,
}) => {
  const textInput = useRef(null);

  useEffect(() => {
    if (textInput.current) {
      // @ts-ignore
      textInput.current.focus();
    }
  }, [newTeamModalVisible, searchForInputRef]);

  return (
    <AddTeamPopoverContent>
      <AddTeamTitle>
        {PopoverLabels["CREATETEAM"]}
        <CloseIcon onClick={() => setNewTeamModalVisible(false)} />
      </AddTeamTitle>
      <StyledInput
        autoFocus
        ref={textInput}
        value={newTeamName}
        onChange={({ target: { value } }) => setNewTeamName(value)}
        placeholder={PopoverLabels["TEAMNAME"]}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Button
          type="secondary"
          onClick={() => {
            setNewTeamModalVisible(false);
            setNewTeamName("");
          }}
        >
          {ButtonsLabels["CANCEL"]}
        </Button>
        <Button
          type="primary"
          disabled={isNewTeamNameEmpty || registeringTeam}
          onClick={handleCreateTeam}
        >
          {ButtonsLabels["CREATE"]}
        </Button>
      </div>
    </AddTeamPopoverContent>
  );
};

const MyTeams: React.FC = () => {
  const [session, setSession] = useContext<any>(SesionContext);
  const { showMessage } = useContext(MessagesContext);
  const popoverRef = useRef<HTMLDivElement>(null);

  const [teams, setTeams] = useState<Team[]>([]);
  const [idFavoriteTeam, setIdFavoriteTeam] = useState<string>("");
  const [newTeamModalVisible, setNewTeamModalVisible] =
    useState<boolean>(false);
  const [newTeamName, setNewTeamName] = useState<string>("");
  const [searchForInputRef, setSearchForInputRef] = useState<boolean>(false);

  const history = useHistory();
  const isNewTeamNameEmpty = _.isEmpty(_.trim(newTeamName));

  const { TitleLabels, TableLabels, ButtonsLabels, PopoverLabels, Messages } =
    Languages["ESP"];

  const { reload } = useFetch<GetTeamsResponse>({
    func: "Ver2-Team-gut",
    onSuccess: ({ Teams, IdFavoriteTeam }) => {
      setTeams(Teams);
      setIdFavoriteTeam(IdFavoriteTeam);
    },
  });

  const [updateFavoriteTeam, updatingFavoriteTeam] =
    useMutation<UpdateFavoriteResponse>({
      func: "Ver2-Team-cft",
      onSuccess: (_res, { IdTeam }) => {
        const favoriteTeam = _.find(teams, { IdTeam: IdTeam });
        if (favoriteTeam) {
          setSession({
            ...session,
            FavoriteTeam: {
              IdFavoriteTeam: IdTeam,
              TeamLogoUrl: favoriteTeam.TeamLogo.SourceUrl,
            },
          });
        }
        reload();
      },
    });

  const [registerNewTeam, registeringTeam] = useMutation<number>({
    func: "Ver2-Team-rnt",
    onSuccess: () => {
      showMessage(Messages.SuccesfulCreation, "success");
      setNewTeamName("");
      reload();
    },
  });

  const handleOpenTeam = (e: React.MouseEvent<HTMLElement>, IdTeam: string) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/home/teams/${IdTeam}`);
  };
  const handleOpenTeamEnter = (IdTeam: string) => {
    history.push(`/home/teams/${IdTeam}`);
  };

  const handleUpdateFavorite = (
    e: React.MouseEvent<HTMLElement>,
    IdTeam: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (updatingFavoriteTeam || registeringTeam) return;
    updateFavoriteTeam({ args: { IdTeam }, shippedData: { IdTeam } });
  };
  const handleUpdateFavoriteEnter = (IdTeam: string) => {
    if (updatingFavoriteTeam || registeringTeam) return;
    updateFavoriteTeam({ args: { IdTeam }, shippedData: { IdTeam } });
  };

  const handleCreateTeam = async () => {
    if (isNewTeamNameEmpty) return;
    let CurrencyFormat = "MXN";
    let PhoneFormat = "MX";
    try {
      const formats = await getUserFormats();
      CurrencyFormat = formats.CurrencyFormat;
      PhoneFormat = formats.PhoneFormat;
    } catch (err) {
      console.log(err);
    }
    registerNewTeam({
      args: {
        TeamName: _.trim(newTeamName),
        CurrencyFormat,
        PhoneFormat,
      },
    });
  };

  return (
    <Container>
      <ViewTitle ShowTeamIcon>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {TitleLabels["TEAMS"]}
          <Popover
            open={newTeamModalVisible}
            anchorEl={popoverRef.current}
            onClose={() => setNewTeamModalVisible(false)}
            PaperProps={{
              style: {
                borderRadius: "6px",
                width: "432px",
                padding: "12px",
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <NewTeamModal
              PopoverLabels={PopoverLabels}
              newTeamName={newTeamName}
              setNewTeamName={setNewTeamName}
              searchForInputRef={searchForInputRef}
              newTeamModalVisible={newTeamModalVisible}
              setNewTeamModalVisible={setNewTeamModalVisible}
              ButtonsLabels={ButtonsLabels}
              isNewTeamNameEmpty={isNewTeamNameEmpty}
              registeringTeam={registeringTeam}
              handleCreateTeam={handleCreateTeam}
            />
          </Popover>
          <div ref={popoverRef}>
            <Button
              onClick={() => {
                setNewTeamModalVisible(true);
                setSearchForInputRef(!searchForInputRef);
              }}
              Icon={AiOutlinePlus}
              style={{ width: "190px" }}
              type="primary"
            >
              Agregar empresa
            </Button>
          </div>
        </div>
      </ViewTitle>
      <Content>
        <Table>
          <TableHeader>
            <div style={{ width: "40px" }} />
            <TableHeaderItem>{TableLabels["CODE"]}</TableHeaderItem>
            <TableHeaderItem>{TableLabels["NAME"]}</TableHeaderItem>
          </TableHeader>
          {teams.map((team, idx) => (
            <TableRow
              onClick={(e) => handleOpenTeam(e, team.IdTeam)}
              tabIndex={0}
              onKeyDown={({ key }) => {
                if (key === "Enter") handleOpenTeamEnter(team.IdTeam);
              }}
              key={idx}
            >
              {idFavoriteTeam === team.IdTeam ? (
                <StarContainer>
                  <StarTwoTone
                    twoToneColor="#ffd700"
                    onClick={(e) => handleUpdateFavorite(e, team.IdTeam)}
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (key === "Enter")
                        handleUpdateFavoriteEnter(team.IdTeam);
                    }}
                    style={{
                      cursor: "pointer",
                      fontSize: "30px",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </StarContainer>
              ) : (
                <StarContainer>
                  <StarOutlined
                    onClick={(e) => handleUpdateFavorite(e, team.IdTeam)}
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (key === "Enter")
                        handleUpdateFavoriteEnter(team.IdTeam);
                    }}
                    style={{
                      cursor: "pointer",
                      fontSize: "30px",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </StarContainer>
              )}
              <TableRowItem>{team.Code}</TableRowItem>
              <TableRowItem>{team.Name}</TableRowItem>
            </TableRow>
          ))}
        </Table>
      </Content>
    </Container>
  );
};

export default MyTeams;
