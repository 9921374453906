import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { noop as NOOP } from "lodash";
import styled from "styled-components";

const StyledCardElement = styled(CardElement)`
  flex: 1;
  padding: 4px 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
`;

const StripeInput = ({ onChange = NOOP, disabled = false }) => {
  return (
    <StyledCardElement
      options={{
        disabled,
        hidePostalCode: true,
        style: {
          base: {
            fontSize: "16px",
            color: "#424770",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#9e2146",
          },
        },
      }}
      id="stripeInput"
      onChange={onChange}
    />
  );
};

export default StripeInput;
