import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FilterOption, Option, Stage } from "../../../Dashboards.d";
import GeestSelect from "../../../../../GeestUI/GeestSelect/GeestSelect";
import { Tag } from "../../../../../GeestUI";

const InputWrapper = styled.div`
  display: flex;
`;

export const StageLabel = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
    color: #48505e;
  }

  .circle {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    background-color: ${({ color = "#ababab" }) => color};
  }
`;

interface FilterMultiselectProps {
  filterData: FilterOption | null;
  onChangeFilter: (filter: FilterOption) => void;
  resetValue?: boolean;
}

const FilterMultiselect: React.FC<FilterMultiselectProps> = ({
  filterData,
  onChangeFilter,
  resetValue,
}) => {
  const [selectValue, setSelectValue] = useState<string>(
    filterData?.Value ? filterData.Value : ""
  );

  useEffect(() => {
    if (resetValue) {
      setSelectValue("");
    }
  }, [resetValue]);

  const getStatusColor = (status: number) => {
    switch (status) {
      case 0: // nuevo
        return "yellow";
      case 1: // iniciado
        return "cyan";
      case 2: // terminado
        return "green";
      case 3: //cancelado
        return "default";
      case 4: //por iniciar
        return "orange";
      case 5: //vencido
        return "red";
      default:
        //desconocido
        return "default";
    }
  };

  const getOptions = () => {
    let options: Option[] = [];
    if (typeof filterData?.DataOrigin === "string") {
      options = filterData.DataOrigin.split(",").map((option) => {
        return {
          label: option,
          value: option,
        };
      });
    } else if (filterData?.Key === "Stage") {
      // Stage select
      const newOptions = (filterData?.DataOrigin as Stage[]).map((stage) => {
        return {
          color: stage.Color,
          value: `${stage.IdTemplate}`,
          searchableValues: `${stage.Label}`,
          label: (
            <StageLabel color={stage.Color}>
              <div className="circle" />
              <p>{stage.Label}</p>
            </StageLabel>
          ),
        };
      });
      options = newOptions as Option[];
    } else {
      // Status select
      const newOptions = (filterData?.DataOrigin as Option[]).map((filter) => {
        return {
          value: `${filter.value}`,
          searchableValues: `${filter.label}`,
          label: (
            <div>
              <Tag color={getStatusColor(+filter.value)}>{filter.label}</Tag>
            </div>
          ),
        };
      });
      options = newOptions as Option[];
    }
    return options;
  };

  const handleOnChangeSelect = (option: string) => {
    setSelectValue(option);

    let newFilter: FilterOption = {
      ...(filterData as FilterOption),
      Value: option,
    };
    onChangeFilter(newFilter);
  };

  return (
    <InputWrapper>
      <GeestSelect
        value={selectValue}
        options={getOptions()}
        onChange={(option) => handleOnChangeSelect(option)}
        $width="188px"
        $listWidth="188px"
        showTags={typeof filterData?.DataOrigin === "string"}
        multiselect
      />
    </InputWrapper>
  );
};

export default FilterMultiselect;
