import React, { useRef, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { Row } from "antd";
import { Typography } from "../../../GeestUI";
import TaskEmptyImage from "../../../resources/img/realajado.png";
import DraggableItem from "./DraggableItem";
import { ColumnType, Order, TableData } from "../DataBases.d";
import { Container, Table } from "../Styles";
import { HoverProvider } from "./HoverContext";

const { H2, P } = Typography;

const EmptyTableComponent: React.FC = () => (
  <Row justify="center" style={{ width: "100%" }}>
    <div style={{ textAlign: "center" }}>
      <img
        src={TaskEmptyImage}
        alt="empty columns"
        style={{ width: "15vw", height: "auto", marginBottom: "1rem" }}
      />
      <H2>Base de datos sin columnas</H2>
      <P mb="0">Para crear una columna y empezar a generar registros</P>
      <P>
        haz click en configurar y luego al botón de agregar columna a la derecha
      </P>
    </div>
  </Row>
);

interface DroppableContainerProps {
  columns?: TableData[];
  setSelectedColumn: (column: TableData | null) => void;
  selectedColumn: TableData | null;
  columnTypeArray: ColumnType[];
  canEditDatabases: boolean;
  order: Order;
  onOrder: (idColumn: number) => void;
  handleSetEditRow: (id: number) => void;
  reload: () => void;
  teamCurrency: string;
}

const DroppableContainer: React.FC<DroppableContainerProps> = ({
  children,
  columns,
  setSelectedColumn,
  selectedColumn,
  columnTypeArray,
  canEditDatabases,
  order,
  onOrder,
  handleSetEditRow,
  reload,
  teamCurrency,
}) => {
  const [editingColumnId, setEditingColumnId] = useState<number | null>(null);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const Scroller = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (Scroller.current) {
      const scrollLeft = Scroller.current.scrollLeft;
      setIsScrolled(scrollLeft > 0);
    }
  };

  return (
    <HoverProvider>
      <Container>
        {columns && columns.length > 1 ? (
          <Droppable droppableId="drag-drop-list" direction="horizontal">
            {(provided) => (
              <Table
                ref={provided.innerRef}
                {...provided.droppableProps}
                onScroll={handleScroll}
              >
                {(columns || []).map((column, idx) => (
                  <DraggableItem
                    key={`${column.Title}-${column.IdColumn}${idx}`}
                    column={column}
                    draggableId={`${column.IdColumn}`}
                    editingColumnId={editingColumnId}
                    setEditingColumnId={setEditingColumnId}
                    setSelectedColumn={setSelectedColumn}
                    selectedColumn={selectedColumn}
                    columnTypeArray={columnTypeArray}
                    order={order}
                    onOrder={onOrder}
                    canEditDatabases={canEditDatabases}
                    handleSetEditRow={handleSetEditRow}
                    index={idx}
                    reload={reload}
                    isScrolled={isScrolled}
                    teamCurrency={teamCurrency}
                    columns={columns}
                  />
                ))}
                {provided.placeholder}
              </Table>
            )}
          </Droppable>
        ) : (
          <EmptyTableComponent />
        )}
      </Container>
    </HoverProvider>
  );
};

export default DroppableContainer;
