import styled from "styled-components";
import { keyframes } from "styled-components";

export const SkeletonContainer = styled.div<{ isExecutionName?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ isExecutionName }) => (isExecutionName ? "8px" : "4px")};
  width: 100%;

  ${({ isExecutionName }) => (isExecutionName ? "padding: 0 7px;" : "")}
`;

const shine = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

// Componente base animado
const Animated = styled.div`
  cursor: progress;
  position: relative;
  overflow: hidden;
  background-color: hsl(0, 0%, 90%);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 10%,
      rgba(255, 255, 255, 0) 25%
    );
    background-size: 200% 100%;
    animation: ${shine} 2s infinite linear;
  }
`;

export const Rectangle = styled(Animated)`
  background-color: #edecec;
  border-radius: 3px;
  height: 18px;
  flex: 1;
`;

export const Square = styled(Animated)`
  background-color: #edecec;
  border-radius: 3px;
  min-height: 16px;
  height: 16px;
  min-width: 16px;
  width: 16px;

  &::after {
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 20%,
      rgba(255, 255, 255, 0) 50%
    );
  }
`;

export const Circle = styled(Animated)`
  background-color: #edecec;
  border-radius: 50%;
  min-height: 16px;
  height: 16px;
  min-width: 16px;
  width: 16px;

  &::after {
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 20%,
      rgba(255, 255, 255, 0) 50%
    );
  }
`;
