import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";
import {
  ButtonsRow,
  CanvasWrapper,
  ClearButton,
  Container,
  PlaceholderContainer,
  PrevSignatureWrapper,
} from "./styles";
import Modal from "../Modal";
import { Button } from "../../GeestUI";

interface SignGeneratorProps {
  signatureData: string;
  fieldName: string;
  handleOnSave: (canvasRef: React.RefObject<ReactSignatureCanvas>) => void;
  handleOnClear: (canvasRef: React.RefObject<ReactSignatureCanvas>) => void;
  handleOnCancel: () => void;
  disabled: boolean;
}

const SignGenerator: React.FC<SignGeneratorProps> = ({
  signatureData,
  fieldName,
  handleOnSave,
  handleOnClear,
  handleOnCancel,
  disabled,
}) => {
  const [canEdit, setCanEdit] = useState<boolean>(true);
  const [isEdited, setIsEdited] = useState<boolean>(signatureData !== "");
  const canvasRef = useRef<SignatureCanvas>(null);

  useEffect(() => {
    if (signatureData) {
      canvasRef.current?.fromDataURL(signatureData);
      setCanEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canEdit) {
      canvasRef.current?.on();
    } else {
      canvasRef.current?.off();
    }
  }, [canEdit]);

  return (
    <Modal
      visible
      title={"Firma: " + fieldName}
      onCancel={handleOnCancel}
      width="800px"
    >
      <Container>
        {!isEdited && (
          <PlaceholderContainer>
            <p>Firma aquí</p>
          </PlaceholderContainer>
        )}
        {disabled ? (
          <div
            style={{
              width: 738,
              height: 414,
              overflow: "visible",
            }}
            className="sigCanvas"
          >
            <img alt="sign" src={signatureData} />
          </div>
        ) : (
          <CanvasWrapper>
            {signatureData && !canEdit ? (
              <PrevSignatureWrapper>
                <img src={signatureData} alt="" />
              </PrevSignatureWrapper>
            ) : (
              <SignatureCanvas
                ref={canvasRef}
                canvasProps={{
                  width: 738,
                  height: 414,
                  className: "sigCanvas",
                }}
                onBegin={() => setIsEdited(true)}
              />
            )}
          </CanvasWrapper>
        )}

        {canEdit && (
          <ClearButton isEdited={isEdited}>
            <Button
              onClick={() => {
                handleOnClear(canvasRef);
                setIsEdited(false);
              }}
            >
              Borrar
            </Button>
          </ClearButton>
        )}

        {!disabled &&
          (signatureData && !canEdit ? (
            <ButtonsRow>
              <Button
                type="primary"
                onClick={() => {
                  setCanEdit(true);
                  setIsEdited(false);
                  handleOnClear(canvasRef);
                }}
              >
                Editar
              </Button>
            </ButtonsRow>
          ) : (
            <ButtonsRow>
              <Button type="secondary" onClick={handleOnCancel}>
                Cancelar
              </Button>
              <Button
                type="primary"
                onClick={() => handleOnSave(canvasRef)}
                disabled={!isEdited}
              >
                Guardar
              </Button>
            </ButtonsRow>
          ))}
      </Container>
    </Modal>
  );
};

export default SignGenerator;
