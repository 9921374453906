import React from "react";
import styled from "styled-components";
import { Space, Tag } from "antd";
import { StatusDataOriginType } from "../DynamicCell.d";

const Container = styled.div<{ $justify: "start" | "center" }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ $justify }) => $justify};
`;

const StyledTag = styled(Tag)<{ isSelected?: boolean }>`
  border-radius: 6px;
  border-width: 2px;
  margin-right: 0;

  width: ${({ isSelected }) => (isSelected ? "120px" : "auto")};
`;

const TagContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
    font-family: Gotham-Book;
  }
`;

interface StatusComponentProps {
  value: number | undefined | null;
  statusDataOrigin: StatusDataOriginType[];
  justify: "start" | "center";
  isSelected?: boolean;
}

const StatusComponent: React.FC<StatusComponentProps> = ({
  value,
  statusDataOrigin,
  justify,
  isSelected = false,
}) => {
  const getStatusInfo = (status: number | undefined | null) => {
    const statusInfo =
      status || status === 0
        ? statusDataOrigin.find((s) => s.Value === status)
        : null;
    if (!statusInfo) return { color: "default", label: status };

    switch (statusInfo.Label) {
      case "New":
        return { color: "yellow", label: "Nuevo" };
      case "OnTime":
        return { color: "cyan", label: "Iniciado" };
      case "Completed":
        return { color: "green", label: "Terminado" };
      case "Canceled":
        return { color: "default", label: "Cancelado" };
      case "WaitingToStart":
        return { color: "orange", label: "Por iniciar" };
      case "Stuck":
        return { color: "red", label: "Vencido" };
      default:
        return { color: "default", label: status };
    }
  };

  return (
    <Container $justify={justify}>
      <Space wrap={false}>
        <StyledTag color={getStatusInfo(value).color} isSelected={isSelected}>
          <TagContent>
            <span>{getStatusInfo(value).label}</span>
          </TagContent>
        </StyledTag>
      </Space>
    </Container>
  );
};

export default StatusComponent;
