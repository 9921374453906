import React from "react";
import Modal from "../../../../../components/Modal";
import DynamicTable from "../../../../../components/DynamicTable";
import styled from "styled-components";
import { Languages } from "../Dictionary";

const StyledDynamicTable = styled(DynamicTable)`
  & .ant-table-thead {
    font-size: 20px;
    background: linear-gradient(to right, white, #c0c0c0);
  }
  .ant-table-thead tr th {
    background: transparent;
    color: #696969;
  }
  & .ant-table-tbody {
    font-size: 16px;
  }
`;

const PaymentHistoryModal = ({ setVisible, data = [], loading }) => {
  const { PaymentHistoty } = Languages["ESP"];
  const columns = [
    { key: 1, title: "Concepto", dataIndex: "Description", width: "40%" },
    { key: 2, title: "Monto", dataIndex: "Amount", borderWidth: 1 },
    { key: 3, title: "Fecha y hora", dataIndex: "ApplicationTime" },
  ];

  return (
    <Modal visible onCancel={setVisible} title={PaymentHistoty} width="50%">
      <StyledDynamicTable
        columns={columns}
        data={data}
        bordered={false}
        loading={loading}
      />
    </Modal>
  );
};

export default PaymentHistoryModal;
