import React from "react";
import {
  CellComponentProps,
  CellTypes,
  DynamicCellProps,
} from "./DynamicCell.d";
import StringComponent from "./CellComponents/StringComponent";
import DateComponent from "./CellComponents/DateComponent";
import ExecutionIdComponent from "./CellComponents/ExecutionIdComponent";
import UserSelectComponent from "./CellComponents/UserSelectComponent";
import StatusComponent from "./CellComponents/StatusComponent";
import StageComponent from "./CellComponents/StageComponent";
import PriorityComponent from "./CellComponents/PriorityComponent";
import TriggerComponent from "./CellComponents/TriggerComponent";
import LastInteractionComponent from "./CellComponents/LastInteractionComponent";
import CurrentExecutorsComponent from "./CellComponents/CurrentExecutorsComponent";
import InfoFromComponent from "./CellComponents/InfoFromComponent";

const CellComponents: Record<CellTypes, React.FC<CellComponentProps>> = {
  [CellTypes.EXECUTION_NAME]: ({ stringValue = "" }) => (
    <StringComponent value={stringValue} />
  ),
  [CellTypes.DURATION]: ({ stringValue = "" }) => (
    <StringComponent value={stringValue} textCenter />
  ),
  [CellTypes.PROCESS_TEMPLATE_NAME]: ({ stringValue = "" }) => (
    <StringComponent value={stringValue} />
  ),
  [CellTypes.STUCK_TIME]: ({ stringValue = "" }) => (
    <StringComponent value={stringValue} textCenter />
  ),
  [CellTypes.TASK_DURATION]: ({ stringValue = "" }) => (
    <StringComponent value={stringValue} textCenter />
  ),

  [CellTypes.START_DATE]: ({ dateValue = "", dataType = "date" }) => (
    <DateComponent value={dateValue} dataType={dataType} />
  ),
  [CellTypes.END_DATE]: ({ dateValue = "", dataType = "date" }) => (
    <DateComponent value={dateValue} dataType={dataType} />
  ),

  [CellTypes.EXECUTION_ID]: ({ numValue }) => (
    <ExecutionIdComponent value={numValue} />
  ),

  [CellTypes.STARTED_USER]: ({ userValue, UserSelectDataOrigin }) => (
    <UserSelectComponent
      value={userValue ?? []}
      UserSelectDataOrigin={UserSelectDataOrigin}
    />
  ),
  [CellTypes.USERS_INVOLVED]: ({ userValue, UserSelectDataOrigin }) => (
    <UserSelectComponent
      value={userValue ?? []}
      UserSelectDataOrigin={UserSelectDataOrigin}
    />
  ),

  [CellTypes.STATUS]: ({ keyValue, StatusDataOrigin }) => (
    <StatusComponent
      value={keyValue}
      statusDataOrigin={StatusDataOrigin}
      justify="center"
      isSelected
    />
  ),

  [CellTypes.STAGE]: ({ keyValue, StageDataOrigin }) => (
    <StageComponent value={keyValue} stageDataOrigin={StageDataOrigin} />
  ),

  [CellTypes.PRIORITY]: ({ keyValue, PriorityDataOrigin }) => (
    <PriorityComponent
      value={keyValue}
      priorityDataOrigin={PriorityDataOrigin}
      justify="center"
      isSelected
    />
  ),

  [CellTypes.PROCESS_TRIGGER]: ({ triggerValue }) => (
    <TriggerComponent value={triggerValue} />
  ),

  [CellTypes.CURRENT_EXECUTORS]: ({
    UserSelectDataOrigin,
    StatusDataOrigin,
    executorsValue,
  }) => (
    <CurrentExecutorsComponent
      userSelectDataOrigin={UserSelectDataOrigin}
      statusDataOrigin={StatusDataOrigin}
      executorsValue={executorsValue ?? []}
    />
  ),

  [CellTypes.LAST_INTERACTION]: ({
    UserSelectDataOrigin,
    interactionValue,
  }) => (
    <LastInteractionComponent
      userSelectDataOrigin={UserSelectDataOrigin}
      interactionValue={
        interactionValue ?? { Message: "", Date: "", UsersInvolved: [] }
      }
    />
  ),

  [CellTypes.INFO_FROM_FIELD]: ({
    dataType,
    stringValue,
    selectValue,
    dateValue,
    numValue,
    userValue,
    formatValue,
    filesValue,

    UserSelectDataOrigin,
  }) => (
    <InfoFromComponent
      dataType={dataType}
      stringValue={stringValue}
      SelectValue={selectValue}
      dateValue={dateValue}
      numValue={numValue}
      userValue={userValue ?? []}
      formatValue={formatValue}
      filesValue={filesValue}
      userSelectDataOrigin={UserSelectDataOrigin}
    />
  ),
  [CellTypes.INFO_FROM_COLUMN]: ({
    dataType,
    stringValue,
    selectValue,
    dateValue,
    numValue,
    userValue,
    formatValue,
    filesValue,

    UserSelectDataOrigin,
  }) => (
    <InfoFromComponent
      dataType={dataType}
      stringValue={stringValue}
      SelectValue={selectValue}
      dateValue={dateValue}
      numValue={numValue}
      userValue={userValue ?? []}
      formatValue={formatValue}
      filesValue={filesValue}
      userSelectDataOrigin={UserSelectDataOrigin}
    />
  ),
};

const DynamicCell: React.FC<DynamicCellProps> = ({
  cellType,
  dataType,
  stringValue,
  dateValue,
  numValue,
  userValue,
  selectValue,
  triggerValue,
  keyValue,
  formatValue,
  filesValue,
  executorsValue,
  interactionValue,

  UserSelectDataOrigin,
  StatusDataOrigin,
  StageDataOrigin,
  PriorityDataOrigin,
}) => {
  const CellComponent = CellComponents[cellType];
  if (CellComponent) {
    return (
      <CellComponent
        dataType={dataType}
        stringValue={stringValue}
        dateValue={dateValue}
        numValue={numValue}
        userValue={userValue}
        selectValue={selectValue}
        triggerValue={triggerValue}
        keyValue={keyValue}
        formatValue={formatValue}
        filesValue={filesValue}
        executorsValue={executorsValue}
        interactionValue={interactionValue}
        // Data origins
        UserSelectDataOrigin={UserSelectDataOrigin}
        StatusDataOrigin={StatusDataOrigin}
        StageDataOrigin={StageDataOrigin}
        PriorityDataOrigin={PriorityDataOrigin}
      />
    );
  }
  return null;
};

export default DynamicCell;
