import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const StyledPaper = styled(Paper)`
  border-radius: 6px !important;
  width: 1200px;
  height: 569px;
  max-width: 90vw;
  display: flex;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 392px;
  color: #727e8b;
`;

export const LeftTitle = styled.div`
  font-size: 16px;
  color: rgba(72, 80, 94, 1);
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-right: 10px;
`;

export const StyledInput = styled.input`
  font-size: 16px;
  color: rgba(72, 80, 94, 1);
  font-weight: 700;
  margin-right: 10px;
  height: 36px;
  width: 227px;
  border-radius: 6px;
  border: 2px solid rgba(72, 80, 94, 1);
  :focus-visible {
    outline: none;
  }
`;

export const ActionsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 15px;
  padding-right: 15px;
  max-height: 477px;
  overflow: auto;
  border-right: 2px solid rgba(237, 236, 236, 1);
`;

export const ActionBox = styled.div<{ $isSelected: boolean }>`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 10px;
  :hover {
    background-color: rgba(237, 236, 236, 1);
  }
  background-color: ${({ $isSelected = false }) =>
    $isSelected ? "rgba(242, 248, 254, 1)" : "white"};
  transition: background-color 0.35s ease;
  border: 2px solid
    ${({ $isSelected = false }) =>
      $isSelected ? "rgba(242, 248, 254, 1)" : "rgba(237, 236, 236, 1)"};
  padding: 10px;
`;

export const StyledActionInput = styled.input`
  border: 2px solid rgba(72, 80, 94, 1);
  width: 230px;
  height: 28px;
  border-radius: 6px;
  color: rgba(72, 80, 94, 1);
  padding-left: 5px;
`;

export const ActionTitle = styled.div`
  flex: 1;
  padding: 10px;
  padding-left: 0;
  text-align: left;
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
`;

export const RightColumn = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding-right: 5px;
`;

export const TitleBar = styled.div`
  border-radius: 0 10px 0 0;
  padding: 5px 10px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const TabsContainer = styled.div`
  min-height: 22px;
  display: flex;
  gap: 5px;
`;

export const TabElement = styled.div`
  background-color: rgba(224, 240, 255, 1);
  border-radius: 6px;
  padding: 6px;
  display: flex;
  height: 34px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  * {
    text-align: center;
  }
`;

export const TabElementTitle = styled.div`
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  font-size: 14px;
  font-weight: 700;
`;

export const CenterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
