import React from "react";
import Modal from "../../../../../components/Modal";
import StripeInput from "../../../StripeInput";
import { Row, Space } from "antd";
import Button from "../../../../../components/Button";
import VISA from "../../../../../resources/img/visa.svg";
import MASTER_CARD from "../../../../../resources/img/mc.svg";
import AMERICAN_EXPRESS from "../../../../../resources/img/am.svg";
import { FiLock } from "react-icons/fi";

interface ModalProps {
  setVisible: () => void;
  onAddPaymentMethod: () => void;
  loading: boolean;
}

const AddPaymentMethodModal: React.FC<ModalProps> = ({
  setVisible,
  loading,
  onAddPaymentMethod,
}) => {
  return (
    <Modal
      title="Agregar método de pago"
      onCancel={setVisible}
      visible
      width="35%"
    >
      <Row
        justify="space-between"
        align="middle"
        style={{ display: "flex", padding: "2px" }}
      >
        <StripeInput />
        <Space size="middle" style={{ marginLeft: "1rem" }}>
          <Row align="middle">
            <img src={VISA} alt="visa" style={{ height: "17px" }} />
            <img
              src={MASTER_CARD}
              alt="masterCard"
              style={{ marginLeft: "0.5rem", height: "17px" }}
            />
            <img
              src={AMERICAN_EXPRESS}
              alt="americanExpress"
              style={{ marginLeft: "0.5rem", height: "17px" }}
            />
          </Row>
          <FiLock size={22} />
        </Space>
      </Row>
      <Row justify="end" style={{ marginTop: "2rem" }}>
        <Row justify="space-between" style={{ width: "50%" }}>
          <Button
            type="default"
            style={{ width: "48%" }}
            onClick={setVisible}
            loading={loading}
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            style={{ width: "48%" }}
            loading={loading}
            onClick={onAddPaymentMethod}
          >
            Guardar
          </Button>
        </Row>
      </Row>
    </Modal>
  );
};

export default AddPaymentMethodModal;
