import React from "react";
import styled from "styled-components";
import moment from "moment";

const DateTimeContent = styled.div`
  font-size: 12px;
  font-family: Gotham-Book;
  color: #48505e;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
  width: 100%;
  text-align: center;
`;

interface DateComponentProps {
  value: string;
  dataType: string;
}

const DateComponent: React.FC<DateComponentProps> = ({ value, dataType }) => {
  const dateFormat = (date: string, hideYear = false) => {
    if (moment(new Date(date)).isValid()) {
      if (hideYear) {
        return moment(date).format("DD/MMM");
      }
      return moment(date).format("DD/MMM/YY");
    } else {
      return "Invalid format";
    }
  };

  const dateHourFormat = (date: string, hideYear = false) => {
    if (moment(new Date(date)).isValid()) {
      if (hideYear) {
        return moment(date).format("DD/MMM HH:mm");
      }
      return moment(date).format("DD/MMM/YY HH:mm");
    } else {
      return "Invalid format";
    }
  };

  return (
    <DateTimeContent>
      {value
        ? dataType === "date"
          ? dateFormat(value)
          : dateHourFormat(value)
        : ""}
    </DateTimeContent>
  );
};

export default DateComponent;
