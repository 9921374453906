import styled from "styled-components";

export const TextConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ConfigRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 34px;
`;

export const ConfigRowName = styled.p`
  margin: 0;
  color: #48505e;
  font-family: Gotham-Bold;
`;
