import React from "react";
import { useFetch } from "../../../../hooks";
import { ChatsData } from "../ProcessDetail.d";
import ChatsContent from "../../../Pendings/ChatsModal/ChatsContent";
import { useParams } from "react-router-dom";

const Chat: React.FC = () => {
  const { idProcess } = useParams<{ idProcess: string }>();
  const { data: chatsData } = useFetch<ChatsData>({
    func: "Ver2-Reports-gcc",
    args: { IdProcessExecution: idProcess },
  });

  return (
    <ChatsContent
      chatsData={chatsData}
      containerExtraStyles={{ flex: 1 }}
      chatExtraStyles={{ flex: 1 }}
    />
  );
};

export default Chat;
