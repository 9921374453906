import { Edge, Node, OnEdgesChange, OnNodesChange } from "reactflow";
import { ToolboxActions } from "./CustomNodes/Toolbox/Toolbox.d";
import { HandlerId } from "./CustomNodes/shared/shared.d";
import { CustomNodeTypes } from "./CustomNodes";

export interface FlowProps {
  nodes: Node<NodeData>[];
  setNodes: React.Dispatch<React.SetStateAction<Node<NodeData>[]>>;
  onNodesChange: OnNodesChange;
  edges: Edge[];
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
  onEdgesChange: OnEdgesChange;
  reloadFlow: () => void;
  setSavingDataStatus: (status: string) => void;
}

export interface SelectedStage {
  stageId: number;
  stageColor: string;
}

export interface NodeData {
  label: string;
  stageId: number;
  stageColor: string;
  deleting: boolean;
  conditionPorts?: ConditionPort[];
  onAction: (action: ToolboxActions, nodeId: string) => void;
  onDeleteAttempt: () => void;
  onAbortDelete: () => void;
  onChangeNodeStage: (nodeId: string, stage: SelectedStage) => void;
  IsFieldMandatory?: boolean;
}

export enum NodeRequest {
  NODE_NAME = "NODE_NAME",
  SUBFLUX_CONFIG = "SUBFLUX_CONFIG",
}

export interface CreateNodeBuffer {
  node: Node<NodeData>;
  type: NodeRequest;
}

export type ApiNodeTypes =
  | "Task"
  | "Condition"
  | "Automation"
  | "Subflux"
  | "EndFlux";

export interface ConditionPort {
  Port: string;
  PortLabel: string;
}

export interface ApiNode {
  ConditionPorts?: ConditionPort[];
  CoordinatesCsv: string;
  IdNode: number;
  IdStage: number;
  StageColor: string;
  NodeTitle: string;
  NodeType: ApiNodeTypes;
  IsFieldMandatory?: boolean;
}

export type IPortNodeDestiny =
  | "BidirectionalLeft"
  | "BidirectionalRight"
  | "BidirectionalTop"
  | "BidirectionalBottom"
  | "InLeft"
  | "InTop"
  | "InBottom"
  | HandlerId;

export type IPortNodeOrigin =
  | "BidirectionalLeft"
  | "BidirectionalRight"
  | "BidirectionalTop"
  | "BidirectionalBottom"
  | "port1"
  | HandlerId
  | string;

export interface ApiLink {
  IdLink: number;
  IdNodeDestiny: number;
  IdNodeOrigin: number;
  PortNodeDestiny: IPortNodeDestiny;
  PortNodeOrigin: IPortNodeOrigin;
}

export interface GetNodesFlux {
  Nodes: ApiNode[];
  Links: ApiLink[];
}

export interface InsertNode {
  IdNode: number;
  NodeTitle: string;
  NodeType: CustomNodeTypes;
  CoordinatesCsv: string;
}

export type CopyNode = ApiNode;
export interface DeleteNode {
  DeletedLinksIds: number[];
}

export interface MoveNode {
  IdNode: string;
  CoordinatesCsv: string;
}

export interface LinkNode {
  IdLink: number;
}

export type InitialNodePosition = {
  nodeId: string;
  x: number;
  y: number;
} | null;
