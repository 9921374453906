import styled from "styled-components";

export const FieldBlockWrapper = styled.span``;

export const FieldContainer = styled.span<{ $hasField: boolean }>`
  border-radius: 6px;
  cursor: pointer;

  border: ${({ $hasField = false }) =>
    $hasField ? "none" : "2px solid #edecec"};
  padding: ${({ $hasField = false }) => ($hasField ? "4px 6px" : "2px 10px")};
  color: ${({ $hasField = false }) => ($hasField ? "#48505e" : "#828d9e")};
  background-color: ${({ $hasField = false }) =>
    $hasField ? "#edecec" : "white"};

  transition: background-color 0.35s ease, color 0.35s ease;
`;

export const IconContainer = styled.span`
  margin-right: 4px;
`;

export const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #48505e;
  padding: 10px 0;
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #828d9e;
`;

export const PopoverTitle = styled.div`
  padding: 12px;

  p {
    margin: 0;
  }
  .field-title {
    color: #48505e;
    font-size: 16px;
    font-family: Gotham-Bold;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  border-radius: 6px;
  border: 2px solid;
  border-color: #edecec;

  padding: 0 10px;
  margin: 0 10px;
`;

export const StyledInput = styled.input`
  border: none;
  background-color: white;
  color: #828d9e;

  width: 100%;
  min-height: 30px;
  resize: none;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const FieldList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 180px;
  overflow-y: auto;

  margin: 0 24px 12px;
`;

export const FieldElement = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 6px 10px;
  background-color: ${({ $selected = false }) =>
    $selected ? "#edecec" : "white"};

  transition: background-color 0.35s ease;

  :hover {
    background-color: #f2f8fe;
  }

  p.label {
    margin: 0;
  }
`;
