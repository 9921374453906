import React from "react";
import { Card, Space } from "antd";
import { Tag, Typography } from "../../GeestUI";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { H1, B } = Typography;

const Tags: React.FC = () => {
  return (
    <>
      <H1>
        <B>Tags</B>
      </H1>
      <Card size="small" title="Tags">
        <Space>
          <Tag color="default">Default</Tag>
          <Tag closable>Closable</Tag>
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            Icon
          </Tag>
          <Tag color="red">Red</Tag>
          <Tag color="yellow">Yellow</Tag>
          <Tag color="var(--grass-green)">var(--grass-green)</Tag>
          <Tag color="var(--navy-blue)">var(--navy-blue)</Tag>
        </Space>
      </Card>
    </>
  );
};

export default Tags;
