import styled from "styled-components";
import { Modal } from "antd";

export const StyledModal = styled(Modal)`
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  flex: 1;
  overflow: hidden;
  font-size: 16px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`;

export const InputContainer = styled.div`
  display: flex;
  align-itms: center;
  justify-content: center;
  padding: 12px;
`;

export const StyledInput = styled.input`
  border: 2px solid #edecec;
  color: #828d9e;
  border-radius: 6px;
  padding-left: 6px;
  min-width: 29px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  font-weight: 300;
  font-family: "Gotham-Book";
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
