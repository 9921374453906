import React, { useRef, useState } from "react";
import { Handle, NodeProps } from "reactflow";
import styled from "styled-components";
import { Tooltip } from "antd";
import {
  defaultHandlers,
  filterTargetHandlers,
  useIsConnecting,
} from "./shared/shared";
import { border, handlerStyles, Rectangle } from "./shared/styles";
import { NodeData } from "../Flow.d";
import Toolbox from "./Toolbox/Toolbox";
import { ToolboxActions } from "./Toolbox/Toolbox.d";
import ConfirmDeletion from "./shared/ConfirmDeletion";
import { useElementOverflow } from "../../../../hooks";

const SubfluxRectangle = styled(Rectangle)`
  padding: 2px;
`;

const Content = styled.div`
  border: ${border};
  border-radius: 7px;
  flex: 1;
  text-align: center;
  display: flex;
  overflow: hidden;
  padding: 12px;
`;
const Text = styled.span`
  margin: auto;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const SubfluxNode: React.FC<NodeProps<NodeData>> = ({ data, id, selected }) => {
  const isConnecting = useIsConnecting();
  const { label, deleting, onAction, onDeleteAttempt, onAbortDelete } = data;
  const showConfirmDeletion = deleting && selected;

  const textRef = useRef<HTMLSpanElement>(null);
  const isOverflow = useElementOverflow(textRef, "height");
  const [openTooltip, setOpenTooltip] = useState(false);
  return (
    <ConfirmDeletion
      visible={showConfirmDeletion}
      onConfirm={() => onAction(ToolboxActions.Delete, id)}
      onCancel={onAbortDelete}
      toolboxOffset
    >
      <SubfluxRectangle $isSelected={selected}>
        <Content>
          <Tooltip
            title={label}
            open={openTooltip && isOverflow}
            onOpenChange={setOpenTooltip}
            overlayInnerStyle={{
              borderRadius: "2px",
              padding: "6px 8px",
            }}
          >
            <Text ref={textRef}>{label}</Text>
          </Tooltip>
        </Content>
        {filterTargetHandlers(defaultHandlers, isConnecting).map((handler) => (
          <Handle style={handlerStyles} key={handler.id} {...handler} />
        ))}
        <Toolbox
          nodeId={id}
          onAction={onAction}
          onDeleteAttempt={onDeleteAttempt}
        />
      </SubfluxRectangle>
    </ConfirmDeletion>
  );
};

export default SubfluxNode;
