import React from "react";
import { Row, Checkbox } from "antd";
import { SelectTemplateProps } from "./CreateProcess.d";
import {
  ProcessTypesContainer,
  BoldLabel,
  TypesContainer,
  TypeLabel,
  BlueLabel,
  TemplateBankContainer,
  SearchBar,
  TemplateListContainer,
  TemplateContainer,
  BlueDiv,
  TemplateInfoContainer,
  TemplateDescription,
} from "./styles";
import imgPlaceHolder from "../../../resources/img/Image_Placeholder.svg";
import { Languages } from "../Dictionary";
import baseURL from "../../../api/baseURL";

const SelectTemplate: React.FC<SelectTemplateProps> = ({
  ProcessTypes,
  TemplateBank,
  setTypeFilter,
  typeFilter,
  filteredTemplateBank,
  nameFilter,
  setNameFilter,
  onCreateBlankProcess,
  onSelectTemplate,
}) => {
  const {
    CategoriesLabel,
    AllCheck,
    SearchLabel,
    BlankProcessLabel,
    BlankProcessDescription,
    KnowMoreButtonLabel,
  } = Languages["ESP"];

  const onCheckAll = (checked: boolean) => {
    if (checked) {
      setTypeFilter(ProcessTypes?.map(({ Id }) => Id) || []);
    } else {
      setTypeFilter([]);
    }
  };

  const onCheckType = (checked: boolean, Id: number) => {
    let typeFilterAux = [...typeFilter];
    if (checked) {
      typeFilterAux.push(Id);
    } else {
      let index = typeFilterAux.findIndex((id) => id === Id);
      typeFilterAux.splice(index, 1);
    }
    setTypeFilter(typeFilterAux);
  };

  return (
    <Row
      justify="space-between"
      style={{ marginTop: "2rem", marginLeft: "2.5rem" }}
    >
      <ProcessTypesContainer>
        <BoldLabel>{CategoriesLabel}</BoldLabel>
        <TypesContainer>
          <Row justify="space-between" style={{ marginTop: "1rem" }}>
            <TypeLabel>
              {AllCheck}
              <BlueLabel>{TemplateBank?.length}</BlueLabel>
            </TypeLabel>
            <Checkbox
              checked={typeFilter.length === ProcessTypes?.length}
              onChange={(e) => onCheckAll(e.target.checked)}
            />
          </Row>
          {ProcessTypes?.map(
            (Type) =>
              Type.Name !== "Blank_Process" && (
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginTop: "5px" }}
                  key={Type.Id}
                >
                  <TypeLabel>
                    {Type.Label}
                    <BlueLabel>{Type.Count}</BlueLabel>
                  </TypeLabel>
                  <Checkbox
                    checked={typeFilter.includes(Type.Id)}
                    onChange={(e) => onCheckType(e.target.checked, Type.Id)}
                  />
                </Row>
              )
          )}
        </TypesContainer>
      </ProcessTypesContainer>
      <TemplateBankContainer>
        <Row justify="end">
          <SearchBar
            type="text"
            placeholder={SearchLabel}
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </Row>
        <TemplateListContainer>
          <TemplateContainer onClick={onCreateBlankProcess}>
            <BlueDiv />
            <TemplateInfoContainer>
              <Row
                align="top"
                justify="space-between"
                style={{ width: "100%" }}
              >
                <Row justify="center" style={{ width: "92px" }}>
                  <img
                    src={`${baseURL}media/ProcessBank-Blank.svg`}
                    alt="img"
                    style={{
                      width: "61px",
                      height: "50px",
                    }}
                  />
                  <BoldLabel style={{ fontSize: "12px" }}>
                    {ProcessTypes && ProcessTypes[0]?.Label}
                  </BoldLabel>
                </Row>
                <div style={{ width: "297px" }}>
                  <BoldLabel>{BlankProcessLabel}</BoldLabel>
                  <TemplateDescription>
                    {BlankProcessDescription}
                  </TemplateDescription>
                </div>
              </Row>
            </TemplateInfoContainer>
          </TemplateContainer>
          {filteredTemplateBank?.map((Template) => (
            <TemplateContainer
              onClick={() => onSelectTemplate(Template)}
              key={Template.Id}
            >
              <BlueDiv />
              <TemplateInfoContainer>
                <Row
                  align="top"
                  justify="space-between"
                  style={{ width: "100%" }}
                >
                  <Row justify="center" style={{ width: "92px" }}>
                    <img
                      src={Template.Image ? Template.Image : imgPlaceHolder}
                      alt="img"
                      style={{
                        width: "61px",
                        height: "50px",
                      }}
                    />
                    <BoldLabel
                      style={{
                        fontSize: "12px",
                        width: "92px",
                        textAlign: "center",
                      }}
                    >
                      {
                        ProcessTypes?.find(
                          (Type) => Type.Id === Template.IdProcessType
                        )?.Label
                      }
                    </BoldLabel>
                  </Row>
                  <div style={{ width: "297px" }}>
                    <div style={{ height: "50px", overflow: "auto" }}>
                      <BoldLabel>{Template.Name}</BoldLabel>
                      <TemplateDescription>
                        {Template.Description}
                      </TemplateDescription>
                    </div>
                    <Row align="bottom" justify="end">
                      <div className="blue">{KnowMoreButtonLabel}</div>
                    </Row>
                  </div>
                </Row>
              </TemplateInfoContainer>
            </TemplateContainer>
          ))}
        </TemplateListContainer>
      </TemplateBankContainer>
    </Row>
  );
};

export default SelectTemplate;
