import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { KeyMaps } from "../../../../constants/PlatformDetails";
import { noop as NOOP } from "lodash";
import { Languages } from "../Dictionary";
import styled from "styled-components";

const { LoadingLabel, NoResultsLabel, LocPlaceholder } = Languages["ESP"];

interface GooglePlacesInputProps {
  onChange: ({
    label,
    lat,
    lng,
  }: {
    label?: string;
    lat?: string;
    lng?: string;
  }) => void;
  setGoogleInputValue: (inputValue: string) => void;
  defaultValue: string;
  focusedInput: boolean;
  setFocusedInput: (val: boolean) => void;
  disabled: boolean;
  borderColor: string;
  editing: boolean;
  setEditing: (arg: boolean) => void;
}

const Container = styled.div<{ $borderColor: string }>`
  div[class*="container"] {
    border-color: none;
    box-shadow: none;
  }
  div[class*="container"]:hover {
    border-color: none;
    box-shadow: none;
  }
  div[class*="container"]:focus {
    border-color: none !important;
    box-shadow: none;
  }
  div[class*="container"]:focus-visible {
    outline: none;
    box-shadow: none;
  }

  div[class*="control"] {
    border-color: ${({ $borderColor }) => $borderColor};
    padding: 0 5px;
    box-shadow: none;
    transition: border-color 0.35s ease;

    div {
      padding: 0;
    }
  }
  div[class*="control"]:hover {
    border-color: #0273e9 !important;
    color: #828d9e !important;
    box-shadow: none;
  }
  div[class*="control"]:focus {
    border-color: #48505e !important;
    box-shadow: none;
  }
  div[class*="control"]:focus-visible {
    outline: none;
  }

  div[class*="placeholder"] {
    color: #828d9e !important;
    font-size: 14px;
  }
`;

const GooglePlacesInput: React.FC<GooglePlacesInputProps> = ({
  onChange = NOOP,
  setGoogleInputValue,
  defaultValue = "",
  focusedInput,
  setFocusedInput,
  disabled,
  borderColor = "",
  setEditing,
  editing,
  ...props
}) => {
  const [input, setInput] = useState<string>(defaultValue);

  useEffect(() => {
    setInput(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleOnChange = ({ label }: any) => {
    geocodeByAddress(label)
      .then((results) => {
        if (results.length) {
          const [result] = results;
          onChange({
            label,
            ...result.geometry.location.toJSON(),
          });
        }
      })
      .catch(console.log);
  };

  return (
    <Container $borderColor={focusedInput ? "#48505e" : borderColor}>
      <GooglePlacesAutocomplete
        apiKey={KeyMaps}
        selectProps={{
          onChange: handleOnChange,
          loadingMessage: () => LoadingLabel,
          noOptionsMessage: () => NoResultsLabel,
          placeholder: LocPlaceholder,
          minMenuHeight: 10,
          maxMenuHeight: 80,
          styles: {
            container: (provided: any) => ({
              ...provided,
              fontFamily: "Gotham-book",
              fontSize: 14,
              borderColor: "none",
            }),
            control: (provided: any) => ({
              ...provided,
              border: "2px solid",
              borderRadius: "6px",
              minHeight: 20,
              height: 32,
              cursor: disabled ? "auto" : "text",
            }),
          },
          components: {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          },
          onInputChange: setInput,
          inputValue: input,
          onFocus: (e: any) => {
            setFocusedInput(true);
            e?.target?.select();
          },
          onBlur: () => {
            setEditing(false);
            setFocusedInput(false);
            if (defaultValue !== input && input) {
              onChange({ label: input });
            }
          },
          onKeyDown: (e: any) => {
            if (e.key === "Enter") {
              setEditing(false);
              setFocusedInput(false);
              if (defaultValue !== input && input) {
                onChange({ label: input });
              }
            }
          },
          onMenuClose: () => {
            setGoogleInputValue(input);
            setFocusedInput(false);
          },
          autoFocus: editing,
        }}
        {...props}
      />
    </Container>
  );
};

export default GooglePlacesInput;
