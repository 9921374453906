import { Position } from "reactflow";

export enum HandlerId {
  IN_TOP = "InTop",
  IN_RIGHT = "InRight",
  IN_BOTTOM = "InBottom",
  IN_LEFT = "InLeft",
  OUT_TOP = "OutTop",
  OUT_RIGHT = "OutRight",
  OUT_BOTTOM = "OutBottom",
  OUT_LEFT = "OutLeft",
}

export interface Handler {
  id: string;
  type: "target" | "source";
  position: Position;
  isConnectableStart?: boolean;
}
