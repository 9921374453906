import React from "react";
import { PermissionPopoverProps } from "../../Intranet.d";
import {
  PermissionsTitle,
  Permission,
  PermissionsConainer,
  PermissionGroup,
  PermissionGroupTitle,
  PermissionsInGroup,
} from "../../styles";
import { CloseIcon } from "../../../../components/hoverIcons";
import { Checkbox } from "antd";
import { useMutation } from "../../../../hooks";

const PermissionsPopover: React.FC<PermissionPopoverProps> = ({
  firstName,
  idUser,
  permissions,
  onClose,
  reload,
}) => {
  const [updateUserPermissions] = useMutation({
    func: "Ver2-Intranet-Perms-uup",
    onSuccess: reload,
  });

  const onUpdatePermission = (value: boolean, key: string) => {
    updateUserPermissions({
      args: {
        IdUser: idUser,
        Permissions: {
          ...permissions,
          [key]: value,
        },
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "19px",
        width: "355px",
      }}
    >
      <PermissionsTitle>
        {`Permisos de ${firstName}`}
        <CloseIcon onClick={onClose} />
      </PermissionsTitle>
      <PermissionsConainer>
        <PermissionGroup>
          <PermissionGroupTitle>Soporte</PermissionGroupTitle>
          <PermissionsInGroup>
            <Permission>
              Pruebas gratis
              <Checkbox
                style={{
                  color: "#727e8b",
                  margin: 0,
                }}
                checked={permissions.Permission_FreeTrials}
                onChange={(e) => {
                  onUpdatePermission(e.target.checked, "Permission_FreeTrials");
                }}
              />
            </Permission>
            <Permission>
              Usuarios
              <Checkbox
                style={{
                  color: "#727e8b",
                  margin: 0,
                }}
                checked={permissions.Permission_Pwd}
                onChange={(e) => {
                  onUpdatePermission(e.target.checked, "Permission_Pwd");
                }}
              />
            </Permission>
          </PermissionsInGroup>
        </PermissionGroup>
      </PermissionsConainer>
    </div>
  );
};

export default PermissionsPopover;
