import React, { useEffect, useRef, useState } from "react";
import _, { noop as NOOP } from "lodash";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
//@ts-ignore
import emoji from "emoji-dictionary";
import styled from "styled-components";
import { Row, Checkbox, Input } from "antd";
import { Typography, Button } from "../../../GeestUI";
import { TooltipPlacement } from "antd/lib/tooltip";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CustomLink, CustomList } from "../../../components/MarkdownHelpers";
import { Languages } from "./Dictionary";
import { ElementType } from "../../../views/Templates/StartTemplate/StartTemplate.d";
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai";
import IsRequired from "./helpers/IsRequired";
import { DeleteIcon } from "../../hoverIcons";

const { P } = Typography;

const { NewElementLabel, NewElementButtonLabel } = Languages["ESP"];

interface CheckListInputProps {
  value: string;
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  configuration: string;
  onChange: (value: number) => void;
  checklistParams?: {
    elements: ElementType[];
    onChangeChecklist: (args: ElementType[]) => void;
  };
  extraParams?: {
    center?: boolean;
    oneLineString?: boolean;
    tooltipOffset?: number;
    tooltipPlacement?: TooltipPlacement;
    mb?: string;
  };
}

const InputContainer = styled.div<{ isConsult: boolean; mb?: string }>`
  ${({ isConsult, mb = "10px" }) => (isConsult ? "" : `margin-bottom: ${mb};`)}
  width: 100%;
  * {
    margin: 0;
    padding: 0;
  }
`;

const ElementContainer = styled.div<{ disabled: boolean; bgColor: string }>`
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  padding: 0 5px;
  margin-bottom: 5px;
  background: ${({ disabled, bgColor }) => (disabled ? "#edecec" : bgColor)};
  transition: background 0.35s ease;
`;

const CheckboxContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
`;

const DisabledChecked = styled.div`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledCheckbox = styled(Checkbox)<{
  $cursor: string;
  required?: boolean;
}>`
  .ant-checkbox-inner {
    background-color: ${({ disabled, checked }) =>
      disabled ? "#e8ecf1" : checked ? "default" : ""};
    border: 1px solid
      ${({ required, checked }) =>
        required
          ? checked
            ? "#1890ff"
            : "#db232c"
          : checked
          ? "#1890ff"
          : "#edecec"};
  }
`;

const TextAreaStyled = styled(Input.TextArea)<{
  $borderColor: string;
  $background: string;
}>`
  border-radius: 6px;
  border: 2px solid;
  border-color: ${({ $borderColor }) => $borderColor};
  background-color: ${({ $background }) => $background};
  color: #828d9e;

  width: calc(100% - 40px);
  resize: none;
  padding: 3px 10px;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const ElementContent = styled.div<{ disabled: boolean; $cursor: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 30px;
  padding: 3px 5px;
  cursor: ${({ $cursor }) => $cursor};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ReactMarkdownStyled = styled(ReactMarkdown)<{ $oneLine: boolean }>`
  width: calc(100% - 35px);
  * {
    ${({ $oneLine }) =>
      $oneLine
        ? `
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `
        : `
          overflow-wrap: break-word;
        `}
  }
`;

const CheckListInput: React.FC<CheckListInputProps> = ({
  value,
  isConsult = false,
  disabled,
  required,
  configuration,
  onChange = NOOP,
  checklistParams = {
    elements: [],
    onChangeChecklist: (args) => {},
  },
  extraParams = { center: false, mb: "10px" },
}) => {
  const { elements = [], onChangeChecklist } = checklistParams;

  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editText, setEditText] = useState<string>("");
  const [hoveringIndex, setHoveringIndex] = useState<number | null>(null);
  const editingElement = editingIndex !== null ? elements[editingIndex] : null;
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const tooltipPlacement = extraParams.tooltipPlacement ?? "bottom";

  const cannotAddOrEditElements = configuration === "CannotAddOrEditElements";

  const nCheckedItems = _.reduce(
    elements,
    (sum, { Checked }) => sum + Number(Checked || 0),
    0
  );
  const nElements = elements.length;

  const defaultElement = {
    IdElement: "0",
    Element: NewElementLabel,
    Checked: false,
    Position: elements.length + 1,
  };

  useEffect(() => {
    if (value === "") {
      onChange(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (editingElement) {
      setEditText(editingElement.Element);
      setImmediate(() => {
        if (inputRef.current && editingElement.Element === NewElementLabel)
          // @ts-ignore
          inputRef.current.focus({ cursor: "all" });
      });
    } else {
      setEditText("");
    }
  }, [editingElement]);

  const updateCheckList = (newElements: ElementType[]) => {
    onChangeChecklist(newElements);
  };

  const addElement = () => {
    updateCheckList([...elements, defaultElement]);
  };

  const onCheck = (e: CheckboxChangeEvent, index: number) => {
    let newElements = _.cloneDeep(elements);
    newElements[index].Checked = e.target.checked;
    updateCheckList(newElements);
  };

  const onDelete = (index: number) => {
    let newElements = _.cloneDeep(elements);
    _.pullAt(newElements, index);
    updateCheckList(newElements);
  };

  const onChangeTitle = (index: number, enter = false) => {
    let newElements = _.cloneDeep(elements);
    const newText = editText.split("\n");
    if (!!elements[index].IdElement && editText.replace("\n", " ") === "") {
      onDelete(index);
      return;
    }
    newText.forEach((text, index) => {
      if (index === 0 && editingIndex !== null) {
        newElements[editingIndex].Element = text.replace(
          /\*{2}(.*?)\*{2}/gi,
          (bold) => `**${_.trim(bold.replace(/\*/gi, ""))}**`
        );
      } else {
        if (text) {
          newElements.push({
            ...defaultElement,
            Element: text,
          });
        }
      }
    });
    updateCheckList(newElements);
    setEditingIndex(null);
    if (enter && index === elements.length - 1) {
      updateCheckList([...newElements, defaultElement]);
      setEditingIndex(newElements.length);
    }
  };

  const noCheckedElements = () => {
    if (elements.length === 0) return false;
    let atLeastOneUnchecked = false;
    elements.forEach((el) => {
      if (el.Checked === false) {
        atLeastOneUnchecked = true;
      }
    });
    return atLeastOneUnchecked;
  };

  return (
    <InputContainer isConsult={isConsult} mb={extraParams.mb}>
      {isConsult ? (
        <div>
          <Row
            justify={extraParams.center ? "center" : "start"}
            align="middle"
            style={{
              overflow: "auto",
              height: "32px",
            }}
          >
            <P style={{ color: "#727e8b" }}>
              {`${nCheckedItems}/${nElements}`}
            </P>
          </Row>
        </div>
      ) : (
        <div>
          <div onMouseLeave={() => setHoveringIndex(null)}>
            {elements.map(
              (
                {
                  Checked,
                  Element: element,
                  IdElement,
                  IdPrepublishChecklistElement,
                },
                index
              ) => (
                <ElementContainer
                  key={index}
                  onMouseEnter={() => setHoveringIndex(index)}
                  disabled={disabled}
                  bgColor={
                    hoveringIndex === index &&
                    editingIndex !== index &&
                    !disabled &&
                    !(required && !!IdPrepublishChecklistElement)
                      ? "#f2f8fe"
                      : "white"
                  }
                >
                  <CheckboxContent>
                    {disabled && Checked ? (
                      <DisabledChecked>
                        <AiOutlineCheck size={16} color="#828d9e" />
                      </DisabledChecked>
                    ) : (
                      <StyledCheckbox
                        checked={Checked}
                        required={required}
                        disabled={disabled}
                        $cursor={disabled ? "auto" : "pointer"}
                        onChange={(e) => onCheck(e, index)}
                      />
                    )}
                    {editingIndex === index ? (
                      <TextAreaStyled
                        ref={inputRef}
                        value={editText.split("\n").join(" ")}
                        onChange={({ target: { value } }) => setEditText(value)}
                        onBlur={() => onChangeTitle(index)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            onChangeTitle(index, true);
                          }
                        }}
                        autoFocus
                        autoSize
                        $borderColor={
                          required ? (value ? "#edecec" : "#db232c") : "#edecec"
                        }
                        $background={disabled ? "#edecec" : "white"}
                      />
                    ) : (
                      <ElementContent
                        disabled={disabled || cannotAddOrEditElements}
                        $cursor={
                          disabled || cannotAddOrEditElements
                            ? "auto"
                            : "pointer"
                        }
                        onClick={
                          disabled ||
                          cannotAddOrEditElements ||
                          (required && !!IdPrepublishChecklistElement)
                            ? NOOP
                            : () => setEditingIndex(index)
                        }
                      >
                        <ReactMarkdownStyled
                          children={`${element}`.replace(
                            /:\w+:/gi,
                            (name) => emoji.getUnicode(name) ?? name
                          )}
                          remarkPlugins={[remarkGfm]}
                          $oneLine={!!extraParams.oneLineString}
                          components={{
                            a: ({ children, href }) => {
                              return (
                                <CustomLink
                                  href={href}
                                  placement={tooltipPlacement}
                                >
                                  {children}
                                </CustomLink>
                              );
                            },
                            ul: ({ children }) => {
                              return <CustomList>{children}</CustomList>;
                            },
                          }}
                        />
                      </ElementContent>
                    )}
                    <div
                      style={{
                        position: "absolute",
                        right: "0",
                      }}
                    >
                      {(hoveringIndex === index || editingIndex === index) &&
                        !disabled &&
                        !cannotAddOrEditElements &&
                        !(required && !!IdPrepublishChecklistElement) && (
                          <DeleteIcon
                            size={20}
                            onClick={() => onDelete(index)}
                            filled
                          />
                        )}
                    </div>
                  </CheckboxContent>
                </ElementContainer>
              )
            )}
            {!disabled && !cannotAddOrEditElements && (
              <Button
                onClick={addElement}
                type="secondary"
                size="fullwidth"
                Icon={AiOutlinePlus}
              >
                {NewElementButtonLabel}
              </Button>
            )}
          </div>
        </div>
      )}
      {required && !isConsult && noCheckedElements() && <IsRequired />}
    </InputContainer>
  );
};

export default CheckListInput;
