import React, { useState, useContext, useEffect } from "react";
import { Switch } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { sha256 } from "js-sha256";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Bowser from "bowser";
import styled from "styled-components";
import queryString from "query-string";
import moment from "moment-timezone";
import _ from "lodash";
// API
import { useMutation } from "../../hooks";
// Styles
import { Navigator, Title } from "../Login/styles";
// Components
import Input from "../../components/Inputs/Input";
import SubmitButton from "../Login/SubmitButton";
// Images
import icono from "./../../resources/img/ICONO_.svg";
import Img from "../../components/Img/Img";
// Context
import { SesionContext } from "./../../config/State";
import { SignUpContext } from "../../config/SignUpState";
import GeestVersion from "../../components/GeestVersion/GeestVersion";
import {
  GeestDestinations,
  SessionType,
} from "../../constants/PlatformDetails";
import { Languages } from "./Dictionary";
import { PhoneNumber } from "../../components/Formik";
import { getPhoneByCountry } from "../../components/DynamicInput/Components/helpers/PhoneNumberHelpers";
import { getUserFormats } from "../../Helpers";

const StyledLink = styled(Link)`
  color: #0273e9;
  text-decoration: underline;
  :hover {
    color: #014c9b;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  color: #828d9e;
  font-family: "Gotham-Book";
  font-size: 14px;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

const StyledSwitch = styled(Switch)`
  background: ${({ checked }) => checked && "#0273E9"};
  margin: 0 5px;
`;

const TitleSwitch = styled.div`
  color: #48505e;
  font-family: "Gotham-Book";
  font-size: 14px;
  text-align: center;
  margin-bottom: 8px;
`;

const OpcSwitch = styled.div`
  cursor: pointer;
`;

const PrivacyTerms = styled.div`
  max-width: 400px;
  color: #828d9e;
  font-size: 12px;
  text-align: center;
  padding: 0 2rem;
  margin-bottom: 14px;
  font-family: "Gotham-Book";
`;

const Register = () => {
  const history = useHistory();
  const [, setSesion] = useContext(SesionContext);
  const { IdPlan, Mode } = useContext(SignUpContext);
  const {
    PasswordsNotMacthError,
    RegisterLabel,
    EmailPlaceholder,
    PhoneNumberPlaceholder,
    NamesPlaceholder,
    LastNamePlaceholder,
    PasswordPlaceholder,
    RepeatPasswordPlaceholder,
    HaveAccountLabel,
    LoginLabel,
    PrivacyNotice,
    TermsAndCond,
    CheckText1,
    CheckText2,
    CheckText3,
    TeamNameLabel,
    JobRoleLabel,
    Collaborator,
    TeamLeader,
    FieldRequired,
    EmailValidation,
    PhoneNumberValidation,
  } = Languages["ESP"];

  const [Ip, setIp] = useState("");
  const [JobRole, setJobRole] = useState(true);
  const {
    parsedResult: { browser },
  } = Bowser.getParser(window.navigator.userAgent);
  const { search } = useLocation();
  const { UserCameFrom = GeestDestinations.App } = queryString.parse(search);

  useEffect(() => {
    fetch("https://api.ipify.org?format=jsonp?callback=?'", {
      method: "GET",
      headers: {},
    })
      .then((res) => {
        return res.text();
      })
      .then((ip) => {
        setIp(ip);
      });
  }, []);

  const [submitLogin] = useMutation({
    func: "Ver2-Session-l",
    onSuccess: (response) => {
      const {
        User,
        Token: { TokenType, AccessToken },
      } = response;
      setSesion({
        ...User,
        FavoriteTeam: { IdFavoriteTeam: "", TeamLogoUrl: "" },
        Auth: `${TokenType} ${AccessToken}`,
      });
      if (IdPlan && Mode) {
        history.push("/redirect?redirect=premium");
      }
    },
  });

  const [submitRegister, submitting] = useMutation({
    func: "Ver2-Session-run",
    onSuccess: (_response, { email, password }) => {
      getSesion(email, password);
    },
  });

  const handleOnSubmit = async ({
    email,
    areaCode,
    phoneNumber,
    firstName,
    lastName,
    password,
    teamName,
  }) => {
    const PhoneNumber = _.replace(
      getPhoneByCountry(areaCode) + phoneNumber,
      "+",
      ""
    );
    let CurrencyFormat = "MXN";
    let PhoneFormat = "MX";
    if (JobRole) {
      try {
        const formats = await getUserFormats();
        CurrencyFormat = formats.CurrencyFormat;
        PhoneFormat = formats.PhoneFormat;
      } catch (err) {
        console.log(err);
      }
    }
    submitRegister({
      args: {
        Email: email.replaceAll(/\s/g, ""),
        PhoneNumber,
        FirstName: firstName,
        LastName: lastName,
        Password: sha256(password),
        TeamName: teamName,
        UserIsLeader: JobRole,
        UserCameFrom,
        CurrencyFormat,
        PhoneFormat,
      },
      shippedData: { email, password },
    });
  };

  const getSesion = (Email, Password) => {
    submitLogin({
      args: {
        IdClient: process.env.REACT_APP_CLIENT_ID,
        ClientSecret: process.env.REACT_APP_CLIENT_SECRET,
        Email,
        Password: sha256(Password),
        SessionType,
        TimeZone: moment.tz.guess(),
        DeviceInfo: {
          Model: "",
          Ip,
          Browser: browser.name,
          ApiVersion: browser.version,
          LanguageCode: navigator.language,
          Country: "",
          PushNotificationsToken: "",
        },
      },
    });
  };

  const isValidForm = (formik) => {
    const fields = [
      "email",
      "areaCode",
      "phoneNumber",
      "firstName",
      "lastName",
      "password",
      "passwordRepeat",
      "teamName",
    ];
    for (const field of fields) {
      if (formik.touched[field] && formik.errors[field]) return false;
    }
    return true;
  };

  const onChangeRole = (value, formik) => {
    setJobRole(value);
    if (!value)
      setTimeout(() => {
        formik.validateForm();
      }, 1);
  };

  const onChangeEmail = (value) => _.replace(value, " ", "");

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        backgroundColor: "#EAEAEA",
        overflow: "auto",
      }}
    >
      <div
        style={{
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div style={{ height: "80px", width: "80px" }}>
            <Img img={icono} size="contain" cursor="default" />
          </div>
          <Title>{RegisterLabel}</Title>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Formik
            initialValues={{
              email: "",
              areaCode: "MX",
              phoneNumber: "",
              firstName: "",
              lastName: "",
              password: "",
              passwordRepeat: "",
              teamName: "",
            }}
            onSubmit={handleOnSubmit}
            validationSchema={Yup.object({
              email: Yup.string()
                .email(EmailValidation)
                .required(FieldRequired),
              areaCode: Yup.string().required(FieldRequired),
              phoneNumber: Yup.string()
                .required(FieldRequired)
                .matches(/^[0-9]+$/, PhoneNumberValidation)
                .min(5, PhoneNumberValidation),
              firstName: Yup.string().required(FieldRequired),
              lastName: Yup.string().required(FieldRequired),
              password: Yup.string().required(FieldRequired),
              passwordRepeat: Yup.string()
                .required(FieldRequired)
                .oneOf([Yup.ref("password"), null], PasswordsNotMacthError),
              teamName: JobRole
                ? Yup.string().required(FieldRequired)
                : Yup.string().notRequired(),
            })}
          >
            {(formik) => (
              <Form noValidate style={{ minWidth: "400px", padding: "0 2rem" }}>
                <Input
                  image=""
                  font="center"
                  type="email"
                  id="Usuario"
                  name="email"
                  placeholder={EmailPlaceholder}
                  controlledOnChange={onChangeEmail}
                />
                <PhoneNumber
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder={PhoneNumberPlaceholder}
                />
                <Input
                  image=""
                  font="center"
                  type="text"
                  id="nombre"
                  name="firstName"
                  placeholder={NamesPlaceholder}
                />
                <Input
                  image=""
                  font="center"
                  type="text"
                  id="apellido"
                  name="lastName"
                  placeholder={LastNamePlaceholder}
                />
                <Input
                  image=""
                  font="center"
                  type="password"
                  id="Contraseña"
                  name="password"
                  placeholder={PasswordPlaceholder}
                />
                <Input
                  image=""
                  font="center"
                  type="password"
                  id="Confirmar_Contraseña"
                  name="passwordRepeat"
                  placeholder={RepeatPasswordPlaceholder}
                />
                <div>
                  <TitleSwitch>{JobRoleLabel}</TitleSwitch>
                  <SwitchContainer>
                    <OpcSwitch onClick={() => onChangeRole(false, formik)}>
                      {Collaborator}
                    </OpcSwitch>
                    <StyledSwitch
                      checked={JobRole}
                      onChange={(value) => onChangeRole(value, formik)}
                    />
                    <OpcSwitch onClick={() => onChangeRole(true, formik)}>
                      {TeamLeader}
                    </OpcSwitch>
                  </SwitchContainer>
                </div>
                {JobRole && (
                  <Input
                    image=""
                    font="center"
                    type="text"
                    id="teamName"
                    name="teamName"
                    placeholder={TeamNameLabel}
                  />
                )}
                <PrivacyTerms>
                  {CheckText1}{" "}
                  <StyledLink to="/legal/privacy">{PrivacyNotice}</StyledLink>{" "}
                  {CheckText3}{" "}
                  <StyledLink to="/legal/terms">{TermsAndCond}</StyledLink>{" "}
                  {CheckText2}
                </PrivacyTerms>
                <SubmitButton
                  disabled={!isValidForm(formik)}
                  type="submit"
                  loading={submitting}
                >
                  Siguiente
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </div>
        <Navigator>
          <span>{HaveAccountLabel}</span>
          <Link to="/auth/login">{LoginLabel}</Link>
        </Navigator>
        <GeestVersion textStyle={{ fontSize: "12px", color: "#828D9E" }} />
      </div>
    </div>
  );
};

export default Register;
