import React, { useRef, useState } from "react";
import ContextMenu from "../../../components/ContextMenu";
import { ThreePointsIcon } from "../../../components/hoverIcons";
import { Button } from "../../../GeestUI";
import { Languages } from "./Dictionary";
import { FolderType, UserVarDB, Vardb } from "../DataBases.d";
import {
  DBCardContainer,
  DBBar,
  Card,
  CardTitle,
  CardControls,
  ContextMenuOptionContainer,
} from "./Styles";
import { AiOutlineRollback } from "react-icons/ai";
import FolderMenu from "./FolderMenu";
import Tooltip from "../../../components/Tooltip";
import openVDBIcon from "../../../resources/img/openVDB.svg";
import ImportIcon from "../../../components/hoverIcons/ImportIcon";
import ExportIcon from "../../../components/hoverIcons/ExportIcon";

interface DBCardProps {
  cardKey: string;
  varDB: Vardb;
  team: UserVarDB;
  idx: number;
  maxItems: number;
  folders: FolderType[];
  selectedFolder: FolderType;
  nestedFolders: FolderType[];
  openDB: () => void;
  onImport: (IdTeam: number, IdVarDB: string, dbName: string) => void;
  onExport: (IdTeam: number, IdVarDB: string) => void;
  onMoveToFolder: (
    IdTeam: number,
    IdVarDB: string | null,
    IdParentFolder: string | null,
    IdVardbFolder: string | null
  ) => void;
}

const DBCard: React.FC<DBCardProps> = ({
  cardKey,
  varDB,
  team,
  idx,
  maxItems,
  folders,
  selectedFolder,
  nestedFolders,
  openDB,
  onImport,
  onExport,
  onMoveToFolder,
}) => {
  const [openContext, setOpenContext] = useState<boolean>(false);
  const { Title, IdVarDB, Color } = varDB;
  const { CanEditDatabases, CanExportDatabases, IdTeam } = team;

  const ref = useRef<HTMLDivElement>(null);

  const { OpenLabel, ImportLabel, ExportLabel, RemoveFromFolderLabel } =
    Languages["ESP"];

  const onRemoveFromFolder = () => {
    let idParentFolder = null;
    if (nestedFolders.length > 1) {
      idParentFolder = nestedFolders[nestedFolders.length - 2].IdVardbFolder;
    }
    onMoveToFolder(IdTeam, IdVarDB, idParentFolder, null);
  };

  const getContextOptions = () => {
    let options: any[] = [];

    const folderOption = {
      label: (
        <FolderMenu
          folders={folders}
          onMove={(IdVardbFolder) =>
            onMoveToFolder(IdTeam, IdVarDB, IdVardbFolder, null)
          }
          disabled={folders.length === 0}
        />
      ),
      func: () => {},
      disabled: folders.length === 0,
    };

    options.push({
      label: (
        <ContextMenuOptionContainer>
          <img
            alt="open"
            src={openVDBIcon}
            width="17px"
            height="17px"
            style={{ marginRight: "3px" }}
          />
          Abrir base de datos
        </ContextMenuOptionContainer>
      ),
      func: openDB,
    });

    if (CanEditDatabases) {
      options.push({
        label: (
          <ContextMenuOptionContainer>
            <ImportIcon $width="20px" $height="20px" />
            {ImportLabel}
          </ContextMenuOptionContainer>
        ),
        func: () => {
          onImport(IdTeam, IdVarDB, Title);
          setOpenContext(false);
        },
      });
      options.push(folderOption);
    }

    if (CanExportDatabases) {
      options.push({
        label: (
          <ContextMenuOptionContainer>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                transform: "rotateY(180deg)",
              }}
            >
              <ExportIcon $width="20px" $height="20px" />
            </div>
            {ExportLabel}
          </ContextMenuOptionContainer>
        ),
        func: () => {
          onExport(IdTeam, IdVarDB);
          setOpenContext(false);
        },
      });
    }

    if (Boolean(selectedFolder) && CanEditDatabases) {
      options.push({
        label: (
          <ContextMenuOptionContainer>
            <AiOutlineRollback size={20} />
            {RemoveFromFolderLabel}
          </ContextMenuOptionContainer>
        ),
        func: onRemoveFromFolder,
      });
    }

    return options;
  };

  const canOpenContext = getContextOptions().length > 0 && CanEditDatabases;

  const getPoints = () => {
    if (!ref.current) {
      return { x: 0, y: 0 };
    }

    const { x, y } = ref.current?.getBoundingClientRect();
    return { x: x + 259, y: y + 20 };
  };

  return (
    <ContextMenu
      isOpen={openContext}
      width={282}
      closeMenu={() => setOpenContext(false)}
      options={getContextOptions()}
      points={getPoints()}
      placeDown
    >
      <DBCardContainer
        key={cardKey}
        $lastItem={(idx + 1) % maxItems === 0}
        onClick={() => setOpenContext(canOpenContext)}
      >
        <DBBar
          $color={Color}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenContext(canOpenContext);
          }}
        />

        <Card
          tabIndex={0}
          ref={ref}
          onKeyDown={({ key }) => {
            if (key === "Enter") openDB();
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenContext(canOpenContext);
          }}
        >
          <CardTitle>
            <div className="title">
              {Title.length > 16 ? (
                <Tooltip title={Title} placement="top">
                  <p>{Title}</p>
                </Tooltip>
              ) : (
                <p>{Title}</p>
              )}
            </div>

            {canOpenContext && (
              <ThreePointsIcon onClick={() => setOpenContext(true)} />
            )}
          </CardTitle>

          <CardControls>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                openDB();
              }}
              onKeyDown={({ key }) => {
                if (key === "Enter") openDB();
              }}
              type="primary"
              size="small"
              style={{ fontSize: "14px", fontFamily: "Gotham-Bold" }}
            >
              {OpenLabel}
            </Button>
          </CardControls>
        </Card>
      </DBCardContainer>
    </ContextMenu>
  );
};

export default DBCard;
