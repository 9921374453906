import React, { useState, useEffect } from "react";
import { loadFromLocalStorage, saveToLocalStorage } from "./Helpers";
import { noop as NOOP } from "lodash";

export const SesionContext = React.createContext(null);

export const State = ({ children }) => {
  const [sesion, setSesion] = useState(loadFromLocalStorage());

  useEffect(() => {
    document.addEventListener("TokenExpired", () => setSesion(null), false);
    return () => {
      document.removeEventListener("TokenExpired", NOOP);
    };
  }, []);

  useEffect(() => {
    saveToLocalStorage(sesion);
  }, [sesion]);

  return (
    <SesionContext.Provider value={[sesion, setSesion]}>
      {children}
    </SesionContext.Provider>
  );
};
