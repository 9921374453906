import React from "react";
import GeestLogo from "../../resources/img/LOGO_.svg";
import GeestIcon from "../../resources/img/ICONO_.svg";
import GeestError from "../../resources/img/GeestError.png";
import Button from "../../components/Button";
import { useHistory } from "react-router-dom";
import {
  Container,
  Card,
  Content,
  Header,
  CardCont,
  ErrorCard,
  ErrorCardShadow,
  Text,
} from "./Style";
import { GeestPhoneNumber } from "../../constants/PlatformDetails";

const Languages = {
  ENG: {
    Label: "¡Ups! Something went wrong",
    GeestButton: "Go to Geest",
    BugButton: "Report a problem",
    Description: "Tell us how you got this error",
  },
  ESP: {
    Label: "¡Ups! Algo salió mal",
    GeestButton: "Ir a Geest",
    BugButton: "Reportar un problema",
    Description: "Cuéntanos cómo llegaste a este error",
  },
};

const ErrorLayout = () => {
  const { Label, GeestButton, BugButton } = Languages["ESP"];
  const history = useHistory();

  return (
    <Container>
      <Card>
        <Content>
          <Header>
            <img src={GeestIcon} alt="GeestIcon" width="65" />
            <img
              src={GeestLogo}
              alt="GeestLogo"
              width="120"
              style={{ marginLeft: "0.5rem" }}
            />
          </Header>
          <CardCont>
            <ErrorCardShadow xs={18} xl={11}>
              <ErrorCard>
                <img
                  src={GeestError}
                  alt="GeestError"
                  width="25%"
                  style={{ marginBottom: "1rem" }}
                />
                <Text>{Label}</Text>
                <Button
                  onClick={() => history.push("/")}
                  type="shadow"
                  style={{
                    width: "100%",
                    height: "3rem",
                    color: "#1a70b2",
                    fontSize: "large",
                    marginBottom: "1.5rem",
                  }}
                >
                  {GeestButton}
                </Button>
                <Button
                  onClick={() =>
                    window.open(`https://wa.me/${GeestPhoneNumber}`)
                  }
                  type="shadow"
                  style={{
                    width: "100%",
                    height: "3rem",
                    fontSize: "large",
                  }}
                >
                  {BugButton}
                </Button>
              </ErrorCard>
            </ErrorCardShadow>
          </CardCont>
        </Content>
      </Card>
    </Container>
  );
};

export default ErrorLayout;
