export const BasicUsageExample = `import React from "react";
import { EditorState } from "draft-js";
import GeestTextEditor from "(...)/components/GeestTextEditor";

const MyComponent: React.FC<MyComponentProps> = () => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  return (
    <GeestTextEditor
      editorState={editorState}
      onEditorStateChange={setEditorState}
    />
  );
};

export default MyComponent;
`;

export const PrevStateExample = `const [editorState, setEditorState] = useState<EditorState>(() => {
  const convertedState = ConvertFromRaw(JSON.parse(JSONPrevStateAsString));
  return EditorState.createWithContent(convertedState);
});`;
