import React, { useRef, useState } from "react";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { Button } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";
import { Languages } from "./Dictionary";
import { DragDropFileProps, GIEWStepOneResponse } from "./ImportDBModal.d";
import {
  DragDileElement,
  DropItContainer,
  FormContent,
  FormFileUpload,
  InputFileUpload,
  LabelFileUpload,
} from "./Styles";

const DragDropFile: React.FC<DragDropFileProps> = ({
  IdTeam,
  IdVarDB,
  importing,
  setUploadingFile,
  setProgress,
  step,
  goNextStep,
  setFileName,
  setFileContent,
  setVarDBColumns,
  setSelectedColumns,
}) => {
  const { DropIt, SelectYourFile, UploadYourFile } = Languages["ESP"];
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [getImportExcelWizard] = useMutation<GIEWStepOneResponse>({
    func: "Ver2-Vardbs-giew",
    onSuccess: ({ ExcelImportingColumns, VardbColumns }) => {
      setUploadingFile(false);
      setProgress(100);
      setVarDBColumns(VardbColumns ?? []);

      setSelectedColumns(
        [...ExcelImportingColumns].map((column) => {
          return {
            ...column,
            IdColumnSelected: 0,
          };
        })
      );

      goNextStep();
    },
  });

  const handleUpload = async (newFile: any) => {
    if (newFile) {
      const filename = newFile.name.split(".").slice(0, -1).join(".");
      setFileName(filename);

      const reader = new FileReader();

      if (newFile.name.split(".").pop() === "csv") {
        reader.onload = async (event: any) => {
          const fileContent = event.target.result;
          setFileContent(fileContent);
          setUploadingFile(true);
          getImportExcelWizard({
            args: {
              IdTeam,
              IdVarDB,
              Step: step + 1,
              Content: fileContent,
            },
          });
        };
        reader.readAsText(newFile);
      } else if (newFile.name.split(".").pop() === "xlsx") {
        reader.onload = (event: any) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // @ts-ignore
          const csv = Papa.unparse(jsonData, { encoding: "UTF-8" });

          setFileContent(csv);
          setUploadingFile(true);
          getImportExcelWizard({
            args: {
              IdTeam,
              IdVarDB,
              Step: step + 1,
              Content: csv,
            },
          });
        };
        reader.readAsArrayBuffer(newFile);
      }
    }
  };

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleUpload(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleUpload(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  return (
    <FormFileUpload
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <InputFileUpload
        id="input-file-upload"
        ref={inputRef}
        type="file"
        accept=".csv, .xlsx"
        multiple={false}
        onChange={handleChange}
      />

      <LabelFileUpload
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        {dragActive && (
          <DropItContainer>
            <p>{DropIt}</p>
          </DropItContainer>
        )}

        <FormContent>
          <Button
            type="primary"
            size="xlarge"
            loading={importing}
            onClick={onButtonClick}
          >
            {SelectYourFile}
          </Button>

          <p>{UploadYourFile}</p>
        </FormContent>
      </LabelFileUpload>

      {dragActive && (
        <DragDileElement
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></DragDileElement>
      )}
    </FormFileUpload>
  );
};

export default DragDropFile;
