import React, { useEffect, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Modal } from "@material-ui/core";
import { AiOutlineInfoCircle } from "react-icons/ai";
//@ts-ignore
import emoji from "emoji-dictionary";
import Tooltip from "../../../../components/Tooltip";
import DataTypeIcons from "../../../../components/DataTypeIcons";
import DataTypeIconsClear from "../../../../components/DataTypeIconsClear";
import DragDropIcon from "../../../../components/hoverIcons/DragDropIcon";
import { CloseIcon } from "../../../../components/hoverIcons";
import { useFetch, useMutation } from "../../../../hooks";
import { Button } from "../../../../GeestUI";
import ProcessIcon from "../../../../components/hoverIcons/ProcessIcon";
import VarDBIcon from "../../../../components/hoverIcons/VarDBIcon";
import {
  ConfigReportModalProps,
  GetReportConfigurationResponse,
  TableHeaderType,
  Process,
  ReportConfiguration,
  VarDB,
} from "./ConfigReportModal.d";
import {
  ButtonsContainer,
  ColumnCategory,
  ColumnCategoryHeader,
  ColumnDivider,
  ColumnFormField,
  ColumnFormType,
  ColumnItem,
  ColumnsContainer,
  ConfigCheckElement,
  ConfigChecks,
  ContentWrapper,
  DeleteColumnButton,
  ElementName,
  FieldsHeaderWrapper,
  Footer,
  FooterContent,
  Header,
  IconContainer,
  ImportedColumnElement,
  ImportedColumnElementWrapper,
  ImportedColumns,
  InfoIconContainer,
  ModalContent,
  Navigation,
  NavigationHeader,
  NavigationItem,
  NavigationItems,
  NavigationSection,
  SearchInput,
  SectionHeader,
  SectionTitle,
  StyledCheckbox,
  StyledPaper,
  Title,
  ColumnItemsContainer,
  ElementData,
  ElementIconInfo,
} from "./Styles";
import { IoClose } from "react-icons/io5";
import UnsavedChangesModal from "./UnsavedChangesModal";
import Loading from "../../../../components/NewLoading";
import SaveReportModal from "../Popups/SaveReportModal";

const Languages = {
  ENG: {
    TitleLabel: "Config Report",
    SearchLabel: "Search...",

    ColumnsSectionTitle: "Aviable Columns",
    ColumnsTooltip: "Select the elements you want to see in the table",

    ImportedColumnsSectionTitle: "Visible Columns",
    ImportedColumnsTooltip:
      "Drag and drop to reorder the elements in the table",

    ShowTotalsLabel: "Show totals row",
    FixFirstColumnLabel: "Fix first column",

    CancelLabel: "Cancel",
    SaveConfigLabel: "Save configuration",
  },
  ESP: {
    TitleLabel: "Configurar Reporte",
    SearchLabel: "Buscar...",

    ColumnsSectionTitle: "Columnas Disponibles",
    ColumnsTooltip: "Selecciona los elementos que quieras ver en la tabla",

    ImportedColumnsSectionTitle: "Columnas visibles",
    ImportedColumnsTooltip:
      "Arrastra y suelta hacia arriba o abajo los elementos para cambiar el orden de las columnas",

    ShowTotalsLabel: "Mostrar fila de totales",
    FixFirstColumnLabel: "Fijar primer columna",

    CancelLabel: "Cancelar",
    SaveConfigLabel: "Guardar configuración",
  },
};

const ConfigReportModal: React.FC<ConfigReportModalProps> = ({
  onClose,
  callGetPipelineReport,
  idTeam,
  idReport,
  reportName,
  onChangeReport,
}) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [search, setSearch] = useState("");
  const [configuration, setConfiguration] = useState<ReportConfiguration>({
    FirstColumStatic: false,
    ShowTotal: false,
  });
  const [importedColumns, setImportedColumns] = useState<string[]>([]);

  const [generalInformation, setGeneralInformation] = useState<
    TableHeaderType[]
  >([]);
  const [processes, setProcesses] = useState<Process[]>([]);
  const [varDBs, setVarDBs] = useState<VarDB[]>([]);

  const [filteredGeneralInformation, setFilteredGeneralInformation] = useState<
    TableHeaderType[]
  >([]);
  const [filteredProccesses, setFilteredProcesses] = useState<Process[]>([]);
  const [filteredVarDBs, setFilteredVarDBs] = useState<VarDB[]>([]);
  const [highlightedId, setHighlightedId] = useState<string>("");
  const fieldsRef = useRef<any>([]);
  const [highlightedGroup, setHighlightedGroup] = useState<string>("");
  const [saveReport, setSaveReport] = useState<boolean>(false);

  const {
    TitleLabel,
    SearchLabel,

    ColumnsSectionTitle,
    ColumnsTooltip,

    ImportedColumnsSectionTitle,
    ImportedColumnsTooltip,

    ShowTotalsLabel,
    FixFirstColumnLabel,

    CancelLabel,
    SaveConfigLabel,
  } = Languages["ESP"];

  const checkImportedColumns = (
    importedColumns: string[],
    generalInfo: TableHeaderType[],
    procesess: Process[],
    varDBs: VarDB[]
  ) => {
    const generalInfoIds = generalInfo.map(({ Id }) => Id);
    let varDBIds: string[] = [];
    varDBs.forEach(({ AllowColumnsToImport }) => {
      varDBIds = [...varDBIds, ...AllowColumnsToImport.map(({ Id }) => Id)];
    });
    let processIds: string[] = [];
    procesess.forEach(({ AllowFieldsToImport, AllowTaskToImport }) => {
      processIds = [
        ...processIds,
        ...AllowFieldsToImport.map(({ Id }) => Id),
        ...AllowTaskToImport.map(({ Id }) => Id),
      ];
    });
    const ids = [...generalInfoIds, ...varDBIds, ...processIds];
    return importedColumns.filter((id) => ids.includes(id));
  };

  const { loading } = useFetch<GetReportConfigurationResponse>({
    func: "Ver2-Pipeline-grc",
    args: {
      IdTeam: idTeam,
      IdReport: idReport,
    },
    onSuccess: ({ Configuration, ImportedColumns, ConfColumns }) => {
      setConfiguration(Configuration);
      let generalInfo = ConfColumns.GeneralInformation.filter(
        (header) => typeof header !== "string"
      );
      setImportedColumns(
        checkImportedColumns(
          ImportedColumns,
          generalInfo,
          ConfColumns.Processes,
          ConfColumns.VarDBs
        )
      );
      setGeneralInformation(generalInfo);
      setFilteredGeneralInformation(generalInfo);
      setProcesses(ConfColumns.Processes || []);
      setFilteredProcesses(ConfColumns.Processes || []);
      setVarDBs(ConfColumns.VarDBs || []);
      setFilteredVarDBs(ConfColumns.VarDBs || []);
    },
  });

  const [saveReportConfiguration, savingReport] = useMutation<number>({
    func: "Ver2-Pipeline-src",
    onSuccess: (data) => {
      setUnsavedChanges(false);
      setOpenUnsavedChangesModal(false);
      onChangeReport(data);
      onClose();
    },
  });

  const normalizeText = (text: string) => {
    return text
      .toLowerCase()
      .replace(/[áéíóúü]/g, (match) => {
        const map: { [key: string]: string } = {
          á: "a",
          é: "e",
          í: "i",
          ó: "o",
          ú: "u",
          ü: "u",
        };
        return map[match];
      })
      .trim();
  };

  const checkItemsInView = (e: any) => {
    const processItems = [...filteredProccesses];
    const containerElement = document.getElementById("container");
    if (!containerElement) return;
    const containerRect = containerElement.getBoundingClientRect();
    const generalElement = document.getElementById("general");
    if (generalElement) {
      const { top, bottom } = generalElement.getBoundingClientRect();
      if (
        (top >= containerRect.top && top < containerRect.bottom) ||
        (bottom > containerRect.top && bottom <= containerRect.bottom)
      ) {
        setHighlightedId("general");
        setHighlightedGroup("general");
        return;
      }
    }
    for (let i = 0; i < processItems.length; i++) {
      const item = processItems[i];
      let element = document.getElementById(
        `process-${item.IdProcessTemplate}-form`
      );
      if (element) {
        const { top, bottom } = element.getBoundingClientRect();
        if (
          (top >= containerRect.top && top < containerRect.bottom) ||
          (bottom > containerRect.top && bottom <= containerRect.bottom)
        ) {
          setHighlightedId(`process-${item.IdProcessTemplate}-form`);
          setHighlightedGroup(`process-${item.IdProcessTemplate}`);
          return;
        }
      }
      element = document.getElementById(
        `process-${item.IdProcessTemplate}-task`
      );
      if (element) {
        const { top, bottom } = element.getBoundingClientRect();
        if (
          (top >= containerRect.top && top < containerRect.bottom) ||
          (bottom > containerRect.top && bottom <= containerRect.bottom)
        ) {
          setHighlightedId(`process-${item.IdProcessTemplate}-task`);
          setHighlightedGroup(`process-${item.IdProcessTemplate}`);
          return;
        }
      }
    }
    const varDBItems = [...filteredVarDBs];
    for (let i = 0; i < varDBItems.length; i++) {
      const item = varDBItems[i];
      let element = document.getElementById(`varDB-${item.IdVarDB}`);
      if (element) {
        const { top, bottom } = element.getBoundingClientRect();
        if (
          (top >= containerRect.top && top < containerRect.bottom) ||
          (bottom > containerRect.top && bottom <= containerRect.bottom)
        ) {
          setHighlightedId(`varDB-${item.IdVarDB}`);
          setHighlightedGroup("varDB");
          return;
        }
      }
    }
  };

  useEffect(() => {
    checkItemsInView(null);
    const element = document.getElementById("container");
    element?.addEventListener("scroll", checkItemsInView);

    return () => element?.removeEventListener("scroll", checkItemsInView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProccesses, filteredVarDBs]);

  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);
    const searchNormalized = normalizeText(searchValue);

    const filteredGeneralInformation = generalInformation.filter((header) => {
      return normalizeText(header.Label).includes(searchNormalized);
    });
    setFilteredGeneralInformation(filteredGeneralInformation);

    let filteredProccesses: Process[] = [];
    processes.forEach((process) => {
      let newProcess = { ...process };

      newProcess.AllowTaskToImport = process.AllowTaskToImport.filter(
        (task) => {
          return normalizeText(task.Label).includes(searchNormalized);
        }
      );
      newProcess.AllowFieldsToImport = process.AllowFieldsToImport.filter(
        (field) => {
          return normalizeText(field.Label).includes(searchNormalized);
        }
      );

      if (
        newProcess.AllowTaskToImport.length > 0 ||
        newProcess.AllowFieldsToImport.length > 0
      ) {
        filteredProccesses.push(newProcess);
      }
    });
    setFilteredProcesses(filteredProccesses);

    let filteredVarDBs: VarDB[] = [];
    varDBs.forEach((varDB) => {
      let newVarDB = { ...varDB };
      newVarDB.AllowColumnsToImport = newVarDB.AllowColumnsToImport.filter(
        (column) => {
          return normalizeText(column.Label).includes(searchNormalized);
        }
      );

      if (newVarDB.AllowColumnsToImport.length > 0) {
        filteredVarDBs.push(newVarDB);
      }
    });
    setFilteredVarDBs(filteredVarDBs);
  };

  const scrollToElementById = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getImportedColumnData = (id: string) => {
    let label = "";
    let dataType: string | null = "";

    generalInformation.forEach((header) => {
      if (header.Id === id) {
        label = header.Label;
        dataType = getGeneralInfoIcon(header.Key);
      }
    });

    processes.forEach((process) => {
      process.AllowTaskToImport.forEach((task) => {
        if (task.Id === id) {
          label = task.Label;
          dataType = task.DataType ?? null;
        }
      });

      process.AllowFieldsToImport.forEach((field) => {
        if (field.Id === id) {
          label = field.Label;
          dataType = field.DataType;
        }
      });
    });

    varDBs.forEach((varDB) => {
      varDB.AllowColumnsToImport.forEach((column) => {
        if (column.Id === id) {
          label = column.Label;
          dataType = column.DataType ?? null;
        }
      });
    });

    return { label, dataType };
  };

  const isChecked = (id: string) => {
    return importedColumns.includes(id);
  };

  const handleCheck = (id: string) => {
    setUnsavedChanges(true);
    if (isChecked(id)) {
      setImportedColumns(importedColumns.filter((column) => column !== id));
    } else {
      setImportedColumns([...importedColumns, id]);
    }
  };

  const handleReorder = ({ source, destination }: DropResult) => {
    if (destination) {
      const { index: sourceIndex } = source;
      const { index: destinationIndex } = destination;
      let newImportedColumns = [...importedColumns];
      newImportedColumns.splice(
        destinationIndex,
        0,
        newImportedColumns.splice(sourceIndex, 1)[0]
      );
      setImportedColumns(newImportedColumns);
      setUnsavedChanges(true);
    }
  };

  const getGeneralInfoIcon = (key: string) => {
    if (key === "StartedUser") return "users_select";
    if (key === "ProcessTrigger") return "process_trigger";
    if (key === "ExecutionId") return "number";
    if (key === "StartDate") return "time";
    if (key === "EndDate") return "time";
    if (key === "Duration") return "duration";
    if (key === "Status") return "status";
    if (key === "Stage") return "stage";
    if (key === "Priority") return "priority";
    if (key === "ProcessTemplateName") return "process";
    if (key === "CurrentExecutors") return "current_executors";
    if (key === "UsersInvolved") return "users_select";
    if (key === "StuckTime") return "stuck_time";
    if (key === "LastInteraction") return "last_interaction";

    return "";
  };

  const getProcessNameFromDuration = (id: string) => {
    for (const process of filteredProccesses) {
      const task = process.AllowTaskToImport.find((task) => task.Id === id);
      if (task) {
        return process.ProcessName;
      }
    }

    return "";
  };

  const handleOnSave = (name?: string) => {
    saveReportConfiguration({
      args: {
        IdTeam: idTeam,
        IdReport: idReport,
        Name: name ?? reportName ?? "Nuevo reporte",
        Configuration: {
          FirstColumStatic: configuration.FirstColumStatic,
          ShowTotal: configuration.ShowTotal,
        },
        ImportedColumns: importedColumns,
      },
    });
  };

  const handleOnCancel = () => {
    if (unsavedChanges) {
      setOpenUnsavedChangesModal(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      {openUnsavedChangesModal && (
        <UnsavedChangesModal
          onCancel={() => setOpenUnsavedChangesModal(false)}
          onExit={() => {
            setUnsavedChanges(false);
            setOpenUnsavedChangesModal(false);
            onClose();
          }}
        />
      )}
      {saveReport && (
        <SaveReportModal
          setSelectedReport={onChangeReport}
          IdTeam={idTeam}
          onClose={() => setSaveReport(false)}
          onSave={handleOnSave}
          reloadReports={() => callGetPipelineReport({ changedReport: true })}
        />
      )}
      <Modal open onClose={handleOnCancel} style={{ opacity: 1, zIndex: 20 }}>
        <ModalContent onClick={handleOnCancel}>
          <StyledPaper onClick={(e) => e.stopPropagation()}>
            <Header>
              <Title>{TitleLabel}</Title>
              <CloseIcon
                size={20}
                onClick={handleOnCancel}
                hoverContainerColor="#d9d9d9"
              />
            </Header>
            <ContentWrapper>
              {loading ? (
                <Loading height={150} width={150} />
              ) : (
                <>
                  <Navigation>
                    {(filteredProccesses.length > 0 ||
                      filteredGeneralInformation.length > 0) && (
                      <NavigationSection
                        $highlight={highlightedGroup === "general"}
                      >
                        <NavigationHeader
                          onClick={() => scrollToElementById("general")}
                          $highlight={highlightedId === "general"}
                        >
                          <p>Info del proceso</p>
                        </NavigationHeader>
                      </NavigationSection>
                    )}

                    {filteredProccesses.map((process) => (
                      <NavigationSection
                        key={process.IdProcessTemplate}
                        $highlight={
                          highlightedGroup ===
                          `process-${process.IdProcessTemplate}`
                        }
                      >
                        <NavigationHeader
                          onClick={() =>
                            scrollToElementById(
                              `process-${process.IdProcessTemplate}`
                            )
                          }
                        >
                          <p>{process.ProcessName}</p>
                        </NavigationHeader>

                        <NavigationItems>
                          {process.AllowFieldsToImport.length > 0 && (
                            <NavigationItem
                              onClick={() =>
                                scrollToElementById(
                                  `process-${process.IdProcessTemplate}-form`
                                )
                              }
                              $highlight={
                                highlightedId ===
                                `process-${process.IdProcessTemplate}-form`
                              }
                            >
                              <p>Campos de formulario</p>
                            </NavigationItem>
                          )}
                          {process.AllowTaskToImport.length > 0 && (
                            <NavigationItem
                              onClick={() =>
                                scrollToElementById(
                                  `process-${process.IdProcessTemplate}-task`
                                )
                              }
                              $highlight={
                                highlightedId ===
                                `process-${process.IdProcessTemplate}-task`
                              }
                            >
                              <p>Duración de tareas</p>
                            </NavigationItem>
                          )}
                        </NavigationItems>
                      </NavigationSection>
                    ))}

                    {filteredVarDBs.length > 0 && (
                      <NavigationSection
                        $highlight={highlightedGroup === "varDB"}
                      >
                        <NavigationHeader
                          onClick={() => scrollToElementById("varDBs")}
                        >
                          <p>Bases de datos</p>
                        </NavigationHeader>
                        <NavigationItems>
                          {filteredVarDBs.map((varDB) => (
                            <NavigationItem
                              key={varDB.IdVarDB}
                              onClick={() =>
                                scrollToElementById(`varDB-${varDB.IdVarDB}`)
                              }
                              $highlight={
                                highlightedId === `varDB-${varDB.IdVarDB}`
                              }
                            >
                              <p>{varDB.DataBaseName}</p>
                            </NavigationItem>
                          ))}
                        </NavigationItems>
                      </NavigationSection>
                    )}
                  </Navigation>

                  <ColumnsContainer>
                    <FieldsHeaderWrapper>
                      <SectionHeader>
                        <Tooltip title={ColumnsTooltip}>
                          <AiOutlineInfoCircle
                            size={16}
                            color="rgba(72, 80, 94, 1)"
                          />
                        </Tooltip>
                        <SectionTitle>{ColumnsSectionTitle}</SectionTitle>
                      </SectionHeader>

                      <SearchInput
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        placeholder={SearchLabel}
                      />
                    </FieldsHeaderWrapper>
                    <ColumnItemsContainer id="container">
                      <ColumnCategory>
                        {filteredGeneralInformation.length > 0 && (
                          <ColumnCategoryHeader id="general">
                            <InfoIconContainer>
                              <AiOutlineInfoCircle size={14} color="#0273e9" />
                            </InfoIconContainer>
                            <p>Info del proceso</p>
                          </ColumnCategoryHeader>
                        )}

                        {filteredGeneralInformation.map((header) => (
                          <ColumnItem
                            key={header.Id}
                            onClick={() => handleCheck(header.Id)}
                          >
                            <StyledCheckbox
                              checked={isChecked(header.Id)}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleCheck(header.Id);
                              }}
                            />
                            <IconContainer m="0">
                              {DataTypeIcons[
                                getGeneralInfoIcon(header.Key)
                              ] && (
                                <img
                                  src={
                                    DataTypeIcons[
                                      getGeneralInfoIcon(header.Key)
                                    ].icon
                                  }
                                  alt=""
                                  style={{
                                    width: "14px",
                                    height: "14px",
                                    ...DataTypeIcons[
                                      getGeneralInfoIcon(header.Key)
                                    ].extraStyles,
                                  }}
                                />
                              )}
                            </IconContainer>
                            <p>{header.Label}</p>
                          </ColumnItem>
                        ))}
                      </ColumnCategory>

                      {filteredProccesses.map((process, i) => (
                        <ColumnCategory key={process.IdProcessTemplate}>
                          <ColumnCategoryHeader
                            id={`process-${process.IdProcessTemplate}`}
                          >
                            <InfoIconContainer>
                              <InfoIconContainer>
                                <ProcessIcon />
                              </InfoIconContainer>
                            </InfoIconContainer>
                            <p>{process.ProcessName}</p>
                          </ColumnCategoryHeader>
                          {process.AllowFieldsToImport.length > 0 && (
                            <ColumnDivider
                              id={`process-${process.IdProcessTemplate}-form`}
                              ref={(el) => (fieldsRef.current[i] = el)}
                            >
                              <p>Campos de formulario</p>
                            </ColumnDivider>
                          )}
                          {process.AllowFieldsToImport.map((field) => (
                            <ColumnItem
                              key={field.Id}
                              onClick={() => handleCheck(field.Id)}
                            >
                              <StyledCheckbox
                                checked={isChecked(field.Id)}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  handleCheck(field.Id);
                                }}
                              />
                              <ColumnFormField>
                                <p>{field.Label}</p>

                                <ColumnFormType>
                                  <IconContainer m="0">
                                    {DataTypeIconsClear[field.DataType] && (
                                      <img
                                        src={
                                          DataTypeIconsClear[field.DataType]
                                            .icon
                                        }
                                        alt=""
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          ...DataTypeIconsClear[field.DataType]
                                            .extraStylesSmall,
                                        }}
                                      />
                                    )}
                                  </IconContainer>

                                  <span>Campos de formulario</span>
                                </ColumnFormType>
                              </ColumnFormField>
                            </ColumnItem>
                          ))}
                          {process.AllowTaskToImport.length > 0 && (
                            <ColumnDivider
                              id={`process-${process.IdProcessTemplate}-task`}
                            >
                              <p>Duraciones de tarea</p>
                            </ColumnDivider>
                          )}
                          {process.AllowTaskToImport.map((task) => (
                            <ColumnItem
                              key={task.Id}
                              onClick={() => handleCheck(task.Id)}
                            >
                              <StyledCheckbox
                                checked={isChecked(task.Id)}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  handleCheck(task.Id);
                                }}
                              />
                              <p>
                                {task.Label.replace(
                                  /\n/gi,
                                  "&nbsp; \n"
                                ).replace(
                                  /:\w+:/gi,
                                  (name) => emoji.getUnicode(name) ?? name
                                )}
                              </p>
                            </ColumnItem>
                          ))}
                        </ColumnCategory>
                      ))}

                      <ColumnCategory>
                        {filteredVarDBs.length > 0 && (
                          <ColumnCategoryHeader id="varDBs">
                            <InfoIconContainer>
                              <InfoIconContainer>
                                <VarDBIcon />
                              </InfoIconContainer>
                            </InfoIconContainer>
                            <p>Bases de datos</p>
                          </ColumnCategoryHeader>
                        )}

                        {filteredVarDBs.map((varDB) => (
                          <>
                            <ColumnDivider
                              key={varDB.IdVarDB.toString()}
                              id={`varDB-${varDB.IdVarDB}`}
                            >
                              <p>{varDB.DataBaseName}</p>
                            </ColumnDivider>

                            {varDB.AllowColumnsToImport.map((column) => (
                              <ColumnItem
                                key={column.Id}
                                onClick={() => handleCheck(column.Id)}
                              >
                                <StyledCheckbox
                                  checked={isChecked(column.Id)}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleCheck(column.Id);
                                  }}
                                />

                                <ColumnFormField>
                                  <p>{column.Label}</p>
                                  <ColumnFormType>
                                    <IconContainer m="0 2px 0 4px">
                                      {DataTypeIconsClear[column.DataType] && (
                                        <img
                                          src={
                                            DataTypeIconsClear[column.DataType]
                                              .icon
                                          }
                                          alt=""
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            ...DataTypeIconsClear[
                                              column.DataType
                                            ].extraStylesSmall,
                                          }}
                                        />
                                      )}
                                    </IconContainer>

                                    <span>{column.DataType}</span>
                                  </ColumnFormType>
                                </ColumnFormField>
                              </ColumnItem>
                            ))}
                          </>
                        ))}
                      </ColumnCategory>
                    </ColumnItemsContainer>
                  </ColumnsContainer>

                  <ImportedColumns>
                    <SectionHeader>
                      <Tooltip title={ImportedColumnsTooltip}>
                        <AiOutlineInfoCircle
                          size={16}
                          color="rgba(72, 80, 94, 1)"
                        />
                      </Tooltip>
                      <SectionTitle>
                        {ImportedColumnsSectionTitle} (
                        {importedColumns.length + 1})
                      </SectionTitle>
                    </SectionHeader>

                    <ImportedColumnElementWrapper>
                      <ImportedColumnElement>
                        <IconContainer m="0" />
                        <IconContainer m="0" />
                        <ElementName>
                          <p>Seguimiento</p>
                        </ElementName>
                      </ImportedColumnElement>

                      <DragDropContext onDragEnd={handleReorder}>
                        <Droppable droppableId="droppable">
                          {({ droppableProps, innerRef }) => (
                            <div ref={innerRef} {...droppableProps}>
                              {importedColumns.map((importedColumn, index) => {
                                const { label, dataType } =
                                  getImportedColumnData(importedColumn);

                                const isFromField =
                                  importedColumn.startsWith("InfoFromField") ||
                                  importedColumn.startsWith("InfoFromColumn");

                                const isTaskDuration =
                                  importedColumn.startsWith("TaskDuration");

                                if (isFromField) {
                                  return (
                                    <Draggable
                                      key={importedColumn}
                                      draggableId={importedColumn}
                                      index={index}
                                    >
                                      {({
                                        draggableProps,
                                        dragHandleProps,
                                        innerRef,
                                      }) => (
                                        <ImportedColumnElement
                                          key={importedColumn}
                                          ref={innerRef}
                                          $mb={
                                            index === importedColumns.length - 1
                                              ? ""
                                              : "6px"
                                          }
                                          {...draggableProps}
                                          {...dragHandleProps}
                                        >
                                          <IconContainer
                                            m="0"
                                            $color="#0273e9"
                                            cursorGrab
                                          >
                                            <DragDropIcon />
                                          </IconContainer>
                                          <ElementData>
                                            <ElementName>
                                              <p>
                                                {label
                                                  .replace(/\n/gi, "&nbsp; \n")
                                                  .replace(
                                                    /:\w+:/gi,
                                                    (name) =>
                                                      emoji.getUnicode(name) ??
                                                      name
                                                  )}
                                              </p>
                                            </ElementName>

                                            <ElementIconInfo>
                                              {DataTypeIconsClear[dataType] && (
                                                <IconContainer m="0">
                                                  <img
                                                    src={
                                                      DataTypeIconsClear[
                                                        dataType
                                                      ].icon
                                                    }
                                                    alt=""
                                                    style={{
                                                      width: "10px",
                                                      height: "10px",
                                                      ...DataTypeIconsClear[
                                                        dataType
                                                      ].extraStyles,
                                                    }}
                                                  />
                                                </IconContainer>
                                              )}
                                              <span>
                                                {DataTypeIconsClear[dataType] &&
                                                  DataTypeIconsClear[dataType]
                                                    .label}
                                              </span>
                                            </ElementIconInfo>
                                          </ElementData>

                                          <DeleteColumnButton
                                            onClick={() =>
                                              handleCheck(importedColumn)
                                            }
                                          >
                                            <IoClose
                                              color="#0273E9"
                                              size={20}
                                            />
                                          </DeleteColumnButton>
                                        </ImportedColumnElement>
                                      )}
                                    </Draggable>
                                  );
                                }

                                if (isTaskDuration) {
                                  const processName =
                                    getProcessNameFromDuration(importedColumn);

                                  return (
                                    <Draggable
                                      key={importedColumn}
                                      draggableId={importedColumn}
                                      index={index}
                                    >
                                      {({
                                        draggableProps,
                                        dragHandleProps,
                                        innerRef,
                                      }) => (
                                        <ImportedColumnElement
                                          key={importedColumn}
                                          ref={innerRef}
                                          $mb={
                                            index === importedColumns.length - 1
                                              ? ""
                                              : "6px"
                                          }
                                          {...draggableProps}
                                          {...dragHandleProps}
                                        >
                                          <IconContainer
                                            m="0"
                                            $color="#0273e9"
                                            cursorGrab
                                          >
                                            <DragDropIcon />
                                          </IconContainer>
                                          <ElementData>
                                            <ElementName>
                                              <p>
                                                {label
                                                  .replace(/\n/gi, "&nbsp; \n")
                                                  .replace(
                                                    /:\w+:/gi,
                                                    (name) =>
                                                      emoji.getUnicode(name) ??
                                                      name
                                                  )}
                                              </p>
                                            </ElementName>

                                            <ElementIconInfo>
                                              {DataTypeIcons[
                                                getGeneralInfoIcon("Duration")
                                              ] && (
                                                <img
                                                  src={
                                                    DataTypeIcons[
                                                      getGeneralInfoIcon(
                                                        "Duration"
                                                      )
                                                    ].icon
                                                  }
                                                  alt=""
                                                  style={{
                                                    width: "10px",
                                                    height: "10px",
                                                    marginRight: "2px",
                                                    ...DataTypeIcons[
                                                      getGeneralInfoIcon(
                                                        "Duration"
                                                      )
                                                    ].extraStyles,
                                                  }}
                                                />
                                              )}
                                              <span>{processName}</span>
                                            </ElementIconInfo>
                                          </ElementData>

                                          <DeleteColumnButton
                                            onClick={() =>
                                              handleCheck(importedColumn)
                                            }
                                          >
                                            <IoClose
                                              color="#0273E9"
                                              size={20}
                                            />
                                          </DeleteColumnButton>
                                        </ImportedColumnElement>
                                      )}
                                    </Draggable>
                                  );
                                }

                                return (
                                  <Draggable
                                    key={importedColumn}
                                    draggableId={importedColumn}
                                    index={index}
                                  >
                                    {({
                                      draggableProps,
                                      dragHandleProps,
                                      innerRef,
                                    }) => (
                                      <ImportedColumnElement
                                        key={importedColumn}
                                        ref={innerRef}
                                        $mb={
                                          index === importedColumns.length - 1
                                            ? ""
                                            : "6px"
                                        }
                                        {...draggableProps}
                                        {...dragHandleProps}
                                      >
                                        <IconContainer
                                          m="0"
                                          $color="#0273e9"
                                          cursorGrab
                                        >
                                          <DragDropIcon />
                                        </IconContainer>
                                        {DataTypeIcons[dataType] && (
                                          <IconContainer m="0">
                                            <img
                                              src={DataTypeIcons[dataType].icon}
                                              alt=""
                                              style={{
                                                width: "10px",
                                                height: "10px",
                                                ...DataTypeIcons[dataType]
                                                  .extraStyles,
                                              }}
                                            />
                                          </IconContainer>
                                        )}
                                        <ElementName>
                                          <p>
                                            {label
                                              .replace(/\n/gi, "&nbsp; \n")
                                              .replace(
                                                /:\w+:/gi,
                                                (name) =>
                                                  emoji.getUnicode(name) ?? name
                                              )}
                                          </p>
                                        </ElementName>

                                        <DeleteColumnButton
                                          onClick={() =>
                                            handleCheck(importedColumn)
                                          }
                                        >
                                          <IoClose color="#0273E9" size={20} />
                                        </DeleteColumnButton>
                                      </ImportedColumnElement>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </ImportedColumnElementWrapper>
                  </ImportedColumns>
                </>
              )}
            </ContentWrapper>
            {!loading && (
              <Footer>
                <FooterContent>
                  <ConfigChecks>
                    <ConfigCheckElement $cursor="pointer">
                      <StyledCheckbox
                        checked={configuration.ShowTotal}
                        onChange={() =>
                          setConfiguration({
                            ...configuration,
                            ShowTotal: !configuration.ShowTotal,
                          })
                        }
                      />
                      <p
                        onClick={() =>
                          setConfiguration({
                            ...configuration,
                            ShowTotal: !configuration.ShowTotal,
                          })
                        }
                      >
                        {ShowTotalsLabel}
                      </p>
                    </ConfigCheckElement>

                    <ConfigCheckElement $cursor="pointer">
                      <StyledCheckbox
                        checked={configuration.FirstColumStatic}
                        onChange={() =>
                          setConfiguration({
                            ...configuration,
                            FirstColumStatic: !configuration.FirstColumStatic,
                          })
                        }
                      />
                      <p
                        onClick={() =>
                          setConfiguration({
                            ...configuration,
                            FirstColumStatic: !configuration.FirstColumStatic,
                          })
                        }
                      >
                        {FixFirstColumnLabel}
                      </p>
                    </ConfigCheckElement>
                  </ConfigChecks>

                  <ButtonsContainer>
                    <Button
                      type="secondary"
                      style={{ width: "79px" }}
                      onClick={handleOnCancel}
                      disabled={loading || savingReport}
                    >
                      {CancelLabel}
                    </Button>
                    <Button
                      type="primary"
                      style={{ width: "180px", padding: "6px 8px" }}
                      onClick={() => {
                        idReport ? handleOnSave() : setSaveReport(true);
                      }}
                      disabled={
                        loading || savingReport || importedColumns.length === 0
                      }
                    >
                      {SaveConfigLabel}
                    </Button>
                  </ButtonsContainer>
                </FooterContent>
              </Footer>
            )}
          </StyledPaper>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfigReportModal;
