export const Languages = {
  ENG: {
    AreYouSureLabel: "Are you sure you want to exit? All changes will be lost",
    ConfirmLabel: "Confirm",
    CancelLabel: "Cancel",
    StartLabel: "Start",
    GlobalNotificationsLabel: "Global notifications",
    ProcessTitleLabel: "Following title",
    GlobalFieldsLabel: "Global fields",
    StartProcessLabel: (Name) => `Start ${Name}`,
    NewEmailLabel: "New email",
    ShareLabel: "Share",
    ShareTemplateLabel: "Share template",
    WantToShareLabel:
      "Do you want the user to be able to share your template to others?",
    ShareThroughLinkLabel: "Share through link",
    CopyLinkLabel: "Copy link",
    Title: "Processes",
    CreateNewTemplateLabel: "Create new process",
    CreateLabel: "Create",
    TemplateTitleLabel: "Process title",
    StartTooltipLabel: "Start process",
    ConfigureLabel: "Configure process",
    DuplicateLabel: "Duplicate process",
    DuplicateModalTitle: "Duplicate process",
    DuplicateModalDescription: (Name) =>
      `Are you sure you want to duplicate ${Name}?`,
    DuplicateModalDescriptionVardbs:
      "This process has linked databases, do you want to duplicate them as well? Select the databases whose structure you want to duplicate",
    AllCheck: "All",
    MoveToFolderLabel: "Move to folder",
    RemoveFromFolderLabel: "Remove from folder",
    ProcessLabel: "Process",
    FolderLabel: "Folder",
    FolderContentSavedInTeam:
      "The content of this folder will be saved in the team's main space",
    FolderContentSavedInFolder: (Folder) =>
      `The content of this folder will be saved in folder ${Folder}`,
    DeletLabel: "Delete",
    SelectCategoryLabel: "Select the categroy(ies) and choose the process",
    KnowMoreLabel: "Know more about the template and add it to your processes",
    CategoriesLabel: "Categories",
    SearchLabel: "Search",
    BlankProcessLabel: "Blank process",
    BlankProcessDescription:
      "Make a process from zero configuring the specific steps and tasks for your business",
    KnowMoreButtonLabel: "Know more",
    ProcessNamePlaceholder: "Write the name of the process...",
    InstalTemplateLabel: "Instal template",
  },
  ESP: {
    AreYouSureLabel: "¿Está seguro que desea salir?, se perderán los cambios",
    ConfirmLabel: "Confirmar",
    CancelLabel: "Cancelar",
    StartLabel: "Iniciar",
    GlobalNotificationsLabel: "Notificaciones globales",
    ProcessTitleLabel: "Título del seguimiento",
    GlobalFieldsLabel: "Campos globales",
    StartProcessLabel: (Name) => `Iniciar ${Name}`,
    NewEmailLabel: "Nuevo email",
    ShareLabel: "Compartir",
    ShareTemplateLabel: "Compartir plantilla",
    WantToShareLabel:
      "¿Quieres que el usuario pueda compartir a otros tu plantilla?",
    ShareThroughLinkLabel: "Compartir por medio de link",
    CopyLinkLabel: "Copiar link",
    Title: "Procesos",
    CreateNewTemplateLabel: "Crear nuevo proceso",
    CreateLabel: "Crear",
    TemplateTitleLabel: "Nombre del proceso",
    StartTooltipLabel: "Iniciar proceso",
    ConfigureLabel: "Configurar proceso",
    DuplicateLabel: "Duplicar proceso",
    DuplicateModalTitle: "Duplicar el proceso",
    DuplicateModalDescription: (processName) =>
      `¿Seguro que quieres duplicar ${processName}?`,
    DuplicateModalDescriptionVardbs:
      "Selecciona las bases de datos vinculadas en este proceso que también quieras duplicar",
    AllCheck: "Todas",
    MoveToFolderLabel: "Mover a carpeta",
    RemoveFromFolderLabel: "Sacar de la carpeta",
    ProcessLabel: "Proceso",
    FolderLabel: "Carpeta",
    FolderContentSavedInFolder: (Folder) =>
      `El contenido de esta carpeta se guardará en la carpeta ${Folder}`,
    FolderContentSavedInTeam:
      "El contenido de esta carpeta se guardará en el espacio principal de la empresa",
    DeleteLabel: "Eliminar",
    SelectCategoryLabel: "Selecciona la(s) categoria(s) y elige la plantilla",
    KnowMoreLabel: "Conoce más de la plantilla y añádela a tus procesos",
    CategoriesLabel: "Categorías",
    SearchLabel: "Buscar",
    BlankProcessLabel: "Proceso en blanco",
    BlankProcessDescription:
      "Elabora un proceso desde cero configurando los pasos y tareas específicos de tu negocio",
    KnowMoreButtonLabel: "Conoce más",
    ProcessNamePlaceholder: "Escribe el nombre del proceso...",
    InstalTemplateLabel: "Instalar plantilla",
    WantToSharePackageLabel:
      "¿Quieres que el usuario pueda compartir a otros tu paquete?",
    ToShareMultipleLabel:
      "Para compartir varios procesos de una empresa,  presiona la tecla Shift + los procesos que necesites y haz clic derecho",
  },
};
