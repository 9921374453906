import React from "react";
import { noop as NOOP } from "lodash";
import Modal from "@material-ui/core/Modal";
import { StyledRow, MainModal, SecondaryModal } from "./styles";

interface MultiModalProps {
  open?: boolean;
  onClose?: () => void;
  openSecondary?: boolean;
  secondaryModalContent?: React.ReactNode;
  height?: string;
  maxHeight?: string;
  width?: string;
  secondaryHeight?: string;
  secondaryWidth?: string;
  align?: "middle" | "top" | "bottom" | "stretch";
  mt?: string;
  padding?: string;
}

const MultiModal: React.FC<MultiModalProps> = ({
  children,
  open = false,
  openSecondary = false,
  onClose = NOOP,
  secondaryModalContent,
  height = "700px",
  maxHeight = "95vh",
  width = "786px",
  secondaryHeight = "700px",
  secondaryWidth = "449px",
  align = "middle",
  mt = "0px",
  padding = "0",
}) => {
  return (
    <Modal open={open} onClose={onClose} style={{ zIndex: 2 }}>
      <StyledRow
        justify="center"
        align={align}
        $mt={mt}
        onClick={() => onClose()}
      >
        <MainModal
          $height={height}
          $maxHeight={maxHeight}
          $width={width}
          $showExtra={openSecondary}
          $padding={padding}
          onClick={(e: any) => e.stopPropagation()}
        >
          {children}
        </MainModal>
        {openSecondary && (
          <SecondaryModal
            onClick={(e: any) => e.stopPropagation()}
            $secondaryHeight={secondaryHeight}
            $secondaryWidth={secondaryWidth}
            $maxHeight={maxHeight}
          >
            {secondaryModalContent}
          </SecondaryModal>
        )}
      </StyledRow>
    </Modal>
  );
};

export default MultiModal;
