export const Languages = {
  ENG: {
    PasswordsNotMacthError: "The passwords don't match",
    RegisterLabel: " Register",
    EmailPlaceholder: "Email...",
    PhoneNumberPlaceholder: "Phone number...",
    NamesPlaceholder: "Name...",
    LastNamePlaceholder: "Last name...",
    PasswordPlaceholder: "Password...",
    RepeatPasswordPlaceholder: "Repeat password...",
    HaveAccountLabel: "Already have an account?",
    LoginLabel: " Login ",
    PrivacyNotice: "Privacy notice",
    TermsAndCond: "Terms and condition",
    CheckText1: "By registering I accept",
    CheckText2: "by Geest",
    CheckText3: "and",
    TeamNameLabel: "Team name (Company)...",
    JobRoleLabel: "Job role",
    Collaborator: "Collaborator",
    TeamLeader: "Company leader",
    FieldRequired: "Required field",
    EmailValidation: "The email format is invalid",
    PhoneNumberValidation: "The phone number format is invalid",
  },
  ESP: {
    PasswordsNotMacthError: "Las contraseñas no coinciden",
    RegisterLabel: " Regístrate",
    EmailPlaceholder: "Correo electrónico...",
    PhoneNumberPlaceholder: "Número...",
    NamesPlaceholder: "Nombre...",
    LastNamePlaceholder: "Apellido...",
    PasswordPlaceholder: "Contraseña...",
    RepeatPasswordPlaceholder: "Repetir contraseña...",
    HaveAccountLabel: "¿Ya tienes cuenta?",
    LoginLabel: " Inicia sesión ",
    PrivacyNotice: "Aviso de privacidad",
    TermsAndCond: "Términos y condiciones",
    CheckText1: "Al registrarme acepto",
    CheckText2: "de Geest",
    CheckText3: "y los",
    TeamNameLabel: "Nombre de empresa (empresa)...",
    JobRoleLabel: "Rol en la empresa",
    Collaborator: "Colaborador",
    TeamLeader: "Líder de empresa",
    FieldRequired: "Campo requerido",
    EmailValidation: "El correo no tiene un formato válido",
    PhoneNumberValidation: "El número de teléfono no tiene un formato válido",
  },
};
