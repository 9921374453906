import React from "react";
import {
  Container,
  MembersContainer,
  MembersHeader,
  MembersHeaderItem,
  MembersHeaderItemFlex,
} from "./Style";
import { TeamMember, TeamRole } from "../../Teams.d";
import Member from "./Member";

interface MembersListProps {
  idTeam: string;
  canEdit: boolean;
  teamLeaderId: number | null;
  teamMembers: TeamMember[];
  teamRoles: TeamRole[];
  isConsultor: (userId: number) => boolean;
  setNoAccountUser: (email: string) => void;
  openRegisterNewUser: () => void;
  openExpellUser: () => void;
  setActionsUser: (user: TeamMember) => void;
  reload: () => void;
}

const MembersList: React.FC<MembersListProps> = ({
  idTeam,
  canEdit,
  teamLeaderId,
  teamMembers,
  teamRoles,
  isConsultor,
  setNoAccountUser,
  openRegisterNewUser,
  openExpellUser,
  setActionsUser,
  reload,
}) => {
  return (
    <Container>
      <MembersContainer>
        <MembersHeader>
          <MembersHeaderItem style={{ width: "350px" }}>
            Integrante
          </MembersHeaderItem>
          <MembersHeaderItemFlex>Roles</MembersHeaderItemFlex>
          {canEdit && (
            <MembersHeaderItem style={{ width: "152px" }}>
              Permisos
            </MembersHeaderItem>
          )}
          {canEdit && (
            <MembersHeaderItem style={{ width: "152px" }}>
              Acciones
            </MembersHeaderItem>
          )}
        </MembersHeader>

        {teamMembers &&
          teamMembers.map((teamMember, i) => {
            return (
              !isConsultor(teamMember.IdUser) && (
                <Member
                  key={`${teamMember.IdUser}-${i}`}
                  idTeam={idTeam}
                  teamMember={teamMember}
                  teamRoles={teamRoles}
                  teamLeaderId={teamLeaderId}
                  canEdit={canEdit}
                  isConsultor={isConsultor}
                  setNoAccountUser={setNoAccountUser}
                  openRegisterNewUser={openRegisterNewUser}
                  openExpellUser={openExpellUser}
                  setActionsUser={setActionsUser}
                  reload={reload}
                  teamMembers={teamMembers}
                />
              )
            );
          })}
      </MembersContainer>
    </Container>
  );
};

export default MembersList;
