import React from "react";
import { Circle, Rectangle, SkeletonContainer, Square } from "./Styles";

interface SkeletonCellProps {
  hasSquare?: boolean;
  hasCircle?: boolean;
  isExecutionName?: boolean;
}

const SkeletonCell: React.FC<SkeletonCellProps> = ({
  hasSquare,
  hasCircle,
  isExecutionName,
}) => {
  return (
    <SkeletonContainer isExecutionName={isExecutionName}>
      {hasSquare && <Square />}
      {hasCircle && <Circle />}
      <Rectangle />
    </SkeletonContainer>
  );
};

export default SkeletonCell;
