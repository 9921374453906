import styled from "styled-components";
import { Row, Switch } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
  overflow: auto;
`;

export const Content = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled.div`
  flex: 1;
`;

export const Header = styled(Row)`
  justify-content: space-between;
  font-family: "Gotham-Medium";
  font-size: 20px;
  margin-bottom: 2rem;
  color: #525151;
`;

export const SwitchContainer = styled.div`
  display: flex;
  font-family: "Gotham-Book";
  align-items: center;
  .ant-switch-checked {
    background: ${({ planColor }) => planColor};
  }
`;

export const StyledSwitch = styled(Switch)`
  background: #9a9a9a;
  margin: 0rem 0.7rem;
`;

export const SwitchText = styled.div`
  color: ${({ $active }) => ($active ? "#525151" : "#c6c6c691")};
  cursor: pointer;
  user-select: none;
`;

export const BackArrow = styled(LeftCircleOutlined)`
  margin-right: 0.5rem;
  font-size: larger;
  border-radius: 50%;
  :hover {
    background: #cacaca;
  }
`;
