import React from "react";
import styled from "styled-components";
import DynamicInput from "../../../../../components/DynamicInput";
import { DataTypes, FormatValueType } from "../DynamicCell.d";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #48505e;
`;

interface FormatValueComponentProps {
  dataType: DataTypes;
  formatValue: FormatValueType;
}

const FormatValueComponent: React.FC<FormatValueComponentProps> = ({
  dataType,
  formatValue,
}) => {
  return (
    <Container>
      <DynamicInput
        value={formatValue.Value}
        type={dataType}
        dataOrigin=""
        format={formatValue.Format}
        configuration=""
        fieldName=""
        isConsult={true}
        disabled={true}
        required={false}
        onChange={() => null}
        extraParams={{
          showNumberLabel: false,
          showFormat: true,
          formatedValueColor: "#48505e",
          formatedValueSize: "12px",
        }}
      />
    </Container>
  );
};

export default FormatValueComponent;
