import * as Yup from "yup";

const missingFieldMessage = (fieldName = "") => `Falta el campo "${fieldName}"`;

export const BillingInformationSchema = Yup.object({
  bussines: Yup.string().required(missingFieldMessage("Empresa")),
  rfc: Yup.string().required(missingFieldMessage("RFC")),
  address: Yup.string().required(missingFieldMessage("Dirección")),
  zip: Yup.string().required(missingFieldMessage("Código postal")),
  city: Yup.string().required(missingFieldMessage("Ciudad")),
  country: Yup.string().required(missingFieldMessage("País")),
  disclosure: Yup.boolean().isTrue("Debes acceptar términos y condiciones"),
});
