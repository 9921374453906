import React from "react";
import { IoIosClose } from "react-icons/io";
import { EditorState } from "draft-js";
import GeestTextEditor from "../../../GeestTextEditor";
import { FieldEditorOrigin } from "../../../GeestTextEditor/GeestTextEditor.d";
import { Element } from "../../PDFConfig.d";
import {
  DeleteIconContainer,
  DraggableItem,
  ResizableContent,
  StyledResizable,
} from "./styles";

interface TextNodeProps {
  currentElement: string | null;
  setCurrentElement: (id: string | null) => void;
  elementToConfig: string | null;
  setElementToConfig: (id: string | null) => void;
  componentKey: string;
  value: EditorState;
  element: Element;
  onChange: (newValue: EditorState) => void;
  setElmentOnFocus: (id: string) => void;
  onStart: () => void;
  onStop: () => void;
  onControlledDrag: (e: any, position: any) => void;
  onResizeStart: () => void;
  onResize: (e: any, data: any) => void;
  onResizeStop: () => void;
  onDeleteElement: () => void;
  fieldEditorOrigin: FieldEditorOrigin[];
  isMovingElements: boolean;
}

const TextNode: React.FC<TextNodeProps> = ({
  currentElement,
  setCurrentElement,
  elementToConfig,
  setElementToConfig,
  componentKey,
  value,
  element,
  onChange,
  setElmentOnFocus,
  onStart,
  onStop,
  onControlledDrag,
  onResizeStart,
  onResize,
  onResizeStop,
  onDeleteElement,
  fieldEditorOrigin,
  isMovingElements,
}) => {
  const dragHandlers = { onStart, onStop };
  const isSelected = element.id === currentElement;
  const isConfig = element.id === elementToConfig;

  const hanldeResize = (e: any, data: any) => {
    if (!isSelected) return false;
    onResize(e, data);
  };

  return (
    <DraggableItem
      bounds="parent"
      position={element.position}
      {...dragHandlers}
      onDrag={onControlledDrag}
    >
      <StyledResizable
        width={element.boxSize.width}
        height={element.boxSize.height}
        selected={isSelected}
        minConstraints={[80, 40]}
        onResizeStart={onResizeStart}
        onResize={hanldeResize}
        onResizeStop={onResizeStop}
      >
        <ResizableContent
          key={componentKey}
          width={element.boxSize.width}
          height={element.boxSize.height}
          selected={isSelected}
          onClick={(e) => e.stopPropagation()}
        >
          <GeestTextEditor
            editorState={value}
            onEditorStateChange={onChange}
            onFocus={() => {
              setElmentOnFocus(element.id);
              setCurrentElement(element.id);
              setElementToConfig(element.id);
            }}
            onBlur={() => setElmentOnFocus("")}
            editorBorder
            PermanentFocusBorder={isConfig}
            smallInnerPadding={element.boxSize.height < 50}
            popupPosition={element.position.y < 70 ? "bottom" : "top"}
            popUpToolbar
            dynamicHeight
            toolbarConfig={{ field: true }}
            fieldEditorOrigin={fieldEditorOrigin}
            fieldsPopoverStyles={{
              zoom: "75%",
            }}
            userSelectOnlyOnFocus
            readOnly={isMovingElements}
          />

          {isSelected && (
            <DeleteIconContainer onClick={onDeleteElement}>
              <IoIosClose size={20} />
            </DeleteIconContainer>
          )}
        </ResizableContent>
      </StyledResizable>
    </DraggableItem>
  );
};

export default TextNode;
