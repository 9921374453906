import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { languages } from "../Controls/dicitonary";
import { CloseIcon } from "../../hoverIcons";
import { Button } from "../../../GeestUI";

const StyledModal = styled(Modal)`
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

const Title = styled.div`
  flex: 1;
  overflow: hidden;
  font-size: 16px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  font-size: 14px;
`;

const Paragraph = styled.p`
  margin: 0;
  text-align: center;
  color: #48505e;
  padding: 0 65px;
  margin-bottom: 12px;
  font-family: Gotham-Bold;
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

interface DeleteOriginElementProps {
  onCancel: () => void;
  onDelete: () => void;
}

const DeleteOriginElement: React.FC<DeleteOriginElementProps> = ({
  onCancel,
  onDelete,
}) => {
  const {
    DeleteElementTitle,
    DeleteElementDescription,
    CancelLabel,
    DeleteLabel,
  } = languages["ESP"];

  return (
    <StyledModal
      open
      footer={null}
      closable={false}
      centered
      width="385px"
      onCancel={onCancel}
      bodyStyle={{
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Gotham-Book",
        padding: "12px",
      }}
    >
      <Header>
        <Title>{DeleteElementTitle}</Title>
        <CloseIcon size={20} onClick={onCancel} color="#48505e" />
      </Header>
      <Content>
        <Paragraph>{DeleteElementDescription}</Paragraph>
        <ButtonsRow>
          <Button type="secondary" onClick={onCancel}>
            {CancelLabel}
          </Button>
          <Button type="primary" danger onClick={onDelete}>
            {DeleteLabel}
          </Button>
        </ButtonsRow>
      </Content>
    </StyledModal>
  );
};

export default DeleteOriginElement;
