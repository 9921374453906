import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Space, Spin } from "antd";
import _, { noop as NOOP } from "lodash";
import { UploadFile } from "antd/es/upload/interface";
import printJS from "print-js";
import Modal from "../../../../components/Modal";
import { Languages } from "../Dictionary";
import { ReloadFileButton, ErrorContainer } from "../Style";
import { Button } from "../../../../GeestUI";

interface PreviewFrameProps {
  PreviewURL: string;
  handleEsc: () => void;
}

const PreviewFrame: React.FC<PreviewFrameProps> = ({
  PreviewURL,
  handleEsc,
}) => {
  const [Loading, setLoading] = useState(true);
  const [Error, setError] = useState(false);
  const loadRef = useRef(Loading);
  loadRef.current = Loading;

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      handleEsc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    setTimeout(() => {
      if (!loadRef.current) return;
      setError(true);
      setLoading(false);
    }, 15000);
  }, [loadRef, Loading]);

  const onError = () => {
    setError(true);
    setLoading(false);
  };

  const onRefresh = () => {
    setLoading(true);
    setError(false);
  };

  return (
    <div
      style={{
        position: "relative",
        height: "65vh",
        width: "100%",
      }}
    >
      {Loading && (
        <Row
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            background: "#ffffff73",
          }}
          justify="center"
          align="middle"
        >
          <Spin size="large" tip="Cargando" />
        </Row>
      )}
      {Error ? (
        <Row
          style={{
            height: "100%",
            width: "100%",
            background: "#ffffff73",
          }}
          justify="center"
          align="middle"
        >
          <ErrorContainer>
            No se pudo cargar el archivo
            <Row justify="center">
              <ReloadFileButton onClick={onRefresh} />
            </Row>
            Reintentar
          </ErrorContainer>
        </Row>
      ) : (
        <iframe
          title="PreviewFile"
          style={{
            width: "100%",
            height: "100%",
          }}
          src={`https://docs.google.com/gview?url=${PreviewURL}&embedded=true`}
          onLoad={() => setLoading(false)}
          onError={onError}
        />
      )}
    </div>
  );
};

interface PreviewImgProps {
  PreviewURL: string;
  handleEsc: () => void;
}

const PreviewImg: React.FC<PreviewImgProps> = ({ PreviewURL, handleEsc }) => {
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      handleEsc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <div
      style={{
        width: "100%",
        height: "65vh",
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img
        src={PreviewURL}
        alt="Preview"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
      />
    </div>
  );
};

interface PreviewFileProps {
  file: UploadFile<any> | null;
  closeModal: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleEsc?: () => void;
}

const PreviewFile: React.FC<PreviewFileProps> = ({
  file,
  closeModal,
  handleEsc = NOOP,
}) => {
  if (!Boolean(file?.url)) return null;
  const { DownloadLabel, VizualizeLabel, PrintLabel } = Languages["ESP"];

  const useIframe =
    file?.url &&
    !["jpeg", "jpg", "png"].includes(_.toLower(file.url.split(".").pop()));

  const printable =
    file?.url &&
    ["jpeg", "jpg", "png", "pdf"].includes(
      _.toLower(file.url.split(".").pop())
    );

  const PreviewComponent = useIframe ? PreviewFrame : PreviewImg;

  const handleDownload = (file: UploadFile<any>) => {
    const { url = "", name = "" } = file;

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(new Blob([blob]));
        const aTag = document.createElement("a");
        aTag.href = blobUrl;
        aTag.setAttribute("download", name);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  const handlePrint = (file: UploadFile<any>): void => {
    const { url = "" } = file;
    if (useIframe) {
      // PDF
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = window.URL.createObjectURL(new Blob([blob]));
          printJS(blobUrl);
        });
    } else {
      // Images
      printJS(url, "image");
    }
  };

  const Footer = [
    <Space key="1">
      {useIframe && (
        <Button
          type="primary"
          href={`https://docs.google.com/viewerng/viewer?url=${file?.url}`}
          //@ts-ignore
          target="_blank"
          download
        >
          {VizualizeLabel}
        </Button>
      )}
      {printable && (
        <Button
          type="primary"
          onClick={() => handlePrint(file || ({} as UploadFile<any>))}
        >
          {PrintLabel}
        </Button>
      )}
      <Button
        type="primary"
        onClick={() => handleDownload(file || ({} as UploadFile<any>))}
      >
        {DownloadLabel}
      </Button>
    </Space>,
  ];

  return (
    <Modal title="" visible onCancel={closeModal} width="90%" footer={Footer}>
      <PreviewComponent PreviewURL={file?.url || ""} handleEsc={handleEsc} />
    </Modal>
  );
};

export default PreviewFile;
