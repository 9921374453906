import React, { useState } from "react";
import { Row, Space } from "antd";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import { AiOutlineClockCircle } from "react-icons/ai";
import _ from "lodash";
import { SchedulePageProps } from "./SubfluxEditor.d";
import { StyledTitle } from "../TaskEditor/styles";
import { StyledInput, StyledSwitch } from "../../styles";
import GeestSelect from "../../../../../GeestUI/GeestSelect";
import {
  timeToAddConfigOptions,
  timeToAddFormatOptions,
} from "../TaskEditor/SchedulePage/constants";
import {
  TimeConfig,
  TimeFormat,
  assertTimeToAdd,
  backOptionToSelectOption,
  decodeTimeToAdd,
  encodeTimeToAdd,
} from "../TaskEditor/SchedulePage/helpers";

const SchedulePage: React.FC<SchedulePageProps> = ({
  subflux,
  onChange,
  saveSubflux,
  onChangeAndSave,
  timesPreconf,
  timesRefDate,
}) => {
  const [scheduleStart, setScheduleStart] = useState<boolean>(
    !(
      (subflux.TimeToAdd === "" || subflux.TimeToAdd === "0") &&
      subflux.RefDate === "" &&
      subflux.Preconf === ""
    )
  );

  const {
    timeAmount: startTimeAmount,
    timeFormat: startTimeFormat,
    timeConfig: startTimeConfig,
  } = decodeTimeToAdd(_.toNumber(subflux.TimeToAdd || "0"));

  const handleSetScheduleStart: SwitchChangeEventHandler = (active) => {
    setScheduleStart(active);
    if (!active) {
      onChangeAndSave({
        ...subflux,
        Preconf: "",
        RefDate: "",
        TimeToAdd: "",
      });
    }
    if (active && subflux.TimeToAdd === "") {
      onChangeAndSave({
        ...subflux,
        Preconf: timesPreconf[0].Value,
        RefDate: timesRefDate[0].Value,
        TimeToAdd: "0",
      });
    }
  };
  const onChangeStartTimeAmount: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) =>
    onChange({
      ...subflux,
      TimeToAdd: _.toString(
        encodeTimeToAdd({
          timeAmount: assertTimeToAdd(e.target.value),
          timeFormat: startTimeFormat,
          timeConfig: startTimeConfig,
        })
      ),
    });
  const onChangeStartTimeFormat = (timeFormat: TimeFormat) =>
    onChangeAndSave({
      ...subflux,
      TimeToAdd: _.toString(
        encodeTimeToAdd({
          timeAmount: startTimeAmount,
          timeFormat,
          timeConfig: startTimeConfig,
        })
      ),
    });
  const onChangeStartTimeConfig = (timeConfig: TimeConfig) => {
    onChangeAndSave({
      ...subflux,
      TimeToAdd: _.toString(
        encodeTimeToAdd({
          timeAmount: startTimeAmount,
          timeFormat: startTimeFormat,
          timeConfig,
        })
      ),
    });
  };
  const onChangeStartPreconf = (Preconf: string) =>
    onChangeAndSave({
      ...subflux,
      Preconf,
    });
  const onChangeStartRefDate = (RefDate: string) =>
    onChangeAndSave({
      ...subflux,
      RefDate,
    });

  return (
    <div>
      <Space align="center" style={{ marginBottom: "13px" }}>
        <StyledTitle>Programar inicio</StyledTitle>
        <StyledSwitch
          checked={scheduleStart}
          onChange={handleSetScheduleStart}
        />
      </Space>
      {scheduleStart && (
        <Space direction="vertical">
          <Row align="middle">
            <AiOutlineClockCircle
              color="#1F9334"
              size={16}
              style={{ marginRight: "5px" }}
            />
            <StyledTitle>Inicio</StyledTitle>
          </Row>
          <Space align="center">
            <StyledTitle>en</StyledTitle>
            <StyledInput
              value={startTimeAmount}
              onChange={onChangeStartTimeAmount}
              pattern="^[0-9]*$"
              onBlur={saveSubflux}
            />
            <GeestSelect
              value={startTimeFormat}
              onChange={onChangeStartTimeFormat}
              options={timeToAddFormatOptions}
              placeholderSelect=""
              $width="124px"
            />
            <GeestSelect
              value={subflux.Preconf}
              onChange={onChangeStartPreconf}
              options={backOptionToSelectOption(timesPreconf)}
              placeholderSelect=""
              $width="124px"
            />
          </Space>
          <Space align="center">
            <GeestSelect
              value={startTimeConfig}
              onChange={onChangeStartTimeConfig}
              options={timeToAddConfigOptions}
              placeholderSelect=""
              $width="110px"
            />
            <StyledTitle>de</StyledTitle>
            <GeestSelect
              value={subflux.RefDate}
              onChange={onChangeStartRefDate}
              options={backOptionToSelectOption(timesRefDate)}
              placeholderSelect=""
              $width="192px"
            />
          </Space>
        </Space>
      )}
    </div>
  );
};

export default SchedulePage;
