import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useReactFlow } from "reactflow";
import { Row } from "antd";
import {
  GetConditionDetailsAndConfigParams,
  Condition,
  UpdateConditionDetails,
  CompareWithField,
  Option,
} from "./ConditionEditor.d";
import { NodeEditorProps } from "../NodeEditor.d";
import Modal from "../../../../../components/Modal";
import { useFetch, useMutation } from "../../../../../hooks";
import GeestSelect from "../../../../../GeestUI/GeestSelect";
import { ConditionPort } from "../../Flow.d";
import { deleteEdgesByIds, updateConditionNode } from "../../helpers";
import { StyledInput } from "../../styles";
import { Description, Label, ConfContainer } from "./styles";
import { getDataTypeIcon } from "../../../helpers";
import { ConfiguratorContext } from "../../../Configurator";

const getConditionPorts = (
  compareWithFields: CompareWithField[],
  IdField: number
): ConditionPort[] => {
  const { DataType, CsvDataOrigin = "" } = _.find(compareWithFields, {
    IdField,
  })!;
  if (["select", "multi_select"].includes(DataType)) {
    const splittedDataOrigin = CsvDataOrigin.split("[-|div|-]");
    return splittedDataOrigin.map((value, i) => ({
      Port: `OutRight${i}`,
      PortLabel: value,
    }));
  }
  return [
    {
      Port: "OutRight0",
      PortLabel: "Si",
    },
    {
      Port: "OutRight1",
      PortLabel: "No",
    },
  ];
};

// Do not allow empty values for other than select type
const isValidForm = (condition: Condition): boolean =>
  !(
    !["select", "multi_select"].includes(condition.DataTypeComparison || "") &&
    ((_.isEmpty(condition.ReferenceValue) &&
      condition.Operator !== "is_empty") ||
      _.isEmpty(condition.Operator))
  );

const ConditionEditor: React.FC<NodeEditorProps> = ({ node, onClose }) => {
  const { getNodes, setNodes, getEdges, setEdges } = useReactFlow();
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const [condition, setCondition] = useState<Condition>({
    IdNode: "",
    NodeTitle: "",
    Operator: "equal_to",
  });
  const { refreshProcessFlow } = useContext(ConfiguratorContext);
  const showComparisonTypes = !["", "select", "multi_select"].includes(
    condition.DataTypeComparison ?? ""
  );

  const { data } = useFetch<GetConditionDetailsAndConfigParams>({
    func: "Ver2-Configurator-gcdcp",
    args: {
      IdTeam,
      IdProcessTemplate,
      IdNode: node.id,
    },
    onSuccess: ({ Condition }) => setCondition(Condition),
  });

  const [updateConditionDetails] = useMutation<UpdateConditionDetails>({
    func: "Ver2-Configurator-ucd",
    onSuccess: (response) => {
      setNodes(updateConditionNode(getNodes(), response));
      setEdges(deleteEdgesByIds(getEdges(), response.DeletedLinksIds));
      refreshProcessFlow();
    },
  });

  const saveCondition = (condition: Condition): void => {
    if (isValidForm(condition))
      updateConditionDetails({
        args: {
          IdTeam,
          IdProcessTemplate,
          IdNode: node.id,
          Condition: {
            ...condition,
            // ReferenceValue must be "" for "select"
            ReferenceValue: ["select", "multi_select"].includes(
              condition.DataTypeComparison || ""
            )
              ? ""
              : condition.Operator === "is_empty"
              ? ""
              : condition.ReferenceValue,
            // Operator must be "equal_to" for "select"
            Operator: ["select", "multi_select"].includes(
              condition.DataTypeComparison || ""
            )
              ? data!.ComparisonTypes.select[0].Value
              : condition.Operator,
            Ports: getConditionPorts(
              data!.CompareWithFields,
              condition.IdField!
            ),
          },
        },
      });
  };

  const setAndSaveCondition = (condition: Condition): void => {
    saveCondition(condition);
    setCondition(condition);
  };

  const compareWithOptions = _.map(
    data?.CompareWithFields || [],
    ({ IdField, Label, DataType }) => ({
      value: IdField,
      label: (() => {
        const Icon = getDataTypeIcon(DataType);
        return (
          <Row align="middle" style={{ fontFamily: "Gotham-book" }}>
            <Icon style={{ marginRight: "5px" }} /> {Label}
          </Row>
        );
      })(),
      searchableValues: Label,
    })
  );

  const operators = (
    _.get(
      data,
      "ComparisonTypes." + condition.DataTypeComparison,
      []
    ) as Option[]
  ).map(({ Label, Value }) => ({ value: Value, label: Label }));

  const handleOnChangeField = (IdField: number) => {
    const { DataType } = _.find(data?.CompareWithFields, { IdField })!;
    let newCondition: Condition = {
      ...condition,
      IdField,
      DataTypeComparison: DataType,
    };
    const operators = (
      _.get(data, "ComparisonTypes." + DataType, []) as Option[]
    ).map(({ Label, Value }) => Value);
    if (!operators.includes(condition.Operator || "")) {
      newCondition.Operator = undefined;
      newCondition.ReferenceValue = "";
    }
    setAndSaveCondition(newCondition);
  };
  const handleOnChangeOperator = (value: string) => {
    const newCondition: Condition = {
      ...condition,
      Operator: value,
    };
    setAndSaveCondition(newCondition);
  };
  const handleOnChangeReferenceValue = (value: string) => {
    const newCondition: Condition = {
      ...condition,
      ReferenceValue: value,
    };
    setCondition(newCondition);
  };

  return (
    <Modal
      title="Configuración de condición"
      centered
      width={432}
      onCancel={onClose}
      contentStyles={{
        padding: "0 12px",
      }}
    >
      <Description>
        Selecciona el campo de formulario que requieras para obtener sus
        opciones dentro de esta condición
      </Description>
      <Label>Cuando el campo de formulario</Label>
      <GeestSelect
        value={condition.IdField}
        options={compareWithOptions}
        onChange={handleOnChangeField}
        placeholderSelect={"Selecciona una opción..."}
        valueNecesary
        style={{ marginBottom: "12px" }}
        autoOpen
      />
      {showComparisonTypes && (
        <ConfContainer>
          <GeestSelect
            value={condition.Operator}
            options={operators}
            onChange={handleOnChangeOperator}
            $width={condition.Operator !== "is_empty" ? "210px" : "100%"}
            placeholderSelect={"Selecciona una opción..."}
            valueNecesary
          />
          {condition.Operator !== "is_empty" && (
            <StyledInput
              value={condition.ReferenceValue}
              onChange={(e) => handleOnChangeReferenceValue(e.target.value)}
              onBlur={() => saveCondition(condition)}
              placeholder="Valor de referencia..."
              type="text"
              style={{ width: "166px" }}
            />
          )}
        </ConfContainer>
      )}
    </Modal>
  );
};

export default ConditionEditor;
