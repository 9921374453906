import React, { useState } from "react";
import { AlertTypes } from ".";

export const AlertContext = React.createContext(null);

const codeTypes = {
  Danger: AlertTypes.Danger,
  ErrorPaymentNotCompleted: AlertTypes.Warning,
  active_free_cardless_trial: AlertTypes.Trial,
  active_bonus_trial: AlertTypes.Trial,
  canceled_bonus_trial: AlertTypes.Trial,
  immediate_payment_required: AlertTypes.PaymentRequired,
};

const AppAlertContext = ({ children }) => {
  const [AlertType, setAlertType] = useState(AlertTypes.Warning);
  const [AlertInfo, setAlertInfo] = useState({});
  const [IsActive, setIsActive] = useState(false);

  // Use this function inside the Context to open new alerts.
  const setAlert = (alertInfo = {}) => {
    setAlertInfo(alertInfo);
    if (!codeTypes[alertInfo.Value]) return;
    setAlertType(codeTypes[alertInfo.Value]);
    setTimeout(() => {
      setIsActive(true);
    }, 200);
  };

  const AlertData = {
    AlertType,
    AlertInfo,
    IsActive,
  };

  return (
    <AlertContext.Provider
      value={{
        setAlert,
        setIsActive,
        AlertTypes,
        AlertData,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AppAlertContext;
