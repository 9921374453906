import React, { useState } from "react";
import { Row } from "antd";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import _ from "lodash";
import { AiOutlineInfoCircle, AiOutlinePlus } from "react-icons/ai";
import { Priority, PriorityPopoverProps } from "../AutomationConfig.d";
import { Button, Tag } from "../../../../../../GeestUI";
import { useFetch, useMutation } from "../../../../../../hooks";
import EditPriority from "../../../../../Pendings/DetailPending/MicroComponents/EditPriority";
import { PriorityItemContainer } from "../../../../../Pendings/DetailPending/Popups/styles";
import { CloseIcon, EditIcon } from "../../../../../../components/hoverIcons";

const DroppableContainer: React.FC = ({ children }) => (
  <Droppable droppableId="1">
    {(provided) => (
      <div ref={provided.innerRef} {...provided.droppableProps}>
        {children}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

const DraggableItem: React.FC<{ draggableId: string; index: number }> = ({
  children,
  draggableId,
  index,
}) => (
  <Draggable draggableId={draggableId} index={index}>
    {(provided) => (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        {children}
      </div>
    )}
  </Draggable>
);

const PriorityPopover: React.FC<PriorityPopoverProps> = ({
  setVisible,
  idTaskTemplate,
  onChange,
  taskPriority,
}) => {
  const [priorities, setPriorities] = useState<Priority[]>([]);
  const [editingPriorityId, setEditingPriorityId] = useState<number | null>(
    null
  );

  const { reload: reloadPriorities } = useFetch<Priority[]>({
    func: "Ver2-Configurator-gtp",
    args: {
      IdTaskTemplate: idTaskTemplate,
    },
    onSuccess: setPriorities,
  });

  const [insertTaskPriority] = useMutation<[]>({
    func: "Ver2-Configurator-itp",
    onSuccess: () => {
      reloadPriorities();
      setEditingPriorityId(null);
    },
  });
  const [updateTaskPriority] = useMutation<[]>({
    func: "Ver2-Configurator-utp",
    onSuccess: () => {
      reloadPriorities();
      setEditingPriorityId(null);
    },
    onError: () => reloadPriorities(),
  });
  const [deleteTaskPriority] = useMutation<[]>({
    func: "Ver2-Configurator-dtp",
    onSuccess: () => {
      reloadPriorities();
      setEditingPriorityId(null);
    },
  });

  const handleOnSelectPriority = (newPriority: Priority): void => {
    const removePriority =
      newPriority.IdTaskPriority === taskPriority?.IdTaskPriority;
    onChange(removePriority ? null : newPriority);
  };

  const handleOnCreatePriority = ({ Title, Color }: Priority): void =>
    insertTaskPriority({
      args: { IdTaskTemplate: idTaskTemplate, Title, Color },
    });
  const handleOnUpdatePriority = (TaskPriority: Priority): void =>
    updateTaskPriority({
      args: { IdTaskTemplate: idTaskTemplate, TaskPriority },
      shippedData: { newPriority: TaskPriority },
    });
  const handleOnDeletePriority = ({ IdTaskPriority }: Priority): void =>
    deleteTaskPriority({
      args: { IdTaskTemplate: idTaskTemplate, IdTaskPriority },
    });
  const handleOnReOrder = ({ source, destination }: DropResult): void => {
    if (destination) {
      const { index: sourceIndex } = source;
      const { index: destinationIndex } = destination;
      let newPriorities = [...priorities];
      // get MovedItem using sourceIndex
      let [movedItem] = _.pullAt(newPriorities, sourceIndex);
      // insert MovedItem using destinationIndex
      newPriorities.splice(destinationIndex, 0, movedItem);
      // reset positions
      newPriorities = newPriorities.map((priority, index) => ({
        ...priority,
        Position: index + 1,
      }));
      // set positions
      setPriorities([...newPriorities]);
      handleOnUpdatePriority(newPriorities[destinationIndex]);
    }
  };

  return (
    <div>
      <Row align="middle" justify="space-between">
        <Row
          align="middle"
          style={{
            flex: 1,
            fontSize: "16px",
            fontFamily: "Gotham-Bold",
            color: "rgba(72, 80, 94, 1)",
            marginBottom: "1rem",
          }}
        >
          Prioridad de la tarea
          <AiOutlineInfoCircle
            color="#727E8B"
            size={15}
            style={{ marginLeft: "10px" }}
          />
        </Row>
        <CloseIcon onClick={() => setVisible(false)} />
      </Row>
      {editingPriorityId === 0 ? (
        <EditPriority
          disableDelete
          onSubmit={handleOnCreatePriority}
          onCancel={() => setEditingPriorityId(null)}
        />
      ) : (
        <Row justify="center">
          <Button
            type="secondary"
            Icon={AiOutlinePlus}
            onClick={() => setEditingPriorityId(0)}
            size="large"
          >
            Nueva prioridad
          </Button>
        </Row>
      )}
      <div style={{ marginTop: "20px" }}>
        <DragDropContext
          onBeforeDragStart={() => setEditingPriorityId(null)}
          onDragEnd={handleOnReOrder}
        >
          <DroppableContainer>
            {priorities.map((priority, index) => (
              <DraggableItem
                draggableId={String(priority.IdTaskPriority)}
                index={index}
                key={priority.IdTaskPriority}
              >
                {editingPriorityId === priority.IdTaskPriority ? (
                  <EditPriority
                    defaultValue={priority}
                    disableDelete={
                      priority.IdTaskPriority === taskPriority?.IdTaskPriority
                    }
                    onSubmit={handleOnUpdatePriority}
                    onDelete={handleOnDeletePriority}
                    onCancel={() => setEditingPriorityId(null)}
                  />
                ) : (
                  <Row
                    style={{ width: "100%", marginBottom: "10px" }}
                    align="middle"
                  >
                    <PriorityItemContainer
                      align="middle"
                      $selected={
                        priority.IdTaskPriority === taskPriority?.IdTaskPriority
                      }
                      onClick={() => handleOnSelectPriority(priority)}
                    >
                      <Tag color={priority.Color}>{priority.Title}</Tag>
                    </PriorityItemContainer>
                    <EditIcon
                      style={{ marginLeft: "10px" }}
                      onClick={() =>
                        setEditingPriorityId(priority.IdTaskPriority)
                      }
                      filled
                    />
                  </Row>
                )}
              </DraggableItem>
            ))}
          </DroppableContainer>
        </DragDropContext>
      </div>
    </div>
  );
};

export default PriorityPopover;
