import { useState, useContext } from "react";
import { noop as NOOP } from "lodash";
import apoloServer from "../api/apoloServer";
import { MessagesContext } from "../components/AppMessages";

interface useApoloMutationParams<Type> {
  route: string;
  method: string;
  onSuccess?: (response: Type, shippedData?: any) => void;
  onError?: (args: any, errorMessage: string) => void;
  conf?: {
    showMessageOnFail?: boolean;
  };
}

interface onCallApoloMutationParams {
  body?: { [key: string]: any };
  params?: { [key: string]: any };
  errorArgs?: { [key: string]: any };
  shippedData?: { [key: string]: any };
}

type loading = boolean;
type callMutation = (params: onCallApoloMutationParams) => void;
type hookResponse = [callMutation, loading];

const useApoloMutation = <Type>({
  route,
  method,
  onSuccess = NOOP,
  onError = NOOP,
  conf,
}: useApoloMutationParams<Type>): hookResponse => {
  const [loading, setLoading] = useState(false);
  const { showMessage } = useContext(MessagesContext);

  const showMessageOnFail = conf?.showMessageOnFail ?? true;

  const onCallApoloMutation = ({
    params,
    body,
    errorArgs,
    shippedData,
  }: onCallApoloMutationParams): void => {
    setLoading(true);
    apoloServer(route, method, params, body)
      .then((response: any) => {
        if (
          response.Request_Error ||
          ["error", "warning"].includes(response.Type)
        ) {
          showMessage(
            response.Message || response.Request_Error,
            response.Type || "error"
          );
          setLoading(false);
          onError(errorArgs, response.Message || response.Request_Error);
        } else {
          const { type, message, data } = response;
          showMessage(message, type);
          setLoading(false);
          onSuccess(data, shippedData);
        }
      })
      .catch((err: any) => {
        if (showMessageOnFail) showMessage("API Error", "error");
        console.error(err);
        setLoading(false);
        onError(errorArgs, "API Error");
      });
  };

  return [onCallApoloMutation, loading];
};

export default useApoloMutation;
