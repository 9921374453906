import React, { useState, useRef, useEffect } from "react";
import { Button } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";
import { useHistory } from "react-router-dom";
import Modal from "../../../../components/Modal";
import {
  InputStyled,
  ModalBoldLabel,
  DeleteModalDescription,
  DeleteModalButtonContainer,
} from "../Style";
import { trim } from "lodash";

interface LeaveModalProps {
  idTeam: string;
  name: string;
  onClose: () => void;
}

const DeleteTeamModal: React.FC<LeaveModalProps> = ({
  idTeam,
  name,
  onClose,
}) => {
  const history = useHistory();
  const ref = useRef<HTMLInputElement>(null);
  const [teamName, setTeamName] = useState<string>("");

  useEffect(() => {
    ref?.current?.focus();
  }, []);

  const [deleteTeam, deletingTeam] = useMutation<[]>({
    func: "Ver2-Team-dt",
    onSuccess: () => {
      onClose();
      history.push("/home/teams");
    },
  });

  const handleDeleteTeam = () => {
    if (trim(teamName) === trim(name)) deleteTeam({ args: { IdTeam: idTeam } });
  };

  return (
    <Modal
      open
      onCancel={onClose}
      title="Eliminar"
      width="432px"
      contentStyles={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <ModalBoldLabel>¿Seguro que deseas eliminar esta empresa?</ModalBoldLabel>
      <DeleteModalDescription>
        Esta acción es irreversible y eliminará toda la información relacionada
        a la empresa, sus procesos, sus bases de datos y seguimientos
        ejecutados. Si estás seguro que deseas continuar, por favor ingresa el
        nombre de la empresa:
        <div style={{ color: "rgba(219, 35, 44, 1)" }}>{name}</div>
      </DeleteModalDescription>
      <InputStyled
        value={teamName}
        onChange={(e) => setTeamName(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleDeleteTeam();
          }
        }}
        placeholder="Escribe aquí..."
        ref={ref}
      />
      <DeleteModalButtonContainer>
        <Button type="secondary" onClick={onClose} loading={deletingTeam}>
          Cancelar
        </Button>
        <Button
          type="primary"
          onClick={handleDeleteTeam}
          loading={deletingTeam}
          disabled={teamName !== name}
          danger
        >
          Eliminar
        </Button>
      </DeleteModalButtonContainer>
    </Modal>
  );
};

export default DeleteTeamModal;
