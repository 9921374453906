const prevState = {
  blocks: [
    {
      key: "2knnt",
      text: "¡Hola!, mi nombre es  [[| FIELD:0 |]] y este es un estado precargado.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 0,
          length: 6,
          style: "BOLD",
        },
        {
          offset: 51,
          length: 17,
          style: "BOLD",
        },
      ],
      entityRanges: [
        {
          offset: 21,
          length: 17,
          key: 0,
        },
      ],
      data: {},
    },
    {
      key: "f832l",
      text: "Como puedes ver, tanto los estilos como los campos: [[| FIELD:1 |]] se mantienen.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 51,
          length: 17,
          key: 1,
        },
      ],
      data: {},
    },
    {
      key: "1a2jq",
      text: "Incluso si los campos tienen un valor null, esto no afecta a la primera carga: [[| FIELD:2 |]] .",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 38,
          length: 4,
          style: "ITALIC",
        },
      ],
      entityRanges: [
        {
          offset: 78,
          length: 17,
          key: 2,
        },
      ],
      data: {},
    },
    {
      key: "5upvp",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "7rp48",
      text: 'Puedes observar en el raw JSON que los ID dentro de el texto del bloque no coincide con el del campo, esto se debe a que ese ID es un contador de campos generados por instancia del editor. De cualquier forma, el ID del field sigue existiendo en la metadata del entity map.\n\nHay varias formas en que se puede acceder a los valores de los campos. Tanto utilizando el offset dentro del bloque utilizando "entityRanges" y desde ahí accediendo con el key al entityMap o bien utilizando el siguiente regex en el contenido: /\\[\\[\\|\\s(FIELD:(null|\\d+))\\s\\|\\]\\]/g para obtener el IdGeestField y con el el entity del entityMap y por lo tanto, el IdFieldSelected.',
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 22,
          length: 8,
          style: "ITALIC",
        },
        {
          offset: 402,
          length: 12,
          style: "ITALIC",
        },
        {
          offset: 453,
          length: 9,
          style: "ITALIC",
        },
        {
          offset: 571,
          length: 12,
          style: "ITALIC",
        },
        {
          offset: 607,
          length: 9,
          style: "ITALIC",
        },
        {
          offset: 636,
          length: 15,
          style: "ITALIC",
        },
        {
          offset: 22,
          length: 8,
          style: "BOLD",
        },
        {
          offset: 402,
          length: 12,
          style: "BOLD",
        },
        {
          offset: 453,
          length: 10,
          style: "BOLD",
        },
        {
          offset: 517,
          length: 37,
          style: "BOLD",
        },
        {
          offset: 571,
          length: 13,
          style: "BOLD",
        },
        {
          offset: 607,
          length: 10,
          style: "BOLD",
        },
        {
          offset: 636,
          length: 15,
          style: "BOLD",
        },
      ],
      entityRanges: [],
      data: {},
    },
    {
      key: "fkud6",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "2af18",
      text: "NOTA: NO es recomendable editar mientras el raw JSON está renderizando. Se alenta mucho ya que está convirtiendo un JSON bastante enorme al que puedes ver ahi en cada cambio, luego lo vuelve a convertir en un string, luego lo pasa por prism para darle formato y finalmente lo renderiza dentro del componente con colores.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 0,
          length: 5,
          style: "color-#E91E63",
        },
        {
          offset: 0,
          length: 5,
          style: "BOLD",
        },
        {
          offset: 6,
          length: 2,
          style: "BOLD",
        },
        {
          offset: 6,
          length: 2,
          style: "ITALIC",
        },
      ],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {
    "0": {
      type: "FIELD",
      mutability: "IMMUTABLE",
      data: {
        IdGeestField: 0,
        IdFieldSelected: 0,
        DataType: "string",
        FieldLabel: "nombre",
      },
    },
    "1": {
      type: "FIELD",
      mutability: "IMMUTABLE",
      data: {
        IdGeestField: 1,
        IdFieldSelected: 9,
        DataType: "string",
        FieldLabel: "generic 9",
      },
    },
    "2": {
      type: "FIELD",
      mutability: "IMMUTABLE",
      data: {
        IdGeestField: 2,
        IdFieldSelected: null,
        DataType: "",
        FieldLabel: "",
      },
    },
  },
};

export default prevState;
