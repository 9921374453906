import React from "react";
import _ from "lodash";
import { Row } from "antd";
import {
  BarsContent,
  BarContainer,
  Label,
  StyledTooltip,
  StyledProgress,
} from "./Styles";
import { Template } from "../Dashboard.d";

interface BarsContainerProps {
  templates: Template[];
  selected: number;
  getProcessInfo: (template: Template, idx: number) => void;
  redStatusPercent?: number;
  yellowStatusPercent?: number;
  greenStatusPercent?: number;
}

const BarsContainer: React.FC<BarsContainerProps> = ({
  templates = [],
  selected,
  getProcessInfo,
  redStatusPercent = 25,
  yellowStatusPercent = 50,
  greenStatusPercent = 100,
}) => {
  const getTooltipColor = (progress: number) => {
    let firstColor;
    let lastColor;
    if (progress <= redStatusPercent) {
      firstColor = "rgba(194,50,40,255)";
      lastColor = "rgba(234,174,60,255)";
    } else if (progress <= yellowStatusPercent) {
      firstColor = "rgba(224,233,71,255)";
      lastColor = "rgba(177,229,73,255)";
    } else if (progress <= greenStatusPercent) {
      firstColor = "rgba(67,162,67,255)";
      lastColor = "rgba(50,149,65,255)";
    }
    return `linear-gradient(to right, ${firstColor},${lastColor})`;
  };

  const getColor = (progress: number) => {
    if (progress <= redStatusPercent) {
      return {
        "0%": "#d71c0d",
        "25%": "#db421a",
        "70%": "#df6a28",
        "100%": "#e49638",
      };
    }
    if (progress <= yellowStatusPercent) {
      return {
        "0%": "#d71c0d",
        "25%": "#e49638",
        "70%": "#dcc841",
        "100%": "#d7ec48",
      };
    }
    return {
      "0%": "#d71c0d",
      "25%": "#e49638",
      "70%": "#d7ec48",
      "100%": "#317a41",
    };
  };

  return (
    <BarsContent>
      {templates.map((template, idx) => {
        const {
          IdProcessTemplate,
          Count,
          ProcessTemplateName,
          ProgressPercentage,
        } = template;
        const percent = ProgressPercentage < 0 ? 0 : ProgressPercentage;

        return (
          <BarContainer
            $isSelected={_.isEqual(selected, idx)}
            key={IdProcessTemplate}
            onClick={() => getProcessInfo(template, idx)}
            tabIndex={0}
            onKeyDown={({ key }) => {
              if (key === "Enter") getProcessInfo(template, idx);
            }}
          >
            <Row align="middle">
              <Label $mr="0.5rem">{Count}</Label>
              <Label $mr="0">{ProcessTemplateName}</Label>
            </Row>
            <StyledTooltip $background={getTooltipColor(percent)}>
              <Row>
                <StyledProgress
                  percent={percent}
                  strokeColor={getColor(percent)}
                  $finish={_.isEqual(percent, 100)}
                />
              </Row>
            </StyledTooltip>
          </BarContainer>
        );
      })}
    </BarsContent>
  );
};

export default BarsContainer;
