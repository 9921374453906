import React from "react";
import ImageBlock from "./Blocks/ImageBlock";

interface BlockRootProps {
  contentState: any;
  block: any;
}

const BlockRoot: React.FC<BlockRootProps> = ({ contentState, block }) => {
  const entity = contentState.getEntity(block.getEntityAt(0));
  const type = entity.getType();

  let blockComponent: any = null;

  if (type === "IMAGE") {
    const { src, height, width } = entity.getData();
    blockComponent = <ImageBlock src={src} height={height} width={width} />;
  }

  return blockComponent;
};

export default BlockRoot;
