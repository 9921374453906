import React, { useContext } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import _ from "lodash";
import { Row } from "antd";
import { AlertContext } from "../AppAlert/AppAlertContext";
import {
  ModalBackground,
  Card,
  Title,
  Text,
  Button,
  ButtonBorder,
} from "./styles";
import States from "../../views/Premium/StateMachine/States";
import { useMutation } from "../../hooks";

const Status = {
  ENDED_FREE_CARDLESS_TRIAL: "ended_free_cardless_trial",
  ENDED_BONUS_TRIAL: "ended_bonus_trial",
  BLOCK_CAUSE_PAYMENT_DUE: "block_cause_payment_due",
  BLOCK_CAUSE_EXCEED_BENEFITS: "block_cause_exceed_benefits",
  CANCELED: "canceled",
};

const TrialEndedModal = () => {
  const {
    AlertData: { AlertInfo },
  } = useContext(AlertContext);

  const history = useHistory();
  const { pathname } = useLocation();

  const matchTaskDetail = !_.isEmpty(useRouteMatch("/home/pendings/:id"));
  const match = [
    !_.isEmpty(useRouteMatch("/home/pendings")),
    !_.isEmpty(useRouteMatch("/home/templates")),
    !_.isEmpty(useRouteMatch("/home/kanban")),
    !_.isEmpty(useRouteMatch("/home/reports")),
    !_.isEmpty(useRouteMatch("/home/dashboards")),
    !_.isEmpty(useRouteMatch("/home/marketplace")),
    !_.isEmpty(useRouteMatch("/home/statistics")),
  ];
  const showModal =
    _.includes(match, true) &&
    [
      Status.ENDED_FREE_CARDLESS_TRIAL,
      Status.ENDED_BONUS_TRIAL,
      Status.BLOCK_CAUSE_PAYMENT_DUE,
      Status.BLOCK_CAUSE_EXCEED_BENEFITS,
      Status.CANCELED,
    ].includes(AlertInfo.Value);

  // To fix issue with TaskDetail modal.
  if (matchTaskDetail && showModal) history.push("/home");

  const titleText = (() => {
    switch (AlertInfo.Value) {
      case Status.ENDED_FREE_CARDLESS_TRIAL:
      case Status.ENDED_BONUS_TRIAL:
        return AlertInfo.IsTeamOwner
          ? "¡Tu prueba gratuita del Plan Pro ha finalizado!"
          : "¡La prueba gratuita del Plan Pro ha finalizado!";
      case Status.BLOCK_CAUSE_PAYMENT_DUE:
      case Status.BLOCK_CAUSE_EXCEED_BENEFITS:
        return "Cuenta congelada";
      case Status.CANCELED:
        return "Tu plan ha finalizado";
      default:
        return AlertInfo.Value;
    }
  })();

  const [changeSubscriptionState, loading] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: () => {
      if (pathname === `/home/premium/${AlertInfo.IdTeam}`) {
        history.go(0);
      } else {
        history.push(`/home/premium/${AlertInfo.IdTeam}`);
      }
    },
    onError: () => {
      if (pathname === `/home/premium/${AlertInfo.IdTeam}`) {
        history.go(0);
      } else {
        history.push(`/home/premium/${AlertInfo.IdTeam}`);
      }
    },
  });

  const onClick = (NextState = "") => {
    changeSubscriptionState({
      args: {
        IdTeam: AlertInfo.IdTeam,
        NextState: NextState,
      },
    });
  };

  const Buttons = () => {
    if (!AlertInfo.IsTeamOwner) return null;
    switch (AlertInfo.Value) {
      case Status.ENDED_FREE_CARDLESS_TRIAL:
        return (
          <Row justify="space-between">
            <Button
              type="premiumPro"
              style={{ width: "calc(50% - 0.5rem)" }}
              disabled={loading}
              onClick={() => onClick(States.REQUEST_BONUS_TRIAL)}
            >
              Extender mi prueba
            </Button>
            <ButtonBorder>
              <Button
                type="defaultActive"
                block
                style={{ borderColor: "#ffffff0f" }}
                disabled={loading}
                onClick={() => onClick(States.CHANGE_PLAN)}
              >
                Contratar ahora
              </Button>
            </ButtonBorder>
          </Row>
        );
      case Status.ENDED_BONUS_TRIAL:
        return (
          <ButtonBorder fullWidth>
            <Button
              type="defaultActive"
              block
              style={{ borderColor: "#ffffff0f" }}
              disabled={loading}
              onClick={() => onClick(States.CHANGE_PLAN)}
            >
              Contratar ahora
            </Button>
          </ButtonBorder>
        );
      case Status.BLOCK_CAUSE_PAYMENT_DUE:
        return (
          <Button
            type="shadow"
            block
            style={{ borderColor: "#ffffff0f" }}
            disabled={loading}
            onClick={() => onClick(States.CHECKOUT_SINGLE_PAYMENT_OUTDATED)}
          >
            Reintentar pago
          </Button>
        );
      case Status.BLOCK_CAUSE_EXCEED_BENEFITS:
      default:
        return null;
    }
  };

  return (
    <ModalBackground visible={showModal}>
      <Card>
        <Title>{titleText}</Title>
        <Text>{AlertInfo.Message}</Text>
        <Buttons />
      </Card>
    </ModalBackground>
  );
};

export default TrialEndedModal;
