import { FormControl, withStyles } from "@material-ui/core";
import { DatePicker, Col } from "antd";
import styled from "styled-components";

const { RangePicker } = DatePicker;

export const StyledFormControl = withStyles({
  root: {
    minWidth: "12rem",
    "& label.Mui-focused": {
      color: "black",
    },
    "& label": {
      fontFamily: "Gotham-Book",
      fontSize: "medium",
      marginLeft: "0.3rem",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Gotham-Book",
      borderRadius: ".4rem",
      marginLeft: "0.4rem",
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(FormControl);

export const StyledRangePicker = styled(RangePicker)`
  border-radius: 0.4rem;
  padding: 6.5px 1rem;
  &.ant-picker-borderless {
    border: 1px solid rgba(0, 0, 0, 0.38) !important;
  }
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87) !important;
  }
  & input {
    font-size: 16px;
  }
`;

export const Card = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #cbccc9;
  width: 100%;
  padding: 0.5rem 1rem;
  height: 100%;
`;

export const StyledCol = styled(Col)`
  &:nth-child(1) {
    padding-left: 0 !important;
  }
  &:nth-child(2) {
    padding-right: 0 !important;
  }
`;
