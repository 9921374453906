import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _, { noop as NOOP } from "lodash";
import { Row } from "antd";
import Modal from "../../../components/Modal";
import {
  StartTemplateProps,
  FieldType,
  FieldVarDBRowType,
  ResponseType,
  ElementType,
} from "./StartTemplate.d";
import { Typography, Button } from "../../../GeestUI";
import { StyledInput, FieldContainer } from "./styles";
import DynamicInput from "../../../components/DynamicInput";
import { useFetch, useMutation } from "../../../hooks";
import ConfirmModal from "./ConfirmModal";
import DataTypeIcons from "../../../components/DataTypeIcons";
import {
  FileType,
  SelectFileFieldModal,
  onPasteImage,
  FileField,
} from "../../../components/DynamicInput/Components/helpers/FileHelpers";

const { B, H4 } = Typography;

const StartTemplate: React.FC<StartTemplateProps> = ({
  Template,
  closeModal,
  IdTeam,
}) => {
  const history = useHistory();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  const [ProcessName, setProcessName] = useState<string>("");
  const [ConsultFields, setConsultFields] = useState<
    (FieldType | FieldVarDBRowType)[]
  >([]);
  const [EditableFields, setEditableFields] = useState<
    (FieldType | FieldVarDBRowType)[]
  >([]);
  const [fileFields, setFileFields] = useState<FileField[]>([]);
  const [VarDBDataOrigin, setVarDBDataOrigin] = useState<{
    [key: number]: {
      IdRow: number;
      Label: string;
      RowSelectedLabel: string;
      [key: number]: number[] | null;
    }[];
  }>({});
  const [FilesPath, setFilesPath] = useState<string>("");
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [userSelectDataOrigin, setUserSelectDataOrigin] = useState<any>([]);
  const [selectFileFieldModal, setSelectFileFieldModal] =
    useState<FileType | null>(null);

  // Check if a value is empty or invalid based on its data type
  const isEmptyOrInvalidValue = (value: any, dataType: string): boolean => {
    if (dataType === "multi_select" || dataType === "user_select") {
      return !Array.isArray(value) || value.length === 0;
    } else if (dataType === "select") {
      return !Boolean(value?.Value) && !Boolean(value?.Label);
    }
    return !Boolean(value);
  };

  // Check if there are any mandatory fields with missing or invalid values
  const hasMissingFields = (cells: FieldType[]): boolean => {
    return cells.some(
      (cell) =>
        cell.IsMandatory && isEmptyOrInvalidValue(cell.Value, cell.DataType)
    );
  };

  // Validate a varDBRow type field
  const isFieldVarDBRowValid = (field: FieldVarDBRowType): boolean => {
    // Check if new rows can be added and existing rows can be used
    if (!field.CanAddNewRows && field.CanUseExistingRows) {
      // Validate that there is at least one valid origin if FilterDataOrigin is set
      if (field.FilterDataOrigin) {
        const validOrigin = getFilteredVarDBDataOrigin(field).find(
          ({ IdRow }: any) => IdRow !== 0
        );
        // Validate that you haven't selected a row in an empty row data origin
        if (
          Boolean(field.IdRowSelected) &&
          Object.keys(validOrigin || {}).length === 0
        ) {
          return false;
        }
      }
      // Ensure IdRowSelected is set or there are no mandatory fields missing
      if (!Boolean(field.IdRowSelected) && hasMissingFields(field.Cells)) {
        return false;
      }
    }
    // Check if there are any missing mandatory fields
    return !hasMissingFields(field.Cells);
  };

  // Validate a regular field
  const isFieldValid = (field: FieldType): boolean => {
    // Check if the field is mandatory
    if (field.IsMandatory) {
      // For checklist type fields, ensure that all elements are checked
      if (
        field.DataType === "checklist" &&
        field.Elements?.some((element) => !element.Checked)
      ) {
        return false;
      }
      // For multi_select and select types, ensure that the value is not empty
      if (isEmptyOrInvalidValue(field.Value, field.DataType)) {
        return false;
      }
    }
    return true;
  };

  // Main function to validate the form
  const isValidForm = (): boolean => {
    if (loading) return false;
    // Check if ProcessName is not empty
    if (_.isEmpty(_.trim(ProcessName)) && data?.TitleMode === "manual")
      return false;

    // Validate each field in EditableFields
    for (const field of EditableFields) {
      if (field.DataType === "varDBRow") {
        // Validate varDBRow type fields
        if (!isFieldVarDBRowValid(field as FieldVarDBRowType)) {
          return false;
        }
      } else {
        // Validate regular fields
        if (!isFieldValid(field as FieldType)) {
          return false;
        }
      }
    }

    return true;
  };

  const { data, loading } = useFetch<ResponseType>({
    func: "Ver2-Processes-gpsf",
    args: {
      IdTeam,
      IdProcessTemplate: Template.IdProcessTemplate,
    },
    onSuccess: ({
      ConsultFields = [],
      EditableFields = [],
      VarDBDataOrigin = [],
      FilesPath,
      UserSelectDataOrigin,
      ...rest
    }) => {
      setConsultFields(ConsultFields);
      setEditableFields(EditableFields);
      const fileFields = EditableFields.filter(
        (field) => field.DataType === "file"
      ).map((field) => ({
        Id: field.IdField || 0,
        Label: field.Label,
      }));
      let fileCells: FileField[] = [];
      EditableFields.filter((field) => field.DataType === "varDBRow").forEach(
        (field) => {
          const fileFields = (field as FieldVarDBRowType).Cells.filter(
            (field) => field.DataType === "file"
          ).map((cell) => ({
            Id: cell.IdField,
            Label: `${field.Label} > ${cell.Label}`,
          }));
          fileCells = [...fileCells, ...fileFields];
        }
      );
      setFileFields([...fileFields, ...fileCells]);
      setVarDBDataOrigin(VarDBDataOrigin);
      setFilesPath(FilesPath);
      setUserSelectDataOrigin(UserSelectDataOrigin);
    },
  });

  const [getCellValues] = useMutation<{ Value: string; IdField: number }[]>({
    func: "Ver2-Processes-gvcv",
    onSuccess: (
      cells,
      {
        indexesToChangeEditable,
        EditableFields,
        indexesToChangeConsult,
        ConsultFields,
        IdVarDBGroup,
        extraFields = [],
      }
    ) => {
      let EditableFieldsAux = [...EditableFields];
      indexesToChangeEditable.forEach((index: number) => {
        const newCells = (
          (EditableFieldsAux[index] as FieldVarDBRowType).Cells || []
        ).map((c) => {
          let cell = cells.find((cell) => cell.IdField === c.IdField);
          if (cell) {
            c = {
              ...c,
              ...cell,
            };
          }
          return c;
        });
        (EditableFieldsAux[index] as FieldVarDBRowType).Cells = newCells;
      });

      let ConsultFieldsAux = [...ConsultFields];
      indexesToChangeConsult.forEach((index: number) => {
        const newCells = (
          (ConsultFieldsAux[index] as FieldVarDBRowType).Cells || []
        ).map((c) => {
          let cell = cells.find((cell) => cell.IdField === c.IdField);
          if (cell) {
            c = {
              ...c,
              ...cell,
            };
          }
          return c;
        });
        (ConsultFieldsAux[index] as FieldVarDBRowType).Cells = newCells;
      });

      const allFields = [...EditableFieldsAux, ...ConsultFieldsAux];

      const childFields: FieldVarDBRowType[] = [];
      allFields.forEach((field) => {
        if (
          field.AutoFill === IdVarDBGroup &&
          !childFields
            .map((field) => field.IdVarDBGroup)
            .includes(field.IdVarDBGroup)
        ) {
          childFields.push(field);
        }
      });
      const allChildFields = [...extraFields, ...childFields];
      if (allChildFields.length > 0) {
        let field = allChildFields[0];
        const prevRowSelected = field.IdRowSelected;
        const newSelectedRow = getFilteredVarDBDataOrigin(
          {
            ...field,
            FilterDataOrigin: field.IdVarDBGroupParent
              ? field.IdVarDBGroupParent
              : IdVarDBGroup,
          },
          EditableFieldsAux,
          ConsultFieldsAux,
          true
        )[0].IdRow;
        if (prevRowSelected !== newSelectedRow) {
          const res = onChangeRow(
            newSelectedRow,
            field.IdVarDBGroup,
            EditableFieldsAux,
            ConsultFieldsAux,
            true,
            allChildFields
              .filter((f) => f.IdVarDBGroup !== field.IdVarDBGroup)
              .map((f) => ({
                ...f,
                IdVarDBGroupParent: f.IdVarDBGroupParent
                  ? f.IdVarDBGroupParent
                  : IdVarDBGroup,
              }))
          );
          if (res) {
            const { EditableFields: editable, ConsultFields: consult } = res;
            EditableFieldsAux = editable;
            ConsultFieldsAux = consult;
          }
        }
      }
      setConsultFields(ConsultFieldsAux);
      setEditableFields(EditableFieldsAux);
    },
  });

  const [submitStartTemplate, submitting] = useMutation<{
    IdExecution: number;
    IdNextTask: number;
  }>({
    func: "Ver2-Processes-sp",
    onSuccess: ({ IdNextTask }) => {
      if (IdNextTask) {
        history.push(`/home/pendings/${IdNextTask}`);
      } else {
        history.push("/home/pendings");
      }
    },
  });

  const [addOptionToDataOriginPendings] = useMutation<{
    value: number;
    label: string;
  }>({
    func: "Ver2-MyPendings-aotdo",
    onSuccess: (newOption, { index }) => {
      let EditableFieldsAux = [...EditableFields];
      (EditableFieldsAux[index] as any).DataOrigin = [
        ...(EditableFieldsAux[index] as any).DataOrigin,
        newOption,
      ];

      if ((EditableFieldsAux[index] as FieldType).DataType === "select") {
        (EditableFieldsAux[index] as FieldType).Value = {
          // @ts-ignore
          Type: "Key",
          Value: newOption.value,
          Label: newOption.label,
        };

        setEditableFields(EditableFieldsAux);
      }

      if ((EditableFieldsAux[index] as FieldType).DataType === "multi_select") {
        if (Array.isArray((EditableFieldsAux[index] as any).Value)) {
          (EditableFieldsAux[index] as any).Value = [
            ...(EditableFieldsAux[index] as any).Value,
            {
              // @ts-ignore
              Type: "Key",
              Value: newOption.value,
              Label: newOption.label,
            },
          ];
        } else {
          (EditableFieldsAux[index] as any).Value = [
            {
              // @ts-ignore
              Type: "Key",
              Value: newOption.value,
              Label: newOption.label,
            },
          ];
        }

        setEditableFields(EditableFieldsAux);
      }
    },
  });

  const [addOptionToDataOriginVarDBs] = useMutation<{
    value: number;
    label: string;
  }>({
    func: "Ver2-Vardbs-aotdo",
    onSuccess: (newOption, { indexField, indexCell }) => {
      let EditableFieldsAux = [...EditableFields];

      let vardbField = EditableFields[indexField];
      let vardbCell = (vardbField as any).Cells[indexCell];

      // @ts-ignore
      EditableFieldsAux[indexField].Cells[indexCell].DataOrigin = [
        ...vardbCell.DataOrigin,
        newOption,
      ];

      if (vardbCell.DataType === "select") {
        // @ts-ignore
        EditableFieldsAux[indexField].Cells[indexCell].Value = {
          // @ts-ignore
          Type: "Key",
          Value: newOption.value,
          Label: newOption.label,
        };

        setEditableFields(EditableFieldsAux);
      }

      if (vardbCell.DataType === "multi_select") {
        if (vardbCell.Value) {
          // @ts-ignore
          EditableFieldsAux[indexField].Cells[indexCell].Value = [
            ...vardbCell.Value,
            {
              // @ts-ignore
              Type: "Key",
              Value: newOption.value,
              Label: newOption.label,
            },
          ];
        } else {
          // @ts-ignore
          EditableFieldsAux[indexField].Cells[indexCell].Value = [
            {
              //@ts-ignore
              Type: "Key",
              Value: newOption.value,
              Label: newOption.label,
            },
          ];
        }

        setEditableFields(EditableFieldsAux);
      }
    },
  });

  const onChangeEditableField = (value: any, index: number) => {
    let EditableFieldsAux = [...EditableFields];
    (EditableFieldsAux[index] as FieldType).Value = value;
    setEditableFields(EditableFieldsAux);
  };

  const onChangeRow = (
    value: number,
    IdVarDBGroup: number,
    editableFields = EditableFields,
    consultFields = ConsultFields,
    returnFields = false,
    extraFields: FieldVarDBRowType[] = []
  ) => {
    let EditableFieldsAux = [...editableFields];
    let ConsultFieldsAux = [...consultFields];

    let indexesToChangeEditable: number[] = [];
    let indexesToChangeConsult: number[] = [];

    EditableFieldsAux = EditableFieldsAux.map((field, index) => {
      if (field.IdVarDBGroup === IdVarDBGroup) {
        field = {
          ...(field as FieldVarDBRowType),
          IdRowSelected: value,
          RowSelectedLabel:
            VarDBDataOrigin[field.IdVarDB].find(({ IdRow }) => IdRow === value)
              ?.RowSelectedLabel || "",
        };
        if (value === 0) {
          field.Cells = field.Cells.map((cell) => ({
            ...cell,
            Value: null,
          }));
        }
        indexesToChangeEditable.push(index);
      }
      return field;
    });

    ConsultFieldsAux = ConsultFieldsAux.map((field, index) => {
      if (field.IdVarDBGroup === IdVarDBGroup) {
        field = {
          ...(field as FieldVarDBRowType),
          IdRowSelected: value,
          RowSelectedLabel:
            VarDBDataOrigin[field.IdVarDB].find(({ IdRow }) => IdRow === value)
              ?.RowSelectedLabel || "",
        };
        if (value === 0) {
          field.Cells = field.Cells.map((cell) => ({
            ...cell,
            Value: null,
          }));
        }
        indexesToChangeConsult.push(index);
      }
      return field;
    });

    setEditableFields(EditableFieldsAux);
    setConsultFields(ConsultFieldsAux);
    if (value && value > 0) {
      getCellValues({
        args: {
          IdRowSelected: value,
          IdVarDBGroup,
        },
        shippedData: {
          indexesToChangeEditable,
          EditableFields: EditableFieldsAux,
          indexesToChangeConsult,
          ConsultFields: ConsultFieldsAux,
          IdVarDBGroup,
          extraFields,
        },
      });
    }
    if (returnFields) {
      return {
        EditableFields: EditableFieldsAux,
        ConsultFields: ConsultFieldsAux,
      };
    }
  };

  const onChangeCells = (value: FieldType[], index: number) => {
    let EditableFieldsAux = [...EditableFields];
    (EditableFieldsAux[index] as FieldVarDBRowType).Cells = value;
    setEditableFields(EditableFieldsAux);
  };

  const onChangeChecklist = (value: ElementType[], index: number) => {
    let EditableFieldsAux = [...EditableFields];
    (EditableFieldsAux[index] as FieldType).Elements = value;
    setEditableFields(EditableFieldsAux);
  };

  const onSubmit = () => {
    const filteredEF = (EditableFields as FieldType[]).map((ef) => {
      let auxEF = { ...ef };
      return auxEF;
    });

    submitStartTemplate({
      args: {
        IdProcessTemplate: Template.IdProcessTemplate,
        IdTeam,
        ProcessName: ProcessName.trim(),
        StartForm: {
          EditableFields: filteredEF,
        },
        TitleMode: data?.TitleMode,
      },
    });
  };

  const getDataOrigin = (field: FieldType | FieldVarDBRowType): string => {
    let dataOrigin: any;
    switch (field.DataType) {
      case "users_select":
        dataOrigin = data?.UserSelectDataOrigin as unknown as string; // Hacky way to pass array
        break;
      default:
        dataOrigin = (field as FieldType).DataOrigin;
        break;
    }
    return dataOrigin;
  };

  const handleAddOption = (option: string, IdField: number, index: number) => {
    addOptionToDataOriginPendings({
      args: {
        IdField,
        NewElement: option,
      },
      shippedData: { index },
    });
  };

  const handleAddOptionFromCell = (
    option: string,
    field: any,
    IdColumn: number | null
  ) => {
    if (IdColumn) {
      const indexField = _.findIndex(EditableFields, {
        IdVarDBGroup: field.IdVarDBGroup,
        IdVarDB: field.IdVarDB,
      });
      if (indexField === -1) return;
      // @ts-ignore
      const indexCell = _.findIndex(EditableFields[indexField].Cells, {
        IdVarDBColumn: IdColumn,
      });
      if (indexCell === -1) return;
      addOptionToDataOriginVarDBs({
        args: {
          IdColumn,
          IdVarDB: field.IdVarDB,
          DataOrigin: [
            // @ts-ignore
            ...EditableFields[indexField].Cells[indexCell].DataOrigin,
            { label: option },
          ],
        },
        shippedData: { indexField, indexCell },
      });
    }
  };

  const handleOnClose = () => {
    if (isEdited) {
      setOpenConfirmModal(true);
      return;
    }
    closeModal();
  };

  const getFilteredVarDBDataOrigin = (
    field: FieldVarDBRowType,
    editableFields = EditableFields,
    consultFields = ConsultFields,
    onlyFiltered = false
  ) => {
    if (!field.IdVarDB) return [];
    const allFields = [...editableFields, ...consultFields];
    const vardbRowParent = allFields.find(
      ({ IdVarDBGroup }) => IdVarDBGroup === field.FilterDataOrigin
    );

    let newDataOrigin = VarDBDataOrigin[field.IdVarDB];

    if (vardbRowParent && (vardbRowParent as any).IdRowSelected === 0) {
      newDataOrigin = [{ IdRow: 0, Label: "Nuevo", RowSelectedLabel: "Nuevo" }];
      return newDataOrigin;
    }

    if (!newDataOrigin) return [];
    const idRowSelected = (vardbRowParent as FieldVarDBRowType)?.IdRowSelected;
    if (!vardbRowParent || idRowSelected === 0 || !idRowSelected) {
      return newDataOrigin.filter(
        ({ IdRow }) => IdRow !== 0 || field.CanAddNewRows
      );
    }
    newDataOrigin = newDataOrigin.filter(
      (dataOrigin) =>
        dataOrigin[field.FilterDataOrigin || 0]?.includes(idRowSelected) ||
        (dataOrigin.IdRow === 0 && field.CanAddNewRows && !onlyFiltered) ||
        (!!field.IdRowSelected &&
          field.IdRowSelected === dataOrigin.IdRow &&
          !onlyFiltered)
    );
    if (newDataOrigin.length === 0) {
      newDataOrigin = [{ IdRow: 0, Label: "Nuevo", RowSelectedLabel: "Nuevo" }];
    }
    return newDataOrigin;
  };

  const onPasteImageToField = (IdField: number, file: FileType) => {
    const value = file;
    const index = EditableFields.findIndex(
      (field) => field.IdField === IdField
    );
    if (index >= 0) {
      const oldValue = (EditableFields[index] as FieldType).Value;
      setIsEdited(true);
      onChangeEditableField(oldValue ? [...oldValue, value] : [value], index);
    } else {
      const index = EditableFields.findIndex(
        (field) =>
          !!(field as FieldVarDBRowType).Cells?.find(
            (cell) => cell.IdField === IdField
          )
      );
      if (index >= 0) {
        const vardbRowInput = EditableFields[index];
        const cells = (vardbRowInput as FieldVarDBRowType).Cells.map((cell) => {
          if (cell.IdField === IdField) {
            return {
              ...cell,
              Value: cell.Value ? [...cell.Value, value] : [value],
            };
          }
          return cell;
        });
        setIsEdited(true);
        onChangeCells(cells, index);
      }
    }
  };

  useEffect(() => {
    if (fileFields.length > 0) {
      const onKeyDown = async (e: KeyboardEvent) => {
        if (e.key.toLowerCase() === "v" && e.ctrlKey) {
          const value = await onPasteImage(FilesPath);
          if (value) {
            if (fileFields.length > 1) {
              setSelectFileFieldModal(value);
            } else {
              onPasteImageToField(fileFields[0].Id, value);
            }
          }
        }
      };
      window.addEventListener("keydown", onKeyDown);

      return () => window.removeEventListener("keydown", onKeyDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileFields, FilesPath, EditableFields]);

  return (
    <Modal
      visible
      centered
      closable={false}
      footer={null}
      title={`Iniciar ${Template.Name}`}
      onCancel={handleOnClose}
      bodyStyle={{
        maxHeight: "100vh",
        padding: "13px 20px 20px 20px",
      }}
      width={
        (ConsultFields || []).length > 0 && (EditableFields || []).length > 0
          ? "700px"
          : "432px"
      }
    >
      {!!selectFileFieldModal && (
        <SelectFileFieldModal
          file={selectFileFieldModal}
          onChangeFileField={onPasteImageToField}
          closeModal={() => setSelectFileFieldModal(null)}
          fileFields={fileFields}
        />
      )}
      {openConfirmModal && (
        <ConfirmModal
          onClose={() => {
            setIsEdited(false);
            setOpenConfirmModal(false);
            closeModal();
          }}
          onCancel={() => {
            setOpenConfirmModal(false);
          }}
        />
      )}
      {data?.TitleMode === "manual" && (
        <StyledInput
          placeholder="Título del seguimiento"
          value={ProcessName}
          ref={ref}
          autoFocus
          // @ts-ignore
          $empty={_.isEmpty(ProcessName.trim())}
          onChange={({ target: { value } }) => {
            setIsEdited(true);
            setProcessName(value);
          }}
          onKeyDown={({ key }) => {
            if (key === "Enter" && !submitting && isValidForm()) {
              onSubmit();
            }
          }}
        />
      )}
      <Row
        style={{
          width: "100%",
          maxHeight: "62vh",
          overflow: "auto",
          marginTop: "0",
          borderRadius: "5px",
          background: "#fafbfd",
          padding: "0px 20px 20px 20px",
        }}
        justify="space-between"
        hidden={_.isEmpty(ConsultFields) && _.isEmpty(EditableFields)}
      >
        {(ConsultFields || []).length > 0 && (
          <div
            style={{
              width: (EditableFields || []).length > 0 ? "48%" : "100%",
            }}
          >
            {ConsultFields.map((field, index) => (
              <FieldContainer key={index}>
                <H4 style={{ display: "flex" }}>
                  {field.DataType !== "varDBRow" && (
                    <div style={{ height: "20px", width: "20px" }}>
                      {DataTypeIcons[field.DataType] && (
                        <img
                          src={DataTypeIcons[field.DataType].icon}
                          alt=""
                          style={{
                            width: "50%",
                            height: "50%",
                            ...DataTypeIcons[field.DataType]?.extraStyles,
                          }}
                        />
                      )}
                    </div>
                  )}
                  <B>{field.Label}</B>
                </H4>
                <DynamicInput
                  value={
                    (field as FieldType).Value ??
                    ((field as FieldVarDBRowType).IdRowSelected || "")
                  }
                  type={field.DataType}
                  dataOrigin={(field as FieldType).DataOrigin || ""}
                  format={(field as FieldType).Format || ""}
                  fieldName={field.Label || ""}
                  configuration={(field as FieldType).Configuration || ""}
                  userSelectDataOrigin={userSelectDataOrigin || []}
                  IdVarDB={(field as FieldType).IdVarDB || 0}
                  VarDBTitle={(field as FieldType).VarDBTitle}
                  Cells={(field as FieldVarDBRowType).Cells || []}
                  VarDBDataOrigin={getFilteredVarDBDataOrigin(
                    field as FieldVarDBRowType
                  )}
                  IdVarDBGroup={field.IdVarDBGroup || 0}
                  VarDBRowShowSelectInput={
                    (field as unknown as FieldVarDBRowType)
                      .VarDBRowShowSelectInput || false
                  }
                  KeyLabel={(field as FieldVarDBRowType).KeyLabel}
                  disabled
                  isConsult={false}
                  required={false}
                  onChange={NOOP}
                  checklistParams={{
                    elements: (field as FieldType).Elements,
                    onChangeChecklist: NOOP,
                  }}
                  extraParams={{
                    FilesPath: "",
                    showFormat: true,
                    canAddNewRows: false,
                    CanUseExistingRows: false,
                  }}
                  RowSelectedLabel={
                    (field as unknown as FieldVarDBRowType).RowSelectedLabel
                  }
                />
              </FieldContainer>
            ))}
          </div>
        )}
        {(EditableFields || []).length > 0 && (
          <div
            style={{
              width: (ConsultFields || []).length > 0 ? "48%" : "100%",
            }}
          >
            {EditableFields.map((field, fieldIndex) => {
              let format = (field as FieldType).Format || "";

              const onChangeFormat = (newFormat: any) => {
                format = `${newFormat}`;
                const auxFields = [...EditableFields];
                (auxFields[fieldIndex] as FieldType).Format = format;
                setEditableFields(auxFields);
              };

              return (
                <FieldContainer key={field.IdField ?? fieldIndex}>
                  <H4 style={{ display: "flex" }}>
                    {field.DataType !== "varDBRow" && (
                      <div style={{ height: "20px", width: "20px" }}>
                        {DataTypeIcons[field.DataType] && (
                          <img
                            src={DataTypeIcons[field.DataType].icon}
                            alt=""
                            style={{
                              width: "50%",
                              height: "50%",
                              ...DataTypeIcons[field.DataType]?.extraStyles,
                            }}
                          />
                        )}
                      </div>
                    )}
                    <B>{field.Label}</B>
                  </H4>
                  <DynamicInput
                    value={
                      (field as FieldVarDBRowType).IdRowSelected ??
                      ((field as FieldType).Value || undefined)
                    }
                    type={field.DataType}
                    dataOrigin={getDataOrigin(field)}
                    format={format}
                    configuration={(field as FieldType).Configuration || ""}
                    fieldName={field.Label || ""}
                    userSelectDataOrigin={userSelectDataOrigin}
                    IdVarDB={(field as FieldType).IdVarDB || 0}
                    VarDBTitle={(field as FieldType).VarDBTitle}
                    Cells={(field as FieldVarDBRowType).Cells || []}
                    VarDBDataOrigin={getFilteredVarDBDataOrigin(
                      field as FieldVarDBRowType
                    )}
                    IdVarDBGroup={field.IdVarDBGroup || 0}
                    VarDBRowShowSelectInput={
                      (field as unknown as FieldVarDBRowType)
                        .VarDBRowShowSelectInput || false
                    }
                    RowSelectedLabel={
                      (field as unknown as FieldVarDBRowType).RowSelectedLabel
                    }
                    KeyLabel={(field as FieldVarDBRowType).KeyLabel}
                    isConsult={false}
                    disabled={false}
                    required={(field as FieldType).IsMandatory}
                    onChange={(val) => {
                      setIsEdited(true);
                      onChangeEditableField(val, fieldIndex);
                    }}
                    onChangeFormat={(newFormat) => {
                      setIsEdited(true);
                      onChangeFormat(newFormat);
                    }}
                    onAddOption={(option) => {
                      setIsEdited(true);
                      handleAddOption(
                        option,
                        (field as FieldType).IdField,
                        fieldIndex
                      );
                    }}
                    onChangeRow={(val) => {
                      setIsEdited(true);
                      onChangeRow(val, field.IdVarDBGroup || 0);
                    }}
                    onChangeCells={(val) => {
                      setIsEdited(true);
                      onChangeCells(val, fieldIndex);
                    }}
                    onAddOptionFromCell={(option, IdColumn) => {
                      setIsEdited(true);
                      handleAddOptionFromCell(option, field, IdColumn);
                    }}
                    checklistParams={{
                      elements: (field as FieldType).Elements || [],
                      onChangeChecklist: (val: any) => {
                        setIsEdited(true);
                        onChangeChecklist(val, fieldIndex);
                      },
                    }}
                    extraParams={{
                      FilesPath,
                      showFormat: true,
                      canAddNewRows: field.CanAddNewRows,
                      CanUseExistingRows: field.CanUseExistingRows,
                      FilterDataOrigin: field.FilterDataOrigin,
                    }}
                  />
                </FieldContainer>
              );
            })}
          </div>
        )}
      </Row>
      <Row justify="center">
        <Button
          type="primary"
          style={{ marginTop: "20px" }}
          disabled={submitting || !isValidForm()}
          onClick={onSubmit}
          loading={submitting}
        >
          Iniciar
        </Button>
      </Row>
    </Modal>
  );
};

export default StartTemplate;
