import styled from "styled-components";

export const Description = styled.div`
  color: #828d9e;
  text-align: center;
  font-family: "Gotham-book";
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 24px;
`;

export const Label = styled.div`
  color: #48505e;
  font-family: "Gotham-bold";
  font-size: 14px;
`;

export const ConfContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
