import React from "react";
import { Row } from "antd";
import { Button } from "../../../../../GeestUI";
import { Languages } from "../../../Dictionary";
import { ActionsList, ActionsPopoverContent } from "../Style";
import { ReactComponent as StarIcon } from "../../../../../resources/img/starIcon.svg";
import { ReactComponent as ReplaceUserIcon } from "../../../../../resources/img/replaceUser.svg";
import { ReactComponent as ReassingUserIcon } from "../../../../../resources/img/reassignUser.svg";
import { RoleTitle } from "../Style";
import { CloseIcon } from "../../../../../components/hoverIcons";
const { Actions, ReplaceUserLabel, ReassignUserTasksLabel } = Languages["ESP"];

interface ActionsPopoverProps {
  isLeader: boolean;
  openReplaceUser: () => void;
  openReasignTasks: () => void;
  setActionsUser: () => void;
  onClose: () => void;
  canChangeLeader: boolean;
  onChangeLeader: () => void;
}

const ActionsPopover: React.FC<ActionsPopoverProps> = ({
  isLeader,
  openReplaceUser,
  openReasignTasks,
  setActionsUser,
  onClose,
  canChangeLeader,
  onChangeLeader,
}) => {
  return (
    <ActionsPopoverContent>
      <Row justify="space-between">
        <RoleTitle>{Actions}</RoleTitle>
        <CloseIcon onClick={onClose} />
      </Row>
      <ActionsList>
        {!isLeader && (
          <Button
            onClick={() => {
              openReplaceUser();
              setActionsUser();
            }}
            tabIndex={0}
            onKeyDown={({ key }) => {
              if (key === "Enter") {
                openReplaceUser();
                setActionsUser();
              }
            }}
            SvgIcon={ReplaceUserIcon}
            style={{ width: "fit-content" }}
          >
            {ReplaceUserLabel}
          </Button>
        )}
        <Button
          onClick={() => {
            openReasignTasks();
            setActionsUser();
          }}
          tabIndex={0}
          onKeyDown={({ key }) => {
            if (key === "Enter") {
              openReasignTasks();
              setActionsUser();
            }
          }}
          SvgIcon={ReassingUserIcon}
          style={{ width: "fit-content" }}
        >
          {ReassignUserTasksLabel}
        </Button>
        {/*canChangeLeader && !isLeader && (
          <Button
            tabIndex={0}
            onClick={onChangeLeader}
            onKeyDown={({ key }) => {
              if (key === "Enter") {
                onChangeLeader();
              }
            }}
            SvgIcon={StarIcon}
            style={{ width: "fit-content" }}
          >
            Convertir en líder
          </Button>
        )*/}
      </ActionsList>
    </ActionsPopoverContent>
  );
};

export default ActionsPopover;
