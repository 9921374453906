import React from "react";
import { TooltipPlacement } from "antd/lib/tooltip";
import { Popover } from "antd";
import {
  MiddleUser,
  UserContainer,
  UserInitials,
  UserProfilePic,
} from "./Styles";
import Tooltip from "../Tooltip";
import TaksPopover from "./TaksPopover";

export interface UserType {
  Email: string;
  IdUser: number;
  Initials: string;
  LastName: string;
  FirstName: string;
  altTooltip?: string;
  ProfilePicture?: string;
}

interface Task {
  Title: string;
  IdStatus: number;
  StartedAt: string;
  Revision: boolean;
  StuckTime: string;
}

export interface StatusDataOriginType {
  Label: string;
  Value: number;
}

interface UserProps {
  user: UserType | undefined;
  size?: "small" | "middle" | "large" | string;
  picSize?: string;
  tooltip?: boolean;
  userLargeTooltip?: boolean;
  popover?: boolean;
  userTasks?: Task[];
  placement?: TooltipPlacement;
  nameStyle?: string;
  nameColor?: string;
  nameSize?: string;
  emailColor?: string;
  emailSize?: string;
  borderColor?: string;
  statusDataOrigin?: StatusDataOriginType[];
}

interface UserPicProps {
  pic: string | undefined;
  picSize: string;
  initials: string;
  borderColor?: string;
}

export const UserPicture: React.FC<UserPicProps> = ({
  pic,
  picSize,
  initials,
  borderColor,
}) => {
  return pic ? (
    <UserProfilePic
      image={pic}
      align="middle"
      justify="center"
      $picSize={picSize}
      $borderColor={borderColor}
    />
  ) : (
    <UserInitials
      align="middle"
      justify="center"
      $picSize={picSize}
      $borderColor={borderColor}
    >
      <span>{initials}</span>
    </UserInitials>
  );
};

const User: React.FC<UserProps> = ({
  user,
  size = "large",
  picSize = "20px",
  tooltip = false,
  userLargeTooltip = false,
  popover = false,
  userTasks = [],
  placement = "bottom",
  nameStyle = "normal",
  nameSize = "14px",
  nameColor = "#828d9e",
  emailColor = "#828d9e",
  emailSize = "12px",
  borderColor,
  statusDataOrigin = [],
}) => {
  if (user) {
    switch (size) {
      case "small":
        return (
          <UserContainer
            nameStyle={nameStyle}
            nameColor={nameColor}
            nameSize={nameSize}
            emailColor={emailColor}
            emailSize={emailSize}
          >
            {tooltip ? (
              <Tooltip
                title={
                  user.altTooltip
                    ? user.altTooltip
                    : `${user.FirstName} ${user.LastName} (${user.Email})`
                }
                placement={placement}
              >
                <div>
                  <UserPicture
                    pic={user.ProfilePicture}
                    picSize={picSize}
                    initials={user.Initials}
                    borderColor={borderColor}
                  />
                </div>
              </Tooltip>
            ) : popover ? (
              <Popover
                placement="bottomLeft"
                content={
                  <TaksPopover
                    user={user}
                    tasks={userTasks}
                    statusDataOrigin={statusDataOrigin}
                  />
                }
              >
                <div>
                  <UserPicture
                    pic={user.ProfilePicture}
                    picSize={picSize}
                    initials={user.Initials}
                    borderColor={borderColor}
                  />
                </div>
              </Popover>
            ) : (
              <div>
                <UserPicture
                  pic={user.ProfilePicture}
                  picSize={picSize}
                  initials={user.Initials}
                  borderColor={borderColor}
                />
              </div>
            )}
          </UserContainer>
        );
      case "middle":
        return (
          <UserContainer
            nameStyle={nameStyle}
            nameColor={nameColor}
            nameSize={nameSize}
            emailColor={emailColor}
            emailSize={emailSize}
          >
            {tooltip ? (
              <Tooltip
                title={user.altTooltip ? user.altTooltip : user.Email}
                placement={placement}
              >
                <MiddleUser>
                  <UserPicture
                    pic={user.ProfilePicture}
                    picSize={picSize}
                    initials={user.Initials}
                    borderColor={borderColor}
                  />
                  <p className="username">{`${user.FirstName} ${user.LastName}`}</p>
                </MiddleUser>
              </Tooltip>
            ) : popover ? (
              <Popover
                placement="bottomLeft"
                content={
                  <TaksPopover
                    user={user}
                    tasks={userTasks}
                    statusDataOrigin={statusDataOrigin}
                  />
                }
              >
                <MiddleUser>
                  <UserPicture
                    pic={user.ProfilePicture}
                    picSize={picSize}
                    initials={user.Initials}
                    borderColor={borderColor}
                  />
                  <p className="username">{`${user.FirstName} ${user.LastName}`}</p>
                </MiddleUser>
              </Popover>
            ) : (
              <MiddleUser>
                <UserPicture
                  pic={user.ProfilePicture}
                  picSize={picSize}
                  initials={user.Initials}
                  borderColor={borderColor}
                />
                <p className="username">{`${user.FirstName} ${user.LastName}`}</p>
              </MiddleUser>
            )}
          </UserContainer>
        );
      case "large":
      default:
        return popover ? (
          <Popover
            placement="bottomLeft"
            content={
              <TaksPopover
                user={user}
                tasks={userTasks}
                statusDataOrigin={statusDataOrigin}
              />
            }
          >
            <UserContainer
              nameStyle={nameStyle}
              nameColor={nameColor}
              nameSize={nameSize}
              emailColor={emailColor}
              emailSize={emailSize}
            >
              <UserPicture
                pic={user.ProfilePicture}
                picSize={picSize}
                initials={user.Initials}
                borderColor={borderColor}
              />
              <div>
                <p className="username">{`${user.FirstName} ${user.LastName}`}</p>
                <p className="email">{user.Email}</p>
              </div>
            </UserContainer>
          </Popover>
        ) : userLargeTooltip ? (
          <Tooltip
            title={user.altTooltip ? user.altTooltip : user.Email}
            placement={placement}
          >
            <UserContainer
              nameStyle={nameStyle}
              nameColor={nameColor}
              nameSize={nameSize}
              emailColor={emailColor}
              emailSize={emailSize}
            >
              <UserPicture
                pic={user.ProfilePicture}
                picSize={picSize}
                initials={user.Initials}
                borderColor={borderColor}
              />
              <div>
                <p className="username">{`${user.FirstName} ${user.LastName}`}</p>
                <p className="email">{user.Email}</p>
              </div>
            </UserContainer>
          </Tooltip>
        ) : (
          <UserContainer
            nameStyle={nameStyle}
            nameColor={nameColor}
            nameSize={nameSize}
            emailColor={emailColor}
            emailSize={emailSize}
          >
            <UserPicture
              pic={user.ProfilePicture}
              picSize={picSize}
              initials={user.Initials}
              borderColor={borderColor}
            />
            <div>
              <p className="username">{`${user.FirstName} ${user.LastName}`}</p>
              <p className="email">{user.Email}</p>
            </div>
          </UserContainer>
        );
    }
  }
  return <div />;
};

export default User;
