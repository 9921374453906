import { ErrorMessage } from "formik";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled.input<{ $error?: boolean }>`
  flex: 1;
  background-color: white;
  border-radius: 6px;
  padding: 4px 8px;
  color: #828d9e;
  font-family: "Gotham-book";
  font-size: 14px;
  outline-width: 0;
  border: 2px solid ${({ $error }) => ($error ? "#db232c" : "#EDECEC")};
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
  :disabled {
    background-color: #edecec;
    border-color: #edecec;
  }
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  color: #db232c;
  text-align: left;
  font-family: "Gotham-book";
  font-size: 14px;
`;
