import styled from "styled-components";

export const ItemContent = styled.div<{ $active: boolean; $isOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${({ $isOpen }) => ($isOpen ? "8px" : "0")};
  border-radius: 6px;
  width: 100%;
  padding: ${({ $isOpen }) => ($isOpen ? "6px 15px" : "6px")};
  background: ${({ $active }) => ($active ? "#1f9334" : "transparent")};
  cursor: pointer;
  user-select: none;

  transition: background 0.35s ease, width 0.35s ease, padding 0.35s ease,
    gap 0.35s ease;
  :hover {
    background: ${({ $active }) => ($active ? "#1f9334" : "#14487c")};
  }
`;

export const ProfileItemContent = styled.div<{
  $active: boolean;
  $isOpen?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${({ $isOpen }) => ($isOpen ? "8px" : "0")};
  border-radius: 6px;
  width: 100%;
  padding: ${({ $isOpen }) => ($isOpen ? "4px 12px" : "4px 3px")};
  background: ${({ $active }) => ($active ? "#1F9334" : "transparent")};
  cursor: pointer;
  user-select: none;

  transition: width 0.35s ease, padding 0.35s ease, gap 0.35s ease;
`;

export const Text = styled.div<{ $isOpen?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;

  opacity: ${({ $isOpen }) => ($isOpen ? "1" : "0")};
  width: ${({ $isOpen }) => ($isOpen ? "100%" : "0")};
  transition: width: 0.35s ease;
`;

export const IconContainer = styled.div`
  margin: auto;
  width: 25px;
  height: 25px;
  position: relative;

  .ant-badge {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ProfileIconContainer = styled.div`
  margin: auto;
  width: 30px;
  height: 30px;
  position: relative;

  .ant-badge {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Img = styled.img`
  width: 25px;
  height: auto;
  max-height: 25px;
`;

export const CountContainer = styled.div<{ $fontSize: string }>`
  position: absolute;
  right: -5px;
  top: -5px;
  color: #0064ad;
  background-color: white;
  font-size: ${({ $fontSize }) => $fontSize};
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
