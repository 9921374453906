import React, { useRef, useState } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import { noop as NOOP } from "lodash";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
//@ts-ignore
import emoji from "emoji-dictionary";
import { Row, Input, Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import {
  CustomImage,
  CustomLink,
  CustomList,
  CustomOrderedList,
} from "../../../components/MarkdownHelpers";
import IsRequired from "./helpers/IsRequired";

interface StringInputProps {
  value: string;
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  onChange: (value: string) => void;
  onBlur?: (e: any) => void;
  extraParams?: {
    center?: boolean;
    oneLineString?: boolean;
    tooltipOffset?: number;
    tooltipPlacement?: TooltipPlacement;
    inputPlaceHolder?: string;
    mb?: string;
  };
}

const InputContainer = styled.div<{ isConsult: boolean; mb?: string }>`
  ${({ isConsult, mb = "10px" }) => (isConsult ? "" : `margin-bottom: ${mb};`)}
  width: 100%;
  * {
    margin: 0;
    padding: 0;
  }
`;

const TextAreaStyled = styled(Input.TextArea)<{
  $borderColor: string;
  $background: string;
}>`
  border-radius: 6px;
  border: 2px solid;
  border-color: ${({ $borderColor }) => $borderColor};
  background-color: ${({ $background }) => $background};
  color: #828d9e;

  width: 100%;
  min-height: 30px;
  resize: none;
  padding: 6px 10px;

  transition: all 0.35s ease;

  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const ContentContainer = styled.div<{
  $borderColor: string;
  $background: string;
  $disabled: boolean;
  $oneLine: boolean;
}>`
  border-radius: 6px;
  border: 2px solid;
  border-color: ${({ $borderColor }) => $borderColor};
  background-color: ${({ $background }) => $background};
  color: #828d9e;

  width: 100%;
  ${({ $oneLine }) => ($oneLine ? "height: 30px" : "min-height: 32px")};
  overflow: ${({ $oneLine }) => ($oneLine ? "hidden" : "")};
  resize: none;
  padding: 2px 10px;

  transition: all 0.35s ease;
  ${({ $disabled }) =>
    !$disabled
      ? css`
          :hover {
            border-color: #0273e9 !important;
            box-shadow: none !important;
          }
        `
      : ""}
`;

const ReactMarkdownStyled = styled(ReactMarkdown)<{ $oneLine: boolean }>`
  width: 100%;
  max-width: 100%;
  overflow-wrap: break-word;

  * {
    ${({ $oneLine }) =>
      $oneLine
        ? `
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      `
        : `
          overflow-wrap: break-word;
      `}
  }
`;

const StringInput: React.FC<StringInputProps> = ({
  value = "",
  isConsult,
  disabled,
  required,
  onChange,
  onBlur = NOOP,
  extraParams = {
    center: false,
    oneLineString: false,
    inputPlaceHolder: "",
    mb: "10px",
  },
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [clickingLink, setClickingLink] = useState<boolean>(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const lengthLimit = extraParams.tooltipOffset;
  const tooltipPlacement = extraParams.tooltipPlacement;

  return (
    <InputContainer isConsult={isConsult} mb={extraParams.mb}>
      {isConsult ? (
        <Row
          justify={extraParams.center ? "center" : "start"}
          align="middle"
          style={
            extraParams.oneLineString
              ? {
                  height: "24px",
                  overflow: "hidden",
                  color: "#48505e",
                }
              : {
                  minHeight: "32px",
                  color: "#48505e",
                }
          }
        >
          {lengthLimit && value.length > lengthLimit ? (
            <Tooltip title={value} placement={tooltipPlacement}>
              <ReactMarkdownStyled
                children={`${_.truncate(value, { length: lengthLimit })}`
                  .replace(/\n/gi, "&nbsp; \n")
                  .replace(/:\w+:/gi, (name) => emoji.getUnicode(name) ?? name)}
                remarkPlugins={[remarkGfm, remarkBreaks]}
                $oneLine={!!extraParams.oneLineString}
                components={{
                  a: ({ children, href }) => {
                    return (
                      <CustomLink href={href} setClickingLink={setClickingLink}>
                        {children}
                      </CustomLink>
                    );
                  },
                  ul: ({ children }) => {
                    return <CustomList>{children}</CustomList>;
                  },
                  ol: ({ children }) => {
                    return <CustomOrderedList>{children}</CustomOrderedList>;
                  },
                }}
              />
            </Tooltip>
          ) : (
            <ReactMarkdownStyled
              children={value
                .replace(/\n/gi, "&nbsp; \n")
                .replace(/:\w+:/gi, (name) => emoji.getUnicode(name) ?? name)}
              remarkPlugins={[remarkGfm, remarkBreaks]}
              $oneLine={!!extraParams.oneLineString}
              components={{
                a: ({ children, href }) => {
                  return (
                    <CustomLink href={href} setClickingLink={setClickingLink}>
                      {children}
                    </CustomLink>
                  );
                },
                ul: ({ children }) => {
                  return <CustomList>{children}</CustomList>;
                },
                ol: ({ children }) => {
                  return <CustomOrderedList>{children}</CustomOrderedList>;
                },
              }}
            />
          )}
        </Row>
      ) : !disabled && editing ? (
        <TextAreaStyled
          value={value}
          onChange={({ target: { value } }) => {
            onChange(value);
          }}
          onBlur={() => {
            const auxVal = value.replace(
              /\*{2}(.*?)\*{2}/gi,
              (bold) => `**${_.trim(bold.replace(/\*/gi, ""))}**`
            );
            if (auxVal !== value) onChange(auxVal);
            setEditing(false);
            setClickingLink(false);
            onBlur();
          }}
          placeholder={extraParams.inputPlaceHolder}
          ref={inputRef}
          autoFocus
          disabled={disabled}
          autoSize={{ maxRows: 10 }}
          $borderColor={required ? (value ? "#edecec" : "#db232c") : "#edecec"}
          $background={disabled ? "#edecec" : "white"}
        />
      ) : (
        <ContentContainer
          $disabled={disabled}
          $borderColor={required ? (value ? "#edecec" : "#db232c") : "#edecec"}
          $background={disabled ? "#edecec" : "white"}
          $oneLine={!!extraParams.oneLineString}
          onClick={() => {
            if (!disabled && !clickingLink) {
              setEditing(true);
            }
          }}
          onKeyUp={({ key }) => {
            if (key === "Tab" && !disabled) setEditing(true);
          }}
          tabIndex={0}
        >
          <ReactMarkdownStyled
            children={
              !!value || disabled
                ? value
                    .replace(/\n/gi, "&nbsp;\n")
                    .replace(
                      /:\w+:/gi,
                      (name) => emoji.getUnicode(name) ?? name
                    )
                : extraParams.inputPlaceHolder || ""
            }
            remarkPlugins={[remarkGfm, remarkBreaks]}
            $oneLine={!!extraParams.oneLineString}
            components={{
              a: ({ children, href }) => {
                return (
                  <CustomLink href={href} setClickingLink={setClickingLink}>
                    {children}
                  </CustomLink>
                );
              },
              ul: ({ children }) => {
                return <CustomList>{children}</CustomList>;
              },
              img: ({ children, src, alt }) => {
                return (
                  <CustomImage src={src} alt={alt}>
                    {children}
                  </CustomImage>
                );
              },
              ol: ({ children }) => {
                return <CustomOrderedList>{children}</CustomOrderedList>;
              },
            }}
          />
        </ContentContainer>
      )}
      {required && !isConsult && !Boolean(value) && <IsRequired />}
    </InputContainer>
  );
};

export default StringInput;
