export const Languages = {
  ENG: {
    ModalTitleLabel: "Durations",
    StepGeneral: "General",
    StepFilter: "Filters",
    Continue: "Continue",
    SelectPlaceholder: "Select...",

    // General pannels
    GeneralLeftTitle: "Configure general parameters for your graph",
    GeneralLeftDescription:
      "First name your new graph, choose the process for which you want to graph the durations of its trackings and/or tasks. Finally, select the graph format you want to display.",

    GeneralLeftNameLabel: "Name",
    GeneralLeftNamePlaceholder: "Durations graph...",

    GeneralLeftProcessLabel: "Process",
    GeneralLeftProcessPlaceholder: "Select a process...",

    GeneralLeftFormatStackbar: "All trackings",
    GeneralLeftFormatAll: "Trackings and averages",
    GeneralLeftFormatDoughnut: "Only averages",

    Previsualization: "Previsualization",

    // Filters pannels
    SelectTheFiltersYouWant: "Select the filters you want!",
    FilterByPlaceholder: "Filter by...",
    FilterLabel: "Filter",
    ApplyFilterLabel: "Apply filter",
    DatePickerPlaceholder: "Select...",
    AddGraphLabel: "Add graph",
    UpdateGraphLabel: "Update graph",
  },
  ESP: {
    ModalTitleLabel: "Duraciones",
    StepGeneral: "General",
    StepFilter: "Filtros",
    Continue: "Continuar",
    SelectPlaceholder: "Selecciona...",

    // General pannels
    GeneralLeftTitle: "Configura los parámetros generales de tu gráfica",
    GeneralLeftDescription:
      "Primero nombra tu nueva gráfica, elige el proceso del cual quieres graficar las duraciones de sus seguimientos y/o tareas. Finalmente selecciona el formato de gráfica que quieres visualizar.",

    GeneralLeftNameLabel: "Nombre",
    GeneralLeftNamePlaceholder: "Gráfica de duraciones...",

    GeneralLeftProcessLabel: "Proceso",
    GeneralLeftProcessPlaceholder: "Selecciona un proceso...",

    GeneralLeftFormatStackbar: "Detallado",
    GeneralLeftFormatAll: "Detallado y totales",
    GeneralLeftFormatDoughnut: "Totales",

    Previsualization: "Previsualización",

    // Filters pannels
    SelectTheFiltersYouWant: "¡Selecciona los filtros que deseas!",
    FilterByPlaceholder: "Filtrar por...",
    FilterLabel: "Filtro",
    ApplyFilterLabel: "Aplicar filtro",
    DatePickerPlaceholder: "Seleciona...",
    AddGraphLabel: "Agregar gráfica",
    UpdateGraphLabel: "Actualizar gráfica",
  },
};
