import React from "react";
import { Row } from "antd";
import { useField } from "formik";

import { ErrorMessageStyle, StyledInput } from "./Style";

const FormikInput = (props) => {
  const [field] = useField(props);

  return (
    <Row style={{ width: "80%" }}>
      <StyledInput
        {...field}
        {...props}
        inputProps={{ autoComplete: "off" }}
        autoComplete="off"
      />
      <ErrorMessageStyle name={props.name} component="div" />
    </Row>
  );
};

export default FormikInput;
