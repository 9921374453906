import React, { useContext, useEffect, useState } from "react";
import {
  Content,
  Title,
  Subtitle,
  ButtonContainer,
  HeaderRow,
  SelectTitleContainer,
  SelectRow,
  HeaderContent,
  SubtitleWrapper,
  FirstTimeWrapper,
} from "./styles";
import { AiOutlineInfoCircle, AiOutlinePlus } from "react-icons/ai";
import GeestSelect from "../../../../../../../GeestUI/GeestSelect";
import { Button } from "../../../../../../../GeestUI";
import { VisualizationStepProps, Step2Response } from "./Steps.d";
import Tooltip from "../../../../../../../components/Tooltip";
import { useFetch, useMutation } from "../../../../../../../hooks";
import { useParams } from "react-router-dom";
import { Row } from "antd";
import DataTypeIcons from "../../../../../../../components/DataTypeIcons";
import { TableData } from "../../../../../DashboardGraph/DynamicGraph/StatsTable/StatsTable.d";
import StatsTable from "../../../../../DashboardGraph/DynamicGraph/StatsTable";
import { DateOptionValue } from "../../StatsModal.d";
import DateInput from "../../../../../../../components/DynamicInput/Components/DateInput";
import TableIcon from "../../table.svg";
import ProcessIcon from "../../process.svg";
import { MessagesContext } from "../../../../../../../components/AppMessages";
import _ from "lodash";

const VisualizationStep: React.FC<VisualizationStepProps> = ({
  TableName,
  TemplateName,
  IdGraph = "",
  setIdGraph,
  setState,
  IdGraphTemplate,
  IdProcessTemplate,
  setGroupByField,
  groupByField,
  dateOption,
  setDateOption,
  stats,
  setStats,
  firstTime,
  setFirstTime,
  onRemoveStat,
  onEditStat,
  onClose,
  setUnsavedChanges,
}) => {
  const [tableData, setTableData] = useState<TableData | undefined>();
  const { ids } = useParams<{ ids: string }>();
  const [, idDashboard] = ids?.split("-");

  const { showMessage } = useContext(MessagesContext);

  const getFilters = () => {
    const dateOptionsFormat = {
      Key: dateOption?.key,
      Value: dateOption?.value,
      Start: dateOption?.Start,
      End: dateOption?.End,
    };

    if (dateOption?.value === "Personalized") {
      if (dateOptionsFormat.Start && dateOptionsFormat.End) {
        return [dateOptionsFormat];
      }
    } else {
      return [dateOptionsFormat];
    }

    return [];
  };

  const { data } = useFetch<Step2Response>({
    func: "Ver2-DashBoards-ggcw",
    args: {
      IdProcessTemplate,
      IdGraph,
      IdGraphTemplate,
      Step: 2,
      IdDashBoard: idDashboard,
      Filters: getFilters(),
    },
    onSuccess: (data) => {
      const { FieldsCollection, GroupBy } = data;
      if (GroupBy && !Array.isArray(GroupBy) && !groupByField) {
        setGroupByField(GroupBy);
      }

      if (GroupBy && !Array.isArray(GroupBy) && (GroupBy as any).IdField) {
        if (
          !FieldsCollection.find(
            ({ IdField }) => IdField === (GroupBy as any)?.IdField
          )
        ) {
          setGroupByField(undefined);
        }
      } else {
        if (!FieldsCollection.find(({ IdField }) => IdField === groupByField)) {
          setGroupByField(undefined);
        }
      }

      if (data.Filters.length > 0) {
        let option: any = data.Filters[0];
        option = { key: option.Key, value: option.Value, ...option };

        if (option.Value === "Personalized") {
          option = { ...option, Start: option.Start, End: option.End };
        }

        setDateOption(option);
      }
    },
  });

  const [registerGraph] = useMutation<string>({
    func: "Ver2-DashBoards-rg",
    onSuccess: (idGraph) => {
      setIdGraph(idGraph);
      onClose(true);
    },
  });

  const onRegisterGraph = () => {
    const dateOptionsFormat = {
      Key: dateOption?.key,
      Value: dateOption?.value,
      Start: dateOption?.Start,
      End: dateOption?.End,
    };
    if (dateOption?.value === "Personalized") {
      if (dateOptionsFormat.Start && dateOptionsFormat.End) {
        registerGraph({
          args: {
            IdProcessTemplate,
            IdDashBoard: idDashboard,
            GraphName: TableName,
            IdGraphTemplate,
            Filters: [dateOptionsFormat],
            Stats: stats,
            IdGraph: IdGraph,
            GroupBy: data?.FieldsCollection.find(
              ({ IdField }) =>
                IdField === groupByField ||
                IdField === (groupByField as any)?.IdField
            ),
          },
        });
      }
    } else {
      registerGraph({
        args: {
          IdProcessTemplate,
          IdDashBoard: idDashboard,
          GraphName: TableName,
          IdGraphTemplate,
          Filters: [dateOptionsFormat],
          Stats: stats,
          IdGraph: IdGraph,
          GroupBy: data?.FieldsCollection.find(
            ({ IdField }) =>
              IdField === groupByField ||
              IdField === (groupByField as any)?.IdField
          ),
        },
      });
    }
  };

  const [getTablePreview] = useMutation<TableData>({
    func: "Ver2-DashBoards-gptp",
    onSuccess: (data, shippedData) => {
      setTableData(data);

      if (shippedData?.updatedStats) {
        showMessage("Estadisticas actualizadas", "success");
      }
    },
  });

  const handleReorderStats = (newStats: number[]) => {
    setStats(newStats);

    const updatedStats = _.isEqual(newStats, stats);
    if (!updatedStats) setUnsavedChanges();

    const groupBy = data?.FieldsCollection.find(
      ({ IdField }) =>
        IdField === groupByField || IdField === (groupByField as any)?.IdField
    );

    const dateOptionsFormat = {
      Key: dateOption?.key,
      Value: dateOption?.value,
      Start: dateOption?.Start,
      End: dateOption?.End,
    };

    if (dateOption?.value === "Personalized") {
      if (dateOptionsFormat.Start && dateOptionsFormat.End) {
        getTablePreview({
          args: {
            IdProcessTemplate,
            IdDashBoard: idDashboard,
            GraphName: TableName,
            IdGraphTemplate,
            Filters: [dateOptionsFormat],
            GroupBy: groupBy,
            Stats: newStats,
          },
          shippedData: {
            updatedStats: !updatedStats,
          },
        });
      }
    } else {
      getTablePreview({
        args: {
          IdProcessTemplate,
          IdDashBoard: idDashboard,
          GraphName: TableName,
          IdGraphTemplate,
          Filters: [dateOptionsFormat],
          GroupBy: groupBy,
          Stats: newStats,
        },
        shippedData: {
          updatedStats: !updatedStats,
        },
      });
    }
  };

  useEffect(() => {
    const groupBy = data?.FieldsCollection.find(
      ({ IdField }) =>
        IdField === groupByField || IdField === (groupByField as any)?.IdField
    );
    if (stats.length > 0) {
      const dateOptionsFormat = {
        Key: dateOption?.key,
        Value: dateOption?.value,
        Start: dateOption?.Start,
        End: dateOption?.End,
      };
      if (dateOption?.value === "Personalized") {
        if (dateOptionsFormat.Start && dateOptionsFormat.End) {
          getTablePreview({
            args: {
              IdProcessTemplate,
              IdDashBoard: idDashboard,
              GraphName: TableName,
              IdGraphTemplate,
              Filters: [dateOptionsFormat],
              GroupBy: groupBy,
              Stats: stats,
            },
          });
        }
      } else {
        getTablePreview({
          args: {
            IdProcessTemplate,
            IdDashBoard: idDashboard,
            GraphName: TableName,
            IdGraphTemplate,
            Filters: [dateOptionsFormat],
            GroupBy: groupBy,
            Stats: stats,
          },
        });
      }
    } else {
      setTableData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IdProcessTemplate, groupByField, dateOption, stats, data]);

  const handleSetDateOption = (option: string) => {
    setUnsavedChanges();
    let dateOption: DateOptionValue | undefined = data?.DateOptions.find(
      ({ value }) => value === option
    );
    if (dateOption?.value === "Personalized") {
      dateOption = { ...dateOption, Start: "", End: "" };
    }
    setDateOption(dateOption);
  };

  const fieldOptions = data?.FieldsCollection.map(
    ({ Label, IdField, DataType }) => ({
      label: (
        <Row align="middle">
          <Row
            align="middle"
            justify="center"
            style={{
              width: "26px",
              height: "26px",
              display: "flex",
            }}
            wrap={false}
          >
            <img
              style={{
                width: "14px",
                height: "14px",
                ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
              }}
              alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
              src={DataTypeIcons[DataType?.toLowerCase()].icon}
            />
          </Row>
          {Label.length >= 50 ? (
            <Tooltip title={Label} placement="right">
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {Label}
              </div>
            </Tooltip>
          ) : (
            <div
              style={{
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {Label}
            </div>
          )}
        </Row>
      ),
      value: IdField,
      searchableValues: Label,
    })
  );

  return (
    <>
      <Content>
        <HeaderContent>
          <HeaderRow>
            <Title $fontSize="24px">
              Configura la Visualización de tu tabla
              <Tooltip
                title="Aquí podrás configurar tu tabla, solo considera 
              que la edición es en esta vista y no en el dashboard"
              >
                <AiOutlineInfoCircle size={20} />
              </Tooltip>
            </Title>
            {!firstTime && (
              <Button
                type="secondary"
                style={{ width: "183px" }}
                Icon={AiOutlinePlus}
                onClick={() => setState("Measurements")}
              >
                Agregar columna
              </Button>
            )}
          </HeaderRow>
          <SubtitleWrapper>
            <Subtitle>
              <img src={TableIcon} alt="" />
              <p>{TableName}</p>
            </Subtitle>
            <Subtitle>
              <img src={ProcessIcon} alt="" />
              <p>{TemplateName}</p>
            </Subtitle>
          </SubtitleWrapper>
        </HeaderContent>

        {firstTime || !Boolean(tableData) ? (
          <FirstTimeWrapper>
            <Button
              type="secondary"
              style={{ width: "183px" }}
              Icon={AiOutlinePlus}
              onClick={() => setState("Measurements")}
            >
              Agregar columna
            </Button>
          </FirstTimeWrapper>
        ) : (
          <>
            <SelectRow>
              <SelectTitleContainer>
                <Title>Selecciona el campo de agrupación</Title>
                <GeestSelect
                  value={
                    typeof groupByField === "object"
                      ? (groupByField as any).IdField
                      : groupByField
                  }
                  onChange={(groupBy) => {
                    setGroupByField(groupBy);
                    setUnsavedChanges();
                  }}
                  options={fieldOptions || []}
                  $width="330px"
                />
              </SelectTitleContainer>
              <SelectTitleContainer>
                <Title>Filtrar periodo</Title>
                <GeestSelect
                  value={dateOption?.value}
                  onChange={(option) => {
                    handleSetDateOption(option);
                    setUnsavedChanges();
                  }}
                  options={data?.DateOptions || []}
                  $width="188px"
                  placeholderSelect="Selecciona..."
                  disableSearch
                />
              </SelectTitleContainer>
              {dateOption?.value === "Personalized" && (
                <>
                  <SelectTitleContainer>
                    <Title>Inicio</Title>
                    <DateInput
                      value={dateOption.Start ?? ""}
                      onChange={(newDate: string | null) =>
                        setDateOption({ ...dateOption, Start: newDate ?? "" })
                      }
                      isConsult={false}
                      disabled={false}
                      required={false}
                      extraParams={{ mb: "0" }}
                    />
                  </SelectTitleContainer>
                  <SelectTitleContainer>
                    <Title>Fin</Title>
                    <DateInput
                      value={dateOption.End ?? ""}
                      onChange={(newDate: string | null) =>
                        setDateOption({ ...dateOption, End: newDate ?? "" })
                      }
                      isConsult={false}
                      disabled={false}
                      required={false}
                      extraParams={{ mb: "0" }}
                    />
                  </SelectTitleContainer>
                </>
              )}
            </SelectRow>
            {tableData && (
              <StatsTable
                data={tableData}
                execution={false}
                stats={stats}
                setStats={handleReorderStats}
                onRemoveStat={onRemoveStat}
                onEditStat={onEditStat}
              />
            )}
          </>
        )}
      </Content>

      <ButtonContainer style={{ marginTop: "6px" }}>
        <Button
          type="primary"
          size="large"
          onClick={onRegisterGraph}
          disabled={!tableData}
        >
          Guardar
        </Button>
      </ButtonContainer>
    </>
  );
};

export default VisualizationStep;
