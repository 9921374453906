import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import DragDropFile from "./DragDropFile";
import ColumnsSelect from "./ColumnsSelect";
import { Languages } from "./Dictionary";
import {
  ImportDBModalProps,
  ImportcolumnBack,
  ImportingColumns,
  VarDBColumn,
} from "./ImportDBModal.d";
import {
  ContentWrapper,
  LoadingContainer,
  ModalContent,
  ModalTitle,
  ModalWrapper,
} from "./Styles";
import Tooltip from "../../../../components/Tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";
import FinishStep from "./FinishStep";
import { useMutation } from "../../../../hooks";
import Loading from "../../../../components/Loading";
import { CloseIcon } from "../../../../components/hoverIcons";

const ImportDBModal: React.FC<ImportDBModalProps> = ({
  IdTeam,
  IdVarDB,
  DBName,
  onClose,
  reload = () => {},
}) => {
  const { ImportDataBase, ImportLabel, TooltipLabel, SuccessfullyImported } =
    Languages["ESP"];
  const [step, setStep] = useState<number>(0);
  const [fileName, setFileName] = useState<string>("");
  const [fileContent, setFileContent] = useState<string>("");
  const [varDBColumns, setVarDBColumns] = useState<VarDBColumn[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<ImportingColumns[]>(
    []
  );
  const [importFirstRow, setImportFirstRow] = useState<boolean>(false);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const progressLabels = {
    20: "Importando archivo",
    40: "Importando archivo",
    60: "Importando archivo",
    80: "Importando archivo",
    100: "Listo",
  };

  useEffect(() => {
    if (!uploadingFile) return;
    const firstTime = Math.random() * 1000;
    const secondTime = Math.random() * 1000 + firstTime;
    const thirdTime = Math.random() * 1000 + secondTime;
    const fourthTime = Math.random() * 1000 + thirdTime;
    const fifthTime = Math.random() * 1000 + fourthTime;

    setTimeout(() => {
      if (!fileName) {
        setProgress(20);
      }
    }, firstTime);

    setTimeout(() => {
      if (!fileName) {
        setProgress(40);
      }
    }, secondTime);

    setTimeout(() => {
      if (!fileName) {
        setProgress(60);
      }
    }, thirdTime);

    setTimeout(() => {
      if (!fileName) {
        setProgress(80);
      }
    }, fourthTime);

    setTimeout(() => {
      if (!fileName) {
        setProgress(100);
      }
    }, fifthTime);
  }, [uploadingFile, fileName]);

  const [ImportExcelToVardb, importing] = useMutation({
    func: "Ver2-Vardbs-ietv",
    onSuccess: () => {
      setStep(2);
      reload();
    },
  });

  const handleResetData = () => {
    setStep(0);
    setFileName("");
    setFileContent("");
    setVarDBColumns([]);
    setSelectedColumns([]);
  };

  const handleOnImport = () => {
    let importColumns = {} as ImportcolumnBack;

    selectedColumns.forEach((column) => {
      if (column.IdColumnSelected === 0) return;
      importColumns[column.Id] = column.IdColumnSelected;
    });

    ImportExcelToVardb({
      args: {
        IdVarDB,
        IdTeam,
        Content: fileContent,
        ImportFirstRow: importFirstRow,
        ImportColumns: importColumns,
      },
    });
  };

  return (
    <Modal open onClose={onClose}>
      <ModalWrapper onClick={onClose}>
        <ModalContent
          onClick={(e) => e.stopPropagation()}
          $width="700px"
          $height="auto"
          $maxHeight="570px"
        >
          <ModalTitle>
            {step === 0 && <p>{ImportDataBase}</p>}

            {step === 1 && (
              <div className="info-wrapper">
                <p>{ImportLabel}</p>
                <Tooltip
                  mui
                  title={TooltipLabel}
                  // @ts-ignore
                  placement="bottom-start"
                >
                  <p>
                    <AiOutlineInfoCircle />
                  </p>
                </Tooltip>
              </div>
            )}

            {step === 2 && <p>{SuccessfullyImported}</p>}

            <CloseIcon size={20} onClick={onClose} />
          </ModalTitle>

          {(importing || uploadingFile) && (
            <LoadingContainer>
              <Loading
                progressBar
                progress={progress}
                progressLabels={progressLabels}
              />
            </LoadingContainer>
          )}

          <ContentWrapper>
            {step === 0 && (
              <DragDropFile
                IdTeam={IdTeam}
                IdVarDB={IdVarDB}
                importing={importing}
                setUploadingFile={setUploadingFile}
                setProgress={setProgress}
                step={step}
                goNextStep={() => setStep(1)}
                setFileName={setFileName}
                setFileContent={setFileContent}
                setVarDBColumns={setVarDBColumns}
                setSelectedColumns={setSelectedColumns}
              />
            )}
            {step === 1 && (
              <ColumnsSelect
                fileName={fileName}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                varDBColumns={varDBColumns}
                importFirstRow={importFirstRow}
                setImportFirstRow={setImportFirstRow}
                importing={importing}
                goBack={handleResetData}
                handleOnImport={handleOnImport}
              />
            )}

            {step === 2 && (
              <FinishStep
                fileName={fileName}
                DBName={DBName}
                onClose={onClose}
              />
            )}
          </ContentWrapper>
        </ModalContent>
      </ModalWrapper>
    </Modal>
  );
};

export default ImportDBModal;
