import React from "react";
import { Row, Progress, Space } from "antd";
import styled from "styled-components";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { IoInfinite } from "react-icons/io5";

const ProgressBar = styled(Progress)`
  .ant-progress-inner {
    border-radius: 0.5rem;
    background: none;
    border: solid 1px #4488c7;
  }
  .ant-progress-bg {
    border-radius: 0.1rem;
  }
`;

const BarType = ({ limit, planColor }) => {
  const {
    Label = "",
    Value = 0,
    ValueLabel = "",
    PlanLimitValue = 0,
    PlanLimitLabel = "",
    StoragePercentage = 0,
  } = limit;
  const { gradientColors = [] } = planColor;
  return (
    <div>
      <Row justify="space-between">
        <div>{Label}</div>
        <div>
          {Value}
          {ValueLabel}/{PlanLimitValue}
          {PlanLimitLabel}
        </div>
      </Row>
      <ProgressBar
        showInfo={false}
        status="active"
        strokeColor={`linear-gradient(to right, ${gradientColors.join(",")})`}
        strokeWidth={45}
        percent={StoragePercentage}
      />
    </div>
  );
};

const BoolType = ({ limit, planColor }) => {
  const { Label, Value } = limit;
  const { mainColor } = planColor;
  const Indicator = Value ? BiCheckCircle : BiXCircle;
  return (
    <Row align="middle">
      <Indicator
        size={28}
        color={mainColor}
        style={{ marginRight: "0.5rem" }}
      />
      <span>{Label}</span>
    </Row>
  );
};

const ValueType = ({ limit, planColor }) => {
  const { Label, Value } = limit;
  const { mainColor } = planColor;
  const ValueText =
    Value === -1 ? (
      <IoInfinite size={28} color={mainColor} />
    ) : (
      <span style={{ color: mainColor }}>{Value}</span>
    );
  return (
    <Row align="middle" justify="space-between">
      <div>{Label}</div>
      {ValueText}
    </Row>
  );
};

const LeftValueType = ({
  limit: { Label, PlanLimitValue },
  planColor: { mainColor },
}) => (
  <Row align="middle" justify="space-between">
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <span style={{ color: mainColor }}>
        {PlanLimitValue === -1 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IoInfinite size={28} color={mainColor} />
          </div>
        ) : (
          PlanLimitValue
        )}
      </span>{" "}
      {Label}
    </div>
  </Row>
);

const Limits = ({ limits, planColor = {} }) => {
  return (
    <Space direction="vertical" size="middle" style={{ fontSize: "22px" }}>
      {limits.map((limit = {}, key) => {
        switch (limit.Type) {
          case "Bar":
            return <BarType limit={limit} planColor={planColor} key={key} />;
          case "Bool":
            return <BoolType limit={limit} planColor={planColor} key={key} />;
          case "Value":
            return <ValueType limit={limit} planColor={planColor} key={key} />;
          case "LeftValue":
            return (
              <LeftValueType limit={limit} planColor={planColor} key={key} />
            );

          default:
            return <div key={key}>{limit.Label}</div>;
        }
      })}
    </Space>
  );
};

export default Limits;
