import { Row, Tag } from "antd";
import styled from "styled-components";
import { StyledInput as GenericInput } from "../../Flow/styles";

// <ChatItem />

export const ItemRow = styled(Row)`
  padding: 10px 0;
  user-select: none;
`;

export const ChatLabel = styled.span`
  color: #48505e;
  font-size: 14px;
  font-family: Gotham-Book;
`;

// <EditChatModal />

export const Label = styled.div`
  color: #828d9e;
  text-align: center;
  font-family: "Gotham-book";
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 12px;
`;

export const List = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 0 40px;
`;

// <TeamRoleItem />

export const ListItem = styled(ItemRow)<{ $selected: boolean }>`
  margin: 5px 0;
  padding: 5px;
  background-color: ${({ $selected }) => ($selected ? "#EDECEC" : "#FFFFFF")};
  cursor: pointer;
  border-radius: 6px;
  :hover {
    background: #edecec;
  }
`;

export const StyledTag = styled(Tag)`
  padding: 3px 10px;
  border-radius: 6px;
  font-family: "Gotham-book";
`;

// <TeamRoleEditor />

export const StyledInput = styled(GenericInput)`
  flex: 1;
  margin-right: 14px;
`;
