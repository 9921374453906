import React from "react";
import { Modal } from "@material-ui/core";
import {
  StyledPaper,
  TitleRow,
  Content,
  Description,
  LimitContainer,
  LimitTitle,
  LimitDescription,
  LimitBadge,
  ButtonContainer,
} from "./styles";
import { ModalProps } from "./SubscriptionLimit.d";
import { Row } from "antd";
import { CloseIcon } from "../hoverIcons";
import { Button } from "../../GeestUI";
import { useHistory } from "react-router-dom";
import { useMutation } from "../../hooks";
import { useApoloMutation } from "../../hooks";

const SubscriptionLimitModal: React.FC<ModalProps> = ({
  SubscriptionLimit,
  onClose,
}) => {
  const Colors = SubscriptionLimit.Limitation.Colors;
  const ButtonLabels: { [key: string]: string } = {
    NotifyLeader: "Avisa a tu líder",
    ShowPlans: "Ver planes",
    GetBudget: "Solicitar presupuesto",
  };
  const history = useHistory();
  const [changeSubsctriptionPlan, loadingPlan] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: () => {
      history.push(`/home/premium/${SubscriptionLimit.IdTeam}`);
      onClose();
    },
  });

  const [notifyTeamLeader, loadingNotifyLeader] = useApoloMutation({
    route: "notifications/notifyLeader",
    method: "post",
    onSuccess: onClose,
  });

  const [requestBudget, loadingRequestBudget] = useApoloMutation({
    route: "notifications/requestSubscriptionBudget",
    method: "post",
    onSuccess: onClose,
  });

  const onClickButton = () => {
    switch (SubscriptionLimit.Action) {
      case "ShowPlans":
        changeSubsctriptionPlan({
          args: {
            IdTeam: SubscriptionLimit.IdTeam,
            NextState: "ChangePlan",
          },
        });
        break;
      case "NotifyLeader":
        notifyTeamLeader({
          body: {
            IdTeam: SubscriptionLimit.IdTeam,
            LimitType: SubscriptionLimit.LimitType,
          },
        });
        break;
      case "GetBudget":
        requestBudget({
          body: {
            IdTeam: SubscriptionLimit.IdTeam,
          },
        });
        break;
      default:
        console.log("Unknown action: ", SubscriptionLimit.Action);
    }
  };

  return (
    <Modal onClose={onClose} open style={{ opacity: 1, zIndex: 9990 }}>
      <Row
        justify="center"
        align="middle"
        onClick={onClose}
        style={{ width: "100%", height: "100%" }}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <TitleRow>
            ¡Ups! suscripción limitada
            <CloseIcon onClick={onClose} />
          </TitleRow>
          <Content>
            <Description>{SubscriptionLimit.Description}</Description>
            <LimitContainer $backgroundColor={Colors.BackgroundColor}>
              <LimitTitle $fontColor={Colors.FontColor}>
                {SubscriptionLimit.Limitation.PlanName}
                <LimitBadge $badgeColor={Colors.BadgeColor}>
                  {SubscriptionLimit.Limitation.LimitValue}
                </LimitBadge>
              </LimitTitle>
              <LimitDescription $fontColor={Colors.FontColor}>
                {SubscriptionLimit.Limitation.Description}
              </LimitDescription>
            </LimitContainer>
          </Content>
          <ButtonContainer>
            <Button
              type="primary"
              style={{ width: "263px", marginBottom: "12px" }}
              onClick={onClickButton}
              loading={
                loadingPlan || loadingNotifyLeader || loadingRequestBudget
              }
            >
              {ButtonLabels[SubscriptionLimit.Action]}
            </Button>
          </ButtonContainer>
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default SubscriptionLimitModal;
