import React, { useState } from "react";
import { Row, Switch, Radio } from "antd";
import {
  Container,
  Title,
  Description,
  SubTitle,
  ScrollOptionsContainer,
  ScrollOption,
} from "./styles";
import OpenNextTaskIcon from "../../../resources/img/OpenNextTask.svg";
import { useFetch, useMutation } from "../../../hooks";
import doubleScrollIcon from "../../../resources/img/DoubleScroll.svg";
import singleScrollIcon from "../../../resources/img/SingleScroll.svg";

export const Configuration: React.FC = () => {
  const [openNextTask, setOpenNextTask] = useState<boolean>(false);
  const [pendingView, setPendingView] = useState<string>("SingleScroll");

  useFetch<{
    OpenNextTask: boolean;
    PendingView: string;
  }>({
    func: "Ver2-Settings-guc",
    onSuccess: ({ OpenNextTask, PendingView }) => {
      setOpenNextTask(OpenNextTask);
      setPendingView(PendingView);
    },
  });

  const [changeOpenNextTaskConfiguration] = useMutation({
    func: "Ver2-Settings-uuc",
    onSuccess: (res, { checked }) => setOpenNextTask(checked),
  });

  const onChangeConfiguration = (checked: boolean, pendingView: string) => {
    changeOpenNextTaskConfiguration({
      args: {
        ShouldOpenNextTask: checked,
        PendingView: pendingView,
      },
      shippedData: { checked },
    });
  };

  return (
    <Row justify="center">
      <Container>
        <Title>Configuración</Title>
        <Row style={{ width: "449px" }} justify="space-between" align="middle">
          <img
            style={{
              width: "24px",
              height: "24px",
            }}
            src={OpenNextTaskIcon}
            alt="openNext"
          />
          <Description>
            Cuando termino una tarea, abrir automáticamente la siguiente tarea
            (si se inició alguna)
          </Description>
          <Switch
            checked={openNextTask}
            onChange={(checked) => onChangeConfiguration(checked, pendingView)}
          />
        </Row>
        <SubTitle>
          Selecciona la forma de visualización del detalle de tarea:
        </SubTitle>
        <Radio.Group
          value={pendingView}
          onChange={(e) => {
            setPendingView(e.target.value);
            onChangeConfiguration(openNextTask, e.target.value);
          }}
        >
          <ScrollOptionsContainer>
            <ScrollOption>
              <img
                alt="double"
                src={singleScrollIcon}
                width="151px"
                height="125px"
                onClick={() => {
                  setPendingView("SingleScroll");
                  onChangeConfiguration(openNextTask, "SingleScroll");
                }}
              />
              <Radio value="SingleScroll" />
              Normal
            </ScrollOption>
            <ScrollOption>
              <img
                alt="double"
                src={doubleScrollIcon}
                width="151px"
                height="125px"
                onClick={() => {
                  setPendingView("MultiScroll");
                  onChangeConfiguration(openNextTask, "MultiScroll");
                }}
              />
              <Radio value="MultiScroll" />
              Seccionado
            </ScrollOption>
          </ScrollOptionsContainer>
        </Radio.Group>
      </Container>
    </Row>
  );
};

export default Configuration;
