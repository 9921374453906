import { Popover } from "@material-ui/core";
import { Row } from "antd";
import React, { useRef, useState } from "react";
import { CloseIcon } from "../../../../../../components/hoverIcons";
import { BsSortAlphaDown } from "react-icons/bs";

interface OrderPopupProps {}

const OrderPopup: React.FC<OrderPopupProps> = ({ children }) => {
  const popoverRef = useRef(null);
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      <Popover
        open={visible}
        onClick={(e) => e.stopPropagation()}
        anchorEl={popoverRef.current}
        onClose={() => setVisible(false)}
        PaperProps={{
          style: {
            borderRadius: "6px",
            width: "282px",
            padding: "12px 16px",
            marginTop: "1rem",
            marginLeft: "1.5rem",
            fontFamily: "Gotham-Book",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        title="Ordenar por"
      >
        <Row
          justify="space-between"
          align="middle"
          style={{
            fontFamily: "Gotham-Bold",
            fontSize: "16px",
            fontWeight: "700",
            marginBottom: "1rem",
          }}
        >
          Ordenar por
          <CloseIcon
            onClick={(e) => {
              setVisible(false);
              e.stopPropagation();
            }}
          />
        </Row>
        <div style={{ padding: "12px" }}>{children}</div>
      </Popover>
      <Row
        align="middle"
        justify="center"
        ref={popoverRef}
        style={{
          height: "20px",
          widows: "20px",
          backgroundColor: visible
            ? "rgba(25, 57, 183, 1)"
            : "rgba(2, 115, 233, 1)",
          borderRadius: "2px",
        }}
      >
        <BsSortAlphaDown
          size={18}
          style={{
            cursor: "pointer",
            color: "white",
          }}
          onClick={(e) => {
            setVisible(true);
            e.stopPropagation();
          }}
        />
      </Row>
    </>
  );
};

export default OrderPopup;
