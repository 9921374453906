import styled from "styled-components";

export const PlaygroundContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;

export const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  background: #48505e;
  padding: 4px 6px;
  border-radius: 6px;
`;

export const TabContainer = styled.div<{ $selected: boolean }>`
  padding: 4px 6px;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  user-select: none;
  background: ${({ $selected = false }) => ($selected ? "#1890ff" : "#48505e")};

  transition: background 0.35s ease;
  p {
    margin: 0;
  }
`;
