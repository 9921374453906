import React, { useState } from "react";
import { Popover, Row, Space, Tooltip } from "antd";
import styled, { css } from "styled-components";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IconContainer, iconSize } from "./styles";
import { Circle } from "../../../../../components/ColorPicker";
import { Stage, StageSelectorProps } from "./Toolbox.d";

const Content = styled(Row)`
  background-color: #48505e;
  color: white;
  padding: 12px 0;
  border-radius: 6px;
  min-width: 200px;
  flex-direction: column;
`;

export const NoStagesPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin: 0;
    font-family: Gotham-bold;
    font-family: 16px;
  }
`;

const Option = styled(Space)<{ $active: boolean }>`
  padding: 6px 12px;
  cursor: pointer;
  :hover {
    background-color: #0273e9;
  }
  ${({ $active }) =>
    $active &&
    css`
      background-color: #0273e9;
    `}

  .ant-space-item {
    display: flex;
    align-items: center;
  }
`;

const emptyStage: Stage = {
  Id: 0,
  Label: "",
  Color: "",
  IsDefaultStage: false,
};

const StageSelector: React.FC<StageSelectorProps> = ({
  activeStageId,
  activeStageColor,
  stages,
  onChangeStage,
}) => {
  const [open, setOpen] = useState(false);

  const onClickStage = (stage: Stage): void => {
    if (stage.Id === activeStageId) {
      onChangeStage(emptyStage);
    } else onChangeStage(stage);
    setOpen(false);
  };

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      placement="bottomLeft"
      overlayStyle={{
        backgroundColor: "transparent",
      }}
      overlayInnerStyle={{
        backgroundColor: "transparent",
        borderColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
        borderRadius: "6px",
      }}
      showArrow={false}
      align={{ offset: [-10, -10] }}
      content={
        <Content>
          {stages.length === 0 ? (
            <NoStagesPlaceholder>
              <p>Sin etapas...</p>
            </NoStagesPlaceholder>
          ) : (
            stages.map((stage) => (
              <Option
                key={stage.Label}
                $active={stage.Id === activeStageId}
                size={4}
                align="center"
                onClick={() => onClickStage(stage)}
              >
                <Circle
                  style={{
                    height: 20,
                    width: 20,
                    minWidth: 20,
                  }}
                  color={stage.Color}
                />
                {stage.Label}
                {stage.IsDefaultStage && (
                  <Tooltip
                    title="Esta etapa agrupa las tareas que forman parte de la prospección"
                    placement="bottomLeft"
                    color="white"
                    overlayInnerStyle={{
                      color: "black",
                      borderRadius: "2px",
                      padding: "6px 8px",
                    }}
                  >
                    <AiOutlineExclamationCircle size={18} />
                  </Tooltip>
                )}
              </Option>
            ))
          )}
        </Content>
      }
    >
      <IconContainer active={open}>
        <Circle
          color={activeStageColor || "#FFFFFF"}
          style={{ height: iconSize, width: iconSize, minWidth: iconSize }}
        />
      </IconContainer>
    </Popover>
  );
};

export default StageSelector;
