import styled from "styled-components";

export const TemplateImportsContainer = styled.div`
  width: 574px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TemplateImportsDescription = styled.div`
  width: 100%;
  text-align: justify;
  color: rgba(130, 141, 158, 1);
  font-size: 14px;
  line-height: 19.6px;
  margin-bottom: 2rem;
`;

export const ColumnTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(72, 80, 94, 1);
  font-size: 14px;
  font-family: Gotham-Bold;
`;

export const ImportItemsContainer = styled.div`
  width: 100%;
  min-height: 272px;
  margin-top: 1rem;
`;

export const ImportItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 52px;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(72, 80, 94, 0.3);
  margin-bottom: 1rem;
`;

export const BlueDiv = styled.div`
  height: 52px;
  width: 26px;
  background-color: rgba(2, 115, 233, 1);
`;

export const DarkBlueDiv = styled.div`
  height: 52px;
  width: 26px;
  background-color: rgba(20, 72, 124, 1);
`;

export const ImportDetail = styled.div`
  width: 246px;
  height: 52px;
  display: flex;
  background-color: rgba(255, 255, 255, 1);
  padding-left: 10px;
  align-items: center;
  line-height: 19.6px;
  color: rgba(72, 80, 94, 1);
  font-size: 14px;
  font-family: Gotham-Bold;
`;

export const AdvancedSettingsDecsription = styled.div`
  width: 100%;
  text-align: justify;
  color: rgba(130, 141, 158, 1);
  font-size: 12px;
  line-height: 16.8px;
  margin-bottom: 2rem;
`;

export const DataBasesContainer = styled.div`
  width: 100%;
  min-height: 336px;
`;

export const ImportedVarDBItem = styled.div`
  width: 236px;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: rgba(237, 236, 236, 1);
  border: 2px solid rgba(237, 236, 236, 1);
  padding-left: 6px;
  border-radius: 6px;
  color: rgba(130, 141, 158, 1);
  font-weight: 325;
  font-size: 14px;
  line-height: 19.6px;
  margin-bottom: 1.5rem;
`;

export const VarDBColumnsContainer = styled.div`
  width: 100%;
  min-height: 280px;
`;
