import React, { useContext, useState } from "react";
import _ from "lodash";
import { Button } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";
import { Languages } from "./Dictionary";
import { TeamMember } from "../../Teams.d";
import { MessagesContext } from "../../../../components/AppMessages";
import { InputStyled } from "../Style";
import { RoleTitle } from "../MembersList/Style";
import { CloseIcon } from "../../../../components/hoverIcons";
import styled from "styled-components";
import { Row } from "antd";

const Text = styled.div`
  text-align: center;
  font-size: 14px;
  font-family: Gotham-Book;
  width: 309px;
  color: #828d9e;
`;

const Container = styled.div`
  display: flex;
  wdith: 400px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 0px 10px;
  margin-top: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 14px;
`;

const {
  ReplaceDescription,
  EmailLabel,
  SuccessOnReplace,
  ErrorNewEmailEmpty,
  ErrorNewEmailInTeam,
} = Languages["ESP"];

interface ReplaceUserPopoverProps {
  idTeam: string;
  user: TeamMember | null;
  teamMembers: TeamMember[];
  title: string;
  okText: string;
  cancelText: string;
  setNoAccountUser: (name: string) => void;
  openRegisterNewUser: () => void;
  onClose: () => void;
  reload: () => void;
}

const ReplaceUserPopover: React.FC<ReplaceUserPopoverProps> = ({
  idTeam,
  user,
  teamMembers,
  title,
  okText,
  cancelText,
  setNoAccountUser,
  openRegisterNewUser,
  onClose,
  reload,
}) => {
  const [newUserEmail, setNewUserEmail] = useState<string>("");
  const { showMessage } = useContext(MessagesContext);

  const validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isNewUserEmailEmpty = _.isEmpty(_.trim(newUserEmail));
  const isNewUserEmailValid = validateEmail.test(_.trim(newUserEmail));
  const isNewUserOnTeam = !_.isEmpty(
    _.filter(teamMembers, { Email: newUserEmail })
  );

  const [replaceUserInTeam, replacing] = useMutation<[]>({
    func: "Ver2-Team-ruit",
    onSuccess: () => {
      showMessage(SuccessOnReplace, "success");
      onClose();
      reload();
    },
    onError: () => {
      setNoAccountUser(newUserEmail);
      setNewUserEmail("");
      openRegisterNewUser();
    },
  });

  const handleReplaceUser = () => {
    if (isNewUserEmailEmpty || !isNewUserEmailValid) {
      showMessage(ErrorNewEmailEmpty, "error");
    }
    if (isNewUserOnTeam) {
      showMessage(ErrorNewEmailInTeam, "error");
    }
    if (isNewUserEmailEmpty || !isNewUserEmailValid || isNewUserOnTeam) return;
    replaceUserInTeam({
      args: {
        IdTeam: idTeam,
        IdOldUser: user?.IdUser,
        Email: _.trim(newUserEmail),
      },
    });
  };

  return (
    <div style={{ width: "400px" }}>
      <Row justify="space-between">
        <RoleTitle>{title}</RoleTitle>
        <CloseIcon onClick={onClose} />
      </Row>
      <Container>
        <Text>{ReplaceDescription}</Text>

        <InputStyled
          style={{ width: "100%" }}
          placeholder={EmailLabel}
          value={newUserEmail}
          onChange={({ target: { value } }) => {
            setNewUserEmail(value);
          }}
        />
        <ButtonContainer>
          <Button onClick={onClose} loading={replacing} type="secondary">
            {cancelText}
          </Button>
          <Button
            onClick={handleReplaceUser}
            disabled={isNewUserEmailEmpty || !isNewUserEmailValid}
            loading={replacing}
            type="primary"
          >
            {okText}
          </Button>
        </ButtonContainer>
      </Container>
    </div>
  );
};

export default ReplaceUserPopover;
