import React from "react";
import {
  ContextBackground,
  ContextMenuArrow,
  ContextMenuContainer,
  ContextualAction,
} from "./style";

/**
 * ! IMPORTANT
 * always remember to put a position different to static
 * and a z-index = 2 on elements that will be used to
 * "right click" them.
 * also, remember to use Points and ContextOption interfaces,
 * you can import them from here or recreate them
 *
 * * if you need an example of how to implement, go to
 * * src/views/Dashboards/DashboardList/List.tsx
 */

export interface Points {
  x: number;
  y: number;
}

export interface ContextOption {
  label: React.ReactNode | string | number;
  func: Function;
  hoverBackground?: string;
  disabled?: boolean;
}

interface ContextMenuProps {
  isOpen: boolean;
  points: Points;
  width?: number;
  options: ContextOption[];
  closeMenu: () => void;
  placeDown?: boolean;
  zIndex?: number;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  children,
  isOpen,
  points,
  width = 200,
  options = [],
  closeMenu,
  placeDown,
  zIndex = 3,
}) => {
  const sidebarOpen =
    JSON.parse(localStorage.getItem("showMenu") ?? "") ?? false;

  const getArrowX = () => {
    let extraSpace = 22;
    extraSpace += sidebarOpen ? 242 : 68;

    if (points.x + width + 4 > window.innerWidth) {
      return points.x - 8 - extraSpace;
    }
    return points.x - extraSpace;
  };
  const getArrowY = () => {
    return points.y - 28;
  };

  const getMenuX = () => {
    let extraSpace = 22;
    extraSpace += sidebarOpen ? 242 : 68;

    if (points.x + width + 4 > window.innerWidth) {
      return points.x - width - 4 - extraSpace;
    }
    return points.x + 4 - extraSpace;
  };
  const getMenuY = () => {
    if (placeDown) {
      return points.y - 40;
    }
    let offset = 8;
    options.forEach((_) => {
      offset += 34;
    });

    return points.y - offset / 2 - 28;
  };

  return (
    <>
      {isOpen && (
        <ContextBackground
          onMouseDown={(e) => {
            e.stopPropagation();
            closeMenu();
          }}
          onClick={(e) => {
            e.stopPropagation();
            closeMenu();
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            closeMenu();
          }}
        />
      )}

      {children}

      {isOpen && (
        <>
          <ContextMenuArrow
            top={getArrowY()}
            left={getArrowX()}
            zIndex={zIndex}
          />
          <ContextMenuContainer
            top={getMenuY()}
            left={getMenuX()}
            width={`${width}px`}
            zIndex={zIndex}
          >
            {options.map((option, i) => (
              <ContextualAction
                onClick={(e) => {
                  e.stopPropagation();
                  if (!option.disabled) {
                    option.func();
                  }
                }}
                key={`option-${i}`}
                hoverBackground={option.hoverBackground ?? "#0273e9"}
                disabled={option.disabled}
              >
                {option.label}
              </ContextualAction>
            ))}
          </ContextMenuContainer>
        </>
      )}
    </>
  );
};

export default ContextMenu;
