import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { StateMachineContext } from "../index";
import { Row, Col, Space, Button } from "antd";
import { useParams } from "react-router-dom";
import ICONO_ from "../../../../resources/img/ICONO_.svg";
import { FaCheck } from "react-icons/fa";
import { Languages } from "./Dictionary";
import States from "../States";
import { getPlanColors } from "../../helpers";
import { useMutation } from "../../../../hooks";

const Container = styled(Row)`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  margin: 3rem;
  border: double 1.5px transparent;
  border-radius: 10px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #6fb124, #033f7a);
  background-origin: border-box;
  background-clip: content-box, border-box;
  overflow: auto;
`;

const Content = styled.div`
  flex: 1;
  padding: 2.5rem;
  color: #535151;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5rem;
  height: 26rem;
`;

const WelcomeText = styled.div`
  font-size: 40px;
  color: #033f7a;
  font-family: "Gotham-Medium";
  text-align: center;
`;

const ContentText = styled.div`
  font-family: ${({ $AddUser }) =>
    $AddUser ? "Gotham-Medium" : "Gotham-Book"};
  color: #696969;
  font-size: 25px;
`;

const PlanText = styled.div`
  font-family: "Gotham-Bold";
  color: ${({ $color }) => $color};
  font-size: 25px;
`;

const Succesful = ({ changedPlan = false }) => {
  const { IdTeam } = useParams();
  const { CurrentState, setCurrentState } = useContext(StateMachineContext);

  const [PlanStatus, setPlanStatus] = useState(null);
  const [plan, setPlan] = useState("");
  const [date, setDate] = useState("");
  const [price, setPrice] = useState("");

  const planColor = getPlanColors(plan).mainColor;

  const {
    Welcome,
    Successful,
    SuccessfulPurchase,
    NextBill,
    GoGeest,
    Plan,
    MessageAddUser,
  } = Languages["ESP"];

  const SuccessfulAddUser = CurrentState === States.SUCCESSFUL_CHANGE_USERS;

  const [getInformation] = useMutation({
    func: "Ver2-Subscription-Successful-gi",
    onSuccess: (response) => {
      const { FrontView, HiredPlan } = response;
      setPlanStatus(FrontView);
      setPlan(HiredPlan.Title);
      setDate(HiredPlan.FullEndTime);
      setPrice(HiredPlan.Amount);
    },
  });

  useEffect(() => {
    getInformation({
      args: {
        IdTeam: IdTeam,
      },
    });
    // eslint-disable-next-line
  }, [IdTeam]);

  const [changeSubscriptionState] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: (response) => {
      const { State } = response;
      setCurrentState(State);
    },
  });

  const onSubmit = () => {
    changeSubscriptionState({
      args: {
        IdTeam,
        NextState: "Normal",
      },
    });
  };

  return (
    <Row
      justify="center"
      style={{
        height: "100%",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Container>
        <Content>
          <Row>
            <Col
              md={24}
              lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Space direction="vertical" align="center" size="small">
                <img
                  src={ICONO_}
                  alt="logo"
                  style={{
                    height: "350px",
                    width: "350px",
                  }}
                />
                <WelcomeText>
                  {changedPlan ? "Has cambiado de plan" : Welcome}
                </WelcomeText>
              </Space>
            </Col>
            <Col
              md={24}
              lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Space direction="vertical">
                  <Row>
                    <ContentText>{SuccessfulPurchase(PlanStatus)}</ContentText>
                    <FaCheck
                      style={{
                        color: "green",
                        marginLeft: "1rem",
                      }}
                      size={35}
                    />
                  </Row>
                  <div>
                    {SuccessfulAddUser && (
                      <ContentText $AddUser>{MessageAddUser("")}</ContentText>
                    )}
                    <PlanText $color={planColor}>{`${Plan} ${plan}`}</PlanText>
                    <ContentText>{Successful(PlanStatus)}</ContentText>
                    <ContentText>{NextBill(date, price)}</ContentText>
                  </div>
                </Space>
                <Button
                  onClick={onSubmit}
                  style={{
                    borderRadius: "10px",
                    color: "white",
                    backgroundColor: "green",
                    borderColor: "white",
                    marginTop: "10.5rem",
                  }}
                  block
                >
                  {GoGeest}
                </Button>
              </div>
            </Col>
          </Row>
        </Content>
      </Container>
    </Row>
  );
};

export default Succesful;
