import React, { useContext } from "react";
import { Row } from "antd";
import { AiOutlineFolder, AiOutlineTeam } from "react-icons/ai";
import { Languages } from "../Dictionary";
import {
  NestedFolder,
  SelectedFolderContainer,
  ProcessTeamContainer,
} from "./style";
import { IoIosArrowForward } from "react-icons/io";
import { SesionContext } from "../../../config/State";
import { useHistory } from "react-router-dom";

interface FolderType {
  Name: string;
  CanShare: boolean;
  IdFolder: number | null;
  IdProcessType: number | null;
  IdProcessTemplate: number | null;
  CanStartProcesses: boolean;
  TeamName?: string;
}

interface HeaderProps {
  getUserProcessTemplates: (params: { args?: any; idUR: number }) => void;
  selectedFolder: FolderType;
  nestedFolders: FolderType[];
  setNestedFolders: (folders: FolderType[]) => void;
  setSelectedFolder: (folder: FolderType | null) => void;
}

const Header: React.FC<HeaderProps> = ({
  getUserProcessTemplates,
  selectedFolder,
  nestedFolders,
  setNestedFolders,
  setSelectedFolder,
}) => {
  const { Title } = Languages["ESP"];
  const { TeamName } = selectedFolder;
  const [Sesion] = useContext<any>(SesionContext);
  const history = useHistory();

  const onGoBack = () => {
    history.push("/home/templates");
  };

  const onReturnToPreviousFolder = (Folder: FolderType, index: number) => {
    let nestedFoldersAux = [...nestedFolders];
    nestedFoldersAux = nestedFoldersAux.slice(0, index + 1);
    setNestedFolders(nestedFoldersAux);
    getUserProcessTemplates({
      args: {
        IdParentFolder: Folder.IdFolder,
      },
      idUR: Sesion.Id,
    });
    setSelectedFolder(Folder);
    history.push({
      pathname: `/home/template/folder/${Folder.IdFolder}`,
      state: Folder,
    });
  };

  return (
    <Row align="middle">
      <ProcessTeamContainer onClick={onGoBack}>
        {Title}
        <AiOutlineTeam size={20} style={{ marginLeft: "5px" }} />
        {TeamName}
      </ProcessTeamContainer>
      {nestedFolders.map((Folder, index) => (
        <Row align="middle" key={Folder.IdFolder}>
          <IoIosArrowForward size={20} color="rgba(130, 141, 158, 1)" />
          {Folder.IdFolder === selectedFolder.IdFolder ? (
            <SelectedFolderContainer>
              <AiOutlineFolder size={20} style={{ marginRight: "8px" }} />
              {Folder.Name}
            </SelectedFolderContainer>
          ) : (
            <NestedFolder
              onClick={() => onReturnToPreviousFolder(Folder, index)}
            >
              <AiOutlineFolder size={20} style={{ marginRight: "8px" }} />
              {Folder.Name}
            </NestedFolder>
          )}
        </Row>
      ))}
    </Row>
  );
};

export default Header;
