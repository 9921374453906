import Bugsnag from "@bugsnag/js";
import { useEffect } from "react";

const useBugsnagMetadata = (
  key: string,
  data: { [key: string]: string }
): void => {
  useEffect(() => {
    Bugsnag.addMetadata(key, data);

    return () => {
      Bugsnag.clearMetadata(key);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useBugsnagMetadata;
