import React from "react";
import styled from "styled-components";

const IndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px !important;
  width: 5px;
`;

const Indicator = styled.div<{ color?: string }>`
  background-color: ${({ color = "#08979c" }) => color};
  height: 17px;
  width: 7px;
  border-radius: 2px 0 0 2px;
`;

const IndicatorTop = styled(Indicator)<{ height: string }>`
  background-color: #08979c;
  height: ${({ height }) => height};
  border-radius: 2px 0 0 0;
`;
const IndicatorMiddle = styled(Indicator)<{ height: string }>`
  background-color: #cf1322;
  height: ${({ height }) => height};
  border-radius: 0;
`;
const IndicatorBottom = styled(Indicator)<{ color: string; height: string }>`
  background-color: ${({ color }) => color};
  height: ${({ height }) => height};
  border-radius: 0 0 0 2px;
`;

interface FilterStatusIndicatorProps {
  status: "All" | "Actuals" | "Started" | "Stuck" | "ToStart";
}

const FilterStatusIndicator: React.FC<FilterStatusIndicatorProps> = ({
  status,
}) => {
  switch (status) {
    case "All":
      return (
        <IndicatorContainer>
          <IndicatorTop height="5px" />
          <IndicatorMiddle height="5px" />
          <IndicatorBottom color="#d46b08" height="5px" />
        </IndicatorContainer>
      );
    case "Actuals":
      return (
        <IndicatorContainer>
          <IndicatorTop height="8px" />
          <IndicatorBottom color="#cf1322" height="8px" />
        </IndicatorContainer>
      );
    case "Started":
      return <Indicator />;
    case "Stuck":
      return <Indicator color="#cf1322" />;
    case "ToStart":
      return <Indicator color="#d46b08" />;

    default:
      return <Indicator />;
  }
};

export default FilterStatusIndicator;
