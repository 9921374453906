import React, { useRef, useState, useContext } from "react";
import { Button } from "../../../../../GeestUI";
import GeestSelect from "../../../../../GeestUI/GeestSelect";
import { AiFillDelete, AiOutlineFile, AiOutlineUpload } from "react-icons/ai";
import { StringInput } from "../../styles";
import SourceIcon from "./img/sourceIcon.svg";
import fieldIcon from "./img/fieldIcon.svg";
import {
  FileValue,
  ImageConfigType,
  ImageDimensionsType,
  Option,
  ImageSourceType,
} from "../../../PDFConfig.d";
import {
  ButtonLabel,
  ConfigHeader,
  ConfigRow,
  ConfigRowName,
  ConfigTab,
  DeleteIconContainer,
  ImageConfigContainer,
  ImageName,
  UploadedImageContainer,
} from "./styles";
import { uploadFileToAWS } from "../../../../../aws/s3Client";
import Tooltip from "../../../../Tooltip";
import _ from "lodash";
import { MessagesContext } from "../../../../AppMessages";

interface ImageConfigProps {
  nodeName: string;
  setNodeName: (newName: string) => void;
  filesPath: string;
  imageValue: FileValue | string;
  setImageValue: (newImage: FileValue | string) => void;
  fieldImageOrigin: Option[];
  imageConfig: ImageConfigType;
  setImageConfig: (newConfig: ImageConfigType) => void;
  imageDimensions: ImageDimensionsType;
  setImageDimensions: (newDimensions: ImageDimensionsType) => void;
  handleUpdate: (
    newImage?: FileValue | string,
    source?: ImageSourceType
  ) => void;
  teamLogo: string;
  imageSource: ImageSourceType;
  setImageSource: (imageSource: ImageSourceType) => void;
}

const ImageConfig: React.FC<ImageConfigProps> = ({
  nodeName,
  setNodeName,
  filesPath,
  imageValue,
  setImageValue,
  fieldImageOrigin,
  imageConfig,
  setImageConfig,
  imageDimensions,
  setImageDimensions,
  handleUpdate,
  teamLogo,
  imageSource,
  setImageSource,
}) => {
  const [imageCache, setImageCache] = useState<FileValue | string>("");
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { showMessage } = useContext(MessagesContext);

  const handleChangeConfig = (newConfig: ImageConfigType) => {
    if (imageConfig === "source" && newConfig === "field") {
      setImageValue("");
    }

    if (
      imageConfig === "field" &&
      newConfig === "source" &&
      imageCache !== ""
    ) {
      setImageValue(imageCache);
    }

    setImageConfig(newConfig);
  };

  const handleSelctImageField = (newImage: FileValue | string) => {
    setImageValue(newImage);
    setImageCache(newImage);
    handleUpdate(newImage);
  };

  const onUpload = () => {
    inputRef.current?.click();
  };

  const handleUpload = async ({ target }: any) => {
    setUploadingImage(true);
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      let cleanName = file.name.replace(/[^a-zA-Z0-9.]/g, "");
      const filePath = await uploadFileToAWS(file, filesPath);
      if (filePath) {
        setImageValue({ name: cleanName, filePath });
        setImageCache({ name: cleanName, filePath });
        handleUpdate({ name: cleanName, filePath });
        setUploadingImage(false);
      }
    }
    setUploadingImage(false);
  };

  const deleteFileValue = () => {
    setImageValue("");
    setImageCache("");
    handleUpdate({ name: "", filePath: "DELETE" });
  };

  const options = [
    {
      label: "Desde archivo",
      value: "file",
    },
    {
      label: "Usar logotipo de mi empresa",
      value: "teamLogo",
    },
  ];

  const onChangeImageSoure = (source: ImageSourceType) => {
    setImageSource(source);
    if (source === "teamLogo") {
      handleUpdate("", source);
      setImageValue("");
      if (!teamLogo) {
        showMessage(
          "Tu empresa no tiene logotipo agregado. Para agregarlo ingresa a “Mis empresas”",
          "warning"
        );
      }
    }
  };

  return (
    <ImageConfigContainer>
      <ConfigHeader>
        <ConfigTab
          current={imageConfig === "source"}
          onClick={() => {
            if (uploadingImage) return;
            handleChangeConfig("source");
          }}
        >
          <img src={SourceIcon} alt="source" />
        </ConfigTab>
        <ConfigTab
          current={imageConfig === "field"}
          onClick={() => {
            if (uploadingImage) return;
            handleChangeConfig("field");
          }}
        >
          <img src={fieldIcon} alt="field" />
        </ConfigTab>
      </ConfigHeader>

      <ConfigRow>
        <ConfigRowName>Nombre del elemento</ConfigRowName>
        <StringInput
          value={nodeName}
          onChange={({ target: { value } }) => setNodeName(value)}
          disabled={uploadingImage}
        />
      </ConfigRow>

      <ConfigRow>
        {imageConfig === "source" &&
          (typeof imageValue === "string" ||
          (typeof imageValue === "string" && imageValue === "") ? (
            <>
              <ConfigRowName>Selecciona la fuente de la imagen</ConfigRowName>
              <GeestSelect
                value={imageSource}
                onChange={onChangeImageSoure}
                options={options}
                style={{ marginBottom: "12px" }}
                valueNecesary
                popoverStyle={{
                  zoom: "75%",
                  marginLeft: "20.5%",
                }}
              />
              {imageSource === "file" && (
                <Button
                  type="secondary"
                  size="fullwidth"
                  borderWidth="3px"
                  Icon={AiOutlineUpload}
                  onClick={onUpload}
                  disabled={uploadingImage}
                >
                  <input
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    disabled={uploadingImage}
                    onChange={(e: any) => handleUpload(e)}
                    style={{ display: "none" }}
                  />
                  <ButtonLabel>Subir imagen</ButtonLabel>
                </Button>
              )}
            </>
          ) : (
            typeof imageValue !== "string" && (
              <>
                <ConfigRowName>Selecciona la fuente de la imagen</ConfigRowName>
                <GeestSelect
                  value={imageSource}
                  onChange={onChangeImageSoure}
                  options={options}
                  style={{ marginBottom: "12px" }}
                />
                {imageSource === "file" && (
                  <UploadedImageContainer>
                    <ImageName>
                      <AiOutlineFile size={14} />
                      <p>
                        {(imageValue as FileValue).name.length > 32 ? (
                          <Tooltip title={(imageValue as FileValue).name}>
                            {`${_.truncate((imageValue as FileValue).name, {
                              length: 32,
                            })}`}
                          </Tooltip>
                        ) : (
                          (imageValue as FileValue).name
                        )}
                      </p>
                    </ImageName>
                    <DeleteIconContainer
                      onClick={() => {
                        if (uploadingImage) return;
                        deleteFileValue();
                      }}
                    >
                      <AiFillDelete size={20} color="#e11909" />
                    </DeleteIconContainer>
                  </UploadedImageContainer>
                )}
              </>
            )
          ))}

        {imageConfig === "field" && (
          <>
            <ConfigRowName>Selecciona el campo</ConfigRowName>
            <GeestSelect
              value={"" + imageValue}
              options={fieldImageOrigin}
              onChange={handleSelctImageField}
              disabled={uploadingImage}
              popoverStyle={{
                zoom: "75%",
                marginLeft: "20.5%",
              }}
            />
          </>
        )}
      </ConfigRow>
    </ImageConfigContainer>
  );
};

export default ImageConfig;
