import React, { MouseEventHandler } from "react";
import { IconContainer } from "./styles";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";

const ViewIcon: React.FC<{
  onClick: MouseEventHandler<HTMLDivElement>;
  size?: number;
  filled?: boolean;
}> = ({ onClick, size = 20, filled }) => {
  const Icon = filled ? AiFillEye : AiOutlineEye;
  return (
    <IconContainer size={size} style={{ paddingLeft: "1px" }} onClick={onClick}>
      <Icon color={filled ? "#0273E9" : "#48505E"} size={16} />
    </IconContainer>
  );
};

export default ViewIcon;
