import React from "react";
import { Modal } from "@material-ui/core";
import {
  ButtonsRow,
  Content,
  Header,
  ModalContent,
  ModalWrapper,
  Title,
} from "./styles";
import { CloseIcon } from "../../../../../components/hoverIcons";
import { Button } from "../../../../../GeestUI";

interface UnsavedChangesModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal open onClose={onCancel}>
      <ModalWrapper onClick={onCancel}>
        <ModalContent
          onClick={(e) => e.stopPropagation()}
          $width="430px"
          $maxWidth="90vw"
          $height="200px"
          $padding="12px"
        >
          <Header>
            <Title>Salir de la configuración</Title>
            <CloseIcon size={20} onClick={onCancel} color="#48505e" />
          </Header>

          <Content>
            <p>
              Al salir se perderá toda la configuración hecha hasta el momento.
              ¿Estás de acuerdo con configurar desde cero tu tabla?
            </p>
          </Content>

          <ButtonsRow>
            <Button type="secondary" onClick={onCancel}>
              Cancelar
            </Button>
            <Button type="primary" onClick={onConfirm}>
              Aceptar
            </Button>
          </ButtonsRow>
        </ModalContent>
      </ModalWrapper>
    </Modal>
  );
};

export default UnsavedChangesModal;
