import React, { useState } from "react";
import Tooltip from "../../../components/Tooltip";
import {
  DeleteIconContainer,
  EditIconContainer,
  HeaderContainer,
  IconsContainer,
  RowContainer,
  StatsTableContainer,
  TableCell,
  TableHeaders,
  TableRows,
  TableWrapper,
} from "./Styles";
import TableDetail from "./Modals/TableDetail";
import DeleteIcon from "../../../components/hoverIcons/DeleteIcon";
import { AiOutlineEdit } from "react-icons/ai";
import data from "./data";

interface StatsTableProps {}

const StatsTable: React.FC<StatsTableProps> = () => {
  const [cellDetail, SetCellDetail] = useState<number | null>(null);

  return (
    <StatsTableContainer>
      {cellDetail !== null && (
        <TableDetail closeModal={() => SetCellDetail(null)} />
      )}
      <TableWrapper>
        <TableHeaders>
          {data.columns.map((column) => (
            <HeaderContainer>
              {column.name.length > 12 ? (
                <Tooltip title={column.name}>
                  <p>{column.name}</p>
                </Tooltip>
              ) : (
                <p>{column.name}</p>
              )}

              {!column.primaryKey && (
                <IconsContainer>
                  <DeleteIconContainer className="deleteIconContainer">
                    <DeleteIcon filled />
                  </DeleteIconContainer>
                  <EditIconContainer>
                    <AiOutlineEdit size={16} color="white" />
                  </EditIconContainer>
                </IconsContainer>
              )}
            </HeaderContainer>
          ))}
        </TableHeaders>

        <TableRows>
          {data.rows.map((row) => {
            return (
              <RowContainer>
                {row.cells.map((cell, i) => {
                  return (
                    <TableCell
                      $pair={i % 2 === 0}
                      onClick={() => SetCellDetail(cell.IdStat as number)}
                    >
                      <p>{cell.Value}</p>
                    </TableCell>
                  );
                })}
              </RowContainer>
            );
          })}
        </TableRows>
      </TableWrapper>
    </StatsTableContainer>
  );
};

export default StatsTable;
