import React, { useEffect, useState } from "react";
import { Languages } from "./Dictionary";
import Modal from "../../../components/Modal";
import { Small, ButtonContainer } from "./styles";
import { useMutation } from "../../../hooks";
import { Button } from "../../../GeestUI";
import { Row } from "antd";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";

const { ModalTitle, SelectTeamLabel, ConfirmLabel } = Languages["ESP"];

interface Template {
  IdProcessTemplate: number;
  CanReShareTemplate: boolean;
}

interface Option {
  label: string;
  value: number;
}

interface DownloadTemplateProps {
  teams: any[];
  reshareTemplateData: Template;
  reload: () => void;
  onClose: () => void;
}

const DownloadTemplate: React.FC<DownloadTemplateProps> = ({
  teams,
  reshareTemplateData,
  reload,
  onClose,
}) => {
  const [teamSelected, setTeamSelected] = useState<number | null>(null);
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (teams) {
      const aux = [...teams].map((t) => {
        return { label: t.Name, value: t.IdTeam };
      });
      if (aux.length > 0) setTeamSelected(aux[0].value);
      setOptions(aux);
    }
  }, [teams]);

  const [copyProcessTemplate] = useMutation({
    func: "Ver2-Processes-cpt",
    onSuccess: () => {
      onClose();
      reload();
    },
  });

  const handleConfirm = () =>
    copyProcessTemplate({
      args: { ...reshareTemplateData, IdTeamDestiny: teamSelected },
    });

  return (
    <Modal
      visible
      onCancel={onClose}
      centered
      title={ModalTitle}
      closable={false}
      width={432}
      bodyStyle={{ height: "210px" }}
    >
      <Row justify="center">
        <Small>{SelectTeamLabel}</Small>
      </Row>
      <Row justify="center">
        <GeestSelect
          value={teamSelected}
          onChange={(e: any) => setTeamSelected(e.target.value)}
          options={options}
          $width="384px"
          $listWidth="384px"
        />
      </Row>
      <ButtonContainer>
        <Button onClick={handleConfirm} type="primary">
          {ConfirmLabel}
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export default DownloadTemplate;
