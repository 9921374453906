import styled from "styled-components";
import { Upload } from "antd";
import TEAM_ORG from "../../../resources/img/TEAM_ORG.svg";
import TEAM_LOGO_PLACEHOLDER from "../../../resources/img/no_team_logo.png";
import { Paper } from "@material-ui/core";

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TeamContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TeamTitle = styled.div`
  display: flex;
  font-size: 24px;
  font-family: Gotham-Bold;
  text-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  flex: 1;
  height: 32px;
  padding-left: 10px;
`;

export const TeamDetailHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const HeaderItem = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  height: 40px;
  font-family: Gotham-Book;
  color: #48505e;
  cursor: pointer;
  font-size: 24px;
  border-radius: 6px;
  padding: 0px 10px;
  background-color: ${({ $selected }) => ($selected ? "#f2f8fe" : "")};
  &:active {
    background-color: #f2f8fe !important;
  }
  :hover {
    background-color: #edecec;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const LeftSidebar = styled.div`
  color: #727e8b;
  font-size: 16px;
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;

export const LeftInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const MembersTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  font-size: 14px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

export const MembersCount = styled.div`
  border: 1px solid #48505e;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-text: center;
`;

export const TeamLogoContainer = styled(CenterContainer)`
  width: 90px;
  height: 90px;
  margin-top: 28px;
  margin-bottom: 32px;
`;

export const TeamLogo = styled(CenterContainer)`
  content: "";
  height: 100%;
  width: 100%;
  max-width: 12rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #fafbfd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props: { sourceUrl: string }) =>
    props.sourceUrl || TEAM_LOGO_PLACEHOLDER});
`;

export const TeamLogoUploader = styled(Upload)`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: flex;

  .ant-upload {
    width: 100%;
    background.color: red;
  }
`;

export const EditTeamLogo = styled(CenterContainer)`
  background-color: #eee3;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  border-radius: 50%;
  transition: opacity 0.35s ease;
  :hover {
    opacity: 1;
  }
`;

export const ReportCurrencyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 10px;

  p {
    margin: 0;
    font-size: 14px;
    color: #828d9e;
    text-align: center;
  }
`;

export const TeamContent = styled.div`
  flex: 4;
  overflow: auto;
  padding: 0 0.5rem;
`;

export const RightSidebar = styled.div`
  background-color: #fafbfd;
  flex: 1;
  padding-top: 2rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const RightSidebarButton = styled.div`
  background: #fafbfd;
  border: 0.5px solid #e8ecf1;
  border-radius: 5px;
  min-width: 160px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.2rem 1rem;
  margin-bottom: 0.5rem;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #e8ecf1;
  }
`;

export const OrgIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${TEAM_ORG});
  background-position: center;
  background-repeat: no-repeat;
`;

export const RegisterForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  margin-bottom: 1.5rem;
  padding: 0px 10px;
`;

export const StyledPaper = styled(Paper)<{ $mr?: string }>`
  border-radius: 10px !important;
  padding: 20px;
  width: 350px;
  margin-right: ${({ $mr = "3rem" }) => $mr};
`;

export const ModalBoldLabel = styled.div`
  text-align: center;
  width: 309px;
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  font-size: 14px;
`;

export const DeleteModalDescription = styled.div`
  text-align: center;
  width: 309px;
  font-size: 14px;
  color: rgba(130, 141, 158, 1);
  font-family: Gotham-Book;
  font-weight: 325;
  line-height: 19.6px;
`;

export const InputStyled = styled.input`
  color: rgba(130, 141, 158, 1);
  font-size: 14px;
  font-family: Gotham-Book;
  border: 2px solid rgba(237, 236, 236, 1);
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
  width: 384px;
  height: 32px;
  :focus-visible {
    outline: none;
  }
  :hover {
    border-color: rgba(2, 115, 233, 1);
  }
  :focus {
    border-color: rgba(72, 80, 94, 1);
  }
`;

export const DeleteModalButtonContainer = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 14px;
`;

export const TeamTitleInput = styled(InputStyled)`
  display: flex;
  flex: 1;
  font-size: 24px;
  font-family: Gotham-Bold;
  color: #48505e;
`;
