import React from "react";
import styled from "styled-components";
import { ReactComponent as SignatureIconSVG } from "../../resources/img/signatureIcon.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px !important;
`;

interface SignatureIconProps {}

const SignatureIcon: React.FC<SignatureIconProps> = () => {
  return (
    <IconWrapper>
      <SignatureIconSVG width="14px" height="14px" />
    </IconWrapper>
  );
};

export default SignatureIcon;
