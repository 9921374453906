import { FaShareAlt } from "react-icons/fa";
import styled, { keyframes } from "styled-components";

export const DBCardContainer = styled.div<{ $lastItem: boolean }>`
  display: flex;

  width: 280px;
  padding: 0;
  margin-right: ${({ $lastItem = false }) => ($lastItem ? "0" : "25px")};
`;

export const DBBar = styled.div<{ $color: string }>`
  width: 26px;
  height: 96px;
  background-color: ${({ $color }) => $color};
  border-radius: 6px 0px 0px 6px;
  box-shadow: 0px 4px 4px 0px rgba(72, 80, 94, 0.3);
  margin: 0;
`;

export const Card = styled.div`
  width: 251px;
  height: 96px;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 0px 6px 6px 0px;
  box-shadow: 0px 4px 4px 0px rgba(72, 80, 94, 0.3);
  margin-right: 0;
  overflow: hidden;
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 14px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  overflow: hidden;
  height: calc(100% - 2rem);

  .title {
    max-width: 89%;

    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
    }
  }
`;

export const CardControls = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 8px;
`;

const Shake = keyframes`
    0%   { transform: translateY(0); }
    25%  { transform: translateY(-4px); }
    50%  { transform: translateY(-0px); }
    75%  { transform: translateY(-4px); }
    100% { transform: translateY(0); }
`;

export const ShareIcon = styled(FaShareAlt)`
  cursor: pointer;
  color: grey;
  margin-right: 0.1rem;
  display: block;
  height: 1.2rem;
  :hover {
    color: black;
    animation: ${Shake} 0.5s;
  }
`;

export const ContextMenuOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    margin: 0;
  }
`;

export const FoldersContainer = styled.div`
  width: 282px;
  background-color: rgba(72, 80, 94, 1);
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

export const FolderMenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  height: 32px;
  cursor: pointer;
  font-family: Gotham-Bold;
  margin: 0;
  padding-left: 5px;
  :hover {
    background-color: rgba(2, 115, 233, 1);
  }
  :active {
    background-color: #1939b7;
  }

  max-width: 100%;
  p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const FolderOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

export const NewCard = styled.div`
  cursor: pointer;
  width: 277px;
  height: 96px;
  border-radius: 6px;
  color: rgba(130, 141, 158, 1);
  margin: 0;
  background-color: rgba(237, 236, 236, 1);
  border-color: rgba(237, 236, 236, 1);
  transition: background-color 0.35s ease, border-color 0.35s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: #828d9e;
    border-color: #828d9e;
    color: #ffffff;
  }
`;

export const NewCardTitle = styled.div`
  text-align: center;
  font-size: 14px;
  font-family: Gotham-Bold;
`;

export const FolderCardContainer = styled.div<{ $lastItem: boolean }>`
  display: flex;

  width: 280px;
  padding: 0;
  margin-right: ${({ $lastItem = false }) => ($lastItem ? "0" : "25px")};
`;

export const SmallFolderBar = styled.div<{ $color: string }>`
  width: 14px;
  height: 50px;
  background-color: ${({ $color }) => $color};
  border-radius: 6px 0px 0px 6px;
  box-shadow: 0px 4px 4px 0px rgba(72, 80, 94, 0.3);
`;

export const FolderBar = styled.div<{ $color: string }>`
  width: 12px;
  height: 96px;
  background-color: ${({ $color }) => $color};
  border-radius: 0px 0px 0px 6px;
  margin: 0;
  box-shadow: 0px 3px 0px 0px rgba(72, 80, 94, 0.3);
`;
