import { getSymbolByCode } from "../../../components/DynamicInput/Components/helpers/CurrencyHelpers";

const getFormatedNumber = (val) =>
  val ? val.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";

export const Languages = {
  ENG: {
    Priority: "Priority",
    NoPriority: "No priority",
    StartedAt: "Started at",
    Deadline: "Deadline",
    EndedAt: "Ended at",
    Description: "Description",
    ProcessInformation: "Process Information",
    Activity: "Activity",
    Process: "Process",
    IdProcessExecutionLabel: "ID",
    Stage: "Stage",
    NoStage: "No stage",
    ActivityTimeConector: "at",
    NewReminder: "New reminder",
    IncomeDescription: (amount, format) =>
      `After completing this task, a sales amount of:
      ${getSymbolByCode(format)}${getFormatedNumber(amount)}
      ${format} will be reported.`,
    Consult: "Consult",
    Editable: "Editables",
    OpenFlux: "Open flux",
    Save: "Save",
    Postpone: "Postpone",
    Reschedule: "Reschedule",
    Start: "Start",
    Reject: "Reject",
    Finish: "Finish",
    Aprove: "Aprove",
    TooltipLabel: {
      ChangeProcessName: "Change process name",
      ChangePriority: "Change priority",
    },
  },
  ESP: {
    Priority: "Prioridad",
    NoPriority: "Sin prioridad",
    StartedAt: "Inicio",
    EndedAt: "Fin",
    Deadline: "Vencimiento",
    Description: "Descripción",
    ProcessInformation: "Información del proceso",
    Activity: "Actividad",
    Process: "Proceso",
    IdProcessExecutionLabel: "Folio",
    Stage: "Etapa",
    NoStage: "Sin etapa",
    ActivityTimeConector: "a las",
    NewReminder: "Nuevo recordatorio",
    IncomeDescription: (amount, format) =>
      `Al terminar esta tarea, se reportará un monto vendido por:
      ${getSymbolByCode(format)}${getFormatedNumber(amount)}
      ${format}`,
    Consult: "Consulta",
    Editable: "Evidencias",
    OpenFlux: "Ver flujo",
    Save: "Guardar",
    Postpone: "Posponer",
    Reschedule: "Reagendar",
    Start: "Iniciar",
    Reject: "Rechazar",
    Finish: "Terminar",
    Aprove: "Aprobar",
    TooltipLabel: {
      ChangeProcessName: "Editar nombre de proceso",
      ChangePriority: "Cambiar prioridad",
    },
  },
};
