import React, { useMemo } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  NodeTypes,
  useNodesState,
} from "reactflow";
import styled from "styled-components";
import CustomNodes from "./CustomNodes";
import { DiagramProps } from "./Diagram.d";
import { defaultEdgeOptions } from "./helpers";

const CustomReactFlow = styled(ReactFlow)`
  .react-flow__attribution {
    display: none;
  }
`;

const Diagram: React.FC<DiagramProps> = ({ nodes: Nodes, edges }) => {
  const nodeTypes: NodeTypes = useMemo(() => CustomNodes, []);
  const [nodes, , onNodesChange] = useNodesState(Nodes);

  return (
    <div style={{ flex: 1 }}>
      <CustomReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        nodeTypes={nodeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        fitView
        minZoom={0}
        nodesDraggable={false}
        nodesConnectable={false}
        draggable={false}
      >
        <Background color="#e8ecf1" variant={BackgroundVariant.Lines} />
        <Controls />
      </CustomReactFlow>
    </div>
  );
};

export default Diagram;
