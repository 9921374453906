import styled from "styled-components";
import { Button, withStyles, FormControl, Chip } from "@material-ui/core";
import UserIcon from "../../../resources/img/OrgChart_UserIcon.svg";

export const EditButton = styled(Button)`
  && {
    border-radius: 10px;
    color: #7c7c7c;
    border: solid 1px #7c7c7c;
    text-transform: inherit;
    font-family: "Gotham-Book";
    font-size: large;
    padding: 0.2rem 1.7rem;
  }
`;

export const NodeCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NodeHeader = styled.div`
  background-image: url(${UserIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 67px;
  height: 67px;
  z-index: 1;
  opacity: ${(props: { isTeamNode: boolean }) =>
    props.isTeamNode ? "0" : "1"};
`;

export const NodeBody = styled.div`
  background: #0067b0;
  color: white;
  width: 200px;
  height: 63px;
  font-family: Gotham-Bold;
  font-size: 16px;
  border-radius: 6px;
  margin-top: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
`;

export const ConfTitle = styled.div`
  font-family: "Gotham-Book";
  font-size: large;
  color: #444444;
  width: 100%;
  border-bottom: solid 1px #cecece;
  padding-bottom: 0.5rem;
  margin-bottom: 0.7rem;
`;

export const ConfSubtitle = styled.div`
  font-family: Gotham-Book;
  font-size: 14px;
  color: #828d9e;
  width: 252px;
  text-align: center;
`;

export const InitialsIcon = styled.div`
  background: #0067b0;
  border-radius: 50%;
  font-family: Gotham-Bold;
  font-size: 12px;
  color: white;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NameStyle = styled.div`
  font-family: "Gotham-Book";
  font-size: medium;
  color: #565656;
  display: flex;
`;

export const SelectStyle = withStyles({
  root: {
    width: "calc(100% - 3rem)",
    marginLeft: "1.8rem",
    "& label.Mui-focused": {
      color: "black",
    },
    "& label": {
      fontFamily: "Gotham-Book",
      fontSize: "medium",
    },
    "& .MuiSelect-outlined": {
      // padding: ".5rem 2rem .5rem .5rem",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Gotham-Book",
      borderRadius: ".4rem",
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(FormControl);

export const StyledChip = styled(Chip)`
  && {
    background-color: #e4e4e4;
    border: 1px solid #cfcfcf;
    flex: 1;
    margin: 2px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    font-family: "Gotham-Book";
    border-radius: 0.5rem;
    color: #5a5a5a;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: visible;
`;

export const ConfContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 10px;
  align-items: center;
  margin-bottom: 22px;
  overflow: auto;
  max-height: calc(604px - 100px);
  margin-top: 5px;
`;

export const ConfItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
  width: 100%;
`;

export const UserRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: #48505e;
  font-family: Gotham-Book;
  font-size: 14px;
`;
