import styled from "styled-components";
import { Switch } from "antd";

export const CardCont = styled.div`
  margin-top: 2rem;
  width: 37%;
  box-shadow: 0px 5px 25px 0px rgb(84 84 84 / 30%);
  border-radius: 1rem;
`;

export const Container = styled.div`
  margin: 1.2rem 2rem;
`;

export const Img = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

export const StyledSwitch = styled(Switch)`
  background: ${({ checked }) => checked && "#0867ad"};
`;
