import React from "react";
import { Row } from "antd";
import Modal from "../../../../../components/Modal";
import { Button, Typography } from "../../../../../GeestUI";
import { useMutation } from "../../../../../hooks";

const { P } = Typography;

interface StopProcessModalProps {
  IdProcessExecution: number | null;
  onSuccess: () => void;
  onCancel: () => void;
}

const StopProcessModal: React.FC<StopProcessModalProps> = ({
  IdProcessExecution,
  onSuccess,
  onCancel,
}) => {
  const [cancelProcessExecution, canceling] = useMutation<[]>({
    func: "Ver2-Reports-cpe",
    onSuccess: () => onSuccess(),
  });

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () =>
    cancelProcessExecution({
      args: { IdProcessExecution },
    });

  return (
    <Modal
      visible
      title="Detener seguimiento"
      width="300px"
      onCancel={onCancel}
    >
      <P mb="27px" mt="10px">
        Todas las tareas actuales y por iniciar del seguimiento desaparecerán de
        los pendientes, pero la información recabada en las bases de datos hasta
        el momento seguirá registrada.
      </P>
      <Row justify="end">
        <Button type="danger" onClick={onClick} loading={canceling}>
          Detener
        </Button>
      </Row>
    </Modal>
  );
};

export default StopProcessModal;
