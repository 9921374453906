import React from "react";
import { FolderType, UserVarDB, Vardb } from "../DataBases.d";
import DBCard from "./DBCard";
import FolderCard from "./FolderCard";

interface DatabaseListItemProps {
  team: UserVarDB;
  cardKey: string;
  varDB: Vardb | FolderType;
  idx: number;
  maxItems: number;
  selectedFolder: FolderType;
  nestedFolders: FolderType[];
  openDB: () => void;
  onImport: (IdTeam: number, IdVarDB: string, dbName: string) => void;
  onExport: (IdTeam: number, IdVarDB: string) => void;
  onDeleteFolder: (IdTeam: number, IdVardbFolder: string) => void;
  onSelectFolder: (
    Folder: FolderType,
    TeamName: string,
    IdTeam: number
  ) => void;
  onMoveToFolder: (
    IdTeam: number,
    IdVarDB: string | null,
    IdParentFolder: string | null,
    IdVardbFolder: string | null
  ) => void;
}

const DatabaseListItem: React.FC<DatabaseListItemProps> = ({
  team,
  cardKey,
  varDB,
  idx,
  maxItems,
  selectedFolder,
  nestedFolders,
  openDB,
  onImport,
  onExport,
  onDeleteFolder,
  onSelectFolder,
  onMoveToFolder,
}) => {
  const { TeamName, Vardbs, IdTeam } = team;

  const folders = (Vardbs as FolderType[]).filter(({ IdVardbFolder }) =>
    Boolean(IdVardbFolder)
  );

  return Boolean(varDB.IdVarDB) && varDB.IdVarDB !== "0" ? (
    <DBCard
      cardKey={cardKey}
      varDB={varDB}
      team={team}
      idx={idx}
      maxItems={maxItems}
      folders={folders}
      selectedFolder={selectedFolder}
      nestedFolders={nestedFolders}
      openDB={openDB}
      onImport={onImport}
      onExport={onExport}
      onMoveToFolder={onMoveToFolder}
    />
  ) : (
    <FolderCard
      cardKey={cardKey}
      folder={varDB as FolderType}
      team={team}
      idx={idx}
      maxItems={maxItems}
      folders={folders}
      selectedFolder={selectedFolder}
      nestedFolders={nestedFolders}
      onDeleteFolder={onDeleteFolder}
      onSelectFolder={() =>
        onSelectFolder(varDB as FolderType, TeamName, IdTeam)
      }
      onMoveToFolder={onMoveToFolder}
    />
  );
};

export default DatabaseListItem;
