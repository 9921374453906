export const Languages = {
  ENG: {
    ModalTitle: "Delete",
    SureLabel: "Are you sure you want to delete this dashboard?",
    IrreversibleLabel:
      "This action is irreversible and it will delete all stored data permanently. If you are sure you wish to continue please write the name of the dashboard:",
    InputPlaceholder: "Write here...",
    CancelLabel: "Cancel",
    DeleteLabel: "Delete",
  },
  ESP: {
    ModalTitle: "Eliminar",
    SureLabel: "¿Seguro que deseas eliminar este dashboard?",
    IrreversibleLabel:
      "Esta acción es irreversible y eliminará permanentemente todos los datos almacenados. Si estás seguro que deseas continuar por favor ingrese el nombre del dashboard:",
    InputPlaceholder: "Escribe aquí...",
    CancelLabel: "Cancelar",
    DeleteLabel: "Eliminar",
  },
};
