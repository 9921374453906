export const Languages = {
  ENG: {
    // General
    DeleteLabel: "Delete",
    CancelLabel: "Cancel",
    SaveLabel: "Save",
    AddLabel: "Agregar",
    ExitLabel: "Exit",

    // Delete modal
    ModalTitleLabel: "Delete database",
    SureYouWantDelete: "Are you sure you want to delete this database?",
    ThisActionIsIrreversible:
      "This action is irreversible and will permanently delete all stored data. If you are certain that you want to proceed, please enter the name of the database:",
    ThisActionIsIrreversibleRows:
      "This action is irreversible and will permanently delete all stored data. If you are certain that you want to proceed, please enter the following text:",
    InputPlaceholder: "Write here...",
    IWantToDeleteAllLabel: "I want to delete all rows",

    // Add/Edit row modal
    NewRegisterLabel: "New register",

    // Add /Edit column
    AddColumnLabel: "Add column",
    ColumnNamePlaceholder: "Column name...",
    DatatypeLabel: "Data type...",
    NewOptionsLabel: "Different options are allowed",
    MoreLocationsLabel: "Can select other locations",
    ActivatePrimaryKey: "Activate primary key",
    DeactivatePrimaryKey: "Deactivate primary key",
    DeleteColumnLabel: "Delete column",
    DataTypeLabel: "Data type:",
    ValueAlreadyExistsLabel: "An option with that name already exists",
    YouNeedAtLeastOneOption: "Option lists needs at least one option",

    // Confirm modal
    UnsavedChanges: "Unsaved changes",
    YouHaveUnsavedChanges:
      "You have unsaved changes. Do you want to exit without starting the process?",
  },
  ESP: {
    // General
    DeleteLabel: "Eliminar",
    CancelLabel: "Cancelar",
    SaveLabel: "Guardar",
    AddLabel: "Agregar",
    ExitLabel: "Salir",

    // Delete modal
    ModalTitleLabel: "Eliminar base de datos",
    SureYouWantDelete: "¿Seguro que deseas eliminar esta base de datos?",
    ThisActionIsIrreversible:
      "Esta acción es irreversible y eliminará permanentemente todos los datos almacenados. Si estás seguro que deseas continuar, por favor ingresa el nombre de la base de datos:",
    ThisActionIsIrreversibleRows:
      "Esta acción es irreversible y eliminará permanentemente todos los datos almacenados. Si estás seguro que deseas continuar, por favor ingresa el siguiente texto:",
    InputPlaceholder: "Escribe aquí...",
    IWantToDeleteAllLabel: "Quiero eliminar todas la filas",

    // Add/Edit row modal
    NewRegisterLabel: "Nuevo registro",

    // Add/Edit column
    AddColumnLabel: "Agregar columna",
    ColumnNamePlaceholder: "Nombre de la columna...",
    DatatypeLabel: "Tipo de dato...",
    NewOptionsLabel: "Se pueden escribir valores distintos a las opciones",
    MoreLocationsLabel: "Permitir seleccionar otras ubicaciones",
    ActivePrimaryKey: "Llave principal activada",
    DesactivatedPrimaryKey: "Llave principal desactivada",
    DeleteColumnLabel: "Eliminar columna",
    DataTypeLabel: "Tipo de dato:",
    ValueAlreadyExistsLabel: "Ya existe una opción con ese nombre",
    YouNeedAtLeastOneOption: "Se necesita almenos una opción",

    // Confirm modal
    UnsavedChanges: "Cambios sin guardar",
    YouHaveUnsavedChanges:
      "Tienes cambios sin guardar, ¿Deseas salir sin iniciar el proceso?",
  },
};
