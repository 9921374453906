import { moneyFormat } from "../../../../Helpers";

export const Languages = {
  ENG: {
    Buy: "Buy:",
    Plan: "Plan",
    PlanPrice: "Plan Price:",
    PricePerPeriod: (price, period, byPackage) =>
      byPackage
        ? moneyFormat(price) + " MXN " + period
        : moneyFormat(price) + " MXN " + period + "/user",
    Users: "Number of users:",
    TotalLabel: "Total:",
    TaxIncluded: "tax included",
    BussinesNameLabel: "Bussiness name",
    RfcLabel: "SSN",
    AddressLabel: "Address",
    ZipCodeLabel: "Zip Code",
    CityLabel: "City",
    CountryLabel: "Country",
    SendBillsToLabel: "Send bills to",
    OtherPlanButton: "Choose another plan",
    Finish: "Finish payment",
    Data: "Data",
    ThisCard: "Use this card",
    StopUsingCard: "Don't use this card",
    Accept: "Accept",
    Error:
      "There's been an unexpected error in the transaction and doesn't has can´t perform. Try with a digital card like possible solution",
    Cancel: "Cancel",
    FieldsValidations: (value) => `The field "${value}" needs to be filled`,
    Monthly: "Monthly",
    Annually: "Annually",
    Agreement1: "Through this purchase I acknowledge and consent to the ",
    TermsAndCondsLabel: "Terms and conditions",
    Agreement2: " of the service as well as Geest's ",
    PrivacyNoticeLabel: "Privacy notice",
    RetryPaymentLabel: "Retry payment",
    UsersIn: "Users in",
    UsersLabel: "Users",
    UsersMessage: (value) => `${value} users for the rest of the period`,
    FooterLabel: "Total by user for the rest of the period",
  },
  ESP: {
    Buy: "Comprar:",
    Plan: "Plan",
    PlanPrice: "Precio del plan:",
    PricePerPeriod: (price, period, byPackage) =>
      moneyFormat(price) + " USD " + period,
    Users: "Cantidad de usuarios:",
    TotalLabel: "Total:",
    TaxIncluded: "incluye IVA",
    BussinesNameLabel: "Razón social",
    RfcLabel: "RFC",
    AddressLabel: "Dirección",
    ZipCodeLabel: "Código Postal",
    CityLabel: "Ciudad",
    CountryLabel: "País",
    SendBillsToLabel: "Enviar facturas a",
    OtherPlanButton: "Elegir otro plan",
    Finish: "Finalizar pago",
    Data: "Datos",
    ThisCard: "Utilizar esta tarjeta",
    StopUsingCard: "Dejar de usar esta tarjeta",
    Accept: "Aceptar",
    Error:
      "Ha ocurrido un error inesperado en la transacción y no se ha podido realizar. Intente con una tarjeta digital como posible solución",
    Cancel: "Cancelar",
    FieldsValidations: (value) => `Falta llenar el campo "${value}"`,
    Monthly: "Mensual",
    Annually: "Anual",
    Agreement1: "Mediante esta contratación doy fe y consentimiento de los ",
    TermsAndCondsLabel: "Términos y condiciones",
    Agreement2: " del servicio así como del ",
    PrivacyNoticeLabel: "Aviso de privacidad",
    Agreement3: " de Geest",
    RetryPaymentLabel: "Reintentar pago",
    UsersIn: "Usuarios en",
    UsersLabel: "Usuarios",
    UsersMessage: (value) => `${value} usuarios por el resto del periodo`,
    FooterLabel: "Total por usuario por el resto del periodo",
  },
};
