import styled from "styled-components";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import { ImageDimensionsType, ImageSize } from "../../PDFConfig.d";

export const DraggableItem = styled(Draggable)``;

export const StyledResizable = styled(Resizable)<{
  width: number;
  height: number;
  selected: boolean;
}>`
  .react-resizable-handle {
    background: none;

    &::after {
      content: "";
      position: absolute;
      right: 3px;
      bottom: 3px;
      width: 10px;
      height: 10px;
      z-index: 2;

      border-right: 2px solid
        ${({ selected = false }) =>
          selected ? "rgba(0, 0, 0, 0.4)" : "transparent"};
      border-bottom: 2px solid
        ${({ selected = false }) =>
          selected ? "rgba(0, 0, 0, 0.4)" : "transparent"};
    }
  }

  .react-resizable-handle-se {
    cursor: ${({ selected = false }) => (selected ? "re-size" : "auto")};
  }
`;

export const CurrentImageContainer = styled.div<{
  width: number;
  height: number;
  selected: boolean;
  imageDimensions: ImageDimensionsType;
  imageSize: ImageSize;
}>`
  position: absolute;
  display: flex;
  align-items: start;
  justify-content: start;

  padding: 6px;
  border: 2px solid;
  transition: border-color 0.35s ease;

  width: ${({ width = 150 }) => width}px;
  height: ${({ height = 150 }) => height}px;
  border-color: ${({ selected = false }) =>
    selected ? "#0273e9" : "transparent"};

  img {
    user-select: none;

    width: ${({ imageSize }) => imageSize.width};
    height: ${({ imageSize }) => imageSize.height};

    max-width: ${({ width = 150 }) => width - 12}px;
    max-height: ${({ height = 150 }) => height - 12}px;
  }

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const DeleteIconContainer = styled.div`
  position: absolute;
  top: -9px;
  right: -9px;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  background-color: #0067b0;
  color: white;
  border-radius: 50%;
  cursor: pointer;

  transition: background-color 0.35s ease;
  :hover {
    background-color: #014c9b;
  }
`;
