import styled from "styled-components";
import { BaseDrawer } from "../../Styles";

export const ControlsConfigWrapper = styled(BaseDrawer)`
  flex-direction: column;
  gap: 24px;
  font-size: 16px;

  width: 400px;
  background-color: #f2f8fe;
  padding-bottom: 24px;

  * {
    user-select: none;
  }
`;

export const ConfigHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #48505e;
  font-family: Gotham-Bold;
  padding: 4px;

  p {
    margin: 0;
  }
`;

export const NodeTypeName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #48505e;
  padding: 4px 35px;

  p {
    margin: 0;
  }
`;

export const ConfigurationOptions = styled.div`
  flex: 1;
  overflow: auto;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 30px;
  .MuiButton-label {
    font-size: 16px;
  }
  .deleteButton {
    padding: 5px 0;
  }
`;

export const ButtonLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 2px;
`;
