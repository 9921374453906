import React from "react";
import { ActionInformationProps } from "./AutomationConfig.d";
import { TitleBar, TabElement, TabElementTitle, TabsContainer } from "./styles";
import { CloseIcon } from "../../../../../components/hoverIcons";
import {
  AddActionView,
  FieldOperationActionView,
  SendEmailActionView,
  ChangeFieldValueActionView,
  ChangeTaskPriorityActionView,
  SendNotificationActionView,
  WebHookActionView,
} from "./views";

const ActionInformation: React.FC<ActionInformationProps> = ({
  closeModal,
  selectedAction,
  actionOptions,
  actions,
  setActions,
  setSelectedAction,
  IdNode,
  reloadHome,
  saveAction,
}) => {
  const getView = (action?: string | null) => {
    switch (action) {
      case "copy_value":
      case "substract_fields":
      case "sum_fields":
      case "mult_fields":
      case "div_fields":
        return (
          <FieldOperationActionView
            IdNode={IdNode}
            reloadHome={reloadHome}
            setSelectedAction={setSelectedAction}
            selectedAction={selectedAction}
            actionOptions={actionOptions}
            saveAction={saveAction}
          />
        );
      case "send_email":
        return (
          <SendEmailActionView
            IdNode={IdNode}
            reloadHome={reloadHome}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            actionOptions={actionOptions}
            saveAction={saveAction}
          />
        );
      case "change_field_value":
        return (
          <ChangeFieldValueActionView
            IdNode={IdNode}
            reloadHome={reloadHome}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            actionOptions={actionOptions}
            saveAction={saveAction}
          />
        );
      case "change_tasks_priority":
        return (
          <ChangeTaskPriorityActionView
            IdNode={IdNode}
            reloadHome={reloadHome}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            actionOptions={actionOptions}
            saveAction={saveAction}
          />
        );
      case "send_notification":
        return (
          <SendNotificationActionView
            IdNode={IdNode}
            reloadHome={reloadHome}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            actionOptions={actionOptions}
            saveAction={saveAction}
          />
        );
      case "webhook":
        return (
          <WebHookActionView
            IdNode={IdNode}
            reloadHome={reloadHome}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            actionOptions={actionOptions}
            saveAction={saveAction}
          />
        );
      default:
        return (
          <AddActionView
            actionOptions={actionOptions}
            actions={actions}
            setActions={setActions}
            setSlectedAction={setSelectedAction}
          />
        );
    }
  };

  return (
    <>
      <TitleBar>
        <TabsContainer>
          <TabElement>
            <TabElementTitle>
              {selectedAction
                ? "Configurar nueva acción"
                : "Agregar nueva acción"}
            </TabElementTitle>
          </TabElement>
        </TabsContainer>
        <CloseIcon onClick={closeModal} />
      </TitleBar>
      {getView(selectedAction?.Action)}
    </>
  );
};

export default ActionInformation;
