import React from "react";
import styled, { css } from "styled-components";
import _, { noop as NOOP } from "lodash";
import { Row, Tooltip, Input } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { Typography } from "../../../GeestUI";
import IsRequired from "./helpers/IsRequired";

const { P } = Typography;

export interface NumberInputProps {
  value: string;
  onChange: (value: string) => void;
  onBlur?: (e: any) => void;
  onPressEnter?: (e: any) => void;
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  extraParams?: {
    center?: boolean;
    tooltipOffset?: number;
    tooltipPlacement?: TooltipPlacement;
    mb?: string;
  };
  test_id?: string;
}

interface InputContainerProps {
  isConsult: boolean;
  mb?: string;
}
const InputContainer = styled.div<InputContainerProps>`
  ${({ isConsult, mb = "10px" }) => (isConsult ? "" : `margin-bottom: ${mb};`)}
  width: 100%;
  * {
    margin: 0;
    padding: 0;
  }
  .ant-input-number-focused {
    border-color: #48505e;
    box-shadow: none;
  }
  .ant-input-number-disabled .ant-input-number-input {
    cursor: auto;
  }
`;

interface InputNumberStyledProps {
  $borderColor: string;
  $background: string;
}
const InputNumberStyled = styled(Input)<InputNumberStyledProps>`
  border-radius: 6px;
  border: 2px solid;
  border-color: ${({ $borderColor }) => $borderColor};
  background: ${({ $background }) => $background};
  color: #828d9e;

  width: 100%;
  min-height: 30px;
  resize: none;
  padding: 0 10px;

  box-shadow: none !important;
  transition: all 0.35s ease;
  ${({ disabled, $borderColor }) =>
    !disabled
      ? css`
          :hover {
            border-color: #0273e9 !important;
            box-shadow: none !important;
          }
        `
      : `
          :hover {
            border-color: ${$borderColor} !important;
            box-shadow: none !important;
          }
      `}
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }

  .ant-input-number-handler-wrap {
    display: none;
    width: 0;
    height: 0;
    border: none;
    margin: 0;
    padding: 0;
  }
`;

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  onChange,
  onBlur = NOOP,
  onPressEnter = NOOP,
  isConsult,
  disabled,
  required,
  test_id = "",
  extraParams = { center: false, tooltipPlacement: "bottom", mb: "10px" },
}) => {
  const lengthLimit = extraParams.tooltipOffset;
  const tooltipPlacement = extraParams.tooltipPlacement ?? "bottom";

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let { value: newValue } = e.target;
    const regex = /^-?[0-9]*(\.[0-9]{0,2})?$/;
    newValue = newValue.replace(/,/g, "");
    if (Number(newValue) > 1000000000000000000) return;
    const isValidNumber = !isNaN(Number(newValue)) && regex.test(newValue);
    if (isValidNumber || newValue === "" || newValue === "-") {
      onChange(newValue);
    }
  };

  const getFormatedNumber = (val: string) =>
    val ? String(val).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";

  return (
    <InputContainer isConsult={isConsult} mb={extraParams.mb}>
      {isConsult ? (
        <Row
          justify={extraParams.center ? "center" : "start"}
          align="middle"
          style={{
            overflow: "auto",
            height: "32px",
          }}
        >
          <P style={{ color: "#727e8b" }} data-testid={test_id + "-consult"}>
            {lengthLimit && getFormatedNumber(value).length > lengthLimit ? (
              <Tooltip
                title={getFormatedNumber(value)}
                placement={tooltipPlacement}
              >
                {`${_.truncate(getFormatedNumber(value), {
                  length: lengthLimit,
                })}`}
              </Tooltip>
            ) : _.isNaN(+value) ? (
              ""
            ) : (
              getFormatedNumber(value)
            )}
          </P>
        </Row>
      ) : (
        <InputNumberStyled
          value={getFormatedNumber(value)}
          onChange={handleOnChange}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
          disabled={disabled}
          max={String(Infinity)}
          $background={disabled ? "#edecec" : "white"}
          $borderColor={
            required
              ? `${value}` !== "null" && value !== "" && !!value
                ? "#edecec"
                : "#db232c"
              : "#edecec"
          }
          placeholder="Escribe cantidad..."
        />
      )}
      {required &&
        !isConsult &&
        (`${value}` === "null" || value === "" || !value) && (
          <IsRequired test_id={test_id + "-input-required"} />
        )}
    </InputContainer>
  );
};

export default NumberInput;
