import React from "react";
import GeneralStep from "./Steps/GeneralStep";
import {
  DurationsGraphFormat,
  FieldsCollection,
  FieldsForGroup,
  FilterOption,
  GraphType,
  GroupByInterface,
  GroupByType,
  XAxisValueType,
} from "../../../Dashboards.d";
import FilterStep from "./Steps/FilterStep";
import VisualizationStep from "./Steps/VisualizationStep";

interface Option {
  value: string | number;
  label: string;
}

interface StepContentProps {
  step: number;
  graphName: string;
  setGraphName: (name: string) => void;
  selectedProcesses: string;
  setSelectedProcesses: (selectedProcesses: string) => void;
  selectedTemplate: number;
  selectedDashboard: string | null;
  format: DurationsGraphFormat;
  setFormat: (format: DurationsGraphFormat) => void;
  processOptions: Option[];
  graphToEdit: GraphType | null;
  filters: FilterOption[];
  setFilters: (newFilters: FilterOption[]) => void;
  groupBy: GroupByType;
  setGroupBy: (groupBy: GroupByType) => void;
  groupByFromExistendGraph: GroupByInterface | null;
  setGroupByFromExistendGraph: (group: GroupByInterface | null) => void;
  xAxisValue: XAxisValueType;
  setXAxisValue: (value: XAxisValueType) => void;
  fields: FieldsCollection[];
  setFields: (newSelection: FieldsCollection[]) => void;
  fieldsForGroup: FieldsForGroup;
  setFieldsForGroup: (fields: FieldsForGroup) => void;
  canGoNext: boolean;
  goNextStep: () => void;
  canSubmit: boolean;
  handleOnSubmit: () => void;
  setUnsavedChanges: () => void;
}

const StepContent: React.FC<StepContentProps> = ({
  step,
  graphName,
  setGraphName,
  selectedProcesses,
  setSelectedProcesses,
  format,
  setFormat,
  processOptions,
  selectedTemplate,
  selectedDashboard,
  graphToEdit,
  filters,
  setFilters,
  groupBy,
  setGroupBy,
  groupByFromExistendGraph,
  setGroupByFromExistendGraph,
  xAxisValue,
  setXAxisValue,
  canGoNext,
  fields,
  setFields,
  fieldsForGroup,
  setFieldsForGroup,
  goNextStep,
  canSubmit,
  handleOnSubmit,
  setUnsavedChanges,
}) => {
  const cleanOnChangeProcesses = () => {
    setFilters([]);
    setFieldsForGroup({});
  };

  switch (step) {
    case 0:
      return (
        <GeneralStep
          graphName={graphName}
          setGraphName={setGraphName}
          selectedProcesses={selectedProcesses}
          setSelectedProcesses={setSelectedProcesses}
          selectedTemplate={selectedTemplate}
          selectedDashboard={selectedDashboard}
          format={format}
          setFormat={setFormat}
          options={processOptions}
          filters={filters}
          groupBy={groupBy}
          xAxisValue={xAxisValue}
          groupByFromExistendGraph={groupByFromExistendGraph}
          cleanOnChangeProcesses={cleanOnChangeProcesses}
          canGoNext={canGoNext}
          goNextStep={goNextStep}
          setUnsavedChanges={setUnsavedChanges}
        />
      );

    case 1:
      return (
        <FilterStep
          graphName={graphName}
          selectedProcesses={selectedProcesses}
          selectedTemplate={selectedTemplate}
          selectedDashboard={selectedDashboard}
          format={format}
          graphToEdit={graphToEdit}
          filters={filters}
          setFilters={setFilters}
          groupBy={groupBy}
          xAxisValue={xAxisValue}
          groupByFromExistendGraph={groupByFromExistendGraph}
          canGoNext={canGoNext}
          goNextStep={goNextStep}
          setUnsavedChanges={setUnsavedChanges}
        />
      );

    case 2:
      return (
        <VisualizationStep
          graphName={graphName}
          selectedProcesses={selectedProcesses}
          selectedTemplate={selectedTemplate}
          selectedDashboard={selectedDashboard}
          format={format}
          graphToEdit={graphToEdit}
          filters={filters}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          xAxisValue={xAxisValue}
          setXAxisValue={setXAxisValue}
          fields={fields}
          setFields={setFields}
          fieldsForGroup={fieldsForGroup}
          setFieldsForGroup={setFieldsForGroup}
          setGroupByFromExistendGraph={setGroupByFromExistendGraph}
          canSubmit={canSubmit}
          handleOnSubmit={handleOnSubmit}
          setUnsavedChanges={setUnsavedChanges}
        />
      );

    default:
      return <div>error</div>;
  }
};

export default StepContent;
