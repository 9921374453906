import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
// Context
import { SesionContext } from "../config/State";

const VisitorRoute = ({ component: Component, ...rest }) => {
  const [sesion] = useContext(SesionContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!sesion) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/home",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default VisitorRoute;
