export const BasicExample = `export const keyBindingFn = (e: SyntheticKeyboardEvent): string | null => {
  if (e.key === "t" && hasCommandModifier(e)) {
    return COMMANDS.CTRL_T_COMMAND;
  }

  return getDefaultKeyBinding(e);
};

export const handleKeyCommand = (
  command: string,
  editorState: EditorState,
  handleOnChange: (newEditorState: EditorState) => void,
): DraftHandleValue => {
  if (command === COMMANDS.CTRL_T_COMMAND) {
    addTableEntity(
      editorState,
      handleOnChange,
    );
    return "handled";
  }

  return "not-handled";
};
`;
