import styled, { keyframes } from "styled-components";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Checkbox } from "antd";

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  padding: 0 6px;
  height: 24px;
`;

export const FilterOptionLabel = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 8px;

  p {
    margin: 0;
  }
`;

export const ActionIconContainer = styled.div<{ hoverBg?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 2px;
  background-color: transparent;
  color: #0273e9;

  transition: all 0.35s ease;

  :hover {
    background-color: ${({ hoverBg }) => hoverBg ?? "#d9d9d9"};
  }
  :focus {
    background-color: ${({ hoverBg }) => hoverBg ?? "#d9d9d9"};
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const ProceseesActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    padding-right: 6px;
    color: #828d9e;
    margin: 0;
    position: relative;
  }

  p::after {
    content: "";
    position: absolute;
    top: 6px;
    right: 0;
    width: 1px;
    height: 11px;
    border-radius: 6px;
    background-color: #828d9e;
  }
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  width: 30px;
  min-height: 30px;
  height: 30px;
`;

export const StyledCheckbox = styled(Checkbox)`
  font-family: Gotham-Book;

  .ant-checkbox {
    max-width: 100%;
    width: 16px;

    .ant-checkbox-inner {
      max-width: 100% !important;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      max-width: 100% !important;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0273e9;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  overflow: auto;
  max-height: 90vh;
`;

export const Content = styled.div<{ $openFilters: boolean; $height: number }>`
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const PipelineTableContainer = styled(TableContainer)<{
  $openFilters: boolean;
  $height: number;
}>`
  height: 100%;
  max-height: 100%;

  & table {
    overflow: auto;
  }
  & .MuiTableRow-root {
    height: 47px;
  }
  & .MuiTableCell-root {
    white-space: nowrap;
    border: none;
    border-right: 2px solid white;
  }
  & .MuiTableCell-head {
    z-index: 2;
  }
  & .MuiTableCell-stickyHeader {
    background-color: #f2f8fe;
  }
  & .MuiTableCell-sizeSmall {
    padding: 6px 8px;
  }
  * {
    user-select: none !important;
  }
`;

export const PipelineTable = styled(Table)`
  z-index: 0;

  .fixed {
    position: sticky;
    left: 0;
    z-index: 3;
  }
`;

export const PipelineTableHead = styled(TableHead)`
  height: 40px;

  .fixed {
    top: 0;
    z-index: 4;
  }
`;

export const PipelineTableRow = styled(TableRow)``;

const shine = keyframes`
  0% {
    background-position: 150% 0;
  }
  70% {
    background-position: -150% 0;
  }
  100% {
    background-position: -150% 0;
  }
`;

export const RowSkeleton = styled(TableRow)`
  cursor: progress;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: hsl(0, 0%, 90%);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 10%,
      rgba(255, 255, 255, 0) 25%
    );
    background-size: 150% 100%;
    animation: ${shine} 1.5s infinite linear;
  }
`;

export const PipelineTableHeadCell = styled(TableCell)<{
  minWidth: string;
  maxWidth: string;
}>`
  background-color: #f2f8fe;
  border-radius: 6px 6px 0 0;
  text-align: left;
  white-space: nowrap;

  min-width: ${({ minWidth = "120px" }) => minWidth};
  max-width: ${({ maxWidth = "271px" }) => maxWidth};

  position: sticky;
  top: 0;

  span {
    display: block;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const TableHeadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const TableHeaderIconContainer = styled.div<{ unsetted: boolean }>`
  color: ${({ unsetted }) => (unsetted ? "#b7dbfe" : "#0273e9")};
  cursor: pointer;
`;

export const TableHeaderOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #0273e9;
  cursor: pointer;
`;

export const TableHeaderLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const TableHeaderName = styled.span`
  font-size: 12px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

export const ContextMenuOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    margin: 0;
  }
`;

export const PipelineTableBody = styled(TableBody)``;

export const PipelineTableBodyCell = styled(TableCell)<{
  bg?: string;
  brNone?: boolean;
  minWidth: string;
  maxWidth: string;
}>`
  background-color: ${({ bg }) => bg || "#fff"};
  border-bottom: 1px solid #edecec !important;
  border-right: ${({ brNone }) =>
    brNone ? "none !important" : "1px solid #828d9e"};

  min-width: ${({ minWidth = "120px" }) => minWidth};
  max-width: ${({ maxWidth = "271px" }) => maxWidth};
`;

export const PipelineTableBodyCellContent = styled.div<{
  $hasCheckbox?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;

  ${({ $hasCheckbox }) => ($hasCheckbox ? "padding-right: 16px" : "")}
`;

export const PipelineTableFooterCell = styled(TableCell)`
  background-color: #f2f8fe;
  text-align: left;
  white-space: nowrap;
  border-right: 1px solid #e0f0ff !important;

  span {
    display: block;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const PipelineTableFoot = styled(TableFooter)`
  height: 40px;
  background-color: white;

  position: sticky;
  bottom: 0;
  z-index: 4;

  .MuiTableCell-footer:first-child {
    border-radius: 0 0 0 6px;
  }

  .MuiTableCell-footer:last-child {
    border-radius: 0 0 6px 0;
    border-right: 1px solid white;
  }
`;

export const TotalsCellContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;

  color: #48505e;
  font-family: Gotham-Book;

  span {
    font-family: Gotham-Bold;
  }

  p {
    margin: 0;
    font-weight: 325;
  }
`;
