import React, { useRef, useState } from "react";
import { Row } from "antd";
import { noop as NOOP } from "lodash";
import moment from "moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Modal from "@material-ui/core/Modal";
import { CalendarToday } from "@material-ui/icons";
import MomentUtils from "@date-io/moment";
import { useMutation } from "../../../../../hooks";
import { PostponeModalProps, PostponeData } from "./PostponeModal.d";
import { GetUserPendingsResponse } from "../../../Pendings.d";
import { IoClose } from "react-icons/io5";
import {
  StyledPaper,
  PopoverTitle,
  PopoverReminderLabel,
  PopoverBody,
  PopoverContent,
  SubmitContainer,
  SubmitButton,
  TextAreaStyled,
  ReminderInputContainer,
  TimeToAddInput,
  TimeToAddSelect,
  DropDownIcon,
  WeekDaySelect,
  IconsContainer,
} from "./styles";
import { Languages } from "./Dictionary";
import { Popover } from "@material-ui/core";
import AddTimePopover from "./AddTimePopover";
import { GoTriangleDown } from "react-icons/go";
import WeekDayPopover from "./WeekDayPopover";
import { AiOutlineCalendar } from "react-icons/ai";

const {
  TitlePostpone,
  TitleReschedule,
  ReminderLabel,
  ReminderPlaceholder,
  AddTimeInputTitlePostpone,
  AddTimeInputTitleReschedule,
  InLabel,
  NextLabel,
  PostponeToDateLabel,
  RescheduleToDateLabel,
  DatePickerPlaceHolder,
  TimeSelet,
  WeekDays,
  PostponeButtonLabel,
  RescheduleButtonLabel,
} = Languages["ESP"];

const emptyValue = {
  date: "",
  reminder: "",
};

const PostponeModal: React.FC<PostponeModalProps> = ({
  idPending,
  onConfirm,
  onCancel,
  isWaiting,
  fieldsForm,
  defaultValue = emptyValue,
  setDefaultValue = NOOP,
}) => {
  const textInput = useRef(null);

  const [postponeData, setPostponeData] = useState<PostponeData>(defaultValue);
  const [typeOfTime, setTypeOfTime] = useState<string>(
    Boolean(isWaiting ? defaultValue.date : emptyValue.date) ? "Picker" : ""
  );
  const [timeToAdd, setTimeToAdd] = useState<number>(1);
  const [timeToAddSelect, setTimeToAddSelect] = useState<
    "minutes" | "hours" | "days" | "weeks" | "months"
  >("days");
  const [timeToAddPopoverVisible, setTimeToAddPopoverVisible] =
    useState<boolean>(false);

  const timeToAddPopoverRef = useRef(null);
  const [weekDay, setWeekDay] = useState<number>(1);
  const [weekDayPopoverVisible, setWeekDayPopoverVisible] =
    useState<boolean>(false);
  const weekDayPopoverRef = useRef(null);

  // Pending detail
  const [userPostponeTask, submitingOnDetails] = useMutation<[]>({
    func: "Ver2-MyPendings-upt",
    onSuccess: () => onConfirm(),
  });

  // Pendings
  const [rescheduleTask, submitingOnPendings] =
    useMutation<GetUserPendingsResponse>({
      func: "Ver2-MyPendings-rst",
      onSuccess: () => onConfirm(),
    });

  const getWeekDayLabel = (day: number) => {
    switch (day) {
      case 1:
        return WeekDays.monday;
      case 2:
        return WeekDays.tuesday;
      case 3:
        return WeekDays.wednesday;
      case 4:
        return WeekDays.thursday;
      case 5:
        return WeekDays.friday;
      case 6:
        return WeekDays.saturday;
      case 7:
        return WeekDays.sunday;
    }
  };

  const setReminder = (reminder: string): void => {
    setPostponeData({
      ...postponeData,
      reminder,
    });
  };

  const setDate = (date: any) => {
    setDefaultValue({
      ...postponeData,
      date: date?.format("YYYY-MM-DD HH:mm") || "",
    });
    setPostponeData({
      ...postponeData,
      date: date?.format("YYYY-MM-DD HH:mm") || "",
    });
  };

  const onSelectTimeToAdd = (
    time: "minutes" | "hours" | "days" | "weeks" | "months"
  ) => {
    if (time === timeToAddSelect) {
      setTimeToAddPopoverVisible(false);
      return;
    }
    setTimeToAddSelect(time);
    setTimeToAddPopoverVisible(false);
    const date = moment().add(timeToAdd, time);
    setDate(date);
  };

  const onSelectWeekDay = (dayToPostpone: number) => {
    if (weekDay === dayToPostpone) {
      setWeekDayPopoverVisible(false);
      return;
    }
    setWeekDay(dayToPostpone);
    setWeekDayPopoverVisible(false);

    const today = moment().isoWeekday();
    if (today < dayToPostpone) {
      // sets this week's instance of the postpone selected day
      setDate(moment().isoWeekday(dayToPostpone));
    } else {
      // otherwise, give me *next week's* instance of that same day
      setDate(moment().add(1, "week").isoWeekday(dayToPostpone));
    }
  };

  const handleFocus = (e: any) => e.target.select();

  const handleChangeTypeOfTime = (type: string) => {
    setTypeOfTime(type);
    let date = null;
    switch (type) {
      case "AddTime":
        date = moment().add(timeToAdd, timeToAddSelect);
        setDate(date);
        return;

      case "NextDay":
        const today = moment().isoWeekday();
        if (today < weekDay) {
          // sets this week's instance of the postpone selected day
          setDate(moment().isoWeekday(weekDay));
        } else {
          // otherwise, give me *next week's* instance of that same day
          setDate(moment().add(1, "week").isoWeekday(weekDay));
        }
        return;
    }
  };

  const isFormValid = Boolean(postponeData.date);

  const onSubmit = (): void => {
    if (isFormValid) {
      if (!isWaiting) {
        userPostponeTask({
          args: {
            IdTaskValue: idPending,
            PostponeDate: postponeData.date,
            PostponeMessage: postponeData.reminder.trim(),
            FieldsForm: fieldsForm,
          },
        });
      } else {
        rescheduleTask({
          args: {
            IdTaskValue: idPending,
            NewScheduleTime: postponeData.date,
            PostponeMessage: postponeData.reminder.trim(),
          },
        });
      }
    }
  };

  return (
    <Modal open onClose={onCancel}>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          height: "100%",
        }}
        onClick={() => onCancel()}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <PopoverTitle>
            <p>{isWaiting ? TitleReschedule : TitlePostpone}</p>
            <div className="icon-container" onClick={onCancel}>
              <IoClose color="#48505e" size={20} />
            </div>
          </PopoverTitle>

          <PopoverReminderLabel>
            <p>{ReminderLabel}</p>
          </PopoverReminderLabel>

          <ReminderInputContainer>
            <TextAreaStyled
              placeholder={ReminderPlaceholder}
              value={postponeData.reminder}
              onChange={(e) => setReminder(e.target.value)}
              autoSize
              autoFocus
              onFocus={(e) => e.target.select()}
            />
          </ReminderInputContainer>

          <PopoverBody>
            <PopoverContent>
              <div className="subtitle">
                {isWaiting
                  ? AddTimeInputTitleReschedule
                  : AddTimeInputTitlePostpone}
              </div>
              <div className="select-element">
                <input
                  tabIndex={0}
                  type="radio"
                  value="AddTime"
                  name="typeOfTime"
                  checked={typeOfTime === "AddTime"}
                  onClick={() => {
                    handleChangeTypeOfTime("AddTime");
                    if (textInput.current) {
                      // @ts-ignore
                      textInput.current.focus();
                    }
                  }}
                />
                <span>{InLabel}</span>
                <TimeToAddInput
                  ref={textInput}
                  tabIndex={0}
                  type="number"
                  name="TimeToAdd"
                  min={1}
                  value={timeToAdd}
                  onFocus={handleFocus}
                  onKeyPress={(e) => {
                    if (e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  onChange={({ target: { value } }) => {
                    if (Boolean(value)) setTimeToAdd(Number(value));
                  }}
                  onClick={() => handleChangeTypeOfTime("AddTime")}
                  onBlur={() => {
                    handleChangeTypeOfTime("AddTime");
                  }}
                />
                <TimeToAddSelect
                  tabIndex={0}
                  ref={timeToAddPopoverRef}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      setTimeToAddPopoverVisible(true);
                    }
                  }}
                  onClick={() => {
                    handleChangeTypeOfTime("AddTime");
                    setTimeToAddPopoverVisible(true);
                  }}
                >
                  {TimeSelet[timeToAddSelect]}
                  <DropDownIcon>
                    <GoTriangleDown size={12} color="#48505e" />
                  </DropDownIcon>
                </TimeToAddSelect>
                <Popover
                  open={timeToAddPopoverVisible}
                  anchorEl={timeToAddPopoverRef.current}
                  onClose={() => setTimeToAddPopoverVisible(false)}
                  PaperProps={{
                    style: {
                      borderRadius: "6px",
                      width: "145px",
                      padding: "5px 0",
                      marginTop: "1px",
                      boxShadow: "4px 10px 6px rgba(72, 80, 94, 0.3)",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <AddTimePopover
                    timeToAddSelect={timeToAddSelect}
                    onSelectTimeToAdd={onSelectTimeToAdd}
                  />
                </Popover>
              </div>

              <div className="select-element">
                <input
                  tabIndex={0}
                  type="radio"
                  value="NextDay"
                  name="typeOfTime"
                  checked={typeOfTime === "NextDay"}
                  onClick={() => handleChangeTypeOfTime("NextDay")}
                />
                <span>{NextLabel}</span>
                <WeekDaySelect
                  tabIndex={0}
                  ref={weekDayPopoverRef}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      setWeekDayPopoverVisible(true);
                    }
                  }}
                  onClick={() => {
                    handleChangeTypeOfTime("NextDay");
                    setWeekDayPopoverVisible(true);
                  }}
                >
                  {getWeekDayLabel(weekDay)}
                  <DropDownIcon>
                    <GoTriangleDown size={12} color="#48505e" />
                  </DropDownIcon>
                </WeekDaySelect>
                <Popover
                  open={weekDayPopoverVisible}
                  anchorEl={weekDayPopoverRef.current}
                  onClose={() => setWeekDayPopoverVisible(false)}
                  PaperProps={{
                    style: {
                      borderRadius: "6px",
                      width: "145px",
                      padding: "5px 0",
                      marginTop: "1px",
                      boxShadow: "4px 10px 6px rgba(72, 80, 94, 0.3)",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <WeekDayPopover
                    weekDay={weekDay}
                    onSelectWeekDay={onSelectWeekDay}
                  />
                </Popover>
              </div>

              <div className="subtitle">
                {isWaiting ? RescheduleToDateLabel : PostponeToDateLabel}
              </div>
              <div
                className="select-element"
                onClick={() => handleChangeTypeOfTime("Picker")}
                style={{ position: "relative" }}
              >
                <input
                  tabIndex={0}
                  type="radio"
                  value="Picker"
                  name="typeOfTime"
                  checked={typeOfTime === "Picker"}
                  onClick={() => handleChangeTypeOfTime("Picker")}
                />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    variant="dialog"
                    format="DD/MMM/YY HH:mm"
                    value={postponeData.date ? moment(postponeData.date) : null}
                    onChange={setDate}
                    InputProps={{
                      style: {
                        color: "#828d9e",
                        background: "white",
                        border: "2px solid #edecec",
                        borderRadius: "6px",
                        paddingLeft: "5px",
                        height: "32px",
                        fontSize: "14px",
                      },
                      endAdornment: (
                        <Row style={{ flexWrap: "nowrap", marginRight: "5px" }}>
                          <CalendarToday
                            style={{
                              color: "#48505e",
                              width: "15px",
                              height: "15px",
                            }}
                          />
                        </Row>
                      ),
                      disableUnderline: true,
                      placeholder: DatePickerPlaceHolder,
                    }}
                    style={{ width: "100%" }}
                  />
                </MuiPickersUtilsProvider>

                <IconsContainer>
                  <AiOutlineCalendar color="#48505e" size={14} />
                </IconsContainer>
              </div>
            </PopoverContent>

            <SubmitContainer>
              <SubmitButton
                disabled={
                  !isFormValid || submitingOnDetails || submitingOnPendings
                }
                onClick={onSubmit}
              >
                {isWaiting ? RescheduleButtonLabel : PostponeButtonLabel}
              </SubmitButton>
            </SubmitContainer>
          </PopoverBody>
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default PostponeModal;
