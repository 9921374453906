import styled from "styled-components";

export const Container = styled.div<{ editionMode: boolean }>`
  height: 100%;
  max-height: 100%;
  overflow: auto;

  ${({ editionMode = false }) => {
    if (editionMode) {
      return `
        background-image: radial-gradient(#edecec 10%, transparent 5%);
        background-size: 25px 25px;
        background-position: 0 0, 30px 30px;
        background-repeat: repeat;
      `;
    }
  }}

  .react-draggable-dragging {
    .table-container {
      border: 1px solid #1f9334;
      box-shadow: 4px 10px 6px 0px rgba(31, 147, 52, 0.3);
    }
  }

  .react-grid-placeholder {
    border-radius: 6px;
    background-color: #0273e988;
  }
`;

export const GridItemWrapper = styled.div`
  z-index: 2;
`;

export const TableContainer = styled.div<{ editionMode: boolean }>`
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #edecec;
  background-color: #fff;
  height: 100%;

  transition: box-shadow 0.35s ease, border 0.35s ease;
  :hover {
    border: 1px solid
      ${({ editionMode = false }) => (editionMode ? "#0273e988" : "#edecec")};
  }

  .wrapper {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: calc(100% - 25px);
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContextMenuOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    margin: 0;
  }
`;

export const GraphTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  p {
    margin: 0;
    font-size: 16px;
    color: #48505e;
    user-select: none;
  }
`;
