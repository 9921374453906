import React, { useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { PageView } from "../../analytics";
import { Languages } from "./Dictionary";
import { Container, Content } from "./Styles";
import Dashboard from "./Dashboard";
import Pipeline from "./Pipeline";
import ProcessDetail from "./ProcessDetail";

const Reports: React.FC = () => {
  const { TabTitles } = Languages["ESP"];
  const { path: BasePath } = useRouteMatch();

  const Routes = [
    {
      title: TabTitles.Tracking,
      view: Dashboard,
      path: "/home/reports/tracking",
    },
    {
      title: TabTitles.Pipeline,
      view: Pipeline,
      path: "/home/reports/pipeline",
    },
    {
      title: "Detalle",
      path: "/home/reports/detail/:idProcess",
      view: ProcessDetail,
    },
  ];

  useEffect(() => {
    PageView("/reports");
  }, []);

  return (
    <Container>
      <Content>
        <Switch>
          {Routes.map(({ path, view }, idx) => (
            <Route path={path} component={view} key={idx} />
          ))}
          <Redirect from={BasePath} to={BasePath + "/tracking"} />
        </Switch>
      </Content>
    </Container>
  );
};

export default Reports;
