import React from "react";
import _ from "lodash";
import styled from "styled-components";
import User from "../../../../../components/User";
import {
  ExecutorValueType,
  StatusDataOriginType,
  User as UserType,
} from "../DynamicCell.d";

const UsersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 5px;
  min-height: 34px;
  font-family: Gotham-Bold;
`;

interface CurrentExecutorsComponentProps {
  userSelectDataOrigin: UserType[];
  statusDataOrigin: StatusDataOriginType[];
  executorsValue: ExecutorValueType[];
}

const CurrentExecutorsComponent: React.FC<CurrentExecutorsComponentProps> = ({
  userSelectDataOrigin,
  statusDataOrigin,
  executorsValue,
}) => {
  const offset = 28;

  const getUser = (id: string) => {
    return _.find(userSelectDataOrigin, { IdUser: Number(id) });
  };

  const decodedValues = (() => {
    const value = executorsValue.map((usr) => usr.IdUser).join(",");

    if (!value) return [];
    return value.split(",").map((idUser) => getUser(idUser));
  })();

  const getSize = (user: UserType | undefined) => {
    let newSize = "large";
    if (user) {
      const fullName = `${user?.FirstName} ${user?.LastName}`;
      if (offset && user.Email.length > offset) newSize = "middle";
      if (offset && fullName.length > offset) newSize = "small";
    }
    return newSize;
  };

  const getStatusColor = (status: number | undefined | null) => {
    const statusInfo = status
      ? statusDataOrigin.find((s) => s.Value === status)
      : null;
    if (!statusInfo) return "#d9d9d9";

    switch (statusInfo.Label) {
      case "New":
        return "#fffbaa";
      case "OnTime":
        return "#08979c";
      case "Completed":
        return "#b4ea9a";
      case "Canceled":
        return "#d9d9d9";
      case "WaitingToStart":
        return "#d46b08";
      case "Stuck":
        return "#cF1322";
      default:
        return "#d9d9d9";
    }
  };

  const getBorderColor = (IdUser: number | undefined) => {
    if (!IdUser) return "";

    const IdStatus = executorsValue.find(
      (usr) => usr.IdUser === IdUser
    )?.IdStatus;

    return getStatusColor(IdStatus);
  };

  const getTasks = (IdUser: number | undefined) => {
    if (!IdUser) return [];

    return executorsValue
      .filter((usr) => usr.IdUser === IdUser)
      .map((usr) => usr.Tasks)
      .flat();
  };

  return (
    <UsersList>
      {[...decodedValues].map((u, idx) => {
        const tasks = getTasks(u?.IdUser);
        const usr = {
          ..._.cloneDeep(u),
          Email: tasks.length > 0 ? tasks[0].Title : "",
        };

        return (
          <User
            key={idx}
            user={usr as UserType}
            size={
              decodedValues.length >= 2 ? "small" : getSize(usr as UserType)
            }
            popover
            userTasks={getTasks(u?.IdUser)}
            borderColor={getBorderColor(u?.IdUser)}
            statusDataOrigin={statusDataOrigin}
            nameColor="#48505e"
            nameSize="12px"
            emailSize="10px"
          />
        );
      })}
    </UsersList>
  );
};

export default CurrentExecutorsComponent;
