export const Languages = {
  ENG: {
    TitleLabels: {
      TEAMS: "My teams",
      TEAMDETAILS: "Team information",
    },
    TableLabels: {
      CODE: "Code",
      NAME: "Name",
      EMAIL: "Email",
      PERMISSIONS: "Permissions",
    },
    ButtonsLabels: {
      CANCEL: "Cancel",
      CREATE: "Create",
      LEAVE: "Leave",
      EDIT: "Edit",
      DELETE: "Delete",
      CONFIRM: "Confirm",
      DELETETEAM: "Delete team",
      SEND: "Send",
      ORG: "Organigram",
    },
    PopoverLabels: {
      CREATETEAM: "Create team",
      LEAVETEAM: "Do you want to leave the team?",
      TEAMNAME: "Team name",
      DELETEMEMBER: "Do you want to remove this member?",
      DELETETEAM: "Do you want to delete this team?",
      OPENORG: "Open organigram",
    },
    TaskTransferLabels: {
      TASKTRANSFER: "Task transfer",
      MESSAGE:
        "The user you are trying to delete has pending  tasks and assigned templates, you cannot delete them until you transfer their tasks.",
      USER: "User",
    },
    Change: "Change",
    Members: "Members",
    Add: "Add",
    AddUserLabel: "Add new member email",
    AddUserAlert: "This emails doesn't have an account",
    AddUserDescription: "Register the new user:",
    WaitingConfirmation: "Waiting confirmation...",
    Actions: "Actions",
    ReplaceUserLabel: "Replace user",
    ReassignUserTasksLabel: "Reassign user tasks",
    RoleSelectPlaceHolder: "Select roles",
    Messages: {
      EmptyName: "Team name is empty",
      InvalidEmail: "Email isn't valid",
      UserAdded: "User added to team",
      OnlyImages: "You can only upload JPG/PNG files",
      FileLimit: (Limit) => `File size must be less than ${Limit}MB`,
      InvalidFileName: "File name is not valid",
      SelectUserFirst: "Select a user first",
      SuccessfulUserCreated: "User was created successfuly",
      UserDeleted: "User deleted",
      SuccesfulCreation: "Team was created successfully",
      ErrorNewEmailEmpty: "Email is not valid",
      ErrorNewEmailInTeam: "A user with that email is already in the team",
      SuccessfulUpdatedRoles: "Roles updated successfully",
      WarningRolesQuantity: "A user cannot have more than three roles",
    },
    PermissionList: [
      {
        Title: "Edit team and its members",
        Options: ["0", "1"],
      },
      {
        Title: "Run templates",
        Options: ["2", "3"],
      },
      {
        Title: "Configure templates",
        Options: ["4", "5"],
      },
      {
        Title: "Share templates to other teams",
        Options: ["6", "7"],
      },
      {
        Title: "Edit reports for the whole team",
        Options: ["8", "9"],
      },
      {
        Title: "Configure databases",
        Options: ["a", "b"],
      },
      {
        Title: "Edit databases information",
        Options: ["c", "d"],
      },
      {
        Title: "View databases information",
        Options: ["e", "f"],
      },
      {
        Title: "Share databases to other teams",
        Options: ["g", "h"],
      },
      {
        Title: "See reports of the entire process where you participate",
        Options: ["i", "j"],
      },
      {
        Title: "Set up a team's dashboards",
        Options: ["k", "l"],
      },
    ],
  },

  ESP: {
    TitleLabels: {
      TEAMS: "Mis empresas",
      TEAMDETAILS: "Información de la empresa",
    },
    TableLabels: {
      CODE: "Código",
      NAME: "Nombre",
      EMAIL: "Email",
      PERMISSIONS: "Permisos",
    },
    ButtonsLabels: {
      CANCEL: "Cancelar",
      CREATE: "Agregar",
      LEAVE: "Abandonar",
      EDIT: "Editar",
      DELETE: "Eliminar",
      CONFIRM: "Confirmar",
      DELETETEAM: "Eliminar empresa",
      SEND: "Enviar",
      ORG: "Organigrama",
    },
    PopoverLabels: {
      CREATETEAM: "Agregar empresa",
      LEAVETEAM: "¿Desea abandonar la empresa?",
      TEAMNAME: "Nombre de la empresa...",
      DELETEMEMBER: "¿Desea eliminar este integrante?",
      DELETETEAM: "¿Desea eliminar esta empresa?",
      OPENORG: "Abrir organigrama",
    },
    TaskTransferLabels: {
      TASKTRANSFER: "Transferir tareas",
      MESSAGE:
        "El usuario que intentas eliminar tiene tareas pendientes y asignaciones en procesos, no puedes eliminarlo hasta que transfieras sus tareas.",
      USER: "Usuario",
    },
    Change: "Cambiar",
    Members: "Integrantes",
    Add: "Agregar",
    AddUserLabel: "Agrega correo electrónico del nuevo integrante...",
    AddUserAlert: "Este correo no tiene cuenta en Geest",
    AddUserDescription: "Registra al nuevo usuario",
    WaitingConfirmation: "Esperando confirmación...",
    Actions: "Acciones",
    ReplaceUserLabel: "Reemplazar usuario",
    ReassignUserTasksLabel: "Reasignar tareas de usuario",
    RoleSelectPlaceHolder: "Selecciona roles",
    Messages: {
      EmptyName: "El nombre de empresa está vacío",
      InvalidEmail: "El correo no es válido",
      UserAdded: "Usuario agregado a la empresa",
      OnlyImages: "Sólo puedes subir archivos JPG/PNG",
      FileLimit: (Limit) =>
        `El tamaño del archivo debe ser menor de ${Limit}MB`,
      InvalidFileName: "El nombre del archivo no es válido",
      SelectUserFirst: "Primero selecciona un usuario",
      SuccessfulUserCreated: "Usuario creado con exito",
      UserDeleted: "Usuario eliminado",
      SuccesfulCreation: "Empresa creada con éxito",
      ErrorNewEmailEmpty: "El correo no es válido",
      ErrorNewEmailInTeam: "El usuario ya es parte de la empresa",
      SuccessfulUpdatedRoles: "Roles actualizados con éxito",
      WarningRolesQuantity: "El usuario no puede tener más de 3 roles",
    },
    PermissionList: [
      {
        Value: "CanEditTeamAndMembers",
        Label: "Editar miembros e información de la empresa",
      },
      {
        Value: "CanConfigureProcesses",
        Label: "Configurar procesos",
      },
      {
        Value: "CanStartProcesses",
        Label: "Iniciar procesos",
      },
      {
        Value: "CanEditAllReports",
        Label:
          "Reportes > Editar la información de todos los procesos de la empresa",
      },
      {
        Value: "CanViewAllReports",
        Label:
          "Reportes > Ver la información de todos los procesos de la empresa",
      },
      {
        Value: "CanEditHisReportsInfo",
        Label:
          "Reportes > Editar toda la información de los procesos donde participa",
      },
      {
        Value: "CanViewHisReportsInfo",
        Label:
          "Reportes > Ver toda la información de los procesos donde participa",
      },
      {
        Value: "CanViewHisReportsFlux",
        Label: "Reportes > Ver el flujo de los procesos donde participa",
      },
      {
        Value: "CanEditDatabases",
        Label: "Editar información de bases de datos",
      },
      {
        Value: "CanViewDatabases",
        Label: "Ver información de bases de datos",
      },
    ],
  },
};
