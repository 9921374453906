import React, { useState } from "react";
import { nanoid } from "nanoid";
import _, { noop as NOOP } from "lodash";
import { Draggable } from "react-beautiful-dnd";
import { AiOutlineEdit } from "react-icons/ai";
import DynamicInput from "../../../components/DynamicInput";
import EditColumn from "../Modals/EditColumn";
import EditLink from "../Modals/EditLink";
import EditIdRowColumn from "../Modals/EditIdRowColumn";
import PrimaryKeyIcon from "../../../resources/img/Databases/icon_key.svg";
import { ColumnType, Order, TableData } from "../DataBases.d";
import {
  Column,
  ColumnHeader,
  Header,
  RowBox,
  RowValue,
  EmptyRow,
  EditColumnButton,
  PrimaryKeyContainer,
  PrimaryKey,
  ColumnTitleWrapper,
  ColumnTitle,
  ContextMenuOptionContainer,
  TableHeaderIconContainer,
} from "../Styles";
import { useHover } from "./HoverContext";
import Cell from "./Cell";
import Tooltip from "../../../components/Tooltip";
import { Popover } from "@material-ui/core";
import OrderIcon from "../../Reports/Pipeline/OrderIcon";
import ContextMenu from "../../../components/ContextMenu";
import { FiTrash } from "react-icons/fi";
import openVDIcon from "../../../resources/img/openVDB.svg";
import { useParams } from "react-router-dom";
import ConfirmDeleteRow from "../Modals/ConfirmDeleteRow";
import { ReactComponent as LinkIcon } from "../../../resources/img/linkdb.svg";

interface DraggableItemProps {
  draggableId: string;
  editingColumnId: number | null;
  setEditingColumnId: (id: number | null) => void;
  index: number;
  column?: TableData;
  setSelectedColumn: (column: TableData | null) => void;
  selectedColumn: TableData | null;
  columnTypeArray: ColumnType[];
  canEditDatabases: boolean;
  order: Order;
  onOrder: (idColumn: number) => void;
  handleSetEditRow: (id: number) => void;
  reload: () => void;
  isScrolled: boolean;
  teamCurrency: string;
  columns: TableData[];
}

const DraggableItem: React.FC<DraggableItemProps> = ({
  draggableId,
  editingColumnId,
  setEditingColumnId,
  index,
  column,
  setSelectedColumn,
  selectedColumn,
  columnTypeArray,
  canEditDatabases,
  order,
  onOrder,
  handleSetEditRow,
  reload,
  isScrolled,
  teamCurrency,
  columns,
}) => {
  const [editPopupVisible, setEditPopupVisible] = useState<boolean>(false);
  const [editColumnEl, setEditColumnEl] = useState<HTMLDivElement | null>(null);
  const [openContextMenu, setOpenContextMenu] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [points, setPoins] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const { IdTeam, IdVarDB } = useParams<{ IdTeam: string; IdVarDB: string }>();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const { hoveredIndex, setHoveredIndex } = useHover();
  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const handleCloseEditPopup = () => {
    setEditPopupVisible(false);
    setEditColumnEl(null);
    setSelectedColumn(null);
    setEditingColumnId(null);
  };

  const handleOpenEditPopup = (column: TableData) => {
    setEditPopupVisible(true);
    setSelectedColumn(column);
    setEditingColumnId(column.IdColumn);
  };

  const getValue = (type: string, value: any) => {
    switch (type) {
      case "number":
        return String(value);
      case "currency":
        return value.Value;
      case "phone_number":
        return value.Value;
      case "users_select":
        return value.map((v: any) => v.IdUser).join(",");
      default:
        return value;
    }
  };

  const getCenter = (type: string) => {
    const centerTypes = ["string", "users_select", "file"];
    return !centerTypes.includes(type);
  };

  const getHeaderMaxWidth = (dataType: string) => {
    return [
      "users_select",
      "multi_select",
      "string",
      "location",
      "phone_number",
    ].includes(dataType)
      ? { width: "213px", maxWidth: "213px" }
      : { width: "213px", maxWidth: "213px" };
  };

  const getCellMaxWidth = (dataType: string) => {
    return [
      "users_select",
      "multi_select",
      "string",
      "location",
      "phone_number",
    ].includes(dataType)
      ? "238px"
      : "238px";
  };

  const getFormat = (format: any) => {
    return format ? format : teamCurrency;
  };

  const contextMenuOptions = [
    {
      label: (
        <ContextMenuOptionContainer>
          <img
            alt="open"
            src={openVDIcon}
            width="17px"
            height="17px"
            style={{ marginRight: "3px" }}
          />
          Abrir fila
        </ContextMenuOptionContainer>
      ),
      func: () => {
        handleSetEditRow(selectedRow);
        setOpenContextMenu(false);
      },
    },
    {
      label: (
        <ContextMenuOptionContainer>
          <FiTrash
            size={14}
            style={{ marginLeft: "2.2px", marginRight: "2.2px" }}
          />
          Eliminar fila
        </ContextMenuOptionContainer>
      ),
      func: () => {
        setOpenContextMenu(false);
        setConfirmDelete(true);
      },
      hoverBackground: "rgba(219, 35, 44, 1)",
    },
  ];

  return (
    <>
      {confirmDelete && (
        <ConfirmDeleteRow
          IdTeam={IdTeam}
          IdVarDB={IdVarDB}
          IdRow={selectedRow}
          closeModal={() => setConfirmDelete(false)}
          reload={reload}
        />
      )}
      <Draggable
        draggableId={draggableId}
        index={index}
        isDragDisabled={
          column?.DataType === "Folio" ||
          !canEditDatabases ||
          column?.Type === "Imported"
        }
      >
        {(provided, snapshot) => {
          return (
            <Column
              {...provided.draggableProps}
              ref={provided.innerRef}
              $sticky={column?.DataType === "Folio"}
              $isScrolled={isScrolled}
            >
              {column && (
                <ColumnHeader
                  className="columnHeader"
                  {...provided.dragHandleProps}
                >
                  <Header
                    center={column.DataType === "Folio"}
                    style={getHeaderMaxWidth(column.DataType)}
                  >
                    <ColumnTitleWrapper>
                      {Boolean(column.PrimaryKey) && (
                        <PrimaryKeyContainer>
                          <PrimaryKey src={PrimaryKeyIcon} alt="" />
                        </PrimaryKeyContainer>
                      )}
                      {column.Type === "Imported" && (
                        <PrimaryKeyContainer>
                          <LinkIcon />
                        </PrimaryKeyContainer>
                      )}

                      <ColumnTitle
                        reducedSpace={column.DataType === "Folio"}
                        onClick={() => onOrder(column.IdColumn)}
                      >
                        {column.Title.length > 12 ? (
                          <Tooltip title={column.Title}>
                            <p>{column.Title}</p>
                          </Tooltip>
                        ) : (
                          <p>{column.Title}</p>
                        )}
                        <TableHeaderIconContainer
                          unsetted={!order[column.IdColumn]}
                        >
                          <OrderIcon
                            Direction={order[column.IdColumn] || "NONE"}
                          />
                        </TableHeaderIconContainer>
                      </ColumnTitle>

                      {canEditDatabases && (
                        <>
                          <EditColumnButton
                            aria-describedby={`${column.IdColumn}`}
                            isSelected={column.IdColumn === editingColumnId}
                            onClick={(e) => {
                              setEditColumnEl(e.currentTarget);
                              handleOpenEditPopup(column);
                            }}
                          >
                            <AiOutlineEdit size={14} color="white" />
                          </EditColumnButton>

                          <Popover
                            open={
                              editPopupVisible &&
                              column.IdColumn === editingColumnId
                            }
                            anchorEl={editColumnEl}
                            onClose={handleCloseEditPopup}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            {column.DataType === "Folio" ? (
                              <EditIdRowColumn
                                closeModal={handleCloseEditPopup}
                                rowOffset={column.RowOffset}
                                reload={reload}
                                pkColumn={columns.find(
                                  (col) =>
                                    Boolean(col.PrimaryKey) &&
                                    col.IdColumn !== 0
                                )}
                              />
                            ) : column.Type === "Imported" ? (
                              <EditLink
                                onClose={handleCloseEditPopup}
                                selectedColumn={selectedColumn}
                                reload={reload}
                                IdTeam={IdTeam}
                                IdVarDB={IdVarDB}
                              />
                            ) : (
                              <EditColumn
                                closeModal={handleCloseEditPopup}
                                selectedColumn={selectedColumn}
                                columnTypeArray={columnTypeArray}
                                reload={reload}
                              />
                            )}
                          </Popover>
                        </>
                      )}
                    </ColumnTitleWrapper>
                  </Header>
                </ColumnHeader>
              )}
              {column &&
                column.data.map((value, idx) => {
                  const key = nanoid();
                  return (
                    <ContextMenu
                      key={key}
                      closeMenu={() => setOpenContextMenu(false)}
                      isOpen={openContextMenu}
                      options={contextMenuOptions}
                      points={points}
                    >
                      <Cell
                        cellKey={`${idx}-${Math.random()}`}
                        maxWidth={getCellMaxWidth(column.DataType)}
                        handleOnClick={() => {
                          if (
                            column.DataType === "file" &&
                            getValue(column.DataType, value).length > 0
                          ) {
                            return;
                          }
                          handleSetEditRow(column.rowIds[idx]);
                        }}
                        handleMouseEnter={() => handleMouseEnter(idx)}
                        handleMouseLeave={handleMouseLeave}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          setSelectedRow(column.rowIds[idx]);
                          setOpenContextMenu(canEditDatabases);
                          setPoins({
                            x: e.clientX,
                            y: e.clientY,
                          });
                        }}
                        isDragging={snapshot.isDragging}
                        isEven={index % 2 === 0}
                      >
                        <RowBox isHovered={hoveredIndex === idx}>
                          {value ? (
                            <RowValue center={column.DataType === "Folio"}>
                              {column.DataType === "Folio" ? (
                                <span>{value}</span>
                              ) : (
                                <DynamicInput
                                  value={getValue(column.DataType, value)}
                                  dataOrigin={column.DataOrigin}
                                  type={column.DataType}
                                  format={getFormat(value.Format)}
                                  configuration={value.Configuration || ""}
                                  userSelectDataOrigin={
                                    _.isArray(value) ? value : []
                                  }
                                  fieldName=""
                                  isConsult={true}
                                  disabled={true}
                                  required={false}
                                  onChange={NOOP}
                                  extraParams={{
                                    FilesPath: String(value),
                                    showFormat: true,
                                    center: getCenter(column.DataType),
                                    userSize: "small",
                                    CanUseExistingRows: true,
                                    oneLineString: true,
                                  }}
                                />
                              )}
                            </RowValue>
                          ) : (
                            <EmptyRow />
                          )}
                        </RowBox>
                      </Cell>
                    </ContextMenu>
                  );
                })}
            </Column>
          );
        }}
      </Draggable>
    </>
  );
};

export default DraggableItem;
