import _ from "lodash";
import { Field } from "../../Configurator.d";
import { DataTypeGroup } from "./ProcessFields.d";
import { sanitizeSearchValues } from "../../../../Helpers";

export const filterFields = (fields: Field[], filter: string): Field[] => {
  return fields.filter((field) => {
    const { Label, VardbGroupInfo } = field as any;
    if (VardbGroupInfo) {
      return (
        sanitizeSearchValues(VardbGroupInfo.Label).includes(
          sanitizeSearchValues(filter)
        ) || sanitizeSearchValues(Label).includes(sanitizeSearchValues(filter))
      );
    }
    return sanitizeSearchValues(Label).includes(sanitizeSearchValues(filter));
  });
};

export const filterDataTypeGroups = (
  dataTypeGroup: DataTypeGroup[],
  filter: string
): DataTypeGroup[] =>
  dataTypeGroup.filter(
    ({ Fields }) => !_.isEmpty(filterFields(Fields, filter))
  );
