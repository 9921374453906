import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";

export const FieldsColumn: React.FC<{
  droppableId: string;
  droppableKey: number;
}> = ({ children, droppableId, droppableKey }) => {
  return (
    <Droppable droppableId={droppableId} key={droppableKey}>
      {(provided) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ flex: 1, overflow: "auto" }}
          >
            {children}
            {provided.placeholder}
          </div>
        </>
      )}
    </Droppable>
  );
};

export const DraggableField: React.FC<{
  draggableId: string;
  draggableIndex: number;
}> = ({ children, draggableId, draggableIndex }) => (
  <Draggable draggableId={draggableId} index={draggableIndex}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div style={{ marginBottom: "9px" }}>{children}</div>
      </div>
    )}
  </Draggable>
);
