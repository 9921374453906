import styled from "styled-components";

export const InfoText = styled.div`
  font-size: 14px;
  color: #0867ad;
  max-height: 44px;
  line-height: 16px;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 8px;
  vertical-align: bottom;
`;

export const UnactiveConsultingButton = styled.div`
  color: #48505e;
  border: 1px solid #48505e;
  border-radius: 5px;
  padding: 0.2rem 1rem;
  font-size: 14px;
  transition: background-color 0.35s ease;
  cursor: pointer;
  :hover {
    background-color: #e8ecf1;
  }
`;

export const ActiveConsultingButton = styled(UnactiveConsultingButton)`
  color: #0867ad;
  border: 1px solid #0867ad;
`;
