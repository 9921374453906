export const Languages = {
  ENG: {
    ModalTitle: "Change dashboard name",
    CancelLabel: "Cancel",
    SaveLabel: "Save",
  },
  ESP: {
    ModalTitle: "Cambiar nombre de dashboard",
    CancelLabel: "Cancelar",
    SaveLabel: "Guardar",
  },
};
