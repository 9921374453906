import React, { useState } from "react";
import _ from "lodash";
import { AiOutlinePlus } from "react-icons/ai";
import { Row, Space } from "antd";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
//@ts-ignore
import emoji from "emoji-dictionary";
import CloseIcon from "../../../../../components/hoverIcons/CloseIcon";
import Button from "../../../../../GeestUI/Button";
import { Tag, Typography } from "../../../../../GeestUI";
import { ProcessOrder } from "./Order";
import OrderIcon from "./OrderIcon";
import {
  Content,
  Header,
  ProcessTitle,
  SearchContainer,
  SearchInput,
  StyledCell,
  StyledModal,
  StyledTableContainer,
  StyledTag,
  TableWrapper,
  Title,
} from "./Styles";
import {
  Order,
  ProcessOrderType,
  ProcessStatus,
  TableDetailProps,
  TableInformation,
  TemplateOrderType,
} from "./TableDetail.d";
import data from "./data";
import TemplateOrder from "./Order/TemplateOrder";
import Tooltip from "../../../../../components/Tooltip";
import { Circle } from "../../../../../components/ColorPicker";
import moment from "moment";

const { Text } = Typography;

const TableDetail: React.FC<TableDetailProps> = ({ closeModal }) => {
  const [filter, setFilter] = useState<string>("");
  const [order, setOrder] = useState<Order>({});
  const [orderHierarchy, setOrderHierarchy] = useState<string[]>([]);

  const [originalTableData] = useState<TableInformation[]>(data);
  const [orderedTableData, setOrderedTableData] =
    useState<TableInformation[]>(data);
  const [processExecutionOrder, setProcessExecutionOrder] =
    useState<ProcessOrderType>("IdProcessExecution");
  const [processTemplateOrder, setProcessTemplateOrder] =
    useState<TemplateOrderType>("ProcessTemplateName");

  const handleSearch = (search: string) => {
    setFilter(search);
  };

  const handleReorder = (
    order: Order,
    hierarchy: string[] = orderHierarchy
  ) => {
    const orders = hierarchy.map((h) => order[h]);
    const newTable = _.orderBy(originalTableData, hierarchy, orders);
    setOrderedTableData(newTable);
  };

  const onOrder = (key: string | number) => {
    let orderAux = { ...order };
    let hierarchy = [...orderHierarchy];

    if (orderAux[key] === "asc") {
      orderAux[key] = "desc";
    } else if (orderAux[key] === "desc") {
      delete orderAux[key];
      const index = orderHierarchy.indexOf(String(key));
      if (index !== -1) {
        hierarchy.splice(index, 1);
      }
    } else {
      orderAux[key] = "asc";
      hierarchy.push(String(key));
    }
    setOrder(orderAux);
    setOrderHierarchy(hierarchy);
    handleReorder(orderAux, hierarchy);
  };

  const onChangeProcessExecutionOrder = (processOrder: ProcessOrderType) => {
    let orderAux = { ...order };
    if (orderAux["Name"] && processOrder !== "Name") {
      orderAux[processOrder] = orderAux["Name"];
      handleReorder(orderAux);
    }
    if (
      orderAux["IdProcessExecution"] &&
      processOrder !== "IdProcessExecution"
    ) {
      orderAux[processOrder] = orderAux["IdProcessExecution"];
      handleReorder(orderAux);
    }
    if (orderAux["Status"] && processOrder !== "Status") {
      orderAux[processOrder] = orderAux["Status"];
      handleReorder(orderAux);
    }
    setProcessExecutionOrder(processOrder);
  };

  const onChangeProcessTemplateOrder = (templateOrder: TemplateOrderType) => {
    let orderAux = { ...order };
    if (
      orderAux["ProcessTemplateName"] &&
      templateOrder !== "ProcessTemplateName"
    ) {
      orderAux[templateOrder] = orderAux["ProcessTemplateName"];
      handleReorder(orderAux);
    }
    if (orderAux["IdStage"] && templateOrder !== "IdStage") {
      orderAux[templateOrder] = orderAux["IdStage"];
      handleReorder(orderAux);
    }
    setProcessTemplateOrder(templateOrder);
  };

  const getStatusInfo = (
    status: ProcessStatus
  ): { color: string; label: string } => {
    switch (status) {
      case "New":
        return { color: "yellow", label: "Nuevo" };
      case "OnTime":
        return { color: "cyan", label: "Iniciado" };
      case "Completed":
        return { color: "green", label: "Terminado" };
      case "Canceled":
        return { color: "default", label: "Cancelado" };
      case "WaitingToStart":
        return { color: "orange", label: "Por iniciar" };
      case "Stuck":
        return { color: "red", label: "Vencido" };
      default:
        return { color: "default", label: status };
    }
  };

  const dateHourFormat = (date: any, hideYear = false) => {
    if (moment(new Date(date)).isValid()) {
      if (hideYear) {
        return moment(date).format("DD/MMM HH:mm");
      }
      return moment(date).format("DD/MMM/YY HH:mm");
    } else {
      return "Invalid format";
    }
  };

  return (
    <StyledModal
      open
      footer={null}
      closable={false}
      centered
      width="850px"
      onCancel={closeModal}
      bodyStyle={{
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Gotham-Book",
        padding: "12px",
      }}
    >
      <Header>
        <Title>Detalle de nombre de columna y fila</Title>
        <CloseIcon size={20} onClick={closeModal} color="#48505e" />
      </Header>
      <Content>
        <SearchContainer>
          <SearchInput
            value={filter}
            onChange={({ target: { value } }) => handleSearch(value)}
            placeholder="Buscar"
          />
          <Button type="primary" Icon={AiOutlinePlus}>
            Exportar
          </Button>
        </SearchContainer>

        <TableWrapper>
          <StyledTableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => onOrder(processExecutionOrder)}>
                    <Row
                      align="middle"
                      justify="space-between"
                      wrap={false}
                      style={{ width: "100%" }}
                    >
                      <Row align="middle" wrap={false}>
                        Seguimiento
                        <OrderIcon Direction={order[processExecutionOrder]} />
                      </Row>
                      <ProcessOrder
                        processOrder={processExecutionOrder}
                        setProcessOrder={onChangeProcessExecutionOrder}
                      />
                    </Row>
                  </TableCell>

                  <TableCell onClick={() => onOrder(processTemplateOrder)}>
                    <Row
                      align="middle"
                      justify="space-between"
                      wrap={false}
                      style={{ minWidth: "196px" }}
                    >
                      <Row align="middle" wrap={false}>
                        Proceso
                        <OrderIcon Direction={order[processTemplateOrder]} />
                      </Row>
                      <TemplateOrder
                        templateOrder={processTemplateOrder}
                        setTemplateOrder={onChangeProcessTemplateOrder}
                      />
                    </Row>
                  </TableCell>

                  <TableCell onClick={() => onOrder("StartedAt")}>
                    <Row align="middle" wrap={false}>
                      Inicio
                      <OrderIcon Direction={order["StartedAt"]} />
                    </Row>
                  </TableCell>

                  <TableCell onClick={() => onOrder("EndedAt")}>
                    <Row align="middle" wrap={false}>
                      Fin
                      <OrderIcon Direction={order["EndedAt"]} />
                    </Row>
                  </TableCell>

                  <TableCell>
                    <Row align="middle" wrap={false}>
                      Costo
                    </Row>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {orderedTableData.map((process) => (
                  <TableRow key={process.IdProcessExecution}>
                    <StyledCell>
                      <Space size={6} direction="vertical">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <Tooltip title="Ver detalle de seguimiento">
                            <Text
                              color="#0067B0"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // setDetailProcessId(process.IdProcessExecution);
                              }}
                            >
                              Folio {process.IdProcessExecution}
                            </Text>
                          </Tooltip>
                          {process.Name.length < 24 ? (
                            <ProcessTitle>
                              {process.Name.replace(
                                /:\w+:/gi,
                                (name) => emoji.getUnicode(name) ?? name
                              )}
                            </ProcessTitle>
                          ) : (
                            <Tooltip
                              title={process.Name.replace(
                                /:\w+:/gi,
                                (name) => emoji.getUnicode(name) ?? name
                              )}
                            >
                              <ProcessTitle>
                                {process.Name.replace(
                                  /:\w+:/gi,
                                  (name) => emoji.getUnicode(name) ?? name
                                )}
                              </ProcessTitle>
                            </Tooltip>
                          )}
                        </div>
                        <Space wrap={false}>
                          <StyledTag
                            color={getStatusInfo(process.Status).color}
                          >
                            {getStatusInfo(process.Status).label}
                          </StyledTag>
                          {process.Priority && (
                            <Tag color={process.Priority.Color}>
                              {process.Priority.Title}
                            </Tag>
                          )}
                        </Space>
                      </Space>
                    </StyledCell>

                    <StyledCell isEven>
                      <Space size={6} direction="vertical">
                        <Text>{process.ProcessTemplateName}</Text>
                        {process.Stage && (
                          <Space size={4}>
                            <Circle color={process.Stage.Color} />
                            <Text>{process.Stage.Label}</Text>
                          </Space>
                        )}
                      </Space>
                    </StyledCell>

                    <StyledCell
                      style={{
                        padding: "8px",
                      }}
                    >
                      {process.StartedAt &&
                        dateHourFormat(process.StartedAt, true)}
                    </StyledCell>

                    <StyledCell
                      isEven
                      style={{
                        padding: "8px",
                      }}
                    >
                      {process.EndedAt &&
                        dateHourFormat(process.StartedAt, true)}
                    </StyledCell>

                    <StyledCell>{process.MeasurementValue}</StyledCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </TableWrapper>
      </Content>
    </StyledModal>
  );
};

export default TableDetail;
