import React from "react";
import styled from "styled-components";
import { ReactComponent as RocketIconOutlined } from "../../resources/img/rocketIcon.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface RocketIconProps {}

const RocketIcon: React.FC<RocketIconProps> = () => {
  return (
    <IconWrapper>
      <RocketIconOutlined width="14px" height="14px" />
    </IconWrapper>
  );
};

export default RocketIcon;
