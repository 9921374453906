export const Languages = {
  ENG: {
    ViewTitleLabel: "My pendings",
    OrderLabel: "Order",
    OrderOptions: {
      Priority: "By priority",
      Deadline: "By deadline",
      StartDate: "By start date",
    },
    FilterLabel: "Filter",
    FilterOptions: {
      All: "All",
      Actual: "Actuals",
      Started: "Started",
      Stuck: "Stuck",
      Waiting: "To start",
      StartToday: "Start today",
      StuckToday: "Stuck today",
      StartTomorrow: "Start tomorrow",
      StuckTomorrow: "Stuck tomorrow",
    },
    SelectPlaceholder: "Select",
    SearchPlaceholder: "Search",
    AddColumnLabel: "Add column",
    Pendings: "pendientes",
    Priority: "Priority",
    ExecutionTitle: "Title",
    Stage: "Stage",
    StartedAt: "Started at",
    Deadline: "Deadline",
  },
  ESP: {
    ViewTitleLabel: "Mis pendientes",
    OrderLabel: "Ordenar",
    OrderOptions: {
      Priority: "Por prioridad",
      Deadline: "Por vencimiento",
      StartDate: "Por fecha de inicio",
      Task: "Por tarea",
      Process: "Seguimiento",
    },
    FilterLabel: "Filtrar",
    FilterOptions: {
      All: "Todos",
      Actual: "Actuales",
      Started: "Iniciados",
      Stuck: "Vencidos",
      Waiting: "Por iniciar",
      StartToday: "Inician hoy",
      StuckToday: "Vencen hoy",
      StartTomorrow: "Inician mañana",
      StuckTomorrow: "Vencen mañana",
    },
    SelectPlaceholder: "Selecciona",
    SearchPlaceholder: "Buscar",
    AddColumnLabel: "Agregar columna",
    Pendings: "pendientes",
    Priority: "Prioridad",
    ExecutionTitle: "Seguimiento",
    Stage: "Etapa",
    StartedAt: "Inicio",
    Deadline: "Vencimiento",
  },
};
