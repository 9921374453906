import React, { MouseEventHandler } from "react";
import { IconContainer } from "./styles";
import ThreePoints from "../../resources/img/3points.svg";

const ThreePointsIcon: React.FC<{
  onClick: MouseEventHandler<HTMLDivElement>;
}> = ({ onClick }) => (
  <IconContainer size={24} onClick={onClick}>
    <img
      src={ThreePoints}
      style={{
        height: "16px",
        userSelect: "none",
      }}
      alt="viewmore"
    />
  </IconContainer>
);

export default ThreePointsIcon;
