import React, { useState } from "react";

export const SignUpContext = React.createContext(null);

export const SignUpState = ({ children }) => {
  const [IdPlan, setIdPlan] = useState("");
  const [Mode, setMode] = useState("");

  return (
    <SignUpContext.Provider value={{ IdPlan, setIdPlan, Mode, setMode }}>
      {children}
    </SignUpContext.Provider>
  );
};
