import React from "react";
import { SidePanel } from "./Styles";
import { OverviewLink } from "./Sections/Overview";
import { BasicLink } from "./Sections/Basic";
import { ToolbarBasicLink } from "./Sections/ToolbarBasic";
import { ToolbarAdvanceLink } from "./Sections/ToolbarAdvance";
import { ParamsLink } from "./Sections/Params";
import { UtilsLink } from "./Sections/Utils";
import { ShortcutsLink } from "./Sections/Shortcuts";
import { ExtendLink } from "./Sections/Extend";
import { SectionsType } from ".";

interface NavigationProps {
  SECTIONS: SectionsType;
  section: Symbol;
  setSection: (section: Symbol) => void;
}

const Navigation: React.FC<NavigationProps> = ({
  SECTIONS,
  section,
  setSection,
}) => {
  const isActive = (currentSection: Symbol) => currentSection === section;
  const {
    OVERVIEW,
    BASIC,
    TOOLBAR_BASIC,
    TOOLBAR_ADVANCE,
    PARAMS,
    UTILS,
    SHORTCUTS,
    EXTEND,
  } = SECTIONS;

  return (
    <SidePanel>
      <OverviewLink
        isActive={() => isActive(OVERVIEW)}
        setSection={() => setSection(OVERVIEW)}
      />
      <BasicLink
        isActive={() => isActive(BASIC)}
        setSection={() => setSection(BASIC)}
      />
      <ToolbarBasicLink
        isActive={() => isActive(TOOLBAR_BASIC)}
        setSection={() => setSection(TOOLBAR_BASIC)}
      />
      <ToolbarAdvanceLink
        isActive={() => isActive(TOOLBAR_ADVANCE)}
        setSection={() => setSection(TOOLBAR_ADVANCE)}
      />
      <ParamsLink
        isActive={() => isActive(PARAMS)}
        setSection={() => setSection(PARAMS)}
      />
      <UtilsLink
        isActive={() => isActive(UTILS)}
        setSection={() => setSection(UTILS)}
      />
      <ShortcutsLink
        isActive={() => isActive(SHORTCUTS)}
        setSection={() => setSection(SHORTCUTS)}
      />
      <ExtendLink
        isActive={() => isActive(EXTEND)}
        setSection={() => setSection(EXTEND)}
      />
    </SidePanel>
  );
};

export default Navigation;
