import styled from "styled-components";
import { MenuItem, Select } from "@material-ui/core";

export const HorizontalDivider = styled.div`
  background-color: #5db12f;
  width: 100%;
  height: 2px;
  margin-bottom: 1rem;
`;

export const Small = styled.p`
  color: rgba(130, 141, 158, 1);
  font-size: 14px;
  width: 309px;
  text-align: center;
`;

export const SelectStyled = styled(Select)`
  border: 1.5px solid;
  border-color: #c0c9d3;
  border-radius: 5px;
  color: #727e8b;
  height: 34px;
  width: 100%;
  resize: none;
  transition: all 0.35s ease;
  font-size: 12px;
  .MuiSelect-select {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    padding: 0;
    padding-left: 5px;
    height: 100%;
    overflow: hidden;
  }
  * {
    font-size: 12px;
    color: #727e8b;
  }
  &:before {
    border: none !important;
  }
  &:after {
    border: none !important;
  }
  :hover {
    border-color: #2684ff;
    box-shadow: none;
  }
  :focus {
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  font-size: 12px;
  * {
    color: #727e8b;
    margin: 0;
    font-size: 12px;
  }
  li {
    background-color: salmon !important;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ButtonStyled = styled.button`
  width: 75%;
  background-color: #14487c;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
`;
