import React from "react";
import styled from "styled-components";
import DynamicInput from "../../../../../components/DynamicInput";
import { User } from "../DynamicCell.d";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Gotham-Bold;
  color: #0067b0;
  width: 100%;
`;

interface UserSelectComponentProps {
  value: number[];
  UserSelectDataOrigin: User[];
}

const UserSelectComponent: React.FC<UserSelectComponentProps> = ({
  value,
  UserSelectDataOrigin,
}) => {
  return (
    <Container>
      <DynamicInput
        value={value.join(",")}
        type="users_select"
        userSelectDataOrigin={UserSelectDataOrigin}
        dataOrigin=""
        format=""
        configuration=""
        fieldName=""
        isConsult={true}
        disabled={true}
        required={false}
        onChange={() => null}
        extraParams={{
          userSize: "middle",
          userNameColor: "#48505e",
          userNameSize: "12px",
        }}
      />
    </Container>
  );
};

export default UserSelectComponent;
