import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Edge,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { noop } from "lodash";
import { Container, Content } from "./styles";
import Flow from "./Flow";
import FlowActions from "./FlowActions";
import { useFetch } from "../../hooks";
import { IConfiguratorContext, GetProcessTemplate } from "./Configurator.d";
import Header from "./Header";
import { GetNodesFlux } from "./Flow/Flow.d";
import { apiResponseToFlow, startNode } from "./Flow/helpers";

export const ConfiguratorContext = createContext<IConfiguratorContext>({
  refreshProcessInformation: noop,
  refreshProcessFlow: noop,
});

const initialEdges: Edge[] = [];

const Configurator: React.FC = () => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();

  const [openStages, setOpenStages] = useState(false);
  const [openChats, setOpenChats] = useState(false);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [savingDataStatus, setSavingDataStatus] = useState<string>("disabled");

  useEffect(() => {
    if (savingDataStatus === "saved") {
      setTimeout(() => {
        setSavingDataStatus("updated");
      }, 2000);
    }
  }, [savingDataStatus]);

  const { data, reload: refreshProcessInformation } =
    useFetch<GetProcessTemplate>({
      func: "Ver2-Configurator-gpt",
      args: {
        IdTeam,
        IdProcessTemplate,
      },
    });

  const { reload: reloadFlow } = useFetch<GetNodesFlux>({
    func: "Ver2-Configurator-gnf",
    args: { IdTeam, IdProcessTemplate },
    conf: {
      showMessageOnFail: true,
    },
    onSuccess: (res) => {
      const { nodes: parsedNodes, edges: parsedEdges } = apiResponseToFlow(res);
      setNodes([startNode, ...parsedNodes]);
      setEdges(parsedEdges);
      refreshProcessInformation();
    },
  });

  const handleFlowClicks: React.MouseEventHandler<HTMLDivElement> = () => {
    setOpenStages(false);
    setOpenChats(false);
  };

  return (
    <ConfiguratorContext.Provider
      value={{ refreshProcessInformation, refreshProcessFlow: reloadFlow }}
    >
      <Container>
        <Header
          processName={data?.Name}
          needsToBePublished={data?.HasPendingPublishChanges}
          refreshProcessInformation={refreshProcessInformation}
          reloadFlow={reloadFlow}
          canShare={Boolean(data?.CanShare)}
          savingDataStatus={savingDataStatus}
        />
        <Content>
          <FlowActions
            openChats={openChats}
            setOpenChats={setOpenChats}
            openStages={openStages}
            setOpenStages={setOpenStages}
          />
          <div
            style={{ width: "100%", height: "100%" }}
            onClick={handleFlowClicks}
          >
            <Flow
              nodes={nodes}
              setNodes={setNodes}
              onNodesChange={onNodesChange}
              edges={edges}
              setEdges={setEdges}
              onEdgesChange={onEdgesChange}
              reloadFlow={reloadFlow}
              setSavingDataStatus={setSavingDataStatus}
            />
          </div>
        </Content>
      </Container>
    </ConfiguratorContext.Provider>
  );
};

const ConfiguratorWithFlowProvider: React.FC = () => (
  <ReactFlowProvider>
    <Configurator />
  </ReactFlowProvider>
);

export default ConfiguratorWithFlowProvider;
