import styled from "styled-components";
import { Modal } from "antd";
import { Paper } from "@material-ui/core";

export const StyledModal = styled(Modal)`
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  width: 100% !important;
  max-width: 1200px;

  .ant-modal-body {
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    font-family: Gotham-Book;
    padding: 0;
  }
`;

export const StyledPaper = styled(Paper)`
  border-radius: 6px !important;
  width: 1200px;
  height: 610px;
  display: flex;
`;

export const StyledPaperSmall = styled(Paper)`
  border-radius: 10px !important;
  display: flex;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 392px;
  color: #727e8b;
  width: 30%;
`;

export const LeftTitle = styled.div`
  font-size: 16px;
  color: rgba(72, 80, 94, 1);
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-right: 10px;
`;

export const TriggersContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 15px;
  padding-right: 15px;
  max-height: 477px;
  overflow: auto;
  border-right: 2px solid rgba(237, 236, 236, 1);
`;

export const TriggerBox = styled.div<{ $isSelected: boolean }>`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  :hover {
    background-color: rgba(237, 236, 236, 1);
  }
  background-color: ${({ $isSelected = false }) =>
    $isSelected ? "rgba(242, 248, 254, 1)" : "white"};
  transition: background-color 0.35s ease;
  border: 2px solid
    ${({ $isSelected = false }) =>
      $isSelected ? "rgba(242, 248, 254, 1)" : "rgba(237, 236, 236, 1)"};
  padding: 10px;
`;

export const TriggerIconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const TriggerTitle = styled.div`
  flex: 1;
  padding: 10px;
  padding-left: 0;
  text-align: center;
  max-width: 242px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AddTriggerButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const AddTriggerButton = styled.button`
  border: 1px dashed #c0c9d3;
  background-color: white;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.35s ease;

  :hover {
    background-color: #cbcfd4;
  }
`;

export const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  max-width: 70%;
`;

export const StyledInput = styled.input`
  border: 2px solid rgba(72, 80, 94, 1);
  width: 230px;
  height: 28px;
  border-radius: 6px;
  color: rgba(72, 80, 94, 1);
  padding-left: 5px;
  :focus-visible {
    outline: none;
  }
`;
