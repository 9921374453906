import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const StyledPaper = styled(Paper)`
  border-radius: 10px !important;
  max-height: 90vh;
  width: 380px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const DescriptionContainer = styled.div`
  flex: 1;
  width: 100%;
  padding: 1rem 12px;
  margin-bottom: 1rem;
`;
