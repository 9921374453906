import { CustomEdgeTypes as EdgeTypes } from "./CustomEdges.d";
import CustomBezierEdge from "./CustomBezierEdge";

export { CustomEdgeTypes } from "./CustomEdges.d";

const CustomEdges = {
  [EdgeTypes.CUSTOM_BEIZER]: CustomBezierEdge,
};

export default CustomEdges;
