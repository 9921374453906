import React, { useState } from "react";
import { ActionOption } from "../AutomationConfig.d";
import { Action } from "../EditAction.d";
import copyActionIcon from "../../../../../../resources/img/copyActionIcon.svg";
import sumActionIcon from "../../../../../../resources/img/sumActionIcon.svg";
import substractActionIcon from "../../../../../../resources/img/substractActionIcon.svg";
import multiplyActionIcon from "../../../../../../resources/img/multiplyActionIcon.svg";
import emailActionIcon from "../../../../../../resources/img/emailActionIcon.svg";
import notificationActionIcon from "../../../../../../resources/img/notificationActionIcon.svg";
import changeFieldValueActionIcon from "../../../../../../resources/img/changeFieldValueActionIcon.svg";
import changeTasksPriorityActionIcon from "../../../../../../resources/img/changeTasksPriorityActionIcon.svg";
import sendNotificationActionIcon from "../../../../../../resources/img/notificationActionIcon.svg";
import webHookTriggerIcon from "../../../../../../resources/img/webHookTriggerIcon.svg";
import divActionIcon from "../../../../../../resources/img/divActionIcon.svg";
import GeestLogo from "../../../../../../resources/img/ICONO_.svg";
import {
  ViewContentAddAction,
  CardOption,
  CardDescription,
  CardIconContainer,
  SearchBar,
} from "./styles";
import { Row } from "antd";

interface AddActionViewOptions {
  actionOptions?: ActionOption[];
  actions: Action[];
  setActions: (actions: Action[]) => void;
  setSlectedAction: (action: Action) => void;
}

const AddActionView: React.FC<AddActionViewOptions> = ({
  actionOptions,
  actions,
  setActions,
  setSlectedAction,
}) => {
  const [filter, setFilter] = useState<string>("");

  const actionIcon: { [key: string]: any } = {
    copy_value: copyActionIcon,
    substract_fields: substractActionIcon,
    sum_fields: sumActionIcon,
    mult_fields: multiplyActionIcon,
    send_email: emailActionIcon,
    notification: notificationActionIcon,
    change_field_value: changeFieldValueActionIcon,
    change_tasks_priority: changeTasksPriorityActionIcon,
    send_notification: sendNotificationActionIcon,
    webhook: webHookTriggerIcon,
    div_fields: divActionIcon,
  };

  const filteredOptions = actionOptions?.filter(({ Label }) =>
    Label.toLowerCase().includes(filter.toLowerCase())
  );

  const onAddAction = (action: string) => {
    let actionsAux = [...actions];
    const newAction = {
      IdAction: 0,
      Title: "Nueva acción",
      Action: action,
    };
    actionsAux.push(newAction);
    setActions(actionsAux);
    setSlectedAction(newAction);
  };

  const getDescriptionWidth = (optionLabel: string) => {
    if (optionLabel.length > 31) {
      return "160px";
    }
    return "120px";
  };

  const getMarginTop = (optionLabel: string) => {
    if (optionLabel.length < 14) {
      return 5;
    }
    return 16;
  };

  return (
    <ViewContentAddAction>
      <Row justify="end" style={{ width: "92%" }}>
        <SearchBar
          placeholder="Buscar"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </Row>
      <Row justify="center">
        <Row style={{ maxWidth: "85%", gap: "15px" }}>
          {filteredOptions?.map((option) => (
            <CardOption
              key={option.Action}
              onClick={() => onAddAction(option.Action)}
              style={{ paddingTop: getMarginTop(option.Label) }}
            >
              <CardIconContainer>
                <img
                  alt=""
                  src={actionIcon[option.Action] || GeestLogo}
                  height="31px"
                  width="31px"
                  style={{
                    backgroundColor: "rgba(2, 115, 233, 1)",
                    borderRadius: "2px",
                  }}
                />
              </CardIconContainer>
              <CardDescription $width={getDescriptionWidth(option.Label)}>
                {option.Label}
              </CardDescription>
            </CardOption>
          ))}
        </Row>
      </Row>
    </ViewContentAddAction>
  );
};

export default AddActionView;
