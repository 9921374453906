import React, { useEffect, useState } from "react";
import { useMutation } from "../../../hooks";
import _, { noop as NOOP } from "lodash";
import styled, { css } from "styled-components";
import { Input, Row } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import {
  getSymbolByCode,
  getCountryFlag,
  transformedOptions,
} from "./helpers/CurrencyHelpers";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import IsRequired from "./helpers/IsRequired";
import Tooltip from "../../Tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Languages } from "./Dictionary";

const { CurrencyIncomInfo } = Languages["ESP"];

interface CurrencyInputProps {
  value: string;
  format: string;
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  onChange: (value: string) => void;
  onBlur?: (e: any) => void;
  onPressEnter?: (e: any) => void;
  onChangeFormat?: (value: string) => void;
  configuration: string;
  extraParams?: {
    center?: boolean;
    tooltipOffset?: number;
    tooltipPlacement?: TooltipPlacement;
    formatedValueColor?: string;
    showFormat?: boolean;
    showNumberLabel?: boolean;
    currencyPlaceholder?: string;
    hideCurrencySelect?: boolean;
    mb?: string;
  };
}

const InputContainer = styled.div<{ isConsult: boolean; mb?: string }>`
  ${({ isConsult, mb = "10px" }) => (isConsult ? "" : `margin-bottom: ${mb};`)}
  width: 100%;
`;

const CurrencyInputsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;

  .inputs {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

const StyledInput = styled(Input)<{
  $borderColor: string;
  $background: string;
  $cursor?: string;
}>`
  border-radius: 6px;
  border: 2px solid;
  border-color: ${({ $borderColor }) => $borderColor};
  background: ${({ $background }) => $background};
  color: #828d9e;
  font-size: 12px;

  display: flex;
  flex: 1;
  min-height: 32px;
  resize: none;
  padding: 0 10px;

  box-shadow: none !important;
  transition: all 0.35s ease;
  .ant-input {
    color: #828d9e;
    background-color: transparent;
  }
  ${({ disabled }) =>
    !disabled
      ? css`
          :hover {
            border-color: #0273e9 !important;
            box-shadow: none !important;
          }
        `
      : ""}
  &.ant-input-affix-wrapper-focused {
    border-color: #48505e !important;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e !important;
    box-shadow: none;
  }
  :focus-visible {
    outline: none !important;
  }
`;

const Text = styled.p`
  color: #828d9e;
  font-weight: 325;
  font-size: 12px;
  margin-bottom: 0;
`;

const InformationBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NumberLabel: React.FC<{ value: string }> = ({ value }) => {
  const [numberLabel, setNumberLabel] = useState<string>("");

  const [getNumberLabel] = useMutation<string>({
    func: "Ver2-Processes-gcc",
    onSuccess: (res) => setNumberLabel(res),
  });

  useEffect(() => {
    getNumberLabel({
      args: {
        Number: value.replace(/,/g, ""),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return <Text>{numberLabel}</Text>;
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value = "",
  format = "",
  isConsult,
  disabled,
  required,
  onChange,
  onBlur = NOOP,
  onPressEnter = NOOP,
  onChangeFormat = NOOP,
  configuration = "",
  extraParams = {
    center: false,
    showFormat: true,
    showNumberLabel: true,
    currencyPlaceholder: "",
    hideCurrencySelect: false,
    mb: "10px",
  },
}) => {
  const lengthLimit = extraParams.tooltipOffset;
  const tooltipPlacement = extraParams.tooltipPlacement ?? "bottom";

  const validFormats = [
    "MXN",
    "USD",
    "GTQ",
    "EUR",
    "GBP",
    "JPY",
    "FR",
    "COP",
    "BZD",
    "CLP",
    "ARS",
    "VES",
    "PAB",
    "CRC",
  ];

  useEffect(() => {
    if (!validFormats.includes(format)) {
      onChangeFormat("MXN");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format]);

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let { value: newValue } = e.target;
    const regex = /^-?[0-9]*(\.[0-9]{0,2})?$/;
    newValue = newValue.replace(/,/g, "");
    if (Number(newValue) > 1000000000000000000) return;
    const isValidNumber = !isNaN(Number(newValue)) && regex.test(newValue);
    if (isValidNumber || newValue === "" || newValue === "-") {
      onChange(newValue);
    }
  };

  const getFormatedNumber = (val: string) =>
    val ? val.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";

  return (
    <InputContainer isConsult={isConsult} mb={extraParams.mb}>
      {isConsult ? (
        <Row
          justify={extraParams.center ? "center" : "start"}
          align="middle"
          style={{
            overflow: "auto",
            minHeight: "32px",
            flexWrap: "wrap",
          }}
        >
          {extraParams.showFormat && getCountryFlag(format, "14px")}
          <Text
            style={{
              marginLeft: "5px",
              color: extraParams.formatedValueColor ?? "#828d9e",
            }}
          >
            {lengthLimit && getFormatedNumber(value).length > lengthLimit ? (
              <Tooltip
                title={`${getSymbolByCode(format)}${getFormatedNumber(value)}`}
                placement={tooltipPlacement}
              >
                {`${getSymbolByCode(format)}${_.truncate(
                  getFormatedNumber(value),
                  {
                    length: lengthLimit,
                  }
                )}`}
              </Tooltip>
            ) : (
              `${getSymbolByCode(format)}${getFormatedNumber(value) || 0}`
            )}
          </Text>
          {extraParams.showNumberLabel && (
            <div style={{ width: "100%", marginTop: "5px" }}>
              <NumberLabel value={value} />
            </div>
          )}
        </Row>
      ) : (
        <CurrencyInputsContainer>
          <div className="inputs">
            {!extraParams.hideCurrencySelect && (
              <GeestSelect
                value={format}
                options={transformedOptions}
                onChange={onChangeFormat}
                required={required}
                disabled={disabled || configuration === "Income"}
                $width="80px"
                $listWidth="200px"
                valueNecesary
                placeholderSelect={extraParams.currencyPlaceholder}
              />
            )}
            <StyledInput
              value={getFormatedNumber(value)}
              onChange={handleOnChange}
              onBlur={onBlur}
              onPressEnter={onPressEnter}
              disabled={disabled}
              $background={disabled ? "#edecec" : "white"}
              $borderColor={
                required ? (value ? "#edecec" : "#db232c") : "#edecec"
              }
              placeholder="Cantidad..."
            />
            {configuration === "Income" && (
              <Tooltip title={CurrencyIncomInfo} placement="top">
                <InformationBadge>
                  <AiOutlineInfoCircle color="#48505e" size={18} />
                </InformationBadge>
              </Tooltip>
            )}
          </div>
          {extraParams.showNumberLabel && (
            <div>
              <NumberLabel value={value} />
            </div>
          )}
        </CurrencyInputsContainer>
      )}
      {required && !isConsult && !Boolean(value) && <IsRequired />}
    </InputContainer>
  );
};

export default CurrencyInput;
