import styled from "styled-components";
import searchIMG from "../../../../../../resources/img/SEARCH_TASKS.svg";
import { Tag } from "../../../../../../GeestUI";
import { TimePicker } from "@material-ui/pickers";

export const ViewContent = styled.div`
  flex: 1;
  overflow: auto;
  border-radius: 0 0 10px 0;
  padding: 20px;
  display: flex;
`;

export const ViewContentAddAction = styled.div`
  overflow: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
`;

export const SelectLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
`;

export const ViewContentAction = styled(ViewContent)`
  flex-direction: column;
  gap: 20px;
  padding-bottom: 10px;
  overflow: auto;
  height: 475px;
`;

export const ActionMetaData = styled.div`
  display: flex;
  align-items: top;
`;

export const InputStyled = styled.input`
  color: rgba(72, 80, 94, 1);
  font-size: 18px;
  font-family: Gotham-Bold;
  font-weight: 700;
  border: 2px solid rgba(72, 80, 94, 1);
  border-radius: 6px;
  padding-left: 10px;
  width: 247px;
  height: 37px;
  :focus-visible {
    outline: none;
  }
`;

export const ActionTitle = styled.div`
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
`;

export const ActionDescription = styled.p`
  margin-top: 5px;
  color: rgba(130, 141, 158, 1);
  font-size: 14px;
  font-family: Gotham-Book;
  font-weight: 325;
`;

export const ActionContent = styled.div`
  flex-direction: column;
  display: flex;
  margin-left: 20px;
`;

export const ActionFormTitle = styled.div`
  color: rgba(72, 80, 94, 1);
  font-size: 14px;
  font-weight: 700;
  font-family: Gotham-Bold;
  display: flex;
  flex-direction: row;
  gap: 5px;
  p {
    align-items: flex-start;
    color: red;
    line-height: 10px;
    padding-top: 4px;
  }
`;

export const CardOption = styled.div`
  border: 1px solid rgba(2, 115, 233, 1);
  border-radius: 6px;
  width: 211px;
  height: 167px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 1);
  transition: box-shadow 0.35s ease;
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  :hover {
    box-shadow: 4px 6px 10px 0px rgba(2, 115, 233, 0.3);
  }
`;

export const CardDescription = styled.div<{ $width: string }>`
  color: rgba(72, 80, 94, 1);
  text-align: center;
  font-size: 14px;
  font-family: Gotham-Book;
  width: ${({ $width }) => $width};
  font-weight: 325;
  line-height: 19.6px;
`;

export const CardIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchBar = styled.input`
  background-image: url(${searchIMG});
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-y: center;
  background-position-x: 6px;
  padding: 2px 0px 2px 35px;
  width: 350px;
  height: 32px;
  border: 2px solid rgba(237, 236, 236, 1);
  border-radius: 6px;
  outline: none;
  color: rgb(130, 141, 158);
  padding-bottom: 0;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
`;

export const StyledTextField = styled.input`
  width: 100%;
  color: rgba(130, 141, 158, 1);
  border: 2px solid rgba(237, 236, 236, 1);
  border-radius: 6px;
  padding-left: 10px;
  height: 32px;
  font-family: Gotham-Book;
  :focus-visible {
    outline: none;
    border-color: #48505e;
  }
`;

export const NoPriorityTag = styled(Tag)`
  margin-right: 0;
  border-radius: 6px;
  border: 1px solid #828d9e;
  color: #828d9e;
`;

export const StyledInput = styled.input`
  border: 2px solid #edecec;
  color: #828d9e;
  border-radius: 6px;
  padding-left: 6px;
  min-width: 29px;
  height: 32px;
  font-size: 14px;
  font-weight: 300;
  font-family: "Gotham-Book";
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
`;

export const DateConfigurationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 350px;
  flex-wrap: wrap;
  alig-items: center;
`;

export const TimePickerStyled = styled(TimePicker)`
  .Mui-disabled {
    cursor: auto;
  }
  .Mui-focused {
    border-color: #48505e !important;
  }
  .MuiInput-formControl {
    :hover {
      border-color: #0273e9 !important;
      box-shadow: none !important;
    }
    :focus {
      border-color: #48505e !important;
      box-shadow: none !important;
    }
    :focus-visible {
      outline: none !important;
    }
  }
`;

export const IconsContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;

  display: flex;
  align-items: center;
  gap: 4px;
  height: 24px;
  transition: background 0.35s ease;
`;

export const DateConfigContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  border-radius: 6px;
  overflow: hidden;
  flex: 1;
`;

export const DateConfigItem = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  font-size: 14px;
  font-family: Gotham-Bold;
  cursor: pointer;
  background-color: ${({ $selected }) => ($selected ? "#0273e9" : "#edecec")};
  color: ${({ $selected }) => ($selected ? "#ffffff" : "#828d9e")};
  border-right: 1px solid #d9d9d9;
  :hover {
    ${({ $selected }) => (!$selected ? "background-color: #d9d9d9;" : "")}
  }
`;
