import React, { useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { Languages } from "./Dictionary";
import ModalTab from "../components/ModalTab/ModalTab";
import StepContent from "./StepContent";
import {
  CloseIcon,
  ContentWrapper,
  ModalContent,
  ModalTitle,
  ModalWrapper,
} from "../modalStyles";
import {
  Team,
  TeamMember,
  Permission,
  DashboardPermission,
  CreateDashboardData,
} from "../../Dashboards.d";
import { useMutation } from "../../../../hooks";

const { CreateDashboard, NameAndAssignDashboard, SelectPermissions } =
  Languages["ESP"];
interface CreateDashboardModalProps {
  onClose: () => void;
  openAddGraphModal: () => void;
  selectedTeam: number | null;
  teams: Team[];
  reloadDashboard: () => void;
}

const CreateDashboardModal: React.FC<CreateDashboardModalProps> = ({
  onClose,
  openAddGraphModal,
  selectedTeam,
  teams,
  reloadDashboard,
}) => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [dashboardName, setDashboardName] = useState<string>("");
  const [selectedTeamValue, setSelectedTeamValue] = useState<number | null>(
    selectedTeam
  );
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const steps = [NameAndAssignDashboard, SelectPermissions];

  const [getCreateDashboardInformation] = useMutation<CreateDashboardData>({
    func: "Ver2-DashBoards-gcdi",
    onSuccess: ({ TeamMembers, Permissions }) => {
      setTeamMembers(TeamMembers);
      setPermissions(Permissions);
    },
  });

  const [createDashboard] = useMutation<number>({
    func: "Ver2-DashBoards-cd",
    onSuccess: (Id) => {
      history.push(`/home/dashboards/${selectedTeamValue}-${Id}`);
      openAddGraphModal();
      reloadDashboard();
    },
  });

  const validateNextStep = () => {
    switch (activeStep) {
      case 0:
        const isDashboardValid = Boolean(dashboardName);
        const isTeamValid = typeof selectedTeamValue !== "undefined";
        return isDashboardValid && isTeamValid;

      default:
        return true;
    }
  };

  const onFinishStepOne = () => {
    if (validateNextStep()) {
      setActiveStep(1);
      getCreateDashboardInformation({
        args: { IdTeam: selectedTeam },
      });
    }
  };

  const onChangeUserPermissions = (
    idUser: number,
    permission: DashboardPermission
  ) => {
    let user = teamMembers[idUser];
    user.Permission = permission;
    let members = _.cloneDeep(teamMembers);
    members[idUser] = user;
    setTeamMembers(members);
  };

  const onFinishStepTwo = () => {
    // const newCreatedDashboardId = 0;
    // this is temporal, change this for the id returned by the create dashboard
    createDashboard({
      args: {
        IdTeam: selectedTeamValue,
        DashBoardName: dashboardName,
        TeamMembers: teamMembers,
      },
    });
  };

  return (
    <Modal open onClose={onClose}>
      <ModalWrapper onClick={onClose}>
        <ModalContent
          onClick={(e) => e.stopPropagation()}
          $height="auto"
          $maxHeight="570px"
        >
          <ModalTitle>
            <p>{CreateDashboard}</p>
            <CloseIcon size={20} onClick={onClose} />
          </ModalTitle>
          <ModalTab
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            linear
            canGoNext={validateNextStep()}
          />

          <ContentWrapper>
            <StepContent
              step={activeStep}
              // step one
              dashboardName={dashboardName}
              setDashboardName={setDashboardName}
              teams={teams}
              selectedTeam={selectedTeamValue}
              setSelectedTeam={setSelectedTeamValue}
              canGoNext={!validateNextStep()}
              onFinishStepOne={onFinishStepOne}
              // step two
              teamMembers={teamMembers}
              permissions={permissions}
              onChangeUserPermissions={onChangeUserPermissions}
              onFinishStepTwo={onFinishStepTwo}
            />
          </ContentWrapper>
        </ModalContent>
      </ModalWrapper>
    </Modal>
  );
};

export default CreateDashboardModal;
