import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const StyledPaper = styled(Paper)`
  border-radius: 10px !important;
  max-height: 90vh;
  width: 350px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
`;

export const ColumnsContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const Column = styled.div<{ $border?: boolean }>`
  flex: 1;
  background-color: white;
  border-right: 1px solid
    ${({ $border = false }) => ($border ? "#c0c9d3" : "white")};
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ShortcutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const ShortcutName = styled.div``;

export const KeyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const KeyIcon = styled.div`
  border: 1px solid #c0c9d3;
  border-radius: 5px;
  padding: 0 5px;
  box-shadow: #c0c9d3 0px -1px 0px inset;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 12px;
  }
`;
