import React from "react";
import { Popover, Row, Space } from "antd";
import styled from "styled-components";
import { CloseIcon } from "../../../../../components/hoverIcons";
import { Button } from "../../../../../GeestUI";

const Container = styled.div`
  padding: 0;
  width: 300px;
`;

const Title = styled.div`
  margin: 0px;
  font-weight: 700;
  font-size: 16px;
  color: var(--dark-gray);
`;

const Message = styled(Title)`
  width: 250px;
  font-size: 14px;
  text-align: center;
  margin: 24px 0;
`;

interface ConfirmDeletionProps {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  toolboxOffset?: boolean;
}

const ConfirmDeletion: React.FC<ConfirmDeletionProps> = ({
  children,
  visible,
  onConfirm,
  onCancel,
  toolboxOffset = false,
}) => {
  const y_offset = toolboxOffset ? 40 : -10;

  const handleOnOpenChange = (visible: boolean) => {
    if (!visible) onCancel();
  };

  return (
    <Popover
      open={visible}
      onOpenChange={handleOnOpenChange}
      placement="bottomLeft"
      showArrow={false}
      trigger="click"
      overlayInnerStyle={{
        overflow: "hidden",
        borderRadius: "6px",
      }}
      content={
        <Container>
          <Row justify="space-between">
            <Title>Eliminar</Title>
            <CloseIcon onClick={onCancel} />
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Message>¿Estás seguro que deseas eliminar este nodo?</Message>
          </div>
          <Row justify="center">
            <Space align="center">
              <Button type="secondary" onClick={onCancel}>
                Cancelar
              </Button>
              <Button type="primary" danger onClick={onConfirm} autoFocus>
                Eliminar
              </Button>
            </Space>
          </Row>
        </Container>
      }
      align={{ offset: [0, y_offset] }}
    >
      {children}
    </Popover>
  );
};

export default ConfirmDeletion;
