import { Tooltip } from "@material-ui/core";
import _, { noop as NOOP } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ColorPicker, { Circle } from "../../../../components/ColorPicker";
import { Priority } from "../Popups/PriorityPopover.d";
import {
  DeleteIcon,
  CancelIcon,
  ConfirmIcon,
} from "../../../../components/hoverIcons";
import { Row, Space } from "antd";

const EditPriorityPopover = styled(Row)`
  width: 100%;
`;

const StyledTextField = styled.input`
  border: 2px solid #edecec;
  color: #828d9e;
  border-radius: 6px;
  padding-left: 6px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  font-weight: 300;
  font-family: "Gotham-Book";
  flex: 1;
  margin-right: 14px;
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
`;

const PriorityActions = styled(Space)``;

const Languages = {
  ENG: {
    DeletePriority: "Delete priority",
    Cancel: "Cancel",
    Save: "Save",
  },
  ESP: {
    DeletePriority: "Eliminar prioridad",
    Cancel: "Cancelar",
    Save: "Guardar",
  },
};

const { DeletePriority, Cancel, Save } = Languages["ESP"];

interface EditPriorityProps {
  defaultValue?: Priority;
  disableDelete?: boolean;
  onSubmit?: (newPriority: Priority) => void;
  onDelete?: (deletedPriority: Priority) => void;
  onCancel?: () => void;
}

const defaultPriority: Priority = {
  Title: "",
  Color: "#03a9f4",
  IdTaskPriority: 0,
  Position: 0,
};

const EditPriority: React.FC<EditPriorityProps> = ({
  defaultValue,
  disableDelete = false,
  onSubmit = NOOP,
  onDelete = NOOP,
  onCancel = NOOP,
}) => {
  const [priority, setPriority] = useState<Priority>(defaultPriority);

  useEffect(() => {
    if (defaultValue) {
      setPriority(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTitle = (Title: string): void => {
    setPriority({ ...priority, Title: _.trim(Title) });
  };
  const onChangeColor = (Color: string): void => {
    setPriority({ ...priority, Color });
  };

  const handleOnDelete = () => {
    if (priority.IdTaskPriority !== 0 && !disableDelete) {
      onDelete(priority);
    }
  };
  const handleOnSubmit = () => {
    if (!_.isEmpty(priority.Title.trim())) {
      onSubmit(priority);
    }
  };

  const handleOnChangeTitle: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    if (value.length <= 15) onChangeTitle(value);
  };

  return (
    <EditPriorityPopover justify="space-between">
      <StyledTextField
        autoFocus
        value={priority.Title}
        onChange={handleOnChangeTitle}
        placeholder="Nombre de prioridad..."
        onKeyDown={(e) => {
          if (e.key === "Enter") handleOnSubmit();
        }}
      />

      <PriorityActions size={2} align="center">
        <ColorPicker color={priority.Color} onChange={onChangeColor}>
          <Circle
            color={priority.Color}
            size={18}
            style={{ cursor: "pointer", marginRight: "4px", marginTop: "1px" }}
          />
        </ColorPicker>

        {!disableDelete && (
          <Tooltip title={DeletePriority} placement="bottom">
            <DeleteIcon onClick={handleOnDelete} size={26} filled />
          </Tooltip>
        )}

        <Tooltip title={Cancel} placement="bottom">
          <CancelIcon onClick={onCancel} size={26} />
        </Tooltip>

        <Tooltip title={Save} placement="bottom">
          <ConfirmIcon onClick={handleOnSubmit} size={32} />
        </Tooltip>
      </PriorityActions>
    </EditPriorityPopover>
  );
};

export default EditPriority;
