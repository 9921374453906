import styled from "styled-components";

export const DataOriginContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    margin: 0;
  }
`;

export const NewOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const StringInput = styled.input<{
  $maxWidth?: string;
  $maxHeight?: string;
}>`
  border-radius: 6px;
  border: 2px solid #edecec;
  background-color: white;
  color: #828d9e;

  width: 100%;
  max-wdith: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : "auto")};
  height: 32px;
  resize: none;
  padding: 6px 10px;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const AddOptionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: Gotham-Bold;
  border: none;
  background-color: white;
  color: #0273e9;
  padding: 6px 16px;
  cursor: pointer;
  transition: background-color 0.35s ease;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const DataOriginOptionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  overflow: auto;
  max-height: 200px;
`;

export const TagContainer = styled.div`
  display: flex;
  padding: 6px 8px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 6px;
  background-color: #edecec;
  color: #48505e;
  pading: 6px;

  p {
    margin: 0;
  }
`;
