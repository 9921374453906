import { useState, useContext } from "react";
import { noop as NOOP } from "lodash";
import server from "../api/server";
import { MessagesContext } from "../components/AppMessages";
import { SubcriptionLimitContext } from "../components/SubscriptionLimit/SubscriptionLimitContext";

interface useMutationParams<Type> {
  func: string;
  onSuccess?: (response: Type, shippedData?: any) => void;
  onError?: (args: any, errorMessage: string) => void;
  conf?: {
    showMessageOnFail?: boolean;
  };
}

interface onCallMutationParams {
  args?: { [key: string]: any };
  idUR?: number;
  options?: {
    pags?: {
      CurrentPage: number;
      PerPage?: number;
      sortBy?: "asc" | "desc";
      sorted?: boolean;
    };
  };
  errorArgs?: { [key: string]: any };
  shippedData?: { [key: string]: any };
}

type loading = boolean;
type callMutation = (params: onCallMutationParams) => void;
type hookResponse = [callMutation, loading];

const useMutation = <Type>({
  func,
  onSuccess = NOOP,
  onError = NOOP,
  conf,
}: useMutationParams<Type>): hookResponse => {
  const [loading, setLoading] = useState(false);
  const { showMessage } = useContext(MessagesContext);
  const { setSubscriptionLimit } = useContext(SubcriptionLimitContext);
  const isVer2 = func.includes("Ver2");

  const showMessageOnFail = conf?.showMessageOnFail ?? true;

  const onCallMutation = ({
    args,
    idUR,
    options,
    errorArgs,
    shippedData,
  }: onCallMutationParams): void => {
    setLoading(true);
    server(func, idUR, args, options)
      .then((response: any) => {
        if (!isVer2) {
          if (response.Request_Error) {
            if (showMessageOnFail) {
              showMessage(response.Request_Error, "error");
            }
            setLoading(false);
            onError(errorArgs, response.Request_Error);
          } else {
            setLoading(false);
            onSuccess(response, shippedData);
          }
        } else {
          if (
            response.Request_Error ||
            ["error", "warning"].includes(response.Type)
          ) {
            showMessage(
              response.Message || response.Request_Error,
              response.Type || "error"
            );
            setLoading(false);
            onError(errorArgs, response.Message || response.Request_Error);
          } else if (response.SubscriptionLimit) {
            setSubscriptionLimit(response.SubscriptionLimit);
            setLoading(false);
            onError(errorArgs, "");
          } else {
            const { Type, Message, Data } = response;
            showMessage(Message, Type);
            setLoading(false);
            onSuccess(Data, shippedData);
          }
        }
      })
      .catch((err) => {
        if (showMessageOnFail) showMessage("API Error", "error");
        console.error(err);
        setLoading(false);
        onError(errorArgs, "API Error");
      });
  };

  return [onCallMutation, loading];
};

export default useMutation;
