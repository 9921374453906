import React from "react";
import styled from "styled-components";
import _, { noop as NOOP } from "lodash";
import { TooltipPlacement } from "antd/lib/tooltip";
import GeestSelect from "../../../GeestUI/GeestSelect";
import User from "../../../components/User";
import { UserType } from "../DynamicInput.d";
import IsRequired from "./helpers/IsRequired";

interface UserSelectInputProps {
  value: string;
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  userSelectDataOrigin?: UserType[];
  onChange: (value: string) => void;
  onBlur?: (e: any) => void;
  onPressEnter?: (e: any) => void;
  extraParams?: {
    center?: boolean;
    userSize?: string;
    userNameColor?: string;
    userNameSize?: string;
    emailColor?: string;
    emailSize?: string;
    tooltipOffset?: number;
    tooltipPlacement?: TooltipPlacement;
    mb?: string;
    userPopover?: boolean;
    userLargeTooltip?: boolean;
  };
}

const InputContainer = styled.div<{ isConsult: boolean; mb?: string }>`
  ${({ isConsult, mb = "10px" }) => (isConsult ? "" : `margin-bottom: ${mb};`)}
  width: 100%;
`;

const UsersList = styled.div<{ $justify: string }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ $justify = "start" }) => $justify};
  align-items: center;
  gap: 5px;
  min-height: 34px;
`;

const UserSelectInput: React.FC<UserSelectInputProps> = ({
  value = "",
  isConsult,
  disabled,
  required,
  userSelectDataOrigin = [],
  onChange,
  onBlur = NOOP,
  onPressEnter = NOOP,
  extraParams = {
    center: false,
    tooltipOffset: 18,
    mb: "10px",
    userPopover: false,
    userLargeTooltip: false,
  },
}) => {
  const offset = extraParams.tooltipOffset;
  const tooltipPlacement = extraParams.tooltipPlacement ?? "bottom";

  const getUser = (id: string) => {
    return _.find(userSelectDataOrigin, { IdUser: Number(id) });
  };

  const transformedOptions = (() =>
    userSelectDataOrigin.map((usr) => {
      return {
        value: `${usr.IdUser}`,
        searchableValues: `${usr.FirstName},${usr.LastName},${usr.Email}`,
        label: <User user={usr} size="middle" placement={tooltipPlacement} />,
      };
    }))();

  const decodedValues = (() => {
    if (!value) return [];
    return value.split(",").map((idUser) => getUser(idUser));
  })();

  const handleOnChange = (value: string | string[]) => {
    if (!value || value === "__@") {
      onChange("");
    } else {
      onChange(Array.isArray(value) ? value.join(",") : value);
    }
  };

  const getSize = (user: UserType | undefined) => {
    let newSize = "large";
    if (extraParams.userSize) {
      newSize = extraParams.userSize;
    } else if (user) {
      const fullName = `${user?.FirstName} ${user?.LastName}`;
      if (offset && user.Email.length > offset) newSize = "middle";
      if (offset && fullName.length > offset) newSize = "small";
    }
    return newSize;
  };

  return (
    <InputContainer isConsult={isConsult} mb={extraParams.mb}>
      {isConsult ? (
        <UsersList $justify={extraParams.center ? "center" : "start"}>
          {[...decodedValues].map((u, index) => (
            <User
              key={index}
              user={u}
              size={decodedValues.length >= 2 ? "small" : getSize(u)}
              tooltip
              placement={tooltipPlacement}
              nameColor={extraParams.userNameColor ?? "#828d9e"}
              nameSize={extraParams.userNameSize ?? "14px"}
              emailColor={extraParams.emailColor ?? "#828d9e"}
              emailSize={extraParams.emailSize ?? "12px"}
              popover={extraParams.userPopover}
              userLargeTooltip={extraParams.userLargeTooltip}
            />
          ))}
        </UsersList>
      ) : (
        <GeestSelect
          value={value}
          multiselect
          required={required}
          disabled={disabled}
          options={transformedOptions}
          onChange={handleOnChange}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
        />
      )}
      {required && !isConsult && !Boolean(value) && <IsRequired />}
    </InputContainer>
  );
};

export default UserSelectInput;
