import styled, { css } from "styled-components";

export const Title = styled.div<{ $fontSize?: string }>`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  color: #48505e;
  font-family: Gotham-Bold;
  font-size: ${({ $fontSize = "14px" }) => $fontSize};
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  height: 100%;
  padding: 0px 10px;
`;

export const StyledInput = styled.input`
  border: 2px solid #edecec;
  color: #828d9e;
  border-radius: 6px;
  padding-left: 6px;
  width: 874px;
  height: 32px;
  font-size: 14px;
  font-weight: 300;
  font-family: "Gotham-Book";
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-contet: center;
  gap: 12px;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-contet: center;
  gap: 8px;
  color: #48505e;

  p {
    font-size: 18px;
    font-weight: 325;
    font-family: Gotham-Book;
    margin: 0;
  }
`;

export const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 10px;
  flex: 1;
`;

export const Header = styled.div`
  color: #ffffff;
  background-color: #0067b0;
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 6px 6px 0px 0px;
  font-family: Gotham-Bold;
  height: 40px;
  flex-direction: row;
  gap: 0;
  margin-bottom: 5px;
  font-size: 14px;
`;

export const LabelItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: 5px;
  width: 444px;
`;

export const ActionItem = styled(LabelItem)`
  justify-content: center;
  width: 177px;
`;

export const MeasurementItem = styled.div<{ $disabled: boolean }>`
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 0px 0px 6px 6px;
  height: 40px;
  flex-direction: row;
  gap: 0;
  font-size: 14px;
  border-bottom: 1px solid #828d9e;
  ${({ $disabled }) =>
    $disabled
      ? css`
          font-family: Gotham-Book;
          font-weight: 325;
          color: #d9d9d9;
        `
      : css`
          font-family: Gotham-Bold;
          color: #48505e;
          cursor: pointer;
          :hover {
            background-color: #edecec;
          }
        `}
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  flex: 1;
  align-items: center;
`;
