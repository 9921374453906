import React, { useState } from "react";
import _, { noop as NOOP } from "lodash";
import { Tooltip } from "@material-ui/core";
import {
  EditorsPopoverContainer,
  EditorsPopoverContent,
  EditorsPopoverTitle,
  UserContainer,
  UsersSelected,
} from "../styles";
import { SearchBar } from "../../styles";
import {
  MinusIcon,
  RemoveEditorIconContainer,
  UserInitialsHover,
  UserProfilePicHover,
  TeamMemberContainer,
} from "./styles";
import { UserInitials, UserProfilePic } from "../styles";
import { AssignedUser, User } from "../DetailPending.d";
import { CloseIcon } from "../../../../components/hoverIcons";

const Languages = {
  ENG: {
    NoResults: "No results",
  },
  ESP: {
    NoResults: "Sin resultados",
  },
};

const { NoResults } = Languages["ESP"];

interface EditorsPopoverProps {
  setVisible: (arg: boolean) => void;
  type: "executors" | "revisers";
  users: AssignedUser[];
  teamMembers: User[];
  userCanReassign: boolean;
  onChange?: (users: AssignedUser[]) => void;
  onClose?: () => void;
  resetFilters?: () => void;
  isInRevision?: boolean;
}

const EditorsPopover: React.FC<EditorsPopoverProps> = ({
  setVisible,
  type,
  users,
  teamMembers,
  userCanReassign = false,
  onChange = NOOP,
  onClose = NOOP,
  resetFilters = NOOP,
  isInRevision = false,
}) => {
  const [filter, setFilter] = useState("");

  const title = {
    executors: "Editar encargados",
    revisers: "Editar revisores",
  };
  const subtitle = {
    executors: "Encargados",
    revisers: "Revisores",
  };

  const filteredTeamMembers = teamMembers.filter((user) => {
    const assignedUser = _.find(users, { IdUser: user.IdUser });
    if (assignedUser) return false;
    if (_.isEmpty(filter)) return true;
    const userName = (user.FirstName + " " + user.LastName).toLowerCase();
    const userEmail = user.Email.toLowerCase();
    if (userName.includes(filter.toLowerCase())) return true;
    if (userEmail.includes(filter.toLowerCase())) return true;
    return false;
  });

  const onRemoveUser = (IdUser: number): void => {
    const newAssignedUsers = _.reject(users, { IdUser });
    if (userCanReassign) {
      onChange(newAssignedUsers);
      resetFilters();
    }
  };

  const onAssignUser = (user: User): void => {
    let newAssignedUsers: AssignedUser[] = [...users];
    newAssignedUsers.push({ ...user, UserTaskStatus: "Started" });
    onChange(newAssignedUsers);
    resetFilters();
  };

  return (
    <EditorsPopoverContainer>
      <EditorsPopoverTitle>
        <p>{title[type]}</p>
        <CloseIcon
          onClick={() => {
            setVisible(false);
            onClose();
          }}
          size={20}
        />
      </EditorsPopoverTitle>

      <SearchBar
        style={{
          marginLeft: 0,
          padding: "0 12px",
          paddingLeft: "35px",
          width: "100%",
        }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Buscar miembros de la empresa"
      />

      <EditorsPopoverContent>
        <p>{subtitle[type]}</p>
        <UsersSelected>
          {users.map((user) => {
            let canRemoveEditor = false;

            if (
              !(
                isInRevision &&
                users.filter((user) => user.UserTaskStatus === "Started")
                  .length < 2 &&
                user.UserTaskStatus === "Started"
              )
            ) {
              canRemoveEditor = true;
            }

            if (type === "executors" && users.length === 1) {
              canRemoveEditor = false;
            }

            return (
              <UserContainer key={user.IdUser}>
                <Tooltip
                  title={`${user.FirstName} ${user.LastName} (${user.Email})`}
                  placement="bottom"
                >
                  {!user.ProfilePicture ? (
                    <UserInitialsHover align="middle" justify="center">
                      {user.Initials}
                    </UserInitialsHover>
                  ) : (
                    <UserProfilePicHover image={user.ProfilePicture} />
                  )}
                </Tooltip>
                {canRemoveEditor && (
                  <RemoveEditorIconContainer
                    align="middle"
                    justify="center"
                    onClick={() => onRemoveUser(user.IdUser)}
                    style={{ cursor: "pointer" }}
                  >
                    <MinusIcon />
                  </RemoveEditorIconContainer>
                )}
              </UserContainer>
            );
          })}
        </UsersSelected>
      </EditorsPopoverContent>

      <EditorsPopoverContent
        gap={filteredTeamMembers.length > 0 ? "10px" : "5px"}
      >
        <p>Miembros de la empresa</p>
        {filteredTeamMembers.length > 0 ? (
          filteredTeamMembers.map((user) => (
            <TeamMemberContainer
              align="middle"
              key={user.IdUser}
              onClick={() => onAssignUser(user)}
            >
              {!user.ProfilePicture ? (
                <UserInitials align="middle" justify="center">
                  {user.Initials}
                </UserInitials>
              ) : (
                <UserProfilePic image={user.ProfilePicture} />
              )}
              <p
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  marginLeft: "5px",
                }}
              >
                {`${user.FirstName} ${user.LastName} (${user.Email})`}
              </p>
            </TeamMemberContainer>
          ))
        ) : (
          <p>{NoResults}</p>
        )}
      </EditorsPopoverContent>
    </EditorsPopoverContainer>
  );
};

export default EditorsPopover;
