import styled from "styled-components";
import { Row } from "antd";

export const Toolbar = styled(Row)`
  background-color: #48505e;
  padding: 6px 0;
  border-radius: 6px;
  color: white;
  flex-direction: column;
  font-family: "Gotham-book";
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  & > div {
    padding: 2px 16px;
    :hover {
      background-color: #0273e9;
    }
  }
  & > div > svg {
    margin-right: 5px;
  }
`;

export const Arrow = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  transform: rotate(45deg);
  background-color: #48505e;
  left: -3px;
  top: calc(50% - 4px);
  z-index: -1;
`;
