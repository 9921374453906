import React, { useState, useRef } from "react";
import {
  BasicInfoContainer,
  BasicInfoRow,
  DetailTitle,
  ProcessBasicInfo,
  ProcessActionsContainer,
  Row,
  TitleValueCol,
  Progress,
  ProgressBar,
  Circle,
  ScheduledProcessInfoContainer,
  TaskGroupContainer,
  TaskCard,
  TaskGroup,
  StyledTag,
  FinishedTasksInfoContainer,
  Badge,
} from "../styles";
import { BasicInfoProps } from "../ProcessDetail.d";
import processIcon from "../../../../resources/img/processIcon.svg";
import { Tag, Button, Typography } from "../../../../GeestUI";
import { Popover } from "@material-ui/core";
import { EditIcon } from "../../../../components/hoverIcons";
import PriorityPopover from "../../SharedComponents/ProcessDetail/PriorityPopover";
import { useMutation } from "../../../../hooks";
import { useLocation, useParams } from "react-router-dom";
import { ReactComponent as restartIcon } from "../../../../resources/img/restartIcon.svg";
import { ReactComponent as playIcon } from "../../../../resources/img/playIcon.svg";
import { ReactComponent as stopIcon } from "../../../../resources/img/stopIcon.svg";
import User from "../../SharedComponents/User";
import {
  AiOutlineClockCircle,
  AiOutlineCalendar,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import moment from "moment";
import RescheduleProcessModal from "../../SharedComponents/ProcessDetail/Modals/RescheduleProcessModal";
import StopProcessModal from "../../Pipeline/Popups/StopProcessModal";
import ConfirmRestartModal from "../../SharedComponents/ProcessDetail/Modals/ConfirmRestartProcessModal";
import { DeleteProcessModal } from "../../Pipeline/Popups";
import { FiTrash } from "react-icons/fi";
import scheduledProcessImage from "../../../../resources/img/scheduledProcessInfo.svg";
import { getStatusInfo, getTaskStatusColors } from "../helpers";
import { parseEmoji } from "../../../../emoji";
import { IoEyeOutline } from "react-icons/io5";
import Tooltip from "../../../../components/Tooltip";
import DetailPending from "../../../Pendings/DetailPending";
import formTriggerIcon from "../../../../resources/img/formTriggerIcon.svg";
import webhookTriggerIcon from "../../../../resources/img/webHookTriggerIcon.svg";
import alarmIcon from "../../../../resources/img/alarmIcon.svg";
import { useHistory } from "react-router-dom";

const { Text } = Typography;

const BasicInfo: React.FC<BasicInfoProps> = ({ processInfo, reload }) => {
  const [priorityPopoverVisible, setPriorityPopoverVisible] =
    useState<boolean>(false);
  const [modal, setModal] = useState<string>("");
  const editPriorityButton = useRef(null);
  const { idProcess } = useParams<{ idProcess: string }>();
  const [detailPendingId, setDetailPendingId] = useState<number | null>(null);
  const history = useHistory();
  const { state: LocState } = useLocation<{
    shouldGoBackTo?: string;
  }>();

  const isScheduled = ["WaitingToStart", "Waiting"].includes(
    processInfo?.Status || ""
  );
  const isEnded = ["Completed", "Canceled"].includes(processInfo?.Status || "");

  const [startScheduledProcess, startingProcess] = useMutation({
    func: "Ver2-Reports-ssp",
    onSuccess: reload,
  });

  const onStartProcess = () => {
    startScheduledProcess({ args: { IdProcessExecution: idProcess } });
  };

  const handleGoBack = () => {
    if (LocState?.shouldGoBackTo) {
      history.push(LocState.shouldGoBackTo);
    } else {
      history.goBack();
    }
  };

  const getDate = (date: string) => {
    return moment(date).format("DD/MMM/YY HH:mm");
  };

  const Modal: { [key: string]: React.ReactNode } = {
    stopProcess: (
      <StopProcessModal
        selectedProcesses={[Number(idProcess)]}
        onCancel={() => setModal("")}
        onSuccess={() => {
          reload();
          setModal("");
        }}
      />
    ),
    deleteProcess: (
      <DeleteProcessModal
        selectedProcesses={[Number(idProcess)]}
        onCancel={() => setModal("")}
        onSuccess={handleGoBack}
      />
    ),
    rescheduleProcess: (
      <RescheduleProcessModal
        IdProcessExecution={Number(idProcess)}
        onSuccess={() => {
          reload();
          setModal("");
        }}
        closeModal={() => setModal("")}
      />
    ),
    restartProcess: (
      <ConfirmRestartModal
        closeModal={() => setModal("")}
        onSuccess={() => {
          reload();
          setModal("");
        }}
        IdProcessExecution={idProcess}
      />
    ),
  };

  const triggerIcons: { [key: string]: any } = {
    Form: formTriggerIcon,
    Webhook: webhookTriggerIcon,
  };

  return (
    <BasicInfoContainer>
      {Modal[modal]}
      {!!detailPendingId && (
        <DetailPending
          idPending={String(detailPendingId)}
          onClose={() => setDetailPendingId(null)}
          onChangePending={(pendingId) => {
            setDetailPendingId(null);
            setTimeout(() => {
              setDetailPendingId(pendingId);
            }, 1);
          }}
          reloadReport={reload}
        />
      )}
      <DetailTitle>Datos del seguimiento</DetailTitle>
      <BasicInfoRow>
        <ProcessBasicInfo>
          <Row style={{ justifyContent: "space-between", gap: "28px" }}>
            <TitleValueCol style={{ flex: 1 }}>
              <DetailTitle
                style={{ fontSize: "14px", gap: "3px", alignItems: "center" }}
              >
                <img
                  alt="process"
                  src={processIcon}
                  style={{
                    width: "12px",
                    height: "12px",
                  }}
                />
                {processInfo?.ProcessTemplateName}
              </DetailTitle>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <Tooltip
                  title={`Este seguimiento está al ${
                    (processInfo?.ProgressPercentage || 0) < 0
                      ? 0
                      : processInfo?.ProgressPercentage
                  }%, es decir, tiene 
                  ${
                    processInfo?.ProcessDelay || 0
                  } horas de vencimientos acumulados`}
                >
                  <ProgressBar>
                    <Progress
                      $progress={
                        (processInfo?.ProgressPercentage || 0) < 0
                          ? 0
                          : processInfo?.ProgressPercentage
                      }
                    />
                  </ProgressBar>
                </Tooltip>
                <DetailTitle style={{ fontSize: "12px" }}>
                  <span
                    style={{ fontFamily: "Gotham-Book", marginRight: "5px" }}
                  >
                    Rendimiento
                  </span>
                  {(processInfo?.ProgressPercentage || 0) < 0
                    ? 0
                    : processInfo?.ProgressPercentage}
                  %
                </DetailTitle>
              </div>
            </TitleValueCol>
            <Row style={{ width: "183px", gap: "16px" }}>
              <TitleValueCol>
                Folio
                <div
                  style={{
                    height: "19px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "#0273E9",
                    fontFamily: "Gotham-Book",
                    fontSize: "12px",
                  }}
                >
                  {processInfo?.IdProcessExecution}
                </div>
              </TitleValueCol>
              <TitleValueCol>
                Prioridad
                <Row style={{ gap: "8px" }}>
                  {processInfo?.Priority ? (
                    <Tag color={processInfo?.Priority.Color}>
                      {processInfo?.Priority.Title}
                    </Tag>
                  ) : (
                    <Tag>Sin prioridad</Tag>
                  )}
                  <Popover
                    open={priorityPopoverVisible}
                    anchorEl={editPriorityButton.current}
                    onClose={() => setPriorityPopoverVisible(false)}
                    PaperProps={{
                      style: {
                        borderRadius: "10px",
                        width: "300px",
                        padding: "12px 16px",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                  >
                    <PriorityPopover
                      IdProcessExecution={processInfo?.IdProcessExecution || 0}
                      idTaskPriority={processInfo?.Priority?.IdTaskPriority}
                      reload={reload}
                      onClose={() => setPriorityPopoverVisible(false)}
                    />
                  </Popover>
                  {processInfo?.Permissions.UserCanEditProcess && (
                    <EditIcon
                      onClick={() => setPriorityPopoverVisible(true)}
                      ref={editPriorityButton}
                      filled
                      style={
                        priorityPopoverVisible
                          ? { background: "rgba(232, 236, 241, 0.5)" }
                          : {}
                      }
                    />
                  )}
                </Row>
              </TitleValueCol>
            </Row>
          </Row>
          <Row style={{ gap: "31px", justifyContent: "space-between" }}>
            {processInfo?.Stage && (
              <TitleValueCol>
                Etapa
                <Row style={{ gap: "8px", alignItems: "center" }}>
                  <Circle color={processInfo.Stage.Color} size={20} />
                  <Text>{processInfo.Stage.Label}</Text>
                </Row>
              </TitleValueCol>
            )}
            {(processInfo?.UserStarted || processInfo?.Trigger) && (
              <TitleValueCol>
                Disparador
                {!processInfo?.Trigger ||
                processInfo?.Trigger.Type === "Manual" ? (
                  <Row style={{ gap: "8px", alignItems: "center" }}>
                    <User
                      size="small"
                      picSize="20px"
                      user={processInfo.UserStarted}
                    />
                    <Text
                      style={{
                        maxWidth: "200px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {`${processInfo.UserStarted.FirstName} ${processInfo.UserStarted.LastName}`}
                    </Text>
                  </Row>
                ) : (
                  <Row style={{ gap: "8px", alignItems: "center" }}>
                    {!!triggerIcons[processInfo?.Trigger.Type] && (
                      <img
                        alt="trigger"
                        src={triggerIcons[processInfo?.Trigger.Type]}
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "2px",
                        }}
                      />
                    )}
                    <Text
                      style={{
                        maxWidth: "200px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {processInfo?.Trigger.Name}
                    </Text>
                  </Row>
                )}
              </TitleValueCol>
            )}
            {processInfo?.StartedAt && (
              <TitleValueCol>
                Inicio
                <Row style={{ gap: "3px", alignItems: "center" }}>
                  <AiOutlineClockCircle color="#1F9334" size={16} />
                  <Text>{getDate(processInfo.StartedAt)}</Text>
                </Row>
              </TitleValueCol>
            )}
            {processInfo?.EndedAt && (
              <TitleValueCol>
                Fin
                <Row style={{ gap: "3px", alignItems: "center" }}>
                  <AiOutlineClockCircle size={16} />
                  <Text>{getDate(processInfo.EndedAt)}</Text>
                </Row>
              </TitleValueCol>
            )}
            {processInfo?.Duration && (
              <TitleValueCol>
                Duración
                <Row style={{ gap: "2px", alignItems: "center" }}>
                  <img
                    alt="alarma"
                    src={alarmIcon}
                    style={{
                      width: "14px",
                      height: "14px",
                    }}
                  />
                  <Text>{processInfo.Duration}</Text>
                </Row>
              </TitleValueCol>
            )}
          </Row>
        </ProcessBasicInfo>
        <ProcessActionsContainer>
          {!isScheduled && processInfo?.Permissions.UserCanEditProcess && (
            <Button
              type="secondary"
              SvgIcon={restartIcon}
              style={{ width: "131px" }}
              onClick={() => setModal("restartProcess")}
              containerStyles={{
                justifyContent: "flex-start",
                marginLeft: "-3.5px",
              }}
            >
              Reiniciar
            </Button>
          )}
          {isScheduled && processInfo?.Permissions.UserCanEditProcess && (
            <Button
              type="secondary"
              loading={startingProcess}
              SvgIcon={playIcon}
              style={{ width: "131px" }}
              onClick={onStartProcess}
              containerStyles={{
                justifyContent: "flex-start",
              }}
            >
              Iniciar
            </Button>
          )}
          {!isEnded &&
            processInfo?.Permissions.UserCanEditProcess &&
            (processInfo.Status === "WaitingToStart" ||
              processInfo.Status === "OnTime") && (
              <Button
                type="secondary"
                Icon={AiOutlineCalendar}
                style={{ width: "131px" }}
                onClick={() => setModal("rescheduleProcess")}
                containerStyles={{
                  justifyContent: "flex-start",
                }}
              >
                Reagendar
              </Button>
            )}
          {!isEnded && processInfo?.Permissions.UserCanCancelProcess && (
            <Button
              type="secondary"
              SvgIcon={stopIcon}
              style={{ width: "131px" }}
              onClick={() => setModal("stopProcess")}
              containerStyles={{
                justifyContent: "flex-start",
              }}
              svgExtraStyles={{
                marginTop: "2px",
              }}
            >
              Detener
            </Button>
          )}
          {processInfo?.Permissions.UserCanDeleteProcess && (
            <Button
              type="secondary"
              Icon={FiTrash}
              style={{ width: "131px" }}
              onClick={() => setModal("deleteProcess")}
              danger
              iconSize={18}
              containerStyles={{
                justifyContent: "flex-start",
              }}
            >
              Eliminar
            </Button>
          )}
        </ProcessActionsContainer>
      </BasicInfoRow>
      {isScheduled && (
        <ScheduledProcessInfoContainer>
          <img alt="process" src={scheduledProcessImage} />
          <div style={{ width: "374px" }}>
            Cuando el proceso se inicie, se mostrará aquí quienes están
            interactuando
          </div>
        </ScheduledProcessInfoContainer>
      )}
      {!isScheduled && (
        <>
          <DetailTitle>
            {isEnded ? "Rendimiento de los encargados" : "Encargados actuales"}
          </DetailTitle>
          <Row
            style={{
              gap: "22px",
              flexWrap: "wrap",
              flex: 1,
            }}
          >
            {processInfo?.UsersTasksInfo?.map(
              ({
                FirstName,
                LastName,
                Tasks,
                OnTimeTasks = 0,
                LateTasks = 0,
                ProfilePicture,
                Initials,
                Email,
                IdUser,
              }) => (
                <TaskGroupContainer
                  style={{
                    height: isEnded ? "192px" : "fit-content",
                    padding: isEnded ? "12px 33px" : "12px 6.5px 12px 16px",
                    maxHeight: isEnded ? "192px" : "392px",
                  }}
                  key={IdUser}
                >
                  <User
                    size="small"
                    user={{
                      FirstName,
                      LastName,
                      ProfilePicture,
                      Initials,
                      Email,
                      IdUser,
                    }}
                  />
                  <DetailTitle>{`${FirstName} ${LastName}`}</DetailTitle>
                  {!isEnded ? (
                    <TaskGroup>
                      {Tasks?.map((task) => (
                        <TaskCard
                          $color={getTaskStatusColors(task.Status).color}
                          $background={
                            getTaskStatusColors(task.Status).background
                          }
                          onClick={() => setDetailPendingId(task.IdTaskValue)}
                          key={task.IdTaskValue}
                        >
                          <Row style={{ gap: "8px", alignItems: "center" }}>
                            <DetailTitle
                              style={{
                                fontSize: "12px",
                                flex: 1,
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  width: "calc(100% - 3px)",
                                }}
                              >
                                {parseEmoji(task.Title)}
                              </div>
                            </DetailTitle>
                            <Row style={{ gap: "3px", alignItems: "center" }}>
                              {task.IsRevisor && (
                                <IoEyeOutline
                                  size={14}
                                  color="rgba(2, 115, 233, 1)"
                                />
                              )}
                              <StyledTag
                                color={getStatusInfo(task?.Status).color}
                                style={{
                                  width: "74px",
                                  justifyContent: "center",
                                }}
                              >
                                {getStatusInfo(task?.Status).label}
                              </StyledTag>
                            </Row>
                          </Row>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "12px",
                              fontFamily: "Gotham-Book",
                              color: "rgba(130, 141, 158, 1)",
                              flex: 1,
                            }}
                          >
                            <Row style={{ alignItems: "center", gap: "2px" }}>
                              <AiOutlineClockCircle
                                size={14}
                                style={{ marginBottom: "2px" }}
                              />
                              Inicio
                              {` ${moment(task.StartedAt).format("DD/MMM/YY")}`}
                            </Row>
                            {!!task.Expired && (
                              <div style={{ color: "rgba(255, 0, 12, 1)" }}>
                                Vencida
                                {` ${task.Expired}`}
                              </div>
                            )}
                          </div>
                        </TaskCard>
                      ))}
                    </TaskGroup>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        width: "234px",
                      }}
                    >
                      <ProgressBar
                        style={{
                          height: "10px",
                          borderRadius: "5px",
                          backgroundColor: "rgba(255, 0, 12, 1)",
                        }}
                      >
                        <Progress
                          $progress={
                            (OnTimeTasks / (OnTimeTasks + LateTasks)) * 100
                          }
                          style={{
                            height: "10px",
                            borderRadius: "5px",
                            backgroundColor: "rgba(139, 195, 74, 1)",
                          }}
                        />
                      </ProgressBar>
                      <Row style={{ justifyContent: "space-between" }}>
                        <FinishedTasksInfoContainer>
                          <Tooltip title="Tareas terminadas a tiempo">
                            <Badge
                              style={{
                                backgroundColor:
                                  OnTimeTasks > 0
                                    ? "rgba(139, 195, 74, 1)"
                                    : "rgba(130, 141, 158, 1)",
                              }}
                            >
                              {OnTimeTasks}
                            </Badge>
                          </Tooltip>
                          <span>
                            Terminadas a tiempo
                            <AiOutlineInfoCircle
                              size={9}
                              color="rgba(72, 80, 94, 1)"
                              style={{
                                marginLeft: "3px",
                                marginBottom: "0.2px",
                              }}
                            />
                          </span>
                        </FinishedTasksInfoContainer>
                        <FinishedTasksInfoContainer>
                          <Tooltip title="Tareas terminadas a destiempo">
                            <Badge
                              style={{
                                backgroundColor:
                                  LateTasks > 0
                                    ? "rgba(255, 0, 12, 1)"
                                    : "rgba(130, 141, 158, 1)",
                              }}
                            >
                              {LateTasks}
                            </Badge>
                          </Tooltip>
                          <span>
                            Terminadas a destiempo
                            <AiOutlineInfoCircle
                              size={9}
                              color="rgba(72, 80, 94, 1)"
                              style={{
                                marginLeft: "3px",
                                marginBottom: "0.2px",
                              }}
                            />
                          </span>
                        </FinishedTasksInfoContainer>
                      </Row>
                    </div>
                  )}
                </TaskGroupContainer>
              )
            )}
          </Row>
        </>
      )}
    </BasicInfoContainer>
  );
};

export default BasicInfo;
