import React from "react";
import styled from "styled-components";
import { TableData } from "../DataBases.d";
import { Paper } from "@material-ui/core";
import { CloseIcon } from "../../../components/hoverIcons";
import varDBIcon from "../../../resources/img/DataType_828D9E/vardb.svg";
import { Button } from "../../../GeestUI";
import { useMutation } from "../../../hooks";

interface EditLinkProps {
  selectedColumn: TableData | null;
  onClose: () => void;
  reload: () => void;
  IdTeam: string;
  IdVarDB: string;
}

const StyledPaper = styled(Paper)`
  border-radius: 6px !important;
  width: 432px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 12px;

  p {
    margin: 0;
    font-family: Gotham-Bold;
    font-size: 16px;
    color: #48505e;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ColumnName = styled.div`
  width: 394px;
  min-height: 32px;
  background-color: rgba(237, 236, 236, 1);
  padding: 6px 10px 6px 10px;
  border-radius: 5px;
  font-family: Gotham-Book;
  font-size: 14px;
  color: rgba(130, 141, 158, 1);
`;

const Description = styled.div`
  text-align: center;
  color: rgba(130, 141, 158, 1);
  font-size: 14px;
  font-weight: 325;
  font-family: Gotham-Book;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 331px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const EditLink: React.FC<EditLinkProps> = ({
  onClose,
  reload,
  selectedColumn,
  IdTeam,
  IdVarDB,
}) => {
  const [unlinkVarDB] = useMutation({
    func: "Ver2-Vardbs-dvl",
    onSuccess: (res, { IdColumn }) => {
      onClose();
      reload();
      let filters: any[] = JSON.parse(
        localStorage.getItem(`DBFilters-${IdTeam}-${IdVarDB}`) || "[]"
      );
      filters = filters.filter(({ IdColumn: Id }) => IdColumn !== Id);
      localStorage.setItem(
        `DBFilters-${IdTeam}-${IdVarDB}`,
        JSON.stringify(filters)
      );
    },
  });

  const onUnlink = () => {
    unlinkVarDB({
      args: {
        IdTeam,
        IdVarDBLink: selectedColumn?.IdVardbLink,
        IdVarDB,
      },
      shippedData: { IdColumn: selectedColumn?.IdColumn },
    });
  };

  return (
    <StyledPaper>
      <ModalTitle>
        <p>Editar Vínculo</p>
        <CloseIcon onClick={onClose} />
      </ModalTitle>
      <Content>
        <ColumnName>{selectedColumn?.Title}</ColumnName>
        <Description>
          Esta base de datos esta vinculada con
          <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
            <img src={varDBIcon} alt="" />
            {selectedColumn?.VardbTitle}
          </div>
        </Description>
        <Description>
          La relación de las bases de datos es automática conforme vas
          ejecutando procesos. Para que se haga la relación, debes tener un
          proceso que contemple ambas bases de datos y ejecutarlo. Conoce más en
          el tutorial
        </Description>
        <ButtonContainer>
          <Button
            style={{ width: "208px" }}
            type="secondary"
            danger
            onClick={onUnlink}
          >
            Eliminar vinculación
          </Button>
          <Button
            type="primary"
            onClick={() =>
              window.open(
                "https://www.youtube.com/watch?v=zzRZpl8uYeo&list=PLTwfrEBfpGHYSn7ho9xq7dvo4MGRuklD7&index=65"
              )
            }
          >
            Ver tutorial
          </Button>
        </ButtonContainer>
      </Content>
    </StyledPaper>
  );
};

export default EditLink;
