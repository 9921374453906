import React, { MouseEventHandler } from "react";
import { IconContainer } from "./styles";
import { RiDownload2Fill } from "react-icons/ri";
import { AiOutlineDownload } from "react-icons/ai";

const AttachmentIcon: React.FC<{
  onClick: MouseEventHandler<HTMLDivElement>;
  colored?: boolean;
}> = ({ onClick, colored }) => {
  const Icon = colored ? RiDownload2Fill : AiOutlineDownload;
  return (
    <IconContainer size={20} onClick={onClick}>
      <Icon color={colored ? "#0273E9" : "#48505E"} size={16} />
    </IconContainer>
  );
};

export default AttachmentIcon;
