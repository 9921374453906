import React, { useState } from "react";
import { Row, Space } from "antd";
import _ from "lodash";
import { StyledInput } from "./styles";
import { Stage } from "../../Flow/CustomNodes/Toolbox/Toolbox.d";
import ColorPicker, { Circle } from "../../../../components/ColorPicker";
import {
  CancelIcon,
  ConfirmIcon,
  DeleteIcon,
} from "../../../../components/hoverIcons";

interface StageEditorProps {
  defaultValue: Stage;
  onSubmit: (stage: Stage) => void;
  onDelete: (stage: Stage) => void;
  onCancel: () => void;
}

const StageEditor: React.FC<StageEditorProps> = ({
  defaultValue,
  onSubmit,
  onDelete,
  onCancel,
}) => {
  const [stage, setStage] = useState(defaultValue);
  const isValidForm = !_.isEmpty(_.trim(stage.Label));
  const showDelete = stage.Id !== 0;

  const handleOnChangeLabel: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setStage({ ...stage, Label: e.target.value });
  const handleOnChangeColor = (color: string): void =>
    setStage({ ...stage, Color: color });

  const handleOnSubmit = () => {
    if (isValidForm) {
      onSubmit(stage);
    }
  };
  const handleOnDelete: React.MouseEventHandler<HTMLDivElement> = () =>
    onDelete(stage);

  return (
    <Row style={{ width: "100%" }} justify="space-between" align="middle">
      <StyledInput
        placeholder="Nombre de etapa..."
        value={stage.Label}
        onChange={handleOnChangeLabel}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleOnSubmit();
        }}
        autoFocus
      />
      <Space size={2} align="center">
        <ColorPicker color={stage.Color} onChange={handleOnChangeColor}>
          <Circle
            color={stage.Color}
            size={18}
            style={{ cursor: "pointer", marginRight: "4px", marginTop: "1px" }}
          />
        </ColorPicker>
        {showDelete && <DeleteIcon onClick={handleOnDelete} size={26} filled />}
        <CancelIcon onClick={onCancel} size={26} />
        <ConfirmIcon onClick={handleOnSubmit} size={32} />
      </Space>
    </Row>
  );
};

export default StageEditor;
