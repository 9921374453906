import React, { useState } from "react";
import _, { noop as NOOP } from "lodash";
import { Popover, Space } from "antd";
import { Typography } from "../../../../GeestUI";
import StatusPopover from "./Popovers/StatusPopover";
import { Status } from "../Dashboard.d";
import { StyledInputBox, StyledTag } from "./Styles";

const { H4 } = Typography;

export const getStatusInfo = (
  status: string
): { color: string; label: string } => {
  switch (status) {
    case "New":
    case "Nuevo":
      return { color: "yellow", label: "Nuevo" };
    case "OnTime":
    case "Iniciado":
    case "A tiempo":
      return { color: "cyan", label: "Iniciado" };
    case "Completed":
    case "Terminado":
      return { color: "green", label: "Terminado" };
    case "Canceled":
    case "Cancelado":
      return { color: "default", label: "Cancelado" };
    case "WaitingToStart":
    case "Waiting":
    case "En espera":
    case "Por iniciar":
      return { color: "orange", label: "Por iniciar" };
    case "Stuck":
    case "Atorado":
    case "Vencido":
      return { color: "red", label: "Vencido" };
    default:
      return { color: "default", label: status };
  }
};

interface StatusFilterProps {
  extended: boolean;
  status: Status[];
  statusCollection: Status[];
  StatusPlaceholder: string;
  onChangeStatus: (oldStatus: Status[], newStatus: Status[]) => void;
}

const StatusFilter: React.FC<StatusFilterProps> = ({
  extended,
  status,
  statusCollection,
  StatusPlaceholder,
  onChangeStatus,
}) => {
  const [statusPopoverVisible, setStatusPopoverVisible] =
    useState<boolean>(false);

  return (
    <Popover
      trigger="click"
      placement="bottom"
      overlayStyle={{
        border: "solid 1px #cfcdcd",
        borderRadius: ".9rem",
        padding: "0",
        overflow: "hidden",
        boxShadow: "0px 0px 30px #cfcdcd",
        width: "350px",
      }}
      destroyTooltipOnHide
      open={statusPopoverVisible}
      onOpenChange={extended ? setStatusPopoverVisible : NOOP}
      content={() => (
        <StatusPopover
          value={status}
          statusCollection={statusCollection}
          getStatusInfo={getStatusInfo}
          onChange={onChangeStatus}
          onClose={() => setStatusPopoverVisible(false)}
        />
      )}
    >
      <StyledInputBox
        tabIndex={0}
        onKeyDown={({ key }) => {
          if (key === "Enter") setStatusPopoverVisible(true);
        }}
      >
        <Space size={5} wrap>
          {status.map((stat, idx) => (
            <StyledTag
              key={idx}
              color={getStatusInfo(stat.Label).color}
              $selected
            >
              {getStatusInfo(stat.Label).label}
            </StyledTag>
          ))}
        </Space>
        {_.isEmpty(status) && (
          <H4
            ml="5px"
            mb="0"
            color="#c0c9d3"
            onClick={extended ? () => setStatusPopoverVisible(true) : NOOP}
          >
            {StatusPlaceholder}
          </H4>
        )}
      </StyledInputBox>
    </Popover>
  );
};

export default StatusFilter;
