import React, { useState, useEffect } from "react";
import { IoIosClose } from "react-icons/io";
import {
  Element,
  FileValue,
  ImageDimensionsType,
  ImageSize,
  ImageSourceType,
  ImageUrlSize,
} from "../../PDFConfig.d";
import ImagePlaeholder from "../../../../resources/img/imagePlaceholder.svg";
import {
  CurrentImageContainer,
  DeleteIconContainer,
  DraggableItem,
  StyledResizable,
} from "./styles";

interface ImageNodeProps {
  currentElement: string | null;
  setCurrentElement: (id: string | null) => void;
  elementToConfig: string | null;
  setElementToConfig: (id: string | null) => void;
  componentKey: string;
  value: FileValue | string;
  element: Element;
  needsPlaceholderImage: boolean;
  imageDimensions: ImageDimensionsType;
  onChange: (newValue: string) => void;
  onStart: () => void;
  onStop: () => void;
  onControlledDrag: (e: any, position: any) => void;
  onResizeStart: () => void;
  onResize: (e: any, data: any) => void;
  onResizeStop: () => void;
  onDeleteElement: () => void;
  teamLogo: string;
  sourceConfig: ImageSourceType;
}

const ImageNode: React.FC<ImageNodeProps> = ({
  currentElement,
  setCurrentElement,
  elementToConfig,
  setElementToConfig,
  componentKey,
  value,
  element,
  needsPlaceholderImage,
  imageDimensions,
  onChange,
  onStart,
  onStop,
  onControlledDrag,
  onResizeStart,
  onResize,
  onResizeStop,
  onDeleteElement,
  teamLogo,
  sourceConfig,
}) => {
  const [imageUrlSize, setImageUrlSize] = useState<ImageUrlSize | null>(null);
  const dragHandlers = { onStart, onStop };
  const isSelected = element.id === currentElement;
  const isConfig = element.id === elementToConfig;

  const hanldeResize = (e: any, data: any) => {
    if (!isSelected) return false;
    onResize(e, data);
  };

  const onSelectElement = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentElement(element.id);
    setElementToConfig(element.id);
  };

  useEffect(() => {
    if ((value as FileValue).filePath) {
      const loadImage = async () => {
        const image = new Image();
        image.src = (value as FileValue).filePath;

        await image.decode();

        setImageUrlSize({ width: image.width, height: image.height });
      };

      loadImage();
    } else {
      setImageUrlSize(null);
    }
  }, [value]);

  const getImageSize = (): ImageSize => {
    if (needsPlaceholderImage) {
      if (element.boxSize.width > element.boxSize.height) {
        return { width: "auto", height: "100%" };
      }
      if (element.boxSize.width < element.boxSize.height) {
        return { width: "100%", height: "auto" };
      }
      return { width: "100%", height: "100%" };
    }

    if (!imageUrlSize) return { width: "100%", height: "100%" };

    let boxRatio = "square";
    if (element.boxSize.width > element.boxSize.height) {
      boxRatio = "horizontal";
    }
    if (element.boxSize.width < element.boxSize.height) {
      boxRatio = "vertical";
    }

    let imageRatio = "square";
    if (imageUrlSize.width > imageUrlSize.height) {
      imageRatio = "horizontal";
    }
    if (imageUrlSize.width < imageUrlSize.height) {
      imageRatio = "vertical";
    }

    if (boxRatio === "square") {
      if (imageRatio === "square") {
        return { width: "100%", height: "100%" };
      }
      if (imageRatio === "horizontal") {
        return { width: "100%", height: "auto" };
      }
      if (imageRatio === "vertical") {
        return { width: "auto", height: "100%" };
      }
    }

    if (boxRatio === "horizontal") {
      if (imageRatio === "square") {
        return { width: "auto", height: "100%" };
      }
      if (imageRatio === "horizontal") {
        if (
          (element.boxSize.width / imageUrlSize.width) * imageUrlSize.height >
          element.boxSize.height
        ) {
          return { width: "auto", height: "100%" };
        }
        return { width: "100%", height: "auto" };
      }
      if (imageRatio === "vertical") {
        return { width: "auto", height: "100%" };
      }
    }

    if (boxRatio === "vertical") {
      if (imageRatio === "square") {
        return { width: "100%", height: "auto" };
      }
      if (imageRatio === "horizontal") {
        return { width: "100%", height: "auto" };
      }
      if (imageRatio === "vertical") {
        if (
          (element.boxSize.height / imageUrlSize.height) * imageUrlSize.width >
          element.boxSize.width
        ) {
          return { width: "100%", height: "auto" };
        }
        return { width: "auto", height: "100%" };
      }
    }

    return { width: "100%", height: "100%" };
  };

  return (
    <DraggableItem
      bounds="parent"
      position={element.position}
      {...dragHandlers}
      onDrag={onControlledDrag}
    >
      <StyledResizable
        width={element.boxSize.width}
        height={element.boxSize.height}
        selected={isSelected}
        minConstraints={[80, 40]}
        onResizeStart={onResizeStart}
        onResize={hanldeResize}
        onResizeStop={onResizeStop}
      >
        <CurrentImageContainer
          key={componentKey}
          width={element.boxSize.width}
          height={element.boxSize.height}
          imageDimensions={imageDimensions}
          imageSize={getImageSize()}
          selected={isSelected || isConfig}
          onClick={onSelectElement}
        >
          {sourceConfig === "teamLogo" && teamLogo ? (
            <img src={teamLogo} alt="logo" />
          ) : (value as FileValue).filePath ? (
            <img
              src={(value as FileValue).filePath}
              alt={(value as FileValue).name}
            />
          ) : (
            needsPlaceholderImage && <img src={ImagePlaeholder} alt="" />
          )}
          {isSelected && (
            <DeleteIconContainer onClick={onDeleteElement}>
              <IoIosClose size={20} />
            </DeleteIconContainer>
          )}
        </CurrentImageContainer>
      </StyledResizable>
    </DraggableItem>
  );
};

export default ImageNode;
