import { IconType } from "react-icons";
import { TbHandClick } from "react-icons/tb";
import {
  AiOutlineAppstore,
  AiOutlineBug,
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineDatabase,
  AiOutlineDollar,
  AiOutlineEnvironment,
  AiOutlineFile,
  AiOutlineFontSize,
  AiOutlineNumber,
  AiOutlinePhone,
  AiOutlineUser,
  AiOutlineCheck,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { ReactComponent as SignatureIcon } from "../../resources/img/signatureIcon.svg";
import { FieldDataTypes } from "./Configurator.d";

export const getDataTypeIcon = (dataType: FieldDataTypes): IconType => {
  switch (dataType) {
    case FieldDataTypes.FILE:
      return AiOutlineFile;
    case FieldDataTypes.DATE:
      return AiOutlineCalendar;
    case FieldDataTypes.TIME:
      return AiOutlineClockCircle;
    case FieldDataTypes.SELECT:
      return TbHandClick;
    case FieldDataTypes.CURRENCY:
      return AiOutlineDollar;
    case FieldDataTypes.NUMBER:
      return AiOutlineNumber;
    case FieldDataTypes.PHONE_NUMBER:
      return AiOutlinePhone;
    case FieldDataTypes.USERS_SELECT:
      return AiOutlineUser;
    case FieldDataTypes.MULTI_SELECT:
      return AiOutlineAppstore;
    case FieldDataTypes.STRING:
      return AiOutlineFontSize;
    case FieldDataTypes.LOCATION:
      return AiOutlineEnvironment;
    case FieldDataTypes.VARDB_ROW:
      return AiOutlineDatabase;
    case FieldDataTypes.CHECKLIST:
      return AiOutlineCheck;
    case FieldDataTypes.PDF_AUTOGENERATED:
      return AiOutlineFilePdf;
    case FieldDataTypes.SIGNATURE:
      // @ts-ignore
      return SignatureIcon;
    default:
      return AiOutlineBug;
  }
};
