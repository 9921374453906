import React, { useEffect } from "react";
import styled from "styled-components";
import { PageView } from "../../analytics";

const Content = styled.div`
  color: black;
  padding: 5vw 10vw;
  font-family: Gotham-Book;
`;

const Title = styled.h2`
  text-align: center;
  font-family: Gotham-Bold;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-family: Gotham-Bold;
  font-size: 1.3em;
`;

const P = styled.p`
  text-align: justify;
  font-size: 1.1em;
`;
const PList = styled.p`
  text-align: justify;
  margin: 0;
`;
const Link = styled.a``;

const Bold = styled.span`
  font-family: Gotham-Bold;
`;

const List = styled.ul`
  font-size: 1.1em;
`;

const ListNumbered = styled.ol`
  font-size: 1.1em;
`;

const ListItem = styled.li``;

const Terms = () => {
  useEffect(() => {
    PageView("/terms");
  }, []);

  return (
    <Content>
      <Title>Términos y Condiciones Geest</Title>
      <P>
        Los presentes términos y condiciones son aplicables en todas sus
        plataformas: aplicación móvil para sistemas operativos iOS, Android,
        panel web, así como cualquier funcionalidad en el sitio web:{" "}
        <Link>https://app.geest.app</Link> o cualquier tipo de dominio o
        subdominio u otro servicio que se le pueda prestar al usuario o a las
        instituciones licenciantes.
      </P>
      <P>
        Estos Términos y Condiciones, así como la Política de Privacidad, han
        sido redactados y aprobados por <Bold>GEEST</Bold> y permanecerán
        vigentes hasta el momento en que sean sustituidas por otras en nuestro
        sitio web.
      </P>
      <P>
        La utilización, consulta y acceso de cualquiera de las plataformas
        conlleva a la aceptación plena y sin reservas de todos y cada uno de los
        términos y condiciones generales, desde el momento mismo en que el
        usuario acceda. Estas condiciones constituyen un acuerdo completo,
        formal, y único, entre el usuario y <Bold>GEEST</Bold> que prevalece y
        reemplaza a otras comunicaciones, acuerdos, o propuestas verbales o
        escritas.
      </P>
      <Subtitle>Obligaciones de los usuarios y condiciones de acceso.</Subtitle>
      <P>
        Los servicios pueden contener correos electrónicos, noticias de grupo,
        foros, comunidades, calendarios, fotografías, archivo de expedientes y/u
        otros mensajes o comunicaciones diseñadas para la comunicación con
        terceros. El usuario acepta utilizar estos servicios únicamente para
        enviar, publicar y recibir mensajes y materiales que no vayan contra los
        valores de <Bold>GEEST</Bold>.
      </P>
      <P>
        Los usuarios se obligan a hacer buen uso de los sitios, así como de sus
        contenidos, respetando la normativa nacional vigente, las buenas
        costumbres y el orden público, comprometiéndose en todo momento a no
        causar daños a <Bold>GEEST</Bold> ni a ningún tercero. A tal efecto, el
        usuario se abstendrá de utilizar cualquiera de los servicios con fines o
        efectos ilícitos, prohibidos en los presentes Términos y Condiciones,
        lesivos de los derechos e intereses de terceros, o que de cualquier
        forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la
        normal utilización de los servicios, funcionamiento de cualquier
        programa, documentos y toda clase de contenidos en y desde cualquier
        empresa informático o de telecomunicaciones. <Bold>GEEST</Bold> se
        deslinda de todo tipo de información que pueda ser publicada, construida
        o realizada por los usuarios. Por otro lado, GEEST se reserva los
        derechos de utilizar los flujos y plantillas que sean diseñados dentro
        de la plataforma para mejorar su servicio. El usuario no puede copiar,
        mostrar, distribuir, modificar, publicar, reproducir, almacenar o
        transmitir a terceros ajenos al servicio, crear obras derivadas, vender
        o licenciar todo o parte de las plantillas y diseños de{" "}
        <Bold>GEEST</Bold>.
      </P>
      <P>
        Solo puede tener acceso a los servicios y utilizarlos con propósitos
        legítimos los titulares de las cuentas ya que son de uso personal, de
        este modo los usuarios no se encuentran facultados para ceder los datos
        de validación para el acceso a la plataforma. El usuario es el único
        responsable del conocimiento y cumplimiento de las leyes, normativas y
        reglamentos relativos al uso del servicio. El usuario se compromete a
        no:
      </P>
      <List>
        <ListItem>
          <PList>
            Utilizar la información de <Bold>GEEST</Bold> para alentar conductas
            que puedan constituir la comisión de un delito o dar lugar a
            responsabilidad civil, o de otro modo incumplir cualquier
            legislación o normativa local, estatal, federal o internacional,
            incluido, a título enunciativo.
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Subir, publicar, enviar por correo electrónico o transferir por
            otros medios contenido para el que no tenga los derechos de
            transmisión.
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Subir, publicar, enviar por correo electrónico o transmitir por
            otros medios contenido que infrinja patentes, marcas registradas,
            secretos comerciales, derechos de autor u otros derechos de
            propiedad de terceros.
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Modificar, dañar o eliminar contenido u otras comunicaciones que no
            sean de su propiedad, o bien interferir en la capacidad de otros a
            acceder a los servicios de <Bold>GEEST</Bold>.
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Subir, publicar, enviar por correo electrónico o transmitir por
            otros medios publicidad, materiales promocionales u otras formas de
            abordar a los demás usuarios que no estén autorizadas.
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Interferir o interrumpir los servicios, los servidores o las redes
            conectadas al servicio <Bold>GEEST</Bold>.
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Utilizar la cuenta de un tercero sin su autorización con fines
            ilícitos que puedan perjudicar a <Bold>GEEST</Bold>, a la
            instituciones licenciantes o terceros.
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Reproducir, duplicar, copiar, usar, distribuir, vender, revender o
            explotar de la forma que fuere y con fines comerciales partes de{" "}
            <Bold>GEEST</Bold>.
          </PList>
        </ListItem>
      </List>
      <P>
        De no cumplir con estos parámetros, <Bold>GEEST</Bold> se reserva el
        derecho de dar de baja a los usuarios por cualquier motivo y en
        cualquier momento y se dispensa a nuestros dueños, directivos, empleados
        y agentes de cualquier demanda o daños, conocidos o desconocidos,
        derivados de cualquier tipo de mal uso de <Bold>GEEST</Bold>.
      </P>
      <P>
        <Bold>GEEST</Bold> se reserva el derecho de modificar los términos y
        condiciones generales en cualquier momento, notificando al usuario la
        nueva versión con las modificaciones mediante la publicación en la
        página web.
      </P>
      <Subtitle>Verificación de la identidad.</Subtitle>
      <P>
        No asumimos ninguna responsabilidad por la no confirmación de identidad
        de ningún usuario. Sin perjuicio de lo anterior, a efectos de
        transparencia y prevención de fraudes, y en la medida permitidas por la
        ley recolectamos información sobre el dispositivo que usas para
        interactuar con nuestras plataformas la cual puede incluir direcciones
        IP, horarios de accesos, fallas en la conexión con nuestras plataformas,
        sistema operativo que usas, ubicación y tipo de navegador.
      </P>
      <Subtitle>Derechos de Autor.</Subtitle>
      <P>
        El uso y acceso a <Bold>GEEST</Bold> no concede, otorga, o transfiere
        algún tipo de derecho, licencia o autorización de uso o disposición
        sobre los derechos de propiedad intelectual. Por tanto, queda prohibida
        la reproducción total o parcial de los contenidos protegidos por la ley
        de propiedad intelectual, así como el uso comercial de los contenidos de{" "}
        <Bold>GEEST</Bold>, su venta, distribución a terceros o su modificación.
        Cualquier otro uso requiere autorización previa, escrita y explícita por
        los derechos de autor que la Ley otorga.
      </P>
      <P>
        Todo el contenido de este Sitio y demás presentaciones, incluido el
        diseño, las imágenes, los programas, el texto y los videos son propiedad
        de <Bold>GEEST</Bold> y está protegido por derechos de autor y otras
        leyes de propiedad intelectual.
      </P>
      <P>
        No puede copiar, mostrar, distribuir, modificar, publicar, reproducir,
        almacenar, transmitir, crear obras derivadas, vender o licenciar todo o
        parte del contenido de <Bold>GEEST</Bold>.
      </P>
      <P>
        Cualquier pregunta sobre si un uso particular está autorizado y
        cualquier solicitud de permiso para publicar, reproducir, distribuir,
        mostrar o realizar trabajos derivados de cualquier contenido debe
        dirigirse a: <Link>atencion@geest.app</Link>
      </P>
      <Subtitle>Términos y condiciones de pago</Subtitle>
      <P>
        Los pagos de los servicios y productos de <Bold>GEEST</Bold> se
        realizarán con tarjeta de crédito o débito. <Bold>GEEST</Bold> se
        reserva el derecho de realizar modificaciones de precios o
        características de planes de suscripción, a suspender o descontinuar el
        servicio o partes del mismo en cualquier momento y sin previo aviso a
        los usuarios.
      </P>
      <P>Además, tus datos proporcionados son tratados con confidencialidad.</P>
      <ListNumbered>
        <ListItem>
          <PList>
            En el caso de cualquier cargo generado en tu tarjeta de crédito o
            débito que no sea reconocido por el titular de la tarjeta o socio
            del <Bold>GEEST</Bold>, deberás de solicitar su aclaración por
            escrito dentro de los 10 días naturales después de haberse realizado
            el cargo, anexando copia de la identificación oficial y de la
            tarjeta. Esta solicitud deberás de enviarla al correo electrónico:{" "}
            <Link>atencion@geest.app</Link>{" "}
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            De no existir ninguna inconformidad al término de este tiempo, se
            dará como válido el cargo realizado a la tarjeta de crédito o
            débito, por lo cual <Bold>GEEST</Bold> ya no aceptará ninguna
            solicitud de aclaración después de este tiempo, sin excepción.
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Cualquier solicitud de aclaración tendrá una respuesta no mayor a 30
            días hábiles.
          </PList>
        </ListItem>
      </ListNumbered>
      <Subtitle>Limitaciones de garantía y responsabilidad.</Subtitle>
      <P>
        Este apartado establece los límites de responsabilidad de{" "}
        <Bold>GEEST</Bold> y de sus asociados, directivos, consejeros,
        empleados, agentes, representantes, socios y licenciatarios. El usuario
        es responsable del uso que haga de los servicios o del contenido y del
        acceso a estos.{" "}
        <Bold>
          Usted entiende y acepta que los servicios se le proporcionan según la
          capacidad de GEEST y no ofrece ningún tipo de garantía o seguro por
          poca satisfacción del usuario.
        </Bold>{" "}
        Intentamos mantener <Bold>GEEST</Bold> en funcionamiento, sin errores y
        seguro, pero usted lo utiliza bajo su propia responsabilidad.
      </P>
      <P>
        Proporcionamos <Bold>GEEST</Bold> tal cual, sin garantía alguna. No
        garantizamos que <Bold>GEEST</Bold> sea siempre seguro o esté libre de
        errores, ni que funcione siempre sin interrupciones, retrasos o
        imperfecciones. <Bold>GEEST</Bold> no se responsabiliza de las acciones,
        el contenido, la información o los datos de terceros, y por la presente
        usted dispensa a nuestros directivos, empleados y agentes de cualquier
        demanda o daños, conocidos o desconocidos, derivados de cualquier
        demanda que tenga interpuesta contra terceros.
      </P>
      <P>
        <Bold>GEEST</Bold> no se responsabiliza por ningún tema que dependa de
        un tercero vinculado a nuestros servicios. Favor de revisar
        cuidadosamente las políticas de terceros y asegurarse de su
        entendimiento antes de participar en cualquier transacción. Quejas,
        reclamos, inquietudes o preguntas con respecto a productos de terceros
        deben ser dirigidas a la tercera parte.
      </P>
      <Subtitle> Legislación aplicable y jurisdicción competente.</Subtitle>
      <P>
        En caso de controversia derivada de la interpretación o cumplimiento de
        los presentes Términos y Condiciones de uso, de las Políticas de
        Privacidad o de cualquier otro documento relevante del software, el
        usuario está de acuerdo en que serán aplicables las Leyes Federales de
        los Estados Unidos Mexicanos y competentes los Tribunales del Estado de
        Jalisco, renunciando expresamente a cualquier otro fuero o jurisdicción
        que pudiera corresponderle en razón de su domicilio presente o futuro.
      </P>
      <P>
        Al leer, navegar o usar <Bold>GEEST</Bold> el usuario declara que ha
        leído y acepta las condiciones de uso de nuestra POLÍTICA DE PRIVACIDAD
        publicada en: <Link>https://app.geest.app</Link> y acepta que la procese
        su información personal para los propósitos mencionados en dicha
        política y en los presentes términos y condiciones.
      </P>
    </Content>
  );
};

export default Terms;
