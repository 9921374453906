import ButtonsV2 from "./pages/ButtonsV2";
import Colors from "./pages/Colors";
import DynamicInput from "./pages/DynamicInput";
import MultiModalPage from "./pages/MultiModalPage";
import Tags from "./pages/Tags";
import SelectInputPage from "./pages/SelectInput";
import TypographyPage from "./pages/Typography";
import Report from "./pages/Report";
import TextEditor from "./pages/TextEditor";
import PDFEditor from "./pages/PDFEditor";
import SignGeneratorView from "./pages/SignGeneratorView";
import StatsTable from "./pages/StatsTable";
import Sockets from "./pages/Sockets";
import VirtualizedTable from "./pages/VirtualizedTable";

interface Route {
  path: string;
  name: string;
  page: React.FC;
}

const playgroundRoutes: Route[] = [
  {
    path: "/colors",
    name: "Colors",
    page: Colors,
  },
  {
    path: "/typography",
    name: "Typography",
    page: TypographyPage,
  },
  {
    path: "/buttons",
    name: "Buttons",
    page: ButtonsV2,
  },
  {
    path: "/multi-modal",
    name: "Multi modal",
    page: MultiModalPage,
  },
  {
    path: "/tags",
    name: "Tags",
    page: Tags,
  },
  {
    path: "/select",
    name: "Select",
    page: SelectInputPage,
  },
  {
    path: "/dynamic-input",
    name: "Dynamic Input",
    page: DynamicInput,
  },
  {
    path: "/graphs",
    name: "Graphs",
    page: Report,
  },
  {
    path: "/text-editor",
    name: "Text Editor",
    page: TextEditor,
  },
  {
    path: "/pdf-editor",
    name: "PDF Editor",
    page: PDFEditor,
  },
  {
    path: "/sign-generator",
    name: "Sign Generator View",
    page: SignGeneratorView,
  },
  {
    path: "/stats-table",
    name: "Stats Table Popop test",
    page: StatsTable,
  },
  {
    path: "/sockets",
    name: "Sockets",
    page: Sockets,
  },
  {
    path: "/virtualized-table",
    name: "Virtualized Table",
    page: VirtualizedTable,
  },
];

export default playgroundRoutes;
