export const Languages = {
  ENG: {
    Status: "State",
    StartedBy: "Started by",
    StartDateLabel: "Started at",
    Duration: "Duration",
    CodeLabel: "Code",
    PriorityLabel: "Priority",
    ChatsButtonLabel: "Chats",
    AlertsButtonLabel: "Alerts",
    FluxButtonLabel: "Open flux",
    NoPriorityLabel: "No prority",
    FormFieldsButtonLabel: "Form fields",
    CancelFollowUpLabel: "Cancel process",
    DeleteFollowUpLabel: "Delete process",
    NewLabel: "New",
    StartedLabel: "Started",
    StuckLabel: "Stuck",
    WaitingLabel: "Waiting to start",
    CompletedLabel: "Completed",
    CanceledLabel: "Canceled",
    SuccessfulUpdateForm: "Successful update",
  },
  ESP: {
    Status: "Estatus",
    StartedBy: "Inició el proceso",
    StartDateLabel: "Inicio",
    Duration: "Duración",
    CodeLabel: "Folio",
    PriorityLabel: "Prioridad",
    ChatsButtonLabel: "Chats",
    AlertsButtonLabel: "Alertas",
    FluxButtonLabel: "Ver flujo",
    NoPriorityLabel: "Sin prioridad",
    FormFieldsButtonLabel: "Campos de formulario",
    CancelFollowUpLabel: "Cancelar seguimiento",
    DeleteFollowUpLabel: "Eliminar seguimiento",
    NewLabel: "Nueva",
    StartedLabel: "Iniciado",
    StuckLabel: "Vencido",
    WaitingLabel: "Por iniciar",
    CompletedLabel: "Terminada",
    CanceledLabel: "Cancelada",
    SuccessfulUpdateForm: "Actualización exitosa",
  },
};
