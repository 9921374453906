export const Languages = {
  ENG: {
    AddYourGraph: "Add your graph template",
    AddGraphSubtitle:
      "Choose one of these customizable templates to fit your needs",
  },
  ESP: {
    AddYourGraph: "Agrega tu plantilla de gráfica",
    AddGraphSubtitle:
      "Elige alguna de estas plantillas personalizables para ajustarlas a tus necesidades",
  },
};
