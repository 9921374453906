import React from "react";
import { StopProcessModalProps } from "./StopProcessModal.d";
import Modal from "../../../../components/Modal";
import { Button } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";
import styled from "styled-components";

const Title = styled.div`
  width: 352px;
  font-size: 14px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  text-align: center;
`;

const Content = styled.div`
  width: 394px;
  font-size: 14px;
  color: rgba(130, 141, 158, 1);
  font-weight: 325;
  font-family: Gotham-Book;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonConteiner = styled.div`
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: center;
  display: flex;
`;

const StopProcessModal: React.FC<StopProcessModalProps> = ({
  selectedProcesses,
  onSuccess,
  onCancel,
  ProcessTitle,
  processes = [],
}) => {
  const [cancelProcessExecution, canceling] = useMutation<[]>({
    func: "Ver2-Reports-cpe",
    onSuccess: () => onSuccess(),
  });

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () =>
    cancelProcessExecution({
      args: { IdProcessExecution: selectedProcesses },
    });

  const getProcessTitle = (selectedProcesses: number[]) => {
    if (ProcessTitle) return ProcessTitle;

    let title = "";

    if (selectedProcesses.length === 1) {
      const process = processes.find(
        (row) => row.IdProcessExecution === selectedProcesses[0]
      );
      if (process) {
        Object.keys(process.Cells).forEach((key: string) => {
          if (key.includes("ExecutionName")) {
            // @ts-ignore
            const cell = process.Cells[key];
            title = cell.CellData.String_Value;
          }
        });
      }
    }
    return title;
  };

  return (
    <Modal
      visible
      title={`Detener${
        selectedProcesses.length > 1 ? ` ${selectedProcesses.length}` : ""
      } seguimiento${
        selectedProcesses.length > 1
          ? "s"
          : ` ${getProcessTitle(selectedProcesses)}`
      }`}
      width="443px"
      onCancel={onCancel}
      bodyStyle={{
        borderRadius: "6px",
      }}
      contentStyles={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
      }}
    >
      <Title>¿Seguro que deseas detener este seguimiento?</Title>
      <Content>
        Todas las tareas actuales y por iniciar del seguimiento desaparecerán de
        los pendientes, pero la información recabada en las bases de datos hasta
        el momento seguirá registrada
      </Content>
      <ButtonConteiner>
        <Button type="secondary" onClick={onCancel} loading={canceling}>
          Cancelar
        </Button>
        <Button type="primary" danger onClick={onClick} loading={canceling}>
          Detener
        </Button>
      </ButtonConteiner>
    </Modal>
  );
};

export default StopProcessModal;
