import React, { useEffect, useState } from "react";
import { Typography } from "../../GeestUI";
import GeestLogo from "../../resources/img/LOGO_.svg";
import {
  LoadingContainer,
  LogoContainer,
  Logo,
  ContentContainer,
  RingsContainer,
  Ring,
  Textcontainer,
  ProgressBarContainer,
  ProgressBar,
  ProgressLabel,
} from "./Styles";

const { H1 } = Typography;

const Languages = {
  ENG: {
    LoadingLabel: "Loading",
  },
  ESP: {
    LoadingLabel: "Cargando",
  },
};

const { LoadingLabel } = Languages["ESP"];

interface LoadingProps {
  progressBar?: boolean;
  progress?: number;
  progressLabels?: { [key: number]: string };
  Label?: string;
  simple?: boolean;
}

const Loading: React.FC<LoadingProps> = ({
  progressBar,
  progress,
  progressLabels,
  Label = LoadingLabel,
  simple = false,
}) => {
  const [LoadingStatus, setLoadingStatus] = useState<number>(3);
  useEffect(() => {
    const interval = setInterval(() => {
      if (LoadingStatus === 3) setLoadingStatus(0);
      else setLoadingStatus(LoadingStatus + 1);
    }, 300);
    return () => clearInterval(interval);
  }, [LoadingStatus]);

  const getPoints = () => {
    let res = "";
    for (let n = 0; n < LoadingStatus; n++) {
      res += ".";
    }
    return res;
  };

  return (
    <LoadingContainer>
      <LogoContainer>
        <Logo src={GeestLogo} alt="" />
      </LogoContainer>

      <ContentContainer>
        <RingsContainer>
          <Ring $rotate="right" $color="#0069B3" />
          <Ring
            $rotate="left"
            $color="#213B87"
            $dotPlacement="left: 4px"
            $delay="-1s"
          />
          <Ring
            $rotate="right"
            $color="#20a537"
            $position="absolute"
            $top="-22px"
            $delay="-3s"
          />
        </RingsContainer>
      </ContentContainer>
      {!progressBar && !simple && (
        <Textcontainer>
          <H1 mb="0">{Label}</H1>
          <span>{getPoints()}</span>
        </Textcontainer>
      )}
      {progressBar && !simple && (
        <ProgressBarContainer>
          <ProgressBar progress={progress} />
        </ProgressBarContainer>
      )}
      {progressLabels && !!progress && !simple && (
        <ProgressLabel>{progressLabels[progress]}</ProgressLabel>
      )}
    </LoadingContainer>
  );
};

export default Loading;
