import React, { useContext } from "react";
import { Tooltip } from "antd";
import { AiOutlineInfoCircle, AiOutlineMessage } from "react-icons/ai";
import { GoTriangleDown } from "react-icons/go";
import { DropdownButton, DropdownButtonLabel } from "../styles";
import EditChatsDropdown from "./EditChatsDropdown";
import { ConfiguratorContext } from "./../../Configurator";

interface EditChatsProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditChats: React.FC<EditChatsProps> = ({ open, setOpen }) => {
  const { refreshProcessInformation } = useContext(ConfiguratorContext);

  const handleOnSetOpen = (value: boolean) => {
    setOpen(value);
    if (!value) refreshProcessInformation();
  };

  return (
    <EditChatsDropdown open={open} setOpen={handleOnSetOpen}>
      <DropdownButton align="middle" open={open}>
        <Tooltip
          title="Agrega los canales que quieres que se muestren en el chat de tu proceso y elige qué roles del equipo acceden"
          placement="bottomLeft"
        >
          <AiOutlineInfoCircle
            size={16}
            color="#48505E"
            style={{ marginRight: "3px" }}
          />
        </Tooltip>
        <DropdownButtonLabel>Chats</DropdownButtonLabel>
        <AiOutlineMessage size={24} color="#48505e" />
        <GoTriangleDown
          size={20}
          color="#48505e"
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
            transition: "all 0.35s ease",
          }}
        />
      </DropdownButton>
    </EditChatsDropdown>
  );
};

export default EditChats;
