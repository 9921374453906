import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../../../hooks";
import { ViewContentAction, ActionFormTitle } from "./styles";
import GeestSelect from "../../../../../../GeestUI/GeestSelect";
import ViewHeader from "./ViewHeader";
import {
  ActionProps,
  sendNotificationActionConfigResponse,
} from "../AutomationConfig.d";
import sendNotifiactionActionIcon from "../../../../../../resources/img/notificationActionIcon.svg";
import { useParams } from "react-router-dom";
import User from "../../../../../../components/User";
import GeestTextEditor from "../../../../../../components/GeestTextEditor";
import { EditorState, convertFromRaw } from "draft-js";
import { editorStateRaw } from "../../../../../../components/GeestTextEditor/Utils/Editor";
import DataTypeIcons from "../../../../../../components/DataTypeIcons";
import { Row } from "antd";
import { Action } from "../EditAction.d";

const SendNotificationActionView: React.FC<ActionProps> = ({
  reloadHome,
  IdNode,
  selectedAction,
  setSelectedAction,
  actionOptions,
  saveAction,
}) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const [title, setTitle] = useState<EditorState>(EditorState.createEmpty());
  const [message, setMessage] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (selectedAction?.NotificationTitle) {
      setTitle(
        EditorState.createWithContent(
          convertFromRaw(selectedAction.NotificationTitle)
        )
      );
    } else {
      setTitle(EditorState.createEmpty());
    }
    if (selectedAction?.Message) {
      setMessage(
        EditorState.createWithContent(convertFromRaw(selectedAction.Message))
      );
    } else {
      setMessage(EditorState.createEmpty());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction?.IdAction]);

  const { data, loading } = useFetch<sendNotificationActionConfigResponse>({
    func: "Ver2-Configurator-gaco",
    args: {
      IdTeam,
      IdProcessTemplate,
      IdNode,
      Action: selectedAction?.Action,
    },
  });

  const sendAction = (action: Action) => {
    if (
      !!action.Message &&
      !!action.Destination &&
      !!action.NotificationTitle
    ) {
      saveAction(action);
    }
  };

  const userFieldOptions =
    data?.Fields.filter(({ DataType }) => DataType === "users_select").map(
      ({ Value, Label, DataType }) => ({
        label: (
          <Row align="middle">
            <Row
              align="middle"
              justify="center"
              style={{
                width: "26px",
                height: "26px",
                display: "flex",
              }}
              wrap={false}
            >
              <img
                style={{
                  width: "14px",
                  height: "14px",
                  ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
                }}
                alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
                src={DataTypeIcons[DataType?.toLowerCase()].icon}
              />
            </Row>
            <div
              style={{
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {Label}
            </div>
          </Row>
        ),
        value: `Field-${Value}`,
        searchableValues: Label,
      })
    ) || [];

  const userOptions =
    (() =>
      data?.Users.map((usr, index) => {
        return {
          value: `User-${usr.IdUser}`,
          searchableValues: `${usr.FirstName},${usr.LastName},${usr.Email}`,
          label: <User user={usr} size="middle" placement="bottom" />,
          separator:
            index === data?.Users.length - 1 && userFieldOptions.length > 0,
        };
      }))() || [];

  const transformedOptions = [...userOptions, ...userFieldOptions];

  const onChangeDesitnation = (val: string) => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.Destination = val;
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const fieldEditorOrigin =
    data?.Fields.map(({ Value, Label, DataType, DataOrigin }) => ({
      IdField: Value,
      Label,
      DataType,
      DataOrigin,
    })) || [];

  const onChangeTitle = () => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.NotificationTitle = editorStateRaw(title);
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  const onChangeMessage = () => {
    if (selectedAction) {
      let actionAux = { ...selectedAction };
      actionAux.Message = editorStateRaw(message);
      setSelectedAction(actionAux);
      sendAction(actionAux);
    }
  };

  return (
    <ViewContentAction>
      <ViewHeader
        reloadHome={reloadHome}
        IdNode={IdNode}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
        actionOptions={actionOptions}
        icon={sendNotifiactionActionIcon}
      />
      <div>
        <ActionFormTitle>Título de la notificacion</ActionFormTitle>
        {!loading && (
          <GeestTextEditor
            editorState={title}
            onEditorStateChange={setTitle}
            onBlur={onChangeTitle}
            editorBorder
            fieldEditorOrigin={fieldEditorOrigin}
            toolbarConfig={{
              field: true,
              inline: false,
              fontSize: false,
              fontFamily: false,
              list: false,
              textalign: false,
              colorPicker: false,
              link: false,
            }}
            maxLength={100}
            oneBlock
            toolbarExtraParams={{ fieldOptions: { largeButton: true } }}
            required={!selectedAction.NotificationTitle}
          />
        )}
      </div>
      <div>
        <ActionFormTitle>Mensaje</ActionFormTitle>
        {!loading && (
          <GeestTextEditor
            editorState={message}
            onEditorStateChange={setMessage}
            onBlur={onChangeMessage}
            editorBorder
            fieldEditorOrigin={fieldEditorOrigin}
            toolbarConfig={{
              field: true,
              inline: false,
              fontSize: false,
              fontFamily: false,
              list: false,
              textalign: false,
              colorPicker: false,
              link: false,
            }}
            maxLength={200}
            oneBlock
            toolbarExtraParams={{ fieldOptions: { largeButton: true } }}
            required={!selectedAction.Message}
          />
        )}
      </div>
      <div>
        <ActionFormTitle>Destinatarios</ActionFormTitle>
        <GeestSelect
          options={transformedOptions}
          onChange={onChangeDesitnation}
          multiselect
          value={selectedAction?.Destination || ""}
          showTags
          $listMaxHeight="134px"
          required
        />
      </div>
    </ViewContentAction>
  );
};

export default SendNotificationActionView;
