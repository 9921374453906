import React, { useEffect } from "react";
import styled from "styled-components";
import { PageView } from "../../analytics";

const Content = styled.div`
  color: black;
  padding: 5vw 10vw;
  font-family: Gotham-Book;
`;

const Title = styled.h2`
  text-align: center;
  font-family: Gotham-Bold;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-family: Gotham-Bold;
  font-size: 1.3em;
`;

const P = styled.p`
  text-align: justify;
  font-size: 1.1em;
`;
const PList = styled.p`
  text-align: justify;
  margin: 0;
`;
const Link = styled.a``;

const Italic = styled.span`
  font-family: Gotham-BookItalic;
`;
const List = styled.ul`
  font-size: 1.1em;
`;

const ListItem = styled.li``;

const Privacy = () => {
  useEffect(() => {
    PageView("/privacy");
  }, []);

  return (
    <Content>
      <Title>Aviso de Privacidad Geest</Title>
      <Subtitle>
        Dirección de correo electrónico en la que puede contactarse con nuestro
        líder de privacidad.
      </Subtitle>
      <P>
        Para todo lo relacionado con el tratamiento y protección de sus datos
        personales, usted podrá contactarse con nuestro líder de privacidad
        enviando un correo electrónico a la siguiente dirección:{" "}
        <Link>atencion@geest.app.</Link>
      </P>
      <Subtitle>
        Finalidades para las que utilizaremos sus datos personales.
      </Subtitle>
      <P>
        Con “datos personales” se hace referencia a cualquier información que
        identifique o que pueda ser usada para contactar o localizar a quien la
        información pertenece. La información personal que recolectamos estará
        sujeta a esta política de privacidad, y será modificada periódicamente.
        Su información personal será utilizada para las siguientes finalidades,
        las cuales son necesarias con relación a su contratación:
      </P>
      <List>
        <ListItem>
          Validar la documentación e información proporcionada por usted.
        </ListItem>
        <ListItem>
          Gestionar su alta en nuestros sistemas e integrar su expediente.
        </ListItem>
        <ListItem>
          Contactarlo con motivo del desarrollo y gestión de sus servicios.
        </ListItem>
        <ListItem>
          Efectuar los cobros correspondientes a nuestros servicios.
        </ListItem>
        <ListItem>Cumplir de manera eficaz el servicio contratado.</ListItem>
        <ListItem>Mejorar la calidad y el rendimiento del servicio.</ListItem>
      </List>
      <P>
        Si usted no desea que sus datos personales sean tratados para las
        finalidades señaladas, puede negarnos su consentimiento desde este
        momento enviando un correo electrónico a nuestro líder de privacidad, en
        el que especifique la o las finalidades secundarias para las que no
        desea que utilicemos sus datos personales. Su negativa no será motivo ni
        afectará los derechos y obligaciones entre usted y esta empresa.
      </P>
      <Subtitle>Datos personales que se recabarán.</Subtitle>
      <P>
        Para llevar a cabo las finalidades del servicio, utilizaremos las
        siguientes categorías de datos personales, las cuales serán solicitadas
        al usuario, quien voluntariamente nos los provee cuando accede a las
        características que los requieran:{" "}
      </P>
      <List>
        <ListItem>Identificación y contacto.</ListItem>
        <ListItem>Referencias laborales.</ListItem>
        <ListItem>
          Financieros y de pago.
          <PList>
            Utilizamos los servicios de un tercero para procesar los pagos
            realizados en nuestro servicio, almacenamos únicamente información
            para identificar los métodos de pago utilizados por el usuario: los
            últimos cuatro dígitos de la tarjeta bancaria y su marca (VISA,
            MASTERCARD, etc.). Por lo tanto, la información de números de
            tarjeta y autenticación bancaria se provee directamente a{" "}
            <Italic>Stripe</Italic>, quién maneja la información de usuario de
            acuerdo a sus políticas consultables en{" "}
            <Link>https://stripe.com/us/privacy</Link>
            {"."}
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Información general y organigrama de su empresa de trabajo.
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Estructura de los procesos (plantillas) e información metodológica
            de trabajo cargada al sistema.
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Machotes de archivos cargados a las plantillas de procesos.
          </PList>
        </ListItem>
        <ListItem>
          <PList>
            Credenciales de conexión y autenticación a sistemas internos o
            cuentas de integraciones autorizadas. A su vez, se accederá a la
            información a la que el usuario haya otorgado consentimiento para
            ser compartida con GEEST desde estas integraciones.
          </PList>
        </ListItem>
      </List>
      <Subtitle>Información del dispositivo</Subtitle>
      <P>
        Se recaba información del dispositivo (computadora, tableta, teléfono
        móvil, etcétera) como tipo de conexión, configuración, navegador,
        horarios de acceso o dirección IP cuando se instala, accede o actualiza
        a nuestros servicios con la finalidad de brindar un mejor servicio para
        nuestros usuarios.
      </P>
      <Subtitle>Permisos del dispositivo.</Subtitle>
      <P>
        Para llevar a cabo las finalidades del servicio, se requiere acceso a
        los siguientes permisos de su dispositivo móvil y/o web a partir del
        cual se acceda al servicio:
      </P>
      <List>
        <ListItem>Ubicación.</ListItem>
        <ListItem>Aplicación de teléfono.</ListItem>
        <ListItem>Almacenamiento del dispositivo.</ListItem>
        <ListItem>Recepción de notificaciones.</ListItem>
      </List>
      <Subtitle>Transferencia de sus datos personales.</Subtitle>
      <P>
        Sus datos personales de identificación, contacto, académicos y
        laborales, podrán ser transferidos a las siguientes personas físicas o
        morales, sin que legalmente se requiera su consentimiento:{" "}
      </P>
      <List>
        <ListItem>
          <PList>
            Autoridades a las que debamos hacerlo en virtud de una ley, con la
            finalidad de cumplir con la normatividad correspondiente.
          </PList>
        </ListItem>
      </List>
      <Subtitle>
        Medidas de seguridad para proteger su información personal.
      </Subtitle>
      <P>
        Su información personal será resguardada bajo estricta confidencialidad,
        y para prevenir razonablemente el uso o divulgación indebida de la
        misma, hemos implementado medidas de seguridad físicas, técnicas y
        administrativas de conformidad con la normatividad aplicable. En
        particular, contamos con un líder de privacidad, la presente política de
        privacidad, cursos de capacitación a nuestros empleados, acceso
        restringido a información personal sólo a usuarios autorizados y
        cláusulas contractuales. La seguridad de sus datos es prioridad para
        nosotros, sin embargo, los medios de almacenamiento digitales o internet
        no son 100% seguros, por lo cual no podemos garantizar la total
        seguridad de su información.
      </P>
      <Subtitle>Derechos ARCO y revocación de su consentimiento.</Subtitle>
      <P>
        En los términos permitidos por la normatividad aplicable, es su derecho
        conocer qué datos personales tenemos de usted, para qué los utilizamos y
        las condiciones del uso que les damos (Acceso). Asimismo, es su derecho
        solicitar la corrección de su información personal en caso de que esté
        desactualizada, sea inexacta o incompleta (Rectificación); que la
        eliminemos de nuestros registros o bases de datos cuando considere que
        la misma no está siendo utilizada conforme a las disposiciones previstas
        en la normatividad aplicable (Cancelación); y como oponerse al uso de
        sus datos personales para fines específicos (Oposición). Estos derechos
        se conocen como derechos ARCO.
      </P>
      <P>
        Asimismo, usted tiene el derecho de revocar en cualquier momento el
        consentimiento que en su caso nos haya otorgado para el tratamiento de
        sus datos personales, salvo que no sea procedente en términos de la
        normatividad aplicable, lo cual haríamos de su conocimiento en nuestra
        respuesta a su solicitud de revocación.
      </P>
      <P>
        Para conocer el procedimiento, requisitos y plazos para el ejercicio de
        los derechos ARCO, así como del derecho de revocación, le agradeceremos
        se ponga en contacto con nuestro líder de privacidad a la mencionada
        dirección de correo electrónico. Si desea ejercer alguno o algunos de
        estos derechos, deberá presentar la solicitud respectiva a nuestro líder
        de privacidad vía correo electrónico, quien dará trámite a la misma y
        atenderá cualquier comentario, duda o queja que pudiera tener respecto
        al tratamiento de su información personal.
      </P>
      <P>
        Nuestro líder de privacidad velará en todo momento por la protección de
        su información personal en cumplimiento con la normatividad aplicable.
        Asimismo, le informamos que el Instituto Federal de Acceso a la
        Información y Protección de Datos es la autoridad encargada de vigilar
        por la debida observancia de las disposiciones legales en materia de
        protección de datos personales.
      </P>
      <Subtitle>
        Formas en que puede limitar el uso o divulgación de la información
        personal.
      </Subtitle>
      <P>
        Adicionalmente al procedimiento contemplado en la sección anterior,
        usted puede limitar el uso o divulgación de su información personal,
        enviando un correo electrónico a nuestro líder de privacidad a la
        dirección arriba indicada, solicitando su inscripción al “Listado de
        Exclusión Publicitaria”, con el objeto de asegurarse que su información
        no será utilizada para fines distintos a los estipulados en este aviso
        de privacidad.
      </P>
      <Subtitle>Cambios al aviso de privacidad.</Subtitle>
      <P>
        Geest® podrá modificar y/o actualizar el presente aviso de privacidad,
        informando sobre cualquier modificación o actualización a través de
        nuestra página de internet: <Link>https://app.geest.app/</Link>
      </P>
      <Subtitle>Consentimiento.</Subtitle>
      <P>
        Por este medio y al utilizar los servicios de GEEST otorgo mi
        consentimiento para que mis datos personales sean tratados de
        conformidad con los términos del presente aviso de privacidad.
      </P>
    </Content>
  );
};

export default Privacy;
