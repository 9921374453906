const LOADING = "LOADING";

const NORMAL = "Normal";
const CHANGE_PLAN = "ChangePlan";
const CHANGE_PLAN_NORMAL_EDIT_PLAN = "ChangePlanNormalEditPlan";
const CHECKOUT = "Checkout";
const CHECKOUT_NORMAL_EDIT_PLAN = "CheckoutNormalEditPlan";
const SELECT_BENEFITS_NORMAL = "SelectBenefitsNormal";
const SUCCESSFUL = "Successful";
const SUCCESSFUL_EDIT_PLAN = "SuccessfulEditPlan";
const ERROR_PAYMENT_NOT_COMPLETED = "ErrorPaymentNotCompleted";
const ERROR_ACCOUNT_FREEZED = "ErrorAccountFreezed";
const SELECT_BENEFITS_FREEZED = "SelectBenefitsFreezed";
const SUCCESSFUL_BENEFITS_FITTED = "SuccessfulBenefitsFitted";
const CHANGE_PLAN_OUTDATED = "ChangePlanOutdated";
const SELECT_BENEFITS_OUDATED = "SelectBenefitsOutdated";
const CHECKOUT_OUTDATED = "CheckoutOutdated";
const CHECKOUT_CHANGE_USERS = "CheckoutChangeUsers";
const SUCCESSFUL_CHANGE_USERS = "SuccessfulChangeUsers";

const REQUEST_BONUS_TRIAL = "RequestBonusTrial";
const SUCCESSFUL_REQUEST_BONUS_TRIAL = "SuccessfulRequestBonusTrial";

const States = {
  LOADING,
  NORMAL,
  CHANGE_PLAN,
  CHANGE_PLAN_NORMAL_EDIT_PLAN,
  SELECT_BENEFITS_NORMAL,
  CHECKOUT,
  CHECKOUT_NORMAL_EDIT_PLAN,
  SUCCESSFUL,
  SUCCESSFUL_EDIT_PLAN,
  ERROR_PAYMENT_NOT_COMPLETED,
  ERROR_ACCOUNT_FREEZED,
  SELECT_BENEFITS_FREEZED,
  SUCCESSFUL_BENEFITS_FITTED,
  CHANGE_PLAN_OUTDATED,
  SELECT_BENEFITS_OUDATED,
  CHECKOUT_OUTDATED,
  CHECKOUT_CHANGE_USERS,
  SUCCESSFUL_CHANGE_USERS,
  REQUEST_BONUS_TRIAL,
  SUCCESSFUL_REQUEST_BONUS_TRIAL,
};

export default States;
