import React from "react";
import styled from "styled-components";
import { ReactComponent as CloudSyncOutlinedIcon } from "../../../resources/img/cloudSyncOutlineIcon.svg";
import { ReactComponent as CoudSuccessOutlinedIcon } from "../../../resources/img/cloudSuccessIcon.svg";
import Tooltip from "../../../components/Tooltip";

const BadgeContainer = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ $color }) => $color};
  font-family: Gotham-Bold;
  font-size: 14px;
`;

interface SavingDataStatusComponentProps {
  savingDataStatus: string;
}

const SavingDataStatusComponent: React.FC<SavingDataStatusComponentProps> = ({
  savingDataStatus,
}) => {
  if (savingDataStatus === "saving") {
    return (
      <BadgeContainer $color="#828d9e">
        <CloudSyncOutlinedIcon width="14px" height="14px" />
        Guardando cambios...
      </BadgeContainer>
    );
  }

  if (savingDataStatus === "saved") {
    return (
      <BadgeContainer $color="#1f9334">
        <CoudSuccessOutlinedIcon width="14px" height="14px" />
        Cambios guardados
      </BadgeContainer>
    );
  }

  if (savingDataStatus === "updated") {
    return (
      <BadgeContainer $color="#1f9334">
        <Tooltip title="Todos los cambios guardados">
          <CoudSuccessOutlinedIcon width="14px" height="14px" />
        </Tooltip>
      </BadgeContainer>
    );
  }

  return <BadgeContainer $color="transparent"></BadgeContainer>;
};

export default SavingDataStatusComponent;
