import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "antd";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import _ from "lodash";
import Bugsnag from "@bugsnag/js";
// Components
import Background from "../../components/Background/Background";
import SideBar from "./SideBar/SideBar";
import ChangePassword from "./SideBar/SettingsView/ChangePassword";
// Routes
import routes from "./../../routes";
// Context
import { SesionContext } from "../../config/State";
import { RedirectContext } from "../../config/Redirect";
// API
import server, { GeestInstance } from "../../api/server";
import { useLocalStore } from "../../hooks";
// Styled
import styled from "styled-components";
import { Languages } from "./Dictionary";
import IntroModal from "./IntroModal";
import ChangePasswordContext from "./ChangePasswordContext";
import { AlertContext } from "../../components/AppAlert/AppAlertContext";
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment-timezone";
import { GlobalHotKeys } from "react-hotkeys";
import useShortcuts from "../../hooks/useShortcuts";
import ShortcutList from "../../components/ShortcutList";
import { ExpandWrapper, OpenSidebarButton } from "./SideBar/Styles";
import { MessagesContext } from "../../components/AppMessages";
import SocketProvider from "../../config/socket";

import "moment/locale/es";
moment.locale("es", {
  monthsShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
});

const Status = {
  ENDED_FREE_CARDLESS_TRIAL: "ended_free_cardless_trial",
  ENDED_BONUS_TRIAL: "ended_bonus_trial",
  BLOCK_CAUSE_PAYMENT_DUE: "block_cause_payment_due",
  BLOCK_CAUSE_EXCEED_BENEFITS: "block_cause_exceed_benefits",
  CANCELED: "canceled",
};

const delayTime = "0.3s";

const SideBarContainer = styled(Col)`
  width: ${({ $showMenu }) => ($showMenu ? "242px" : "68px")};
  transition: width ${delayTime} ease;
`;

const BackgroundContainer = styled(Col)`
  flex: 1;
  transition: width ${delayTime} ease;
  overflow: auto;
  font-family: "Gotham-book";
`;

const Routes = routes.map((r, key) => {
  if (r.layout === "/home" && !r.disabled) {
    return <Route path={r.layout + r.path} component={r.component} key={key} />;
  } else {
    return null;
  }
});

const Content = React.memo(
  () => {
    return (
      <BackgroundContainer>
        <Background>
          <Switch>
            {Routes}
            <Redirect from="/home" to="/home/pendings" />
          </Switch>
        </Background>
      </BackgroundContainer>
    );
  },
  () => true
);

const HomeLayout = () => {
  const [Sesion, setSesion] = useContext(SesionContext);
  const [RedirectURL, setRedirectURL] = useContext(RedirectContext);
  const [showMenu, setShowMenu] = useLocalStore("showMenu", false);
  const [SettingsOpen, setSettingsOpen] = useState(false);
  const [OpenModal, setOpenModal] = useState("");
  const [Intro, setIntro] = useState(false);
  const { WelcomeMessage, SomethingWrong, LoadingLabel } = Languages["ESP"];
  const { setAlert } = useContext(AlertContext);
  const [Loading, setLoading] = useState(true);
  const history = useHistory();
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [keyMap, handlers] = useShortcuts("ALL", setShowShortcuts);
  const { showMessage } = useContext(MessagesContext);
  const [alertInfo, setAlertInfo] = useState("");

  const match = [
    !_.isEmpty(useRouteMatch("/home/pendings")),
    !_.isEmpty(useRouteMatch("/home/templates")),
    !_.isEmpty(useRouteMatch("/home/kanban")),
    !_.isEmpty(useRouteMatch("/home/reports")),
    !_.isEmpty(useRouteMatch("/home/dashboards")),
    !_.isEmpty(useRouteMatch("/home/marketplace")),
    !_.isEmpty(useRouteMatch("/home/statistics")),
  ];
  const showAlert =
    _.includes(match, true) &&
    [
      Status.ENDED_FREE_CARDLESS_TRIAL,
      Status.ENDED_BONUS_TRIAL,
      Status.BLOCK_CAUSE_PAYMENT_DUE,
      Status.BLOCK_CAUSE_EXCEED_BENEFITS,
      Status.CANCELED,
    ].includes(alertInfo);

  const Modal = {
    Change_Password: <ChangePassword />,
  };

  useEffect(() => {
    GeestInstance.defaults.headers.common["Authorization"] = Sesion.Auth;
    server("Ver2-Session-sl", Sesion.Id, {
      PushNotificationsToken: "",
      TimeZone: moment.tz.guess(),
    })
      .then((response) => {
        if (response.Request_Error) {
          showMessage(response.Request_Error, "error");
          GeestInstance.defaults.headers.common["Authorization"] = "";
          setSesion(null);
        } else {
          const { User, IdFavoriteTeam, TeamLogo, ShowSubscriptionStatus } =
            response.Data || {};
          if (!_.isEmpty(User)) {
            setIntro(Sesion.FirstTimeLogin);
            if (User.FirstTimeLogin) history.push("/home/templates");
            let TeamLogoUrl = "";
            if (TeamLogo) {
              TeamLogoUrl = TeamLogo;
            }
            showMessage(WelcomeMessage, "info");
            Bugsnag.setUser(User.Id.toString());
            setSesion({
              ...Sesion,
              ...User,
              FavoriteTeam: { IdFavoriteTeam, TeamLogoUrl },
            });
            if (RedirectURL) {
              history.push(RedirectURL);
              setRedirectURL(null);
            }
            if (ShowSubscriptionStatus?.Value) {
              setAlert(ShowSubscriptionStatus);
              setAlertInfo(ShowSubscriptionStatus.Value);
            }
          } else {
            showMessage(SomethingWrong, "error");
            GeestInstance.defaults.headers.common["Authorization"] = "";
            setSesion(null);
          }
        }
      })
      .finally(() => setLoading(false));
    //eslint-disable-next-line
  }, [Sesion.Email, Sesion.Id, setSesion]);

  const canCloseMenu = () => {
    if (SettingsOpen && !showMenu) {
      setSettingsOpen(false);
    } else {
      setShowMenu(false);
    }
  };

  const closeMenu = (val) => {
    setSettingsOpen(val);
    if (!val && !showMenu) {
      setShowMenu(false);
    }
  };

  if (Loading) {
    return <div>{LoadingLabel}</div>;
  }

  return (
    <ChangePasswordContext.Provider
      value={{ OpenModal, setOpenModal, showMenu }}
      style={{ position: "relative" }}
    >
      <SocketProvider>
        {Modal[OpenModal]}
        {showShortcuts && (
          <ShortcutList setShowShortcuts={() => setShowShortcuts(false)} />
        )}
        <IntroModal visible={Intro} onCancel={() => setIntro(false)} />
        <GlobalHotKeys
          keyMap={keyMap}
          handlers={handlers}
          style={{ height: "100%" }}
          allowChanges={false}
        >
          <Row style={{ height: "100%" }}>
            <SideBarContainer $showMenu={showMenu}>
              <SideBar
                isOpen={showMenu}
                setSettingsOpen={(val) => closeMenu(val)}
                SettingsOpen={SettingsOpen}
              />
            </SideBarContainer>

            <ExpandWrapper $renderBlankSpace={showAlert}>
              <OpenSidebarButton
                onClick={() => {
                  if (showMenu) {
                    setShowMenu(false);
                    canCloseMenu();
                  } else {
                    setShowMenu(true);
                  }
                }}
              >
                <IoIosArrowBack
                  size={15}
                  color="white"
                  style={{
                    transform: showMenu ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "all 0.5s ease",
                  }}
                />
              </OpenSidebarButton>
            </ExpandWrapper>

            <Content />
          </Row>
        </GlobalHotKeys>
      </SocketProvider>
    </ChangePasswordContext.Provider>
  );
};

export default HomeLayout;
