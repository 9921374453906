import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import styled from "styled-components";
import { noop as NOOP } from "lodash";
import axios from "axios";
import { Row, Modal } from "antd";
import Tooltip from "../../Tooltip";
import { TooltipPlacement } from "antd/lib/tooltip";
import { Typography } from "../../../GeestUI";
import { Languages } from "./Dictionary";
import mapIcon from "../../../resources/img/maps.png";
import GooglePlacesInput from "./helpers/GooglePlacesInput";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { KeyMaps } from "../../../constants/PlatformDetails";
import IsRequired from "./helpers/IsRequired";
import { MessagesContext } from "../../AppMessages";
import { DeleteIcon, LocationIcon, EditIcon } from "../../hoverIcons";

const { P } = Typography;
const {
  LocationTooltipTitle,
  LocationTooltipClear,
  CalculatingLabel,
  ObtainingLabel,
  LocDefaultButtonLabel,
} = Languages["ESP"];

interface CurrentLocationInputProps {
  value: string | null;
  onChange: (value: string) => void;
  onBlur?: (e: any) => void;
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  configuration: string;
  extraParams?: {
    center?: boolean;
    tooltipOffset?: number;
    tooltipPlacement?: TooltipPlacement;
    locationColor?: string;
    locatonFontSize?: string;
    mb?: string;
  };
}

const InputContainer = styled.div<{
  isConsult: boolean;
  focused: boolean;
  mb?: string;
}>`
  ${({ isConsult, focused, mb = "10px" }) =>
    isConsult ? "" : focused ? "margin-bottom: 70px;" : `margin-bottom: ${mb};`}

  width: 100%;
  * {
    margin: 0;
    padding: 0;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin-left: 5px;
  border-radius: 2px;
  background: transparent;
  transition: background 0.35s ease;
  :hover {
    background: #d9d9d9;
  }
`;

const SearchContainer = styled.div<{ $overflow: string }>`
  flex: 1;
  overflow: ${({ $overflow }) => $overflow};
  max-width: calc(100% - 25px);
  margin-right: 5px;
  * {
    color: #48505e !important;
  }
`;

const EmptyLocationInput = styled.div`
  height: 34px;
  width: 100%;
`;

const ButtonStyled = styled.button<{ $color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  border-radius: 6px !important;
  border: 2px solid;
  border-color: ${({ disabled, $color }) => (disabled ? "#edecec" : $color)};
  background-color: ${({ disabled }) => (disabled ? "#edecec" : "white")};
  color: ${({ disabled, $color }) => (disabled ? "#828d9e" : $color)};

  height: 32px;
  width: 100%;
  padding: 0 15px;
  font-size: 14px;
  font-family: "Gotham-Bold";
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};

  transition: all 0.35s ease;
  :hover {
    border-color: ${({ disabled }) => (disabled ? "#edecec" : "#014c9b")};
    color: ${({ disabled }) => (disabled ? "#828d9e" : "#014c9b")};
    box-shadow: none;
  }
  :focus {
    border-color: ${({ disabled }) => (disabled ? "#edecec" : "#1939B7")};
    color: ${({ disabled }) => (disabled ? "#828d9e" : "#1939B7")};
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const AddressContainer = styled.div<{ $justify: string; $pl: string }>`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: ${({ $justify }) => $justify};
  align-items: center;
  padding-left: ${({ $pl }) => $pl};
`;

const CurrentLocationInput: React.FC<CurrentLocationInputProps> = ({
  value = null,
  onChange,
  onBlur = NOOP,
  isConsult,
  disabled,
  required,
  configuration,
  extraParams = { center: false, mb: "10px" },
}) => {
  const [address, setAddress] = useState<string>(
    value ? value.split("|")[1] : ""
  );
  const [focusedInput, setFocusedInput] = useState<boolean>(false);
  const [googleInputValue, setGoogleInputValue] = useState<string>("");
  const [loadingLocation, setLoadingLocation] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);

  const canSelectOthers = configuration === "SelectAndCurrent";
  const lengthLimit = extraParams.tooltipOffset;
  const tooltipPlacement = extraParams.tooltipPlacement ?? "bottom";

  const { showMessage } = useContext(MessagesContext);

  useEffect(() => {
    if (value && (value || canSelectOthers)) {
      const [, label] = value.split("|");
      setAddress(label);
      setLoadingLocation(false);
    } else {
      setAddress("");
    }
  }, [value, canSelectOthers]);

  useEffect(() => {
    if (!focusedInput) {
      if (!!address) return;
      if (loadingLocation) return;
      else if (!!googleInputValue) {
        setAddress(googleInputValue);
        onChange(`|${googleInputValue}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedInput, googleInputValue, address]);

  const openInMap = () => {
    if (value) {
      const [coords, label] = value.split("|");
      const query = _.isEmpty(coords) || coords === "," ? label : coords;
      if (!_.isEmpty(query)) {
        window.open(`https://www.google.com/maps/search/?api=1&query=${query}`);
      }
    }
  };

  const handleOnChange = ({ label = "", lat = "", lng = "" }) => {
    setAddress(label);
    onChange(`${lat},${lng}|${label}`);
    setEditing(false);
  };

  const getAddress = (coords: string) => {
    const modal = Modal.info({
      title: CalculatingLabel,
      keyboard: false,
      centered: true,
      okButtonProps: { hidden: true },
    });
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords}&key=${KeyMaps}`
      )
      .then((response) => {
        if (response.data.results.length === 0) {
          return console.log(response.data.error_message);
        }
        const [addr] = response.data.results;
        onChange(`${coords}|${addr.formatted_address}`);
      })
      .catch(console.log)
      .finally(() => {
        modal.destroy();
        setLoadingLocation(false);
      });
  };

  const updateCoords = () => {
    setLoadingLocation(true);
    setEditing(false);
    const modal = Modal.info({
      title: ObtainingLabel,
      keyboard: false,
      centered: true,
      okButtonProps: { hidden: true },
    });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        getAddress(`${latitude},${longitude}`);
        modal.destroy();
      },
      (err) => {
        showMessage(err.message, "error");
        modal.destroy();
      },
      {
        maximumAge: 10000,
        timeout: 5000,
        enableHighAccuracy: true,
      }
    );
  };

  const handleClearLocation = () => {
    setAddress("");
    setGoogleInputValue("");
    onChange("");
  };

  return (
    <InputContainer
      isConsult={isConsult}
      focused={focusedInput}
      mb={extraParams.mb}
    >
      {address || canSelectOthers ? (
        <Row
          justify={extraParams.center && isConsult ? "center" : "start"}
          align="middle"
          style={{
            width: "100%",
            height: "32px",
            overflow: canSelectOthers ? "visible" : "hidden",
          }}
        >
          {canSelectOthers && !disabled && (!value || editing) ? (
            // enter location input
            !loadingLocation && (
              <SearchContainer $overflow={focusedInput ? "visible" : "hidden"}>
                <GooglePlacesInput
                  onChange={handleOnChange}
                  setGoogleInputValue={setGoogleInputValue}
                  defaultValue={address}
                  focusedInput={focusedInput}
                  setFocusedInput={setFocusedInput}
                  disabled={disabled}
                  editing={editing}
                  setEditing={setEditing}
                  borderColor={
                    required ? (address ? "#edecec" : "#db232c") : "#edecec"
                  }
                />
              </SearchContainer>
            )
          ) : address ? (
            // location value
            <AddressContainer
              $justify={extraParams.center ? "center" : "start"}
              $pl={address ? "5px" : ""}
            >
              <div style={{ maxWidth: "100%" }}>
                <Tooltip title={address} placement={tooltipPlacement}>
                  <P
                    style={{
                      color: extraParams.locationColor ?? "#828d9e",
                      fontSize: extraParams.locatonFontSize ?? "14px",
                    }}
                  >
                    {lengthLimit && address.length > lengthLimit
                      ? _.truncate(address, { length: lengthLimit })
                      : address}
                  </P>
                </Tooltip>
              </div>
            </AddressContainer>
          ) : (
            <EmptyLocationInput />
          )}
          {!disabled && !isConsult && address && !editing && (
            <Tooltip title={LocationTooltipClear} placement={tooltipPlacement}>
              <DeleteIcon onClick={handleClearLocation} />
            </Tooltip>
          )}
          {!disabled &&
            !isConsult &&
            address &&
            canSelectOthers &&
            !editing && (
              <Tooltip title="Editar ubicación" placement={tooltipPlacement}>
                <EditIcon onClick={() => setEditing(true)} />
              </Tooltip>
            )}
          {!disabled && !isConsult && (
            <Tooltip title={LocationTooltipTitle} placement={tooltipPlacement}>
              <LocationIcon onClick={updateCoords} />
            </Tooltip>
          )}
          {address && !editing && (
            <IconContainer>
              <img
                alt="map"
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                src={mapIcon}
                onClick={openInMap}
              />
            </IconContainer>
          )}
        </Row>
      ) : isConsult ? (
        <AddressContainer
          $justify={extraParams.center ? "center" : "start"}
          $pl={required && address ? "5px" : ""}
        >
          {address ? (
            <P style={{ color: extraParams.locationColor ?? "#48505e" }}>
              {lengthLimit && address.length > lengthLimit
                ? _.truncate(address, { length: lengthLimit })
                : address}
            </P>
          ) : (
            <div style={{ height: "32px" }} />
          )}
        </AddressContainer>
      ) : (
        <div style={{ cursor: disabled ? "auto" : "" }}>
          <ButtonStyled
            onClick={updateCoords}
            disabled={disabled}
            $color={required && !address ? "#db232c" : "#0273e9"}
          >
            <HiOutlineLocationMarker size={15} />
            {LocDefaultButtonLabel}
          </ButtonStyled>
        </div>
      )}
      {required && !isConsult && !Boolean(address) && <IsRequired />}
    </InputContainer>
  );
};

export default CurrentLocationInput;
