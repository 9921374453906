import React, { useState } from "react";
import styled from "styled-components";
import User from "../../../../../components/User";
import { FilterOption, User as UserType } from "../../../Dashboards.d";
import GeestSelect from "../../../../../GeestUI/GeestSelect/GeestSelect";

const InputWrapper = styled.div`
  display: flex;
`;

interface FilterUsersProps {
  filterData: FilterOption | null;
  onChangeFilter: (filter: FilterOption) => void;
  teamMembers: UserType[];
}

const FilterUsers: React.FC<FilterUsersProps> = ({
  filterData,
  onChangeFilter,
  teamMembers,
}) => {
  const [selectValue, setSelectValue] = useState<string>(
    filterData?.Value ? filterData.Value : ""
  );

  const transformedOptions = (() =>
    teamMembers.map((usr) => {
      return {
        value: `${usr.IdUser}`,
        searchableValues: `${usr.FirstName},${usr.LastName},${usr.Email}`,
        label: <User user={usr} size="middle" placement="bottom" />,
      };
    }))();

  const handleOnChangeSelect = (option: string) => {
    setSelectValue(option);

    let newFilter: FilterOption = {
      ...(filterData as FilterOption),
      Value: option,
    };
    onChangeFilter(newFilter);
  };

  return (
    <InputWrapper>
      <GeestSelect
        value={selectValue}
        options={transformedOptions}
        onChange={(option) => handleOnChangeSelect(option)}
        $width="188px"
        $listWidth="188px"
        multiselect
      />
    </InputWrapper>
  );
};

export default FilterUsers;
