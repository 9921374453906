import React, { useState } from "react";
import Flux from "../../SharedComponents/Flux";
import DetailPending from "../../../Pendings/DetailPending";
import { useParams } from "react-router-dom";
import { FluxProps } from "../ProcessDetail.d";

const FluxTab: React.FC<FluxProps> = ({ reload }) => {
  const [detailPendingId, setDetailPendingId] = useState<number | null>(null);
  const { idProcess } = useParams<{ idProcess: string }>();
  const [shouldReloadFlux, setShouldReloadFlux] = useState<boolean>(false);

  return (
    <>
      {!!detailPendingId && (
        <DetailPending
          idPending={String(detailPendingId)}
          onClose={() => {
            setDetailPendingId(null);
            setShouldReloadFlux(true);
          }}
          onChangePending={(pendingId) => {
            setDetailPendingId(null);
            setTimeout(() => {
              setDetailPendingId(pendingId);
            }, 1);
          }}
          reloadReport={reload}
        />
      )}
      <Flux
        IdProcessExecution={Number(idProcess)}
        onDetails
        onRestartFlux={reload}
        onClose={() => {}}
        closable={false}
        onChangePending={setDetailPendingId}
        shouldReloadFlux={shouldReloadFlux}
        setshouldReloadFlux={setShouldReloadFlux}
      />
    </>
  );
};

export default FluxTab;
