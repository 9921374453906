import React, { useContext, useState, useEffect } from "react";
import { Row } from "antd";
import Bowser from "bowser";
import { sha256 } from "js-sha256";
import _ from "lodash";
import ChangePasswordContext from "../../../HomeLayout/ChangePasswordContext";
import { SesionContext } from "../../../../config/State";
import { SessionType } from "../../../../constants/PlatformDetails";
import { ChangePasswordInput, InvisibleInputForEmail } from "./Styles";
import { Button, Typography } from "../../../../GeestUI";
import { GeestInstance } from "../../../../api/server";
import { Languages } from "./Dictionary";
import Modal from "../../../../components/Modal";
import { useMutation } from "../../../../hooks";
import moment from "moment-timezone";
import { MessagesContext } from "../../../../components/AppMessages";
import { CloseIcon } from "../../../../components/hoverIcons";

const { H2, B } = Typography;

const ChangePassword = () => {
  const { setOpenModal } = useContext(ChangePasswordContext);
  const [Sesion, setSesion] = useContext(SesionContext);
  const { showMessage } = useContext(MessagesContext);
  const [Password, setPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [RepNewPassword, setRepNewPassword] = useState("");
  const {
    PasswordNotMatchError,
    EmptyFieldsError,
    PasswordChangedMessage,
    CurrentPasswordLabel,
    NewPasswordLabel,
    RepeatPasswordLabel,
    CancelLabel,
    SaveLabel,
    ChangePasswordLabel,
  } = Languages["ESP"];

  const [Loading, setLoading] = useState(false);

  const emptyFieldsForm =
    _.isEmpty(_.trim(Password)) ||
    _.isEmpty(_.trim(NewPassword)) ||
    _.isEmpty(_.trim(RepNewPassword));
  const passwordsMatch = _.isEqual(_.trim(NewPassword), _.trim(RepNewPassword));
  const isValidForm = !emptyFieldsForm && passwordsMatch;

  const [Ip, setIp] = useState("");
  const {
    parsedResult: { browser },
  } = Bowser.getParser(window.navigator.userAgent);

  useEffect(() => {
    fetch("https://api.ipify.org?format=jsonp?callback=?'", {
      method: "GET",
      headers: {},
    })
      .then((res) => {
        return res.text();
      })
      .then((ip) => {
        setIp(ip);
      });
  }, []);

  const [ChangePassword] = useMutation({
    func: "Ver2-Session-cp",
    onSuccess: () => {
      showMessage(PasswordChangedMessage, "success");
      onLogin();
      setOpenModal("");
      setLoading(false);
    },
  });

  const onPasswordSubmit = () => {
    if (!isValidForm) {
      if (emptyFieldsForm) showMessage(EmptyFieldsError, "error");
      if (!passwordsMatch) showMessage(PasswordNotMatchError, "error");
      return;
    }
    setLoading(true);
    ChangePassword({
      args: {
        Password: sha256(Password),
        NewPassword: sha256(NewPassword),
      },
    });
  };

  const [submitLogin] = useMutation({
    func: "Ver2-Session-l",
    onSuccess: (response) => {
      const {
        User,
        Token: { TokenType, AccessToken },
      } = response;
      setSesion({
        ...User,
        FavoriteTeam: { IdFavoriteTeam: "", TeamLogoUrl: "" },
        Auth: `${TokenType} ${AccessToken}`,
      });
      GeestInstance.defaults.headers.common[
        "Authorization"
      ] = `${TokenType} ${AccessToken}`;
    },
  });

  const onLogin = () => {
    submitLogin({
      args: {
        IdClient: process.env.REACT_APP_CLIENT_ID,
        ClientSecret: process.env.REACT_APP_CLIENT_SECRET,
        Email: Sesion.Email,
        Password: sha256(NewPassword),
        OnesignalPlayerId: "",
        SessionType,
        TimeZone: moment.tz.guess(),
        DeviceInfo: {
          Model: "",
          Ip: Ip,
          Browser: browser.name,
          ApiVersion: browser.version,
          LanguageCode: navigator.language,
          Country: "",
          PushNotificationsToken: "",
        },
      },
    });
  };

  return (
    <Modal
      visible
      onCancel={() => setOpenModal("")}
      centered
      showClose={false}
      closable={false}
      title={
        <Row justify="space-between">
          <H2 mb="0">
            <B>{ChangePasswordLabel}</B>
          </H2>
          <CloseIcon onClick={() => setOpenModal("")} size={15} />
        </Row>
      }
      footer={null}
      width="25%"
    >
      <Row style={{ marginBottom: "1rem" }}>
        <InvisibleInputForEmail type="text" />
        <ChangePasswordInput
          placeholder={CurrentPasswordLabel}
          value={Password}
          onChange={({ target: { value } }) => setPassword(value)}
        />
        <ChangePasswordInput
          placeholder={NewPasswordLabel}
          value={NewPassword}
          onChange={({ target: { value } }) => setNewPassword(value)}
        />
        <ChangePasswordInput
          placeholder={RepeatPasswordLabel}
          value={RepNewPassword}
          onChange={({ target: { value } }) => setRepNewPassword(value)}
        />
      </Row>
      <Row justify="space-between">
        <Button
          style={{
            width: "calc(50% - 5px)",
          }}
          onClick={() => setOpenModal("")}
          loading={Loading}
          type="secondary"
        >
          {CancelLabel}
        </Button>
        <Button
          style={{
            width: "calc(50% - 5px)",
          }}
          type="primary"
          onClick={onPasswordSubmit}
          loading={Loading}
          disabled={!isValidForm}
        >
          {SaveLabel}
        </Button>
      </Row>
    </Modal>
  );
};

export default ChangePassword;
