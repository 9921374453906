import styled, { css } from "styled-components";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

export const StatsTableContainer = styled.div`
  flex: 1;
  overflow: auto scroll;
  max-height: 100%;
  padding-bottom: 10px;
`;

export const StyledTableContainer = styled(TableContainer)`
  height: 100%;
  max-height: 100%;

  & table {
    overflow: auto;
  }
  & .MuiTableCell-root {
    white-space: nowrap;
    border: none;
    border-right: 2px solid white;
  }
  & .MuiTableCell-stickyHeader {
    background-color: #f2f8fe;
  }
  & .MuiTableCell-sizeSmall {
    padding: 6px 8px;
  }
  * {
    user-select: none !important;
  }
`;

export const StyledTable = styled(Table)`
  z-index: 0;

  .fixed {
    position: sticky;
    left: 0;
    z-index: 3;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTableHead = styled(TableHead)`
  height: 40px;

  .fixed {
    top: 0;
    z-index: 4;
  }
`;

export const StyledTableRow = styled(TableRow)``;

export const TableHeaders = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: start;
  gap: 1px;
`;

export const StyledTableHeadCell = styled(TableCell)<{
  minWidth: string;
  maxWidth: string;
}>`
  background-color: #f2f8fe;
  border-radius: 6px 6px 0 0;
  text-align: left;
  white-space: nowrap;

  min-width: ${({ minWidth = "120px" }) => minWidth};
  max-width: ${({ maxWidth = "271px" }) => maxWidth};

  position: sticky;
  top: 0;

  span {
    display: block;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :hover {
    .deleteIconContainer {
      opacity: 1;
    }
  }
`;

export const TableHeadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const TableHeaderLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const TableHeaderName = styled.span`
  font-size: 12px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

export const HeaderContainer = styled.div<{
  $execution: boolean;
  $primary: boolean;
}>`
  border: 1px solid red;

  min-width: ${({ $execution, $primary }) =>
    $execution ? ($primary ? "230px" : "180px") : $primary ? "310px" : "260px"};
  width: ${({ $execution, $primary }) =>
    $execution ? ($primary ? "230px" : "180px") : $primary ? "310px" : "260px"};
  padding: 12px 10px;
  display: flex;
  align-item: center;
  justify-content: ${({ $primary, $execution }) =>
    $primary ? "center" : $execution ? "center" : "space-between"};
  gap: 8px;
  background-color: #f2f8fe;
  cursor: auto;

  p {
    margin: 0;
    font-family: Gotham-Bold;
    text-align: center;
    color: #48505e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
  }

  :hover {
    .deleteIconContainer {
      opacity: 1;
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 66px;
`;

export const DeleteIconContainer = styled.div`
  opacity: 0;
  transition: opacity 0.35s ease;
`;

export const EditIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: #0273e9;
  cursor: pointer;
  height: 20px;
  width: 20px;
  transition: background-color 0.35s ease;

  :hover {
    background-color: #014c9b;
  }
`;

export const NoDataTable = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 10px 8px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #48505e;
  text-align: center;
  user-select: none;
`;

export const TableRows = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: stretch;
  gap: 1px;
`;

export const StyledTableBody = styled(TableBody)``;

export const StyledTableBodyCell = styled(TableCell)<{
  bg?: string;
  brNone?: boolean;
  minWidth: string;
  maxWidth: string;
  hovereable?: boolean;
  $detailed: boolean;
}>`
  background-color: ${({ bg }) => bg || "#fff"};
  border-bottom: 1px solid #edecec !important;
  border-right: ${({ brNone }) =>
    brNone ? "none !important" : "1px solid #828d9e"};

  min-width: ${({ minWidth = "120px" }) => minWidth};
  max-width: ${({ maxWidth = "271px" }) => maxWidth};

  cursor: ${({ $detailed = false }) => ($detailed ? "cursor" : "")};

  p {
    margin: 0;
    color: #48505e;
    text-align: center;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ hovereable }) =>
    hovereable &&
    css`
      :hover {
        z-index: 1;
        box-shadow: 4px 10px 6px 0px #48505e4d;
        cursor: pointer;
      }
    `}
`;

export const StyledTableBodyCellContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StatsTableCell = styled.div<{
  $pair?: boolean;
  $execution: boolean;
  $detailed: boolean;
  $primary?: boolean;
}>`
  background-color: ${({ $pair = false }) => ($pair ? "#fff" : "#f6f5f5")};
  min-width: ${({ $execution, $primary }) =>
    $execution ? ($primary ? "230px" : "180px") : $primary ? "310px" : "260px"};
  width: ${({ $execution, $primary }) =>
    $execution ? ($primary ? "230px" : "180px") : $primary ? "310px" : "260px"};
  min-height: 100%;
  padding: 10px 2px;
  cursor: ${({ $detailed = false }) => ($detailed ? "cursor" : "")};

  p {
    margin: 0;
    color: #48505e;
    text-align: center;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ $execution, $detailed }) =>
    $execution &&
    $detailed &&
    css`
      :hover {
        z-index: 1;
        box-shadow: 4px 10px 6px 0px #48505e4d;
        cursor: pointer;
      }
    `}
`;

export const NoDataCell = styled.div<{ $execution: boolean }>`
  background-color: #fff;
  min-width: ${({ $execution }) => ($execution ? "180px" : "260px")};
  width: ${({ $execution }) => ($execution ? "180px" : "260px")};
  min-height: 100%;
  padding: 10px 8px;
  flex: 1;

  p {
    margin: 0;
    color: #48505e;
    text-align: center;
    user-select: none;
  }
`;
