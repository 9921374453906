import React, { useContext, useEffect, useRef, useState } from "react";
import _, { noop as NOOP } from "lodash";
import { Row, Progress, Input } from "antd";
import { HiMenuAlt2 } from "react-icons/hi";
import { Popover } from "@material-ui/core";
import PriorityPopover from "./PriorityPopover";
import { Tag, Button } from "../../../../GeestUI";
import { Languages } from "./Dictionary";
import { useMutation } from "../../../../hooks";
import MultiModal from "../../../../components/MultiModal";
import User from "../User";
import { ProcessData } from "./ProcessDetail.d";
import {
  ButtonsContainer,
  Container,
  SubTitle,
  DataContainer,
  DataRow,
  DetailContent,
  GraphContainer,
  ProcessName,
  ProcessNameStatus,
  ProcessNameWrapper,
  TitleContainer,
  IdContainer,
  DataText,
  SpaceRow,
  CancelButton,
} from "./Styles";
import Alerts from "../Alerts";
import Flux from "../Flux";
import ChatsModal from "./ChatsModal";
import Fields from "../Fields";
import StopProcessModal from "./Modals/StopProcessModal";
import DeleteProcessModal from "./Modals/DeleteProcessModal";
import UnsavedChanges from "../UnsavedChanges";
import { MessagesContext } from "../../../../components/AppMessages";
import {
  EditIcon,
  CloseIcon,
  PlayIcon,
} from "../../../../components/hoverIcons";
import Tooltip from "../../../../components/Tooltip";
import { AiOutlineNodeIndex } from "react-icons/ai";
import moment from "moment";
import { ReactComponent as chatDot } from "../../../../resources/img/example.svg";
import { ReactComponent as alertIcon } from "../../../../resources/img/bellIcon.svg";
import { ReactComponent as alertDot } from "../../../../resources/img/bellDot.svg";
import { ReactComponent as chatBubbleIcon } from "../../../../resources/img/chatBubble.svg";
import { StyledTag } from "../../Pipeline/styles";
import { AiOutlineCalendar } from "react-icons/ai";
import RescheduleProcessModal from "./Modals/RescheduleProcessModal";
import { ReactComponent as restartIcon } from "../../../../resources/img/restartIcon.svg";
//@ts-ignore
import emoji from "emoji-dictionary";

const {
  Status,
  NewLabel,
  StartedLabel,
  StuckLabel,
  WaitingLabel,
  CompletedLabel,
  CanceledLabel,
  StartedBy,
  StartDateLabel,
  Duration,
  PriorityLabel,
  NoPriorityLabel,
  ChatsButtonLabel,
  AlertsButtonLabel,
  FluxButtonLabel,
  FormFieldsButtonLabel,
  CancelFollowUpLabel,
  DeleteFollowUpLabel,
  SuccessfulUpdateForm,
} = Languages["ESP"];

interface ProcessDetailProps {
  open?: boolean;
  processId: number | null;
  onClose?: () => void;
  onChangePending?: (idPending: number) => void;
  reload: () => void;
  softReload: () => void;
}

const ProcessDetail: React.FC<ProcessDetailProps> = ({
  open = false,
  processId,
  onClose = NOOP,
  onChangePending = NOOP,
  reload = NOOP,
  softReload = NOOP,
}) => {
  const [process, setProcess] = useState<ProcessData | null>(null);
  const [secondaryModal, setSecondaryModal] = useState<
    "" | "Chats" | "Flux" | "Fields"
  >("");
  const [openUnsaved, setOpenUnsaved] = useState<boolean>(false);
  const [priorityPopoverVisible, setPriorityPopoverVisible] =
    useState<boolean>(false);
  const [alertsPopoverVisible, setAlertsPopoverVisible] =
    useState<boolean>(false);
  const [editingProcessName, setEditingProcessName] = useState<boolean>(false);
  const [newProcessName, setNewProcessName] = useState<string>("");
  const [stopProcessId, setStopProcessId] = useState<number | null>(null);
  const [deleteProcessId, setDeleteProcessId] = useState<number | null>(null);
  const [isFileOpen, setIsFileOpen] = useState<boolean>(false);
  const [rescheduleProcess, setRescheduleProcess] = useState<boolean>(false);

  const { showMessage } = useContext(MessagesContext);

  const editPriorityButton = useRef(null);
  const openAlertsButton = useRef(null);
  const redStatusPercent = 25;
  const yellowStatusPercent = 50;
  const greenStatusPercent = 100;

  const [getProcess, gettingProcess] = useMutation<ProcessData>({
    func: "Ver2-Reports-gpd",
    onSuccess: (res) => {
      setProcess(res);
      setNewProcessName(res.Name);
    },
  });

  const [updateProcessExecutionTitle] = useMutation<[]>({
    func: "Ver2-Reports-upet",
    onSuccess: () => {
      setEditingProcessName(false);
      softReload();
    },
    onError: () => {
      setEditingProcessName(false);
    },
  });

  useEffect(() => {
    if (open) getProcess({ args: { IdProcessExecution: processId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const reloadProcessDetails = () => {
    getProcess({ args: { IdProcessExecution: processId } });
    softReload();
  };

  const [startScheduledProcess] = useMutation({
    func: "Ver2-Reports-ssp",
    onSuccess: reloadProcessDetails,
  });

  const [restartProcess, restartingProcess] = useMutation({
    func: "Ver2-Reports-rp",
    onSuccess: reloadProcessDetails,
  });

  const onStartProcess = () => {
    startScheduledProcess({ args: { IdProcessExecution: processId } });
  };

  const onRestartProcess = () => {
    restartProcess({ args: { IdProcessExecution: processId } });
  };

  const getStatusInfo = (status: string): { color: string; label: string } => {
    switch (status) {
      case "New":
      case "Nuevo":
        return { color: "yellow", label: NewLabel };
      case "OnTime":
      case "Iniciado":
      case "A tiempo":
        return { color: "cyan", label: StartedLabel };
      case "Completed":
      case "Terminado":
        return { color: "green", label: CompletedLabel };
      case "Canceled":
      case "Cancelado":
        return { color: "default", label: CanceledLabel };
      case "WaitingToStart":
      case "Waiting":
      case "En espera":
      case "Por iniciar":
        return { color: "orange", label: WaitingLabel };
      case "Stuck":
      case "Vencido":
      case "Atorado":
        return { color: "red", label: StuckLabel };
      default:
        return { color: "default", label: status };
    }
  };

  const isScheduled = ["WaitingToStart", "Waiting"].includes(
    process?.Status || ""
  );

  const getCircleColor = (progress: number) => {
    let firstColor;
    let lastColor;
    if (progress <= redStatusPercent) {
      firstColor = "rgba(194,50,40,255)";
      lastColor = "rgba(234,174,60,255)";
    } else if (progress <= yellowStatusPercent) {
      firstColor = "rgba(224,233,71,255)";
      lastColor = "rgba(177,229,73,255)";
    } else if (progress <= greenStatusPercent) {
      firstColor = "rgba(67,162,67,255)";
      lastColor = "rgba(50,149,65,255)";
    } else {
      firstColor = "rgba(67,162,67,255)";
      lastColor = "rgba(50,149,65,255)";
    }
    return {
      "0%": firstColor,
      "100%": lastColor,
    };
  };

  const onChangeProcessName = () => {
    const isProcessEmpty = _.isEmpty(_.trim(newProcessName));
    const isSameProcessName = _.isEqual(_.trim(newProcessName), process?.Name);

    if (isProcessEmpty || isSameProcessName) {
      setEditingProcessName(false);
      return;
    }
    updateProcessExecutionTitle({
      args: {
        IdProcessExecution: process?.IdProcessExecution,
        Name: _.trim(newProcessName),
      },
    });
  };

  const handleClose = () => {
    if (!isFileOpen) {
      setProcess(null);
      setSecondaryModal("");
      onClose();
    }
  };

  const SecondaryModals: { [key: string]: React.ReactNode } = {
    Chats: (
      <ChatsModal
        IdProcessExecution={process?.IdProcessExecution}
        onClose={() => setSecondaryModal("")}
      />
    ),
    Flux: (
      <Flux
        IdProcessExecution={process?.IdProcessExecution}
        onDetails
        onClose={() => setSecondaryModal("")}
        onChangePending={onChangePending}
        onRestartFlux={() => {
          reloadProcessDetails();
        }}
      />
    ),
    Fields: (
      <Fields
        IdProcessExecution={process?.IdProcessExecution}
        canEdit={process?.Permissions.UserCanEditProcess || false}
        onDetails
        isFileOpenDetails={isFileOpen}
        setIsFileOpenDetails={setIsFileOpen}
        openUnsaved={() => setOpenUnsaved(true)}
        onSuccess={() => showMessage(SuccessfulUpdateForm, "success")}
        onCancel={() => setSecondaryModal("")}
      />
    ),
  };

  return (
    <MultiModal
      open={open}
      onClose={handleClose}
      openSecondary={!_.isEmpty(secondaryModal)}
      secondaryModalContent={SecondaryModals[secondaryModal]}
      height="330px"
      width="663px"
      secondaryWidth={_.isEqual(secondaryModal, "Fields") ? "400px" : "40%"}
      secondaryHeight={_.isEqual(secondaryModal, "Fields") ? "600px" : "85%"}
      maxHeight="85vh"
      align="top"
      mt="5rem"
      padding="20px"
    >
      {stopProcessId && (
        <StopProcessModal
          IdProcessExecution={stopProcessId}
          onCancel={() => setStopProcessId(null)}
          onSuccess={() => {
            setStopProcessId(null);
            onClose();
            reload();
          }}
        />
      )}
      {deleteProcessId && (
        <DeleteProcessModal
          IdProcessExecution={deleteProcessId}
          hasVarDBs={process?.Permissions.ProcessHasVardbRowLinked || false}
          onCancel={() => setDeleteProcessId(null)}
          onSuccess={() => {
            setDeleteProcessId(null);
            onClose();
            reload();
          }}
        />
      )}
      {openUnsaved && (
        <UnsavedChanges
          onCancel={() => setOpenUnsaved(false)}
          onClose={() => {
            setOpenUnsaved(false);
            setSecondaryModal("");
          }}
        />
      )}
      {rescheduleProcess && (
        <RescheduleProcessModal
          IdProcessExecution={processId}
          onSuccess={reloadProcessDetails}
          closeModal={() => setRescheduleProcess(false)}
        />
      )}
      {process && (
        <Container>
          <TitleContainer>
            <ProcessNameStatus>
              {!editingProcessName ? (
                <ProcessNameWrapper
                  onClick={() => {
                    if (process.Permissions.UserCanEditProcess) {
                      setNewProcessName(newProcessName || "");
                      setEditingProcessName(!editingProcessName);
                    }
                  }}
                >
                  {newProcessName.length > 22 ? (
                    <Tooltip
                      title={newProcessName.replace(
                        /:\w+:/gi,
                        (name) => emoji.getUnicode(name) ?? name
                      )}
                    >
                      <ProcessName mr="1rem" style={{ color: "#48505e" }}>
                        {newProcessName.replace(
                          /:\w+:/gi,
                          (name) => emoji.getUnicode(name) ?? name
                        )}
                      </ProcessName>
                    </Tooltip>
                  ) : (
                    <ProcessName mr="1rem" style={{ color: "#48505e" }}>
                      {newProcessName.replace(
                        /:\w+:/gi,
                        (name) => emoji.getUnicode(name) ?? name
                      )}
                    </ProcessName>
                  )}
                </ProcessNameWrapper>
              ) : (
                <Input
                  style={{
                    height: "25px",
                    maxWidth: "320px",
                    borderRadius: "5px",
                    marginRight: "0.5rem",
                  }}
                  autoFocus
                  onPressEnter={onChangeProcessName}
                  onBlur={onChangeProcessName}
                  value={newProcessName}
                  onChange={(e) => setNewProcessName(e.target.value)}
                />
              )}
            </ProcessNameStatus>

            <CloseIcon onClick={handleClose} />
          </TitleContainer>

          <DetailContent>
            <DataContainer>
              <SpaceRow>
                <DataContainer>
                  <SubTitle>{process.ProcessTemplateName}</SubTitle>
                  <IdContainer>{`Folio ${process.IdProcessExecution}`}</IdContainer>
                  <DataRow>
                    <SubTitle>{StartDateLabel}</SubTitle>
                    {isScheduled && process.Permissions.UserCanEditProcess && (
                      <PlayIcon onClick={onStartProcess} />
                    )}
                    <DataText>
                      {moment(process.StartedAt).format("DD/MMM/YY")}
                    </DataText>
                    <DataText>
                      {moment(process.StartedAt).format("HH:mm")}
                    </DataText>
                  </DataRow>

                  <DataRow>
                    <SubTitle>{Duration}</SubTitle>
                    <DataText>{process.Duration}</DataText>
                  </DataRow>
                </DataContainer>
                <GraphContainer>
                  <Progress
                    percent={
                      process.ProgressPercentage >= 0
                        ? process.ProgressPercentage
                        : 0
                    }
                    type="circle"
                    width={60}
                    strokeColor={getCircleColor(process.ProgressPercentage)}
                    strokeLinecap="butt"
                  />
                </GraphContainer>
              </SpaceRow>
              <SpaceRow style={{ marginTop: "10px", alignItems: "center" }}>
                {!!process.UserStarted && (
                  <DataRow>
                    <User
                      user={process.UserStarted}
                      size="small"
                      justify="start"
                    />
                    <div>
                      <DataText
                        style={{
                          color: "rgba(72, 80, 94, 1)",
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {`${process.UserStarted.FirstName} ${process.UserStarted.LastName}`}
                      </DataText>
                      <DataText>{StartedBy}</DataText>
                    </div>
                  </DataRow>
                )}
                <DataContainer>
                  <DataRow>
                    <DataText>{Status}</DataText>
                    <StyledTag color={getStatusInfo(process.Status).color}>
                      {getStatusInfo(process.Status).label}
                    </StyledTag>
                  </DataRow>
                  <DataRow style={{ gap: "5px" }}>
                    <DataText>{PriorityLabel}</DataText>
                    {process.Priority ? (
                      <Tag color={process.Priority.Color}>
                        {process.Priority.Title}
                      </Tag>
                    ) : (
                      <Tag>{NoPriorityLabel}</Tag>
                    )}
                    <Popover
                      open={priorityPopoverVisible}
                      anchorEl={editPriorityButton.current}
                      onClose={() => setPriorityPopoverVisible(false)}
                      PaperProps={{
                        style: {
                          borderRadius: "10px",
                          width: "300px",
                          padding: "12px 16px",
                        },
                      }}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                    >
                      <PriorityPopover
                        IdProcessExecution={process.IdProcessExecution}
                        idTaskPriority={process.Priority?.IdTaskPriority}
                        reload={reloadProcessDetails}
                        onClose={() => setPriorityPopoverVisible(false)}
                      />
                    </Popover>
                    {process.Permissions.UserCanEditProcess && (
                      <EditIcon
                        onClick={() => setPriorityPopoverVisible(true)}
                        ref={editPriorityButton}
                        filled
                        style={
                          priorityPopoverVisible
                            ? { background: "rgba(232, 236, 241, 0.5)" }
                            : {}
                        }
                      />
                    )}
                  </DataRow>
                </DataContainer>
              </SpaceRow>
            </DataContainer>

            <ButtonsContainer>
              <Button
                disabled={gettingProcess}
                onClick={() =>
                  _.isEqual(secondaryModal, "Chats")
                    ? setSecondaryModal("")
                    : setSecondaryModal("Chats")
                }
                type="secondary"
                style={{ width: "220px" }}
                SvgIcon={process.MessageCount > 0 ? chatDot : chatBubbleIcon}
              >
                {ChatsButtonLabel}
              </Button>

              <Popover
                open={alertsPopoverVisible}
                anchorEl={openAlertsButton.current}
                onClose={() => setAlertsPopoverVisible(false)}
                PaperProps={{
                  style: {
                    borderRadius: "10px",
                    width: "300px",
                    padding: "12px 16px",
                  },
                }}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
              >
                <Alerts
                  alertsId={process.IdProcessExecution}
                  onClose={() => setAlertsPopoverVisible(false)}
                />
              </Popover>
              <div ref={openAlertsButton}>
                <Button
                  disabled={gettingProcess}
                  onClick={() => setAlertsPopoverVisible(true)}
                  type="secondary"
                  style={{ width: "220px" }}
                  SvgIcon={process.AlertsCount > 0 ? alertDot : alertIcon}
                >
                  {AlertsButtonLabel}
                </Button>
              </div>

              {process.Permissions.UserCanOpenFlux && (
                <Button
                  disabled={gettingProcess}
                  onClick={() =>
                    _.isEqual(secondaryModal, "Flux")
                      ? setSecondaryModal("")
                      : setSecondaryModal("Flux")
                  }
                  type="secondary"
                  style={{ width: "220px" }}
                  Icon={AiOutlineNodeIndex}
                >
                  {FluxButtonLabel}
                </Button>
              )}
              {!isScheduled && process.Permissions.UserCanEditProcess && (
                <Button
                  type="secondary"
                  disabled={gettingProcess}
                  loading={restartingProcess}
                  SvgIcon={restartIcon}
                  style={{ width: "220px" }}
                  onClick={onRestartProcess}
                >
                  Reiniciar
                </Button>
              )}
              {isScheduled && process.Permissions.UserCanEditProcess && (
                <Button
                  type="secondary"
                  disabled={gettingProcess}
                  style={{ width: "220px" }}
                  Icon={AiOutlineCalendar}
                  onClick={() => setRescheduleProcess(true)}
                >
                  Reagendar
                </Button>
              )}

              {process.Permissions.UserCanOpenFields && (
                <Button
                  disabled={gettingProcess}
                  onClick={() =>
                    _.isEqual(secondaryModal, "Fields")
                      ? setSecondaryModal("")
                      : setSecondaryModal("Fields")
                  }
                  type="secondary"
                  style={{ width: "220px" }}
                  Icon={HiMenuAlt2}
                >
                  {FormFieldsButtonLabel}
                </Button>
              )}
            </ButtonsContainer>
          </DetailContent>

          <Row
            justify={
              process.Permissions.UserCanCancelProcess ? "space-between" : "end"
            }
            align="middle"
          >
            {process.Permissions.UserCanCancelProcess ? (
              <CancelButton
                disabled={gettingProcess}
                onClick={() => setStopProcessId(process.IdProcessExecution)}
              >
                {CancelFollowUpLabel}
              </CancelButton>
            ) : (
              <div />
            )}

            {process.Permissions.UserCanDeleteProcess && (
              <Button
                disabled={gettingProcess}
                onClick={() => setDeleteProcessId(process.IdProcessExecution)}
                danger
                style={{ width: "220px" }}
              >
                {DeleteFollowUpLabel}
              </Button>
            )}
          </Row>
        </Container>
      )}
    </MultiModal>
  );
};

export default ProcessDetail;
