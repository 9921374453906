import React, { useContext, useState } from "react";
import { Tooltip } from "antd";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GoTriangleDown } from "react-icons/go";
import AddNodeDropdown from "./AddNodeDropdown";
import { DropdownButton, DropdownButtonLabel } from "../styles";
import { ConfiguratorContext } from "./../../Configurator";
import { NodeIconSmall } from "./NodeIcons";

const AddNode: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { refreshProcessInformation } = useContext(ConfiguratorContext);

  const handleOnSetOpen = (value: boolean) => {
    setOpen(value);
    if (!value) refreshProcessInformation();
  };

  return (
    <AddNodeDropdown open={open} setOpen={handleOnSetOpen}>
      <DropdownButton align="middle" open={open}>
        <Tooltip
          title="Selecciona, arrastra y suelta el tipo de nodo que deseas agregar"
          placement="bottomRight"
        >
          <AiOutlineInfoCircle
            size={16}
            color="#48505E"
            style={{ marginRight: "3px" }}
          />
        </Tooltip>
        <DropdownButtonLabel>Agregar nodos</DropdownButtonLabel>
        {NodeIconSmall}
        <GoTriangleDown
          size={20}
          color="#48505e"
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
            transition: "all 0.35s ease",
          }}
        />
      </DropdownButton>
    </AddNodeDropdown>
  );
};

export default AddNode;
