import React, { useState } from "react";
import { Card } from "antd";
import { Typography } from "../../GeestUI";
import GeestSelect from "../../GeestUI/GeestSelect";

const { H1, B } = Typography;

type OptionLabel = React.ReactNode | string | number | undefined | null;

const SelectInputPage: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [selectedMulti, setSelectedMulti] = useState<any>(null);

  const options = [
    { value: "1", label: "opción 1" },
    { value: "2", label: "opción 2" },
    { value: "3", label: "opción 3" },
    {
      value: "4",
      searchableValues: "opción 4",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ margin: 0 }}>
            opción <b style={{ color: "salmon" }}>4</b>
          </p>
        </div>
      ),
    },
    { value: "5", label: "opción con un texto muy muy muy pero que muy largo" },
    { value: "6", label: "opción 6" },
    { value: "7", label: "opción 7" },
    { value: "8", label: "opción 8" },
    { value: "9", label: "opción 9" },
    { value: "10", label: "opción 10" },
    { value: "11", label: "opción 11" },
    { value: "12", label: "opción 12" },
    { value: "13", label: "opción 13" },
    { value: "14", label: "opción 14" },
    { value: "15", label: "opción 15" },
    { value: "16", label: "opción 16" },
    { value: "17", label: "opción 17" },
    { value: "18", label: "opción 18" },
    { value: "19", label: "opción 19" },
    { value: "20", label: "opción 20" },
  ];

  return (
    <>
      <H1>
        <B>SelectInput</B>
      </H1>
      <Card size="small" title="select">
        <div style={{ width: "300px" }}>
          <GeestSelect
            value={selectedOption}
            options={options}
            onChange={(newValue: OptionLabel) => setSelectedOption(newValue)}
          />
        </div>
      </Card>
      <Card size="small" title="multiselect">
        <div style={{ width: "300px" }}>
          <GeestSelect
            multiselect
            showTags
            value={selectedMulti}
            options={options}
            onChange={(newValue: OptionLabel) => setSelectedMulti(newValue)}
          />
        </div>
      </Card>
    </>
  );
};

export default SelectInputPage;
