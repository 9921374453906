import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { Row } from "antd";
import _, { debounce } from "lodash";
import { AiOutlineFilter } from "react-icons/ai";
import { useMutation } from "../../../hooks";
import ViewTitle from "../SharedComponents/ViewTitle";
import { Languages } from "../Dictionary";
import {
  StyledTab,
  ReportsHeader,
  NavigationComponents,
  ReportContainer,
  ReportContent,
  ReportOptionsWrapper,
} from "../Styles";
import {
  PipelineContainer,
  FilterContainer,
  VerticalDivider,
  ArrowContainer,
  FiltersConteiner,
  FilterCountContainer,
  CustomReportTitleContainer,
  ContentWrapper,
  LoadingContainer,
  NoDataContainer,
  FilterIconContainer,
  FilterLabel,
} from "./styles";
import { Button } from "../../../GeestUI";
import { DeleteProcessModal } from "./Popups";
import DetailPending from "../../Pendings/DetailPending";
import StopProcessModal from "./Popups/StopProcessModal";
import DynamicFilter from "../../../components/DynamicFilter";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import { CancelIcon, DeleteIcon } from "../../../components/hoverIcons";
import DataTypeIcons from "../SharedComponents/DataTypeIcons";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AiOutlineInfoCircle, AiOutlineSave } from "react-icons/ai";
import SaveReportModal from "./Popups/SaveReportModal";
import ReportItem from "./ReportItem";
import RescheduleProcessModal from "../SharedComponents/ProcessDetail/Modals/RescheduleProcessModal";
import Loading from "../../../components/Loading";
import { AlertContext } from "../../../components/AppAlert/AppAlertContext";
import ConfigReportIcon from "../../../components/hoverIcons/ConfigReportIcon";
import ReportOptionsModal from "./Popups/ReportOptionsModal";
import ConfigReportModal from "./ConfigReportModal";
import {
  ActionsContainer,
  Content,
  Controls,
  FilterOptionLabel,
  PipelineTable,
  PipelineTableBody,
  PipelineTableBodyCell,
  PipelineTableBodyCellContent,
  PipelineTableContainer,
  PipelineTableFoot,
  PipelineTableFooterCell,
  PipelineTableHead,
  PipelineTableHeadCell,
  PipelineTableRow,
  ProceseesActions,
  StyledCheckbox,
  StyledCheckboxContainer,
  TableHeadContent,
  TableHeaderIconContainer,
  TableHeaderLabel,
  TableHeaderName,
  TotalsCellContent,
} from "./PipelineStyles";
import classNames from "classnames";
import Tooltip from "../../../components/Tooltip";
import {
  PriorityDataOriginType,
  StageDataOriginType,
  StatusDataOriginType,
  TableDataType,
  User,
} from "./DynamicCell/DynamicCell.d";
import {
  CallGetPipelineReportParams,
  CellTypes,
  DataTypes,
  Filter,
  FilterOption,
  GetPipelineReport,
  GetTeamsAndFiltersResponse,
  Header,
  Option,
  Order,
  OrderValue,
  RowData,
  TotalsType,
} from "./PipelineTypes.d";
import OrderIcon from "./OrderIcon";
import { useHistory } from "react-router-dom";
import usePipeline from "./PipelineContext";
//@ts-ignore
import emoji from "emoji-dictionary";
import StatusComponent from "./DynamicCell/CellComponents/StatusComponent";
import PriorityComponent from "./DynamicCell/CellComponents/PriorityComponent";
import StageComponent from "./DynamicCell/CellComponents/StageComponent";
import DynamicSkeletonCell from "./DynamicSkeletonCell";
import TableRow from "./TableComponents/TableRow";
import NoData from "./NoData";
import ReloadPipelineIcon from "../../../components/hoverIcons/ReloadPipelineIcon";
import StopProcessIcon from "../../../components/hoverIcons/StopProcessIcon";
import InvisibleRows from "./InvisibleRows";

const Pipeline: React.FC = () => {
  const { AlertData } = useContext<any>(AlertContext);
  const {
    zusHasMoreData,
    setZusHasMoreData,
    zusUsersSelectDataOrigin,
    setZusUsersSelectDataOrigin,
    zusStatusDataOrigin,
    setZusStatusDataOrigin,
    zusStageDataOrigin,
    setZusStageDataOrigin,
    zusPriorityDataOrigin,
    setZusPriorityDataOrigin,
    zusIdTeam,
    setZusIdTeam,
    zusFilters,
    zusSetFilters,
    zusOrder,
    zusSetOrder,
    zusReportOptions,
    setZusReportOptions,
    zusSelectedReport,
    setZusSelectedReport,
    zusTableData,
    setZusTableData,
    zusTotalsData,
    setZusTotalsData,
    zusScrollTop,
    setZusScrollTop,
    zusCurrentPage,
    setZusCurrentPage,
  } = usePipeline();

  const [tableData, setTableData] = useState<TableDataType>(zusTableData);
  const [totalsData, setTotalsData] = useState<TotalsType>(zusTotalsData);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(zusCurrentPage ?? 1);
  const [hasMoreData, setHasMoreData] = useState<boolean>(zusHasMoreData);
  const rowsPerPage = 25;
  const [tableContainerHeight, setTableContainerHeight] = useState<number>(0);
  const tableRef = useRef<HTMLDivElement>(null);
  const previousScrollPosition = useRef<number>(0);

  const [UserSelectDataOrigin, setUserSelectDataOrigin] = useState<User[]>(
    zusUsersSelectDataOrigin
  );
  const [StatusDataOrigin, setStatusDataOrigin] =
    useState<StatusDataOriginType[]>(zusStatusDataOrigin);
  const [StageDataOrigin, setStageDataOrigin] =
    useState<StageDataOriginType[]>(zusStageDataOrigin);
  const [PriorityDataOrigin, setPriorityDataOrigin] = useState<
    PriorityDataOriginType[]
  >(zusPriorityDataOrigin);

  const { TabTitles } = Languages["ESP"];
  const [teamsAndFilters, setTeamsAndFilters] =
    useState<GetTeamsAndFiltersResponse>({
      Statuses: [],
      Teams: [],
    });
  const [idTeam, setIdTeam] = useState<number>(zusIdTeam);
  const [filters, setFilters] = useState<Filter[]>(zusFilters);
  const [order, setOrder] = useState<Order>(zusOrder);
  const [showReportOptions, setShowReportOptions] = useState<boolean>(false);
  const [openConfigReport, setOpenConfigReport] = useState<boolean>(false);

  const [selectedProcesses, setSelectedProcesses] = useState<number[]>([]);
  const [openDeleteProcess, setOpenDeleteProcess] = useState<boolean>(false);
  const [openStopProcess, setOpenStopProcess] = useState<boolean>(false);

  const [detailPendingId, setDetailPendingId] = useState<number | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<FilterOption | null>();
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [reportOptions, setReportOptions] =
    useState<Option[]>(zusReportOptions);
  const [selectedReport, setSelectedReport] = useState<number | null>(
    zusSelectedReport
  );
  const [saveReport, setSaveReport] = useState<boolean>(false);
  const [idProcessReschedule, setIdProcessReschedule] = useState<
    number | null
  >();
  const [editingReport, setEditingReport] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(!zusIdTeam);
  const selectedReportForBack = selectedReport === 0 ? null : selectedReport;
  const history = useHistory();

  const { pathname, state } = useLocation<{
    IdTeam: number;
    IdProcessExecution: number;
  }>();

  const Routes = [
    {
      title: TabTitles.Tracking,
      path: "tracking",
    },
    {
      title: TabTitles.Pipeline,
      path: "pipeline",
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    debounce(() => {
      if (tableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current;

        if (
          scrollHeight - scrollTop <= clientHeight + 240 &&
          hasMoreData &&
          !zusCurrentPage &&
          !isLoading
        ) {
          setIsLoading(true);
          setCurrentPage((prevPage) => prevPage + 1);
        }

        previousScrollPosition.current = scrollTop;
      }
    }, 50),
    [hasMoreData, isLoading, zusCurrentPage]
  );

  useEffect(() => {
    const divRef = tableRef;

    const handleResize = debounce(() => {
      if (divRef.current) {
        const newHeight = divRef.current.offsetHeight;
        if (newHeight !== tableContainerHeight) {
          setTableContainerHeight(newHeight);
        }
      }
    }, 50); // Adjust debounce time as needed

    const observer = new ResizeObserver(handleResize);
    if (divRef.current) {
      observer.observe(divRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      handleResize.cancel();
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [tableContainerHeight]);

  useEffect(() => {
    const tableElement = tableRef.current;

    if (tableElement) {
      if (zusScrollTop) {
        tableElement.scrollTo({ top: zusScrollTop });
        setZusScrollTop(0);
      }
      tableElement.addEventListener("scroll", handleScroll);

      return () => {
        tableElement.removeEventListener("scroll", handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll, zusScrollTop]);

  useEffect(() => {
    setZusHasMoreData(true);
    setZusUsersSelectDataOrigin([]);
    setZusStatusDataOrigin([]);
    setZusStageDataOrigin([]);
    setZusPriorityDataOrigin([]);
    setZusIdTeam(0);
    zusSetFilters([]);
    zusSetOrder({});
    setZusReportOptions([]);
    setZusSelectedReport(null);
    setZusTableData({
      isFirstColumnFixed: true,
      Headers: [],
      Rows: [],
      showTotals: false,
    });
    setZusTotalsData({});
    setZusCurrentPage(null);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getTeamsAndFilters, loading] = useMutation<GetTeamsAndFiltersResponse>(
    {
      func: "Ver2-Pipeline-gt",
      onSuccess: (response, { updateTeam } = { updateTeam: true }) => {
        setTeamsAndFilters(response);
        if (!updateTeam) return;
        if (!state) {
          if (!zusIdTeam) {
            handleSetIdTeam(_.get(response, "Teams[0].IdTeam", 0), response);
          } else {
            handleSetIdTeam(zusIdTeam, response);
          }
        } else {
          handleSetIdTeam(state?.IdTeam, response);
        }
      },
    }
  );

  const reload = () => {
    getTeamsAndFilters({ args: {} });
  };

  useEffect(() => {
    if (!idTeam) {
      getTeamsAndFilters({ args: {} });
    } else if (currentPage === 1) {
      softReload(currentPage);
      getTeamsAndFilters({ args: {}, shippedData: { updateTeam: false } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectedReport &&
      selectedReport !== 0 &&
      !reportOptions.map(({ value }) => value).includes(selectedReport)
    ) {
      onChangeReport(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportOptions]);

  const [getSavedPipelineReports, loadingReports] = useMutation<
    { Id: number; Name: string }[]
  >({
    func: "Ver2-Pipeline-gspr",
    onSuccess: (data, { IdTeam, IdReport }) => {
      setReportOptions([
        {
          label: (
            <p
              style={{
                padding: "6px 0",
                height: "32px",
                margin: 0,
                wordWrap: "break-word",
                maxWidth: "calc(100% - 25px)",
              }}
            >
              Nuevo reporte
            </p>
          ),
          value: 0,
        },
        ...data.map(({ Id, Name }) => ({
          label: (
            <ReportItem
              Id={Id}
              Name={Name}
              reloadReports={() => reloadReports(IdTeam)}
              setEditingReport={setEditingReport}
              IdTeam={IdTeam}
            />
          ),
          value: Id,
          asSelected: Name,
          searchableValues: Name,
        })),
      ]);
    },
  });

  const [getPipelineReport, gettingPipelineReport] =
    useMutation<GetPipelineReport>({
      func: "Ver2-Pipeline-gpr",
      onSuccess: (data, shippedData) => {
        setIsLoading(false);

        const currentPage = shippedData?.pags?.CurrentPage ?? 1;

        if (data.Rows.length < rowsPerPage) {
          setHasMoreData(false);
        }

        if (currentPage > 1) {
          setTableData((prevData) => {
            let prevRows = [...prevData.Rows];
            if (zusCurrentPage) {
              prevRows = prevRows.slice(0, (zusCurrentPage - 1) * rowsPerPage);
            }
            return {
              ...prevData,
              Rows: [...prevRows, ...data.Rows],
            };
          });
          return;
        }

        if (tableRef.current && !zusScrollTop && shippedData.changedReport) {
          tableRef.current.scrollTo(0, 0);
        }

        setSelectedProcesses([]);

        let hasFilterProcess = false;
        let hasFilterStage = false;
        let stageFilterId = -1;
        let filterProcessIds: number[] = [];

        const newFilters = data.Filters.filter((filter) =>
          Boolean(filter.FilterType)
        );

        newFilters.forEach((filter, i) => {
          if (filter.IdReportColumn.includes("ProcessTemplateName")) {
            hasFilterProcess = true;
            filterProcessIds = `${filter.Value}`
              .split(",")
              .map((id) => Number(id));
          }
          if (filter.IdReportColumn.includes("Stage")) {
            hasFilterStage = true;
            stageFilterId = i;
          }
        });

        let newStageDataOrigin: StageDataOriginType[] = [];

        let newTableData: TableDataType = {
          isFirstColumnFixed: data.Configuration.FirstColumStatic,
          Headers: data.Headers,
          Rows: [...data.Rows],
          showTotals: data.Configuration.ShowTotal,
        };

        if (hasFilterProcess) {
          let stageHeaderId = -1;
          data.Headers.forEach((header, i) => {
            if (header.Key === "Stage") {
              newStageDataOrigin = header.DataOrigin as StageDataOriginType[];
              stageHeaderId = i;
            }
          });

          if (filterProcessIds[0] !== 0) {
            newStageDataOrigin = newStageDataOrigin.filter((stageDO) =>
              filterProcessIds.includes(stageDO.IdProcessTemplate)
            );
          }

          if (stageHeaderId !== -1) {
            newTableData.Headers[stageHeaderId].DataOrigin = newStageDataOrigin;
          }
        }

        setTableData(newTableData);

        if (hasFilterProcess && hasFilterStage && stageFilterId !== -1) {
          const allStages = newStageDataOrigin.map((stage) => stage.IdStage);

          let selectedStages = [
            ...`${
              newFilters[stageFilterId].Value
                ? newFilters[stageFilterId].Value
                : ""
            }`
              .split(",")
              .map((id) => Number(id)),
          ];

          selectedStages.forEach((selectedStage) => {
            if (!allStages.includes(selectedStage)) {
              let stageValue = `${
                newFilters[stageFilterId].Value
                  ? newFilters[stageFilterId].Value
                  : ""
              }`.split(",");
              const stageToRemoveIndex = stageValue.findIndex(
                (stage) => Number(stage) === selectedStage
              );

              if (stageToRemoveIndex !== -1 && filterProcessIds[0] !== 0) {
                stageValue.splice(stageToRemoveIndex, 1);
                newFilters[stageFilterId].Value = stageValue.join(",");
              }
            }
          });
        }

        setFilters(newFilters ?? []);

        setOrder(data.Order ?? {});

        setUserSelectDataOrigin(data.UserSelectDataOrigin ?? []);
        data.Headers.forEach((header) => {
          if (header.Key === "Status") {
            setStatusDataOrigin(
              (header.DataOrigin as StatusDataOriginType[]) ?? []
            );
          }
          if (header.Key === "Stage") {
            setStageDataOrigin(
              (header.DataOrigin as StageDataOriginType[]) ?? []
            );
          }
          if (header.Key === "Priority") {
            setPriorityDataOrigin(
              (header.DataOrigin as PriorityDataOriginType[]) ?? []
            );
          }
        });

        const totals = data.Totals ?? {};
        let newTotals = {};
        data.Headers.forEach(({ Id }) => {
          if (Object.hasOwn(totals, Id)) {
            newTotals = {
              ...newTotals,
              [Id]: totals[Id],
            };
          }
        });
        setTotalsData(newTotals ?? {});

        setFirstLoad(false);
      },
    });

  const [exportPipelineToExcel] = useMutation<string>({
    func: "Ver2-Pipeline-epte",
    onSuccess: (response) => {
      if (response) window.open(response);
    },
  });

  const getCellType = (cellKey: string) => {
    let key = "";

    tableData.Headers.forEach((header) => {
      if (header.Id === cellKey) {
        key = header.Key;
      }
    });

    return key;
  };

  const getCellDataType = (cellKey: string) => {
    let dataType = "";

    tableData.Headers.forEach((header) => {
      if (header.Id === cellKey && header.DataType) {
        dataType = header.DataType;
      }
    });

    return dataType;
  };

  const callGetPipelineReport = ({
    IdTeam = idTeam,
    IdReport = selectedReportForBack,
    teamsAndFiltersInfo = teamsAndFilters,
    changedReport = false,
  }: CallGetPipelineReportParams = {}): void => {
    if (IdTeam !== 0) {
      if (!zusCurrentPage) {
        setCurrentPage(1);
      }
      setHasMoreData(true);

      getPipelineReport({
        args: {
          IdTeam,
          IdReport: IdReport === 0 ? null : IdReport,
        },
        options: {
          pags: {
            CurrentPage: zusCurrentPage ? zusCurrentPage : 1,
          },
        },
        shippedData: {
          IdTeam,
          selectedReport: IdReport,
          data: teamsAndFiltersInfo,
          currentPage: zusCurrentPage ? zusCurrentPage : 1,
          changedReport,
        },
      });
    }
  };

  const softReload = (page?: number) => {
    setIsLoading(true);

    getPipelineReport({
      args: {
        IdTeam: idTeam,
        IdReport: selectedReportForBack,
      },
      options: {
        pags: {
          CurrentPage: page ?? currentPage ?? 1,
        },
      },
      shippedData: {
        preserveInformation: true,
        data: teamsAndFilters,
        currentPage: page ?? currentPage ?? 1,
        pags: {
          CurrentPage: page ?? currentPage ?? 1,
        },
      },
    });
  };

  useEffect(() => {
    if (currentPage > 1 && hasMoreData && !gettingPipelineReport) {
      softReload(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const [registerFilter] = useMutation({
    func: "Ver2-Pipeline-rf",
    onSuccess: () => {
      let idReport: number | string | null = localStorage.getItem(
        `${idTeam}-PipelineReport`
      );

      callGetPipelineReport({
        IdTeam: idTeam,
        IdReport: Number(idReport) || null,
        teamsAndFiltersInfo: teamsAndFilters,
      });
    },
  });

  const handleRegisterFilter = (args: {
    IdTeam: number;
    IdReport: string | null;
    Filters: Filter[];
    from?: string;
  }) => {
    const { IdTeam, IdReport, Filters } = args;

    let hasFilterProcess = false;
    let hasFilterStage = false;
    let stageFilterId = -1;
    let filterProcessIds: number[] = [];
    let newFilters = _.cloneDeep(Filters);

    Filters.forEach((filter, i) => {
      if (filter.IdReportColumn.includes("ProcessTemplateName")) {
        hasFilterProcess = true;
        filterProcessIds = `${filter.Value}`.split(",").map((id) => Number(id));
      }
      if (filter.IdReportColumn.includes("Stage")) {
        hasFilterStage = true;
        stageFilterId = i;
      }
    });

    let newStageDataOrigin: StageDataOriginType[] = [];

    if (hasFilterProcess) {
      let stageHeaderId = -1;
      tableData.Headers.forEach((header, i) => {
        if (header.Key === "Stage") {
          newStageDataOrigin = header.DataOrigin as StageDataOriginType[];
          stageHeaderId = i;
        }
      });

      if (filterProcessIds[0] !== 0) {
        newStageDataOrigin = newStageDataOrigin.filter((stageDO) =>
          filterProcessIds.includes(stageDO.IdProcessTemplate)
        );
      }

      if (stageHeaderId !== -1) {
        tableData.Headers[stageHeaderId].DataOrigin = newStageDataOrigin;
      }
    }

    if (hasFilterProcess && hasFilterStage && stageFilterId !== -1) {
      const allStages = newStageDataOrigin.map((stage) => stage.IdStage);

      let selectedStages = [
        ...`${
          newFilters[stageFilterId].Value ? newFilters[stageFilterId].Value : ""
        }`
          .split(",")
          .map((id) => Number(id)),
      ];

      selectedStages.forEach((selectedStage) => {
        if (!allStages.includes(selectedStage)) {
          let stageValue = `${
            newFilters[stageFilterId].Value
              ? newFilters[stageFilterId].Value
              : ""
          }`.split(",");
          const stageToRemoveIndex = stageValue.findIndex(
            (stage) => Number(stage) === selectedStage
          );

          if (stageToRemoveIndex !== -1 && filterProcessIds[0] !== 0) {
            stageValue.splice(stageToRemoveIndex, 1);
            newFilters[stageFilterId].Value = stageValue.join(",");
          }
        }
      });
    }

    if (selectedFilter) setSelectedFilter(null);

    setFilters(newFilters);

    registerFilter({
      args: {
        IdTeam,
        IdReport,
        Filters: newFilters,
      },
    });
  };

  const [registerOrder] = useMutation({
    func: "Ver2-Pipeline-ro",
    onSuccess: () => {
      let idReport: number | string | null = localStorage.getItem(
        `${idTeam}-PipelineReport`
      );

      callGetPipelineReport({
        IdTeam: idTeam,
        IdReport: Number(idReport) || null,
        teamsAndFiltersInfo: teamsAndFilters,
      });
    },
  });

  const reloadReports = (
    IdTeam: number,
    IdReport: number | null = selectedReport
  ) => {
    getSavedPipelineReports({
      args: {
        IdTeam,
      },
      shippedData: { IdTeam, IdReport },
    });
  };

  const handleSetIdTeam = (
    IdTeam: number,
    data: GetTeamsAndFiltersResponse | null
  ): void => {
    setIdTeam(IdTeam);
    localStorage.removeItem(`${IdTeam}-PipelineOrder`);
    localStorage.removeItem(`${IdTeam}-PipelineFilters`);
    localStorage.removeItem(`${IdTeam}-ProcessTemplateOrder`);
    localStorage.removeItem(`${IdTeam}-ProcessExecutionOrder`);
    let idReport: number | string | null = localStorage.getItem(
      `${IdTeam}-PipelineReport`
    );
    setSelectedFilter(null);
    if (idReport) {
      idReport = parseInt(idReport, 10);
    }
    setSelectedReport(idReport as number | null);
    if (idTeam !== IdTeam) {
      callGetPipelineReport({
        IdTeam,
        IdReport: idReport as number | null,
        teamsAndFiltersInfo: data,
        changedReport: true,
      });
    }
    reloadReports(IdTeam);
  };

  const handleExport = () => {
    exportPipelineToExcel({
      args: {
        IdTeam: idTeam,
        IdReport: selectedReportForBack,
      },
    });
  };

  const onOrder = (headerId: string) => {
    let orderAux = { ...order };

    if (orderAux[headerId] === "DESC") {
      orderAux[headerId] = "ASC";
    } else if (orderAux[headerId] === "ASC") {
      delete orderAux[headerId];
    } else {
      orderAux[headerId] = "DESC";
    }

    if (Object.keys(orderAux).length > 1) {
      const differentKeys = Object.keys(orderAux).filter(
        (key) => key !== headerId
      );

      differentKeys.forEach((key) => {
        delete orderAux[key];
      });
    }

    let idReport: number | string | null =
      localStorage.getItem(`${idTeam}-PipelineReport`) ?? null;
    setOrder(orderAux);
    registerOrder({
      args: {
        IdTeam: idTeam,
        IdReport: idReport,
        Order: orderAux,
      },
    });
  };

  const getFilterOptions = () => {
    const validFilters = [...tableData.Headers].filter(
      // @ts-ignore
      (header) => header.FilterType !== "no_filter"
    );

    const filterOptions = validFilters.map((header) => {
      let type = header.DataType ?? header.FilterType ?? "";
      // @ts-ignore
      if (type === "date_range") {
        // @ts-ignore
        type = "time";
      }
      const IconType = DataTypeIcons[type];

      return {
        label: (
          <Row align="middle">
            <Row
              align="middle"
              justify="center"
              style={{
                width: "22px",
                height: "22px",
                display: "flex",
              }}
              wrap={false}
            >
              {IconType && (
                <img
                  style={{
                    width: "14px",
                    height: "14px",
                    ...IconType.extraStyles,
                  }}
                  alt={IconType?.label || ""}
                  src={IconType.icon}
                />
              )}
            </Row>
            <FilterOptionLabel>
              <p>{header.Label}</p>
            </FilterOptionLabel>
          </Row>
        ),
        asSelected: (
          <Row align="middle">
            <Row
              align="middle"
              justify="center"
              style={{
                minHeight: "22px",
                display: "flex",
              }}
              wrap={false}
            >
              {IconType && (
                <img
                  style={{
                    width: "14px",
                    height: "14px",
                    ...IconType.extraStyles,
                  }}
                  alt={IconType?.label || ""}
                  src={IconType.icon}
                />
              )}
            </Row>
            <FilterOptionLabel>
              <p>{header.Label}</p>
            </FilterOptionLabel>
          </Row>
        ),
        value: header.Id,
        searchableValues: header.Label,
      };
    });

    return filterOptions;
  };

  const getHeaderDataOrigin = (header: Header) => {
    let DataOrigin = header.DataOrigin;
    switch (header.Key) {
      case "Status":
        (DataOrigin as StatusDataOriginType[]) = (
          DataOrigin as StatusDataOriginType[]
        )?.map(({ Value }) => ({
          Label: (
            <StatusComponent
              value={Value}
              statusDataOrigin={header.DataOrigin as StatusDataOriginType[]}
              justify="start"
            />
          ),
          asSelected: (
            <StatusComponent
              value={Value}
              statusDataOrigin={header.DataOrigin as StatusDataOriginType[]}
              justify="center"
              isSelected
            />
          ),
          Value,
        }));
        break;
      case "Priority":
        (DataOrigin as StatusDataOriginType[]) = (
          DataOrigin as PriorityDataOriginType[]
        )?.map(({ IdTaskPriority }) => ({
          Label: (
            <PriorityComponent
              value={IdTaskPriority}
              priorityDataOrigin={header.DataOrigin as PriorityDataOriginType[]}
              justify="start"
            />
          ),
          asSelected: (
            <PriorityComponent
              value={IdTaskPriority}
              priorityDataOrigin={header.DataOrigin as PriorityDataOriginType[]}
              justify="center"
              isSelected
            />
          ),
          Value: IdTaskPriority,
        }));
        break;
      case "Stage":
        (DataOrigin as StatusDataOriginType[]) = (
          DataOrigin as StageDataOriginType[]
        )?.map(({ IdStage }) => ({
          Label: (
            <StageComponent
              value={IdStage}
              stageDataOrigin={header.DataOrigin as StageDataOriginType[]}
            />
          ),
          Value: IdStage,
        }));
    }
    if (header.FilterType === "users_select") {
      (DataOrigin as User[]) = [...UserSelectDataOrigin];
    }

    return DataOrigin;
  };

  const onChangeFilter = (IdReportColumn: string) => {
    const header = _.find(tableData.Headers, { Id: IdReportColumn });
    if (header) {
      setSelectedFilter({
        IdReportColumn: header.Id,
        Value: "",
        Label: header.Label ?? header.Key,
        DataOrigin: getHeaderDataOrigin(header) ?? "",
        FilterType: header.FilterType,
      });
    } else {
      setSelectedFilter(null);
    }
  };

  const onChangeFilterValue = (value: any) => {
    if (selectedFilter) {
      const newFilter: FilterOption = { ...selectedFilter, Value: value };
      setSelectedFilter(newFilter);
    }
  };

  const onAddFilter = () => {
    if (selectedFilter) {
      let idReport: number | string | null =
        localStorage.getItem(`${idTeam}-PipelineReport`) ?? null;

      handleRegisterFilter({
        IdTeam: idTeam,
        IdReport: idReport,
        Filters: [...filters, selectedFilter] as any,
        from: "onAddFilter",
      });
      setSelectedFilter(null);
    }
  };

  const onRemoveFilter = (IdReportColumn: string) => {
    if (IdReportColumn) {
      let idReport: number | string | null =
        localStorage.getItem(`${idTeam}-PipelineReport`) ?? null;

      const newFilters = filters.filter(
        (filter) => filter.IdReportColumn !== IdReportColumn
      );

      handleRegisterFilter({
        IdTeam: idTeam,
        IdReport: idReport,
        Filters: newFilters as any,
        from: "onRemoveFilter",
      });
    }
  };

  const onSubmitUpdateFilter = (filterToUpdate: Filter) => {
    let idReport: number | string | null =
      localStorage.getItem(`${idTeam}-PipelineReport`) ?? null;

    const filtersAux = [...filters];

    const newFilters = filtersAux.map((filter) => {
      if (filter.IdReportColumn === filterToUpdate.IdReportColumn) {
        return filterToUpdate;
      } else {
        return filter;
      }
    });

    handleRegisterFilter({
      IdTeam: idTeam,
      IdReport: idReport,
      Filters: newFilters as any,
      from: "onSubmitUpdateFilter",
    });
  };

  const onUpdateFilter = (filterToUpdate: Filter, value: any) => {
    const filtersAux = [...filters];

    const newFilters = filtersAux.map((filter) => {
      if (filter.IdReportColumn === filterToUpdate.IdReportColumn) {
        return { ...filter, Value: value };
      } else {
        return filter;
      }
    });

    if (
      !["currency", "string", "phone_number"].includes(
        filterToUpdate.FilterType
      )
    ) {
      let idReport: number | string | null =
        localStorage.getItem(`${idTeam}-PipelineReport`) ?? null;
      handleRegisterFilter({
        IdTeam: idTeam,
        IdReport: idReport,
        Filters: newFilters as any,
        from: "onUpdateFilter",
      });
    } else {
      setFilters(newFilters);
    }
  };

  const getSelectedFilter = (filterSelected: Filter): FilterOption => {
    const header = _.find(tableData.Headers, {
      Id: filterSelected.IdReportColumn,
    });

    if (header) {
      const filter = {
        IdReportColumn: header.Id,
        Value: filterSelected.Value,
        Label: header.Label ?? header.Key,
        DataOrigin: getHeaderDataOrigin(header) ?? "",
        FilterType: header.FilterType,
      };

      if (filter.FilterType === "users_select") {
        // @ts-ignore
        filter.DataOrigin = UserSelectDataOrigin;
      }

      return filter;
    }
    return {} as FilterOption;
  };

  const canRenderDetailPending = () => {
    const id = detailPendingId as any;
    if (typeof id === "number") {
      return Boolean(id);
    }
    if (Array.isArray(id)) {
      return id.length > 0;
    }

    return false;
  };

  const onChangeReport = (idReport: number | null) => {
    setSelectedFilter(null);

    localStorage.setItem(
      `${idTeam}-PipelineReport`,
      idReport?.toString() || ""
    );
    setSelectedReport(idReport);
    callGetPipelineReport({
      IdReport: idReport === 0 ? null : idReport,
      changedReport: true,
    });
  };

  const getCurrentReportName = (
    reportOptions: Option[],
    selectedReport: number | null
  ) => {
    const report = reportOptions.find(({ value }) => value === selectedReport);
    if (report) {
      return report.label as string;
    }
    return null;
  };

  const getOrderDirection = (header: Header): OrderValue => {
    const currentOrder = order[header.Id];

    if (currentOrder) {
      return currentOrder;
    }

    if (header.FilterType) {
      return "NONE";
    }

    return null;
  };

  const canDeleteSelectedProcesses = () => {
    selectedProcesses.forEach((id) => {
      const process = tableData.Rows.find(
        (row) => row.IdProcessExecution === id
      );
      if (process?.Permissions.UserCanDeleteProcess) {
        return false;
      }
    });

    return true;
  };

  const canCancelSelectedProcesses = () => {
    selectedProcesses.forEach((id) => {
      const process = tableData.Rows.find(
        (row) => row.IdProcessExecution === id
      );
      if (process?.Permissions.UserCanCancelProcess) {
        return false;
      }
    });

    return true;
  };

  const handleSelectProcess = (idProcess: number) => {
    if (selectedProcesses.includes(idProcess)) {
      setSelectedProcesses(selectedProcesses.filter((id) => id !== idProcess));
      return;
    }
    setSelectedProcesses([...selectedProcesses, idProcess]);
  };

  const handleSelectAllProcess = () => {
    if (selectedProcesses.length === tableData.Rows.length) {
      setSelectedProcesses([]);
      return;
    }

    setSelectedProcesses(tableData.Rows.map((row) => row.IdProcessExecution));
  };

  const canExport = () => {
    if (!teamsAndFilters) return false;
    const team = teamsAndFilters.Teams.find((team) => team.IdTeam === idTeam);
    if (!team) return false;

    return team.CanExportReportData;
  };

  const getMinMaxWidth = (key: CellTypes, dataType: DataTypes | null) => {
    if (key === CellTypes.EXECUTION_NAME) {
      return { minWidth: "120px", maxWidth: "271px" };
    }

    if (key === CellTypes.DURATION) {
      return { minWidth: "80px", maxWidth: "271px" };
    }

    if (key === CellTypes.PROCESS_TEMPLATE_NAME) {
      return { minWidth: "120px", maxWidth: "271px" };
    }

    if (key === CellTypes.STUCK_TIME) {
      return { minWidth: "80px", maxWidth: "271px" };
    }

    if (key === CellTypes.TASK_DURATION) {
      return { minWidth: "80px", maxWidth: "271px" };
    }

    if (key === CellTypes.START_DATE) {
      return { minWidth: "80px", maxWidth: "271px" };
    }

    if (key === CellTypes.END_DATE) {
      return { minWidth: "80px", maxWidth: "271px" };
    }

    if (key === CellTypes.EXECUTION_ID) {
      return { minWidth: "90px", maxWidth: "271px" };
    }

    if (key === CellTypes.STARTED_USER) {
      return { minWidth: "120px", maxWidth: "271px" };
    }

    if (key === CellTypes.USERS_INVOLVED) {
      return { minWidth: "90px", maxWidth: "271px" };
    }

    if (key === CellTypes.STATUS) {
      return { minWidth: "80px", maxWidth: "271px" };
    }

    if (key === CellTypes.STAGE) {
      return { minWidth: "80px", maxWidth: "271px" };
    }

    if (key === CellTypes.PRIORITY) {
      return { minWidth: "80px", maxWidth: "271px" };
    }

    if (key === CellTypes.PROCESS_TRIGGER) {
      return { minWidth: "120px", maxWidth: "271px" };
    }

    if (key === CellTypes.CURRENT_EXECUTORS) {
      return { minWidth: "100px", maxWidth: "271px" };
    }

    if (key === CellTypes.LAST_INTERACTION) {
      return { minWidth: "90px", maxWidth: "271px" };
    }

    if (
      key === CellTypes.INFO_FROM_FIELD ||
      key === CellTypes.INFO_FROM_COLUMN
    ) {
      if (dataType === DataTypes.STRING || dataType === DataTypes.LOCATION) {
        return { minWidth: "80px", maxWidth: "271px" };
      }

      if (
        dataType === DataTypes.SELECT ||
        dataType === DataTypes.MULTI_SELECT
      ) {
        return { minWidth: "80px", maxWidth: "271px" };
      }

      if (dataType === DataTypes.DATE || dataType === DataTypes.TIME) {
        return { minWidth: "80px", maxWidth: "271px" };
      }

      if (dataType === DataTypes.NUMBER) {
        return { minWidth: "80px", maxWidth: "271px" };
      }

      if (dataType === DataTypes.USERS_SELECT) {
        return { minWidth: "80px", maxWidth: "271px" };
      }

      if (
        dataType === DataTypes.PHONE_NUMBER ||
        dataType === DataTypes.CURRENCY
      ) {
        return { minWidth: "80px", maxWidth: "271px" };
      }

      if (
        dataType === DataTypes.FILE ||
        dataType === DataTypes.PDF_AUTOGENERATED
      ) {
        return { minWidth: "80px", maxWidth: "271px" };
      }
    }

    return { minWidth: "80px", maxWidth: "271px" };
  };

  const getReportsSelectWidth = (selectedReport: number) => {
    const reportLabel =
      reportOptions.find(({ value }) => value === selectedReport)?.asSelected ??
      "";

    if (reportLabel.length > 30) {
      return "350px";
    }

    if (reportLabel.length > 22) {
      return "300px";
    }

    return "236px";
  };

  const CanSaveReport =
    !selectedReportForBack &&
    (filters.length > 0 || Object.keys(order).length > 0);

  // add validation if badge is open
  const maxScrollHeight = AlertData.IsActive
    ? window.innerHeight - 320 - 80
    : window.innerHeight - 320;

  return (
    <PipelineContainer>
      {openConfigReport && (
        <ConfigReportModal
          idTeam={idTeam}
          idReport={selectedReport ?? null}
          reportName={getCurrentReportName(reportOptions, selectedReport)}
          onClose={() => setOpenConfigReport(false)}
          callGetPipelineReport={callGetPipelineReport}
          onChangeReport={(idReport) => {
            getSavedPipelineReports({
              args: {
                IdTeam: idTeam,
              },
              shippedData: { IdTeam: idTeam, IdReport: idReport },
            });
            onChangeReport(idReport);
          }}
        />
      )}

      {openStopProcess && (
        <StopProcessModal
          selectedProcesses={selectedProcesses}
          onCancel={() => setOpenStopProcess(false)}
          onSuccess={() => {
            setOpenStopProcess(false);
            callGetPipelineReport();
          }}
          processes={tableData.Rows}
        />
      )}
      {openDeleteProcess && (
        <DeleteProcessModal
          selectedProcesses={selectedProcesses}
          onCancel={() => setOpenDeleteProcess(false)}
          onSuccess={() => {
            setOpenDeleteProcess(false);
            callGetPipelineReport();
          }}
          processes={tableData.Rows}
        />
      )}
      {canRenderDetailPending() && (
        <DetailPending
          idPending={String(detailPendingId)}
          onClose={() => setDetailPendingId(null)}
          onChangePending={(pendingId) => {
            setDetailPendingId(null);
            setTimeout(() => {
              setDetailPendingId(pendingId);
            }, 1);
          }}
          reloadReport={callGetPipelineReport}
        />
      )}
      {saveReport && (
        <SaveReportModal
          setSelectedReport={onChangeReport}
          IdTeam={idTeam}
          onClose={() => setSaveReport(false)}
          reloadReports={() => reloadReports(idTeam)}
        />
      )}
      {!!idProcessReschedule && (
        <RescheduleProcessModal
          IdProcessExecution={idProcessReschedule}
          closeModal={() => setIdProcessReschedule(null)}
          onSuccess={reload}
        />
      )}
      <ViewTitle
        teams={teamsAndFilters?.Teams}
        idTeam={idTeam}
        onChangeTeam={(idTeam) => handleSetIdTeam(idTeam, teamsAndFilters)}
        rightElement={
          <NavigationComponents>
            <ReportContainer>
              <CustomReportTitleContainer>
                {reportOptions.length === 1 ? (
                  <Tooltip
                    placement="bottom"
                    title="Guarda los filtros para conservar registro de este reporte"
                  >
                    <AiOutlineInfoCircle size={15} />
                  </Tooltip>
                ) : (
                  <div style={{ width: "15px", height: "100%" }} />
                )}
              </CustomReportTitleContainer>
              <ReportContent>
                <GeestSelect
                  value={loadingReports ? 0 : selectedReport || 0}
                  options={reportOptions}
                  onChange={onChangeReport}
                  placeholderSelect="Nuevo reporte"
                  optionPadding="0 10px"
                  canHoverOption={!editingReport}
                  onCloseList={() => setEditingReport(false)}
                  $width={getReportsSelectWidth(
                    loadingReports ? 0 : selectedReport || 0
                  )}
                  $height="32px"
                  $maxHeight="32px"
                  valueNecesary
                  renderPlaceholderIfNoValueInList
                  itemsContainerContentWidth="100%"
                />
                {CanSaveReport && (
                  <Button
                    size="xsmall"
                    Icon={AiOutlineSave}
                    iconSize={25}
                    onClick={() => setSaveReport(true)}
                  />
                )}
              </ReportContent>
            </ReportContainer>

            <ReportOptionsWrapper>
              <Button
                type="primary"
                style={{ width: "200px" }}
                onClick={() => setOpenConfigReport(true)}
                // @ts-ignore
                Icon={ConfigReportIcon}
              >
                Configurar reporte
              </Button>

              {canExport() && (
                <ReportOptionsModal
                  open={showReportOptions}
                  onOpen={() => setShowReportOptions(true)}
                  onCancel={() => setShowReportOptions(false)}
                  handleExport={handleExport}
                />
              )}
            </ReportOptionsWrapper>
          </NavigationComponents>
        }
      >
        <ReportsHeader>
          {Routes.map(({ title, path }, idx) => (
            <StyledTab
              to={`/home/reports/${loading ? "pipeline" : path}`}
              $active={pathname.includes(path)}
              key={idx}
            >
              {title}
            </StyledTab>
          ))}
        </ReportsHeader>
      </ViewTitle>
      {
        <>
          <Row
            align="bottom"
            justify="space-between"
            style={{ marginBottom: "0.5rem" }}
          >
            <Row align="top">
              <Row
                align="middle"
                onClick={() => setFiltersVisible(!filtersVisible)}
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                  marginTop: "5px",
                }}
              >
                <ArrowContainer open={filtersVisible}>
                  {filtersVisible ? (
                    <IoIosArrowUp size={20} color="white" />
                  ) : (
                    <IoIosArrowDown size={20} color="white" />
                  )}
                </ArrowContainer>
                <FilterLabel>Filtros</FilterLabel>
              </Row>
              {!filtersVisible && !!filters.length && (
                <FilterCountContainer onClick={() => setFiltersVisible(true)}>
                  <FilterIconContainer>
                    <AiOutlineFilter size={14} />
                  </FilterIconContainer>
                  {`${filters.length} filtro${
                    filters.length === 1 ? "" : "s"
                  } aplicado${filters.length === 1 ? "" : "s"}`}
                </FilterCountContainer>
              )}
              {filtersVisible && (
                <>
                  <GeestSelect
                    value={selectedFilter?.IdReportColumn ?? null}
                    onChange={onChangeFilter}
                    options={getFilterOptions()}
                    placeholderSelect="Filtrar columna..."
                    style={{ marginRight: "5px" }}
                    $width="236px"
                  />
                  {!!selectedFilter && (
                    <DynamicFilter
                      selectedFilter={selectedFilter}
                      onChangeFilter={onChangeFilterValue}
                      onPressEnter={onAddFilter}
                      autoFocus
                    />
                  )}
                  {selectedFilter?.Value && (
                    <Button
                      type="primary"
                      style={{ marginLeft: "5px" }}
                      onClick={onAddFilter}
                    >
                      Aplicar filtro
                    </Button>
                  )}
                </>
              )}
            </Row>
          </Row>
          <FiltersConteiner
            $filtersVisible={filtersVisible && filters.length > 0}
          >
            {filters.map((filter, index) => (
              <Row key={filter.IdReportColumn}>
                <FilterContainer align="middle" justify="space-between">
                  {getSelectedFilter(filter)?.Label}
                  <VerticalDivider />
                  <DynamicFilter
                    selectedFilter={getSelectedFilter(filter)}
                    onChangeFilter={(val) => onUpdateFilter(filter, val)}
                    onSubmit={() => onSubmitUpdateFilter(filter)}
                  />
                </FilterContainer>
                <CancelIcon
                  onClick={() => onRemoveFilter(filter.IdReportColumn)}
                  style={{ marginLeft: "-10px", marginTop: "-2px" }}
                  // @ts-ignore
                  hoverColor="#014c9b"
                />
              </Row>
            ))}
          </FiltersConteiner>
          <Controls>
            <ReloadPipelineIcon
              filled
              hoverContainer
              onClick={() => {
                if (gettingPipelineReport) return;
                callGetPipelineReport();
              }}
            />

            {selectedProcesses.length > 0 && (
              <ProceseesActions>
                <p>
                  {`${selectedProcesses.length} ${
                    selectedProcesses.length === 1
                      ? "seguimiento seleccionado"
                      : "seguimientos seleccionados"
                  }`}
                </p>

                <ActionsContainer>
                  {canDeleteSelectedProcesses() && (
                    <DeleteIcon
                      filled
                      hoverContainer
                      onClick={() => setOpenDeleteProcess(true)}
                    />
                  )}
                  {canCancelSelectedProcesses() && (
                    <StopProcessIcon
                      hoverContainer
                      onClick={() => setOpenStopProcess(true)}
                    />
                  )}
                </ActionsContainer>
              </ProceseesActions>
            )}
          </Controls>

          <ContentWrapper>
            {firstLoad && (
              <LoadingContainer>
                <Loading />
              </LoadingContainer>
            )}
            <Content $openFilters={filtersVisible} $height={maxScrollHeight}>
              <PipelineTableContainer
                $openFilters={filtersVisible}
                $height={maxScrollHeight}
                ref={tableRef}
                id="tableContainer"
              >
                <PipelineTable stickyHeader={false} size="small">
                  <PipelineTableHead>
                    <PipelineTableRow>
                      {tableData.Headers.map((header, idx) => {
                        const title = header.Label ?? header.Key ?? "";
                        const direction = getOrderDirection(header);
                        const IconType = DataTypeIcons[header.DataType || ""];
                        const { minWidth, maxWidth } = getMinMaxWidth(
                          header.Key,
                          header.DataType
                        );

                        if (`${title}`.length >= 30) {
                          return (
                            <PipelineTableHeadCell
                              key={header.Id}
                              className={classNames({
                                fixed:
                                  idx === 0 && tableData.isFirstColumnFixed,
                              })}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (gettingPipelineReport && header.FilterType)
                                  return;
                                onOrder(header.Id);
                              }}
                              minWidth={minWidth}
                              maxWidth={maxWidth}
                              style={
                                header.FilterType ? { cursor: "pointer" } : {}
                              }
                            >
                              <TableHeadContent>
                                <TableHeaderLabel>
                                  {idx === 0 && (
                                    <StyledCheckboxContainer
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleSelectAllProcess();
                                      }}
                                    >
                                      <StyledCheckbox
                                        checked={
                                          selectedProcesses.length ===
                                          tableData.Rows.length
                                        }
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleSelectAllProcess();
                                        }}
                                      />
                                    </StyledCheckboxContainer>
                                  )}
                                  {IconType && (
                                    <img
                                      style={{
                                        width: "14px",
                                        height: "14px",
                                        ...IconType.extraStyles,
                                      }}
                                      alt={IconType?.label || ""}
                                      src={IconType.icon}
                                    />
                                  )}

                                  <Tooltip
                                    title={title
                                      .replace(/\n/gi, "&nbsp; \n")
                                      .replace(
                                        /:\w+:/gi,
                                        (name) => emoji.getUnicode(name) ?? name
                                      )}
                                    placement="top"
                                  >
                                    <TableHeaderName>
                                      {_.truncate(
                                        title
                                          .replace(/\n/gi, "&nbsp; \n")
                                          .replace(
                                            /:\w+:/gi,
                                            (name) =>
                                              emoji.getUnicode(name) ?? name
                                          ),
                                        { length: 30 }
                                      )}
                                    </TableHeaderName>
                                  </Tooltip>

                                  {header.FilterType && (
                                    <TableHeaderIconContainer
                                      unsetted={direction === "NONE"}
                                    >
                                      <OrderIcon Direction={direction} />
                                    </TableHeaderIconContainer>
                                  )}
                                </TableHeaderLabel>
                              </TableHeadContent>
                            </PipelineTableHeadCell>
                          );
                        }

                        return (
                          <PipelineTableHeadCell
                            key={header.Id}
                            className={classNames({
                              fixed: idx === 0 && tableData.isFirstColumnFixed,
                            })}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (gettingPipelineReport && header.FilterType)
                                return;
                              onOrder(header.Id);
                            }}
                            minWidth={minWidth}
                            maxWidth={maxWidth}
                            style={
                              header.FilterType ? { cursor: "pointer" } : {}
                            }
                          >
                            <TableHeadContent>
                              <TableHeaderLabel>
                                {idx === 0 && (
                                  <StyledCheckboxContainer
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSelectAllProcess();
                                    }}
                                  >
                                    <StyledCheckbox
                                      checked={
                                        selectedProcesses.length ===
                                        tableData.Rows.length
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleSelectAllProcess();
                                      }}
                                    />
                                  </StyledCheckboxContainer>
                                )}

                                {IconType && (
                                  <img
                                    style={{
                                      width: "14px",
                                      height: "14px",
                                      ...IconType.extraStyles,
                                    }}
                                    alt={IconType?.label || ""}
                                    src={IconType.icon}
                                  />
                                )}

                                <TableHeaderName>
                                  {title
                                    .replace(/\n/gi, "&nbsp; \n")
                                    .replace(
                                      /:\w+:/gi,
                                      (name) => emoji.getUnicode(name) ?? name
                                    )}
                                </TableHeaderName>

                                {header.FilterType && (
                                  <TableHeaderIconContainer
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onOrder(header.Id);
                                    }}
                                    unsetted={direction === "NONE"}
                                  >
                                    <OrderIcon Direction={direction} />
                                  </TableHeaderIconContainer>
                                )}
                              </TableHeaderLabel>
                            </TableHeadContent>
                          </PipelineTableHeadCell>
                        );
                      })}
                    </PipelineTableRow>
                  </PipelineTableHead>

                  <PipelineTableBody>
                    {tableData.Rows.map((Row: RowData, index: number) => (
                      <TableRow
                        idProcessExecution={Row.IdProcessExecution}
                        onClickRow={() => {
                          setZusHasMoreData(hasMoreData);
                          setZusUsersSelectDataOrigin(UserSelectDataOrigin);
                          setZusStatusDataOrigin(StatusDataOrigin);
                          setZusStageDataOrigin(StageDataOrigin);
                          setZusPriorityDataOrigin(PriorityDataOrigin);
                          setZusIdTeam(idTeam);
                          zusSetFilters(filters);
                          zusSetOrder(order);
                          setZusReportOptions(reportOptions);
                          setZusSelectedReport(selectedReport);
                          setZusTableData(tableData);
                          setZusTotalsData(totalsData);
                          setZusCurrentPage(
                            Math.trunc(index / rowsPerPage) + 1
                          );
                          setZusScrollTop(tableRef.current?.scrollTop || 0);
                          history.push({
                            pathname: `/home/reports/detail/${Row.IdProcessExecution}`,
                          });
                        }}
                        headers={tableData.Headers}
                        row={Row}
                        isFirstColumnFixed={tableData.isFirstColumnFixed}
                        handleSelectProcess={handleSelectProcess}
                        selectedProcess={selectedProcesses}
                        getCellType={
                          getCellType as (headerId: string) => CellTypes
                        }
                        getCellDataType={
                          getCellDataType as (headerId: string) => DataTypes
                        }
                        getMinMaxWidth={getMinMaxWidth}
                        // data origin
                        userSelectDataOrigin={UserSelectDataOrigin}
                        statusDataOrigin={StatusDataOrigin}
                        stageDataOrigin={StageDataOrigin}
                        priorityDataOrigin={PriorityDataOrigin}
                      />
                    ))}

                    {!firstLoad && tableData.showTotals && (
                      <InvisibleRows
                        containerHeight={tableContainerHeight}
                        headerHeight={47}
                        rowHeight={47}
                        footerHeight={47}
                        rowCount={tableData.Rows.length}
                      />
                    )}

                    {!firstLoad
                      ? hasMoreData &&
                        tableData.Rows.length >= 25 &&
                        Array.from(Array(5).keys()).map((_, i) => (
                          <PipelineTableRow key={`skeleton-row-${i}`}>
                            {tableData.Headers.map(
                              (header: Header, j: number) => {
                                const { minWidth, maxWidth } = getMinMaxWidth(
                                  header.Key,
                                  header.DataType
                                );

                                return (
                                  <PipelineTableBodyCell
                                    key={`skeleton-cell-${i}-${j}`}
                                    bg="transparent"
                                    brNone
                                    minWidth={minWidth}
                                    maxWidth={maxWidth}
                                  >
                                    <PipelineTableBodyCellContent>
                                      <DynamicSkeletonCell
                                        cellType={
                                          getCellType(header.Id) as CellTypes
                                        }
                                        dataType={
                                          getCellDataType(
                                            header.Id
                                          ) as DataTypes
                                        }
                                      />
                                    </PipelineTableBodyCellContent>
                                  </PipelineTableBodyCell>
                                );
                              }
                            )}
                          </PipelineTableRow>
                        ))
                      : null}
                  </PipelineTableBody>

                  <PipelineTableFoot>
                    <PipelineTableRow>
                      {tableData.showTotals &&
                        tableData.Headers.map((header: Header, idx: number) => {
                          const totalCell = totalsData[header.Id];

                          if (!totalCell) {
                            return (
                              <PipelineTableFooterCell>
                                <span></span>
                              </PipelineTableFooterCell>
                            );
                          }

                          return (
                            <PipelineTableFooterCell
                              key={idx}
                              className={classNames({
                                fixed:
                                  idx === 0 && tableData.isFirstColumnFixed,
                              })}
                            >
                              {header.Key === "ExecutionName" ? (
                                <TotalsCellContent>
                                  <span>Totales: </span>
                                  <p>
                                    {totalCell} seguimiento
                                    {`${Number(totalCell) > 1 ? "s" : ""}`}
                                  </p>
                                </TotalsCellContent>
                              ) : (
                                <TotalsCellContent>
                                  <p>{totalCell}</p>
                                </TotalsCellContent>
                              )}
                            </PipelineTableFooterCell>
                          );
                        })}
                    </PipelineTableRow>
                  </PipelineTableFoot>
                </PipelineTable>
              </PipelineTableContainer>
            </Content>

            {!firstLoad && tableData.Rows.length === 0 && (
              <NoDataContainer>
                <NoData />
              </NoDataContainer>
            )}
          </ContentWrapper>
        </>
      }
    </PipelineContainer>
  );
};

export default Pipeline;
