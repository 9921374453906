import React from "react";
import { Space, Tag } from "antd";
import { Redirect, Switch, useLocation, Link, Route } from "react-router-dom";
import styled from "styled-components";
import ViewTitle from "../components/ViewTitle/ViewTitle";
import playgroundRoutes from "./routes";

const { CheckableTag } = Tag;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  flex: 1;
  overflow: auto;
`;

const Header = styled(Space)`
  width: 100%;
  background: var(--silver);
  padding: 0.5rem;
  border-radius: 5px;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
`;

const Playground: React.FC = () => {
  const location = useLocation();

  return (
    <Container>
      <ViewTitle>Playground</ViewTitle>
      <Header wrap={false}>
        {playgroundRoutes.map(({ name, path }, key) => (
          <Link key={key} to={`/home/playground${path}`}>
            <CheckableTag checked={location.pathname.endsWith(path)}>
              {name}
            </CheckableTag>
          </Link>
        ))}
      </Header>
      <Body>
        <Switch>
          {playgroundRoutes.map(({ page, path }, key) => (
            <Route
              path={`/home/playground${path}`}
              component={page}
              key={key}
            />
          ))}
          <Redirect
            from="/home/playground"
            to={`/home/playground${playgroundRoutes[0].path}`}
          />
        </Switch>
      </Body>
    </Container>
  );
};

export default Playground;
