import React, { MouseEventHandler, useState } from "react";
import styled from "styled-components";
import _, { noop as NOOP } from "lodash";
import { Row, Tooltip, Typography } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { PlusOutlined } from "@ant-design/icons";
import GeestSelect from "../../../GeestUI/GeestSelect";
import { Typography as T } from "../../../GeestUI";
import { Languages } from "./Dictionary";
import IsRequired from "./helpers/IsRequired";
import { SelectDataOrigin, MultiSelectValue } from "../DynamicInput.d";

const { P } = T;
const { AddOtherOption, Add } = Languages["ESP"];

interface SelectInputProps {
  value: MultiSelectValue;
  configuration: string;
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  dataOrigin: SelectDataOrigin[];
  onChange: (value: MultiSelectValue) => void;
  onBlur?: (e: any) => void;
  onPressEnter?: (e: any) => void;
  onAddOption?: (option: string) => void;
  onRemoveOption?: (newOptions: any) => void;
  extraParams?: {
    center?: boolean;
    tooltipOffset?: number;
    tooltipPlacement?: TooltipPlacement;
    mb?: string;
  };
}

const InputContainer = styled.div<{ isConsult: boolean; mb?: string }>`
  ${({ isConsult, mb = "10px" }) => (isConsult ? "" : `margin-bottom: ${mb};`)}
  width: 100%;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  .ant-input-affix-wrapper-focused {
    border-color: var(--navy-blue) !important;
    box-shadow: none;
  }
`;

const InputStyled = styled.input<{
  $borderColor: string;
  $background: string;
}>`
  flex: 1;
  min-height: 32px;
  height: 32px;
  width: 100%;
  border: 2px solid;
  border-color: ${({ $borderColor }) => $borderColor};
  background-color: ${({ $background }) => $background};
  border-radius: 6px;
  color: #828d9e;
  padding: 3px 5px;
  transition: all 0.35s ease;
  font-size: 14px;
  ::placeholder {
    color: #d8dbdf;
  }
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const SelectInput: React.FC<SelectInputProps> = ({
  value = { Label: null, Value: null, Type: "Key" },
  configuration = "CannotAddOptions",
  isConsult,
  disabled,
  required,
  dataOrigin = [],
  onChange,
  onBlur = NOOP,
  onPressEnter = NOOP,
  onAddOption = NOOP,
  onRemoveOption = NOOP,
  extraParams = { center: false, mb: "10px" },
}) => {
  const [newOption, setNewOption] = useState<string>("");
  const isValidOption = !_.isEmpty(_.trim(newOption));
  const lengthLimit = extraParams.tooltipOffset;
  const tooltipPlacement = extraParams.tooltipPlacement ?? "bottom";

  const getOptionLabel = () => {
    if (value === null) return "";
    return value.Label || "";
  };

  const handleOnChange = (newValue: number) => {
    onChange({
      Type: "Key",
      Label: dataOrigin?.find(({ value }) => value === newValue)?.label || "",
      Value: newValue,
    });
    setNewOption("");
  };

  const onEnterNewOption: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation();
    if (isValidOption) {
      onAddOption(_.trim(newOption));
      setNewOption("");
    }
  };

  const handleOnPressEnter = (e: any) => {
    if (e.key === "Enter") {
      if (isValidOption) {
        onAddOption(_.trim(newOption));
        setNewOption("");
      }
    }
    onPressEnter();
  };

  return (
    <InputContainer isConsult={isConsult} mb={extraParams.mb}>
      {isConsult ? (
        <Row
          justify={extraParams.center ? "center" : "start"}
          align="middle"
          style={{
            overflow: "auto",
            height: "32px",
          }}
        >
          <P mb="0" style={{ color: "#48505e" }}>
            {lengthLimit && getOptionLabel().length > lengthLimit ? (
              <Tooltip title={getOptionLabel()} placement={tooltipPlacement}>
                {`${_.truncate(getOptionLabel(), { length: lengthLimit })}`}
              </Tooltip>
            ) : (
              getOptionLabel()
            )}
          </P>
        </Row>
      ) : (
        <InputsContainer>
          <GeestSelect
            value={value.Type === "Key" ? value.Value : value.Label}
            required={required}
            disabled={disabled}
            options={dataOrigin}
            onChange={handleOnChange}
            onBlur={onBlur}
            onPressEnter={onPressEnter}
          />
          {!disabled && configuration === "CanAddOptions" && (
            <Row align="middle" style={{ marginTop: "5px", width: "100%" }}>
              <InputStyled
                value={newOption}
                placeholder={AddOtherOption}
                $borderColor="#edecec"
                $background={disabled ? "#edecec" : "white"}
                onChange={(e) => {
                  if (e.target.value.includes(",")) return;
                  setNewOption(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleOnPressEnter(e);
                }}
              />
              <Typography.Link
                onClick={onEnterNewOption}
                disabled={!isValidOption}
                style={{
                  whiteSpace: "nowrap",
                  marginLeft: "10px",
                  fontFamily: "Gotham-Bold",
                  color: !isValidOption ? "#828d9e" : "#0273e9",
                  cursor: isValidOption && !disabled ? "pointer" : "auto",
                }}
              >
                <PlusOutlined /> {Add}
              </Typography.Link>
            </Row>
          )}
        </InputsContainer>
      )}
      {required && !isConsult && !Boolean(value) && <IsRequired />}
    </InputContainer>
  );
};

export default SelectInput;
