import React, { useState } from "react";
import moment from "moment";
import _ from "lodash";
import { Row } from "antd";
import {
  Container,
  ContainerCompact,
  MessageNumberBox,
  Division,
  SearchBar,
  NewMessages,
  ChatContainer,
  TitleBar,
  TitleHeader,
  ChatTitle,
  InfiniteScrollStyled,
} from "./styles";
import { useLocalStore } from "../../../hooks";
import { Typography } from "../../../GeestUI";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { ChatFromUserPendings } from "../Pendings.d";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";

const { P, B } = Typography;

const Languages = {
  ENG: {
    loadingLabel: "loading...",
  },
  ESP: {
    loadingLabel: "cargando...",
  },
};

const { loadingLabel } = Languages["ESP"];

const getParsedActivityDate = (date: string) => {
  const isToday =
    moment(date).format("DD/MMM/YY") === moment().format("DD/MMM/YY");
  const isYeserday =
    moment(date).format("MMM/YY") === moment().format("MMM/YY") &&
    Number(moment(date).format("DD")) === Number(moment().format("DD")) - 1;

  if (isToday) {
    return `${moment(date).format("hh:mm A")}`;
  }
  if (isYeserday) {
    return `Ayer ${moment(date).format("hh:mm A")}`;
  }
  return moment(date).format("DD/MMM/YY hh:mm A");
};

const ChatList: React.FC<{
  Chats: ChatFromUserPendings[];
  setIdExecutionChat: (id: number) => void;
}> = ({ Chats, setIdExecutionChat }) => {
  const chatsPerPage = 20;
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [records, setRecords] = useState<number>(chatsPerPage);
  const [showChats, setShowChats] = useLocalStore("showChats", true);
  const totalChatMessages = _.sum(Chats.map((chat) => chat.MessageCount)) || 0;
  const [filter, setFilter] = useState<string>("");

  const filteredChats = (() =>
    _.filter(Chats, (chat) =>
      chat.Name.toLowerCase().includes(filter.toLowerCase())
    ))();

  const loadMore = () => {
    if (records >= [...filteredChats].length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setRecords(records + chatsPerPage);
      }, 100);
    }
  };

  const renderChats = () => {
    let items = [];
    for (let i = 0; i < records; i++) {
      const chat = filteredChats[i];
      if (chat) {
        items.push(
          <div key={`${chat.IdExecutionChat}-${i}`}>
            <ChatContainer
              tabIndex={0}
              onClick={() => setIdExecutionChat(chat.IdExecutionChat)}
              onKeyDown={({ key }) => {
                if (key === "Enter") setIdExecutionChat(chat.IdExecutionChat);
              }}
            >
              <ChatTitle>
                <div className="chat-name">{chat.Name}</div>
                <div className="chat-time">
                  {getParsedActivityDate(chat.SentAt)}
                </div>
              </ChatTitle>

              <Row align="middle" justify="space-between">
                <div style={{ flex: 1 }}>
                  <P mb="0">
                    {!!chat.UserName && <B>{`${chat?.UserName}: `}</B>}
                    {chat.Message}
                  </P>
                </div>
                {chat.MessageCount > 0 && (
                  <NewMessages>{chat.MessageCount}</NewMessages>
                )}
              </Row>
            </ChatContainer>
          </div>
        );
      }
    }
    return items;
  };

  return (
    <>
      {showChats ? (
        <Container>
          <TitleBar>
            <TitleHeader onClick={() => setShowChats(!showChats)}>
              <div className="mainTitle">
                <div style={{ position: "relative" }}>
                  <IoChatbubbleEllipsesOutline size={20} color="#828d9e" />
                  {totalChatMessages > 0 && (
                    <MessageNumberBox>{totalChatMessages}</MessageNumberBox>
                  )}
                </div>
                <p>Chats</p>
              </div>

              <div>
                <IoIosArrowForward size={15} color="#828d9e" />
                <IoIosArrowForward
                  size={15}
                  style={{ marginLeft: "-0.5rem" }}
                  color="#828d9e"
                />
              </div>
            </TitleHeader>

            <SearchBar
              type="text"
              placeholder="Buscar"
              autoComplete="off"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </TitleBar>

          <Division />

          <InfiniteScrollStyled
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={
              <div key="loading">
                <P mb="0">{loadingLabel}</P>
              </div>
            }
            useWindow={false}
          >
            {renderChats()}
          </InfiniteScrollStyled>
        </Container>
      ) : (
        <ContainerCompact onClick={() => setShowChats(!showChats)}>
          <div>
            <IoIosArrowBack size={15} color="#828d9e" />
            <IoIosArrowBack
              size={15}
              style={{ marginLeft: "-0.5rem" }}
              color="#828d9e"
            />
          </div>

          <div style={{ position: "relative" }}>
            <IoChatbubbleEllipsesOutline size={20} color="#828d9e" />
            {totalChatMessages > 0 && (
              <MessageNumberBox>{totalChatMessages}</MessageNumberBox>
            )}
          </div>
        </ContainerCompact>
      )}
    </>
  );
};

export default ChatList;
