export const Languages = {
  ENG: {
    ShareTile: (Title) => `Shara ${Title}`,
    DestinedTeamCodeLabel: "Destined team code:",
    TeamCodeLabel: "Team code",
    DestinedTeamCanShareLabel: "Destined team can share",
    CancelLabel: "Cancel",
    ShareLabel: "Share",
    IntroduceTeamCodeError: "Introduce team code",
    NewRegistryLabel: "New registry",
    RegisrtyLabel: (Id) => `Regisrty ${Id}`,
    MissingFieldLabel: (Title) => `Missing field "${Title}"`,
    SuccessfulCreationLabel: "Successful creation",
    DeleteRowQuestion: "Delete row?",
    DeleteRowLabel: "Delete row",
    DeleteLabel: "Delete",
    SaveLabel: "Save",
    CreateLabel: "Create",
    DataBasesLabel: "Data bases",
    CantDeleteDatabase:
      "No puedes eliminar una carpeta que contenga bases de datos o carpetas dentro",
    EditLabel: "Edit",
    AddColumnLabel: "Add column",
    DeleteTableLabel: "Delete database",
    NewTableNameLabel: "Database",
    NewTablePlaceholder: "Write the name for the new database...",
    CreateNewDataBaseLabel: "New data base",
    ColumnExistsError: "There's already a column with this name",
    ForbiddenOptions: 'Field "Options" cannot include ',
    MissingDataType: "Missing data type",
    MissingName: "Missing name",
    MissingOptions: "Missing options",
    MissingTable: "Missing database",
    MissingColumn: "Missing Column",
    OptionExistsError: "This option already exists",
    NameLabel: "Name:",
    DataTypeLabel: "Data type:",
    OptionsLabel: "Options",
    ImportLabel: "Import from database",
    AddLabel: "Add",
    TableLabel: "database",
    RequiredLabel: "Required",
    ColumnLabel: "Column",
    ExportTable: "Export database",
    NewOptionsLabel: "Different options are allowed",
    FilterLabel: "Filter",
    FiltersLabel: "Filters",
    FiltersPlaceholder: "Filter column...",
    ApplyFilter: "Apply filter",
    AddRegister: "Add register",
    ImportButtonLabel: "Import",
    ExportButtonLabel: "Export",
  },
  ESP: {
    ShareTitle: (Title) => `Compartir ${Title}`,
    DestinedTeamCodeLabel: "Código de empresa destino:",
    TeamCodeLabel: "Código de empresa",
    DestinedTeamCanShareLabel: "empresa destino puede compartir",
    CancelLabel: "Cancelar",
    ShareLabel: "Compartir",
    IntroduceTeamCodeError: "Introduce el código de empresa",
    NewRegistryLabel: "Nuevo registro",
    RegistryLabel: (Id) => `Folio ${Id}`,
    MissingFieldLabel: (Title) => `Falta llenar el campo "${Title}"`,
    SuccessfulCreationLabel: "Creación exitosa",
    DeleteRowQuestion: "¿Eliminar fila?",
    DeleteRowLabel: "Eliminar fila",
    DeleteLabel: "Eliminar",
    SaveLabel: "Guardar",
    CreateLabel: "Crear",
    DataBasesLabel: "Bases de datos",
    CantDeleteDatabase:
      "No puedes eliminar una carpeta que contenga bases de datos o carpetas dentro",
    EditLabel: "Editar",
    AddColumnLabel: "Agregar columna",
    DeleteTableLabel: "Eliminar base de datos",
    NewTableNameLabel: "Base de datos",
    NewTablePlaceholder: "Escribe el nombre de la base de datos...",
    CreateNewDataBaseLabel: "Nueva base de datos",
    ColumnExistsError: "Ya existe una columna con este nombre",
    ForbiddenOptions: 'El campo "Opciones" no puede tener ',
    MissingDataType: "Falta tipo de dato",
    MissingName: "Falta nombre",
    MissingOptions: "Falta opciones",
    MissingTable: "Falta base de datos",
    MissingColumn: "Falta Columna",
    OptionExistsError: "Esa opción ya existe",
    NameLabel: "Nombre:",
    DataTypeLabel: "Tipo de dato:",
    OptionsLabel: "Opciones",
    ImportLabel: "Importar de base de datos",
    AddLabel: "Agregar",
    TableLabel: "base de datos",
    RequiredLabel: "Requerido",
    ColumnLabel: "Columna",
    ExportTable: "Exportar base de datos",
    NewOptionsLabel: "Se pueden escribir valores distintos a las opciones",
    FilterLabel: "Filtro",
    FiltersLabel: "Filtros",
    FiltersPlaceholder: "Filtrar columna...",
    ApplyFilter: "Aplicar filtro",
    AddRegister: "Agregar registro",
    ImportButtonLabel: "Importar",
    ExportButtonLabel: "Exportar",
  },
};
