import React from "react";
import { Row, Tooltip } from "antd";
import { Stage } from "../../Flow/CustomNodes/Toolbox/Toolbox.d";
import { ItemRow, StageLabel } from "./styles";
import { EditIcon } from "../../../../components/hoverIcons";
import { Circle } from "../../../../components/ColorPicker";
import { AiOutlineInfoCircle } from "react-icons/ai";

interface StageItemProps {
  stage: Stage;
  onEdit: (stage: Stage) => void;
}

const StageItem: React.FC<StageItemProps> = ({ stage, onEdit }) => {
  const editable = !stage.IsDefaultStage;
  const showTooltip = stage.IsDefaultStage;
  const handleOnEdit: React.MouseEventHandler<HTMLDivElement> = () =>
    onEdit(stage);
  return (
    <ItemRow justify="space-between" align="middle">
      <Row align="middle">
        <Circle color={stage.Color} size={20} />
        <StageLabel>{stage.Label}</StageLabel>
        {showTooltip && (
          <Tooltip
            title="Esta etapa agrupa las tareas que forman parte de la prospección"
            placement="bottomLeft"
          >
            <AiOutlineInfoCircle size={16} color="#48505E" />
          </Tooltip>
        )}
      </Row>
      {editable && <EditIcon filled onClick={handleOnEdit} />}
    </ItemRow>
  );
};

export default StageItem;
