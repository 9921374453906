import { Row, Space, Tag } from "antd";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import _, { noop as NOOP } from "lodash";

import { useParams } from "react-router";
import Button from "../../../../../components/Button";
import VISA from "./../../../../../resources/img/visa.svg";
import MASTER_CARD from "./../../../../../resources/img/mc.svg";
import AMERICAN_EXPRESS from "./../../../../../resources/img/am.svg";
import PaymentHistoryModal from "./../Modals/PaymentHistoryModal";
import EditBillingDataModal from "./../Modals/EditBillingDataModal";
import CancelSubscriptionModal from "./../Modals/CancelSupscriptionModal";
import ReactiveSubscriptionModal from "./../Modals/ReactiveSubscriptionModal";
import PaymentMethodsModal from "../Modals/PaymentMethodsModal";
import { Languages } from "./../Dictionary";
import { getPlanColors } from "../../../helpers";
import { AiFillStar } from "react-icons/ai";
import { useMutation } from "../../../../../hooks";
import { MessagesContext } from "../../../../../components/AppMessages";

const Container = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  padding: 2rem;
  color: #535151;
  height: 27rem;
`;

const CardTitle = styled.div`
  font-size: 20px;
  border-bottom: 1px solid #8e9090;
  margin-bottom: 1rem;
  font-family: "Gotham-Medium";
  text-align: center;
`;

const Text = styled.div`
  font-size: 18px;
  font-family: "Gotham-Book";
  line-height: 1.2rem;
  text-align: center;
  color: ${({ color }) => color};
`;

const OutlinedButton = styled(Button)`
  && {
    font-size: larger;
    color: ${({ $color }) => $color};
    border-color: ${({ $color }) => $color};
  }
`;

const PaymentMethodsButton = styled(Button)`
  && {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    border: none;
  }
`;

// Keys must be in lowercase
const BrandLogos = {
  visa: VISA,
  mastercard: MASTER_CARD,
  americanexpress: AMERICAN_EXPRESS,
};

const PaymentMetod = ({ Card }) => {
  return (
    <Row align="middle">
      {BrandLogos[Card.Brand] ? (
        <img src={BrandLogos[_.toLower(Card.Brand)]} alt={Card.Brand} />
      ) : (
        <Tag color="cyan">{Card.Brand}</Tag>
      )}
      <div style={{ marginLeft: "0.5rem" }}>
        <div style={{ fontSize: "14px" }}>
          <b>{`•••• •••• •••• ${Card.Last4}`}</b>
        </div>
      </div>
    </Row>
  );
};

const PaymentsCard = ({
  EndTime = "",
  NextPlan,
  setPlanStatus,
  setEndTime,
  date,
  amount,
  paymentMethods,
  paymentMethod,
  canceled = false,
  updateNextPayment = NOOP,
  setReload = NOOP,
}) => {
  const {
    Subscription,
    EditBillingData,
    PaymentHistoty,
    CancelRenewal,
    ReactivateRenewal,
    nextBill,
  } = Languages["ESP"];

  const { showMessage } = useContext(MessagesContext);
  const [Modal, setModal] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { IdTeam } = useParams();
  const [socialReason, setSocialReason] = useState("");
  const [rfc, setRfc] = useState("");
  const [regimen, setRegimen] = useState();
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [notifyToCsv, setNotifyToCsv] = useState("");
  const planColor = getPlanColors(NextPlan).mainColor;

  const parsedDate = EndTime && moment(EndTime).format("DD/MMM/YY");

  const [cancelSubscription] = useMutation({
    func: "Ver2-Subscription-Normal-cs",
    onSuccess: () => {
      setModal(null);
      setReload();
    },
    onError: () => {
      setModal(null);
      setReload();
    },
  });

  const handleCancelSubscription = () => {
    cancelSubscription({
      args: {
        IdTeam,
      },
    });
  };

  const [reactivateSubscription] = useMutation({
    func: "Ver2-Subscription-Normal-rs",
    onSuccess: () => {
      setModal(null);
      setLoading(false);
      setReload();
    },
    onError: () => {
      setLoading(false);
      setReload();
    },
  });

  const handleReactiveSubscription = () => {
    setLoading(true);
    reactivateSubscription({
      args: {
        IdTeam,
      },
    });
  };

  const [getPaymentHistory] = useMutation({
    func: "Ver2-Subscription-Normal-gph",
    onSuccess: (response) => {
      const data = response.map((d) => {
        const { Amount: amount } = d;
        return { ...d, Amount: `$${amount} USD` };
      });
      setHistoryData(data);
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  const getPaymentHistoryData = () => {
    setModal("HistoryModal");
    setLoading(true);
    getPaymentHistory({
      args: {
        IdTeam,
      },
    });
  };

  const [getBillingInfo] = useMutation({
    func: "Ver2-Subscription-Normal-gbi",
    onSuccess: (response) => {
      const {
        SocialReason,
        RFC,
        Address,
        PostalCode,
        City,
        Country,
        EnterpriseRegimen,
        NotifyToCsv,
      } = response;
      setSocialReason(SocialReason);
      setRfc(RFC);
      setAddress(Address);
      setPostalCode(PostalCode);
      setCity(City);
      setCountry(Country);
      setRegimen(EnterpriseRegimen ? EnterpriseRegimen : null);
      setNotifyToCsv(NotifyToCsv);
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  const getBillingData = () => {
    setModal("EditBillingModal");
    setLoading(true);
    getBillingInfo({
      args: {
        IdTeam,
      },
    });
  };

  const [updateBillingInfo] = useMutation({
    func: "Ver2-Subscription-Normal-ubi",
    onSuccess: () => {
      showMessage("Información actualizada", "success");
      setModal(null);
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  const handleSubmitBillingData = () => {
    setLoading(true);
    updateBillingInfo({
      args: {
        IdTeam,
        BillingInformation: {
          SocialReason: socialReason,
          RFC: rfc,
          Address: address,
          PostalCode: postalCode,
          City: city,
          Country: country,
          EnterpriseRegimen: regimen,
          NotifyToCsv: notifyToCsv,
        },
      },
    });
  };

  const Modals = {
    CancelModal: (
      <CancelSubscriptionModal
        date={date}
        setVisible={() => setModal(null)}
        onConfirm={handleCancelSubscription}
      />
    ),
    EditBillingModal: (
      <EditBillingDataModal
        setVisible={() => setModal(null)}
        socialReason={socialReason}
        setSocialReason={setSocialReason}
        rfc={rfc}
        setRfc={setRfc}
        address={address}
        setAddress={setAddress}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        city={city}
        setCity={setCity}
        country={country}
        setCountry={setCountry}
        notifyToCsv={notifyToCsv}
        setNotifyToCsv={setNotifyToCsv}
        handleSubmit={handleSubmitBillingData}
        loading={loading}
        regimen={regimen}
        setRegimen={setRegimen}
      />
    ),
    HistoryModal: (
      <PaymentHistoryModal
        setVisible={() => setModal(null)}
        data={historyData}
        loading={loading}
      />
    ),
    ReactiveModal: (
      <ReactiveSubscriptionModal
        date={date}
        amount={amount}
        setVisible={() => setModal(null)}
        onConfirm={handleReactiveSubscription}
        Loading={loading}
      />
    ),
    PaymentMethodsModal: (
      <PaymentMethodsModal
        cards={paymentMethods}
        setVisible={() => setModal(null)}
        reload={setReload}
      />
    ),
  };

  return (
    <Container>
      {Modals[Modal]}
      <CardTitle>{Subscription}</CardTitle>
      <Row justify="center">
        <Space direction="vertical" size={20} style={{ width: "85%" }}>
          {canceled ? (
            <Text color="#df2112">
              {!NextPlan
                ? `Tu suscripción finalizará el ${parsedDate}`
                : `Tu plan actual finalizará el ${parsedDate} y a partir de ese día te cambiarás al`}
              {!!NextPlan && (
                <Text color={planColor}>{`Plan ${NextPlan}`}</Text>
              )}
            </Text>
          ) : (
            <Text color="#5d5c5c">{nextBill(amount, date)}</Text>
          )}
          <OutlinedButton
            $color="#5d5c5c"
            type="default"
            block
            onClick={getBillingData}
          >
            {EditBillingData}
          </OutlinedButton>
          <OutlinedButton
            $color="#337fba"
            type="default"
            block
            onClick={getPaymentHistoryData}
          >
            {PaymentHistoty}
          </OutlinedButton>
          {!NextPlan &&
            (canceled ? (
              <OutlinedButton
                $color="#3a9a48"
                type="default"
                block
                onClick={() => setModal("ReactiveModal")}
              >
                {ReactivateRenewal}
              </OutlinedButton>
            ) : (
              <OutlinedButton
                $color="#df2112"
                type="default"
                block
                onClick={() => setModal("CancelModal")}
              >
                {CancelRenewal}
              </OutlinedButton>
            ))}
        </Space>
      </Row>
      {(!canceled || !!NextPlan) && (
        <Row justify="center" style={{ marginTop: "2rem" }}>
          <PaymentMethodsButton onClick={() => setModal("PaymentMethodsModal")}>
            Mis métodos de pago
          </PaymentMethodsButton>
        </Row>
      )}
      {!!paymentMethod && (
        <Row
          align="middle"
          style={{
            marginTop: canceled ? "3.5rem" : "1rem",
            width: "100%",
          }}
          justify="center"
        >
          <AiFillStar color="yellow" size={25} />
          <PaymentMetod Card={paymentMethod} />
        </Row>
      )}
    </Container>
  );
};

export default PaymentsCard;
