import React, { useContext } from "react";
import { OverviewSection } from "./Sections/Overview";
import { BasicSection } from "./Sections/Basic";
import { ToolbarBasicSection } from "./Sections/ToolbarBasic";
import { ToolbarAdvanceSection } from "./Sections/ToolbarAdvance";
import { ParamsSection } from "./Sections/Params";
import { UtilsSection } from "./Sections/Utils";
import { ShortcutsSection } from "./Sections/Shortcuts";
import { ExtendSection } from "./Sections/Extend";
import { SectionsType } from ".";
import { MessagesContext } from "../../../../components/AppMessages";

interface BodyProps {
  SECTIONS: SectionsType;
  section: Symbol;
}

const Body: React.FC<BodyProps> = ({ SECTIONS, section }) => {
  const {
    OVERVIEW,
    BASIC,
    TOOLBAR_BASIC,
    TOOLBAR_ADVANCE,
    PARAMS,
    UTILS,
    SHORTCUTS,
    EXTEND,
  } = SECTIONS;

  const { showMessage } = useContext(MessagesContext);

  const handleCopy = (element: string) => {
    navigator.clipboard.writeText(element);
    showMessage("Contenido copiado", "success");
  };

  if (section === OVERVIEW) {
    return <OverviewSection />;
  }
  if (section === BASIC) {
    return <BasicSection handleCopy={handleCopy} />;
  }
  if (section === TOOLBAR_BASIC) {
    return <ToolbarBasicSection handleCopy={handleCopy} />;
  }
  if (section === TOOLBAR_ADVANCE) {
    return <ToolbarAdvanceSection handleCopy={handleCopy} />;
  }
  if (section === PARAMS) {
    return <ParamsSection />;
  }
  if (section === UTILS) {
    return <UtilsSection />;
  }
  if (section === SHORTCUTS) {
    return <ShortcutsSection />;
  }
  if (section === EXTEND) {
    return <ExtendSection />;
  }

  return <OverviewSection />;
};

export default Body;
