import React, { useEffect, useState } from "react";
import _ from "lodash";
import { BackIcon } from "../../../hoverIcons";
import { languages } from "../dicitonary";
import TypeIcons from "../../TypeIcons";
import { Button } from "../../../../GeestUI";
import TextConfig from "./TextConfig";
import UnsavedChanges from "../../Modals/UnsavedChanges";
import ImageConfig from "./ImageConfig";
import {
  ElementOrigin,
  FileValue,
  ImageConfigType,
  ImageDimensionsType,
  Option,
  ImageSourceType,
} from "../../PDFConfig.d";
import {
  ActionsWrapper,
  ButtonLabel,
  ConfigHeader,
  ConfigurationOptions,
  ControlsConfigWrapper,
  NodeTypeName,
  TitleSection,
} from "./styles";
import SignConfig from "./SignConfig";
import DeleteOriginElement from "../../Modals/DeleteOriginElement";

interface ControlsConfigProps {
  elementsOrigin: ElementOrigin[];
  elementToConfig: string | null;
  setElementToConfig: (id: string | null) => void;
  filesPath: string;
  updateElementsOrigin: (newElementsOrigin: ElementOrigin[]) => void;
  onDeleteOriginElement: (elementId: string) => void;
  fieldImageOrigin: Option[];
  teamLogo: string;
}

const ControlsConfig: React.FC<ControlsConfigProps> = ({
  elementsOrigin,
  elementToConfig,
  setElementToConfig,
  filesPath,
  updateElementsOrigin,
  onDeleteOriginElement,
  fieldImageOrigin,
  teamLogo,
}) => {
  const [elementToDelete, setElementToDelete] = useState<string>("");
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [nodeName, setNodeName] = useState<string>("");
  const [imageValue, setImageValue] = useState<FileValue | string>("");
  const [imageConfig, setImageconfig] = useState<ImageConfigType>("source");
  const [imageSource, setImageSource] = useState<ImageSourceType>("file");
  const [imageDimensions, setImageDimensions] =
    useState<ImageDimensionsType>("width");
  const [signColor, setSignColor] = useState<string>("#000");
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] =
    useState<boolean>(false);

  const {
    ConfigureElement,
    TextEditorLabel,
    ImageEditorLabel,
    SignEditorLabel,
    DeleteElement,
    Accept,
  } = languages["ESP"];

  const indexedElements = elementsOrigin.reduce(
    (acc: { [id: string]: ElementOrigin }, el) => {
      acc[el.id] = el;
      return acc;
    },
    {}
  );

  useEffect(() => {
    getElementInitialStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementToConfig]);

  function getElementInitialStatus() {
    const indexedElements = elementsOrigin.reduce(
      (acc: { [id: string]: ElementOrigin }, el) => {
        acc[el.id] = el;
        return acc;
      },
      {}
    );

    if (elementToConfig && elementToConfig in indexedElements) {
      setNodeName(indexedElements[elementToConfig].name);

      if (indexedElements[elementToConfig].type === "image") {
        setImageconfig(indexedElements[elementToConfig].imageConfig);
        if (indexedElements[elementToConfig].imageSourceConfig) {
          setImageSource(indexedElements[elementToConfig].imageSourceConfig);
        } else {
          setImageSource("file");
        }
        setImageDimensions(indexedElements[elementToConfig].imageDimensions);
        // @ts-ignore
        setImageValue(indexedElements[elementToConfig].value);
      }
      if (indexedElements[elementToConfig].type === "sign") {
        setSignColor(indexedElements[elementToConfig].signColor);
      }
    } else {
      setNodeName("");
      setImageconfig("source");
      setImageSource("file");
      setImageDimensions("width");
    }
  }

  const getTypeIcon = (elementToConfig: string) => {
    const type = indexedElements[elementToConfig].type;
    const typeIcon = TypeIcons[type];
    return typeIcon;
  };

  const getElementLabel = (elementToConfig: string) => {
    const type = indexedElements[elementToConfig].type;
    if (type === "text") return TextEditorLabel;
    if (type === "image") return ImageEditorLabel;
    if (type === "sign") return SignEditorLabel;
    return "";
  };

  const handleOnChangeName = (newName: string) => {
    setUnsavedChanges(true);
    setNodeName(newName);
  };

  const handleCloseConfig = () => {
    if (unsavedChanges) {
      setOpenUnsavedChangesModal(true);
      return;
    }
    setElementToConfig(null);
  };

  const handleDiscardChanges = () => {
    setUnsavedChanges(false);
    setOpenUnsavedChangesModal(false);
    setElementToConfig(null);
  };

  const handleUpdate = (
    newImageValue?: FileValue | string,
    source?: ImageSourceType
  ) => {
    setUnsavedChanges(false);
    setOpenUnsavedChangesModal(false);

    const elementExists = elementToConfig && elementToConfig in indexedElements;
    if (!elementExists) {
      setElementToConfig(null);
      return;
    }
    const currentElement = indexedElements[elementToConfig];

    const newName = _.trim(nodeName);
    const isNewName =
      currentElement.name.toLowerCase() !== newName.toLocaleLowerCase();
    const isNewImageConfig = currentElement.imageConfig !== imageConfig;
    const isNewImageDimensions =
      currentElement.imageDimensions !== imageDimensions;
    const isNewImageSouce = currentElement.imageSourceConfig !== imageSource;

    let isNewImage = false;

    if (newImageValue) {
      isNewImage =
        currentElement.type === "image" &&
        (currentElement.value !== newImageValue ||
          (currentElement.value as FileValue).filePath !==
            (newImageValue as FileValue).filePath ||
          (currentElement.value as FileValue).name !==
            (newImageValue as FileValue).name);
    } else {
      isNewImage =
        currentElement.type === "image" &&
        (currentElement.value !== imageValue ||
          (currentElement.value as FileValue).filePath !==
            (imageValue as FileValue).filePath ||
          (currentElement.value as FileValue).name !==
            (imageValue as FileValue).name);
    }

    const isNewSignColor = currentElement.signColor !== signColor;

    if (currentElement.type === "text") {
      if (!isNewName) {
        setElementToConfig(null);
        return;
      }
    }
    if (currentElement.type === "image") {
      if (
        !isNewName &&
        !isNewImageConfig &&
        !isNewImageDimensions &&
        !isNewImage &&
        !isNewImageSouce &&
        !source
      ) {
        setElementToConfig(null);
        return;
      }
    }
    if (currentElement.type === "sign") {
      if (!isNewName && !isNewSignColor) {
        setElementToConfig(null);
        return;
      }
    }

    let newElementsOrigin = [...elementsOrigin];
    const elementIndex = _.findIndex(newElementsOrigin, {
      id: elementToConfig,
    });

    let elementToUpdate = {
      ...newElementsOrigin[elementIndex],
    };

    // update adding all trackings based on type an change evals
    if (isNewName) {
      elementToUpdate = { ...elementToUpdate, name: newName };
    }
    if (isNewImageConfig) {
      elementToUpdate = { ...elementToUpdate, imageConfig: imageConfig };
    }
    if (isNewImageDimensions) {
      elementToUpdate = {
        ...elementToUpdate,
        imageDimensions: imageDimensions,
      };
    }
    if (isNewSignColor) {
      elementToUpdate = {
        ...elementToUpdate,
        signColor: signColor,
      };
    }
    if (isNewImageSouce) {
      elementToUpdate = {
        ...elementToUpdate,
        imageSourceConfig: imageSource,
      };
    }
    if (isNewImage) {
      if (newImageValue) {
        if (
          typeof newImageValue !== "string" &&
          newImageValue.filePath === "DELETE"
        ) {
          elementToUpdate = {
            ...elementToUpdate,
            value: "",
          };
        } else {
          elementToUpdate = {
            ...elementToUpdate,
            value: newImageValue,
          };
        }
      } else {
        elementToUpdate = {
          ...elementToUpdate,
          value: imageValue ?? "",
        };
      }
    }

    if (source) {
      elementToUpdate.imageSourceConfig = source;
    }

    newElementsOrigin[elementIndex] = elementToUpdate;
    updateElementsOrigin(newElementsOrigin);
    setUnsavedChanges(false);
    setElementToConfig(null);
  };

  return (
    <ControlsConfigWrapper>
      {openUnsavedChangesModal && (
        <UnsavedChanges
          discardChanges={handleDiscardChanges}
          saveChanges={() => {
            handleUpdate();
            setOpenUnsavedChangesModal(false);
            setElementToConfig(null);
          }}
          closeModal={() => setOpenUnsavedChangesModal(false)}
        />
      )}

      {elementToDelete && (
        <DeleteOriginElement
          onCancel={() => setElementToDelete("")}
          onDelete={() => {
            onDeleteOriginElement(elementToDelete);
            setElementToDelete("");
          }}
        />
      )}

      <ConfigHeader>
        <TitleSection>
          <BackIcon onClick={handleCloseConfig} size={20} fillColor="#CCE6FF" />
          <p>{ConfigureElement}</p>
        </TitleSection>

        <NodeTypeName>
          {elementToConfig && (
            <>
              <img
                src={getTypeIcon(elementToConfig).icon}
                style={{ ...getTypeIcon(elementToConfig).extraStyles }}
                alt=""
              />
              <p>{getElementLabel(elementToConfig)}</p>
            </>
          )}
        </NodeTypeName>
      </ConfigHeader>

      {elementToConfig && (
        <ConfigurationOptions>
          {indexedElements[elementToConfig].type === "text" && (
            <TextConfig nodeName={nodeName} setNodeName={handleOnChangeName} />
          )}

          {indexedElements[elementToConfig].type === "image" && (
            <ImageConfig
              nodeName={nodeName}
              setNodeName={handleOnChangeName}
              filesPath={filesPath}
              imageValue={imageValue}
              setImageValue={setImageValue}
              fieldImageOrigin={fieldImageOrigin}
              imageConfig={imageConfig}
              setImageConfig={setImageconfig}
              imageDimensions={imageDimensions}
              setImageDimensions={setImageDimensions}
              handleUpdate={handleUpdate}
              teamLogo={teamLogo}
              imageSource={imageSource}
              setImageSource={setImageSource}
            />
          )}

          {indexedElements[elementToConfig].type === "sign" && (
            <SignConfig
              nodeName={nodeName}
              setNodeName={handleOnChangeName}
              signColor={signColor}
              setSignColor={setSignColor}
            />
          )}
        </ConfigurationOptions>
      )}

      <ActionsWrapper>
        <Button
          type="danger"
          size="large"
          className="deleteButton"
          borderWidth="3px"
          onClick={() => setElementToDelete(elementToConfig ?? "")}
        >
          <ButtonLabel>{DeleteElement}</ButtonLabel>
        </Button>
        <Button type="primary" onClick={() => handleUpdate()}>
          <ButtonLabel>{Accept}</ButtonLabel>
        </Button>
      </ActionsWrapper>
    </ControlsConfigWrapper>
  );
};

export default ControlsConfig;
