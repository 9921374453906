import React from "react";
import styled from "styled-components";
import { Modal, Paper } from "@material-ui/core";
import { Row } from "antd";
import { Typography, Button } from "../../../GeestUI";
import { Languages } from "./Dictionary";
import { CloseIcon } from "../../../components/hoverIcons";

const { H3, B, P } = Typography;

const StyledPaper = styled(Paper)`
  border-radius: 10px !important;
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const ConfirmModal: React.FC<{
  openModal: boolean;
  onClose: () => void;
  onCancel: () => void;
}> = ({ openModal, onClose, onCancel }) => {
  const { UnsavedChanges, YouHaveUnsavedChanges, CancelLabel, ExitLabel } =
    Languages["ESP"];

  return (
    <Modal open={openModal} onClose={onCancel}>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <Row
            align="middle"
            justify="space-between"
            style={{ marginBottom: "10px" }}
          >
            <H3 mb="0">
              <B>{UnsavedChanges}</B>
            </H3>
            <CloseIcon size={25} onClick={onCancel} />
          </Row>
          <P>{YouHaveUnsavedChanges}</P>
          <Row align="middle" justify="space-between">
            <Button
              type="secondary"
              style={{ width: "48%" }}
              onClick={onCancel}
            >
              {CancelLabel}
            </Button>
            <Button type="primary" style={{ width: "48%" }} onClick={onClose}>
              {ExitLabel}
            </Button>
          </Row>
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default ConfirmModal;
