import {
  BezierPathOptions,
  Edge,
  MarkerType,
  Node,
  XYPosition,
} from "reactflow";
import _ from "lodash";
import { ConditionPort, NodeData, Stage, TaskInfo, User } from "./Diagram.d";
import {
  ApiConditionPort,
  ApiLink,
  ApiNode,
  ApiNodeTypes,
  ApiStage,
  ApiUser,
  GetExecutionFluxResponse,
  IPortNodeDestiny,
  IPortNodeOrigin,
  NodeInfo,
  NodeStatus,
} from "./Flow.d";
import { CustomNodeTypes, HandlerId } from "./CustomNodes/CustomNodes.d";
import { parseEmoji } from "../../../../../emoji";

const completedEdgeColor = "#389e0d";
const regularEdgeColor = "#48505e";

// Node configuration

export const defaultNodeData: NodeData = {
  label: "",
  status: NodeStatus.WAITING_TO_START,
  currentTask: false,
};

export const startNode: Node<NodeData> = {
  id: "0",
  data: { ...defaultNodeData, label: "Inicio", status: NodeStatus.COMPLETED },
  position: { x: 0, y: 0 },
  type: CustomNodeTypes.START_NODE,
};

// Edge configuration

const pathOptions: BezierPathOptions = {
  curvature: 0.3,
};

export const defaultEdgeOptions = {
  type: "default",
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 16,
    height: 16,
    color: regularEdgeColor,
  },
  style: {
    strokeWidth: 2,
    stroke: regularEdgeColor,
  },
};

// Node status

export const getNodeStatusBackgroundColor = (status: NodeStatus): string => {
  switch (status) {
    case NodeStatus.COMPLETED:
      return "#F6FFEF";
    case NodeStatus.STUCK:
      return "#FFF1F0";
    case NodeStatus.ON_TIME:
      return "#E6FFFB";
    case NodeStatus.WAITING_TO_START:
      return "#fff7e6";
    default:
      return "white";
  }
};

export const getConditionStatusBackgroundColor = (
  nodeData: NodeData
): string => {
  const completedPort = !!_.find(nodeData.conditionPorts, {
    Status: NodeStatus.COMPLETED,
  });
  const generalNodeStatus: NodeStatus = completedPort
    ? NodeStatus.COMPLETED
    : NodeStatus.NEW;
  return getNodeStatusBackgroundColor(generalNodeStatus);
};

export const getStatusTag = (
  status: NodeStatus
): { color: string; label: string } => {
  switch (status) {
    case NodeStatus.NEW:
      return { color: "yellow", label: "Nuevo" };
    case NodeStatus.ON_TIME:
      return { color: "cyan", label: "Iniciado" };
    case NodeStatus.COMPLETED:
      return { color: "green", label: "Terminado" };
    case NodeStatus.CANCELED:
      return { color: "default", label: "Cancelado" };
    case NodeStatus.WAITING_TO_START:
      return { color: "orange", label: "Por iniciar" };
    case NodeStatus.STUCK:
      return { color: "red", label: "Vencido" };
  }
};

export const getConditionStatusTag = (
  nodeData: NodeData,
  defaultNodeStatus: NodeStatus
): { color: string; label: string } => {
  const completedPort = !!_.find(nodeData.conditionPorts, {
    Status: NodeStatus.COMPLETED,
  });
  const generalNodeStatus: NodeStatus = completedPort
    ? NodeStatus.COMPLETED
    : defaultNodeStatus;
  return getStatusTag(generalNodeStatus);
};

// API Response

const apiCoordinatesToFlowPosition = (coordinates: string): XYPosition => {
  const [x, y] = _.split(coordinates, ",");
  return {
    x: _.toNumber(x),
    y: _.toNumber(y),
  };
};

const apiTypeToFlowType = (apiNodeType: ApiNodeTypes): CustomNodeTypes => {
  switch (apiNodeType) {
    case "Task":
      return CustomNodeTypes.TASK_NODE;
    case "Condition":
      return CustomNodeTypes.CONDITIONAL_NODE;
    case "Automation":
      return CustomNodeTypes.AUTOMATION_NODE;
    case "Subflux":
      return CustomNodeTypes.SUBFLUX_NODE;
    case "EndFlux":
      return CustomNodeTypes.END_NODE;
  }
};

const apiConditionPorts = (
  conditionPorts?: ApiConditionPort[]
): ConditionPort[] | undefined => {
  if (_.isEmpty(conditionPorts)) return undefined;
  return conditionPorts;
};

const apiStageToFlowStage = (stage: ApiStage): Stage => ({
  label: stage.Label,
  color: stage.Color,
});

const apiUserToFlowUser = (user: ApiUser): User => ({
  id: user.IdUser,
  email: user.Email,
  fullName: [user.FirstName, user.LastName].join(" "),
  initials: user.Initials,
  profilePicture: user.ProfilePicture,
});

const getTaskInfo = (
  node: ApiNode,
  stages: ApiStage[]
): TaskInfo | undefined => {
  if (apiTypeToFlowType(node.NodeType) === CustomNodeTypes.TASK_NODE) {
    const apiStage = _.find(stages, { Id: node.IdStage });
    const stage = apiStage && apiStageToFlowStage(apiStage);
    const nodeInfo = node.NodeInfo as NodeInfo;

    return {
      stage,
      idTaskValue: nodeInfo.IdTaskValue || 0,
      isRevision: nodeInfo.IsRevision === true,
      startedAt: nodeInfo.StartedAt || "",
      deadline: nodeInfo.Deadline || "",
      endedAt: nodeInfo.EndedAt || "",
      users: _.map(nodeInfo.UsersRelations || [], apiUserToFlowUser),
    };
  }
  return;
};

const apiNodeToFlowNode = (
  node: ApiNode,
  stages: ApiStage[]
): Node<NodeData> => ({
  id: _.toString(node.IdNode),
  position: apiCoordinatesToFlowPosition(node.CoordinatesCsv),
  type: apiTypeToFlowType(node.NodeType),
  data: {
    ...defaultNodeData,
    label: parseEmoji(node.NodeTitle),
    conditionPorts: apiConditionPorts(node.ConditionPorts),
    status: node.Status,
    currentTask: node.IsThisNodeTaskOpeningFlux,
    taskInfo: getTaskInfo(node, stages),
  },
});

const apiPortOriginToSourceHandle = (
  port: IPortNodeOrigin
): HandlerId | string => {
  switch (port) {
    case "BidirectionalLeft":
      return HandlerId.OUT_LEFT;
    case "BidirectionalRight":
    case "port1":
      return HandlerId.OUT_RIGHT;
    case "BidirectionalTop":
      return HandlerId.OUT_TOP;
    case "BidirectionalBottom":
      return HandlerId.OUT_BOTTOM;
    default:
      return port;
  }
};

const apiPortDestinyToTargetHandle = (port: IPortNodeDestiny): HandlerId => {
  switch (port) {
    case "BidirectionalTop":
    case "InTop":
      return HandlerId.IN_TOP;
    case "BidirectionalRight":
      return HandlerId.IN_RIGHT;
    case "BidirectionalBottom":
    case "InBottom":
      return HandlerId.IN_BOTTOM;
    case "BidirectionalLeft":
    case "InLeft":
      return HandlerId.IN_LEFT;
    default:
      return port;
  }
};

const getApiNodeStatus = (apiNode: ApiNode): NodeStatus => {
  if (apiNode.NodeType === "Condition") {
    const completedPort = !!_.find(apiNode.ConditionPorts, {
      Status: NodeStatus.COMPLETED,
    });
    if (completedPort) return NodeStatus.COMPLETED;
  }
  return apiNode.Status;
};

const getEdgeColor = (
  link: ApiLink,
  apiNodes: ApiNode[]
): string | undefined => {
  const nodeOrigin = _.find(apiNodes, { IdNode: link.IdNodeOrigin });
  const nodeDestiny = _.find(apiNodes, { IdNode: link.IdNodeDestiny })!;
  if (!nodeOrigin) return completedEdgeColor;
  const nodeOriginStatus = getApiNodeStatus(nodeOrigin);
  const nodeDestinyStatus = getApiNodeStatus(nodeDestiny);
  if (
    [NodeStatus.COMPLETED, NodeStatus.ON_TIME, NodeStatus.STUCK].includes(
      nodeOriginStatus
    )
  ) {
    switch (nodeOrigin.NodeType) {
      case "Condition":
        const originPort = _.find(nodeOrigin.ConditionPorts, {
          Port: link.PortNodeOrigin,
        });
        if (originPort?.Status === NodeStatus.COMPLETED)
          return completedEdgeColor;
        break;

      default:
        if (
          nodeDestinyStatus === NodeStatus.COMPLETED ||
          (nodeOriginStatus === NodeStatus.COMPLETED &&
            [NodeStatus.ON_TIME, NodeStatus.STUCK].includes(nodeDestinyStatus))
        )
          return completedEdgeColor;
        break;
    }
  }
  return regularEdgeColor;
};

const apiLinkToFlowEdge = (link: ApiLink, apiNodes: ApiNode[]): Edge => ({
  id: _.toString(link.IdLink),
  source: _.toString(link.IdNodeOrigin),
  target: _.toString(link.IdNodeDestiny),
  sourceHandle: apiPortOriginToSourceHandle(link.PortNodeOrigin),
  targetHandle: apiPortDestinyToTargetHandle(link.PortNodeDestiny),
  markerEnd: {
    ...defaultEdgeOptions.markerEnd,
    color: getEdgeColor(link, apiNodes),
  },
  style: {
    ...defaultEdgeOptions.style,
    stroke: getEdgeColor(link, apiNodes),
  },
  pathOptions,
});

export const apiResponseToFlow = ({
  Nodes,
  Links,
  Stages,
}: GetExecutionFluxResponse): {
  nodes: Node<NodeData>[];
  edges: Edge[];
} => ({
  nodes: [
    startNode,
    ..._.map(Nodes, (apiNode) => apiNodeToFlowNode(apiNode, Stages)),
  ],
  edges: _.map(Links, (apiLink) => apiLinkToFlowEdge(apiLink, Nodes)),
});
