import styled from "styled-components";
import { Coords, Size } from "../../../PDFConfig.d";

export const AbstractionWrapper = styled.div<{
  size: Size;
  position: Coords;
}>`
  position: absolute;

  left: ${({ position }) => position.x}px;
  top: ${({ position }) => position.y}px;

  width: ${({ size }) => size.width}px;
  height: ${({ size }) => size.height}px;
`;

export const TextAbstractionWrapper = styled(AbstractionWrapper)`
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(90deg, white 2px, transparent 0),
      linear-gradient(white 2px, transparent 0);
    background-size: 4px 6px;
    background-position: 0 0, 0 0; /* ajusta el espaciado entre rectángulos */
    background-color: #48505e;
  }
`;

export const ImageAbstractionWrapper = styled(AbstractionWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    user-select: none;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const SignAbstractionWrapper = styled(AbstractionWrapper)`
  border-radius: 6px;
  border: 2px solid #edecec;
  background-color: #f2f8fe;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    user-select: none;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;
