import React from "react";
import Modal from "../../../../../components/Modal";
import { Row } from "antd";
import styled from "styled-components";
import { Button } from "../../../../../GeestUI";

interface ConfirmDeleteActionProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const TitleBar = styled.div`
  width: 309px;
  text-align: center;
  font-size: 14px;
  line-height: 19.6px;
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  font-weight: 700;
  margin-bottom: 1.2rem;
  margin-top: 0.2rem;
`;

const ConfirmDeleteAction: React.FC<ConfirmDeleteActionProps> = ({
  open,
  onConfirm,
  onClose,
}) => {
  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title="Eliminar diparador"
      width="432px"
    >
      <Container>
        <TitleBar>¿Seguro que deseas eliminar esta acción</TitleBar>
        <Row align="middle" justify="space-between" style={{ width: "275px" }}>
          <Button type="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button type="primary" onClick={onConfirm} danger>
            Aceptar
          </Button>
        </Row>
      </Container>
    </Modal>
  );
};

export default ConfirmDeleteAction;
