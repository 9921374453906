import styled from "styled-components";
import { Tag } from "../../../GeestUI";
import { Paper } from "@material-ui/core";

export const StyledPaper = styled(Paper)`
  border-radius: 6px !important;
  height: 95vh;
  width: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  font-family: Gotham-Bold;
  color: #48505e;
  height: 32px;
`;

export const ProcessName = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledInput = styled.input`
  font-size: 16px;
  color: rgba(72, 80, 94, 1);
  font-weight: 700;
  margin-right: 10px;
  height: 32px;
  width: 227px;
  border-radius: 6px;
  border: 2px solid rgba(72, 80, 94, 1);
  :focus-visible {
    outline: none;
  }
`;

export const InputStyled = styled.input`
  color: rgba(130, 141, 158, 1);
  font-size: 14px;
  font-family: Gotham-Book;
  border: 2px solid rgba(237, 236, 236, 1);
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
  display: flex;
  flex: 1;
  height: 32px;
  :focus-visible {
    outline: none;
  }
  :hover {
    border-color: rgba(2, 115, 233, 1);
  }
  :focus {
    border-color: rgba(72, 80, 94, 1);
  }
`;

export const StyledTag = styled(Tag)`
  border-radius: 6px;
  border-width: 2px;
  font-size: 12px;
  font-family: Gotham-Book;
  max-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Container = styled.div`
  padding: 0px 12px;
  display: flex;
  flex-direction: row;
  gap: 22px;
  flex: 1;
  margin-top: 24px;
  height: calc(100% - 67px);
  max-width: 100%;
`;

export const LeftBarContainer = styled.div`
  width: 222px;
  min-width: 222px;
  border-right: 1px solid #edecec;
  height: 100%;
`;

export const StateItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StateItem = styled.div<{ $selected: boolean }>`
  height: 40px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  flex: 1;
  color: ${({ $selected }) => ($selected ? "#0273e9" : "#48505e")};
  font-family: Gotham-Bold;
  cursor: pointer;
  .bar {
    width: 4px;
    height: 40px;
    border-radius: 2px;
    background-color: ${({ $selected }) =>
      $selected ? "#0273e9" : "transparent"};
  }
`;

export const StateContent = styled.div`
  display: flex;
  flex: 1;
  max-width: calc(100% - 244px);
`;

export const BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
  gap: 10px;
  padding-right: 6px;
  flex: 1;
`;

export const BasicInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 192px;
  margin-top: 2px;
`;

export const ProcessBasicInfo = styled.div`
  width: calc(100% - 159px);
  display: flex;
  flex-direction: column;
  gap: 14px;
  background-color: rgba(248, 248, 248, 1);
  border-radius: 6px;
  padding: 12px 16px;
`;

export const ProcessActionsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 131px;
  gap: 8px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TitleValueCol = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Gotham-Bold;
  color: #48505e;
  font-size: 14px;
  gap: 8px;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 19px;
  border-radius: 9.5px;
  background-color: #d9d9d9;
  flex-direction: row;
  justify-content: flex-start;
`;

export const Progress = styled.div<{ $progress?: number }>`
  height: 19px;
  border-radius: 9.5px;
  background-color: #0273e9;
  width: ${({ $progress = 0 }) => `${$progress}%`};
`;

export const DetailTitle = ProcessName;

export const Circle = styled.div<{
  color?: string;
  border?: string;
  size?: number;
}>`
  background: ${({ color = "white" }) => color};
  border: ${({ border = "none" }) => border};
  border-radius: 50%;
  height: ${({ size = 15 }) => `${size}px`};
  width: ${({ size = 15 }) => `${size}px`};
  min-width: ${({ size = 15 }) => `${size}px`};
`;

export const ScheduledProcessInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #48505e;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: Gotham-Bold;
  border: 2px solid #edecec;
  border-radius: 6px;
`;

export const TaskGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 393px;
  border-radius: 10px;
  background-color: #f8f8f8;
  gap: 8px;
`;

export const TaskGroup = styled.div`
  display: flex;
  flex: 1;
  max-height: 286px;
  overflow: auto;
  padding-right: 10px;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

export const TaskCard = styled.div<{ $color: string; $background: string }>`
  display: flex;
  flex: 1;
  height: 90px;
  min-height: 90px;
  max-height: 90px;
  flex-direction: column;
  border-radius: 6px;
  background-color: ${({ $background }) => $background};
  border: 1px solid ${({ $color }) => $color};
  padding: 10px;
  cursor: pointer;
  :hover {
    border-width: 2px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const FinishedTasksInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 115px;
  height: 76px;
  font-size: 12px;
  color: rgba(130, 141, 158, 1);
  font-family: Gotham-Book;
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Gotham-Bold;
  background-color: rgba(130, 141, 158, 1);
  border-radius: 12px;
  text-align: center;
  padding: 0;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-height: calc(100% - 40px);
  overflow: auto;
  background-color: rgba(248, 248, 248, 1);
  gap: 40px;
  padding: 12px;
  border-radius: 6px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  max-height: 100%;
  overflow: auto;
  padding-right: 18px;
  flex: 1;
`;

export const TableHeader = styled.div`
  display: flex;
  background-color: #f2f8fe;
  color: #48505e;
  font-size: 14px;
  font-family: Gotham-Bold;
  height: 40px;
  flex-direction: row;
  gap: 0;
  width: 100%;
`;

export const TableItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 10px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: center;
`;

export const TableRow = styled.div`
  cursor: pointer;
  display: flex;
  color: #828d9e;
  font-family: Gotham-Book;
  border-bottom: 1px solid #828d9e;
  font-size: 14px;
  transition: background-color 0.35s ease;
  height: 40px;
  align-items: center;
  flex-direction: row;
  gap: 0;
  width: 100%;
  :hover {
    background-color: rgba(237, 236, 236, 1);
  }
  :active {
    background-color: #f2f8fe;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ActivityTabContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
  max-height: 100%;
`;

export const AcitivityInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 4px;
  max-height: calc(100% - 38px);
`;

export const TaskCountsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  max-width: 291px;
  max-height: 100%;
  overflow: auto;
`;

const TaskCountGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 14px;
  gap: 13px;
  color: rgba(255, 255, 255, 1);
  font-family: Gotham-Bold;
  cursor: pointer;
  height: 101px;
  border-radius: 6px;

  .square {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 30px;
  }
`;

export const StuckTasksCount = styled(TaskCountGroup)`
  background-color: rgba(255, 83, 92, 1);
  .square {
    background-color: rgba(255, 120, 126, 1);
  }
  :hover {
    background-color: rgba(255, 0, 12, 1);
    .square {
      background-color: rgba(255, 83, 92, 1);
    }
  }
`;

export const StartedTaskCount = styled(TaskCountGroup)`
  background-color: rgba(66, 156, 249, 1);
  .square {
    background-color: rgba(16, 133, 255, 1);
  }
  :hover {
    background-color: rgba(2, 115, 233, 1);
    .square {
      background-color: rgba(16, 133, 255, 1);
    }
  }
`;

export const WaitingTaskCount = styled(TaskCountGroup)`
  background-color: rgba(255, 152, 0, 1);
  .square {
    background-color: rgba(255, 177, 62, 1);
  }
  :hover {
    background-color: rgba(247, 147, 0, 1);
    .square {
      background-color: rgba(255, 177, 62, 1);
    }
  }
`;

export const FinishedTaskCount = styled(TaskCountGroup)`
  background-color: rgba(94, 215, 99, 1);
  .square {
    background-color: rgba(84, 193, 88, 1);
  }
  :hover {
    background-color: rgba(76, 175, 80, 1);
    .square {
      background-color: rgba(84, 193, 88, 1);
    }
  }
`;

export const RevisionTaskCount = styled(TaskCountGroup)`
  background-color: rgba(47, 176, 234, 1);
  .square {
    background-color: rgba(81, 192, 243, 1);
  }
  :hover {
    background-color: rgba(0, 157, 228, 1);
    .square {
      background-color: rgba(81, 192, 243, 1);
    }
  }
`;

export const ActivityContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: rgba(248, 248, 248, 1);
  flex-direction: row;
  border-radius: 6px;
  padding: 12px 16px;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  gap: 30px;
`;

export const NodeContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0px;
  max-width: 14px;
  align-items: center;
  margin-top: 3px;
`;

export const Node = styled.div`
  width: 14px;
  height: 14px;
  min-height: 14px;
  border-radius: 7px;
  border: 2px solid rgba(2, 115, 233, 1);
`;

export const NodeLink = styled.div`
  height: 31px;
  min-height: 31px;
  width: 2px;
  border: 1px solid rgba(237, 236, 236, 1);
`;

export const ActivitiesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  max-width: calc(100% - 44px);
`;

export const ActivityInfo = styled.div`
  height: 37px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const Label = styled.div`
  font-size: 12px;
  font-family: Gotham-Book;
  color: rgba(130, 141, 158, 1);
  display: flex;
  flex-direction: row;
`;

export const PendingMessages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 18px;
  padding: 4px;
  height: 18px;
  background-color: rgba(219, 35, 44, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  font-family: Gotham-Bold;
  border-radius: 100px;
`;
