import * as Yup from "yup";
import { Languages } from "../Dictionary";

const { EmailValidation, FieldRequired } = Languages["ESP"];

export const emailValidation = Yup.string()
  .email(EmailValidation)
  .required(FieldRequired);

export const fieldValidation = Yup.string().required(FieldRequired);
