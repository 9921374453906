import React from "react";
import { useParams } from "react-router-dom";
// Views
import ValidateToken from "./Views/ValidateToken";
import InvalidToken from "./Views/InvalidToken";
import { LoadingOutlined } from "@ant-design/icons";
import { useFetch } from "../../hooks";

const ForgotPass = () => {
  const { token, IdUser } = useParams();

  const { data, loading } = useFetch({
    func: "Ver2-Session-vrpr",
    args: {
      Token: token,
    },
    idUR: IdUser,
  });

  const validToken = !!!data?.Error;
  const Content = validToken ? ValidateToken : InvalidToken;

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      {loading ? (
        <div style={{ flex: 1, display: "flex" }}>
          <LoadingOutlined
            spin
            style={{ fontSize: "5rem", color: "#0273e9", margin: "auto" }}
          />
        </div>
      ) : (
        <Content />
      )}
    </div>
  );
};

export default ForgotPass;
