import React, { useContext, useState } from "react";
import { Popover, Badge } from "antd";
import { withRouter } from "react-router-dom";
// Img
import UpgradePlan from "../../../resources/img/Sidebar/GeestPremium.svg";
import SidebarTeams from "./../../../resources/img/Sidebar/GeestTeam.svg";
import SidebarNotifications from "./../../../resources/img/Sidebar/GeestNotifications.svg";
// import SidebarSettings from './../../../resources/img/SIDEBAR_SETTINGS.svg';
import GeestLogo from "./../../../resources/img/LOGO_.svg";
import GeestIcon from "./../../../resources/img/ICONO_.svg";
import SidebarIntranet from "../../../resources/img/Sidebar/GeestIntranet.svg";
// Routes
import routes from "./../../../routes";
// Components
import Item from "./Item/Item";
// Context
import { SesionContext } from "../../../config/State";
import Settings from "./SettingsView/Settings";
import { useEffect } from "react";
// API
import { Languages } from "../Dictionary";
import _ from "lodash";
import {
  Body,
  Content,
  Divider,
  Footer,
  GeestLogoImg,
  Header,
  Img,
  ProfileImg,
  ProfileNoImg,
  SideBarBox,
} from "./Styles";
import {
  IconContainer,
  ItemContent,
  ProfileIconContainer,
  ProfileItemContent,
  Text,
} from "./Item/Styles";
import Tooltip from "../../../components/Tooltip";
import { useMutation } from "../../../hooks";
import { SocketContext } from "../../../config/socket";
import { parseEmoji } from "../../../emoji";
import geestLogo from "../../../resources/img/icono.png";

const overlayStyle = {
  zIndex: "9999",
  width: "22rem",
  border: "solid 2px #cfcdcd",
  borderRadius: "1rem",
  paddingLeft: "0",
  overflow: "hidden",
  boxShadow: "8px 0px 10px 1px #CBCCC9",
};

const Routes = routes.filter(
  (route) => route.layout === "/home" && !route.hidden
);

const socketReconnectEvent = new Event("socketReconnect", {
  bubbles: true,
  cancelable: false,
});

const SideBar = ({
  isOpen,
  history,
  location,
  SettingsOpen,
  setSettingsOpen,
}) => {
  const [Sesion, setSesion] = useContext(SesionContext);
  const { MyAccountLabel, MyTeamsLabel, NotificationsLabel, UpgradePlanLabel } =
    Languages["ESP"];
  const [socket] = useContext(SocketContext);

  const [UserInfo, setUserInfo] = useState({});

  const { Initials, NPendings, NTemplates, NotificationsCount } = UserInfo;
  const { ProfilePicture } = Sesion;

  const counts = {
    "/pendings": NPendings,
    "/templates": NTemplates,
  };

  const [getUserSession] = useMutation({
    func: "Ver2-Session-gsi",
    onSuccess: (data) => {
      const { ProfilePicture } = data;
      setUserInfo(data);
      setSesion({
        ...Sesion,
        ProfilePicture,
      });
    },
  });

  const [changeSubsctriptionPlan] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: (res, { IdTeam }) => {
      history.push(`/home/premium/${IdTeam}`);
    },
  });

  useEffect(() => {
    if (isOpen || _.isEmpty(UserInfo)) {
      getUserSession({
        args: {
          Type: "Web",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    socket?.io.on("reconnect", () => {
      getUserSession({
        args: {
          Type: "Web",
        },
      });
      document.dispatchEvent(socketReconnectEvent);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    socket?.on("pendingTasks", (pendingTaks) => {
      setUserInfo({
        ...UserInfo,
        NPendings: pendingTaks,
      });
    });
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
    socket?.on(
      "notificationUpdate",
      ({ message, UnreadNotifications, data }) => {
        if (message) {
          const not = new Notification(parseEmoji(message), {
            data,
            icon: geestLogo,
          });
          not.addEventListener("click", (e) => {
            const data = e.target.data;
            if (data) {
              switch (data.type) {
                case "taskRedirect":
                  history.push(`/home/pendings/${data.actionId}`);
                  break;
                case "premimumChangePlanRedirect":
                  changeSubsctriptionPlan({
                    args: {
                      IdTeam: data.actionId,
                      NextState: "ChangePlan",
                    },
                    shippedData: {
                      IdTeam: data.actionId,
                    },
                  });
                  break;
                default:
                  console.log(data);
              }
            }
          });
        }
        setUserInfo({
          ...UserInfo,
          NotificationsCount: UnreadNotifications,
        });
      }
    );
    return () => {
      socket?.off("pendingTasks");
      socket?.off("notificationUpdate");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, UserInfo]);

  return (
    <SideBarBox>
      <Header onClick={() => history.push("/home/pendings")}>
        <GeestLogoImg src={isOpen ? GeestLogo : GeestIcon} />
      </Header>

      <Content $isOpen={isOpen}>
        <Body>
          {Routes.map((route, index) => (
            <Item
              onClick={() => history.push(route.layout + route.path)}
              route={route}
              count={counts[route.path]}
              isOpen={isOpen}
              key={index}
              active={location.pathname.includes(route.layout + route.path)}
            />
          ))}
        </Body>

        <Divider />
        {UserInfo.Intranet && (
          <ItemContent
            onClick={() => history.push("/home/intranet")}
            $active={location.pathname.includes("/home/intranet")}
            $isOpen={isOpen}
          >
            <IconContainer>
              <Img src={SidebarIntranet} />
            </IconContainer>

            <Text $isOpen={isOpen}>
              <div>Intranet</div>
            </Text>
          </ItemContent>
        )}
        <Footer>
          <Tooltip title={UpgradePlanLabel} placement="right" hide={isOpen}>
            <ItemContent
              onClick={() => history.push("/home/premium")}
              $active={location.pathname.includes("/home/premium")}
              $isOpen={isOpen}
            >
              <IconContainer>
                <Img src={UpgradePlan} />
              </IconContainer>

              <Text $isOpen={isOpen}>
                <div>{UpgradePlanLabel}</div>
              </Text>
            </ItemContent>
          </Tooltip>

          <ItemContent
            $isOpen={isOpen}
            onClick={() => history.push("/home/notifications")}
            $active={location.pathname.includes("/home/notifications")}
          >
            <IconContainer>
              <Badge
                count={NotificationsCount}
                size="small"
                offset={isOpen ? [-7, 6] : [-7, 6]}
                style={{ boxShadow: "none" }}
              >
                <Img src={SidebarNotifications} />
              </Badge>
            </IconContainer>

            <Text $isOpen={isOpen}>
              <div>{NotificationsLabel}</div>
            </Text>
          </ItemContent>

          <Tooltip title={MyTeamsLabel} placement="right" hide={isOpen}>
            <ItemContent
              onClick={() => history.push("/home/teams")}
              $active={location.pathname.includes("/home/teams")}
              $isOpen={isOpen}
            >
              <IconContainer>
                <Img src={SidebarTeams} />
              </IconContainer>

              <Text $isOpen={isOpen}>
                <div>{MyTeamsLabel}</div>
              </Text>
            </ItemContent>
          </Tooltip>

          <Popover
            open={SettingsOpen}
            placement="rightBottom"
            content={Settings}
            arrowPointAtCenter
            onOpenChange={(visible) => setSettingsOpen(visible)}
            overlayStyle={overlayStyle}
            align={{ offset: [0, 20] }}
            mouseLeaveDelay={0.3}
          >
            <ProfileItemContent
              $active={location.pathname.includes("/home/settings")}
              $isOpen={isOpen}
            >
              <ProfileIconContainer>
                {ProfilePicture ? (
                  <ProfileImg src={ProfilePicture} />
                ) : (
                  <ProfileNoImg>{Initials}</ProfileNoImg>
                )}
              </ProfileIconContainer>
              <Text $isOpen={isOpen}>
                <div>{MyAccountLabel}</div>
              </Text>
            </ProfileItemContent>
          </Popover>
        </Footer>
      </Content>
    </SideBarBox>
  );
};

export default withRouter(SideBar);
