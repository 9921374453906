import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import _, { noop as NOOP } from "lodash";
import { Badge } from "antd";
import { Popover } from "@material-ui/core";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
//@ts-ignore
import emoji from "emoji-dictionary";
import { ChatsData } from "../Pendings.d";
import ChatsContent from "../ChatsModal/ChatsContent";
import { FinishResponse } from "./Popups/FinishTaskModal.d";
import { Tag } from "../../../GeestUI";
import { useBugsnagMetadata, useFetch, useMutation } from "../../../hooks";
import { dateHourFormat } from "../../../Helpers";
import { UserList, FieldList } from "./MicroComponents";
import { Circle } from "../../../components/ColorPicker";
import MultiModal from "../../../components/MultiModal";
import Flow from "./Popups/Flow";
import Loading from "../../../components/Loading";
import { PostponeData } from "./Popups/PostponeModal/PostponeModal.d";
import {
  CustomImage,
  CustomLink,
  CustomList,
} from "../../../components/MarkdownHelpers";
import { Languages } from "./Dictionary";
import {
  AiOutlineBell,
  AiOutlineClockCircle,
  AiOutlineInfoCircle,
  AiOutlineNodeIndex,
  AiOutlineSave,
  AiOutlineFundView,
} from "react-icons/ai";
import {
  AssignedUser,
  DetailPendingProps,
  FormField,
  PendingDetail,
  TaskPriority,
  SaveTaskResponse,
  SecondaryModal,
  VarDBRow,
  VarDBRowField,
  Activity as ActivityTask,
} from "./DetailPending.d";
import {
  PostponeModal,
  FinishTaskModal,
  UnsavedModal,
  PriorityPopover,
} from "./Popups";
import {
  Container,
  TaskInfoContainer,
  UserInitials,
  UserProfilePic,
  ReactMarkdownStyled,
  TitleContainer,
  CloseContainer,
  TaskTitle,
  TaskMetaData,
  TaskMetaDataContainer,
  TaskMetaDataRow,
  ProcessNameInput,
  PriorityData,
  NoPriorityTag,
  TimeRow,
  TimeIconContainer,
  TaskDataContainer,
  TaskInformation,
  TaskActions,
  TaskDescription,
  TaskFieldsContainer,
  TaskFieldsList,
  FieldListContainer,
  TaskInfoContent,
  StageContainer,
  VarDBRowItem,
  TaskActivityContent,
  TaskActivity,
  TaskActivityData,
  ReminderBox,
  RedDot,
  IncomeInformation,
  UserImageContainer,
  UserCount,
  UsersContainer,
} from "./styles";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import Tooltip from "../../../components/Tooltip";
import { MessagesContext } from "../../../components/AppMessages";
import Button from "../../../GeestUI/Button";
import { EditIcon, CloseIcon } from "../../../components/hoverIcons";
import { useLocation, useHistory } from "react-router-dom";
import {
  SelectFileFieldModal,
  onPasteImage,
  FileType,
  FileField,
} from "../../../components/DynamicInput/Components/helpers/FileHelpers";
import { SocketContext } from "../../../config/socket";
import { SesionContext } from "../../../config/State";

const {
  Priority,
  NoPriority,
  StartedAt,
  Deadline,
  EndedAt,
  Description,
  ProcessInformation,
  Activity,
  Process,
  IdProcessExecutionLabel,
  Stage,
  NoStage,
  ActivityTimeConector,
  NewReminder,
  IncomeDescription,
  Consult,
  Editable,
  OpenFlux,
  Save,
  Postpone,
  Reschedule,
  Start,
  Reject,
  Finish,
  Aprove,
  TooltipLabel,
} = Languages["ESP"];

interface CellType {
  Value: string;
  Format: string;
  IdField: number;
  IdCell?: number;
}

const DetailPending: React.FC<DetailPendingProps> = ({
  idPending: IdPending = "",
  onClose = NOOP,
  reloadPendings = NOOP,
  onChangePending = NOOP,
  resetFilters = NOOP,
  reloadReport = NOOP,
}) => {
  const { idPending = IdPending } = useParams<{ idPending?: string }>();
  const { showMessage } = useContext(MessagesContext);
  const location = useLocation();
  const nextTask = location?.state;
  const history = useHistory();

  useBugsnagMetadata("Pending", {
    id: IdPending,
  });

  const editPriorityButton = useRef(null);

  const [EditingProcessName, setEditingProcessName] = useState<boolean>(false);
  const [newProcessName, setNewProcessName] = useState<string>("");
  const [EditableFields, setEditableFields] = useState<FormField[]>([]);
  const [ConsultFields, setConsultFields] = useState<FormField[]>([]);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [isFileOpen, setIsFileOpen] = useState<boolean>(false);
  const [usersExecutors, setUsersExecutors] = useState<AssignedUser[]>([]);
  const [usersRevisers, setUsersRevisers] = useState<AssignedUser[]>([]);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [varDBRows, setVarDBRows] = useState<VarDBRow[]>([]);
  const [idProcessExecution, setIdProcessExecution] = useState<number>();
  const [reportIncome, setReportIncome] = useState<boolean>(false);
  const [copiedFileValue, setCopiedFileValue] = useState<FileType | null>();
  const [fileFields, setFileFields] = useState<FileField[]>([]);
  const [incomeReported, setIncomeReported] = useState<{
    value:
      | string
      | { Type?: string; Value: number; Label: string }
      | { Type?: string; Value: number; Label: string }[];
    format: string;
  }>({ value: "0", format: "MXN" });
  const [taskPriority, setTaskPriority] = useState<TaskPriority | undefined>(
    undefined
  );
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [postponeCache, setPostponeCache] = useState<PostponeData>({
    date: "",
    reminder: "",
  });
  const [originalExecutors, setOriginalExecutors] = useState<AssignedUser[]>(
    []
  );
  const [originalRevisers, setOriginalRevisers] = useState<AssignedUser[]>([]);

  const [showProcessInfo, setShowProcessInfo] = useState<boolean>(false);
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const [priorityPopoverVisible, setPriorityPopoverVisible] =
    useState<boolean>(false);
  const [modal, setModal] = useState<"" | "Postpone" | "Finish" | "Unsaved">(
    ""
  );
  const [secondaryModal, setSecondaryModal] = useState<SecondaryModal>("");
  const [activity, setActivity] = useState<ActivityTask[]>([]);
  const [submitedTask, setSubmitedTask] = useState<boolean>(false);

  const [chatsData, setChatsData] = useState<ChatsData | null>(null);

  const [progress, setProgress] = useState<number>(0);
  const [socket] = useContext<any>(SocketContext);
  const [session] = useContext<any>(SesionContext);

  const progressLabels = {
    20: "Generando tu siguiente tarea",
    40: "Generando tu siguiente tarea",
    60: "Generando tu siguiente tarea",
    80: "Generando tu siguiente tarea",
    100: "Listo",
  };

  const handleFirstSetReportIncome = (
    initial: { Value: string; Format: string },
    fields: FormField[]
  ) => {
    let founded = false;
    fields.forEach((field) => {
      if (field.Configuration === "Income") {
        setIncomeReported({
          value: field.Value ?? initial.Value,
          format: field.Format ?? initial.Format ?? "MXN",
        });
        founded = true;
      } else if (field.DataType === "varDBRow") {
        (field as unknown as VarDBRowField).Cells.forEach((field) => {
          if (field.Configuration === "Income") {
            setIncomeReported({
              value: field.Value ?? initial.Value,
              format: field.Format ?? initial.Format ?? "MXN",
            });
            founded = true;
          }
        });
      }
    });

    // fallback
    if (!founded) {
      setIncomeReported({
        value: initial.Value,
        format: initial.Format,
      });
    }
  };

  useEffect(() => {
    socket?.on(
      `updateTask-${idPending}`,
      ({
        message,
        reload: shouldReload,
        Activity,
        Executors,
        Revisers,
        closeTask,
      }: {
        message: string;
        Activity: ActivityTask[];
        Executors: AssignedUser[];
        Revisers: AssignedUser[];
        reload: boolean;
        closeTask: boolean;
      }) => {
        showMessage(message, "info");
        if (closeTask) {
          onClose();
          return;
        }
        if (shouldReload && !submitedTask) {
          reload();
          return;
        }
        setActivity(Activity);
        if (Executors) {
          setUsersExecutors(Executors);
        }
        if (Revisers) {
          setUsersRevisers(Revisers);
        }
      }
    );
    window.addEventListener("socketReconnect", reload);

    return () => {
      socket?.off(`updateTask-${idPending}`);
      window.removeEventListener("socketReconnect", reload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, submitedTask]);

  const {
    data: pendingDetail,
    error,
    reload,
  } = useFetch<PendingDetail>({
    func: "Ver2-MyPendings-gpd",
    args: { IdTaskValue: idPending },
    onSuccess: (response) => {
      setEditingProcessName(false);
      setEditableFields(response.FieldsForm.EditableFields || []);
      const fileFields = (response.FieldsForm.EditableFields || [])
        .filter((field) => field.DataType === "file")
        .map((field) => ({
          Id: field.IdField,
          Label: field.Label,
        }));
      let fileCells: FileField[] = [];
      (response.FieldsForm.EditableFields || [])
        .filter((field) => field.DataType === "varDBRow")
        .forEach((field) => {
          const fileFields = (field as unknown as VarDBRowField).Cells.filter(
            (field) => field.DataType === "file"
          ).map((cell) => ({
            Id: cell.IdField,
            Label: `${field.Label} > ${cell.Label}`,
          }));
          fileCells = [...fileCells, ...fileFields];
        });
      setFileFields([...fileFields, ...fileCells]);
      setConsultFields(response.FieldsForm.ConsultFields || []);
      setVarDBRows(response.ProcessInfo?.VarDBRows || []);
      setIdProcessExecution(response.ProcessInfo?.IdProcessExecution);
      setIsEdited(false);
      setMessageCount(response.MessageCount);
      setIsWaiting(response.TaskStatus === "WaitingToStart");
      setReportIncome(response.Mode === "ReportIncome");
      if (response.TaskActivity) {
        setActivity(response.TaskActivity);
      }
      if (response.Mode === "ReportIncome") {
        handleFirstSetReportIncome(
          response.ModeInit,
          response.FieldsForm.EditableFields || []
        );
      }
      if (response.TaskStatus === "WaitingToStart") {
        setPostponeCache({
          date: response.StartedAt,
          reminder: "",
        });
      }
      if (response.Priority) setTaskPriority(response.Priority);
      if (response.UsersRelations?.Executors) {
        setUsersExecutors(response.UsersRelations.Executors);
        setOriginalExecutors(response.UsersRelations.Executors);
      } else {
        setUsersExecutors([]);
        setOriginalExecutors([]);
      }
      if (response.UsersRelations?.Revisers) {
        setUsersRevisers(response.UsersRelations.Revisers);
        setOriginalRevisers(response.UsersRelations.Revisers);
      } else {
        setUsersRevisers([]);
        setOriginalRevisers([]);
      }
      if (nextTask) {
        if (progress !== 100) {
          setProgress(100);
        }
      }
    },
  });

  useEffect(() => {
    if (!nextTask) return;
    const firstTime = Math.random() * 1000;
    const secondTime = Math.random() * 1000 + firstTime;
    const thirdTime = Math.random() * 1000 + secondTime;
    const fourthTime = Math.random() * 1000 + thirdTime;
    const fifthTime = Math.random() * 1000 + fourthTime;
    setTimeout(() => {
      if (!pendingDetail) {
        setProgress(20);
      }
    }, firstTime);
    setTimeout(() => {
      if (!pendingDetail) {
        setProgress(40);
      }
    }, secondTime);
    setTimeout(() => {
      if (!pendingDetail) {
        setProgress(60);
      }
    }, thirdTime);
    setTimeout(() => {
      if (!pendingDetail) {
        setProgress(80);
      }
    }, fourthTime);
    setTimeout(() => {
      if (!pendingDetail) {
        setProgress(100);
      }
    }, fifthTime);
  }, [nextTask, pendingDetail]);

  const [getChatChannelsForTasks] = useMutation<ChatsData>({
    func: "Ver2-MyPendings-gccft",
    onSuccess: (res) => setChatsData(res),
  });

  const [reassignUsersExecutors] = useMutation<[]>({
    func: "Ver2-MyPendings-rue",
    onSuccess: (_response, newExecutors: AssignedUser[]) => {
      setUsersExecutors(newExecutors as AssignedUser[]);
      if (isEdited) {
        handleOnSave();
      }
      reload();
      reloadReport();
    },
    onError: () => reload(),
  });
  const [reassignUsersRevisers] = useMutation<[]>({
    func: "Ver2-MyPendings-rar",
    onSuccess: (_response, newRevisers: AssignedUser[]) => {
      setUsersRevisers(newRevisers as AssignedUser[]);
      if (isEdited) {
        handleOnSave();
      }
      reload();
      reloadReport();
    },
    onError: () => reload(),
  });

  const [reviserDisapprobedTask, rejectingTask] = useMutation<[]>({
    func: "Ver2-MyPendings-rdt",
    onSuccess: () => {
      reload();
      reloadReport();
    },
    onError: () => reload(),
  });

  const [userSaveTask, savingTask] = useMutation<SaveTaskResponse>({
    func: "Ver2-MyPendings-ust",
    onSuccess: (response, extraData) => {
      setEditableFields(response.EditableFields);
      if (response.ProccessInfo) {
        setVarDBRows(response.ProccessInfo.VarDBRows);
      }
      setIsEdited(false);
      reload();
      reloadReport();
      if (extraData?.extraFunc) {
        extraData.extraFunc();
      }
    },
    onError: () => reload(),
  });

  const [updateProcessExecutionTitle] = useMutation<[]>({
    func: "Ver2-MyPendings-upet",
    onSuccess: () => {
      if (isEdited) {
        handleOnSave();
      }
      reload();
      reloadPendings();
      reloadReport();
    },
    onError: () => reload(),
  });

  const [userFinishTask, finishingTask] = useMutation<FinishResponse>({
    func: "Ver2-MyPendings-uft",
    onSuccess: (idNextTask, { shouldCloseTask }) => {
      setSubmitedTask(false);
      if (shouldCloseTask) {
        onClose();
      } else if (!idNextTask || Array.isArray(idNextTask)) {
        reload();
      }
      reloadReport();
      if (idNextTask && !Array.isArray(idNextTask)) {
        onChangePending(idNextTask, true);
      }
    },
  });

  const [startScheduledTask, startingTask] = useMutation<FinishResponse>({
    func: "Ver2-MyPendings-sst",
    onSuccess: () => {
      reload();
      //reloadPendings();
      reloadReport();
    },
  });

  if (error) {
    setTimeout(() => {
      onClose(true);
    }, 1);
  }

  const hideTaskInformation =
    _.isEmpty(ConsultFields) &&
    _.isEmpty(EditableFields) &&
    _.isEmpty(pendingDetail?.Description);

  const getParsedActivityDate = (date: string) => {
    if (Number(moment(date).format("DD")) === Number(moment().format("DD"))) {
      return "Hoy";
    }
    if (
      Number(moment(date).format("DD")) ===
      Number(moment().format("DD")) - 1
    ) {
      return "Ayer";
    }
    return moment(date).format("DD/MMM/YY");
  };

  const Modals: { [key: string]: React.ReactNode } = {
    Postpone: (
      <PostponeModal
        idPending={idPending}
        onConfirm={() => {
          onClose();
          reloadReport();
        }}
        onCancel={() => setModal("")}
        isWaiting={isWaiting}
        defaultValue={postponeCache}
        setDefaultValue={setPostponeCache}
        fieldsForm={{
          ConsultFields: pendingDetail?.FieldsForm.ConsultFields,
          EditableFields: EditableFields,
        }}
      />
    ),
    Finish: (
      <FinishTaskModal
        idTeam={pendingDetail?.IdTeam}
        idPending={idPending}
        onConfirm={() => {
          onClose();
          reloadReport();
        }}
        onCancel={() => setModal("")}
        fieldsForm={{
          ConsultFields: pendingDetail?.FieldsForm.ConsultFields,
          EditableFields: EditableFields,
        }}
      />
    ),
    Unsaved: (
      <UnsavedModal
        onConfirm={() => onClose(true)}
        onCancel={() => setModal("")}
        onSave={() => {
          setModal("");
          handleOnSave();
        }}
      />
    ),
  };

  const SecondaryModals: { [key: string]: React.ReactNode } = {
    Flow: (
      <Flow
        IdTaskValue={Number(idPending)}
        onClose={() => setSecondaryModal("")}
        onChangePending={onChangePending}
        onRestartFlux={() => {
          showMessage("Reinicio exitoso", "success");
          reloadReport();
          onClose(true);
        }}
      />
    ),
    Chat: (
      <ChatsContent
        chatsData={chatsData}
        onClose={() => setSecondaryModal("")}
        showHeader
      />
    ),
  };

  const handleOpenChat = () => {
    onChangeSecondaryModal("Chat");
    getChatChannelsForTasks({ args: { IdTaskValue: idPending } });
  };

  const handleOnUpdateField = (
    updatedField: FormField,
    index?: number,
    cellsValues: CellType[] = [],
    updateRow: boolean = false,
    editableField = EditableFields,
    consultFields = ConsultFields,
    consultIndex?: number,
    returnUpdatedFields = false
  ) => {
    let fieldIndex = index;
    let newEditableFields = _.cloneDeep(editableField);
    if (fieldIndex === undefined && !consultIndex) {
      fieldIndex = _.findIndex(newEditableFields, {
        IdField: updatedField.IdField,
      });
    }
    if (fieldIndex !== undefined && fieldIndex >= 0) {
      newEditableFields[fieldIndex] = updatedField;
      setEditableFields([...newEditableFields]);
      setIsEdited(true);
    }

    let newConsultFields = _.cloneDeep(consultFields);
    if (consultIndex && consultIndex >= 0) {
      newConsultFields[consultIndex] = updatedField;
      setConsultFields([...newConsultFields]);
    }

    // update vardb values on change row
    if (
      updatedField.DataType === "varDBRow" &&
      (cellsValues.length > 0 || updateRow)
    ) {
      const field = updatedField as any;

      // update consult fields
      const consultFieldsAux = _.cloneDeep(consultFields);
      let consultFieldsToEdit: any[] = [];
      let consultFieldsIndexToUpdate: number[] = [];

      consultFieldsAux.forEach((fieldAux: any, index) => {
        if (fieldAux.IdVarDBGroup === field.IdVarDBGroup) {
          consultFieldsToEdit.push(fieldAux);
          consultFieldsIndexToUpdate.push(index);
        }
      });

      consultFieldsIndexToUpdate.forEach((idx) => {
        let fieldToEdit: any = consultFieldsAux[idx];
        fieldToEdit.IdRowSelected = field.IdRowSelected;
        fieldToEdit.RowSelectedLabel = field.RowSelectedLabel;
        fieldToEdit.Cells.map((cell: any) => {
          const newCellValue = _.find(cellsValues, { IdField: cell.IdField });
          cell.Value = newCellValue ? newCellValue.Value : "";
          if (cell.DataType === "phone_number") {
            cell.Format = newCellValue?.Format ? newCellValue?.Format : "MX";
          }
          if (cell.DataType === "currency") {
            cell.Format = newCellValue?.Format ? newCellValue?.Format : "MXN";
          }
          return cell;
        });
        consultFieldsAux[idx] = fieldToEdit;
      });
      setConsultFields(consultFieldsAux);

      // update editable fields
      const editableFieldsAux = _.cloneDeep(editableField);
      let editableFieldsToEdit: any[] = [];
      let editableFieldsIndexToUpdate: number[] = [];

      editableFieldsAux.forEach((fieldAux: any, index) => {
        if (fieldAux.IdVarDBGroup === field.IdVarDBGroup) {
          editableFieldsToEdit.push(fieldAux);
          editableFieldsIndexToUpdate.push(index);
        }
      });

      editableFieldsIndexToUpdate.forEach((idx) => {
        let fieldToEdit: any = editableFieldsAux[idx];
        fieldToEdit.IdRowSelected = field.IdRowSelected;
        fieldToEdit.RowSelectedLabel = field.RowSelectedLabel;
        fieldToEdit.Cells.map((cell: any) => {
          const newCellValue = _.find(cellsValues, { IdField: cell.IdField });
          cell.Value = newCellValue ? newCellValue.Value : "";
          if (cell.DataType === "phone_number") {
            cell.Format = newCellValue?.Format ? newCellValue?.Format : "MX";
          }
          if (cell.DataType === "currency") {
            cell.Format = newCellValue?.Format ? newCellValue?.Format : "MXN";
          }
          return cell;
        });
        editableFieldsAux[idx] = fieldToEdit;
      });

      setEditableFields(editableFieldsAux);
      if (returnUpdatedFields) {
        return {
          EditableFields: editableFieldsAux,
          ConsultFields: consultFieldsAux,
        };
      }
    }
  };

  const updateAllFields = (editable: FormField[], consult: FormField[]) => {
    setEditableFields(editable);
    setConsultFields(consult);
  };

  const updateExecutors = (): void => {
    if (_.isEqual(originalExecutors, usersExecutors)) {
      setUsersExecutors(originalExecutors);
      return;
    }
    reassignUsersExecutors({
      args: {
        IdTaskValue: idPending,
        Executors: usersExecutors,
      },
      shippedData: usersExecutors,
    });
  };
  const updateRevisers = (): void => {
    if (_.isEqual(originalRevisers, usersRevisers)) {
      setUsersExecutors(originalExecutors);
      return;
    }
    reassignUsersRevisers({
      args: {
        IdTaskValue: idPending,
        Revisers: usersRevisers,
      },
      shippedData: usersRevisers,
    });
  };

  const handleOnClose = (): void => {
    if (!isFileOpen) {
      if (isEdited) {
        setModal("Unsaved");
      } else {
        onClose();
      }
    }
  };

  const handleOnReject = (): void => {
    reviserDisapprobedTask({
      args: {
        IdTaskValue: idPending,
        FieldsForm: {
          ...(pendingDetail?.FieldsForm || {}),
          EditableFields,
        },
      },
    });
  };

  const handleOnSave = (): void => {
    userSaveTask({
      args: {
        IdTaskValue: idPending,
        FieldsForm: {
          ...(pendingDetail?.FieldsForm || {}),
          EditableFields,
        },
      },
    });
  };
  const handleOnFinish = (openModal: boolean, approving?: boolean): void => {
    if (openModal) {
      setModal("Finish");
    } else {
      let shouldCloseTask = !(
        !approving && usersRevisers.find(({ IdUser }) => IdUser === session.Id)
      );
      setSubmitedTask(true);
      userFinishTask({
        args: {
          IdTeam: pendingDetail?.IdTeam,
          IdTaskValue: idPending,
          FieldsForm: {
            ConsultFields: pendingDetail?.FieldsForm.ConsultFields,
            EditableFields: EditableFields,
          },
        },
        shippedData: { shouldCloseTask },
      });
    }
  };

  const handleOnApprove = (): void => handleOnFinish(false, true);

  const handleOnStart = (): void => {
    if (isEdited) {
      userSaveTask({
        args: {
          IdTaskValue: idPending,
          FieldsForm: {
            ...(pendingDetail?.FieldsForm || {}),
            EditableFields,
          },
        },
      });
    }
    startScheduledTask({
      args: {
        IdTaskValue: idPending,
      },
    });
  };

  const onChangeProcessName = (): void => {
    const ProcessExecutionTitle = newProcessName.trim();
    // Validate empty or no changed.
    if (
      _.isEmpty(ProcessExecutionTitle) ||
      ProcessExecutionTitle === pendingDetail?.ProcessExecutionTitle
    )
      setEditingProcessName(false);
    else {
      updateProcessExecutionTitle({
        args: {
          IdTaskValue: idPending,
          ProcessExecutionTitle,
        },
      });
    }
  };

  const onChangeSecondaryModal = (newSecondaryModal: SecondaryModal): void => {
    if (newSecondaryModal === secondaryModal) {
      setSecondaryModal("");
    } else setSecondaryModal(newSecondaryModal);
  };

  const isStuck = (date: string) => {
    return moment().diff(date, "minutes") >= 0;
  };

  const onGeneratePdf = (extraFunc: () => void) => {
    userSaveTask({
      args: {
        IdTaskValue: idPending,
        FieldsForm: {
          ...(pendingDetail?.FieldsForm || {}),
          EditableFields,
        },
      },
      shippedData: { extraFunc },
    });
  };

  const onPasteImageToField = (IdField: number, file: FileType) => {
    const value = file;
    const index = EditableFields.findIndex(
      (field) => field.IdField === IdField
    );
    if (index >= 0) {
      const oldValue = (EditableFields[index] as FormField).Value as any[];
      setIsEdited(true);
      let EditableFieldsAux = [...EditableFields];
      EditableFieldsAux[index].Value = oldValue
        ? [...oldValue, value]
        : [value];
      setEditableFields(EditableFieldsAux);
    } else {
      const index = EditableFields.findIndex(
        (field) =>
          !!(field as unknown as VarDBRowField).Cells?.find(
            (cell) => cell.IdField === IdField
          )
      );
      if (index >= 0) {
        const vardbRowInput = EditableFields[index];
        const cells = (vardbRowInput as unknown as VarDBRowField).Cells.map(
          (cell) => {
            if (cell.IdField === IdField) {
              return {
                ...cell,
                Value: cell.Value ? [...(cell.Value as any[]), value] : [value],
              };
            }
            return cell;
          }
        );
        setIsEdited(true);
        let EditableFieldsAux = [...EditableFields];
        (EditableFieldsAux[index] as unknown as VarDBRowField).Cells = cells;
        setEditableFields(EditableFieldsAux);
      }
    }
  };

  useEffect(() => {
    if (fileFields.length > 0) {
      const onKeyDown = async (e: KeyboardEvent) => {
        if (e.key.toLowerCase() === "v" && e.ctrlKey) {
          const value = await onPasteImage(
            pendingDetail?.FieldsForm.FilesPath || ""
          );
          if (value) {
            if (fileFields.length > 1) {
              setCopiedFileValue(value);
            } else {
              onPasteImageToField(fileFields[0].Id, value);
            }
          }
        }
      };
      window.addEventListener("keydown", onKeyDown);

      return () => window.removeEventListener("keydown", onKeyDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileFields, pendingDetail, EditableFields]);

  return (
    <MultiModal
      open
      onClose={() => handleOnClose()}
      openSecondary={!_.isEmpty(secondaryModal)}
      secondaryModalContent={SecondaryModals[secondaryModal]}
      width="800px"
      secondaryWidth={secondaryModal === "Flow" ? "550px" : "449px"}
    >
      {copiedFileValue && (
        <SelectFileFieldModal
          file={copiedFileValue}
          fileFields={fileFields}
          onChangeFileField={onPasteImageToField}
          closeModal={() => setCopiedFileValue(null)}
        />
      )}
      {Modals[modal]}
      {pendingDetail ? (
        <Container>
          <TitleContainer>
            <TaskTitle>
              {pendingDetail?.TaskTitle.replace(
                /:\w+:/gi,
                (name) => emoji.getUnicode(name) ?? name
              )}
            </TaskTitle>
            <CloseContainer>
              <CloseIcon onClick={() => handleOnClose()} size={20} />
            </CloseContainer>
          </TitleContainer>
          <TaskMetaDataContainer>
            <TaskMetaData>
              <TaskMetaDataRow mb="6px">
                {EditingProcessName ? (
                  <ProcessNameInput
                    autoFocus
                    onKeyDown={({ key }) => {
                      if (key === "Enter") onChangeProcessName();
                    }}
                    onBlur={onChangeProcessName}
                    value={newProcessName}
                    onChange={({ target: { value } }) =>
                      setNewProcessName(value)
                    }
                  />
                ) : pendingDetail?.ProcessExecutionTitle?.length <= 30 ? (
                  <p>
                    {pendingDetail?.ProcessExecutionTitle.replace(
                      /:\w+:/gi,
                      (name) => emoji.getUnicode(name) ?? name
                    )}
                  </p>
                ) : (
                  <Tooltip
                    title={pendingDetail?.ProcessExecutionTitle.replace(
                      /:\w+:/gi,
                      (name) => emoji.getUnicode(name) ?? name
                    )}
                  >
                    <p>
                      {pendingDetail?.ProcessExecutionTitle.replace(
                        /:\w+:/gi,
                        (name) => emoji.getUnicode(name) ?? name
                      )}
                    </p>
                  </Tooltip>
                )}
                <Tooltip
                  title={TooltipLabel.ChangeProcessName}
                  placement="bottom"
                >
                  {pendingDetail?.Permissions.UserCanEditProcessName && (
                    <EditIcon
                      onClick={() => {
                        setNewProcessName(
                          pendingDetail?.ProcessExecutionTitle || ""
                        );
                        setEditingProcessName(true);
                      }}
                      filled
                    />
                  )}
                </Tooltip>
              </TaskMetaDataRow>

              <TaskMetaDataRow>
                <PriorityData>
                  <p>{Priority}</p>
                  {taskPriority ? (
                    <Tag
                      color={taskPriority?.Color || "#757575"}
                      hidden={_.isEmpty(taskPriority)}
                    >
                      {taskPriority?.Title}
                    </Tag>
                  ) : (
                    <NoPriorityTag>{NoPriority}</NoPriorityTag>
                  )}
                </PriorityData>
                {pendingDetail?.Permissions.UserCanChangeTaskPriority && (
                  <>
                    <Popover
                      open={priorityPopoverVisible}
                      anchorEl={editPriorityButton.current}
                      onClose={() => setPriorityPopoverVisible(false)}
                      PaperProps={{
                        style: {
                          borderRadius: "6px",
                          width: "362px",
                          padding: "0 0 12px",
                        },
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <PriorityPopover
                        setVisible={setPriorityPopoverVisible}
                        taskPriority={taskPriority}
                        onChange={setTaskPriority}
                        idPending={idPending}
                        idTeam={pendingDetail?.IdTeam || 0}
                      />
                    </Popover>
                    <Tooltip
                      title={TooltipLabel.ChangePriority}
                      placement="bottom"
                    >
                      <EditIcon
                        onClick={() => setPriorityPopoverVisible(true)}
                        ref={editPriorityButton}
                        filled
                      />
                    </Tooltip>
                  </>
                )}
              </TaskMetaDataRow>
            </TaskMetaData>

            <TaskMetaData>
              <>
                <UserList
                  users={usersExecutors}
                  onChange={setUsersExecutors}
                  onFinishEdit={updateExecutors}
                  teamMembers={pendingDetail?.FieldsForm.UserSelectDataOrigin}
                  userCanReassign={
                    pendingDetail?.Permissions.UserCanReassignExecutors || false
                  }
                  type="executors"
                  resetFilters={resetFilters}
                />
              </>
            </TaskMetaData>

            <TaskMetaData>
              <>
                <UserList
                  users={usersRevisers}
                  onChange={setUsersRevisers}
                  onFinishEdit={updateRevisers}
                  teamMembers={pendingDetail?.FieldsForm.UserSelectDataOrigin}
                  userCanReassign={
                    pendingDetail?.Permissions.UserCanReassignRevisers || false
                  }
                  type="revisers"
                  resetFilters={resetFilters}
                  isInRevision={
                    !usersExecutors.find(
                      (user) => user.UserTaskStatus !== "Completed"
                    )
                  }
                />
              </>
            </TaskMetaData>

            <TaskMetaData>
              <TaskMetaDataRow>
                <TimeRow>
                  <TimeIconContainer>
                    <AiOutlineClockCircle
                      color="#1f9334"
                      style={{ width: "16px", height: "16px" }}
                    />
                    <p>{StartedAt}</p>
                  </TimeIconContainer>
                  <p>{dateHourFormat(pendingDetail?.StartedAt, true)}</p>
                </TimeRow>
              </TaskMetaDataRow>

              <TaskMetaDataRow>
                <TimeRow>
                  <TimeIconContainer>
                    <AiOutlineClockCircle
                      color="#db232c"
                      style={{ width: "16px", height: "16px" }}
                    />
                    <p>{Deadline}</p>
                  </TimeIconContainer>
                  <p
                    style={{
                      color: isStuck(pendingDetail?.Deadline) ? "#db232c" : "",
                    }}
                  >
                    {dateHourFormat(pendingDetail?.Deadline, true)}
                  </p>
                </TimeRow>
              </TaskMetaDataRow>
              {pendingDetail?.EndedAt && (
                <TaskMetaDataRow>
                  <TimeRow>
                    <TimeIconContainer>
                      <AiOutlineClockCircle
                        color="#48505e"
                        style={{ width: "16px", height: "16px" }}
                      />
                      <p>{EndedAt}</p>
                    </TimeIconContainer>
                    <p>{dateHourFormat(pendingDetail?.EndedAt, true)}</p>
                  </TimeRow>
                </TaskMetaDataRow>
              )}
            </TaskMetaData>
          </TaskMetaDataContainer>

          <TaskDataContainer>
            <TaskInformation>
              {reportIncome && (
                <IncomeInformation>
                  <AiOutlineInfoCircle color="#ffae3d" size={22} />
                  <p>
                    {IncomeDescription(
                      incomeReported.value,
                      incomeReported.format
                    )}
                  </p>
                </IncomeInformation>
              )}

              {pendingDetail.Description && (
                <TaskDescription>
                  <p className="task-title">{Description}</p>

                  <ReactMarkdownStyled
                    children={pendingDetail.Description.replace(
                      /\n/gi,
                      "&nbsp;\n"
                    ).replace(
                      /:\w+:/gi,
                      (name) => emoji.getUnicode(name) ?? name
                    )}
                    remarkPlugins={[remarkGfm, remarkBreaks]}
                    components={{
                      a: ({ children, href }) => {
                        return <CustomLink href={href}>{children}</CustomLink>;
                      },
                      ul: ({ children }) => {
                        return <CustomList>{children}</CustomList>;
                      },
                      img: ({ children, src, alt }) => {
                        return (
                          <CustomImage src={src} alt={alt}>
                            {children}
                          </CustomImage>
                        );
                      },
                    }}
                  />
                </TaskDescription>
              )}

              <TaskFieldsContainer
                style={
                  pendingDetail?.PendingView === "MultiScroll"
                    ? { maxHeight: "70%" }
                    : {}
                }
              >
                <TaskFieldsList
                  style={
                    pendingDetail?.PendingView === "MultiScroll"
                      ? { overflow: "auto", maxHeight: "60vh" }
                      : {}
                  }
                >
                  <p className="task-title">{Consult}</p>
                  <FieldListContainer>
                    <FieldList
                      fields={ConsultFields}
                      filesPath={pendingDetail?.FieldsForm.FilesPath}
                      IdProcessExecution={Number(idProcessExecution)}
                      isConsult
                      onUpdateField={handleOnUpdateField}
                      UserSelectDataOrigin={
                        pendingDetail?.FieldsForm.UserSelectDataOrigin
                      }
                      VarDBDataOrigin={
                        pendingDetail?.FieldsForm.VarDBDataOrigin
                      }
                      isFileOpen={isFileOpen}
                      setIsFileOpen={setIsFileOpen}
                      funcBeforePdf={onGeneratePdf}
                    />
                  </FieldListContainer>
                </TaskFieldsList>

                <TaskFieldsList
                  style={
                    pendingDetail?.PendingView === "MultiScroll"
                      ? { overflow: "auto", maxHeight: "60vh" }
                      : {}
                  }
                >
                  <p className="task-title">{Editable}</p>
                  <FieldListContainer>
                    <FieldList
                      fields={EditableFields}
                      filesPath={pendingDetail?.FieldsForm.FilesPath}
                      IdProcessExecution={Number(idProcessExecution)}
                      editable={pendingDetail?.Permissions.UserCanEditFields}
                      onUpdateField={handleOnUpdateField}
                      UserSelectDataOrigin={
                        pendingDetail?.FieldsForm.UserSelectDataOrigin
                      }
                      VarDBDataOrigin={
                        pendingDetail?.FieldsForm.VarDBDataOrigin
                      }
                      isFileOpen={isFileOpen}
                      setIsFileOpen={setIsFileOpen}
                      setIncomeReported={setIncomeReported}
                      updateAllFields={updateAllFields}
                      consultFields={ConsultFields}
                      funcBeforePdf={onGeneratePdf}
                      disablePdf={savingTask || finishingTask}
                    />
                  </FieldListContainer>
                </TaskFieldsList>
              </TaskFieldsContainer>

              <TaskInfoContainer
                isOpen={showProcessInfo}
                hidden={hideTaskInformation}
              >
                <p
                  className="info-title"
                  onClick={() => setShowProcessInfo(!showProcessInfo)}
                >
                  {showProcessInfo ? (
                    <IoIosArrowDown color="#48505e" size={15} />
                  ) : (
                    <IoIosArrowForward color="#48505e" size={15} />
                  )}
                  {ProcessInformation}
                </p>

                {showProcessInfo && (
                  <TaskInfoContent>
                    <div className="col">
                      <p className="col-title">{Process}</p>
                      <p>{pendingDetail?.ProcessInfo?.ProcessTemplateName}</p>

                      <p className="col-title">{IdProcessExecutionLabel}</p>
                      <p>{pendingDetail?.ProcessInfo?.IdProcessExecution}</p>

                      <p className="col-title">{Stage}</p>
                      <StageContainer>
                        <Circle
                          color={
                            pendingDetail?.ProcessInfo?.Stage?.Color ?? "white"
                          }
                          border="1px solid #828d9e"
                        />
                        <p>
                          {pendingDetail?.ProcessInfo?.Stage?.Label ?? NoStage}
                        </p>
                      </StageContainer>
                    </div>

                    <div className="col">
                      {varDBRows.map((dbRow, idx) => (
                        <VarDBRowItem key={dbRow.Label + idx}>
                          <p className="title">{dbRow.Label}</p>
                          <p className="varDBRow-Item">{dbRow.Value}</p>
                        </VarDBRowItem>
                      ))}
                    </div>
                  </TaskInfoContent>
                )}
              </TaskInfoContainer>

              <TaskInfoContainer
                isOpen={showActivity}
                hidden={hideTaskInformation}
              >
                <p
                  className="info-title"
                  onClick={() => setShowActivity(!showActivity)}
                >
                  {showActivity ? (
                    <IoIosArrowDown color="#48505e" size={15} />
                  ) : (
                    <IoIosArrowForward color="#48505e" size={15} />
                  )}
                  {Activity}
                </p>

                {showActivity && (
                  <TaskActivityContent>
                    {activity.map(
                      (activity, idx) =>
                        Boolean(activity.Users.length) && (
                          <TaskActivity
                            isReminder={activity.Type === "reminder"}
                            key={activity.Message + idx}
                          >
                            {activity.Users.length > 1 ? (
                              <Tooltip
                                title={activity.Users.map(
                                  ({ FirstName, LastName }) =>
                                    `${FirstName} ${LastName}`
                                ).join(", ")}
                              >
                                <UsersContainer>
                                  {_.slice(activity.Users, 0, 3).map(
                                    (User, index) => (
                                      <UserImageContainer
                                        $index={index}
                                        key={User.IdUser}
                                      >
                                        {User.ProfilePicture ? (
                                          <UserProfilePic
                                            image={User.ProfilePicture || ""}
                                            align="middle"
                                            justify="center"
                                          />
                                        ) : (
                                          <UserInitials
                                            align="middle"
                                            justify="center"
                                          >
                                            {User.Initials}
                                          </UserInitials>
                                        )}
                                      </UserImageContainer>
                                    )
                                  )}
                                  {activity.Users.length > 3 && (
                                    <UserCount>3+</UserCount>
                                  )}
                                </UsersContainer>
                              </Tooltip>
                            ) : (
                              _.slice(activity.Users, 0, 3).map(
                                (User, index) => (
                                  <UserImageContainer
                                    $index={index}
                                    key={User.IdUser}
                                  >
                                    {User.ProfilePicture ? (
                                      <UserProfilePic
                                        image={User.ProfilePicture || ""}
                                        align="middle"
                                        justify="center"
                                      />
                                    ) : (
                                      <UserInitials
                                        align="middle"
                                        justify="center"
                                      >
                                        {User.Initials}
                                      </UserInitials>
                                    )}
                                  </UserImageContainer>
                                )
                              )
                            )}

                            <TaskActivityData>
                              <div className="userAndTime">
                                <p className="name">
                                  {activity.Users.length === 1
                                    ? `${activity.Users[0].FirstName} ${activity.Users[0].LastName}`
                                    : activity.Users.length > 3
                                    ? "Más de 3 personas"
                                    : `${activity.Users.length} personas`}
                                </p>
                                <p className="time">{`${getParsedActivityDate(
                                  activity.Time
                                )} ${ActivityTimeConector} ${moment(
                                  activity.Time
                                ).format("HH:mm")}`}</p>
                              </div>

                              {activity.Type === "reminder" ? (
                                <ReminderBox>
                                  <div className="notification-icon">
                                    <AiOutlineBell color="#48505e" />
                                    <RedDot />
                                  </div>
                                  <p>
                                    {`${NewReminder}: `}
                                    <span>{activity.Message}</span>
                                  </p>
                                </ReminderBox>
                              ) : (
                                <p>{activity.Message}</p>
                              )}
                            </TaskActivityData>
                          </TaskActivity>
                        )
                    )}
                  </TaskActivityContent>
                )}
              </TaskInfoContainer>
            </TaskInformation>

            <TaskActions>
              <div className="actions">
                <Button
                  type="secondary"
                  onClick={() => onChangeSecondaryModal("Flow")}
                  disabled={!pendingDetail?.Permissions.UserCanOpenFlux}
                  Icon={AiOutlineNodeIndex}
                  size="large"
                  style={{
                    fontFamily: "Gotham-Bold",
                  }}
                >
                  {OpenFlux}
                </Button>
                <Button
                  type="secondary"
                  onClick={handleOpenChat}
                  size="large"
                  style={{
                    fontFamily: "Gotham-Bold",
                  }}
                >
                  <Badge count={messageCount} offset={[0, 6]} size="small">
                    <IoChatbubbleEllipsesOutline
                      color="#0273e9"
                      size={20}
                      style={{ marginRight: "5px" }}
                    />
                  </Badge>
                  Chat
                </Button>
                <Button
                  type="secondary"
                  onClick={() =>
                    history.push({
                      pathname:
                        "/home/reports/detail/" +
                        pendingDetail?.ProcessInfo?.IdProcessExecution,
                      state: {
                        shouldGoBackTo: "/home/pendings",
                      },
                    })
                  }
                  Icon={AiOutlineFundView}
                  size="large"
                >
                  Ver detalle
                </Button>
              </div>

              <div className="actions bottom-actions">
                {pendingDetail?.Permissions.UserCanSaveTask && (
                  <Button
                    onClick={handleOnSave}
                    disabled={savingTask || !isEdited}
                    Icon={AiOutlineSave}
                    size="large"
                    style={{
                      fontFamily: "Gotham-Bold",
                    }}
                  >
                    {Save}
                  </Button>
                )}

                {pendingDetail?.Permissions.UserCanPostponeTask && (
                  <Button
                    type="secondary"
                    onClick={() => setModal("Postpone")}
                    size="large"
                    style={{
                      fontFamily: "Gotham-Bold",
                    }}
                  >
                    {isWaiting ? Reschedule : Postpone}
                  </Button>
                )}

                {pendingDetail?.Permissions.UserCanStartTask && (
                  <Button
                    type="primary"
                    onClick={handleOnStart}
                    disabled={startingTask}
                    size="large"
                    style={{
                      fontFamily: "Gotham-Bold",
                    }}
                  >
                    {Start}
                  </Button>
                )}

                {pendingDetail?.Permissions.UserCanRejectTask && (
                  <Button
                    type="secondary"
                    size="large"
                    onClick={handleOnReject}
                    disabled={rejectingTask}
                    style={{
                      fontFamily: "Gotham-Bold",
                    }}
                  >
                    {Reject}
                  </Button>
                )}

                {pendingDetail?.Permissions.UserCanFinishTask && (
                  <Button
                    type="primary"
                    onClick={() => handleOnFinish(false)}
                    size="large"
                    disabled={finishingTask || savingTask}
                    style={{
                      fontFamily: "Gotham-Bold",
                    }}
                  >
                    {Finish}
                  </Button>
                )}

                {pendingDetail?.Permissions.UserCanApproveTask && (
                  <Button
                    type="primary"
                    onClick={handleOnApprove}
                    disabled={finishingTask || savingTask}
                    size="large"
                    style={{
                      fontFamily: "Gotham-Bold",
                    }}
                  >
                    {Aprove}
                  </Button>
                )}
              </div>
            </TaskActions>
          </TaskDataContainer>
        </Container>
      ) : (
        <Loading
          progressBar={!!nextTask}
          progress={progress}
          progressLabels={progressLabels}
        />
      )}
    </MultiModal>
  );
};

export default DetailPending;
