import React, { useState, useEffect } from "react";
import { Modal } from "@material-ui/core";
import { Row } from "antd";
import _ from "lodash";
import { useFetch, useMutation } from "../../../../hooks";
import { Button } from "../../../../GeestUI";
import { FieldsProps, GetProcessFieldsResponse } from "./Fields.d";
import {
  StyledPaper,
  FormRow,
  ModalTitle,
  SecondaryModalContainer,
  SaveButtonContainer,
} from "./Styles";
import { FieldList } from "../../../Pendings/DetailPending/MicroComponents";
import { BiSave } from "react-icons/bi";
import {
  FormField,
  VarDBRowField,
} from "../../../Pendings/DetailPending/DetailPending.d";
import { CloseIcon } from "../../../Pendings/DetailPending/styles";
import {
  SelectFileFieldModal,
  FileType,
  onPasteImage,
  FileField,
} from "../../../../components/DynamicInput/Components/helpers/FileHelpers";

const Languages = {
  ENG: {
    Title: "Form fields",
    SaveLabel: "Save",
  },
  ESP: {
    Title: "Campos de formulario",
    SaveLabel: "Guardar",
  },
};

const { Title, SaveLabel } = Languages["ESP"];

const Fields: React.FC<FieldsProps> = ({
  IdProcessExecution,
  onDetails = false,
  canEdit = false,
  isFileOpenDetails,
  setIsFileOpenDetails,
  openUnsaved,
  onSuccess,
  onCancel,
}) => {
  const [editableFields, setEditableFields] = useState<FormField[]>([]);
  const [auxFields, setAuxFields] = useState<FormField[]>([]);
  const [isFileOpen, setIsFileOpen] = useState<boolean>(false);
  const [copiedFile, setCopiedFile] = useState<FileType | null>(null);
  const [fileFields, setFileFields] = useState<FileField[]>([]);

  const { data, loading } = useFetch<GetProcessFieldsResponse>({
    func: "Ver2-Reports-gpf",
    args: {
      IdProcessExecution,
    },
    onSuccess: ({ Fields }) => {
      setEditableFields(Fields);
      const fileFields = Fields.filter(
        (field) => field.DataType === "file"
      ).map((field) => ({
        Id: field.IdField,
        Label: field.Label,
      }));
      let fileCells: FileField[] = [];
      Fields.filter((field) => field.DataType === "varDBRow").forEach(
        (field) => {
          const fileFields = (field as unknown as VarDBRowField).Cells.filter(
            (field) => field.DataType === "file"
          ).map((cell) => ({
            Id: cell.IdField,
            Label: `${field.Label} > ${cell.Label}`,
          }));
          fileCells = [...fileCells, ...fileFields];
        }
      );
      setFileFields([...fileFields, ...fileCells]);
      setAuxFields(_.cloneDeep(Fields));
    },
  });

  const [saveProcessFields, saving] = useMutation<[]>({
    func: "Ver2-Reports-spf",
    onSuccess: () => onSuccess(),
  });

  const handleOnUpdateField = (
    updatedField: FormField,
    index?: number
  ): void => {
    let fieldIndex = index;
    let newEditableFields = editableFields;
    if (fieldIndex === undefined) {
      fieldIndex = _.findIndex(editableFields, {
        IdField: updatedField.IdField,
      });
    }
    if (fieldIndex >= 0) {
      newEditableFields[fieldIndex] = updatedField;
      setEditableFields([...newEditableFields]);
    }
  };

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = () => {
    let updatedFields = _.cloneDeep(editableFields);
    updatedFields.map((field) => {
      if (field.DataType === "varDBRow") {
        if (
          typeof (field as unknown as VarDBRowField).IdRowSelected !== "number"
        ) {
          (field as unknown as VarDBRowField).IdRowSelected = 0;
          return field;
        }
      }
      return field;
    });

    setAuxFields(updatedFields);
    saveProcessFields({
      args: {
        IdProcessExecution,
        Fields: updatedFields,
      },
    });
  };

  const handleOnClose = () => {
    if (onDetails) {
      if (!isFileOpenDetails) {
        const hasUnsavedChanges = !_(editableFields)
          .differenceWith(auxFields, _.isEqual)
          .isEmpty();
        if (hasUnsavedChanges) {
          openUnsaved();
          return;
        }
        onCancel();
      }
      return;
    }
    if (!isFileOpen) {
      const hasUnsavedChanges = !_(editableFields)
        .differenceWith(auxFields, _.isEqual)
        .isEmpty();
      if (hasUnsavedChanges) {
        openUnsaved();
        return;
      }
      onCancel();
    }
  };

  const onPasteImageToField = (IdField: number, file: FileType) => {
    const value = file;
    const index = editableFields.findIndex(
      (field) => field.IdField === IdField
    );
    if (index >= 0) {
      const oldValue = (editableFields[index] as FormField).Value as any[];
      let EditableFieldsAux = [...editableFields];
      EditableFieldsAux[index].Value = oldValue
        ? [...oldValue, value]
        : [value];
      setEditableFields(EditableFieldsAux);
    } else {
      const index = editableFields.findIndex(
        (field) =>
          !!(field as unknown as VarDBRowField).Cells?.find(
            (cell) => cell.IdField === IdField
          )
      );
      if (index >= 0) {
        const vardbRowInput = editableFields[index];
        const cells = (vardbRowInput as unknown as VarDBRowField).Cells.map(
          (cell) => {
            if (cell.IdField === IdField) {
              return {
                ...cell,
                Value: cell.Value ? [...(cell.Value as any[]), value] : [value],
              };
            }
            return cell;
          }
        );
        let EditableFieldsAux = [...editableFields];
        (EditableFieldsAux[index] as unknown as VarDBRowField).Cells = cells;
        setEditableFields(EditableFieldsAux);
      }
    }
  };

  useEffect(() => {
    if (fileFields.length > 0) {
      const onKeyDown = async (e: KeyboardEvent) => {
        if (e.key.toLowerCase() === "v" && e.ctrlKey) {
          const value = await onPasteImage(data?.FilesPath || "");
          if (value) {
            if (fileFields.length > 1) {
              setCopiedFile(value);
            } else {
              onPasteImageToField(fileFields[0].Id, value);
            }
          }
        }
      };
      window.addEventListener("keydown", onKeyDown);

      return () => window.removeEventListener("keydown", onKeyDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileFields, editableFields]);

  if (onDetails) {
    return (
      <SecondaryModalContainer>
        <ModalTitle>
          <p>{Title}</p>
          <CloseIcon
            onClick={() => handleOnClose()}
            size={20}
            color="#48505e"
          />
        </ModalTitle>
        <FormRow>
          <FieldList
            fields={editableFields}
            filesPath={data?.FilesPath}
            editable={canEdit}
            onUpdateField={handleOnUpdateField}
            UserSelectDataOrigin={data?.UserSelectDataOrigin || []}
            VarDBDataOrigin={data?.VarDBDataOrigin || []}
            isFileOpen={isFileOpenDetails}
            setIsFileOpen={setIsFileOpenDetails}
            IdProcessExecution={IdProcessExecution || undefined}
          />
        </FormRow>
        {canEdit && (
          <SaveButtonContainer>
            <Button
              type="primary"
              disabled={loading || saving}
              onClick={onSubmit}
              Icon={BiSave}
            >
              {SaveLabel}
            </Button>
          </SaveButtonContainer>
        )}
      </SecondaryModalContainer>
    );
  }

  return (
    <Modal open onClose={handleOnClose} style={{ opacity: 1, zIndex: 2 }}>
      <Row
        justify="center"
        align="middle"
        onClick={handleOnClose}
        style={{ width: "100%", height: "100%" }}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          {!!copiedFile && (
            <SelectFileFieldModal
              file={copiedFile}
              onChangeFileField={onPasteImageToField}
              fileFields={fileFields}
              closeModal={() => setCopiedFile(null)}
            />
          )}
          <ModalTitle>
            <p>{Title}</p>
            <CloseIcon
              onClick={() => handleOnClose()}
              size={20}
              color="#48505e"
            />
          </ModalTitle>
          <FormRow>
            <FieldList
              fields={editableFields}
              filesPath={data?.FilesPath}
              editable={canEdit}
              onUpdateField={handleOnUpdateField}
              UserSelectDataOrigin={data?.UserSelectDataOrigin || []}
              VarDBDataOrigin={data?.VarDBDataOrigin || []}
              isFileOpen={isFileOpen}
              setIsFileOpen={setIsFileOpen}
              IdProcessExecution={IdProcessExecution || undefined}
            />
          </FormRow>
          {canEdit && (
            <SaveButtonContainer>
              <Button
                type="primary"
                disabled={loading || saving}
                onClick={onSubmit}
                Icon={BiSave}
              >
                {SaveLabel}
              </Button>
            </SaveButtonContainer>
          )}
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default Fields;
