import React, { useState } from "react";
import { ViewContainer } from "./styles";
import SignGenerator from "../../../components/SignGenerator";
import ReactSignatureCanvas from "react-signature-canvas";
import { Button } from "../../../GeestUI";

interface SignGeneratorViewProps {}

const SignGeneratorView: React.FC<SignGeneratorViewProps> = () => {
  const [openSignatureModal, setOpenSignatureModal] = useState<boolean>(false);
  const [signatureData, setSignatureData] = useState<string>("");

  const handleOnSave = async (
    canvasRef: React.RefObject<ReactSignatureCanvas>
  ) => {
    const svgData = canvasRef.current?.toDataURL("image/svg+xml");
    setSignatureData(svgData ?? "");
    setOpenSignatureModal(false);
  };

  const handleOnClear = (canvasRef: React.RefObject<ReactSignatureCanvas>) => {
    canvasRef.current?.clear();
  };

  const handleOnCancel = () => {
    setOpenSignatureModal(false);
  };

  return (
    <ViewContainer>
      {openSignatureModal && (
        <SignGenerator
          signatureData={signatureData}
          fieldName="field name"
          handleOnSave={handleOnSave}
          handleOnClear={handleOnClear}
          handleOnCancel={handleOnCancel}
          disabled={false}
        />
      )}
      <Button
        type="secondary"
        size="large"
        onClick={() => setOpenSignatureModal(true)}
      >
        Firma
      </Button>
    </ViewContainer>
  );
};

export default SignGeneratorView;
