import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ViewTitle from "../../components/ViewTitle/ViewTitle";
import { SesionContext } from "../../config/State";
import { PageView } from "../../analytics";
import StartTemplate from "./StartTemplate";
import { Container, Content } from "./Styles";
import { Languages } from "./Dictionary";
import CreateProcess from "./CreateProcess";
import { useFetch, useMutation } from "../../hooks";
import queryString from "query-string";
import DownloadTemplate from "./DownloadTemplate";
import CreateFolder from "./CreateFolder";
import Header from "./Header";
import CreateBlankProcess from "./CreateProcess/CreateBlankProcess";
import InstallTemplate from "./InstallTemplate";
import { useParams } from "react-router-dom";
import DuplicateModal from "./DuplicateModal";
import TeamListItem from "./TeamListItem";
import EditFolderName from "./EditFolderName/EditFolderName";

const Templates = () => {
  const [Sesion] = useContext(SesionContext);
  const location = useLocation();
  const { enc } = queryString.parse(location.search);
  const [reshareTemplateData, setReshareTemplateData] = useState(null);
  const { IdParentFolder } = useParams();

  const history = useHistory();

  const [IdTeamToCreate, setIdTeamToCreate] = useState(null);
  const [TemplateSelected, setTemplateSelected] = useState(null);
  const [Modal, setModal] = useState(null);
  const [IdTeamSelected, setIdTeamSelected] = useState();
  const [Teams, setTeams] = useState([]);
  const [nestedFolders, setNestedFolders] = useState(
    location.state ? [location.state] : []
  );
  const [selectedFolder, setSelectedFolder] = useState(location.state);
  const [isSharing, setIsSharing] = useState(false);
  const [folderToEdit, setFolderToEdit] = useState(null);

  const { Title } = Languages["ESP"];

  useEffect(() => {
    if (enc) {
      const decoded = atob(enc);
      const val = JSON.parse(decoded);
      setReshareTemplateData(val);
      setIsSharing(true);
      setModal("installTemplate");
    }
  }, [enc]);

  useFetch({
    func: "Ver2-Processes-gupt",
    idUR: Sesion.Id,
    args: { IdParentFolder: IdParentFolder ? IdParentFolder : null },
    onSuccess: (Teams) => {
      setTeams(Teams);
      if (!selectedFolder && IdParentFolder) {
        const selectedTeamIndex = Teams.findIndex(
          ({ TeamFolders }) =>
            !!TeamFolders?.find(
              ({ IdFolder }) => IdFolder === Number(IdParentFolder)
            )
        );
        if (selectedTeamIndex >= 0) {
          const TeamFolders = Teams[selectedTeamIndex].TeamFolders;
          let folder = TeamFolders?.find(
            ({ IdFolder }) => IdFolder === Number(IdParentFolder)
          );
          setSelectedFolder(folder);
          let idParentFolder = folder?.IdParentFolder;
          let nestedFolders = [folder];
          while (idParentFolder) {
            folder = TeamFolders?.find(
              //eslint-disable-next-line
              ({ IdFolder }) => IdFolder === idParentFolder
            );
            idParentFolder = folder?.IdParentFolder;
            if (folder) {
              nestedFolders.push(folder);
            }
          }
          nestedFolders.reverse();
          setNestedFolders(nestedFolders);
        }
      }
    },
  });

  const [getUserProcessTemplates, loading] = useMutation({
    func: "Ver2-Processes-gupt",
    onSuccess: setTeams,
  });

  const reload = () => {
    getUserProcessTemplates({
      args: {
        IdParentFolder: selectedFolder?.IdFolder || IdParentFolder,
      },
      idUR: Sesion.Id,
    });
  };

  const [duplicateProcess] = useMutation({
    func: "Ver2-Processes-cpt",
    onSuccess: (res) => {
      reload();
      closeModal();
    },
  });

  const [moveToFolder] = useMutation({
    func: "Ver2-Processes-mptf",
    onSuccess: reload,
  });

  const [deleteFolder] = useMutation({
    func: "Ver2-Processes-dpf",
    onSuccess: reload,
  });

  const [reorderTemplates] = useMutation({
    func: "Ver2-Processes-ropt",
    onError: reload,
  });

  const closeModal = () => {
    setModal(null);
    setTemplateSelected(null);
    setIsSharing(false);
  };

  const getMaxItems = () => {
    const width = window.innerWidth;
    if (width < 1220) {
      return 2;
    }
    if (width < 1530) {
      return 3;
    }
    return 4;
  };

  const handleOnDuplicate = (Id, IdTeam, vardbsToCopy, varDBsInTemplate) => {
    if (vardbsToCopy.length > 0) {
      duplicateProcess({
        args: {
          IdTeamDestiny: IdTeam,
          IdProcessTemplates: [Id],
          CanReShareTemplate: true,
          IdParentFolder: selectedFolder?.IdFolder,
          VardbToCopy: vardbsToCopy,
        },
      });
      return;
    }

    if (varDBsInTemplate.length > 0) {
      const auxToCopy = varDBsInTemplate.map((vardb) => {
        return {
          ProcessTemplateIdVarDB: vardb.ProcessTemplateIdVarDB,
          TeamDestinyIdVarDB: vardb.ProcessTemplateIdVarDB,
        };
      });

      duplicateProcess({
        args: {
          IdTeamDestiny: IdTeam,
          IdProcessTemplates: [Id],
          CanReShareTemplate: true,
          IdParentFolder: selectedFolder?.IdFolder,
          VardbToCopy: auxToCopy ?? [],
        },
      });
      return;
    }

    duplicateProcess({
      args: {
        IdTeamDestiny: IdTeam,
        IdProcessTemplates: [Id],
        CanReShareTemplate: true,
        IdParentFolder: selectedFolder?.IdFolder,
      },
    });
  };

  const Modals = {
    start: (
      <StartTemplate
        Template={TemplateSelected}
        closeModal={closeModal}
        IdTeam={IdTeamSelected}
      />
    ),
    create: (
      <CreateProcess
        closeModal={closeModal}
        onCreateBlankProcess={() => setModal("createBlank")}
        onInstallTemplate={(Template) => {
          setReshareTemplateData({
            IdProcessTemplate: [Template.IdProcessTemplate],
            CanReShareTemplate: true,
          });
          setModal("installTemplate");
        }}
      />
    ),
    duplicate: (
      <DuplicateModal
        IdProcessTemplate={TemplateSelected?.IdProcessTemplate}
        TemplateName={TemplateSelected?.Name}
        IdTeam={IdTeamSelected}
        open
        onDuplicate={(importedVardb, hasVardbs) => {
          handleOnDuplicate(
            TemplateSelected?.IdProcessTemplate,
            IdTeamSelected,
            importedVardb,
            hasVardbs
          );
        }}
        onCancel={closeModal}
      />
    ),
    installTemplate: (
      <InstallTemplate
        closeModal={closeModal}
        IdTeam={IdTeamToCreate}
        Teams={Teams}
        reshareTemplateData={reshareTemplateData}
        IdParentFolder={selectedFolder?.IdFolder}
        isSharing={isSharing}
        reload={reload}
      />
    ),
    createBlank: (
      <CreateBlankProcess
        idTeam={IdTeamToCreate}
        closeModal={closeModal}
        IdParentFolder={selectedFolder?.IdFolder}
        reload={reload}
      />
    ),
    download: (
      <DownloadTemplate
        teams={Teams}
        reshareTemplateData={reshareTemplateData}
        reload={reload}
        onClose={() => setModal(null)}
      />
    ),
    createFolder: (
      <CreateFolder
        IdTeam={IdTeamToCreate}
        onClose={closeModal}
        reloadTemplates={reload}
        IdParentFolder={selectedFolder?.IdFolder}
      />
    ),
    editFolder: (
      <EditFolderName
        Folder={folderToEdit}
        closeModal={() => {
          closeModal();
          setFolderToEdit(null);
        }}
        reload={reload}
      />
    ),
  };

  useEffect(() => {
    PageView("/templates");
  }, []);

  const handleOnEdit = (Id, IdTeam) =>
    history.push(`/home/configurator/${IdTeam}/${Id}`);

  const onSelectDuplicate = (Template, IdTeam) => {
    setTemplateSelected(Template);
    setModal("duplicate");
    setIdTeamSelected(IdTeam);
  };

  const handleOnStart = (Template, IdTeam) => {
    setTemplateSelected(Template);
    setIdTeamSelected(IdTeam);
    setModal("start");
  };

  const handleOnMoveToFolder = (
    IdTeam,
    IdProcessTemplate,
    IdParentFolder,
    IdFolder
  ) => {
    moveToFolder({
      args: {
        IdTeam,
        IdProcessTemplate,
        IdParentFolder,
        IdFolder,
      },
    });
  };

  const onDragEnd = (from, to, idTeam) => {
    let teamsAux = [...Teams];
    const teamIndex = Teams.findIndex((team) => team.IdTeam === idTeam);
    const team = teamsAux[teamIndex];
    let processAux = [...team.ProcessTemplates];
    processAux.splice(to, 0, processAux.splice(from, 1)[0]);
    teamsAux[teamIndex] = {
      ...team,
      ProcessTemplates: processAux,
    };
    setTeams(teamsAux);
    reorderTemplates({
      args: {
        IdTeam: idTeam,
        IdParentFolder: selectedFolder?.IdFolder,
        ProcessTemplates: processAux,
      },
    });
  };

  const handleSelectFolder = (Folder, TeamName, IdTeam) => {
    if (loading) {
      return;
    }
    const folder = { ...Folder, TeamName, IdTeam };
    let nestedFoldersAux = [...nestedFolders];
    nestedFoldersAux.push(folder);
    setSelectedFolder(folder);
    setNestedFolders(nestedFoldersAux);
    if (IdParentFolder) {
      getUserProcessTemplates({
        args: {
          IdParentFolder: Folder.IdFolder,
        },
        idUR: Sesion.id,
      });
    }
    history.push({
      pathname: `/home/template/folder/${Folder.IdFolder}`,
      state: folder,
    });
  };

  const handleEditFolder = (Folder, IdTeam) => {
    setFolderToEdit({ ...Folder, IdTeam });
    setModal("editFolder");
  };

  const handleDeleteFolder = (IdTeam, IdFolder) => {
    deleteFolder({
      args: {
        IdTeam,
        IdFolder,
      },
    });
  };

  return (
    <Container>
      {Modals[Modal]}
      <ViewTitle ShowTeamIcon>{Title}</ViewTitle>
      {selectedFolder && (
        <Header
          selectedFolder={selectedFolder}
          nestedFolders={nestedFolders}
          getUserProcessTemplates={getUserProcessTemplates}
          setNestedFolders={setNestedFolders}
          setSelectedFolder={setSelectedFolder}
        />
      )}
      <Content>
        {(Teams || []).map((Team, index) => (
          <TeamListItem
            Team={Team}
            index={index}
            selectedFolder={selectedFolder}
            getMaxItems={getMaxItems}
            handleOnStart={handleOnStart}
            handleOnEdit={handleOnEdit}
            handleDeleteFolder={handleDeleteFolder}
            handleOnMoveToFolder={handleOnMoveToFolder}
            handleSelectFolder={handleSelectFolder}
            nestedFolders={nestedFolders}
            onSelectDuplicate={onSelectDuplicate}
            onDragEnd={onDragEnd}
            setIdTeamToCreate={setIdTeamToCreate}
            setModal={setModal}
            key={index}
            handleEditFolder={handleEditFolder}
          />
        ))}
      </Content>
    </Container>
  );
};

export default Templates;
