import styled from "styled-components";

interface SharedProps {
  color?: string;
  mr?: string;
  ml?: string;
  mt?: string;
  mb?: string;
}

const getMargin = (props: SharedProps): string => {
  let margins = "";
  if (props.mt) margins += `margin-top: ${props.mt}};`;
  if (props.mr) margins += `margin-right: ${props.mr}};`;
  if (props.mb) margins += `margin-bottom: ${props.mb}};`;
  if (props.ml) margins += `margin-left: ${props.ml}};`;
  return margins;
};

export const StyledH1 = styled.h1<SharedProps>`
  font-family: "Gotham-Book";
  font-size: 22px;
  color: ${({ color }) => color || "var(--dark-gray)"};
  ${getMargin}
`;

export const StyledH2 = styled.h2<SharedProps>`
  font-family: "Gotham-Book";
  font-size: 18px;
  color: ${({ color }) => color || "var(--dark-gray)"};
  ${getMargin}
`;

export const StyledH3 = styled.h3<SharedProps>`
  font-family: "Gotham-Book";
  font-size: 16px;
  color: ${({ color }) => color || "var(--dark-gray)"};
  ${getMargin}
`;

export const StyledH4 = styled.h4<SharedProps>`
  font-family: "Gotham-Book";
  font-size: 14px;
  color: ${({ color }) => color || "var(--dark-gray)"};
  ${getMargin}
`;

export const StyledP = styled.p<SharedProps>`
  font-family: "Gotham-Book";
  font-size: 14px;
  color: ${({ color }) => color || "var(--dark-gray)"};
  ${getMargin}
`;

export const StyledText = styled.span<SharedProps>`
  font-family: "Gotham-Book";
  font-size: 14px;
  color: ${({ color }) => color || "inherit"};
  ${getMargin}
`;

export const StyledB = styled.b<SharedProps>`
  font-family: "Gotham-Medium";
  color: ${({ color }) => color || "inherit"};
  ${getMargin}
`;

export const StyledLight = styled.span<SharedProps>`
  font-family: "Gotham-Light";
  color: ${({ color }) => color || "inherit"};
  ${getMargin}
`;
