export enum ToolboxActions {
  Edit = "edit",
  Fields = "fields",
  Duplicate = "duplicate",
  Delete = "delete",
}

export interface ToolboxProps {
  nodeId: string;
  onAction: (action: ToolboxActions, nodeId: string) => void;
  onDeleteAttempt: () => void; // Before deletion user needs to confirm
}

export interface Stage {
  Id: number;
  Label: string;
  Color: string;
  IsDefaultStage: boolean;
}

export type GetProcessStages = Stage[];

export interface StageSelectorProps {
  activeStageColor: string;
  activeStageId: number;
  stages: Stage[];
  onChangeStage: (stage: Stage) => void;
}
