import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";

import PaymentForm from "../../../../components/PaymentForm";
import { defaultValues } from "../../../../components/PaymentForm/Form";
import { planTypes, getPlanColors } from "../../helpers";
import Details from "./Details";
import PlansModal from "./PlansModal";
import { Container, Bold, PlanLabel, Content } from "./styles";
import { BillingInformationSchema } from "./schemas";
import { useContext } from "react";
import { LoadingWrapperContext } from "../../LoadingWrapper";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StateMachineContext } from "..";
import ErrorModal from "../Checkout/ErrorModal";
import { useMutation } from "../../../../hooks";
import { MessagesContext } from "../../../../components/AppMessages";

const planModes = {
  monthly: "Monthly",
  annually: "Annually",
};

const Header = ({ plan = planTypes.PRO, extendedDays = 0 }) => {
  const { mainColor } = getPlanColors(plan);
  return (
    <div style={{ marginBottom: "1rem" }}>
      Registra tu tarjeta de crédito y disfruta de{" "}
      <Bold>{extendedDays} días más gratis</Bold> del{" "}
      <PlanLabel planColor={mainColor}>Plan {plan}</PlanLabel>
    </div>
  );
};

const RequestBonusTrial = () => {
  const { IdTeam } = useParams();
  const { setCurrentState } = useContext(StateMachineContext);
  const { HardLoading, setHardLoading } = useContext(LoadingWrapperContext);
  const [Loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const [planSelected, setPlanSelected] = useState({
    plan: {},
    mode: planModes.monthly,
  });
  const [billingInformation, setBillingInformation] = useState(defaultValues);
  const [information, setInformation] = useState({});
  const { Name, TrialBonusDays } = information;

  const { showMessage } = useContext(MessagesContext);

  const [getInformation] = useMutation({
    func: "Ver2-Subscription-RequestBonusTrial-gi",
    onSuccess: (response) => {
      const { BillingInformation, Name, Plans } = response;
      if (!!BillingInformation.Address) {
        setBillingInformation(BillingInformation);
      }
      setPlanSelected({ ...planSelected, plan: Plans[Name] });
      setInformation(response);
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  useEffect(() => {
    setLoading(true);
    getInformation({
      args: {
        IdTeam: IdTeam,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSelectPlan = (plan) => {
    setShowModal(false);
    setPlanSelected({ ...planSelected, plan });
  };

  const handleOnChangePlanMode = (mode) =>
    setPlanSelected({ ...planSelected, mode });

  const [changeSubscriptionState] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: (response) => {
      const { State } = response;
      setCurrentState(State);
    },
  });

  const handleGoBack = () => {
    changeSubscriptionState({
      args: {
        IdTeam: IdTeam,
        NextState: "Normal",
      },
    });
  };

  const parseBillingInformation = (values = defaultValues) => ({
    RFC: values.rfc,
    PostalCode: values.zip,
    SocialReason: values.bussines,
    Address: values.address,
    City: values.city,
    Country: values.country,
    Dsclosure: values.disclosure,
    EnterpriseRegimen: values.regimen,
    NotifyToCsv: values.NotifyToCsv,
  });

  const getCardInfo = async () => {
    const cardElement = elements.getElement(CardElement);

    const response = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    return response;
  };

  const validateForm = async () => {
    setHardLoading(true);
    BillingInformationSchema.validate(billingInformation, {
      abortEarly: false,
    })
      .then(async () => {
        // Validate Stripe
        const { error, paymentMethod } = await getCardInfo();
        if (error) {
          setHardLoading(false);
          return showMessage(error.message, "error");
        } else {
          onSubmit({
            PaymentMethodStripeId: paymentMethod.id,
            PaymentMethodId: "0",
          });
        }
      })
      .catch((errors) => {
        setHardLoading(false);
        errors.inner.map((error) => showMessage(error.message, "error"));
      });
  };

  const [requestCardAndExtendTrial] = useMutation({
    func: "Ver2-Subscription-RequestBonusTrial-rcaet",
    onSuccess: async (response) => {
      const {
        SubscriptionStateChangeTo,
        requires_action,
        Error_CardDeclined,
        Error_PaymentDeclined,
      } = response;
      if (SubscriptionStateChangeTo === "Successful") {
        showMessage(SubscriptionStateChangeTo, "success");
        history.go(0);
      }
      if (Error_CardDeclined || Error_PaymentDeclined) {
        showMessage(Error_CardDeclined || Error_PaymentDeclined, "error");
        setError(true);
      }
      if (requires_action) {
        const { client_secret, PaymentIntentId, PaymentObject } =
          requires_action;
        setHardLoading(true);
        const r = await stripe.confirmCardPayment(client_secret);
        if (r) {
          verifyUser(PaymentIntentId, PaymentObject);
        }
      }
      setHardLoading(false);
    },
    onError: () => setHardLoading(false),
  });

  const onSubmit = (PaymentMethod) => {
    const { plan, mode } = planSelected;
    requestCardAndExtendTrial({
      args: {
        IdTeam,
        PaymentMethod,
        IdPlan: plan.IdPlan,
        BillingInformation: parseBillingInformation(billingInformation),
        BillingMode: mode,
      },
    });
  };

  const [verifyPayment] = useMutation({
    func: "Ver2-Subscription-RequestBonusTrial-vp",
    onSuccess: (response) => {
      const { Success, Error_PaymentDeclined = "" } = response;
      if (Error_PaymentDeclined) {
        setError(true);
      } else {
        showMessage(Success, "success");
        history.go(0);
      }
      setHardLoading(false);
    },
    onError: () => setHardLoading(false),
  });

  const verifyUser = (PaymentIntentId, PaymentObject) => {
    verifyPayment({
      args: {
        IdTeam,
        PaymentIntentId,
        PaymentObject,
      },
    });
  };

  if (Loading) return null;

  return (
    <Container>
      <ErrorModal visible={error} setVisible={setError} />
      <PlansModal
        visible={showModal}
        planSelected={planSelected}
        closeModal={() => setShowModal(false)}
        onSelectPlan={handleOnSelectPlan}
        onChangePlanMode={handleOnChangePlanMode}
        plansData={_.pick(information, [
          "Plans",
          "PlansOrder",
          "PlanLimitations",
        ])}
        planModes={planModes}
      />
      <Header plan={Name} extendedDays={TrialBonusDays} />
      <Content justify="space-between">
        <div style={{ width: "calc(65% - 1rem)" }}>
          <PaymentForm
            values={billingInformation}
            onChange={setBillingInformation}
            onCancel={handleGoBack}
            loading={HardLoading}
          />
        </div>
        <div style={{ width: "calc(35% - 1rem)" }}>
          <Details
            information={information}
            planSelected={planSelected}
            openModal={() => setShowModal(true)}
            onSubmit={validateForm}
            planModes={planModes}
          />
        </div>
      </Content>
    </Container>
  );
};

export default RequestBonusTrial;
