import React from "react";
import { Space } from "antd";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { noop as NOOP } from "lodash";
import { Container, Title, Footer } from "./styles";
import FormComponent, { defaultValues } from "./Form";
import { Button } from "../../GeestUI";

const PaymentForm = ({
  values = defaultValues,
  onChange = NOOP,
  onCancel = NOOP,
  loading = false,
}) => {
  const handleOnChange = (value) => onChange({ ...values, ...value });

  return (
    <Container>
      <Title>Datos</Title>
      <FormComponent values={values} onChange={handleOnChange} />
      <Footer justify="space-between" align="middle">
        <Button type="default" onClick={onCancel} loading={loading}>
          Cancelar
        </Button>
        <Space align="middle" size={[4, 0]}>
          <IoShieldCheckmarkOutline
            color="green"
            size={24}
            style={{ marginTop: "2px" }}
          />
          <span>Pago seguro</span>
        </Space>
      </Footer>
    </Container>
  );
};

export default PaymentForm;
