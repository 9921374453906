import React, { createContext, useContext, useState } from "react";

interface HoverContextType {
  hoveredIndex: number;
  setHoveredIndex: React.Dispatch<React.SetStateAction<number>>;
}

const HoverContext = createContext<HoverContextType | undefined>(undefined);

export const HoverProvider: React.FC = ({ children }) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  return (
    <HoverContext.Provider value={{ hoveredIndex, setHoveredIndex }}>
      {children}
    </HoverContext.Provider>
  );
};

export const useHover = (): HoverContextType => {
  const context = useContext(HoverContext);
  if (!context) {
    throw new Error("useHover must be used within a HoverProvider");
  }
  return context;
};
