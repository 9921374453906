import React, { useRef } from "react";
import { Popover } from "@material-ui/core";
import Responsibles from "../../../SharedComponents/Responsibles";

interface ResponsiblesPopoverProps {
  open: boolean;
  onClose: () => void;
  processId: number | null;
}

const ResponsiblesPopover: React.FC<ResponsiblesPopoverProps> = ({
  children,
  open,
  onClose,
  processId,
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const enoughSpace =
    window.innerHeight -
      (popoverRef?.current?.getBoundingClientRect()?.y || 0) >
    100;

  return (
    <>
      <Popover
        open={open}
        anchorEl={popoverRef.current}
        onClose={onClose}
        PaperProps={{
          style: {
            borderRadius: "6px",
            width: "357px",
            padding: "12px",
            boxShadow: "4px 10px 6px 0px rgba(72, 80, 94, 0.3)",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: enoughSpace ? "top" : "bottom",
          horizontal: "right",
        }}
      >
        <Responsibles onClose={onClose} processId={processId} />
      </Popover>
      <div ref={popoverRef}>{children}</div>
    </>
  );
};

export default ResponsiblesPopover;
