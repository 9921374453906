import React from "react";
import GeneralStep from "./Steps/GeneralStep";
import FiltersStep from "./Steps/FiltersStep";
import { DurationsGraphFormat, GraphType } from "../../../Dashboards.d";

interface Option {
  value: string | number;
  label: string;
}

interface StepContentProps {
  step: number;
  graphName: string;
  setGraphName: (name: string) => void;
  selectedProcess: number | null;
  setSelectedProcess: (idProcessTemplate: number) => void;
  format: DurationsGraphFormat;
  setFormat: (format: DurationsGraphFormat) => void;
  processOptions: Option[];
  selectedTemplate: number;
  selectedDashboard: string | null;
  graphToEdit: GraphType | null;
  canGoNext: boolean;
  goNextStep: () => void;
  onClose: (submit?: boolean) => void;
  setUnsavedChanges: () => void;
}

const StepContent: React.FC<StepContentProps> = ({
  step,
  graphName,
  setGraphName,
  selectedProcess,
  setSelectedProcess,
  format,
  setFormat,
  processOptions,
  selectedTemplate,
  selectedDashboard,
  graphToEdit,
  canGoNext,
  goNextStep,
  onClose,
  setUnsavedChanges,
}) => {
  switch (step) {
    case 0:
      return (
        <GeneralStep
          graphName={graphName}
          setGraphName={setGraphName}
          selectedProcess={selectedProcess}
          setSelectedProcess={setSelectedProcess}
          selectedTemplate={selectedTemplate}
          selectedDashboard={selectedDashboard}
          format={format}
          setFormat={setFormat}
          options={processOptions}
          canGoNext={canGoNext}
          goNextStep={goNextStep}
          setUnsavedChanges={setUnsavedChanges}
        />
      );

    case 1:
      return (
        <FiltersStep
          selectedTemplate={selectedTemplate}
          selectedDashboard={selectedDashboard}
          selectedProcess={selectedProcess}
          graphName={graphName}
          format={format}
          graphToEdit={graphToEdit}
          onClose={onClose}
          setUnsavedChanges={setUnsavedChanges}
        />
      );

    default:
      return <div>error</div>;
  }
};

export default StepContent;
