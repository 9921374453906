import styled from "styled-components";

export const Container = styled.div`
  width: 497px;
  box-shadow: 4px 10px 6px 0px rgba(72, 80, 94, 0.3);
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

export const Title = styled.div`
  width: 100%;
  text-align: center;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  font-size: 16px;
  margin-bottom: 1rem;
`;

export const Description = styled.div`
  height: 40px;
  width: 357px;
  font-size: 14px;
  color: rgba(130, 141, 158, 1);
  line-height: 19.6px;
`;

export const SubTitle = styled.div`
  width: 100%;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  font-size: 14px;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const ScrollOptionsContainer = styled.div`
  flex-direction: row;
  justify-content: center;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 70px;
`;

export const ScrollOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  width: 117px;
  font-size: 14px;
  gap: 10px;

  img {
    cursor: pointer;
  }
`;
