import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Spinner } from "spin.js";

const spinnerOps = {
  lines: 9,
  length: 38,
  width: 19,
  radius: 52,
  scale: 0.5,
  corners: 1,
  speed: 1,
  rotate: 31,
  animation: "spinner-line-fade-quick",
  direction: 1,
  color: "#1fbae0",
  fadeColor: "#1886a1",
  shadow: "0 0 1px transparent",
  zIndex: 2000000000,
};

const SpinnerIcon = new Spinner(spinnerOps).spin().el;

const LoadingAnimation = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: #ffffffa4;
  display: flex;
  z-index: 1;
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
`;

export const LoadingWrapperContext = React.createContext(null);

const LoadingWrapper = ({ children }) => {
  const [HardLoading, setHardLoading] = useState(false);
  const SpinnerRef = useRef(null);

  if (SpinnerRef.current) SpinnerRef.current.appendChild(SpinnerIcon);

  return (
    <LoadingWrapperContext.Provider value={{ HardLoading, setHardLoading }}>
      <LoadingAnimation hidden={!HardLoading}>
        <div ref={SpinnerRef} />
      </LoadingAnimation>
      <Content>{children}</Content>
    </LoadingWrapperContext.Provider>
  );
};

export default LoadingWrapper;
