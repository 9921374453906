import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row } from "antd";
import { Container } from "./Style";
//@ts-ignore
import OrgChart from "@dabeng/react-orgchart";
import { useFetch } from "../../../hooks";
import ViewTitle from "../../../components/ViewTitle/ViewTitle";
import Node from "./Node";
import Configurator from "./Configurator";
import "./Node.css";
import { TeamDiagramInfo, OrgChartType } from "./Organization.d";
import { Button } from "../../../GeestUI";
import { ReactComponent as SettingsIcon } from "../../../resources/img/orgChartEdit.svg";

const Languages = {
  ENG: {
    Title: "Organization chart",
    EditLabel: "Edit",
  },
  ESP: {
    Title: "Organigrama",
    EditLabel: "Editar",
  },
};

const OrganizationChart = () => {
  const { Title } = Languages["ESP"];

  const history = useHistory();
  const { idTeam } = useParams<{ idTeam: string }>();

  const [UserCanEdit, setUserCanEdit] = useState<boolean>(false);
  const [Chart, setChart] = useState<OrgChartType>({} as OrgChartType);

  const [Modal, setModal] = useState<string>("");

  const Modals: { [key: string]: React.ReactNode } = {
    Config: (
      <Configurator
        setModal={setModal}
        setChart={setChart}
        setUserCanEdit={setUserCanEdit}
      />
    ),
  };

  useFetch<TeamDiagramInfo>({
    func: "Ver2-Team-gtud",
    args: { IdTeam: idTeam },
    onSuccess: (response) => {
      const { UserCanEditDiagram, OrgChart } = response;
      setUserCanEdit(UserCanEditDiagram);
      setChart(OrgChart);
    },
  });

  return (
    <Container>
      {Modals[Modal]}
      <ViewTitle
        ShowTeamIcon
        ShowBack
        onBack={() => history.push(`/home/teams/${idTeam}`)}
      >
        <Row align="middle" justify="space-between">
          {Title}
          {UserCanEdit && (
            <Button
              style={{ width: "183px" }}
              type="primary"
              SvgIcon={SettingsIcon}
              onClick={() => setModal("Config")}
            >
              Editar
            </Button>
          )}
        </Row>
      </ViewTitle>
      <OrgChart
        zoom
        pan
        collapsible={false}
        datasource={Chart}
        NodeTemplate={Node}
        chartClass="myChart"
        containerClass="myChart"
      />
    </Container>
  );
};

export default OrganizationChart;
