import React from "react";
import { Button } from "../../../../../GeestUI";
import Modal from "../../../../../components/Modal";
import styled from "styled-components";
import { useMutation } from "../../../../../hooks";

export const Description = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 0.25rem;
  color: #828d9e;
  width: 309px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;

interface ConfirmRestartModalProps {
  onSuccess: () => void;
  closeModal: () => void;
  IdProcessExecution: string;
}

const ConfirmRestartModal: React.FC<ConfirmRestartModalProps> = ({
  onSuccess,
  closeModal,
  IdProcessExecution,
}) => {
  const [restartProcess, restartingProcess] = useMutation({
    func: "Ver2-Reports-rp",
    onSuccess: onSuccess,
  });

  const onRestartProcess = () => {
    restartProcess({ args: { IdProcessExecution } });
  };

  return (
    <Modal
      width="439px"
      visible
      onCancel={closeModal}
      title="Reiniciar proceso"
      bodyStyle={{ zIndex: 2 }}
    >
      <Container>
        <Description>
          ¿Estás seguro que deseas reiniciar el proceso?
        </Description>
        <ButtonContainer>
          <Button type="secondary" onClick={closeModal}>
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={onRestartProcess}
            loading={restartingProcess}
          >
            Reiniciar
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default ConfirmRestartModal;
