import React from "react";
import { AtomicBlockUtils, EditorState } from "draft-js";
import image from "../../../Images/image.svg";

interface ImageProps {
  onChange: (newState: EditorState) => void;
  editorState: EditorState;
}

const Image: React.FC<ImageProps> = ({ onChange, editorState }) => {
  const addImage = () => {
    // CREATE <img /> block
    const entityKey = editorState // from STATE
      .getCurrentContent()
      .createEntity("IMAGE", "MUTABLE", {
        src: "https://placekitten.com/200/300",
        height: "300px",
        width: "200px",
      })
      .getLastCreatedEntityKey();

    // NEW EDITOR STATE
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " "
    );

    // SETSTATE
    onChange(newEditorState);
  };

  return (
    <div
      onClick={addImage}
      className="geestEditorCustomWrapper"
      title="add image"
    >
      <div className="geestEditorCustomOption">
        <img src={image} alt="" />
      </div>
    </div>
  );
};

export default Image;
