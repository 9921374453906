import styled from "styled-components";
import { Row } from "antd";

export const UserContainer = styled.div<{
  nameStyle: string;
  nameColor: string;
  nameSize: string;
  emailColor: string;
  emailSize: string;
  $picSize?: string;
}>`
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;

  p.username,
  p.email {
    margin: 0;
    height: auto;
    font-family: Gotham-Book;
    font-size: ${({ nameSize }) => nameSize};

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  p.username {
    color: ${({ nameColor }) => nameColor};
    ${({ nameStyle }) => {
      if (nameStyle === "bold") {
        return `
        font-family: Gotham-Bold;
        `;
      }
    }}
  }

  p.email {
    color: ${({ emailColor }) => emailColor};
    font-size: ${({ emailSize }) => emailSize};
  }
`;

export const MiddleUser = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

interface profileArgs {
  image?: string;
  $picSize?: string;
  $borderColor?: string;
}

export const UserInitials = styled(Row)<profileArgs>`
  display: flex;
  align-items: center;
  min-width: ${({ $picSize = "35px" }) => $picSize};
  min-height: ${({ $picSize = "35px" }) => $picSize};
  width: ${({ $picSize = "35px" }) => $picSize};
  height: ${({ $picSize = "35px" }) => $picSize};
  border-radius: 50%;
  margin-right: 5px;
  background: #014c9b;
  font-size: 9px !important;
  color: white !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${({ $borderColor }) => $borderColor && `border: 1px solid ${$borderColor};`}
`;

export const UserProfilePic = styled(Row)<profileArgs>`
  min-width: ${({ $picSize = "35px" }) => $picSize};
  min-height: ${({ $picSize = "35px" }) => $picSize};
  width: ${({ $picSize = "35px" }) => $picSize};
  height: ${({ $picSize = "35px" }) => $picSize};
  border-radius: 50%;
  background-image: ${({ image }) => `url("${image}")`};
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  font-size: 14px;
  color: #ffffff;

  ${({ $borderColor }) => $borderColor && `border: 1px solid ${$borderColor};`}
`;
