import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import { IoClose } from "react-icons/io5";

interface ModalProps {
  $showExtra?: boolean;
  $minHeight?: string;
  $height?: string;
  $maxHeight?: string;
  $width?: string;
  $maxWidth?: string;
  $secondaryHeight?: string;
  $secondaryWidth?: string;
  $padding?: string;
}

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ModalContent = styled(Paper)<ModalProps>`
  display: flex;
  flex-direction: column;
  width: ${({ $width = "560px" }) => $width};
  max-width: ${({ $maxWidth = "60%" }) => $maxWidth};
  min-height: ${({ $minHeight = "100px" }) => $minHeight};
  height: ${({ $height = "379px" }) => $height};
  max-height: ${({ $maxHeight = "95vh" }) => $maxHeight};
  border-radius: 6px !important;
  padding: ${({ $padding = "0" }) => $padding};
  animation: all 1s ease;
  font-family: Gotham-Book;
`;

export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  p {
    margin: 0;
    font-family: "Gotham-Bold";
    font-size: 16px;
    color: #48505e;
  }
`;

export const CloseIcon = styled(IoClose)`
  color: #525151;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  max-height: 100%;
  margin: 12px;
  overflow: auto;
`;
