import React from "react";
import _ from "lodash";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
  ReferenceLine,
} from "recharts";
import { EmptyData } from "../components";
import { ISalesPerUser } from "../sales.d";
import { moneyFormat } from "../../../../Helpers";

const barColors = ["#9de8ad", "#00a024", "#007d1c", "#52c470"];

const TotalSalesPerUser: React.FC<{
  data?: ISalesPerUser;
  loading: boolean;
}> = ({ data, loading }) => {
  if (loading) return <div>Cargando...</div>;
  if (!data || _.isEmpty(data.GraphData)) return <EmptyData />;

  return (
    <div style={{ width: "100%", paddingRight: "0.5rem", height: "100%" }}>
      <ResponsiveContainer maxHeight={300}>
        <BarChart layout="vertical" data={data.GraphData} barCategoryGap={5}>
          <XAxis
            axisLine={false}
            dataKey="Value"
            type="number"
            tickFormatter={(value) => moneyFormat(value, 0)}
          />
          <YAxis axisLine={false} dataKey="Name" type="category" width={85} />
          <Tooltip
            formatter={(value: number) => [
              moneyFormat(value, 0) as unknown as number,
              "Ventas",
            ]}
          />
          <Bar dataKey="Value" fill={barColors[0]}>
            {data.GraphData.map((_entry, index) => (
              <Cell
                fill={barColors[index % barColors.length]}
                key={`cell-${index}`}
              />
            ))}
          </Bar>
          <ReferenceLine
            x={data.Goal}
            stroke="#0b2f83"
            strokeDasharray="3 3"
            label={data.Goal}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TotalSalesPerUser;
