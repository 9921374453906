import React, { useState, useRef, useEffect } from "react";
import { useFetch, useMutation } from "../../../../../../hooks";
import {
  EditMeasuremensProps,
  StatsOptions,
  ConditionalGroup,
  Stat,
  Condition,
} from "./EditMeasurements.d";
import {
  Title,
  Container,
  Content,
  StyledInput,
  Subtitle,
  SelectTitleContainer,
  ButtonContainer,
  SubtitleWrapper,
} from "./styles";
import { EditIcon } from "../../../../../../components/hoverIcons";
import GeestSelect from "../../../../../../GeestUI/GeestSelect";
import { Button } from "../../../../../../GeestUI";
import ProcessMeasurements from "./ProcessMeasurements";
import { useParams } from "react-router-dom";
import TableIcon from "../table.svg";
import ProcessIcon from "../process.svg";

const EditMeasurements: React.FC<EditMeasuremensProps> = ({
  IdProcessTemplate,
  IdStat = 0,
  TableName,
  setState,
  onAddStat,
  prevEditState,
  defaultMeasurementName,
  setDefaultMeasurementName,
  setFirstTime,
}) => {
  const [measurementName, setMeasurementName] = useState<string>(
    defaultMeasurementName
  );
  const [editingName, setEditingName] = useState<boolean>(false);
  const [type, setType] = useState<string>("Measurement");
  const [selectedTemplateId, setSelectedTemplateId] = useState<
    number | undefined
  >(IdProcessTemplate);
  const [measurementType, setMeasurementType] = useState<string>("Count");
  const [idSumField, setIdSumField] = useState<number>();
  const [conditionalGroups, setConditionalGroups] = useState<
    ConditionalGroup[]
  >([
    {
      Conditional: "And",
      Conditions: [
        {
          Type: "",
          ConditionValue: "",
        },
      ],
    },
  ]);
  const [conditional, setConditional] = useState<string>("And");

  const { ids } = useParams<{ ids: string }>();
  const [idTeam, idDashboard] = ids?.split("-");

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef, editingName]);

  const { data, loading: loadingOptions } = useFetch<StatsOptions>({
    func: "Ver2-Stats-gso",
    args: {
      IdTeam: idTeam,
      IdDashBoard: idDashboard,
      IdProcessTemplate,
    },
  });

  const [getStat, loadingStat] = useMutation<Stat>({
    func: "Ver2-Stats-gs",
    onSuccess: ({
      IdProcessTemplate,
      IdFieldTemplate,
      Measurement,
      Type,
      Conditional,
      ConditionalGroups,
      Title,
    }) => {
      setMeasurementName(Title);
      setSelectedTemplateId(IdProcessTemplate);
      setType(Type);
      setMeasurementType(Measurement);
      setIdSumField(IdFieldTemplate);
      setConditional(Conditional);
      setConditionalGroups(ConditionalGroups);
    },
  });

  useEffect(() => {
    if (IdStat > 0) {
      getStat({
        args: {
          IdStat,
          IdTeam: idTeam,
          IdDashBoard: idDashboard,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IdStat]);

  const [registerStat, submitting] = useMutation<number>({
    func: "Ver2-Stats-rs",
    onSuccess: (idStat) => {
      setState("Wizard");
      onAddStat(idStat);
      setDefaultMeasurementName("");
      setFirstTime(false);
    },
  });

  const onSubmit = () => {
    registerStat({
      args: {
        IdTeam: idTeam,
        IdDashBoard: idDashboard,
        IdProcessTemplate: selectedTemplateId,
        Title: measurementName,
        Type: type,
        Measurement: measurementType,
        IdFieldTemplate: idSumField,
        Conditional: conditional,
        ConditionalGroups: conditionalGroups,
        IdStat,
      },
    });
  };

  const typeOptions = [
    {
      label: "Medición de procesos",
      value: "Measurement",
    },
  ];

  const getContent = () => {
    switch (type) {
      case "Measurement":
        return (
          <ProcessMeasurements
            StatsOptions={data}
            IdProcessTemplate={IdProcessTemplate}
            selectedTemplateId={selectedTemplateId}
            setSelectedTemplateId={setSelectedTemplateId}
            idSumField={idSumField}
            setIdSumField={setIdSumField}
            measurementType={measurementType}
            setMeasurementType={setMeasurementType}
            conditionalGroups={conditionalGroups}
            setConditionalGroups={setConditionalGroups}
            conditional={conditional}
            setConditional={setConditional}
          />
        );
      default:
        return <></>;
    }
  };

  const validateCondition = (conditon: Condition) => {
    const {
      Type,
      IdField,
      IdProcessTemplate,
      IdTaskTemplate,
      IdStage,
      ConditionValue,
      Value,
    } = conditon;
    if (!Type || !ConditionValue) {
      return false;
    }
    switch (Type) {
      case "Process":
        return !!IdProcessTemplate;
      case "Task":
        return !!IdTaskTemplate;
      case "Stage":
        return !!IdStage;
      case "Field":
        return (
          !!IdField &&
          (["Field_Have_Any_Value", "Field_Is_Empty"].includes(
            ConditionValue
          ) ||
            !!Value)
        );
      default:
        return false;
    }
  };

  const shouldEnableSubmit = () => {
    switch (type) {
      case "Measurement":
        return (
          !!selectedTemplateId &&
          !!measurementName &&
          !!measurementType &&
          (measurementType !== "Sum" || !!idSumField) &&
          !!conditional &&
          conditionalGroups.length > 0 &&
          conditionalGroups.filter(({ Conditions }) => Conditions.length > 0)
            .length === conditionalGroups.length &&
          conditionalGroups.filter(
            ({ Conditions }) =>
              Conditions.filter(validateCondition).length === Conditions.length
          ).length === conditionalGroups.length &&
          !!measurementName
        );
      default:
        return false;
    }
  };

  return (
    <Container>
      <Content>
        <SelectTitleContainer>
          {!editingName ? (
            <Title $fontSize="24px">
              {measurementName}
              <EditIcon size={32} filled onClick={() => setEditingName(true)} />
            </Title>
          ) : (
            <StyledInput
              value={measurementName}
              onChange={(e) => setMeasurementName(e.target.value)}
              ref={inputRef}
              onFocus={(e) => e.target.select()}
              onBlur={() => setEditingName(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setEditingName(false);
                }
              }}
            />
          )}
          <SubtitleWrapper>
            <Subtitle>
              <img src={TableIcon} alt="" />
              <p>{TableName}</p>
            </Subtitle>
            <Subtitle>
              <img src={ProcessIcon} alt="" />
              <p>
                {
                  data?.Processes.Values.find(
                    ({ IdProcessTemplate }) =>
                      IdProcessTemplate === selectedTemplateId
                  )?.Label
                }
              </p>
            </Subtitle>
          </SubtitleWrapper>
        </SelectTitleContainer>
        <SelectTitleContainer>
          <Title $fontSize="14pz">Tipo de medición</Title>
          <GeestSelect
            value={type}
            onChange={setType}
            options={typeOptions}
            $width="330px"
            valueNecesary
          />
        </SelectTitleContainer>
        {!loadingOptions && !loadingStat && getContent()}
      </Content>
      <ButtonContainer>
        <Button
          type="primary"
          size="large"
          onClick={onSubmit}
          disabled={!shouldEnableSubmit()}
          loading={submitting}
        >
          {IdStat === 0 ? "Crear medición" : "Guardar"}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default EditMeasurements;
