import React from "react";
import { IconContainer } from "./styles";
import { ReactComponent as ReloadPipelineIconSVG } from "../../resources/img/reloadPipelineIcon.svg";

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  $width?: string;
  $height?: string;
  size?: 20 | 26 | 32;
  filled?: boolean;
  fillColor?: string;
  containerSize?: number;
  customIconSize?: number;
  hoverContainer?: boolean;
}

const ReloadPipelineIcon: React.FC<IconProps> = ({
  $width = "14px",
  $height = "14px",
  size = 20,
  filled,
  fillColor,
  containerSize,
  customIconSize,
  hoverContainer,
  ...props
}) => {
  return (
    <IconContainer
      size={containerSize ? containerSize : size}
      fillColor={fillColor}
      hoverContainerColor={hoverContainer ? "#cce6ff" : ""}
      {...props}
    >
      <ReloadPipelineIconSVG width={$width} height={$height} />
    </IconContainer>
  );
};

export default ReloadPipelineIcon;
