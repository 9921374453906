import styled from "styled-components";

export const ImageConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ConfigRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 34px;
`;

export const ConfigRowName = styled.p`
  margin: 0;
  color: #48505e;
  font-family: Gotham-Bold;
`;

export const ButtonLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 2px;
`;

export const ConfigHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

export const ConfigTab = styled.div<{ current: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 31px;
  max-width: 50%;
  color: white;
  cursor: pointer;
  background-color: ${({ current = false }) =>
    current ? "#014c9b" : "#0067b0"};

  :hover {
    background-color: ${({ current = false }) =>
      current ? "#014c9b" : "rgba(2, 115, 233, 1)"};
  }

  transition: background-color 0.35s ease;
`;

export const UploadedImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const ImageName = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 10px;

  p {
    margin: 0;
    color: #48505e;
    font-size: 14px;
    margin-top: 3px;
  }
`;

export const DeleteIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  background-color: #f2f8fe;

  transition: background-color 0.35s ease;

  :hover {
    background-color: #cce6ff;
  }
`;

export const DimensionsForm = styled.div`
  display: flex;
  gap: 21px;
`;

export const DimensionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  cursor: pointer;

  input[type="radio"] {
    accent-color: #0273e9;
  }
`;

export const DimensionAbstraction = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 96px;
  height: 78px;
  background-color: white;
  border-radius: 6px;
  padding: 17px 38px;

  transition: box-shadow 0.35s ease;
  box-shadow: ${({ selected = false }) =>
    selected ? "0px 4px 4px rgba(72, 80, 94, 0.3)" : "none"};

  img {
    user-select: none;
    width: 18px;
    height: 44px;
  }
  img.width {
    transform: rotate(90deg);
  }
  img.height {
    transform: rotate(0);
  }
  img.both {
    transform: rotate(45deg);
  }
`;

export const DimensionName = styled.p`
  margin: 0;
  color: #48505e;
  font-family: Gotham-Bold;
  text-align: center;
`;
