import React from "react";
import _ from "lodash";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import {
  LinkStyled,
  IconContainer,
  ImgContainer,
  ListContainer,
  OrderedListContainer,
} from "./Styles";

interface CustomLinkProps {
  href?: string;
  size?: string;
  placement?: TooltipPlacement;
  setClickingLink?: (isClicking: boolean) => void;
}

export const CustomLink: React.FC<CustomLinkProps> = ({
  href = "",
  size = "18px",
  placement = "bottom",
  setClickingLink = () => {},
}) => {
  const MAXLENGTH = 10;
  const getUrlShort = (href: string): string =>
    /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/gim.exec(href)![1];

  const getValue = (href: string): string => {
    return _.truncate(getUrlShort(href), { length: MAXLENGTH }).split(".")[0];
  };

  return (
    <LinkStyled
      href={href}
      rel="noreferrer noopener"
      target="_blank"
      onClick={() => setClickingLink(true)}
    >
      {href.includes("@") ? (
        href.split(":")[1]
      ) : href.length > MAXLENGTH ? (
        <Tooltip title={href} placement={placement}>
          <IconContainer size={size}>
            <img
              src={`https://www.google.com/s2/favicons?domain=${href}&sz=128`}
              alt=""
            />
          </IconContainer>
          {getValue(href)}
        </Tooltip>
      ) : (
        <>
          <img
            src={`https://www.google.com/s2/favicons?domain=${getUrlShort(
              href
            )}&sz=128`}
            alt=""
          />
          {href.split(".")[0]}
        </>
      )}
    </LinkStyled>
  );
};

interface CustomImageProps {
  src?: string;
  alt?: string;
}

export const CustomImage: React.FC<CustomImageProps> = ({
  src = "",
  alt = "",
}) => {
  return (
    <ImgContainer>
      <img src={src} alt={alt} />
    </ImgContainer>
  );
};

export const CustomList: React.FC = ({ children }) => {
  return <ListContainer>{children}</ListContainer>;
};

export const CustomOrderedList: React.FC = ({ children }) => {
  return <OrderedListContainer>{children}</OrderedListContainer>;
};
