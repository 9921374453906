import React from "react";
import { FlattenSimpleInterpolation } from "styled-components";
import { IButtonProps, ButtonTypes, ButtonSizes } from "./Button.d";
import {
  DangerStyles,
  PrimaryDangerStyle,
  SecondaryDangerStyles,
  DefaultDangerStyles,
  DefaultStyles,
  PrimaryStyles,
  SecondaryStyles,
  StyledButton,
  IconWrapper,
} from "./styles";
import Loading from "../../resources/img/loading.svg";

const getStyles = (
  type: ButtonTypes,
  danger?: boolean
): FlattenSimpleInterpolation => {
  if (danger) {
    switch (type) {
      case "primary":
        return PrimaryDangerStyle;
      case "secondary":
        return SecondaryDangerStyles;
      case "default":
        return DefaultDangerStyles;
      case "danger":
        return DangerStyles;
    }
  }
  switch (type) {
    case "primary":
      return PrimaryStyles;
    case "secondary":
      return SecondaryStyles;
    case "default":
      return DefaultStyles;
    case "danger":
      return DangerStyles;
  }
};

const getVariant = (type: ButtonTypes): "outlined" | "contained" => {
  switch (type) {
    case "primary":
      return "contained";
    case "secondary":
    case "default":
    case "danger":
      return "outlined";
  }
};

const getIconSize = (size: ButtonSizes) => {
  if (size === "small") {
    return 14;
  }
  if (size === "medium") {
    return 18;
  }
  return 20;
};

const LoadingIcon: React.FC<{ size: ButtonSizes }> = ({ size }) => (
  <img
    src={Loading}
    alt="loading"
    style={{
      width: `${getIconSize(size)}px`,
      height: `${getIconSize(size)}px`,
    }}
  />
);

const Button: React.FC<IButtonProps> = ({
  type = "default",
  loading = false,
  disabled = false,
  htmlType = undefined,
  children,
  danger = false,
  size = "regular",
  Icon,
  InvertIcon = false,
  onClick,
  iconSize,
  SvgIcon,
  borderWidth = "2px",
  containerStyles,
  svgExtraStyles = {},
  ...rest
}) => {
  return (
    <StyledButton
      variant={getVariant(type)}
      $typeStyles={getStyles(type, danger)}
      disableElevation
      disabled={disabled}
      startIcon={loading && <LoadingIcon size={size} />}
      $size={size}
      disableRipple
      onClick={!loading ? onClick : () => {}}
      $iconSize={iconSize ? iconSize : getIconSize(size)}
      $borderWidth={borderWidth}
      {...rest}
      type={htmlType}
    >
      <div
        style={
          !loading
            ? {
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ...containerStyles,
              }
            : {}
        }
      >
        {!!SvgIcon && !loading && (
          <SvgIcon
            style={{ marginRight: !!children ? "5px" : 0, ...svgExtraStyles }}
          />
        )}
        {!!Icon && !loading && InvertIcon && (
          <IconWrapper>
            <Icon
              size={iconSize ? iconSize : getIconSize(size)}
              style={{ marginLeft: !!children ? "5px" : 0 }}
            />
          </IconWrapper>
        )}
        {!!Icon && !loading && !InvertIcon && (
          <Icon
            size={iconSize ? iconSize : getIconSize(size)}
            style={{ marginRight: !!children ? "5px" : 0 }}
          />
        )}
        {(!loading || !!Icon) && children}
      </div>
    </StyledButton>
  );
};

export default Button;
