import React from "react";
import { DataTypes } from "./DynamicSkeletonCell.d";
import SkeletonCell from "./SkeletonCell";

interface SkeletonFromComponentProps {
  dataType: DataTypes | undefined;
}

const SkeletonFromComponent: React.FC<SkeletonFromComponentProps> = ({
  dataType,
}) => {
  if (
    dataType === DataTypes.FILE ||
    dataType === DataTypes.PDF_AUTOGENERATED ||
    dataType === DataTypes.CURRENCY ||
    dataType === DataTypes.PHONE_NUMBER
  ) {
    return <SkeletonCell hasSquare />;
  }

  if (dataType === DataTypes.USERS_SELECT) {
    return <SkeletonCell hasCircle />;
  }

  return <SkeletonCell />;
};

export default SkeletonFromComponent;
