import React from "react";
import { Row, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import _, { noop as NOOP } from "lodash";
import { TeamItem, TeamLogo, UserItem } from "./styles";

const SelectConsultors = ({
  loading = false,
  teams = [],
  selected = { team: null, users: [] },
  setSelected = NOOP,
}) => {
  const selectedTeam = _.find(teams, { Id: selected.team });

  const onTeamSelect = (team) => {
    if (selected.team === team.Id) return;
    setSelected({ team: team.Id, users: [team.ConsultingUsers[0].IdUser] });
  };

  const onUserSelect = (user) => {
    if (selected.users.includes(user.IdUser)) {
      // Remove
      // Skip for removing last user
      if (selected.users.length === 1) return;
      else {
        // Remove user from selected
        setSelected({
          ...selected,
          users: _.filter(selected.users, (Id) => Id !== user.IdUser),
        });
      }
    } else {
      // Add
      setSelected({ ...selected, users: [...selected.users, user.IdUser] });
    }
  };

  if (loading)
    return (
      <Row justify="center">
        <LoadingOutlined
          style={{ fontSize: "30px", color: "#0867ad", margin: "1rem 0" }}
        />
      </Row>
    );
  return (
    <div>
      <Row justify="center" style={{ marginBottom: "0px" }}>
        <Space
          style={{
            overflow: "auto",
            maxWidth: "100%",
            paddingBottom: "10px",
          }}
        >
          {teams.map((team, index) => (
            <TeamItem
              active={team.Id === selected.team}
              onClick={() => onTeamSelect(team)}
              key={index}
            >
              <TeamLogo style={{ backgroundImage: `url(${team.Logo})` }} />
            </TeamItem>
          ))}
        </Space>
      </Row>
      <Row justify="center" style={{ marginBottom: "1rem" }}>
        <Space
          style={{
            overflow: "auto",
            maxWidth: "100%",
            paddingBottom: "0.25rem",
          }}
        >
          {selectedTeam.ConsultingUsers.map((user, index) => (
            <UserItem
              active={selected.users.includes(user.IdUser)}
              onClick={() => onUserSelect(user)}
              key={index}
            >
              {user.Name}
            </UserItem>
          ))}
        </Space>
      </Row>
    </div>
  );
};

export default SelectConsultors;
