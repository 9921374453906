import styled from "styled-components";
import Paper from "@material-ui/core/Paper";

interface ModalProps {
  $showExtra?: boolean;
  $minHeight?: string;
  $height?: string;
  $maxHeight?: string;
  $width?: string;
  $maxWidth?: string;
  $secondaryHeight?: string;
  $secondaryWidth?: string;
  $padding?: string;
}

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ModalContent = styled(Paper)<ModalProps>`
  display: flex;
  flex-direction: column;
  width: ${({ $width = "560px" }) => $width};
  max-width: ${({ $maxWidth = "60%" }) => $maxWidth};
  min-height: ${({ $minHeight = "100px" }) => $minHeight};
  height: ${({ $height = "379px" }) => $height};
  max-height: ${({ $maxHeight = "95vh" }) => $maxHeight};
  border-radius: 6px !important;
  padding: ${({ $padding = "0" }) => $padding};
  animation: all 1s ease;
  font-family: Gotham-Book;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  flex: 1;
  overflow: hidden;
  font-size: 16px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1;
  padding-block: 12px;

  p {
    width: 306px;
    margin: 0;
    color: #828d9e;
    text-align: center;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
