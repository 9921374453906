import React, { useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { AiOutlineCopy } from "react-icons/ai";
import GeestTextEditor from "../../../../../components/GeestTextEditor";
import {
  ArticleWrapper,
  EditorWrapper,
  IconWrapper,
  SectionWrapper,
  SnippetWrapper,
  StyledLink,
} from "../Styles";
import { BasicUsageExample, PrevStateExample } from "./Snippets/BasicSnippets";

const SectionName = "Uso básico";

interface BasicLinkProps {
  isActive: () => boolean;
  setSection: () => void;
}

export const BasicLink: React.FC<BasicLinkProps> = ({
  isActive,
  setSection,
}) => {
  return (
    <StyledLink onClick={setSection} $isActive={isActive()}>
      {SectionName}
    </StyledLink>
  );
};

const prevState = {
  blocks: [
    {
      key: "9moni",
      text: "Hola!",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
};

interface BasicSectionProps {
  handleCopy: (element: string) => void;
}

export const BasicSection: React.FC<BasicSectionProps> = ({ handleCopy }) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [secondEditor, setSecondEditor] = useState<EditorState>(() => {
    const convertedState = convertFromRaw(
      JSON.parse(JSON.stringify(prevState))
    );
    return EditorState.createWithContent(convertedState);
  });

  return (
    <SectionWrapper>
      <h2>{SectionName}</h2>
      <ArticleWrapper>
        <p>Este es el ejemplo más básico de implementación del editor.</p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(BasicUsageExample)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={BasicUsageExample}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <p>
          Este ejemplo básico da como resultado un editor de texto como este:
        </p>
        <EditorWrapper>
          <GeestTextEditor
            editorState={editorState}
            onEditorStateChange={setEditorState}
          />
        </EditorWrapper>
        <p className="note">
          Por defecto, el editor de texto no tiene un borde asignado, así que
          por fines ilustrativos, he puesto un fondo gris detrás del mismo para
          que pueda aprecisarse mejor. En el resto de secciones se utilizará la
          propiedad para activar el borde pero tal no debe entenderse como
          obligatoria.
        </p>

        <hr />

        <p>
          Si el estado debe ser persistente, puedes inicializar el estado de
          esta manera:
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(PrevStateExample)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={PrevStateExample}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <p>Aquí hay un ejemplo de un editor con un estado previo precargado:</p>
        <EditorWrapper>
          <GeestTextEditor
            editorState={secondEditor}
            onEditorStateChange={setSecondEditor}
          />
        </EditorWrapper>
        <p className="note">
          La función <code className="code">ConvertFromRaw</code> es parte de la
          utilería del componente. Puedes ver más al respecto en la sección
          Utils.
        </p>
      </ArticleWrapper>
    </SectionWrapper>
  );
};
