import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import _, { noop as NOOP } from "lodash";
import {
  Container,
  DeleteTagButton,
  Divider,
  DropDownIcon,
  EmptyList,
  FilterContainer,
  FilterInput,
  ItemsContainer,
  ListContainer,
  OptionItem,
  SelectInputBox,
  Tag,
  TagLabel,
} from "./style";
import { GoTriangleDown } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { Popover } from "@material-ui/core";
import { BiSad } from "react-icons/bi";
import { sanitizeSearchValues } from "../../Helpers";

const Languages = {
  ENG: {
    PlaceholderSelect: "Select an option...",
    PlaceholderFilter: "Search...",
    Empty: "No elements",
  },
  ESP: {
    PlaceholderSelect: "Selecciona una opción...",
    PlaceholderFilter: "Buscar...",
    Empty: "Sin elementos",
  },
};

const { PlaceholderSelect, PlaceholderFilter, Empty } = Languages["ESP"];

type OptionValue = string | number | undefined | null;
type OptionLabel = React.ReactNode | string | number | undefined | null;

interface Option {
  value: OptionValue;
  label: OptionLabel;
  asSelected?: OptionLabel;
  searchableValues?: string;
  separator?: boolean;
  type?: string;
}

interface GeestSelectProps {
  value: OptionValue;
  multiselect?: boolean;
  showTags?: boolean;
  required?: boolean;
  disabled?: boolean;
  options: Option[];
  onChange: (newValue: any) => void;
  onBlur?: () => void;
  onCloseList?: () => void;
  onPressEnter?: () => void;
  placeholderSelect?: OptionLabel;
  placeholderFilter?: string;
  optionPadding?: string;
  canHoverOption?: boolean;
  $width?: string;
  $listWidth?: string;
  $listMaxHeight?: string;
  $height?: string;
  $maxHeight?: string;
  arrowSize?: number;
  valueNecesary?: boolean;
  disableSearch?: boolean;
  autoOpen?: boolean;
  style?: React.CSSProperties;
  extraValues?: string;
  onRemoveExtraValue?: (val: string) => void;
  popoverStyle?: React.CSSProperties;
  renderPlaceholderIfNoValueInList?: boolean;
  itemsContainerContentWidth?: string;
}

/**
 * option should be an array of { value, label } objects.
 *
 * If your label is a React.ReactNode, you should add searchableValues to the object.
 *
 * its searchableValues should be a csv.
 */
const GeestSelect: React.FC<GeestSelectProps> = ({
  value,
  multiselect = false,
  showTags = false,
  required = false,
  disabled = false,
  options = [],
  onChange = NOOP,
  onBlur = NOOP,
  onCloseList = NOOP,
  onPressEnter = NOOP,
  placeholderSelect = PlaceholderSelect,
  placeholderFilter = PlaceholderFilter,
  optionPadding = "6px 10px",
  canHoverOption = true,
  $width = "100%",
  $listWidth = "",
  $listMaxHeight = "200px",
  $height = "",
  $maxHeight = "",
  arrowSize = 12,
  valueNecesary = false,
  disableSearch = false,
  autoOpen = false,
  style = {},
  extraValues,
  onRemoveExtraValue = NOOP,
  popoverStyle = {},
  renderPlaceholderIfNoValueInList = false,
  itemsContainerContentWidth = "auto",
}) => {
  const [openList, setOpenList] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [inputWidth, setInputWidth] = useState<number>(236);
  const [hasPressedKeys, setHasPressedKeys] = useState<boolean>(false);
  const [focusedIdx, setFocusedIdx] = useState<number | null>(null);
  const popoverRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setOpenList(autoOpen);
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (options.length > 0 && !_.isEqual(filteredOptions, options)) {
      if (filter) {
        setFilteredOptions(getFilteredOptions(filter));
      } else {
        setFilteredOptions(options);
      }
    } else if (options.length === 0) {
      setFilteredOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useLayoutEffect(() => {
    // @ts-ignore
    if (popoverRef.current.offsetWidth) {
      // @ts-ignore
      setInputWidth(popoverRef.current.offsetWidth);
    }
    // @ts-ignore
  }, [popoverRef?.current?.offsetWidth]);

  const getFilteredOptions = (filter: string) => {
    const filtered = _.filter(options, (option) => {
      let properties: string[] = [];
      if (typeof option.label === "string") {
        properties = [sanitizeSearchValues(option.label)];
      }

      let searchBy: string = "";
      if (option.searchableValues) {
        searchBy = sanitizeSearchValues(option.searchableValues);
      }
      properties = [...properties, ...searchBy.split(",")];

      for (const property of properties)
        if (property.includes(sanitizeSearchValues(filter))) return true;
      return false;
    });

    if (filtered.length === 0 && filter === "") {
      return options;
    }

    return filtered;
  };

  const handleFilter = (val: string) => {
    setFocusedIdx(0);
    setHasPressedKeys(false);
    setFilter(val);
    const newOptions = getFilteredOptions(val);
    setFilteredOptions(newOptions);
  };

  const closeList = () => {
    setOpenList(false);
    setFilter("");
    setFilteredOptions(options);
    setFocusedIdx(null);
    setHasPressedKeys(false);
  };

  const handleSelect = (val: OptionValue) => {
    if ((!Boolean(val) && typeof val !== "number") || val === "__@") {
      onChange("");
      closeList();
    } else {
      onChange(val);
      closeList();
    }
  };

  const handleMultiSelect = (val: OptionValue) => {
    if ((!Boolean(val) && typeof val !== "number") || val === "__@") return;
    let currentValues = `${value}`.split(",");
    let newValues = [];

    if (currentValues.includes(`${val}`)) {
      newValues = currentValues.filter((v) => `${v}` !== `${val}`);
    } else {
      newValues = [...currentValues, val];
    }
    newValues = newValues.filter((v) => v !== "");

    if (newValues.length === 0 && valueNecesary) {
      closeList();
      return;
    }

    onChange(newValues.join(","));
    closeList();
    return;
  };

  const handleOnPressEnter = () => {
    if (focusedIdx !== null) {
      if (multiselect) {
        handleMultiSelect(filteredOptions[focusedIdx]?.value);
        return;
      }

      if (filteredOptions[focusedIdx]?.value === value && valueNecesary) {
        closeList();
        return;
      }

      if (filteredOptions[focusedIdx]?.value === value && !valueNecesary) {
        handleSelect("");
        return;
      }

      handleSelect(filteredOptions[focusedIdx]?.value);
    } else if (openList) {
      onPressEnter();
      closeList();
    } else {
      setOpenList(true);
    }
  };

  const isSelected = (val: OptionValue) =>
    `${value}`.split(",").includes(`${val}`);

  const handleOnKeyDown = (e: any) => {
    if (disableSearch || disabled) return;
    let element = null;
    if (e.key === "ArrowDown") {
      // on last element
      if (!openList) {
        setOpenList(true);
        return;
      }
      if (focusedIdx !== null && focusedIdx + 1 >= filteredOptions.length) {
        setHasPressedKeys(true);
        setFocusedIdx(0);
        element = document.getElementById(`#${filteredOptions[0]?.value}-0`);
        element?.scrollIntoView();
        return;
      }
      // on first element and first time
      if (!hasPressedKeys) {
        setHasPressedKeys(true);
        setFocusedIdx(0);
        element = document.getElementById(`#${filteredOptions[0]?.value}-0`);
        element?.scrollIntoView();
        return;
      }
      // ny other time
      setHasPressedKeys(true);
      if (focusedIdx !== null) setFocusedIdx(focusedIdx + 1);
      element = document.getElementById(
        `#${filteredOptions[(focusedIdx || -1) + 1]?.value}-${
          (focusedIdx || -1) + 1
        }`
      );
      element?.scrollIntoView();
    }

    if (e.key === "ArrowUp") {
      if (!openList) {
        setOpenList(true);
        return;
      }
      if ((focusedIdx !== null && focusedIdx - 1 < 0) || focusedIdx === null) {
        setHasPressedKeys(true);
        setFocusedIdx(filteredOptions.length - 1);
        element = document.getElementById(
          `#${filteredOptions[filteredOptions.length - 1]?.value}-${
            filteredOptions.length - 1
          }`
        );
        element?.scrollIntoView();
        return;
      }
      setHasPressedKeys(true);
      if (focusedIdx !== null) {
        setFocusedIdx(focusedIdx - 1);
        element = document.getElementById(
          `#${filteredOptions[(focusedIdx || -1) - 1]?.value}-${
            (focusedIdx || -1) - 1
          }`
        );
        element?.scrollIntoView();
      }
    }
    if (e.key === "Enter") {
      handleOnPressEnter();
    }
  };

  const SelectedElements = (() => {
    if (multiselect) {
      const keyValues = `${value}`
        .split(",")
        .map((v) => options.find(({ value }) => String(value) === v))
        .filter((elm) => Boolean(elm?.label));
      const stringValues =
        extraValues
          ?.split(",")
          .map((val) => ({ label: val, value: val, type: "string" } as Option))
          .filter(({ value }) => !!value) || [];
      const elements = [...keyValues, ...stringValues];

      if (renderPlaceholderIfNoValueInList && elements.length === 0) {
        return (
          <ItemsContainer
            itemsContainerContentWidth={itemsContainerContentWidth}
          >
            <div>
              <span style={{ color: "#828d9e", fontFamily: "Gotham-Book" }}>
                {placeholderSelect}
              </span>
            </div>
          </ItemsContainer>
        );
      }

      return (
        <ItemsContainer itemsContainerContentWidth={itemsContainerContentWidth}>
          {elements.length > 0 ? (
            showTags ? (
              elements.map((elm, idx) => (
                <Tag disabled={disabled} key={idx}>
                  <TagLabel>
                    <p>{elm?.asSelected ? elm?.asSelected : elm?.label}</p>
                  </TagLabel>
                  {!disabled && (
                    <DeleteTagButton
                      className="deleteTag"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (elm?.type !== "string") {
                          handleMultiSelect(elm?.value ?? "");
                        } else {
                          onRemoveExtraValue(elm?.label);
                        }
                      }}
                    >
                      <IoClose color="#48505e" />
                    </DeleteTagButton>
                  )}
                </Tag>
              ))
            ) : (
              elements.map((elm, idx) => (
                <div key={idx}>
                  {elm?.asSelected ? elm.asSelected : elm?.label}
                </div>
              ))
            )
          ) : (
            <div>
              <span style={{ color: "#828d9e", fontFamily: "Gotham-Book" }}>
                {placeholderSelect}
              </span>
            </div>
          )}
        </ItemsContainer>
      );
    } else {
      const element = _.find(options, { value: value });

      if (renderPlaceholderIfNoValueInList && !element) {
        return (
          <ItemsContainer
            itemsContainerContentWidth={itemsContainerContentWidth}
          >
            <div>
              <span style={{ color: "#828d9e", fontFamily: "Gotham-Book" }}>
                {placeholderSelect}
              </span>
            </div>
          </ItemsContainer>
        );
      }

      return (
        <ItemsContainer itemsContainerContentWidth={itemsContainerContentWidth}>
          {showTags ? (
            !!value ? (
              <Tag disabled={disabled}>
                <TagLabel>
                  {Boolean(element)
                    ? element?.asSelected
                      ? element?.asSelected
                      : element?.label
                    : value}
                </TagLabel>
                {!disabled && (
                  <DeleteTagButton
                    className="deleteTag"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!valueNecesary) handleSelect("");
                    }}
                  >
                    <IoClose color="#48505e" />
                  </DeleteTagButton>
                )}
              </Tag>
            ) : (
              <div>
                <span style={{ color: "#828d9e", fontFamily: "Gotham-Book" }}>
                  {placeholderSelect}
                </span>
              </div>
            )
          ) : (
            <div>
              {(element?.asSelected
                ? element?.asSelected
                : element?.label
                ? element?.label
                : value) || (
                <span style={{ color: "#828d9e", fontFamily: "Gotham-Book" }}>
                  {placeholderSelect}
                </span>
              )}
            </div>
          )}
        </ItemsContainer>
      );
    }
  })();

  return (
    <Container $width={$width}>
      <SelectInputBox
        tabIndex={0}
        ref={popoverRef}
        $borderColor={required ? (value ? "#edecec" : "#db232c") : "#edecec"}
        $background={disabled ? "#edecec" : "white"}
        $cursor={disabled ? "auto" : "pointer"}
        $height={$height}
        $maxHeight={$maxHeight}
        onClick={() => {
          if (!disabled) {
            setOpenList(true);
          }
        }}
        onKeyDown={handleOnKeyDown}
        style={style}
        $disabled={disabled}
      >
        {SelectedElements || placeholderSelect}
        <DropDownIcon>
          <GoTriangleDown
            size={arrowSize}
            color={disabled ? "#828d9e" : "#48505e"}
            style={{
              transform: openList ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all 0.35s ease",
            }}
          />
        </DropDownIcon>
      </SelectInputBox>
      <Popover
        open={openList}
        anchorEl={popoverRef.current}
        onClose={() => {
          setOpenList(false);
          onCloseList();
        }}
        style={popoverStyle}
        PaperProps={{
          style: {
            borderRadius: "6px",
            width: `${$listWidth ? $listWidth : `${inputWidth}px`}`,
            padding: "5px 0",
            marginTop: "1px",
            boxShadow: "4px 10px 6px rgba(72, 80, 94, 0.3)",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {!disableSearch && (
          <>
            <FilterContainer>
              <FilterInput
                autoFocus
                id="GeestSelectFilterInput"
                placeholder={placeholderFilter}
                value={filter}
                onChange={(e) => handleFilter(e.target.value)}
                onBlur={onBlur}
                onKeyDown={handleOnKeyDown}
                autoComplete="off"
              />
            </FilterContainer>

            <Divider />
          </>
        )}
        <ListContainer $listMaxHeight={$listMaxHeight}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, i) => (
              <OptionItem
                key={`${option.value}-${i}`}
                id={`#${option.value}-${i}`}
                $padding={optionPadding}
                canHoverOption={canHoverOption}
                $selected={isSelected(option.value)}
                $focused={hasPressedKeys && focusedIdx === i && !disableSearch}
                $separator={option.separator}
                onClick={() => {
                  if (multiselect) {
                    handleMultiSelect(option.value);
                    return;
                  }

                  if (option.value === value && valueNecesary) {
                    closeList();
                    return;
                  }

                  if (option.value === value && !valueNecesary) {
                    handleSelect("");
                    return;
                  }

                  handleSelect(option.value);
                }}
              >
                {option.label}
              </OptionItem>
            ))
          ) : (
            <EmptyList>
              <BiSad color="#48505e" size={28} />
              {Empty}
            </EmptyList>
          )}
        </ListContainer>
      </Popover>
    </Container>
  );
};

export default GeestSelect;
