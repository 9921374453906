export const Languages = {
  ENG: {
    SettingsLabel: "Settings",
    ProfileLabel: "Profile",
    NotificationsLabel: "Notifications",
    DevicesLabel: "Devices",
    FieldRequired: "This field is required",
    EmailValidation: "The email format is invalid",
    FirstNameLabel: "First name",
    LastNameLabel: "Last name",
    EmailLabel: "Email",
    SaveLabel: "Save",
    ViewImage: "View image",
    UploadImage: "Upload image",
    DeleteImage: "Delete image",
    ByEmail: "Get email notifications",
    PushNotification: "Get push notifications",
  },
  ESP: {
    SettingsLabel: "Ajustes",
    ProfileLabel: "Perfil",
    NotificationsLabel: "Notificaciones",
    DevicesLabel: "Dispositivos",
    FieldRequired: "Este campo es requerido",
    EmailValidation: "El correo no tiene un formato válido",
    FirstNameLabel: "Nombre",
    LastNameLabel: "Apellido",
    EmailLabel: "Correo electrónico",
    SaveLabel: "Guardar",
    ViewImage: "Visualizar imagen",
    UploadImage: "Subir imagen",
    DeleteImage: "Eliminar imagen",
    ByEmail: "Recibir notificaciones por email",
    PushNotification: "Recibir notificaciones push",
  },
};
