import React from "react";
import { noop as NOOP } from "lodash";
import { Table } from "antd";
import moment from "moment-timezone";
import { dateFormat } from "../../Helpers";

const DynamicTable = ({
  data = [],
  columns = [],
  extraColumns = [],
  bordered = true,
  pagination = false,
  setEditingRow = NOOP,
  UserCanEditVarDB = false,
  varDB = false,
  ...props
}) => {
  const Columns = (() => {
    if (columns) {
      return [...columns, ...extraColumns];
    } else {
      let Columns = [];
      const [DataSource] = data;
      if (!data.length) return [];
      for (let column in DataSource) {
        Columns.push({
          title: column,
          dataIndex: column,
          key: column,
        });
      }
      return [...Columns, ...extraColumns];
    }
  })();

  const formatTz = (date) => {
    const mxOffset = moment(date).tz("America/Mexico_City").utcOffset();
    const tzOffset = moment().tz(moment.tz.guess()).utcOffset();
    const offset = mxOffset - tzOffset;
    return moment(date).add(offset, "minutes").format("DD/MMM/YY HH:mm");
  };

  const Data = (() =>
    data.map((d, key) => {
      let dt = Object.keys(d);
      let dat = {};
      dt.forEach((p) => {
        dat[p] = moment(d[p], "YYYY-MM-DD HH:mm:ss", true).isValid()
          ? varDB
            ? formatTz(d[p])
            : moment(d[p]).format("DD/MMM/YY HH:mm")
          : moment(d[p], "YYYY-MM-DD", true).isValid()
          ? dateFormat(d[p])
          : d[p];
      });
      return { ...dat, key };
    }))();

  return (
    <Table
      dataSource={Data}
      columns={Columns}
      bordered={bordered}
      pagination={pagination}
      onRow={(record) => ({
        onClick: () => {
          if (UserCanEditVarDB) {
            data.forEach((d, index) => {
              if (index === record.key) {
                setEditingRow(d);
              }
            });
          }
        },
      })}
      {...props}
    />
  );
};

export default DynamicTable;
