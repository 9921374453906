import React, { useState, useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ListManager } from "react-beautiful-dnd-grid";
import { Row } from "antd";
import { AlertContext } from "../../components/AppAlert/AppAlertContext";
import { Languages } from "./Dictionary";
import { useFetch, useMutation } from "../../hooks";
import ViewTitle from "../../components/ViewTitle/ViewTitle";
import CreateNewTable from "./Modals/CreateNewTable";
import DatabaseListItem from "./DatabaseListItem";
import NewDBCard from "./DatabaseListItem/NewDBCard";
import CreateFolder from "./Modals/CreateFolder";
import Header from "./Header";
import { TeamNameBox } from "./Styles";
import {
  Vardb,
  UserVarDB,
  DatabasesParams,
  FolderType,
  ModalType,
} from "./DataBases.d";
import ExcelExportAlert from "../../components/ExcelExportAlert";
import ImportDBModal from "./Modals/ImportDBModal";

const Status = {
  ENDED_FREE_CARDLESS_TRIAL: "ended_free_cardless_trial",
  ENDED_BONUS_TRIAL: "ended_bonus_trial",
};

const DataBases: React.FC = () => {
  const {
    AlertData: { AlertInfo },
  } = useContext<any>(AlertContext);
  const AccountFreezed = [
    Status.ENDED_BONUS_TRIAL,
    Status.ENDED_FREE_CARDLESS_TRIAL,
  ].includes(AlertInfo.Value);

  const { DataBasesLabel } = Languages["ESP"];
  const [userVarDBs, setUserVarDBs] = useState<UserVarDB[]>([]);
  const [modal, setModal] = useState<ModalType>("");
  const [idTeam, setIdTeam] = useState<number | null>(null);

  const history = useHistory();
  const location = useLocation();
  const [selectedVarDB, setSelectedVarDB] = useState<string>("");
  const [selectedVarDBName, setSelectedVarDBName] = useState<string>("");
  const { IdParentFolder } = useParams<DatabasesParams>();
  const [selectedFolder, setSelectedFolder] = useState<FolderType>(
    location.state as FolderType
  );
  const [nestedFolders, setNestedFolders] = useState<FolderType[]>(
    location.state ? [location.state as FolderType] : []
  );

  const { reload } = useFetch<UserVarDB[]>({
    func: "Ver2-Vardbs-guv",
    args: { IdParentFolder: IdParentFolder ? IdParentFolder : null },
    onSuccess: (response) => {
      setUserVarDBs(response);

      if (!selectedFolder && IdParentFolder) {
        const selectedTeamIndex = response.findIndex(
          ({ TeamVardbFolders }) =>
            !!TeamVardbFolders?.find(
              ({ IdVardbFolder }) => `${IdVardbFolder}` === `${IdParentFolder}`
            )
        );

        if (selectedTeamIndex >= 0) {
          const TeamFolders = response[selectedTeamIndex].TeamVardbFolders;
          let folder = TeamFolders?.find(
            ({ IdVardbFolder }) => `${IdVardbFolder}` === `${IdParentFolder}`
          );
          setSelectedFolder(folder as FolderType);
          let idParentFolder = folder?.IdParentFolder;
          let nestedFolders = [folder];
          while (idParentFolder) {
            folder = TeamFolders?.find(
              //eslint-disable-next-line
              ({ IdVardbFolder }) => `${IdVardbFolder}` === `${idParentFolder}`
            );
            idParentFolder = folder?.IdParentFolder;
            if (folder) {
              nestedFolders.push(folder);
            }
          }
          nestedFolders.reverse();
          setNestedFolders([...(nestedFolders as unknown as FolderType[])]);
        }
      }
    },
  });

  const [getUserVarDBs, loading] = useMutation<UserVarDB[]>({
    func: "Ver2-Vardbs-guv",
    onSuccess: (response) => {
      setUserVarDBs(response);
    },
  });

  const [moveToFolder] = useMutation({
    func: "Ver2-Vardbs-mvtf",
    onSuccess: reload,
  });

  const [deleteFolder] = useMutation({
    func: "Ver2-Vardbs-dvf",
    onSuccess: reload,
  });

  const [reorderVardbs] = useMutation({
    func: "Ver2-Vardbs-rov",
    onSuccess: reload,
  });

  const [exportVarDBToExcel] = useMutation<string>({
    func: "Ver2-Vardbs-evte",
    onSuccess: (response) => {
      if (response) window.open(response);
      else setModal("ExcelAlert");
    },
  });

  const closeModal = () => {
    setModal("");
    setIdTeam(null);
  };

  const getMaxItems = () => {
    const width = window.innerWidth;
    if (width < 1220) {
      return 2;
    }
    if (width < 1530) {
      return 3;
    }
    return 4;
  };

  const handleCreateTable = (IdTeam: number) => {
    setIdTeam(IdTeam);
    setModal("create");
  };

  const handleCreateFolder = (IdTeam: number) => {
    setIdTeam(IdTeam);
    setModal("createFolder");
  };

  const handleImport = (IdTeam: number, IdVarDB: string, dbName: string) => {
    setIdTeam(IdTeam);
    setSelectedVarDB(IdVarDB);
    setSelectedVarDBName(dbName);
    setModal("ImportDB");
  };

  const exportVarDBs = (IdTeam: number, IdVarDB: string) => {
    exportVarDBToExcel({
      args: {
        IdTeam,
        IdVarDB,
      },
    });
  };

  const handleOnSelectFolder = (
    Folder: FolderType,
    TeamName: string,
    IdTeam: number
  ) => {
    if (loading) return;

    const folder = { ...Folder, TeamName, IdTeam };
    let nestedFoldersAux = [...nestedFolders];
    nestedFoldersAux.push(folder);
    setSelectedFolder(folder);
    setNestedFolders(nestedFoldersAux);

    if (IdParentFolder) {
      getUserVarDBs({
        args: {
          IdParentFolder: Folder.IdVardbFolder,
        },
      });
    }

    history.push({
      pathname: `/home/database/folder/${Folder.IdVardbFolder}`,
      state: folder,
    });
  };

  const handleOnDeleteFolder = (IdTeam: number, IdVardbFolder: string) => {
    deleteFolder({
      args: {
        IdTeam,
        IdVardbFolder,
        IdParentFolder,
      },
    });
  };

  const handleOnMoveToFolder = (
    IdTeam: number,
    IdVarDB: string | null,
    IdParentFolder: string | null,
    IdVardbFolder: string | null
  ) => {
    moveToFolder({
      args: {
        IdTeam,
        IdVarDB,
        IdParentFolder,
        IdVardbFolder,
      },
    });
  };

  const onDragEnd = (
    from: number,
    to: number,
    idTeam: number,
    teamIndex: number
  ) => {
    let userVarDBsAux = [...userVarDBs];
    const teamAux = userVarDBsAux[teamIndex];
    let teamVarDBsAux = [...teamAux.Vardbs];

    teamVarDBsAux.splice(to, 0, teamVarDBsAux.splice(from, 1)[0]);
    userVarDBsAux[teamIndex] = {
      ...teamAux,
      Vardbs: teamVarDBsAux,
    };
    setUserVarDBs(userVarDBsAux);
    reorderVardbs({
      args: {
        IdTeam: idTeam,
        IdParentFolder: selectedFolder?.IdVardbFolder ?? null,
        Vardbs: teamVarDBsAux,
      },
    });
  };

  const canCreateDB = (CanEditDatabases: boolean) => {
    return CanEditDatabases && !AccountFreezed;
  };

  const getDBIndex = (Vardbs: Vardb[], db: Vardb) => {
    return Vardbs.findIndex((current) =>
      current.IdVarDB
        ? current.IdVarDB === db.IdVarDB
        : (current as FolderType).IdVardbFolder ===
          (db as FolderType).IdVardbFolder
    );
  };

  const canRenderRow = (IdTeam: number) => {
    return !selectedFolder || IdTeam === selectedFolder?.IdTeam;
  };

  const Modals: { [key: string]: React.ReactNode } = {
    create: (
      <CreateNewTable
        closeModal={closeModal}
        reload={reload}
        idTeam={idTeam}
        IdParentFolder={IdParentFolder || null}
      />
    ),
    createFolder: (
      <CreateFolder
        IdTeam={idTeam}
        IdParentFolder={IdParentFolder || null}
        closeModal={closeModal}
        reload={reload}
      />
    ),
    ExcelAlert: (
      <ExcelExportAlert
        onCancel={() => setModal("")}
        onAccept={() => history.push(`/home/premium/${idTeam}`)}
        isOwner={true}
      />
    ),
    ImportDB: (
      <ImportDBModal
        IdTeam={idTeam}
        IdVarDB={selectedVarDB}
        DBName={selectedVarDBName}
        onClose={() => setModal("")}
      />
    ),
  };

  return (
    <>
      {Modals[modal]}
      <ViewTitle ShowTeamIcon>{DataBasesLabel}</ViewTitle>
      {selectedFolder && (
        <Header
          selectedFolder={selectedFolder}
          nestedFolders={nestedFolders}
          setSelectedFolder={setSelectedFolder}
          setNestedFolders={setNestedFolders}
          getUserVarDBs={getUserVarDBs}
        />
      )}

      {userVarDBs.map((team, idx) => {
        const { IdTeam, TeamName, Vardbs = [], CanEditDatabases } = team;
        return (
          <React.Fragment key={`${TeamName}-${idx}`}>
            {!selectedFolder && <TeamNameBox>{TeamName}</TeamNameBox>}

            {(Vardbs.length > 0 ||
              (canRenderRow(IdTeam) && CanEditDatabases)) && (
              <Row
                gutter={[16, 16]}
                style={{
                  marginTop: "0.5rem",
                  marginBottom: "1rem",
                  marginLeft: 0,
                  marginRight: 0,
                  maxWidth: 305 * (getMaxItems() - 1) + 282,
                }}
              >
                {CanEditDatabases ? (
                  <ListManager
                    items={Vardbs}
                    direction="horizontal"
                    maxItems={getMaxItems()}
                    render={(VarDB: Vardb) => (
                      <DatabaseListItem
                        team={team}
                        cardKey={`${VarDB.Title}-${VarDB.IdVarDB}`}
                        varDB={VarDB}
                        idx={getDBIndex(Vardbs, VarDB)}
                        maxItems={getMaxItems()}
                        selectedFolder={selectedFolder}
                        nestedFolders={nestedFolders}
                        openDB={() => {
                          history.push(
                            `/home/database/${VarDB.IdVarDB}/${IdTeam}`
                          );
                        }}
                        onImport={handleImport}
                        onExport={exportVarDBs}
                        onDeleteFolder={handleOnDeleteFolder}
                        onSelectFolder={handleOnSelectFolder}
                        onMoveToFolder={handleOnMoveToFolder}
                      />
                    )}
                    onDragEnd={(from, to) => onDragEnd(from, to, IdTeam, idx)}
                  />
                ) : (
                  Vardbs.map((VarDB) => (
                    <DatabaseListItem
                      team={team}
                      cardKey={`${VarDB.Title}-${VarDB.IdVarDB}`}
                      varDB={VarDB}
                      idx={getDBIndex(Vardbs, VarDB)}
                      maxItems={getMaxItems()}
                      selectedFolder={selectedFolder}
                      nestedFolders={nestedFolders}
                      openDB={() => {
                        history.push(
                          `/home/database/${VarDB.IdVarDB}/${IdTeam}`
                        );
                      }}
                      onImport={handleImport}
                      onExport={exportVarDBs}
                      onDeleteFolder={handleOnDeleteFolder}
                      onSelectFolder={handleOnSelectFolder}
                      onMoveToFolder={handleOnMoveToFolder}
                    />
                  ))
                )}

                {canRenderRow(IdTeam) && canCreateDB(CanEditDatabases) && (
                  <NewDBCard
                    onCreateDB={() => handleCreateTable(IdTeam)}
                    onCreateFolder={() => handleCreateFolder(IdTeam)}
                  />
                )}
              </Row>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default DataBases;
