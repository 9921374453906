import styled from "styled-components";

export const NodeCard = styled.div<{ dragging: boolean }>`
  background-color: #ffffff;
  border-radius: 6px;
  padding: 0 2px;
  width: 110px;
  box-shadow: 4px 10px 6px 0px rgba(2, 115, 233, 0.3);
  border: 2px solid ${({ dragging }) => (dragging ? "#0273e9" : "#ffffff")};
  cursor: grab;
  :hover {
    border: 2px solid #0273e9;
  }
  p {
    text-align: center;
    margin-bottom: 15px;
    font-family: "Gotham-bold";
    font-size: 12px;
    line-height: 12px;
  }
`;
