import { Space } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  padding: 2rem;
  color: #535151;
  height: 27rem;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Header = styled.div`
  border-bottom: 1.5px solid #c9c9c9;
  margin-bottom: 1rem;
  text-align: center;
`;

export const Message = styled.div`
  text-align: center;
`;

export const ErrorMessage = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 1.2rem;
  color: #e10600;
`;

export const Content = styled.div`
  flex: 1;
`;

export const Footer = styled(Space)`
  && span {
    font-size: 18px;
  }
`;

export const ButtonBorder = styled.div`
  width: 100%;
  padding: 1px;
  background: ${({ loading }) =>
    loading ? "#0000001e" : "linear-gradient(to right, #84be54, #3f7db8)"};
  border-radius: 11px;
`;
