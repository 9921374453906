import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Spin } from "antd";
import { ErrorContainer, FrameWrapper, ReloadFileButton } from "./Styles";

interface PreviewFrameProps {
  PreviewURL: string;
  handleEsc: () => void;
}

const PreviewFrame: React.FC<PreviewFrameProps> = ({
  PreviewURL,
  handleEsc,
}) => {
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);
  const loadRef = useRef(Loading);
  loadRef.current = Loading;

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      handleEsc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    setTimeout(() => {
      if (!loadRef.current) return;
      setLoading(false);
    }, 15000);
  }, [loadRef, Loading]);

  const onError = () => {
    setError(true);
    setLoading(false);
  };

  const onRefresh = () => {
    setLoading(true);
    setError(false);
  };

  return (
    <FrameWrapper className="swiper-no-swiping">
      {Loading && (
        <Row
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            background: "#ffffff73",
            zIndex: 10,
          }}
          justify="center"
          align="middle"
        >
          <Spin size="large" tip="Cargando" />
        </Row>
      )}
      {Error ? (
        <Row
          style={{
            height: "100%",
            width: "100%",
            background: "#ffffff73",
          }}
          justify="center"
          align="middle"
        >
          <ErrorContainer>
            No se pudo cargar el archivo
            <Row justify="center">
              <ReloadFileButton onClick={onRefresh} />
            </Row>
            Reintentar
          </ErrorContainer>
        </Row>
      ) : Boolean(encodeURIComponent(PreviewURL)) ? (
        <iframe
          title="PreviewFile"
          style={{
            width: "100%",
            height: "100%",
          }}
          src={`https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(
            PreviewURL
          )}&hl=en&embedded=true`}
          onLoad={() => setLoading(false)}
          onError={onError}
          sandbox="allow-scripts allow-same-origin"
        />
      ) : null}
    </FrameWrapper>
  );
};

export default PreviewFrame;
