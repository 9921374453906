import React from "react";
import Modal from "../../../../components/Modal";
import {
  Description,
  Container,
  ButtonContainer,
} from "./ActivateModal/styles";
import { Button } from "../../../../GeestUI";

interface DeactivateModalProps {
  consultorsNames: string[];
  onConfirm: () => void;
  onCancel: () => void;
  loading: boolean;
  visible: boolean;
}

const DeactivateModal: React.FC<DeactivateModalProps> = ({
  consultorsNames,
  onConfirm,
  onCancel,
  loading,
  visible,
}) => {
  return (
    <Modal
      visible={visible}
      title="Desactivar consultoría"
      onCancel={onCancel}
      width="439px"
    >
      <Container>
        <Description>
          ¿Seguro que deseas desactivar la consultoría con:{" "}
          <span style={{ fontFamily: "Gotham-Bold", color: "#48505e" }}>
            {consultorsNames}
          </span>
          ?
        </Description>
        <ButtonContainer>
          <Button type="secondary" loading={loading} onClick={onCancel}>
            Cancelar
          </Button>
          <Button type="primary" loading={loading} onClick={onConfirm}>
            Desactivar
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default DeactivateModal;
