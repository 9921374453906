import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import { useParams } from "react-router";
import _ from "lodash";

import server from "../../../../api/server";
import { SesionContext } from "../../../../config/State";
import { StateMachineContext } from "../.";

import PlanCard from "./LeftCards/PlanCard";
import TrialInfo from "./LeftCards/TrialInfo";

import ActiveTrial from "./RightCards/ActiveTrial";
import PaymentsCard from "./RightCards/PaymentsCard";
import ActiveBonusTrial from "./RightCards/ActiveBonusTrial";
import BonusTrialEnded from "./FullScreenCards/BonusTrialEnded";
import { FrontViewStates } from "./FrontViewStates";
import Button, { ButtonBorder } from "../../../../components/Button";
import useMutation from "../../../../hooks/useMutation";
import CanceledSubScription from "./FullScreenCards/CanceledSubscription";
import { MessagesContext } from "../../../../components/AppMessages";

const Container = styled(Row)`
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
`;

const Normal = () => {
  const { IdTeam } = useParams();
  const [session] = useContext(SesionContext);
  const { setCurrentState } = useContext(StateMachineContext);
  const { showMessage } = useContext(MessagesContext);
  const { Id: IdUR } = session;

  const [PlanStatus, setPlanStatus] = useState(null);
  const [EndTime, setEndTime] = useState("");
  const [PlanName, setPlanName] = useState("");
  const [date, setDate] = useState("");
  const [Amount, setAmount] = useState("");
  const [Limits, setLimits] = useState([]);
  const [PaymentMethods, setPaymentMethods] = useState([]);
  const [PaymentMethod, setPaymentMethod] = useState([]);
  const [UsersManagement, setUsersManagement] = useState({});
  const [UsersMessage, setUsersMessage] = useState({});
  const [nextPlan, setNextPlan] = useState("");

  const [currentPlan, setCurrentPlan] = useState({});

  const [Reload, setReload] = useState(true);
  const fullscreenMode = [
    FrontViewStates.ENDED_BONUS_TRIAL,
    FrontViewStates.CANCELED,
  ].includes(PlanStatus);

  const [getTeamSubscriptionInfo] = useMutation({
    func: "Ver2-Subscription-Normal-gtsi",
    onSuccess: (response) => {
      const { CurrentPlan } = response;
      const {
        FrontView,
        PlanName,
        SubscriptionEnds,
        NextPayment = {},
        Limits,
        UsersManagement = {},
        WaitingPlanName,
      } = CurrentPlan;
      setNextPlan(WaitingPlanName);
      setLimits(Limits);
      setPlanStatus(FrontView);
      setPlanName(PlanName);
      if (!!NextPayment.ApplicationTime) {
        setEndTime(NextPayment.ApplicationTime);
      } else {
        setEndTime(SubscriptionEnds);
      }
      setUsersManagement(UsersManagement);
      setPaymentMethods(NextPayment.PaymentMethods);
      updateNextPayment(NextPayment);
      setCurrentPlan(CurrentPlan);
      setReload(false);
    },
    onError: () => setReload(false),
  });

  useEffect(() => {
    if (!Reload) return;
    getTeamSubscriptionInfo({
      args: {
        IdTeam: IdTeam,
      },
    });
    // eslint-disable-next-line
  }, [IdTeam, IdUR, Reload]);

  const onChangeUsers = (NewPurchasedUsers) => {
    server("Subscription-Normal-upu", IdUR, { IdTeam, NewPurchasedUsers }).then(
      (response) => {
        if (response.Request_Error) {
          showMessage(response.Request_Error, "error");
        } else {
          if (response.SubscriptionStateChangeTo) {
            const { SubscriptionStateChangeTo } = response;
            setCurrentState(SubscriptionStateChangeTo);
          } else {
            const {
              Update: { CurrentPlan },
            } = response;
            const {
              FrontView,
              PlanName,
              SubscriptionEnds,
              NextPayment = {},
              Limits = [],
              UsersManagement = {},
            } = CurrentPlan;
            setPlanStatus(FrontView);
            setPlanName(PlanName);
            setEndTime(SubscriptionEnds);
            setLimits(Limits);
            setUsersManagement(UsersManagement);
            updateNextPayment(NextPayment);
            if (UsersManagement.UsersWereEditedMessage) {
              const { UsersWereEditedMessage } = UsersManagement;
              setUsersMessage(UsersWereEditedMessage);
            }
          }
        }
      }
    );
  };

  const updateNextPayment = ({ ApplicationTime, Amount, PaymentMethods }) => {
    setDate(ApplicationTime);
    setAmount(Amount);
    setPaymentMethod(_.find(PaymentMethods, "IsFavorite"));
  };

  const [onChangeNewPlan, loadNewPlan] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: ({ State }) => setCurrentState(State),
  });

  const LeftCard = () => {
    switch (PlanStatus) {
      case FrontViewStates.CARDLESS_TRIAL:
      case FrontViewStates.ENDED_CARDLESS_TRIAL:
      case FrontViewStates.BONUS_TRIAL:
      case FrontViewStates.CANCEL_BONUS_TRIAL:
        return <TrialInfo currentPlan={currentPlan} />;
      case FrontViewStates.PREMIUM:
      case FrontViewStates.TERMINATING:
      case FrontViewStates.DOWNGRADE:
        return <TrialInfo currentPlan={currentPlan} premium />;
      case FrontViewStates.ENDED_BONUS_TRIAL:
        return FrontViewStates.ENDED_BONUS_TRIAL;
      case null:
      case undefined:
        return null;
      default:
        return (
          <PlanCard
            planName={PlanName}
            limits={Limits}
            usersManagement={UsersManagement}
            usersMessage={UsersMessage}
            date={date}
            planStatus={PlanStatus}
            onChangeUsers={onChangeUsers}
          />
        );
    }
  };

  const RightCard = () => {
    switch (PlanStatus) {
      case FrontViewStates.BU_PREMIUM:
      case FrontViewStates.PREMIUM:
      case FrontViewStates.DOWNGRADE:
        return (
          <PaymentsCard
            setPlanStatus={setPlanStatus}
            setEndTime={setEndTime}
            updateNextPayment={updateNextPayment}
            date={date}
            amount={Amount}
            paymentMethods={PaymentMethods}
            paymentMethod={PaymentMethod}
            setReload={() => setReload(true)}
          />
        );
      case FrontViewStates.BP_CHANGED_PLAN_VIEW:
      case FrontViewStates.BU_CANCELED:
      case FrontViewStates.TERMINATING:
        return (
          <PaymentsCard
            EndTime={EndTime}
            setPlanStatus={setPlanStatus}
            setEndTime={setEndTime}
            updateNextPayment={updateNextPayment}
            date={date}
            amount={Amount}
            paymentMethods={PaymentMethods}
            paymentMethod={PaymentMethod}
            setReload={() => setReload(true)}
            NextPlan={nextPlan}
            canceled
          />
        );
      case FrontViewStates.CARDLESS_TRIAL:
        return <ActiveTrial currentPlan={currentPlan} />;
      case FrontViewStates.ENDED_CARDLESS_TRIAL:
        return <ActiveTrial currentPlan={currentPlan} expired />;
      case FrontViewStates.BONUS_TRIAL:
        return (
          <ActiveBonusTrial
            currentPlan={currentPlan}
            reload={() => setReload(true)}
          />
        );
      case FrontViewStates.CANCEL_BONUS_TRIAL:
        return (
          <ActiveBonusTrial
            currentPlan={currentPlan}
            canceled
            reload={() => setReload(true)}
          />
        );
      case null:
      case undefined:
        return null;
      default:
        return String(PlanStatus);
    }
  };

  const FullScreenCard = () => {
    switch (PlanStatus) {
      case FrontViewStates.ENDED_BONUS_TRIAL:
        return <BonusTrialEnded />;
      case FrontViewStates.CANCELED:
        return <CanceledSubScription />;

      case null:
      case undefined:
        return null;
      default:
        return String(PlanStatus);
    }
  };

  const ChooseOtherPlanButton = () => {
    switch (PlanStatus) {
      case FrontViewStates.BU_PREMIUM:
      case FrontViewStates.PREMIUM:
        return (
          <Row
            style={{
              width: "100%",
              marginBottom: "0.5rem",
            }}
            justify="end"
            align="bottom"
          >
            <ButtonBorder style={{ width: "12rem" }}>
              <Button
                style={{ border: "none", width: "100%" }}
                loading={loadNewPlan}
                onClick={() =>
                  onChangeNewPlan({
                    args: { IdTeam, NextState: "ChangePlan" },
                  })
                }
              >
                Elegir otro plan
              </Button>
            </ButtonBorder>
          </Row>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <ChooseOtherPlanButton />
      {fullscreenMode ? (
        <Col span={24}>
          <FullScreenCard />
        </Col>
      ) : (
        <>
          <Col xs={24} md={13}>
            <LeftCard />
          </Col>
          <Col xs={24} md={10}>
            <RightCard />
          </Col>
        </>
      )}
    </Container>
  );
};

export default Normal;
