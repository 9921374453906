export const Languages = {
  ENG: {
    ModalTitle: "Download template",
    SelectTeamLabel: "Select the team you want to download this template to",
    SelectLabel: "Select a team",
    ConfirmLabel: "Confirm",
  },
  ESP: {
    ModalTitle: "Descargar plantilla",
    SelectTeamLabel:
      "Selecciona la empresa en el que deseas descargar esta plantilla",
    SelectLabel: "Selecciona una empresa",
    ConfirmLabel: "Confirmar",
  },
};
