import React from "react";
import { useField } from "formik";
import _ from "lodash";
import { Container, Input, StyledErrorMessage } from "./styles";
import { PhoneNumberProps } from "./PhoneNumber.d";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import { transformedOptions } from "../../DynamicInput/Components/helpers/PhoneNumberHelpers";

const PhoneNumber: React.FC<PhoneNumberProps> = ({
  inputName = "phoneNumber",
  selectName = "areaCode",
  placeholder,
}) => {
  const [phoneNumberField, phoneNumberMeta] = useField(inputName);
  const [areaCodeField] = useField(selectName);

  const onChangePhoneNumber: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    phoneNumberField.onChange(inputName)(
      _.replace(e.target.value, /[^0-9]/g, "")
    );

  const onChangeAreaCode = (value: string) => {
    if (value) phoneNumberField.onChange(selectName)(value);
  };

  return (
    <div style={{ marginBottom: "8px" }}>
      <Container>
        <GeestSelect
          value={areaCodeField.value}
          options={transformedOptions}
          onChange={onChangeAreaCode}
          required
          $width="100px"
          $listWidth="200px"
          arrowSize={8}
          style={{ padding: "4px 8px" }}
        />
        <Input
          placeholder={placeholder}
          $error={phoneNumberMeta.touched && !!phoneNumberMeta.error}
          {...phoneNumberField}
          onChange={onChangePhoneNumber}
        />
      </Container>
      <StyledErrorMessage name={inputName} component="div" />
    </div>
  );
};

export default PhoneNumber;
