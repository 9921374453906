import React, { useState, useContext } from "react";
import _ from "lodash";
import { Row } from "antd";
import Tooltip from "../../../../../components/Tooltip";
import { AiOutlineInfoCircle, AiOutlinePlus } from "react-icons/ai";
import { useFetch, useMutation } from "../../../../../hooks";
import TriggerInformation from "./TriggerInformation";
import { GetTemplateTriggersResponse, Trigger } from "./TriggersConfig";
import GeestLogo from "../../../../../resources/img/ICONO_.svg";
import GeestCalendar from "../../../../../resources/img/GeestCalendar.svg";
import { Languages } from "./Dictionary";
import {
  StyledPaper,
  LeftColumn,
  RightColumn,
  LeftTitle,
  TriggersContainer,
  TriggerBox,
  TriggerIconContainer,
  TriggerTitle,
  StyledModal,
} from "./Style";
import { Button } from "../../../../../GeestUI";
import manualTriggerIcon from "../../../../../resources/img/manualTriggerIcon.svg";
import formTriggerIcon from "../../../../../resources/img/formTriggerIcon.svg";
import webHookTriggerIcon from "../../../../../resources/img/webHookTriggerIcon.svg";
import { DeleteIcon } from "../../../../../components/hoverIcons";
import ConfirmModal from "./TriggerInformation/views/ConfirmModal";
import { NodeEditorProps } from "../NodeEditor.d";
import { useParams } from "react-router-dom";
import { ConfiguratorContext } from "../../../Configurator";

const { TemplateTriggersTitle, TemplateTriggersInfo } = Languages["ESP"];

const TriggersEditor: React.FC<NodeEditorProps> = ({ onClose }) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const [view, setView] = useState<string>("addTrigger");
  const [selectedTrigger, setSelectedTrigger] = useState<string | null>(null);
  const [triggers, setTriggers] = useState<Trigger[]>([]);
  const [idPrepublishTrigger, setIdPrepublishTrigger] = useState<string | null>(
    null
  );
  const [deletingTrigger, setDeletingTrigger] = useState<string | null>(null);
  const { refreshProcessFlow } = useContext(ConfiguratorContext);

  const { data, reload } = useFetch<GetTemplateTriggersResponse>({
    func: "Ver2-Configurator-gtt",
    args: {
      IdTeam,
      IdProcessTemplate,
    },
    onSuccess: (response) => setTriggers(response.MyTriggers),
  });

  const [deleteTemplateTriggers] = useMutation({
    func: "Ver2-Configurator-dtt",
    onSuccess: (res, { deletingTrigger }) => {
      if (selectedTrigger === deletingTrigger) {
        setView("addTrigger");
      }
      reload();
      refreshProcessFlow();
    },
  });

  const getTriggerIcon = (type: string) => {
    switch (type) {
      case "Manual":
        return (
          <img src={manualTriggerIcon} width="18px" height="18px" alt="" />
        );
      case "Form":
        return <img src={formTriggerIcon} width="18px" height="18px" alt="" />;
      case "Webhook":
        return (
          <img src={webHookTriggerIcon} width="18px" height="18px" alt="" />
        );
      case "Calendar":
        return <img src={GeestCalendar} width="18px" height="18px" alt="" />;
      default:
        return <img src={GeestLogo} width="18px" height="18px" alt="" />;
    }
  };

  const handleSelectTrigger = (id: string, type: string) => {
    setSelectedTrigger(id);
    setIdPrepublishTrigger(id);
    switch (type) {
      case "Manual":
        setView("manualTrigger");
        break;
      case "Form":
        setView("formTrigger");
        break;
      case "Webhook":
        setView("webhookTrigger");
        break;
      case "Default":
        setView("default");
        break;
    }
  };

  const handleDelete = () => {
    deleteTemplateTriggers({
      args: {
        IdPrepublishTrigger: deletingTrigger,
        IdTeam,
      },
      shippedData: { deletingTrigger },
    });
    setDeletingTrigger(null);
  };

  return (
    <StyledModal
      open
      footer={null}
      closable={false}
      centered
      onCancel={onClose}
    >
      <Row
        justify="center"
        align="middle"
        style={{ width: "100%", maxWidth: "1200px", height: "100%" }}
        onClick={onClose}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <ConfirmModal
            open={!!deletingTrigger}
            onClose={() => setDeletingTrigger(null)}
            onConfirm={handleDelete}
          />
          <LeftColumn>
            <Row
              align="middle"
              style={{
                marginLeft: "10px",
                marginBottom: "2.5rem",
                marginTop: "10px",
              }}
            >
              <LeftTitle>{TemplateTriggersTitle}</LeftTitle>
              <Tooltip title={TemplateTriggersInfo} placement="bottom">
                <AiOutlineInfoCircle size={16} color="rgba(72, 80, 94, 1)" />
              </Tooltip>
            </Row>
            <TriggersContainer>
              <Button
                type="primary"
                onClick={() => {
                  setSelectedTrigger(null);
                  setIdPrepublishTrigger(null);
                  setView("addTrigger");
                }}
                size="fullwidth"
                Icon={AiOutlinePlus}
                style={{ marginBottom: "1rem" }}
                disabled={view === "addTrigger"}
              >
                Agregar nuevo disparador
              </Button>
              {data &&
                triggers.map(({ IdPrepublishTrigger, Type, Label }, index) => (
                  <TriggerBox
                    key={IdPrepublishTrigger}
                    $isSelected={_.isEqual(
                      IdPrepublishTrigger,
                      selectedTrigger
                    )}
                    onClick={() =>
                      handleSelectTrigger(IdPrepublishTrigger, Type)
                    }
                  >
                    <Row align="middle">
                      <TriggerIconContainer>
                        {getTriggerIcon(Type)}
                      </TriggerIconContainer>
                      <TriggerTitle>{Label}</TriggerTitle>
                    </Row>
                    <DeleteIcon
                      filled
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeletingTrigger(IdPrepublishTrigger);
                      }}
                    />
                  </TriggerBox>
                ))}
            </TriggersContainer>
          </LeftColumn>
          <RightColumn>
            <TriggerInformation
              IdTeam={IdTeam}
              IdProcessTemplate={IdProcessTemplate}
              IdPrepublishTrigger={idPrepublishTrigger || ""}
              triggerOptions={data?.ProcessTemplateTriggersOptions || []}
              view={view}
              setView={setView}
              setTriggers={setTriggers}
              onClose={onClose}
              handleSelectTrigger={handleSelectTrigger}
              reload={reload}
              Label={
                triggers?.find(
                  ({ IdPrepublishTrigger }) =>
                    IdPrepublishTrigger === idPrepublishTrigger
                )?.Label
              }
            />
          </RightColumn>
        </StyledPaper>
      </Row>
    </StyledModal>
  );
};

export default TriggersEditor;
