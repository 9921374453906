import React, { useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import Modal from "../../../../../components/Modal";
import { useFetch, useMutation } from "../../../../../hooks";
import { useParams } from "react-router-dom";
import GeestSelect from "../../../../../GeestUI/GeestSelect";
import { getDataTypeIcon } from "../../../helpers";
import FieldConfiguration from "./FieldConfiguration";
import {
  FieldNameContainer,
  FieldNameInput,
  ModalContent,
  OptionLabel,
  SubTitle,
} from "./Styles";
import {
  DataOrigins,
  DataType,
  DataTypeValues,
  DefualtFormats,
  EditFieldProps,
  ElementType,
  FieldDataOrigin,
  FieldType,
  GetFieldDataType,
  GetProcessFieldResponse,
  ImportedDataOrigin,
  ValidationsType,
  VarDBRelationship,
} from "./EditField.d";
import { ConfiguratorContext } from "../../../Configurator";
import { FieldDataTypes } from "../../../Configurator.d";

const EditField: React.FC<EditFieldProps> = ({
  IdField,
  onRegisterField,
  onUpdateField,
  onClose,
}) => {
  const validationsByDataType: ValidationsType = {
    file: ["Label"],
    date: ["Label"],
    time: ["Label"],
    checklist: ["Label"],
    varDBRow: ["Label", "IdVarDB"],
    select: ["Label", "DataOrigin", "Configuration"],
    currency: ["Label", "Format"],
    number: ["Label"],
    phone_number: ["Label", "Format"],
    users_select: ["Label"],
    multi_select: ["Label", "DataOrigin", "Configuration"],
    string: ["Label"],
    location: ["Label", "Configuration"],
    pdf_autogenerated: ["Label"],
    signature: ["Label"],
  };

  const { IdTeam, IdProcess } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const { refreshProcessFlow } = useContext(ConfiguratorContext);

  const [dataOrigins, setDataOrigins] = useState<DataOrigins>({
    users_select: [],
  });
  const [defaultFormats, setDefaultFormats] = useState<DefualtFormats>({
    currency: "MXN",
    phone_number: "MX",
  });
  const [dataTypes, setDataTypes] = useState<DataType[]>([]);
  const [filesPath, setFilesPath] = useState<string>("");
  const [prevField, setPrevField] = useState<FieldType>();

  const [currentField, setCurrentField] = useState<FieldType>({
    IdTeam,
    IdProcessTemplate: IdProcess,
    Field: {
      Type: "Manual",
      DataType: "",
      Label: "",
      DataOrigin: [],
      Configuration: "",
      Format: "",
      IdField: null,
      Value: null,
      IdVarDB: null,
      IdVarDBGroup: null,
      Elements: [],
      CanUseExistingRows: false,
      CanAddNewRows: false,
      AutoFill: null,
      FilterDataOrigin: null,
    },
  });

  const [dataOriginCache, setdataOriginCache] = useState<FieldDataOrigin[]>([]);

  const fieldNameInputRef = useRef<HTMLInputElement>(null);

  const editionMode = IdField !== 0;
  const title = editionMode
    ? "Editar campo de formulario"
    : "Agregar nuevo campo de formulario";
  const renderFieldNameInput = currentField.Field.DataType !== "";
  const renderFieldConfig =
    currentField.Field.DataType !== "" && currentField.Field.Type !== "Ghost";

  const configValues = {
    ImportedFromVardbColumn: "ImportedFromVardbColumn",
    CannotAddOptions: "CannotAddOptions",
    CanAddOptions: "CanAddOptions",
    SelectAndCurrent: "SelectAndCurrent",
    OnlyCurrent: "OnlyCurrent",
  };

  const [insertProcessField, insertingProcessField] = useMutation<number>({
    func: "Ver2-Configurator-ipf",
    onSuccess: (fieldId) => {
      onRegisterField(fieldId);
    },
  });

  const [updateProcessField, updatingProcessField] = useMutation({
    func: "Ver2-Configurator-upf",
    onSuccess: (_response, shippedData) => {
      if (shippedData.getProcessField) {
        refreshProcessFlow();
        getProcessField({
          args: {
            IdTeam,
            IdField,
            IdProcessTemplate: IdProcess,
          },
        });
      }
    },
  });

  const [updateProcessVarDBGroup, updatingProcessVarDBGroup] = useMutation({
    func: "Ver2-Configurator-upvg",
  });

  const [getProcessField, gettingProccessField] =
    useMutation<GetProcessFieldResponse>({
      func: "Ver2-Configurator-gpf",
      onSuccess: (field) => {
        let safeValue = field.GlobalFields.Value ?? null;
        if (Array.isArray(safeValue)) {
          safeValue =
            safeValue.filter((val) => JSON.stringify(val) !== "null") ?? [];
        }

        let baseField = {
          IdTeam,
          IdProcessTemplate: IdProcess,
          Field: {
            Type: field.GlobalFields.Type,
            DataType: field.GlobalFields.DataType,
            Label: field.GlobalFields.Label,
            DataOrigin: field.GlobalFields.DataOrigin ?? [],
            Configuration: field.GlobalFields.Configuration ?? "",
            Format: field.GlobalFields.Format ?? "",
            IdField: field.GlobalFields.IdField,
            Value: safeValue ?? null,
            IdVarDB: field.GlobalFields.IdVarDB ?? null,
            Elements: field.GlobalFields.Elements ?? [],
            CanUseExistingRows: field.GlobalFields.CanUseExistingRows ?? false,
            CanAddNewRows: field.GlobalFields.CanAddNewRows ?? false,
            FilterDataOrigin: field.GlobalFields.FilterDataOrigin ?? null,
            AutoFill: field.GlobalFields.AutoFill ?? null,
            PdfInfo: field.GlobalFields.PdfInfo ?? {},
          },
        } as FieldType;

        if (field.GlobalFields.VardbGroupInfo) {
          baseField.Field = {
            ...baseField.Field,
            DataType: "varDBRow",
            IdVarDB: field.GlobalFields.VardbGroupInfo.IdVarDB,
            IdVarDBGroup: field.GlobalFields.VardbGroupInfo.IdVarDBGroup,
            Label: field.GlobalFields.VardbGroupInfo.Label,
            CanUseExistingRows:
              field.GlobalFields.VardbGroupInfo.CanUseExistingRows,
            CanAddNewRows: field.GlobalFields.VardbGroupInfo.CanAddNewRows,
            FilterDataOrigin:
              field.GlobalFields.VardbGroupInfo.FilterDataOrigin,
            AutoFill: field.GlobalFields.VardbGroupInfo.AutoFill,
          };
        }

        setPrevField(baseField);
        setCurrentField(baseField);
        setdataOriginCache(
          Array.isArray(field.GlobalFields.DataOrigin)
            ? field.GlobalFields.DataOrigin
            : []
        );
      },
    });

  const loading =
    insertingProcessField ||
    updatingProcessField ||
    updatingProcessVarDBGroup ||
    gettingProccessField;

  useEffect(() => {
    if (IdField !== 0) {
      getProcessField({
        args: {
          IdTeam,
          IdField,
          IdProcessTemplate: IdProcess,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IdField]);

  useEffect(() => {
    if (fieldNameInputRef.current && IdField === 0)
      fieldNameInputRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderFieldNameInput]);

  useFetch<GetFieldDataType>({
    func: "Ver2-Configurator-gfdt",
    args: { IdTeam, IdProcessTemplate: IdProcess },
    onSuccess: ({ DataOrigins, DataTypes, FilesPath, DefaultFormats }) => {
      setDataOrigins(DataOrigins);
      setFilesPath(FilesPath);
      setDefaultFormats(DefaultFormats);
      let formatedDataTypes = [] as DataType[];
      DataTypes.forEach((dt) => {
        const DataTypeIcon = getDataTypeIcon(dt.Value);
        formatedDataTypes.push({
          value: dt.Value,
          label: (
            <OptionLabel>
              <DataTypeIcon size={20} style={{ marginRight: "8px" }} />
              <span>{dt.Label}</span>
            </OptionLabel>
          ),
          searchableValues: dt.Label,
        });
      });
      setDataTypes(formatedDataTypes);
    },
  });

  const getSignatureDataTypeOption = () => {
    const DataTypeIcon = getDataTypeIcon("signature" as FieldDataTypes);
    return [
      {
        value: "signature",
        label: (
          <OptionLabel>
            <DataTypeIcon size={20} style={{ marginRight: "8px" }} />
            <span>Firma</span>
          </OptionLabel>
        ),
      },
    ];
  };

  const closeModal = () => {
    if (!_.isEqual(currentField, prevField)) {
      onUpdateField();
    }
    onClose();
  };

  const validateAndSubmitField = (
    submitedField: FieldType,
    getProcessField = false
  ) => {
    if (submitedField.Field.DataType === "") return;
    const validations = validationsByDataType[submitedField.Field.DataType];

    let canSubmit = true;

    validations.forEach((validation) => {
      const { Label, IdVarDB, DataType, Configuration, DataOrigin, Format } =
        submitedField.Field;

      if (validation === "Label") {
        if (_.trim(Label) === "") canSubmit = false;
      }

      if (validation === "IdVarDB") {
        if (IdVarDB === null) canSubmit = false;
      }

      if (validation === "Format") {
        if (Format === "") canSubmit = false;
      }

      if (validation === "Configuration") {
        if (Configuration === "") canSubmit = false;
      }

      if (validation === "DataOrigin") {
        if (DataType === "select" || DataType === "multi_select") {
          if (Configuration === configValues.ImportedFromVardbColumn) {
            if (
              (DataOrigin as ImportedDataOrigin).IdVarDB === null ||
              (DataOrigin as ImportedDataOrigin).IdVarDBColumn === null
            ) {
              canSubmit = false;
            }
          } else if ((DataOrigin as FieldDataOrigin[]).length === 0)
            canSubmit = false;
        }
      }
    });

    if (!canSubmit) return;

    submitedField.Field = {
      ...submitedField.Field,
      Label: _.trim(submitedField.Field.Label),
    };

    if (submitedField.Field.Value === null) {
      submitedField.Field = { ...submitedField.Field, Value: "" };
    }
    if (Array.isArray(submitedField.Field.Value)) {
      let safeValue = submitedField.Field.Value.filter(
        (val) => JSON.stringify(val) !== "null"
      );
      submitedField.Field = { ...submitedField.Field, Value: safeValue ?? [] };
    }

    if (IdField === 0) {
      insertProcessField({
        args: { ...submitedField },
      });
      return;
    }

    if (IdField !== 0 || IdField !== null) {
      submitedField.Field = { ...submitedField.Field, IdField };
    }

    if (submitedField.Field.IdVarDB !== null) {
      const submitedVarDBGroup = {
        IdTeam,
        IdProcessTemplate: IdProcess,
        VarDBGroup: {
          IdVarDBGroup: submitedField.Field.IdVarDBGroup,
          Label: submitedField.Field.Label,
          CanAddNewRows: submitedField.Field.CanAddNewRows,
          CanUseExistingRows: submitedField.Field.CanUseExistingRows,
          FilterDataOrigin: submitedField.Field.FilterDataOrigin || "",
          AutoFill: submitedField.Field.AutoFill || "",
        },
      };

      updateProcessVarDBGroup({
        args: { ...submitedVarDBGroup },
      });
    } else {
      updateProcessField({
        args: { ...submitedField },
        shippedData: { getProcessField },
      });
    }
  };

  const handleOnChangeName = (newName: string) => {
    let newField = _.cloneDeep(currentField);
    newField.Field = { ...newField.Field, Label: newName };
    setCurrentField(newField);
  };

  const handleOnChangeValue = (newValue: any) => {
    let newField = _.cloneDeep(currentField);
    if (newValue?.Label !== "") {
      newField.Field = { ...newField.Field, Value: newValue };
    } else {
      newField.Field = { ...newField.Field, Value: null };
    }
    setCurrentField(newField);
    validateAndSubmitField(newField);
  };

  const handleOnChangeFormat = (newFormat: string) => {
    let newField = _.cloneDeep(currentField);
    newField.Field = { ...newField.Field, Format: newFormat };
    setCurrentField(newField);
    validateAndSubmitField(newField);
  };

  const handleOnChangeConfiguration = (newConfig: string) => {
    let newField = _.cloneDeep(currentField);
    newField.Field = { ...newField.Field, Configuration: newConfig };

    if (newConfig === configValues.ImportedFromVardbColumn) {
      newField.Field = {
        ...newField.Field,
        Value: null,
        DataOrigin: { IdVarDB: null, IdVarDBColumn: null },
      };
    }

    if (
      currentField.Field.Configuration ===
        configValues.ImportedFromVardbColumn &&
      newConfig !== configValues.ImportedFromVardbColumn
    ) {
      newField.Field = {
        ...newField.Field,
        Value: null,
        DataOrigin: dataOriginCache,
      };
    }

    setCurrentField(newField);
    validateAndSubmitField(newField);
  };

  const handleOnChangeFieldDataOrigin = (
    newDataOrigin: FieldDataOrigin[] | ImportedDataOrigin | null
  ) => {
    let newField = _.cloneDeep(currentField);
    newField.Field = { ...newField.Field, DataOrigin: newDataOrigin };

    if (
      !(newField.Field.Configuration === configValues.ImportedFromVardbColumn)
    ) {
      if (newField.Field.DataType === "select") {
        let valueExists = false;
        (newDataOrigin as FieldDataOrigin[]).forEach(
          (field: FieldDataOrigin) => {
            if (field.value === newField.Field.Value) {
              valueExists = true;
            }
          }
        );

        if (!valueExists) {
          newField.Field = { ...newField.Field, Value: null };
        }
      }

      if (newField.Field.DataType === "multi_select") {
        const dataOriginIndexed = (newDataOrigin as FieldDataOrigin[]).reduce(
          (acc: any, el: any) => {
            acc[el.value] = el;
            return acc;
          },
          {}
        );

        let valueToRemoveIdx: number | null = null;
        if (Array.isArray(newField.Field.Value)) {
          newField.Field.Value.forEach((val, idx) => {
            if (!(val.Value in dataOriginIndexed)) {
              valueToRemoveIdx = idx;
            }
          });
        }

        if (valueToRemoveIdx !== null) {
          let newValue = _.cloneDeep(newField.Field.Value);
          newValue.splice(valueToRemoveIdx, 1);
          newField.Field = { ...newField.Field, Value: newValue };
        }
      }

      setdataOriginCache(
        Array.isArray(newField.Field.DataOrigin)
          ? newField.Field.DataOrigin
          : []
      );
    }

    setCurrentField(newField);
    const getProcessField = true;
    validateAndSubmitField(newField, getProcessField);
  };

  const handleOnChangeIdVarDB = (newSelectedIdVarDB: number | null) => {
    let newField = _.cloneDeep(currentField);
    newField.Field = { ...newField.Field, IdVarDB: newSelectedIdVarDB };
    setCurrentField(newField);
    validateAndSubmitField(newField);
  };

  const handleOnChangeVarDBRelationship = (newRelation: VarDBRelationship) => {
    let { CanAddNewRows, CanUseExistingRows, AutoFill, FilterDataOrigin } =
      newRelation;

    if (
      currentField.Field.CanAddNewRows &&
      !currentField.Field.CanUseExistingRows &&
      !CanAddNewRows
    ) {
      CanUseExistingRows = true;
    }

    if (
      !currentField.Field.CanAddNewRows &&
      currentField.Field.CanUseExistingRows &&
      !CanUseExistingRows
    ) {
      CanAddNewRows = true;
    }

    if (!CanAddNewRows && !CanUseExistingRows) {
      CanAddNewRows = true;
    }

    if (!CanUseExistingRows) {
      FilterDataOrigin = null;
      AutoFill = null;
    }

    let newField = _.cloneDeep(currentField);
    newField.Field = {
      ...newField.Field,
      CanAddNewRows,
      CanUseExistingRows,
      AutoFill,
      FilterDataOrigin,
    };
    setCurrentField(newField);
    validateAndSubmitField(newField);
  };

  const handleOnChangeChecklistElements = (newElements: ElementType[]) => {
    let newField = _.cloneDeep(currentField);
    newField.Field = { ...newField.Field, Elements: newElements };
    setCurrentField(newField);
    validateAndSubmitField(newField);
  };

  const handleOnChangeDataType = (newDataType: DataTypeValues) => {
    let newField = {
      IdTeam,
      IdProcessTemplate: IdProcess,
      Field: {
        Type: currentField.Field.Type,
        DataType: newDataType,
        Label: currentField.Field.Label,
        DataOrigin: [],
        Configuration: "",
        Format: "",
        IdField: currentField.Field.IdField,
        Value: null,
        IdVarDB: null,
        IdVarDBGroup: null,
        CanUseExistingRows: false,
        CanAddNewRows: false,
        AutoFill: null,
        FilterDataOrigin: null,
      },
    } as FieldType;

    if (newDataType === "currency") {
      newField.Field = { ...newField.Field, Format: defaultFormats.currency };
    }
    if (newDataType === "phone_number") {
      newField.Field = {
        ...newField.Field,
        Format: defaultFormats.phone_number,
      };
    }

    if (newDataType === "select") {
      newField.Field = {
        ...newField.Field,
        Value: "",
        Configuration: configValues.CannotAddOptions,
      };
    }
    if (newDataType === "multi_select") {
      newField.Field = {
        ...newField.Field,
        Value: [],
        Configuration: configValues.CannotAddOptions,
      };
    }

    if (newDataType === "location") {
      newField.Field = {
        ...newField.Field,
        Configuration: configValues.SelectAndCurrent,
      };
    }

    if (newDataType === "varDBRow") {
      newField.Field = {
        ...newField.Field,
        CanUseExistingRows: true,
        CanAddNewRows: true,
      };
    }

    setCurrentField(newField);
    const getProcessField = true;
    validateAndSubmitField(newField, getProcessField);
  };

  return (
    <Modal open title={title} onCancel={closeModal}>
      <ModalContent>
        <GeestSelect
          value={currentField.Field.DataType}
          onChange={handleOnChangeDataType}
          options={
            currentField.Field.DataType !== "signature"
              ? dataTypes
              : getSignatureDataTypeOption()
          }
          valueNecesary
          disabled={IdField !== 0 || loading}
          placeholderSelect="Tipo de dato..."
          autoOpen={IdField === 0}
        />

        {renderFieldNameInput && (
          <FieldNameContainer>
            <SubTitle>Nombre del campo</SubTitle>
            <FieldNameInput
              autoFocus
              ref={fieldNameInputRef}
              value={currentField.Field.Label}
              onChange={({ target: { value } }) => handleOnChangeName(value)}
              onBlur={() => {
                if (currentField.Field.Label !== prevField?.Field.Label) {
                  validateAndSubmitField({ ...currentField });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (currentField.Field.Label !== prevField?.Field.Label) {
                    validateAndSubmitField({ ...currentField });
                  }
                }
              }}
              placeholder="Nombre del campo..."
              disabled={
                loading ||
                currentField.Field.Type === "Ghost" ||
                currentField.Field.DataType === "signature"
              }
            />
          </FieldNameContainer>
        )}

        {renderFieldConfig && (
          <FieldConfiguration
            dataType={currentField.Field.DataType}
            filesPath={filesPath}
            disabled={loading}
            IdField={IdField}
            value={currentField.Field.Value}
            setValue={handleOnChangeValue}
            format={currentField.Field.Format ?? ""}
            setFormat={handleOnChangeFormat}
            configuration={currentField.Field.Configuration}
            setConfiguration={handleOnChangeConfiguration}
            dataOrigins={dataOrigins}
            fieldDataOrigin={currentField.Field.DataOrigin}
            setFieldDataOrigin={handleOnChangeFieldDataOrigin}
            checklistElements={currentField.Field.Elements ?? []}
            setChecklistElements={handleOnChangeChecklistElements}
            idVarDB={currentField.Field.IdVarDB}
            setIdVarDB={handleOnChangeIdVarDB}
            IdFieldParent={currentField.Field.PdfInfo?.IdFieldParent ?? null}
            varDBRelationship={{
              CanAddNewRows: currentField.Field.CanAddNewRows,
              CanUseExistingRows: currentField.Field.CanUseExistingRows,
              AutoFill: currentField.Field.AutoFill,
              FilterDataOrigin: currentField.Field.FilterDataOrigin,
            }}
            setVarDBRelationship={handleOnChangeVarDBRelationship}
            FieldLabel={currentField.Field.Label}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default EditField;
