import React, { useEffect, useRef } from "react";
import { Input } from "./styles";

interface StringFilterProps {
  value: string;
  placeholder: string;
  onChange: (val: string) => void;
  onSubmit: () => void;
  onPressEnter?: () => void;
  autoFocus?: boolean;
}

const StringFilter: React.FC<StringFilterProps> = ({
  value,
  placeholder,
  onChange,
  onSubmit,
  onPressEnter,
  autoFocus,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (autoFocus) {
      ref?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);
  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (onPressEnter) {
            onPressEnter();
          } else {
            e.currentTarget.blur();
          }
        }
      }}
      onBlur={onSubmit}
      style={{ width: "285px" }}
      placeholder={placeholder}
      ref={ref}
    />
  );
};

export default StringFilter;
