import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Route, Switch, useHistory } from "react-router-dom";
import { useFetch } from "../../hooks";
import DashboardList from "./DashboardList/DashboardList";
import DashboardGraph from "./DashboardGraph/DashboardGraph";
import Header from "./Header";
import CreateDashboardModal from "./Modals/CreateDashboardModal";
import AddGraphModal from "./Modals/AddGraphModal/AddGraphModal";
import { DashboardsContainer } from "./style";
import {
  Dashboard,
  DashboardInformation,
  ModalsType,
  Team,
  DashboardPermissions,
} from "./Dashboards.d";
import EditPermissions from "./Modals/EditPermissions";
import ChangeNameModal from "./Modals/ChangeName";
import DeleteDashboardModal from "./Modals/DeleteDashboardModal";
import { useLocation } from "react-router-dom";

const Dashboards: React.FC = () => {
  const history = useHistory();
  const [teamsInfo, setTeamsInfo] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<number | null>(null);
  const [canAddDashBoards, setCanAddDashBoards] = useState<boolean>(false);
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [selectedDashboard, setSelectedDashboard] = useState<string | null>(
    null
  );
  const [selectedDashboardName, setSelectedDashboardName] =
    useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<number>(0);
  const [dashboardToEdit, setDashboardToEdit] = useState<Dashboard | null>(
    null
  );
  const [modal, setModal] = useState<ModalsType>("");
  const [graphModal, setGraphModal] = useState<ModalsType>("");
  const [editionMode, setEditionMode] = useState<boolean>(false);
  const [reloadPermissions, setReloadPermissions] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<DashboardPermissions>({
    CanViewDashBoard: false,
    CanDeleteDashboard: false,
    CanShareDashboard: false,
    CanEditPermissions: false,
    CanEditLayout: false,
    CanAddGraphs: false,
    CanEditGraphs: false,
  });
  const location = useLocation();
  const { loading, reload } = useFetch<DashboardInformation>({
    func: "Ver2-DashBoards-gi",
    onSuccess: ({ Teams, IdTeamSelected }) => {
      setTeamsInfo(Teams);
      if (location?.pathname.split("/").length === 4) {
        return;
      }
      if (!selectedTeam) {
        setSelectedTeam(IdTeamSelected);
      }
      const teamSelected = _.find(Teams, {
        IdTeam: selectedTeam || IdTeamSelected,
      });

      let dashboards = [] as Dashboard[];
      if (teamSelected) {
        dashboards = teamSelected?.Dashboards as Dashboard[];
      }
      setDashboards(dashboards);
    },
  });

  const setCanAddDashboard = () => {
    if (typeof selectedTeam === "number") {
      const team = _.find(teamsInfo, { IdTeam: selectedTeam });
      setCanAddDashBoards(team?.CanAddDashBoards ?? false);
    }
  };

  useEffect(() => {
    if (reloadPermissions) {
      setCanAddDashboard();
      setReloadPermissions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadPermissions]);

  useEffect(() => {
    setCanAddDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  useEffect(() => {
    const teamSelected = _.find(teamsInfo, {
      IdTeam: selectedTeam,
    });

    let dashboards = [] as Dashboard[];
    if (teamSelected) {
      dashboards = (teamSelected as unknown as Team)?.Dashboards;
    }
    setDashboards(dashboards);
  }, [selectedTeam, teamsInfo]);

  useEffect(() => {
    const dashBoard = dashboards.find(
      ({ IdDashBoard }) => String(IdDashBoard) === selectedDashboard
    );
    setSelectedDashboardName(dashBoard?.DashBoardName || "");
  }, [selectedDashboard, dashboards]);

  const backHome = () => {
    setReloadPermissions(true);
    history.push("/home/dashboards");
  };

  const Modals: { [key: string]: React.ReactNode } = {
    CreateDashboard: (
      <CreateDashboardModal
        onClose={() => setModal("")}
        openAddGraphModal={() => setModal("AddGraph")}
        selectedTeam={selectedTeam}
        teams={teamsInfo}
        reloadDashboard={reload}
      />
    ),
    AddGraph: (
      <AddGraphModal
        onClose={() => setModal("")}
        selectedDashboard={selectedDashboard}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        openGraphTemplateModal={() => setGraphModal("GraphTemplates")}
      />
    ),
    EditPermissions: (
      <EditPermissions
        dashboard={dashboardToEdit}
        onClose={() => {
          setModal("");
          setDashboardToEdit(null);
        }}
      />
    ),
    ChangeName: (
      <ChangeNameModal
        onClose={() => {
          setModal("");
          setDashboardToEdit(null);
        }}
        dashboardName={dashboardToEdit?.DashBoardName}
        idDashboard={dashboardToEdit?.IdDashBoard}
        reloadDashboards={reload}
      />
    ),
    DeleteDashboard: (
      <DeleteDashboardModal
        onClose={() => {
          setModal("");
          setDashboardToEdit(null);
        }}
        dashboardName={dashboardToEdit?.DashBoardName}
        idDashboard={dashboardToEdit?.IdDashBoard}
        reloadDashboard={reload}
        handleBack={backHome}
      />
    ),
  };

  const onOpenEditModal = (modal: ModalsType) => {
    const dashboard = dashboards.find(
      ({ IdDashBoard }) => String(IdDashBoard) === selectedDashboard
    );
    setDashboardToEdit(dashboard || null);
    setModal(modal);
  };

  return (
    <DashboardsContainer>
      {Modals[modal]}
      <Header
        teamsInfo={teamsInfo}
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        selectedDashboard={selectedDashboard}
        onOpenEditModal={onOpenEditModal}
        setSelectedDashboard={setSelectedDashboard}
        selectedDashboardName={selectedDashboardName}
        openModal={setModal}
        backHome={backHome}
        editionMode={editionMode}
        setEditionMode={setEditionMode}
        canAddDashBoards={canAddDashBoards}
        dashboardPermissions={permissions}
      />
      <Switch>
        <Route exact path="/home/dashboards">
          <DashboardList
            selectedTeam={selectedTeam}
            Teams={teamsInfo}
            dashboards={dashboards}
            loading={loading}
            setDashboards={setDashboards}
            setSelectedDashboard={setSelectedDashboard}
            setDashboardToEdit={setDashboardToEdit}
            openPermissionsModal={() => setModal("EditPermissions")}
            setModal={setModal}
            reload={reload}
          />
        </Route>
        <Route path="/home/dashboards/:ids">
          <DashboardGraph
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            setSelectedDashboard={setSelectedDashboard}
            setSelectedDashboardName={setSelectedDashboardName}
            editionMode={editionMode}
            setEditionMode={setEditionMode}
            setSelectedTemplate={setSelectedTemplate}
            setDashboardsModal={setModal}
            backHome={backHome}
            modal={graphModal}
            setModal={setGraphModal}
            selectedTemplate={selectedTemplate}
            permissions={permissions}
            setPermissions={setPermissions}
          />
        </Route>
      </Switch>
    </DashboardsContainer>
  );
};

export default Dashboards;
