import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Row } from "antd";

import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { uploadFileToAWS } from "../../../../aws/s3Client";

const CropImage = ({ onClose, UpImg, setProfilePicture, UserId }) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [Crop, setCrop] = useState({
    unit: "px",
    aspect: 1,
  });
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    if (!previewCanvasRef.current || !imgRef.current) return;
    setLoading(true);

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = Crop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    setLoading(false);
  }, [Crop]);

  const onLoad = useCallback(
    (img) => {
      imgRef.current = img;
      const minSize = Math.min(img.width, img.height);
      setCrop({
        ...Crop,
        width: minSize,
        height: minSize,
        x: 0,
        y: 0,
      });
      return false;
    },
    [Crop]
  );

  const onSave = () => {
    setLoading(true);
    if (!previewCanvasRef.current) return setLoading(false);

    let imgB64 = previewCanvasRef.current.toDataURL();
    let FilesPath = `Users/ProfilePictures/User-${UserId}`;
    fetch(imgB64)
      .then((res) => res.blob())
      .then(async (blob) => {
        const file = new File([blob], "Profile image", { type: "image/png" });
        const filePath = await uploadFileToAWS(file, FilesPath);
        if (filePath) {
          setProfilePicture({
            IdFile: "0",
            Name: file.name,
            SourceUrl: filePath,
          });
        }
        setLoading(false);
      });
  };

  return (
    <Modal visible title="Recortar imagen" onCancel={onClose}>
      <Row>
        <ReactCrop
          src={UpImg}
          onImageLoaded={onLoad}
          crop={Crop}
          minWidth={30}
          onChange={setCrop}
          circularCrop
          keepSelection
          scale={1}
        />
      </Row>
      <canvas
        ref={previewCanvasRef}
        style={{
          // Display for crop preview
          display: "none",
          width: Math.round(Crop?.width ?? 0),
          height: Math.round(Crop?.height ?? 0),
        }}
      />
      <Row justify="space-between" style={{ margin: "2rem 0 0.5rem 0" }}>
        <Button
          onClick={onClose}
          loading={Loading}
          type="default"
          style={{ width: "calc(50% - 0.5rem)" }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onSave}
          loading={Loading}
          type="primary"
          style={{ width: "calc(50% - 0.5rem)" }}
        >
          Guardar
        </Button>
      </Row>
    </Modal>
  );
};

export default CropImage;
