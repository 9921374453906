import React, { useContext } from "react";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";

import { AlertContext } from "./AppAlertContext";
import { device } from "../../constants/css";

import WarningContent from "./Contents/WarningContent";
import TrialContent from "./Contents/TrialContent";

const Container = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 9999;
`;

const AlertContainer = styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: 16px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: ${({ open, height }) => (open ? height : 0)};
  padding: ${({ open }) => (open ? "0.75rem" : 0)};
  overflow: hidden;
  transition: all 0.5s ease;
`;

const AlertText = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${({ alertDisplayed }) =>
    alertDisplayed ? "0.25rem" : "1.25rem"};
  margin: 0.5rem;
  @media ${device.tablet} {
    // margin: 1.25rem 2rem;
    margin: 18px 20px 24px;
  }
`;

const CloseIcon = styled(IoClose)`
  cursor: pointer;
  :hover {
    color: black;
  }
`;

// All new alert types should be added here.
export const AlertTypes = {
  Warning: "WARNING",
  Danger: "DANGER",
  Trial: "TRIAL",
  PaymentRequired: "PAYMENTREQUIRED",
};

// Used to set visual (Not functional) props.
const AlertTypeProps = {
  [AlertTypes.Warning]: {
    backgroundColor: "linear-gradient(to right, #fcee1c, #fac525)",
    color: "#4d4d4c",
    height: "3rem",
    closable: true,
  },
  [AlertTypes.Danger]: {
    backgroundColor: "linear-gradient(to right, #ff4d4d, #e20000)",
    color: "white",
    height: "3rem",
    closable: true,
  },
  [AlertTypes.Trial]: {
    backgroundColor: "linear-gradient(to right, #8381d2, #9a68c4)",
    color: "white",
    height: "3.9rem",
    closable: true,
  },
  [AlertTypes.PaymentRequired]: {
    backgroundColor: "linear-gradient(to right, #8c89d7, #b544ad)",
    color: "white",
    height: "3.9rem",
    closable: false,
  },
};

const AppAlert = ({ children }) => {
  // AlertInfo must be used to fill variable data to be used inside the alert.
  // Example: { ExpirationDate: "15/may/21" } --- Could be used to set the date for the Warning alert.
  const {
    AlertData: { AlertType, AlertInfo, IsActive },
    setIsActive,
  } = useContext(AlertContext);
  const { backgroundColor, color, closable, height } =
    AlertTypeProps[AlertType];

  // This object will be used as Dictionary for the content of each type of alert.
  const AlertTextContent = {
    [AlertTypes.Warning]: <WarningContent AlertInfo={AlertInfo} />,
    [AlertTypes.Danger]: AlertTypes.Danger,
    [AlertTypes.Trial]: <TrialContent AlertInfo={AlertInfo} />,
    [AlertTypes.PaymentRequired]: <TrialContent AlertInfo={AlertInfo} />,
  }[AlertType];

  // Here we can include extra functionality when closing the alert.
  const handleCloseAlert = () => {
    switch (AlertType) {
      case AlertTypes.Warning:
      case AlertTypes.PaymentRequired:
      case AlertTypes.Trial:
        setIsActive(false);
        break;
      case AlertTypes.Danger:
      default:
        break;
    }
  };

  return (
    <Container>
      <AlertContainer
        backgroundColor={backgroundColor}
        color={color}
        height={height}
        open={IsActive}
      >
        <AlertText>{AlertTextContent}</AlertText>
        <CloseIcon
          hidden={!closable}
          size={25}
          onClick={handleCloseAlert}
          style={{ margin: "auto 0" }}
        />
      </AlertContainer>
      <Content alertDisplayed={IsActive}>{children}</Content>
    </Container>
  );
};

export default AppAlert;
