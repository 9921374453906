import styled from "styled-components";
import { device } from "../../constants/css";
import ButtonComponent from "../Button";

export const ModalBackground = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00000061;
  position: absolute;
  z-index: 9999;
  display: ${({ visible }) => (visible ? "flex" : "none")};
`;

export const Card = styled.div`
  background: white;
  margin: auto;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  box-shadow: 20px 20px 60px #8b8b8b, -20px -20px 60px #bbbbbb;
  text-align: center;
  font-size: 17px;
  line-height: 1.25rem;
  width: 90%;
  @media ${device.tablet} {
    width: 25rem;
  }
`;

export const Title = styled.div`
  color: #e40017;
  margin-bottom: 1rem;
  font-family: "gotham-bold";
`;

export const Text = styled.div`
  margin-bottom: 1.5rem;
`;

export const Button = styled(ButtonComponent)`
  && {
    padding: 5px 10px;
  }
`;

export const ButtonBorder = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "calc(50% - 0.5rem)")};
  padding: 1px;
  background: linear-gradient(to right, #84be54, #3f7db8);
  border-radius: 11px;
`;
