import React, { useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Progress, Space, Input } from "antd";
import ResponsiblesPopover from "./Popups/ResponsiblesPopover";
import AlertsPopover from "./Popups/AlertsPopover";
import { Languages } from "./Dictionary";
import {
  ProcessesInformationContainer,
  CardTitle,
  ProcessesContainer,
  ProcessBox,
  ProcessLeftContent,
  ProcessName,
  ProcessMetadata,
  ProcessRightContent,
  GraphContainer,
  NameWrapper,
} from "./Styles";
import { ProcessesInformationType, Template } from "../Dashboard.d";
import { getStatusInfo } from "../../Pipeline/helpers";
import { Circle } from "../../../../components/ColorPicker";
import Tooltip from "../../../../components/Tooltip";
import { Tag, Typography } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";
import _ from "lodash";
import {
  UserIcon,
  NodeIcon,
  BellIcon,
  FormIcon,
} from "../../../../components/hoverIcons";
//@ts-ignore
import emoji from "emoji-dictionary";

const { Text } = Typography;

const {
  ProcessDetailsTooltipLabel,
  ResponsiblesTooltipLabel,
  AlertsTooltipLabel,
  FlowTooltipLabel,
  FormFieldsTooltipLabel,
} = Languages["ESP"];

interface ProcessesInformationProps {
  processesInfo: ProcessesInformationType[];
  selectedTemplate: Template | null;
  openFlux: () => void;
  openFields: () => void;
  setCanEditProcess: (canEdit: boolean) => void;
  setProcessToOpen: (id: number, state?: string) => void;
  openMultiModal: () => void;
  reload: () => void;
}

const ProcessesInformation: React.FC<ProcessesInformationProps> = ({
  processesInfo,
  selectedTemplate,
  openFlux,
  openFields,
  setCanEditProcess,
  setProcessToOpen,
  openMultiModal,
  reload,
}) => {
  const { Count, ProcessTemplateName } = selectedTemplate || {
    Count: 0,
    ProcessTemplateName: "",
  };
  const redStatusPercent = 25;
  const yellowStatusPercent = 50;
  const greenStatusPercent = 100;
  const itemsPerPage = 5;

  const [hasMore, setHasMore] = useState<boolean>(true);
  const [records, setRecords] = useState<number>(itemsPerPage);
  const [responsiblesId, setResponsiblesId] = useState<number | null>(null);
  const [alertsId, setAlertsId] = useState<number | null>(null);
  const [editingProcess, setEditingProcess] =
    useState<ProcessesInformationType | null>();
  const [newProcessName, setNewProcessName] = useState<string>("");
  const scrollParent = useRef(null);

  const [updateProcessExecutionTitle] = useMutation<[]>({
    func: "Ver2-Reports-upet",
    onSuccess: () => {
      setEditingProcess(null);
      reload();
    },
    onError: () => {
      setEditingProcess(null);
      reload();
    },
  });

  const loadMore = () => {
    if (processesInfo && [...processesInfo].length > 0) {
      if (records === processesInfo.length || records > processesInfo.length) {
        setHasMore(false);
      } else {
        setRecords(records + itemsPerPage);
      }
    }
  };

  const getCircleColor = (progress: number) => {
    let firstColor;
    let lastColor;
    if (progress <= redStatusPercent) {
      firstColor = "rgba(194,50,40,255)";
      lastColor = "rgba(234,174,60,255)";
    } else if (progress <= yellowStatusPercent) {
      firstColor = "rgba(224,233,71,255)";
      lastColor = "rgba(177,229,73,255)";
    } else if (progress <= greenStatusPercent) {
      firstColor = "rgba(67,162,67,255)";
      lastColor = "rgba(50,149,65,255)";
    } else {
      firstColor = "rgba(67,162,67,255)";
      lastColor = "rgba(50,149,65,255)";
    }
    return {
      "0%": firstColor,
      "100%": lastColor,
    };
  };

  const openProcessDetail = (id: number) => {
    setProcessToOpen(id);
    openMultiModal();
  };

  const handleOpenFlux = (id: number) => {
    setProcessToOpen(id, "flux");
    openFlux();
  };

  const handleOpenFields = (id: number, canEdit: boolean) => {
    setCanEditProcess(canEdit);
    setProcessToOpen(id, "fields");
    openFields();
  };

  const onChangeProcessName = () => {
    const isProcessEmpty = _.isEmpty(_.trim(newProcessName));
    const isSameProcessName = _.isEqual(
      _.trim(newProcessName),
      editingProcess?.Name
    );

    if (isProcessEmpty || isSameProcessName) {
      setEditingProcess(null);
      return;
    }
    updateProcessExecutionTitle({
      args: {
        IdProcessExecution: editingProcess?.IdProcessExecution,
        Name: _.trim(newProcessName),
      },
    });
  };

  const renderProcess = () => {
    let items = [];
    for (let idx = 0; idx < records; idx++) {
      const process = [...processesInfo][idx];
      if (process) {
        items.push(
          <ProcessBox key={idx}>
            <ProcessLeftContent>
              <ProcessName>
                <div>
                  <Tooltip title={ProcessDetailsTooltipLabel}>
                    <Text
                      color="#0067B0"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        openProcessDetail(process.IdProcessExecution)
                      }
                      tabIndex={0}
                      onKeyDown={({ key }) => {
                        if (key === "Enter")
                          openProcessDetail(process.IdProcessExecution);
                      }}
                    >
                      Folio {process.IdProcessExecution}
                    </Text>
                  </Tooltip>
                </div>

                {process.Permissions.UserCanEditProcess &&
                _.isEqual(
                  editingProcess?.IdProcessExecution,
                  process.IdProcessExecution
                ) ? (
                  <Input
                    style={{
                      height: "25px",
                      maxWidth: "250px",
                      borderRadius: "5px",
                      marginRight: "0.5rem",
                    }}
                    autoFocus
                    onPressEnter={onChangeProcessName}
                    onBlur={onChangeProcessName}
                    value={newProcessName}
                    onChange={(e) => setNewProcessName(e.target.value)}
                  />
                ) : (
                  <NameWrapper>
                    {process.Name.length > 22 ? (
                      <Tooltip
                        title={process.Name.replace(
                          /:\w+:/gi,
                          (name) => emoji.getUnicode(name) ?? name
                        )}
                      >
                        <p
                          onClick={() => {
                            setNewProcessName(process.Name || "");
                            setEditingProcess(process);
                          }}
                        >
                          {process.Name.replace(
                            /:\w+:/gi,
                            (name) => emoji.getUnicode(name) ?? name
                          )}
                        </p>
                      </Tooltip>
                    ) : (
                      <p
                        onClick={() => {
                          setNewProcessName(process.Name || "");
                          setEditingProcess(process);
                        }}
                      >
                        {process.Name.replace(
                          /:\w+:/gi,
                          (name) => emoji.getUnicode(name) ?? name
                        )}
                      </p>
                    )}
                  </NameWrapper>
                )}
              </ProcessName>

              <ProcessMetadata>
                <div>
                  <Tag color={getStatusInfo(process.Status).color}>
                    {getStatusInfo(process.Status).label}
                  </Tag>
                </div>

                {process.Priority && (
                  <Tag color={process.Priority.Color}>
                    {process.Priority.Title}
                  </Tag>
                )}

                {process.Stage && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Space size={4}>
                      <Circle color={process.Stage.Color} />
                      <Text>{process.Stage.Label}</Text>
                    </Space>
                  </div>
                )}
              </ProcessMetadata>
            </ProcessLeftContent>

            <ProcessRightContent>
              <ResponsiblesPopover
                open={responsiblesId === process.IdProcessExecution}
                onClose={() => setResponsiblesId(null)}
                processId={responsiblesId}
              >
                <Tooltip title={ResponsiblesTooltipLabel}>
                  <UserIcon
                    onClick={() =>
                      setResponsiblesId(process.IdProcessExecution)
                    }
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (key === "Enter")
                        setResponsiblesId(process.IdProcessExecution);
                    }}
                    filled
                  />
                </Tooltip>
              </ResponsiblesPopover>

              {process.Permissions.UserHasPendingNotifications && (
                <AlertsPopover
                  open={alertsId === process.IdProcessExecution}
                  onClose={() => setAlertsId(null)}
                  alertsId={alertsId}
                >
                  <Tooltip title={AlertsTooltipLabel}>
                    <BellIcon
                      onClick={() => setAlertsId(process.IdProcessExecution)}
                      tabIndex={0}
                      onKeyDown={({ key }) => {
                        if (key === "Enter")
                          setAlertsId(process.IdProcessExecution);
                      }}
                      dot={process.Permissions.UserHasPendingNotifications}
                      fill
                    />
                  </Tooltip>
                </AlertsPopover>
              )}

              {process.Permissions.UserCanOpenFlux && (
                <Tooltip title={FlowTooltipLabel}>
                  <NodeIcon
                    onClick={() => handleOpenFlux(process.IdProcessExecution)}
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (key === "Enter")
                        handleOpenFlux(process.IdProcessExecution);
                    }}
                    filled
                  />
                </Tooltip>
              )}

              {process.Permissions.UserCanOpenFields && (
                <Tooltip title={FormFieldsTooltipLabel}>
                  <FormIcon
                    onClick={() =>
                      handleOpenFields(
                        process.IdProcessExecution,
                        process.Permissions.UserCanEditProcess
                      )
                    }
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (key === "Enter")
                        handleOpenFields(
                          process.IdProcessExecution,
                          process.Permissions.UserCanEditProcess
                        );
                    }}
                    colored
                  />
                </Tooltip>
              )}

              <div>
                <GraphContainer>
                  <Progress
                    percent={
                      process.ProgressPercentage >= 0
                        ? process.ProgressPercentage
                        : 0
                    }
                    type="circle"
                    width={60}
                    strokeColor={getCircleColor(process.ProgressPercentage)}
                    strokeLinecap="butt"
                  />
                </GraphContainer>
              </div>
            </ProcessRightContent>
          </ProcessBox>
        );
      }
    }
    return items;
  };

  return (
    <ProcessesInformationContainer ref={scrollParent}>
      {selectedTemplate && (
        <CardTitle>{`${Count} ${ProcessTemplateName}`}</CardTitle>
      )}
      <ProcessesContainer>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          useWindow={false}
          getScrollParent={() => scrollParent.current}
          loader={<div key="loading" />}
        >
          {renderProcess()}
        </InfiniteScroll>
      </ProcessesContainer>
    </ProcessesInformationContainer>
  );
};

export default ProcessesInformation;
