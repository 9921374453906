export const Languages = {
  ENG: {
    Welcome: "Welcome to premium",
    Plan: "Plan ",
    Successful: "Your purchase was successful!",
    SuccessfulPurchase: "Succesful purchase!",
    NextBill: (date, price) =>
      "Your next bill will be of $" + price + " and it will arrive on" + date,
    GoGeest: "Go to Geest",
    MessageAddUser: (val) =>
      `Has been add ${val} ${val === "1" ? "user" : "users"} at your`,
  },
  ESP: {
    Welcome: "Bienvenido a premium",
    Plan: "Plan ",
    Successful: (FrontView) =>
      FrontView === "SuccessfulBonusTrial"
        ? "Tu prueba se extendió con éxito"
        : "Tu compra se realizó con éxito",
    SuccessfulPurchase: (FrontView) =>
      FrontView === "SuccessfulBonusTrial"
        ? "¡Registro exitoso!"
        : "¡Compra exitosa!",
    NextBill: (date, price) =>
      "Tu próxima factura será de $" + price + " USD y llegará el " + date,
    GoGeest: "Ir a Geest",
    MessageAddUser: (val) =>
      `Se ${val === "1" ? "ha" : "han"} agregado ${val} ${
        val === "1" ? "usuario" : "usuarios"
      }  a tu`,
  },
};
