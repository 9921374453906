import styled, { css } from "styled-components";

export const borderColor = "#48505E";
export const border = `2px solid ${borderColor}`;
export const handlerStyles: React.CSSProperties = {
  border: "none",
  background: borderColor,
  height: "10px",
  width: "10px",
};

interface RectangleProps {
  $isSelected: boolean;
  stageColor?: string;
}
export const Rectangle = styled.div<RectangleProps>`
  border: ${border};
  border-color: ${({ stageColor = borderColor }) => stageColor};
  border-radius: 10px;
  height: 90px;
  width: 220px;
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: "Gotham-book";
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      box-shadow: 4px 10px 6px 0px rgba(72, 80, 94, 0.3);
    `}
`;

export const Circle = styled(Rectangle)`
  border-radius: 50%;
  height: 90px;
  width: 90px;
`;
