import styled from "styled-components";

export const Title = styled.div`
  font-family: "Gotham-Book";
  color: #7c7c7c;
  font-size: x-large;
  display: block;
  text-align: center;
  margin-bottom: 1rem;
`;

export const Text = styled.div`
  font-family: "Gotham-Book";
  font-size: 18px;
  color: #7c7c7c;
  margin-bottom: 0.5rem;
`;

export const ProcessNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;

  p {
    margin: 0;
    font-size: 18px;
  }
`;

export const StyledInput = styled.input`
  border-radius: 6px;
  border: 2px solid;
  border-color: ${({ $empty = false }) => ($empty ? "#db232c" : "#edecec")};
  background-color: white;
  color: #828d9e;

  width: 100%;
  min-height: 30px;
  height: 30px;
  resize: none;
  padding: 6px 10px;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const FieldLabel = styled(Text)`
  margin: 0.5rem 0 0 0;
`;

export const FieldContainer = styled.div`
  margin-top: 14px;
`;
