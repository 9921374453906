import React from "react";
import { noop as NOOP } from "lodash";
import {
  Table,
  Column,
  ColumnHeader,
  Cell,
  HeaderText,
  HeaderSubtext,
} from "./styles";
import { getPlanColors } from "../../views/Premium/helpers";
import Limitation, { CenteredText } from "./Limitation";
import { moneyFormat } from "../../Helpers";
import Button, { ButtonBorder } from "../Button";

const PlansTable = ({
  PlanLimitations = [],
  Plans = {},
  PlansOrder = [],
  PriceType,
  onSelectPlan = NOOP,
  activePlan = "Pro",
  pricingMode = "byUsers",
  CurrentPlan = {},
}) => {
  const priceTypes = {
    Monthly: "CostMonthly",
    Annually: "CostAnnually",
  };

  return (
    <Table>
      <Column count={PlansOrder.length + 1}>
        <ColumnHeader blank>
          <div>Limitations</div>
          <div>PricePlaceholder</div>
        </ColumnHeader>
        {PlanLimitations.map(({ Name }) => (
          <Cell key={Name}>{Name}</Cell>
        ))}
        <Cell>
          <div style={{ fontSize: "12px" }}>
            <span style={{ color: "red" }}>*</span>Incluye IVA
          </div>
        </Cell>
      </Column>
      {PlansOrder.map((plan) => (
        <Column
          key={plan}
          count={PlansOrder.length + 1}
          active={plan === activePlan}
          planColor={getPlanColors(plan).mainColor}
          style={{ textAlign: "center" }}
        >
          <ColumnHeader>
            <HeaderText planColor={getPlanColors(plan).mainColor}>
              {plan}
            </HeaderText>
            <HeaderSubtext>
              {moneyFormat(Plans[plan][priceTypes[PriceType]], 0)} USD
              <span style={{ color: "red" }}>*</span>
            </HeaderSubtext>
          </ColumnHeader>
          {PlanLimitations.map((limitation, index) => (
            <Cell key={index}>
              <Limitation limitation={limitation} plan={plan} />
            </Cell>
          ))}
          {(plan !== CurrentPlan.PlanName ||
            CurrentPlan.BillingMode !== PriceType) && (
            <Cell>
              <CenteredText>
                <ButtonBorder>
                  <Button
                    style={{ border: "none" }}
                    onClick={() => onSelectPlan(Plans[plan])}
                  >
                    Elegir este plan
                  </Button>
                </ButtonBorder>
              </CenteredText>
            </Cell>
          )}
        </Column>
      ))}
    </Table>
  );
};

export default PlansTable;
