import React from "react";
import styled from "styled-components";
import { ReactComponent as OrderUpIconSVG } from "../../resources/img/OrderUpIcon.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface OrderUpIconProps {
  width?: string;
  height?: string;
}

const OrderUpIcon: React.FC<OrderUpIconProps> = ({
  width = "14px",
  height = "14px",
}) => {
  return (
    <IconWrapper>
      <OrderUpIconSVG width={width} height={height} />
    </IconWrapper>
  );
};

export default OrderUpIcon;
