export const Languages = {
  ENG: {
    LoginLabel: " Log in",
    UserPlaceholder: "Email...",
    PasswordPlaceholder: "Password...",
    ForgotPasswordLabel: "Forgot your password?",
    NoAccountLabel: "Don't have an account?",
    RegisterLabel: " Register ",
    EmailPleaseLabel: "Please enter email",
    RestorePasswordLabel: "Restore password",
    EnterEmailMessage:
      "Enter your email and we will send you a link to recover your password",
    EnterEmailPlaceholder: "Email...",
    SendLinkLabel: "Send link",
    FieldRequired: "Required field",
    EmailValidation: "The email format is invalid",
  },
  ESP: {
    LoginLabel: " Iniciar sesión",
    UserPlaceholder: "Correo electrónico...",
    PasswordPlaceholder: "Contraseña...",
    ForgotPasswordLabel: "¿Olvidaste tu contraseña?",
    NoAccountLabel: "¿No tienes cuenta?",
    RegisterLabel: " Regístrate ",
    EmailPleaseLabel: "Por favor ingresa el correo electrónico",
    RestorePasswordLabel: "Restablecer contraseña",
    EnterEmailMessage:
      "Ingresa tu correo electrónico y te mandaremos un enlace para recuperar el acceso a tu cuenta",
    EnterEmailPlaceholder: "Correo electrónico...",
    SendLinkLabel: "Enviar enlace",
    FieldRequired: "Campo requerido",
    EmailValidation: "El correo no tiene un formato válido",
  },
};
