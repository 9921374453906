import styled, { css } from "styled-components";
import { Checkbox, Divider, Drawer, Tag } from "antd";

// <TaskEditor />

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    background: #f2f8fe;
    padding: 0;
  }
`;

export const StyledBody = styled.div`
  padding: 24px;
`;

// <TabsHeader />
export const StyledTabs = styled.div`
  background: #0067b0;
  display: flex;
  flex-direction: row;
`;

export const Tab = styled.div<{ active: boolean }>`
  flex: 1;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: rgba(2, 115, 233, 1);
    ${({ active }) =>
      active &&
      css`
        background-color: #014c9b;
      `}
  }
  ${({ active }) =>
    active &&
    css`
      background-color: #014c9b;
    `}
`;

export const CloseIconContainer = styled.div`
  padding: 12px;
  cursor: pointer;
  :hover {
    background-color: #014c9b;
  }
`;

// <GeneralPage />

export const StyledTitle = styled.span`
  color: #48505e;
  font-family: "Gotham-bold";
  font-size: 14px;
`;
export const StyledLabel = styled.div`
  color: #48505e;
  font-family: Gotham-Book;
  font-size: 14px;
`;
export const StyledCheckbox = styled(Checkbox)`
  font-family: Gotham-Book;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0273e9;
  }
`;
export const StyledTag = styled(Tag)`
  border-radius: 6px;
  padding: 3px 10px;
  font-family: "Gotham-book";
  margin: 0;
`;

// <SchedulePage />

export const StyledDivider = styled(Divider)`
  border-color: #edecec;
  border-width: 2px;
`;
