import React from "react";
import { ArticleWrapper, SectionWrapper, StyledLink } from "../Styles";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { BasicExample } from "./Snippets/ShortcutsSnippets";

interface ShortcutsLinkProps {
  isActive: () => boolean;
  setSection: () => void;
}

const SectionName = "Shortcuts";

export const ShortcutsLink: React.FC<ShortcutsLinkProps> = ({
  isActive,
  setSection,
}) => {
  return (
    <StyledLink onClick={setSection} $isActive={isActive()}>
      {SectionName}
    </StyledLink>
  );
};

interface ShortcutsSectionProps {}

export const ShortcutsSection: React.FC<ShortcutsSectionProps> = () => {
  return (
    <SectionWrapper>
      <h2>{SectionName}</h2>
      <ArticleWrapper>
        <p>
          El editor recibe dos funciones para poder trabajar con shortcuts. La
          primera es <code className="code">keyBindingFn</code> y la segunda{" "}
          <code className="code">handleKeyCommand</code>.
        </p>
        <p>
          La idea es que, al detectar un evento de teclado, el editor dispara la
          función keyBindingFn, pasando como argumento un evento sintético, con
          el cual podemos evaluar el valor de dicha tecla y retornar un string
          que sirve como nombre del comando ejecutado. Después el editor recibe
          ese nombre de comando y lo pasa a la función{" "}
          <code className="code">handleKeyCommand</code> para poder evaluarlo y
          ejecutar la lógica necesaria.
        </p>
        <p>
          En nuestro editor, ya tenemos preparadas unas funciones para gestionar
          este proceso, además que el propio editor ya cuenta con una serie de
          comandos predefinidos.
        </p>
        <p>Los comandos ya definidos por el editor son:</p>
        <ul>
          <li>
            <code className="code">ctrl + b</code> - bold
          </li>
          <li>
            <code className="code">ctrl + i</code> - italics
          </li>
          <li>
            <code className="code">ctrl + u</code> - underline
          </li>
          <li>
            <code className="code">ctrl + z</code> - undo
          </li>
          <li>
            <code className="code">ctrl + y</code> - redo
          </li>
        </ul>
        <p className="note">
          Recuerda que si quieres extender los shortcuts disponibles, agrega el
          nombre de tu comando siguiendo el estilo definido, es decir, agregando
          tu constante al objeto <code className="code">COMMANDS</code> con
          snake_case, con el nombre de la propiedad todo mayusculas y el valor
          en minúsculas. <span className="type">¡Nada de magic strings!</span>{" "}
          Además, recuerda retornar <code className="code">"handled"</code> o{" "}
          <code className="code">"not-handled"</code> en la función{" "}
          <code className="code">handleKeyCommand</code>
        </p>
        <p>
          La librería de <code className="code">draft-js</code> nos brinda un
          objeto llamado <code className="code">KeyBindingUtils</code> con
          varias propiedades que nos resultarán muy útiles, por ejemplo{" "}
          <code className="code">hasCommandModifier</code>, así, si queremos
          hacer un commando que sea por ejemplo:{" "}
          <code className="code">ctrl+t</code> para insertar una tabla, bastaría
          con agregar un código como el siguiente:
        </p>
        <SyntaxHighlighter
          children={BasicExample}
          language="typescript"
          style={atomOneDark}
        />
      </ArticleWrapper>
    </SectionWrapper>
  );
};
