import React from "react";
import { Container } from "./styles";
import { StatsConfigWizardProps } from "./StatsConfigWizard.d";
import ModalTab from "../../../components/ModalTab";
import { GeneralStep, VisualizationStep } from "./Steps";

const StatsConfigWizard: React.FC<StatsConfigWizardProps> = ({
  IdGraph,
  setIdGraph,
  IdProcessTemplate,
  TableName,
  TemplateName,
  setIdProcessTemplate,
  setTableName,
  activeStep,
  setActiveStep,
  processOptions,
  setState,
  groupByField,
  setGroupByField,
  dateOption,
  setDateOption,
  IdGraphTemplate,
  stats,
  setStats,
  firstTime,
  setFirstTime,
  onRemoveStat,
  onEditStat,
  onClose,
  setUnsavedChanges,
}) => {
  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <GeneralStep
            TableName={TableName}
            IdGraph={IdGraph}
            IdProcessTemplate={IdProcessTemplate}
            setTableName={setTableName}
            setIdProcessTemplate={setIdProcessTemplate}
            setActiveStep={setActiveStep}
            processOptions={processOptions}
            setGroupByField={setGroupByField}
            setUnsavedChanges={setUnsavedChanges}
          />
        );
      case 1:
        return (
          <VisualizationStep
            TableName={TableName}
            IdGraph={IdGraph}
            setIdGraph={setIdGraph}
            TemplateName={TemplateName}
            setState={setState}
            groupByField={groupByField}
            setGroupByField={setGroupByField}
            dateOption={dateOption}
            setDateOption={setDateOption}
            IdGraphTemplate={IdGraphTemplate}
            IdProcessTemplate={IdProcessTemplate}
            stats={stats}
            setStats={setStats}
            firstTime={firstTime}
            setFirstTime={setFirstTime}
            onRemoveStat={onRemoveStat}
            onEditStat={onEditStat}
            onClose={onClose}
            setUnsavedChanges={setUnsavedChanges}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Container>
      <ModalTab
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        canGoNext={!!TableName && !!IdProcessTemplate}
        linear
        steps={["General", "Visualización"]}
      />
      {getStepContent()}
    </Container>
  );
};

export default StatsConfigWizard;
