import stringDT from "../../resources/img/DataType_828D9E/string.svg";
import numberDT from "../../resources/img/DataType_828D9E/number.svg";
import phoneDT from "../../resources/img/DataType_828D9E/phone.svg";
import dateDT from "../../resources/img/DataType_828D9E/date.svg";
import timeDT from "../../resources/img/DataType_828D9E/time.svg";
import locationDT from "../../resources/img/DataType_828D9E/location.svg";
import currencyDT from "../../resources/img/DataType_828D9E/currency.svg";
import selectDT from "../../resources/img/DataType_828D9E/select.svg";
import multiSelectDT from "../../resources/img/DataType_828D9E/multiselect.svg";
import usersSelectDT from "../../resources/img/DataType_828D9E/user.svg";
import checkListDT from "../../resources/img/DataType_828D9E/checklist.svg";
import fileDT from "../../resources/img/DataType_828D9E/file.svg";
import varDBRowDT from "../../resources/img/DataType_828D9E/vardb.svg";
import pdfDT from "../../resources/img/DataType_828D9E/pdf.svg";
import signatureDT from "../../resources/img/DataType_828D9E/sign.svg";

const DataTypeIcons: {
  [key: string]: {
    icon: string;
    label: string;
    extraStyles: { [key: string]: any };
    extraStylesSmall?: { [key: string]: any };
  };
} = {
  string: {
    icon: stringDT,
    label: "Campo tipo texto",
    extraStyles: {},
  },
  number: {
    icon: numberDT,
    label: "Campo tipo número",
    extraStyles: {},
  },
  phone_number: {
    icon: phoneDT,
    label: "Campo tipo número telefónico",
    extraStyles: {},
  },
  date: {
    icon: dateDT,
    label: "Campo tipo fecha",
    extraStyles: {},
  },
  time: {
    icon: timeDT,
    label: "Campo tipo fecha y hora",
    extraStyles: {},
  },
  location: {
    icon: locationDT,
    label: "Campo tipo ubicación",
    extraStyles: {},
  },
  currency: {
    icon: currencyDT,
    label: "Campo tipo moneda",
    extraStyles: {},
  },
  select: {
    icon: selectDT,
    label: "Campo tipo lista de opciones",
    extraStyles: {},
  },
  multi_select: {
    icon: multiSelectDT,
    label: "Campo tipo selección mútliple",
    extraStyles: {},
  },
  users_select: {
    icon: usersSelectDT,
    label: "Campo tipo selección de usuarios",
    extraStyles: {},
  },
  checklist: {
    icon: checkListDT,
    label: "Campo tipo checklist",
    extraStyles: {},
  },
  file: {
    icon: fileDT,
    label: "Campo tipo archivo",
    extraStyles: {},
  },
  varDBRow: {
    icon: varDBRowDT,
    label: "",
    extraStyles: {},
  },
  pdf_autogenerated: {
    icon: pdfDT,
    label: "Campo tipo pdf autogenerado",
    extraStyles: {},
  },
  signature: {
    icon: signatureDT,
    label: "Campo tipo firma",
    extraStyles: {},
  },
};

export default DataTypeIcons;
