import React from "react";
import {
  Connection,
  Edge,
  MarkerType,
  Node,
  NodeChange,
  BezierPathOptions,
  XYPosition,
  EdgeChange,
} from "reactflow";
import _ from "lodash";
import {
  ApiLink,
  ApiNode,
  ApiNodeTypes,
  ConditionPort,
  GetNodesFlux,
  IPortNodeDestiny,
  IPortNodeOrigin,
  InitialNodePosition,
  NodeData,
  SelectedStage,
} from "./Flow.d";
import { CustomNodeTypes } from "./CustomNodes";
import { CustomEdgeTypes } from "./CustomEdges";
import { HandlerId } from "./CustomNodes/shared/shared.d";
import { UpdateConditionDetails } from "./NodeEditor/ConditionEditor/ConditionEditor.d";
import { EditStageAction } from "../FlowActions/EditStages/EditStagesDropdown.d";
import { Stage } from "./CustomNodes/Toolbox/Toolbox.d";
import { Task } from "./NodeEditor/TaskEditor/TaskEditor.d";
import { parseEmoji } from "../../../emoji";

const regularEdgeColor = "#48505E";
const selectedEdgeColor = "#E11909";
const enableLogs = false;

const logger = (msg: string): void => {
  if (enableLogs) console.warn(msg);
};

// Flow

const setDeletingState = (
  nodeId: string,
  nodes: Node<NodeData>[],
  deleting: boolean
): Node<NodeData>[] =>
  _.map(nodes, (node) =>
    updateNodeData(node, { deleting: node.id === nodeId ? deleting : false })
  );

export const resetDeletingState = (nodes: Node<NodeData>[]): Node<NodeData>[] =>
  _.map(nodes, (node) => updateNodeData(node, { deleting: false }));

export const handleOnDragOver: React.DragEventHandler<HTMLDivElement> = (
  event
) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
};

const findNodeById = (nodes: Node<NodeData>[], id: string) =>
  _.find(nodes, { id });
export const removeNode = (
  nodes: Node<NodeData>[],
  id: string
): Node<NodeData>[] => _.reject(nodes, { id });
export const removeEdge = (edges: Edge[], id: string): Edge[] =>
  _.reject(edges, { id });
export const filterEdgesByIds = (edges: Edge[], linkIds: number[]): Edge[] =>
  _.filter(edges, ({ id }) => !_.includes(linkIds, _.toNumber(id)));

export const isValidConnection = (
  connection: Connection,
  nodes: Node<NodeData>[],
  edges: Edge[]
): boolean => {
  const { source, sourceHandle, target, targetHandle } = connection;

  // Validate not completed edges
  if (!source || !target) return false;

  const sourceNode = findNodeById(nodes, source)!;
  const targetNode = findNodeById(nodes, target)!;

  // Same node not allowed
  if (source === target) {
    logger("Same node not allowed");
    return false;
  }

  const existingEdges = _.filter(edges, { source, target });
  if (!_.isEmpty(existingEdges)) {
    // Duplicated edges not allowed
    if (sourceNode.type !== CustomNodeTypes.CONDITIONAL_NODE) {
      logger("Duplicated edge");
      return false;
    }
    // Diferent Condition ports exception
    if (
      _.find(existingEdges, { sourceHandle, targetHandle }) ||
      _.find(existingEdges, { sourceHandle, target })
    ) {
      logger("Duplicated edge on condition");
      return false;
    }
  }
  // Immediate Cycles are not allowed
  if (_.find(edges, { source: target, target: source })) {
    // Task to Condition and Condition to task exception
    if (
      (sourceNode.type !== CustomNodeTypes.TASK_NODE ||
        targetNode.type !== CustomNodeTypes.CONDITIONAL_NODE) &&
      (sourceNode.type !== CustomNodeTypes.CONDITIONAL_NODE ||
        targetNode.type !== CustomNodeTypes.TASK_NODE)
    ) {
      logger("Immediate Cycles are not allowed");
      return false;
    }
  }

  return true;
};

// Flow configuration

export const filterDeleteKey = (
  event: React.KeyboardEvent<HTMLDivElement>,
  onDelete: () => void
) => {
  if (["Backspace", "Delete"].includes(event.key)) {
    onDelete();
  }
};

// Edge configuration

const pathOptions: BezierPathOptions = {
  curvature: 0.3,
};

export const defaultEdgeOptions = {
  type: CustomEdgeTypes.CUSTOM_BEIZER,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 16,
    height: 16,
    color: regularEdgeColor,
  },
  style: {
    strokeWidth: 3,
    stroke: regularEdgeColor,
  },
};

// Node configuration

export const defaultNodeData: NodeData = {
  label: "",
  stageId: 0,
  stageColor: "",
  deleting: false,
  onAction: _.noop,
  onDeleteAttempt: _.noop,
  onAbortDelete: _.noop,
  onChangeNodeStage: _.noop,
};

export const startNode: Node<NodeData> = {
  id: "0",
  data: { ...defaultNodeData, label: "Inicio" },
  position: { x: 0, y: 0 },
  type: CustomNodeTypes.START_NODE,
};

const updateNodeData = (
  node: Node<NodeData>,
  values: Partial<NodeData>
): Node<NodeData> => ({ ...node, data: { ...node.data, ...values } });

export const nodeInjections = (
  nodes: Node<NodeData>[],
  injections?: Partial<Node<Partial<NodeData>>>
): Node<NodeData>[] =>
  _.map(nodes, (node) => ({
    ...node,
    ...injections,
    deletable: false,
    data: {
      ...node.data,
      ...injections?.data,
    },
  }));

export const createNodeDefaults = (
  type: CustomNodeTypes,
  position: XYPosition
): Node<NodeData> => ({
  id: "0",
  position,
  type,
  data: { ...defaultNodeData },
});

// Node actions

const updateNodeDataById = (
  nodeId: string,
  nodes: Node<NodeData>[],
  stage: SelectedStage
): Node<NodeData>[] =>
  _.map(nodes, (node) => {
    if (node.id === nodeId) {
      return updateNodeData(node, { ...stage });
    }
    return node;
  });

export const onNodeDeleteAttempt = (
  nodes: Node<NodeData>[]
): Node<NodeData>[] => {
  const node = _.find(nodes, { selected: true });
  if (node !== undefined && node.id !== "0") {
    return setDeletingState(node.id, nodes, true);
  }
  return nodes;
};

export const onEdgeDelete = (
  edges: Edge[]
): {
  edges: Edge[];
  deletedEdge?: Edge;
} => {
  const edge = _.find(edges, { selected: true });
  if (edge !== undefined) {
    return { edges: _.reject(edges, { id: edge.id }), deletedEdge: edge };
  }
  return { edges };
};

export const onCopyNode = (
  nodes: Node<NodeData>[],
  setCopiedNode: (nodeId: string) => void
): void => {
  const node = _.find(nodes, { selected: true });
  if (node !== undefined && node.id !== "0") {
    setCopiedNode(node.id);
  }
};

export const suppressOnChangeNodes = (changes: NodeChange[]): boolean => {
  const [change] = changes;
  // Suppress moving StartNode
  if (change.type === "position" && change.id === "0") {
    return true;
  }
  return false;
};

const nodeChangedPosition = (
  initialNodePosition: InitialNodePosition,
  node: Node<NodeData>
): boolean => {
  if (!initialNodePosition) return true;
  if (
    node.id !== initialNodePosition.nodeId ||
    node.position.x !== initialNodePosition.x ||
    node.position.y !== initialNodePosition.y
  )
    return true;
  return false;
};

export const onNodeMoved = (
  changes: NodeChange[],
  initialNodePosition: InitialNodePosition,
  nodes: Node<NodeData>[]
): {
  nodeId: string;
  positionCSV: string;
} | null => {
  const [change] = changes;
  if (change.type === "position" && change.dragging === false) {
    const node = findNodeById(nodes, change.id);
    if (
      node &&
      node.type !== CustomNodeTypes.START_NODE &&
      nodeChangedPosition(initialNodePosition, node)
    )
      return {
        nodeId: change.id,
        positionCSV: [node.position.x, node.position.y].join(","),
      };
  }
  return null;
};

export const onNodeStageChange = (
  nodes: Node<NodeData>[],
  nodeId: string,
  stage: SelectedStage
): Node<NodeData>[] => {
  const node = findNodeById(nodes, nodeId);
  if (node !== undefined && node.id !== "0") {
    return updateNodeDataById(nodeId, nodes, stage);
  }
  return nodes;
};

// API Response

const apiCoordinatesToFlowPosition = (coordinates: string): XYPosition => {
  const [x, y] = _.split(coordinates, ",");
  return {
    x: _.toNumber(x),
    y: _.toNumber(y),
  };
};

const apiTypeToFlowType = (apiNodeType: ApiNodeTypes): CustomNodeTypes => {
  switch (apiNodeType) {
    case "Task":
      return CustomNodeTypes.TASK_NODE;
    case "Condition":
      return CustomNodeTypes.CONDITIONAL_NODE;
    case "Automation":
      return CustomNodeTypes.AUTOMATION_NODE;
    case "Subflux":
      return CustomNodeTypes.SUBFLUX_NODE;
    case "EndFlux":
      return CustomNodeTypes.END_NODE;
  }
};

export const flowTypeToApiType = (
  nodeType: CustomNodeTypes
): ApiNodeTypes | CustomNodeTypes.START_NODE => {
  switch (nodeType) {
    case CustomNodeTypes.TASK_NODE:
      return "Task";
    case CustomNodeTypes.CONDITIONAL_NODE:
      return "Condition";
    case CustomNodeTypes.AUTOMATION_NODE:
      return "Automation";
    case CustomNodeTypes.SUBFLUX_NODE:
      return "Subflux";
    case CustomNodeTypes.END_NODE:
      return "EndFlux";
    case CustomNodeTypes.START_NODE:
      return CustomNodeTypes.START_NODE;
  }
};

const apiConditionPorts = (
  conditionPorts?: ConditionPort[]
): ConditionPort[] | undefined => {
  if (_.isEmpty(conditionPorts)) return undefined;
  return conditionPorts;
};

export const apiNodeToFlowNode = (node: ApiNode): Node<NodeData> => ({
  id: _.toString(node.IdNode),
  position: apiCoordinatesToFlowPosition(node.CoordinatesCsv),
  type: apiTypeToFlowType(node.NodeType),
  data: {
    ...defaultNodeData,
    label: parseEmoji(node.NodeTitle),
    stageColor: node.StageColor,
    stageId: node.IdStage,
    conditionPorts: apiConditionPorts(node.ConditionPorts),
    IsFieldMandatory: node.IsFieldMandatory,
  },
});

const apiPortOriginToSourceHandle = (
  port: IPortNodeOrigin
): HandlerId | string => {
  switch (port) {
    case "BidirectionalLeft":
      return HandlerId.OUT_LEFT;
    case "BidirectionalRight":
    case "port1":
      return HandlerId.OUT_RIGHT;
    case "BidirectionalTop":
      return HandlerId.OUT_TOP;
    case "BidirectionalBottom":
      return HandlerId.OUT_BOTTOM;
    default:
      return port;
  }
};

const sourceHandleToApiPortOrigin = (
  handler: HandlerId,
  nodes: Node<NodeData>[],
  idNodeSource: string
): IPortNodeOrigin => {
  const { type } = findNodeById(nodes, idNodeSource)!;
  const parsedNodeType = type as CustomNodeTypes;
  switch (handler) {
    case HandlerId.OUT_TOP:
      return "BidirectionalTop";
    case HandlerId.OUT_RIGHT:
      if (parsedNodeType === CustomNodeTypes.START_NODE) return "port1";
      return "BidirectionalRight";
    case HandlerId.OUT_BOTTOM:
      return "BidirectionalBottom";
    case HandlerId.OUT_LEFT:
      return "BidirectionalLeft";
    default:
      return handler;
  }
};

const apiPortDestinyToTargetHandle = (port: IPortNodeDestiny): HandlerId => {
  switch (port) {
    case "BidirectionalTop":
    case "InTop":
      return HandlerId.IN_TOP;
    case "BidirectionalRight":
      return HandlerId.IN_RIGHT;
    case "BidirectionalBottom":
    case "InBottom":
      return HandlerId.IN_BOTTOM;
    case "BidirectionalLeft":
    case "InLeft":
      return HandlerId.IN_LEFT;
    default:
      return port;
  }
};

const targetHandleToApiPortDestiny = (
  handler: HandlerId,
  nodes: Node<NodeData>[],
  idNodeTarget: string
): IPortNodeDestiny => {
  const { type } = findNodeById(nodes, idNodeTarget)!;
  const parsedNodeType = type as CustomNodeTypes;
  switch (handler) {
    case HandlerId.IN_TOP:
      if (parsedNodeType === CustomNodeTypes.CONDITIONAL_NODE) return "InTop";
      return "BidirectionalTop";
    case HandlerId.IN_RIGHT:
      return "BidirectionalRight";
    case HandlerId.IN_BOTTOM:
      if (parsedNodeType === CustomNodeTypes.CONDITIONAL_NODE)
        return "InBottom";
      return "BidirectionalBottom";
    case HandlerId.IN_LEFT:
      if (
        [CustomNodeTypes.CONDITIONAL_NODE, CustomNodeTypes.END_NODE].includes(
          parsedNodeType as CustomNodeTypes
        )
      )
        return "InLeft";
      return "BidirectionalLeft";
    default:
      return handler;
  }
};

const apiLinkToFlowEdge = (link: ApiLink): Edge => ({
  id: _.toString(link.IdLink),
  source: _.toString(link.IdNodeOrigin),
  target: _.toString(link.IdNodeDestiny),
  sourceHandle: apiPortOriginToSourceHandle(link.PortNodeOrigin),
  targetHandle: apiPortDestinyToTargetHandle(link.PortNodeDestiny),
  pathOptions,
});

const getEdgeColor = (isSelected: boolean): string =>
  isSelected ? selectedEdgeColor : regularEdgeColor;

export const updateEdgesOnSelectionChange = (
  changes: EdgeChange[],
  edges: Edge[]
): Edge[] | null => {
  if (_.find(changes, { type: "select" })) {
    let newEdges = [...edges];
    const orderedChanges = _.orderBy(changes, "selected");
    for (const change of orderedChanges) {
      if (change.type === "select") {
        newEdges = newEdges.map((edge) => ({
          ...edge,
          selected: change.id === edge.id ? change.selected : false,
          markerEnd: {
            ...defaultEdgeOptions.markerEnd,
            color:
              change.id === edge.id
                ? getEdgeColor(change.selected)
                : defaultEdgeOptions.markerEnd.color,
          },
          style: {
            ...defaultEdgeOptions.style,
            stroke:
              change.id === edge.id
                ? getEdgeColor(change.selected)
                : defaultEdgeOptions.style.stroke,
          },
        }));
      }
    }
    return newEdges;
  }
  return null;
};

export const flowConnectionToApiLink = (
  connection: Connection,
  nodes: Node<NodeData>[]
): Omit<ApiLink, "IdLink"> => ({
  IdNodeOrigin: _.toInteger(connection.source),
  PortNodeOrigin: sourceHandleToApiPortOrigin(
    connection.sourceHandle as HandlerId,
    nodes,
    connection.source as string
  ),
  IdNodeDestiny: _.toInteger(connection.target),
  PortNodeDestiny: targetHandleToApiPortDestiny(
    connection.targetHandle as HandlerId,
    nodes,
    connection.target as string
  ),
});

export const apiResponseToFlow = ({
  Nodes,
  Links,
}: GetNodesFlux): {
  nodes: Node<NodeData>[];
  edges: Edge[];
} => ({
  nodes: _.map(Nodes, apiNodeToFlowNode),
  edges: _.map(Links, apiLinkToFlowEdge),
});

// Condition edition

export const updateConditionNode = (
  nodes: Node<NodeData>[],
  condition: UpdateConditionDetails
): Node<NodeData>[] =>
  _.map(nodes, (node) => {
    if (node.id === condition.IdNode) {
      return {
        ...node,
        data: {
          ...node.data,
          label: parseEmoji(condition.NodeTitle),
          conditionPorts: condition.Ports,
        },
      };
    }
    return node;
  });

export const deleteEdgesByIds = (edges: Edge[], ids: number[]): Edge[] =>
  _.reject(edges, ({ id }) => ids.includes(_.toNumber(id)));

// Stage edition

const updateNodeColors = (
  nodes: Node<NodeData>[],
  stage: Stage
): Node<NodeData>[] =>
  _.map(nodes, (node) => {
    if (node.data.stageId === stage.Id) {
      return updateNodeData(node, { stageColor: stage.Color });
    }
    return node;
  });

const updateNodeForDeletedStage = (
  nodes: Node<NodeData>[],
  stage: Stage
): Node<NodeData>[] =>
  _.map(nodes, (node) => {
    if (node.data.stageId === stage.Id) {
      return updateNodeData(node, { stageId: 0, stageColor: "" });
    }
    return node;
  });

export const syncNodeStages = (
  nodes: Node<NodeData>[],
  action: EditStageAction
): Node<NodeData>[] => {
  switch (action.action) {
    case "update":
      return updateNodeColors(nodes, action.stage);
    case "delete":
      return updateNodeForDeletedStage(nodes, action.stage);
    default:
      return nodes;
  }
};

// TaskNode editor

export const updateNodeFromTask = (
  nodes: Node<NodeData>[],
  nodeId: string,
  task: Task
): Node<NodeData>[] =>
  _.map(nodes, (node) => {
    if (node.id === nodeId) {
      return updateNodeData(node, { label: parseEmoji(task.Title) });
    }
    return node;
  });

// Automation editor

export const updateNodeFromAutomation = (
  nodes: Node<NodeData>[],
  nodeId: string,
  label: string
): Node<NodeData>[] =>
  _.map(nodes, (node) => {
    if (node.id === nodeId) {
      return updateNodeData(node, { label: parseEmoji(label) });
    }
    return node;
  });
