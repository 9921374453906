import styled from "styled-components";

const blueBackground = "#67cfff47";

export const Description = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 14px;
  color: #828d9e;
  width: 309px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;

export const Note = styled(Description)`
  font-size: 12px;
  margin-bottom: 1rem;
`;

export const TeamItem = styled.div`
  background: ${({ active }) => (active ? blueBackground : "white")};
  padding: 0.25rem;
  border-radius: 7px;
  cursor: pointer;
  :hover {
    background: #e7e7e7;
  }
`;

export const UserItem = styled(TeamItem)`
  color: ${({ active }) => (active ? "#014b80" : "#0867ad")};
  white-space: nowrap;
  user-select: none;
`;

export const TeamLogo = styled.div`
  height: 3rem;
  width: 8rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
