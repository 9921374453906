import styled from "styled-components";

export const GeneralStepContainer = styled.div`
  display: flex;
  align-items: flex-start;

  height: 100%;
  max-height: 100%;
`;

export const LeftPannel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 0 20px;
  width: 50%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: white;
  position: sticky;
  top: 0;

  p {
    margin: 0;
  }
  .title {
    color: #48505e;
    font-size: 24px;
    font-family: Gotham-Bold;
  }
  .description {
    font-size: 14px;
    color: #828d9e;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .name {
    margin: 0;
    color: #48505e;
    font-size: 14px;
    font-family: Gotham-Bold;
  }
  .input {
    width: 236px;
  }
`;

export const StringInput = styled.input`
  border-radius: 6px;
  border: 2px solid;
  border-color: #edecec;
  background-color: white;
  color: #828d9e;

  width: 100%;
  min-height: 30px;
  resize: none;
  padding: 3px 10px;

  transition: all 0.35s ease;
  ::placeholder {
    color: #d8dbdf;
  }
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const FormatForm = styled.div`
  display: flex;
  gap: 20px;
`;

export const FormatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  padding: 4px;

  p {
    margin: 0;
    color: #48505e;
    font-size: 14px;
    font-family: Gotham-Bold;
    text-align: center;
    cursor: pointer;
  }
`;

export const GraphFormatAbstraction = styled.div<{
  selected: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 151px;
  height: 125px;
  background: #f6f5f5;
  border-radius: 6px;
  padding: 12px;

  transition: box-shadow 0.35s ease;
  box-shadow: ${({ selected = false }) =>
    selected ? "0px 4px 4px rgba(72, 80, 94, 0.3)" : "none"};

  .stack {
    width: 45%;
    padding-left: 10px;
  }
  .donut {
    width: 55%;
  }
`;

export const ContinueButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PrimaryButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 187px;
  border-radius: 6px;
  padding: 6px 16px;
  border: none;
  font-family: Gotham-Bold;
  background: ${({ disabled = false }) => (disabled ? "#edecec" : "#0273e9")};
  color: ${({ disabled = false }) => (disabled ? "#828d9e" : "white")};
  cursor: ${({ disabled = false }) => (disabled ? "auto" : "pointer")};

  transition: backgroud 0.35s ease;
  :hover {
    backgroud: #014c9b;
  }
`;

export const RightPannel = styled.div`
  width: 50%;
  height: 100%;
  padding 0 20px 20px;
`;

export const PrevisualizationCard = styled.div`
  display: flex;
  flex-direction: column;

  background: #f6f5f5;
  border-radius: 6px;
  height: 500px;
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(72, 80, 94, 0.3);

  .title {
    margin: 0;
    color: #48505e;
    font-size: 14px;
    font-family: Gotham-Bold;
    text-align: center;
  }
`;

export const GraphPreviewContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;
