export enum TaskEditorPages {
  GENERAL = "GENERAL",
  SCHEDULE = "SCHEDULE",
  FIELDS = "FIELDS",
}

export interface SelectOption {
  Label: string;
  Value: string;
}

interface ReportIncomeField {
  Label: string;
  Value: number;
}

interface UserField {
  Email: string;
  FirstName: string;
  Initials: string;
  LastName: string;
  ProfilePicture: string;
  Value: number;
}

export interface TaskPriority {
  IdTaskPriority: number;
  Position: number;
  Color: string;
  Title: string;
}

export type TaskUsers = number[] | string[];

export interface Task {
  IdTaskTemplate: number;
  Title: string;
  Description: string;
  UsersExecutors: TaskUsers;
  UsersRevisers: TaskUsers;
  Postpone: boolean;
  DeadlineSchedTime_Preconf: string;
  DeadlineSchedTime_RefDate: string;
  DeadlineSchedTime_TimeToAdd: number;
  ExecutionMode: boolean;
  RevisionMode: boolean;
  ReportIncomeField: "" | number;
  StartSchedTime_Preconf: string;
  StartSchedTime_RefDate: string;
  StartSchedTime_TimeToAdd: number;
  Priority: [] | TaskPriority;
}

export interface GetTaskDetailAndConfigParams {
  ReportIncomeFields: ReportIncomeField[];
  Task: Task;
  TaskReportingIncome: number | null; // Related to process
  TimesPreconf: SelectOption[];
  TimesRefDateForStart: SelectOption[];
  TimesRefDateForDeadline: SelectOption[];
  UserFields: UserField[];
}

interface SharedPageProps {
  task: Task;
  onChange: (task: Task) => void;
  onChangeAndSave: (task: Task) => void;
  saveTask: () => void;
}

export interface ActivePageProps extends SharedPageProps {
  activePage: TaskEditorPages;
  userList: UserField[];
  reportIncomeFields: ReportIncomeField[];
  taskReportingIncome: number | null;
  setTaskReportingIncome: (taskReportingIncome: number | null) => void;
  timesPreconf: SelectOption[];
  timesRefDateForStart: SelectOption[];
  timesRefDateForDeadline: SelectOption[];
}
export interface GeneralPageProps extends SharedPageProps {
  userList: UserField[];
  reportIncomeFields: ReportIncomeField[];
  taskReportingIncome: number | null;
  setTaskReportingIncome: (taskReportingIncome: number | null) => void;
}
export interface SchedulePageProps extends SharedPageProps {
  timesPreconf: SelectOption[];
  timesRefDateForStart: SelectOption[];
  timesRefDateForDeadline: SelectOption[];
}
