import React, { useState } from "react";
import _ from "lodash";
import { noop as NOOP } from "lodash";
import Modal from "@material-ui/core/Modal";
import { Row } from "antd";
import { useMutation } from "../../../hooks";
import { Paper } from "@material-ui/core";
import { Button } from "../../../GeestUI";
import styled from "styled-components";
import { CloseIcon } from "../../../components/hoverIcons";
import { Languages } from "./Dictionary";

const StyledPaper = styled(Paper)`
  border-radius: 10px !important;
  width: 432px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 12px;

  p {
    margin: 0;
    font-family: Gotham-Bold;
    font-size: 16px;
    color: #48505e;
  }
`;

const ModalSubTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;

  p {
    margin: 0;
    text-align: center;
    font-family: Gotham-Bold;
    font-size: 14px;
    color: #48505e;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 60px;

  p {
    margin: 0;
    text-align: center;
    font-size: 16px;
    color: #828d9e;
  }
  .table-name {
    color: #db232c;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
`;

const Input = styled.input`
  border: 1.5px solid;
  border-color: #c0c9d3;
  background-color: white;
  border-radius: 5px;
  color: #727e8b;
  font-family: Gotham-Book;
  width: 100%;
  min-height: 30px;
  padding: 5px;
  transition: all 0.35s ease;
  ::placeholder {
    color: #828d9e;
  }
  :hover {
    border-color: #2684ff;
    box-shadow: none;
  }
  :focus {
    border-color: var(--navy-blue) !important;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
`;

const DeleteVarDBRows: React.FC<{
  closeModal: () => void;
  IdTeam: string;
  IdVarDB: string;
  reload: () => void;
}> = ({ closeModal, IdTeam, IdVarDB, reload }) => {
  const [title, setTitle] = useState<string>("");

  const {
    ModalTitleLabel,
    DeleteLabel,
    ThisActionIsIrreversibleRows,
    InputPlaceholder,
    CancelLabel,
    IWantToDeleteAllLabel,
  } = Languages["ESP"];

  const [deleteVarDBRows, deleting] = useMutation<[]>({
    func: "Ver2-Vardbs-davr",
    onSuccess: () => {
      closeModal();
      reload();
    },
  });

  const handleConfirm = () => {
    deleteVarDBRows({
      args: { IdTeam, IdVarDB },
    });
  };

  return (
    <Modal
      open
      onClose={deleting ? NOOP : closeModal}
      title={ModalTitleLabel}
      style={{ color: "#727e8b" }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          height: "100%",
        }}
        onClick={deleting ? NOOP : closeModal}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <ModalTitle>
            <p>{DeleteLabel}</p>
            <CloseIcon onClick={closeModal} />
          </ModalTitle>

          <ModalSubTitle>
            <p>¿Seguro que quieres eliminar todas las filas?</p>
          </ModalSubTitle>

          <Description>
            <p>{ThisActionIsIrreversibleRows}</p>
            <p className="table-name">{IWantToDeleteAllLabel}</p>
          </Description>

          <InputContainer>
            <Input
              value={title}
              onChange={({ target: { value } }) => setTitle(value)}
              placeholder={InputPlaceholder}
            />
          </InputContainer>

          <ButtonsRow>
            <Button
              type="secondary"
              onClick={closeModal}
              disabled={deleting}
              loading={deleting}
            >
              {CancelLabel}
            </Button>

            <Button
              type="primary"
              danger
              onClick={handleConfirm}
              disabled={
                deleting || _.trim(title) !== _.trim(IWantToDeleteAllLabel)
              }
              loading={deleting}
            >
              {DeleteLabel}
            </Button>
          </ButtonsRow>
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default DeleteVarDBRows;
