import React from "react";
import { Avatar, Tooltip } from "antd";
import { User } from "../../Diagram.d";

interface UserListProps {
  users: User[];
}

const initialsStyles = {
  border: "none",
  color: "white",
  background: "#014C9B",
};

const UserList: React.FC<UserListProps> = ({ users }) => {
  return (
    <Avatar.Group
      maxCount={3}
      size="small"
      maxStyle={initialsStyles}
      style={{ margin: "0 auto" }}
    >
      {users.map((user) => (
        <Tooltip
          key={user.id}
          title={user.fullName}
          overlayInnerStyle={{
            borderRadius: "2px",
            padding: "6px 8px",
          }}
        >
          <Avatar src={user.profilePicture} style={initialsStyles}>
            {user.initials}
          </Avatar>
        </Tooltip>
      ))}
    </Avatar.Group>
  );
};

export default UserList;
