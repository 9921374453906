import React, { useState } from "react";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { Row } from "antd";
import _, { noop as NOOP } from "lodash";
import { BsEyeSlashFill } from "react-icons/bs";
import { BiCalendarAlt } from "react-icons/bi";
import { AiFillPlayCircle, AiOutlineBell, AiOutlineEye } from "react-icons/ai";
import { Tag } from "../../../GeestUI";
import { TaskGroupProps } from "./PendingList.d";
import { DynamicColumn } from "../Pendings.d";
import { StartedTask, TasksWaitingToStart, UserRole } from "../Pendings.d";
import tasksEmptyImage from "../../../resources/img/realajado.png";
import { HideColumnModal } from "./Popups";
import { dateHourFormat } from "../../../Helpers";
import { PostponeModal } from "../DetailPending/Popups";
import { useMutation } from "../../../hooks";
import { Circle } from "../../../components/ColorPicker";
import DynamicInput from "../../../components/DynamicInput";
import Tooltip from "../../../components/Tooltip";
import { Languages } from "../Dicionary";
import {
  TasksNotEmptyContainer,
  TasksEmptyContainer,
  RedDot,
  ColumnIconContainer,
  IconButton,
  StartTaskButton,
  PendingElement,
  ColumnTitle,
  PendingData,
  PendingsHeader,
  TableHeaderContent,
  NoPriorityTag,
  ReviserIconContainer,
  EmptyContainer,
  ListContainer,
  EmptyWrapper,
  StatusIndicator,
} from "./styles";
import { ContextMenuBackground, ContextMenu } from "./ContextMenu";
//@ts-ignore
import emoji from "emoji-dictionary";

const { Pendings, Priority, ExecutionTitle, Stage, StartedAt, Deadline } =
  Languages["ESP"];

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

const TasksEmptyComponent: React.FC = () => (
  <EmptyContainer>
    <EmptyWrapper>
      <img alt="" src={tasksEmptyImage} />
      <div>
        <p style={{ color: "#48505e" }}>¡Yei! Por ahora todo está tranquilo</p>
        <p>Cuando tengas pendientes por hacer se mostrarán aquí</p>
      </div>
    </EmptyWrapper>
  </EmptyContainer>
);

const TaskGroupContainer: React.FC<{
  children: React.ReactNode;
  tasks: (StartedTask | TasksWaitingToStart)[];
}> = ({ children, tasks }) => {
  return tasks.length > 0 ? (
    <TasksNotEmptyContainer>{children}</TasksNotEmptyContainer>
  ) : (
    <TasksEmptyContainer>{children}</TasksEmptyContainer>
  );
};

const TaskGroup: React.FC<TaskGroupProps> = ({
  isLoadingTaks,
  Tasks,
  ExtraColumns,
  onSelectTask = NOOP,
  reloadPendings = NOOP,
}) => {
  const [columnToHide, setColumnToHide] = useState<DynamicColumn | null>(null);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [postponeTaskId, setPostponeTaskId] = useState<number | null>(null);
  const [taskForContextMenu, setTaskForContextMenu] = useState<
    StartedTask | TasksWaitingToStart | null
  >(null);
  const [points, setPoints] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const selectedTask = _.find(Tasks, {
    IdTaskValue: postponeTaskId,
  });

  const rowHeight = 40;

  const [startScheduledTask] = useMutation<[]>({
    func: "Ver2-MyPendings-sst",
    onSuccess: (_response, upcomingTask) => {
      reloadPendings();
      onSelectTask(upcomingTask as TasksWaitingToStart);
    },
  });

  const [hideImportedColumn] = useMutation<[]>({
    func: "Ver2-MyPendings-hic",
    onSuccess: () => reloadPendings(),
    onError: () => reloadPendings(),
  });

  const getBackground = (role: UserRole): string => {
    switch (role) {
      case "Executor":
        return "#ffffff";
      case "Reviser":
        return "#CBEEFD";
      case "Supervisor":
        return "#E4E4F4";
    }
  };
  const getHoverBackground = (role: UserRole): string => {
    switch (role) {
      case "Executor":
        return "rgba(232, 236, 241, 0.5)";
      case "Reviser":
        return "#94CEF3";
      case "Supervisor":
        return "#B1B1EE";
    }
  };

  const getActionLabel = (role: UserRole): boolean => {
    switch (role) {
      case "Executor":
        return false;
      case "Reviser":
        return true;
      case "Supervisor":
        return true;
      default:
        return false;
    }
  };

  const handleHideColumn = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    column: DynamicColumn
  ) => {
    e.stopPropagation();
    hideImportedColumn({
      args: {
        IdDynamicColumn: column?.Id,
      },
    });
  };

  const onStartUpcomingTask = (pending: TasksWaitingToStart): void => {
    startScheduledTask({
      args: {
        IdTaskValue: pending.IdTaskValue,
      },
      shippedData: pending,
    });
  };

  const onPostponeTask = (): void => {
    setPostponeTaskId(null);
    setIsWaiting(false);
    setTaskForContextMenu(null);
  };

  const onChangePriority = (): void => {
    reloadPendings();
    setPostponeTaskId(null);
    setIsWaiting(false);
    setTaskForContextMenu(null);
  };

  const getCellWidth = (column: string): string => {
    switch (column) {
      // defualt columns
      case "reminders":
        return "50px";
      case "priority":
        return "100px";
      case "task_date":
        return "130px";

      // extra columns by datatype
      case "string":
        return "220px";
      case "phone_number":
        return "180px";
      case "date":
        return "120px";
      case "time":
        return "150px";
      case "location":
        return "220px";
      case "currency":
        return "150px";
      case "select":
        return "220px";
      case "multi_select":
        return "220px";
      case "users_select":
        return "250px";
      case "file":
        return "220px";

      default:
        return "220px";
    }
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case "OnTime":
        return "#08979c";
      case "Stuck":
        return "#cf1322";
      case "WaitingToStart":
        return "#d46b08";
      default:
        return "#08979c";
    }
  };

  const renderTask = ({ index, key, style, parent }: any) => {
    const task = Tasks[index];

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <PendingElement
            // @ts-ignore
            ref={registerChild}
            style={style}
            tabIndex={0}
            isTaskForContextMenu={
              taskForContextMenu?.IdTaskValue === task.IdTaskValue
            }
            background={getBackground(task.UserTaskRole)}
            hoverBackground={
              task.TaskStatus === "Stuck"
                ? "#EDECEC"
                : getHoverBackground(task.UserTaskRole)
            }
            onClick={() => onSelectTask(task)}
            onKeyDown={({ key }) => {
              if (key === "Enter" && !Boolean(taskForContextMenu))
                onSelectTask(task);
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              setTaskForContextMenu(task);
              setPoints({ x: e.pageX, y: e.pageY });
            }}
          >
            {/* task title */}
            <PendingData padding="0 5px 0 14px">
              <StatusIndicator color={getStatusColor(task.TaskStatus)} />
              {task.TaskTitle?.length > 33 ? (
                <Tooltip
                  title={task.TaskTitle.replace(
                    /:\w+:/gi,
                    (name) => emoji.getUnicode(name) ?? name
                  )}
                >
                  <p className="task-title">
                    {task.TaskTitle.replace(
                      /:\w+:/gi,
                      (name) => emoji.getUnicode(name) ?? name
                    )}
                  </p>
                </Tooltip>
              ) : (
                <p className="task-title">
                  {task.TaskTitle.replace(
                    /:\w+:/gi,
                    (name) => emoji.getUnicode(name) ?? name
                  )}
                </p>
              )}
            </PendingData>

            {/* reminders */}
            <PendingData
              gap="5px"
              padding="0 5px"
              $width={getCellWidth("reminders")}
            >
              <ReviserIconContainer>
                {getActionLabel(task.UserTaskRole) && (
                  <Tooltip title="Pendiente de revisión">
                    <AiOutlineEye color="#0273e9" size={16} />
                  </Tooltip>
                )}
              </ReviserIconContainer>

              {!_.isEmpty(task.Reminders) && (
                <Tooltip title={task.Reminders[0].Message}>
                  <Row align="top">
                    <AiOutlineBell color="#48505e" size={16} />
                    <RedDot />
                  </Row>
                </Tooltip>
              )}
            </PendingData>

            {/* Prioridad */}
            <PendingData justify="center" $width={getCellWidth("priority")}>
              {task.Priority ? (
                <Tag
                  color={
                    task.Priority.Color.length === 6
                      ? `#${task.Priority.Color}`
                      : task.Priority.Color || "white"
                  }
                >
                  {task.Priority.Title.length > 7 ? (
                    <Tooltip title={task.Priority.Title}>
                      {`${_.truncate(task.Priority.Title, {
                        length: 10,
                      })}`}
                    </Tooltip>
                  ) : (
                    task.Priority.Title
                  )}
                </Tag>
              ) : (
                <NoPriorityTag>Sin prioridad</NoPriorityTag>
              )}
            </PendingData>

            {/* Seguimiento */}
            <PendingData>
              {task.ProcessExecutionTitle?.length > 33 ? (
                <Tooltip
                  title={task.ProcessExecutionTitle.replace(
                    /:\w+:/gi,
                    (name) => emoji.getUnicode(name) ?? name
                  )}
                >
                  <p className="task-execution-title">
                    {task.ProcessExecutionTitle.replace(
                      /:\w+:/gi,
                      (name) => emoji.getUnicode(name) ?? name
                    )}
                  </p>
                </Tooltip>
              ) : (
                <p className="task-execution-title">
                  {task.ProcessExecutionTitle.replace(
                    /:\w+:/gi,
                    (name) => emoji.getUnicode(name) ?? name
                  )}
                </p>
              )}
            </PendingData>

            {/* Etapa */}
            <PendingData gap="5px" $width={getCellWidth("stage")}>
              <Circle
                color={task.Stage.Color || "white"}
                border={`1px solid ${task.Stage.Color || "#828d9e"}`}
              />

              {task.Stage.Label.length > 22 ? (
                <Tooltip title={task.Stage.Label}>
                  {`${_.truncate(task.Stage.Label, {
                    length: 25,
                  })}`}
                </Tooltip>
              ) : (
                task.Stage.Label || "Sin etapa"
              )}
            </PendingData>

            {/* Inicio */}
            <PendingData justify="center" $width={getCellWidth("task_date")}>
              {dateHourFormat(task.StartedAt, true)}
              {task.TaskStatus === "WaitingToStart" &&
                task.Permissions.UserCanPostponeTask && (
                  <Tooltip title="Cambiar fecha de inicio">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setPostponeTaskId(
                          (task as TasksWaitingToStart).IdTaskValue
                        );
                        setIsWaiting(true);
                      }}
                    >
                      <BiCalendarAlt size={20} />
                    </IconButton>
                  </Tooltip>
                )}
            </PendingData>

            {/* Vencimiento */}
            <PendingData justify="center" $width={getCellWidth("task_date")}>
              <span
                style={task.TaskStatus === "Stuck" ? { color: "#E11909" } : {}}
              >
                {dateHourFormat(task.Deadline, true)}
              </span>
              {task.TaskStatus === "WaitingToStart" && (
                <Tooltip title={"Iniciar tarea"}>
                  <StartTaskButton
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      e.stopPropagation();
                      onStartUpcomingTask(task as TasksWaitingToStart);
                    }}
                  >
                    <AiFillPlayCircle size={20} />
                  </StartTaskButton>
                </Tooltip>
              )}
            </PendingData>

            {/* DynamicColumns */}
            {ExtraColumns.map((extraCol, i) => {
              let val: any = _.get(task, "DynamicData." + extraCol.Id, "");
              let format = "";

              switch (extraCol.DataType) {
                case "currency":
                  format = val.Format;
                  val = val.Value;
                  break;
                case "phone_number":
                  format = val.Format;
                  val = val.Value;
                  break;
                case "multi_select":
                  if (!val) {
                    val = [];
                  }
                  break;
              }

              return (
                <PendingData
                  key={`${extraCol.Id}-${i}`}
                  $width={getCellWidth(extraCol.DataType)}
                >
                  {task.DynamicData && (
                    <DynamicInput
                      value={
                        extraCol.DataType === "users_select" && val
                          ? [...val].map((u: any) => u.IdUser).join(",")
                          : val
                      }
                      type={extraCol.DataType}
                      dataOrigin={""}
                      userSelectDataOrigin={
                        extraCol.DataType === "users_select" ? val || [] : []
                      }
                      format={format}
                      configuration={extraCol.Configuration || ""}
                      fieldName=""
                      isConsult={true}
                      disabled={true}
                      required={false}
                      onChange={NOOP}
                      extraParams={{
                        FilesPath: _.get(
                          task,
                          "DynamicData." + extraCol.Id + "[0].SourceUrl",
                          ""
                        ),
                        showFormat: true,
                        center: false,
                        oneLineString: true,
                        canAddNewRows: false,
                        CanUseExistingRows: true,
                        userSize: "middle",
                        tooltipOffset:
                          extraCol.DataType === "users_select" ? 18 : 30,
                      }}
                    />
                  )}
                </PendingData>
              );
            })}
          </PendingElement>
        )}
      </CellMeasurer>
    );
  };

  return (
    <TaskGroupContainer tasks={Tasks}>
      {!!postponeTaskId && (
        <PostponeModal
          idPending={String(postponeTaskId)}
          onConfirm={onPostponeTask}
          onCancel={() => {
            setPostponeTaskId(null);
            setIsWaiting(false);
          }}
          isWaiting={
            taskForContextMenu?.TaskStatus === "WaitingToStart" || isWaiting
          }
          defaultValue={{
            date: (selectedTask as TasksWaitingToStart).StartedAt || "",
            reminder:
              (selectedTask as TasksWaitingToStart).Reminders[0]?.Message || "",
          }}
        />
      )}

      <HideColumnModal
        visible={!!columnToHide}
        setVisible={() => setColumnToHide(null)}
        Column={columnToHide}
        reload={() => reloadPendings()}
      />

      <PendingsHeader>
        <ColumnTitle justify="start">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <p>{`${Tasks.length} ${Pendings}`}</p>
          </div>
        </ColumnTitle>
        <ColumnTitle $width={getCellWidth("reminders")} />
        <ColumnTitle $width={getCellWidth("priority")}>
          <p>{Priority}</p>
        </ColumnTitle>
        <ColumnTitle>
          <p>{ExecutionTitle}</p>
        </ColumnTitle>
        <ColumnTitle $width={getCellWidth("stage")}>
          <p>{Stage}</p>
        </ColumnTitle>
        <ColumnTitle $width={getCellWidth("task_date")}>
          <p>{StartedAt}</p>
        </ColumnTitle>
        <ColumnTitle $width={getCellWidth("task_date")}>
          <p>{Deadline}</p>
        </ColumnTitle>

        {ExtraColumns.map((column, i) => {
          const { Label, Id, DataType } = column;
          return (
            <ColumnTitle
              key={`${Label}-${Id}-${i}`}
              $width={getCellWidth(DataType)}
            >
              <TableHeaderContent>
                <p>
                  {Label.length > 17 ? (
                    <Tooltip title={Label}>
                      {`${_.truncate(Label, { length: 20 })}`}
                    </Tooltip>
                  ) : (
                    Label
                  )}
                </p>
                <ColumnIconContainer
                  className="iconContainer"
                  justify="center"
                  align="middle"
                  onClick={(e) => handleHideColumn(e, column)}
                >
                  <BsEyeSlashFill size={10} />
                </ColumnIconContainer>
              </TableHeaderContent>
            </ColumnTitle>
          );
        })}
      </PendingsHeader>

      {Boolean(taskForContextMenu?.IdTaskValue) && (
        <ContextMenuBackground onClose={() => setTaskForContextMenu(null)} />
      )}

      {Tasks.length > 0 && (
        <ListContainer
          onClick={(e) => {
            e.stopPropagation();
            setTaskForContextMenu(null);
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={rowHeight}
                rowRenderer={renderTask}
                rowCount={Tasks.length}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        </ListContainer>
      )}

      {Boolean(taskForContextMenu?.IdTaskValue) && (
        <ContextMenu
          task={taskForContextMenu}
          isTaskWaiting={taskForContextMenu?.TaskStatus === "WaitingToStart"}
          points={points}
          openPostponeModal={setPostponeTaskId}
          onChangePriority={onChangePriority}
          onClose={() => setTaskForContextMenu(null)}
        />
      )}

      {_.isEmpty(Tasks) && <TasksEmptyComponent />}
    </TaskGroupContainer>
  );
};

export default TaskGroup;
