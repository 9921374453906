import React, { useState, useRef, useEffect } from "react";
import { useMutation } from "../../../hooks";
import Modal from "../../../components/Modal";
import { Button } from "../../../GeestUI";
import { EditFolderNameProps } from "./EditFolderName.d";
import { StyledInput, ButtonContainer } from "./styles";

const EditFolderName: React.FC<EditFolderNameProps> = ({
  Folder,
  closeModal,
  reload,
}) => {
  const [name, setName] = useState<string>(Folder?.Name || "");
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref?.current?.focus();
  }, []);

  const [changeFolderName, changing] = useMutation({
    func: "Ver2-Processes-cpfn",
    onSuccess: () => {
      closeModal();
      reload();
    },
  });

  const onSubmit = () => {
    changeFolderName({
      args: {
        Name: name,
        IdFolder: Folder?.IdFolder,
        IdTeam: Folder?.IdTeam,
      },
    });
  };

  return (
    <Modal onCancel={closeModal} visible title="Editar título" width="432px">
      <StyledInput
        value={name}
        onChange={(e) => setName(e.target.value)}
        ref={ref}
        onFocus={(e) => e.currentTarget.select()}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
      />
      <ButtonContainer>
        <Button type="primary" loading={changing} onClick={onSubmit}>
          Guardar
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export default EditFolderName;
