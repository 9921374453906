import React from "react";
import _ from "lodash";
import { UploadFile } from "antd";
import PreviewFrame from "./PreviewFrame";
import PreviewImg from "./PreviewImg";
import PreviewPDF from "./previewPDF";

interface PreviewComponentProps {
  file: UploadFile<any>;
  PreviewURL: string;
  handleEsc: () => void;
  isActive: boolean;
}

const PreviewComponent: React.FC<PreviewComponentProps> = ({
  file,
  PreviewURL,
  handleEsc,
  isActive,
}) => {
  const extension = _.toLower(`${file?.url}`.split(".").pop());
  const isPDF = Boolean(["pdf"].includes(extension));
  const isImage = Boolean(["jpeg", "jpg", "png", "webp"].includes(extension));
  const isVideo = Boolean(["mp4", "webm", "ogg"].includes(extension));
  const isAudio = Boolean(["mpeg", "mp3", "wav", "ogg"].includes(extension));

  if (isPDF)
    return (
      <PreviewPDF
        isActive={isActive}
        PreviewURL={PreviewURL}
        handleEsc={handleEsc}
      />
    );

  if (isImage)
    return <PreviewImg PreviewURL={PreviewURL} handleEsc={handleEsc} />;

  if (isVideo)
    return <PreviewFrame PreviewURL={PreviewURL} handleEsc={handleEsc} />;

  if (isAudio)
    return <PreviewFrame PreviewURL={PreviewURL} handleEsc={handleEsc} />;

  return <PreviewFrame PreviewURL={PreviewURL} handleEsc={handleEsc} />;
};

export default PreviewComponent;
