import React, { useCallback, useEffect, useState } from "react";
// @ts-ignore
import * as pdfjs from "pdfjs-dist/build/pdf";
// @ts-ignore
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { LoadingWrapper, PDFPlaceholder, PDFWrapper } from "./Styles";
import Loading from "../../../../Loading";

interface PreviewPDFProps {
  PreviewURL: string;
  handleEsc: () => void;
  isActive: boolean;
}

const PreviewPDF: React.FC<PreviewPDFProps> = ({
  PreviewURL,
  handleEsc,
  isActive,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [loading, setLoading] = useState<boolean>(false);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      handleEsc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    const renderPDF = async () => {
      setLoading(true);
      try {
        // Cargar el PDF utilizando PDF.js
        const loadingTask = pdfjs.getDocument(PreviewURL);
        const pdf = await loadingTask.promise;

        const container = document.getElementById("pdf-container");
        if (!container) {
          throw new Error("Contenedor no encontrado");
        }

        // Limpiar el contenedor antes de renderizar las páginas
        container.innerHTML = "";

        // Iterar sobre todas las páginas del PDF
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          // Obtener la página del PDF
          const page = await pdf.getPage(pageNumber);

          // Escalar la página para que se ajuste al contenedor y mejorar la calidad de renderizado
          const viewport = page.getViewport({ scale: 2 });

          // Crear un canvas para renderizar la página
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          if (!context) {
            throw new Error("Contexto 2D no soportado");
          }
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          // Renderizar la página en el canvas con mayor calidad
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;

          // Agregar un espacio entre las páginas
          if (pageNumber !== 1) {
            const space = document.createElement("div");
            space.style.height = "20px"; // Espacio de 20px entre páginas
            container.appendChild(space);
          }

          // Añadir el canvas al contenedor
          container.appendChild(canvas);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error al cargar el PDF:", error);
        setLoading(false);
      }
    };

    if (isActive && !loading) renderPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <>
      {isActive ? <PDFWrapper id="pdf-container" /> : <PDFPlaceholder />}
      {loading && (
        <LoadingWrapper>
          <Loading simple />
        </LoadingWrapper>
      )}
    </>
  );
};

export default PreviewPDF;
