import React from "react";
import { Languages } from "./Dictionary";
import { FinishStepProps } from "./ImportDBModal.d";
import {
  ButtonsRow,
  FileNameContainer,
  FinishStepWrapper,
  SubTitle,
} from "./Styles";
import { Button } from "../../../../GeestUI";

const FinishStep: React.FC<FinishStepProps> = ({
  fileName,
  DBName,
  onClose,
}) => {
  const { SuccessfullyImportedDescription, AcceptLabel } = Languages["ESP"];

  return (
    <FinishStepWrapper>
      <FileNameContainer>
        <p>({fileName})</p>
      </FileNameContainer>

      <SubTitle>
        <p>
          {SuccessfullyImportedDescription} <span>{DBName}</span>
        </p>
      </SubTitle>

      <ButtonsRow>
        <Button type="primary" onClick={onClose}>
          {AcceptLabel}
        </Button>
      </ButtonsRow>
    </FinishStepWrapper>
  );
};

export default FinishStep;
