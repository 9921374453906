import React from "react";
import styled from "styled-components";
import { ReactComponent as NoDataImageSVG } from "../../../resources/img/nodata.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 17px;
  height: 100%;
  background-color: white;
`;

const Text = styled.p`
  margin: 0;
  width: 278px;
  font-family: "Gotham-Bold";
  font-size: 14px;
  color: #828d9e;
  text-align: center;
  line-height: 19.6px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface NoDataImageProps {
  $width?: string;
  $height?: string;
}

const NoDataImage: React.FC<NoDataImageProps> = ({
  $width = "388px",
  $height = "297px",
}) => {
  return (
    <IconWrapper>
      <NoDataImageSVG width={$width} height={$height} />
    </IconWrapper>
  );
};

interface NoDataProps {}

const NoData: React.FC<NoDataProps> = () => {
  return (
    <Container>
      <NoDataImage />
      <Text>Los filtros aplicados no generaron ningún resultado</Text>
    </Container>
  );
};

export default NoData;
