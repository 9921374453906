import styled from "styled-components";
import { Row } from "antd";
import { IoClose } from "react-icons/io5";
import { device } from "../../constants/css";
import searchIMG from "../../resources/img/SEARCH_TASKS.svg";

export const CloseIcon = styled(IoClose)`
  cursor: pointer;
  :hover {
    color: black;
  }
`;

export const TeamNameBox = styled.div`
  font-family: "Gotham-Bold";
  font-size: 18px;
  color: #48505e;
`;

export const Text = styled.div`
  font-family: "Gotham-Book";
  font-size: 18px;
  color: #7c7c7c;
  margin-bottom: 0.5rem;
`;

export const StyledInput = styled.input`
  border: 2px solid rgba(237, 236, 236, 1);
  color: rgba(130, 141, 158, 1);
  border-radius: 6px;
  padding-left: 6px;
  width: 384px;
  height: 32px;
  font-size: 14px;
  font-weight: 350;
  font-family: Gotham-Book;
  margin-bottom: 1.2rem;
  margin-top: 0.5rem;
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
`;

export const DBTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const DeleteOption = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: rgba(219, 35, 44, 1);
  font-size: 14px;
  font-family: Gotham-Book;
  width: 252px;
  font-weight: 325;
  height: 32px;
  padding: 6px 10px 6px 10px;
  cursor: pointer;
  :hover {
    background-color: rgba(237, 236, 236, 1);
  }
  :active {
    background-color: rgba(242, 248, 254, 1);
  }
`;

export const OrganizeColumnsOptions = styled(DeleteOption)`
  color: rgba(130, 141, 158, 1);
`;

export const TitleInput = styled(StyledInput)`
  margin: 0;
  height: 40px;

  color: #48505e;
  font-family: Gotham-Book;
  font-size: 24px;
`;

export const DBTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  p {
    margin: 0;
    color: #48505e;
    font-family: Gotham-Book;
    font-size: 24px;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover {
      background: #d9d9d9;
    }
  }
`;

export const OptionLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 15px;
    height: 15px;
  }
  p {
    margin: 0;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  max-height: 150px;
  max-height: 250px;
  margin-bottom: 5px;
`;

export const FilterToAdd = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  gap: 10px;
`;

export const FilterTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background-color: rgba(130, 141, 158, 1);

  transition: transform 0.35s ease;
  &.active {
    transform: rotate(180deg);
  }
`;

export const FilterContainer = styled.div`
  position: relative;

  p.filterLabel {
    position: absolute;
    top: -22px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 10px;
    margin: 0;
    font-size: 14px;
    color: #48505e;
  }
`;

export const AppliedFilters = styled.div<{ $filtersVisible: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  height: ${({ $filtersVisible }) => ($filtersVisible ? "" : "0px")};
  transition: height 0.35s ease;
  overflow: auto;
`;

export const FilterField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  border-radius: 6px;
  min-height: 40px;
  height: auto;
  background-color: rgba(246, 245, 245, 1);
  padding: 6px;
  user-select: none;
`;

export const RemoveFilterIconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: -6px;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 80%;
  border-left: 1px solid #48505e;
`;

export const CenterContainer = styled.div<{ $gap?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ $gap = "0px" }) => $gap};
  text-align: center;
`;

export const ImportExportIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const ContextMenuOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    margin: 0;
  }
`;

export const PaginationBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-right: 0.5rem;
`;

export const PaginationControlsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationButton = styled.button`
  border: none;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  aspect-ratio: 1/1;
  text-align: center;

  transition: color 0.35s ease, background-color 0.35s ease;

  font-family: "Gotham-Bold";
  color: ${(props: { isCurrent: boolean }) =>
    props.isCurrent ? "#fff" : "#828d9e"};

  background-color: ${(props: { isCurrent: boolean }) =>
    props.isCurrent ? "#0067b0" : "#fff"};

  &:hover {
    cursor: pointer;
    background-color: ${(props: { isCurrent: boolean }) =>
      props.isCurrent ? "#0273e9" : "#edecec"};
  }
`;

export const EmptyButton = styled.div`
  aspect-ratio: 1/1;
  height: 80%;
`;

export const AddEditButtonsRow = styled.div<{ isEditing: boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: ${({ isEditing }) =>
    isEditing ? "space-between" : "center"};
`;

export const GeneralButton = styled.button`
  background-color: #fafbfd;
  color: #727e8b;
  border: 0.5px solid #e8ecf1;
  border-radius: 5px;
  padding: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  transition: background-color 0.35s ease;
  :hover {
    background-color: #e8ecf1;
    cursor: pointer;
  }

  @media ${device.tablet} {
    padding: 0.2rem 0.6rem;
  }
`;

export const DeleteButton = styled(GeneralButton)`
  border-color: ${({ disabled }) => (disabled ? "#fba39d" : "#e11909")};
  color: ${({ disabled }) => (disabled ? "#fba39d" : "#e11909")};
  background-color: ${({ disabled }) => (disabled ? "#fafbfd" : "white")};
  transition: background-color 0.35s ease, color 0.35s ease,
    border-color 0.35s ease;
`;

export const FormRow = styled(Row)`
  margin-bottom: 1rem;
  height: 100%;
  overflow-y: auto;
`;

export const TableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 380px);

  transition: height 0.35s ease;
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;

  p {
    margin: 0;
    margin-top: 2px;
  }
`;

export const Container = styled.div`
  flex: 1;
  overflow: auto;
  max-height: 100%;
  width: 100%;
`;

export const Table = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const Column = styled.div<{ $sticky: boolean; $isScrolled: boolean }>`
  padding: 0 1px;
`;

export const ColumnHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: #f2f8fe;
  color: #48505e;
  font-size: 12px;
  border-radius: 5px 5px 0 0;
  padding: 8px 12px;
  transition: width 0.35s ease;
`;

export const Header = styled.div<{ center: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${({ center }) => (center ? "auto" : "213px")};
`;

export const ColumnTitleWrapper = styled.span`
  display: flex;
  align-tems: cemter;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;

export const PrimaryKeyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 12px;
  height: 12px;
`;

export const PrimaryKey = styled.img`
  width: 12px;
  height: 12px;
`;

export const ColumnTitle = styled.div<{ reducedSpace: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  width: ${({ reducedSpace }) =>
    reducedSpace ? "100%" : "calc(100% - 14px - 24px)"};

  p {
    margin: 0;
    color: #48505e;
    font-family: Gotham-Bold;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const TableHeaderIconContainer = styled.div<{ unsetted: boolean }>`
  color: ${({ unsetted }) => (unsetted ? "#b7dbfe" : "#0273e9")};
  cursor: pointer;
`;

export const EditColumnButton = styled(CenterContainer)<{
  isSelected: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 2px;
  width: 20px;
  height: 20px;
  background-color: ${({ isSelected }) => (isSelected ? "#1939b7" : "#0273e9")};
  transition: background-color 0.35s ease;
  :hover {
    background-color: #014c9b;
    cursor: pointer;
  }
  :focus {
    background-color: #1939b7;
  }
`;

export const RowWrapper = styled.div<{
  $maxWidth: string;
  isEven: boolean;
  isDragging: boolean;
}>`
  max-width: ${({ $maxWidth }) => $maxWidth};
  transition: background-color 0.35s ease, opacity 0.35s ease;
  background-color: ${({ isEven }) => (isEven ? "transparent" : "#f6f5f5")};
  opacity: ${({ isDragging }) => (isDragging ? "0.8" : "1")};
`;

export const RowBox = styled.div<{ isHovered: boolean }>`
  padding: 0.2rem 0.5rem;
  transition: background-color 0.35s ease;
  background-color: ${({ isHovered }) =>
    isHovered ? "#cbeefd" : "transparent"};
`;

export const RowValue = styled.div<{ center: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? "center" : "auto")};
  gap: 0.5rem;
  height: 2rem;
`;

export const EmptyRow = styled.div`
  content: "";
  height: 2rem;
`;

export const FiltersSelectContainer = styled(Row)<{ $filtersVisible: boolean }>`
  max-height: ${({ $filtersVisible }) => ($filtersVisible ? "30vh" : "0px")};
  transition: max-height 0.3s ease;
  overflow: hidden;
`;

export const FilterCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 33px;
  width: 129px;
  background-color: rgba(242, 248, 254, 1);
  border-radius: 6px;
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  font-size: 12px;
  cursor: pointer;
`;

export const ColumnsViewTitleContainer = styled.div`
  color: rgba(72, 80, 94, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: Gotham-Book;
  font-weight: 325;
  margin: 0px 2rem 10px;
`;

export const SearchBar = styled.input`
  background-image: url(${searchIMG});
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-y: center;
  background-position-x: 6px;
  padding: 2px 0px 2px 35px;
  width: 396px;
  height: 32px;
  border: 2px solid rgba(237, 236, 236, 1);
  border-radius: 6px;
  outline: none;
  color: rgb(130, 141, 158);
  padding-bottom: 0;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
`;

export const ColumnItem = styled.div<{ $filtered: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 0px 100px 100px 0px;
  width: calc(100% - 40px);
  background-color: ${({ $filtered }) =>
    $filtered ? "rgba(255, 226, 166, 1)" : "rgba(237, 236, 236, 1)"};
  font-size: 14px;
  color: rgba(72, 80, 94, 1);
  font-family: Gotham-Bold;
  padding: 8px 12px 8px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
  gap: 5px;
`;

export const PositionContainer = styled.div`
  border-radius: 100px 0px 0px 100px;
  width: 40px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(72, 80, 94, 1);
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  font-family: Gotham-Bold;
`;

export const ColumnsListContainer = styled.div`
  overflow: auto;
  padding: 0px 2rem 10px;
  height: 100%;
`;

export const ColumnItemContainer = styled.div`
  width: 100%;
  flex-direction: row;
  gap: 0px;
  padding: 0;
  display: flex;
`;

export const ListItem = styled.div<{ $width: number }>`
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: ${({ $width }) => $width}px;
  align-items: center;
  .bar {
    width: 85%;
    height: 3px;
    border-radius: 1.5px;
    background-color: transparent;
    margin-top: 5px;
  }
  :active {
    .bar {
      background-color: rgba(2, 115, 233, 1);
      .tooltip-arrow {
        display: none;
      }
      .tooltip {
        display: none;
      }
    }
  }
`;
