import styled from "styled-components";

export const VisualizationStepContainer = styled.div`
  display: flex;
  align-items: flex-start;

  height: 100%;
  max-height: 100%;
`;

export const LeftPannel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 0 20px;
  width: 50%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: white;
  position: sticky;
  top: 0;

  p {
    margin: 0;
  }
  .title {
    color: #48505e;
    font-size: 24px;
    font-family: Gotham-Bold;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  .description {
    font-size: 14px;
    color: #828d9e;
  }
`;

export const GroupByContainer = styled.div`
  display: flex;
  gap: 8px;

  p.label {
    margin: 0;
    color: #48505e;
    font-family: Gotham-Bold;
  }

  .radio-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const SelectElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  cursor: pointer;

  p.option {
    margin: 0;
    color: #828d9e;
  }
`;

export const AxisConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const YAxisContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p.label {
    margin: 0;
    color: #48505e;
    font-family: Gotham-Bold;
  }
`;

export const IncomeConfiguration = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .select-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  p.link {
    margin: 0;
    color: #0273e9;
    font-family: Gotham-Bold;
    font-size: 12px;
    cursor: pointer;
  }
`;

export const InformationBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const XAxisContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p.label {
    margin: 0;
    color: #48505e;
    font-family: Gotham-Bold;
  }
`;

export const ByFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: #f6f5f5;
  border-radius: 6px;
  padding: 8px 10px;
  width: 100%;

  .remove-selection {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
      margin: 0;
      color: #0273e9;
      font-family: Gotham-Bold;
      cursor: pointer;
    }
  }
`;

export const ProcessContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p.name {
    margin: 0;
    padding: 6px 10px;
    color: #48505e;
    font-family: Gotham-Bold;
  }
`;

export const FieldContainer = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  cursor: ${({ $isDisabled }) => ($isDisabled ? "auto" : "pointer")};

  .label {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      margin: 0;
      color: #828d9e;
    }
  }

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PrimaryButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 187px;
  border-radius: 6px;
  padding: 6px 16px;
  border: none;
  font-family: Gotham-Bold;
  background: ${({ disabled = false }) => (disabled ? "#edecec" : "#0273e9")};
  color: ${({ disabled = false }) => (disabled ? "#828d9e" : "white")};
  cursor: ${({ disabled = false }) => (disabled ? "auto" : "pointer")};

  transition: backgroud 0.35s ease;
  :hover {
    backgroud: #014c9b;
  }
`;

export const RightPannel = styled.div`
  width: 50%;
  height: 100%;
  padding 0 20px 20px;
`;

export const PrevisualizationCard = styled.div`
  display: flex;
  flex-direction: column;

  background: #f6f5f5;
  border-radius: 6px;
  height: 500px;
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(72, 80, 94, 0.3);

  .title {
    margin: 0;
    color: #48505e;
    font-size: 14px;
    font-family: Gotham-Bold;
    text-align: center;
  }
`;

export const GraphPreviewContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;
