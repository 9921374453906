import { Switch } from "antd";
import styled from "styled-components";

export const StyledInput = styled.input`
  border: 2px solid #edecec;
  color: #828d9e;
  border-radius: 6px;
  padding-left: 6px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  font-weight: 300;
  font-family: "Gotham-Book";
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
`;

export const StyledTextArea = styled.textarea`
  border: 2px solid #edecec;
  color: #828d9e;
  border-radius: 6px;
  padding-left: 6px;
  width: 100%;
  height: 72px;
  font-size: 14px;
  font-weight: 300;
  font-family: "Gotham-Book";
  resize: none;
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
`;

export const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #0273e9;
  }
`;
