import React from "react";
import { noop as NOOP } from "lodash";
import Modal from "@material-ui/core/Modal";
import { Row } from "antd";
import { useMutation } from "../../../hooks";
import { Paper } from "@material-ui/core";
import { Button } from "../../../GeestUI";
import styled from "styled-components";
import { CloseIcon } from "../../../components/hoverIcons";
import { Languages } from "./Dictionary";

const StyledPaper = styled(Paper)`
  border-radius: 10px !important;
  width: 432px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 12px;

  p {
    margin: 0;
    font-family: Gotham-Bold;
    font-size: 16px;
    color: #48505e;
  }
`;

const ModalSubTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;

  p {
    margin: 0;
    text-align: center;
    font-family: Gotham-Bold;
    font-size: 14px;
    color: #48505e;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
`;

const ConfirmDeleteRow: React.FC<{
  closeModal: () => void;
  IdTeam: string;
  IdVarDB: string;
  IdRow: number;
  reload: () => void;
}> = ({ closeModal, IdTeam, IdVarDB, IdRow, reload }) => {
  const { ModalTitleLabel, DeleteLabel, CancelLabel } = Languages["ESP"];

  const [deleteVarDBRow, deleting] = useMutation<[]>({
    func: "Ver2-Vardbs-dvr",
    onSuccess: () => {
      reload();
      closeModal();
    },
  });

  const handleConfirm = () => {
    deleteVarDBRow({
      args: { IdTeam, IdVarDB, IdRow },
    });
  };

  return (
    <Modal
      open
      onClose={deleting ? NOOP : closeModal}
      title={ModalTitleLabel}
      style={{ color: "#727e8b" }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          height: "100%",
        }}
        onClick={deleting ? NOOP : closeModal}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <ModalTitle>
            <p>{DeleteLabel}</p>
            <CloseIcon onClick={closeModal} />
          </ModalTitle>

          <ModalSubTitle>
            <p>¿Seguro que quieres eliminar esta fila?</p>
          </ModalSubTitle>

          <ButtonsRow>
            <Button
              type="secondary"
              onClick={closeModal}
              disabled={deleting}
              loading={deleting}
            >
              {CancelLabel}
            </Button>

            <Button
              type="primary"
              danger
              onClick={handleConfirm}
              loading={deleting}
            >
              {DeleteLabel}
            </Button>
          </ButtonsRow>
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default ConfirmDeleteRow;
