import styled from "styled-components";
import searchIMG from "../../../../resources/img/SEARCH_TASKS.svg";
import { BaseDrawer } from "../../Styles";

export const ControlsWrapper = styled(BaseDrawer)`
  flex-direction: column;
  gap: 24px;
  width: 400px;
  overflow: auto;
  font-size: 16px;

  * {
    user-select: none;
  }
`;

export const ControlsSecction = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #48505e;
  font-family: Gotham-Bold;

  p {
    margin: 0;
  }
`;

export const AppliedControlsSecction = styled(ControlsSecction)`
  flex: 1;
  background-color: #f2f8fe;
  border-radius: 6px;
`;

export const SearchContainer = styled.div``;

export const SearchInput = styled.input`
  background-image: url(${searchIMG});
  background-repeat: no-repeat;
  background-size: 16px;
  background-position-y: center;
  background-position-x: 8px;
  background-color: white;

  border-radius: 6px;
  border: 2px solid #edecec;
  color: #828d9e;

  width: 100%;
  min-height: 30px;
  resize: none;
  padding: 6px 10px;
  padding-left: 30px;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const AppliedList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const NoElementsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
  color: #48505e;
  font-family: Gotham-Bold;

  p {
    margin: 0;
  }

  img {
    width: 70%;
    height: auto;
  }
`;

export const AppliedNode = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background-color: white;
  border-radius: 6px;
  border: 2px solid #edecec;
  padding: 10px 12px;
  color: #48505e;
  cursor: pointer;

  .title {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      margin: 0;
    }
  }

  .icon-box {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    transition: opacity 0.35s ease;
  }

  transition: background-color 0.35s ease, border 0.35s ease;
  :hover {
    border-color: #e0f0ff;
    background-color: #e0f0ff;

    .icon-box {
      opacity: 1;
    }
  }
`;
