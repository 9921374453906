import { TableInformation } from "./TableDetail.d";

const data: TableInformation[] = [
  {
    IdProcessExecution: 37,
    Name: "Titulo de seguimiento",
    Status: "OnTime",
    Priority: {
      Color: "#F0BE0C",
      Title: "Media",
      IdTaskPriority: 9,
    },
    IdProcessTemplate: 1,
    ProcessTemplateName: "Proceso",
    Stage: {
      Color: "#673AB7",
      Label: "Cotización",
      IdStage: 1,
    },
    StartedAt: "2024-02-20 11:24:41.000000",
    EndedAt: "2024-02-21 11:24:41.000000",
    MeasurementValue: "$100",
  },
  {
    IdProcessExecution: 3,
    Name: "Titulo de seguimiento",
    Status: "OnTime",
    Priority: {
      Color: "#F0BE0C",
      Title: "Media",
      IdTaskPriority: 9,
    },
    IdProcessTemplate: 1,
    ProcessTemplateName: "Zapato",
    Stage: {
      Color: "#673AB7",
      Label: "Cotización",
      IdStage: 1,
    },
    StartedAt: "2024-02-28 11:24:41.000000",
    EndedAt: "2024-02-29 11:24:41.000000",
    MeasurementValue: "$100",
  },
  {
    IdProcessExecution: 469,
    Name: "Titulo de seguimiento",
    Status: "OnTime",
    Priority: {
      Color: "#F0BE0C",
      Title: "Media",
      IdTaskPriority: 9,
    },
    IdProcessTemplate: 1,
    ProcessTemplateName: "Automatización",
    Stage: {
      Color: "#673AB7",
      Label: "Cotización",
      IdStage: 1,
    },
    StartedAt: "2024-02-25 11:24:41.000000",
    EndedAt: "2024-02-26 11:24:41.000000",
    MeasurementValue: "$100",
  },
  {
    IdProcessExecution: 34,
    Name: "Titulo de seguimiento",
    Status: "OnTime",
    Priority: {
      Color: "#F0BE0C",
      Title: "Media",
      IdTaskPriority: 9,
    },
    IdProcessTemplate: 1,
    ProcessTemplateName: "Proceso",
    Stage: {
      Color: "#673AB7",
      Label: "Cotización",
      IdStage: 1,
    },
    StartedAt: "2024-02-02 11:24:41.000000",
    EndedAt: "2024-02-03 11:24:41.000000",
    MeasurementValue: "$100",
  },
  {
    IdProcessExecution: 13,
    Name: "Titulo de seguimiento",
    Status: "OnTime",
    Priority: {
      Color: "#F0BE0C",
      Title: "Media",
      IdTaskPriority: 9,
    },
    IdProcessTemplate: 1,
    ProcessTemplateName: "Proceso",
    Stage: {
      Color: "#673AB7",
      Label: "Cotización",
      IdStage: 1,
    },
    StartedAt: "2024-02-13 11:24:41.000000",
    EndedAt: "2024-02-14 11:24:41.000000",
    MeasurementValue: "$100",
  },
];

export default data;
