import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import InputNumber from "../../../../../components/InputNumber";
import IconUsersAlert from "../../../../../resources/img/ICON_RED_ALERT.png";
import { Row, Col } from "antd";
import { TeamOutlined } from "@ant-design/icons";
import { Languages } from "../Dictionary";

const Text = styled(Row)`
  width: calc(50% - 1rem);
  color: #808080;
  font-size: medium;
  margin-bottom: ${({ $txt }) => ($txt ? "0.2rem" : "auto")};
`;

const Container = styled(Row)`
  flex-direction: column;
  width: 100%;
  align-items: end;
`;

const UsersIcon = styled(TeamOutlined)`
  font-size: 37px;
  color: #0867ad;
  margin-right: 0.7rem;
`;

const UsersCont = styled.div`
  font-family: "Gotham-Medium";
  color: #0867ad;
  font-size: x-large;
  margin-left: 1.5rem;
`;

const DivCont = styled(Row)`
  margin-top: 0.5rem;
  font-size: xxx-large;
  color: #5f5f5f;
  justify-content: center;
  align-items: center;
`;

const AlertMessage = styled.div`
  margin-bottom: 1.5rem;
  font-size: revert;
  font-family: "Gotham-Book";
  color: #df2112;
  padding: 0rem 2.7rem;
`;

const AddMessage = styled.div`
  margin-bottom: 1.5rem;
  font-size: revert;
  font-family: "Gotham-Book";
  color: #808080;
  padding: 0rem 2.7rem;
`;

const DeleteUserCont = styled(Row)`
  font-size: large;
  font-family: "Gotham-Light";
  margin-top: -0.9rem;
  margin-bottom: 1rem;
`;

const ImgCont = styled(Row)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const DeleteUserModal = ({ onCancel, users, onDeleteUsers }) => {
  const { LabelDeleteUser, UsersQ, Cancel, LabelDelete } = Languages["ESP"];
  return (
    <Modal width={400} onCancel={onCancel}>
      <DeleteUserCont>
        <Col span={4}>
          <ImgCont>
            <img src={IconUsersAlert} alt="icon" width="38" />
          </ImgCont>
        </Col>
        <Col span={20}>
          <Row>
            <span style={{ marginRight: "4px" }}>{LabelDeleteUser}</span>
            <b>{users}</b>
            <span style={{ marginLeft: "4px" }}>{UsersQ}</span>
          </Row>
        </Col>
      </DeleteUserCont>
      <Row justify="space-between">
        <Button
          type="default"
          style={{ width: "calc(50% - 0.5rem)" }}
          onClick={onCancel}
        >
          {Cancel}
        </Button>
        <Button
          type="danger"
          style={{ width: "calc(50% - 0.5rem)" }}
          onClick={() => {
            onDeleteUsers();
            onCancel();
          }}
        >
          {LabelDelete}
        </Button>
      </Row>
    </Modal>
  );
};

const UsersManagementModal = ({
  onCancel,
  usersManagement: {
    PurchasedUsers,
    UsersInUse,
    CostPerUser: costPerUser,
    BilledMonthly,
  },
  date,
  onChangeUsers,
}) => {
  const purchasedUsers = Number(PurchasedUsers);
  const [CurrentUsers, setCurrentUsers] = useState(purchasedUsers);
  const [modal, setModal] = useState(null);

  const ShowButtons = purchasedUsers !== CurrentUsers;
  const MessageDeleteUser = purchasedUsers > CurrentUsers;
  const MessageAddUser = purchasedUsers < CurrentUsers;
  const Price = costPerUser * CurrentUsers;
  const UsersToDelete = purchasedUsers - CurrentUsers;
  const UsersToAdd = CurrentUsers - purchasedUsers;

  const {
    UsersQuantity,
    InUse,
    Hired,
    Cancel,
    ModifyNUsers,
    MessageAlertTxt,
    MessageAddUserTxt,
  } = Languages["ESP"];

  const Modals = {
    DeleteUser: (
      <DeleteUserModal
        onCancel={() => setModal(null)}
        users={UsersToDelete}
        onDeleteUsers={() => {
          onChangeUsers(CurrentUsers);
          onCancel();
        }}
      />
    ),
  };

  return (
    <Modal width={550} title={UsersQuantity} visible onCancel={onCancel}>
      {Modals[modal]}
      <Row>
        <Col span={11}>
          <Container justify="end">
            <Text $txt justify="end">
              {InUse}
            </Text>
            <Row align="middle">
              <UsersIcon />
              <UsersCont>{UsersInUse}</UsersCont>
            </Row>
          </Container>
        </Col>
        <Col span={2}>
          <DivCont>/</DivCont>
        </Col>
        <Col span={11}>
          <Text>{Hired}</Text>
          <Row align="middle">
            <InputNumber
              value={CurrentUsers}
              onChange={setCurrentUsers}
              min={1}
            />
          </Row>
        </Col>
      </Row>
      {MessageDeleteUser && (
        <AlertMessage>
          {MessageAlertTxt(date, CurrentUsers, Price)}
        </AlertMessage>
      )}
      {MessageAddUser && (
        <AddMessage>
          {MessageAddUserTxt(
            date,
            CurrentUsers,
            Price,
            BilledMonthly,
            UsersToAdd
          )}
        </AddMessage>
      )}
      {ShowButtons && (
        <Row justify="space-between">
          <Button
            type="default"
            style={{ width: "calc(50% - 0.5rem)" }}
            onClick={onCancel}
          >
            {Cancel}
          </Button>
          <Button
            type="primary"
            style={{ width: "calc(50% - 0.5rem)" }}
            onClick={() =>
              MessageAddUser
                ? onChangeUsers(CurrentUsers)
                : setModal("DeleteUser")
            }
          >
            {ModifyNUsers}
          </Button>
        </Row>
      )}
    </Modal>
  );
};

export default UsersManagementModal;
