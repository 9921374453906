import React from "react";
import styled from "styled-components";
//@ts-ignore
import emoji from "emoji-dictionary";
import _ from "lodash";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import Tooltip from "../../../../../components/Tooltip";
import {
  CustomLink,
  CustomList,
  CustomOrderedList,
} from "../../../../../components/MarkdownHelpers";

const ExecutionName = styled.div<{ textCenter: boolean }>`
  font-size: 12px;
  font-family: Gotham-Book;
  color: #48505e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
  width: 100%;
  ${({ textCenter }) => (textCenter ? "text-align: center;" : "")};

  p {
    margin: 0;
  }
`;

const ReactMarkdownStyled = styled(ReactMarkdown)<{ $oneLine: boolean }>`
  width: 100%;
  max-width: 100%;
  overflow-wrap: break-word;

  * {
    ${({ $oneLine }) =>
      $oneLine
        ? `
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      `
        : `
          overflow-wrap: break-word;
      `}
  }
`;

interface StringComponentProps {
  value: string;
  textCenter?: boolean;
}

const StringComponent: React.FC<StringComponentProps> = ({
  value,
  textCenter = false,
}) => {
  const lengthLimit = 24;

  return (
    <Tooltip title={value} placement="bottom">
      <ExecutionName textCenter={textCenter}>
        <ReactMarkdownStyled
          children={`${_.truncate(value, { length: lengthLimit })}`
            .replace(/\n/gi, "&nbsp; \n")
            .replace(/:\w+:/gi, (name) => emoji.getUnicode(name) ?? name)}
          remarkPlugins={[remarkGfm, remarkBreaks]}
          $oneLine={true}
          components={{
            a: ({ children, href }) => {
              return (
                <CustomLink href={href} setClickingLink={() => {}}>
                  {children}
                </CustomLink>
              );
            },
            ul: ({ children }) => {
              return <CustomList>{children}</CustomList>;
            },
            ol: ({ children }) => {
              return <CustomOrderedList>{children}</CustomOrderedList>;
            },
          }}
        />
      </ExecutionName>
    </Tooltip>
  );
};

export default StringComponent;
