import styled from "styled-components";

export const Input = styled.input<{ $width?: string }>`
  border-radius: 6px;
  border: 2px solid;
  border-color: #edecec;
  background-color: white;
  color: #828d9e;
  font-family: Gotham-Book;

  width: ${({ $width }) => $width ?? "200px"};
  height: 32px;
  resize: none;
  padding: 6px 10px;

  transition: all 0.35s ease;
  ::placeholder {
    color: #828d9e;
  }
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;
