import React, { useEffect, useRef, useState } from "react";
import { Space } from "antd";
import _ from "lodash";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { GeneralPageProps, TaskPriority, TaskUsers } from "./TaskEditor.d";
import { StyledInput, StyledTextArea, StyledSwitch } from "../../styles";
import { StyledTitle, StyledLabel, StyledCheckbox, StyledTag } from "./styles";
import { EditIcon } from "../../../../../components/hoverIcons";
import GeestSelect from "../../../../../GeestUI/GeestSelect";
import User from "../../../../../components/User";
import PriorityPopover from "./PriorityPopover";
import { parseEmoji } from "../../../../../emoji";

const userValueParser = (users: string): number[] => {
  if (_.isEmpty(users)) return [];
  return _.map(_.split(users, ","), _.toNumber);
};

const GeneralPage: React.FC<GeneralPageProps> = ({
  task,
  userList,
  reportIncomeFields,
  taskReportingIncome,
  setTaskReportingIncome,
  onChange,
  onChangeAndSave,
  saveTask,
}) => {
  const [open, setOpen] = useState(false);
  const [showReportIncome, setShowReportIncome] = useState(
    task.ReportIncomeField !== ""
  );

  const [titleInputActive, setTitleInputActive] = useState(false);
  const [descriptionInputActive, setDescriptionInputActive] = useState(false);

  const TaskNameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (TaskNameInputRef.current) TaskNameInputRef.current.focus();
    }, 350);
  }, [TaskNameInputRef]);

  const onChangeTitle: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    onChange({ ...task, Title: e.target.value });
  const onChangeDescription: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => onChange({ ...task, Description: e.target.value });
  const onChangeExecutors = (value: string) =>
    onChangeAndSave({ ...task, UsersExecutors: userValueParser(value) });
  const onChangeExecutionMode = (e: CheckboxChangeEvent) =>
    onChangeAndSave({ ...task, ExecutionMode: e.target.checked });
  const onChangeRevisers = (value: string) =>
    onChangeAndSave({ ...task, UsersRevisers: userValueParser(value) });
  const onChangeRevisionMode = (e: CheckboxChangeEvent) =>
    onChangeAndSave({ ...task, RevisionMode: e.target.checked });
  const onChangeReportIncomeField = (ReportIncomeField: number | "") =>
    onChangeAndSave({ ...task, ReportIncomeField });
  const onChangePriority = (Priority: TaskPriority | []) =>
    onChange({ ...task, Priority });

  const shouldShowUsersMode = (taskUsers: TaskUsers) => {
    const users = _.map(taskUsers, _.toString);
    if (!users.length) return false; // No users
    if (users.length > 1) return true; // More than 1 user.
    const [User] = users;
    if (!User.includes("-")) return true; // Positive Id = Field.
    if (User.includes("|")) return true; // VarDBRow field.
  };

  const onChangeShowReportIncome = (value: boolean) => {
    if (!value) {
      onChangeReportIncomeField("");
      setTaskReportingIncome(null);
    }
    if (value && taskReportingIncome) {
      onChangeReportIncomeField(taskReportingIncome);
    }
    setShowReportIncome(value);
  };

  const handleOnBlur = (
    setActiveField: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setActiveField(false);
    saveTask();
  };

  const usersOptions = userList.map((user) => {
    // If user is a Field
    if (user.Value > 0) {
      user.FirstName = "Campo: ";
    }
    return {
      label: (
        <div style={{ padding: "5px 0" }}>
          <User
            user={{ ...user, IdUser: user.Value }}
            size="middle"
            picSize="20px"
            tooltip
          />
        </div>
      ),
      searchableValues: `${user.FirstName},${user.LastName}`,
      value: _.toString(user.Value),
    };
  });
  const reportIncomeOptions = reportIncomeFields.map(({ Value, Label }) => ({
    value: Value,
    label: Label,
  }));
  const parsedTaskPriority = !_.isEmpty(task.Priority)
    ? (task.Priority as TaskPriority)
    : null;

  return (
    <Space direction="vertical" size={[0, 20]} style={{ width: "100%" }}>
      <StyledInput
        placeholder="Nombre de la tarea..."
        value={titleInputActive ? task.Title : parseEmoji(task.Title)}
        onFocus={() => setTitleInputActive(true)}
        onChange={onChangeTitle}
        onBlur={() => handleOnBlur(setTitleInputActive)}
        autoFocus
        ref={TaskNameInputRef}
      />
      <StyledTextArea
        placeholder="Escribe una descripción..."
        value={
          descriptionInputActive
            ? task.Description
            : parseEmoji(task.Description)
        }
        onFocus={() => setDescriptionInputActive(true)}
        onChange={onChangeDescription}
        onBlur={() => handleOnBlur(setDescriptionInputActive)}
      />
      <Space align="center">
        <StyledTitle>Prioridad</StyledTitle>
        <StyledTag color={parsedTaskPriority?.Color}>
          {parsedTaskPriority?.Title || "Sin  prioridad"}
        </StyledTag>
        <PriorityPopover
          IdTaskTemplate={task.IdTaskTemplate}
          taskPriorityId={parsedTaskPriority?.IdTaskPriority}
          onChangePriority={onChangePriority}
          open={open}
          setOpen={setOpen}
        >
          <div>
            <EditIcon filled />
          </div>
        </PriorityPopover>
      </Space>
      <div>
        <StyledTitle>Encargados</StyledTitle>
        <GeestSelect
          // @ts-ignore
          value={_.map(task.UsersExecutors, _.toString)}
          multiselect
          showTags
          options={usersOptions}
          placeholderSelect="Selecciona una opción..."
          onChange={onChangeExecutors}
        />
        {shouldShowUsersMode(task.UsersExecutors) && (
          <StyledCheckbox
            checked={task.ExecutionMode}
            onChange={onChangeExecutionMode}
          >
            Todos deben terminar la tarea
          </StyledCheckbox>
        )}
      </div>
      <div>
        <StyledTitle>Revisores</StyledTitle>
        <GeestSelect
          // @ts-ignore
          value={_.map(task.UsersRevisers, _.toString)}
          multiselect
          showTags
          options={usersOptions}
          placeholderSelect="Selecciona una opción..."
          onChange={onChangeRevisers}
        />
        {shouldShowUsersMode(task.UsersRevisers) && (
          <StyledCheckbox
            checked={task.RevisionMode}
            onChange={onChangeRevisionMode}
          >
            Todos deben revisar la tarea
          </StyledCheckbox>
        )}
      </div>
      <div>
        <Space align="center">
          <StyledTitle>Reportar ingresos</StyledTitle>
          <StyledSwitch
            checked={showReportIncome}
            onChange={onChangeShowReportIncome}
          />
        </Space>
        <StyledLabel>
          {showReportIncome
            ? "Reportar ingresos al terminar tarea"
            : "Esta tarea no implica un ingreso"}
        </StyledLabel>
      </div>
      {showReportIncome && (
        <div>
          <StyledTitle>
            Selecciona el campo del cual se tomará el monto:
          </StyledTitle>
          <GeestSelect
            value={task.ReportIncomeField}
            options={reportIncomeOptions}
            onChange={onChangeReportIncomeField}
            placeholderSelect="Selecciona una opción..."
          />
        </div>
      )}
    </Space>
  );
};

export default GeneralPage;
