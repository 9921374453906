import React, { useRef, useState } from "react";
import GeestDB from "../../../resources/img/Sidebar/GeestDB.svg";
import { Languages } from "./Dictionary";
import { ContextMenuOptionContainer, NewCard, NewCardTitle } from "./Styles";
import { AiOutlineFolder } from "react-icons/ai";
import ContextMenu from "../../../components/ContextMenu";
import { HiOutlinePlus } from "react-icons/hi";

interface NewDBCardProps {
  onCreateDB: () => void;
  onCreateFolder: () => void;
}

const NewDBCard: React.FC<NewDBCardProps> = ({
  onCreateDB,
  onCreateFolder,
}) => {
  const [showContext, setShowContext] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const { CreateLabel, DBLabel, FolderLabel } = Languages["ESP"];

  const getPoints = () => {
    if (!ref.current) {
      return { x: 0, y: 0 };
    }

    const { x, y } = ref.current?.getBoundingClientRect();
    return { x: x + 285, y: y + 20 };
  };

  const contextOptions = [
    {
      label: (
        <ContextMenuOptionContainer>
          <img
            src={GeestDB}
            alt="process"
            style={{
              width: "14px",
              height: "14px",
            }}
          />
          {DBLabel}
        </ContextMenuOptionContainer>
      ),
      func: () => {
        onCreateDB();
        setShowContext(false);
      },
    },
    {
      label: (
        <ContextMenuOptionContainer>
          <AiOutlineFolder size={20} />
          {FolderLabel}
        </ContextMenuOptionContainer>
      ),
      func: () => {
        onCreateFolder();
        setShowContext(false);
      },
    },
  ];

  return (
    <ContextMenu
      isOpen={showContext}
      width={287}
      closeMenu={() => setShowContext(false)}
      points={getPoints()}
      options={contextOptions}
      placeDown
    >
      <NewCard
        tabIndex={0}
        onClick={() => setShowContext(true)}
        onKeyDown={({ key }) => {
          if (key === "Enter") setShowContext(true);
        }}
        ref={ref}
      >
        <HiOutlinePlus size={20} />
        <NewCardTitle>{CreateLabel}</NewCardTitle>
      </NewCard>
    </ContextMenu>
  );
};

export default NewDBCard;
