import React from "react";
import { IconContainer } from "./styles";
import { IoClose } from "react-icons/io5";

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 15 | 20 | 25;
  color?: string;
  hoverContainerColor?: string;
}

const CloseIcon: React.FC<IconProps> = ({
  size = 20,
  color = "#48505E",
  hoverContainerColor = "#fff1f0",
  ...props
}) => (
  <IconContainer
    size={size}
    style={{ paddingLeft: "0.5px" }}
    hoverContainerColor={hoverContainerColor}
    {...props}
  >
    <IoClose color={color} size={size} />
  </IconContainer>
);

export default CloseIcon;
