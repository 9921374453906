import React, { useRef } from "react";
import styled from "styled-components";
import { Popover } from "@material-ui/core";
import { GoTriangleDown } from "react-icons/go";
import ExportIcon from "../../../../components/hoverIcons/ExportIcon";

const OptionsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #0273e9;
  border: 2px solid #0273e9;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    color: #014c9b;
    border: 2px solid #014c9b;
  }
  :focus {
    color: #1939b7;
    border: 2px solid #1939b7;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 10px;
  color: #828d9e;
  user-select: none;
  cursor: pointer;
  transition: color 0.35s ease;
  :hover {
    background-color: #edecec;
  }
`;

interface ReportOptionsModalProps {
  open: boolean;
  onOpen: () => void;
  onCancel: () => void;
  handleExport: () => void;
}

const ReportOptionsModal: React.FC<ReportOptionsModalProps> = ({
  open,
  onOpen,
  onCancel,
  handleExport,
}) => {
  const popoverRef = useRef(null);

  return (
    <>
      <Popover
        open={open}
        anchorEl={popoverRef.current}
        onClose={() => onCancel()}
        PaperProps={{
          style: {
            borderRadius: "10px",
            padding: "5px 0",
            width: "167px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <OptionContainer onClick={handleExport}>
          <ExportIcon />
          <span>Exportar</span>
        </OptionContainer>
      </Popover>
      <OptionsButton ref={popoverRef} onClick={onOpen}>
        <GoTriangleDown size={12} />
      </OptionsButton>
    </>
  );
};

export default ReportOptionsModal;
