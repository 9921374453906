import React, { useState } from "react";
import _ from "lodash";
import { Languages } from "./Dictionary";
import { TeamMember, UserTask } from "../Dashboard.d";
import {
  TeamMembersInformationContainer,
  CardTitle,
  HeadersContainer,
  HeaderItem,
  TeamMembersBox,
  TeamMemberContainer,
  TeamMemberData,
  TasksInfoContainer,
  UserContainer,
  MemberDataButton,
  StartedButton,
  StuckButton,
  WaitingButton,
  CompletedButton,
} from "./Styles";
import User from "../../SharedComponents/User";
import UserTasksInfo from "./UserTasksInfo";

const {
  TeamMembersTitle,
  HeaderTasksLabel,
  HeaderStartedLabel,
  HeaderStuckLabel,
  HeaderWaitingLabel,
  HeaderFinishedLabel,
  StartedDateLabel,
  StuckDateLabel,
  WaitingDateLabel,
  FinishedDateLabel,
} = Languages["ESP"];

interface TeamMembersInformationProps {
  members: TeamMember[];
  handleGetUserTasks: (idUser: number, status: number) => void;
  userTasks: UserTask[];
  setProcessToOpen: (id: number) => void;
  openMultiModal: () => void;
  setUserTasksStatus: (args: any) => void;
}

interface SelectedReport {
  idUser: number;
  status: string;
}

const TeamMembersInformation: React.FC<TeamMembersInformationProps> = ({
  members,
  handleGetUserTasks,
  userTasks,
  setProcessToOpen,
  openMultiModal,
  setUserTasksStatus,
}) => {
  const [selectedReport, setSelectedReport] = useState<SelectedReport | null>(
    null
  );
  const [titleLabel, setTitleLabel] = useState<string>("");
  const [titleColor, setTitleColor] = useState<string>("");
  const [dateLabel, setDateLabel] = useState<string>("");

  const handleSelectReport = (report: SelectedReport): void => {
    if (_.isEqual(report, selectedReport)) {
      setSelectedReport(null);
      return;
    }
    setSelectedReport(report);
    switch (report.status) {
      case "started":
        handleGetUserTasks(report.idUser, 1);
        setTitleLabel(HeaderStartedLabel);
        setTitleColor("#08979c");
        setDateLabel(StartedDateLabel);
        break;
      case "stuck":
        handleGetUserTasks(report.idUser, 5);
        setTitleLabel(HeaderStuckLabel);
        setTitleColor("#e11909");
        setDateLabel(StuckDateLabel);
        break;
      case "waiting":
        handleGetUserTasks(report.idUser, 4);
        setTitleLabel(HeaderWaitingLabel);
        setTitleColor("#d46b08");
        setDateLabel(WaitingDateLabel);
        break;
      case "completed":
        handleGetUserTasks(report.idUser, 2);
        setTitleLabel(HeaderFinishedLabel);
        setTitleColor("#389e0d");
        setDateLabel(FinishedDateLabel);
        break;
    }
  };

  const isSelected = (report: SelectedReport): boolean => {
    return _.isEqual(report, selectedReport);
  };

  const showTaskData = (idUser: number) => {
    return _.isEqual(idUser, selectedReport?.idUser);
  };

  const getUserSize = (member: TeamMember): string => {
    const fullname = `${member.FirstName} ${member.LastName}`;
    if (fullname.length > 18 || member.Email.length > 18) return "middle";
    return "large";
  };

  return (
    <TeamMembersInformationContainer>
      <CardTitle>{TeamMembersTitle}</CardTitle>
      <HeadersContainer>
        <HeaderItem $flex={2}>{HeaderTasksLabel}</HeaderItem>
        <HeaderItem $center>{HeaderStartedLabel}</HeaderItem>
        <HeaderItem $center>{HeaderStuckLabel}</HeaderItem>
        <HeaderItem $center>{HeaderWaitingLabel}</HeaderItem>
        <HeaderItem $center>{HeaderFinishedLabel}</HeaderItem>
      </HeadersContainer>

      <TeamMembersBox>
        {(members || []).map((member) => {
          const { Report } = member;

          return (
            <TeamMemberContainer key={member.IdUser}>
              <TeamMemberData>
                <UserContainer>
                  <User
                    user={member}
                    size={getUserSize(member)}
                    justify="start"
                  />
                </UserContainer>
                {Report && Report.StartedTasks > 0 ? (
                  <StartedButton
                    onClick={() =>
                      handleSelectReport({
                        idUser: member.IdUser,
                        status: "started",
                      })
                    }
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (key === "Enter")
                        handleSelectReport({
                          idUser: member.IdUser,
                          status: "started",
                        });
                    }}
                    $selected={isSelected({
                      idUser: member.IdUser,
                      status: "started",
                    })}
                  >
                    {Report.StartedTasks}
                  </StartedButton>
                ) : (
                  <MemberDataButton
                    onClick={() => {
                      setSelectedReport(null);
                      setUserTasksStatus(null);
                    }}
                  >
                    {Report?.StartedTasks}
                  </MemberDataButton>
                )}

                {Report && Report.StuckTasks > 0 ? (
                  <StuckButton
                    onClick={() =>
                      handleSelectReport({
                        idUser: member.IdUser,
                        status: "stuck",
                      })
                    }
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (key === "Enter")
                        handleSelectReport({
                          idUser: member.IdUser,
                          status: "stuck",
                        });
                    }}
                    $selected={isSelected({
                      idUser: member.IdUser,
                      status: "stuck",
                    })}
                  >
                    {Report.StuckTasks}
                  </StuckButton>
                ) : (
                  <MemberDataButton
                    onClick={() => {
                      setSelectedReport(null);
                      setUserTasksStatus(null);
                    }}
                  >
                    {Report?.StuckTasks}
                  </MemberDataButton>
                )}

                {Report && Report.WaitingTasks > 0 ? (
                  <WaitingButton
                    onClick={() =>
                      handleSelectReport({
                        idUser: member.IdUser,
                        status: "waiting",
                      })
                    }
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (key === "Enter")
                        handleSelectReport({
                          idUser: member.IdUser,
                          status: "waiting",
                        });
                    }}
                    $selected={isSelected({
                      idUser: member.IdUser,
                      status: "waiting",
                    })}
                  >
                    {Report.WaitingTasks}
                  </WaitingButton>
                ) : (
                  <MemberDataButton onClick={() => setSelectedReport(null)}>
                    {Report?.WaitingTasks}
                  </MemberDataButton>
                )}

                {Report && Report.CompletedTasks > 0 ? (
                  <CompletedButton
                    onClick={() =>
                      handleSelectReport({
                        idUser: member.IdUser,
                        status: "completed",
                      })
                    }
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (key === "Enter")
                        handleSelectReport({
                          idUser: member.IdUser,
                          status: "completed",
                        });
                    }}
                    $selected={isSelected({
                      idUser: member.IdUser,
                      status: "completed",
                    })}
                  >
                    {Report.CompletedTasks}
                  </CompletedButton>
                ) : (
                  <MemberDataButton
                    onClick={() => {
                      setSelectedReport(null);
                      setUserTasksStatus(null);
                    }}
                  >
                    {Report?.CompletedTasks}
                  </MemberDataButton>
                )}
              </TeamMemberData>
              <TasksInfoContainer $selected={showTaskData(member.IdUser)}>
                {selectedReport && (
                  <UserTasksInfo
                    userTasks={userTasks}
                    titleLabel={titleLabel}
                    titleColor={titleColor}
                    dateLabel={dateLabel}
                    setProcessToOpen={setProcessToOpen}
                    openMultiModal={openMultiModal}
                  />
                )}
              </TasksInfoContainer>
            </TeamMemberContainer>
          );
        })}
      </TeamMembersBox>
    </TeamMembersInformationContainer>
  );
};

export default TeamMembersInformation;
