import React, { useContext, useEffect, useState } from "react";
import { Collapse, Input, Row } from "antd";
import moment from "moment";
import _, { noop as NOOP } from "lodash";
import Modal from "../../../../components/Modal";
import { useFetch } from "../../../../hooks";
import { Panel } from "./styles";
import DynamicTable from "../../../../components/DynamicTable";
import Button from "../../../../components/Button";
import { useMediaQuery } from "@material-ui/core";
import { device } from "../../../../constants/css";
import useMutation from "../../../../hooks/useMutation";
import { MessagesContext } from "../../../../components/AppMessages";

const isNumber = /^-?[0-9]*(\.[0-9]*)?$/;

interface GoalsProps {
  visible: boolean;
  onSuccess: () => void;
  onClose?: () => void;
  IdTeam: number;
  IdProcessTemplate: number;
}

interface Goal {
  IdGoal: number;
  Date: string;
  Value: number;
}

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const encodeDate = (year: number, month: number): string => {
  let result = year + "-";
  if (month + 1 < 10) {
    result += "0" + (month + 1);
  } else result += month + 1;
  result += "-01 00:00:00.000000";
  return result;
};

const Goals: React.FC<GoalsProps> = ({
  visible,
  onSuccess,
  onClose,
  IdTeam,
  IdProcessTemplate,
}) => {
  const { showMessage } = useContext(MessagesContext);
  const isMobile = !useMediaQuery(device.tablet);
  const [goals, setGoals] = useState<
    { IdGoal: number; Date: string; Value: string }[]
  >([]);

  const { loading, reload } = useFetch<Goal[]>({
    func: "Ver2-Statistics-gpg",
    args: {
      IdTeam,
      IdProcessTemplate,
    },
    conf: { skip: !IdTeam || !IdProcessTemplate },
    onSuccess: (response) =>
      setGoals(
        response.map((goal) => ({
          ...goal,
          Value: String(goal.Value),
        }))
      ),
  });

  const [registerProcessGoals, submitting] = useMutation<[]>({
    func: "Ver2-Statistics-rpg",
    onSuccess: () => {
      showMessage("Metas actualizadas", "success");
      onSuccess();
    },
  });

  const actualMonth = moment().month();
  const actualYear = moment().year();
  const nextYear = moment().add(1, "y").year();

  useEffect(() => {
    if (visible) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onChangeGoal = (date: string, value: string): void => {
    value = value.replaceAll(",", "");
    if (isNumber.test(value) || value === "") {
      const existent = _.findIndex(goals, { Date: date });
      if (existent >= 0) {
        let newGoals = [...goals];
        newGoals[existent].Value = value;
        setGoals(newGoals);
      } else {
        setGoals([
          ...goals,
          {
            IdGoal: 0,
            Date: date,
            Value: value,
          },
        ]);
      }
    }
  };

  const columns = [
    {
      title: "Mes",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Meta total",
      dataIndex: "goal",
      key: "goal",
      render: (
        _index: number,
        item: { month: string; goal: string; year: number; key: number }
      ) => (
        <Input
          value={item.goal.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          onChange={(e) =>
            onChangeGoal(encodeDate(item.year, item.key), e.target.value)
          }
          bordered={false}
          prefix="$"
          size="small"
          disabled={submitting}
        />
      ),
    },
  ];

  const actualYearData = months.map((month, index) => ({
    year: actualYear,
    month,
    goal: _.find(goals, { Date: encodeDate(actualYear, index) })?.Value ?? "0",
  }));

  const nextYearData = months.slice(0, actualMonth + 1).map((month, index) => ({
    year: nextYear,
    month,
    goal: _.find(goals, { Date: encodeDate(nextYear, index) })?.Value ?? "0",
  }));

  const onSubmit = (): void => {
    const args = {
      IdTeam,
      IdProcessTemplate,
      Goals: _.map(goals, (goal) => ({
        ...goal,
        Value: Number(goal.Value),
      })),
    };
    registerProcessGoals({ args });
  };

  return (
    <Modal
      title="Metas"
      visible={visible}
      onCancel={submitting ? NOOP : onClose}
      width={isMobile ? "90%" : "30%"}
    >
      {loading ? (
        <div>Cargando...</div>
      ) : (
        <Collapse defaultActiveKey={[1, 2]}>
          <Panel header={actualYear} key="1">
            <DynamicTable
              columns={columns}
              data={actualYearData}
              size="small"
            />
          </Panel>
          <Panel header={nextYear} key="2">
            <DynamicTable columns={columns} data={nextYearData} size="small" />
          </Panel>
        </Collapse>
      )}
      <Row justify="space-between" style={{ marginTop: "1rem" }}>
        <Button
          style={{ width: "calc(50% - 0.5rem)" }}
          loading={loading || submitting}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          type="primary"
          style={{ width: "calc(50% - 0.5rem)" }}
          loading={loading || submitting}
          onClick={onSubmit}
        >
          Aceptar
        </Button>
      </Row>
    </Modal>
  );
};

export default Goals;
