import styled from "styled-components";
import { Row } from "antd";

export const Header = styled(Row)`
  font-size: 22px;
  margin-bottom: 1.5rem;
  .ant-switch-checked {
    background: ${({ $planColor }) => $planColor};
  }
`;

export const Content = styled.div`
  min-height: 20rem;
  padding: 0 2rem;
  padding-bottom: 1.5rem;
`;

export const ModeLabel = styled.div`
  cursor: pointer;
  user-select: none;
  color: ${({ active }) => (active ? "inherit" : "#bbbbbb")};
  margin-top: 2px;
`;
