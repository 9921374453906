import React from "react";
import {
  ButtonsRow,
  Content,
  Description,
  Header,
  StyledModal,
  Title,
} from "./Styles";
import { CloseIcon } from "../../../../../../../components/hoverIcons";
import { Button } from "../../../../../../../GeestUI";

interface DeleteColumnModalProps {
  closeModal: () => void;
  onRemoveStat: () => void;
}

const DeleteColumnModal: React.FC<DeleteColumnModalProps> = ({
  closeModal,
  onRemoveStat,
}) => {
  return (
    <StyledModal
      open
      footer={null}
      closable={false}
      centered
      width="432px"
      onCancel={closeModal}
      bodyStyle={{
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Gotham-Book",
        padding: "12px",
      }}
    >
      <Header>
        <Title>Eliminar</Title>
        <CloseIcon size={20} onClick={closeModal} color="#48505e" />
      </Header>

      <Content>
        <Description>
          <p>¿Estás seguro que deseas eliminar esta columna?</p>
        </Description>

        <ButtonsRow>
          <Button type="secondary" onClick={closeModal}>
            Cancelar
          </Button>
          <Button type="primary" danger onClick={onRemoveStat}>
            Eliminar
          </Button>
        </ButtonsRow>
      </Content>
    </StyledModal>
  );
};

export default DeleteColumnModal;
