export const Languages = {
  ENG: {
    ProcessDetailsTooltipLabel: "Follow up details",
    ResponsiblesTooltipLabel: " Follow up responsibles",
    AlertsTooltipLabel: "Follow up alerts",
    FlowTooltipLabel: "Follow up flux",
    FormFieldsTooltipLabel: "Follow up fields",
    loadingLabel: "loading...",
  },
  ESP: {
    ProcessDetailsTooltipLabel: "Ver detalle de seguimiento",
    ResponsiblesTooltipLabel: " Ver responsables de seguimiento",
    AlertsTooltipLabel: "Ver alertas de seguimiento",
    FlowTooltipLabel: "Ver flujo de seguimiento",
    FormFieldsTooltipLabel: "Ver campos del formulario",
    loadingLabel: "cargando...",
  },
};
