import React from "react";
import styled from "styled-components";
import DynamicInput from "../../../../../components/DynamicInput";
import { FileValueType } from "../DynamicCell.d";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Gotham-Bold;
  color: #0067b0;
  max-width: 250px;
`;

interface FileComponentProps {
  filesValue: FileValueType[];
}

const FileComponent: React.FC<FileComponentProps> = ({ filesValue }) => {
  return (
    <Container>
      <DynamicInput
        value={filesValue}
        type="file"
        dataOrigin=""
        format=""
        configuration=""
        fieldName=""
        isConsult={true}
        disabled={true}
        required={false}
        onChange={() => null}
      />
    </Container>
  );
};

export default FileComponent;
