export enum CustomNodeTypes {
  START_NODE = "START_NODE",
  TASK_NODE = "TASK_NODE",
  CONDITIONAL_NODE = "CONDITIONAL_NODE",
  AUTOMATION_NODE = "AUTOMATION_NODE",
  SUBFLUX_NODE = "SUBFLUX_NODE",
  END_NODE = "END_NODE",
}

export enum HandlerId {
  IN_TOP = "InTop",
  IN_RIGHT = "InRight",
  IN_BOTTOM = "InBottom",
  IN_LEFT = "InLeft",
  OUT_TOP = "OutTop",
  OUT_RIGHT = "OutRight",
  OUT_BOTTOM = "OutBottom",
  OUT_LEFT = "OutLeft",
}

export interface Handler {
  id: string;
  type: "target" | "source";
  position: Position;
  isConnectableStart?: boolean;
}
