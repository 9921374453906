import React, { useContext } from "react";
import { Divider } from "antd";
import { noop as NOOP } from "lodash";
import { getPlanColors } from "../../../helpers";
import { Card, Title, H3, H4, H6, Bold, Button } from "./styles";
import { moneyFormat } from "../../../../../Helpers";
import { LoadingWrapperContext } from "../../../LoadingWrapper";

const Details = ({
  information = {},
  planSelected = {},
  openModal = NOOP,
  onSubmit = NOOP,
  planModes = {},
}) => {
  const { HardLoading } = useContext(LoadingWrapperContext);

  const { Name: currentPlanName, FullEndTime } = information;
  const {
    plan: { Name: selectedPlanName, CostMonthly, CostAnnually },
    mode,
  } = planSelected;
  const currentPlanColors = getPlanColors(currentPlanName);
  const selectedPlanColors = getPlanColors(selectedPlanName);

  const planModeLabels = {
    [planModes.monthly]: "mensual",
    [planModes.annually]: "anual",
  };
  const planCost = mode === planModes.monthly ? CostMonthly : CostAnnually;

  return (
    <Card planColor={currentPlanColors.mainColor}>
      <Title planColor={currentPlanColors.mainColor}>{currentPlanName}</Title>
      <H3>
        Tu prueba gratuita finalizará el <Bold>{FullEndTime}.</Bold>
      </H3>
      <H4>Cuando finalice mi prueba quiero contratar:</H4>
      <Button
        onClick={openModal}
        block
        type="shadow"
        $planColor={selectedPlanColors.mainColor}
        loading={HardLoading}
      >
        Plan {selectedPlanName}
      </Button>
      <H3 style={{ color: "#b8b8b8" }}>
        A partir de ese día se te hará tu primer cargo {planModeLabels[mode]}{" "}
        por:{" "}
        <Bold>
          {moneyFormat(planCost)} USD
          <span style={{ color: "red" }}>*</span>
        </Bold>
      </H3>
      <Divider />
      <H4 style={{ color: currentPlanColors.mainColor }}>
        <Bold>No se te hará ningun cargo</Bold> mientras estés en el periodo de
        prueba gratis o si cancelas tu prueba antes del {FullEndTime}.
      </H4>
      <div style={{ margin: "0 2rem" }}>
        <Button onClick={onSubmit} type="secondary" block loading={HardLoading}>
          Extender prueba gratis
        </Button>
      </div>
      <H6>
        <span style={{ color: "red" }}>*</span>Incluye IVA
      </H6>
    </Card>
  );
};

export default Details;
