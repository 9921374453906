import React from "react";
import _ from "lodash";
import { Row, Space } from "antd";
import { Typography } from "../../../../../GeestUI";
import { Languages } from "../Dictionary";
import {
  CloseIcon,
  StatusContainer,
  PopoverContentWrapper,
  StyledTag,
} from "../Styles";
import { Status } from "../../Dashboard.d";

const { H3 } = Typography;
const { StatusLabel } = Languages["ESP"];

interface StatusPopoverProps {
  value: Status[];
  statusCollection: Status[];
  getStatusInfo: (status: string) => {
    color: string;
    label: string;
  };
  onChange: (oldStatus: Status[], newStatus: Status[]) => void;
  onClose: () => void;
}

const StatusPopover: React.FC<StatusPopoverProps> = ({
  value,
  statusCollection,
  getStatusInfo,
  onChange,
  onClose,
}) => {
  const onSelectStatus = (status: Status): void => {
    const existentStatus = !!_.find(value, { Value: status.Value });
    if (existentStatus) {
      // Remove status
      onChange(value, _.reject(value, { Value: status.Value }));
    } else {
      // Add status
      onChange(value, [...value, status]);
    }
  };

  return (
    <PopoverContentWrapper>
      <Row>
        <H3 mb="0" style={{ flex: 1 }}>
          {StatusLabel}
        </H3>
        <CloseIcon onClick={onClose} />
      </Row>
      <Space direction="vertical" style={{ width: "100%" }} size={2}>
        {(statusCollection || []).map((status) => (
          <div key={status.Value}>
            <StatusContainer
              justify="space-between"
              $selected={!!_.find(value, { Value: status.Value })}
              tabIndex={0}
              onClick={() => onSelectStatus(status)}
              onKeyDown={({ key }) => {
                if (key === "Enter") onSelectStatus(status);
              }}
            >
              <StyledTag color={getStatusInfo(status.Label).color}>
                {getStatusInfo(status.Label).label}
              </StyledTag>
            </StatusContainer>
          </div>
        ))}
      </Space>
    </PopoverContentWrapper>
  );
};

export default StatusPopover;
