import React, { useState } from "react";
import { ControlWrapper, PlaygroundContainer, TabContainer } from "./Styles";
import Playground from "./Playground";
import Documentation from "./Documentation";

interface TextEditorProps {}

const VIEWS = {
  PLAYGROUND: 0,
  DOCUMENTATION: 1,
};

const TextEditor: React.FC<TextEditorProps> = () => {
  const [view, setView] = useState<number>(VIEWS.PLAYGROUND);

  return (
    <PlaygroundContainer>
      <ControlWrapper>
        <TabContainer
          $selected={view === VIEWS.PLAYGROUND}
          onClick={() => setView(VIEWS.PLAYGROUND)}
        >
          <p>playground</p>
        </TabContainer>
        <TabContainer
          $selected={view === VIEWS.DOCUMENTATION}
          onClick={() => setView(VIEWS.DOCUMENTATION)}
        >
          <p>documentation</p>
        </TabContainer>
      </ControlWrapper>
      {view === VIEWS.PLAYGROUND && <Playground />}
      {view === VIEWS.DOCUMENTATION && <Documentation />}
    </PlaygroundContainer>
  );
};

export default TextEditor;
