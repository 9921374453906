import React from "react";
import { BsSortAlphaDown, BsSortAlphaUp } from "react-icons/bs";

interface OrderIconProps {
  Direction: "ASC" | "asc" | "DESC" | "desc" | undefined;
}

const OrderIcon: React.FC<OrderIconProps> = ({ Direction }) => {
  if (!Direction) {
    return <></>;
  }
  if (Direction.toUpperCase() === "ASC") {
    return (
      <BsSortAlphaDown
        color="rgba(2, 115, 233, 1)"
        size={20}
        style={{ marginLeft: "5px" }}
      />
    );
  }
  return (
    <BsSortAlphaUp
      color="rgba(2, 115, 233, 1)"
      size={20}
      style={{ marginLeft: "5px" }}
    />
  );
};

export default OrderIcon;
