import styled from "styled-components";

export const Title = styled.div`
  margin-bottom: 0.5rem;
  font-size: 16px;
  line-height: 20px;
`;

export const Message = styled.div`
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
`;
