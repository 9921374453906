import styled from "styled-components";
import processBlue from "../../../resources/img/processBlue.svg";
import processGrey from "../../../resources/img/processGrey.svg";

export const NestedFolder = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  align-items: center;
  cursor: pointer;
  color: rgba(130, 141, 158, 1);
  margin-right: 5px;
  font-family: "Gotham-Bold";
  :hover {
    color: rgba(1, 76, 155, 1);
  }
`;

export const SelectedFolderContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  align-items: center;
  color: rgba(72, 80, 94, 1);
  font-family: "Gotham-Bold";
`;

export const ProcessTeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  align-items: center;
  cursor: pointer;
  color: rgba(130, 141, 158, 1);
  font-family: "Gotham-Bold";
  margin-right: 5px;
  background-image: url(${processGrey});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-left: 25px;
  :hover {
    color: rgba(1, 76, 155, 1);
    background-image: url(${processBlue});
  }
`;
