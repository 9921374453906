import React, { useState } from "react";
import DynamicInput from "../../../../../../../components/DynamicInput";
import { FieldConfigWrapper, SubTitle } from "../../Styles";

interface NumberConfigProps {
  value: string;
  onChange: (newValue: string) => void;
  disabled: boolean;
}

const NumberConfig: React.FC<NumberConfigProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const [localValue, setLocalValue] = useState<string>(value);

  return (
    <>
      <FieldConfigWrapper>
        <SubTitle>Valor por defecto</SubTitle>
        <DynamicInput
          value={localValue ?? ""}
          type="number"
          dataOrigin={[]}
          format=""
          configuration=""
          fieldName=""
          isConsult={false}
          disabled={disabled}
          required={false}
          onChange={setLocalValue}
          onBlur={() => onChange(localValue)}
        />
      </FieldConfigWrapper>
    </>
  );
};

export default NumberConfig;
