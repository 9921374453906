import React, { memo } from "react";
import {
  CellTypes,
  DataTypes,
  Header,
  PriorityDataOriginType,
  ReportCell,
  RowData,
  StageDataOriginType,
  StatusDataOriginType,
  User,
} from "../PipelineTypes.d";
import {
  PipelineTableRow,
  PipelineTableBodyCell,
  PipelineTableBodyCellContent,
  StyledCheckboxContainer,
  StyledCheckbox,
} from "../PipelineStyles";
import DynamicCell from "../DynamicCell";
import classNames from "classnames";

interface TableRowProps {
  idProcessExecution: number;
  onClickRow: () => void;
  headers: Header[];
  row: RowData;
  isFirstColumnFixed: boolean;
  handleSelectProcess: (idProcessExecution: number) => void;
  selectedProcess: number[];
  getCellType: (headerId: string) => CellTypes;
  getCellDataType: (headerId: string) => DataTypes;
  getMinMaxWidth: (
    key: CellTypes,
    dataType: DataTypes | null
  ) => {
    minWidth: string;
    maxWidth: string;
  };
  // Data Origins
  userSelectDataOrigin: User[];
  statusDataOrigin: StatusDataOriginType[];
  stageDataOrigin: StageDataOriginType[];
  priorityDataOrigin: PriorityDataOriginType[];
}

const TableRow: React.FC<TableRowProps> = memo(
  ({
    idProcessExecution,
    onClickRow,
    headers,
    row,
    isFirstColumnFixed,
    handleSelectProcess,
    selectedProcess,
    getCellType,
    getCellDataType,
    getMinMaxWidth,
    // Data Origins
    userSelectDataOrigin,
    statusDataOrigin,
    stageDataOrigin,
    priorityDataOrigin,
  }) => {
    return (
      <PipelineTableRow key={idProcessExecution} onClick={onClickRow}>
        {headers.map((header, idx) => {
          const cell = row.Cells[header.Id as any] as ReportCell;
          const cellId = `${idProcessExecution}-${header.Id}`;
          const { minWidth, maxWidth } = getMinMaxWidth(
            header.Key,
            header.DataType
          );

          return (
            <PipelineTableBodyCell
              key={cellId}
              className={classNames({ fixed: idx === 0 && isFirstColumnFixed })}
              minWidth={minWidth}
              maxWidth={maxWidth}
            >
              <PipelineTableBodyCellContent $hasCheckbox={idx === 0}>
                {idx === 0 && (
                  <StyledCheckboxContainer
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectProcess(idProcessExecution);
                    }}
                  >
                    <StyledCheckbox
                      checked={selectedProcess.includes(idProcessExecution)}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectProcess(idProcessExecution);
                      }}
                    />
                  </StyledCheckboxContainer>
                )}

                <DynamicCell
                  cellType={getCellType(header.Id)}
                  dataType={getCellDataType(header.Id)}
                  stringValue={cell.CellData.String_Value}
                  dateValue={cell.CellData.Date_Value}
                  numValue={cell.CellData.Num_Value}
                  userValue={cell.CellData.User_Value}
                  selectValue={cell.CellData.Select_Value}
                  triggerValue={cell.CellData.Trigger_Value}
                  keyValue={cell.CellData.Key_Value}
                  formatValue={cell.CellData.Format_Value}
                  filesValue={cell.CellData.Files_Value}
                  executorsValue={cell.CellData.Executors_Value}
                  interactionValue={cell.CellData.Interaction_Value}
                  // Data origins
                  UserSelectDataOrigin={userSelectDataOrigin}
                  StatusDataOrigin={statusDataOrigin}
                  StageDataOrigin={stageDataOrigin}
                  PriorityDataOrigin={priorityDataOrigin}
                />
              </PipelineTableBodyCellContent>
            </PipelineTableBodyCell>
          );
        })}
      </PipelineTableRow>
    );
  },
  (prevProps, nextProps) => {
    // Compare props that are relevant to rendering
    if (prevProps.idProcessExecution !== nextProps.idProcessExecution)
      return false;
    if (prevProps.onClickRow !== nextProps.onClickRow) return false;
    if (prevProps.isFirstColumnFixed !== nextProps.isFirstColumnFixed)
      return false;
    if (prevProps.handleSelectProcess !== nextProps.handleSelectProcess)
      return false;
    if (prevProps.selectedProcess !== nextProps.selectedProcess) return false;
    if (prevProps.getCellType !== nextProps.getCellType) return false;
    if (prevProps.getCellDataType !== nextProps.getCellDataType) return false;
    if (prevProps.userSelectDataOrigin !== nextProps.userSelectDataOrigin)
      return false;
    if (prevProps.statusDataOrigin !== nextProps.statusDataOrigin) return false;
    if (prevProps.stageDataOrigin !== nextProps.stageDataOrigin) return false;
    if (prevProps.priorityDataOrigin !== nextProps.priorityDataOrigin)
      return false;

    // Verify if rows have changed
    if (prevProps.row.Cells !== nextProps.row.Cells) return false;

    return true;
  }
);

export default TableRow;
