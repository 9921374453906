import React from "react";
import styled from "styled-components";
import { SelectValueType } from "../DynamicCell.d";

const SelectValue = styled.div`
  font-size: 14px;
  font-family: Gotham-Book;
  color: #48505e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
`;

interface SelectComponentProps {
  selectValue: SelectValueType | undefined | null;
}

const SelectComponent: React.FC<SelectComponentProps> = ({ selectValue }) => {
  if (selectValue) {
    return <SelectValue>{selectValue.String_Value}</SelectValue>;
  }

  return <div />;
};

export default SelectComponent;
