import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 100%;
  max-width: 100%;
  position: relative;
  flex-direction: column;
`;

export const FlowContainer = styled.div`
  flex: 1;
  padding: 5px;
  display: flex;
`;
