import React from "react";
import styled from "styled-components";

const NumberContainer = styled.div`
  font-size: 12px;
  font-family: Gotham-Book;
  color: #0273e9;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
`;

interface NumberComponentProps {
  value: number | undefined | null;
}

const NumberComponent: React.FC<NumberComponentProps> = ({ value }) => {
  const getFormatedNumber = (val: string) =>
    val ? val.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";

  return (
    <NumberContainer>
      {value ? getFormatedNumber(`${value}`) : ""}
    </NumberContainer>
  );
};

export default NumberComponent;
