import React, { useEffect, useState } from "react";
import _ from "lodash";
import DynamicGraph from "../../DynamicGraph";
import { useMutation } from "../../../../hooks";
import {
  DurationsGraphFormat,
  FilterOption,
  FilterBackend,
  GraphType,
  FieldsForGroup,
  GroupByType,
  XAxisValueType,
  GroupByInterface,
} from "../../Dashboards.d";

interface DynamicGraphPreviewProps {
  selectedDashboard: string | null;
  selectedTemplate: number;
  selectedProcess?: number | null;
  selectedProcesses?: string;
  format: DurationsGraphFormat;
  graphName: string;
  rawFilters: FilterOption[];
  groupBy?: GroupByType;
  groupByFromExistendGraph?: GroupByInterface | null;
  xAxisValue?: XAxisValueType;
  fieldsForGroup?: FieldsForGroup;
}

const DynamicGraphPreview: React.FC<DynamicGraphPreviewProps> = ({
  selectedDashboard,
  selectedTemplate,
  selectedProcess,
  selectedProcesses,
  format,
  graphName,
  rawFilters,
  groupBy,
  groupByFromExistendGraph,
  xAxisValue,
  fieldsForGroup,
}) => {
  const [graphData, setGraphData] = useState<GraphType[]>([]);
  const [lastData, setLastData] = useState<any>(null);

  const [getGraphTemplatePreview, loading] = useMutation<GraphType[]>({
    func: "Ver2-DashBoards-gptp",
    onSuccess: (data) => setGraphData(data),
  });

  useEffect(() => {
    let newFilters: FilterBackend[] = _.cloneDeep(rawFilters).map((filter) => {
      let newFilter: FilterBackend = {
        Key: filter.Key,
        Label: filter.Label,
        Value: filter.Value,
      };

      if (filter.Type !== "Static") {
        newFilter.DataType = filter.DataType;
      }

      if (filter.Start) {
        newFilter.Start = filter.Start;
      }
      if (filter.End) {
        newFilter.End = filter.End;
      }

      return newFilter;
    });

    let data: any = {
      args: {
        IdDashBoard: selectedDashboard,
        IdGraphTemplate: selectedTemplate,
        IdProcessTemplate: selectedProcess ?? selectedProcesses ?? "",
        GraphMode: format,
        GraphName: graphName,
        Filters: newFilters,
      },
    };

    if (groupBy) {
      let GroupByForGraph = { Mode: groupBy, Value: "" };
      if (groupBy === "ByProcess") {
        GroupByForGraph.Value = selectedProcesses ?? "";
      }

      if (groupBy === "ByField" && groupByFromExistendGraph) {
        GroupByForGraph.Value = groupByFromExistendGraph.Value;
      }

      if (groupBy === "ByField" && fieldsForGroup) {
        let fields = "";
        let lastFieldIndex = Object.keys(fieldsForGroup).length - 1;
        Object.keys(fieldsForGroup).forEach((process: string, index) => {
          fields += fieldsForGroup[+process];
          if (index !== lastFieldIndex) fields += ",";
        });
        GroupByForGraph.Value = fields;
      }

      data.args.GroupBy = GroupByForGraph;
    }

    if (xAxisValue) {
      data.args.XAxis = { Frequency: xAxisValue };
    }

    setLastData(data);

    if (format !== "" && !_.isEqual(data, lastData)) {
      getGraphTemplatePreview(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFilters, groupBy, xAxisValue, fieldsForGroup, format]);

  return (
    <DynamicGraph
      data={graphData}
      loading={loading}
      selectedTemplate={selectedTemplate}
    />
  );
};

export default DynamicGraphPreview;
