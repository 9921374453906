import React from "react";
import styled from "styled-components";
import Modal from "../../../../components/Modal";
import { Button } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";

const Title = styled.div`
  width: 309px;
  font-size: 14px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  text-align: center;
`;

const Content = styled.div`
  width: 309px;
  font-size: 14px;
  color: rgba(130, 141, 158, 1);
  font-weight: 325;
  font-family: Gotham-Book;
  text-align: center;
`;

const ButtonContainer = styled.div`
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: center;
  display: flex;
  padding-top: 14px;
`;

interface DeleteReportModalProps {
  IdReport: number;
  IdTeam: number;
  onSuccess: () => void;
  onCancel: () => void;
}

const DeleteReportModal: React.FC<DeleteReportModalProps> = ({
  IdReport,
  IdTeam,
  onSuccess,
  onCancel,
}) => {
  const [deletePipelineReport, deleting] = useMutation({
    func: "Ver2-Pipeline-dpr",
    onSuccess: onSuccess,
  });

  const onDelete = () => {
    deletePipelineReport({
      args: {
        IdTeam,
        IdReport,
      },
    });
  };

  return (
    <Modal
      visible
      title="Eliminar"
      width="432px"
      onCancel={onCancel}
      zIndex={9999}
      bodyStyle={{
        borderRadius: "6px",
      }}
      contentStyles={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Title>¿Seguro que deseas eliminar este reporte?</Title>
      <Content>
        Al eliminar este reporte, no se eliminará la información, únicamente los
        filtros y ordenamientos guardados
      </Content>
      <ButtonContainer>
        <Button type="secondary" onClick={onCancel} loading={deleting}>
          Cancelar
        </Button>
        <Button type="primary" danger onClick={onDelete} loading={deleting}>
          Eliminar
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export default DeleteReportModal;
