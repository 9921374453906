import React, { useRef, useState } from "react";
import { Languages } from "./Dictionary";
import {
  Card,
  CardTitle,
  ContextMenuOptionContainer,
  FolderBar,
  FolderCardContainer,
  FolderOptionWrapper,
  SmallFolderBar,
} from "./Styles";
import { FolderType, UserVarDB } from "../DataBases.d";
import ContextMenu from "../../../components/ContextMenu";
import { ThreePointsIcon } from "../../../components/hoverIcons";
import FolderMenu from "./FolderMenu";
import { AiOutlineInfoCircle, AiOutlineRollback } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import Tooltip from "../../../components/Tooltip";

interface FolderCardProps {
  cardKey: string;
  folder: FolderType;
  team: UserVarDB;
  idx: number;
  maxItems: number;
  folders: FolderType[];
  selectedFolder: FolderType;
  nestedFolders: FolderType[];
  onSelectFolder: () => void;
  onDeleteFolder: (IdTeam: number, IdVardbFolder: string) => void;
  onMoveToFolder: (
    IdTeam: number,
    IdVarDB: string | null,
    IdParentFolder: string | null,
    IdVardbFolder: string
  ) => void;
}

const FolderCard: React.FC<FolderCardProps> = ({
  cardKey,
  folder,
  team,
  idx,
  maxItems,
  folders,
  selectedFolder,
  nestedFolders = [],
  onSelectFolder,
  onDeleteFolder,
  onMoveToFolder,
}) => {
  const [openContext, setOpenContext] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const { Title, IdVardbFolder, Color } = folder;
  const { IdTeam, CanEditDatabases } = team;

  const {
    DeleteLabel,
    FolderContentSavedInFolder,
    FolderContentSavedInTeam,
    RemoveFromFolderLabel,
  } = Languages["ESP"];

  const getPoints = () => {
    if (!ref.current) {
      return { x: 0, y: 0 };
    }

    const { x, y } = ref.current?.getBoundingClientRect();
    return { x: x + 285, y: y + 20 };
  };

  const getDeleteTooltipLabel = () => {
    if (selectedFolder) {
      return FolderContentSavedInFolder(selectedFolder.Title);
    }
    return FolderContentSavedInTeam;
  };

  const onRemoveFromFolder = () => {
    let idParentFolder = null;
    if (nestedFolders.length > 1) {
      idParentFolder = nestedFolders[nestedFolders.length - 2].IdVardbFolder;
    }
    onMoveToFolder(IdTeam, null, idParentFolder, IdVardbFolder);
  };

  const getContextOptions = () => {
    let options: any[] = [];

    if (CanEditDatabases) {
      options.push({
        label: (
          <FolderMenu
            folders={folders}
            onMove={(idParentFolder) =>
              onMoveToFolder(IdTeam, null, idParentFolder, IdVardbFolder)
            }
            IdVardbFolder={IdVardbFolder}
            disabled={folders.length < 2}
          />
        ),
        func: () => {},
        disabled: folders.length === 0,
      });
      if (Boolean(selectedFolder)) {
        options.push({
          label: (
            <ContextMenuOptionContainer>
              <AiOutlineRollback size={20} />
              {RemoveFromFolderLabel}
            </ContextMenuOptionContainer>
          ),
          func: onRemoveFromFolder,
        });
      }
      options.push({
        label: (
          <FolderOptionWrapper>
            <ContextMenuOptionContainer>
              <FiTrash size={20} />
              {DeleteLabel}
            </ContextMenuOptionContainer>

            <Tooltip title={getDeleteTooltipLabel()} placement="right">
              <AiOutlineInfoCircle size={20} />
            </Tooltip>
          </FolderOptionWrapper>
        ),
        func: () => {
          onDeleteFolder(IdTeam, IdVardbFolder);
          setOpenContext(false);
        },
        hoverBackground: "#db232c",
      });
    }

    return options;
  };

  const canOpenContext =
    getContextOptions().length > 0 && team.CanEditDatabases;

  return (
    <ContextMenu
      isOpen={openContext}
      width={282}
      closeMenu={() => setOpenContext(false)}
      options={getContextOptions()}
      points={getPoints()}
      placeDown
    >
      <FolderCardContainer
        key={cardKey}
        $lastItem={(idx + 1) % maxItems === 0}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSelectFolder();
        }}
      >
        <SmallFolderBar $color={Color} />
        <FolderBar $color={Color} />

        <Card
          tabIndex={0}
          onKeyDown={({ key }) => {
            if (key === "Enter") onSelectFolder();
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenContext(canOpenContext);
          }}
        >
          <CardTitle>
            <div className="title">
              {Title.length > 16 ? (
                <Tooltip title={Title} placement="top">
                  <p>{Title}</p>
                </Tooltip>
              ) : (
                <p>{Title}</p>
              )}
            </div>

            {canOpenContext && (
              <ThreePointsIcon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenContext(true);
                }}
              />
            )}
          </CardTitle>
        </Card>
      </FolderCardContainer>
    </ContextMenu>
  );
};

export default FolderCard;
