/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import {
  ArticleWrapper,
  IconWrapper,
  SectionWrapper,
  SnippetWrapper,
  StyledLink,
} from "../Styles";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import GeestTextEditor from "../../../../../components/GeestTextEditor";
import { EditorState } from "draft-js";
import { AiOutlineCopy } from "react-icons/ai";
import {
  alignExtraParms,
  blockExtraParams,
  colorExtraParms,
  defaultExtraParams,
  fieldExtraParms,
  fontFamilyExtraParams,
  fontSizeExtraParams,
  inlineExtraParams,
  linkExtraParms,
  listExtraParams,
} from "./Snippets/ToolbarAdvanceSnippets";

interface ToolbarAdvanceLinkProps {
  isActive: () => boolean;
  setSection: () => void;
}

const SectionName = "Toolbar avanzada";

export const ToolbarAdvanceLink: React.FC<ToolbarAdvanceLinkProps> = ({
  isActive,
  setSection,
}) => {
  return (
    <StyledLink onClick={setSection} $isActive={isActive()}>
      {SectionName}
    </StyledLink>
  );
};

interface ToolbarAdvanceSectionProps {
  handleCopy: (element: string) => void;
}

export const ToolbarAdvanceSection: React.FC<ToolbarAdvanceSectionProps> = ({
  handleCopy,
}) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  return (
    <SectionWrapper>
      <h2>{SectionName}</h2>
      <ArticleWrapper>
        <p>
          Si configurar las secciones de controladores como un todo, no es
          suficiente para el caso de uso particular que deseas dar al editor,
          existe una propiedad llamada{" "}
          <code className="code">toolbarExtraParams</code> con la cual puedes
          elegir de manera mucho más específica los controles que deseas
          aparezcan en la toolbar.
        </p>
        <h3>
          toolbarExtraParams:
          <span className="info type">obj</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Al igual que <code className="code">toolbarConfig</code>, el objeto{" "}
          <code className="code">toolbarExtraParams</code> tiene una
          configuración por defecto:
        </p>
        <p className="note">
          Sé que muchos de los tipos concretos no están especificados en este
          snippet, pero los veremos con detalle uno por uno más adelante.
        </p>
        <SnippetWrapper>
          <SyntaxHighlighter
            children={defaultExtraParams}
            language="typescript"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <h3>
          inlineOptions:
          <span className="info type">array</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad acepta un arreglo con cualquiera de los siguientes
          valores: <code className="code">"bold"</code>,{" "}
          <code className="code">"italic"</code>,{" "}
          <code className="code">"underline"</code>,{" "}
          <code className="code">"strikethrough"</code>,{" "}
          <code className="code">"monospace"</code>,{" "}
          <code className="code">"superscript"</code> y{" "}
          <code className="code">"subscript"</code>. Dependiendo de cuales
          agregues y en que orden, será lo que se renderice.
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(inlineExtraParams)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={inlineExtraParams}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarExtraParams={{ inlineOptions: ["italic", "bold"] }}
          toolbarConfig={{
            fontSize: false,
            fontFamily: false,
            list: false,
            textalign: false,
            colorPicker: false,
            link: false,
          }}
          editorBorder
        />
        <h3>
          blockTypeOptions:
          <span className="info type">array</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad acepta un arreglo con cualquiera de los siguientes
          valores: <code className="code">"Normal"</code>,{" "}
          <code className="code">"H1"</code>, <code className="code">"H2"</code>
          , <code className="code">"H3"</code>,{" "}
          <code className="code">"H4"</code>, <code className="code">"H5"</code>
          , code.code{"H6"}, <code className="code">"Blockquote"</code> y{" "}
          <code className="code">"Code"</code>.
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(blockExtraParams)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={blockExtraParams}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarExtraParams={{
            blockTypeOptions: ["Normal", "H1", "H2", "H3", "Code"],
          }}
          toolbarConfig={{
            blockType: true,
            inline: false,
            fontSize: false,
            fontFamily: false,
            list: false,
            textalign: false,
            colorPicker: false,
            link: false,
          }}
          editorBorder
        />
        <h3>
          fontSizeOptions:
          <span className="info type">array</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad acepta un arreglo con cualquiera de los siguientes
          valores: <span className="type">8</span>,{" "}
          <span className="type">9</span>, <span className="type">10</span>,{" "}
          <span className="type">11</span>, <span className="type">12</span>,{" "}
          <span className="type">14</span>, <span className="type">16</span>,{" "}
          <span className="type">18</span>, <span className="type">24</span>,{" "}
          <span className="type">30</span>, <span className="type">36</span>,{" "}
          <span className="type">48</span>, <span className="type">60</span>,{" "}
          <span className="type">72</span> y <span className="type">96</span>
        </p>
        <p className="note">
          técnicamente puedes agregar cualquier valor numérico que desees, pero
          la interfaz está preparada solo para estos valores posibles dentro del
          arreglo. Si deseas agregar uno diferente, extiende la interfaz en{" "}
          <code className="code">
            src/components/GeestTextEditor/GeestTextEditor.d.ts
          </code>{" "}
          o utilizar <code className="code">// @ts-ignore</code>.
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(fontSizeExtraParams)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={fontSizeExtraParams}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarExtraParams={{
            fontSizeOptions: [8, 12, 14, 16, 24],
          }}
          toolbarConfig={{
            inline: false,
            fontFamily: false,
            list: false,
            textalign: false,
            colorPicker: false,
            link: false,
          }}
          editorBorder
        />

        <h3>
          fontFamilyOptions:
          <span className="info type">array</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad acepta un arreglo con cualquiera de los siguientes
          valores: <code className="code">"Gotham-Book"</code>,{" "}
          <code className="code">"Arial"</code>,{" "}
          <code className="code">"Georgia"</code>,{" "}
          <code className="code">"Impact"</code>,{" "}
          <code className="code">"Tahoma"</code>,{" "}
          <code className="code">"Times New Roman"</code> y{" "}
          <code className="code">"Verdana"</code>. Su valor por defecto es tener
          todas estas fuentes activas.
        </p>
        <p className="note">
          Técnicamente puedes agregar cualquier nombre de fuente que desees,
          pero la interfaz está preparada solo para estos valores posibles
          dentro del arreglo. Si deseas agregar uno diferente, extiende la
          interfaz en{" "}
          <code className="code">
            src/components/GeestTextEditor/GeestTextEditor.d.ts
          </code>{" "}
          o utilizar <code className="code">// @ts-ignore</code>.
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(fontFamilyExtraParams)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={fontFamilyExtraParams}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarExtraParams={{
            fontFamilyOptions: ["Gotham-Book", "Arial", "Times New Roman"],
          }}
          toolbarConfig={{
            inline: false,
            fontSize: false,
            fontFamily: true,
            list: false,
            textalign: false,
            colorPicker: false,
            link: false,
          }}
          editorBorder
        />

        <h3>
          listOptions:
          <span className="info type">array</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad acepta un arreglo con cualquiera de los siguientes
          valores: <code className="code">"unordered"</code>,{" "}
          <code className="code">"ordered"</code>,{" "}
          <code className="code">"indent"</code> y{" "}
          <code className="code">"outdent"</code>. Su valor por defecto es tener
          todas sus opciones activas.
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(listExtraParams)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={listExtraParams}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarExtraParams={{
            listOptions: ["unordered", "ordered"],
          }}
          toolbarConfig={{
            inline: false,
            fontSize: false,
            fontFamily: false,
            list: true,
            textalign: false,
            colorPicker: false,
            link: false,
          }}
          editorBorder
        />

        <h3>
          texAlign:
          <span className="info type">array</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad acepa un arreglo con culquiera de los siguientes
          valores: <code className="code">"left"</code>,{" "}
          <code className="code">"center"</code> y{" "}
          <code className="code">"right"</code>. Su valor por defeco es tener
          tyodas sus opciones activas.
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(alignExtraParms)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={alignExtraParms}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarExtraParams={{
            textAlignOptions: ["left", "center"],
          }}
          toolbarConfig={{
            inline: false,
            fontSize: false,
            fontFamily: false,
            list: false,
            textalign: true,
            colorPicker: false,
            link: false,
          }}
          editorBorder
        />

        <h3>
          colorPicker:
          <span className="info type">array</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad acepta un arreglo de strings. Cualquier tipo valido
          para ser configurado como un <code className="code">color</code> en{" "}
          <span className="type">css</span>, desde constantes, hasta funciones
          como por ejemplo: <code className="code">rgb(255, 255, 255)</code> o{" "}
          <code className="code">hsla(323, 100%, 50%, 1)</code>.
        </p>
        <p>
          Sus valores por defecto son los siguientes:{" "}
          <code className="code">
            [ "#000000", "#48505E", "#828D9E", "#FFFFFF", "#F44336", "#E91E63",
            "#2196F3", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4",
            "#009688", "#4CAF50", "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107",
            "#FF9800", "#FF5722", "#795548", "#E299FC", "transparent" ]
          </code>
          .
        </p>
        <p className="note">
          Por limitntes de <code className="code">Draft.js</code> y{" "}
          <code className="code">react-draft-wysiwyg</code>, los colores de
          texto como para fondos, son compartidos.
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(colorExtraParms)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={colorExtraParms}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarExtraParams={{
            colorPickerOptions: [
              "black",
              "white",
              "#00acc1",
              "hsla(323, 100%, 50%, 1)",
            ],
          }}
          toolbarConfig={{
            inline: false,
            fontSize: false,
            fontFamily: false,
            list: false,
            textalign: false,
            colorPicker: true,
            link: false,
          }}
          editorBorder
        />

        <h3>
          linkOptions:
          <span className="info type">obj</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          Esta propiedad acepta un objeto con dos propiedades opcionales muy
          simples. <code className="code">defaultTarget</code> y{" "}
          <code className="code">options</code>.
        </p>
        <p>
          defaultTarget por su lado, recibe un string que puede tener uno de dos
          valores: <code className="code">"_self"</code> o{" "}
          <code className="code">"_blank"</code>. Esta propiedad como puede
          intuirse sirve para configurar la propiedad target en un anchor
          element.
        </p>
        <p>
          En el caso de <code className="code">options</code>, esta recibe un
          arreglo con los posibles valores <code className="code">link</code> y{" "}
          <code className="code">unlink</code>. Estas propiedades activan y
          desactivan los controles.
        </p>
        <p>
          Por defecto, la configuración es mantener links con un target{" "}
          <code className="code">"_blank"</code> y solo la opción{" "}
          <code className="code">"link"</code> activa.
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(linkExtraParms)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={linkExtraParms}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarExtraParams={{
            linkOptions: {
              defaultTarget: "_blank",
              options: ["link"],
            },
          }}
          toolbarConfig={{
            inline: false,
            fontSize: false,
            fontFamily: false,
            list: false,
            textalign: false,
            colorPicker: false,
            link: true,
          }}
          editorBorder
        />

        <h3>
          fieldOptions:
          <span className="info type">obj</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          fieldOptions recibe un objeto con una única propiedad llamada{" "}
          <code className="code">"largeButton"</code> la cual tiene un valor
          booleano. Esta sirve para cambiar la forma en que se renderiza el
          controlador para insertar campos. Por defecto está desactivada.
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(fieldExtraParms)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={fieldExtraParms}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarExtraParams={{
            fieldOptions: { largeButton: true },
          }}
          toolbarConfig={{
            inline: false,
            fontSize: false,
            fontFamily: false,
            list: false,
            textalign: false,
            colorPicker: false,
            link: false,
            field: true,
          }}
          editorBorder
        />
      </ArticleWrapper>
    </SectionWrapper>
  );
};
