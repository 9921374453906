import React from "react";
import styled from "styled-components";
import { ReactComponent as ConfigReportIconSVG } from "../../resources/img/ConfigReportIcon.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px !important;
`;

interface ConfigReportIconProps {}

const ConfigReportIcon: React.FC<ConfigReportIconProps> = () => {
  return (
    <IconWrapper>
      <ConfigReportIconSVG width="14px" height="14px" />
    </IconWrapper>
  );
};

export default ConfigReportIcon;
