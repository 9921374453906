import styled from "styled-components";
import { Row, Col } from "antd";

export const Container = styled.div`
  flex: 1 1 0%;
  overflow: auto;
  font-family: Gotham-book;
  background: #e4e2e2;
  height: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
  max-height: 100vh;
`;

export const Card = styled.div`
  background: #fcfcfb;
  border-radius: 15px;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 10px 8px #cbccc9;
`;

export const Content = styled.div`
  background: #f5f5f5;
  border-radius: 15px;
  height: 100%;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
`;

export const Header = styled(Row)`
  height: 10%;
  align-items: center;
`;

export const CardCont = styled(Row)`
  height: 80%;
  align-items: center;
  justify-content: center;
`;

export const ErrorCard = styled.div`
  box-shadow: -3px -3px 10px 6px #fcfcfb;
  height: 100%;
  border-radius: 15px;
  background: #f5f5f5;
  padding: 3.5rem 4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  overflow: auto;
  justify-content: center;
`;

export const ErrorCardShadow = styled(Col)`
  height: 100%;
  box-sizing: border-box;
  box-shadow: 12px 12px 10px -6px #cbccc9;
  border-radius: 15px;
  margin: 0.8rem 0rem;
`;

export const Text = styled.div`
  font-size: xx-large;
  font-family: Gotham-Medium;
  color: #e22111;
  margin-bottom: 1rem;
`;
