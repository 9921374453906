import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import _ from "lodash";
import { useParams } from "react-router-dom";
import ReactFlow, {
  Controls,
  Node,
  Edge,
  NodeTypes,
  useOnSelectionChange,
  addEdge,
  NodeMouseHandler,
  ReactFlowInstance,
  Connection,
  OnNodesChange,
  Background,
  BackgroundVariant,
  EdgeTypes,
  OnEdgesChange,
  NodeDragHandler,
} from "reactflow";
import CustomNodes, { CustomNodeTypes } from "./CustomNodes";
import {
  CopyNode,
  CreateNodeBuffer,
  DeleteNode,
  FlowProps,
  InitialNodePosition,
  InsertNode,
  LinkNode,
  MoveNode,
  NodeData,
  NodeRequest,
  SelectedStage,
} from "./Flow.d";
import {
  createNodeDefaults,
  defaultEdgeOptions,
  filterDeleteKey,
  filterEdgesByIds,
  flowTypeToApiType,
  handleOnDragOver,
  nodeInjections,
  onNodeDeleteAttempt,
  removeNode,
  resetDeletingState,
  flowConnectionToApiLink,
  onNodeMoved,
  onNodeStageChange,
  isValidConnection,
  apiNodeToFlowNode,
  onCopyNode,
  suppressOnChangeNodes,
  onEdgeDelete,
  updateEdgesOnSelectionChange,
} from "./helpers";
import { ToolboxActions } from "./CustomNodes/Toolbox/Toolbox.d";
import CreateNodeModal from "./CreateNodeModal";
import { useCopyPaste, useMutation } from "../../../hooks";
import NodeEditor from "./NodeEditor";
import { ConfiguratorContext } from "../Configurator";
import TaskFields from "./TaskFields";
import CustomEdges from "./CustomEdges";

const CustomReactFlow = styled(ReactFlow)`
  .react-flow__attribution {
    display: none;
  }
`;

const Flow: React.FC<FlowProps> = ({
  nodes,
  setNodes,
  onNodesChange,
  edges,
  setEdges,
  onEdgesChange,
  reloadFlow,
  setSavingDataStatus,
}) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const { refreshProcessInformation } = useContext(ConfiguratorContext);

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance<NodeData> | null>(null);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);

  const nodeTypes: NodeTypes = useMemo(() => CustomNodes, []);
  const edgeTypes: EdgeTypes = useMemo(() => CustomEdges, []);

  const [copiedNodeId, setCopiedNodeId] = useState<string>("");
  const [editingNode, setEditingNode] = useState<Node<NodeData> | null>(null);
  const [fieldsNodeId, setFieldsNodeId] = useState<string | null>();

  const [initialNodePosition, setInitialNodePosition] =
    useState<InitialNodePosition>(null);

  const { handleOnKeyDown } = useCopyPaste({
    onCopy: () => onCopyNode(nodes, setCopiedNodeId),
    onPaste: () => handleOnNodeAction(ToolboxActions.Duplicate, copiedNodeId),
    onNotMatching: (event) => filterDeleteKey(event, handleOnNodeDeleteAttempt),
  });

  const [createNodeBuffer, setCreateNodeBuffer] =
    useState<CreateNodeBuffer | null>(null);

  useOnSelectionChange({
    onChange: () => {
      setNodes(resetDeletingState(nodes));
    },
  });

  const [insertNode] = useMutation<InsertNode>({
    func: "Ver2-Configurator-in",
    onSuccess: ({ IdNode, NodeTitle }, node: Node<NodeData>) => {
      setNodes((nds) =>
        nds.concat({
          ...node,
          id: _.toString(IdNode),
          data: { ...node.data, label: NodeTitle },
        })
      );
      refreshProcessInformation();
      setSavingDataStatus("saved");
    },
  });
  const [copyNode] = useMutation<CopyNode>({
    func: "Ver2-Configurator-cn",
    onSuccess: (node) => {
      setNodes((nds) => {
        const nds_temp = _.cloneDeep(nds).map((nd) => {
          if (nd.selected) nd.selected = false;
          return nd;
        });
        return nds_temp.concat(apiNodeToFlowNode(node));
      });
      setSavingDataStatus("saved");
    },
  });
  const [deleteNode] = useMutation<DeleteNode>({
    func: "Ver2-Configurator-dn",
    onSuccess: ({ DeletedLinksIds }, { nodeId }) => {
      const newEdges = filterEdgesByIds(edges, DeletedLinksIds);
      const newNodes = removeNode(nodes, nodeId as string);
      setNodes(newNodes);
      setEdges(newEdges);
      refreshProcessInformation();
      setSavingDataStatus("saved");
    },
  });
  const [moveNode] = useMutation<MoveNode>({
    func: "Ver2-Configurator-mn",
    onSuccess: () => {
      refreshProcessInformation();
      setSavingDataStatus("saved");
    },
    onError: reloadFlow,
  });
  const [linkNodes] = useMutation<LinkNode>({
    func: "Ver2-Configurator-ln",
    onSuccess: ({ IdLink }, connection: Connection) => {
      if (IdLink)
        setEdges((eds) =>
          addEdge(
            {
              ...connection,
              id: _.toString(IdLink),
            },
            eds
          )
        );
      refreshProcessInformation();
      setSavingDataStatus("saved");
      reloadFlow();
    },
  });
  const [unlinkNodes] = useMutation<[]>({
    func: "Ver2-Configurator-un",
    onSuccess: () => {
      refreshProcessInformation();
      setSavingDataStatus("saved");
      reloadFlow();
    },
    onError: reloadFlow,
  });

  const createNode = (node: Node<NodeData>): void => {
    setSavingDataStatus("saving");
    insertNode({
      args: {
        IdTeam,
        IdProcessTemplate,
        NodeTitle: node.data.label,
        NodeType: flowTypeToApiType(node.type as CustomNodeTypes),
        CoordinatesCsv: _.join([node.position.x, node.position.y], ","),
      },
      shippedData: node,
    });
  };

  const createSubfluxNode = (
    node: Node<NodeData>,
    IdProcessTemplateToTrigger: number
  ): void => {
    setSavingDataStatus("saving");
    insertNode({
      args: {
        IdTeam,
        IdProcessTemplate,
        IdProcessTemplateToTrigger,
        NodeTitle: node.data.label,
        NodeType: flowTypeToApiType(node.type as CustomNodeTypes),
        CoordinatesCsv: _.join([node.position.x, node.position.y], ","),
      },
      shippedData: node,
    });
  };

  const onConnect = useCallback(
    (connection: Connection) => {
      setSavingDataStatus("saving");
      linkNodes({
        args: {
          IdTeam,
          IdProcessTemplate,
          ...flowConnectionToApiLink(connection, nodes),
        },
        shippedData: connection,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEdges, nodes]
  );
  const deleteEdge = (edge: Edge): void => {
    setSavingDataStatus("saving");
    unlinkNodes({
      args: {
        IdTeam,
        IdProcessTemplate,
        IdLink: edge.id,
      },
    });
  };
  const onDragOver = useCallback(handleOnDragOver, []);
  const onDrop: React.DragEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData("application/reactflow");

      if (
        typeof type === "undefined" ||
        !type ||
        !reactFlowWrapper.current ||
        !reactFlowInstance
      ) {
        return;
      }

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = createNodeDefaults(type as CustomNodeTypes, position);

      switch (type) {
        case CustomNodeTypes.TASK_NODE:
        case CustomNodeTypes.AUTOMATION_NODE:
          setCreateNodeBuffer({
            node: newNode,
            type: NodeRequest.NODE_NAME,
          });
          break;
        case CustomNodeTypes.SUBFLUX_NODE:
          setCreateNodeBuffer({
            node: newNode,
            type: NodeRequest.SUBFLUX_CONFIG,
          });
          break;
        default:
          createNode(newNode);
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlowInstance]
  );

  const handleOnNodeDeleteAttempt = () => {
    setNodes(onNodeDeleteAttempt(nodes));
    const { edges: updatedEdges, deletedEdge } = onEdgeDelete(edges);
    if (deletedEdge) {
      setEdges(updatedEdges);
      deleteEdge(deletedEdge);
    }
  };
  const handleOnNodeAbortDelete = () => setNodes(resetDeletingState(nodes));
  const handleOnNodeAction = (action: ToolboxActions, nodeId: string) => {
    switch (action) {
      case ToolboxActions.Edit:
        setEditingNode(_.find(nodes, { id: nodeId }) ?? null);
        break;
      case ToolboxActions.Fields:
        setFieldsNodeId(nodeId);
        break;
      case ToolboxActions.Duplicate:
        setSavingDataStatus("saving");
        copyNode({
          args: {
            IdTeam,
            IdProcessTemplate,
            IdNode: nodeId,
          },
        });
        break;
      case ToolboxActions.Delete:
        setSavingDataStatus("saving");
        deleteNode({
          args: {
            IdTeam,
            IdProcessTemplate,
            IdNode: nodeId,
          },
          shippedData: { nodeId },
        });
        break;
    }
  };
  const handleOnNodeDoubleClick: NodeMouseHandler = (
    _event,
    node: Node<NodeData>
  ) => handleOnNodeAction(ToolboxActions.Edit, node.id);
  const handleOnNodeStageChange = (nodeId: string, stage: SelectedStage) =>
    setNodes(onNodeStageChange(nodes, nodeId, stage));

  const handleOnStopEditing = (): void => {
    setEditingNode(null);
    refreshProcessInformation();
  };

  const handleOnOpenTaskFields = (node: Node<NodeData>) =>
    handleOnNodeAction(ToolboxActions.Fields, node.id);

  const cleanNodeBuffer = (): void => setCreateNodeBuffer(null);
  const closeTaskFields = (): void => {
    setFieldsNodeId(null);
    refreshProcessInformation();
  };

  const handleOnChangeNodes: OnNodesChange = (changes) => {
    if (suppressOnChangeNodes(changes)) return;
    onNodesChange(changes);
    const movedNode = onNodeMoved(changes, initialNodePosition, nodes);
    if (movedNode) {
      setSavingDataStatus("saving");
      moveNode({
        args: {
          IdTeam,
          IdProcessTemplate,
          IdNode: movedNode.nodeId,
          CoordinatesCsv: movedNode.positionCSV,
        },
      });
    }
  };

  const handleOnEdgeChanges: OnEdgesChange = (changes) => {
    const updatedEdges = updateEdgesOnSelectionChange(changes, edges);
    if (updatedEdges) {
      setEdges(updatedEdges);
    } else onEdgesChange(changes);
  };

  const handleOnNodeDragStart: NodeDragHandler = (_evt, node) =>
    setInitialNodePosition({
      nodeId: node.id,
      x: node.position.x,
      y: node.position.y,
    });
  const handleOnNodeDragStop: NodeDragHandler = () =>
    setInitialNodePosition(null);

  return (
    <div style={{ width: "100%", height: "100%" }} ref={reactFlowWrapper}>
      {createNodeBuffer && (
        <CreateNodeModal
          createNodeBuffer={createNodeBuffer}
          cleanNodeBuffer={cleanNodeBuffer}
          onCreateNodeWithName={createNode}
          onCreateSubfluxNode={createSubfluxNode}
        />
      )}
      {editingNode && (
        <NodeEditor
          node={editingNode}
          onClose={handleOnStopEditing}
          openTaskFields={handleOnOpenTaskFields}
        />
      )}
      {fieldsNodeId && (
        <TaskFields nodeId={fieldsNodeId} onClose={closeTaskFields} />
      )}
      <CustomReactFlow
        defaultViewport={{
          x: 20,
          y: 20,
          zoom: 1,
        }}
        fitViewOptions={{
          maxZoom: 1,
        }}
        minZoom={0}
        onInit={setReactFlowInstance}
        nodes={nodeInjections(nodes, {
          data: {
            onAction: handleOnNodeAction,
            onDeleteAttempt: handleOnNodeDeleteAttempt,
            onAbortDelete: handleOnNodeAbortDelete,
            onChangeNodeStage: handleOnNodeStageChange,
          },
        })}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        onNodeDragStart={handleOnNodeDragStart}
        onNodeDragStop={handleOnNodeDragStop}
        onNodesChange={handleOnChangeNodes}
        onEdgesChange={handleOnEdgeChanges}
        onConnect={onConnect}
        onNodeDoubleClick={handleOnNodeDoubleClick}
        onKeyDown={handleOnKeyDown}
        connectOnClick={false}
        onDragOver={onDragOver}
        onDrop={onDrop}
        isValidConnection={(connection) =>
          isValidConnection(connection, nodes, edges)
        }
        connectionLineStyle={defaultEdgeOptions.style}
        connectionRadius={50}
      >
        <Controls />
        <Background color="#e8ecf1" variant={BackgroundVariant.Lines} />
      </CustomReactFlow>
    </div>
  );
};

export default Flow;
