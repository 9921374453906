import { Row, Space } from "antd";
import _, { noop as NOOP } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ColorPicker, { Circle } from "../../../../../components/ColorPicker";
import { TeamRole } from "../../../Teams.d";
import {
  DeleteIcon,
  ConfirmIcon,
  CancelIcon,
} from "../../../../../components/hoverIcons";
import { IconContainer } from "../../../../../components/hoverIcons/styles";

const StyledTextField = styled.input`
  width: 100%;
  color: rgba(130, 141, 158, 1);
  border: 2px solid rgba(237, 236, 236, 1);
  border-radius: 6px;
  padding-left: 10px;
  height: 32px;
  font-family: Gotham-Book;
`;

interface EditRoleProps {
  defaultValue?: TeamRole;
  disableDelete?: boolean;
  onSubmit?: (role: TeamRole) => void;
  onDelete?: (role: TeamRole) => void;
  onCancel?: () => void;
  teamRoles?: TeamRole[];
}

const defaultRole: TeamRole = {
  Label: "",
  Color: "#03a9f4",
  IdTeamRole: 0,
};

const EditRole: React.FC<EditRoleProps> = ({
  defaultValue,
  disableDelete = false,
  onSubmit = NOOP,
  onDelete = NOOP,
  onCancel = NOOP,
}) => {
  const [role, setRole] = useState<TeamRole>(defaultRole);
  const [, setInputTouched] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setRole(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeLabel = (Label: string): void => setRole({ ...role, Label });
  const onChangeColor = (Color: string): void => setRole({ ...role, Color });

  const handleOnDelete: React.MouseEventHandler<HTMLDivElement> = () => {
    if (role.IdTeamRole !== 0 && !disableDelete) {
      onDelete(role);
    }
  };
  const handleOnSubmit: React.MouseEventHandler<HTMLDivElement> = () => {
    if (!_.isEmpty(role.Label.trim())) {
      onSubmit(role);
    }
  };

  return (
    <Row align="middle" justify="space-between">
      <div style={{ flex: 1, marginRight: 10 }}>
        <StyledTextField
          autoFocus
          placeholder="Nombre del rol..."
          onBlur={() => setInputTouched(true)}
          value={role.Label}
          onChange={(e) => onChangeLabel(e.target.value)}
        />
      </div>
      <Space align="center" size={7}>
        <ColorPicker color={role.Color} onChange={onChangeColor}>
          <IconContainer size={26}>
            <Circle color={role.Color} style={{ cursor: "pointer" }} />
          </IconContainer>
        </ColorPicker>
        {!disableDelete && (
          <DeleteIcon size={26} filled onClick={handleOnDelete} />
        )}
        <CancelIcon onClick={onCancel} size={26} />
        <ConfirmIcon onClick={handleOnSubmit} />
      </Space>
    </Row>
  );
};

export default EditRole;
