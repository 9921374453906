import React from "react";
import { StringInput } from "../../styles";
import { ConfigRow, ConfigRowName, TextConfigContainer } from "./styles";

interface TextConfigProps {
  nodeName: string;
  setNodeName: (newName: string) => void;
}

const TextConfig: React.FC<TextConfigProps> = ({ nodeName, setNodeName }) => {
  return (
    <TextConfigContainer>
      <ConfigRow>
        <ConfigRowName>Nombre del elemento</ConfigRowName>
        <StringInput
          value={nodeName}
          onChange={({ target: { value } }) => setNodeName(value)}
        />
      </ConfigRow>
    </TextConfigContainer>
  );
};

export default TextConfig;
