import React from "react";
import _ from "lodash";
import { Row, Tag } from "antd";
import { Typography } from "../../../../../GeestUI";
import { Languages } from "../Dictionary";
import {
  CloseIcon,
  PopoverContentWrapper,
  ProcessContainer,
  ProcessesBox,
} from "../Styles";
import { Proccess } from "../../Dashboard.d";

const { H3, Text } = Typography;
const { ProcessLabel } = Languages["ESP"];

interface ProcessPopoverProps {
  value: Proccess[];
  proccessCollection: Proccess[];
  onChange: (oldStatus: Proccess[], newStatus: Proccess[]) => void;
  onClose: () => void;
}

const ProcessPopover: React.FC<ProcessPopoverProps> = ({
  value,
  proccessCollection,
  onChange,
  onClose,
}) => {
  const onSelectProcess = (process: Proccess): void => {
    const existentProcess = !!_.find(value, {
      IdTemplateProcess: process.IdTemplateProcess,
    });
    if (existentProcess) {
      // Remove process
      onChange(
        proccessCollection,
        _.reject(value, { IdTemplateProcess: process.IdTemplateProcess })
      );
    } else {
      // Add process
      onChange(value, [...value, process]);
    }
  };

  return (
    <PopoverContentWrapper>
      <Row>
        <H3 mb="0" style={{ flex: 1 }}>
          {ProcessLabel}
        </H3>
        <CloseIcon onClick={onClose} />
      </Row>
      <ProcessesBox>
        {(proccessCollection || []).map((proc) => (
          <div key={proc.IdTemplateProcess}>
            <ProcessContainer
              justify="space-between"
              $selected={
                !!_.find(value, { IdTemplateProcess: proc.IdTemplateProcess })
              }
              onClick={() => onSelectProcess(proc)}
              tabIndex={0}
              onKeyDown={({ key }) => {
                if (key === "Enter") onSelectProcess(proc);
              }}
            >
              <Tag
                color="#0066af"
                style={{
                  borderRadius: "5px",
                  minWidth: "100px",
                  maxWidth: "calc(100% - 0.5rem - 20px)",
                  textAlign: "center",
                }}
              >
                <Text>{proc.ProcessName}</Text>
              </Tag>
            </ProcessContainer>
          </div>
        ))}
      </ProcessesBox>
    </PopoverContentWrapper>
  );
};

export default ProcessPopover;
