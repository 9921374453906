export const FrontViewStates = {
  // Old states
  BU_FREE: "ByUsers-FreeView",
  BU_PREMIUM: "ByUsers-PremiumView",
  BU_CANCELED: "ByUsers-CanceledView",
  // Trial states
  CARDLESS_TRIAL: "CardlessTrialView",
  ENDED_CARDLESS_TRIAL: "EndedCardlessTrialView",
  BONUS_TRIAL: "BonusTrialView",
  CANCEL_BONUS_TRIAL: "CancelBonusTrialView",
  ENDED_BONUS_TRIAL: "EndedBonusTrialView",

  PREMIUM: "PremiumView",
  TERMINATING: "TerminatingView",
  DOWNGRADE: "DowngradePlanView",
  CANCELED: "CanceledView",
};
