import React from "react";
import _, { noop as NOOP } from "lodash";
import {
  FormField,
  CheckListField,
  VarDBRowField,
  User,
  IVarDBDataOrigin,
  VarDBCellField,
} from "../DetailPending.d";
import {
  ElementType,
  FieldType,
} from "../../../Templates/StartTemplate/StartTemplate.d";
import DynamicInput from "../../../../components/DynamicInput";
import { Typography } from "../../../../GeestUI";
import { Space } from "antd";
import { useMutation } from "../../../../hooks";
import DataTypeIcons from "../../../../components/DataTypeIcons";

const { P } = Typography;

interface CellType {
  Value: string;
  Format: string;
  IdField: number;
  IdCell: number;
}

interface FieldListProps {
  fields: FormField[];
  onUpdateField: (
    formField: FormField,
    index?: number,
    cellsValues?: CellType[],
    updateRow?: boolean,
    editableField?: FormField[],
    consultFields?: FormField[],
    consultIndex?: number,
    returnUpdatedFields?: boolean
  ) => { EditableFields: FormField[]; ConsultFields: FormField[] } | void;
  IdProcessExecution?: number;
  filesPath?: string;
  editable?: boolean;
  isConsult?: boolean;
  UserSelectDataOrigin?: User[];
  VarDBDataOrigin?: IVarDBDataOrigin;
  isFileOpen?: boolean;
  setIsFileOpen?: (isOpen: boolean) => void;
  setIncomeReported?: (newIncome: { value: string; format: string }) => void;
  consultFields?: FormField[];
  funcBeforePdf?: (generate: () => void) => void;
  disablePdf?: boolean;
  updateAllFields?: (
    editableFiels: FormField[],
    ConsultFields: FormField[]
  ) => void;
  savePrevStateBeforeChangeRow?: () => void;
}

const FieldList: React.FC<FieldListProps> = ({
  fields,
  onUpdateField,
  editable = false,
  isConsult = false,
  IdProcessExecution,
  filesPath,
  UserSelectDataOrigin = [],
  VarDBDataOrigin = {},
  isFileOpen = false,
  setIsFileOpen = NOOP,
  setIncomeReported = NOOP,
  consultFields = [],
  updateAllFields = NOOP,
  funcBeforePdf,
  savePrevStateBeforeChangeRow = NOOP,
  disablePdf = false,
}) => {
  const [getVarDBCellValues] = useMutation<CellType[]>({
    func: "Ver2-Processes-gvcv",
    onSuccess: (
      cellsValues,
      {
        field,
        fieldIndex,
        editableFields = fields,
        newConsult = consultFields,
        consultIndex,
        extraEditable = {},
        extraConsult = {},
      }
    ) => {
      let editable: FormField[] = [...editableFields];
      let consult: FormField[] = [...newConsult];
      let updatedField = field as VarDBRowField;
      let specialProperties: { [key: string]: any } = {};
      updatedField.Cells = updatedField.Cells.map((cell) => {
        specialProperties = {};
        const cellValue = _.find(cellsValues, {
          IdField: cell.IdField,
        });
        return {
          ...cell,
          Value: cellValue?.Value || "",
          Format: cellValue?.Format || "",
          IdValue: cellValue?.IdCell || "",
          ...specialProperties,
        };
      });
      const res = onUpdateField(
        updatedField as unknown as FormField,
        fieldIndex,
        cellsValues,
        false,
        editable,
        consult,
        consultIndex,
        true
      );
      if (res) {
        const { ConsultFields, EditableFields } = res;
        editable = EditableFields;
        consult = ConsultFields;
      }
      let childEditable: { [key: string]: VarDBRowField } = {};
      editable.forEach((field, index) => {
        if (
          (field as unknown as VarDBRowField).AutoFill ===
            updatedField.IdVarDBGroup &&
          !Object.keys(childEditable)
            .map((key) => childEditable[key].IdVarDBGroup)
            .includes((field as unknown as VarDBRowField).IdVarDBGroup)
        ) {
          const prevRowSelected = (field as unknown as VarDBRowField)
            .IdRowSelected;
          const newDataOrigin = getFilteredVarDBDataOrigin(
            {
              ...(field as unknown as VarDBRowField),
              FilterDataOrigin: updatedField.IdVarDBGroup,
            },
            editable,
            consult,
            true
          )[0];
          (field as unknown as VarDBRowField).IdRowSelected =
            newDataOrigin.IdRow;
          (field as unknown as VarDBRowField).RowSelectedLabel =
            newDataOrigin.RowSelectedLabel;
          if (
            (field as unknown as VarDBRowField).IdRowSelected !== 0 &&
            (field as unknown as VarDBRowField).IdRowSelected !==
              prevRowSelected
          ) {
            childEditable[index] = field as unknown as VarDBRowField;
          }
        }
      });
      let childConsult: { [key: string]: VarDBRowField } = {};
      consult.forEach((field, index) => {
        if (
          (field as unknown as VarDBRowField).AutoFill ===
            updatedField.IdVarDBGroup &&
          !Object.keys(childConsult)
            .map((key) => childConsult[key].IdVarDBGroup)
            .includes((field as unknown as VarDBRowField).IdVarDBGroup) &&
          !Object.keys(childEditable)
            .map((key) => childEditable[key].IdVarDBGroup)
            .includes((field as unknown as VarDBRowField).IdVarDBGroup)
        ) {
          const prevRowSelected = (field as unknown as VarDBRowField)
            .IdRowSelected;
          const newDataOrigin = getFilteredVarDBDataOrigin(
            {
              ...(field as unknown as VarDBRowField),
              FilterDataOrigin: updatedField.IdVarDBGroup,
            },
            editable,
            consult,
            true
          )[0];
          (field as unknown as VarDBRowField).IdRowSelected =
            newDataOrigin.IdRow;
          (field as unknown as VarDBRowField).RowSelectedLabel =
            newDataOrigin.RowSelectedLabel;
          if (
            (field as unknown as VarDBRowField).IdRowSelected !== 0 &&
            (field as unknown as VarDBRowField).IdRowSelected !==
              prevRowSelected
          ) {
            childConsult[index] = field as unknown as VarDBRowField;
          }
        }
      });
      let alreadySentOne = false;
      const newEditable = { ...extraEditable, ...childEditable };
      const allConsult = { ...extraConsult, ...childConsult };
      Object.keys(newEditable).forEach((key) => {
        if (!alreadySentOne) {
          const newExtra = { ...newEditable };
          delete newExtra[key];
          handleOnChangeVarDBRowSelected(
            newEditable[key],
            Number(key),
            editable,
            consult,
            undefined,
            newExtra,
            allConsult
          );
          alreadySentOne = true;
        }
      });
      Object.keys(allConsult).forEach((key) => {
        if (!alreadySentOne) {
          const newExtra = { ...allConsult };
          delete newExtra[key];
          handleOnChangeVarDBRowSelected(
            allConsult[key],
            undefined,
            editable,
            consult,
            Number(key),
            newEditable,
            newExtra
          );
          alreadySentOne = true;
        }
      });
      updateAllFields(editable, consult);
    },
  });

  const handleOnChangeFormat = (format: string, IdField: number): void => {
    if (isConsult) return;
    let updatedField = _.find(fields, { IdField });
    if (updatedField) {
      updatedField.Format = format;
      onUpdateField(updatedField);
    }
  };

  const handleOnChangeValue = (value: string, IdField: number): void => {
    if (isConsult) return;
    let updatedField = _.find(fields, { IdField });
    if (updatedField) {
      if (updatedField.Configuration === "Income") {
        setIncomeReported({
          value: value,
          format: updatedField.Format,
        });
      }

      let filteredValue = value;
      updatedField.Value = filteredValue;
      onUpdateField(updatedField);
    }
  };

  const handleOnChangeCheckListField = (
    elements: ElementType[],
    IdField: number
  ): void => {
    if (isConsult) return;
    let updatedField = _.find(fields, { IdField });
    if (updatedField) {
      (updatedField as CheckListField).Elements = elements;
      onUpdateField(updatedField);
    }
  };

  const getDataOrigin = (field: FormField): string => {
    let dataOrigin;
    switch (field.DataType) {
      case "users_select":
      case "varDBRow":
        dataOrigin = UserSelectDataOrigin as unknown as any; // Hacky way to pass array
        break;
      default:
        dataOrigin = field.DataOrigin;
        break;
    }
    return dataOrigin;
  };

  const getValue = (field: any, dataOrigin: any) => {
    let val = (field as unknown as VarDBRowField).IdRowSelected || field.Value;
    return val;
  };

  const [addOptionToDataOriginPendings] = useMutation<{
    label: string;
    value: number;
  }>({
    func: "Ver2-MyPendings-aotdo",
    onSuccess: (newOption, { index }) => {
      let fieldsAux = [...fields];
      (fieldsAux[index] as FormField).DataOrigin = [
        ...(fieldsAux[index] as FormField).DataOrigin,
        newOption,
      ];

      if ((fieldsAux[index] as FormField).DataType === "select") {
        (fieldsAux[index] as FormField).Value = {
          Type: "Key",
          Value: newOption.value,
          Label: newOption.label,
        };
      }

      if ((fieldsAux[index] as FormField).DataType === "multi_select") {
        if (Array.isArray((fieldsAux[index] as FormField).Value)) {
          (fieldsAux[index] as FormField).Value = [
            // @ts-ignore
            ...(fieldsAux[index] as FormField).Value,
            {
              Type: "Key",
              Value: newOption.value,
              Label: newOption.label,
            },
          ];
        } else {
          (fieldsAux[index] as FormField).Value = [
            {
              Type: "Key",
              Value: newOption.value,
              Label: newOption.label,
            },
          ];
        }
      }

      onUpdateField(fieldsAux[index], index);
    },
  });

  const [addOptionToDataOriginVarDBs] = useMutation<{
    label: string;
    value: number;
  }>({
    func: "Ver2-Vardbs-aotdo",
    onSuccess: (newOption, { indexField, indexCell }) => {
      let fieldsAux = [...fields];

      let vardbField = fieldsAux[indexField];
      let vardbCell = (vardbField as any).Cells[indexCell];

      // @ts-ignore
      fieldsAux[indexField].Cells[indexCell].DataOrigin = [
        ...vardbCell.DataOrigin,
        newOption,
      ];

      if (
        (fieldsAux as any)[indexField].Cells[indexCell].DataType === "select"
      ) {
        (fieldsAux as any)[indexField].Cells[indexCell].Value = {
          Type: "Key",
          Value: newOption.value,
          Label: newOption.label,
        };
      }

      if (
        (fieldsAux as any)[indexField].Cells[indexCell].DataType ===
        "multi_select"
      ) {
        if (
          Array.isArray((fieldsAux as any)[indexField].Cells[indexCell].Value)
        ) {
          (fieldsAux as any)[indexField].Cells[indexCell].Value = [
            // @ts-ignore
            ...(fieldsAux as any)[indexField].Cells[indexCell].Value,
            {
              Type: "Key",
              Value: newOption.value,
              Label: newOption.label,
            },
          ];
        } else {
          (fieldsAux as any)[indexField].Cells[indexCell].Value = [
            {
              Type: "Key",
              Value: newOption.value,
              Label: newOption.label,
            },
          ];
        }
      }

      onUpdateField(fieldsAux[indexField], indexField);
    },
  });

  const handleOnChangeVarDBRowSelected = (
    updatedField: VarDBRowField,
    index?: number,
    editableFields = fields,
    consult = consultFields,
    consultIndex?: number,
    extraEditable?: { [key: string]: VarDBRowField },
    extraConsult?: { [key: string]: VarDBRowField }
  ): void => {
    if (updatedField) {
      savePrevStateBeforeChangeRow();
      if (updatedField.IdRowSelected) {
        getVarDBCellValues({
          args: {
            IdRowSelected: updatedField.IdRowSelected,
            IdVarDBGroup: updatedField.IdVarDBGroup,
          },
          shippedData: {
            field: { ...updatedField },
            fieldIndex: index,
            editableFields,
            newConsult: consult,
            consultIndex,
            extraEditable,
            extraConsult,
          },
        });
      } else {
        updatedField.Cells = updatedField.Cells.map((cell) => ({
          ...cell,
          IdValue: "",
          Value: undefined,
        }));
        onUpdateField(updatedField as unknown as FormField, index, [], true);
      }
    }
  };

  const handleOnChangeVarDBCells = (
    cells: FieldType[],
    index: number
  ): void => {
    if (isConsult) return;
    let updatedField = fields[index] as unknown as VarDBRowField;
    if (updatedField) {
      updatedField.Cells.forEach((field) => {
        if (field.Configuration === "Income") {
          setIncomeReported({
            value: field.Value,
            format: field.Format,
          });
        }
      });

      if (typeof updatedField.IdRowSelected !== "number") {
        updatedField.IdRowSelected = 0;
      }
      (updatedField as unknown as VarDBRowField).Cells =
        cells as unknown as VarDBCellField[];
      onUpdateField(updatedField as unknown as FormField, index);
    }
  };

  const handleAddOption = (option: string, IdField: number, index: number) => {
    addOptionToDataOriginPendings({
      args: {
        IdField,
        NewElement: option,
      },
      shippedData: { index },
    });
  };

  const handleAddOptionFromCell = (
    option: string,
    field: any,
    IdColumn: number | null
  ) => {
    if (IdColumn) {
      const indexField = _.findIndex(fields as any, {
        IdVarDBGroup: field.IdVarDBGroup,
        IdVarDB: field.IdVarDB,
      });
      if (indexField === -1) return;
      const indexCell = _.findIndex((fields as any)[indexField].Cells, {
        IdVarDBColumn: IdColumn,
      });
      if (indexCell === -1) return;

      addOptionToDataOriginVarDBs({
        args: {
          IdColumn,
          IdVarDB: field.IdVarDB,
          DataOrigin: [
            ...(fields as any)[indexField].Cells[indexCell].DataOrigin,
            { label: option },
          ],
        },
        shippedData: { indexField, indexCell },
      });
    }
  };

  const getFilteredVarDBDataOrigin = (
    field: VarDBRowField,
    editable = fields,
    consult = consultFields,
    onlyFiltered = false
  ) => {
    if (!field.IdVarDB) return [];
    const allFields = [...editable, ...consult];
    const vardbRowParent = allFields.find(
      (f) =>
        (f as unknown as VarDBRowField).IdVarDBGroup === field.FilterDataOrigin
    );

    let newDataOrigin = VarDBDataOrigin[field.IdVarDB];

    if (vardbRowParent && (vardbRowParent as any).IdRowSelected === 0) {
      newDataOrigin = [{ IdRow: 0, Label: "Nuevo", RowSelectedLabel: "Nuevo" }];
      return newDataOrigin;
    }

    if (!newDataOrigin) return [];
    const idRowSelected = (vardbRowParent as unknown as VarDBRowField)
      ?.IdRowSelected;
    if (!vardbRowParent || idRowSelected === 0 || !idRowSelected) {
      return newDataOrigin.filter(
        ({ IdRow }) => IdRow !== 0 || field.CanAddNewRows
      );
    }
    newDataOrigin = newDataOrigin.filter(
      (dataOrigin) =>
        dataOrigin[field.FilterDataOrigin || 0]?.includes(idRowSelected) ||
        (dataOrigin.IdRow === 0 && field.CanAddNewRows && !onlyFiltered) ||
        (!!field.IdRowSelected &&
          field.IdRowSelected === dataOrigin.IdRow &&
          !onlyFiltered)
    );
    if (newDataOrigin.length === 0) {
      newDataOrigin = [{ IdRow: 0, Label: "Nuevo", RowSelectedLabel: "Nuevo" }];
    }
    return newDataOrigin;
  };

  return (
    <Space size={[14, 0]} direction="vertical" style={{ width: "100%" }}>
      {fields.map((field, fieldIndex) => {
        return (
          <div key={field.IdField || fieldIndex}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginBottom: "5px",
              }}
            >
              {DataTypeIcons[field.DataType] && (
                <img
                  src={DataTypeIcons[field.DataType].icon}
                  alt=""
                  style={{
                    width: "14px",
                    height: "14px",
                    ...DataTypeIcons[field.DataType]?.extraStyles,
                  }}
                />
              )}
              <P mb="0" style={{ fontFamily: "Gotham-Bold" }}>
                {field.Label}
              </P>
            </div>
            <DynamicInput
              value={getValue(field, getDataOrigin(field))}
              type={field.DataType}
              dataOrigin={getDataOrigin(field)}
              format={field.Format || ""}
              configuration={field.Configuration || ""}
              userSelectDataOrigin={UserSelectDataOrigin || []}
              // VarDB
              IdVarDB={(field as unknown as VarDBRowField).IdVarDB || 0}
              VarDBTitle={(field as unknown as VarDBRowField).VarDBTitle || ""}
              Cells={
                ((field as unknown as VarDBRowField)
                  .Cells as unknown as FieldType[]) || []
              }
              RowSelectedLabel={
                (field as unknown as VarDBRowField).RowSelectedLabel
              }
              VarDBDataOrigin={getFilteredVarDBDataOrigin(
                field as unknown as VarDBRowField
              )}
              IdVarDBGroup={
                (field as unknown as VarDBRowField).IdVarDBGroup || 0
              }
              VarDBRowShowSelectInput={
                (field as unknown as VarDBRowField).VarDBRowShowSelectInput ||
                false
              }
              // methods and generic params
              isConsult={false}
              disabled={
                field.Type === "Ghost" ||
                !editable ||
                isConsult ||
                (field.DataType === "pdf_autogenerated" && disablePdf)
              }
              required={field.IsMandatory}
              IdProcessExecution={IdProcessExecution}
              IdField={field.IdField}
              fieldName={field.Label}
              onChange={(value) => handleOnChangeValue(value, field.IdField)}
              onChangeFormat={(format) =>
                handleOnChangeFormat(format as string, field.IdField)
              }
              onAddOption={(option) =>
                handleAddOption(option, field.IdField, fieldIndex)
              }
              onChangeRow={(value) =>
                handleOnChangeVarDBRowSelected(
                  {
                    ...(field as unknown as VarDBRowField),
                    IdRowSelected: value,
                    RowSelectedLabel:
                      VarDBDataOrigin[
                        (field as unknown as VarDBRowField).IdVarDB
                      ].find(({ IdRow }) => IdRow === value)
                        ?.RowSelectedLabel || "",
                  },
                  fieldIndex
                )
              }
              onChangeCells={(cells) =>
                handleOnChangeVarDBCells(cells, fieldIndex)
              }
              onAddOptionFromCell={(option, IdColumn, DataOrigin, indexCell) =>
                handleAddOptionFromCell(option, field, IdColumn)
              }
              needsCatchEsc
              isFileOpen={isFileOpen}
              setIsFileOpen={setIsFileOpen}
              checklistParams={{
                elements: (field as CheckListField).Elements || [],
                onChangeChecklist: (elements) =>
                  handleOnChangeCheckListField(elements, field.IdField),
              }}
              extraParams={{
                FilesPath: filesPath || "",
                showFormat: true,
                canAddNewRows: isConsult
                  ? false
                  : (field as unknown as VarDBRowField).CanAddNewRows || false,
                CanUseExistingRows: isConsult
                  ? false
                  : (field as unknown as VarDBRowField).CanUseExistingRows ||
                    false,
                showNumberLabel: true,
                FilterDataOrigin: (field as unknown as VarDBRowField)
                  .FilterDataOrigin,
              }}
              funcBeforePdf={funcBeforePdf}
            />
          </div>
        );
      })}
    </Space>
  );
};

export default FieldList;
