import styled from "styled-components";
import { ErrorMessage } from "formik";
import { Row } from "antd";
import { withStyles, TextField } from "@material-ui/core";
import Logo from "../../../resources/img/SETTINGS_LOGO.svg";

export const CardCont = styled.div`
  margin-top: 2rem;
  width: 42%;
  box-shadow: 0px 5px 25px 0px rgb(84 84 84 / 30%);
  border-radius: 1rem;
`;

export const LogoContainer = styled(Row)`
  background-image: ${({ $imgProfile }) =>
    `url(${$imgProfile ? `"${$imgProfile}"` : Logo})`};
  background-repeat: no-repeat;
  color: white;
  background-size: cover;
  margin-bottom: 0.5rem;
  height: 10rem;
  width: 10rem;
  font-size: 4.2rem;
  text-align: center;
  display: block;
  background-position: center;
  opacity: ${({ $opacity }) => ($opacity ? "0.4" : "1")};
  transition: all 0.3s ease;
  border-radius: 50%;
`;

export const StyledInput = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "#7c7c7c",
    },
    "& label": {
      fontFamily: "Gotham-Book",
      fontSize: "medium",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Gotham-Book",
      borderRadius: ".5rem",
      marginBottom: "1rem",
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

export const ErrorMessageStyle = styled(ErrorMessage)`
  color: red;
  text-align: left;
  width: 80%;
  font-family: "Gotham-book";
  margin: -0.9rem 0rem 1rem 1rem;
`;

export const EditImgCont = styled.img`
  width: 3rem;
  position: absolute;
  top: 35%;
  left: 36%;
  z-index: 2;
`;

export const ProfileImgCont = styled.div`
  width: 10rem;
  height: 10rem;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
`;

export const InitialsCont = styled(Row)`
  width: 10rem;
  height: 9rem;
  border-radius: 50%;
`;

export const ActionsItems = styled.div`
  font-family: "Gotham-Book";
  font-size: larger;
  color: ${({ $alert }) => ($alert ? "#e10600" : "#535151")};
  padding-left: 0.5rem;
`;

export const ActionsItemsCont = styled(Row)`
  padding: 0.4rem 0.3rem;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: #efefef;
  }
`;
