import React, { useState } from "react";
import _, { noop as NOOP } from "lodash";
import { Popover } from "antd";
import { Typography } from "../../../../GeestUI";
import InvolvedPopover from "./Popovers/InvolvedPopover";
import { StyledInputBox } from "./Styles";
import { TeamMember } from "../Dashboard.d";
import User from "../../SharedComponents/User";

const { H4 } = Typography;

interface InvolvedFilterProps {
  extended: boolean;
  involved: TeamMember[];
  teamMembers: TeamMember[];
  InvolvedPlaceholder: string;
  onChangeInvolved: (
    oldInvolved: TeamMember[],
    newInvolved: TeamMember[]
  ) => void;
}

const InvolvedFilter: React.FC<InvolvedFilterProps> = ({
  extended,
  involved,
  teamMembers,
  InvolvedPlaceholder,
  onChangeInvolved,
}) => {
  const [membersPopoverVisible, setMembersPopoverVisible] =
    useState<boolean>(false);

  return (
    <Popover
      trigger="click"
      placement="bottom"
      overlayStyle={{
        border: "solid 1px #cfcdcd",
        borderRadius: ".9rem",
        padding: "0",
        overflow: "hidden",
        boxShadow: "0px 0px 30px #cfcdcd",
        width: "350px",
      }}
      destroyTooltipOnHide
      visible={membersPopoverVisible}
      onVisibleChange={extended ? setMembersPopoverVisible : NOOP}
      content={() => (
        <InvolvedPopover
          value={involved}
          teamMembers={teamMembers}
          onChange={onChangeInvolved}
          onClose={() => setMembersPopoverVisible(false)}
        />
      )}
    >
      <StyledInputBox
        style={involved.length > 1 ? { paddingLeft: "0.5rem" } : {}}
        tabIndex={0}
        onKeyDown={({ key }) => {
          if (key === "Enter") setMembersPopoverVisible(true);
        }}
      >
        {involved.map((invo, idx) => (
          <span
            key={idx}
            style={{
              border: "1px solid sallmon",
              borderRadius: "5px",
              marginRight:
                involved.length === 1 || idx === involved.length - 1
                  ? 0
                  : "5px",
              marginBottom: involved.length <= 4 ? 0 : "5px",
            }}
          >
            <User
              user={invo}
              size={involved.length === 1 ? "middle" : "small"}
              picSize="22px"
              placement="bottom"
            />
          </span>
        ))}
        {_.isEmpty(involved) && (
          <H4
            ml="5px"
            mb="0"
            color="#c0c9d3"
            onClick={extended ? () => setMembersPopoverVisible(true) : NOOP}
          >
            {InvolvedPlaceholder}
          </H4>
        )}
      </StyledInputBox>
    </Popover>
  );
};

export default InvolvedFilter;
