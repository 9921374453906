import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import { ModalItem, StyledInput, ModalContainer } from "../../styles";
import { Button } from "../../../../GeestUI";
import { sha256 } from "js-sha256";
import { RestorePasswordModalProps } from "../../Intranet.d";
import { useMutation } from "../../../../hooks";

const RestorePasswordModal: React.FC<RestorePasswordModalProps> = ({
  onClose,
  user,
}) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [resetPassword, loading] = useMutation({
    func: "Ver2-Intranet-Pwd-rp",
    onSuccess: onClose,
  });

  const onSave = () => {
    resetPassword({
      args: {
        IdUser: user.IdUser,
        NewPassword: sha256(newPassword),
      },
    });
  };
  return (
    <Modal title="Restablecer contraseña" onCancel={onClose}>
      <ModalContainer>
        <ModalItem>
          Email
          <p>{user.Email}</p>
        </ModalItem>
        <ModalItem>
          Nombre
          <p>{user.Name}</p>
        </ModalItem>
        <StyledInput
          placeholder="Escribe la nueva contraseña..."
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type="password"
          style={{ width: "100%" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSave();
            }
          }}
        />
        <Button
          type="primary"
          disabled={!newPassword}
          onClick={onSave}
          loading={loading}
          style={{ alignSelf: "center", marginTop: "24px" }}
        >
          Guardar
        </Button>
      </ModalContainer>
    </Modal>
  );
};

export default RestorePasswordModal;
