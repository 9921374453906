import React, { useContext, useState } from "react";
import Modal from "../../../../../components/Modal";
import VISA from "../../../../../resources/img/visa.svg";
import MASTER_CARD from "../../../../../resources/img/mc.svg";
import AMERICAN_EXPRESS from "../../../../../resources/img/am.svg";
import { Row, Tag } from "antd";
import { AiFillStar } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import Button from "../../../../../components/Button";
import styled from "styled-components";
import { FiPlus } from "react-icons/fi";
import AddPaymentMethodModal from "./AddPaymentMethodModal";
import ConfirmDeleteCardModal from "./ConfirmDeleteCardModal";
import { useParams } from "react-router";
import useMutation from "../../../../../hooks/useMutation";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { MessagesContext } from "../../../../../components/AppMessages";

const AddMethodButton = styled(Button)`
  && {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    border: none;
  }
`;

interface CardsProps {
  PaymentMethodId: number;
  Brand: string;
  ExpirationMonth: string;
  ExpirationYear: string;
  Last4: string;
  IsFavorite: boolean;
}

interface ModalProps {
  cards: CardsProps[];
  setVisible: () => void;
  reload: () => void;
}

const PaymentMethodsModal: React.FC<ModalProps> = ({
  cards,
  setVisible,
  reload,
}) => {
  const { showMessage } = useContext(MessagesContext);
  const [idCardDeleteOpen, setIdCardDeleteOpen] = useState<number>();
  const [modal, setModal] = useState<string | null>(null);
  const [Cards, setCards] = useState<CardsProps[]>(cards);
  const [loadCard, setLoadCard] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const { IdTeam } = useParams<{ IdTeam: string }>();
  const stripe = useStripe();
  const elements = useElements();

  const BrandLogos: { [key: string]: string } = {
    visa: VISA,
    mastercard: MASTER_CARD,
    amex: AMERICAN_EXPRESS,
  };

  const getCardInfo = async () => {
    const cardElement = elements?.getElement(CardElement);

    const response = await stripe?.createPaymentMethod({
      type: "card",
      card: cardElement!,
    });

    return response;
  };

  const [addPaymentMethod, submitting] = useMutation<CardsProps[]>({
    func: "Ver2-Subscription-Normal-apm",
    onSuccess: (response) => {
      setCards(response);
      setModal(null);
    },
  });

  const [onSelectPaymentMethod, selecting] = useMutation<CardsProps[]>({
    func: "Ver2-Subscription-Normal-sfpm",
    onSuccess: (response) => {
      setCards(response);
      setHasChanged(true);
    },
  });

  const [onDeletePaymentMethod, deleting] = useMutation<CardsProps[]>({
    func: "Ver2-Subscription-Normal-dpm",
    onSuccess: (response) => {
      setCards(response);
      setModal(null);
      setHasChanged(true);
    },
  });

  const onAddPaymentMethod = async (): Promise<void> => {
    setLoadCard(true);
    const cardInfoResponse = await getCardInfo();
    if (!cardInfoResponse || cardInfoResponse?.error) {
      showMessage(cardInfoResponse?.error.message || "CardInfo error", "error");
    } else {
      addPaymentMethod({
        args: {
          IdTeam,
          PaymentMethodStripeId: cardInfoResponse.paymentMethod.id,
        },
      });
    }
    setLoadCard(false);
  };

  const Modals: { [key: string]: React.ReactElement } = {
    AddPaymentMethodModal: (
      <AddPaymentMethodModal
        setVisible={() => setModal(null)}
        onAddPaymentMethod={onAddPaymentMethod}
        loading={loadCard || submitting}
      />
    ),
    ConfirmDeleteCardModal: (
      <ConfirmDeleteCardModal
        setVisible={() => setModal(null)}
        onConfirm={() =>
          onDeletePaymentMethod({
            args: { IdTeam, PaymentMethodId: idCardDeleteOpen },
          })
        }
        loading={deleting}
      />
    ),
  };

  const onCloseModal = (): void => {
    if (hasChanged) {
      reload();
    } else {
      setVisible();
    }
  };

  return (
    <Modal
      title="Mis métodos de pago"
      visible
      onCancel={onCloseModal}
      width="35%"
    >
      {Modals[modal || ""]}
      {Cards.map((Card) => (
        <>
          <Row
            justify="space-between"
            style={{ marginBottom: "1rem" }}
            key={Card.PaymentMethodId}
          >
            <Row align="middle" style={{ height: "15px" }}>
              {BrandLogos[Card.Brand] ? (
                <img
                  style={{ height: "17px" }}
                  src={BrandLogos[Card.Brand]}
                  alt={Card.Brand}
                />
              ) : (
                <Tag color="cyan">{Card.Brand}</Tag>
              )}
              <div style={{ marginLeft: "0.5rem" }}>
                <div style={{ fontSize: "14px" }}>
                  <b>{`•••• •••• •••• ${Card.Last4}`}</b>
                </div>
                <div style={{ fontSize: "10px" }}>
                  {`${Card.ExpirationMonth}/${Card.ExpirationYear}`}
                </div>
              </div>
              {Cards.length > 1 &&
                (idCardDeleteOpen === Card.PaymentMethodId ? (
                  <IoIosArrowUp
                    color="grey"
                    size={25}
                    style={{ marginLeft: "0.5rem" }}
                    onClick={() => setIdCardDeleteOpen(0)}
                    cursor="pointer"
                  />
                ) : (
                  <IoIosArrowDown
                    color="grey"
                    size={25}
                    style={{ marginLeft: "0.5rem" }}
                    onClick={() => setIdCardDeleteOpen(Card.PaymentMethodId)}
                    cursor="pointer"
                  />
                ))}
            </Row>
            <Row align="middle">
              Establecer como predeterminada
              <AiFillStar
                color={Card.IsFavorite ? "yellow" : "grey"}
                size={25}
                cursor={Card.IsFavorite ? "default" : "pointer"}
                onClick={() => {
                  if (!Card.IsFavorite) {
                    onSelectPaymentMethod({
                      args: { PaymentMethodId: Card.PaymentMethodId, IdTeam },
                    });
                  }
                }}
              />
            </Row>
          </Row>
          {idCardDeleteOpen === Card.PaymentMethodId && (
            <Row
              style={{ marginLeft: "2rem", cursor: "pointer" }}
              onClick={() => setModal("ConfirmDeleteCardModal")}
            >
              <FaTrash size={20} color="red" />
              <div style={{ color: "red" }}>Eliminar tarjeta</div>
            </Row>
          )}
        </>
      ))}
      <Row justify="center" style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <AddMethodButton
          onClick={() => setModal("AddPaymentMethodModal")}
          loading={selecting}
        >
          <FiPlus size={20} color="grey" />
          Agregar método de pago
        </AddMethodButton>
      </Row>
    </Modal>
  );
};

export default PaymentMethodsModal;
