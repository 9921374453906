import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Modal } from "@material-ui/core";
import { Languages } from "./Dictionary";
import ModalTab from "../../components/ModalTab/ModalTab";
import {
  CloseIcon,
  ContentWrapper,
  ModalContent,
  ModalTitle,
  ModalWrapper,
} from "../../modalStyles";
import StepContent from "./StepContent";
import {
  GraphType,
  DurationsGraphFormat,
  FilterOption,
  SellsStepOneResponse,
  GroupByType,
  XAxisValueType,
  FieldsCollection,
  FilterBackend,
  FieldsForGroup,
  GroupByInterface,
} from "../../../Dashboards.d";
import { useMutation } from "../../../../../hooks";
import UnsavedChangesModal from "../UnsavedChangesModal";

const { ModalTitleLabel, StepGeneral, StepFilter, StepVisualization } =
  Languages["ESP"];

interface Option {
  value: string | number;
  label: string;
}

interface SellsProps {
  onClose: () => void;
  selectedTemplate: number;
  selectedDashboard: string | null;
  graphToEdit: GraphType | null;
}

const Sells: React.FC<SellsProps> = ({
  onClose,
  selectedTemplate,
  selectedDashboard,
  graphToEdit,
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [graphName, setGraphName] = useState<string>("");
  const [selectedProcesses, setSelectedProcesses] = useState<string>("");
  const [format, setFormat] = useState<DurationsGraphFormat>("");
  const [processOptions, setProcesssOptions] = useState<Option[]>([]);
  const [filters, setFilters] = useState<FilterOption[]>([]);
  const [groupBy, setGroupBy] = useState<GroupByType>("");
  const [groupByFromExistendGraph, setGroupByFromExistendGraph] =
    useState<GroupByInterface | null>(null);
  const [xAxisValue, setXAxisValue] = useState<XAxisValueType>("");
  const [fields, setFields] = useState<FieldsCollection[]>([]);
  const [fieldsForGroup, setFieldsForGroup] = useState<FieldsForGroup>({});
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] =
    useState<boolean>(false);

  const steps = [StepGeneral, StepFilter, StepVisualization];

  const handleFormatFilters = (
    filterOptions: FilterOption[],
    filters: FilterBackend[]
  ): FilterOption[] => {
    if (filters.length === 0) {
      return [];
    }

    const newFilters = filters.map((filter) => {
      const options =
        (_.find(filterOptions, {
          Key: filter.Key,
        }) as FilterOption) ?? {};
      const newFilter = {
        ...options,
        ...filter,
        RenderKey: `filter-${Math.random()}`,
      };
      return newFilter;
    });

    return newFilters;
  };

  const [getGraphConfigurationWizard] = useMutation<SellsStepOneResponse>({
    func: "Ver2-DashBoards-ggcw",
    onSuccess: ({
      Processes,
      GraphMode,
      GraphName,
      IdProcessTemplate,
      FilterOptions,
      Filters,
      GroupBy,
      XAxis,
    }) => {
      const newOptions = Processes.map((p) => {
        return {
          value: p.IdProcessTemplate,
          label: p.Name,
        };
      });
      setProcesssOptions(newOptions);
      setFormat(GraphMode || "StackBar");
      setGraphName(GraphName);
      setSelectedProcesses(`${IdProcessTemplate}`);
      if (Filters) {
        const formatedFilters = handleFormatFilters(FilterOptions, [
          ...Filters,
        ]);
        setFilters(formatedFilters ?? []);
      }
      if (GroupBy) {
        setGroupBy(GroupBy.Mode ?? "");
        setGroupByFromExistendGraph(GroupBy ?? null);
      }
      if (XAxis) {
        setXAxisValue(XAxis.Frequency ?? "");
      }
    },
  });

  useEffect(() => {
    if (processOptions.length === 0) {
      getGraphConfigurationWizard({
        args: {
          IdDashBoard: selectedDashboard,
          IdGraphTemplate: selectedTemplate,
          IdGraph: graphToEdit?.IdGraph ?? "",
          Step: 1,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [registerGraph] = useMutation<any[]>({
    func: "Ver2-DashBoards-rg",
    onSuccess: () => onClose(),
  });

  const canSubmit = () => {
    let canSubmit = true;
    filters.forEach((filter) => {
      if (!filter.Value) {
        canSubmit = false;
      }
    });

    if (xAxisValue === "") {
      canSubmit = false;
    }

    return canSubmit;
  };

  const handleOnSubmit = () => {
    if (canSubmit()) {
      let newFilters: FilterBackend[] = _.cloneDeep(filters).map((filter) => {
        let newFilter: FilterBackend = {
          Key: filter.Key,
          Label: filter.Label,
          Value: filter.Value,
        };

        if (filter.Type !== "Static") {
          newFilter.DataType = filter.DataType;
        }

        if (filter.Start) {
          newFilter.Start = filter.Start;
        }
        if (filter.End) {
          newFilter.End = filter.End;
        }

        return newFilter;
      });

      let data: any = {
        args: {
          IdDashBoard: selectedDashboard,
          IdGraphTemplate: selectedTemplate,
          IdProcessTemplate: selectedProcesses,
          IdGraph: graphToEdit?.IdGraph ?? "",
          GraphMode: format,
          GraphName: graphName,
          Filters: newFilters,
          GroupBy: { Mode: groupBy, Value: "" },
          XAxis: { Frequency: xAxisValue },
        },
      };

      if (groupBy === "ByProcess") {
        data.args.GroupBy.Value = selectedProcesses;
      }

      if (groupBy === "ByField") {
        let fields = "";
        let limit = Object.keys(fieldsForGroup).length - 1;
        Object.keys(fieldsForGroup).forEach((process: string, i) => {
          fields += fieldsForGroup[+process];
          if (i !== limit) fields += ",";
        });
        data.args.GroupBy.Value = fields;
      }

      registerGraph(data);
    }
  };

  const validateNextStep = () => {
    switch (activeStep) {
      case 0:
        const graphNameValid = Boolean(graphName);
        const selectedProcessValid = _.trim(selectedProcesses).length > 0;
        return graphNameValid && selectedProcessValid;

      case 1:
        // we need validations for filters?
        return true;

      default:
        return true;
    }
  };

  const handleOnClose = (submit = false) => {
    if (unsavedChanges && !submit) {
      setOpenUnsavedChangesModal(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      {openUnsavedChangesModal && (
        <UnsavedChangesModal
          onCancel={() => setOpenUnsavedChangesModal(false)}
          onConfirm={onClose}
        />
      )}
      <Modal open onClose={() => handleOnClose()}>
        <ModalWrapper onClick={() => handleOnClose()}>
          <ModalContent
            onClick={(e) => e.stopPropagation()}
            $width="1113px"
            $maxWidth="90vw"
            $height="700px"
            $maxHeight="700px"
          >
            <ModalTitle>
              <p>{ModalTitleLabel}</p>
              <CloseIcon size={20} onClick={() => handleOnClose()} />
            </ModalTitle>
            <ModalTab
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={steps}
              linear
              canGoNext={validateNextStep()}
            />

            <ContentWrapper>
              <StepContent
                step={activeStep}
                graphName={graphName}
                setGraphName={setGraphName}
                selectedProcesses={selectedProcesses}
                setSelectedProcesses={setSelectedProcesses}
                format={format}
                setFormat={setFormat}
                processOptions={processOptions}
                selectedTemplate={selectedTemplate}
                selectedDashboard={selectedDashboard}
                graphToEdit={graphToEdit}
                filters={filters}
                setFilters={setFilters}
                groupBy={groupBy}
                setGroupBy={setGroupBy}
                groupByFromExistendGraph={groupByFromExistendGraph}
                setGroupByFromExistendGraph={setGroupByFromExistendGraph}
                xAxisValue={xAxisValue}
                setXAxisValue={setXAxisValue}
                fields={fields}
                setFields={setFields}
                fieldsForGroup={fieldsForGroup}
                setFieldsForGroup={setFieldsForGroup}
                canGoNext={validateNextStep()}
                goNextStep={() => setActiveStep(activeStep + 1)}
                canSubmit={canSubmit()}
                handleOnSubmit={handleOnSubmit}
                setUnsavedChanges={() => setUnsavedChanges(true)}
              />
            </ContentWrapper>
          </ModalContent>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default Sells;
