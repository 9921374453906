import styled, { css } from "styled-components";
import { Row } from "antd";

export const Table = styled(Row)`
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  color: #727272;
`;

export const Column = styled.div`
  background: white;
  width: ${({ count }) => `calc(100% / ${count})`};
  border: 1px solid var(--transparent);
  transition: 0.5s all;
  ${({ active }) =>
    active &&
    css`
      border-radius: 5px;
      border-color: ${({ planColor }) => planColor};
      box-shadow: 0px 0px 10px 0px ${({ planColor }) => planColor};
      z-index: 1;
      padding: 0.5rem 0;
      margin: -0.5rem 0;
    `}
  &:nth-child(1) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

export const ColumnHeader = styled.div`
  color: ${({ blank }) => (blank ? "var(--transparent)" : "inherit")};
  user-select: ${({ blank }) => (blank ? "none" : "default")};
  font-size: 17px;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;

export const Cell = styled.div`
  font-size: 17px;
  padding: 0.75rem 1.5rem;
  line-height: 1.5rem;
  height: 3.05rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const HeaderText = styled.b`
  color: ${({ planColor }) => planColor};
  font-size: 20px;
`;

export const HeaderSubtext = styled.div`
  font-size: 14px;
`;
