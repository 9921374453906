import styled from "styled-components";
import { Row } from "antd";
import { Tag } from "../../../GeestUI";

export const StyledDivider = styled.div`
  border: 1px solid var(--light-gray);
  width: 100%;
`;

export const TasksHeaderContainer = styled(Row)`
  cursor: pointer;
  height: 40px;
  flex-wrap: nowrap;
  border-radius: 5px;
  :hover {
    background: #f0f3f7;

    .iconContainer {
      opacity: 1;
    }
  }
  & > div ~ div {
    margin-left: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const TasksNotEmptyContainer = styled.div`
  display: block;
  white-space: nowrap;
  flex: 1;
`;

export const TasksEmptyContainer = styled.div``;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: end;
  gap: 15px;

  width: 100%;
  height: 50%;
  text-align: center;

  img {
    width: 150px;
    height: auto;
  }
  p {
    margin: 0;
    color: #828d9e;
    font-family: "Gotham-Bold";
    font-size: 14px;
  }
`;

export const EmptyWrapper = styled.div`
  width: 100vw;
  width: calc(100vw - 100px);
  position: sticky;
  left: 0;
`;

export const RedDot = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 2.5px;
  background: #e11909;
  margin-left: -0.4rem;
`;

export const ColumnIconContainer = styled(Row)`
  background: transparent;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  opacity: 0;
  :hover {
    background: #c0c9d3;
  }
`;

export const IconButton = styled.div`
  background: var(--transparent);
  border-radius: 5px;
  width: 30px;
  height: 30px;
  padding: 5px;
  :hover {
    background: #dfe4e9;
  }
`;

export const StartTaskButton = styled.div`
  background: var(--transparent);
  border-radius: 5px;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: #c0c9d3;
  transition: color 0.35s ease;
  :hover {
    color: inherit;
  }
`;

export const ListContainer = styled.div`
  z-index: 2;
  margin-top: 8px;
  height: calc(100% - 50px);
`;

export const PendingsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: sticky;
  top: 0;

  user-select: none;
  height: 40px;
  flex-wrap: nowrap;
  background: #0067b0;
  color: white;
  border-radius: 6px 6px 0 0;

  p {
    margin: 0;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
`;

export const PendingElement = styled.div<{
  background: string;
  hoverBackground: string;
  isTaskForContextMenu: boolean;
}>`
  display: flex;
  position: relative;
  user-select: none;
  height: 40px;
  color: #48505e;
  cursor: pointer;
  width: 100%;
  background: ${({ background, isTaskForContextMenu }) =>
    isTaskForContextMenu ? "#f2f8fe" : background};
  border-bottom: 1px solid #828d9e;
  border-radius: 6px;
  :hover {
    background: ${({ background, hoverBackground, isTaskForContextMenu }) =>
      isTaskForContextMenu ? background : hoverBackground};
  }
`;

export const ColumnTitle = styled.div<{ $width?: string; justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify = "center" }) => justify};

  width: ${({ $width = "270px" }) => $width};
  text-align: center;
  font-size: 14px;
  padding: 0 10px;

  :hover {
    .iconContainer {
      opacity: 1;
    }
  }
`;

export const TableHeaderContent = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const PendingData = styled.div<{
  $width?: string;
  align?: string;
  justify?: string;
  gap?: string;
  padding?: string;
}>`
  position: relative;
  padding: ${({ padding = "0 10px" }) => padding};
  display: flex;
  align-items: ${({ align = "center" }) => align};
  justify-content: ${({ justify = "start" }) => justify};
  gap: ${({ gap }) => gap};

  width: ${({ $width = "270px" }) => $width};

  .task-title,
  .task-execution-title {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StatusIndicator = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 9px;
  border-radius: 6px 0 0 6px;
  background-color: ${({ color }) => color};
`;

export const NoPriorityTag = styled(Tag)`
  margin-right: 0;
  border-radius: 6px;
  border: 1px solid #828d9e;
  color: #828d9e;
`;

export const ReviserIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
`;
