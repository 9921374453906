import React from "react";
import styled from "styled-components";
import { ReactComponent as OrderNoneIconSVG } from "../../resources/img/OrderNoneIcon.svg";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface OrderNoneIconProps {
  width?: string;
  height?: string;
}

const OrderNoneIcon: React.FC<OrderNoneIconProps> = ({
  width = "14px",
  height = "14px",
}) => {
  return (
    <IconWrapper>
      <OrderNoneIconSVG width={width} height={height} />
    </IconWrapper>
  );
};

export default OrderNoneIcon;
