import React, { useState } from "react";
import DetailPending from "../../../Pendings/DetailPending";
import { useFetch } from "../../../../hooks";
import {
  TableContainer,
  TableHeader,
  TableItem,
  TableRow,
  StyledTag,
  UserContainer,
} from "../styles";
import { useParams } from "react-router-dom";
import { DurationsProps, ProcessDurations } from "../ProcessDetail.d";
import Tooltip from "../../../../components/Tooltip";
import moment from "moment";
import { getStatusInfo } from "../helpers";
import {
  UserInitials,
  UserProfilePic,
} from "../../SharedComponents/User/Styles";
import _ from "lodash";
import { parseEmoji } from "../../../../emoji";

const Durations: React.FC<DurationsProps> = ({ reloadProcess }) => {
  const { idProcess } = useParams<{ idProcess: string }>();
  const [detailPendingId, setDetailPendingId] = useState<number | null>(null);

  const { data, reload } = useFetch<ProcessDurations[]>({
    func: "Ver2-Reports-gpdu",
    args: { IdProcessExecution: idProcess },
  });

  const userLimit = 4;

  return (
    <TableContainer>
      {!!detailPendingId && (
        <DetailPending
          idPending={String(detailPendingId)}
          onClose={() => setDetailPendingId(null)}
          onChangePending={(pendingId) => {
            setDetailPendingId(null);
            setTimeout(() => {
              setDetailPendingId(pendingId);
            }, 1);
          }}
          reloadReport={() => {
            reloadProcess();
            reload();
          }}
        />
      )}
      <TableHeader>
        <TableItem
          style={{
            justifyContent: "flex-start",
            flex: 1,
          }}
        >
          Tarea
        </TableItem>
        <TableItem style={{ width: "121px" }}>Inicio</TableItem>
        <TableItem style={{ width: "121px" }}>Fin</TableItem>
        <TableItem style={{ width: "130px" }}>Duración</TableItem>
        <TableItem style={{ width: "130px" }}>Retraso</TableItem>
        <TableItem style={{ width: "113px", padding: 0 }}>
          Estatus actual
        </TableItem>
        <TableItem style={{ width: "134px" }}>Encargados</TableItem>
      </TableHeader>
      {data?.map(
        ({ Id, Title, StartedAt, EndedAt, Status, Duration, Delay, Users }) => (
          <TableRow key={Id} onClick={() => setDetailPendingId(Id)}>
            <TableItem
              style={{
                justifyContent: "flex-start",
                flex: 1,
              }}
            >
              <div
                style={{
                  width: "calc(100% - 3px)",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {Title.length > 40 ? (
                  <Tooltip title={parseEmoji(Title)}>
                    {parseEmoji(Title)}
                  </Tooltip>
                ) : (
                  parseEmoji(Title)
                )}
              </div>
            </TableItem>
            <TableItem style={{ width: "121px" }}>
              {moment(StartedAt).format("DD/MMM hh:mm")}
            </TableItem>
            <TableItem style={{ width: "121px" }}>
              {!!EndedAt ? moment(EndedAt).format("DD/MMM hh:mm") : "-"}
            </TableItem>
            <TableItem style={{ width: "130px" }}>{Duration}</TableItem>
            <TableItem style={{ width: "130px" }}>{Delay}</TableItem>
            <TableItem style={{ width: "113px" }}>
              <StyledTag
                color={getStatusInfo(Status).color}
                style={{
                  width: "74px",
                  justifyContent: "center",
                }}
              >
                {getStatusInfo(Status).label}
              </StyledTag>
            </TableItem>
            <TableItem style={{ width: "134px" }}>
              <UserContainer>
                {_.slice(Users, 0, userLimit).map((user, index) => (
                  <Tooltip
                    title={
                      index === userLimit - 1
                        ? _.slice(Users, index, Users.length)
                            .map((user) => `${user.FirstName} ${user.LastName}`)
                            .join(", ")
                        : `${user.FirstName} ${user.LastName}`
                    }
                    placement="bottom"
                  >
                    {!user.ProfilePicture ? (
                      <UserInitials
                        align="middle"
                        justify="center"
                        picSize="30px"
                        style={{
                          opacity:
                            Users.length > userLimit && index === userLimit - 1
                              ? 0.6
                              : 1,
                          margin: 0,
                          marginLeft: index > 0 ? "-10px" : "0",
                        }}
                      >
                        {user.Initials}
                        <div
                          style={{
                            position: "absolute",
                            margin: "auto",
                            fontSize: "15px",
                            opacity: 1,
                          }}
                        >
                          {Users.length > userLimit &&
                            index === userLimit - 1 &&
                            `+${Users.length - userLimit}`}
                        </div>
                      </UserInitials>
                    ) : (
                      <UserProfilePic
                        image={user.ProfilePicture}
                        align="middle"
                        justify="center"
                        picSize="30px"
                        style={{
                          opacity:
                            Users.length > userLimit && index === userLimit - 1
                              ? 0.6
                              : 1,
                          margin: 0,
                          marginLeft: index > 0 ? "-15px" : "0",
                        }}
                      >
                        <div style={{ opacity: 1, color: "#48505e" }}>
                          {Users.length > userLimit &&
                            index === userLimit - 1 &&
                            `+${Users.length - userLimit}`}
                        </div>
                      </UserProfilePic>
                    )}
                  </Tooltip>
                ))}
              </UserContainer>
            </TableItem>
          </TableRow>
        )
      )}
    </TableContainer>
  );
};

export default Durations;
