import React, { useContext, useState } from "react";
import _ from "lodash";
import {
  PaginationBox,
  PaginationButton,
  EmptyButton,
  CenterContainer,
  PaginationControlsContainer,
  ImportExportIconWrapper,
} from "./Styles";
import { AiOutlineLeft, AiOutlineRight, AiOutlinePlus } from "react-icons/ai";
import { Button } from "../../GeestUI";
import GeestSelect from "../../GeestUI/GeestSelect";
import { Order } from "./DataBases.d";
import { SesionContext } from "../../config/State";
import { Languages } from "./Dictionary";
import ImportIcon from "../../components/hoverIcons/ImportIcon";
import ExportIcon from "../../components/hoverIcons/ExportIcon";

const Pagination: React.FC<{
  totalPages: number;
  currentPage: number;
  handleChangeCurrent: (num: number) => void;
  changingPage: boolean;
}> = ({ totalPages, currentPage, handleChangeCurrent, changingPage }) => {
  let list: (string | number)[] = [];

  if (totalPages > 5) {
    const visibleRange = _.range(currentPage - 3, currentPage + 4).filter(
      (p) => p <= totalPages && p > 0
    );

    list = [
      visibleRange[0] > 1 ? "..." : 0,
      ...visibleRange,
      visibleRange[visibleRange.length - 1] < totalPages ? "..." : 0,
    ].filter((p) => p !== 0);

    if (visibleRange[0] > 2) list.unshift(1);
    if (visibleRange[visibleRange.length - 1] < totalPages)
      list.push(totalPages);
  } else {
    list = _.range(1, totalPages + 1);
  }

  return (
    <PaginationControlsContainer>
      {currentPage !== 1 ? (
        <PaginationButton
          isCurrent={false}
          disabled={changingPage}
          onClick={() => {
            handleChangeCurrent(currentPage - 1);
          }}
        >
          <CenterContainer>
            <AiOutlineLeft size={20} />
          </CenterContainer>
        </PaginationButton>
      ) : (
        <EmptyButton />
      )}

      {list.map((page, i) =>
        _.isNumber(page) ? (
          <PaginationButton
            key={`${i}-${Math.random()}`}
            isCurrent={page === currentPage}
            disabled={changingPage}
            onClick={() => handleChangeCurrent(page)}
          >
            {page}
          </PaginationButton>
        ) : (
          <div key={`${i}-${Math.random()}`} style={{ padding: "0 2px" }}>
            ...
          </div>
        )
      )}

      {currentPage + 1 <= totalPages ? (
        <PaginationButton
          isCurrent={false}
          disabled={changingPage}
          onClick={() => {
            handleChangeCurrent(currentPage + 1);
          }}
        >
          <CenterContainer>
            <AiOutlineRight size={20} />
          </CenterContainer>
        </PaginationButton>
      ) : (
        <EmptyButton />
      )}
    </PaginationControlsContainer>
  );
};

const PaginationContainer: React.FC<{
  IdTeam: string;
  IdVarDB: string;
  atLeastOne: boolean;
  totalPages: number;
  currentPage: number;
  perPage: number;
  changePage: (props: any) => void;
  changingPage: boolean;
  canEditDatabases: boolean;
  canExportDatabases: boolean;
  showAddRow: boolean;
  openAddRowModal: () => void;
  canImport: boolean;
  exportVarDBs: () => void;
  importVarDBs: () => void;
  exportingVarDBToExcel: boolean;
  Filters: any[];
  Order: Order;
}> = ({
  IdTeam,
  IdVarDB,
  atLeastOne,
  totalPages,
  currentPage,
  perPage,
  changePage,
  changingPage,
  canEditDatabases,
  canExportDatabases,
  showAddRow,
  openAddRowModal,
  canImport,
  exportVarDBs,
  importVarDBs,
  exportingVarDBToExcel,
  Filters,
  Order,
}) => {
  const [sesion] = useContext<any>(SesionContext);
  const [newPerPage, setNewPerPage] = useState<number>(perPage);

  const { AddRegister, ImportButtonLabel, ExportButtonLabel } =
    Languages["ESP"];

  const perPageOptions = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 15,
      label: "15",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 40,
      label: "40",
    },
    {
      value: 50,
      label: "50",
    },
  ];

  const handleChangeCurrent = (newCurrent: number) => {
    changePage({
      args: { IdTeam, IdVarDB, Filters, Order },
      idUR: sesion.Id,
      options: {
        pags: {
          PerPage: perPage,
          CurrentPage: newCurrent || currentPage,
        },
      },
    });
  };

  const handleChangePerPage = (newCuantity: number) => {
    setNewPerPage(newCuantity);
    changePage({
      args: { IdTeam, IdVarDB, Filters, Order },
      idUR: sesion.Id,
      options: {
        pags: {
          PerPage: newCuantity,
          CurrentPage: 1,
        },
      },
    });
  };

  return (
    <PaginationBox>
      <CenterContainer $gap="30px">
        <GeestSelect
          value={newPerPage}
          options={perPageOptions}
          onChange={(newCuantity: number) => handleChangePerPage(newCuantity)}
          $width="110px"
          $listWidth="110px"
          placeholderFilter="Por página..."
          valueNecesary
        />

        {totalPages > 0 && (
          <CenterContainer>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              handleChangeCurrent={handleChangeCurrent}
              changingPage={changingPage}
            />
          </CenterContainer>
        )}

        {canEditDatabases && showAddRow && (
          <Button
            type="secondary"
            size="large"
            Icon={AiOutlinePlus}
            onClick={openAddRowModal}
            style={{ width: "190px" }}
          >
            {AddRegister}
          </Button>
        )}
      </CenterContainer>

      <CenterContainer>
        <Button
          disabled={exportingVarDBToExcel || !canExportDatabases || !canImport}
          onClick={importVarDBs}
          Icon={() => (
            <ImportExportIconWrapper>
              <ImportIcon />
            </ImportExportIconWrapper>
          )}
        >
          {ImportButtonLabel}
        </Button>

        <Button
          disabled={exportingVarDBToExcel || !atLeastOne || !canExportDatabases}
          onClick={exportVarDBs}
          Icon={() => (
            <ImportExportIconWrapper>
              <ExportIcon />
            </ImportExportIconWrapper>
          )}
        >
          {ExportButtonLabel}
        </Button>
      </CenterContainer>
    </PaginationBox>
  );
};

export default PaginationContainer;
