import React, { useRef, useState, useContext } from "react";
import _ from "lodash";
import { useHistory, useParams, Prompt } from "react-router-dom";
import Header from "./Header";
import {
  DocumentData,
  GetProcessFieldResponse,
  ImportPDFResponse,
  Option,
} from "../../../components/PDFConfig/PDFConfig.d";
import PDFConfig from "../../../components/PDFConfig/PDFConfig";
import { ViewContainer } from "./styles";
import { editorStateRaw } from "../../../components/GeestTextEditor/Utils/Editor";
import { EditorState, convertFromRaw } from "draft-js";
import { useFetch, useMutation } from "../../../hooks";
import { nanoid } from "nanoid";
import { FieldEditorOrigin } from "../../../components/GeestTextEditor/GeestTextEditor.d";
import { uploadFileToAWS } from "../../../aws/s3Client";
import UnsavedChanges from "../../../components/PDFConfig/Modals/UnsavedChanges";
import ImportPDFConfirmation from "../../../components/PDFConfig/Modals/ImportPDFConfirmation";
import * as pdfjs from "pdfjs-dist";
import { MessagesContext } from "../../../components/AppMessages";
import Loading from "../../../components/Loading";
import routes from "../../../routes";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFConfiguratorProps {}

const PDFConfigurator: React.FC<PDFConfiguratorProps> = () => {
  const [documentData, setDocumentData] = useState<DocumentData>({
    pages: [{ id: nanoid(), elements: [] }],
    elementsOrigin: [],
    width: 794,
    height: 1123,
  });
  const [importedDocumentDataCache, setImportedDocumentDataCache] =
    useState<DocumentData | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [fieldEditorOrigin, setFieldEditorOrigin] = useState<
    FieldEditorOrigin[]
  >([]);
  const [fieldImageOrigin, setFieldImageOrigin] = useState<Option[]>([]);
  const [filesPath, setFilesPath] = useState<string>("");
  const [teamLogo, setTeamLogo] = useState<string>("");
  const [importFilesPath, setImportFilesPath] = useState<string>("");
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] =
    useState<boolean>(false);
  const [pdfFieldsGetted, setPdfFieldsGetted] = useState<boolean>(false);
  const [pdfConfigGetted, setPdfConfigGetted] = useState<boolean>(false);
  const [pdfProcessFieldsGetted, setPdfProcessFieldsGetted] =
    useState<boolean>(false);
  const importInputRef = useRef<HTMLInputElement>(null);
  const { showMessage } = useContext(MessagesContext);

  const history = useHistory();

  const { IdTeam, IdProcess, IdField } = useParams<{
    IdTeam: string;
    IdProcess: string;
    IdField: string;
  }>();

  const { loading: gettingPDFConfigurationFields } = useFetch<
    {
      Value: string;
      Label: string;
      DataType: string;
    }[]
  >({
    func: "Ver2-Configurator-gpcf",
    args: { IdTeam, IdProcessTemplate: IdProcess },
    onSuccess: (fields) => {
      let fieldsForEditor: FieldEditorOrigin[] = [];
      let fieldsForImages: Option[] = [];
      fields.forEach((field) => {
        if (field.DataType !== "file") {
          fieldsForEditor.push({
            DataType: field.DataType,
            Label: field.Label,
            IdField: String(field.Value),
          });
        } else {
          fieldsForImages.push({
            value: field.Value,
            label: field.Label,
          });
        }
      });

      setFieldEditorOrigin(fieldsForEditor);
      setFieldImageOrigin(fieldsForImages);
      setPdfFieldsGetted(true);
    },
  });

  const { loading: getingPDFconfiguration } = useFetch<any>({
    func: "Ver2-Configurator-gpc",
    args: { IdTeam, IdProcessTemplate: IdProcess, IdField },
    onSuccess: (data) => {
      if (!_.isEqual(data, [])) {
        data.elementsOrigin = data.elementsOrigin.map((el: any) => {
          if (el.type === "text") {
            const convertedState = convertFromRaw(el.value);
            el.value = EditorState.createWithContent(convertedState);
          }
          return el;
        });

        setDocumentData(data);
      }
      setPdfConfigGetted(true);
    },
  });

  const { data: fieldData, loading: gettingProcessfield } =
    useFetch<GetProcessFieldResponse>({
      func: "Ver2-Configurator-gpf",
      args: { IdTeam, IdProcessTemplate: IdProcess, IdField },
      onSuccess: (data) => {
        setFilesPath(data.FilesPath);
        setImportFilesPath(data.PdfFilesPath);
        setPdfProcessFieldsGetted(true);
        setTeamLogo(data.TeamLogo);
      },
    });

  const [updatePDFFieldConfiguration, updatingPDF] = useMutation<any>({
    func: "Ver2-Configurator-upfc",
    onSuccess: () => setUnsavedChanges(false),
  });

  const [importPDF, importingPDF] = useMutation<ImportPDFResponse>({
    func: "Ver2-Configurator-cpti",
    onSuccess: (data) => {
      let newPages = data.Images.map((image) => {
        return { id: nanoid(), elements: [], backgroundImage: image };
      });
      const newDocumentData: DocumentData = {
        ...documentData,
        pages: newPages,
        width: data.width,
        height: data.height,
      };

      setImportedDocumentDataCache(newDocumentData);
    },
  });

  const applyImportedDataChanges = () => {
    if (importedDocumentDataCache) {
      setCurrentPage(0);
      setUnsavedChanges(true);
      setDocumentData(importedDocumentDataCache);
    }
    setImportedDocumentDataCache(null);
  };

  const syncing =
    gettingPDFConfigurationFields ||
    getingPDFconfiguration ||
    gettingProcessfield ||
    updatingPDF ||
    importingPDF;

  const goBack = () => {
    const prevPageExists = history.length > 1;
    prevPageExists
      ? history.goBack()
      : history.push("/home/configurator/" + IdTeam + "/" + IdProcess);
  };

  const handleGoBack = () => {
    if (unsavedChanges) {
      setOpenUnsavedChangesModal(true);
      return;
    }
    goBack();
  };

  const handleDiscardChanges = () => {
    setUnsavedChanges(false);
    setOpenUnsavedChangesModal(false);
    goBack();
  };

  const handleOnImport = async ({ target }: any) => {
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      let cleanName = file.name.replace(/[^a-zA-Z0-9.]/g, "");

      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const loadingTask = pdfjs.getDocument(event.target?.result as any);
          const pdf = await loadingTask.promise;

          const page = await pdf.getPage(1);
          const { width, height } = page.getViewport({ scale: 1 });
          if (width > 2400 || height > 3400) {
            showMessage("Dimensiones de pdf inválidas", "error");
            return;
          }
          const filePath = await uploadFileToAWS(
            file,
            importFilesPath,
            cleanName
          );
          if (filePath) {
            importPDF({
              args: {
                IdTeam,
                IdProcessTemplate: IdProcess,
                IdField,
                PdfLink: filePath,
              },
            });
          } else console.error("Upload failed");
        } catch (e) {
          console.log(e);
        }
      };

      reader.onerror = console.log;

      reader.readAsArrayBuffer(file);
    }
  };

  const handleOnSave = () => {
    let pdfConfig = _.cloneDeep(documentData);
    pdfConfig.elementsOrigin = pdfConfig.elementsOrigin.map((element) => {
      if (element.type === "text") {
        // @ts-ignore
        element.value = editorStateRaw(element.value as EditorState);
      }
      return element;
    });

    updatePDFFieldConfiguration({
      args: {
        IdTeam,
        IdProcessTemplate: IdProcess,
        IdField,
        Configuration: pdfConfig,
      },
    });
  };

  return (
    <ViewContainer>
      {openUnsavedChangesModal && (
        <UnsavedChanges
          discardChanges={handleDiscardChanges}
          saveChanges={() => {
            handleOnSave();
            setOpenUnsavedChangesModal(false);
          }}
          closeModal={() => setOpenUnsavedChangesModal(false)}
        />
      )}

      <Prompt
        when={unsavedChanges}
        message={(location) => {
          const routeName =
            routes.find((route) => `/home${route.path}` === location.pathname)
              ?.name ?? "la página anterior";
          return `Tienes cambios sin guardar. ¿Estás seguro de que quieres ir a ${routeName}?`;
        }}
      />

      {importedDocumentDataCache && (
        <ImportPDFConfirmation
          discardImport={() => setImportedDocumentDataCache(null)}
          acceptImport={applyImportedDataChanges}
        />
      )}

      <Header
        syncing={syncing}
        unsavedChanges={unsavedChanges}
        importInputRef={importInputRef}
        handleGoBack={handleGoBack}
        handleOnImport={handleOnImport}
        handleOnSave={handleOnSave}
        fieldLabel={fieldData?.GlobalFields.Label}
      />
      {pdfConfigGetted && pdfFieldsGetted && pdfProcessFieldsGetted ? (
        <PDFConfig
          documentData={documentData}
          setDocumentData={setDocumentData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onDocumentDataChange={() => setUnsavedChanges(true)}
          fieldEditorOrigin={fieldEditorOrigin}
          fieldImageOrigin={fieldImageOrigin}
          filesPath={filesPath}
          importingPDF={importingPDF}
          teamLogo={teamLogo}
        />
      ) : (
        <Loading simple />
      )}
    </ViewContainer>
  );
};

export default PDFConfigurator;
