import styled from "styled-components";
import ViewMore from "../../resources/img/DETALLE_DE_SEGUIMIENTO_ICONO.svg";
import ViewMoreOutline from "../../resources/img/ViewMoreOutline.svg";
import ViewMoreHover from "../../resources/img/HOVER_PROCESS_DETAIL.svg";

export const IconContainer = styled.div<{
  size: number;
  fillColor?: string;
  hoverColor?: string;
  hoverContainerColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  color: ${({ fillColor }) => (fillColor ? fillColor : "#0273e9")};
  :hover {
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : "#0273e9")};
    ${({ hoverContainerColor }) =>
      hoverContainerColor && `background-color: ${hoverContainerColor}`};
  }
`;

export const RedDot = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 2.5px;
  background: #e11909;
  margin-left: -0.4rem;
`;

export const ActionsButton = styled.div`
  background-color: #0273e9;
  color: white;
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
`;

export const ViewMoreIconImg = styled(IconContainer)<{ colored: boolean }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-image: url(${({ colored }) =>
    colored ? ViewMore : ViewMoreOutline});
  :hover {
    background-image: url(${ViewMoreHover});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }
`;
