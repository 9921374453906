import React from "react";
import DataTypeIcons from "../../../../../../../components/DataTypeIcons";
import GeestSelect from "../../../../../../../GeestUI/GeestSelect/GeestSelect";
import {
  TriggerFormTitle,
  WebHookFieldsContainer,
  HookFieldLabel,
  NoHookFieldsContainer,
  NoHookFieldsSubtitle,
  NoHookFieldsTitle,
} from "../Style";
import { useFetch, useMutation } from "../../../../../../../hooks";
import { useParams } from "react-router-dom";
import { GetHookTriggerFieldsResponse } from "../../TriggersConfig.d";
import { Row } from "antd";
import { Button } from "../../../../../../../GeestUI";
import noHookFieldsIcon from "../../../../../../../resources/img/noHookFieldsIcon.svg";

interface EditHookFieldsViewProps {
  IdTeam: string;
  IdPrepublishTrigger: string;
  setView: (view: string) => void;
  Label?: string;
  selectedRequest?: number | null;
  json: { [key: string]: any } | undefined;
  setJson: (json: { [key: string]: number }) => void;
}

const EditHookFieldsView: React.FC<EditHookFieldsViewProps> = ({
  IdTeam,
  IdPrepublishTrigger,
  setView,
  Label,
  selectedRequest,
  json,
  setJson,
}) => {
  const { IdProcess: IdProcessTemplate } = useParams<{ IdProcess: string }>();
  const { data, loading: loadInfo } = useFetch<GetHookTriggerFieldsResponse[]>({
    func: "Ver2-Configurator-ghtf",
    args: {
      IdTeam,
      IdProcessTemplate,
    },
  });

  const [updateTriggerInfo, loading] = useMutation({
    func: "Ver2-Configurator-utdi",
  });

  const getSelectValue = (id: number) => {
    if (json) {
      return Object.keys(json).find((key) => json[key] === id);
    }
    return null;
  };

  const getSelectOptions = (id: number) => {
    if (json) {
      return Object.keys(json)
        .filter(
          (key) =>
            !data?.map(({ Id }) => Id).includes(json[key]) || json[key] === id
        )
        .map((key) => ({ label: key, value: key }));
    }
    return [];
  };

  const onChangeSelect = (value: string | null, id: number, prevValue: any) => {
    if (json) {
      let jsonAux = { ...json };
      if (value) {
        jsonAux[value] = id;
      }
      if (prevValue) {
        jsonAux[prevValue] = null;
      }
      setJson(jsonAux);
    }
  };

  const handleOnUpdate = () => {
    if (json) {
      let FieldsJson: { [key: string]: any } = {};
      Object.keys(json).forEach((key) => {
        if (data?.map(({ Id }) => Id).includes(json[key])) {
          FieldsJson[key] = json[key];
        }
      });
      updateTriggerInfo({
        args: {
          IdTeam,
          IdPrepublishTrigger,
          Type: "Webhook",
          FieldsJson,
          Label,
          IdRequest: selectedRequest,
        },
      });
    }
  };

  return (
    <WebHookFieldsContainer>
      <TriggerFormTitle>
        Selecciona los campos de registro de prueba
      </TriggerFormTitle>
      {!data?.length && !loadInfo && (
        <NoHookFieldsContainer>
          <NoHookFieldsTitle>
            No tienes campos para configurar
          </NoHookFieldsTitle>
          <NoHookFieldsSubtitle>
            Solo puedes vincular campos de número, fecha, fecha y hora, texto,
            teléfono y moneda
          </NoHookFieldsSubtitle>
          <img
            src={noHookFieldsIcon}
            height="300px"
            width="400px"
            alt="no-hook-fields-icon"
          />
        </NoHookFieldsContainer>
      )}
      <div style={{ overflow: "auto", height: "460px", marginBottom: "10px" }}>
        {data?.map(({ DataType, Label, Id }) => (
          <div key={Id}>
            <Row align="middle" style={{ marginBottom: "2px" }}>
              <img
                src={DataTypeIcons[DataType].icon}
                alt=""
                style={{
                  width: "14px",
                  height: "14px",
                  ...DataTypeIcons[DataType]?.extraStyles,
                }}
              />
              <HookFieldLabel>{Label}</HookFieldLabel>
            </Row>
            <GeestSelect
              value={getSelectValue(Id)}
              options={getSelectOptions(Id)}
              onChange={(val) => onChangeSelect(val, Id, getSelectValue(Id))}
            />
          </div>
        ))}
      </div>
      <Row style={{ width: "100%" }} justify="end">
        <Row style={{ width: "272px" }} justify="space-between">
          <Button type="secondary" onClick={() => setView("webhookTrigger")}>
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={handleOnUpdate}
            loading={loading}
            disabled={!data?.length}
          >
            Vincular
          </Button>
        </Row>
      </Row>
    </WebHookFieldsContainer>
  );
};

export default EditHookFieldsView;
