import React, { MouseEventHandler } from "react";
import { IconContainer } from "./styles";
import { IoIosArrowBack } from "react-icons/io";

const BackIcon: React.FC<{
  onClick: MouseEventHandler<HTMLDivElement>;
  size?: number;
  fillColor?: string;
  containerStyle?: object;
}> = ({ onClick, size = 18, fillColor }) => (
  <IconContainer size={20} onClick={onClick} fillColor={fillColor}>
    <IoIosArrowBack color="#48505E" size={size} />
  </IconContainer>
);

export default BackIcon;
