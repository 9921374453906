import styled from "styled-components";
import { device } from "../../../constants/css";

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Content = styled.div`
  width: 100%;
  flex: 1;
  margin-top: 19px;
`;

export const Table = styled.div``;

export const TableHeader = styled.div`
  display: flex;
  background-color: #f2f8fe;
  color: #48505e;
  font-size: 14px;
  font-family: Gotham-Bold;
  height: 40px;
`;

export const TableHeaderItem = styled.div`
  padding: 0.5rem;
  flex-basis: 25%;
`;

export const TableRow = styled.div`
  cursor: pointer;
  display: flex;
  padding: 0.5rem 0;
  color: #828d9e;
  font-family: Gotham-Book;
  border-bottom: 1px solid #828d9e;
  font-size: 14px;
  transition: background-color 0.35s ease;
  height: 40px;
  align-items: center;
  :hover {
    background-color: #edecec;
  }
  :active {
    background-color: #f2f8fe;
  }
`;

export const StarContainer = styled(CenterContainer)`
  width: 40px;
`;

export const TableRowItem = styled.div`
  padding: 0.5rem;
  flex-basis: 25%;
`;

export const AddTeamContainer = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: 0.5rem;
  right: 0.5rem;
  @media ${device.tablet} {
    bottom: 4rem;
    right: 3rem;
  }
`;

export const AddTeamPopoverContent = styled.div`
  width: 100%;
`;

export const AddTeamTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #48505e;
  font-size: 16px;
  font-family: Gotham-Bold;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const StyledInput = styled.input`
  border: 2px solid rgba(237, 236, 236, 1);
  color: rgba(130, 141, 158, 1);
  border-radius: 6px;
  padding-left: 10px;
  width: calc(100% - 24px);
  height: 32px;
  font-size: 14px;
  font-weight: 300;
  font-family: Gotham-Book;
  margin: 12px;
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
  :focus-visible: {
    outline: none;
  }
`;
