import styled from "styled-components";
import { Row } from "antd";

export const UserContainer = styled(Row)`
  border: 5px;
  background: transparent;
`;

interface profileArgs {
  image?: string;
  picSize?: string;
}

export const UserInitials = styled(Row)<profileArgs>`
  width: ${({ picSize = "35px" }) => picSize};
  height: ${({ picSize = "35px" }) => picSize};
  border-radius: 50%;
  margin-right: 5px;
  background: linear-gradient(
    180deg,
    #4b86c0 0%,
    #4d85c0 17.65%,
    #4f7cb7 52.55%,
    #5667a4 100%
  );
  font-size: 10px;
  color: #ffffff;
`;

export const UserProfilePic = styled(Row)<profileArgs>`
  width: ${({ picSize = "35px" }) => picSize};
  height: ${({ picSize = "35px" }) => picSize};
  border-radius: 50%;
  margin-right: 5px;
  background-image: ${({ image }) => `url("${image}")`};
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  font-weight: 325;
  font-size: 15px;
  color: #ffffff;
`;
