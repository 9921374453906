import React from "react";
import { ArticleWrapper, SectionWrapper, StyledLink } from "../Styles";

interface UtilsLinkProps {
  isActive: () => boolean;
  setSection: () => void;
}

const SectionName = "Utils";

export const UtilsLink: React.FC<UtilsLinkProps> = ({
  isActive,
  setSection,
}) => {
  return (
    <StyledLink onClick={setSection} $isActive={isActive()}>
      {SectionName}
    </StyledLink>
  );
};

interface UtilsSectionProps {}

export const UtilsSection: React.FC<UtilsSectionProps> = () => {
  return (
    <SectionWrapper>
      <h2>{SectionName}</h2>
      <ArticleWrapper>
        <p>
          El componente provee una serie de funciones de utilería para trabajar
          con el estado del editor para cubrir multiples necesidades y
          propósitos.
        </p>
        <p>
          Las funciones disponibles son:{" "}
          <code className="code">editorStateToHTML</code>,{" "}
          <code className="code">editorStateToRaw</code>,{" "}
          <code className="code">getLengthOfSelectedText</code> y{" "}
          <code className="code">getContentLength</code>
        </p>
        <p>
          Si revisas el código del editor, podrás notar que existen varias
          funciones más dentro de la carpeta utils, además de algunas funciones
          de utilería dentro del archivo con el que se genera la toolbar, pero
          estas no suelen tener mucha utilidad fuera del propio editor, por lo
          que serán explicadas en la sección{" "}
          <code className="code">Extender</code>.
        </p>

        <h3>
          editorStateToHTML
          <span className="info">
            (editorState: <span className="type">EditorState</span>) =&gt;{" "}
            <span className="type">string</span>
          </span>
        </h3>
        <p>
          Esta función recibe un estado, lo convierte a un JSON simple
          utilizando la función <code className="code">editorStateRaw</code> y
          luego utiliza la función de una librería llamada{" "}
          <code className="code">draftjs-to-html</code> para convertir este json
          a html en un string. Si el código no ha sido posible de convertir,
          retorna el string <code className="code type">"error"</code>.
        </p>

        <h3>
          editorStateToRaw
          <span className="info">
            (editorState: <span className="type">EditorState</span>) =&gt;{" "}
            <span className="type">string</span>
          </span>
        </h3>
        <p>
          Esta función recibe un estado y lo convierte a un JSON utilizando la
          función convertToRaw de <code className="code">draft-js</code>. Si la
          conversión no ha sido posible, retorna el string{" "}
          <code className="code type">"error"</code>.
        </p>

        <h3>
          getLengthOfSelectedText
          <span className="info">
            (editorState <span className="type">EditorState</span>) =&gt;{" "}
            <span className="type">number</span>
          </span>
        </h3>
        <p>
          Esta función tiene una utilidad principalmente interna pero podría ser
          de utilidad. Recibe un estado, de este extrae la selección que hay en
          el mismo, el contenido, la key del bloque seleccionado y la key donde
          termina la selección. Con estas, busca el bloque entero dentro del
          contenido actual del estado. Además, guarda el largo del bloque de
          inicio y el largo del bloque de fin y la key después del final.
        </p>
        <p>
          Luego verifica si el bloque de inicio es el mismo del bloque de fin en
          la selección, de ser así, simplemente realiza un offset de la
          selección, en caso contrario, recorre los bloques utilizando las keys
          para ir haciendo una sumatoria de los lengths de los bloques
          involucrados en dicha selección.
        </p>

        <h3>
          getContentLength
          <span className="info">
            (editorState <span className="type">EditorState</span>) =&gt;{" "}
            <span className="type">number</span>
          </span>
        </h3>
        <p>
          Esta función retorna la cuenta total de caracteres dentro de un editor
          state, sacando el contenido actual del mismo para luego convertirlo en
          texto plano y así obtener una cuenta del largo de ese string.
        </p>
        <p className="note">
          Es importante resaltar que al ser una cuenta con texto plano, no toma
          en consideración, por ejemplo, el largo real de un label en un campo
          de tipo field, ya que en realidad este componente es solo un decorador
          para un texto que sirve como template. Para saber más sobre el
          elemento de campo puedes consultar en la sección{" "}
          <code className="code">Extender</code>.
        </p>
      </ArticleWrapper>
    </SectionWrapper>
  );
};
