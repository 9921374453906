import styled from "styled-components";
import { StepConnector, makeStyles, withStyles } from "@material-ui/core";

export const ModalTabContainer = styled.div`
  margin: 24px 24px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;

  .MuiStepper-root {
    padding: 0;
    flex: 1;
  }

  .MuiStepLabel-root {
    cursor: pointer;
  }
`;

export const useDotStyles = makeStyles({
  root: {
    backgroundColor: "#edecec",
    minWidth: 17,
    width: 17,
    minHeight: 17,
    height: 17,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.35s ease",
    zIndex: 2,
  },
  active: {
    background: "#0273e9",
  },
  completed: {
    background: "#0273e9",
  },
});

export const StepperConnector = withStyles({
  alternativeLabel: {
    top: 7,
    transition: "all 0.35s ease",
  },
  active: {
    "& $line": {
      background: "#0273e9",
    },
  },
  completed: {
    "& $line": {
      background: "#0273e9",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#edecec",
    transition: "all 0.35s ease",
    zInex: 1,
  },
})(StepConnector);

export const StepLabelValue = styled.p<{ active: boolean }>`
  font-family: ${({ active = false }) => (active ? "Gotham-Bold" : "")};
  font-size: 14px;
  text-align: center;
  color: #48505e;
  margin: 0;
  justify-content: center;
`;
