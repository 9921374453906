import React from "react";
import { IoIosClose } from "react-icons/io";
import { Element } from "../../PDFConfig.d";
import {
  DeleteIconContainer,
  DraggableItem,
  ResizableContent,
  SignName,
  StyledResizable,
} from "./styles";

interface SignNodeProps {
  currentElement: string | null;
  setCurrentElement: (id: string | null) => void;
  elementToConfig: string | null;
  setElementToConfig: (id: string | null) => void;
  componentKey: string;
  element: Element;
  signName: string;
  onStart: () => void;
  onStop: () => void;
  onControlledDrag: (e: any, position: any) => void;
  onResizeStart: () => void;
  onResize: (e: any, data: any) => void;
  onResizeStop: () => void;
  onDeleteElement: () => void;
}

const SignNode: React.FC<SignNodeProps> = ({
  currentElement,
  setCurrentElement,
  elementToConfig,
  setElementToConfig,
  componentKey,
  element,
  signName,
  onStart,
  onStop,
  onControlledDrag,
  onResizeStart,
  onResize,
  onResizeStop,
  onDeleteElement,
}) => {
  const dragHandlers = { onStart, onStop };
  const isSelected = element.id === currentElement;
  const isConfig = element.id === elementToConfig;

  const onSelectElement = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentElement(element.id);
    setElementToConfig(element.id);
  };

  const hanldeResize = (e: any, data: any) => {
    if (!isSelected) return false;
    onResize(e, data);
  };

  return (
    <DraggableItem
      bounds="parent"
      position={element.position}
      {...dragHandlers}
      onDrag={onControlledDrag}
    >
      <StyledResizable
        width={element.boxSize.width}
        height={element.boxSize.height}
        selected={isSelected}
        minConstraints={[80, 40]}
        onResizeStart={onResizeStart}
        onResize={hanldeResize}
        onResizeStop={onResizeStop}
      >
        <ResizableContent
          key={componentKey}
          selected={isSelected || isConfig}
          width={element.boxSize.width}
          height={element.boxSize.height}
          onClick={onSelectElement}
        >
          <SignName>{signName}</SignName>

          {isSelected && (
            <DeleteIconContainer onClick={onDeleteElement}>
              <IoIosClose size={20} />
            </DeleteIconContainer>
          )}
        </ResizableContent>
      </StyledResizable>
    </DraggableItem>
  );
};

export default SignNode;
