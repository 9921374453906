export const Languages = {
  ENG: {
    TitlePostpone: "Postpone task",
    TitleReschedule: "Reschedule task",
    ReminderLabel: "Reminder",
    ReminderPlaceholder: "When task start, remind me...",
    AddTimeInputTitlePostpone: "Postpone",
    AddTimeInputTitleReschedule: "Reschedule",
    InLabel: "in",
    NextLabel: "next",
    PostponeToDateLabel: "Postpone task to date",
    RescheduleToDateLabel: "Reschedule task to date",
    DatePickerPlaceHolder: "Select...",
    PostponeButtonLabel: "Postpone",
    RescheduleButtonLabel: "Reschedule",
    SaveButtonLabel: "Save",
    TimeSelet: {
      minutes: "Minute(s)",
      hours: "Hour(s)",
      days: "Day(s)",
      weeks: "Week(s)",
      months: "Month(s)",
    },
    WeekDays: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
    },
  },
  ESP: {
    TitlePostpone: "Posponer tarea",
    TitleReschedule: "Reagendar tarea",
    ReminderLabel: "Recordatorio",
    ReminderPlaceholder: "Cuando la tarea inicie, recuérdame...",
    AddTimeInputTitlePostpone: "Posponer",
    AddTimeInputTitleReschedule: "Reagendar",
    InLabel: "en",
    NextLabel: "el próximo",
    PostponeToDateLabel: "Posponer tarea a la fecha",
    RescheduleToDateLabel: "Reagendar tarea a la fecha",
    DatePickerPlaceHolder: "Selecciona...",
    PostponeButtonLabel: "Posponer",
    RescheduleButtonLabel: "Reagendar",
    SaveButtonLabel: "Guardar",
    TimeSelet: {
      minutes: "Minuto(s)",
      hours: "Hora(s)",
      days: "Día(s)",
      weeks: "Semana(s)",
      months: "Mes(es)",
    },
    WeekDays: {
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado",
      sunday: "Domingo",
    },
  },
};
