import styled from "styled-components";
import { Row } from "antd";
import { IoClose } from "react-icons/io5";
import { Tag } from "../../../../GeestUI";

export const FiltersContent = styled.div`
  margin-bottom: 1rem;
  color: #727e8b;
`;

export const OpenFiltersButton = styled.button`
  width: 25px;
  height: 25px;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: color 0.35s ease;
  &:hover {
    color: #222;
  }
`;

export const FiltersHeaders = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const FilterContainer = styled.div<{ $flex: string; $visible: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  flex: ${({ $flex = "1" }) => $flex};
  margin-left: 0.5rem;
  overflow: hidden;
  min-width: 80px;

  transition: opacity 0.35s ease, height 0.35s ease;
  opacity: ${({ $visible = true }) => ($visible ? 1 : 0)};
  height: ${({ $visible = true }) => ($visible ? "" : "0px")};
`;

export const FilterContainerAlt = styled.div<{
  $flex: string;
  $visible: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  flex: ${({ $flex = "1" }) => $flex};
  margin-left: 0.5rem;

  transition: background-color 0.35s ease;
  background-color: ${({ $visible = true }) =>
    $visible ? "transparent" : "#eee"};
`;

export const FiltersInputs = styled.div<{ $extended: boolean }>`
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: height 0.35s ease, opacity 0.35s ease;
  height: ${({ $extended = false }) => ($extended ? "30px" : "0")};
  opacity: ${({ $extended = false }) => ($extended ? "1" : "0")};
`;

export const StyledInputBox = styled.div`
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  align-items: center;
  width: 100%;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #c0c9d3;
`;

export const StyledTag = styled(Tag)<{ $selected?: boolean }>`
  border-radius: 6px;
  border-width: 2px;
  font-size: ${({ $selected = false }) => ($selected ? "14px" : "10px")};
  font-family: ${({ $selected = false }) =>
    $selected ? "Gotham-Book" : "Gotham-Bold"};
`;

export const CloseIcon = styled(IoClose)`
  cursor: pointer;
  :hover {
    color: black;
  }
`;

export const FiltersCloseIcon = styled(IoClose)`
  color: white;
`;

export const FiltersResetButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: background-color 0.35s ease, opacity 0.35s ease;
  :hover {
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 1;
  }
`;

export const StatusContainer = styled(Row)<{ $selected?: boolean }>`
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.35s ease;
  background: ${({ $selected = false }) =>
    $selected ? "var(--silver)" : "transparent"};
  :hover {
    background: var(--silver);
  }
`;

export const PopoverContentWrapper = styled.div`
  width: calc(350px - 32px);
`;

export const UserContainer = styled(Row)<{ $selected?: boolean }>`
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.35s ease;
  background: ${({ $selected = false }) =>
    $selected ? "var(--silver)" : "transparent"};
  :hover {
    background: var(--silver);
  }
`;

export const ProcessesBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ProcessContainer = styled(Row)<{ $selected?: boolean }>`
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.35s ease;
  background: ${({ $selected = false }) =>
    $selected ? "var(--silver)" : "transparent"};
  :hover {
    background: var(--silver);
  }
`;

export const StageContainer = styled(Row)<{ $selected?: boolean }>`
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.35s ease;
  background: ${({ $selected = false }) =>
    $selected ? "var(--silver)" : "transparent"};
  :hover {
    background: var(--silver);
  }
`;
