import React from "react";
import _ from "lodash";
import { FieldOrigin, Subflux } from "./SubfluxEditor.d";
import { getDataTypeIcon } from "../../../helpers";
import { OptionLabel } from "../../TaskFields/EditField/Styles";
import { FieldDataTypes } from "../../../Configurator.d";

export const getSelectedFieldValue = (
  IdFieldDestiny: string,
  subflux: Subflux
): string => {
  const value = _.find(subflux.StartFields, { IdFieldDestiny });
  return value?.IdFieldOrigin || "";
};

const getLabel = (DataType: FieldDataTypes, Label: string) => {
  const DataTypeIcon = getDataTypeIcon(DataType);
  return (
    <OptionLabel>
      <DataTypeIcon size={18} style={{ marginRight: "5px" }} />
      {Label}
    </OptionLabel>
  );
};

export const possibleOriginsToSelectOption = (
  origins: FieldOrigin[]
): { label: React.ReactNode; value: string }[] =>
  _.map(origins, ({ IdFieldOrigin, Label, DataType }) => ({
    value: IdFieldOrigin,
    label: getLabel(DataType, Label),
    searchableValues: Label,
  }));

export const getSelectedChatValue = (
  IdPrepublishTemplateChatDestiny: number,
  subflux: Subflux
): number | "" => {
  const value = _.find(subflux.Chats, { IdPrepublishTemplateChatDestiny });
  return value?.IdPrepublishProcessTemplateChatOrigin || "";
};
