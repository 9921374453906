import styled from "styled-components";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";

export const DraggableItem = styled(Draggable)``;

export const StyledResizable = styled(Resizable)<{ selected: boolean }>`
  .react-resizable-handle {
    background: none;

    &::after {
      content: "";
      position: absolute;
      right: 3px;
      bottom: 3px;
      width: 5px;
      height: 5px;

      border-right: 2px solid
        ${({ selected = false }) =>
          selected ? "rgba(0, 0, 0, 0.4)" : "transparent"};
      border-bottom: 2px solid
        ${({ selected = false }) =>
          selected ? "rgba(0, 0, 0, 0.4)" : "transparent"};
    }
  }

  .react-resizable-handle-se {
    cursor: ${({ selected = false }) => (selected ? "re-size" : "auto")};
  }
`;

export const ResizableContent = styled.div<{
  width: number;
  height: number;
  selected: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  padding: 16px;
  border-radius: 6px;
  background-color: #f2f8fe;
  border: 2px solid;
  transition: border-color 0.35s ease;

  width: ${({ width = 300 }) => width}px;
  height: ${({ height = 50 }) => height}px;
  border-color: ${({ selected = false }) => (selected ? "#0273e9" : "#edecec")};
`;

export const SignName = styled.p`
  margin: 0;
  color: #48505e;
  font-family: Gotham-Bold;
  text-align: center;
  user-select: none;
`;

export const DeleteIconContainer = styled.div`
  position: absolute;
  top: -9px;
  right: -9px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  background-color: #0067b0;
  color: white;
  border-radius: 50%;
  cursor: pointer;

  transition: background-color 0.35s ease;
  :hover {
    background-color: #014c9b;
  }
`;
