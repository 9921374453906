import React, { useState } from "react";
import _ from "lodash";
import { AiOutlinePlus } from "react-icons/ai";
import { Row, Space } from "antd";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
//@ts-ignore
import emoji from "emoji-dictionary";
import CloseIcon from "../../../../../../../components/hoverIcons/CloseIcon";
import Button from "../../../../../../../GeestUI/Button";
import { Tag, Typography } from "../../../../../../../GeestUI";
import { ProcessOrder } from "./Order";
import OrderIcon from "./OrderIcon";
import {
  Content,
  FormatCellValue,
  Header,
  ProcessTitle,
  SearchContainer,
  SearchInput,
  StyledCell,
  StyledModal,
  StyledTableContainer,
  StyledTag,
  TableWrapper,
  Title,
} from "./Styles";
import {
  Measurement,
  Order,
  ProcessInformationData,
  ProcessOrderType,
  ProcessStatus,
  TableDetailProps,
  TableInformation,
  TemplateOrderType,
} from "./TableDetail.d";
import TemplateOrder from "./Order/TemplateOrder";
import Tooltip from "../../../../../../../components/Tooltip";
import { Circle } from "../../../../../../../components/ColorPicker";
import moment from "moment";
import { useFetch } from "../../../../../../../hooks";
import Loading from "../../../../../../../components/Loading";
import { sanitizeSearchValues } from "../../../../../../../Helpers";
import {
  getCountryFlag,
  getSymbolByCode,
} from "../../../../../../../components/DynamicInput/Components/helpers/CurrencyHelpers";

const { Text } = Typography;

const TableDetail: React.FC<TableDetailProps> = ({
  closeModal,
  idStat,
  metaData,
  setDetailProceessId,
}) => {
  const [filter, setFilter] = useState<string>("");
  const [order, setOrder] = useState<Order>({});
  const [orderHierarchy, setOrderHierarchy] = useState<string[]>([]);

  const [originalTableData, setOriginalTableData] = useState<TableInformation>({
    CanExportReportData: false,
    MeasurementName: "Celda",
    RowName: "Fila",
    ProcessInformation: [],
  });
  const [orderedTableData, setOrderedTableData] = useState<TableInformation>({
    CanExportReportData: false,
    MeasurementName: "Celda",
    RowName: "Fila",
    ProcessInformation: [],
  });
  const [filteredTableData, setFilteredTableData] = useState<TableInformation>({
    CanExportReportData: false,
    MeasurementName: "Celda",
    RowName: "Fila",
    ProcessInformation: [],
  });

  const [processExecutionOrder, setProcessExecutionOrder] =
    useState<ProcessOrderType>("IdProcessExecution");
  const [processTemplateOrder, setProcessTemplateOrder] =
    useState<TemplateOrderType>("ProcessTemplateName");

  const { loading: gettingGraphTableDetail } = useFetch<TableInformation>({
    func: "Ver2-DashBoards-ggtd",
    args: {
      ...metaData,
      IdStat: idStat,
    },
    onSuccess: (tableData) => {
      setOriginalTableData(tableData);
      setOrderedTableData(tableData);
      setFilteredTableData(tableData);
    },
  });

  const filterData = (data: TableInformation, search: string) => {
    const { ProcessInformation } = data;
    const filteredProcesses = _.filter(
      ProcessInformation,
      (process: ProcessInformationData) => {
        for (const propery of [
          `${process.IdProcessExecution}`,
          sanitizeSearchValues(process.Name),
          sanitizeSearchValues(process.ProcessTemplateName),
          `${(process.Measurement as Measurement)?.Value}`,
          sanitizeSearchValues(dateHourFormat(process.StartedAt, true)),
          sanitizeSearchValues(dateHourFormat(process.EndedAt, true)),
          sanitizeSearchValues(process.Stage?.Label),
        ])
          if (propery.includes(sanitizeSearchValues(search))) return true;
        return false;
      }
    );

    setFilteredTableData({ ...data, ProcessInformation: filteredProcesses });
  };

  const handleSearch = (search: string) => {
    setFilter(search);
    if (search === "") {
      setFilteredTableData(orderedTableData);
      return;
    }

    filterData(orderedTableData, search);
  };

  const handleReorder = (
    order: Order,
    hierarchy: string[] = orderHierarchy
  ) => {
    const orders = hierarchy.map((h) => order[h]);
    const newTable = _.orderBy(
      originalTableData.ProcessInformation,
      hierarchy,
      orders
    );
    setOrderedTableData({ ...originalTableData, ProcessInformation: newTable });
    handleSearch(filter);
  };

  const onOrder = (key: string | number) => {
    let orderAux = { ...order };
    let hierarchy = [...orderHierarchy];

    if (orderAux[key] === "asc") {
      orderAux[key] = "desc";
    } else if (orderAux[key] === "desc") {
      delete orderAux[key];
      const index = orderHierarchy.indexOf(String(key));
      if (index !== -1) {
        hierarchy.splice(index, 1);
      }
    } else {
      orderAux[key] = "asc";
      hierarchy.push(String(key));
    }
    setOrder(orderAux);
    setOrderHierarchy(hierarchy);
    handleReorder(orderAux, hierarchy);
  };

  const onChangeProcessExecutionOrder = (processOrder: ProcessOrderType) => {
    let orderAux = { ...order };
    if (orderAux["Name"] && processOrder !== "Name") {
      orderAux[processOrder] = orderAux["Name"];
      handleReorder(orderAux);
    }
    if (
      orderAux["IdProcessExecution"] &&
      processOrder !== "IdProcessExecution"
    ) {
      orderAux[processOrder] = orderAux["IdProcessExecution"];
      handleReorder(orderAux);
    }
    if (orderAux["Status"] && processOrder !== "Status") {
      orderAux[processOrder] = orderAux["Status"];
      handleReorder(orderAux);
    }
    setProcessExecutionOrder(processOrder);
  };

  const onChangeProcessTemplateOrder = (templateOrder: TemplateOrderType) => {
    let orderAux = { ...order };
    if (
      orderAux["ProcessTemplateName"] &&
      templateOrder !== "ProcessTemplateName"
    ) {
      orderAux[templateOrder] = orderAux["ProcessTemplateName"];
      handleReorder(orderAux);
    }
    if (orderAux["IdStage"] && templateOrder !== "IdStage") {
      orderAux[templateOrder] = orderAux["IdStage"];
      handleReorder(orderAux);
    }
    setProcessTemplateOrder(templateOrder);
  };

  const getStatusInfo = (
    status: ProcessStatus
  ): { color: string; label: string } => {
    switch (status) {
      case "New":
        return { color: "yellow", label: "Nuevo" };
      case "OnTime":
        return { color: "cyan", label: "Iniciado" };
      case "Completed":
        return { color: "green", label: "Terminado" };
      case "Canceled":
        return { color: "default", label: "Cancelado" };
      case "WaitingToStart":
        return { color: "orange", label: "Por iniciar" };
      case "Stuck":
        return { color: "red", label: "Vencido" };
      default:
        return { color: "default", label: status };
    }
  };

  const dateHourFormat = (date: any, hideYear = false) => {
    if (moment(new Date(date)).isValid()) {
      if (hideYear) {
        return moment(date).format("DD/MMM HH:mm");
      }
      return moment(date).format("DD/MMM/YY HH:mm");
    } else {
      return "Invalid format";
    }
  };

  const getFormatedNumber = (val: string) =>
    val ? val.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";

  return (
    <StyledModal
      open
      footer={null}
      closable={false}
      centered
      width="850px"
      onCancel={closeModal}
      bodyStyle={{
        height: "90vh",
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Gotham-Book",
        padding: "12px",
      }}
    >
      <Header>
        <Title>
          Detalle de {originalTableData.MeasurementName}{" "}
          {originalTableData.RowName}
        </Title>
        <CloseIcon size={20} onClick={closeModal} color="#48505e" />
      </Header>

      {gettingGraphTableDetail ? (
        <Loading />
      ) : (
        <Content>
          <SearchContainer>
            <SearchInput
              value={filter}
              onChange={({ target: { value } }) => handleSearch(value)}
              placeholder="Buscar"
            />
            {originalTableData.CanExportReportData && (
              <Button type="primary" Icon={AiOutlinePlus}>
                Exportar
              </Button>
            )}
          </SearchContainer>

          <TableWrapper>
            <StyledTableContainer>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => onOrder(processExecutionOrder)}>
                      <Row
                        align="middle"
                        justify="space-between"
                        wrap={false}
                        style={{ width: "100%" }}
                      >
                        <Row align="middle" wrap={false}>
                          Seguimiento
                          <OrderIcon Direction={order[processExecutionOrder]} />
                        </Row>
                        <ProcessOrder
                          processOrder={processExecutionOrder}
                          setProcessOrder={onChangeProcessExecutionOrder}
                        />
                      </Row>
                    </TableCell>

                    <TableCell onClick={() => onOrder(processTemplateOrder)}>
                      <Row
                        align="middle"
                        justify="space-between"
                        wrap={false}
                        style={{ minWidth: "196px" }}
                      >
                        <Row align="middle" wrap={false}>
                          Proceso
                          <OrderIcon Direction={order[processTemplateOrder]} />
                        </Row>
                        <TemplateOrder
                          templateOrder={processTemplateOrder}
                          setTemplateOrder={onChangeProcessTemplateOrder}
                        />
                      </Row>
                    </TableCell>

                    <TableCell onClick={() => onOrder("StartedAt")}>
                      <Row align="middle" wrap={false}>
                        Inicio
                        <OrderIcon Direction={order["StartedAt"]} />
                      </Row>
                    </TableCell>

                    <TableCell onClick={() => onOrder("EndedAt")}>
                      <Row align="middle" wrap={false}>
                        Fin
                        <OrderIcon Direction={order["EndedAt"]} />
                      </Row>
                    </TableCell>

                    <TableCell>
                      <Row
                        align="middle"
                        wrap={false}
                        style={{ maxWidth: "200px" }}
                      >
                        {originalTableData.MeasurementName.length > 25 ? (
                          <Tooltip title={originalTableData.MeasurementName}>
                            <p
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                margin: 0,
                              }}
                            >
                              {originalTableData.MeasurementName}
                            </p>
                          </Tooltip>
                        ) : (
                          <p
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              margin: 0,
                            }}
                          >
                            {originalTableData.MeasurementName}
                          </p>
                        )}
                      </Row>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredTableData.ProcessInformation.map((process) => (
                    <TableRow key={process.IdProcessExecution}>
                      <StyledCell>
                        <Space size={6} direction="vertical">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <Tooltip title="Ver detalle de seguimiento">
                              <Text
                                color="#0067B0"
                                style={{ cursor: "pointer", margin: 0 }}
                                onClick={() => {
                                  setDetailProceessId(
                                    process.IdProcessExecution
                                  );
                                }}
                              >
                                Folio {process.IdProcessExecution}
                              </Text>
                            </Tooltip>
                            {process.Name.length < 24 ? (
                              <ProcessTitle>
                                {process.Name.replace(
                                  /:\w+:/gi,
                                  (name) => emoji.getUnicode(name) ?? name
                                )}
                              </ProcessTitle>
                            ) : (
                              <Tooltip
                                title={process.Name.replace(
                                  /:\w+:/gi,
                                  (name) => emoji.getUnicode(name) ?? name
                                )}
                              >
                                <ProcessTitle>
                                  {process.Name.replace(
                                    /:\w+:/gi,
                                    (name) => emoji.getUnicode(name) ?? name
                                  )}
                                </ProcessTitle>
                              </Tooltip>
                            )}
                          </div>
                          <Space wrap={false}>
                            <StyledTag
                              color={getStatusInfo(process.Status).color}
                            >
                              {getStatusInfo(process.Status).label}
                            </StyledTag>
                            {process.Priority && (
                              <Tag color={process.Priority.Color}>
                                {process.Priority.Title}
                              </Tag>
                            )}
                          </Space>
                        </Space>
                      </StyledCell>

                      <StyledCell isEven>
                        <Space size={6} direction="vertical">
                          <Text>{process.ProcessTemplateName}</Text>
                          {process.Stage && (
                            <Space size={4}>
                              <Circle color={process.Stage.Color} />
                              <Text>{process.Stage.Label}</Text>
                            </Space>
                          )}
                        </Space>
                      </StyledCell>

                      <StyledCell
                        style={{
                          padding: "8px",
                        }}
                      >
                        {process.StartedAt &&
                          dateHourFormat(process.StartedAt, true)}
                      </StyledCell>

                      <StyledCell
                        isEven
                        style={{
                          padding: "8px",
                        }}
                      >
                        {process.EndedAt &&
                          dateHourFormat(process.StartedAt, true)}
                      </StyledCell>

                      <StyledCell>
                        {typeof process.Measurement === "string" ? (
                          <p>{process.Measurement}</p>
                        ) : (
                          <FormatCellValue>
                            {process.Measurement.Format &&
                              getCountryFlag(
                                process.Measurement.Format,
                                "14px"
                              )}
                            <p>
                              {`${getSymbolByCode(process.Measurement.Format)}${
                                getFormatedNumber(
                                  `${process.Measurement.Value}`
                                ) || 0
                              }`}
                            </p>
                          </FormatCellValue>
                        )}
                      </StyledCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </TableWrapper>
        </Content>
      )}
    </StyledModal>
  );
};

export default TableDetail;
