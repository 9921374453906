import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Space } from "antd";
import { AiOutlineYoutube } from "react-icons/ai";
import ViewTitle from "../../../components/ViewTitle/ViewTitle";
import ProcessName from "./ProcessName";
import { Button } from "../../../GeestUI";
import { useMutation } from "../../../hooks";
import { RiErrorWarningFill } from "react-icons/ri";
import ProcessActions from "./ProcessActions";
import RocketIcon from "../../../components/hoverIcons/RocketIcon";
import SavingDataStatusComponent from "./SavingDataStatusComponent";

interface HeaderProps {
  refreshProcessInformation: () => void;
  processName?: string;
  needsToBePublished?: boolean;
  reloadFlow: () => void;
  canShare: boolean;
  savingDataStatus: string;
}

const Header: React.FC<HeaderProps> = ({
  processName = "",
  needsToBePublished = false,
  refreshProcessInformation,
  reloadFlow,
  canShare,
  savingDataStatus,
}) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const history = useHistory();

  const [publishProcess, publishing] = useMutation<[]>({
    func: "Ver2-Configurator-p",
    onSuccess: refreshProcessInformation,
  });

  const onClickTutorials: React.MouseEventHandler<HTMLButtonElement> = () =>
    window.open(
      "https://www.youtube.com/playlist?list=PLTwfrEBfpGHYSn7ho9xq7dvo4MGRuklD7",
      "_blank"
    );
  const onClickPublish: React.MouseEventHandler<HTMLButtonElement> = () =>
    publishProcess({
      args: {
        IdTeam,
        IdProcessTemplate,
      },
    });

  const handleGoBack = () => {
    const prevPageExists = history.length > 1;
    prevPageExists ? history.goBack() : history.push("/home/templates");
  };

  return (
    <ViewTitle ShowBack onBack={handleGoBack} ShowTeamIcon headerMT="7px">
      <Row justify="space-between" align="middle">
        <ProcessName
          processName={processName}
          onFinishEditing={() => {
            refreshProcessInformation();
            reloadFlow();
          }}
        />
        <Space>
          <SavingDataStatusComponent savingDataStatus={savingDataStatus} />
          <Button
            Icon={AiOutlineYoutube}
            onClick={onClickTutorials}
            style={{ width: "160px" }}
          >
            Ver tutoriales
          </Button>
          <div style={{ position: "relative" }}>
            <Button
              type="primary"
              disabled={!needsToBePublished}
              loading={publishing}
              onClick={onClickPublish}
              // @ts-ignore
              Icon={RocketIcon}
              style={{ width: "200px" }}
            >
              <span style={{ marginLeft: "8px" }}>Publicar cambios</span>
            </Button>
            {needsToBePublished && (
              <RiErrorWarningFill
                color="#DB232C"
                size={20}
                style={{
                  position: "absolute",
                  top: -8,
                  right: -8,
                  backgroundColor: "#FFFFFF",
                  borderRadius: "50%",
                }}
              />
            )}
          </div>
          <ProcessActions processName={processName} canShare={canShare} />
        </Space>
      </Row>
    </ViewTitle>
  );
};

export default Header;
