import React from "react";
import { IconContainer } from "./styles";
import { HiMenuAlt2 } from "react-icons/hi";

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  colored?: boolean;
}

const FormIcon: React.FC<IconProps> = ({ onClick, colored, ...props }) => {
  return (
    <IconContainer size={20} onClick={onClick} {...props}>
      <HiMenuAlt2 size={16} color={colored ? "#0273E9" : "#48505E"} />
    </IconContainer>
  );
};

export default FormIcon;
