import React, { useState } from "react";
import { Languages } from "./Dictionary";
import {
  GeneralInformationContainer,
  GeneralInfoContent,
  CardTitle,
  GeneralGraphic,
  GraphicContainer,
  GeneralProgressBars,
} from "./Styles";
import { GeneralInformationType, Template } from "../Dashboard.d";
import DashboardGraphic from "./DashboardGraphic";
import BarsContainer from "./BarsContainer";

const { CardTitleLabel } = Languages["ESP"];

interface GeneralInformationProps {
  generalInfo: GeneralInformationType;
  getProcessInfo: (template: Template) => void;
  selectedIndex?: number;
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({
  generalInfo,
  getProcessInfo,
  selectedIndex = 0,
}) => {
  const [selected, setSelected] = useState<number>(
    selectedIndex >= 0 ? selectedIndex : 0
  );

  const handleGetProcessInfo = (template: Template, idx: number) => {
    getProcessInfo(template);
    setSelected(idx);
  };

  return (
    <GeneralInformationContainer>
      <CardTitle>{CardTitleLabel}</CardTitle>
      <GeneralInfoContent>
        <GeneralGraphic>
          <GraphicContainer>
            <DashboardGraphic data={generalInfo.General} width={150} />
          </GraphicContainer>
        </GeneralGraphic>
        <GeneralProgressBars>
          <BarsContainer
            templates={generalInfo.Templates}
            selected={selected}
            getProcessInfo={handleGetProcessInfo}
          />
        </GeneralProgressBars>
      </GeneralInfoContent>
    </GeneralInformationContainer>
  );
};

export default GeneralInformation;
