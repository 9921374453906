import React, { useEffect, useState, useContext } from "react";
import io from "socket.io-client";
import { SesionContext } from "./State";

export const SocketContext = React.createContext(null);

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState();
  const [sesion] = useContext(SesionContext);

  useEffect(() => {
    if (sesion?.Auth && !socket) {
      const socket = io(process.env.REACT_APP_SOCKET_URL, {
        query: {
          auth: sesion.Auth,
        },
        transports: ["websocket"],
      });
      setSocket(socket);
      return () => {
        socket.disconnect();
        setSocket();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sesion?.Auth]);

  return (
    <SocketContext.Provider value={[socket, setSocket]}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
