import React from "react";
import GeestRocket from "../../../../../../../resources/img/GeestRocket.svg";
import { Languages } from "../../Dictionary";
import { ViewContentDefault, CenterItem, TextElement } from "../Style";

const { DefaultText1, DefaultText2, DefaultText3 } = Languages["ESP"];

interface DefaultViewProps {
  onClose: () => void;
}

const DefaultView: React.FC<DefaultViewProps> = ({ onClose }) => {
  return (
    <ViewContentDefault>
      <CenterItem>
        <img src={GeestRocket} width="100px" height="100px" alt="" />
      </CenterItem>
      <CenterItem>
        <TextElement fontSize="45px">{DefaultText1}</TextElement>
      </CenterItem>
      <CenterItem>
        <TextElement fontSize="20px">{DefaultText2}</TextElement>
      </CenterItem>
      <CenterItem>
        <TextElement fontSize="20px">{DefaultText3}</TextElement>
      </CenterItem>
    </ViewContentDefault>
  );
};

export default DefaultView;
