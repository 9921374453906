import React from "react";
import styled, { keyframes } from "styled-components";
import { Row } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { noop as NOOP } from "lodash";

const Container = styled(Row)`
  border: solid 1px #b4b4b4;
  border-radius: 0.5rem;
  height: fit-content;
  width: fit-content;
`;

const NumbCont = styled.input`
  width: 4rem;
  font-family: "Gotham-Medium";
  font-size: x-large;
  color: #5f5f5f;
  align-items: center;
  padding: 3px;
  border: none;
  border-radius: 0.5rem;
  outline: none;
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const RowsCont = styled(Row)`
  flex-direction: column;
  width: 2.5rem;
`;

const RowUpCont = styled(Row)`
  background: #0867ad;
  height: 50%;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 0.4rem;
  border-bottom: solid 1px white;
  cursor: ${({ $cursor }) => ($cursor ? "not-allowed" : "pointer")};
  transition: all 0.3s ease;
  :hover {
    background: #075994;
    border-bottom: none;
  }
`;

const ShakeUp = keyframes`
    0%   { transform: translateY(0); }
    25%  { transform: translateY(-4px); }
    50%  { transform: translateY(-0px); }
    75%  { transform: translateY(-4px); }
    100% { transform: translateY(0); }
`;

const RowUp = styled(UpOutlined)`
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    animation: ${ShakeUp} 0.5s;
  }
`;

const RowDownCont = styled(Row)`
  background: #0867ad;
  height: 50%;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 0.4rem;
  border-top: solid 1px white;
  cursor: ${({ $cursor }) => ($cursor ? "not-allowed" : "pointer")};
  transition: all 0.3s ease;
  :hover {
    background: #075994;
    border-top: none;
  }
`;

const ShakeDown = keyframes`
    0%   { transform: translateY(0); }
    25%  { transform: translateY(+4px); }
    50%  { transform: translateY(-0px); }
    75%  { transform: translateY(+4px); }
    100% { transform: translateY(0); }
`;

const RowDown = styled(DownOutlined)`
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    animation: ${ShakeDown} 0.5s;
  }
`;

const InputNumber = ({
  value = 0,
  onChange = NOOP,
  min = Number.NEGATIVE_INFINITY,
  max = Number.POSITIVE_INFINITY,
  testTags = {},
  onPressEnter = NOOP,
}) => {
  const isInt = (value) => /^-?[0-9]*$/.test(value);
  const isNum = (value) => !isNaN(value);
  const cantAdd = value === max;
  const cantRemove = value === min;
  const { UpArrowTestId, DownArrowTestId, InputTestId } = testTags;

  const handleOnChange = (value) => {
    if (isInt(value) && isNum(value)) {
      if (value <= max && value >= min) {
        onChange(Number(value));
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onPressEnter();
    }
  };

  return (
    <Container>
      <NumbCont
        data-testid={InputTestId}
        value={value}
        onChange={({ target: { value } }) => handleOnChange(value)}
        min={min}
        max={max}
        onKeyDown={handleKeyPress}
      />
      <RowsCont>
        <RowUpCont
          data-testid={UpArrowTestId}
          $cursor={cantAdd}
          onClick={() => handleOnChange(value + 1)}
        >
          <RowUp />
        </RowUpCont>
        <RowDownCont
          data-testid={DownArrowTestId}
          $cursor={cantRemove}
          onClick={() => handleOnChange(value - 1)}
        >
          <RowDown />
        </RowDownCont>
      </RowsCont>
    </Container>
  );
};

export default InputNumber;
