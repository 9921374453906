import styled from "styled-components";

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    font-family: Gotham-Book;
    color: #48505e;
    font-size: 24px;
  }
`;

export const ButtonList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const ImportIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
