import React, { useRef, useState } from "react";
import {
  ContextBackground,
  ContextMenuArrow,
  ContextMenuContainer,
  ContextualAction,
} from "./styles";
import { StartedTask, TasksWaitingToStart } from "../../Pendings.d";
import { AiOutlineCalendar, AiOutlineFlag } from "react-icons/ai";
import { Popover } from "@material-ui/core";
import { PriorityPopover } from "../../DetailPending/Popups";

const Languages = {
  ENG: {
    PostponeLabel: "Postpone",
    ChangeReminder: "Reschedule",
    ChangePriorityLabel: "Change priority",
  },
  ESP: {
    PostponeLabel: "Posponer",
    ChangeReminder: "Reagendar",
    ChangePriorityLabel: "Cambiar prioridad",
  },
};

const { PostponeLabel, ChangeReminder, ChangePriorityLabel } = Languages["ESP"];

interface Points {
  x: number;
  y: number;
}

interface ContextMenuProps {
  task: StartedTask | TasksWaitingToStart | null;
  isTaskWaiting: boolean;
  points: Points;
  openPostponeModal: (id: number | null) => void;
  onChangePriority: () => void;
  onClose: () => void;
}

export const ContextMenuBackground: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  return (
    <ContextBackground
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        onClose();
      }}
    />
  );
};

export const ContextMenu: React.FC<ContextMenuProps> = ({
  task,
  isTaskWaiting,
  points,
  openPostponeModal,
  onChangePriority,
  onClose,
}) => {
  const sidebarOpen =
    JSON.parse(localStorage.getItem("showMenu") ?? "") ?? false;

  const [priorityPopoverVisible, setPriorityPopoverVisible] =
    useState<boolean>(false);
  const editPriorityButton = useRef(null);

  const getArrowX = () => {
    let extraSpace = 22;
    extraSpace += sidebarOpen ? 242 : 68;

    if (points.x + 204 > window.innerWidth) {
      return points.x - 8 - extraSpace;
    }
    return points.x - extraSpace;
  };
  const getArrowY = () => {
    return points.y - 28;
  };

  const getMenuX = () => {
    let extraSpace = 22;
    extraSpace += sidebarOpen ? 242 : 68;

    if (points.x + 204 > window.innerWidth) {
      return points.x - 204 - extraSpace;
    }
    return points.x + 4 - extraSpace;
  };
  const getMenuY = () => {
    let offset = 8;
    if (task?.Permissions.UserCanPostponeTask) offset += 34;
    if (task?.Permissions.UserCanChangeTaskPriority) offset += 34;

    return points.y - offset / 2 - 28;
  };

  return (
    <>
      <ContextMenuArrow top={getArrowY()} left={getArrowX()} />
      <ContextMenuContainer top={getMenuY()} left={getMenuX()}>
        {task?.Permissions.UserCanPostponeTask && (
          <ContextualAction
            onClick={(e) => {
              e.stopPropagation();
              openPostponeModal(task?.IdTaskValue ?? null);
            }}
          >
            <AiOutlineCalendar color="white" />
            <p>{isTaskWaiting ? ChangeReminder : PostponeLabel}</p>
          </ContextualAction>
        )}

        {task?.Permissions.UserCanChangeTaskPriority && (
          <>
            <ContextualAction
              ref={editPriorityButton}
              onClick={(e) => {
                e.stopPropagation();
                setPriorityPopoverVisible(true);
              }}
            >
              <AiOutlineFlag color="white" />
              <p>{ChangePriorityLabel}</p>
            </ContextualAction>

            <Popover
              open={priorityPopoverVisible}
              anchorEl={editPriorityButton.current}
              onClose={() => setPriorityPopoverVisible(false)}
              PaperProps={{
                style: {
                  borderRadius: "6px",
                  width: "362px",
                  padding: "0 0 12px",
                },
              }}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
            >
              <PriorityPopover
                setVisible={setPriorityPopoverVisible}
                taskPriority={task?.Priority}
                onChange={onChangePriority}
                idPending={`${task?.IdTaskValue}`}
                idTeam={Number(task?.IdTeam)}
              />
            </Popover>
          </>
        )}
      </ContextMenuContainer>
    </>
  );
};
