import React from "react";
import Sells from "./Sells";
import Durations from "./Durations";
import { GraphType } from "../../Dashboards.d";
import StatsModal from "./Stats";

const GraphTypes = {
  MASTER: 0,
  DURATIONS: 1,
  SELLS: 2,
  STATS: 3,
};

interface GraphTemplatesProps {
  onClose: () => void;
  selectedTemplate: number;
  selectedDashboard: string | null;
  graphToEdit: GraphType | null;
}

const GraphTemplates: React.FC<GraphTemplatesProps> = ({
  onClose,
  selectedTemplate,
  selectedDashboard,
  graphToEdit,
}) => {
  switch (selectedTemplate) {
    case GraphTypes.DURATIONS:
      return (
        <Durations
          onClose={onClose}
          selectedTemplate={selectedTemplate}
          selectedDashboard={selectedDashboard}
          graphToEdit={graphToEdit}
        />
      );

    case GraphTypes.SELLS:
      return (
        <Sells
          onClose={onClose}
          selectedTemplate={selectedTemplate}
          selectedDashboard={selectedDashboard}
          graphToEdit={graphToEdit}
        />
      );

    case GraphTypes.STATS:
      return (
        <StatsModal
          onClose={onClose}
          selectedTemplate={selectedTemplate}
          graphToEdit={graphToEdit}
        />
      );

    default:
      return <div>unexistent type</div>;
  }
};

export default GraphTemplates;
