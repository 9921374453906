export const Languages = {
  ENG: {
    // step one
    CreateDashboard: "Create dashboard",
    NameAndAssignDashboard: "Name and assign your dashboard",
    SelectPermissions: "Select your dashbaord's permissions",
    DashboardNameLabel: "What will be your dashboard's name?",
    DashboardNamePlaceholder: "Dashboard's name...",
    TeamSelectLabel: "Select the team to which this dashboard will be assigned",
    TeamSelectPlaceHolder: "Select team",
    Continue: "Continue",
    // step two
    Subtitle:
      "Select the permissions that each user, members of the team you selected, will have in the dashboard",
    PermissionDescription: {
      No_Access: "Will not be able to view the dashboard",
      Just_View: "Will be able to see dashboard detail",
      Edit_Graphs: "Can configure the graphs within a dashboard",
      Configure_Dashboard:
        "You will be able to add, move and delete graphs. Share, delete and modify dashboard permissions",
    },
    Confirm: "Confirm",
  },
  ESP: {
    // step one
    CreateDashboard: "Crear dashboard",
    NameAndAssignDashboard: "Nombra y asigna tu dashboard",
    SelectPermissions: "Selecciona los permisos de tu dashboard",
    DashboardNameLabel: "¿Cómo se llamará tu dashboard?",
    DashboardNamePlaceholder: "Nombre del dashboard...",
    TeamSelectLabel:
      "Selecciona la empresa al que se le asignará este dashboard",
    TeamSelectPlaceHolder: "Selecciona empresa",
    Continue: "Continuar",
    // step two
    Subtitle:
      "Selecciona los permisos que tendrá cada usuario, integrantes del empresa que seleccionaste, en el dashboard",
    PermissionDescription: {
      No_Access: "No podrá visualizar el dashboard",
      Just_View: "Podrá ver detalle de dashboard",
      Edit_Graphs: "Podrá configurar las gráficas dentro de un dashboard",
      Configure_Dashboard:
        "Podrá agregar, mover y elminar gráficas. Compartir, elimminar y modificar los permisos del dashboard",
    },
    Confirm: "Confirmar",
  },
};
