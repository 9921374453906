import _ from "lodash";
import { Node, Position, ReactFlowState, useNodes, useStore } from "reactflow";
import { Handler, HandlerId } from "./shared.d";
import { NodeData } from "../../Flow.d";
import { CustomNodeTypes } from "../../CustomNodes";

export const defaultHandlers: Handler[] = [
  {
    id: HandlerId.IN_TOP,
    type: "target",
    position: Position.Top,
    isConnectableStart: false,
  },
  {
    id: HandlerId.IN_RIGHT,
    type: "target",
    position: Position.Right,
    isConnectableStart: false,
  },
  {
    id: HandlerId.IN_BOTTOM,
    type: "target",
    position: Position.Bottom,
    isConnectableStart: false,
  },
  {
    id: HandlerId.IN_LEFT,
    type: "target",
    position: Position.Left,
    isConnectableStart: false,
  },
  {
    id: HandlerId.OUT_TOP,
    type: "source",
    position: Position.Top,
  },
  {
    id: HandlerId.OUT_RIGHT,
    type: "source",
    position: Position.Right,
  },
  {
    id: HandlerId.OUT_BOTTOM,
    type: "source",
    position: Position.Bottom,
  },
  {
    id: HandlerId.OUT_LEFT,
    type: "source",
    position: Position.Left,
  },
];

// Remove Source and Right handlers
export const conditionNodeDefaultHandlers = _.reject(
  defaultHandlers,
  (handler) => handler.type === "source" || handler.position === Position.Right
);

export const startNodeHandlers = _.filter(defaultHandlers, {
  type: "source",
  position: Position.Right,
});

export const endNodeHandlers = _.filter(defaultHandlers, {
  type: "target",
  position: Position.Left,
});

// Flow state

const connectionNodeIdSelector = (state: ReactFlowState): string | null =>
  state.connectionNodeId;

export const useIsConnecting = (): boolean => {
  const connectionNodeId = useStore(connectionNodeIdSelector);
  return !!connectionNodeId;
};

// Node handlers

export const filterTargetHandlers = (
  handlers: Handler[],
  isActive: boolean
): Handler[] => {
  if (isActive) return _.reject(handlers, { type: "source" });
  return handlers;
};

// Toolbox

export const useNode = (nodeId: string): Node<NodeData> => {
  const nodes = useNodes<NodeData>();
  return _.find(nodes, { id: nodeId }) as Node<NodeData>;
};
export const useTooltipActions = (
  nodeType: CustomNodeTypes
): {
  showEdit: boolean;
  showStageSelector: boolean;
  showFields: boolean;
  showDuplicate: boolean;
  showDelete: boolean;
} => {
  const showEdit = [
    CustomNodeTypes.START_NODE,
    CustomNodeTypes.TASK_NODE,
    CustomNodeTypes.CONDITIONAL_NODE,
    CustomNodeTypes.AUTOMATION_NODE,
    CustomNodeTypes.SUBFLUX_NODE,
  ].includes(nodeType);
  const showStageSelector = [CustomNodeTypes.TASK_NODE].includes(nodeType);
  const showFields = [CustomNodeTypes.TASK_NODE].includes(nodeType);
  const showDuplicate = [
    CustomNodeTypes.TASK_NODE,
    // Back doesn't support other types
    // CustomNodeTypes.CONDITIONAL_NODE,
    // CustomNodeTypes.AUTOMATION_NODE,
    // CustomNodeTypes.SUBFLUX_NODE,
    // CustomNodeTypes.END_NODE,
  ].includes(nodeType);
  const showDelete = [
    CustomNodeTypes.TASK_NODE,
    CustomNodeTypes.CONDITIONAL_NODE,
    CustomNodeTypes.AUTOMATION_NODE,
    CustomNodeTypes.SUBFLUX_NODE,
    CustomNodeTypes.END_NODE,
  ].includes(nodeType);
  return {
    showEdit,
    showStageSelector,
    showFields,
    showDuplicate,
    showDelete,
  };
};
