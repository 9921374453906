import React, { useRef, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Popover } from "@material-ui/core";
import {
  AiFillCaretDown,
  AiOutlineDelete,
  AiOutlineLock,
  AiOutlinePlus,
  AiOutlineShareAlt,
} from "react-icons/ai";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import { Languages } from "./Dictionary";
import { ModalsType, Team, DashboardPermissions } from "../Dashboards.d";
import {
  CreateElementButton,
  HeaderContainer,
  HeaderOption,
  HeaderOptions,
  MoreOptionsButton,
  MoreOptionsList,
  OptionLabel,
  StyledSwitch,
} from "./style";

const {
  selectTeamPlaceholder,
  CreateDashboardLabel,
  AddGraphLabel,
  EditionMode,
  ShareDashboard,
  Permissions,
  Delete,
} = Languages["ESP"];

interface HeaderProps {
  teamsInfo: Team[];
  selectedTeam: number | null;
  setSelectedTeam: (team: number) => void;
  selectedDashboard: string | null;
  setSelectedDashboard: (dashboard: string | null) => void;
  selectedDashboardName: string;
  openModal: (modal: ModalsType) => void;
  backHome: () => void;
  editionMode: boolean;
  setEditionMode: (enable: boolean) => void;
  canAddDashBoards: boolean;
  dashboardPermissions: DashboardPermissions;
  onOpenEditModal: (modal: ModalsType) => void;
}

const Header: React.FC<HeaderProps> = ({
  teamsInfo,
  selectedTeam,
  setSelectedTeam,
  selectedDashboard,
  setSelectedDashboard,
  selectedDashboardName,
  openModal,
  backHome,
  editionMode,
  setEditionMode,
  canAddDashBoards,
  dashboardPermissions,
  onOpenEditModal,
}) => {
  const moreOptionsButton = useRef(null);
  const [moreOptionsPopoverVisible, setMoreOptionsPopoverVisible] =
    useState<boolean>(false);

  const teamOptions = (() => {
    return teamsInfo.map((team) => {
      return {
        value: team.IdTeam,
        label: team.Name,
      };
    });
  })();

  const handleChangeTeam = (idTeam: number) => {
    setSelectedTeam(idTeam);
    setSelectedDashboard(null);
    setEditionMode(false);
    if (selectedDashboard) {
      backHome();
    }
  };

  const handleBack = () => {
    setSelectedDashboard(null);
    setEditionMode(false);
    backHome();
  };

  const handleOpenModal = () => {
    if (selectedDashboard) {
      openModal("AddGraph");
    } else {
      openModal("CreateDashboard");
    }
  };

  const renderMoreOptions = () => {
    return (
      dashboardPermissions.CanShareDashboard ||
      dashboardPermissions.CanEditPermissions ||
      dashboardPermissions.CanDeleteDashboard
    );
  };

  return (
    <HeaderContainer>
      {selectedDashboard && (
        <IoIosArrowBack
          size={24}
          color="#48505e"
          onClick={handleBack}
          style={{ cursor: "pointer" }}
        />
      )}
      <p>
        {selectedDashboard && selectedDashboardName
          ? selectedDashboardName
          : "Dashboards"}
      </p>
      <GeestSelect
        value={selectedTeam}
        options={teamOptions}
        onChange={handleChangeTeam}
        placeholderSelect={selectTeamPlaceholder}
        $width="200px"
        valueNecesary
      />

      <HeaderOptions>
        {selectedDashboard && dashboardPermissions.CanEditLayout && (
          <HeaderOption>
            <p>{EditionMode}</p>
            <StyledSwitch
              checked={editionMode}
              onChange={() => setEditionMode(!editionMode)}
            />
          </HeaderOption>
        )}

        {selectedDashboard && dashboardPermissions.CanAddGraphs ? (
          <HeaderOption>
            <CreateElementButton onClick={handleOpenModal}>
              <AiOutlinePlus color="white" size={20} />
              {AddGraphLabel}
            </CreateElementButton>
          </HeaderOption>
        ) : (
          !Boolean(selectedDashboard) &&
          canAddDashBoards && (
            <HeaderOption>
              <CreateElementButton onClick={handleOpenModal}>
                <AiOutlinePlus color="white" size={20} />
                {CreateDashboardLabel}
              </CreateElementButton>
            </HeaderOption>
          )
        )}

        {selectedDashboard && renderMoreOptions() && (
          <HeaderOption>
            <MoreOptionsButton
              ref={moreOptionsButton}
              onClick={() => setMoreOptionsPopoverVisible(true)}
            >
              <AiFillCaretDown color="#0273e9" />
            </MoreOptionsButton>
            <Popover
              open={moreOptionsPopoverVisible}
              anchorEl={moreOptionsButton.current}
              onClose={() => setMoreOptionsPopoverVisible(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: "0.5rem" }}
            >
              <MoreOptionsList>
                {false && dashboardPermissions.CanShareDashboard && (
                  <OptionLabel>
                    <AiOutlineShareAlt size={16} />
                    {ShareDashboard}
                  </OptionLabel>
                )}
                {dashboardPermissions.CanEditPermissions && (
                  <OptionLabel
                    onClick={() => onOpenEditModal("EditPermissions")}
                  >
                    <AiOutlineLock size={16} />
                    {Permissions}
                  </OptionLabel>
                )}
                {dashboardPermissions.CanDeleteDashboard && (
                  <OptionLabel
                    color="rgba(219, 35, 44, 1)"
                    onClick={() => {
                      setMoreOptionsPopoverVisible(false);
                      onOpenEditModal("DeleteDashboard");
                    }}
                  >
                    <AiOutlineDelete size={16} />
                    {Delete}
                  </OptionLabel>
                )}
              </MoreOptionsList>
            </Popover>
          </HeaderOption>
        )}
      </HeaderOptions>
    </HeaderContainer>
  );
};

export default Header;
