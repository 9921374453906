import moment from "moment-timezone";
import axios from "axios";
import { transformedOptions as phoneOptions } from "./components/DynamicInput/Components/helpers/PhoneNumberHelpers";
import { transformedOptions as currencyOptions } from "./components/DynamicInput/Components/helpers/CurrencyHelpers";

const mxOffset = -360;

export const getUserFormats = async () => {
  let CurrencyFormat = "MXN";
  let PhoneFormat = "MX";
  const res = await axios.get("https://ipapi.co/json/");
  const { currency, country_code } = res.data;
  if (currencyOptions.map(({ value }) => value).includes(currency)) {
    CurrencyFormat = currency;
  }
  if (phoneOptions.map(({ value }) => value).includes(country_code)) {
    PhoneFormat = country_code;
  }
  return { CurrencyFormat, PhoneFormat };
};

export const moneyFormat = (value = 0, decimals = 2) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimals,
  });
  return formatter.format(value);
};

export const dateFormat = (date, hideYear = false) => {
  if (moment(new Date(date)).isValid()) {
    if (hideYear) {
      return moment(date).format("DD/MMM");
    }
    return moment(date).format("DD/MMM/YY");
  } else {
    return "Invalid format";
  }
};

export const dateHourFormat = (date, hideYear = false) => {
  if (moment(new Date(date)).isValid()) {
    if (hideYear) {
      return moment(date).format("DD/MMM HH:mm");
    }
    return moment(date).format("DD/MMM/YY HH:mm");
  } else {
    return "Invalid format";
  }
};

const withQuotations = (label) => {
  if (!label) return label;
  let newLabel = label.replaceAll("x22", '"');
  newLabel = newLabel.replaceAll("x27", "'");
  return newLabel;
};

const withTimezone = (response) => {
  if (
    moment(response, "YYYY-MM-DD HH:mm:ss.SSSSSS", true).isValid() ||
    moment(response, "YYYY-MM-DD HH:mm.SSSSSS", true).isValid() ||
    moment(response, "YYYY-MM-DD HH:mm:ss", true).isValid() ||
    moment(response, "YYYY-MM-DD HH:mm", true).isValid()
  ) {
    const tzOffset = moment().utcOffset();
    const offset = tzOffset - mxOffset;
    return moment(response)
      .add(offset, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
  } else {
    return response;
  }
};

const mxTimezone = (response) => {
  if (
    moment(response, "YYYY-MM-DD HH:mm:ss.SSSSSS", true).isValid() ||
    moment(response, "YYYY-MM-DD HH:mm.SSSSSS", true).isValid() ||
    moment(response, "YYYY-MM-DD HH:mm:ss", true).isValid() ||
    moment(response, "YYYY-MM-DD HH:mm", true).isValid()
  ) {
    const tzOffset = moment().utcOffset();
    const offset = mxOffset - tzOffset;
    return moment(response)
      .add(offset, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
  } else {
    return response;
  }
};

const formatArray = (response, sending = false) => {
  return response.map((r) => {
    let res = {};
    if (r && typeof r === "object") {
      res = formatObject(r, sending);
    } else if (typeof r === "string") {
      if (sending) {
        res = mxTimezone(r);
      } else {
        res = withQuotations(r);
        res = withTimezone(res);
      }
    } else {
      res = r;
    }
    return res;
  });
};

const formatObject = (response, sending = false) => {
  if (!response) return;
  const keys = Object.keys(response);
  let res = {};
  keys.forEach((k) => {
    if (typeof response[k] === "string") {
      if (sending) {
        res[k] = mxTimezone(response[k]);
      } else {
        res[k] = withQuotations(response[k]);
        res[k] = withTimezone(res[k]);
      }
    } else if (Array.isArray(response[k])) {
      res[k] = formatArray(response[k], sending);
    } else if (typeof response[k] === "object") {
      res[k] = formatObject(response[k], sending);
    } else {
      res[k] = response[k];
    }
  });
  return res;
};

export const formatQuery = (response, sending = false) => {
  let newResponse;
  if (Array.isArray(response)) {
    newResponse = formatArray(response, sending);
  } else if (response && typeof response === "object") {
    newResponse = formatObject(response, sending);
  } else if (typeof response === "string") {
    if (sending) {
      newResponse = mxTimezone(response);
    } else {
      newResponse = withQuotations(response);
      newResponse = withTimezone(newResponse);
    }
  } else {
    newResponse = response;
  }
  return newResponse;
};

export const formatRequest = (data = "") => {
  let requestData = data;
  requestData = requestData.replaceAll('\\"', "x22");
  requestData = requestData.replaceAll("'", "x27");
  return requestData;
};

export const createUUID = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const sanitizeSearchValues = (value) => {
  if (!value) return "";
  let newValue = value.toLowerCase();
  newValue = newValue.normalize("NFD").replaceAll(/[\u0300-\u036f]/g, "");
  newValue = newValue.replace(/ :\w+: /gi, "");
  newValue = newValue.replace(/ :\w+:/gi, "");
  newValue = newValue.replace(/:\w+: /gi, "");
  newValue = newValue.replace(/:\w+:/gi, "");
  return newValue;
};
