import React, { useEffect } from "react";
import _ from "lodash";
import { Dashboard, Team } from "../Dashboards.d";
import { ListContainer } from "./style";
import Welcome from "./Welcome";
import List from "./List";
import { ModalsType } from "../Dashboards.d";

interface DashboardListProps {
  selectedTeam: number | null;
  Teams: Team[];
  dashboards: Dashboard[];
  setDashboards: (dashboards: Dashboard[]) => void;
  setSelectedDashboard: (dashboard: string | null) => void;
  setDashboardToEdit: (dashboard: Dashboard | null) => void;
  openPermissionsModal: () => void;
  setModal: (modal: ModalsType) => void;
  loading: boolean;
  reload: () => void;
}

const DashboardList: React.FC<DashboardListProps> = ({
  selectedTeam,
  Teams = [],
  dashboards,
  setDashboards,
  setSelectedDashboard,
  setDashboardToEdit,
  openPermissionsModal,
  setModal,
  loading,
  reload,
}) => {
  useEffect(() => {
    if (selectedTeam) {
      const teamDashboards = _.find(Teams, { IdTeam: selectedTeam })
        ?.Dashboards as Dashboard[];
      const sortedDashboards = teamDashboards
        .slice()
        .sort((first, second) => first.Position - second.Position);
      setDashboards(sortedDashboards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  const hasDashboardsToRender = () => {
    const renderizable = dashboards.filter(
      (dashboard) => dashboard.Permissions.CanViewDashBoard
    );
    return renderizable.length > 0;
  };

  return (
    <ListContainer>
      {loading || hasDashboardsToRender() ? (
        <List
          idTeam={selectedTeam}
          dashboards={dashboards}
          setDashboards={setDashboards}
          setSelectedDashboard={setSelectedDashboard}
          setDashboardToEdit={setDashboardToEdit}
          openPermissionsModal={openPermissionsModal}
          setModal={setModal}
          reload={reload}
        />
      ) : (
        <Welcome selectedTeam={Boolean(selectedTeam)} />
      )}
    </ListContainer>
  );
};

export default DashboardList;
