import React from "react";
import GeestSelect from "../../../../GeestUI/GeestSelect/GeestSelect";
import { Languages } from "./Dictionary";
import {
  DashboardPermission,
  Permission,
  Team,
  TeamMember,
} from "../../Dashboards.d";
import {
  NameAndAssignContainer,
  PermissionContent,
  PermissionDescriptionWrapper,
  PermissionsContainer,
  PermissionsForm,
  PermisssionElement,
  StepForm,
  StringInput,
} from "./style";
import User from "../../../../components/User";
import { Button } from "../../../../GeestUI";

const {
  DashboardNameLabel,
  DashboardNamePlaceholder,
  TeamSelectLabel,
  TeamSelectPlaceHolder,
  Continue,
  Subtitle,
  PermissionDescription,
  Confirm,
} = Languages["ESP"];

interface StepContentProps {
  step: number;
  // step one
  dashboardName: string;
  setDashboardName: (name: string) => void;
  teams: Team[];
  selectedTeam: number | null;
  setSelectedTeam: (team: number | null) => void;
  onFinishStepOne: () => void;
  canGoNext: boolean;
  // step two
  teamMembers: TeamMember[];
  permissions: Permission[];
  onChangeUserPermissions: (
    user: number,
    permission: DashboardPermission
  ) => void;
  onFinishStepTwo: () => void;
}

const StepContent: React.FC<StepContentProps> = ({
  step,
  // step one
  dashboardName,
  setDashboardName,
  teams,
  selectedTeam,
  setSelectedTeam,
  onFinishStepOne,
  canGoNext,
  // step two
  teamMembers,
  permissions,
  onChangeUserPermissions,
  onFinishStepTwo,
}) => {
  const teamOptions = (() => {
    return teams.map((team) => {
      return {
        value: team.IdTeam,
        label: team.Name,
      };
    });
  })();

  switch (step) {
    case 0:
      return (
        <NameAndAssignContainer>
          <StepForm>
            <p>{DashboardNameLabel}</p>
            <StringInput
              autoFocus
              value={dashboardName}
              onChange={({ target: { value } }) => setDashboardName(value)}
              placeholder={DashboardNamePlaceholder}
            />
            <p>{TeamSelectLabel}</p>
            <GeestSelect
              value={selectedTeam}
              options={teamOptions}
              onChange={(team) => setSelectedTeam(team)}
              placeholderSelect={TeamSelectPlaceHolder}
              valueNecesary
            />
          </StepForm>
          <Button type="primary" disabled={canGoNext} onClick={onFinishStepOne}>
            {Continue}
          </Button>
        </NameAndAssignContainer>
      );
    case 1:
      return (
        <PermissionsContainer>
          <p>{Subtitle}</p>

          <PermissionsForm>
            {teamMembers.map((user, idx) => (
              <PermisssionElement key={user.IdUser}>
                <PermissionContent>
                  <div className="user">
                    <User
                      user={user}
                      size="middle"
                      picSize="26px"
                      nameStyle="bold"
                      nameColor="#48505e"
                    />
                  </div>

                  <div className="select">
                    <GeestSelect
                      value={user.Permission}
                      options={permissions}
                      onChange={(permission) =>
                        onChangeUserPermissions(idx, permission)
                      }
                      valueNecesary
                    />
                  </div>
                </PermissionContent>

                <PermissionDescriptionWrapper>
                  <p>{PermissionDescription[user.Permission]}</p>
                </PermissionDescriptionWrapper>
              </PermisssionElement>
            ))}
          </PermissionsForm>

          <Button onClick={onFinishStepTwo} type="primary">
            {Confirm}
          </Button>
        </PermissionsContainer>
      );

    default:
      return (
        <div>
          <p>error</p>
        </div>
      );
  }
};

export default StepContent;
