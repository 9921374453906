import React, { useRef } from "react";
import { Popover } from "@material-ui/core";
import { Row } from "antd";
import { StopProcessPopoverProps } from "./StopProcessPopover.d";
import { CloseIcon } from "../../../Pendings/DetailPending/styles";
import { Button, Typography } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";

const { H3, B, P } = Typography;

const StopProcessPopover: React.FC<StopProcessPopoverProps> = ({
  children,
  IdProcessExecution,
  open,
  onSuccess,
  onCancel,
}) => {
  const popoverRef = useRef(null);

  const [cancelProcessExecution, canceling] = useMutation<[]>({
    func: "Ver2-Reports-cpe",
    onSuccess: () => onSuccess(),
  });

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () =>
    cancelProcessExecution({
      args: { IdProcessExecution },
    });

  return (
    <>
      <Popover
        open={open}
        anchorEl={popoverRef.current}
        onClose={() => onCancel()}
        PaperProps={{
          style: {
            borderRadius: "10px",
            width: "300px",
            padding: "12px 16px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div>
          <Row align="middle" justify="space-between">
            <H3 mb="0">
              <B>Detener seguimiento</B>
            </H3>
            <CloseIcon onClick={() => onCancel()} />
          </Row>
          <P mb="27px" mt="10px">
            Todas las tareas actuales y por iniciar del seguimiento
            desaparecerán de los pendientes, pero la información recabada en las
            bases de datos hasta el momento seguirá registrada.
          </P>
          <Row justify="end">
            <Button type="danger" onClick={onClick} loading={canceling}>
              Detener
            </Button>
          </Row>
        </div>
      </Popover>
      <div ref={popoverRef}>{children}</div>
    </>
  );
};

export default StopProcessPopover;
