import React, { useState } from "react";
import { Popover } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { GoTriangleDown } from "react-icons/go";
import { AiOutlineDelete, AiOutlineShareAlt } from "react-icons/ai";
import { Button } from "../../../GeestUI";
import { ShareProcessItem, DeleteProcessItem, StyledPaper } from "./styles";
import ShareTemplate from "../../Templates/ShareTemplate/ShareTemplate";
import DeleteProcessModal from "./DeleteProcessModal";
import { useMutation } from "../../../hooks";

type ModalType = "share" | "delete";

const ProcessActions: React.FC<{ processName: string; canShare: boolean }> = ({
  processName,
  canShare,
}) => {
  const history = useHistory();
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const [modal, setModal] = useState<ModalType | null>(null);
  const [openActionsPopover, setOpenActionsPopover] = useState<boolean>(false);
  const [actionsEl, setActionsEl] = useState<HTMLButtonElement | null>(null);

  const [deleteProcessTemplate, deleting] = useMutation<{}>({
    func: "Ver2-Configurator-dpt",
    onSuccess: () => history.push("/home/templates"),
  });

  const handleOnDeleteProcess = () =>
    deleteProcessTemplate({
      args: {
        IdTeam,
        IdProcessTemplate,
      },
    });

  return (
    <>
      {modal === "share" && (
        <ShareTemplate
          templatesToShare={[IdProcessTemplate]}
          closeModal={() => setModal(null)}
        />
      )}
      {modal === "delete" && (
        <DeleteProcessModal
          processName={processName}
          deleting={deleting}
          onClose={() => setModal(null)}
          onDelete={handleOnDeleteProcess}
        />
      )}

      <Button
        type="secondary"
        style={{ width: "30px" }}
        onClick={(e) => {
          setOpenActionsPopover(true);
          setActionsEl(e.currentTarget);
        }}
        Icon={GoTriangleDown}
      />
      <Popover
        open={openActionsPopover}
        anchorEl={actionsEl}
        onClose={() => {
          setOpenActionsPopover(false);
          setActionsEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ marginTop: "0.5rem" }}
      >
        <StyledPaper>
          {canShare && (
            <ShareProcessItem
              onClick={() => {
                setModal("share");
                setOpenActionsPopover(false);
              }}
            >
              <AiOutlineShareAlt size={14} />
              <span>Compatir plantilla</span>
            </ShareProcessItem>
          )}
          <DeleteProcessItem
            onClick={() => {
              setModal("delete");
              setOpenActionsPopover(false);
            }}
          >
            <AiOutlineDelete size={14} />
            <span>Eliminar</span>
          </DeleteProcessItem>
        </StyledPaper>
      </Popover>
    </>
  );
};

export default ProcessActions;
