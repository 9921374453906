import React from "react";
import styled from "styled-components";
//@ts-ignore
import emoji from "emoji-dictionary";
import { InteractionValueType, User } from "../DynamicCell.d";
import DynamicInput from "../../../../../components/DynamicInput";
import moment from "moment";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  font-size: 12px;
  font-family: Gotham-Bold;
  color: #0067b0;

  max-width: 268px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserContainer = styled.div`
  max-width: 268px;
`;

interface LastInteractionComponentProps {
  userSelectDataOrigin: User[];
  interactionValue: InteractionValueType;
}

const LastInteractionComponent: React.FC<LastInteractionComponentProps> = ({
  userSelectDataOrigin,
  interactionValue: { Message, Date: dateValue, UsersInvolved },
}) => {
  const userSelectDataOriginWithMessage = UsersInvolved.map((IdUser) => {
    const user = userSelectDataOrigin.find((user) => user.IdUser === IdUser);

    return {
      ...user,
      altTooltip: `${user?.FirstName} ${user?.LastName} - ${Message.replace(
        /:\w+:/gi,
        (name) => emoji.getUnicode(name) ?? name
      )}`,
      FirstName: dateHourFormat(dateValue),
      LastName: "",
      Email: Message.replace(
        /:\w+:/gi,
        (name) => emoji.getUnicode(name) ?? name
      ),
    };
  });

  function dateHourFormat(date: string, hideYear = false) {
    if (moment(new Date(date)).isValid()) {
      if (hideYear) {
        return moment(date).format("DD/MMM HH:mm");
      }
      return moment(date).format("DD/MMM/YY HH:mm");
    } else {
      return "Invalid format";
    }
  }

  return (
    <Container>
      <UserContainer>
        <DynamicInput
          value={UsersInvolved.join(",")}
          type="users_select"
          userSelectDataOrigin={userSelectDataOriginWithMessage as User[]}
          dataOrigin=""
          format=""
          configuration=""
          fieldName=""
          isConsult={true}
          disabled={true}
          required={false}
          onChange={() => null}
          extraParams={{
            userSize: "large",
            userNameColor: "#48505e",
            userNameSize: "12px",
            emailColor: "#828d9e",
            emailSize: "10px",
            userLargeTooltip: true,
          }}
        />
      </UserContainer>
    </Container>
  );
};

export default LastInteractionComponent;
