import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { languages } from "../Controls/dicitonary";
import { CloseIcon } from "../../hoverIcons";
import { Button } from "../../../GeestUI";

const StyledModal = styled(Modal)`
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

const Title = styled.div`
  flex: 1;
  overflow: hidden;
  font-size: 16px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  font-size: 14px;
`;

const Paragraph = styled.p`
  margin: 0;
  text-align: center;
  color: #828d9e;
  padding: 0 13.5px;
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

interface ImportPDFConfirmationProps {
  discardImport: () => void;
  acceptImport: () => void;
}

const ImportPDFConfirmation: React.FC<ImportPDFConfirmationProps> = ({
  discardImport,
  acceptImport,
}) => {
  const { ImportPDFTitle, ImportPDFDescription, CancelLabel, Accept } =
    languages["ESP"];

  return (
    <StyledModal
      open
      footer={null}
      closable={false}
      centered
      width="385px"
      onCancel={discardImport}
      bodyStyle={{
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Gotham-Book",
        padding: "12px",
      }}
    >
      <Header>
        <Title>{ImportPDFTitle}</Title>
        <CloseIcon size={20} onClick={discardImport} color="#48505e" />
      </Header>
      <Content>
        <Paragraph>{ImportPDFDescription}</Paragraph>
        <ButtonsRow>
          <Button type="secondary" onClick={discardImport}>
            {CancelLabel}
          </Button>
          <Button type="primary" onClick={acceptImport}>
            {Accept}
          </Button>
        </ButtonsRow>
      </Content>
    </StyledModal>
  );
};

export default ImportPDFConfirmation;
