import styled from "styled-components";

export const DashboardCard = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
`;

export const DashboardContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  height: calc(100% - 150px);
  border-radius: 10px;
`;

export const DasboardLeft = styled.div`
  flex: 1;
  border-radius: 10px;
  background-color: #f5f5f5;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DasboardRight = styled.div`
  flex: 1;
  border-radius: 10px;
  background-color: #f5f5f5;
  overflow: auto;
`;
