import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useMutation } from "../../../../hooks";
import { Button } from "../../../../GeestUI";
import { Modal, Paper } from "@material-ui/core";
import { CloseIcon } from "../../../../components/hoverIcons";

interface SaveReportModalProps {
  onClose: () => void;
  setSelectedReport: (id: number) => void;
  IdTeam: number;
  reloadReports: () => void;
  onSave?: (name: string) => void;
}

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const StyledPaper = styled(Paper)`
  border-radius: 10px !important;
  height: 158px;
  max-height: 90vh;
  width: 385px;
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  padding-bottom: 0;
`;

const Title = styled.h2`
  font-size: 16px;
  font-family: Gotham-Bold;
  color: #48505e;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  padding-bottom: 0;
`;

const Input = styled.input`
  background-color: #ffffff;
  width: 100%;
  height: 32px;
  padding-left: 6px;
  margin-bottom: 1.5rem;
  font-family: Gotham-Book;

  border-radius: 6px;
  outline: none;
  border: 2px solid rgba(237, 236, 236, 1);
  font-size: 14px;
  font-weight: 325;
  color: rgba(130, 141, 158, 1);

  ::placeholder {
    color: rgba(130, 141, 158, 1);
  }

  transition: all 0.35s ease;
  :hover {
    border-color: rgba(2, 115, 233, 1);
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;

const SaveReportModal: React.FC<SaveReportModalProps> = ({
  onClose,
  setSelectedReport,
  IdTeam,
  reloadReports,
  onSave,
}) => {
  const [name, setName] = useState<string>("");
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  const [saveReport, saving] = useMutation<number>({
    func: "Ver2-Pipeline-spr",
    onSuccess: (id) => {
      setSelectedReport(id);
      reloadReports();
      onClose();
    },
  });

  const onSubmit = () => {
    saveReport({
      args: {
        IdTeam,
        Name: name,
      },
    });
  };

  return (
    <Modal open onClose={onClose} style={{ opacity: 1, zIndex: 20 }}>
      <ModalContent onClick={onClose}>
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <Header>
            <Title>Guardar reporte</Title>
            <CloseIcon size={20} onClick={onClose} />
          </Header>
          <Content>
            <Input
              placeholder="Nombre del reporte..."
              value={name}
              onChange={(e) => {
                if (e.target.value.length >= 35) return;
                setName(e.target.value);
              }}
              autoFocus
              ref={ref}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSave ? onSave(name) : onSubmit();
                }
              }}
            />
            <ButtonContainer>
              <Button type="secondary" onClick={onClose} loading={saving}>
                Cancelar
              </Button>
              <Button
                type="primary"
                onClick={
                  onSave
                    ? () => {
                        onSave(name);
                      }
                    : onSubmit
                }
                loading={saving}
                disabled={!name}
              >
                Guardar
              </Button>
            </ButtonContainer>
          </Content>
        </StyledPaper>
      </ModalContent>
    </Modal>
  );
};

export default SaveReportModal;
