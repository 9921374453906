import React from "react";
import { TableHeader, TableRow as TR, Content } from "../MyTeams/Style";
import GeestSelect from "../../../GeestUI/GeestSelect";
import { transformedOptions } from "../../../components/DynamicInput/Components/helpers/CurrencyHelpers";
import styled from "styled-components";
import { optionsWithoutLabels } from "../../../components/DynamicInput/Components/helpers/PhoneNumberHelpers";

const TableRow = styled(TR)`
  :hover {
    background-color: transparent;
  }
  :active {
    background-color: transparent;
  }
  cursor: default;
`;

interface TeamConfigurationProps {
  onChangeReportsCurrencyFormat: (format: string) => void;
  reportsCurrencyFormat: string;
  fieldsCurrencyFormat: string;
  onChangeFieldsCurrencyFormat: (format: string) => void;
  fieldsPhoneFormat: string;
  onChangeFieldsPhoneFormat: (format: string) => void;
}

const TeamConfiguration: React.FC<TeamConfigurationProps> = ({
  onChangeReportsCurrencyFormat,
  reportsCurrencyFormat,
  fieldsCurrencyFormat,
  onChangeFieldsCurrencyFormat,
  fieldsPhoneFormat,
  onChangeFieldsPhoneFormat,
}) => {
  return (
    <Content>
      <TableHeader style={{ alignItems: "center", padding: "0px 10px" }}>
        Tablero de configuraciones
      </TableHeader>
      <TableRow
        style={{
          justifyContent: "space-between",
          padding: "0px 10px",
        }}
      >
        Selecciona la divisa con la que se reportarán las ventas de la empresa
        <GeestSelect
          value={reportsCurrencyFormat}
          options={transformedOptions}
          onChange={onChangeReportsCurrencyFormat}
          $width="125px"
          valueNecesary
          placeholderSelect="Divisa..."
        />
      </TableRow>
      <TableRow
        style={{
          justifyContent: "space-between",
          padding: "0px 10px",
        }}
      >
        Selecciona la divisa por default que aparecerá en los campos de
        formulario de la empresa
        <GeestSelect
          value={fieldsCurrencyFormat}
          options={transformedOptions}
          onChange={onChangeFieldsCurrencyFormat}
          $width="125px"
          valueNecesary
          placeholderSelect="Divisa..."
        />
      </TableRow>
      <TableRow
        style={{
          justifyContent: "space-between",
          padding: "0px 10px",
        }}
      >
        Selecciona el código de país por default que aparecerá en los campos de
        formulario de teléfono
        <GeestSelect
          value={fieldsPhoneFormat}
          options={optionsWithoutLabels}
          onChange={onChangeFieldsPhoneFormat}
          $width="125px"
          valueNecesary
          placeholderSelect="Divisa..."
        />
      </TableRow>
    </Content>
  );
};

export default TeamConfiguration;
