import React from "react";
import { Space } from "antd";
import { FiLock } from "react-icons/fi";
import VISA from "./../../resources/img/visa.svg";
import MASTER_CARD from "./../../resources/img/mc.svg";
import AMERICAN_EXPRESS from "./../../resources/img/am.svg";

const CardAddOn = () => {
  return (
    <Space align="middle" size={5} style={{ marginLeft: "2rem" }}>
      <img src={VISA} alt="visa" style={{ width: "30px", marginTop: "5px" }} />
      <img
        src={MASTER_CARD}
        alt="masterCard"
        style={{ width: "30px", marginTop: "5px" }}
      />
      <img
        src={AMERICAN_EXPRESS}
        alt="americanExpress"
        style={{ width: "30px", marginTop: "5px" }}
      />
      <FiLock size={22} style={{ marginLeft: "8px", marginTop: "5px" }} />
    </Space>
  );
};

export default CardAddOn;
