import styled from "styled-components";
import { Row } from "antd";
import { IoClose } from "react-icons/io5";
import { Typography } from "../../../../GeestUI";
const { B } = Typography;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(72, 80, 94, 1);
  font-size: 16px;
  font-family: Gotham-Bold;
  align-items: flex-start;
`;

export const SubTitle = styled.div`
  color: rgba(72, 80, 94, 1);
  font-size: 16px;
  font-family: Gotham-Bold;
  font-size: 14px;
`;

export const IdContainer = styled.div`
  font-family: Gotham-Book;
  font-size: 14px;
  color: rgba(0, 103, 176, 1);
  font-weight: 325;
`;

export const ProcessNameStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProcessNameWrapper = styled.div`
  max-width: 320px;
  display: flex;
`;

export const ProcessName = styled(B)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CloseIcon = styled(IoClose)`
  cursor: pointer;
  :hover {
    color: #48505e;
  }
`;

export const StatusBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #727e8b;
`;

export const DetailContent = styled.div`
  flex: 1;
  color: #727e8b;
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DataRow = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

export const DataText = styled.div`
  font-family: Gotham-Book;
  font-size: 14px;
  color: rgba(130, 141, 158, 1);
  font-weight: 325;
`;

export const SpaceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
`;

export const DataItem = styled.div``;

export const GraphContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

export const PriorityContainer = styled(Row)<{ $selected?: boolean }>`
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.35s ease;
  background: ${({ $selected = false }) =>
    $selected ? "var(--silver)" : "transparent"};
  :hover {
    background: var(--silver);
  }
`;

export const CustomTag = styled(Row)<{ $color?: string }>`
  border: ${({ $color = "#727e8b" }) => `1px solid ${$color}`};
  border-radius: 5px;
  text-align: center;
  background: ${({ $color = "#727e8b" }) => `${$color}33`};
  min-width: 76px;
  max-width: 100%;
  height: 20px;
  font-size: 14px;
  line-height: 10px;
  color: ${({ $color = "#727e8b" }) => $color};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 5px;
`;

export const PencilContainer = styled(Row)`
  width: 22.5px;
  height: 22.5px;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background: rgba(232, 236, 241, 0.5);
  }
`;

export const DangerButton = styled.button`
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #e11909;
  padding: 0.2rem 0.5rem;
  font-size: 16px;
  color: #e11909;
  background-color: white;
  transition: color 0.35s ease, background-color 0.35s ease;
  :hover {
    color: white;
    background-color: #e11909;
  }
`;

export const CancelButton = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Gotham-Bold;
  font-size: 14px;
  cursor: pointer;
  height: 32px;
  width: 200px;
  color: ${({ disabled }) => (disabled ? "#828d9e" : "#db232c")};
  :hover {
    color: ${({ disabled }) => (disabled ? "#828d9e" : "#a40810")};
  }
  :active {
    color: ${({ disabled }) => (disabled ? "#828d9e" : "#ff000c")};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DetailButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 30px;
  width: 100%;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: 0.5px solid #e8ecf1;
  padding: 0 10px;
  font-size: 16px;
  color: #727e8b;
  background-color: #fafbfd;
  transition: background-color 0.35s ease, border-color 0.35s ease;
  :hover {
    background-color: rgba(192, 201, 211, 0.3);
    border: 0.5px solid #c0c9d3;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: inherit;
`;
