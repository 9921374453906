import React from "react";
import { Card, Space } from "antd";
import { Typography } from "../../GeestUI";
import { Circle } from "../../components/ColorPicker";

const { H1, B, Text } = Typography;

const colors: { name: string; value: string }[] = [
  {
    name: "Lemon Green",
    value: "var(--lemon-green)",
  },
  {
    name: "Grass Green",
    value: "var(--grass-green)",
  },
  {
    name: "Royal Blue",
    value: "var(--royal-blue)",
  },
  {
    name: "Navy Blue",
    value: "var(--navy-blue)",
  },
  {
    name: "Red",
    value: "var(--red)",
  },
  {
    name: "Dark Gray",
    value: "var(--dark-gray)",
  },
  {
    name: "Medium Gray",
    value: "var(--medium-gray)",
  },
  {
    name: "Light Gray",
    value: "var(--light-gray)",
  },
  {
    name: "Super Light Gray",
    value: "var(--super-light-gray)",
  },
  {
    name: "Silver",
    value: "var(--silver)",
  },
];

const Colors: React.FC = () => {
  return (
    <>
      <H1>
        <B>Colors</B>
      </H1>
      <Card size="small" title="Colors">
        {colors.map(({ name, value }, key) => (
          <Space style={{ width: "100%" }} key={key}>
            <Circle color={value} />
            <Text color={value}>
              {name} = {value}
            </Text>
          </Space>
        ))}
      </Card>
    </>
  );
};

export default Colors;
