import React from "react";
import styled from "styled-components";
import _ from "lodash";
import {
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BR,
  BS,
  BT,
  BV,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  XK,
  YE,
  YT,
  ZA,
  ZM,
  ZW,
} from "country-flag-icons/react/3x2";

export interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

export const countries: CountryType[] = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Republic of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

const FlagContainer = styled.div<{ $color?: string; $fontSize?: string }>`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${({ $color }) => ($color ? $color : "#828d9e")};
  font-weight: 325;
  font-size: ${({ $fontSize }) => $fontSize ?? "14px"};
  margin-bottom: 0;
`;

const ListElement = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #828d9e;

  p {
    color: #828d9e;
    font-weight: 325;
    font-size: 14px;
    margin-bottom: 0;
  }
`;

export const getPhoneByCountry = (countryCode: string) => {
  const country = _.find(countries, { code: countryCode });
  return country?.phone ? `+${country.phone}` : "";
};

export const getCountryFlag = (
  countryCode: string,
  color?: string,
  fontSize?: string
) => {
  switch (countryCode) {
    case "AD":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AD style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AD")}</span>
        </FlagContainer>
      );
    case "AE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AE")}</span>
        </FlagContainer>
      );
    case "AF":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AF style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AF")}</span>
        </FlagContainer>
      );
    case "AG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AG")}</span>
        </FlagContainer>
      );
    case "AI":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AI style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AI")}</span>
        </FlagContainer>
      );
    case "AL":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AL style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AL")}</span>
        </FlagContainer>
      );
    case "AM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AM")}</span>
        </FlagContainer>
      );
    case "AO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AO")}</span>
        </FlagContainer>
      );
    case "AQ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AQ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AQ")}</span>
        </FlagContainer>
      );
    case "AR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AR")}</span>
        </FlagContainer>
      );
    case "AS":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AS style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AS")}</span>
        </FlagContainer>
      );
    case "AT":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AT style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AT")}</span>
        </FlagContainer>
      );
    case "AU":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AU style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AU")}</span>
        </FlagContainer>
      );
    case "AW":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AW style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AW")}</span>
        </FlagContainer>
      );
    case "AX":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AX style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AX")}</span>
        </FlagContainer>
      );
    case "AZ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <AZ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("AZ")}</span>
        </FlagContainer>
      );
    case "BA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BA")}</span>
        </FlagContainer>
      );
    case "BB":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BB style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BB")}</span>
        </FlagContainer>
      );
    case "BD":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BD style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BD")}</span>
        </FlagContainer>
      );
    case "BE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BE")}</span>
        </FlagContainer>
      );
    case "BF":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BF style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BF")}</span>
        </FlagContainer>
      );
    case "BG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BG")}</span>
        </FlagContainer>
      );
    case "BH":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BH style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BH")}</span>
        </FlagContainer>
      );
    case "BI":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BI style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BI")}</span>
        </FlagContainer>
      );
    case "BJ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BJ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BJ")}</span>
        </FlagContainer>
      );
    case "BL":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BL style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BL")}</span>
        </FlagContainer>
      );
    case "BM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BM")}</span>
        </FlagContainer>
      );
    case "BN":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BN style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BN")}</span>
        </FlagContainer>
      );
    case "BO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BO")}</span>
        </FlagContainer>
      );
    case "BR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BR")}</span>
        </FlagContainer>
      );
    case "BS":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BS style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BS")}</span>
        </FlagContainer>
      );
    case "BT":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BT style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BT")}</span>
        </FlagContainer>
      );
    case "BV":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BV style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BV")}</span>
        </FlagContainer>
      );
    case "BW":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BW style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BW")}</span>
        </FlagContainer>
      );
    case "BY":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BY style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BY")}</span>
        </FlagContainer>
      );
    case "BZ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <BZ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("BZ")}</span>
        </FlagContainer>
      );
    case "CA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CA")}</span>
        </FlagContainer>
      );
    case "CC":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CC style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CC")}</span>
        </FlagContainer>
      );
    case "CD":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CD style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CD")}</span>
        </FlagContainer>
      );
    case "CF":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CF style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CF")}</span>
        </FlagContainer>
      );
    case "CG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CG")}</span>
        </FlagContainer>
      );
    case "CH":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CH style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CH")}</span>
        </FlagContainer>
      );
    case "CI":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CI style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CI")}</span>
        </FlagContainer>
      );
    case "CK":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CK style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CK")}</span>
        </FlagContainer>
      );
    case "CL":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CL style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CL")}</span>
        </FlagContainer>
      );
    case "CM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CM")}</span>
        </FlagContainer>
      );
    case "CN":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CN style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CN")}</span>
        </FlagContainer>
      );
    case "CO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CO")}</span>
        </FlagContainer>
      );
    case "CR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CR")}</span>
        </FlagContainer>
      );
    case "CU":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CU style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CU")}</span>
        </FlagContainer>
      );
    case "CV":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CV style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CV")}</span>
        </FlagContainer>
      );
    case "CW":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CW style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CW")}</span>
        </FlagContainer>
      );
    case "CX":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CX style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CX")}</span>
        </FlagContainer>
      );
    case "CY":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CY style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CY")}</span>
        </FlagContainer>
      );
    case "CZ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <CZ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("CZ")}</span>
        </FlagContainer>
      );
    case "DE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <DE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("DE")}</span>
        </FlagContainer>
      );
    case "DJ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <DJ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("DJ")}</span>
        </FlagContainer>
      );
    case "DK":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <DK style={{ width: "14px" }} />
          <span>{getPhoneByCountry("DK")}</span>
        </FlagContainer>
      );
    case "DM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <DM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("DM")}</span>
        </FlagContainer>
      );
    case "DO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <DO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("DO")}</span>
        </FlagContainer>
      );
    case "DZ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <DZ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("DZ")}</span>
        </FlagContainer>
      );
    case "EC":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <EC style={{ width: "14px" }} />
          <span>{getPhoneByCountry("EC")}</span>
        </FlagContainer>
      );
    case "EE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <EE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("EE")}</span>
        </FlagContainer>
      );
    case "EG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <EG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("EG")}</span>
        </FlagContainer>
      );
    case "EH":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <EH style={{ width: "14px" }} />
          <span>{getPhoneByCountry("EH")}</span>
        </FlagContainer>
      );
    case "ER":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <ER style={{ width: "14px" }} />
          <span>{getPhoneByCountry("ER")}</span>
        </FlagContainer>
      );
    case "ES":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <ES style={{ width: "14px" }} />
          <span>{getPhoneByCountry("ES")}</span>
        </FlagContainer>
      );
    case "ET":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <ET style={{ width: "14px" }} />
          <span>{getPhoneByCountry("ET")}</span>
        </FlagContainer>
      );
    case "FI":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <FI style={{ width: "14px" }} />
          <span>{getPhoneByCountry("FI")}</span>
        </FlagContainer>
      );
    case "FJ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <FJ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("FJ")}</span>
        </FlagContainer>
      );
    case "FK":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <FK style={{ width: "14px" }} />
          <span>{getPhoneByCountry("FK")}</span>
        </FlagContainer>
      );
    case "FM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <FM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("FM")}</span>
        </FlagContainer>
      );
    case "FO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <FO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("FO")}</span>
        </FlagContainer>
      );
    case "FR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <FR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("FR")}</span>
        </FlagContainer>
      );
    case "GA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GA")}</span>
        </FlagContainer>
      );
    case "GB":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GB style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GB")}</span>
        </FlagContainer>
      );
    case "GD":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GD style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GD")}</span>
        </FlagContainer>
      );
    case "GE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GE")}</span>
        </FlagContainer>
      );
    case "GF":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GF style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GF")}</span>
        </FlagContainer>
      );
    case "GG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GG")}</span>
        </FlagContainer>
      );
    case "GH":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GH style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GH")}</span>
        </FlagContainer>
      );
    case "GI":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GI style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GI")}</span>
        </FlagContainer>
      );
    case "GL":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GL style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GL")}</span>
        </FlagContainer>
      );
    case "GM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GM")}</span>
        </FlagContainer>
      );
    case "GN":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GN style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GN")}</span>
        </FlagContainer>
      );
    case "GP":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GP style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GP")}</span>
        </FlagContainer>
      );
    case "GQ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GQ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GQ")}</span>
        </FlagContainer>
      );
    case "GR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GR")}</span>
        </FlagContainer>
      );
    case "GS":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GS style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GS")}</span>
        </FlagContainer>
      );
    case "GT":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GT style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GT")}</span>
        </FlagContainer>
      );
    case "GU":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GU style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GU")}</span>
        </FlagContainer>
      );
    case "GW":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GW style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GW")}</span>
        </FlagContainer>
      );
    case "GY":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <GY style={{ width: "14px" }} />
          <span>{getPhoneByCountry("GY")}</span>
        </FlagContainer>
      );
    case "HK":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <HK style={{ width: "14px" }} />
          <span>{getPhoneByCountry("HK")}</span>
        </FlagContainer>
      );
    case "HM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <HM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("HM")}</span>
        </FlagContainer>
      );
    case "HN":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <HN style={{ width: "14px" }} />
          <span>{getPhoneByCountry("HN")}</span>
        </FlagContainer>
      );
    case "HR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <HR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("HR")}</span>
        </FlagContainer>
      );
    case "HT":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <HT style={{ width: "14px" }} />
          <span>{getPhoneByCountry("HT")}</span>
        </FlagContainer>
      );
    case "HU":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <HU style={{ width: "14px" }} />
          <span>{getPhoneByCountry("HU")}</span>
        </FlagContainer>
      );
    case "ID":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <ID style={{ width: "14px" }} />
          <span>{getPhoneByCountry("ID")}</span>
        </FlagContainer>
      );
    case "IE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <IE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("IE")}</span>
        </FlagContainer>
      );
    case "IL":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <IL style={{ width: "14px" }} />
          <span>{getPhoneByCountry("IL")}</span>
        </FlagContainer>
      );
    case "IM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <IM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("IM")}</span>
        </FlagContainer>
      );
    case "IN":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <IN style={{ width: "14px" }} />
          <span>{getPhoneByCountry("IN")}</span>
        </FlagContainer>
      );
    case "IO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <IO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("IO")}</span>
        </FlagContainer>
      );
    case "IQ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <IQ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("IQ")}</span>
        </FlagContainer>
      );
    case "IR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <IR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("IR")}</span>
        </FlagContainer>
      );
    case "IS":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <IS style={{ width: "14px" }} />
          <span>{getPhoneByCountry("IS")}</span>
        </FlagContainer>
      );
    case "IT":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <IT style={{ width: "14px" }} />
          <span>{getPhoneByCountry("IT")}</span>
        </FlagContainer>
      );
    case "JE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <JE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("JE")}</span>
        </FlagContainer>
      );
    case "JM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <JM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("JM")}</span>
        </FlagContainer>
      );
    case "JO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <JO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("JO")}</span>
        </FlagContainer>
      );
    case "JP":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <JP style={{ width: "14px" }} />
          <span>{getPhoneByCountry("JP")}</span>
        </FlagContainer>
      );
    case "KE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <KE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("KE")}</span>
        </FlagContainer>
      );
    case "KG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <KG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("KG")}</span>
        </FlagContainer>
      );
    case "KH":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <KH style={{ width: "14px" }} />
          <span>{getPhoneByCountry("KH")}</span>
        </FlagContainer>
      );
    case "KI":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <KI style={{ width: "14px" }} />
          <span>{getPhoneByCountry("KI")}</span>
        </FlagContainer>
      );
    case "KM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <KM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("KM")}</span>
        </FlagContainer>
      );
    case "KN":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <KN style={{ width: "14px" }} />
          <span>{getPhoneByCountry("KN")}</span>
        </FlagContainer>
      );
    case "KP":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <KP style={{ width: "14px" }} />
          <span>{getPhoneByCountry("KP")}</span>
        </FlagContainer>
      );
    case "KR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <KR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("KR")}</span>
        </FlagContainer>
      );
    case "KW":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <KW style={{ width: "14px" }} />
          <span>{getPhoneByCountry("KW")}</span>
        </FlagContainer>
      );
    case "KY":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <KY style={{ width: "14px" }} />
          <span>{getPhoneByCountry("KY")}</span>
        </FlagContainer>
      );
    case "KZ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <KZ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("KZ")}</span>
        </FlagContainer>
      );
    case "LA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <LA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("LA")}</span>
        </FlagContainer>
      );
    case "LB":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <LB style={{ width: "14px" }} />
          <span>{getPhoneByCountry("LB")}</span>
        </FlagContainer>
      );
    case "LC":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <LC style={{ width: "14px" }} />
          <span>{getPhoneByCountry("LC")}</span>
        </FlagContainer>
      );
    case "LI":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <LI style={{ width: "14px" }} />
          <span>{getPhoneByCountry("LI")}</span>
        </FlagContainer>
      );
    case "LK":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <LK style={{ width: "14px" }} />
          <span>{getPhoneByCountry("LK")}</span>
        </FlagContainer>
      );
    case "LR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <LR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("LR")}</span>
        </FlagContainer>
      );
    case "LS":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <LS style={{ width: "14px" }} />
          <span>{getPhoneByCountry("LS")}</span>
        </FlagContainer>
      );
    case "LT":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <LT style={{ width: "14px" }} />
          <span>{getPhoneByCountry("LT")}</span>
        </FlagContainer>
      );
    case "LU":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <LU style={{ width: "14px" }} />
          <span>{getPhoneByCountry("LU")}</span>
        </FlagContainer>
      );
    case "LV":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <LV style={{ width: "14px" }} />
          <span>{getPhoneByCountry("LV")}</span>
        </FlagContainer>
      );
    case "LY":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <LY style={{ width: "14px" }} />
          <span>{getPhoneByCountry("LY")}</span>
        </FlagContainer>
      );
    case "MA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MA")}</span>
        </FlagContainer>
      );
    case "MC":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MC style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MC")}</span>
        </FlagContainer>
      );
    case "MD":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MD style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MD")}</span>
        </FlagContainer>
      );
    case "ME":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <ME style={{ width: "14px" }} />
          <span>{getPhoneByCountry("ME")}</span>
        </FlagContainer>
      );
    case "MF":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MF style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MF")}</span>
        </FlagContainer>
      );
    case "MG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MG")}</span>
        </FlagContainer>
      );
    case "MH":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MH style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MH")}</span>
        </FlagContainer>
      );
    case "MK":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MK style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MK")}</span>
        </FlagContainer>
      );
    case "ML":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <ML style={{ width: "14px" }} />
          <span>{getPhoneByCountry("ML")}</span>
        </FlagContainer>
      );
    case "MM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MM")}</span>
        </FlagContainer>
      );
    case "MN":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MN style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MN")}</span>
        </FlagContainer>
      );
    case "MO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MO")}</span>
        </FlagContainer>
      );
    case "MP":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MP style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MP")}</span>
        </FlagContainer>
      );
    case "MQ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MQ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MQ")}</span>
        </FlagContainer>
      );
    case "MR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MR")}</span>
        </FlagContainer>
      );
    case "MS":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MS style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MS")}</span>
        </FlagContainer>
      );
    case "MT":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MT style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MT")}</span>
        </FlagContainer>
      );
    case "MU":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MU style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MU")}</span>
        </FlagContainer>
      );
    case "MV":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MV style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MV")}</span>
        </FlagContainer>
      );
    case "MW":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MW style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MW")}</span>
        </FlagContainer>
      );
    case "MX":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MX style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MX")}</span>
        </FlagContainer>
      );
    case "MY":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MY style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MY")}</span>
        </FlagContainer>
      );
    case "MZ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <MZ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("MZ")}</span>
        </FlagContainer>
      );
    case "NA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NA")}</span>
        </FlagContainer>
      );
    case "NC":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NC style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NC")}</span>
        </FlagContainer>
      );
    case "NE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NE")}</span>
        </FlagContainer>
      );
    case "NF":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NF style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NF")}</span>
        </FlagContainer>
      );
    case "NG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NG")}</span>
        </FlagContainer>
      );
    case "NI":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NI style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NI")}</span>
        </FlagContainer>
      );
    case "NL":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NL style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NL")}</span>
        </FlagContainer>
      );
    case "NO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NO")}</span>
        </FlagContainer>
      );
    case "NP":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NP style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NP")}</span>
        </FlagContainer>
      );
    case "NR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NR")}</span>
        </FlagContainer>
      );
    case "NU":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NU style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NU")}</span>
        </FlagContainer>
      );
    case "NZ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <NZ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("NZ")}</span>
        </FlagContainer>
      );
    case "OM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <OM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("OM")}</span>
        </FlagContainer>
      );
    case "PA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PA")}</span>
        </FlagContainer>
      );
    case "PE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PE")}</span>
        </FlagContainer>
      );
    case "PF":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PF style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PF")}</span>
        </FlagContainer>
      );
    case "PG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PG")}</span>
        </FlagContainer>
      );
    case "PH":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PH style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PH")}</span>
        </FlagContainer>
      );
    case "PK":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PK style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PK")}</span>
        </FlagContainer>
      );
    case "PL":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PL style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PL")}</span>
        </FlagContainer>
      );
    case "PM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PM")}</span>
        </FlagContainer>
      );
    case "PN":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PN style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PN")}</span>
        </FlagContainer>
      );
    case "PR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PR")}</span>
        </FlagContainer>
      );
    case "PS":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PS style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PS")}</span>
        </FlagContainer>
      );
    case "PT":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PT style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PT")}</span>
        </FlagContainer>
      );
    case "PW":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PW style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PW")}</span>
        </FlagContainer>
      );
    case "PY":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <PY style={{ width: "14px" }} />
          <span>{getPhoneByCountry("PY")}</span>
        </FlagContainer>
      );
    case "QA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <QA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("QA")}</span>
        </FlagContainer>
      );
    case "RE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <RE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("RE")}</span>
        </FlagContainer>
      );
    case "RO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <RO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("RO")}</span>
        </FlagContainer>
      );
    case "RS":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <RS style={{ width: "14px" }} />
          <span>{getPhoneByCountry("RS")}</span>
        </FlagContainer>
      );
    case "RU":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <RU style={{ width: "14px" }} />
          <span>{getPhoneByCountry("RU")}</span>
        </FlagContainer>
      );
    case "RW":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <RW style={{ width: "14px" }} />
          <span>{getPhoneByCountry("RW")}</span>
        </FlagContainer>
      );
    case "SA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SA")}</span>
        </FlagContainer>
      );
    case "SB":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SB style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SB")}</span>
        </FlagContainer>
      );
    case "SC":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SC style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SC")}</span>
        </FlagContainer>
      );
    case "SD":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SD style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SD")}</span>
        </FlagContainer>
      );
    case "SE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SE")}</span>
        </FlagContainer>
      );
    case "SG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SG")}</span>
        </FlagContainer>
      );
    case "SH":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SH style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SH")}</span>
        </FlagContainer>
      );
    case "SI":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SI style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SI")}</span>
        </FlagContainer>
      );
    case "SJ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SJ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SJ")}</span>
        </FlagContainer>
      );
    case "SK":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SK style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SK")}</span>
        </FlagContainer>
      );
    case "SL":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SL style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SL")}</span>
        </FlagContainer>
      );
    case "SM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SM")}</span>
        </FlagContainer>
      );
    case "SN":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SN style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SN")}</span>
        </FlagContainer>
      );
    case "SO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SO")}</span>
        </FlagContainer>
      );
    case "SR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SR")}</span>
        </FlagContainer>
      );
    case "SS":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SS style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SS")}</span>
        </FlagContainer>
      );
    case "ST":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <ST style={{ width: "14px" }} />
          <span>{getPhoneByCountry("ST")}</span>
        </FlagContainer>
      );
    case "SV":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SV style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SV")}</span>
        </FlagContainer>
      );
    case "SX":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SX style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SX")}</span>
        </FlagContainer>
      );
    case "SY":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SY style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SY")}</span>
        </FlagContainer>
      );
    case "SZ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <SZ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("SZ")}</span>
        </FlagContainer>
      );
    case "TC":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TC style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TC")}</span>
        </FlagContainer>
      );
    case "TD":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TD style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TD")}</span>
        </FlagContainer>
      );
    case "TF":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TF style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TF")}</span>
        </FlagContainer>
      );
    case "TG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TG")}</span>
        </FlagContainer>
      );
    case "TH":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TH style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TH")}</span>
        </FlagContainer>
      );
    case "TJ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TJ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TJ")}</span>
        </FlagContainer>
      );
    case "TK":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TK style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TK")}</span>
        </FlagContainer>
      );
    case "TL":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TL style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TL")}</span>
        </FlagContainer>
      );
    case "TM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TM")}</span>
        </FlagContainer>
      );
    case "TN":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TN style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TN")}</span>
        </FlagContainer>
      );
    case "TO":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TO style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TO")}</span>
        </FlagContainer>
      );
    case "TR":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TR style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TR")}</span>
        </FlagContainer>
      );
    case "TT":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TT style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TT")}</span>
        </FlagContainer>
      );
    case "TV":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TV style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TV")}</span>
        </FlagContainer>
      );
    case "TW":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TW style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TW")}</span>
        </FlagContainer>
      );
    case "TZ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <TZ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("TZ")}</span>
        </FlagContainer>
      );
    case "UA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <UA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("UA")}</span>
        </FlagContainer>
      );
    case "UG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <UG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("UG")}</span>
        </FlagContainer>
      );
    case "US":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <US style={{ width: "14px" }} />
          <span>{getPhoneByCountry("US")}</span>
        </FlagContainer>
      );
    case "UY":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <UY style={{ width: "14px" }} />
          <span>{getPhoneByCountry("UY")}</span>
        </FlagContainer>
      );
    case "UZ":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <UZ style={{ width: "14px" }} />
          <span>{getPhoneByCountry("UZ")}</span>
        </FlagContainer>
      );
    case "VA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <VA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("VA")}</span>
        </FlagContainer>
      );
    case "VC":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <VC style={{ width: "14px" }} />
          <span>{getPhoneByCountry("VC")}</span>
        </FlagContainer>
      );
    case "VE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <VE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("VE")}</span>
        </FlagContainer>
      );
    case "VG":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <VG style={{ width: "14px" }} />
          <span>{getPhoneByCountry("VG")}</span>
        </FlagContainer>
      );
    case "VI":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <VI style={{ width: "14px" }} />
          <span>{getPhoneByCountry("VI")}</span>
        </FlagContainer>
      );
    case "VN":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <VN style={{ width: "14px" }} />
          <span>{getPhoneByCountry("VN")}</span>
        </FlagContainer>
      );
    case "VU":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <VU style={{ width: "14px" }} />
          <span>{getPhoneByCountry("VU")}</span>
        </FlagContainer>
      );
    case "WF":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <WF style={{ width: "14px" }} />
          <span>{getPhoneByCountry("WF")}</span>
        </FlagContainer>
      );
    case "WS":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <WS style={{ width: "14px" }} />
          <span>{getPhoneByCountry("WS")}</span>
        </FlagContainer>
      );
    case "XK":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <XK style={{ width: "14px" }} />
          <span>{getPhoneByCountry("XK")}</span>
        </FlagContainer>
      );
    case "YE":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <YE style={{ width: "14px" }} />
          <span>{getPhoneByCountry("YE")}</span>
        </FlagContainer>
      );
    case "YT":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <YT style={{ width: "14px" }} />
          <span>{getPhoneByCountry("YT")}</span>
        </FlagContainer>
      );
    case "ZA":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <ZA style={{ width: "14px" }} />
          <span>{getPhoneByCountry("ZA")}</span>
        </FlagContainer>
      );
    case "ZM":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <ZM style={{ width: "14px" }} />
          <span>{getPhoneByCountry("ZM")}</span>
        </FlagContainer>
      );
    case "ZW":
      return (
        <FlagContainer $color={color} $fontSize={fontSize}>
          <ZW style={{ width: "14px" }} />
          <span>{getPhoneByCountry("ZW")}</span>
        </FlagContainer>
      );
    default:
      return "";
  }
};

export const getPhoneFormatSearchableValues = (countryCode: string) => {
  switch (countryCode) {
    case "AD":
      return "AD,376,+376,Andorra";

    case "AE":
      return "AE,971,+971,United Arab Emirates";

    case "AF":
      return "AF,93,+93,Afghanistan";

    case "AG":
      return "AG,1-268,+1-268,Antigua and Barbuda";

    case "AI":
      return "AI,1-264,+1-264,Anguilla";

    case "AL":
      return "AL,355,+355,Albania";

    case "AM":
      return "AM,374,+374,Armenia";

    case "AO":
      return "AO,244,+244,Angola";

    case "AQ":
      return "AQ,672,+672,Antarctica";

    case "AR":
      return "AR,54,+54,Argentina";

    case "AS":
      return "AS,1-684,+1-684,American Samoa";

    case "AT":
      return "AT,43,+43,Austria";

    case "AU":
      return "AU,61,+61,Australia";

    case "AW":
      return "AW,297,+297,Aruba";

    case "AX":
      return "AX,358,+358,Alland Islands";

    case "AZ":
      return "AZ,994,+994,Azerbaijan";

    case "BA":
      return "BA,387,+387,Bosnia and Herzegovina";

    case "BB":
      return "BB,1-246,+1-246,Barbados";

    case "BD":
      return "BD,880,+880,Bangladesh";

    case "BE":
      return "BE,32,+32,Belgium";

    case "BF":
      return "BF,226,+226,Burkina Faso";

    case "BG":
      return "BG,359,+359,Bulgaria";

    case "BH":
      return "BH,973,+973,Bahrain";

    case "BI":
      return "BI,257,+257,Burundi";

    case "BJ":
      return "BJ,229,+229,Benin";

    case "BL":
      return "BL,590,+590,Saint Barthelemy";

    case "BM":
      return "BM,1-441,+1-441,Bermuda";

    case "BN":
      return "BN,673,+673,Brunei Darussalam";

    case "BO":
      return "BO,591,+591,Bolivia";

    case "BR":
      return "BR,55,+55,Brazil";

    case "BS":
      return "BS,1-242,+1-242,Bahamas";

    case "BT":
      return "BT,975,+975,Bhutan";

    case "BV":
      return "BV,47,+47,Bouvet Island";

    case "BW":
      return "BW,267,+267,Botswana";

    case "BY":
      return "BY,375,+375,Belarus";

    case "BZ":
      return "BZ,501,+501,Belize";

    case "CA":
      return "CA,1,+1,Canada";

    case "CC":
      return "CC,61,+61,Cocos (Keeling) Islands";

    case "CD":
      return "CD,243,+243,Congo, Democratic Republic of the";

    case "CF":
      return "CF,236,+236,Central African Republic";

    case "CG":
      return "CG,242,+242,Congo, Republic of the";

    case "CH":
      return "CH,41,+41,Switzerland";

    case "CI":
      return "CI,225,+225,Cote d'Ivoire";

    case "CK":
      return "CK,682,+682,Cook Islands";

    case "CL":
      return "CL,56,+56,Chile";

    case "CM":
      return "CM,237,+237,Cameroon";

    case "CN":
      return "CN,86,+86,China";

    case "CO":
      return "CO,57,+57,Colombia";

    case "CR":
      return "CR,506,+506,Costa Rica";

    case "CU":
      return "CU,53,+53,Cuba";

    case "CV":
      return "CV,238,+238,Cape Verde";

    case "CW":
      return "CW,599,+599,Curacao";

    case "CX":
      return "CX,61,+61,Christmas Island";

    case "CY":
      return "CY,357,+357,Cyprus";

    case "CZ":
      return "CZ,420,+420,Czech Republic";

    case "DE":
      return "DE,49,+49,Germany";

    case "DJ":
      return "DJ,253,+253,Djibouti";

    case "DK":
      return "DK,45,+45,Denmark";

    case "DM":
      return "DM,1-767,+1-767,Dominica";

    case "DO":
      return "DO,1-809,+1-809,Dominican Republic";

    case "DZ":
      return "DZ,213,+213,Algeria";

    case "EC":
      return "EC,593,+593,Ecuador";

    case "EE":
      return "EE,372,+372,Estonia";

    case "EG":
      return "EG,20,+20,Egypt";

    case "EH":
      return "EH,212,+212,Western Sahara";

    case "ER":
      return "ER,291,+291,Eritrea";

    case "ES":
      return "ES,34,+34,Spain";

    case "ET":
      return "ET,251,+251,Ethiopia";

    case "FI":
      return "FI,358,+358,Finland";

    case "FJ":
      return "FJ,679,+679,Fiji";

    case "FK":
      return "FK,500,+500,Falkland Islands (Malvinas)";

    case "FM":
      return "FM,691,+691,Micronesia, Federated States of";

    case "FO":
      return "FO,298,+298,Faroe Islands";

    case "FR":
      return "FR,33,+33,France";

    case "GA":
      return "GA,241,+241,Gabon";

    case "GB":
      return "GB,44,+44,United Kingdom";

    case "GD":
      return "GD,1-473,+1-473,Grenada";

    case "GE":
      return "GE,995,+995,Georgia";

    case "GF":
      return "GF,594,+594,French Guiana";

    case "GG":
      return "GG,44,+44,Guernsey";

    case "GH":
      return "GH,233,+233,Ghana";

    case "GI":
      return "GI,350,+350,Gibraltar";

    case "GL":
      return "GL,299,+299,Greenland";

    case "GM":
      return "GM,220,+220,Gambia";

    case "GN":
      return "GN,224,+224,Guinea";

    case "GP":
      return "GP,590,+590,Guadeloupe";

    case "GQ":
      return "GQ,240,+240,Equatorial Guinea";

    case "GR":
      return "GR,30,+30,Greece";

    case "GS":
      return "GS,500,+500,South Georgia and the South Sandwich Islands";

    case "GT":
      return "GT,502,+502,Guatemala";

    case "GU":
      return "GU,1-671,+1-671,Guam";

    case "GW":
      return "GW,245,+245,Guinea-Bissau";

    case "GY":
      return "GY,592,+592,Guyana";

    case "HK":
      return "HK,852,+852,Hong Kong";

    case "HM":
      return "HM,672,+672,Heard Island and McDonald Islands";

    case "HN":
      return "HN,504,+504,Honduras";

    case "HR":
      return "HR,385,+385,Croatia";

    case "HT":
      return "HT,509,+509,Haiti";

    case "HU":
      return "HU,36,+36,Hungary";

    case "ID":
      return "ID,62,+62,Indonesia";

    case "IE":
      return "IE,353,+353,Ireland";

    case "IL":
      return "IL,972,+972,Israel";

    case "IM":
      return "IM,44,+44,Isle of Man";

    case "IN":
      return "IN,91,+91,India";

    case "IO":
      return "IO,246,+246,British Indian Ocean Territory";

    case "IQ":
      return "IQ,964,+964,Iraq";

    case "IR":
      return "IR,98,+98,Iran, Islamic Republic of";

    case "IS":
      return "IS,354,+354,Iceland";

    case "IT":
      return "IT,39,+39,Italy";

    case "JE":
      return "JE,44,+44,Jersey";

    case "JM":
      return "JM,1-876,+1-876,Jamaica";

    case "JO":
      return "JO,962,+962,Jordan";

    case "JP":
      return "JP,81,+81,Japan";

    case "KE":
      return "KE,254,+254,Kenya";

    case "KG":
      return "KG,996,+996,Kyrgyzstan";

    case "KH":
      return "KH,855,+855,Cambodia";

    case "KI":
      return "KI,686,+686,Kiribati";

    case "KM":
      return "KM,269,+269,Comoros";

    case "KN":
      return "KN,1-869,+1-869,Saint Kitts and Nevis";

    case "KP":
      return "KP,850,+850,Korea, Democratic People's Republic of";

    case "KR":
      return "KR,82,+82,Korea, Republic of";

    case "KW":
      return "KW,965,+965,Kuwait";

    case "KY":
      return "KY,1-345,+1-345,Cayman Islands";

    case "KZ":
      return "KZ,7,+7,Kazakhstan";

    case "LA":
      return "LA,856,+856,Lao People's Democratic Republic";

    case "LB":
      return "LB,961,+961,Lebanon";

    case "LC":
      return "LC,1-758,+1-758,Saint Lucia";

    case "LI":
      return "LI,423,+423,Liechtenstein";

    case "LK":
      return "LK,94,+94,Sri Lanka";

    case "LR":
      return "LR,231,+231,Liberia";

    case "LS":
      return "LS,266,+266,Lesotho";

    case "LT":
      return "LT,370,+370,Lithuania";

    case "LU":
      return "LU,352,+352,Luxembourg";

    case "LV":
      return "LV,371,+371,Latvia";

    case "LY":
      return "LY,218,+218,Libya";

    case "MA":
      return "MA,212,+212,Morocco";

    case "MC":
      return "MC,377,+377,Monaco";

    case "MD":
      return "MD,373,+373,Moldova, Republic of";

    case "ME":
      return "ME,382,+382,Montenegro";

    case "MF":
      return "MF,590,+590,Saint Martin (French part)";

    case "MG":
      return "MG,261,+261,Madagascar";

    case "MH":
      return "MH,692,+692,Marshall Islands";

    case "MK":
      return "MK,389,+389,Macedonia, the Former Yugoslav Republic of";

    case "ML":
      return "ML,223,+223,Mali";

    case "MM":
      return "MM,95,+95,Myanmar";

    case "MN":
      return "MN,976,+976,Mongolia";

    case "MO":
      return "MO,853,+853,Macao";

    case "MP":
      return "MP,1-670,+1-670,Northern Mariana Islands";

    case "MQ":
      return "MQ,596,+596,Martinique";

    case "MR":
      return "MR,222,+222,Mauritania";

    case "MS":
      return "MS,1-664,+1-664,Montserrat";

    case "MT":
      return "MT,356,+356,Malta";

    case "MU":
      return "MU,230,+230,Mauritius";

    case "MV":
      return "MV,960,+960,Maldives";

    case "MW":
      return "MW,265,+265,Malawi";

    case "MX":
      return "MX,52,+52,Mexico";

    case "MY":
      return "MY,60,+60,Malaysia";

    case "MZ":
      return "MZ,258,+258,Mozambique";

    case "NA":
      return "NA,264,+264,Namibia";

    case "NC":
      return "NC,687,+687,New Caledonia";

    case "NE":
      return "NE,227,+227,Niger";

    case "NF":
      return "NF,672,+672,Norfolk Island";

    case "NG":
      return "NG,234,+234,Nigeria";

    case "NI":
      return "NI,505,+505,Nicaragua";

    case "NL":
      return "NL,31,+31,Netherlands";

    case "NO":
      return "NO,47,+47,Norway";

    case "NP":
      return "NP,977,+977,Nepal";

    case "NR":
      return "NR,674,+674,Nauru";

    case "NU":
      return "NU,683,+683,Niue";

    case "NZ":
      return "NZ,64,+64,New Zealand";

    case "OM":
      return "OM,968,+968,Oman";

    case "PA":
      return "PA,507,+507,Panama";

    case "PE":
      return "PE,51,+51,Peru";

    case "PF":
      return "PF,689,+689,French Polynesia";

    case "PG":
      return "PG,675,+675,Papua New Guinea";

    case "PH":
      return "PH,63,+63,Philippines";

    case "PK":
      return "PK,92,+92,Pakistan";

    case "PL":
      return "PL,48,+48,Poland";

    case "PM":
      return "PM,508,+508,Saint Pierre and Miquelon";

    case "PN":
      return "PN,870,+870,Pitcairn";

    case "PR":
      return "PR,1,+1,Puerto Rico";

    case "PS":
      return "PS,970,+970,Palestine, State of";

    case "PT":
      return "PT,351,+351,Portugal";

    case "PW":
      return "PW,680,+680,Palau";

    case "PY":
      return "PY,595,+595,Paraguay";

    case "QA":
      return "QA,974,+974,Qatar";

    case "RE":
      return "RE,262,+262,Reunion";

    case "RO":
      return "RO,40,+40,Romania";

    case "RS":
      return "RS,381,+381,Serbia";

    case "RU":
      return "RU,7,+7,Russian Federation";

    case "RW":
      return "RW,250,+250,Rwanda";

    case "SA":
      return "SA,966,+966,Saudi Arabia";

    case "SB":
      return "SB,677,+677,Solomon Islands";

    case "SC":
      return "SC,248,+248,Seychelles";

    case "SD":
      return "SD,249,+249,Sudan";

    case "SE":
      return "SE,46,+46,Sweden";

    case "SG":
      return "SG,65,+65,Singapore";

    case "SH":
      return "SH,290,+290,Saint Helena";

    case "SI":
      return "SI,386,+386,Slovenia";

    case "SJ":
      return "SJ,47,+47,Svalbard and Jan Mayen";

    case "SK":
      return "SK,421,+421,Slovakia";

    case "SL":
      return "SL,232,+232,Sierra Leone";

    case "SM":
      return "SM,378,+378,San Marino";

    case "SN":
      return "SN,221,+221,Senegal";

    case "SO":
      return "SO,252,+252,Somalia";

    case "SR":
      return "SR,597,+597,Suriname";

    case "SS":
      return "SS,211,+211,South Sudan";

    case "ST":
      return "ST,239,+239,Sao Tome and Principe";

    case "SV":
      return "SV,503,+503,El Salvador";

    case "SX":
      return "SX,1-721,+1-721,Sint Maarten (Dutch part)";

    case "SY":
      return "SY,963,+963,Syrian Arab Republic";

    case "SZ":
      return "SZ,268,+268,Swaziland";

    case "TC":
      return "TC,1-649,+1-649,Turks and Caicos Islands";

    case "TD":
      return "TD,235,+235,Chad";

    case "TF":
      return "TF,262,+262,French Southern Territories";

    case "TG":
      return "TG,228,+228,Togo";

    case "TH":
      return "TH,66,+66,Thailand";

    case "TJ":
      return "TJ,992,+992,Tajikistan";

    case "TK":
      return "TK,690,+690,Tokelau";

    case "TL":
      return "TL,670,+670,Timor-Leste";

    case "TM":
      return "TM,993,+993,Turkmenistan";

    case "TN":
      return "TN,216,+216,Tunisia";

    case "TO":
      return "TO,676,+676,Tonga";

    case "TR":
      return "TR,90,+90,Turkey";

    case "TT":
      return "TT,1-868,+1-868,Trinidad and Tobago";

    case "TV":
      return "TV,688,+688,Tuvalu";

    case "TW":
      return "TW,886,+886,Taiwan, Republic of China";

    case "TZ":
      return "TZ,255,+255,United Republic of Tanzania";

    case "UA":
      return "UA,380,+380,Ukraine";

    case "UG":
      return "UG,256,+256,Uganda";

    case "US":
      return "US,1,+1,United States";

    case "UY":
      return "UY,598,+598,Uruguay";

    case "UZ":
      return "UZ,998,+998,Uzbekistan";

    case "VA":
      return "VA,379,+379,Holy See (Vatican City State)";

    case "VC":
      return "VC,1-784,+1-784,Saint Vincent and the Grenadines";

    case "VE":
      return "VE,58,+58,Venezuela";

    case "VG":
      return "VG,1-284,+1-284,British Virgin Islands";

    case "VI":
      return "VI,1-340,+1-340,US Virgin Islands";

    case "VN":
      return "VN,84,+84,Vietnam";

    case "VU":
      return "VU,678,+678,Vanuatu";

    case "WF":
      return "WF,681,+681,Wallis and Futuna";

    case "WS":
      return "WS,685,+685,Samoa";

    case "XK":
      return "XK,383,+383,Kosovo";

    case "YE":
      return "YE,967,+967,Yemen";

    case "YT":
      return "YT,262,+262,Mayotte";

    case "ZA":
      return "ZA,27,+27,South Africa";

    case "ZM":
      return "ZM,260,+260,Zambia";

    case "ZW":
      return "ZW,263,+263,Zimbabwe";
  }
};

export const transformedOptions = (() =>
  countries.map((c) => {
    return {
      value: c.code,
      searchableValues: getPhoneFormatSearchableValues(c.code),
      label: (
        <ListElement>
          {getCountryFlag(c.code || "")}
          <p>{c.label}</p>
        </ListElement>
      ),
      asSelected: <ListElement>{getCountryFlag(c.code || "")}</ListElement>,
    };
  }))();

export const optionsWithoutLabels = countries.map((c) => {
  return {
    value: c.code,
    searchableValues: getPhoneFormatSearchableValues(c.code),
    label: <ListElement>{getCountryFlag(c.code || "")}</ListElement>,
    asSelected: <ListElement>{getCountryFlag(c.code || "")}</ListElement>,
  };
});
