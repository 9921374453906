import styled, { css } from "styled-components";

export const ContextBackground = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

export const ContextMenuArrow = styled.div<{ top: number; left: number }>`
  z-index: 3;
  position: absolute;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}

  background: #48505e;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  transform: rotate(45deg);
`;

export const ContextMenuContainer = styled.div<{ top: number; left: number }>`
  z-index: 3;
  position: absolute;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}

  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: hidden;

  border-radius: 6px;
  background: #48505e;
  width: 200px;
  padding: 4px 0;
  cursor: auto;
`;

export const ContextualAction = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 16px;
  background: #48505e;
  width: 100%;
  height: 34px;
  max-height: 34px;
  cursor: pointer;
  user-select: none;

  p {
    margin: 0;
    color: white;
    font-family: "Gotham-Bold";
  }

  transition: background 0.35s ease;
  :hover {
    background: #0273e9;
  }
`;
