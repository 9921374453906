import styled from "styled-components";

export const StatsTableContainer = styled.div`
  flex: 1;
  overflow: auto scroll;
  max-height: 100%;
  padding-bottom: 10px;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableHeaders = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: start;
  gap: 1px;
`;

export const HeaderContainer = styled.div`
  width: 213px;
  padding: 12px 10px;
  display: flex;
  align-item: center;
  justify-content: center;
  background-color: #f2f8fe;
  cursor: grab;

  p {
    margin: 0;
    font-family: Gotham-Bold;
    text-align: center;
    color: #48505e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
  }

  :hover {
    .deleteIconContainer {
      opacity: 1;
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 42px;
`;

export const DeleteIconContainer = styled.div`
  opacity: 0;
  transition: opacity 0.35s ease;
`;

export const EditIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: #0273e9;
  cursor: pointer;

  :hover {
    background-color: #014c9b;
  }
`;

export const TableRows = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`;

export const TableCell = styled.div<{ $pair?: boolean }>`
  background-color: ${({ $pair = false }) => ($pair ? "#fff" : "#f6f5f5")};
  width: 213px;
  padding: 10px 8px;

  p {
    margin: 0;
    color: #48505e;
    text-align: center;
  }

  :hover {
    z-index: 1;
    box-shadow: 4px 10px 6px 0px #48505e4d;
  }
`;
