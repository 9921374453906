const NewLabel = "Nueva";
const StartedLabel = "Iniciado";
const StuckLabel = "Vencido";
const WaitingLabel = "Por iniciar";
const CompletedLabel = "Terminada";
const CanceledLabel = "Cancelada";

export const getStatusInfo = (
  status?: string
): { color: string; label?: string } => {
  switch (status) {
    case "New":
    case "Nuevo":
      return { color: "yellow", label: NewLabel };
    case "OnTime":
    case "Iniciado":
    case "A tiempo":
      return { color: "cyan", label: StartedLabel };
    case "Completed":
    case "Terminado":
      return { color: "green", label: CompletedLabel };
    case "Canceled":
    case "Cancelado":
      return { color: "default", label: CanceledLabel };
    case "WaitingToStart":
    case "Waiting":
    case "En espera":
    case "Por iniciar":
      return { color: "orange", label: WaitingLabel };
    case "Stuck":
    case "Vencido":
    case "Atorado":
      return { color: "red", label: StuckLabel };
    case "Por revisar":
      return { color: "blue", label: "Por revisar" };
    default:
      return { color: "default", label: status };
  }
};

export const getTaskStatusColors = (
  status?: string
): { color: string; background: string } => {
  switch (status) {
    case "OnTime":
    case "Iniciado":
    case "A tiempo":
      return {
        color: "rgba(8, 151, 156, 1)",
        background: "rgba(255, 255, 255, 1)",
      };
    case "WaitingToStart":
    case "Waiting":
    case "En espera":
    case "Por iniciar":
      return {
        color: "rgba(212, 107, 8, 1)",
        background: "rgba(255, 255, 255, 1)",
      };
    case "Stuck":
    case "Vencido":
    case "Atorado":
      return {
        color: "rgba(255, 0, 12, 1)",
        background: "rgba(255, 241, 240, 0.5)",
      };
    default:
      return { color: "default", background: "default" };
  }
};
