import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import { StringInput } from "./style";
import { Button } from "../../../../GeestUI";
import { Row } from "antd";
import { useMutation } from "../../../../hooks";
import { Languages } from "./Dictionary";
import { GraphType } from "../../Dashboards.d";

interface DeleteGraphModalProps {
  idDashboard: string | null;
  graph: GraphType | null;
  onClose: () => void;
}

const DeleteGraphModal: React.FC<DeleteGraphModalProps> = ({
  onClose,
  idDashboard,
  graph,
}) => {
  const [name, setName] = useState<string>("");
  const {
    ModalTitle,
    IrreversibleLabel,
    SureLabel,
    InputPlaceholder,
    CancelLabel,
    DeleteLabel,
  } = Languages["ESP"];

  const [deleteGraph] = useMutation({
    func: "Ver2-DashBoards-dg",
    onSuccess: () => {
      onClose();
    },
  });

  const onDeleteGraph = () => {
    deleteGraph({
      args: {
        IdDashBoard: idDashboard,
        IdGraph: graph?.IdGraph ?? "",
      },
    });
  };

  return (
    <Modal
      title={ModalTitle}
      onCancel={onClose}
      width="432px"
      contentStyles={{ textAlign: "center" }}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ width: "309px" }}>
          <p>
            <b>{SureLabel}</b>
          </p>
          <div>
            {IrreversibleLabel}
            <p style={{ color: "#DB232C" }}>{graph?.Configuration.Title}</p>
          </div>
        </div>
      </div>

      <StringInput
        value={name}
        onChange={({ target: { value } }) => setName(value)}
        placeholder={InputPlaceholder}
      />
      <Row justify="center" style={{ marginTop: "1rem" }}>
        <Button
          type="secondary"
          onClick={onClose}
          size="regular"
          style={{ marginRight: "0.5rem" }}
        >
          {CancelLabel}
        </Button>
        <Button
          type="primary"
          onClick={onDeleteGraph}
          size="regular"
          danger
          disabled={name !== graph?.Configuration.Title}
        >
          {DeleteLabel}
        </Button>
      </Row>
    </Modal>
  );
};

export default DeleteGraphModal;
