import React from "react";
import { LeftBarProps } from "./ProcessDetail.d";
import {
  LeftBarContainer,
  StateItem,
  StateItemsContainer,
  PendingMessages,
  Row,
} from "./styles";

const LeftBar: React.FC<LeftBarProps> = ({ state, setState, processInfo }) => {
  return (
    <LeftBarContainer>
      <StateItemsContainer>
        <StateItem
          $selected={state === "basicInfo"}
          onClick={() => setState("basicInfo")}
        >
          <div className="bar" />
          Información básica
        </StateItem>
        <StateItem
          $selected={state === "activity"}
          onClick={() => setState("activity")}
        >
          <div className="bar" />
          Actividad
        </StateItem>
        {processInfo?.Permissions?.UserCanOpenFlux && (
          <StateItem
            $selected={state === "flux"}
            onClick={() => setState("flux")}
          >
            <div className="bar" />
            Flujo
          </StateItem>
        )}
        {processInfo?.Permissions?.UserCanOpenFields && (
          <StateItem
            $selected={state === "fields"}
            onClick={() => setState("fields")}
          >
            <div className="bar" />
            Campos de formulario
          </StateItem>
        )}
        <StateItem
          $selected={state === "chat"}
          onClick={() => setState("chat")}
        >
          <div className="bar" />
          <Row style={{ alignItems: "center", gap: "8px" }}>
            Chat
            {!!processInfo?.MessageCount && processInfo?.MessageCount > 0 && (
              <PendingMessages>{processInfo?.MessageCount}</PendingMessages>
            )}
          </Row>
        </StateItem>
        <StateItem
          $selected={state === "durations"}
          onClick={() => setState("durations")}
        >
          <div className="bar" />
          Duraciones
        </StateItem>
      </StateItemsContainer>
    </LeftBarContainer>
  );
};

export default LeftBar;
