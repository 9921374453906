import React from "react";
import OrderPopup from "./OrderPopup";
import { Radio, Row } from "antd";
import { TemplateOrderType } from "../TableDetail.d";

interface TemplateOrderProps {
  templateOrder: TemplateOrderType;
  setTemplateOrder: (val: TemplateOrderType) => void;
}

const TemplateOrder: React.FC<TemplateOrderProps> = ({
  templateOrder,
  setTemplateOrder,
}) => {
  return (
    <OrderPopup>
      <Radio.Group
        value={templateOrder}
        onChange={(e) => setTemplateOrder(e.target.value)}
        style={{ width: "100%" }}
      >
        <Row
          style={{
            width: "100%",
            fontSize: "14px",
            lineHeight: "2.5rem",
          }}
          justify="space-between"
          align="middle"
        >
          Nombre de proceso
          <Radio value="ProcessTemplateName" />
        </Row>
        <Row
          style={{
            width: "100%",
            fontSize: "14px",
            lineHeight: "2.5rem",
          }}
          justify="space-between"
          align="middle"
        >
          Etapa
          <Radio value="IdStage" />
        </Row>
      </Radio.Group>
    </OrderPopup>
  );
};

export default TemplateOrder;
