import React from "react";
import _ from "lodash";
import { Row, Space } from "antd";
import { useFetch, useMutation } from "../../../../hooks";
import { Typography, Tag } from "../../../../GeestUI";
import { Languages } from "./Dictionary";
import { Priority } from "./ProcessDetail.d";
import { CloseIcon, PriorityContainer } from "./Styles";

const { H3 } = Typography;
const { PriorityLabel } = Languages["ESP"];

interface PriorityPopoverProps {
  IdProcessExecution: number;
  idTaskPriority?: number;
  reload: () => void;
  onClose: () => void;
}

const PriorityPopover: React.FC<PriorityPopoverProps> = ({
  IdProcessExecution,
  idTaskPriority,
  reload,
  onClose,
}) => {
  const { data } = useFetch<Priority[]>({
    func: "Ver2-Reports-gtp",
    args: { IdProcessExecution },
  });

  const [updatePriority] = useMutation<[]>({
    func: "Ver2-Reports-upp",
    onSuccess: () => reload(),
  });

  const onSelectPriority = (priority: Priority) => {
    if (idTaskPriority === priority.IdTaskPriority) {
      updatePriority({
        args: {
          IdProcessExecution,
          IdTaskPriority: "",
        },
      });
      return;
    }

    updatePriority({
      args: {
        IdProcessExecution,
        IdTaskPriority: priority.IdTaskPriority,
      },
    });
  };

  return (
    <div>
      <Row>
        <H3 mb="0" style={{ flex: 1 }}>
          {PriorityLabel}
        </H3>
        <CloseIcon onClick={onClose} />
        <Space direction="vertical" style={{ width: "100%" }} size={2}>
          {(data || []).map((priority) => (
            <PriorityContainer
              justify="space-between"
              $selected={!!_.isEqual(priority.IdTaskPriority, idTaskPriority)}
              onClick={() => onSelectPriority(priority)}
              key={priority.IdTaskPriority}
            >
              <Tag color={priority.Color}>{priority.Title}</Tag>
            </PriorityContainer>
          ))}
        </Space>
      </Row>
    </div>
  );
};

export default PriorityPopover;
