import React from "react";
import { BsLightningFill } from "react-icons/bs";
import { Handle, NodeProps } from "reactflow";
import styled from "styled-components";
import { endNodeHandlers } from "./shared/shared";
import { Circle, StyledTag, border, handlerStyles } from "./shared/styles";
import { NodeData } from "../Diagram.d";
import { getNodeStatusBackgroundColor, getStatusTag } from "../helpers";

const Top = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
`;
const Bottom = styled(Top)`
  border-top: ${border};
`;
const TextContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  line-height: 17px;
`;
const Text = styled.span``;

const EndNode: React.FC<NodeProps<NodeData>> = ({ selected, data }) => {
  const { status } = data;
  const nodeBackground = getNodeStatusBackgroundColor(status);
  const statusTag = getStatusTag(status);

  return (
    <Circle $isSelected={selected} $backgroundColor={nodeBackground}>
      <Top>
        <TextContainer>
          <Text>Fin</Text>
          <div>
            <StyledTag color={statusTag.color}>{statusTag.label}</StyledTag>
          </div>
        </TextContainer>
      </Top>
      <Bottom>
        <BsLightningFill color="#0273e9" size={18} style={{ margin: "auto" }} />
      </Bottom>
      {endNodeHandlers.map((handler) => (
        <Handle
          style={handlerStyles}
          key={handler.id}
          {...handler}
          isConnectableStart={false}
        />
      ))}
    </Circle>
  );
};

export default EndNode;
