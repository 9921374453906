import React, { useRef, useState } from "react";
import { Route, useHistory } from "react-router-dom";
// chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, getDatasetAtEvent, getElementAtEvent } from "react-chartjs-2";
// grid
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
// styles and data
import { data, options } from "./config";
import {
  DataBox,
  GridItemWrapper,
  ReportsContainer,
  TableContainer,
} from "./style";
import DetailPending from "../../../views/Pendings/DetailPending/DetailPending";

const ResponsiveGridLayout = WidthProvider(Responsive);

const layout = [
  { i: "stacked-horizontal", x: 0, y: 0, w: 2, h: 2 },
  { i: "empty-example-1", x: 2, y: 0, w: 1, h: 1 },
  { i: "empty-example-2", x: 3, y: 0, w: 1, h: 1 },
  { i: "empty-example-3", x: 2, y: 1, w: 1, h: 1 },
  { i: "empty-example-4", x: 0, y: 3, w: 1, h: 1 },
];

const getLayouts = () => {
  const savedLayouts = localStorage.getItem("gridLayoutReports");
  return savedLayouts ? JSON.parse(savedLayouts) : { lg: layout };
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Report: React.FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const chartRef = useRef();
  const history = useHistory();

  const toggleEditing = () => {
    setIsEditing(!isEditing);
    let newLayout = getLayouts();
    Object.keys(newLayout).forEach((key) => {
      newLayout[key] = newLayout[key].map((item: any) => {
        return { ...item, isDraggable: !isEditing, static: isEditing };
      });
    });
    localStorage.setItem("gridLayoutReports", JSON.stringify(newLayout));
  };

  const handleLayoutChange = (layout: any, layouts: any) => {
    localStorage.setItem("gridLayoutReports", JSON.stringify(layouts));
  };

  const openTask = (id: number) => {
    history.push("/home/playground/report/" + id);
  };

  const onCloseDetailPending = (): void => {
    history.push("/home/playground/report");
  };

  const onClickBar = (event: any) => {
    const { current: chart } = chartRef;
    if (!chart) return;
    const dataset = getDatasetAtEvent(chart, event);
    if (!dataset.length) return;
    const datasetIndex = dataset[0].datasetIndex;
    const task = data.datasets[datasetIndex].metaData;
    openTask(task.idTaskValue);
    console.log(task);

    const element = getElementAtEvent(chart, event);
    if (!element.length) return;
  };

  return (
    <>
      <Route
        path={"/home/playground/report/:idPending"}
        render={() => (
          <DetailPending
            onClose={onCloseDetailPending}
            onChangePending={(idPending) => {
              onCloseDetailPending();
              setTimeout(() => {
                history.push("/home/playground/report/" + idPending);
              }, 1);
            }}
          />
        )}
      />
      <ReportsContainer>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <p>drag:</p>
          <p style={{ color: "#00acc1", fontFamily: "Gotham-Bold" }}>
            {isEditing ? "activado" : "desactivado"}
          </p>
          <button onClick={toggleEditing}>
            {isEditing ? "desactivar" : "activar"}
          </button>
        </div>
        <DataBox>
          <ResponsiveGridLayout
            layouts={getLayouts()}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 4, md: 4, sm: 3, xs: 2, xxs: 1 }}
            rowHeight={150}
            maxRows={4}
            width={1000}
            onLayoutChange={handleLayoutChange}
          >
            <GridItemWrapper key="stacked-horizontal">
              <TableContainer>
                <div className="wrapper">
                  <Bar
                    ref={chartRef}
                    options={options}
                    data={data}
                    onClick={onClickBar}
                  />
                </div>
              </TableContainer>
            </GridItemWrapper>

            <GridItemWrapper key="empty-example-1">
              <TableContainer>
                <div className="wrapper">empty 1</div>
              </TableContainer>
            </GridItemWrapper>

            <GridItemWrapper key="empty-example-2">
              <TableContainer>
                <div className="wrapper">empty 2</div>
              </TableContainer>
            </GridItemWrapper>

            <GridItemWrapper key="empty-example-3">
              <TableContainer>
                <div className="wrapper">empty 3</div>
              </TableContainer>
            </GridItemWrapper>

            <GridItemWrapper key="empty-example-4">
              <TableContainer>
                <div className="wrapper">empty 4</div>
              </TableContainer>
            </GridItemWrapper>
          </ResponsiveGridLayout>
        </DataBox>
      </ReportsContainer>
    </>
  );
};

export default Report;
