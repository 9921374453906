import React from "react";
import { Row } from "antd";
import {
  TemplateImportsDescription,
  AdvancedSettingsDecsription,
  DataBasesContainer,
  ImportedVarDBItem,
} from "./style";
import {
  VarDBTeamDestinyType,
  AdvancedSettingsProps,
} from "./InstallTemplate.d";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import { Button } from "../../../GeestUI";
import { Languages } from "./Dictionary";
import { useFetch } from "../../../hooks";

const AdvancedSettings: React.FC<AdvancedSettingsProps> = ({
  varDBs,
  setVarDBs,
  steps,
  setActiveStep,
  onInstall,
  IdProcessTemplate,
  teamSelected,
}) => {
  const {
    AdvancedSettingsDescriptionLabel,
    ReuseDBLabel,
    InstallLabel,
    NextLabel,
  } = Languages["ESP"];
  const { data: varDBTeamDestiny } = useFetch<VarDBTeamDestinyType[]>({
    func: "Ver2-Processes-giw",
    args: {
      IdProcessTemplates: IdProcessTemplate,
      Step: "AdvanceConf",
      IdTeamDestiny: teamSelected,
    },
  });

  const getOptions = (IdVarDB: number) => {
    const selectedVarDBs = varDBs
      .filter(
        ({ ProcessTemplateIdVarDB, TeamDestinyIdVarDB }) =>
          IdVarDB !== ProcessTemplateIdVarDB && TeamDestinyIdVarDB !== 0
      )
      .map(({ TeamDestinyIdVarDB }) => TeamDestinyIdVarDB);
    const filteredVarDBs = varDBTeamDestiny?.filter(
      ({ TeamDestinyIdVarDB }) => !selectedVarDBs.includes(TeamDestinyIdVarDB)
    );
    let options =
      filteredVarDBs?.map(({ TeamDestinyIdVarDB, Title }) => ({
        label: Title,
        value: TeamDestinyIdVarDB,
      })) || [];
    return options;
  };
  const onChangeSelect = (value: number, index: number) => {
    if (varDBs) {
      let varDBsAux = [...varDBs];
      varDBsAux[index].TeamDestinyIdVarDB = value;
      if (varDBsAux[index].ImportingColumns) {
        delete varDBsAux[index].ImportingColumns;
      }
      setVarDBs(varDBsAux);
    }
  };

  return (
    <Row style={{ width: "100%", marginTop: "2rem" }} justify="center">
      <div style={{ width: "495px" }}>
        <TemplateImportsDescription style={{ marginBottom: 5 }}>
          {ReuseDBLabel}
        </TemplateImportsDescription>
        <AdvancedSettingsDecsription>
          {AdvancedSettingsDescriptionLabel}
        </AdvancedSettingsDecsription>
        <DataBasesContainer>
          {varDBs?.map(
            ({ Label, TeamDestinyIdVarDB, ProcessTemplateIdVarDB }, index) => (
              <Row
                justify="space-between"
                style={{ width: "100%" }}
                key={ProcessTemplateIdVarDB}
              >
                <ImportedVarDBItem>{Label}</ImportedVarDBItem>
                <div style={{ width: "236px" }}>
                  <GeestSelect
                    value={TeamDestinyIdVarDB}
                    options={getOptions(ProcessTemplateIdVarDB)}
                    onChange={(value) => onChangeSelect(value, index)}
                    disableSearch
                    valueNecesary
                  />
                </div>
              </Row>
            )
          )}
        </DataBasesContainer>
        <Row justify="center" style={{ width: "100%", marginTop: "1rem" }}>
          {steps.length === 2 ? (
            <Button type="primary" onClick={onInstall}>
              {InstallLabel}
            </Button>
          ) : (
            <Button type="primary" onClick={() => setActiveStep(2)}>
              {NextLabel}
            </Button>
          )}
        </Row>
      </div>
    </Row>
  );
};

export default AdvancedSettings;
