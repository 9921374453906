import styled from "styled-components";
import { Row, Space } from "antd";
import { IoClose } from "react-icons/io5";

export const Container = styled.div``;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #48505e;
`;

export const AddUserInput = styled.input`
  border: 2px solid rgba(237, 236, 236, 1);
  color: rgba(130, 141, 158, 1);
  border-radius: 6px;
  padding-left: 10px;
  width: 396px;
  height: 32px;
  font-size: 14px;
  font-weight: 300;
  font-family: Gotham-Book;
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
  :focus-visible: {
    outline: none;
  }
`;

export const MembersContainer = styled.div`
  margin-top: 1rem;
`;

export const MembersHeader = styled.div`
  background-color: #f2f8fe;
  display: flex;
  flex-direction: row;
  color: #48505e;
  font-family: Gotham-Bold;
  align-items: center;
  font-size: 14px;
  height: 40px;
`;

export const MembersHeaderItem = styled.div`
  padding-left: 10px;
`;
export const MembersHeaderItemFlex = styled(MembersHeaderItem)`
  display: flex;
  padding-left: 10px;
  flex: 1;
`;

export const MembersRow = styled.div`
  color: #828d9e;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #828d9e;
  height: 56px;
  font-family: Gotham-Bold;
`;

export const MembersRowCell = styled.div`
  padding-left: 10px;
`;

export const MembersRowCellFlex = styled.div`
  display: flex;
  flex: 1;
`;

export const UserContainer = styled(Row)`
  border: 5px;
  background: transparent;
  max-width: 100%;
  width: 100%;

  p {
    width: calc(100% - 64px);
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const MemberContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 90%;
  overflow: hidden;
  gap: 1rem;
`;

export const UserInitials = styled(Row)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  background: #014c9b;
  font-size: 12px;
  color: #ffffff;
`;

export const UserProfilePic = styled(Row)<{ image: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  background-image: ${({ image }) => `url("${image}")`};
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  font-weight: 325;
  font-size: 15px;
  color: #ffffff;
`;

export const CloseIcon = styled(IoClose)`
  cursor: pointer;
  :hover {
    color: black;
  }
`;

export const RolesContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  height: 49px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  transition: background-color 0.35s ease;
  cursor: pointer;
  :hover {
    background-color: var(--silver);
  }
`;

export const StyledSpace = styled(Space)`
  width: 100%;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
`;

export const RoleContainer = styled(Row)<{ $selected?: boolean }>`
  padding: 0px 10px;
  border-radius: 6px;
  height: 40px;
  cursor: pointer;
  background: ${({ $selected = false }) =>
    $selected ? "#edecec" : "transparent"};
  :hover {
    background: #edecec;
  }
`;

export const RoleTitle = styled.div`
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  font-size: 16px;
`;

export const RolesSpaceContainer = styled.div`
  padding: 0px 10px 0px 10px;
  gap: 4px;
  max-height: 240px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const PermissionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin-top: 5px;
  overflow: auto;
  max-height: 450px;
  gap: 4px;
  overflow-x: hidden;
  padding-left: 16px;
`;

export const PermissionLabel = styled.div`
  max-width: calc(100% - 30px);
`;

export const PermissionItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  font-family: Gotham-Book;
  color: #828d9e;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ActionsButton = styled.div`
  color: #0273e9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.35s ease;
  width: fit-content;
  :hover {
    color: #012c9b;
  }
  :active {
    color: #1939b7;
  }
`;

export const ActionsPopoverContent = styled.div`
  width: calc(438px - 32px);
  height: fit-content;
`;

export const ActionsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding-left: 10px;
  align-items: flex-start;
  width: 100%;
  gap: 15px;
  margin-bottom: 14px;
`;

export const ActionContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.2rem 0.5rem;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #e8ecf1;
  }
`;

export const DeleteButton = styled.div`
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 0.35s ease;
  :hover {
    background-color: #eee;
  }
`;
