import React, { useState, useEffect, useContext } from "react";
import {
  ViewContentWebhookTrigger,
  WebhookTriggerContent,
  WebhookTriggerDescription,
  WebhookTriggerMetaData,
  WebhookTriggerTitle,
  InputStyled,
  LinkContainer,
  TriggerFormTitle,
  WebHookData,
  RequestSelectDate,
  RequestsSelectLabel,
  JsonData,
  JsonContainer,
  JsonRequestContainer,
} from "../Style";
import webHookTriggerIcon from "../../../../../../../resources/img/webHookTriggerIcon.svg";
import { Row } from "antd";
import { EditIcon, CopyIcon } from "../../../../../../../components/hoverIcons";
import { GetTriggerWebHookResponse } from "../../TriggersConfig";
import { useFetch, useMutation } from "../../../../../../../hooks";
import { AiOutlineReload, AiOutlineInfoCircle } from "react-icons/ai";
import { Button } from "../../../../../../../GeestUI";
import GeestSelect from "../../../../../../../GeestUI/GeestSelect/GeestSelect";
import moment from "moment";
import { MessagesContext } from "../../../../../../../components/AppMessages";
import { Languages } from "../../Dictionary";
import Tooltip from "../../../../../../../components/Tooltip";
import { ConfiguratorContext } from "../../../../../Configurator";
import { useParams } from "react-router-dom";

interface WebhookTriggerViewProps {
  IdTeam: string;
  IdPrepublishTrigger: string;
  setView: (newView: string) => void;
  reloadHome: () => void;
  Label?: string;
  selectedJson: string;
  setSelectedJson: (json: string) => void;
  selectedRequest: number | null | undefined;
  setSelectedRequest: (req: number | null) => void;
  setJson: (json: { [key: string]: any }) => void;
  prevIdPrepublishTrigger: string;
  setPrevIdPrepublishTrigger: (id: string) => void;
  json?: { [key: string]: any };
}

const WebhookTriggerView: React.FC<WebhookTriggerViewProps> = ({
  IdTeam,
  IdPrepublishTrigger,
  setView,
  reloadHome,
  Label,
  selectedJson,
  setSelectedJson,
  selectedRequest,
  setSelectedRequest,
  setJson,
  prevIdPrepublishTrigger,
  setPrevIdPrepublishTrigger,
  json,
}) => {
  const [editingTitle, setEditingTitle] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const { showMessage } = useContext(MessagesContext);
  const { SuccessCopy } = Languages["ESP"];
  const { refreshProcessFlow } = useContext(ConfiguratorContext);
  const { IdProcess: IdProcessTemplate } = useParams<{ IdProcess: string }>();

  const { data, reload, loading } = useFetch<GetTriggerWebHookResponse>({
    func: "Ver2-Configurator-gtd",
    args: {
      IdTeam,
      IdPrepublishTrigger,
      IdProcessTemplate,
    },
    onSuccess: ({
      FieldsJson,
      SelectedRequest,
      HookRequests,
      IdPrepublishTrigger,
    }) => {
      if (
        SelectedRequest &&
        (!selectedRequest || IdPrepublishTrigger !== prevIdPrepublishTrigger)
      ) {
        const selJson = HookRequests?.find(
          ({ Id }) => Id === SelectedRequest
        )?.Json;
        if (
          !!FieldsJson &&
          (!selectedJson || IdPrepublishTrigger !== prevIdPrepublishTrigger)
        ) {
          const newSelJson = mapJsonObject(selJson || {}, "");
          setJson({ ...newSelJson, ...FieldsJson });
        }
        if (!selectedJson || IdPrepublishTrigger !== prevIdPrepublishTrigger) {
          setSelectedJson(JSON.stringify(selJson, null, 2));
        }
        setSelectedRequest(SelectedRequest || null);
      } else if (IdPrepublishTrigger !== prevIdPrepublishTrigger) {
        setSelectedJson("");
        setSelectedRequest(null);
        setJson({});
      }
      setPrevIdPrepublishTrigger(IdPrepublishTrigger);
    },
  });

  const [updateTriggerLabel] = useMutation({
    func: "Ver2-Configurator-utl",
    onError: reload,
    onSuccess: () => {
      reloadHome();
      refreshProcessFlow();
    },
  });

  useEffect(() => {
    setTitle(Label || "");
  }, [Label]);

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IdPrepublishTrigger]);

  const mapJsonObject = (
    object: { [key: string]: any },
    parentProperty: string
  ): { [key: string]: any } => {
    let json: { [key: string]: any } = {};
    Object.keys(object).forEach((property) => {
      if (!Array.isArray(object[property])) {
        if (typeof object[property] === "object") {
          if (parentProperty) {
            json = {
              ...json,
              ...mapJsonObject(
                object[property],
                `${parentProperty}->${property}`
              ),
            };
          } else {
            json = { ...json, ...mapJsonObject(object[property], property) };
          }
        } else if (parentProperty) {
          json[`${parentProperty}->${property}`] = "";
        } else {
          json[property] = "";
        }
      }
    });
    return json;
  };

  const handleCopy = (element: string) => {
    navigator.clipboard.writeText(element);
    showMessage(SuccessCopy, "success");
  };

  const handleOnChangeTitle = () => {
    setEditingTitle(false);
    if (title !== Label) {
      updateTriggerLabel({
        args: {
          IdTeam,
          IdPrepublishTrigger,
          Label: title,
        },
      });
    }
  };

  const getRequestOptions = () => {
    if (data?.HookRequests) {
      return data?.HookRequests?.map(({ Id, Date }, index) => ({
        label: (
          <div>
            <RequestsSelectLabel>{`Request ${index + 1}`}</RequestsSelectLabel>
            <RequestSelectDate>
              {moment(Date).format("DD/MMM HH:mm")}
            </RequestSelectDate>
          </div>
        ),
        asSelected: `Request ${index + 1}`,
        value: Id,
      }));
    }
    return [];
  };

  const onChangeSelectedRequest = (id: number) => {
    setSelectedRequest(id);
    let selectedJson = data?.HookRequests?.find(({ Id }) => Id === id)?.Json;
    setSelectedJson(JSON.stringify(selectedJson, null, 2));
    selectedJson = mapJsonObject(selectedJson || {}, "");
    if (json && Object.keys(json).length > 0) {
      Object.keys(selectedJson).forEach((key) => {
        if (json[key] && selectedJson) {
          selectedJson[key] = json[key];
        }
      });
    } else if (data?.FieldsJson) {
      Object.keys(selectedJson).forEach((key) => {
        if (
          data.FieldsJson &&
          data.FieldsJson[key as unknown as string] &&
          selectedJson
        ) {
          selectedJson[key] = data.FieldsJson[key as unknown as string];
        }
      });
    }
    setJson(selectedJson);
  };

  return (
    <ViewContentWebhookTrigger>
      <WebhookTriggerMetaData>
        <img src={webHookTriggerIcon} width="40px" height="40px" alt="" />
        <WebhookTriggerContent>
          {editingTitle ? (
            <InputStyled
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={handleOnChangeTitle}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleOnChangeTitle();
                }
              }}
              onFocus={(e) => e.target.select()}
              autoFocus
            />
          ) : (
            <Row align="bottom">
              <WebhookTriggerTitle>{title}</WebhookTriggerTitle>
              <EditIcon
                size={32}
                filled
                onClick={() => setEditingTitle(true)}
              />
            </Row>
          )}
          <WebhookTriggerDescription>WebHook</WebhookTriggerDescription>
        </WebhookTriggerContent>
      </WebhookTriggerMetaData>
      <Row style={{ width: "100%" }} justify="space-between">
        <WebHookData>
          <div>
            <TriggerFormTitle>
              Copia este link y pruébalo
              <Tooltip
                title="Este es un link de prueba y no iniciará un proceso. Haz una petición POST a este link para vincularán tu información"
                placement="bottom"
              >
                <AiOutlineInfoCircle size={20} />
              </Tooltip>
            </TriggerFormTitle>
            <LinkContainer>
              <div style={{ width: "340px" }}>
                <p>{data?.TriggerLink}</p>
              </div>
              <CopyIcon onClick={() => handleCopy(data?.TriggerLink || "")} />
            </LinkContainer>
          </div>
          <div>
            <TriggerFormTitle>
              Selecciona el registro de prueba para vincular el Hook
            </TriggerFormTitle>
            <Row>
              <GeestSelect
                value={selectedRequest}
                options={getRequestOptions()}
                onChange={onChangeSelectedRequest}
                $width="330px"
              />
              <Tooltip placement="bottom" title="Extraer datos">
                <Button
                  type="secondary"
                  size="xsmall"
                  Icon={AiOutlineReload}
                  onClick={reload}
                  style={{ marginLeft: "5px" }}
                  loading={loading}
                />
              </Tooltip>
            </Row>
          </div>
          <Button
            type="primary"
            onClick={() => setView("editHookFields")}
            style={{ width: "140px" }}
            disabled={!selectedRequest}
          >
            Vincular datos
          </Button>
        </WebHookData>
        {selectedRequest && (
          <JsonData>
            <TriggerFormTitle>
              Información del registro de prueba
            </TriggerFormTitle>
            <JsonContainer>
              <JsonRequestContainer>Json request</JsonRequestContainer>
              <textarea disabled value={selectedJson} />
            </JsonContainer>
          </JsonData>
        )}
      </Row>
    </ViewContentWebhookTrigger>
  );
};

export default WebhookTriggerView;
