import React, { useState } from "react";
import {
  CreateProcessProps,
  CreateProcessTemplateOptions,
  ProcessTemplateBank,
} from "./CreateProcess.d";
import Modal from "../../../components/Modal";
import { useFetch } from "../../../hooks";
import ModalTab from "./ModalTab";
import SelectTemplate from "./SelectTemplate";
import TemplateDetail from "./TemplateDetail";
import { Languages } from "../Dictionary";

const CreateProcess: React.FC<CreateProcessProps> = ({
  closeModal,
  onCreateBlankProcess,
  onInstallTemplate,
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [typeFilter, setTypeFilter] = useState<number[]>([]);
  const [nameFilter, setNameFilter] = useState<string>("");
  const [selectedTemplate, setSelectetdTemplate] =
    useState<ProcessTemplateBank>();
  const { CreateNewTemplateLabel, SelectCategoryLabel, KnowMoreLabel } =
    Languages["ESP"];
  const { data, loading } = useFetch<CreateProcessTemplateOptions>({
    func: "Ver2-Processes-gcpto",
    onSuccess: (res) => {
      const { ProcessTypes } = res;
      setTypeFilter(ProcessTypes.map(({ Id }) => Id));
    },
  });
  const steps = [SelectCategoryLabel, KnowMoreLabel];

  const getFilteredProcessTemplates = () => {
    let ProcessTemplates = data?.TemplateBank;
    ProcessTemplates = ProcessTemplates?.filter((t) =>
      typeFilter.includes(t.IdProcessType)
    );
    if (nameFilter) {
      ProcessTemplates = ProcessTemplates?.filter((t) =>
        t.Name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }
    return ProcessTemplates;
  };

  const onSelectTemplate = (Template: ProcessTemplateBank) => {
    setSelectetdTemplate(Template);
    setActiveStep(1);
  };

  if (loading) return null;

  return (
    <Modal
      title={CreateNewTemplateLabel}
      onCancel={closeModal}
      width={879}
      bodyStyle={{ height: "663px" }}
      contentStyles={{ paddingTop: "1rem" }}
    >
      <ModalTab
        linear
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      {activeStep === 0 ? (
        <SelectTemplate
          ProcessTypes={data?.ProcessTypes}
          TemplateBank={data?.TemplateBank}
          setTypeFilter={setTypeFilter}
          typeFilter={typeFilter}
          filteredTemplateBank={getFilteredProcessTemplates()}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          onCreateBlankProcess={onCreateBlankProcess}
          onSelectTemplate={onSelectTemplate}
        />
      ) : (
        <TemplateDetail
          setActiveStep={setActiveStep}
          selectedTemplate={selectedTemplate}
          selectedCategory={
            data?.ProcessTypes.find(
              (type) => type.Id === selectedTemplate?.IdProcessType
            )?.Label
          }
          onInstallTemplate={onInstallTemplate}
        />
      )}
    </Modal>
  );
};

export default CreateProcess;
