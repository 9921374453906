import styled from "styled-components";
import { Row, Select } from "antd";
import ButtonComponent from "../Button";
import { TextField, withStyles } from "@material-ui/core";
import { IoClose } from "react-icons/io5";

export const Container = styled.div`
  background: white;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 20px;
  font-family: "gotham-bold";
  border-bottom: 1.5px solid #c9c9c9;
  margin-bottom: 1rem;
`;

export const Form = styled.div`
  flex: 1;
`;

export const Footer = styled(Row)`
  /* background: blue; */
`;

export const Button = styled(ButtonComponent)`
  span {
    font-size: 18px;
  }
`;

const TextFieldStyled = styled(TextField)`
  &.Mui-focused fieldset {
    border-color: ${({ borderColor }) => borderColor} !important;
  }
`;

export const StyledInput = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "grey",
    },
    "& label": {
      fontFamily: "Gotham-Book",
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Gotham-Book",
      borderRadius: "5px",
      "&.Mui-focused fieldset": {
        borderColor: "grey",
      },
    },
    "&.MuiFormControl-root": {
      marginBottom: "1.5rem",
    },
    "&.MuiFormControl-root:nth-child(odd)": {
      width: "calc(55% - 0.75rem)",
    },
    "&.MuiFormControl-root:nth-child(even)": {
      width: "calc(45% - 0.75rem)",
    },
  },
})(TextFieldStyled);

export const CheckboxLabel = styled.div`
  margin-top: 7px;
  font-size: 12px;
  line-height: 1rem;
`;

export const StyledSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #c0c0c0;
    border-radius: 0.4rem;
    height: 2.5rem;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    :hover {
      border: 1px solid grey;
    }
  }
  &.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    font-size: medium;
    font-family: Gotham-Book;
    height: 1.1876;
    text-align-vertical: center;
    align-self: center;
    margin-top: 2px;
  }
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-size: medium;
    font-family: Gotham-Book;
    height: 1.1876;
    text-align-vertical: center;
    align-self: center;
  }
  .ant-select-selection-placeholder {
    font-size: medium;
    font-family: Gotham-Book;
    color: grey;
    height: 1.1876;
    text-align-vertical: center;
    align-self: center;
  }
`;

export const NotificationUsersContainer = styled.div`
  border: ${({ show }) => (show ? "1px solid #aaa" : "none")};
  border-radius: 10px;
  width: 100%;
  margin-bottom: ${({ show }) => (show ? "1rem" : "0")};
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: ${({ show }) => (show ? "5px" : "0")};
  font-size: 14px;
  color: #535151;

  transition: height 0.35s ease, margin-bottom 0.35s ease;
`;

export const EmailContainer = styled.div`
  border: 1px solid #aaa;
  border-radius: 10px;
  max-width: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: width 0.35s ease;

  p {
    margin: 0;
    overflow-wrap: break-word;
  }
`;

export const DeleteEmailButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${({ show }) => (show ? "16px" : "0")};
  height: ${({ show }) => (show ? "16px" : "0")};
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: ${({ show }) => (show ? "1" : "0")};
  transition: background-color 0.35s ease, opacity 0.35s ease;
  :hover {
    background-color: rgba(0, 0, 0, 0.45);
  }
`;

export const CloseIcon = styled(IoClose)`
  cursor: pointer;
  color: white;
`;
