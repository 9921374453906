import styled from "styled-components";

export const PageWrapper = styled.div`
  position: relative;
  min-height: 115px;
  :hover .deleteIconContainer {
    opacity: 1;
  }
`;

export const Paper = styled.div<{
  width: number;
  height: number;
  backgroundImage: string;
  isCurrent?: boolean;
}>`
  cursor: pointer;
  position: relative;
  min-width: ${({ width = 59 }) => width}px;
  width: ${({ width = 59 }) => width}px;
  min-height: ${({ height = 86 }) => height}px;
  height: ${({ height = 86 }) => height}px;
  border-radius: 6px;
  border: 4px solid;
  border-color: ${({ isCurrent = false }) =>
    isCurrent ? "#cbeefd" : "#edecec"};
  transition: border-color 0.35s ease;

  background-image: url(${({ backgroundImage = "" }) => backgroundImage});
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const DeleteIconContainer = styled.div`
  position: absolute;
  top: -9px;
  right: -9px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  background-color: #0067b0;
  color: white;
  border-radius: 50%;
  cursor: pointer;

  opacity: 0;
  transition: opacity 0.35s ease, background-color 0.35s ease;
  :hover {
    background-color: #014c9b;
  }
`;
