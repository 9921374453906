import React, { useState } from "react";
import { Card, Popover, Tooltip } from "antd";
import { Button, Typography } from "../../GeestUI";
import MultiModal from "../../components/MultiModal";

const { H1, B } = Typography;

const MultiModalPage: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <H1>
        <B>MultiModal</B>
      </H1>
      <Card size="small" title="Example">
        <Button type="primary" onClick={() => setOpenModal(true)}>
          Open modal
        </Button>
      </Card>
      <MultiModal open={openModal} onClose={() => setOpenModal(false)}>
        <Tooltip title="Tooltip">
          <H1 style={{ width: "fit-content", cursor: "pointer" }}>Tooltip</H1>
        </Tooltip>
        <Popover content={() => <div>Popover</div>}>
          <H1 style={{ width: "fit-content", cursor: "pointer" }}>Popover</H1>
        </Popover>
      </MultiModal>
    </>
  );
};

export default MultiModalPage;
