import React from "react";
import { Button as ButtonBase } from "@material-ui/core";
import styled, { css } from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

const StyledButton = styled(ButtonBase)`
  && {
    border-radius: 10px;
    color: white;
    text-transform: inherit;
    width: ${(props) => (props.$Block ? "100%" : "inherit")};
    ${(props) => props.$TypeProperties};
  }
`;

export const ButtonBorder = styled.div`
  width: 100%;
  padding: 1px;
  background: ${({ loading }) =>
    loading ? "#0000001e" : "linear-gradient(to right, #84be54, #3f7db8)"};
  border-radius: 11px;
  button {
    border: none;
  }
`;

const TypeProperties = {
  primary: css`
    background-color: #0867ad;
    border-color: #0867ad;
    :hover {
      background-color: #064e81;
      border-color: #064e81;
    }
  `,
  secondary: css`
    background-color: #399a23;
    border-color: #399a23;
    :hover {
      background-color: #2c791b;
      border-color: #2c791b;
    }
  `,
  danger: css`
    background-color: #e10600;
    border-color: #e10600;
    :hover {
      background-color: #af0600;
      border-color: #af0600;
    }
  `,
  warning: css`
    color: #e10600;
    border-color: #e10600;
    background-color: white;
    :hover {
      background-color: #e6e6e6;
    }
  `,
  secondaryOutlined: css`
    color: #399a23;
    border-color: #399a23;
    background-color: white;
    :hover {
      background-color: #e6e6e6;
    }
  `,
  default: css`
    color: #525151;
    border-color: #525151;
    background-color: white;
    :hover {
      background-color: #e6e6e6;
    }
  `,
  defaultActive: css`
    color: #0867ad;
    border-color: #0867ad;
    background-color: white;
    :hover {
      background-color: #e6e6e6;
    }
  `,
  premiumPro: css`
    color: #9a68c4;
    border-color: #9a68c4;
    background-color: white;
    :hover {
      background-color: #e6e6e6;
    }
  `,
  shadow: css`
    color: #525151;
    border: none;
    box-shadow: 0px 3px 4px 2px #d7d7d7;
    background-color: white;
    :hover {
      background-color: #e6e6e6;
    }
  `,
  geestV1: css`
    color: white;
    border: none;
    transition: all 0.3s ease;
    box-shadow: 0px 3px 4px 2px #444444;
    background: linear-gradient(
      218deg,
      rgba(39, 87, 145, 1) 0%,
      rgba(39, 87, 145, 1) 20%,
      rgba(119, 185, 72, 1) 100%
    );
    :hover {
      opacity: 0.9;
    }
  `,
  geestV2: css`
    color: white;
    border: none;
    transition: all 0.3s ease;
    box-shadow: 0px 3px 4px 2px #444444;
    background: #275791;
    background: linear-gradient(
      218deg,
      rgba(39, 87, 145, 1) 0%,
      rgba(71, 123, 143, 1) 0%,
      rgba(119, 185, 72, 1) 100%
    );
    :hover {
      opacity: 0.9;
    }
  `,
};

const Variants = {
  primary: "contained",
  secondary: "contained",
  danger: "contained",
  secondaryOutlined: "outlined",
  warning: "outlined",
  default: "outlined",
  defaultActive: "outlined",
  premiumPro: "outlined",
  shadow: "outlined",
  geestV1: "contained",
  geestV2: "contained",
};

const Button = ({
  type = "default",
  loading = false,
  disabled = false,
  children,
  block = false,
  testTags = {},
  htmlType = "",
  ...props
}) => {
  const disableButtom = disabled || loading;
  const { btnTestId, loadingTestId } = testTags;

  return (
    <StyledButton
      data-testid={btnTestId}
      disableElevation
      variant={Variants[type]}
      disabled={disableButtom}
      $TypeProperties={TypeProperties[type]}
      $Block={block}
      startIcon={loading && <LoadingOutlined data-testid={loadingTestId} />}
      type={htmlType}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
