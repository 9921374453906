export const Languages = {
  ENG: {
    ReestablishLabel: "Reestablish password",
    LinkExpiredLabel: "Your link has expired",
    AskNewOneLabel: "Ask for a new one here",
    EnterNewPasswordLabel: "New password...",
    ConfirmNewPasswordLabel: "Confirm new password...",
    ChangePasswordLabel: "Change password",
    FieldRequired: "This field is required",
    PasswordsNotMacthError: "The passwords don't match",
  },
  ESP: {
    ReestablishLabel: "Restablecer contraseña",
    LinkExpiredLabel: "Tu enlace ha expirado",
    AskNewOneLabel: "Solicita uno nuevo aquí",
    EnterNewPasswordLabel: "Nueva contraseña...",
    ConfirmNewPasswordLabel: "Confirmar nueva contraseña...",
    ChangePasswordLabel: "Cambiar contraseña",
    FieldRequired: "Este campo es requerido",
    PasswordsNotMacthError: "Las contraseñas no coinciden",
  },
};
