import React, { useContext } from "react";
import { Divider, Row } from "antd";
import { noop as NOOP } from "lodash";
import { useHistory } from "react-router";
import styled from "styled-components";
// Context
import { SesionContext } from "../../../../config/State";
import ChangePasswordContext from "../../../HomeLayout/ChangePasswordContext";
import { ActionsContainer, InfoContainer, LogoContainer } from "./Styles";
import Help from "../../../../resources/img/SETTINGS_HELP.svg";
import ChangePassword from "../../../../resources/img/SETTINGS_CHANGE_PASSWORD.svg";
import Premium from "../../../../resources/img/SETTINGS_PREMIUM.svg";
import ConfigurateIcon from "../../../../resources/img/TEMPLATES_CONFIGURATE.svg";
import GeestVersion from "../../../../components/GeestVersion/GeestVersion";
import { GeestPhoneNumber } from "../../../../constants/PlatformDetails";
import { GeestInstance } from "../../../../api/server";
import { Languages } from "./Dictionary";
import { Button, Typography } from "../../../../GeestUI";
import { useMutation } from "../../../../hooks";

const { H2, H3 } = Typography;

const SettingsContainer = styled.div`
  width: calc(350px - 32px);
  * {
    user-select: none;
  }
`;

const Settings = () => {
  const history = useHistory();
  const [Sesion, setSesion] = useContext(SesionContext);
  const context = useContext(ChangePasswordContext);
  const { FirstName, LastName, Email, ProfilePicture } = Sesion;
  const {
    ChangePasswordLabel,
    MySubscriptionLabel,
    HelpLabel,
    LogoutLabel,
    TermsAndCondsLabel,
    PrivacyNoticeLabel,
    Settings,
  } = Languages["ESP"];

  const [logout] = useMutation({
    func: "Ver2-Session-lo",
    onSuccess: () => {
      GeestInstance.defaults.headers.common["Authorization"] = null;
      setSesion(null);
    },
  });

  const handleLogOut = () => {
    logout({});
  };

  return (
    <SettingsContainer>
      <GeestVersion style={{ marginBottom: "0.5rem", textAlign: "right" }} />
      <Row justify="center">
        <LogoContainer $picture={ProfilePicture}>
          {ProfilePicture ? "" : `${FirstName[0]}${LastName[0]}`}
        </LogoContainer>
      </Row>
      <InfoContainer>
        <H2 mb="0">{`${FirstName} ${LastName}`}</H2>
        <H3 mb="0">{Email}</H3>
      </InfoContainer>
      <Divider style={{ margin: ".5rem 0rem", background: "var(--silver)" }} />
      <ActionsContainer
        onClick={() =>
          context ? context.setOpenModal("Change_Password") : NOOP
        }
      >
        <img
          src={ChangePassword}
          alt="Password"
          width="24"
          height="24"
          style={{ marginRight: ".5rem" }}
        />
        <div>{ChangePasswordLabel}</div>
      </ActionsContainer>
      <Divider style={{ margin: ".5rem 0rem", background: "var(--silver)" }} />
      <ActionsContainer onClick={() => history.push("/home/settings/profile")}>
        <img
          src={ConfigurateIcon}
          alt="Settings"
          width="24"
          height="24"
          style={{ marginRight: ".5rem" }}
        />
        <div>{Settings}</div>
      </ActionsContainer>
      <Divider style={{ margin: ".5rem 0rem", background: "var(--silver)" }} />
      <ActionsContainer onClick={() => history.push("/home/premium")}>
        <img
          src={Premium}
          alt="Password"
          width="24"
          height="24"
          style={{ marginRight: ".5rem" }}
        />
        <div>{MySubscriptionLabel}</div>
      </ActionsContainer>
      <Divider style={{ margin: ".5rem 0rem", background: "var(--silver)" }} />
      <ActionsContainer
        onClick={() => window.open(`https://wa.me/${GeestPhoneNumber}`)}
      >
        <img
          src={Help}
          alt="Help"
          width="27"
          height="27"
          style={{ marginRight: ".5rem" }}
        />
        <div>{HelpLabel}</div>
      </ActionsContainer>
      <Divider style={{ margin: ".5rem 0rem", background: "var(--silver)" }} />
      <div
        style={{
          padding: "0rem 1.5rem",
        }}
      >
        <Button type="secondary" size="fullwidth" danger onClick={handleLogOut}>
          {LogoutLabel}
        </Button>
      </div>
      <Row
        justify="space-between"
        style={{ marginTop: "1.5rem", marginBottom: "1rem" }}
      >
        <a
          href={`${process.env.REACT_APP_BACK}#/legal/terms`}
          target="_blank"
          rel="noreferrer"
          style={{
            fontSize: "14px",
            paddingLeft: "3px",
            paddingRight: "3px",
            textDecoration: "underline",
          }}
        >
          {TermsAndCondsLabel}
        </a>
        <a
          href={`${process.env.REACT_APP_BACK}#/legal/privacy`}
          target="_blank"
          rel="noreferrer"
          style={{
            fontSize: "14px",
            paddingLeft: "3px",
            paddingRight: "3px",
            textDecoration: "underline",
          }}
        >
          {PrivacyNoticeLabel}
        </a>
      </Row>
    </SettingsContainer>
  );
};

export default Settings;
