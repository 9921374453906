import React, { useRef } from "react";
import _ from "lodash";
import { EditorState } from "draft-js";
import { Container, LoadingContainer, Page } from "./styles";
import TextNode from "../NodeTypes/TextNode";
import { Element, ElementOrigin } from "../PDFConfig.d";
import { FieldEditorOrigin } from "../../GeestTextEditor/GeestTextEditor.d";
import ImageNode from "../NodeTypes/ImageNode";
import SignNode from "../NodeTypes/SignNode";
import Loading from "../../Loading";

interface PageWrapperProps {
  pageRef: any;
  elementsOrigin: ElementOrigin[];
  elements: Element[];
  pageWidth: number;
  pageHeight: number;
  pageBackgroundImage: string;
  currentElement: string | null;
  setCurrentElement: (id: string | null) => void;
  elementToConfig: string | null;
  setElementToConfig: (id: string | null) => void;
  onInputStateChange: (newValue: EditorState, id: string) => void;
  onImageSourceChange: (newValue: string, id: string) => void;
  setElmentOnFocus: (id: string) => void;
  onStartMoving: (element: Element, i: number) => void;
  onStopMoving: (element: Element, i: number) => void;
  onControlledDrag: (
    e: any,
    position: any,
    element: Element,
    i: number
  ) => void;
  onResizeStart: () => void;
  onResize: (
    e: any,
    { node, size, hadle }: any,
    element: Element,
    i: number
  ) => void;
  onResizeStop: () => void;
  onDeleteElement: (i: number) => void;
  fieldEditorOrigin: FieldEditorOrigin[];
  importingPDF: boolean;
  isMovingElements: boolean;
  movingElment: string | null;
  teamLogo: string;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  pageRef,
  elementsOrigin,
  elements,
  pageWidth,
  pageHeight,
  pageBackgroundImage,
  currentElement,
  setCurrentElement,
  elementToConfig,
  setElementToConfig,
  onInputStateChange,
  onImageSourceChange,
  setElmentOnFocus,
  onStartMoving,
  onStopMoving,
  onControlledDrag,
  onResizeStart,
  onResize,
  onResizeStop,
  onDeleteElement,
  fieldEditorOrigin,
  importingPDF,
  isMovingElements,
  movingElment,
  teamLogo,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const getNodeValue = (id: string) => {
    const element = _.find(elementsOrigin, { id });
    return element?.value ?? EditorState.createEmpty();
  };

  const getImageSourceConfig = (id: string) => {
    const element = _.find(elementsOrigin, { id });
    return element?.imageSourceConfig || "file";
  };

  const getNodeName = (id: string) => {
    const element = _.find(elementsOrigin, { id });
    return element?.name ?? "Firma";
  };

  const getImageDiemsnions = (id: string) => {
    const element = _.find(elementsOrigin, { id });
    return element?.imageDimensions ?? "width";
  };

  const needsPlaceholderImage = (id: string) => {
    const element = _.find(elementsOrigin, { id });
    if (element?.type !== "image") return true;

    return (
      element.imageConfig === "field" ||
      (element.imageConfig === "source" && element.value === "")
    );
  };

  return (
    <Container ref={containerRef} onClick={() => setElementToConfig(null)}>
      {importingPDF && (
        <LoadingContainer targetWidth={794}>
          <Loading simple />
        </LoadingContainer>
      )}

      {!importingPDF && (
        <Page
          ref={pageRef}
          id="target"
          contentWidth={pageWidth ?? 794}
          contentHeight={pageHeight ?? 1123}
          targetWidth={794}
          scale={1}
          backgroundImage={pageBackgroundImage}
        >
          {elements.map((element, i) => {
            if (element.type === "text") {
              return (
                <TextNode
                  currentElement={currentElement}
                  setCurrentElement={setCurrentElement}
                  elementToConfig={elementToConfig}
                  setElementToConfig={setElementToConfig}
                  componentKey={`${element.id}_${i}`}
                  value={getNodeValue(element.id) as EditorState}
                  element={element}
                  onChange={(newValue: EditorState) =>
                    onInputStateChange(newValue, element.id)
                  }
                  setElmentOnFocus={setElmentOnFocus}
                  onStart={() => onStartMoving(element, i)}
                  onStop={() => onStopMoving(element, i)}
                  onControlledDrag={(e, position) =>
                    onControlledDrag(e, position, element, i)
                  }
                  onResizeStart={onResizeStart}
                  onResize={(e, data) => onResize(e, data, element, i)}
                  onResizeStop={onResizeStop}
                  onDeleteElement={() => onDeleteElement(i)}
                  fieldEditorOrigin={fieldEditorOrigin}
                  isMovingElements={
                    isMovingElements || movingElment === element.id
                  }
                />
              );
            }
            if (element.type === "image") {
              return (
                <ImageNode
                  currentElement={currentElement}
                  setCurrentElement={setCurrentElement}
                  elementToConfig={elementToConfig}
                  setElementToConfig={setElementToConfig}
                  componentKey={`${element.id}_${i}`}
                  value={getNodeValue(element.id) as string}
                  element={element}
                  needsPlaceholderImage={needsPlaceholderImage(element.id)}
                  imageDimensions={getImageDiemsnions(element.id)}
                  onChange={(newValue: string) =>
                    onImageSourceChange(newValue, element.id)
                  }
                  onStart={() => onStartMoving(element, i)}
                  onStop={() => onStopMoving(element, i)}
                  onControlledDrag={(e, position) =>
                    onControlledDrag(e, position, element, i)
                  }
                  onResizeStart={onResizeStart}
                  onResize={(e, data) => onResize(e, data, element, i)}
                  onResizeStop={onResizeStop}
                  onDeleteElement={() => onDeleteElement(i)}
                  teamLogo={teamLogo}
                  sourceConfig={getImageSourceConfig(element.id)}
                />
              );
            }
            if (element.type === "sign") {
              return (
                <SignNode
                  currentElement={currentElement}
                  setCurrentElement={setCurrentElement}
                  elementToConfig={elementToConfig}
                  setElementToConfig={setElementToConfig}
                  componentKey={`${element.id}_${i}`}
                  element={element}
                  signName={getNodeName(element.id)}
                  onStart={() => onStartMoving(element, i)}
                  onStop={() => onStopMoving(element, i)}
                  onControlledDrag={(e, position) =>
                    onControlledDrag(e, position, element, i)
                  }
                  onResizeStart={onResizeStart}
                  onResize={(e, data) => onResize(e, data, element, i)}
                  onResizeStop={onResizeStop}
                  onDeleteElement={() => onDeleteElement(i)}
                />
              );
            }
            return null;
          })}
        </Page>
      )}
    </Container>
  );
};

export default PageWrapper;
