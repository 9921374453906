import styled from "styled-components";

export const ImportedPDFContainer = styled.div`
  max-width: 100%;
  max-height: 100%;

  img {
    width: 100%;
    height: auto;
  }
`;

export const ConfigWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #f6f5f5;

  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  zoom: 75%;
`;

export const BaseDrawer = styled.div`
  display: flex;
  height: 100%;
  padding: 4px 6px;
  background-color: white;

  box-shadow: 0.6px 0.6px 10px rgba(0, 0, 0, 0.035),
    5px 5px 80px rgba(0, 0, 0, 0.07);
`;
