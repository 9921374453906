import styled from "styled-components";

export const NameAndAssignContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  text-align: center;

  height: 100%;
  padding: 0 53px;

  p {
    margin: 0;
    color: #828d9e;
  }
`;

export const StepForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const StringInput = styled.input`
  border-radius: 6px;
  border: 2px solid;
  border-color: #edecec;
  background-color: white;
  color: #828d9e;

  width: 100%;
  min-height: 30px;
  resize: none;
  padding: 6px 10px;

  transition: all 0.35s ease;
  ::placeholder {
    color: #828d9e;
  }
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

export const PrimaryButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  padding: 6px 16px;
  border: none;
  font-family: Gotham-Bold;
  background: ${({ disabled = false }) => (disabled ? "#edecec" : "#0273e9")};
  color: ${({ disabled = false }) => (disabled ? "#828d9e" : "white")};
  cursor: ${({ disabled = false }) => (disabled ? "auto" : "pointer")};

  transition: backgroud 0.35s ease;
  :hover {
    backgroud: #014c9b;
  }
`;

export const PermissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  text-align: center;

  height: 100%;
  padding: 0 53px;

  p {
    margin: 0;
    color: #828d9e;
  }
`;

export const PermissionsForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-height: 290px;
  overflow: auto;
`;

export const PermisssionElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  p {
    margin: 0;
    color: #828d9e;
  }
`;

export const PermissionDescriptionWrapper = styled.div`
  text-align: start;
`;

export const PermissionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .user {
    width: 50%;
  }

  .select {
    width: 50%;
  }

  p {
    margin: 0;
    color: #828d9e;
  }
`;
