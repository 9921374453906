import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { useReactFlow } from "reactflow";
import { Card, Header, List, NoStagesPlaceholder } from "./styles";
import {
  GetProcessStages,
  Stage,
} from "../../Flow/CustomNodes/Toolbox/Toolbox.d";
import { useMutation } from "../../../../hooks";
import StageItem from "./StageItem";
import StageEditor from "./StageEditor";
import { Button } from "../../../../GeestUI";
import {
  EditStageAction,
  EditStagesDropdownProps,
  UpdateStages,
} from "./EditStagesDropdown.d";
import { syncNodeStages } from "../../Flow/helpers";

const defaultStage: Stage = {
  Id: 0,
  Color: "#F44336",
  Label: "",
  IsDefaultStage: false,
};

const EditStagesDropdown: React.FC<EditStagesDropdownProps> = ({
  children,
  open,
  setOpen,
}) => {
  const { getNodes, setNodes } = useReactFlow();
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const [stages, setStages] = useState<Stage[]>([]);
  const [editingStageId, setEditingStageId] = useState<number | null>(null);

  useEffect(() => {
    if (open)
      getStages({
        args: {
          IdProcessTemplate,
          IdTeam,
        },
      });
    else setEditingStageId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [getStages] = useMutation<GetProcessStages>({
    func: "Ver2-Configurator-gps",
    onSuccess: setStages,
  });
  const [updateProcessStages] = useMutation<Stage[]>({
    func: "Ver2-Configurator-ups",
    onSuccess: (response, action: EditStageAction) => {
      setStages(response);
      setEditingStageId(null);
      setNodes(syncNodeStages(getNodes(), action));
    },
  });

  const updateStages: UpdateStages = (Stages, action) =>
    updateProcessStages({
      args: {
        IdTeam,
        IdProcessTemplate,
        Stages,
      },
      shippedData: action,
    });

  const handleOnEditStage = (stage: Stage) => setEditingStageId(stage.Id);
  const handleOnCancel = () => setEditingStageId(null);
  const handleOnDeleteStage = (stage: Stage) =>
    updateStages(_.reject(stages, { Id: stage.Id }), {
      action: "delete",
      stage,
    });
  const handleOnSubmitEdition = (stage: Stage) => {
    let newStages = [...stages];
    let action: "create" | "update" = "create";
    if (stage.Id === 0) {
      newStages = [...newStages, stage];
    } else {
      const index = _.findIndex(stages, { Id: stage.Id });
      if (index >= 0) {
        newStages[index] = stage;
        action = "update";
      }
    }
    updateStages(newStages, {
      action,
      stage,
    });
  };

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      trigger="click"
      placement="bottom"
      showArrow={false}
      overlayInnerStyle={{
        borderRadius: "6px",
      }}
      align={{ offset: [0, -10] }}
      content={
        <Card>
          <Header>
            {editingStageId === 0 ? (
              <StageEditor
                defaultValue={defaultStage}
                onSubmit={handleOnSubmitEdition}
                onDelete={handleOnDeleteStage}
                onCancel={handleOnCancel}
              />
            ) : (
              <Button
                type="secondary"
                onClick={() => setEditingStageId(0)}
                Icon={AiOutlinePlus}
                size="large"
              >
                Nueva etapa
              </Button>
            )}
          </Header>
          <List>
            {stages.length === 0 ? (
              <NoStagesPlaceholder>
                <p>Sin etapas...</p>
              </NoStagesPlaceholder>
            ) : (
              stages.map((stage) =>
                stage.Id === editingStageId ? (
                  <StageEditor
                    key={stage.Id}
                    defaultValue={stage}
                    onSubmit={handleOnSubmitEdition}
                    onDelete={handleOnDeleteStage}
                    onCancel={handleOnCancel}
                  />
                ) : (
                  <StageItem
                    key={stage.Id}
                    stage={stage}
                    onEdit={handleOnEditStage}
                  />
                )
              )
            )}
          </List>
        </Card>
      }
    >
      {children}
    </Popover>
  );
};

export default EditStagesDropdown;
