import React, { useContext } from "react";
import { StateMachineContext } from "../index";
import { Languages } from "./Dictionary";
import { InputNumber, Space, Row } from "antd";
import { Container, Content, MainText, ContentText, CardTitle } from "./Style";
import States from "../States";
import { moneyFormat } from "../../../../Helpers";
import { getPlanColors } from "../../helpers";

const PlanCard = ({
  users,
  plan,
  setUsers,
  period,
  price,
  MaxUsers,
  MinUsers,
  AmountPerUser,
  FrontView = "",
  amountToPay,
}) => {
  const { CurrentState } = useContext(StateMachineContext);

  const {
    Buy,
    Plan,
    PlanPrice,
    PricePerPeriod,
    Users,
    TotalLabel,
    TaxIncluded,
    RetryPaymentLabel,
    UsersIn,
    UsersLabel,
    UsersMessage,
    FooterLabel,
  } = Languages["ESP"];

  const planColor = getPlanColors(plan).mainColor;

  const paymentOutdated =
    CurrentState === States.CHECKOUT_SINGLE_PAYMENT_OUTDATED;
  const paymentAddUser = CurrentState === States.CHECKOUT_CHANGE_USERS;
  const canChangeUsers =
    ![States.CHECKOUT_SINGLE_PAYMENT_OUTDATED].includes(CurrentState) ||
    ["ByPackage-CheckoutSinglePaymentOutdated"].includes(FrontView);
  const byPackage = [
    "ByPackage-BuyPlanView",
    "ByPackage-CheckoutSinglePaymentOutdated",
    "CheckoutNormalEditPlans-DowngradePlan",
    "CheckoutNormalEditPlans-UpdragePlan",
  ].includes(FrontView);
  const TotalAmount = amountToPay
    ? moneyFormat(amountToPay, 2)
    : byPackage
    ? moneyFormat(price, 2)
    : moneyFormat(AmountPerUser || (users * price).toFixed(2), 2);

  return (
    <>
      <div style={paymentOutdated ? { height: "5rem" } : {}}>
        <span style={{ color: "#696969", fontSize: "22px", fontWeight: 500 }}>
          {`${Buy} `}
        </span>
        <span
          style={{ color: planColor, fontSize: "22px", fontWeight: "bold" }}
        >
          {paymentAddUser ? `${UsersIn} ${Plan} ${plan}` : `${Plan} ${plan}`}
        </span>
        {paymentOutdated && (
          <div style={{ color: "#e0271a", fontSize: "22px", fontWeight: 500 }}>
            {RetryPaymentLabel}
          </div>
        )}
      </div>
      <Container $border={"2px solid " + planColor}>
        <Content>
          <CardTitle $color={planColor}>
            {paymentAddUser ? UsersLabel : plan}
          </CardTitle>
          <Space
            direction="vertical"
            style={{
              borderBottom: "1px solid #aaaaaa",
              paddingBottom: "1.5rem",
            }}
          >
            <div>
              <MainText $fontSize="16px">{PlanPrice}</MainText>
              <ContentText
                $fontSize="16px"
                $color={paymentOutdated || paymentAddUser}
              >
                {PricePerPeriod(
                  price,
                  period === "Annually" ? "Anual" : "Mensual",
                  byPackage
                )}
              </ContentText>
            </div>
            <div>
              {paymentAddUser ? (
                <MainText $fontSize="16px">{UsersMessage(users)}</MainText>
              ) : (
                <>
                  {!["ByPackage-CheckoutSinglePaymentOutdated"].includes(
                    FrontView
                  ) && <MainText $fontSize="16px">{Users}</MainText>}
                  {canChangeUsers ? (
                    <ContentText $fontSize="16px" $color={paymentOutdated}>
                      {users}
                    </ContentText>
                  ) : (
                    <InputNumber
                      style={{
                        width: "35%",
                        borderRadius: "8px",
                        color: "grey",
                        marginTop: "0.5rem",
                      }}
                      value={users}
                      onChange={(value) => {
                        if (Number.isInteger(value)) setUsers(value);
                      }}
                      max={MaxUsers}
                      min={MinUsers}
                    />
                  )}
                </>
              )}
            </div>
          </Space>
          <Row justify="space-between" style={{ marginTop: "0.5rem" }}>
            <MainText $fontSize="18px">{TotalLabel}</MainText>
            <ContentText $fontSize="18px">
              {TotalAmount} USD
              <span style={{ color: "red" }}>*</span>
            </ContentText>
          </Row>
          <MainText $fontSize="10px" style={{ marginTop: "1rem" }}>
            <span style={{ color: "red" }}>*</span>
            {TaxIncluded}
            {paymentAddUser && <div>{FooterLabel}</div>}
          </MainText>
        </Content>
      </Container>
    </>
  );
};

export default PlanCard;
