import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import ViewTitle from "../../components/ViewTitle/ViewTitle";
import Sales from "./Sales";
import { SelectStyle, Content } from "./styles";
import { IRoute } from "./types.d";

const routes: IRoute[] = [
  {
    label: "Ventas",
    path: "/sales",
    view: Sales,
  },
];

const Statistics: React.FC = () => {
  const { path: BasePath } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const validPaths = routes.map(({ path }) => BasePath + path);

  const onChangeView = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => history.push(e.target.value as string);

  return (
    <>
      <ViewTitle ShowTeamIcon>
        Estadísticas de
        <SelectStyle margin="dense">
          <Select
            labelId="type-label"
            id="type-select"
            value={validPaths.includes(pathname) ? pathname : ""}
            onChange={onChangeView}
          >
            {routes.map(({ label, path }) => (
              <MenuItem key={path} value={BasePath + path}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </SelectStyle>
      </ViewTitle>
      <Content>
        <Switch>
          {routes.map(({ path, view }) => (
            <Route path={BasePath + path} component={view} key={path} />
          ))}
          <Redirect from={BasePath} to={BasePath + routes[0].path} />
        </Switch>
      </Content>
    </>
  );
};

export default Statistics;
