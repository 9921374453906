import React, { useEffect, useState } from "react";
import _ from "lodash";
import { IoClose } from "react-icons/io5";
import { useMutation } from "../../../../../../hooks";
import GeestSelect from "../../../../../../GeestUI/GeestSelect/GeestSelect";
import DynamicFilterBy from "../../DynamicFilterBy";
import Tooltip from "../../../../../../components/Tooltip";
import NoFilterImage from "../../../../../../resources/img/Dashboards/no_filter.svg";
import DataTypeIcons from "../../../../DataTypeIcons";
import DynamicGraphPreview from "../../DynamicGraphPreview";
import { Languages } from "../Dictionary";
import {
  DuratonsStepTwoResponse,
  FilterOption,
  User,
  Option,
  FilterBackend,
  DurationsGraphFormat,
  GraphType,
} from "../../../../Dashboards.d";
import {
  ApplyFilterButton,
  DeleteFilterButton,
  FilterByElement,
  FilterByWrapper,
  FilterElement,
  FilterStepContainer,
  FiltersWrapper,
  GraphPreviewContainer,
  LeftPannel,
  MainTitle,
  NoAppliedFilters,
  OptionLabelContainer,
  PrevisualizationCard,
  RightPannel,
  SubmitContainer,
} from "./stepTwoSyles";
import { Button } from "../../../../../../GeestUI";

const {
  SelectTheFiltersYouWant,
  FilterByPlaceholder,
  FilterLabel,
  ApplyFilterLabel,
  AddGraphLabel,
  UpdateGraphLabel,
} = Languages["ESP"];

interface FiltersStepProps {
  selectedTemplate: number;
  selectedDashboard: string | null;
  selectedProcess: number | null;
  graphName: string;
  format: DurationsGraphFormat;
  graphToEdit: GraphType | null;
  onClose: (submit?: boolean) => void;
  setUnsavedChanges: () => void;
}

const FiltersStep: React.FC<FiltersStepProps> = ({
  selectedTemplate,
  selectedDashboard,
  selectedProcess,
  graphName,
  format,
  graphToEdit,
  onClose,
  setUnsavedChanges,
}) => {
  const [teamMembers, setTeamMembers] = useState<User[]>([]);
  const [dateOptions, setDateOptions] = useState<Option[]>([]);

  const [filterBy, setFilterBy] = useState<number | null>(null);
  const [filterByOptions, setFilterByOptions] = useState<Option[]>([]);
  const [filterToAdd, setFilterToAdd] = useState<FilterOption | null>(null);
  const [resetFilterToAddValue, setResetFilterTuAddValue] =
    useState<boolean>(false);
  const [canAppendFilter, setCanAppendFilter] = useState<boolean>(false);

  const [filterOptionsConsult, setFilterOptionsConsult] = useState<
    FilterOption[]
  >([]);
  const [filters, setFilters] = useState<FilterOption[]>([]);

  const lengthLimit = 9;

  const getOptionLabel = (type: string, label: string) => {
    return (
      <OptionLabelContainer>
        {DataTypeIcons[type] && (
          <img
            src={DataTypeIcons[type].icon}
            alt=""
            style={{
              ...DataTypeIcons[type]?.extraStyles,
            }}
          />
        )}
        <p>{label}</p>
      </OptionLabelContainer>
    );
  };

  const handleFormatFilters = (
    filterOptions: FilterOption[],
    filters: FilterBackend[]
  ): FilterOption[] => {
    if (filters.length === 0) {
      return [];
    }

    const newFilters = filters.map((filter) => {
      const options =
        (_.find(filterOptions, {
          Key: filter.Key,
        }) as FilterOption) ?? {};
      const newFilter = {
        ...options,
        ...filter,
        RenderKey: `filter-${Math.random()}`,
      };
      return newFilter;
    });

    return newFilters;
  };

  const [getGraphConfigurationWizard] = useMutation<DuratonsStepTwoResponse>({
    func: "Ver2-DashBoards-ggcw",
    onSuccess: ({ TeamMembers, DateOptions, FilterOptions, Filters }) => {
      setTeamMembers(TeamMembers);
      setDateOptions(DateOptions);
      setFilterOptionsConsult(FilterOptions);
      const formatedFilters = handleFormatFilters(FilterOptions, Filters);
      setFilters(formatedFilters);
      setFilterBy(null);

      let lastStaticIdx = 0;
      FilterOptions.forEach((filter, idx) => {
        if (filter.Type === "Static") lastStaticIdx = idx;
      });

      let newFilterByOptions = [] as Option[];
      FilterOptions.forEach((filter, idx) => {
        let newOption = {
          value: idx,
          label: getOptionLabel(filter.DataType ?? "", filter.Label),
          separator: idx === lastStaticIdx,
          searchableValues: filter.Label,
        };
        newFilterByOptions = [...newFilterByOptions, newOption];
      });
      setFilterByOptions(newFilterByOptions);
    },
  });

  useEffect(() => {
    getGraphConfigurationWizard({
      args: {
        IdDashBoard: selectedDashboard,
        IdGraphTemplate: selectedTemplate,
        IdGraph: graphToEdit?.IdGraph ?? "",
        Step: 2,
        IdProcessTemplate: selectedProcess,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [registerGraph] = useMutation<any[]>({
    func: "Ver2-DashBoards-rg",
    onSuccess: () => onClose(true),
  });

  const handleSelectFilterBy = (option: number) => {
    setUnsavedChanges();
    setFilterBy(option);
    setCanAppendFilter(false);
    const newFilterToAdd = { ...filterOptionsConsult[option] };
    delete newFilterToAdd.Value;
    setFilterToAdd(newFilterToAdd);
    setResetFilterTuAddValue(true);
  };

  const handleOnChangeFilterToAdd = (newFilterToAdd: FilterOption) => {
    setUnsavedChanges();
    if (newFilterToAdd?.Value !== undefined) {
      setCanAppendFilter(true);
    }
    setFilterToAdd(newFilterToAdd);
    setResetFilterTuAddValue(false);
  };

  const handleAddFilter = () => {
    setUnsavedChanges();
    let filter = {
      ...filterToAdd,
      RenderKey: `filter-${Math.random()}`,
    };
    const newFilters = [...filters, filter];
    setFilters(newFilters as FilterOption[]);
    setFilterBy(null);
    setCanAppendFilter(false);
    setFilterToAdd(null);
  };

  const handleEditFilter = (idx: number, filterToEdit: FilterOption) => {
    setUnsavedChanges();
    let newFilters = _.cloneDeep(filters);
    newFilters[idx] = filterToEdit;
    setFilters(newFilters);
  };

  const handleOnDeleteFilter = (idx: number) => {
    setUnsavedChanges();
    let newFilters = _.cloneDeep(filters);
    newFilters.splice(idx, 1);
    setFilters(newFilters);
  };

  const canSubmit = () => {
    let canSubmit = true;
    filters.forEach((filter) => {
      if (!filter.Value) {
        canSubmit = false;
      }
    });
    return canSubmit;
  };

  const handleOnSubmit = () => {
    if (canSubmit()) {
      let newFilters: FilterBackend[] = _.cloneDeep(filters).map((filter) => {
        let newFilter: FilterBackend = {
          Key: filter.Key,
          Label: filter.Label,
          Value: filter.Value,
        };

        if (filter.Type !== "Static") {
          newFilter.DataType = filter.DataType;
        }

        if (filter.Start) {
          newFilter.Start = filter.Start;
        }
        if (filter.End) {
          newFilter.End = filter.End;
        }

        return newFilter;
      });

      registerGraph({
        args: {
          IdDashBoard: selectedDashboard,
          IdGraphTemplate: selectedTemplate,
          IdProcessTemplate: selectedProcess,
          IdGraph: graphToEdit?.IdGraph ?? "",
          GraphMode: format,
          GraphName: graphName,
          Filters: newFilters,
        },
      });
    }
  };

  return (
    <FilterStepContainer>
      <LeftPannel>
        <MainTitle>{SelectTheFiltersYouWant}</MainTitle>
        <FilterByWrapper>
          <FilterByElement filter>
            <p className="label" />
            <GeestSelect
              value={filterBy}
              options={filterByOptions}
              onChange={(option) => handleSelectFilterBy(option)}
              placeholderSelect={FilterByPlaceholder}
            />
          </FilterByElement>

          <FilterByElement filter>
            {filterToAdd && JSON.stringify(filterBy) !== "null" && (
              <>
                <p className="label">{FilterLabel}</p>
                <DynamicFilterBy
                  filterData={filterToAdd}
                  TeamMembers={teamMembers}
                  dateOptions={dateOptions}
                  onChangeFilter={handleOnChangeFilterToAdd}
                  resetValue={resetFilterToAddValue}
                />
              </>
            )}
          </FilterByElement>

          <FilterByElement>
            <p className="label" />
            {canAppendFilter && (
              <ApplyFilterButton onClick={handleAddFilter}>
                {ApplyFilterLabel}
              </ApplyFilterButton>
            )}
          </FilterByElement>
        </FilterByWrapper>

        {filters?.length > 0 ? (
          <FiltersWrapper>
            {filters.map((filter, idx) => (
              <FilterElement key={filter.RenderKey}>
                {filter?.Label && filter.Label.length > lengthLimit ? (
                  <Tooltip title={filter.Label} mui>
                    <p className="title">
                      {_.truncate(filter.Label, { length: lengthLimit })}
                    </p>
                  </Tooltip>
                ) : (
                  <p className="title">{filter.Label}</p>
                )}
                <DynamicFilterBy
                  editingFilter
                  filterData={filter}
                  onChangeFilter={(filter: FilterOption) => {
                    handleEditFilter(idx, filter);
                  }}
                  TeamMembers={teamMembers}
                  dateOptions={dateOptions}
                />
                <DeleteFilterButton>
                  <IoClose onClick={() => handleOnDeleteFilter(idx)} />
                </DeleteFilterButton>
              </FilterElement>
            ))}
          </FiltersWrapper>
        ) : (
          <NoAppliedFilters>
            <img src={NoFilterImage} alt="" />
          </NoAppliedFilters>
        )}

        <SubmitContainer>
          <Button
            type="primary"
            disabled={!canSubmit()}
            onClick={handleOnSubmit}
            size="large"
          >
            {graphToEdit?.IdGraph ? UpdateGraphLabel : AddGraphLabel}
          </Button>
        </SubmitContainer>
      </LeftPannel>

      <RightPannel>
        <PrevisualizationCard>
          <p className="title">{graphName}</p>
          <GraphPreviewContainer>
            <DynamicGraphPreview
              selectedDashboard={selectedDashboard}
              selectedTemplate={selectedTemplate}
              selectedProcess={selectedProcess}
              format={format}
              graphName={graphName}
              rawFilters={filters}
            />
          </GraphPreviewContainer>
        </PrevisualizationCard>
      </RightPannel>
    </FilterStepContainer>
  );
};

export default FiltersStep;
