import React, { useState, useContext, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SesionContext } from "../../config/State";
import _ from "lodash";
import { Row } from "antd";
import { FiTrash } from "react-icons/fi";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { AiFillEdit, AiOutlinePlus, AiOutlineFilter } from "react-icons/ai";
import { Popover } from "@material-ui/core";
import ImportDBModal from "./Modals/ImportDBModal";
import { IoIosArrowDown } from "react-icons/io";
import { useFetch, useMutation } from "../../hooks";
import ExcelExportAlert from "../../components/ExcelExportAlert";
import PaginationContainer from "./PaginationContainer";
import DroppableContainer from "./DragAndDropComponents/DroppableContainer";
import ViewTitle from "../../components/ViewTitle/ViewTitle";
import DeleteVarDB from "./Modals/DeleteVarDB";
import AddColumnPopup from "./Modals/AddColumnPopup";
import AddEditRowPopup from "./Modals/AddEditRowPopup";
import LinkVarDBModal from "./Modals/LinkVarDBModal";
import GeestSelect from "../../GeestUI/GeestSelect";
import DeleteVarDBRows from "./Modals/DeleteVarDBRows";
import { Button } from "../../GeestUI";
import DataTypeIcons from "../../components/DataTypeIcons";
import DynamicFilter from "../../components/DynamicFilter/DynamicFilter";
import { CancelIcon } from "../../components/hoverIcons";
import { Languages } from "./Dictionary";
import {
  TableContainer,
  DBTitleWrapper,
  TitleInput,
  DBTitle,
  FiltersContainer,
  FilterToAdd,
  AppliedFilters,
  ArrowContainer,
  FilterTitle,
  OptionLabelWrapper,
  FilterField,
  VerticalDivider,
  FilterContainer,
  RemoveFilterIconContainer,
  FilterCountContainer,
  DeleteOption,
  OrganizeColumnsOptions,
} from "./Styles";
import {
  GetVarDBResponse,
  VarDBColumn,
  VarDBRow,
  Pagination,
  ColumnType,
  TableData,
  DBModalType,
  Filter,
  FilterForDynamicFilter,
  Order,
  DefaultFormats,
  FilterTypes,
} from "./DataBases.d";
import { BiSolidDownArrow } from "react-icons/bi";
import icon_delete_row from "../../resources/img/Databases/icon_delete_row.svg";
import Loading from "../../components/Loading";
import arrowsIcon from "../../resources/img/arrowsIcon.svg";
import { ReactComponent as LinkIcon } from "../../resources/img/linkdb.svg";
import { GetTeamDetailResponse } from "../Teams/Teams.d";

const DataBase: React.FC = () => {
  const { IdTeam, IdVarDB } = useParams<{ IdTeam: string; IdVarDB: string }>();
  const [sesion] = useContext<any>(SesionContext);
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(15);
  const [pagination, setPagination] = useState<Pagination>({} as Pagination);
  const [selectedColumn, setSelectedColumn] = useState<TableData | null>(null);
  const [filesPath, setFilesPath] = useState<string>("");
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [defaultFormats, setDefaultFormats] = useState<DefaultFormats>({
    currency: "MXN",
    phone_number: "MX",
  });

  const [modal, setModal] = useState<DBModalType>("");

  const [tableTitle, setTableTitle] = useState<string>("");

  const [canEditDatabases, setCanEditDatabases] = useState<boolean>(false);
  const [canExportDatabases, setCanExportDatabases] = useState<boolean>(false);

  const titleRef = useRef<HTMLInputElement>(null);
  const [editedTitle, setEditedTitle] = useState<string>("");
  const [editingTitle, setEditingTitle] = useState<boolean>(false);

  const [deleteOptionsVisible, setDeleteOptionsVisible] =
    useState<boolean>(false);
  const [deleteOptionsEl, setDeleteOptionsEl] =
    useState<HTMLButtonElement | null>(null);

  const [addColumnVisible, setAddColumnVisible] = useState<boolean>(false);
  const [addColumnEl, setAddColumnElm] = useState<HTMLButtonElement | null>(
    null
  );
  const [columnTypeArray, setColumnTypeArray] = useState<ColumnType[]>([]);

  const [editingRow, setEditingRow] = useState<VarDBRow | null>(null);
  const [varDBRows, setVarDBRows] = useState<VarDBRow[]>([]);
  const [varDBCols, setVarDBCols] = useState<VarDBColumn[]>([]);
  const [tableData, setTableData] = useState<TableData[]>([]);

  const [order, setOrder] = useState<Order>({});
  const [filters, setFilters] = useState<Filter[]>([]);
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);

  const [teamCurrency, setTeamCurrency] = useState<string>("MXN");

  const {
    AddColumnLabel,
    FilterLabel,
    FiltersLabel,
    FiltersPlaceholder,
    ApplyFilter,
  } = Languages["ESP"];

  const processVarDBData = (
    cols: VarDBColumn[],
    rows: VarDBRow[],
    RowOffset: number
  ) => {
    let isIdPrimaryKey = true;

    cols.forEach((col) => {
      if (col.PrimaryKey) {
        isIdPrimaryKey = false;
      }
    });

    const firstColumn = {
      Title: "Folio",
      DataType: "Folio",
      FilterType: "",
      IdColumn: 0,
      Position: 0,
      PrimaryKey: isIdPrimaryKey,
      rowIds: rows.map((row) => row.IdRow),
      data: rows.map((row) => row.RowLabel),
      RowOffset,
      ChangeToDataTypes: [],
      Type: "",
    };

    return [
      firstColumn,
      ...cols.map((col) => ({
        ...col,
        rowIds: rows.map((row) => row.IdRow),
        data: rows.map((row) => row[col.IdColumn]),
      })),
    ];
  };

  const getFormatedFilters = () => {
    const filters = JSON.parse(
      localStorage.getItem(`DBFilters-${IdTeam}-${IdVarDB}`) || "[]"
    );
    const filtersToAdd = _.cloneDeep(filters).map((filter: any) => {
      let filterAux = filter as any;
      delete filterAux.Key;
      delete filterAux.defaultOptions;
      delete filterAux.Label;
      delete filterAux.DataOrigin;

      return filterAux;
    });

    return filtersToAdd;
  };

  const getOrder = () => {
    const order = JSON.parse(
      localStorage.getItem(`DBOrder-${IdTeam}-${IdVarDB}`) || "{}"
    );
    return order;
  };

  const handleSetEditRow = (rowId: number) => {
    const row = _.find(varDBRows, { IdRow: rowId });
    setEditingRow(row ? row : null);
    setModal(row ? "AddEditRow" : "");
  };

  const { reload, loading } = useFetch<GetVarDBResponse>({
    func: "Ver2-Vardbs-gv",
    idUR: sesion.Id,
    args: {
      IdTeam,
      IdVarDB,
      Filters: getFormatedFilters(),
      OrderBy: getOrder(),
    },
    options: { pags: { PerPage: perPage, CurrentPage: currentPage } },
    onSuccess: ({
      Title,
      RowOffset,
      VarDBData,
      CanEditDatabases,
      Pagination,
      FilesPath,
      CanExportDatabases,
      DefaultFormats,
    }) => {
      setDefaultFormats(DefaultFormats);
      setFirstLoad(false);
      setTableTitle(Title);
      setPerPage(Pagination.PerPage);
      setCurrentPage(Pagination.CurrentPage);
      setPagination(Pagination);
      setCanEditDatabases(CanEditDatabases);
      setCanExportDatabases(CanExportDatabases);
      setVarDBRows(VarDBData.Rows || []);
      setVarDBCols(VarDBData.Columns || []);
      setFilesPath(FilesPath || "");
      setTableData(
        processVarDBData(
          VarDBData.Columns || [],
          VarDBData.Rows || [],
          RowOffset
        )
      );

      const order = JSON.parse(
        localStorage.getItem(`DBOrder-${IdTeam}-${IdVarDB}`) || "{}"
      );
      const filters = JSON.parse(
        localStorage.getItem(`DBFilters-${IdTeam}-${IdVarDB}`) || "[]"
      );
      setOrder(order);
      setFilters(filters);
    },
  });

  const { loading: gettingTeamCurrency } = useFetch<GetTeamDetailResponse>({
    func: "Ver2-Team-gtd",
    args: { IdTeam },
    onSuccess: ({ ReportsCurrencyFormat }) =>
      setTeamCurrency(ReportsCurrencyFormat),
  });

  const [changePage, changingPage] = useMutation<GetVarDBResponse>({
    func: "Ver2-Vardbs-gv",
    onSuccess: ({
      Title,
      VarDBData,
      CanEditDatabases,
      RowOffset,
      CanExportDatabases,
      Pagination,
      FilesPath,
    }) => {
      setTableTitle(Title);
      setPerPage(Pagination.PerPage);
      setCurrentPage(Pagination.CurrentPage);
      setPagination(Pagination);
      setCanEditDatabases(CanEditDatabases);
      setCanExportDatabases(CanExportDatabases);
      setVarDBRows(VarDBData.Rows);
      setFilesPath(FilesPath || "");
      setTableData(
        processVarDBData(VarDBData.Columns, VarDBData.Rows, RowOffset)
      );
    },
  });

  useFetch<ColumnType[]>({
    func: "Ver2-Vardbs-gvct",
    idUR: sesion.Id,
    onSuccess: (response) => setColumnTypeArray(response),
  });

  const [updateVarDB] = useMutation<[]>({
    func: "Ver2-Vardbs-uv",
    onSuccess: () => reload(),
  });

  const [updateVarDBColumn] = useMutation<[]>({
    func: "Ver2-Vardbs-uvc",
    onSuccess: () => reload(),
  });

  const [exportVarDBToExcel, exportingVarDBToExcel] = useMutation<string>({
    func: "Ver2-Vardbs-evte",
    onSuccess: (response) => {
      if (response) window.open(response);
      else setModal("ExcelAlert");
    },
  });

  const exportVarDBs = () => {
    exportVarDBToExcel({
      args: {
        IdTeam,
        IdVarDB,
      },
    });
  };

  useEffect(() => {
    titleRef?.current?.focus();
    // eslint-disable-next-line
  }, [editingTitle]);

  const editTitle = () => {
    const isTheSameTitle = _.trim(editedTitle) === tableTitle;
    const isTitleEmpty = _.isEmpty(_.trim(editedTitle));
    if (isTheSameTitle || isTitleEmpty) return;
    setTableTitle(_.trim(editedTitle));
    setEditingTitle(false);
    updateVarDB({
      args: {
        IdVarDB,
        IdTeam,
        Title: _.trim(editedTitle),
      },
    });
  };

  const onOrder = (Key: string | number) => {
    let orderAux = { ...order };
    if (orderAux[Key] === "DESC") {
      orderAux[Key] = "ASC";
    } else if (orderAux[Key] === "ASC") {
      delete orderAux[Key];
    } else {
      orderAux[Key] = "DESC";
    }
    setOrder(orderAux);

    localStorage.setItem(
      `DBOrder-${IdTeam}-${IdVarDB}`,
      JSON.stringify(orderAux)
    );

    changePage({
      args: { IdTeam, IdVarDB, Filters: filters, OrderBy: orderAux },
      idUR: sesion.Id,
      options: {
        pags: {
          PerPage: perPage,
          CurrentPage: 1,
        },
      },
    });
  };

  const getFilterOptions = () => {
    let newFilters = varDBCols.map(({ Title, IdColumn, DataType }, idx) => ({
      value: IdColumn,
      label: (
        <OptionLabelWrapper>
          <img src={DataTypeIcons[DataType].icon} alt={DataType} />
          <p>{Title}</p>
        </OptionLabelWrapper>
      ),
      searchableValues: Title,
    }));

    return newFilters;
  };

  const onChangeFilter = (value: string | number) => {
    const filter = varDBCols.find(({ IdColumn }) => IdColumn === value);
    if (filter) {
      setSelectedFilter({
        Key: filter.IdColumn,
        IdColumn: filter.IdColumn,
        FilterValue: "",
        DataOrigin: filter.DataOrigin,
        DataType: filter.DataType,
        FilterType: filter.FilterType as FilterTypes,
        Label: filter.Title,
      });
    }
  };

  const onChangeFilterValue = (value: any) => {
    if (selectedFilter) {
      const newFilter = { ...selectedFilter, FilterValue: value };
      setSelectedFilter(newFilter);
    }
  };

  const onAddFilter = () => {
    if (selectedFilter) {
      let filtersAux = _.cloneDeep(filters);
      filtersAux.push(selectedFilter);
      setFilters(filtersAux);
      setSelectedFilter(null);
      localStorage.setItem(
        `DBFilters-${IdTeam}-${IdVarDB}`,
        JSON.stringify(filtersAux)
      );
      setFiltersVisible(true);

      const filtersToAdd = _.cloneDeep(filtersAux).map((filter) => {
        let filterAux = filter as any;
        delete filterAux.Key;
        delete filterAux.defaultOptions;
        delete filterAux.Label;
        delete filterAux.DataOrigin;

        return filterAux;
      });

      changePage({
        args: { IdTeam, IdVarDB, Filters: filtersToAdd, OrderBy: order },
        idUR: sesion.Id,
        options: {
          pags: {
            PerPage: perPage,
            CurrentPage: 1,
          },
        },
      });
    }
  };

  const onRemoveFilter = (idx: number) => {
    let filtersAux = _.cloneDeep(filters);
    filtersAux.splice(idx, 1);
    setFilters(filtersAux);
    localStorage.setItem(
      `DBFilters-${IdTeam}-${IdVarDB}`,
      JSON.stringify(filtersAux)
    );
    const filtersToAdd = _.cloneDeep(filtersAux).map((filter) => {
      let filterAux = filter as any;
      delete filterAux.Key;
      delete filterAux.defaultOptions;
      delete filterAux.Label;
      delete filterAux.DataOrigin;

      return filterAux;
    });

    changePage({
      args: { IdTeam, IdVarDB, Filters: filtersToAdd, OrderBy: order },
      idUR: sesion.Id,
      options: {
        pags: {
          PerPage: perPage,
          CurrentPage: 1,
        },
      },
    });
  };

  const onUpdateFilter = (idx: number, value: any) => {
    let filtersAux = _.cloneDeep(filters);
    filtersAux[idx].FilterValue = value;
    setFilters(filtersAux);
    localStorage.setItem(
      `DBFilters-${IdTeam}-${IdVarDB}`,
      JSON.stringify(filtersAux)
    );
    const filtersToAdd = _.cloneDeep(filtersAux).map((filter) => {
      let filterAux = filter as any;
      delete filterAux.Key;
      delete filterAux.defaultOptions;
      delete filterAux.Label;
      delete filterAux.DataOrigin;

      return filterAux;
    });

    changePage({
      args: { IdTeam, IdVarDB, Filters: filtersToAdd, OrderBy: order },
      idUR: sesion.Id,
      options: {
        pags: {
          PerPage: perPage,
          CurrentPage: 1,
        },
      },
    });
  };

  const getSelectedFilter = (filter: Filter): FilterForDynamicFilter => {
    const selectedFilter: FilterForDynamicFilter = {
      IdReportColumn: "" + filter.IdColumn,
      Value: filter.FilterValue,
      Label: filter.Label,
      DataOrigin: filter.DataOrigin,
      defaultOptions: filter.defaultOptions,
      FilterType: filter.FilterType,
    };

    return selectedFilter;
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    const minIndex = tableData.filter(({ Type }) => Type === "Imported").length;
    if (destination && destination.index > minIndex) {
      const { index: sourceIndex } = source;
      const { index: destinationIndex } = destination;
      let newTableData = _.cloneDeep(tableData);
      let [movedItem] = newTableData.splice(sourceIndex, 1);
      newTableData.splice(destinationIndex, 0, movedItem);
      setTableData(newTableData);

      if (
        destinationIndex !== sourceIndex &&
        destinationIndex - minIndex !== movedItem.Position &&
        movedItem.Position > 0
      ) {
        updateVarDBColumn({
          args: {
            IdTeam,
            IdVarDB,
            Column: {
              IdColumn: movedItem.IdColumn,
              DataType: movedItem.DataType,
              Title: movedItem.Title,
              DataOrigin: movedItem.DataOrigin || "",
              Configuration: "",
              DataOriginFromIdColumn: "",
              PrimaryKey: movedItem.PrimaryKey,
              Position: destinationIndex - minIndex,
              LastPosition: movedItem.Position,
            },
          },
        });
      }
    }
  };

  const transformSelectedFilter = (
    filter: Filter | null
  ): FilterForDynamicFilter => {
    if (!filter) return {} as FilterForDynamicFilter;

    return getSelectedFilter(filter);
  };

  const Modals: { [key: string]: React.ReactNode } = {
    DeleteVarDB: (
      <DeleteVarDB
        closeModal={() => setModal("")}
        IdTeam={IdTeam}
        IdVarDB={IdVarDB}
        tableTitle={tableTitle}
      />
    ),
    DeleteVarDBRows: (
      <DeleteVarDBRows
        closeModal={() => setModal("")}
        IdTeam={IdTeam}
        IdVarDB={IdVarDB}
        reload={reload}
      />
    ),
    AddEditRow: (
      <AddEditRowPopup
        onClose={() => setModal("")}
        columns={tableData}
        editingRow={editingRow}
        unsetEditingRow={() => setEditingRow(null)}
        isEditing={!_.isEmpty(editingRow)}
        filesPath={filesPath}
        reload={reload}
        teamCurrency={teamCurrency}
        defaultFormats={defaultFormats}
        canEdit={canEditDatabases}
      />
    ),
    ExcelAlert: (
      <ExcelExportAlert
        onCancel={() => setModal("")}
        onAccept={() => history.push(`/home/premium/${IdTeam}`)}
        isOwner={true}
      />
    ),
    ImportDB: (
      <ImportDBModal
        IdTeam={+IdTeam}
        IdVarDB={IdVarDB}
        DBName={tableTitle}
        onClose={() => setModal("")}
        reload={reload}
      />
    ),
    LinkVarDB: (
      <LinkVarDBModal
        onClose={() => setModal("")}
        reload={reload}
        IdTeam={IdTeam}
        IdVarDB={IdVarDB}
        VarDBTitle={tableTitle}
      />
    ),
  };

  return (
    <div
      style={{
        color: "#727e8b",
        flex: 1,
        maxHeight: "calc(100% - 20px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {Modals[modal]}
      <ViewTitle ShowBack ShowTeamIcon onBack={() => history.goBack()}>
        <DBTitleWrapper>
          {editingTitle ? (
            <TitleInput
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              ref={titleRef}
              onBlur={editTitle}
              onKeyDown={({ key }) => {
                if (key === "Enter") editTitle();
              }}
              style={{ width: "40%", fontSize: "24px" }}
            />
          ) : (
            <DBTitle>
              <p>{tableTitle}</p>
              {canEditDatabases && (
                <div className="icon-container">
                  <AiFillEdit
                    color="#0273e9"
                    size={20}
                    onClick={() => {
                      setEditedTitle(tableTitle);
                      setEditingTitle(true);
                    }}
                  />
                </div>
              )}
            </DBTitle>
          )}

          {canEditDatabases && (
            <>
              <Row>
                <Button
                  type="primary"
                  size="large"
                  Icon={AiOutlinePlus}
                  onClick={(e) => {
                    setAddColumnElm(e.currentTarget);
                    setAddColumnVisible(true);
                  }}
                  style={{ width: "190px" }}
                >
                  {AddColumnLabel}
                </Button>
                <Button
                  type="secondary"
                  Icon={BiSolidDownArrow}
                  iconSize={12}
                  size="xsmall"
                  onClick={(e) => {
                    setDeleteOptionsVisible(true);
                    setDeleteOptionsEl(e.currentTarget);
                  }}
                  style={{ marginLeft: "10px" }}
                />
              </Row>
              <Popover
                open={addColumnVisible}
                anchorEl={addColumnEl}
                onClose={() => {
                  setAddColumnVisible(false);
                  setAddColumnElm(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <AddColumnPopup
                  onClose={() => {
                    setAddColumnVisible(false);
                    setAddColumnElm(null);
                  }}
                  columnTypeArray={columnTypeArray}
                  nextPosition={tableData.length + 1}
                  reload={reload}
                  varDBColumns={varDBCols}
                />
              </Popover>
              <Popover
                open={deleteOptionsVisible}
                anchorEl={deleteOptionsEl}
                onClose={() => {
                  setDeleteOptionsVisible(false);
                  setDeleteOptionsEl(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{ marginTop: "0.5rem" }}
              >
                <OrganizeColumnsOptions
                  onClick={() =>
                    history.push(`/home/database/columns/${IdVarDB}/${IdTeam}`)
                  }
                >
                  <img src={arrowsIcon} alt="" />
                  Organizar columnas
                </OrganizeColumnsOptions>
                <OrganizeColumnsOptions
                  onClick={() => {
                    setModal("LinkVarDB");
                    setDeleteOptionsVisible(false);
                  }}
                >
                  <LinkIcon />
                  Vincular a otra base de datos
                </OrganizeColumnsOptions>
                <DeleteOption onClick={() => setModal("DeleteVarDBRows")}>
                  <img src={icon_delete_row} alt="" />
                  Eliminar filas
                </DeleteOption>
                <DeleteOption onClick={() => setModal("DeleteVarDB")}>
                  <FiTrash size={14} />
                  Eliminar base de datos
                </DeleteOption>
              </Popover>
            </>
          )}
        </DBTitleWrapper>
      </ViewTitle>
      {(loading || gettingTeamCurrency) && firstLoad ? (
        <Loading />
      ) : (
        <>
          <PaginationContainer
            IdTeam={IdTeam}
            IdVarDB={IdVarDB}
            atLeastOne={tableData[0]?.data.length > 0}
            totalPages={pagination.TotalPages}
            currentPage={currentPage}
            perPage={perPage}
            canExportDatabases={canExportDatabases}
            changePage={changePage}
            changingPage={changingPage}
            canEditDatabases={canEditDatabases}
            showAddRow={tableData.length > 1}
            openAddRowModal={() => setModal("AddEditRow")}
            canImport={varDBCols.length > 0}
            exportVarDBs={exportVarDBs}
            importVarDBs={() => setModal("ImportDB")}
            exportingVarDBToExcel={exportingVarDBToExcel}
            Filters={getFormatedFilters()}
            Order={order}
          />

          <FiltersContainer>
            <FilterToAdd>
              <FilterTitle onClick={() => setFiltersVisible(!filtersVisible)}>
                <ArrowContainer className={filtersVisible ? "active" : ""}>
                  <IoIosArrowDown size={20} color="white" />
                </ArrowContainer>
                <p>{FiltersLabel}</p>
              </FilterTitle>
              {!filtersVisible && !!filters.length && (
                <FilterCountContainer onClick={() => setFiltersVisible(true)}>
                  <AiOutlineFilter size={20} />
                  {`${filters.length} filtro${
                    filters.length === 1 ? "" : "s"
                  } aplicado${filters.length === 1 ? "" : "s"}`}
                </FilterCountContainer>
              )}
              {filtersVisible && (
                <>
                  <GeestSelect
                    value={selectedFilter?.Key}
                    onChange={onChangeFilter}
                    options={getFilterOptions()}
                    placeholderSelect={FiltersPlaceholder}
                    $width="236px"
                    $listWidth="236px"
                  />

                  {Boolean(selectedFilter) && (
                    <FilterContainer>
                      <p className="filterLabel">{FilterLabel}</p>
                      <DynamicFilter
                        selectedFilter={
                          transformSelectedFilter(
                            selectedFilter
                          ) as FilterForDynamicFilter
                        }
                        onChangeFilter={onChangeFilterValue}
                        onPressEnter={onAddFilter}
                        autoFocus
                      />
                    </FilterContainer>
                  )}

                  {selectedFilter?.FilterValue && (
                    <Button type="primary" onClick={onAddFilter}>
                      {ApplyFilter}
                    </Button>
                  )}
                </>
              )}
            </FilterToAdd>

            <AppliedFilters $filtersVisible={filtersVisible}>
              {filters.map((filter, idx) => (
                <Row>
                  <FilterField>
                    {filter.Label}
                    <VerticalDivider />
                    <DynamicFilter
                      selectedFilter={
                        getSelectedFilter(filter) as FilterForDynamicFilter
                      }
                      onChangeFilter={(val) => onUpdateFilter(idx, val)}
                    />
                  </FilterField>
                  <RemoveFilterIconContainer>
                    <CancelIcon onClick={() => onRemoveFilter(idx)} />
                  </RemoveFilterIconContainer>
                </Row>
              ))}
            </AppliedFilters>
          </FiltersContainer>

          <TableContainer>
            <DragDropContext onDragEnd={onDragEnd}>
              <DroppableContainer
                columns={tableData}
                setSelectedColumn={setSelectedColumn}
                selectedColumn={selectedColumn}
                columnTypeArray={columnTypeArray}
                order={order}
                onOrder={onOrder}
                canEditDatabases={canEditDatabases}
                handleSetEditRow={handleSetEditRow}
                reload={reload}
                teamCurrency={teamCurrency}
              />
            </DragDropContext>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default DataBase;
