import styled from "styled-components";

export const SideBarBox = styled.div`
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  padding: 10px;
  position: relative;
`;

export const Content = styled.div<{ $isOpen: boolean }>`
  box-sizing: border-box;
  flex-grow: 1;
  background-color: #0067b0;
  padding: ${({ $isOpen }) => ($isOpen ? "12px 20px" : "12px 16px")};
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: padding 0.35s ease;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const ExpandWrapper = styled.div<{ $renderBlankSpace: boolean }>`
  position: relative;
  width: 13px;
  background-color: #e4e2e2;
  display: flex;

  &::after {
    content: "";
    width: 13px;
    height: 100%;
    background-color: ${({ $renderBlankSpace }) =>
      $renderBlankSpace ? "#00000061" : ""};
  }
`;

export const OpenSidebarButton = styled.button`
  position: absolute;
  top: 17px;
  left: -12px;
  width: 25px;
  height: 25px;
  border: none;
  background-color: #0067b0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: left 0.35s ease, background-color 0.35s ease;
  &:hover {
    background-color: #014c9b;
  }
`;

export const GeestLogoImg = styled.img`
  width: 100%;
  max-width: 80px;
  height: auto;
`;

export const Img = styled.img`
  width: 100%;
  max-width: 80px;
  height: auto;
  max-height: 25px;
`;

export const Divider = styled.div`
  background: #14487c;
  border-radius: 43px;
  width: 100%;
  height: 4px;
`;

export const Footer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 15px;
`;

export const ProfileNoImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 10px;
  background: #014c9b;
`;

export const ProfileImg = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 50%;
  }
`;
