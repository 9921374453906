export enum CellTypes {
  EXECUTION_NAME = "ExecutionName",
  DURATION = "Duration",
  PROCESS_TEMPLATE_NAME = "ProcessTemplateName",
  STUCK_TIME = "StuckTime",
  TASK_DURATION = "TaskDuration",

  START_DATE = "StartDate",
  END_DATE = "EndDate",

  EXECUTION_ID = "ExecutionId",

  STARTED_USER = "StartedUser",
  USERS_INVOLVED = "UsersInvolved",

  PROCESS_TRIGGER = "ProcessTrigger",

  STATUS = "Status",
  STAGE = "Stage",
  PRIORITY = "Priority",

  CURRENT_EXECUTORS = "CurrentExecutors",

  LAST_INTERACTION = "LastInteraction",

  INFO_FROM_FIELD = "InfoFromField",
  INFO_FROM_COLUMN = "InfoFromColumn",
}

export enum DataTypes {
  STRING = "string",
  LOCATION = "location",
  SELECT = "select",
  MULTI_SELECT = "multi_select",
  DATE = "date",
  TIME = "time",
  NUMBER = "number",
  USERS_SELECT = "users_select",
  PHONE_NUMBER = "phone_number",
  CURRENCY = "currency",
  FILE = "file",
  PDF_AUTOGENERATED = "pdf_autogenerated",
}

export interface CellComponentProps {
  dataType?: DataTypes;
}

export interface DynamicSkeletonCellProps {
  cellType: CellTypes;
  dataType?: DataTypes;
}
