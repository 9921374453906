import React, { useRef, useState } from "react";
import { Handle, NodeProps } from "reactflow";
import styled from "styled-components";
import { Tooltip } from "antd";
import { defaultHandlers } from "./shared/shared";
import { border, handlerStyles, Rectangle, StyledTag } from "./shared/styles";
import { NodeData } from "../Diagram.d";
import { getNodeStatusBackgroundColor, getStatusTag } from "../helpers";
import { useElementOverflow } from "../../../../../../hooks";

const SubfluxRectangle = styled(Rectangle)`
  padding: 2px;
`;

const Content = styled.div`
  border: ${border};
  border-radius: 7px;
  flex: 1;
  text-align: center;
  display: flex;
  overflow: hidden;
  padding: 8px;
`;
const TextContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
`;
const Text = styled.span`
  margin-bottom: 4px;
  line-height: 110%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const SubfluxNode: React.FC<NodeProps<NodeData>> = ({ data, selected }) => {
  const { label, status } = data;
  const nodeBackground = getNodeStatusBackgroundColor(status);
  const statusTag = getStatusTag(status);

  const textRef = useRef<HTMLSpanElement>(null);
  const isOverflow = useElementOverflow(textRef, "height");
  const [openTooltip, setOpenTooltip] = useState(false);
  return (
    <SubfluxRectangle $isSelected={selected} $backgroundColor={nodeBackground}>
      <Content>
        <TextContainer>
          <Tooltip
            title={label}
            open={openTooltip && isOverflow}
            onOpenChange={setOpenTooltip}
            overlayInnerStyle={{
              borderRadius: "2px",
              padding: "6px 8px",
            }}
          >
            <Text ref={textRef}>{label}</Text>
          </Tooltip>
          <div>
            <StyledTag color={statusTag.color}>{statusTag.label}</StyledTag>
          </div>
        </TextContainer>
      </Content>
      {defaultHandlers.map((handler) => (
        <Handle
          style={handlerStyles}
          key={handler.id}
          {...handler}
          isConnectableStart={false}
        />
      ))}
    </SubfluxRectangle>
  );
};

export default SubfluxNode;
