import stringDT from "../../../resources/img/DataType_48505E/string.svg";
import numberDT from "../../../resources/img/DataType_48505E/number.svg";
import phoneDT from "../../../resources/img/DataType_48505E/phone.svg";
import dateDT from "../../../resources/img/DataType_48505E/date.svg";
import timeDT from "../../../resources/img/DataType_48505E/time.svg";
import locationDT from "../../../resources/img/DataType_48505E/location.svg";
import currencyDT from "../../../resources/img/DataType_48505E/currency.svg";
import selectDT from "../../../resources/img/DataType_48505E/select.svg";
import multiSelectDT from "../../../resources/img/DataType_48505E/multiselect.svg";
import usersSelectDT from "../../../resources/img/DataType_48505E/user.svg";
import checkListDT from "../../../resources/img/DataType_48505E/checklist.svg";
import fileDT from "../../../resources/img/DataType_48505E/file.svg";
import varDBRowDT from "../../../resources/img/DataType_48505E/vardb.svg";
import pdfDT from "../../../resources/img/DataType_48505E/pdf.svg";
import signatureDT from "../../../resources/img/DataType_48505E/sign.svg";

const DataTypeIcons: {
  [key: string]: {
    icon: string;
    label: string;
    extraStyles: { [key: string]: any };
  };
} = {
  string: {
    icon: stringDT,
    label: "Campo tipo texto",
    extraStyles: {},
  },
  number: {
    icon: numberDT,
    label: "Campo tipo número",
    extraStyles: {},
  },
  phone_number: {
    icon: phoneDT,
    label: "Campo tipo número telefónico",
    extraStyles: {},
  },
  date: {
    icon: dateDT,
    label: "Campo tipo fecha",
    extraStyles: {},
  },
  time: {
    icon: timeDT,
    label: "Campo tipo fecha y hora",
    extraStyles: {},
  },
  location: {
    icon: locationDT,
    label: "Campo tipo ubicación",
    extraStyles: {},
  },
  currency: {
    icon: currencyDT,
    label: "Campo tipo moneda",
    extraStyles: {},
  },
  select: {
    icon: selectDT,
    label: "Campo tipo lista de opciones",
    extraStyles: {},
  },
  multi_select: {
    icon: multiSelectDT,
    label: "Campo tipo selección mútliple",
    extraStyles: {},
  },
  users_select: {
    icon: usersSelectDT,
    label: "Campo tipo selección de usuarios",
    extraStyles: {},
  },
  checklist: {
    icon: checkListDT,
    label: "Campo tipo checklist",
    extraStyles: {},
  },
  file: {
    icon: fileDT,
    label: "Campo tipo archivo",
    extraStyles: {},
  },
  varDBRow: {
    icon: varDBRowDT,
    label: "",
    extraStyles: {},
  },
  pdf_autogenerated: {
    icon: pdfDT,
    label: "",
    extraStyles: {},
  },
  signature: {
    icon: signatureDT,
    label: "",
    extraStyles: {},
  },
};

export default DataTypeIcons;
