import React from "react";
import { Modal } from "@material-ui/core";
import { Row } from "antd";
import { Button, Typography } from "../../../../GeestUI";
import { CloseIcon } from "../../../Pendings/DetailPending/styles";
import { StyledPaper, DescriptionContainer } from "./Styles";
import { UnsavedChangesProps } from "./UnsavedChanges.d";

const Languages = {
  ENG: {
    Title: "Unsaved Changes",
    Description:
      "You have some unsaved changes, do you want to close without save?",
    CancelLabel: "Cancel",
    CloseLabel: "Close",
  },
  ESP: {
    Title: "Cambios sin guardar",
    Description: "Tienes cambios sin guardar, ¿Deseas salir sin guardar?",
    CancelLabel: "Cancelar",
    CloseLabel: "Salir",
  },
};

const { Title, Description, CancelLabel, CloseLabel } = Languages["ESP"];

const { H3, B, P } = Typography;

const UnsavedChanges: React.FC<UnsavedChangesProps> = ({
  onCancel,
  onClose,
}) => {
  return (
    <Modal open onClose={onCancel} style={{ opacity: 1, zIndex: 20 }}>
      <Row
        justify="center"
        align="middle"
        onClick={onCancel}
        style={{ width: "100%", height: "100%" }}
      >
        <StyledPaper onClick={(e) => e.stopPropagation()}>
          <Row align="middle" justify="space-between">
            <H3 mb="0">
              <B>{Title}</B>
            </H3>
            <CloseIcon onClick={onCancel} />
          </Row>
          <DescriptionContainer>
            <P mb="0">{Description}</P>
          </DescriptionContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <Button type="secondary" onClick={onCancel}>
              {CancelLabel}
            </Button>
            <Button type="primary" onClick={onClose}>
              {CloseLabel}
            </Button>
          </div>
        </StyledPaper>
      </Row>
    </Modal>
  );
};

export default UnsavedChanges;
