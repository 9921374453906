export const Languages = {
  ENG: {
    // general
    ImportDataBase: "Import database",
    ImportLabel: "Import",
    SuccessfullyImported: "Successfully imported",
    TooltipLabel:
      "Assign the imported fields from your file to the columns in your Geest database",

    // drag drop file
    DropIt: "Drop it!",
    SelectYourFile: "Select your file .csv or .xlsx",
    UploadYourFile: "Upload your file by dragging it into this window",

    // column select
    SelectFieldsLabel: "Select files you want to sync with your database",
    ColumnsToImport: "Columns to import:",
    PutOnDBColumn: "Put on DB column:",
    FirstRowLabel: "First row should be imported",
    ReturnLabel: "Return",
    DontImportThisField: "Don't Import this field",

    // finish step
    SuccessfullyImportedDescription:
      "Your file has been successfully imported to your database:",
    AcceptLabel: "Accept",
  },
  ESP: {
    // general
    ImportDataBase: "Importar base de datos",
    ImportLabel: "Importar",
    SuccessfullyImported: "Importación exitosa",
    TooltipLabel:
      "Asigna los campos importados de tu archivo a las columnas de tu base de datos en geest",

    // drag drop file
    DropIt: "¡Sueltalo!",
    SelectYourFile: "Selecciona tu archivo .csv o .xlsx",
    UploadYourFile: "Sube tu archivo arrastrándolo a esta ventana",

    // column select
    SelectFieldsLabel:
      "Selecciona los campos que quieres sincronizar con tu base de datos",
    ColumnsToImport: "Columnas a importar:",
    PutOnDBColumn: "Colocar en columna de BD:",
    FirstRowLabel: "La primer fila debe ser importada",
    ReturnLabel: "Regresar",
    DontImportThisField: "No importar este campo",

    // finish step
    SuccessfullyImportedDescription:
      "El archivo se a importado correctamente a tu base de datos:",
    AcceptLabel: "Aceptar",
  },
};
