import React from "react";
import {
  DynamicFilterProps,
  OriginElement,
  UsersType,
} from "./DynamicFilter.d";
import {
  StringFilter,
  UserSelectFilter,
  MultiSelectFilter,
  CurrencyFilter,
  PhoneNumberFilter,
  DateFilter,
  SelectFilter,
} from "./components";

const DynamicFilter: React.FC<DynamicFilterProps> = ({
  selectedFilter,
  onChangeFilter,
  onSubmit = () => {},
  onPressEnter,
  autoFocus,
}) => {
  const { FilterType, IdReportColumn, DataOrigin, Value, defaultOptions } =
    selectedFilter;

  const getPlaceHolder = () => {
    if (IdReportColumn.startsWith("ExecutionName")) {
      return "Escribe el seguimiento para filtrar...";
    }
    if (IdReportColumn.startsWith("StartedUser")) {
      return "Selecciona los usuarios para filtrar...";
    }
    if (IdReportColumn.startsWith("ProcessTrigger")) {
      return "Selecciona el disparador para filtrar...";
    }
    if (IdReportColumn.startsWith("ExecutionId")) {
      return "Escribe el folio para filtrar...";
    }
    if (IdReportColumn.startsWith("Status")) {
      return "Selecciona el estatus para filtrar...";
    }
    if (IdReportColumn.startsWith("Stage")) {
      return "Selecciona la etapa para filtrar...";
    }
    if (IdReportColumn.startsWith("Priority")) {
      return "Selecciona la prioridad para filtrar...";
    }
    if (IdReportColumn.startsWith("ProcessTemplateName")) {
      return "Selecciona el proceso para filtrar...";
    }
    if (IdReportColumn.startsWith("UsersInvolved")) {
      return "Selecciona los usuarios para filtrar...";
    }

    return "Escribe aquí...";
  };

  switch (FilterType) {
    case "select":
      return (
        <SelectFilter
          value={Value}
          DataOrigin={DataOrigin as string}
          onChangeFilter={onChangeFilter}
          defaultOpions={defaultOptions}
          placeholder={getPlaceHolder()}
        />
      );
    case "multi_select":
      return (
        <MultiSelectFilter
          value={Value}
          DataOrigin={DataOrigin as OriginElement[]}
          onChangeFilter={onChangeFilter}
          defaultOpions={defaultOptions}
          placeholder={getPlaceHolder()}
        />
      );
    case "users_select":
      return (
        <UserSelectFilter
          value={Value}
          DataOrigin={DataOrigin as UsersType[]}
          onChangeFilter={onChangeFilter}
          placeholder={getPlaceHolder()}
        />
      );
    case "string":
      return (
        <StringFilter
          value={Value || ""}
          placeholder={getPlaceHolder()}
          onChange={onChangeFilter}
          onSubmit={onSubmit}
          onPressEnter={onPressEnter}
          autoFocus={autoFocus}
        />
      );
    case "currency":
      return (
        <CurrencyFilter
          value={Value || { Format: "", Value: "" }}
          onChangeFilter={onChangeFilter}
          onSubmit={onSubmit}
          onPressEnter={onPressEnter}
          autoFocus={autoFocus}
        />
      );
    case "phone_number":
      return (
        <PhoneNumberFilter
          value={Value || { Format: "", Value: "" }}
          onChangeFilter={onChangeFilter}
          onSubmit={onSubmit}
          onPressEnter={onPressEnter}
          autoFocus={autoFocus}
        />
      );
    case "date_range":
      return <DateFilter Value={Value} onChange={onChangeFilter} />;

    default:
      return (
        <div>
          <p>no case: {FilterType}</p>
        </div>
      );
  }
};

export default DynamicFilter;
