import React, { useContext, useState } from "react";
import _, { noop as NOOP } from "lodash";
import styled from "styled-components";
import EditFileNameModal from "../../../components/EditFileNameModal";
import ConnectionErrorModal from "../../../components/ConnectionErrorModal";
import { TooltipPlacement } from "antd/lib/tooltip";
import { Upload, Row, Spin, Input, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Languages } from "./Dictionary";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { AiOutlineUpload, AiOutlineFile } from "react-icons/ai";
import { Button } from "../../../GeestUI";

import {
  FileSizeLimit,
  maxFilesList,
} from "../../../constants/PlatformDetails";
import IsRequired from "./helpers/IsRequired";
import { MessagesContext } from "../../AppMessages";
import {
  DeleteIcon,
  EditIcon,
  CloseIcon,
  AttachmentIcon,
} from "../../hoverIcons";
import FileCarousel from "./helpers/FileCarousel";
import { uploadFileToAWS } from "../../../aws/s3Client";
import { ReactComponent as clipBoardIcon } from "../../../resources/img/clipBoardIcon.svg";
import { onPasteImage } from "./helpers/FileHelpers";
import TP from "../../Tooltip";
import { useMutation } from "../../../hooks";

interface FileType {
  Name: string;
  SourceUrl: string;
}

interface FileInputProps {
  value: FileType[];
  isConsult: boolean;
  disabled: boolean;
  required: boolean;
  onChange: (value: FileType[]) => void;
  onBlur?: (e: any) => void;
  needsCatchEsc?: boolean;
  isFileOpen?: boolean;
  setIsFileOpen?: (isOpen: boolean) => void;
  extraParams?: {
    FilesPath?: string;
    center?: boolean;
    oneLineString?: boolean;
    tooltipOffset?: number;
    tooltipPlacement?: TooltipPlacement;
    mb?: string;
    shouldValidateSize?: boolean;
  };
}

const InputContainer = styled.div<{ isConsult: boolean; mb?: string }>`
  ${({ isConsult, mb = "10px" }) => (isConsult ? "" : `margin-bottom: ${mb};`)}
  width: 100%;
  * {
    margin: 0;
    padding: 0;
  }
  max-width: 100%;
`;

const StyledUpload = styled(Upload)`
  .ant-upload {
    width: 100%;
    * :focus-visible {
      border-color: var(--navy-blue) !important;
    }
  }
`;

const StyledUploadSmall = styled(Upload)`
  .ant-upload {
    width: 100%;
    * :focus-visible {
      border-color: var(--navy-blue) !important;
    }
  }
  .ant-upload-list {
    display: flex;
    align-items: center;
  }
  .ant-upload-list-picture {
    display: flex;
    align-items: center;
  }
`;

const InputStyled = styled(Input)`
  border-radius: 6px;
  border: 2px solid;
  border-color: #edecec;
  background-color: white;
  color: #828d9e;

  flex: 1;
  min-height: 30px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e !important;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const ElementContainer = styled.div<{ bgColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  border-radius: 5px;
  background: ${({ bgColor }) => bgColor};
  color: #0273e9;
  padding: 5px;
  font-size: 12px;
  font-family: Gotham-Book;
  max-width: 100%;
`;

const FileName = styled.div<{ hovering?: boolean }>`
  margin-top: 2px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding-right: ${({ hovering }) => (hovering ? "0" : "25px")};
`;

const EmptySpace = styled.div`
  height: 30px;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const validateFileName = (fileName: string) => {
  const { InvalidFileName } = Languages["ESP"];
  let isValidFileName = true;
  let errorMessage = "";

  if (fileName.includes('"')) {
    errorMessage = InvalidFileName('"');
    isValidFileName = false;
  }

  return {
    isValidFileName,
    errorMessage,
  };
};

const FileInput: React.FC<FileInputProps> = ({
  value = [],
  isConsult,
  disabled,
  required,
  onChange,
  onBlur = NOOP,
  needsCatchEsc = false,
  isFileOpen = false,
  setIsFileOpen = NOOP,
  extraParams = { center: false, mb: "10px" },
}) => {
  const [fileToOpen, setFileToOpen] = useState<UploadFile<any> | null>(null);
  const [fileNameModal, setFileNameModal] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [UIDFile, setUIDFile] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [hoveringUID, setHoveringUID] = useState<string | undefined>(undefined);
  const { showMessage } = useContext(MessagesContext);

  const [validateFileSize, loadingValidate] = useMutation({
    func: "Ver2-Processes-vfs",
    onSuccess: (req, { fileList }) => {
      setLoading(true);
      onUploadFilesToAWS(fileList)
        .then((newFilesArray) =>
          onChange([...value, ...(newFilesArray as FileType[])])
        )
        .finally(() => setLoading(false))
        .catch((err) => {
          console.log(err);
          setFileNameModal("ConnectionError");
        });
    },
  });

  const { FileNameExists, FileSizeError, NoMoreThan, UploadFileLabel } =
    Languages["ESP"];

  const setOpenFile = (file: UploadFile<any> | null) => {
    setFileToOpen(file);
    setIsFileOpen(Boolean(file?.url));
  };

  const tooltipPlacement = extraParams.tooltipPlacement ?? "bottom";
  const canShowUploadButton = value.length >= maxFilesList;
  const { FilesPath, shouldValidateSize = true } = extraParams;
  const convertedFiles =
    value && Array.isArray(value)
      ? value.slice(-maxFilesList).map((file: any) => ({
          uid: file.Name + file.SourceUrl,
          name: file.Name,
          url: file.SourceUrl,
          thumbUrl: file.SourceUrl,
          ...file,
        }))
      : [];
  const fileLimitReached = convertedFiles.length >= maxFilesList;

  const onChangeFileName = () => {
    if (!_.isEmpty(fileName.trim()))
      onChange(
        convertedFiles.map((file: any) => {
          if (file.uid === UIDFile) {
            const fileType = "." + (_.last(_.split(file.Name, ".")) ?? "");
            file.Name = fileName + fileType;
            file.name = fileName + fileType;
            file.uid = fileName;
          }
          return { Name: file.Name, SourceUrl: file.SourceUrl };
        })
      );
    setUIDFile("");
  };

  const Modals: { [key: string]: React.ReactNode } = {
    EditFileName: (
      <EditFileNameModal
        FileName={fileName}
        setFileName={setFileName}
        onChange={onChangeFileName}
        onClose={() => {
          setFileNameModal("");
          setFileName("");
          setUIDFile("");
        }}
      />
    ),
    ConnectionError: (
      <ConnectionErrorModal onClose={() => setFileNameModal("")} />
    ),
  };

  const onRemove = ({ uid }: UploadFile<any>) =>
    onChange(
      convertedFiles
        .filter((file: any) => file.uid !== uid)
        .map(({ name, url }) => ({ Name: name, SourceUrl: url }))
    );

  const onUploadFilesToAWS = (fileList: RcFile[]) =>
    new Promise(async (resolve, reject) => {
      let newFilesArray: FileType[] = [];
      if (!FilesPath) return reject(new Error("`FilesPath` not defined"));
      for (let file of fileList) {
        const filePath = await uploadFileToAWS(file, FilesPath);
        if (filePath) {
          newFilesArray.push({
            Name: file.name,
            SourceUrl: filePath,
          });
          if (newFilesArray.length === fileList.length) resolve(newFilesArray);
        } else return reject(new Error("Upload failed"));
      }
    });

  const beforeUpload = async (file: RcFile, fileList: RcFile[]) => {
    let canUpload = true;
    const isSizeValid = fileList.find(
      (file: any) => file.size / 1000000 < FileSizeLimit
    );
    const { isValidFileName, errorMessage } = validateFileName(file.name);
    // File validation
    if (Array.isArray(value)) {
      const repeatedName = value.find((element) => element.Name === file.name);
      if (repeatedName) {
        _.remove(fileList, (f) => f.name === repeatedName.Name);
        showMessage(FileNameExists, "error");
        if (fileList.length <= 1) return;
      }
    }
    if (!isSizeValid) {
      return showMessage(FileSizeError(FileSizeLimit), "error");
    }
    if (!isValidFileName) {
      return showMessage(errorMessage, "error");
    }

    // fileList validation
    if ([...value, ...fileList].length > maxFilesList) {
      canUpload = false;
    }

    if (file.uid !== fileList[0].uid) return;

    if (canUpload) {
      const firstPath = FilesPath?.split("/")[0];
      if (firstPath && shouldValidateSize) {
        const splitPath = firstPath.split("-");
        if (splitPath[0] === "Team" && !!splitPath[1]) {
          const IdTeam = splitPath[1];
          let FileSize = 0;
          fileList.forEach((file: any) => {
            FileSize += file.size / 1073741824;
          });
          validateFileSize({
            args: {
              IdTeam,
              FileSize,
            },
            shippedData: { fileList },
          });
          return;
        }
      }
      setLoading(true);
      onUploadFilesToAWS(fileList)
        .then((newFilesArray) =>
          onChange([...value, ...(newFilesArray as FileType[])])
        )
        .finally(() => setLoading(false))
        .catch((err) => {
          console.log(err);
          setFileNameModal("ConnectionError");
        });
    } else if (file.uid === fileList[fileList.length - 1].uid) {
      return showMessage(NoMoreThan, "error");
    }
  };

  const handleDownload = (file: UploadFile<any>) => {
    const { url = "", name = "" } = file;

    const fileExtension = _.toLower(url.split(".").pop());
    const regularExtensions = ["jpeg", "jpg", "png"];
    const useIframe = url && !regularExtensions.includes(fileExtension);

    const downloadBlob = (blob: Blob, filename: string) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const aTag = document.createElement("a");
      aTag.href = blobUrl;
      aTag.setAttribute("download", filename);
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
      window.URL.revokeObjectURL(blobUrl); // Clean up the URL object
    };

    if (useIframe) {
      // PDF or other file types not in the regularExtensions list
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          downloadBlob(blob, name);
        })
        .catch((error) => {
          console.error("Error downloading the file", error);
        });
    } else {
      // Images or other regular extensions
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          downloadBlob(blob, name);
        })
        .catch((error) => {
          console.error("Error downloading the file", error);
        });
    }
  };

  const onAddCopiedImage = async () => {
    const newValue = await onPasteImage(FilesPath || "");
    if (newValue) {
      onChange([...value, newValue]);
    }
  };

  if (isConsult && _.isEmpty(value)) return <EmptySpace />;

  return (
    <>
      {Modals[fileNameModal]}
      <InputContainer isConsult={isConsult} mb={extraParams.mb}>
        {!isConsult || value.length <= 1 ? (
          <StyledUpload
            showUploadList={{
              showDownloadIcon: !disabled,
              downloadIcon: (
                <div title="Edit file">
                  <EditOutlined style={{ marginRight: "5px" }} />
                </div>
              ),
            }}
            beforeUpload={beforeUpload}
            fileList={convertedFiles || []}
            disabled={disabled || fileLimitReached}
            listType="picture"
            multiple
            itemRender={(
              node: React.ReactNode,
              file: UploadFile,
              fileList: UploadFile[] | undefined
            ) => (
              <ElementContainer
                onMouseEnter={() => setHoveringUID(file.uid)}
                onMouseLeave={() => setHoveringUID(undefined)}
                bgColor={
                  hoveringUID === file.uid
                    ? "rgba(0, 0, 0, 0.05)"
                    : "transparent"
                }
              >
                <Row
                  align="middle"
                  style={{
                    flex: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                    flexWrap: "nowrap",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpenFile(file);
                  }}
                >
                  <AiOutlineFile
                    size={15}
                    color="#48505e"
                    style={{ marginRight: "5px" }}
                  />
                  {UIDFile !== file.uid ? (
                    <FileName
                      hovering={
                        hoveringUID === file.uid || UIDFile === file.uid
                      }
                    >
                      {file.name}
                    </FileName>
                  ) : (
                    <InputStyled
                      onChange={(e) => setFileName(e.target.value)}
                      value={fileName}
                      onBlur={() => {
                        onChangeFileName();
                        onBlur();
                      }}
                      onPressEnter={onChangeFileName}
                      autoFocus
                      onClick={(e) => e.stopPropagation()}
                    />
                  )}
                </Row>
                {!isConsult && UIDFile === file.uid && (
                  <CloseIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setUIDFile("");
                    }}
                  />
                )}
                {(hoveringUID === file.uid || UIDFile === file.uid) && (
                  <AttachmentIcon onClick={() => handleDownload(file)} />
                )}
                {!isConsult &&
                  (hoveringUID === file.uid || UIDFile === file.uid) &&
                  !disabled && (
                    <>
                      {UIDFile !== file.uid && (
                        <EditIcon
                          onClick={() => {
                            if (!disabled) {
                              const fileType =
                                "." + (_.last(_.split(file.name, ".")) ?? "");
                              setFileName(_.replace(file.name, fileType, ""));
                              setUIDFile(file.uid);
                            }
                          }}
                        />
                      )}
                      <DeleteIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          onRemove(file);
                        }}
                      />
                    </>
                  )}
              </ElementContainer>
            )}
          >
            {!isConsult && !canShowUploadButton && !disabled && (
              <UploadContainer>
                <Button
                  type="secondary"
                  disabled={fileLimitReached}
                  danger={required && value.length === 0}
                  Icon={AiOutlineUpload}
                  style={{ width: "calc(100% - 36px)" }}
                >
                  {UploadFileLabel}
                </Button>
                <TP title="Pegar archivo de portapapeles">
                  <Button
                    type="secondary"
                    size="xsmall"
                    SvgIcon={clipBoardIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      onAddCopiedImage();
                    }}
                  />
                </TP>
              </UploadContainer>
            )}
          </StyledUpload>
        ) : (
          <StyledUploadSmall
            showUploadList={{
              showDownloadIcon: !disabled,
              downloadIcon: (
                <div title="Edit file">
                  <EditOutlined style={{ marginRight: "5px" }} />
                </div>
              ),
            }}
            beforeUpload={beforeUpload}
            fileList={convertedFiles || []}
            disabled={disabled || fileLimitReached}
            listType="picture"
            multiple
            itemRender={(
              node: React.ReactNode,
              file: UploadFile,
              fileList: UploadFile[] | undefined
            ) => (
              <Tooltip title={file.name} placement={tooltipPlacement}>
                <ElementContainer
                  onMouseEnter={() => setHoveringUID(file.uid)}
                  onMouseLeave={() => setHoveringUID(undefined)}
                  bgColor={
                    hoveringUID === file.uid
                      ? "rgba(0, 0, 0, 0.05)"
                      : "transparent"
                  }
                >
                  <Row
                    align="middle"
                    style={{
                      flex: 1,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setOpenFile(file);
                    }}
                  >
                    <AiOutlineFile size={15} color="#48505e" />
                    {UIDFile === file.uid && (
                      <InputStyled
                        onChange={(e) => setFileName(e.target.value)}
                        value={fileName}
                        onBlur={() => {
                          onChangeFileName();
                          onBlur();
                        }}
                        onPressEnter={onChangeFileName}
                        autoFocus
                        onClick={(e) => e.stopPropagation()}
                      />
                    )}
                  </Row>
                  {!isConsult && UIDFile === file.uid && (
                    <CloseIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setUIDFile("");
                      }}
                    />
                  )}
                  {!isConsult &&
                    (hoveringUID === file.uid || UIDFile === file.uid) &&
                    !disabled && (
                      <>
                        {UIDFile !== file.uid && (
                          <EditIcon
                            onClick={() => {
                              if (!disabled) {
                                setFileName(file.name);
                                setUIDFile(file.uid);
                              }
                            }}
                          />
                        )}
                        <DeleteIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            onRemove(file);
                          }}
                          filled
                        />
                      </>
                    )}
                </ElementContainer>
              </Tooltip>
            )}
          >
            {!isConsult && !canShowUploadButton && !disabled && (
              <Button
                type="secondary"
                size="fullwidth"
                disabled={fileLimitReached}
                danger={required && value.length === 0}
                Icon={AiOutlineUpload}
              >
                {UploadFileLabel}
              </Button>
            )}
          </StyledUploadSmall>
        )}
        {required && !isConsult && value.length === 0 && <IsRequired />}
      </InputContainer>

      {(loading || loadingValidate) && (
        <Row justify="center" align="middle" style={{ marginTop: "5px" }}>
          <Spin size="default" />
        </Row>
      )}

      {needsCatchEsc
        ? isFileOpen &&
          Boolean(fileToOpen?.url) && (
            <FileCarousel
              fileToOpen={fileToOpen as UploadFile<any>}
              files={convertedFiles || []}
              closeModal={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                setOpenFile(null);
                setIsFileOpen(false);
              }}
              handleEsc={() => {
                setOpenFile(null);
                setIsFileOpen(false);
              }}
            />
          )
        : Boolean(fileToOpen?.url) && (
            <FileCarousel
              fileToOpen={fileToOpen as UploadFile<any>}
              files={convertedFiles || []}
              closeModal={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                setOpenFile(null);
                setIsFileOpen(false);
              }}
              handleEsc={() => {
                setOpenFile(null);
                setIsFileOpen(false);
              }}
            />
          )}
    </>
  );
};

export default FileInput;
