import React, { useContext, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import TagOptions from "../../../components/TagOptions";
import { Switch } from "antd";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { useMutation } from "../../../hooks";
import DataTypeIcons from "./DataTypeIcons";
import { ColumnType } from "../DataBases.d";
import { CloseIcon } from "../../../components/hoverIcons";
import GeestSelect from "../../../GeestUI/GeestSelect";
import { Button } from "../../../GeestUI";
import { Languages } from "./Dictionary";
import { Row } from "antd";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Tooltip from "../../../components/Tooltip";
import { VarDBColumn } from "../DataBases.d";
import { MessagesContext } from "../../../components/AppMessages";
import { DropResult } from "react-beautiful-dnd";

const StyledPaper = styled(Paper)`
  border-radius: 6px !important;
  width: 432px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 12px;

  p {
    margin: 0;
    font-family: Gotham-Bold;
    font-size: 16px;
    color: #48505e;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
`;

const SelectContainer = styled(InputContainer)`
  flex-direction: column;
`;

const Input = styled.input<{ $hasValue: boolean }>`
  width: 100%;
  color: rgba(130, 141, 158, 1);
  border: 2px solid
    ${({ $hasValue }) => ($hasValue ? "rgba(237, 236, 236, 1)" : "#db232c")};
  border-radius: 6px;
  padding-left: 10px;
  height: 32px;
  font-family: Gotham-Book;
  ::placeholder {
    color: #828d9e;
  }
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
`;

const ChangeDataTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;
`;

const DataOriginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  padding: 0 60px;

  p {
    margin: 0;
    text-align: center;
    font-size: 14px;
    color: #828d9e;
  }
`;

const ColumnFromTitle = styled.div`
  color: rgba(72, 80, 94, 1);
  font-size: 14px;
  font-family: Gotham-Bold;
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`;

const CannAddOptionsWrapper = styled(SwitchContainer)``;

const SelectMoreLocations = styled(SwitchContainer)``;

export const StyledSwitch = styled(Switch)`
  background: ${({ checked }) => (checked ? "#0273E9" : "#828D9E")};
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
`;

const AddColumnPopup: React.FC<{
  onClose: () => void;
  columnTypeArray: ColumnType[];
  nextPosition: number;
  reload: () => void;
  varDBColumns: VarDBColumn[];
}> = ({ onClose, columnTypeArray, nextPosition, reload, varDBColumns }) => {
  const { IdTeam, IdVarDB } = useParams<{ IdTeam: string; IdVarDB: string }>();
  const [columnName, setColumnName] = useState<string>("");
  const [dataType, setDataType] = useState<string>("");
  const [configuration, setConfiguration] = useState<
    | ""
    | "CanAddOptions"
    | "CannotAddOptions"
    | "SelectAndCurrent"
    | "OnlyCurrent"
  >("");

  const [dataOrigin, setDataOrigin] = useState<any>([]);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputOpcVis, setInputOpcVis] = useState<boolean>(false);
  const [opcValue, setOpcValue] = useState<string>("");
  const [opcIndex, setOpcIndex] = useState<any>(null);
  const [createAfter, setCreateAfter] = useState<"column" | "end">("end");
  const [columnAfter, setColumnAfter] = useState<number | null>(null);

  const { showMessage } = useContext(MessagesContext);

  const emptyName = _.isEmpty(_.trim(columnName));
  const emptydataType = _.isEmpty(_.trim(dataType));
  const emptyNextColumn = createAfter === "column" && !columnAfter;
  const importing = dataType === "select" || dataType === "multi_select";
  const emptyDataOrigin = importing && dataOrigin.length === 0;

  const cantSave =
    emptyName || emptydataType || emptyNextColumn || emptyDataOrigin;

  const {
    AddColumnLabel,
    ColumnNamePlaceholder,
    DatatypeLabel,
    NewOptionsLabel,
    MoreLocationsLabel,
    CancelLabel,
    AddLabel,
    ValueAlreadyExistsLabel,
    YouNeedAtLeastOneOption,
  } = Languages["ESP"];

  const datatypeSelectOptions = (() => {
    return [...columnTypeArray].map((columnType) => {
      return {
        value: columnType.Value,
        searchableValues: `${columnType.Value},${columnType.Label}`,
        label: (
          <Row align="middle">
            <Row
              align="middle"
              justify="center"
              style={{
                width: "26px",
                height: "26px",
                display: "flex",
              }}
              wrap={false}
            >
              <img
                style={{
                  width: "14px",
                  height: "14px",
                  ...DataTypeIcons[columnType.Value?.toLowerCase()].extraStyles,
                }}
                alt={
                  DataTypeIcons[columnType.Value?.toLowerCase()]?.label || ""
                }
                src={DataTypeIcons[columnType.Value?.toLowerCase()].icon}
              />
            </Row>
            <div
              style={{
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {columnType.Label}
            </div>
          </Row>
        ),
      };
    });
  })();

  const handleOnDeleteOption = (e: any, index: any) => {
    e.preventDefault();
    if (dataOrigin.length === 1) {
      showMessage(YouNeedAtLeastOneOption, "error");
      return;
    }
    let data = [...dataOrigin];
    data.splice(index, 1);
    setDataOrigin(data);
  };

  const valueAlreadyExists = (value: string) => {
    const indexedDataOrigin = [...dataOrigin].reduce((acc, el) => {
      acc[el.label.toLowerCase()] = el;
      return acc;
    }, {});

    if (value.toLowerCase() in indexedDataOrigin) {
      showMessage(ValueAlreadyExistsLabel, "error");
      return true;
    }
    return false;
  };

  const handleInputConfirm = () => {
    if (inputValue === "") {
      setInputValue("");
      setInputVisible(false);
      return;
    }

    if (!valueAlreadyExists(inputValue)) {
      let newDataOrigin = [...dataOrigin];
      newDataOrigin.push({ label: inputValue.trim() });
      setDataOrigin(newDataOrigin);
    }

    setInputValue("");
  };

  const handleInputOpcConfirm = () => {
    let modifiedValue = opcValue.trim();
    if (modifiedValue.includes(","))
      modifiedValue = modifiedValue.replaceAll(",", "");
    if (modifiedValue === "") {
      setOpcValue("");
      setInputOpcVis(false);
      setOpcIndex(null);
      return;
    }

    if (!valueAlreadyExists(modifiedValue)) {
      let data = [...dataOrigin];
      const newOpc = { ...data[opcIndex], label: modifiedValue };
      data.splice(opcIndex, 1, newOpc);
      setDataOrigin(data);
    }

    setOpcValue("");
    setInputOpcVis(false);
    setOpcIndex(null);
  };

  const toggleInput = async (inputVisible: any) => {
    await setInputVisible(inputVisible);
  };

  const toggleInputOpc = async (inputVisible: any) => {
    await setInputOpcVis(inputVisible);
  };

  const [addColumn, addingColumn] = useMutation<[]>({
    func: "Ver2-Vardbs-ivc",
    onSuccess: () => {
      onClose();
      reload();
    },
  });

  const handleSetDataType = (dataType: string) => {
    setDataType(dataType);
    switch (dataType) {
      case "select":
        setConfiguration("CannotAddOptions");
        break;

      case "location":
        setConfiguration("SelectAndCurrent");
        break;

      default:
        setConfiguration("");
        break;
    }
  };

  const handleAddColumn = () => {
    if (cantSave) return;
    addColumn({
      args: {
        IdTeam,
        IdVarDB,
        Column: {
          DataType: dataType,
          Title: columnName,
          DataOrigin: dataOrigin,
          Configuration: configuration,
          DataOriginFromIdColumn: "",
          PrimaryKey: false,
          Position: nextPosition,
          PreviousColumn: createAfter === "end" ? null : columnAfter,
        },
      },
    });
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(dataOrigin);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const newDataOrigin = reorder(
      result.source.index,
      result.destination.index
    );

    setDataOrigin(newDataOrigin);
  };

  const afterOptions = [
    {
      value: "end",
      label: "En la última posición",
    },
    {
      value: "column",
      label: "Crear justo después de otra columna",
    },
  ];

  const columnOptions = varDBColumns.map(({ Title, DataType, IdColumn }) => ({
    label: (
      <Row align="middle">
        <Row
          align="middle"
          justify="center"
          style={{
            width: "26px",
            height: "26px",
            display: "flex",
          }}
          wrap={false}
        >
          <img
            style={{
              width: "14px",
              height: "14px",
              ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
            }}
            alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
            src={DataTypeIcons[DataType?.toLowerCase()].icon}
          />
        </Row>
        <div
          style={{
            flex: 1,
            maxWidth: "calc(100% - 14px)",
          }}
        >
          {Title}
        </div>
      </Row>
    ),
    value: IdColumn,
    searchableValues: Title,
  }));

  return (
    <StyledPaper>
      <ModalTitle>
        <p>{AddColumnLabel}</p>
        <CloseIcon onClick={onClose} size={25} />
      </ModalTitle>

      <InputContainer>
        <Input
          autoFocus
          disabled={addingColumn}
          value={columnName}
          onChange={(e) => setColumnName(e.target.value)}
          placeholder={ColumnNamePlaceholder}
          $hasValue={!!columnName}
        />
      </InputContainer>

      <ChangeDataTypeWrapper>
        <GeestSelect
          value={dataType}
          options={datatypeSelectOptions}
          onChange={(newDataType: string) => handleSetDataType(newDataType)}
          placeholderSelect={DatatypeLabel}
          valueNecesary
        />
      </ChangeDataTypeWrapper>

      {importing && (
        <DataOriginWrapper>
          <TagOptions
            onDragEnd={onDragEnd}
            DataOrigin={dataOrigin}
            handleOnClose={(val: any, index: number) =>
              handleOnDeleteOption(val, index)
            }
            inputVisible={inputVisible}
            inputValue={inputValue}
            setinputValue={(val: any) => setInputValue(val)}
            handleInputConfirm={handleInputConfirm}
            toggleInput={(val: boolean) => toggleInput(val)}
            InputOpcVis={inputOpcVis}
            OpcValue={opcValue}
            setOpcValue={setOpcValue}
            toggleInputOpc={toggleInputOpc}
            OpcIndex={opcIndex}
            setOpcIndex={setOpcIndex}
            handleInputOpcConfirm={handleInputOpcConfirm}
          />
        </DataOriginWrapper>
      )}

      {(dataType === "select" || dataType === "multi_select") && (
        <CannAddOptionsWrapper>
          <StyledSwitch
            checked={configuration === "CanAddOptions"}
            onChange={(val) =>
              setConfiguration(val ? "CanAddOptions" : "CannotAddOptions")
            }
          />
          <p>{NewOptionsLabel}</p>
        </CannAddOptionsWrapper>
      )}

      {dataType === "location" && (
        <SelectMoreLocations>
          <StyledSwitch
            checked={configuration === "SelectAndCurrent"}
            onChange={(val) =>
              setConfiguration(val ? "SelectAndCurrent" : "OnlyCurrent")
            }
          />
          <p>{MoreLocationsLabel}</p>
        </SelectMoreLocations>
      )}

      {!!dataType && (
        <SelectContainer>
          <ColumnFromTitle>
            Posición donde se creará la columna
            <Tooltip
              title="Las columnas siempre se agregan al final 
                de la tabla. Aquí puedes crearla justo
                después de la columna que elijas"
              mui
            >
              <div style={{ marginTop: "2px" }}>
                <AiOutlineInfoCircle size={14} />
              </div>
            </Tooltip>
          </ColumnFromTitle>
          <GeestSelect
            value={createAfter}
            onChange={setCreateAfter}
            disableSearch
            valueNecesary
            options={afterOptions}
          />
        </SelectContainer>
      )}

      {createAfter === "column" && (
        <SelectContainer>
          <ColumnFromTitle>Agregarla después de</ColumnFromTitle>
          <GeestSelect
            value={columnAfter}
            onChange={setColumnAfter}
            options={columnOptions}
          />
        </SelectContainer>
      )}

      <ButtonsRow>
        <Button
          type="secondary"
          disabled={addingColumn}
          loading={addingColumn}
          onClick={onClose}
        >
          {CancelLabel}
        </Button>

        <Button
          type="primary"
          disabled={addingColumn || cantSave}
          loading={addingColumn}
          onClick={handleAddColumn}
        >
          {AddLabel}
        </Button>
      </ButtonsRow>
    </StyledPaper>
  );
};

export default AddColumnPopup;
