import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import { StringInput } from "./style";
import { Button } from "../../../../GeestUI";
import { Row } from "antd";
import { useMutation } from "../../../../hooks";
import { Languages } from "./Dictionary";

interface ChangeNameProps {
  onClose: () => void;
  dashboardName?: string;
  idDashboard?: string;
  reloadDashboards: () => void;
}

const ChangeNameModal: React.FC<ChangeNameProps> = ({
  onClose,
  dashboardName,
  idDashboard,
  reloadDashboards,
}) => {
  const [name, setName] = useState<string>(dashboardName || "");
  const { ModalTitle, SaveLabel, CancelLabel } = Languages["ESP"];

  const [changeName] = useMutation({
    func: "Ver2-DashBoards-ud",
    onSuccess: () => {
      reloadDashboards();
      onClose();
    },
  });

  const onChangeName = () => {
    changeName({
      args: {
        IdDashBoard: idDashboard,
        DashBoardName: name,
      },
    });
  };

  return (
    <Modal
      title={ModalTitle}
      onCancel={onClose}
      width="432px"
      titleStyles={{ fontFamily: "Gotham-Bold" }}
    >
      <Row justify="center">
        <StringInput
          value={name}
          onChange={({ target: { value } }) => setName(value)}
        />
      </Row>
      <Row justify="center" style={{ marginTop: "1rem" }}>
        <Button
          type="secondary"
          onClick={onClose}
          size="regular"
          style={{ marginRight: "0.5rem", fontFamily: "Gotham-Bold" }}
        >
          {CancelLabel}
        </Button>
        <Button
          type="primary"
          onClick={onChangeName}
          size="regular"
          style={{ fontFamily: "Gotham-Bold" }}
        >
          {SaveLabel}
        </Button>
      </Row>
    </Modal>
  );
};

export default ChangeNameModal;
