import React from "react";
import { IconContainer, RedDot } from "./styles";
import { AiFillBell, AiOutlineBell } from "react-icons/ai";
import { Row } from "antd";

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  fill?: boolean;
  dot?: boolean;
}

const BellIcon: React.FC<IconProps> = ({ onClick, fill, dot, ...props }) => {
  const Icon = fill ? AiFillBell : AiOutlineBell;
  return (
    <IconContainer onClick={onClick} size={20} {...props}>
      <Row align="top">
        <Icon color={fill ? "#0273E9" : "#48505E"} size={16} />
        {dot && <RedDot />}
      </Row>
    </IconContainer>
  );
};

export default BellIcon;
