import React from "react";
import { IconContainer } from "./styles";
import { AiOutlineUser } from "react-icons/ai";
import UserFilled from "../../resources/img/UserIconFilled.svg";

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  filled?: boolean;
}

const UserIcon: React.FC<IconProps> = ({ filled, ...props }) => (
  <IconContainer size={20} {...props}>
    {!filled ? (
      <AiOutlineUser color="#48505E" size={20} />
    ) : (
      <img
        src={UserFilled}
        alt="UserFilled"
        style={{
          width: "14px",
          height: "14px",
        }}
      />
    )}
  </IconContainer>
);

export default UserIcon;
