import React, { useRef, useState, useEffect } from "react";
import { Popover } from "@material-ui/core";
import { AiOutlinePlus } from "react-icons/ai";
import { Row, Space } from "antd";
import _ from "lodash";
import {
  ImportColumnPopupProps,
  GetImportableColumnsResponse,
  SelectedValues,
  ColumnType,
} from "./ImportColumnPopup.d";
import DataTypeIcons from "../../SharedComponents/DataTypeIcons";
import { Button } from "../../../../GeestUI";
import { CloseIcon } from "../../../../components/hoverIcons";
import { useFetch, useMutation } from "../../../../hooks";
import styled from "styled-components";
import GeestSelect from "../../../../GeestUI/GeestSelect";
import Tooltip from "../../../../components/Tooltip";

const defaultSelectedValues: SelectedValues = {
  IdProcessTemplate: "",
  IdFieldTemplate: "",
  IdVarDBColumn: "",
  IdVarDB: "",
  IdDefaultField: "",
};

const Title = styled.div`
  font-size: 16px;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

const ColumnOptionTypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const ColumnTypeOption = styled.div<{ $selected: boolean }>`
  text-align: center;
  font-family: Gotham-Bold;
  color: rgba(72, 80, 94, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 5px;
  border-radius: 6px;
  height: 32px;
  cursor: pointer;
  background-color: ${({ $selected }) =>
    $selected ? "rgba(242, 248, 254, 1)" : ""};
  :hover {
    background-color: ${({ $selected }) =>
      $selected ? "rgba(242, 248, 254, 1)" : "rgba(237, 236, 236, 1)"};
  }
`;

const ImportColumnPopup: React.FC<ImportColumnPopupProps> = ({
  open,
  IdTeam,
  onOpen,
  onSuccess,
  onCancel,
  IdReport,
  CanAddDynamicColumns,
}) => {
  const popoverRef = useRef(null);
  const [selectedValues, setSelectedValues] = useState<SelectedValues>(
    defaultSelectedValues
  );
  const [columnType, setColumnType] = useState<ColumnType>("Field");

  const { data, loading, reload } = useFetch<GetImportableColumnsResponse>({
    func: "Ver2-Pipeline-gic",
    args: { IdTeam },
    conf: { skip: !IdTeam },
  });

  useEffect(() => {
    if (IdTeam) {
      reload();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IdTeam]);

  const [importColumn, importingColumn] = useMutation<[]>({
    func: "Ver2-Pipeline-ic",
    onSuccess: () => onSuccess(),
  });

  const fieldColumnsOptions = (() => {
    if (columnType === "Field") {
      if (selectedValues.IdProcessTemplate) {
        return (
          _.find(data?.Fields, {
            IdProcessTemplate: Number(selectedValues.IdProcessTemplate),
          })?.AllowFieldsToImport?.map(
            ({ IdFieldTemplate, Label, DataType }) => ({
              value: IdFieldTemplate,
              label: (
                <Row align="middle">
                  <Row
                    align="middle"
                    justify="center"
                    style={{
                      width: "26px",
                      height: "26px",
                      display: "flex",
                    }}
                    wrap={false}
                  >
                    <img
                      style={{
                        width: "14px",
                        height: "14px",
                        ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
                      }}
                      alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
                      src={DataTypeIcons[DataType?.toLowerCase()].icon}
                    />
                  </Row>
                  <div
                    style={{
                      flex: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {Label}
                  </div>
                </Row>
              ),
              searchableValues: Label,
            })
          ) || []
        );
      }
    }
    if (columnType === "VarDBColumn") {
      if (selectedValues.IdVarDB) {
        return (
          _.find(data?.VarDBColumns, {
            IdVarDB: Number(selectedValues.IdVarDB),
          })?.AllowColumnsToImport?.map(
            ({ IdVarDBColumn, Label, DataType }) => ({
              value: IdVarDBColumn,
              label: (
                <Row align="middle">
                  <Row
                    align="middle"
                    justify="center"
                    style={{
                      width: "26px",
                      height: "26px",
                      display: "flex",
                    }}
                    wrap={false}
                  >
                    <img
                      style={{
                        width: "14px",
                        height: "14px",
                        ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
                      }}
                      alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
                      src={DataTypeIcons[DataType?.toLowerCase()].icon}
                    />
                  </Row>
                  <div
                    style={{
                      flex: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {Label}
                  </div>
                </Row>
              ),
              searchableValues: Label,
            })
          ) || []
        );
      }
    }
    if (columnType === "DefaultField") {
      return (
        data?.DefaultFields.map(({ Id, Label, DataType }) => ({
          value: Id,
          label: (
            <Row align="middle">
              <Row
                align="middle"
                justify="center"
                style={{
                  width: "26px",
                  height: "26px",
                  display: "flex",
                }}
                wrap={false}
              >
                <img
                  style={{
                    width: "14px",
                    height: "14px",
                    ...DataTypeIcons[DataType?.toLowerCase()].extraStyles,
                  }}
                  alt={DataTypeIcons[DataType?.toLowerCase()]?.label || ""}
                  src={DataTypeIcons[DataType?.toLowerCase()].icon}
                />
              </Row>
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {Label}
              </div>
            </Row>
          ),
          searchableValues: Label,
        })) || []
      );
    }
    return [];
  })();

  const onChangeProcessVarDB = (value: string): void => {
    if (columnType === "Field") {
      setSelectedValues({
        ...selectedValues,
        IdProcessTemplate: value,
        IdFieldTemplate: "",
      });
    }
    if (columnType === "VarDBColumn") {
      setSelectedValues({
        ...selectedValues,
        IdVarDB: value,
        IdVarDBColumn: "",
      });
    }
  };

  const onChangeColumn = (value: string): void => {
    if (columnType === "Field") {
      setSelectedValues({ ...selectedValues, IdFieldTemplate: value });
    }
    if (columnType === "VarDBColumn") {
      setSelectedValues({ ...selectedValues, IdVarDBColumn: value });
    }
    if (columnType === "DefaultField") {
      setSelectedValues({ ...selectedValues, IdDefaultField: value });
    }
  };

  const onSubmit = (): void => {
    const { IdFieldTemplate, IdVarDBColumn, IdDefaultField } = selectedValues;
    let args: any = { IdTeam, IdReport };
    if (columnType === "Field") {
      args = { ...args, IdFieldTemplate };
    }
    if (columnType === "VarDBColumn") {
      args = { ...args, IdVarDBColumn };
    }
    if (columnType === "DefaultField") {
      args = { ...args, IdDefaultField };
    }
    importColumn({ args });
  };

  const processVarDBOptions = (() => {
    if (columnType === "Field") {
      return (
        data?.Fields?.map(({ IdProcessTemplate, ProcessName }) => ({
          label: ProcessName,
          value: IdProcessTemplate,
        })) || []
      );
    }
    if (columnType === "VarDBColumn") {
      return (
        data?.VarDBColumns?.map(({ IdVarDB, DataBaseName }) => ({
          label: DataBaseName,
          value: IdVarDB,
        })) || []
      );
    }
    return [];
  })();

  const processVarDBPlaceHolder = (() => {
    if (columnType === "Field") {
      return "Selecciona un proceso...";
    }
    if (columnType === "VarDBColumn") {
      return "Selecciona una base datos...";
    }
    return "";
  })();

  const columnPlaceholder = (() => {
    if (columnType === "Field") {
      return "Selecciona el campo...";
    }
    if (columnType === "VarDBColumn") {
      return "Selecciona la columna...";
    }
    if (columnType === "DefaultField") {
      return "Selecciona la información...";
    }
    return "";
  })();

  const processVarDBValue = (() => {
    if (columnType === "Field") {
      return selectedValues.IdProcessTemplate;
    }
    if (columnType === "VarDBColumn") {
      return selectedValues.IdVarDB;
    }
  })();

  const columnValue = (() => {
    if (columnType === "Field") {
      return selectedValues.IdFieldTemplate;
    }
    if (columnType === "VarDBColumn") {
      return selectedValues.IdVarDBColumn;
    }
    if (columnType === "DefaultField") {
      return selectedValues.IdDefaultField;
    }
  })();

  const disableSubmit = (() => {
    if (columnType === "Field") {
      return !selectedValues.IdFieldTemplate;
    }
    if (columnType === "VarDBColumn") {
      return !selectedValues.IdVarDBColumn;
    }
    if (columnType === "DefaultField") {
      return !selectedValues.IdDefaultField;
    }
    return true;
  })();

  return (
    <>
      <Popover
        open={open}
        anchorEl={popoverRef.current}
        onClose={() => onCancel()}
        PaperProps={{
          style: {
            borderRadius: "10px",
            width: "433px",
            padding: "12px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div>
          <Row justify="space-between" align="top">
            <Title>Agregar columna</Title>
            <CloseIcon onClick={() => onCancel()} />
          </Row>
          <ColumnOptionTypesContainer>
            <Tooltip
              mui
              title="Mostar campo de formulario"
              placement="bottomRight"
            >
              <ColumnTypeOption
                $selected={columnType === "Field"}
                onClick={() => setColumnType("Field")}
              >
                Campo de formulario
              </ColumnTypeOption>
            </Tooltip>
            <Tooltip
              mui
              title="Mostar columna de base de datos"
              placement="bottomRight"
            >
              <ColumnTypeOption
                $selected={columnType === "VarDBColumn"}
                onClick={() => setColumnType("VarDBColumn")}
              >
                Base de datos
              </ColumnTypeOption>
            </Tooltip>
            <Tooltip
              mui
              title="Mostar información del proceso"
              placement="bottomRight"
            >
              <ColumnTypeOption
                $selected={columnType === "DefaultField"}
                onClick={() => setColumnType("DefaultField")}
              >
                Info del proceso
              </ColumnTypeOption>
            </Tooltip>
          </ColumnOptionTypesContainer>
          <Space
            direction="vertical"
            size={14}
            style={{ width: "100%", margin: "1rem 0" }}
          >
            {columnType !== "DefaultField" && (
              <GeestSelect
                value={processVarDBValue}
                onChange={onChangeProcessVarDB}
                options={processVarDBOptions}
                placeholderSelect={processVarDBPlaceHolder}
              />
            )}
            {!_.isEmpty(fieldColumnsOptions) && (
              <GeestSelect
                value={columnValue}
                onChange={onChangeColumn}
                options={fieldColumnsOptions}
                placeholderSelect={columnPlaceholder}
              />
            )}
          </Space>
          <ButtonContainer>
            <Button
              type="secondary"
              loading={loading || importingColumn}
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              loading={loading || importingColumn}
              disabled={disableSubmit}
              onClick={onSubmit}
            >
              Agregar
            </Button>
          </ButtonContainer>
        </div>
      </Popover>
      <div ref={popoverRef}>
        <Button
          type="primary"
          style={{ width: "185px" }}
          Icon={AiOutlinePlus}
          onClick={onOpen}
          disabled={!CanAddDynamicColumns}
        >
          Agregar columna
        </Button>
      </div>
    </>
  );
};

export default ImportColumnPopup;
