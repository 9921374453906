import React, { useState, useEffect } from "react";
import {
  FieldConfigWrapper,
  StyledSwitch,
  SubTitle,
  SwitchContainer,
  SwitchItem,
  AdvancedConfigurationContainer,
  AdvancedConfSwitchContainer,
  AdvancedConfDescription,
} from "../../Styles";
import GeestSelect from "../../../../../../../GeestUI/GeestSelect";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../../../../../hooks";
import {
  VarDBRelationship,
  GetImportableVarDBsResponse,
} from "../../EditField.d";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";

interface VarDBRowConfigProps {
  IdField: number | null;
  disabled: boolean;
  idVarDB: number | null;
  setIdVarDB: (id: number | null) => void;
  varDBRelationship: VarDBRelationship;
  setVarDBRelationship: (newRelation: VarDBRelationship) => void;
  FieldLabel: string;
}

const VarDBRowConfig: React.FC<VarDBRowConfigProps> = ({
  IdField,
  disabled,
  idVarDB,
  setIdVarDB,
  varDBRelationship,
  setVarDBRelationship,
  FieldLabel,
}) => {
  const [vardbOptions, setVarDBOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [showAdvancedConf, setShowAdvancedConf] = useState<boolean>(false);
  const [filterDataOrigin, setFilterDataOrigin] = useState<boolean>(false);
  const [autoFill, setAutoFill] = useState<boolean>(false);

  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();

  const { data } = useFetch<GetImportableVarDBsResponse>({
    func: "Ver2-Vardbs-gtiv",
    args: { IdTeam, IdProcessTemplate },
    onSuccess: ({ ImportableVarDBs }) => {
      const newOptions = ImportableVarDBs.map((db) => {
        return { value: db.IdVardb, label: db.Title };
      });
      setVarDBOptions(newOptions);
    },
  });

  useEffect(() => {
    setFilterDataOrigin(!!varDBRelationship.FilterDataOrigin);
    setAutoFill(!!varDBRelationship.AutoFill);
    setShowAdvancedConf(
      !!varDBRelationship.AutoFill || !!varDBRelationship.FilterDataOrigin
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const importableVarDBGroup = data?.ImportableVardbGroups?.find(
    ({ IdVarDB }) => IdVarDB === idVarDB
  );

  const importableGroupOptions =
    importableVarDBGroup?.FieldVardbGroups?.map(({ Label, IdVarDBGroup }) => ({
      label: Label,
      value: IdVarDBGroup,
    })) || [];

  return (
    <>
      <FieldConfigWrapper>
        <SubTitle>
          {IdField === 0 ? "Bases de datos" : "Base de datos"}
        </SubTitle>
        <GeestSelect
          value={idVarDB}
          onChange={setIdVarDB}
          options={vardbOptions}
          disabled={disabled || IdField !== 0}
          valueNecesary
        />
      </FieldConfigWrapper>
      <SwitchContainer>
        <SwitchItem>
          <StyledSwitch
            checked={varDBRelationship.CanUseExistingRows}
            onChange={(CanUseExistingRows) =>
              setVarDBRelationship({ ...varDBRelationship, CanUseExistingRows })
            }
            disabled={disabled}
          />
          <SubTitle>Usar registros existentes</SubTitle>
        </SwitchItem>
        <SwitchItem>
          <StyledSwitch
            checked={varDBRelationship.CanAddNewRows}
            onChange={(CanAddNewRows) =>
              setVarDBRelationship({ ...varDBRelationship, CanAddNewRows })
            }
            disabled={disabled}
          />
          <SubTitle>Insertar nuevos registros</SubTitle>
        </SwitchItem>
      </SwitchContainer>
      {!!importableVarDBGroup && varDBRelationship.CanUseExistingRows && (
        <>
          <AdvancedConfigurationContainer
            onClick={() => setShowAdvancedConf(!showAdvancedConf)}
          >
            {showAdvancedConf ? (
              <IoMdArrowDropdown size={23} />
            ) : (
              <IoMdArrowDropright size={23} />
            )}
            Configuración avanzada de relaciones
          </AdvancedConfigurationContainer>
          {showAdvancedConf && (
            <>
              <AdvancedConfSwitchContainer>
                <StyledSwitch
                  checked={filterDataOrigin}
                  onChange={(val) => {
                    if (!val) {
                      setVarDBRelationship({
                        ...varDBRelationship,
                        FilterDataOrigin: null,
                      });
                    }
                    setFilterDataOrigin(val);
                  }}
                  disabled={disabled}
                />
                Filtra opciones respecto a otra base de datos
              </AdvancedConfSwitchContainer>
              {filterDataOrigin && (
                <AdvancedConfDescription>
                  Cuando
                  <GeestSelect
                    $width="211px"
                    value={varDBRelationship.FilterDataOrigin}
                    onChange={(val) => {
                      setVarDBRelationship({
                        ...varDBRelationship,
                        FilterDataOrigin: val,
                      });
                    }}
                    valueNecesary
                    options={importableGroupOptions}
                  />
                  <div>sea llenado, </div> las opciones de la fila ({FieldLabel}
                  ) se prefiltrarán
                </AdvancedConfDescription>
              )}
              <AdvancedConfSwitchContainer>
                <StyledSwitch
                  checked={autoFill}
                  onChange={(val) => {
                    if (!val) {
                      setVarDBRelationship({
                        ...varDBRelationship,
                        AutoFill: null,
                      });
                    }
                    setAutoFill(val);
                  }}
                  disabled={disabled}
                />
                Llenar valor de la fila
              </AdvancedConfSwitchContainer>
              {autoFill && (
                <AdvancedConfDescription>
                  Cuando
                  <GeestSelect
                    $width="211px"
                    value={varDBRelationship.AutoFill}
                    onChange={(val) => {
                      setVarDBRelationship({
                        ...varDBRelationship,
                        AutoFill: val,
                      });
                    }}
                    valueNecesary
                    options={importableGroupOptions}
                  />
                  <div>sea llenado, </div> llenar la fila ({FieldLabel}) con el
                  primer valor encontrado si está vacía
                </AdvancedConfDescription>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default VarDBRowConfig;
