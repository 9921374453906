import React from "react";
import TypeIcons from "../TypeIcons";
import { languages } from "./dicitonary";
import { NodeType } from "../PDFConfig.d";
import { NodeBase } from "./styles";

interface NodeProps {
  nodeType: NodeType;
  dropNewNode: (e: any, nodeType: NodeType) => void;
}

const Node: React.FC<NodeProps> = ({ nodeType, dropNewNode }) => {
  const { TextElementName, ImageElementName, SignElementName } =
    languages["ESP"];

  const typeIcon = TypeIcons[nodeType];

  if (nodeType === "text") {
    return (
      <NodeBase
        id="textNode"
        draggable
        onDragEnd={(e) => dropNewNode(e, "text")}
      >
        <img src={typeIcon.icon} style={{ ...typeIcon.extraStyles }} alt="" />
        <p>{TextElementName}</p>
      </NodeBase>
    );
  }

  if (nodeType === "image") {
    return (
      <NodeBase
        id="imageNode"
        draggable
        onDragEnd={(e) => dropNewNode(e, "image")}
      >
        <img src={typeIcon.icon} style={{ ...typeIcon.extraStyles }} alt="" />
        <p>{ImageElementName}</p>
      </NodeBase>
    );
  }

  if (nodeType === "sign") {
    return (
      <NodeBase
        id="signNode"
        draggable
        onDragEnd={(e) => dropNewNode(e, "sign")}
      >
        <img src={typeIcon.icon} style={{ ...typeIcon.extraStyles }} alt="" />
        <p>{SignElementName}</p>
      </NodeBase>
    );
  }

  return (
    <NodeBase draggable onDragEnd={(e) => dropNewNode(e, "text")}>
      <p>{TextElementName}</p>
    </NodeBase>
  );
};

export default Node;
