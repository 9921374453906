import React from "react";
import { IconContainer } from "./styles";
import { HiCheckCircle } from "react-icons/hi";

const iconSize: { [key: number]: number } = {
  20: 14,
  26: 18,
  32: 22,
};

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 20 | 26 | 32;
  filled?: boolean;
  fillColor?: string;
  containerSize?: number;
  customIconSize?: number;
}

const ConfirmIcon: React.FC<IconProps> = ({ size = 26, ...props }) => (
  <IconContainer size={size} {...props}>
    <HiCheckCircle color="#0273E9" size={iconSize[size]} />
  </IconContainer>
);

export default ConfirmIcon;
