import { DropResult } from "react-beautiful-dnd";
import { ConsultField, EditableField, Field } from "../../Configurator.d";

export interface TaskFieldsProps {
  nodeId: string;
  onClose: () => void;
}
export interface GetTemplateTaskFields {
  TaskName: string;
  GlobalFields: Field[];
  ConsultFields?: ConsultField[];
  EditableFields?: EditableField[];
}

interface AllFields {
  globalFields: Field[];
  consultFields: ConsultField[];
  editableFields: EditableField[];
}

interface HandleDragAndDropResult {
  allFields: AllFields;
  succeeded: boolean;
}

export type HandleDragAndDrop = (
  dropResult: DropResult,
  allFields: AllFields,
  triggersMode?: boolean
) => HandleDragAndDropResult;

interface ApiInsertField {
  IdField: number;
  FillAtStart: boolean;
  IsEditable: boolean;
  IsMandatory: boolean;
  Position: number;
}

interface ApiUpdateField extends ApiInsertField {
  LastPosition: number;
  ChangeOfColumn: boolean;
  IdFieldForm: number;
}

export enum DragAndDropAction {
  INSERT = "insert",
  UPDATED = "updated",
  NONE = "none",
}

interface DragAndDropToApiResult {
  action: DragAndDropAction;
  field?: ApiInsertField | ApiUpdateField;
}

export type DragAndDropToApi = (
  dropResult: DropResult,
  allFields: AllFields
) => DragAndDropToApiResult;

export type BuildInsertedFieldFromDragAndDrop = (
  dropResult: DropResult,
  allFields: AllFields
) => ApiInsertField;

export type BuildUpdatedFieldFromDragAndDrop = (
  dropResult: DropResult,
  allFields: AllFields
) => ApiUpdateField;

export interface OnChangeMandatoryParams {
  field: Field;
  editableField: EditableField;
  position: number;
}

export type BuildApiUpdatedFieldFromEditableFields = (
  fieldData: OnChangeMandatoryParams
) => ApiUpdateField;
