import styled from "styled-components";
import { Row } from "antd";
import ReloadFile from "../../../resources/img/RELOADFILE.svg";
import ReloadFileHover from "../../../resources/img/RELOADFILE_HOVER.svg";

export const ChatCont = styled.div`
  padding: 3px;
  width: fit-content;
  border-radius: 50%;
  transition: all 0.5s ease-out;
  :hover {
    background-color: #c2c2c289;
  }
`;

export const ReloadFileButton = styled.div`
  height: 5rem;
  width: 5rem;
  background-image: url(${ReloadFile});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: 0.2s all;
  :hover {
    background-image: url(${ReloadFileHover});
  }
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

export const ErrorContainer = styled.div`
  font-family: Gotham-Book;
  color: grey;
  text-align: center;
  font-size: 20px;
`;

export const RowCont = styled.div`
  width: 96%;
  min-width: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px 7px rgb(245 245 245);
  margin-left: 0.6rem;
  margin-top: 0.5rem;
  border: #ededed 1px solid;
`;

export const TitleRowCont = styled(Row)`
  color: #484848;
  font-family: "Gotham-Bold";
  font-size: 15px;
  background: #f3f3f3;
  padding: 0.8rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  justify-content: space-around;
`;

export const TitleCont = styled.div`
  width: 12rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #a3a3a3;
  font-weight: 100;
`;
