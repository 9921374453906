import styled from "styled-components";

export const StyledInput = styled.input`
  border: 2px solid rgba(237, 236, 236, 1);
  color: rgba(130, 141, 158, 1);
  border-radius: 6px;
  padding-left: 6px;
  width: 384px;
  height: 32px;
  font-size: 14px;
  font-weight: 300;
  font-family: Gotham-Book;
  margin-bottom: 1.2rem;
  margin-top: 0.5rem;
  :hover {
    border-color: #0273e9;
  }
  :focus {
    border-color: #48505e;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
