import React from "react";
import { Languages } from "../Dictionary";
import { FolderType } from "../DataBases.d";
import {
  DatabaseTeamContainer,
  HeaderContainer,
  NestedFolder,
  NestedFoldersWrapper,
  SelectedFolderContainer,
} from "./Styles";
import { AiOutlineFolder, AiOutlineTeam } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { useHistory } from "react-router-dom";

interface HeaderProps {
  selectedFolder: FolderType;
  nestedFolders: FolderType[];
  setSelectedFolder: (folder: FolderType) => void;
  setNestedFolders: (folders: FolderType[]) => void;
  getUserVarDBs: (params: { args?: any }) => void;
}

const Header: React.FC<HeaderProps> = ({
  selectedFolder,
  nestedFolders,
  setSelectedFolder,
  setNestedFolders,
  getUserVarDBs,
}) => {
  const { DataBasesLabel } = Languages["ESP"];
  const history = useHistory();

  const onGoBack = () => {
    history.push("/home/databases");
  };

  const onReturnToPreviousFolder = (folder: FolderType, idx: number) => {
    let nestedFoldersAux = [...nestedFolders];
    nestedFoldersAux = nestedFoldersAux.slice(0, idx + 1);
    setNestedFolders(nestedFoldersAux);
    getUserVarDBs({
      args: {
        IdParentFolder: folder.IdVardbFolder,
      },
    });
    setSelectedFolder(folder);
    history.push({
      pathname: `/home/database/folder/${folder.IdVardbFolder}`,
      state: folder,
    });
  };

  return (
    <HeaderContainer>
      <DatabaseTeamContainer onClick={onGoBack}>
        <p>{DataBasesLabel}</p>
        <AiOutlineTeam size={20} />
        <p>{selectedFolder.TeamName}</p>
      </DatabaseTeamContainer>

      {nestedFolders.map((folder, idx) => {
        return (
          <NestedFoldersWrapper key={folder.IdVardbFolder}>
            <IoIosArrowForward size={20} color="rgba(130, 141, 158, 1)" />

            {folder.IdVardbFolder === selectedFolder.IdVardbFolder ? (
              <SelectedFolderContainer>
                <AiOutlineFolder size={20} />
                {folder.Title}
              </SelectedFolderContainer>
            ) : (
              <NestedFolder
                onClick={() => onReturnToPreviousFolder(folder, idx)}
              >
                <AiOutlineFolder size={20} />
                {folder.Title}
              </NestedFolder>
            )}
          </NestedFoldersWrapper>
        );
      })}
    </HeaderContainer>
  );
};

export default Header;
