export const Languages = {
  ENG: {
    titleLabel: "Geest Premium is required to export databases in Excel",
    memberLabel: "Notify your company leader to change subscription plan.",
    leaderLabel: "Hire Geest for you can access this function.",
    cancelLabel: "Cancel",
    hireLabel: "Hire",
  },
  ESP: {
    titleLabel:
      "Se requiere Geest Premium para que puedas exportar bases de datos a Excel",
    memberLabel:
      "Notifica a tu líder de empresa para que cambie el plan de suscripción.",
    leaderLabel: "Contrata Geest para que puedas acceder a esta función.",
    cancelLabel: "Cancelar",
    hireLabel: "Contratar",
  },
};
