import React from "react";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import { Option } from "../DynamicFilter.d";

interface SelectFilterProps {
  DataOrigin: string;
  onChangeFilter: (value: (string | number | undefined | null)[]) => void;
  value: string | number | undefined | null;
  defaultOpions?: Option[];
  placeholder: string;
}

const SelectFilter: React.FC<SelectFilterProps> = ({
  DataOrigin,
  onChangeFilter,
  value,
  defaultOpions,
  placeholder,
}) => {
  const options =
    defaultOpions ??
    DataOrigin.split(",").map((option) => ({
      label: option,
      value: option,
    }));
  return (
    <GeestSelect
      options={options}
      onChange={onChangeFilter}
      value={value}
      $width="285px"
      placeholderSelect={placeholder}
    />
  );
};

export default SelectFilter;
