import React, { useState } from "react";
import { convertToRaw } from "draft-js";
import { FieldBlockWrapper, FieldContainer, IconContainer } from "./Styles";
import SelectFieldPopover from "./SelectFieldPopover";
import { languages } from "./dictionary";
import { FieldEditorOrigin } from "../../../../GeestTextEditor.d";
import DataTypeIcons from "./DataTypeIcons";

interface FieldBlockProps {
  fieldEditorOrigin: FieldEditorOrigin[];
  setFieldPopoverOpen: (open: boolean) => void;
  readOnly: boolean;
  contentState: any;
  decoratedText: string;
  entityKey: string;
  fieldsPopoverStyles: React.CSSProperties;
}

const FieldBlock: React.FC<FieldBlockProps> = ({
  fieldEditorOrigin,
  setFieldPopoverOpen,
  readOnly,
  contentState,
  decoratedText,
  entityKey,
  fieldsPopoverStyles,
}) => {
  const [selectedIdField, setSelectedIdField] = useState<
    number | string | null
  >(getCurrentSelected());
  const [selectedField, setSelectedField] = useState<FieldEditorOrigin | null>(
    getPreSelectedField()
  );
  const [fieldPopover, setFieldPopover] = useState<HTMLButtonElement | null>(
    null
  );

  const { FieldEntityPlaceholder } = languages["ESP"];

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (readOnly) return;
    setFieldPopover(event.currentTarget);
    setFieldPopoverOpen(true);
  };

  const closePopover = () => {
    setFieldPopover(null);
    setFieldPopoverOpen(false);
  };

  const replaceEntityData = (field: FieldEditorOrigin | null) => {
    if (field !== null && entityKey) {
      contentState.mergeEntityData(entityKey, {
        IdFieldSelected: field?.IdField ?? null,
        DataType: field?.DataType ?? "",
        FieldLabel: field?.Label ?? "",
      });
    } else if (entityKey) {
      contentState.mergeEntityData(entityKey, {
        IdFieldSelected: null,
        DataType: "",
        FieldLabel: "",
      });
    }
  };

  const onSelectNewIdField = (newSelectedField: number | string | null) => {
    setSelectedIdField(newSelectedField);

    if (newSelectedField !== null) {
      const fieldIdx = getFieldOriginIndex(newSelectedField);
      if (fieldIdx !== -1) {
        setSelectedField(fieldEditorOrigin[fieldIdx]);
      }
      replaceEntityData(fieldEditorOrigin[fieldIdx]);
      return;
    }
    replaceEntityData(null);
    setSelectedField(null);
  };

  function getFieldOriginIndex(id: string | number): number {
    return fieldEditorOrigin.findIndex(
      ({ IdField }) => String(IdField) === String(id)
    );
  }

  function getPreSelectedField(): FieldEditorOrigin | null {
    const fieldId = getCurrentSelected();
    if (fieldId !== null) {
      const fieldIdx = getFieldOriginIndex(fieldId);
      if (fieldIdx !== -1) {
        return fieldEditorOrigin[fieldIdx];
      }
    }
    return null;
  }

  function getCurrentSelected(): string | number | null {
    const FIELD_REGEX = /\[\[\|\s(FIELD:(null|.*?))\s\|\]\]/g;
    let match = FIELD_REGEX.exec(decoratedText);

    let selected: string | null = null;

    while (match != null) {
      if (match[2]) {
        selected = match[2];
      }
      match = FIELD_REGEX.exec(decoratedText);
    }

    let fieldId = null;
    const entities = convertToRaw(contentState).entityMap;
    Object.values(entities).forEach((entity) => {
      if (
        entity.type === "FIELD" &&
        selected !== null &&
        String(entity.data.IdGeestField) === String(selected)
      ) {
        fieldId = entity.data.IdFieldSelected;
      }
    });
    const validId = fieldId !== null && getFieldOriginIndex(fieldId) !== -1;
    return validId ? fieldId : null;
  }

  return (
    <FieldBlockWrapper>
      <FieldContainer $hasField={Boolean(selectedField)} onClick={openPopover}>
        {selectedField !== null ? (
          <>
            {DataTypeIcons[selectedField.DataType] && (
              <IconContainer>
                <img
                  src={DataTypeIcons[selectedField.DataType].icon}
                  alt=""
                  style={{
                    ...DataTypeIcons[selectedField.DataType]?.extraStyles,
                    marginBottom: "3px",
                  }}
                />
              </IconContainer>
            )}
            <span>{selectedField.Label}</span>
          </>
        ) : (
          <span>{FieldEntityPlaceholder}</span>
        )}
      </FieldContainer>

      <SelectFieldPopover
        anchorEl={fieldPopover}
        fieldEditorOrigin={fieldEditorOrigin}
        selectedIdField={selectedIdField}
        fieldsPopoverStyles={fieldsPopoverStyles}
        onSelectNewIdField={onSelectNewIdField}
        onClose={closePopover}
      />
    </FieldBlockWrapper>
  );
};

export default FieldBlock;
