import React from "react";
import { Row } from "antd";
import { Typography } from "../../../../GeestUI";
import { UserContainer, UserInitials, UserProfilePic } from "./Style";
import Tooltip from "../../../../components/Tooltip";

const { P } = Typography;

interface UserType {
  IdUser: number;
  Email: string;
  Initials: string;
  FirstName: string;
  LastName: string;
  ProfilePicture?: string;
}

interface UserProps {
  user: UserType;
  size: string;
  nameColor?: string;
  tooltipValue?: string;
  justify?:
    | "end"
    | "start"
    | "center"
    | "space-around"
    | "space-between"
    | undefined;
  placement?:
    | "topLeft"
    | "top"
    | "topRight"
    | "leftTop"
    | "left"
    | "leftBottom"
    | "rightTop"
    | "right"
    | "rightBottom"
    | "bottomLeft"
    | "bottom"
    | "bottomRight";
}

const User: React.FC<UserProps> = ({
  user,
  size,
  nameColor = "#727e8b",
  tooltipValue = "",
  justify = "end",
  placement = "bottom",
}) => {
  switch (size) {
    case "small":
      return (
        <UserContainer justify={justify}>
          <Tooltip
            title={
              tooltipValue
                ? tooltipValue
                : `${user.FirstName} ${user.LastName} (${user.Email})`
            }
            placement={placement}
          >
            {!user.ProfilePicture ? (
              <UserInitials align="middle" justify="center">
                {user.Initials}
              </UserInitials>
            ) : (
              <UserProfilePic
                image={user.ProfilePicture}
                align="middle"
                justify="center"
              />
            )}
          </Tooltip>
        </UserContainer>
      );
    case "middle":
      return (
        <UserContainer justify={justify}>
          <Tooltip
            title={tooltipValue ? tooltipValue : user.Email}
            placement={placement}
          >
            <Row justify={justify} align="middle" style={{ width: "100%" }}>
              {!user.ProfilePicture ? (
                <UserInitials align="middle" justify="center">
                  {user.Initials}
                </UserInitials>
              ) : (
                <UserProfilePic
                  image={user.ProfilePicture}
                  align="middle"
                  justify="center"
                />
              )}
              <p className="user-fullname">{`${user.FirstName} ${user.LastName}`}</p>
            </Row>
          </Tooltip>
        </UserContainer>
      );
    case "large":
    default:
      return (
        <UserContainer justify={justify} align="middle">
          {!user.ProfilePicture ? (
            <UserInitials align="middle" justify="center">
              {user.Initials}
            </UserInitials>
          ) : (
            <UserProfilePic
              image={user.ProfilePicture}
              align="middle"
              justify="center"
            />
          )}
          <div>
            <P
              mb="0"
              style={{ color: nameColor }}
            >{`${user.FirstName} ${user.LastName}`}</P>
            <P mb="0" style={{ color: nameColor, fontSize: "12px" }}>
              {user.Email}
            </P>
          </div>
        </UserContainer>
      );
  }
};

export default User;
