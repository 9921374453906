import styled from "styled-components";

export const ReportsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  height: 100%;
  overflow: auto;

  p {
    margin: 0;
  }
  pre {
    border: 1px solid #222;
  }
`;

export const DataBox = styled.div`
  flex: 1;
  max-height: 100%;
  overflow: auto;
  padding: 10px;
  border-radius: 6px;
  background-color: var(--silver);

  .polkadot {
    height: 100%;
    background-image: radial-gradient(#222 2%, transparent 5%);
    background-size: 25px 25px;
    background-position: 0 0, 30px 30px;
    background-repeat: repeat;
  }
`;

export const GridItemWrapper = styled.div``;

export const TableContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  height: 100%;

  .wrapper {
    height: 100%;
    overflow: auto;
  }
`;
