import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
  overflow: auto;
`;

export const NavigationComponents = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
`;

export const ReportContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
`;

export const ReportContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  font-size: 14px;
`;

export const ReportOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledTab = styled(Link)<{ $active: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  height: 40px;
  font-family: Gotham-Book;
  color: #48505e;
  cursor: pointer;
  font-size: 24px;
  border-radius: 6px;
  padding: 0px 10px;
  background-color: ${({ $active }) => ($active ? "#f2f8fe" : "")};
  &:active {
    background-color: #f2f8fe !important;
  }
  :hover {
    background-color: #edecec;
    color: #48505e;
  }
`;

export const ReportsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
