import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

interface DateFilterProps {
  Value?: {
    Start: string;
    End: string;
  };
  onChange: (filter: any) => void;
}

const DateFilter: React.FC<DateFilterProps> = ({ Value, onChange }) => (
  <RangePicker
    allowClear={false}
    placeholder={["Inicial", "Final"]}
    value={Value ? [moment(Value.Start), moment(Value.End)] : null}
    onChange={(val, values) => onChange({ Start: values[0], End: values[1] })}
    popupStyle={{ zIndex: 1300 }}
    style={{ width: "285px", borderRadius: "6px" }}
  />
);

export default DateFilter;
