import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Languages } from "./Dictionary";
import { StateMachineContext } from "../index";
import {
  Container,
  Content,
  Header,
  SwitchContainer,
  SwitchText,
  StyledSwitch,
  BackArrow,
  TableContainer,
} from "./Style";
import PlansTable from "../../../../components/PlansTable";
import { getPlanColors, planTypes } from "../../helpers";
import { useMutation } from "../../../../hooks";

const ChangePlan = () => {
  const { setCurrentState, CurrentState } = useContext(StateMachineContext);
  const { IdTeam } = useParams();

  const [SwitchPriceType, setSwitchPriceType] = useState("Monthly");
  const [PlansData, setPlansData] = useState({});
  const [activePlan, setActivePlan] = useState(planTypes.IMPULSE);

  const { ChooseYourPlan, Monthly, Yearly } = Languages["ESP"];

  const [getPlans] = useMutation({
    func: "Ver2-Subscription-ChangePlan-gp",
    onSuccess: (response) => {
      setPlansData(response);
      if (response.FrontView === "ChangePlanView") {
        setSwitchPriceType(response.CurrentPlan.BillingMode);
      }
      const auxActive = _.find(response.Plans, { ShowAsSuggested: true });
      setActivePlan(auxActive.Name);
    },
  });

  useEffect(() => {
    getPlans({
      args: {
        IdTeam,
      },
    });
    // eslint-disable-next-line
  }, [CurrentState]);

  const [changeSubscriptionState] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: (response) => {
      const { State } = response;
      setCurrentState(State);
    },
  });

  const onBack = () => {
    changeSubscriptionState({
      args: {
        IdTeam,
        NextState: "Normal",
      },
    });
  };

  const [selectPlan] = useMutation({
    func: "Ver2-Subscription-ChangePlan-sp",
    onSuccess: (response) => {
      const { SubscriptionStateChangeTo } = response;
      setCurrentState(SubscriptionStateChangeTo);
    },
  });

  const onSelectPlan = ({ IdPlan }) => {
    selectPlan({
      args: {
        IdTeam,
        IdPlan,
        BillingMode: SwitchPriceType,
      },
    });
  };

  const handleOnChange = (value) => {
    const values = {
      true: "Annually",
      false: "Monthly",
    };
    setSwitchPriceType(values[value]);
  };

  return (
    <Container>
      <Content>
        <Header>
          <div style={{ display: "flex", alignItems: "center" }}>
            <BackArrow onClick={onBack} />
            {ChooseYourPlan}
          </div>
          <SwitchContainer planColor={getPlanColors(activePlan).mainColor}>
            <SwitchText
              $active={SwitchPriceType === "Monthly"}
              onClick={() => setSwitchPriceType("Monthly")}
            >
              {Monthly}
            </SwitchText>
            <StyledSwitch
              checked={SwitchPriceType === "Annually"}
              onChange={handleOnChange}
            />
            <SwitchText
              $active={SwitchPriceType === "Annually"}
              onClick={() => setSwitchPriceType("Annually")}
            >
              {Yearly}
            </SwitchText>
          </SwitchContainer>
        </Header>
        <TableContainer>
          {!_.isEmpty(PlansData) && (
            <PlansTable
              {...PlansData}
              PriceType={SwitchPriceType}
              onSelectPlan={onSelectPlan}
              activePlan={activePlan}
            />
          )}
        </TableContainer>
      </Content>
    </Container>
  );
};

export default ChangePlan;
