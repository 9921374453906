import React from "react";
import DynamicInput from "../../../../../../../components/DynamicInput";
import { FieldConfigWrapper, SubTitle } from "../../Styles";
import { ElementType } from "../../EditField.d";

interface ChecklistConfigProps {
  checklistElements: ElementType[];
  setChecklistElements: (newElements: ElementType[]) => void;
  disabled: boolean;
}

const ChecklistConfig: React.FC<ChecklistConfigProps> = ({
  checklistElements,
  setChecklistElements,
  disabled,
}) => {
  return (
    <>
      <FieldConfigWrapper>
        <SubTitle>Valor por defecto</SubTitle>
        <DynamicInput
          value={""}
          type="checklist"
          dataOrigin={[]}
          format=""
          configuration=""
          fieldName=""
          isConsult={false}
          disabled={disabled}
          required={false}
          onChange={() => {}}
          checklistParams={{
            elements: checklistElements,
            onChangeChecklist: setChecklistElements,
          }}
        />
      </FieldConfigWrapper>
    </>
  );
};

export default ChecklistConfig;
