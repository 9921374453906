import React, { useCallback, useContext } from "react";
import { Row } from "antd";
import { noop as NOOP } from "lodash";
import styled from "styled-components";
import { SesionContext } from "../../config/State";
import { useHistory } from "react-router-dom";
import { Typography } from "../../GeestUI";
import { IoIosArrowBack } from "react-icons/io";

const Division = styled.div`
  height: 2px;
  background-color: #edecec;

  margin-top: ${({ dividerMT }) => dividerMT};
  margin-bottom: ${({ dividerMB }) => dividerMB};
  width: 100%;
`;

const StyledRow = styled(Row)`
  font-family: "Gotham-Book";
  width: 100%;
`;

const StyledContentRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledInnerContent = styled(Row)`
  flex: 1;
`;

const StyledLogo = styled.img`
  cursor: pointer;
  margin-left: 16px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  // max-height: 3rem;
  // max-width: 20%;
  // border-radius: 5px;
`;

const { H1 } = Typography;

const ViewTitle = ({
  children,
  ShowBack = false,
  onBack = NOOP,
  ShowTeamIcon = false,
  getElement = NOOP,
  headerMB = "0.5rem",
  headerMT = "2px",
  dividerMB = "1rem",
  dividerMT = "0.5rem",
}) => {
  const [
    {
      FavoriteTeam: { TeamLogoUrl, IdFavoriteTeam },
    },
  ] = useContext(SesionContext);
  const history = useHistory();
  const DisplayIcon = ShowTeamIcon && !!TeamLogoUrl;

  const onComponentChange = useCallback(
    (elem) => {
      if (elem) getElement(elem);
    },
    [getElement]
  );

  return (
    <Row align="middle" style={{ marginBottom: headerMB, marginTop: headerMT }}>
      <StyledRow
        justify="space-between"
        align="middle"
        ref={onComponentChange}
        data-testid="viewTitle"
      >
        <StyledContentRow>
          {ShowBack && (
            <IoIosArrowBack
              size={24}
              color="#48505e"
              onClick={onBack}
              style={{ cursor: "pointer" }}
            />
          )}
          <StyledInnerContent $ShowBack={ShowBack} align="middle">
            <H1 mb="0" style={{ width: "100%" }}>
              {children}
            </H1>
          </StyledInnerContent>
        </StyledContentRow>
        {DisplayIcon && (
          <StyledLogo
            src={TeamLogoUrl}
            alt="TeamLogo"
            data-testid="viewTitle--TeamLogo"
            onClick={() => history.push(`/home/teams/${IdFavoriteTeam}`)}
          />
        )}
      </StyledRow>
      <Division dividerMB={dividerMB} dividerMT={dividerMT} />
    </Row>
  );
};

export default ViewTitle;
