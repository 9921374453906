import _ from "lodash";
import { DropResult } from "react-beautiful-dnd";
import {
  ConsultField,
  EditableField,
  Field,
  FieldDataTypes,
  FieldTypes,
} from "../../Configurator.d";
import {
  BuildApiUpdatedFieldFromEditableFields,
  BuildInsertedFieldFromDragAndDrop,
  BuildUpdatedFieldFromDragAndDrop,
  DragAndDropAction,
  DragAndDropToApi,
  HandleDragAndDrop,
} from "./TaskFields.d";
import { ApiTriggerField } from "../NodeEditor/TriggersEditor/TriggerInformation/views/EditTriggerFieldsView.d";

const errorField: Field = {
  IdField: 0,
  DataType: FieldDataTypes.STRING,
  Label: "ERROR: Field not found",
  Type: FieldTypes.MANUAL,
};

export const getFieldFromGlobals = (
  globalFields: Field[],
  IdField: number
): Field => _.find(globalFields, { IdField }) ?? errorField;

export const buildApiUpdatedFieldFromEditableFields: BuildApiUpdatedFieldFromEditableFields =
  ({ field, editableField, position }) => ({
    IdField: field.IdField,
    FillAtStart: false,
    IsEditable: true,
    IsMandatory: editableField.IsMandatory,
    Position: position + 1,
    LastPosition: position + 1,
    ChangeOfColumn: false,
    IdFieldForm: editableField.IdFieldForm,
  });

// Drag And Drop

const fieldExistsInFieldType = (
  IdField: number,
  fields: ConsultField[] | EditableField[]
): boolean => !!_.find(fields, { IdField });

const fieldExistsInFields = (
  IdField: number,
  consultFields: ConsultField[],
  editableFields: EditableField[]
): boolean =>
  fieldExistsInFieldType(IdField, consultFields) ||
  fieldExistsInFieldType(IdField, editableFields);

const isGhostField = (field: Field): boolean => field.Type === FieldTypes.GHOST;
const checkListsNotAllowed = (field: Field, triggersMode: boolean): boolean =>
  triggersMode && field.DataType === FieldDataTypes.CHECKLIST;

const insertIntoFields = <AnyField extends {}>(
  index: number,
  field: AnyField,
  fields: AnyField[]
): AnyField[] => [...fields.slice(0, index), field, ...fields.slice(index)];

const globalToConsultFields = (
  sourceIndex: number,
  destinationIndex: number,
  globalFields: Field[],
  consultFields: ConsultField[],
  editableFields: EditableField[]
): { consultFields: ConsultField[]; succeeded: boolean } => {
  const { IdField } = globalFields[sourceIndex];
  if (fieldExistsInFields(IdField, consultFields, editableFields))
    return { consultFields, succeeded: false };
  const consultField: ConsultField = {
    IdField,
    IdFieldForm: 0,
    IsMandatory: false,
  };
  return {
    consultFields: insertIntoFields(
      destinationIndex,
      consultField,
      consultFields
    ),
    succeeded: true,
  };
};
const globalToEditableFields = (
  sourceIndex: number,
  destinationIndex: number,
  globalFields: Field[],
  editableFields: EditableField[],
  consultFields: ConsultField[],
  triggersMode: boolean
): { editableFields: EditableField[]; succeeded: boolean } => {
  const { IdField } = globalFields[sourceIndex];
  if (
    fieldExistsInFields(IdField, consultFields, editableFields) ||
    isGhostField(globalFields[sourceIndex]) ||
    checkListsNotAllowed(globalFields[sourceIndex], triggersMode)
  )
    return { editableFields, succeeded: false };
  const editableField: EditableField = {
    IdField,
    IdFieldForm: 0,
    IsMandatory: false,
  };
  return {
    editableFields: insertIntoFields(
      destinationIndex,
      editableField,
      editableFields
    ),
    succeeded: true,
  };
};
const consultToEditableFields = (
  sourceIndex: number,
  destinationIndex: number,
  globalFields: Field[],
  consultFields: ConsultField[],
  editableFields: EditableField[]
): {
  editableFields: EditableField[];
  consultFields: ConsultField[];
  succeeded: boolean;
} => {
  const newEditableField: EditableField = { ...consultFields[sourceIndex] };
  const globalField = getFieldFromGlobals(
    globalFields,
    newEditableField.IdField
  );
  if (isGhostField(globalField)) {
    return {
      consultFields,
      editableFields,
      succeeded: false,
    };
  }
  return {
    consultFields: _.without(consultFields, consultFields[sourceIndex]),
    editableFields: insertIntoFields(
      destinationIndex,
      newEditableField,
      editableFields
    ),
    succeeded: true,
  };
};
const editableToConsultFields = (
  sourceIndex: number,
  destinationIndex: number,
  editableFields: EditableField[],
  consultFields: ConsultField[]
): { editableFields: EditableField[]; consultFields: ConsultField[] } => {
  const newConsultField: ConsultField = {
    ...editableFields[sourceIndex],
    IsMandatory: false,
  };
  return {
    editableFields: _.without(editableFields, editableFields[sourceIndex]),
    consultFields: insertIntoFields(
      destinationIndex,
      newConsultField,
      consultFields
    ),
  };
};
const consultToConsultFields = (
  sourceIndex: number,
  destinationIndex: number,
  consultFields: ConsultField[]
): ConsultField[] => {
  let newConsultFields = [...consultFields];
  const [movedField] = _.pullAt(newConsultFields, sourceIndex);
  newConsultFields = insertIntoFields(
    destinationIndex,
    movedField,
    newConsultFields
  );
  return newConsultFields;
};
const editableToEditableFields = (
  sourceIndex: number,
  destinationIndex: number,
  editableFields: EditableField[]
): EditableField[] => {
  let newEditableFields = [...editableFields];
  const [movedField] = _.pullAt(newEditableFields, sourceIndex);
  newEditableFields = insertIntoFields(
    destinationIndex,
    movedField,
    newEditableFields
  );
  return newEditableFields;
};

export const handleDragAndDrop: HandleDragAndDrop = (
  { source, destination },
  allFields,
  triggersMode = false
) => {
  let newFields = allFields;
  let succeeded = false;
  if (destination) {
    switch (source.droppableId) {
      case "globals":
        if (destination.droppableId === "consult") {
          const { consultFields, succeeded: actionSucceeded } =
            globalToConsultFields(
              source.index,
              destination.index,
              allFields.globalFields,
              allFields.consultFields,
              allFields.editableFields
            );
          newFields = {
            ...allFields,
            consultFields,
          };
          succeeded = actionSucceeded;
          break;
        }
        if (destination.droppableId === "editable") {
          const { editableFields, succeeded: actionSucceeded } =
            globalToEditableFields(
              source.index,
              destination.index,
              allFields.globalFields,
              allFields.editableFields,
              allFields.consultFields,
              triggersMode
            );
          newFields = {
            ...allFields,
            editableFields,
          };
          succeeded = actionSucceeded;
          break;
        }
        break;
      case "consult":
        if (destination.droppableId === "editable") {
          const {
            consultFields,
            editableFields,
            succeeded: actionSucceeded,
          } = consultToEditableFields(
            source.index,
            destination.index,
            allFields.globalFields,
            allFields.consultFields,
            allFields.editableFields
          );
          newFields = {
            ...allFields,
            consultFields,
            editableFields,
          };
          succeeded = actionSucceeded;
          break;
        }
        if (
          destination.droppableId === "consult" &&
          source.index !== destination.index
        ) {
          newFields = {
            ...allFields,
            consultFields: consultToConsultFields(
              source.index,
              destination.index,
              allFields.consultFields
            ),
          };
          succeeded = true;
          break;
        }
        break;
      case "editable":
        if (destination.droppableId === "consult") {
          newFields = {
            ...allFields,
            ...editableToConsultFields(
              source.index,
              destination.index,
              allFields.editableFields,
              allFields.consultFields
            ),
          };
          succeeded = true;
          break;
        }
        if (
          destination.droppableId === "editable" &&
          source.index !== destination.index
        ) {
          newFields = {
            ...allFields,
            editableFields: editableToEditableFields(
              source.index,
              destination.index,
              allFields.editableFields
            ),
          };
          succeeded = true;
          break;
        }
        break;

      default:
        break;
    }
  }
  return { allFields: newFields, succeeded };
};

// API

const determineDragAndDropAction = ({
  source,
}: DropResult): DragAndDropAction => {
  switch (source.droppableId) {
    case "globals":
      return DragAndDropAction.INSERT;
    case "consult":
    case "editable":
      return DragAndDropAction.UPDATED;
    default:
      return DragAndDropAction.NONE;
  }
};

const buildInsertedFieldFromDragAndDrop: BuildInsertedFieldFromDragAndDrop = (
  { source, destination },
  { globalFields }
) => {
  const globalField = globalFields[source.index];
  const { index: position, droppableId: destinationColumn } = destination!;
  return {
    IdField: globalField.IdField,
    FillAtStart: false,
    IsEditable: destinationColumn === "editable",
    IsMandatory: false,
    Position: position + 1,
  };
};

const buildUpdatedFieldFromDragAndDrop: BuildUpdatedFieldFromDragAndDrop = (
  { source, destination },
  { globalFields, consultFields, editableFields }
) => {
  const { index: sourcePosition, droppableId: sourceColumn } = source;
  const { index: destinationPosition, droppableId: destinationColumn } =
    destination!;

  const existentField =
    sourceColumn === "editable"
      ? editableFields[sourcePosition]
      : consultFields[sourcePosition];
  const globalField = getFieldFromGlobals(globalFields, existentField.IdField);

  return {
    IdField: globalField.IdField,
    FillAtStart: false,
    IsEditable: destinationColumn === "editable",
    IsMandatory: false,
    Position: destinationPosition + 1,
    LastPosition: sourcePosition + 1,
    ChangeOfColumn: sourceColumn !== destinationColumn,
    IdFieldForm: existentField.IdFieldForm,
  };
};

export const dragAndDropToApi: DragAndDropToApi = (dropResult, allFields) => {
  const action = determineDragAndDropAction(dropResult);

  switch (action) {
    case DragAndDropAction.INSERT:
      return {
        action,
        field: buildInsertedFieldFromDragAndDrop(dropResult, allFields),
      };
    case DragAndDropAction.UPDATED:
      return {
        action,
        field: buildUpdatedFieldFromDragAndDrop(dropResult, allFields),
      };

    case DragAndDropAction.NONE:
      return { action };
  }
};

// Edit Manual Fields (Start Form Fields)

export const manualFieldsDragAndDropToApi: DragAndDropToApi = (
  dropResult,
  allFields
) => {
  const { action, field } = dragAndDropToApi(dropResult, allFields);
  if (field) {
    return {
      action,
      field: {
        ...field,
        FillAtStart: true,
      },
    };
  }
  return { action };
};

export const manualFieldsBuildApiUpdatedFieldFromEditableFields: BuildApiUpdatedFieldFromEditableFields =
  (fieldInfo) => ({
    ...buildApiUpdatedFieldFromEditableFields(fieldInfo),
    FillAtStart: true,
  });

export const buildTriggerFields = (
  globalFields: Field[],
  triggerFields: EditableField[]
): ApiTriggerField[] =>
  _.map(triggerFields, (triggerField, index) => {
    const field = getFieldFromGlobals(globalFields, triggerField.IdField);
    return {
      ...field,
      IsMandatory: triggerField.IsMandatory,
      IsEditable: true,
      Position: index + 1,
    };
  });

export const apiTriggerFieldsToEditableFields = (
  triggerFields: ApiTriggerField[]
): EditableField[] =>
  _.map(triggerFields, (triggerField) => {
    return {
      IdField: triggerField.IdField,
      IdFieldForm: 0,
      IsMandatory: triggerField.IsMandatory,
    };
  });
