import React from "react";
import { BsLightningFill } from "react-icons/bs";
import { Handle, NodeProps } from "reactflow";
import styled from "styled-components";
import { endNodeHandlers } from "./shared/shared";
import { Circle, border, handlerStyles } from "./shared/styles";
import Toolbox from "./Toolbox/Toolbox";
import { ToolboxActions } from "./Toolbox/Toolbox.d";
import ConfirmDeletion from "./shared/ConfirmDeletion";

const Top = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
`;
const Bottom = styled(Top)`
  border-top: ${border};
`;
const Text = styled.span`
  margin: auto;
`;

const EndNode: React.FC<NodeProps> = ({ data, id, selected }) => {
  const { deleting, onAction, onDeleteAttempt, onAbortDelete } = data;
  const showConfirmDeletion = deleting && selected;
  return (
    <ConfirmDeletion
      visible={showConfirmDeletion}
      onConfirm={() => onAction(ToolboxActions.Delete, id)}
      onCancel={onAbortDelete}
      toolboxOffset
    >
      <Circle $isSelected={selected}>
        <Top>
          <Text>Fin</Text>
        </Top>
        <Bottom>
          <BsLightningFill
            color="#0273e9"
            size={18}
            style={{ margin: "auto" }}
          />
        </Bottom>
        {endNodeHandlers.map((handler) => (
          <Handle style={handlerStyles} key={handler.id} {...handler} />
        ))}
        <Toolbox
          nodeId={id}
          onAction={onAction}
          onDeleteAttempt={onDeleteAttempt}
        />
      </Circle>
    </ConfirmDeletion>
  );
};

export default EndNode;
