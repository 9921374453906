import React, { useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { NodeEditorProps } from "../NodeEditor.d";
import { StyledBody, StyledDrawer } from "./styles";
import { useFetch, useMutation } from "../../../../../hooks";
import {
  GetSubfluxDetailAndConfigParams,
  SubfluxEditorPages,
  ActivePageProps,
  Subflux,
  UpdateSubfluxDetailsResponse,
} from "./SubfluxEditor.d";
import TabsHeader from "./TabsHeader";
import SchedulePage from "./SchedulePage";
import GeneralPage from "./GeneralPage";
import ChatsPage from "./ChatsPage";

const ActivePage: React.FC<ActivePageProps> = ({
  activePage,
  startFieldsOptions,
  originChats,
  destinyChats,
  ...rest
}) => {
  switch (activePage) {
    case SubfluxEditorPages.GENERAL:
      return <GeneralPage {...rest} startFieldsOptions={startFieldsOptions} />;
    case SubfluxEditorPages.SCHEDULE:
      return <SchedulePage {...rest} />;
    case SubfluxEditorPages.CHATS:
      return (
        <ChatsPage
          {...rest}
          originChats={originChats}
          destinyChats={destinyChats}
        />
      );
  }
};

const SubfluxEditor: React.FC<NodeEditorProps> = ({ node, onClose }) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const [page, setPage] = useState<SubfluxEditorPages>(
    SubfluxEditorPages.GENERAL
  );
  const [subflux, setSubflux] = useState<Subflux | null>(null);

  const { data, reload } = useFetch<GetSubfluxDetailAndConfigParams>({
    func: "Ver2-Configurator-gsdcp",
    args: {
      IdTeam,
      IdProcessTemplate,
      IdNode: node.id,
    },
    onSuccess: ({ Subflux }) => setSubflux(Subflux),
  });

  const [updateSubfluxDetails] = useMutation<UpdateSubfluxDetailsResponse>({
    func: "Ver2-Configurator-usd",
    onError: reload,
  });

  // Must be used for onBlur inputs
  const handleOnSave = (updatedSubflux: Subflux = subflux as Subflux) => {
    if (
      _.isEmpty(updatedSubflux.Preconf) &&
      !_.isEmpty(`${updatedSubflux.RefDate}`)
    )
      return;
    if (
      !_.isEmpty(updatedSubflux.Preconf) &&
      _.isEmpty(`${updatedSubflux.RefDate}`)
    )
      return;
    updateSubfluxDetails({
      args: {
        IdTeam,
        IdProcessTemplate,
        IdNode: node.id,
        Subflux: {
          ...updatedSubflux,
          TimeToAdd: Number(updatedSubflux.TimeToAdd || "0"),
        },
      },
    });
  };
  // Must be used for text/number inputs where change should be saved until onBlur.
  const handleOnChange = setSubflux;
  // Must be used on selects/switches when value changes.
  const handleOnChangeAndSave = (updatedSubflux: Subflux) => {
    setSubflux(updatedSubflux);
    handleOnSave(updatedSubflux);
  };

  if (!subflux) return null;

  return (
    <StyledDrawer open closable={false} onClose={onClose}>
      <TabsHeader value={page} onChange={setPage} onClose={onClose} />
      <StyledBody>
        <ActivePage
          subflux={subflux}
          activePage={page}
          onChange={handleOnChange}
          onChangeAndSave={handleOnChangeAndSave}
          saveSubflux={() => handleOnSave()}
          timesPreconf={data?.TimesPreconf ?? []}
          timesRefDate={data?.TimesRefDate ?? []}
          startFieldsOptions={data?.StartFieldsOptions ?? []}
          destinyChats={data?.DestinyChats ?? []}
          originChats={data?.OriginChats ?? []}
        />
      </StyledBody>
    </StyledDrawer>
  );
};

export default SubfluxEditor;
