import React from "react";
import _ from "lodash";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
} from "recharts";
import styled from "styled-components";
import { EmptyData } from "../components";
import { ITotalClosures } from "../sales.d";

const barColors = ["#09276e", "#309d09", "#73b63a", "#5bc588"];

const Container = styled(ResponsiveContainer)`
  margin-bottom: 1.5rem;
`;

const TotalClosures: React.FC<{ data?: ITotalClosures; loading: boolean }> = ({
  data,
  loading,
}) => {
  if (loading) return <div>Cargando...</div>;
  if (
    !data ||
    _.isEmpty(data.GraphData) ||
    !_.countBy(data.GraphData, { Value: 0 })
  )
    return <EmptyData />;

  return (
    <Container maxHeight={180}>
      <BarChart layout="vertical" data={data.GraphData} barCategoryGap={2}>
        <XAxis
          axisLine={false}
          dataKey="Value"
          type="number"
          allowDecimals={false}
        />
        <YAxis axisLine={false} dataKey="Label" type="category" width={75} />
        <Tooltip formatter={(value: number) => [value, "Cantidad"]} />
        <Bar dataKey="Value" fill="#b0e7a9">
          {data.GraphData.map((entry, index) => (
            <Cell fill={barColors[index]} key={`cell-${index}`} />
          ))}
        </Bar>
      </BarChart>
    </Container>
  );
};

export default TotalClosures;
