import { Switch } from "antd";
import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 24px;
`;

export const OptionLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const FieldNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SubTitle = styled.p`
  margin: 0;
  color: #48505e;
  font-family: Gotham-Bold;
`;

export const FieldNameInput = styled.input<{ disabled: boolean }>`
  border-radius: 6px;
  border: 2px solid #edecec;
  background-color: ${({ disabled }) => (disabled ? "#edecec" : "white")};
  color: #828d9e;

  width: 100%;
  height: 32px;
  resize: none;
  padding: 6px 10px;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }
  ::placeholder {
    color: #828d9e;
  }
`;

export const FieldConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: 0 auto;
`;

export const SwitchItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex: 1;
  width: 100%;

  p {
    flex: 1;
  }
`;

export const StyledSwitch = styled(Switch)`
  background: ${({ checked }) => (checked ? "#0273E9" : "#828D9E")};
`;

export const SelectDataBasesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DataBases = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`;

export const AdvancedConfigurationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: rgba(25, 57, 183, 1);
  font-size: 14px;
  font-family: Gotham-Bold;
  cursor: pointer;
`;

export const AdvancedConfSwitchContainer = styled(SubTitle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const AdvancedConfDescription = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  font-family: Gotham-Book;
  color: rgba(72, 80, 94, 1);
  font-weight: 325;
  align-items: center;
`;
