import React, { useState, useContext } from "react";
import { Row } from "antd";
import MailImg from "../../../resources/img/Ajustes_Mail.png";
import DevicesImg from "../../../resources/img/Ajustes_Dispositivo.png";
import WebImg from "../../../resources/img/webNotsIcon.svg";
import { Languages } from "../Dictionary";
import { Typography } from "../../../GeestUI";
import { CardCont, Container, Img, StyledSwitch } from "./Style";
import { useFetch, useMutation } from "../../../hooks";
import { MessagesContext } from "../../../components/AppMessages";

const { H1, H2 } = Typography;

const Notifications = () => {
  const [EmailNoti, setEmailNoti] = useState(false);
  const [PushNoti, setPushNoti] = useState(false);
  const [WebNoti, setWebNoti] = useState(false);
  const { showMessage } = useContext(MessagesContext);

  const { NotificationsLabel, ByEmail, PushNotification } = Languages["ESP"];

  const [configureUserNotifications] = useMutation({
    func: "Ver2-Settings-cun",
    onSuccess: (res, { shouldShowMessage = true }) => {
      if (shouldShowMessage) {
        showMessage("Actualización exitosa", "success");
      }
    },
  });

  const onChange = (
    EmailNoti,
    PushNotification,
    WebNoti,
    shouldShowMessage = true
  ) => {
    let NotificationType = "";
    if (PushNotification) {
      NotificationType += "push";
    }
    if (EmailNoti) {
      NotificationType += "email";
    }
    if (WebNoti) {
      NotificationType += "web";
    }
    configureUserNotifications({
      args: {
        NotificationType,
      },
      shippedData: { shouldShowMessage },
    });
  };

  useFetch({
    func: "Ver2-Settings-gunc",
    onSuccess: (response) => {
      setWebNoti(response?.includes("web"));
      setPushNoti(response?.includes("push"));
      setEmailNoti(response?.includes("email"));
    },
  });

  return (
    <Row justify="center">
      <CardCont>
        <H1 mb="0" mt="1rem" style={{ textAlign: "center" }}>
          {NotificationsLabel}
        </H1>
        <Container>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "1.5rem" }}
          >
            <Row align="middle">
              <Img src={MailImg} alt="MailImg" />
              <H2 mb="0">{ByEmail}</H2>
            </Row>
            <StyledSwitch
              checked={EmailNoti}
              onChange={(val) => {
                setEmailNoti(val);
                onChange(val, PushNoti, WebNoti);
              }}
            />
          </Row>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "1.5rem" }}
          >
            <Row align="middle">
              <Img src={DevicesImg} alt="DevicesImg" />
              <H2 mb="0">{PushNotification}</H2>
            </Row>
            <StyledSwitch
              checked={PushNoti}
              onChange={(val) => {
                setPushNoti(val);
                onChange(EmailNoti, val, WebNoti);
              }}
            />
          </Row>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "1.5rem" }}
          >
            <Row align="middle">
              <Img src={WebImg} alt="DevicesImg" />
              <H2 mb="0">Recibir notificaciones web</H2>
            </Row>
            <StyledSwitch
              checked={WebNoti}
              onChange={(val) => {
                setWebNoti(val);
                let shouldShowMessage = true;
                if (val) {
                  if (!("Notification" in window)) {
                    console.log(
                      "Browser does not support desktop notification"
                    );
                  } else {
                    Notification.requestPermission((perm) => {
                      if (perm !== "granted") {
                        shouldShowMessage = false;
                        showMessage(
                          "Habilita las notificaciones en tu navegador",
                          "info"
                        );
                      }
                      onChange(EmailNoti, PushNoti, val, shouldShowMessage);
                    });
                  }
                } else {
                  onChange(EmailNoti, PushNoti, val, shouldShowMessage);
                }
              }}
            />
          </Row>
        </Container>
      </CardCont>
    </Row>
  );
};

export default Notifications;
