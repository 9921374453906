import React from "react";
import { Row } from "antd";
import Modal from "../Modal";
import Button from "./../Button";
import { Message, Title } from "./styles";

interface ConnectionErrorModalProps {
  onClose: () => void;
}

const ConnectionErrorModal: React.FC<ConnectionErrorModalProps> = ({
  onClose,
}) => {
  const onReloadPage = (): void => window.location.reload();

  return (
    <Modal visible title="Error de conexión" width="450px" onCancel={onClose}>
      <Title>No se ha podido conectar con Geest. Volver a cargar página.</Title>
      <Message>
        Te recomendamos guardar cambios antes de volver a cargar página.
      </Message>
      <Row justify="space-between">
        <Button style={{ width: "calc(50% - 0.5rem)" }} onClick={onClose}>
          Cancelar
        </Button>
        <Button
          type="primary"
          style={{ width: "calc(50% - 0.5rem)" }}
          onClick={onReloadPage}
        >
          Volver a cargar página
        </Button>
      </Row>
    </Modal>
  );
};

export default ConnectionErrorModal;
