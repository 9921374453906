import React, { useState } from "react";
import { BodyContent, DocumentationContainer } from "./Styles";
import Navigation from "./Navigation";
import Body from "./Body";

interface DocumentationProps {}

export interface SectionsType {
  OVERVIEW: Symbol;
  BASIC: Symbol;
  TOOLBAR_BASIC: Symbol;
  TOOLBAR_ADVANCE: Symbol;
  PARAMS: Symbol;
  UTILS: Symbol;
  SHORTCUTS: Symbol;
  EXTEND: Symbol;
}

const SECTIONS = {
  OVERVIEW: Symbol(),
  BASIC: Symbol(),
  TOOLBAR_BASIC: Symbol(),
  TOOLBAR_ADVANCE: Symbol(),
  PARAMS: Symbol(),
  UTILS: Symbol(),
  SHORTCUTS: Symbol(),
  EXTEND: Symbol(),
};

const Documentation: React.FC<DocumentationProps> = () => {
  const [section, setSection] = useState<Symbol>(SECTIONS.OVERVIEW);

  return (
    <DocumentationContainer>
      <Navigation
        SECTIONS={SECTIONS}
        section={section}
        setSection={setSection}
      />
      <BodyContent>
        <Body SECTIONS={SECTIONS} section={section} />
      </BodyContent>
    </DocumentationContainer>
  );
};

export default Documentation;
