import { Button } from "@material-ui/core";
import styled, {
  css,
  FlattenSimpleInterpolation,
  keyframes,
} from "styled-components";
import { ButtonSizes } from "./Button.d";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const width = {
  xsmall: "32px",
  small: "82px",
  regular: "132px",
  medium: "100px",
  large: "175px",
  xlarge: "346px",
  fullwidth: "100%",
};

const getFontSize = (size: ButtonSizes) => {
  if (size === "small" || size === "xsmall") {
    return "10px";
  }
  if (size === "medium") {
    return "12px";
  }
  return "14px";
};

export const PrimaryDangerStyle = css`
  background-color: #db232c;
  border-color: #db232c;
  color: #ffffff;
  :hover {
    background-color: #a40810;
    border-color: #a40810;
  }
  :disabled {
    border-color: #edecec;
    background-color: #edecec;
    color: #828d9e;
  }
  :active {
    background-color: #ff000c;
    border-color: #ff000c;
  }
`;

export const SecondaryDangerStyles = css`
  background-color: transparent;
  border-color: #db232c;
  color: #db232c;
  :hover {
    border-color: #a40810;
    color: #a40810;
  }
  :disabled {
    border-color: #edecec;
    background-color: #f2f2f2;
  }
  :active {
    color: #ff000c;
    border-color: #ff000c;
  }
`;

export const DefaultDangerStyles = css`
  background-color: transparent;
  border-color: transparent;
  color: #db232c;
  :hover {
    color: #a40810;
  }
  :disabled {
    color: #828d9e;
  }
  :active {
    color: #ff000c;
  }
`;

export const PrimaryStyles = css`
  background-color: #0273e9;
  border-color: #0273e9;
  color: #ffffff;
  :hover {
    background-color: #014c9b;
    border-color: #014c9b;
  }
  :disabled {
    border-color: #edecec;
    background-color: #edecec;
    color: #828d9e;
  }
  :active {
    background-color: #1939b7;
    border-color: #1939b7;
  }
`;

export const SecondaryStyles = css`
  background-color: transparent;
  border-color: #0273e9;
  color: #0273e9;
  :hover {
    border-color: #014c9b;
    color: #014c9b;
  }
  :disabled {
    border-color: #edecec;
    color: #828d9e;
  }
  :active {
    color: #1939b7;
    border-color: #1939b7;
  }
`;

export const DefaultStyles = css`
  background-color: transparent;
  border-color: transparent;
  color: #0273e9;
  :hover {
    background-color: transparent;
    color: #014c9b;
  }
  :disabled {
    color: #828d9e;
  }
`;

export const DangerStyles = css`
  background-color: transparent;
  border-color: #db232c;
  color: #db232c;
  :disabled {
    border-color: #edecec;
    color: #828d9e;
  }
  :hover {
    border-color: #a40810;
    color: #a40810;
  }
  :focus {
    border-color: #ff000c;
    color: #ff000c;
  }
`;

export const StyledButton = styled(Button)<{
  $typeStyles: FlattenSimpleInterpolation | undefined;
  $size: ButtonSizes;
  $iconSize: number;
  $borderWidth?: string;
}>`
  && {
    border-radius: 6px;
    border-width: ${({ $borderWidth = "2px" }) => $borderWidth};
    border-style: solid;
    font-family: "Gotham-Bold";
    text-transform: none;
    font-size: ${({ $size }) => getFontSize($size)};
    height: 32px;
    width: ${({ $size }) => width[$size]};
    min-width: 32px;
    .MuiButton-startIcon {
      animation: ${spin} 1s linear infinite;
      margin-right: 0.5px;
    }
    svg {
      width: ${({ $iconSize = 20 }) => $iconSize}px;
      height: ${({ $iconSize = 20 }) => $iconSize}px;
    }
    ${({ $typeStyles }) => $typeStyles}
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  transform: scaleX(-1);
`;
