export const Languages = {
  ENG: {
    OpenLabel: "Open",
    CreateLabel: "Create",
    DBLabel: "Database",
    FolderLabel: "Folder",
    DeleteLabel: "Delete",

    DuplicateLabel: "Duplicate",
    ImportLabel: "Import",
    ExportLabel: "Export",

    FolderContentSavedInFolder: (folder) =>
      `The content of this folder will be saved in folder ${folder}`,
    FolderContentSavedInTeam:
      "The content of this folder will be saved in the team's main space",
    RemoveFromFolderLabel: "Remove from folder",
  },
  ESP: {
    OpenLabel: "Abrir",
    CreateLabel: "Crear",
    DBLabel: "Base de datos",
    FolderLabel: "Carpeta",
    DeleteLabel: "Eliminar",

    DuplicateLabel: "Duplicar",
    ImportLabel: "Importar",
    ExportLabel: "Exportar",

    FolderContentSavedInFolder: (folder) =>
      `El contenido de esta carpeta se guardará en la carpeta ${folder}`,
    FolderContentSavedInTeam:
      "El contenido de esta carpeta se guardará en el espacio principal del equipo",
    RemoveFromFolderLabel: "Sacar de la carpeta",
  },
};
