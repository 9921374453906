import React from "react";
import { Coords, Page, Size } from "../../PDFConfig.d";
import { DeleteIconContainer, PageWrapper, Paper } from "./styles";
import { IoIosClose } from "react-icons/io";
import TextElementAbstraction from "./ElementAbstractions/TextElementAbstraction";
import ImageAbstraction from "./ElementAbstractions/ImageAbstraction";
import SignAbstraction from "./ElementAbstractions/SignAbstraction";

interface PageComponentProps {
  page: Page;
  isCurrent: boolean;
  canDeletePages: boolean;
  pageWidth: number;
  pageHeight: number;
  onClick: () => void;
  onDeletePage: () => void;
}

const PageComponent: React.FC<PageComponentProps> = ({
  page,
  isCurrent,
  canDeletePages,
  pageWidth,
  pageHeight,
  onClick,
  onDeletePage,
}) => {
  const maxWidth = 100;
  const maxHeight = 100;
  const minWidth = 50;
  const minHeight = 50;

  function calculateThumbnailSize(): Size {
    const aspectRatio = pageWidth / pageHeight;

    let width = Math.min(maxWidth, pageWidth);
    let height = width / aspectRatio;

    if (height > maxHeight) {
      height = maxHeight;
      width = height * aspectRatio;
    }

    width = Math.max(minWidth, width);
    height = Math.max(minHeight, height);

    return { width, height };
  }

  const thumbnailSize = calculateThumbnailSize();

  const getElementSizeOnRatio = (size: Size, thumbnailSize: Size) => {
    const sizeRatio = thumbnailSize.width / pageWidth;

    let newWidth = Math.round(size.width * sizeRatio);
    let newHeight = Math.round(size.height * sizeRatio);

    newWidth = Math.min(thumbnailSize.width - 10, newWidth);
    newHeight = Math.min(thumbnailSize.height - 10, newHeight);

    return { width: newWidth, height: newHeight };
  };

  const getElementPositionOnRatio = (position: Coords, thumbnailSize: Size) => {
    const coordinatesRatio = thumbnailSize.width / pageWidth;

    let newX = Math.round(position.x * coordinatesRatio);
    let newY = Math.round(position.y * coordinatesRatio);

    newX = Math.max(0, Math.min(thumbnailSize.width, newX));
    newY = Math.max(0, Math.min(thumbnailSize.height, newY));

    return { x: newX, y: newY };
  };

  return (
    <PageWrapper>
      <Paper
        onClick={onClick}
        isCurrent={isCurrent}
        width={thumbnailSize.width}
        height={thumbnailSize.height}
        backgroundImage={page.backgroundImage ?? ""}
      >
        {page.elements.map((element, i) => {
          if (element.type === "text") {
            return (
              <TextElementAbstraction
                componentKey={`${element.id}_${i}`}
                size={getElementSizeOnRatio(element.boxSize, thumbnailSize)}
                position={getElementPositionOnRatio(
                  element.position,
                  thumbnailSize
                )}
                thumbnailSize={thumbnailSize}
              />
            );
          }

          if (element.type === "image") {
            return (
              <ImageAbstraction
                componentKey={`${element.id}_${i}`}
                size={getElementSizeOnRatio(element.boxSize, thumbnailSize)}
                position={getElementPositionOnRatio(
                  element.position,
                  thumbnailSize
                )}
                thumbnailSize={thumbnailSize}
              />
            );
          }

          if (element.type === "sign") {
            return (
              <SignAbstraction
                componentKey={`${element.id}_${i}`}
                size={getElementSizeOnRatio(element.boxSize, thumbnailSize)}
                position={getElementPositionOnRatio(
                  element.position,
                  thumbnailSize
                )}
                thumbnailSize={thumbnailSize}
              />
            );
          }

          return null;
        })}
      </Paper>

      {canDeletePages && (
        <DeleteIconContainer
          className="deleteIconContainer"
          onClick={onDeletePage}
        >
          <IoIosClose size={20} />
        </DeleteIconContainer>
      )}
    </PageWrapper>
  );
};

export default PageComponent;
