import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Header,
  Message,
  Content,
  Footer,
  ButtonBorder,
  ErrorMessage,
} from "./styles";
import Button from "../../../../../../components/Button";
import Img from "../../../../../../components/Img/Img";
import GeestIcon from "./../../../../../../resources/img/ICONO_.svg";
import States from "../../../States";
import { StateMachineContext } from "../../..";
import { useMutation } from "../../../../../../hooks";

const MessageSection = ({ message = "", expired = false }) => {
  if (expired) {
    return (
      <ErrorMessage>
        <b>Tu prueba gratuita ha finalizado.</b>
        <div style={{ margin: "0.5rem 0 0 0" }}>{message}</div>
      </ErrorMessage>
    );
  } else {
    return <Message>{message}</Message>;
  }
};

const ActiveTrial = ({ currentPlan = {}, expired = false }) => {
  const {
    SubscriptionTrialEnds,
    TrialBonusDaysMessage,
    Message: expiredMessage,
  } = currentPlan;
  const { IdTeam } = useParams();
  const { setCurrentState } = useContext(StateMachineContext);
  const [loading, setLoading] = useState(false);

  const [changeSubscriptionState] = useMutation({
    func: "Ver2-Subscription-css",
    onSuccess: (response) => {
      const { State } = response;
      setCurrentState(State);
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  const onClick = (NextState = "") => {
    setLoading(true);
    changeSubscriptionState({
      args: {
        IdTeam: IdTeam,
        NextState: NextState,
      },
    });
  };

  return (
    <Container>
      <Header>Suscripción</Header>
      <MessageSection
        message={SubscriptionTrialEnds || expiredMessage}
        expired={expired}
      />
      <Content>
        <Img cursor="default" img={GeestIcon} size="contain" />
      </Content>
      <Footer size="middle" direction="vertical">
        <Button
          block
          type="premiumPro"
          loading={loading}
          onClick={() => onClick(States.REQUEST_BONUS_TRIAL)}
        >
          {TrialBonusDaysMessage}
        </Button>
        <ButtonBorder disable={loading}>
          <Button
            type="defaultActive"
            block
            style={{ borderColor: "#ffffff0f" }}
            loading={loading}
            onClick={() => onClick(States.CHANGE_PLAN)}
          >
            Contratar ahora
          </Button>
        </ButtonBorder>
      </Footer>
    </Container>
  );
};

export default ActiveTrial;
