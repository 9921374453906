import React, { useState } from "react";
import { Row, Space } from "antd";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  GetSubluxOptions,
  RequestSubfluxConfigProps,
} from "./RequestSubfluxConfig.d";
import { Button } from "../../../../GeestUI";
import { useFetch } from "../../../../hooks";
import GeestSelect from "../../../../GeestUI/GeestSelect";

const RequestSubfluxConfig: React.FC<RequestSubfluxConfigProps> = ({
  onSubmit,
  onCancel,
}) => {
  const { IdTeam, IdProcess: IdProcessTemplate } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const [IdProcessTemplateToTrigger, setIdProcessTemplateToTrigger] =
    useState<number>(0);
  const isValidForm = IdProcessTemplateToTrigger !== 0;

  const { data } = useFetch<GetSubluxOptions>({
    func: "Ver2-Configurator-gsoti",
    args: {
      IdTeam,
      IdProcessTemplate,
    },
  });

  const subfluxOptions = _.map(
    data,
    ({ IdProcessTemplateToTrigger, Label }) => ({
      value: IdProcessTemplateToTrigger,
      label: Label,
    })
  );

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit({
      IdProcessTemplateToTrigger,
      Label: _.find(subfluxOptions, { value: IdProcessTemplateToTrigger })!
        .label,
    });
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <div style={{ margin: "0px 15px" }}>
        <Space direction="vertical" size={[0, 8]} style={{ width: "100%" }}>
          <p style={{ fontFamily: "Gotham-Book", color: "#828d9e" }}>
            Selecciona un proceso ya configurado para insertarlo como un nodo en
            el flujo
          </p>
          <GeestSelect
            value={IdProcessTemplateToTrigger}
            onChange={setIdProcessTemplateToTrigger}
            options={subfluxOptions}
            placeholderSelect="Selecciona una opción..."
            valueNecesary
          />
          <Row justify="center">
            <Space align="center">
              <Button type="secondary" onClick={onCancel}>
                Cancelar
              </Button>
              <Button disabled={!isValidForm} type="primary" htmlType="submit">
                Guardar
              </Button>
            </Space>
          </Row>
        </Space>
      </div>
    </form>
  );
};

export default RequestSubfluxConfig;
