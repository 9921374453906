import React, { useContext, useState } from "react";
import { Col, Row, Tooltip } from "antd";
import { AiOutlineInfoCircle, AiOutlinePlus } from "react-icons/ai";
import { useParams } from "react-router-dom";
import {
  DeleteProcessField,
  GetTemplateFields,
  ProcessFieldsProps,
} from "./ProcessFields.d";
import Modal from "../../../../components/Modal";
import { Body, ColumnTitle, StyledDivider } from "../../Flow/TaskFields/styles";
import { SearchBar } from "../../../Pendings/styles";
import { Button } from "../../../../GeestUI";
import FieldItem from "../../Flow/TaskFields/FieldItem";
import { useFetch, useMutation } from "../../../../hooks";
import { filterDataTypeGroups, filterFields } from "./helpers";
import { getDataTypeIcon } from "../../helpers";
import EditField from "../../Flow/TaskFields/EditField";
import { Field, FieldTypes, VarDBCellField } from "../../Configurator.d";
import { ConfiguratorContext } from "../../Configurator";
import DeleteFieldModal from "../../Flow/TaskFields/DeleteFieldModal";

const ProcessFields: React.FC<ProcessFieldsProps> = ({ onClose }) => {
  const { IdTeam, IdProcess } = useParams<{
    IdTeam: string;
    IdProcess: string;
  }>();
  const { refreshProcessFlow } = useContext(ConfiguratorContext);
  const [filter, setFilter] = useState("");
  const [editingFieldId, setEditingFieldId] = useState<number | null>(null);
  const [fieldToDelete, setFieldToDelete] = useState<Field | null>(null);

  const { data, reload: reloadFields } = useFetch<GetTemplateFields>({
    func: "Ver2-Configurator-gtf",
    args: {
      IdProcessTemplate: IdProcess,
      IdTeam,
    },
  });
  const [deleteProcessField] = useMutation<DeleteProcessField>({
    func: "Ver2-Configurator-dpf",
    onSuccess: () => {
      reloadFields();
      refreshProcessFlow();
    },
  });
  const [deleteProcessVarDB] = useMutation<[]>({
    func: "Ver2-Configurator-dpvg",
    onSuccess: () => {
      reloadFields();
      refreshProcessFlow();
    },
  });
  const filteredDataTypeGroups = filterDataTypeGroups(
    data?.ProcessFields ?? [],
    filter
  );

  const handleOnClickAddField: React.MouseEventHandler<
    HTMLButtonElement
  > = () => setEditingFieldId(0);
  const handleOnClickField = (field: Field): void =>
    setEditingFieldId(field.IdField);
  const handleOnRegisterField = (fieldId: number): void => {
    setEditingFieldId(fieldId);
    reloadFields();
  };
  const handleOnUpdateField = (): void => {
    reloadFields();
    refreshProcessFlow();
  };
  const handleOnCloseFieldEditor = (): void => setEditingFieldId(null);

  const handleOnDeleteField = (field: Field): void => {
    const { IdField, Type } = field;
    setFieldToDelete(null);

    if (Type === FieldTypes.CELL) {
      const { IdVarDBGroup } = (field as VarDBCellField).VardbGroupInfo;
      deleteProcessVarDB({
        args: {
          IdTeam,
          IdProcessTemplate: IdProcess,
          IdVarDBGroup,
        },
      });
    } else {
      deleteProcessField({
        args: {
          IdTeam,
          IdProcessTemplate: IdProcess,
          IdField,
        },
      });
    }
  };

  const openDeleteModal = (field: Field) => {
    setFieldToDelete(field);
  };

  return (
    <Modal
      title={data?.ProcessName ?? ""}
      width={1200}
      onCancel={onClose}
      contentStyles={{ display: "flex" }}
      bodyStyle={{ height: "90vh" }}
    >
      {fieldToDelete && (
        <DeleteFieldModal
          onDelete={() => handleOnDeleteField(fieldToDelete)}
          onCancel={() => setFieldToDelete(null)}
        />
      )}

      {editingFieldId !== null && (
        <EditField
          IdField={editingFieldId}
          onRegisterField={handleOnRegisterField}
          onUpdateField={handleOnUpdateField}
          onClose={handleOnCloseFieldEditor}
        />
      )}
      <Body style={{ flexDirection: "column" }}>
        <Row
          justify="space-between"
          align="bottom"
          style={{ marginBottom: "32px", width: "100%" }}
          gutter={[16, 12]}
        >
          <Col span={8}>
            <Row justify="space-between" align="middle">
              <ColumnTitle>Campos de formulario</ColumnTitle>
              <Tooltip
                title="Gestiona los campos de formulario que se utilizan en tu proceso. Si quieres solicitar campos específicamente en cierto paso, debes hacerlo desde el nodo de inicio o desde alguna tarea."
                placement="bottomLeft"
              >
                <AiOutlineInfoCircle size={16} color="#48505E" />
              </Tooltip>
              <StyledDivider style={{ marginBottom: 0 }} />
            </Row>
          </Col>
          <Col span={8}>
            <SearchBar
              type="text"
              placeholder="Buscar"
              autoComplete="off"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={8}>
            <div>
              <Button
                type="primary"
                size="fullwidth"
                Icon={AiOutlinePlus}
                onClick={handleOnClickAddField}
              >
                Nuevo campo
              </Button>
            </div>
          </Col>
        </Row>
        <div style={{ flex: 1, overflow: "auto" }}>
          {filteredDataTypeGroups.map((dataTypeGroup, index) => {
            const DataTypeIcon = getDataTypeIcon(dataTypeGroup.DataType);
            return (
              <div key={index}>
                <Row align="middle" style={{ marginBottom: "15px" }}>
                  <DataTypeIcon size={20} style={{ marginRight: "8px" }} />
                  <ColumnTitle>{dataTypeGroup.Label}</ColumnTitle>
                </Row>
                <Row
                  style={{ width: "100%", marginBottom: "25px" }}
                  gutter={[16, 12]}
                >
                  {filterFields(dataTypeGroup.Fields, filter).map((field) => (
                    <Col span={8} key={field.IdField}>
                      <FieldItem
                        column="global"
                        field={field}
                        onClick={handleOnClickField}
                        onRemove={openDeleteModal}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            );
          })}
        </div>
      </Body>
    </Modal>
  );
};

export default ProcessFields;
