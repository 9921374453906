import styled from "styled-components";
import { Switch } from "antd";
import { Paper } from "@material-ui/core";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-bottom: 16px;
  border-bottom: 2px solid #edecec;
  margin-bottom: 25px;

  p {
    margin: 0;
    font-weight: 325;
    font-size: 24px;
    color: #48505e;
    user-select: none;
  }
`;

export const HeaderOptions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
`;

export const HeaderOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  p {
    font-weight: 325;
    font-size: 14px;
    color: #828d9e;
    user-select: none;
  }
`;

export const StyledSwitch = styled(Switch)`
  background: ${({ checked }) => checked && "#0273e9"};
`;

export const CreateElementButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;

  background: #0273e9;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  font-family: Gotham-Medium;
  color: white;
  height: 32px;
  cursor: pointer;
  user-select: none;

  transition: background 0.35s ease;
  :hover {
    background: #014c9b;
  }
`;

export const MoreOptionsButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background: white;
  border: 2px solid #0273e9;
  border-radius: 6px;
  width: 32px;
  height: 32px;
`;

export const MoreOptionsList = styled(Paper)`
  border-radius: 6px !important;
  display: flex;
  flex-direction: column;
  color: #828d9e;
  width: 252px;
`;

export const OptionLabel = styled.p<{ color?: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  cursor: pointer;
  color: ${({ color = "rgba(130, 141, 158, 1)" }) => color};
  font-size: 14px;
  font-family: Gotham-Book;
  width: 252px;
  font-weight: 325;
  height: 32px;
  padding: 6px 10px 6px 10px;
  cursor: pointer;
  :hover {
    background-color: rgba(237, 236, 236, 1);
  }
  :active {
    background-color: rgba(242, 248, 254, 1);
  }
`;
