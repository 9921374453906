import styled from "styled-components";
import { BaseDrawer } from "../Styles";

export const NavigationWrapper = styled(BaseDrawer)`
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 164px;
  border-right: 4px solid #f6f5f5;
  font-size: 16px;
`;

export const PagesWrapper = styled.div`
  padding: 14px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const NewPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .MuiButton-label {
    font-size: 16px;
  }
`;

export const PageCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5px;
  color: #828d9e;

  span {
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const PageInput = styled.input<{ length: number }>`
  border-radius: 6px;
  border: 2px solid;
  border-color: #edecec;
  background-color: white;
  color: #828d9e;

  width: ${({ length = 0 }) => (length + 1) * 8 + 18}px;
  max-width: 60px;
  min-height: 30px;
  max-height: 32px;
  resize: none;
  padding: 6px 10px;

  transition: all 0.35s ease;
  :hover {
    border-color: #0273e9;
    box-shadow: none;
  }
  :focus {
    border-color: #48505e;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  -moz-appearance: textfield;
`;
