export const planTypes = {
  PRO: "Pro",
  STANDARD: "Standard",
  BASIC: "Basic",
  IMPULSE: "Impulse",
};

export const getPlanColors = (planName) => {
  let plan = planName;
  if (plan === "Estándar") plan = planTypes.STANDARD;
  if (plan === "Básico") plan = planTypes.BASIC;
  if (plan === "Impulso") plan = planTypes.IMPULSE;

  switch (plan) {
    case planTypes.PRO:
      return {
        mainColor: "#9932CC",
        gradientColors: ["#9a68c4", "#8381d2"],
      };
    case planTypes.STANDARD:
      return {
        mainColor: "#ffc40c",
        gradientColors: ["#c28414", "#e4b914", "#e5d119"],
      };
    case planTypes.BASIC:
      return {
        mainColor: "#00cce7",
        gradientColors: ["#47c7ba", "#52dadf"],
      };
    case planTypes.IMPULSE:
      return {
        mainColor: "#2E4EE9",
        gradientColors: ["#2E41AF", "#2E4EE9"],
      };

    default:
      return {
        mainColor: "black",
        gradientColors: ["#6fb124", "#033f7a"],
      };
  }
};

export const regimenOptions = [
  {
    label: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
    value: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  },
  {
    label: "Coordinados",
    value: "Coordinados",
  },
  {
    label: "General de Ley Personas Morales",
    value: "General de Ley Personas Morales",
  },
  {
    label: "Opcional para Grupos de Sociedades",
    value: "Opcional para Grupos de Sociedades",
  },
  {
    label: "Personas Físicas con Actividad Empresarial",
    value: "Personas Físicas con Actividad Empresarial",
  },
  {
    label: "Personas Morales con Fines no Lucrativos",
    value: "Personas Morales con Fines no Lucrativos",
  },
  {
    label:
      "Residentes en el Extranjero sin Establecimiento Permanente en México",
    value:
      "Residentes en el Extranjero sin Establecimiento Permanente en México",
  },
  {
    label: "Régimen Simplificado de Confianza",
    value: "Régimen Simplificado de Confianza",
  },
  {
    label:
      "Sociedades Corporativas de Producción que optan por diferir sus ingresos",
    value:
      "Sociedades Corporativas de Producción que optan por diferir sus ingresos",
  },
];
