import React, { useRef, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import styled from "styled-components";
import { Tooltip } from "antd";
import {
  conditionNodeDefaultHandlers,
  filterTargetHandlers,
  useIsConnecting,
} from "./shared/shared";
import { Rectangle, handlerStyles, border } from "./shared/styles";
import { ConditionPort, NodeData } from "../Flow.d";
import Toolbox from "./Toolbox/Toolbox";
import { ToolboxActions } from "./Toolbox/Toolbox.d";
import ConfirmDeletion from "./shared/ConfirmDeletion";
import { useElementOverflow } from "../../../../hooks";
import TP from "../../../../components/Tooltip";
import warningIcon from "../../../../resources/img/warningIcon.svg";

const defaultConditionPorts: ConditionPort[] = [
  { Port: "OutRight0", PortLabel: "Si" },
  { Port: "OutRight1", PortLabel: "No" },
];

const ConditionRectangle = styled(Rectangle)<{ $showWarning?: boolean }>`
  flex-direction: row;
  ${({ $showWarning }) => ($showWarning ? "border: 2px solid #ffae3d" : "")}
`;

const Left = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  overflow: hidden;
  border-right: ${border};
  padding: 0 4px;
`;
const Right = styled.div`
  width: 90px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  display: flex;
  flex-direction: column;
`;
const Option = styled.div`
  border-bottom: ${border};
  text-align: center;
  display: flex;
  height: 40px;
  padding: 0 4px;
  :last-child {
    border-bottom: none;
  }
`;
const Text = styled.span`
  margin: auto;
  line-height: 110%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const OptionText = styled.span`
  margin: auto;
  line-height: 110%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const ConditionOption: React.FC<ConditionPort> = ({ PortLabel }) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const isOverflow = useElementOverflow(textRef, "height");
  const [openTooltip, setOpenTooltip] = useState(false);

  return (
    <Option>
      <Tooltip
        title={PortLabel}
        open={openTooltip && isOverflow}
        onOpenChange={setOpenTooltip}
        overlayInnerStyle={{
          borderRadius: "2px",
          padding: "6px 8px",
        }}
      >
        <OptionText ref={textRef}>{PortLabel}</OptionText>
      </Tooltip>
    </Option>
  );
};

const TooltipContainer: React.FC<{
  children: React.ReactNode;
  showTooltip: boolean;
  tooltipLabel: string;
}> = ({ showTooltip, children, tooltipLabel }) =>
  showTooltip ? (
    <TP
      title={tooltipLabel}
      placement="top"
      color="#FADB14"
      fontColor="#48505E"
    >
      {children}
    </TP>
  ) : (
    <>{children}</>
  );

const ConditionalNode: React.FC<NodeProps<NodeData>> = ({
  data,
  id,
  selected,
}) => {
  const isConnecting = useIsConnecting();
  const {
    label,
    deleting,
    conditionPorts = defaultConditionPorts,
    onAction,
    onDeleteAttempt,
    onAbortDelete,
    IsFieldMandatory,
  } = data;
  const showConfirmDeletion = deleting && selected;

  const textRef = useRef<HTMLSpanElement>(null);
  const isOverflow = useElementOverflow(textRef, "height", 1);
  const [openTooltip, setOpenTooltip] = useState(false);
  const showWarning = label === "?" || !IsFieldMandatory;
  const tooltipLabel =
    label === "?"
      ? "La condición no está configurada"
      : `El campo ${label} no lo haz pedido como evidencia y tiene que ser evidencia obligatoria`;

  return (
    <ConfirmDeletion
      visible={showConfirmDeletion}
      onConfirm={() => onAction(ToolboxActions.Delete, id)}
      onCancel={onAbortDelete}
      toolboxOffset
    >
      <TooltipContainer showTooltip={showWarning} tooltipLabel={tooltipLabel}>
        <ConditionRectangle
          style={{ height: conditionPorts.length * 40 }}
          $isSelected={selected}
          $showWarning={showWarning}
        >
          <Left>
            <Tooltip
              title={label}
              open={openTooltip && isOverflow}
              onOpenChange={setOpenTooltip}
              overlayInnerStyle={{
                borderRadius: "2px",
                padding: "6px 8px",
              }}
            >
              <Text ref={textRef}>{label}</Text>
            </Tooltip>
          </Left>
          <Right>
            {conditionPorts.map((conditionPort) => (
              <ConditionOption {...conditionPort} key={conditionPort.Port} />
            ))}
          </Right>
          {filterTargetHandlers(conditionNodeDefaultHandlers, isConnecting).map(
            (handler) => (
              <Handle style={handlerStyles} key={handler.id} {...handler} />
            )
          )}
          {conditionPorts.map(({ Port }, idx) => (
            <Handle
              style={{ ...handlerStyles, top: (idx + 1) * 40 - 20 }}
              key={Port}
              id={Port}
              position={Position.Right}
              type="source"
            />
          ))}
          <Toolbox
            nodeId={id}
            onAction={onAction}
            onDeleteAttempt={onDeleteAttempt}
          />
          {showWarning && (
            <img
              src={warningIcon}
              style={{
                position: "absolute",
                bottom: 7,
                left: 9,
                width: "12px",
                height: "10px",
              }}
              alt="warning"
            />
          )}
        </ConditionRectangle>
      </TooltipContainer>
    </ConfirmDeletion>
  );
};

export default ConditionalNode;
