export const NodeIconSmall = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.78809"
      y="1.78809"
      width="18.4951"
      height="18.4951"
      rx="3"
      stroke="#48505E"
      strokeWidth="2"
    />
    <circle cx="2" cy="11" r="2" fill="#48505E" />
    <circle cx="20" cy="11" r="2" fill="#48505E" />
    <circle cx="11" cy="20" r="2" fill="#48505E" />
    <circle cx="11" cy="2" r="2" fill="#48505E" />
  </svg>
);

export const TaskIcon = (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="3"
      width="50"
      height="50"
      rx="5"
      stroke="#48505E"
      strokeWidth="2"
    />
    <circle cx="2.90909" cy="27.9091" r="2.90909" fill="#48505E" />
    <circle cx="52.9091" cy="27.9091" r="2.90909" fill="#48505E" />
    <circle cx="27.9091" cy="52.9091" r="2.90909" fill="#48505E" />
    <circle cx="27.9091" cy="2.90909" r="2.90909" fill="#48505E" />
  </svg>
);

export const ConditionIcon = (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="3"
      width="50"
      height="50"
      rx="5"
      stroke="#48505E"
      strokeWidth="2"
    />
    <path d="M28 3V53" stroke="#48505E" strokeWidth="2" />
    <circle cx="27.9091" cy="2.90909" r="2.90909" fill="#48505E" />
    <circle cx="52.9091" cy="27.9091" r="2.90909" fill="#48505E" />
    <circle cx="27.9091" cy="52.9091" r="2.90909" fill="#48505E" />
    <circle cx="2.90909" cy="27.9091" r="2.90909" fill="#48505E" />
    <path d="M28 28H53" stroke="#48505E" strokeWidth="2" />
  </svg>
);

export const AutomationIcon = (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="3"
      width="50"
      height="50"
      rx="5"
      stroke="#48505E"
      strokeWidth="2"
    />
    <circle cx="52.9091" cy="27.9091" r="2.90909" fill="#48505E" />
    <circle cx="27.9091" cy="52.9091" r="2.90909" fill="#48505E" />
    <circle cx="2.90909" cy="27.9091" r="2.90909" fill="#48505E" />
    <circle cx="27.9091" cy="2.90909" r="2.90909" fill="#48505E" />
    <path
      d="M32.3446 37.7442H29.3132L32.0391 34.1849C32.0955 34.1095 32.0446 34 31.9524 34H26.6754C26.6368 34 26.5997 34.0213 26.5804 34.0569L23.0152 40.4204C22.9725 40.4958 23.0248 40.591 23.1101 40.591H25.5099L24.2797 45.6762C24.2536 45.7871 24.3829 45.8653 24.4627 45.7857L32.4203 37.939C32.4918 37.8693 32.4437 37.7442 32.3446 37.7442Z"
      fill="#0273E9"
    />
    <path d="M52 28L2 28" stroke="#48505E" strokeWidth="2" />
  </svg>
);

export const SubfluxIcon = (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="3"
      width="50"
      height="50"
      rx="5"
      stroke="#48505E"
      strokeWidth="2"
    />
    <rect
      x="7"
      y="7"
      width="42"
      height="42"
      rx="5"
      stroke="#48505E"
      strokeWidth="2"
    />
    <circle cx="27.9091" cy="2.90909" r="2.90909" fill="#48505E" />
    <circle cx="52.9091" cy="27.9091" r="2.90909" fill="#48505E" />
    <circle cx="2.90909" cy="27.9091" r="2.90909" fill="#48505E" />
    <circle cx="27.9091" cy="52.9091" r="2.90909" fill="#48505E" />
  </svg>
);

export const EndIcon = (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="28" cy="28" r="25" stroke="#48505E" strokeWidth="2" />
    <path
      d="M32.3446 35.7442H29.3132L32.0391 32.1849C32.0955 32.1095 32.0446 32 31.9524 32H26.6754C26.6368 32 26.5997 32.0213 26.5804 32.0569L23.0152 38.4204C22.9725 38.4958 23.0248 38.591 23.1101 38.591H25.5099L24.2797 43.6762C24.2536 43.7871 24.3829 43.8653 24.4627 43.7857L32.4203 35.939C32.4918 35.8693 32.4437 35.7442 32.3446 35.7442Z"
      fill="#0273E9"
    />
    <path d="M54 27L4 27" stroke="#48505E" strokeWidth="2" />
    <circle cx="27.9091" cy="2.90909" r="2.90909" fill="#48505E" />
    <circle cx="52.9091" cy="26.9091" r="2.90909" fill="#48505E" />
    <circle cx="2.90909" cy="26.9091" r="2.90909" fill="#48505E" />
    <circle cx="27.9091" cy="52.9091" r="2.90909" fill="#48505E" />
  </svg>
);

const NodeIcons = {
  TaskIcon,
  ConditionIcon,
  AutomationIcon,
  SubfluxIcon,
  EndIcon,
};

export default NodeIcons;
