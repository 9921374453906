import React, { useState } from "react";
import {
  ArticleWrapper,
  IconWrapper,
  SectionWrapper,
  SnippetWrapper,
  StyledLink,
} from "../Styles";
import { AiOutlineCopy } from "react-icons/ai";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import GeestTextEditor from "../../../../../components/GeestTextEditor";
import { EditorState } from "draft-js";
import {
  defaultToolbarConfig,
  popupToolbarExample,
  toolbarConfigExample,
  toolbarConfigNoLink,
} from "./Snippets/ToolbarBasicSnippets";

const SectionName = "Toolbar básica";

interface ToolbarBasicLinkProps {
  isActive: () => boolean;
  setSection: () => void;
}

export const ToolbarBasicLink: React.FC<ToolbarBasicLinkProps> = ({
  isActive,
  setSection,
}) => {
  return (
    <StyledLink onClick={setSection} $isActive={isActive()}>
      {SectionName}
    </StyledLink>
  );
};

interface ToolbarBasicSectionProps {
  handleCopy: (element: string) => void;
}

export const ToolbarBasicSection: React.FC<ToolbarBasicSectionProps> = ({
  handleCopy,
}) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  return (
    <SectionWrapper>
      <h2>{SectionName}</h2>
      <ArticleWrapper>
        <p>
          La toolbar tiene una serie de argumentos para configurar sus elementos
          y posicionamiento.
        </p>

        <h3>
          editorBorder:
          <span className="info type">boolean</span>
          <span className="info req">optional</span>
          <span className="info default">
            default value: <span className="type">false</span>
          </span>
        </h3>
        <p>
          El parametro <code className="code">editorBorder</code> nos permite
          agregar un borde al editor para hacerlo visible. De momento no existen
          parametros de configuración para sobreescribir los estilos del mismo.
        </p>

        <h3>
          popUpToolbar:
          <span className="info type">boolean</span>
          <span className="info req">optional</span>
          <span className="info default">
            default value: <span className="type">false</span>
          </span>
        </h3>
        <p>
          El parametro <code className="code">popUpToolbar</code> permite
          configurar la toolbar para aparecer solo cuando el editor está en
          focus.
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(popupToolbarExample)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={popupToolbarExample}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <p>
          El uso de estas propiedades da como resultado un editor con borde que
          al dar click en el, despliega el toolbar a modo de popup.
        </p>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          popUpToolbar
          editorBorder
        />

        <h3>
          toolbarConfig:
          <span className="info type">obj</span>
          <span className="info req">optional</span>
        </h3>
        <p>
          El editor tiene una configuración por defecto que determina los
          controladores que aparecerán en la toolbar, aquí puedes ver cual es la
          configuración por defecto y la interfaz:
        </p>
        <SnippetWrapper>
          <SyntaxHighlighter
            children={defaultToolbarConfig}
            language="typescript"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <p>
          Si nosotros quisieramos un editor de texto que, por ejemplo solo
          tuviese las opciones inline (bold, italic, etc), los alignments para
          el texto un espaciador y el botón para insertar campos, podríamos
          hacerlo mandando un objeto de configuración de la siguiente manera:
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(toolbarConfigExample)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={toolbarConfigExample}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarConfig={{
            fontSize: false,
            fontFamily: false,
            list: false,
            colorPicker: false,
            link: false,
            spacer: true,
            field: true,
          }}
          editorBorder
        />
        <p>
          En este ejemplo, desactivamos los controladores que están activados
          por defecto dandoles un valor de{" "}
          <code className="code type">false</code> y activando los que por
          defecto están desactivados asignando un valor{" "}
          <code className="code type">true</code>.
        </p>
        <hr />
        <p>
          Cabe resaltar que si la configuración que queremos, solo difiere en
          una propiedad, por ejemplo, desactivar los links, no hace falta
          sobreescribir todo el valor por defecto, bastaría con agregar un
          objeto con una única propiedad <code className="code">link</code> con
          un valor <code className="code type">false</code> y listo.
        </p>
        <SnippetWrapper>
          <IconWrapper onClick={() => handleCopy(toolbarConfigNoLink)}>
            <AiOutlineCopy size={20} />
          </IconWrapper>
          <SyntaxHighlighter
            children={toolbarConfigNoLink}
            language="tsx"
            style={atomOneDark}
          />
        </SnippetWrapper>
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarConfig={{ link: false }}
          editorBorder
        />
      </ArticleWrapper>
    </SectionWrapper>
  );
};
