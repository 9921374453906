import styled, { keyframes } from "styled-components";
import { Row } from "antd";

export const StyledRow = styled(Row)`
  margin-top: 5px;
  font-size: x-large;
  color: #535151;
`;

export const StyledContentRow = styled(Row)`
  border: 1px solid transparent;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    animation: ${spin} 1s linear infinite;
  }
`;

export const StyledInnerContent = styled(Row)<{ $showBack: boolean }>`
  width: ${({ $showBack }) => `calc(100% - ${$showBack ? "2.3rem" : "0rem"})`};
  padding: ${({ $showBack }) => ($showBack ? "0rem" : "0rem 1.5rem")};
`;

export const StyledTeamsInput = styled(Row)`
  width: 10rem;
  margin-left: 0.5rem;
`;

export const StyledLogo = styled.img`
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  margin-left: 0.5rem;
`;

export const StyledDivider = styled.div`
  height: 2px;
  background-color: #c0c9d3;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
`;
