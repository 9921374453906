import React from "react";
import { ElementOrigin, NodeType, Option } from "../PDFConfig.d";
import ControlsHome from "./ControlsHome";
import ControlsConfig from "./ControlsConfig";

interface ControlsProps {
  elementsOrigin: ElementOrigin[];
  elementToConfig: string | null;
  setElementToConfig: (id: string | null) => void;
  filesPath: string;
  dropNewNode: (e: any, nodeType: NodeType) => void;
  instantiateNode: (e: any, node: ElementOrigin) => void;
  onDeleteOriginElement: (elementId: string) => void;
  updateElementsOrigin: (newElementsOrigin: ElementOrigin[]) => void;
  fieldImageOrigin: Option[];
  teamLogo: string;
}

const Controls: React.FC<ControlsProps> = ({
  elementsOrigin,
  elementToConfig,
  setElementToConfig,
  filesPath,
  dropNewNode,
  instantiateNode,
  onDeleteOriginElement,
  updateElementsOrigin,
  fieldImageOrigin,
  teamLogo,
}) => {
  if (elementToConfig) {
    return (
      <ControlsConfig
        elementsOrigin={elementsOrigin}
        elementToConfig={elementToConfig}
        setElementToConfig={setElementToConfig}
        filesPath={filesPath}
        updateElementsOrigin={updateElementsOrigin}
        onDeleteOriginElement={onDeleteOriginElement}
        fieldImageOrigin={fieldImageOrigin}
        teamLogo={teamLogo}
      />
    );
  }

  return (
    <ControlsHome
      elementsOrigin={elementsOrigin}
      setElementToConfig={setElementToConfig}
      dropNewNode={dropNewNode}
      instantiateNode={instantiateNode}
      onDeleteOriginElement={onDeleteOriginElement}
    />
  );
};

export default Controls;
