import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Row } from "antd";
import {
  ConfSubtitle,
  InitialsIcon,
  ConfContainer,
  ConfItem,
  UserRow,
} from "./Style";
import { useFetch, useMutation } from "../../../hooks";
import {
  TeamDiagramConfig,
  OrgChartType,
  TeamMember,
  ReporterType,
  UpdateTeamDiagramResponse,
} from "./Organization.d";
import { MessagesContext } from "../../../components/AppMessages";
import Modal from "../../../components/Modal";
import GeestSelect from "../../../GeestUI/GeestSelect";
import { Button } from "../../../GeestUI";

const Languages = {
  ENG: {
    Title: "Configure organization chart",
    Subtitle: "Select who each user responds to",
    NameLabel: "to",
    PersonInCharge: "Person in charge",
    OpcLabel: "Nobody",
    CancelButton: "Cancel",
    SaveButton: "Save",
    ErrorLabel: "Logic error",
  },
  ESP: {
    Title: "Configurar organigrama",
    Subtitle: "Selecciona a quién responde cada usuario",
    NameLabel: "a",
    PersonInCharge: "Persona a cargo",
    OpcLabel: "Nadie",
    CancelButton: "Cancelar",
    SaveButton: "Guardar",
    ErrorLabel: "Error de lógica",
  },
};

interface ConfiguratorProps {
  setModal: (modalName: string) => void;
  setChart: (chart: OrgChartType) => void;
  setUserCanEdit: (canEdit: boolean) => void;
}

const Configurator: React.FC<ConfiguratorProps> = ({
  setModal,
  setChart,
  setUserCanEdit,
}) => {
  const { Subtitle, CancelButton, SaveButton, OpcLabel, ErrorLabel } =
    Languages["ESP"];

  const { idTeam } = useParams<{ idTeam: string }>();
  const { showMessage } = useContext(MessagesContext);

  const [TeamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [Reporters, setReporters] = useState<ReporterType[]>([]);

  useFetch<TeamDiagramConfig>({
    func: "Ver2-Team-gtudc",
    args: { IdTeam: idTeam },
    onSuccess: (response) => {
      const { TeamMembers, Reporters } = response;
      setTeamMembers([
        { Id: "", FirstName: OpcLabel, LastName: "" },
        ...TeamMembers,
      ]);
      setReporters(Reporters);
    },
  });

  const [updateDiagramInfo, loading] = useMutation<UpdateTeamDiagramResponse>({
    func: "Ver2-Team-utud",
    onSuccess: (response) => {
      const {
        Update: { OrgChart, UserCanEditDiagram },
      } = response;
      setChart(OrgChart);
      setUserCanEdit(UserCanEditDiagram);
      handleOnClose();
    },
  });

  const handleOnSubmit = () => {
    updateDiagramInfo({
      args: {
        IdTeam: idTeam,
        Reporters,
      },
    });
  };

  const handleOnClose = () => {
    setModal("");
    setTeamMembers([]);
    setReporters([]);
  };

  const validateLoops = (originId: string, reporterId: string): boolean => {
    if (reporterId) {
      const { Reporter } =
        Reporters.find(({ User: { Id } }) => Id === reporterId) ||
        ({} as ReporterType);
      if (Reporter === originId) {
        showMessage(ErrorLabel, "error");
        return false;
      } else return validateLoops(originId, Reporter);
    }
    return true;
  };

  const handleOnChange = (value: string | any, index: number) => {
    if (validateLoops(Reporters[index]["User"]["Id"], value)) {
      let ReportersAux = [...Reporters];
      ReportersAux[index]["Reporter"] = Number(value);
      setReporters(ReportersAux);
    }
  };

  const getName = (fName: string, lName: string) => {
    return `${fName.split(" ")[0]} ${lName.split(" ")[0]}`;
  };

  const getOptions = (IdUser: string) =>
    TeamMembers.filter(({ Id }) => Id !== IdUser).map(
      ({ Id, FirstName, LastName, Email }) => ({
        label: getName(FirstName, LastName),
        value: Id,
        searchableValues: `${FirstName},${LastName},${Email}`,
      })
    );

  return (
    <Modal
      title="Configurar organigrama"
      onCancel={() => setModal("")}
      width="324px"
    >
      <ConfContainer>
        <ConfSubtitle>{Subtitle}</ConfSubtitle>
        {Reporters &&
          Reporters.map(
            (
              {
                User: { FirstName, LastName, Initials, Id, Email },
                Reporter = "",
              },
              index
            ) => (
              <ConfItem key={Id}>
                <UserRow>
                  <InitialsIcon>{Initials}</InitialsIcon>
                  {getName(FirstName, LastName)}
                </UserRow>
                <GeestSelect
                  value={Reporter}
                  onChange={(value) => handleOnChange(value, index)}
                  options={getOptions(Id)}
                  placeholderSelect="Persona a cargo..."
                />
              </ConfItem>
            )
          )}
      </ConfContainer>
      <Row justify="space-between" align="middle">
        <Button
          loading={loading}
          type="secondary"
          onClick={handleOnClose}
          style={{ width: "calc(50% - 5px)" }}
        >
          {CancelButton}
        </Button>
        <Button
          loading={loading}
          type="primary"
          onClick={handleOnSubmit}
          style={{ width: "calc(50% - 5px)" }}
        >
          {SaveButton}
        </Button>
      </Row>
    </Modal>
  );
};

export default Configurator;
