import React, { useEffect, useState } from "react";
import _ from "lodash";
import { nanoid } from "nanoid";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Tooltip from "../../../Tooltip";
import Node from "../Node";
import noDataHooks from "../../../../resources/img/noDataHooks.svg";
import { languages } from "../dicitonary";
import { ElementOrigin, NodeType } from "../../PDFConfig.d";
import {
  AppliedControlsSecction,
  AppliedList,
  AppliedNode,
  ControlsSecction,
  ControlsWrapper,
  NoElementsWrapper,
  SearchContainer,
  SearchInput,
  SectionTitle,
} from "./styles";
import { EditIcon, DeleteIcon } from "../../../hoverIcons";
import TypeIcons from "../../TypeIcons";
import DeleteOriginElement from "../../Modals/DeleteOriginElement";

interface ControlsHomeProps {
  elementsOrigin: ElementOrigin[];
  setElementToConfig: (id: string | null) => void;
  dropNewNode: (e: any, nodeType: NodeType) => void;
  instantiateNode: (e: any, node: ElementOrigin) => void;
  onDeleteOriginElement: (elementId: string) => void;
}

const ControlsHome: React.FC<ControlsHomeProps> = ({
  elementsOrigin,
  setElementToConfig,
  dropNewNode,
  instantiateNode,
  onDeleteOriginElement,
}) => {
  const [elementToDelete, setElementToDelete] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [filteredElements, setFilteredElements] =
    useState<ElementOrigin[]>(elementsOrigin);

  const {
    ElementTypes,
    ElementTypesInfo,
    CreatedElements,
    CreatedElementsInfo,
    SearchLabel,
    NoCreatedElements,
    noSearchResults,
  } = languages["ESP"];

  const filterElements = (filterValue: string) => {
    let newElements = _.filter(elementsOrigin, (element: ElementOrigin) => {
      if (
        element.name
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase())
      )
        return true;
      return false;
    });
    setFilteredElements(newElements);
  };

  const handleSearch = (value: string) => {
    setFilter(value);
    filterElements(value);
  };

  useEffect(() => {
    filterElements(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsOrigin]);

  return (
    <ControlsWrapper>
      {elementToDelete && (
        <DeleteOriginElement
          onCancel={() => setElementToDelete("")}
          onDelete={() => {
            onDeleteOriginElement(elementToDelete);
            setElementToDelete("");
          }}
        />
      )}

      <ControlsSecction>
        <SectionTitle>
          <p>{ElementTypes}</p>
          <Tooltip placement="bottom" title={ElementTypesInfo}>
            <AiOutlineInfoCircle size={16} />
          </Tooltip>
        </SectionTitle>

        <Node nodeType="text" dropNewNode={dropNewNode} />
        <Node nodeType="image" dropNewNode={dropNewNode} />
        <Node nodeType="sign" dropNewNode={dropNewNode} />
      </ControlsSecction>

      <AppliedControlsSecction>
        <SectionTitle>
          <p>{CreatedElements}</p>
          <Tooltip placement="bottom" title={CreatedElementsInfo}>
            <AiOutlineInfoCircle size={16} />
          </Tooltip>
        </SectionTitle>

        <SearchContainer>
          <SearchInput
            value={filter}
            onChange={({ target: { value } }) => handleSearch(value)}
            placeholder={SearchLabel}
          />
        </SearchContainer>

        <AppliedList>
          {filteredElements.length === 0 ? (
            <NoElementsWrapper>
              <p>
                {elementsOrigin.length === 0
                  ? NoCreatedElements
                  : noSearchResults}
              </p>
              <img src={noDataHooks} alt="" />
            </NoElementsWrapper>
          ) : (
            filteredElements.map((element) => {
              const typeIcon = TypeIcons[element.type];

              return (
                <AppliedNode
                  key={nanoid()}
                  draggable
                  onDragEnd={(e) => instantiateNode(e, element)}
                >
                  <div className="title">
                    <img
                      src={typeIcon.icon}
                      style={{ ...typeIcon.extraStyles }}
                      alt=""
                    />
                    <p>{element.name}</p>
                  </div>
                  <div className="icon-box">
                    <EditIcon
                      filled
                      containerSize={28}
                      customIconSize={17}
                      fillColor="#CCE6FF"
                      onClick={() => setElementToConfig(element.id)}
                    />
                    <DeleteIcon
                      filled
                      containerSize={28}
                      customIconSize={14}
                      fillColor="#CCE6FF"
                      onClick={() => setElementToDelete(element.id)}
                    />
                  </div>
                </AppliedNode>
              );
            })
          )}
        </AppliedList>
      </AppliedControlsSecction>
    </ControlsWrapper>
  );
};

export default ControlsHome;
