import React, { useContext, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { Tooltip } from "antd";
import { AiOutlineCopy } from "react-icons/ai";
import { MessagesContext } from "../../../../components/AppMessages";

import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import {
  editorStateRaw,
  editorStateToHTML,
} from "../../../../components/GeestTextEditor/Utils/Editor";
import GeestTextEditor from "../../../../components/GeestTextEditor";

import prevState from "./prevState";
import { FieldEditorOrigin } from "../../../../components/GeestTextEditor/GeestTextEditor.d";
import {
  ControlsContainer,
  DisplayControl,
  EditorTitleWrapper,
  EditorValueTitle,
  EditorValueWrapper,
  IconWrapper,
  StyledSwitch,
  TextEditorContainer,
} from "./Styles";

const DefaultFieldEditorOrigin = [
  {
    DataType: "string",
    Label: "nombre",
    IdField: 0,
  },
  {
    DataType: "number",
    Label: "edad",
    IdField: 1,
  },
  {
    DataType: "string",
    Label: "generic 2",
    IdField: 2,
  },
  {
    DataType: "string",
    Label: "generic 3",
    IdField: 3,
  },
  {
    DataType: "string",
    Label: "generic 4",
    IdField: 4,
  },
  {
    DataType: "string",
    Label: "generic 5",
    IdField: 5,
  },
  {
    DataType: "string",
    Label: "generic 6",
    IdField: 6,
  },
  {
    DataType: "string",
    Label: "generic 7",
    IdField: 7,
  },
  {
    DataType: "string",
    Label: "generic 8",
    IdField: 8,
  },
  {
    DataType: "string",
    Label: "generic 9",
    IdField: 9,
  },
  {
    DataType: "string",
    Label: "generic 10",
    IdField: 10,
  },
  {
    DataType: "string",
    Label: "generic 11",
    IdField: 11,
  },
  {
    DataType: "string",
    Label: "generic 12",
    IdField: 12,
  },
  {
    DataType: "string",
    Label: "generic 13",
    IdField: 13,
  },
];

interface PlaygroundProps {}

const Playground: React.FC<PlaygroundProps> = () => {
  const { showMessage } = useContext(MessagesContext);
  const [showFirstEditor, setShowFirstEditor] = useState<boolean>(true);
  const [showHTML, setShowHTML] = useState<boolean>(false);
  const [showRawJSON, setShowRawJSON] = useState<boolean>(false);
  const [oneBlock, setOneBlock] = useState<boolean>(false);
  const [required, setRequired] = useState<boolean>(false);
  const [characterLimit, setCharacterLimit] = useState<boolean>(false);

  const [toolbarPopup, setToolbarPopup] = useState<boolean>(false);

  const [showSecondEditor, setShowSecondEditor] = useState<boolean>(false);
  const [showSecondEditorRawJSON, setShowSecondEditorRawJSON] =
    useState<boolean>(false);
  const [seconEditorAsReadOnly, setSeconEditorAsReadOnly] =
    useState<boolean>(false);

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const [secondEditor, setSecondEditor] = useState<EditorState>(() => {
    const convertedState = convertFromRaw(
      JSON.parse(JSON.stringify(prevState))
    );

    return EditorState.createWithContent(convertedState);
  });

  const [fieldEditorOrigin] = useState<FieldEditorOrigin[]>(
    DefaultFieldEditorOrigin
  );

  const handleCopy = (element: string) => {
    navigator.clipboard.writeText(element);
    showMessage("Contenido copiado", "success");
  };

  return (
    <TextEditorContainer>
      <ControlsContainer>
        <p className="title">first editor:</p>

        <DisplayControl>
          <StyledSwitch
            checked={toolbarPopup}
            onChange={(check) => setToolbarPopup(check)}
          />
          <p>Toolbar as popup</p>
        </DisplayControl>

        <DisplayControl>
          <StyledSwitch
            checked={showFirstEditor}
            onChange={(check) => setShowFirstEditor(check)}
          />
          <p>Show editor</p>
        </DisplayControl>

        <DisplayControl>
          <StyledSwitch
            checked={showHTML}
            onChange={(check) => setShowHTML(check)}
          />
          <p>Show HTML</p>
        </DisplayControl>

        <DisplayControl>
          <StyledSwitch
            checked={showRawJSON}
            onChange={(check) => setShowRawJSON(check)}
          />
          <p>Show raw JSON</p>
        </DisplayControl>

        <DisplayControl>
          <StyledSwitch
            checked={oneBlock}
            onChange={(check) => setOneBlock(check)}
          />
          <p>no enter</p>
        </DisplayControl>

        <DisplayControl>
          <StyledSwitch
            checked={required}
            onChange={(check) => setRequired(check)}
          />
          <p>required</p>
        </DisplayControl>

        <DisplayControl>
          <StyledSwitch
            checked={characterLimit}
            onChange={(check) => setCharacterLimit(check)}
          />
          <p>character limit 70</p>
        </DisplayControl>
      </ControlsContainer>

      <ControlsContainer>
        <p className="title">second editor:</p>

        <DisplayControl>
          <StyledSwitch
            checked={toolbarPopup}
            onChange={(check) => setToolbarPopup(check)}
          />
          <p>Toolbar as popup</p>
        </DisplayControl>

        <DisplayControl>
          <StyledSwitch
            checked={showSecondEditor}
            onChange={(check) => setShowSecondEditor(check)}
          />
          <p>Show editor</p>
        </DisplayControl>

        <DisplayControl>
          <StyledSwitch
            checked={showSecondEditorRawJSON}
            onChange={(check) => setShowSecondEditorRawJSON(check)}
          />
          <p>Show editor raw JSON</p>
        </DisplayControl>

        <DisplayControl>
          <StyledSwitch
            checked={seconEditorAsReadOnly}
            onChange={(check) => setSeconEditorAsReadOnly(check)}
          />
          <p>editor as read only</p>
        </DisplayControl>
      </ControlsContainer>

      {showFirstEditor && (
        <GeestTextEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          editorBorder
          toolbarConfig={{ spacer: true, field: true, image: true }}
          popUpToolbar={toolbarPopup}
          fieldEditorOrigin={fieldEditorOrigin}
          oneBlock={oneBlock}
          required={required}
          maxLength={characterLimit ? 70 : null}
        />
      )}

      {showHTML && (
        <EditorValueWrapper $overflow>
          <EditorTitleWrapper>
            <Tooltip
              title="Copiar contenido de visualización en portapapeles"
              placement="right"
            >
              <IconWrapper
                onClick={() => handleCopy(editorStateToHTML(editorState))}
              >
                <AiOutlineCopy color="#48505e" size={20} />
              </IconWrapper>
            </Tooltip>
            <EditorValueTitle>HTML</EditorValueTitle>
          </EditorTitleWrapper>
          <SyntaxHighlighter
            children={editorStateToHTML(editorState)}
            language="html"
            style={atomOneDark}
          />
        </EditorValueWrapper>
      )}

      {showRawJSON && (
        <EditorValueWrapper>
          <EditorTitleWrapper>
            <Tooltip
              title="Copiar contenido de visualización en portapapeles"
              placement="right"
            >
              <IconWrapper
                onClick={() =>
                  handleCopy(
                    JSON.stringify(editorStateRaw(editorState), null, 2)
                  )
                }
              >
                <AiOutlineCopy color="#48505e" size={20} />
              </IconWrapper>
            </Tooltip>
            <EditorValueTitle>Raw state JSON</EditorValueTitle>
          </EditorTitleWrapper>
          <SyntaxHighlighter
            children={JSON.stringify(editorStateRaw(editorState), null, 2)}
            language="JSON"
            style={atomOneDark}
          />
        </EditorValueWrapper>
      )}

      {showSecondEditor && (
        <GeestTextEditor
          editorState={secondEditor}
          onEditorStateChange={setSecondEditor}
          editorBorder
          toolbarConfig={{ spacer: true, field: true, image: true }}
          popUpToolbar={toolbarPopup}
          fieldEditorOrigin={fieldEditorOrigin}
          readOnly={seconEditorAsReadOnly}
        />
      )}

      {showSecondEditorRawJSON && (
        <EditorValueWrapper>
          <EditorTitleWrapper>
            <Tooltip
              title="Copiar contenido de visualización en portapapeles"
              placement="right"
            >
              <IconWrapper
                onClick={() =>
                  handleCopy(
                    JSON.stringify(editorStateRaw(secondEditor), null, 2)
                  )
                }
              >
                <AiOutlineCopy color="#48505e" size={20} />
              </IconWrapper>
            </Tooltip>
            <EditorValueTitle>Second Editor Raw state JSON</EditorValueTitle>
          </EditorTitleWrapper>
          <SyntaxHighlighter
            children={JSON.stringify(editorStateRaw(secondEditor), null, 2)}
            language="JSON"
            style={atomOneDark}
          />
        </EditorValueWrapper>
      )}
    </TextEditorContainer>
  );
};

export default Playground;
