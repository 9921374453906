import React from "react";
import DynamicInput from "../../../../../../../components/DynamicInput";
import { FieldConfigWrapper, SubTitle } from "../../Styles";

interface TimeConfigProps {
  value: string;
  onChange: (newValue: string) => void;
  disabled: boolean;
}

const TimeConfig: React.FC<TimeConfigProps> = ({
  value,
  onChange,
  disabled,
}) => {
  return (
    <>
      <FieldConfigWrapper>
        <SubTitle>Valor por defecto</SubTitle>
        <DynamicInput
          value={value}
          type="time"
          dataOrigin={[]}
          format=""
          configuration=""
          fieldName=""
          isConsult={false}
          disabled={disabled}
          required={false}
          onChange={onChange}
        />
      </FieldConfigWrapper>
    </>
  );
};

export default TimeConfig;
