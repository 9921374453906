import React, { useState, useEffect } from "react";
import { TemplateImportsProps, Option } from "./InstallTemplate.d";
import { Row } from "antd";
import { TemplateImportsContainer, ColumnTitle } from "./style";
import { IoSettingsOutline } from "react-icons/io5";
import { Button } from "../../../GeestUI";
import GeestSelect from "../../../GeestUI/GeestSelect/GeestSelect";
import { Languages } from "./Dictionary";
import TemplateImportsList from "./TemplateImportsList";

const TemplateImports: React.FC<TemplateImportsProps> = ({
  onAdvancedSettings,
  varDBs,
  onInstall,
  Teams,
  teamSelected,
  setTeamSelected,
  processTemplates,
  IdTeam,
  isSharing,
  IdProcessTemplate,
}) => {
  const {
    BusinessToInstallLabel,
    BusinessToInstallPackageLabel,
    InstallLabel,
    AdvancedSettingsLabel,
  } = Languages["ESP"];
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (Teams) {
      const aux = [...Teams].map((t) => {
        return { label: t.Name, value: t.IdTeam };
      });
      if (aux.length > 0 && !teamSelected) setTeamSelected(aux[0].value);
      setOptions(aux);
    }
    //eslint-disable-next-line
  }, [Teams]);

  return (
    <Row style={{ width: "100%", marginTop: "1rem" }} justify="center">
      <TemplateImportsContainer>
        <Row
          style={{ marginBottom: "1rem", width: "100%" }}
          justify="space-between"
        >
          <ColumnTitle>
            {IdProcessTemplate && IdProcessTemplate?.length > 1
              ? BusinessToInstallPackageLabel
              : BusinessToInstallLabel}
          </ColumnTitle>
          <GeestSelect
            value={teamSelected}
            onChange={(value: number) => setTeamSelected(value)}
            options={options}
            $width="265px"
            $listWidth="265px"
            style={{
              paddingLeft: "10px",
            }}
            disabled={Boolean(IdTeam) || IdTeam === 0 || !isSharing}
            valueNecesary
          />
        </Row>
        <TemplateImportsList
          processTemplates={processTemplates}
          varDBs={varDBs}
          IdProcessTemplate={IdProcessTemplate}
        />
        <Row style={{ width: "100%", marginTop: "1.2rem" }} justify="center">
          <Button
            size="xlarge"
            Icon={IoSettingsOutline}
            onClick={onAdvancedSettings}
          >
            {AdvancedSettingsLabel}
          </Button>
        </Row>
        <Row style={{ width: "100%", marginTop: "2rem" }} justify="center">
          <Button onClick={onInstall} type="primary">
            {InstallLabel}
          </Button>
        </Row>
      </TemplateImportsContainer>
    </Row>
  );
};

export default TemplateImports;
